import * as Yup from 'yup';

export const RoleValidationSchema = Yup.object().shape({
	name: Yup.string()
		.required('Nazwa jest wymagana.')
		.max(64, 'Nazwa nie może być dłuższa niż 64 znaki.'),
	type: Yup.string().required('Typ jest wymagany'),
	category: Yup.string().required('Zasięg jest wymagany'),
});

export const EditRoleValidationSchema = Yup.object().shape({
	name: Yup.string()
		.required('Nazwa jest wymagana.')
		.max(64, 'Nazwa nie może być dłuższa niż 64 znaki.'),
	type: Yup.string().required('Typ jest wymagany'),
	category: Yup.string().required('Zasięg jest wymagany'),
	permissions: Yup.array()
		.of(Yup.string())
		.min(1, 'Lista uprawnień nie może być pusta.'),
});
