import { SubjectContactDto } from '@services/src/models';

export const CheckIfContactAlreadyExists = (
	newContact: any,
	contacts: SubjectContactDto[],
	isEditable: boolean
) => {
	if (isEditable) {
		if (
			contacts.some((x) => {
				return (
					CheckIfSharedDataAlredyExists(x, newContact) &&
					(x.connectedWithIkpRecrutation ?? false) ===
						(newContact.connectedWithIkpRecrutation ?? false)
				);
			})
		) {
			return true;
		}

		return false;
	} else {
		if (
			contacts.some((x) => {
				return CheckIfSharedDataAlredyExists(x, newContact);
			})
		) {
			return true;
		}

		return false;
	}
};

function CheckIfSharedDataAlredyExists(x: SubjectContactDto, newContact: any) {
	return (
		x.firstName === newContact.firstName &&
		x.lastName === newContact.lastName &&
		x.email === newContact.email &&
		x.phoneNumber === newContact.phoneNumber &&
		x.position === newContact.position
	);
}
