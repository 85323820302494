import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<ProfiBazaAPIModels.ProposalStatus, string>([
	[ProfiBazaAPIModels.ProposalStatus.Accepted, 'Zaakceptowany'],
	[ProfiBazaAPIModels.ProposalStatus.Canceled, 'Anulowany'],
	[ProfiBazaAPIModels.ProposalStatus.Rejected, 'Odrzucony'],
	[ProfiBazaAPIModels.ProposalStatus.Sended, 'Wysłany'],
]);

export const map = (value: ProfiBazaAPIModels.ProposalStatus): string =>
	mapings.get(value) ?? '';
