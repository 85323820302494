import Authorized from '@authorization/authorized';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import Modal from 'antd/lib/modal/Modal';
import { UpdateDictionaryValueDto } from 'components/dictionary/model/UpdateDictionaryValueCommand';
import { FormValueDto } from 'components/forms/model/CreateFormValueCommand';
import { UpdateFormValueDto } from 'components/forms/model/UpdateFormValueCommand';
import { UpdateFormVersionDto } from 'components/forms/model/UpdateFormVersionCommand';
import { Formik, FormikActions, FormikProps } from 'formik';
import { showConfirm } from 'help/NotificationHelper';
import { useRootData } from 'hooks/hook';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormPartDto, FormType } from 'services/src/models';
import { IGridStore } from 'stores/GridStore';

import AddButton from '../../../shared/buttons/AddButton';
import { createFormValueValidationSchema } from './FormValueValidationSchema';
import { ValuesForm } from './ValuesForm';

export interface FormValuesModalProps {
	formVersionId: string;
	refresh: (value?: FormPartDto) => void;
	id?: string;
	setId: (id: string | undefined) => void;
	visible: boolean;
	setVisible: (visible: boolean) => void;
	smallButton?: boolean;
	noButton?: boolean;
	maxOrder?: number;
	type: FormType;
}

const FormValuesModal: React.FC<FormValuesModalProps> = (props) => {
	const {
		formVersionId,
		id,
		visible,
		setVisible,
		setId,
		smallButton,
		maxOrder,
		type,
	} = props;

	const [updateModel, setUpdateModel] = useState<UpdateFormValueDto>();

	useEffect(() => {
		if (id) {
			ajaxCatch(() =>
				getProfiBazaApiClient().then((api) => {
					return api.formPart
						.getById(id)
						.then((result: ProfiBazaAPIModels.FormPartDto) =>
							setUpdateModel(new UpdateFormValueDto(result))
						);
				})
			);
		}
	}, [id]);

	const handleCancel = (
		e: React.MouseEvent<HTMLElement, MouseEvent>,
		actions: FormikActions<any>
	) => {
		setVisible(false);
		actions.resetForm();
		setId(undefined);
	};

	const handleCreate = (
		values: FormValueDto,
		actions: FormikActions<ProfiBazaAPIModels.CreateFormPartCommand>,
	) => {
		const command = FormValueDto.toCommand(values, props.type);

		let createdFormId: string | undefined;
		ajaxByUser(
			'Utworzono część formularza',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.formPart
						.create({
							body: command,
						})
						.then((response) => {
							createdFormId = response.id;
							return response;
						})
				),
			() => {
				setVisible(false);
				actions.resetForm();
				const mappedValues = FormValueDto.mapToFormValueItem(
					values,
					createdFormId,
					maxOrder ?? 0
				);
				props.refresh(mappedValues);
			},
			(errors) => {
				actions.setErrors(errors);
			}
		).then(() => actions.setSubmitting(false));
	};

	const handleUpdate = (
		values: UpdateFormValueDto,
		actions: FormikActions<UpdateFormValueDto>
	) => {
		const command = UpdateFormValueDto.toCommand(values);

		const update = () => {
			ajaxByUser(
				'Zaktualizowano część formularza',
				() =>
					getProfiBazaApiClient().then((api) =>
						api.formPart.update({
							body: command,
						})
					),
				() => {
					setVisible(false);
					actions.resetForm();
					setUpdateModel(undefined);
					setId(undefined);
					const mappedValues = FormValueDto.mapToFormValueItem(
						values,
						values.id,
						values.order
					);
					props.refresh(mappedValues);
				},
				(errors) => {
					actions.setErrors(errors);
				}
			).then(() => actions.setSubmitting(false));
		};

		update();
	};

	return (
		<>
			{!props.noButton && (
				<AddButton tall={!smallButton} onClick={() => setVisible(true)}>
					Dodaj
				</AddButton>
			)}

			{id === undefined ? (
				<Formik
					validateOnChange={false}
					validateOnBlur
					initialValues={{
						formVersionId,
						pageOrientation: undefined,
						label: undefined,
						applicationName: undefined,
						formName: undefined,
					}}
					enableReinitialize
					validationSchema={createFormValueValidationSchema(
						id === undefined,
						type
					)}
					isInitialValid
					onSubmit={handleCreate}
					render={(
						props: FormikProps<
							ProfiBazaAPIModels.CreateFormPartCommand
						>
					) => (
						<Modal
							title="Dodaj część formularza"
							visible={visible}
							onOk={(e) => {
								props.submitForm();
							}}
							okButtonProps={{ disabled: props.isSubmitting }}
							cancelButtonProps={{ disabled: props.isSubmitting }}
							cancelText="Anuluj"
							centered
							onCancel={(e) => handleCancel(e, props)}
							okText="Utwórz"
							destroyOnClose
						>
							<ValuesForm
								isCreation={true}
								formikProps={props}
								editable
								type={type}
							/>
						</Modal>
					)}
				/>
			) : (
				<Formik
					validateOnChange={false}
					validateOnBlur
					initialValues={updateModel || {}}
					enableReinitialize
					validationSchema={createFormValueValidationSchema(
						id === undefined,
						type
					)}
					isInitialValid
					onSubmit={handleUpdate}
					render={(props: FormikProps<UpdateFormValueDto>) => (
						<Modal
							title="Zaktualizuj wartość formularza"
							visible={visible}
							onOk={() => {
								props.submitForm();
							}}
							okButtonProps={{ disabled: props.isSubmitting }}
							cancelButtonProps={{ disabled: props.isSubmitting }}
							cancelText="Anuluj"
							centered
							onCancel={(e) => handleCancel(e, props)}
							okText="Aktualizuj"
							destroyOnClose
						>
							<ValuesForm
								isCreation={false}
								formikProps={props}
								editable
								type={type}
							/>
						</Modal>
					)}
				/>
			)}
		</>
	);
};

export default observer(FormValuesModal);
