import { Activations } from 'components/notifications/types/Activations';
import moment from 'moment';
import { Moment } from 'moment';
import { NotificationDefinitionActivationDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export class UpdateNotificationOccurrenceCommand {
	id?: string;
	header?: string;
	triggeredWeekBefore?: boolean;
	triggeredDayBefore?: boolean;
	triggeredExactDay?: boolean;
	customTriggerActivation?: boolean;
	customTrigger?: number;
	validDateRange?: [Moment?, Moment?];

	constructor(model?: ProfiBazaAPIModels.NotificationOccurrenceDto) {
		this.id = model?.id;
		this.header = model?.description;
		this.validDateRange = [moment(model?.dateFrom), moment(model?.dateTo)];
		this.triggeredWeekBefore =
			model?.activations?.find(
				(x) => x.dayDifference === Activations.WeekBefore
			) != null ?? false;
		this.triggeredDayBefore =
			model?.activations?.find(
				(x) => x.dayDifference === Activations.DayBefore
			) != null ?? false;
		this.triggeredExactDay =
			model?.activations?.find(
				(x) => x.dayDifference === Activations.ExactDay
			) != null ?? false;

		const predefinedActivations = Object.keys(Activations).map((key) =>
			parseInt(key)
		);
		const customTriggers = model?.activations?.filter(
			(a) => !predefinedActivations.includes(a.dayDifference!)
		);

		this.customTriggerActivation = !!customTriggers?.length;
		this.customTrigger = customTriggers?.length
			? Math.abs(customTriggers[0].dayDifference!)
			: undefined;
	}

	toDto(): ProfiBazaAPIModels.NotificationOccurrenceDto {
		let tmpActivations = Array<NotificationDefinitionActivationDto>();

		if (this.triggeredWeekBefore)
			tmpActivations.push({ dayDifference: Activations.WeekBefore });

		if (this.triggeredDayBefore)
			tmpActivations.push({ dayDifference: Activations.DayBefore });

		if (this.triggeredExactDay)
			tmpActivations.push({ dayDifference: Activations.ExactDay });

		if (this.customTrigger)
			tmpActivations.push({
				dayDifference: Math.abs(this.customTrigger),
			});

		const dto: ProfiBazaAPIModels.NotificationOccurrenceDto = {
			id: this.id,
			description: this.header,
			dateFrom: this.validDateRange
				? this.validDateRange[0]?.utc(true).toDate()
				: undefined,
			dateTo: this.validDateRange
				? this.validDateRange[1]?.utc(true).toDate()
				: undefined,
			activations: tmpActivations,
		};

		return dto;
	}

	static toUpdateCommand(
		model?: UpdateNotificationOccurrenceCommand
	): ProfiBazaAPIModels.UpdateNotificationOccurrenceCommand {
		let tmpActivations = Array<NotificationDefinitionActivationDto>();

		if (model?.triggeredWeekBefore)
			tmpActivations.push({ dayDifference: Activations.WeekBefore });

		if (model?.triggeredDayBefore)
			tmpActivations.push({ dayDifference: Activations.DayBefore });

		if (model?.triggeredExactDay)
			tmpActivations.push({ dayDifference: Activations.ExactDay });

			if (model?.customTrigger !== undefined && model?.customTrigger !== null)
			tmpActivations.push({
				dayDifference: -Math.abs(model?.customTrigger),
			});

		const command: ProfiBazaAPIModels.UpdateNotificationOccurrenceCommand = {
			id: model?.id,
			description: model?.header,
			activations: tmpActivations,
			dateFrom: model?.validDateRange
				? model.validDateRange[0]?.utc(true).toDate()
				: undefined,
			dateTo: model?.validDateRange
				? model.validDateRange[1]?.utc(true).toDate()
				: undefined,
		};

		return command;
	}
}
