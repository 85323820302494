import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<ProfiBazaAPIModels.DocumentCategory, string>([
	[ProfiBazaAPIModels.DocumentCategory.Attachment, 'Załącznik'],
	[ProfiBazaAPIModels.DocumentCategory.Statement, 'Sprawozdanie'],
	[ProfiBazaAPIModels.DocumentCategory.VoivodeOpinion, 'Opinia wojewody'],
]);

export const map = (value: ProfiBazaAPIModels.DocumentCategory): string =>
	mappings.get(value) ?? '';
