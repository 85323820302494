import React, { useState } from 'react';
import { Row, Col, Select, Space } from 'antd';
import {
	FFieldLabel,
	FInput,
	FSelect,
	FSelectSubjects,
	FCheckbox,
} from 'forms/FormikFormItems';
import { Field, FormikProps } from 'formik';
import * as PageOrientationMapper from 'services/mappers/PageOrientation';
import OrbeonFormTable from 'components/forms/table/OrbeonFormTable';
import { FormType, OrbeonFormDefinition } from 'services/src/models';

interface IProps {
	editable: boolean;
	isCreation: boolean;
	formikProps: FormikProps<any>;
	type: FormType;
}

export const ValuesForm: React.FC<IProps> = ({
	editable,
	formikProps,
	isCreation,
	type,
}) => {
	const [visible, setVisible] = useState<boolean>(false);
	const [selectedValue, setSelectedValue] = useState<
		OrbeonFormDefinition | undefined
	>(undefined);

	return (
		<>
			<Row>
				<Col span={24}>
					<FFieldLabel label="Nazwa" />
					<Field
						label="Nazwa"
						component={FInput}
						placeholder="Nazwa"
						name="label"
					/>
				</Col>
			</Row>
			{isCreation && (
				<Row align="bottom">
					<Col md={24}>
						<FFieldLabel label="Część formularza" />
						<Field
							label="Część formularza"
							style={{ width: '100%' }}
							component={FSelectSubjects}
							name="title"
							onClick={() => {
								setVisible(true);
							}}
							dropDown={true}
							editable={editable}
						/>
					</Col>
				</Row>
			)}

			<OrbeonFormTable
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				type={type}
				acceptForm={(value) => {
					setVisible(false);
					formikProps.setFieldValue('title', value?.title);
					formikProps.setFieldValue(
						'applicationName',
						value?.applicationName
					);
					formikProps.setFieldValue('formName', value?.formName);
				}}
			/>
			{(type === FormType.StatementMZ49 || type === FormType.StatementMZ53 || type === FormType.StatementNPZ) && 
			<>
				<Row>
					<Col span={12}>
						<Space>
							<Field
								label="Widoczna w podglądzie"
								component={FCheckbox}
								name="isVisibleInViewModel"
								id="isVisibleInViewModel"
							/>
							<FFieldLabel
								label="Widoczna w podglądzie"
								for="isVisibleInViewModel"
							/>
						</Space>
					</Col>
				</Row>
				<Row align="bottom">
					<Col span={24}>
						<FFieldLabel label="Orientacja" />
						<Field
							label="Orientacja"
							component={FSelect}
							readOnly={!editable}
							placeholder="Orientacja"
							name="pageOrientation"
						>
							{Array.from(
								PageOrientationMapper.mapings.entries()
							).map((x, i) => (
								<Select.Option key={i} value={x[0]}>
									{x[1]}
								</Select.Option>
							))}
						</Field>
					</Col>
				</Row>
			</>
			}
		</>
	);
};
