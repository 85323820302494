import { WarningTwoTone } from '@ant-design/icons';
import { InterventionCommentArea } from '@components/intervention/comments/InterventionCommentArea';
import { InterventionCommentIcon } from '@components/intervention/comments/InterventionCommentIcon';
import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { Space } from 'antd';
import { ChangedVersionDictionaryDto } from 'components/intervention/model/InterventionBaseCommand';
import { Field } from 'formik';
import { FFieldLabel } from 'forms/FormikFormItems';
import { useRootData } from 'hooks/hook';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { DictionaryValueItemDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IRizpDictionaryStore } from 'stores/RizpDictionaryStore';

import { IDictionaryStore } from '../../dictionaryStore';
import { FSelectDictionary } from '../FSelectDictionary';
import MultiLevelDictionaryPicker from './MultiLevelDictionaryPicker';

/**
 * @param readOnly define if initial dictionary conditions not fulfilled
 */
interface IProps<T> {
	label?: string;
	disabled?: boolean;
	name: string;
	codeName: string;
	multiple?: boolean;
	checkChanges?: (key: any, value?: any, parent?: any) => void;
	readOnly?: boolean;
	inline?: boolean;
	otherName?: string;
	otherValue?: string;
	valueId?: string;
	fetchInitialValues?: boolean;
	onChangeCallback?: (item: DictionaryValueItemDto) => void;
	onChangeEnd?: () => void;
	interventionPath: ProfiBazaAPIModels.DictionaryInterventionPath;
	dateRange?: [(Moment | undefined)?, (Moment | undefined)?] | Moment[];
	changedVersion?: ChangedVersionDictionaryDto | undefined;
	filter?: (value: DictionaryValueItemDto) => boolean;
	small?: boolean;
	disableBreadcrumbs?: boolean;
	helpField?: string;
	commentField?: string;
	disabledCodeName?: string[];
}

export const getOtherValueName = (
	defaultPrefix: string,
	value: string
): string => `${defaultPrefix}: ${value}`;

const DictionaryPicker = <T extends {}>(props: IProps<T>) => {
	const {
		name,
		label,
		disabled,
		checkChanges,
		codeName,
		multiple,
		readOnly,
		inline,
		fetchInitialValues,
		onChangeCallback,
		onChangeEnd,
		interventionPath,
		dateRange,
		small,
		disableBreadcrumbs,
		helpField,
		commentField,
		disabledCodeName,
	} = props;

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const [dictionaryInfo, setDictionaryInfo] = useState<
		ProfiBazaAPIModels.DictionaryInfoDto | undefined
	>();

	const [values, setValues] = useState<DictionaryValueItemDto[]>([]);
	const [
		isChangedDictionaryVersion,
		setIsChangedDictionaryVersion,
	] = useState<boolean>(false);
	const rizpDictionaryStore: IRizpDictionaryStore = useRootData(
		(store) => store.rizpDictionaryStore
	);

	const setChanged = (state: boolean) => {
		setIsChangedDictionaryVersion(state);
		rizpDictionaryStore.valueChangedInActivity.set(true);
	};

	useEffect(() => {
		dictionaryStore.initializeDictionary(
			codeName,
			interventionPath,
			dateRange?.[0],
			dateRange?.[1]
		);
	}, [dateRange?.[0], dateRange?.[1], interventionPath]);

	useEffect(() => {
		const dictionary = dictionaryStore.getDictionary(
			codeName,
			interventionPath
		);
		if (dictionary) {
			setDictionaryInfo(dictionary);
		}
	}, [
		props.interventionPath,
		dictionaryStore.getDictionary(codeName, interventionPath),
	]);

	const isChangedVersion = () => {
		if (props.changedVersion !== undefined && !disabled) {
			return true;
		} else if (isChangedDictionaryVersion && !disabled) {
			return true;
		}

		return false;
	};

	return (
		<>
			{dictionaryInfo?.isHierarchical ? (
				<Field
					name={name}
					component={MultiLevelDictionaryPicker}
					previewValues={rizpDictionaryStore.getByCodeName(
						props.codeName
					)}
					label={label!}
					helpField={helpField}
					multiple={multiple}
					disabled={disabled!}
					readOnly={readOnly}
					inline={inline}
					codeName={codeName}
					values={values}
					setValues={setValues}
					checkChanges={checkChanges}
					dictionaryValues={dictionaryInfo?.items ?? []}
					fetchInitialValues={fetchInitialValues}
					otherName={props.otherName}
					otherValue={props.otherValue || props.otherName}
					onChangeCallback={onChangeCallback}
					interventionPath={interventionPath}
					changedVersion={props.changedVersion}
					dateRange={dateRange}
					small={small}
					disableBreadcrumbs={disableBreadcrumbs}
					commentField={commentField}
				/>
			) : (
				<>
					<Space>
						{label && <FFieldLabel label={label} for={name} />}
						{helpField && (
							<LabelHelpTooltip
								field={helpField}
								defaultValue={label!}
							/>
						)}
						{commentField && (
							<InterventionCommentIcon fieldName={commentField} />
						)}
					</Space>
					{isChangedVersion() && (
						<ProfiBazaTooltip
							placement="top"
							title="Zmieniona wersja słownikowa"
						>
							<WarningTwoTone twoToneColor="#ff0000" />
						</ProfiBazaTooltip>
					)}
					<InterventionCommentArea fieldName={commentField}>
						<Field
							component={FSelectDictionary}
							previewValues={rizpDictionaryStore.getByCodeName(
								props.codeName
							)}
							setChanged={setChanged}
							codeName={codeName}
							dateRange={props.dateRange}
							name={name}
							label={label}
							otherValue={props.otherValue}
							otherName={props.otherName ?? 'value'}
							valueId={props.valueId}
							disabled={disabled}
							readOnly={readOnly}
							changeData={checkChanges}
							dictionary={dictionaryInfo}
							mode={props.multiple ? 'multiple' : undefined}
							onChangeCallback={onChangeCallback}
							onChangeEnd={onChangeEnd}
							changedVersion={props.changedVersion}
							filter={props.filter}
							disabledCodeName={props.disabledCodeName}
							className="dictionary-picker"
						/>
					</InterventionCommentArea>
				</>
			)}
		</>
	);
};

export default observer(DictionaryPicker);
