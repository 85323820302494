import { LoginOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { DeadlineProcessDto } from 'services/src/models';

interface IProps {
	deadlineProcess?: DeadlineProcessDto[];
	subjectId?: string;
	statusName?: string;
	editable: boolean;
	setVisible: (value: boolean) => void;
	setSelectedProcess: (value: string) => void;
}

const SubjectReportingProcessLabels: React.FC<IProps> = (props) => {
	return (
		<>
			{props.subjectId && (
				<Space>
					{ReportingProcessLabel(
						props.editable,
						props.setVisible,
						undefined,
						props.deadlineProcess?.length
					)}

					{props.deadlineProcess &&
						props.deadlineProcess.map((x) =>
							ReportingProcessLabel(
								props.editable,
								props.setVisible,
								x,
								props.deadlineProcess!.length,
								props.setSelectedProcess
							)
						)}
				</Space>
			)}
		</>
	);
};

const ManageProcessButton = () => {
	const history = useHistory();

	return (
		<Button
			type="primary"
			icon={<LoginOutlined />}
			onClick={() => {
				history.push('/admin/subjectReportingProcess');
			}}
		>
			Zarządzaj sprawozdawczością
		</Button>
	);
};

const EmptyProcessesButton = () => {
	return (
		<Button type="text" style={{ cursor: 'default' }}>
			Brak
		</Button>
	);
};

const ReportingProcessLabel = (
	editable: boolean,
	setVisible: Function,
	process?: DeadlineProcessDto,
	length?: number,
	setSelectedProcess?: Function
) => {
	return (
		<>
			{process ? (
				<Button
					type="default"
					onClick={() => {
						setVisible(true);
						setSelectedProcess!(process.id);
					}}
				>
					{process.process} (
					{process.day &&
						process.month &&
						process.cycleLength &&
						` co ${process.cycleLength} ${
							process.cycleLength == 1
								? `rok`
								: process.cycleLength < 5
								? `lata`
								: `lat`
						},
						${process.day}.${process.month < 10 ? `0${process.month}` : process.month}`}
					)
				</Button>
			) : (
				<>
					<ManageProcessButton />

					{!length && !editable && <EmptyProcessesButton />}
				</>
			)}
		</>
	);
};

export default SubjectReportingProcessLabels;
