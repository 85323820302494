import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<ProfiBazaAPIModels.ActionKind, string>([
	[ProfiBazaAPIModels.ActionKind.Added, 'Dodanie'],
	[ProfiBazaAPIModels.ActionKind.Downloaded, 'Pobranie'],
	[ProfiBazaAPIModels.ActionKind.Deleted, 'Usunięcie'],
]);

export const map = (value: ProfiBazaAPIModels.ActionKind): string =>
	mapings.get(value) ?? '';
