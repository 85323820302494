/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/userManualMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a UserManual. */
export class UserManual {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a UserManual.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UserManualGetManualResponse>
   */
  getManual(options?: Models.UserManualGetManualOptionalParams): Promise<Models.UserManualGetManualResponse>;
  /**
   * @param callback The callback
   */
  getManual(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getManual(options: Models.UserManualGetManualOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getManual(options?: Models.UserManualGetManualOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.UserManualGetManualResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getManualOperationSpec,
      callback) as Promise<Models.UserManualGetManualResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UserManualCreateResponse>
   */
  create(options?: Models.UserManualCreateOptionalParams): Promise<Models.UserManualCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.UserManualCreateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  create(options?: Models.UserManualCreateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UserManualCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.UserManualCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.UserManualUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.UserManualUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.UserManualUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param manualType Possible values include: 'Main', 'Administrative', 'Intervention',
   * 'Statement', 'Subject', 'RODO'
   * @param [options] The optional parameters
   * @returns Promise<Models.UserManualGetManualCategoryResponse>
   */
  getManualCategory(manualType: Models.UserManualType, options?: msRest.RequestOptionsBase): Promise<Models.UserManualGetManualCategoryResponse>;
  /**
   * @param manualType Possible values include: 'Main', 'Administrative', 'Intervention',
   * 'Statement', 'Subject', 'RODO'
   * @param callback The callback
   */
  getManualCategory(manualType: Models.UserManualType, callback: msRest.ServiceCallback<Models.UserManualDto[]>): void;
  /**
   * @param manualType Possible values include: 'Main', 'Administrative', 'Intervention',
   * 'Statement', 'Subject', 'RODO'
   * @param options The optional parameters
   * @param callback The callback
   */
  getManualCategory(manualType: Models.UserManualType, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.UserManualDto[]>): void;
  getManualCategory(manualType: Models.UserManualType, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.UserManualDto[]>, callback?: msRest.ServiceCallback<Models.UserManualDto[]>): Promise<Models.UserManualGetManualCategoryResponse> {
    return this.client.sendOperationRequest(
      {
        manualType,
        options
      },
      getManualCategoryOperationSpec,
      callback) as Promise<Models.UserManualGetManualCategoryResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteMethod(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.UserManualDownloadResponse>
   */
  download(id: string, options?: msRest.RequestOptionsBase): Promise<Models.UserManualDownloadResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  download(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  download(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  download(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.UserManualDownloadResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      downloadOperationSpec,
      callback) as Promise<Models.UserManualDownloadResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.UserManualPreviewResponse>
   */
  preview(id: string, options?: msRest.RequestOptionsBase): Promise<Models.UserManualPreviewResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  preview(id: string, callback: msRest.ServiceCallback<Models.UserManualFileDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  preview(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.UserManualFileDto>): void;
  preview(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.UserManualFileDto>, callback?: msRest.ServiceCallback<Models.UserManualFileDto>): Promise<Models.UserManualPreviewResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      previewOperationSpec,
      callback) as Promise<Models.UserManualPreviewResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getManualOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "documentsRepository/UserManual",
  queryParameters: [
    Parameters.manualType0,
    Parameters.formVersionId,
    Parameters.interventionPath1,
    Parameters.reportType0,
    Parameters.userManualAdministrativeType0,
    Parameters.rODOManualType0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "documentsRepository/UserManual",
  formDataParameters: [
    Parameters.file,
    Parameters.manualType1,
    Parameters.interventionPath2,
    Parameters.reportType1,
    Parameters.userManualAdministrativeType1,
    Parameters.formVersionId,
    Parameters.formValidFrom,
    Parameters.rODOManualType1
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "documentsRepository/UserManual",
  formDataParameters: [
    Parameters.id3,
    Parameters.file
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getManualCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "documentsRepository/UserManual/{manualType}/category",
  urlParameters: [
    Parameters.manualType2
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UserManualDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "documentsRepository/UserManual/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const downloadOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "documentsRepository/UserManual/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const previewOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "documentsRepository/UserManual/preview/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.UserManualFileDto
    },
    default: {}
  },
  serializer
};
