import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import formatHelpers from 'helper/formatHelpers';
import { DictionaryName } from 'services/mappers/DictionaryName';
import * as SubjectKind from 'services/mappers/SubjectKind';
import { SubjectListItemDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const defaultColumns: ProfiBazaColumnProps<SubjectListItemDto>[] = [
	{
		sorter: true,
		dataIndex: 'codeName',
		key: 'codeName',
		title: 'Id',
		filter: 'equals',
	},
	{
		sorter: true,
		dataIndex: 'name',
		key: 'name',
		title: 'Nazwa',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'address',
		key: 'address',
		title: 'Adres',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'cruipCode',
		key: 'cruipCode',
		title: 'Kod CRUiP',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'nip',
		key: 'nip',
		title: 'NIP',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'regon',
		key: 'regon',
		title: 'REGON',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'subjectKind',
		key: 'subjectKind',
		title: 'Rodzaj podmiotu',
		filter: 'dictionary',
		filterDictionary: DictionaryName.SubjectKind,
		render: (value: ProfiBazaAPIModels.SubjectKind) =>
			SubjectKind.map(value),
	},
	{
		sorter: true,
		dataIndex: 'validFrom',
		key: 'validFrom',
		title: 'Ważny od',
		filter: 'dateRange',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: true,
		dataIndex: 'validTo',
		key: 'validTo',
		title: 'Ważny do',
		filter: 'dateRange',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: true,
		dataIndex: 'isActiveAdminAccount',
		key: 'isActiveAdminAccount',
		title: 'Czy utworzono konto administratora',
		render: (value: boolean) => (value ? 'Tak' : 'Nie'),
		permission: ProfiBazaAPIModels.Permission.SubjectsProposalPossibilityHandling
	},
];

const interventionRealizationSubjectColumns: ProfiBazaColumnProps<
	SubjectListItemDto
>[] = [
	{
		sorter: true,
		dataIndex: 'codeName',
		key: 'codeName',
		title: 'Id',
		filter: 'equals',
	},
	{
		sorter: true,
		dataIndex: 'name',
		key: 'name',
		title: 'Nazwa',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'address',
		key: 'address',
		title: 'Adres',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'nip',
		key: 'nip',
		title: 'NIP',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'regon',
		key: 'regon',
		title: 'REGON',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'subjectKind',
		key: 'subjectKind',
		title: 'Rodzaj podmiotu',
		filter: 'dictionary',
		filterDictionary: DictionaryName.SubjectKind,
		render: (value: ProfiBazaAPIModels.SubjectKind) =>
			SubjectKind.map(value),
	},
	{
		sorter: true,
		dataIndex: 'voivodeship',
		key: 'voivodeship',
		title: 'województwo',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'county',
		key: 'county',
		title: 'powiat',
		filter: 'contains',
	},
];

export { defaultColumns, interventionRealizationSubjectColumns };
