import React from 'react';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { Tag } from 'antd';
import { SubjectReportingProcessStatus } from 'services/src/models';
import * as SubjectReportingProcessStatusMapper from '@services/mappers/SubjectReportingProcessStatus';
import { DictionaryName } from 'services/mappers/DictionaryName';
import formatHelpers from 'helper/formatHelpers';
import * as SubjectKind from 'services/mappers/SubjectKind';

const columns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.SubjectReportingProcessListItemDto
>[] = [
	{
		sorter: true,
		dataIndex: 'identifier',
		key: 'identifier',
		title: 'Kategoria/ID',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Podmiot',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'subjectKind',
		key: 'subjectKind',
		title: 'Kategoria podmiotu',
		filter: 'dictionary',
		filterDictionary: DictionaryName.SubjectKind,
		render: (value: ProfiBazaAPIModels.SubjectKind) =>
			SubjectKind.map(value),
	},
	{
		sorter: true,
		dataIndex: 'process',
		key: 'process',
		title: 'Proces',
		filter: 'dictionary',
		filterDictionary: DictionaryName.ReportingArea,
	},

	{
		sorter: true,
		dataIndex: 'deadline',
		key: 'deadline',
		title: 'Deadline',
		filter: 'contains',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: true,
		dataIndex: 'validFrom',
		key: 'validFrom',
		title: 'Data od',
		filter: 'contains',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: true,
		dataIndex: 'validTo',
		key: 'validTo',
		title: 'Data do',
		filter: 'dateRange',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		dataIndex: 'status',
		key: 'status',
		title: 'Status',
		width: 100,
		filter: 'dictionary',
		filterDictionary: DictionaryName.SubjectReportingProcessStatus,
		render: (value?: SubjectReportingProcessStatus) => {
			let color =
				value === SubjectReportingProcessStatus.ACTIVE
					? 'green'
					: value === SubjectReportingProcessStatus.INACTIVE
					? 'volcano'
					: 'geekblue';
			const statusValue = SubjectReportingProcessStatusMapper.map(value!);
			return <Tag color={color}>{statusValue}</Tag>;
		},
	},
];

export default columns;
