import React from 'react';
import { Row, Col, Button } from 'antd';
import {
	FFieldLabel,
	FInput,
	FDatePicker,
	FTextArea,
} from 'forms/FormikFormItems';
import { Field } from 'formik';

export const versionsForm = (isCreation: boolean) => (
	<>
		<Row gutter={[8, 8]}>
			{!isCreation && (
				<Col span={12}>
					<FFieldLabel label="Wersja formularza" />
					<Field
						label="Wersja formularza"
						component={FInput}
						disabled={true}
						placeholder="Wersja"
						name="version"
					/>
				</Col>
			)}
			<Col span={12}>
				<FFieldLabel label="Data od" />
				<Field
					label="Data od"
					component={FDatePicker}
					name="validFrom"
					mode="date"
					style={{ width: '100%' }}
				/>
			</Col>
		</Row>
	</>
);
