import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { GridFilterDateRange } from 'components/shared/paginatedProfiBazaTable/filters/GridFilterDateRange';

export const mapings = new Map<ProfiBazaAPIModels.FormStatus, string>([
	[ProfiBazaAPIModels.FormStatus.ACTIVE, 'aktywna'],
	[ProfiBazaAPIModels.FormStatus.INACTIVE, 'nieaktywna'],
	[ProfiBazaAPIModels.FormStatus.DRAFT, 'robocza'],
	[ProfiBazaAPIModels.FormStatus.DELETED, 'usunięta'],
]);

export const map = (value: ProfiBazaAPIModels.FormStatus): string =>
	mapings.get(value) ?? '';

