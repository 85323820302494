import './TerytAreaPicker.less';

import { ArrowRightOutlined } from '@ant-design/icons';
import {
	AreaValueDto,
	TerritorialUnitDto,
	TerritorialUnitType,
	TerritoryDto,
	TerytCommunitiesOptionalParams,
	TerytCountiesOptionalParams,
	TerytGetAreasByUrbanRuralCommunityResponse,
	TerytGetLocalitiesByCommunityOptionalParams,
	TerytVoivodeshipsOptionalParams,
} from '@services/src/models';
import { Button, Checkbox, Col, Divider, Row, Steps } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ColumnProps } from 'antd/lib/table';
import GridRow from 'components/shared/GridRow';
import PaginatedProfiBazaTable from 'components/shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import { SearchBarElement } from 'components/shared/paginatedProfiBazaTable/SearchBar';
import { SieveModel } from 'components/shared/paginatedProfiBazaTable/SieveModels';
import { RenderStaticActions } from 'components/statements/table/RenderActions';
import { ajaxCatch } from 'helper/api';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';

import DownshiftSearch from '../../DownshiftSearch';
import territorialUnitTableColumns from './TerritorialUnitTableColumns';
import {
	AreaCodeNames,
	AreaState,
	COMMUNITY_TERYT_LENGTH,
	Levels,
	NationWideTeryt,
	TerritoryType,
	initialLevelNames,
	mapStageAreaValuesToTerritorialUnits,
	mapTerritorialUnitsToStageAreaValues,
	urbanRuralCommunityLevelNames,
} from './TeryAreaMappers';
import { SearchOptions } from '.';

const { Step } = Steps;

interface IProps {
	visible: boolean;
	setVisible: (visible: boolean) => void;

	values: ProfiBazaAPIModels.AreaValueDto[];
	setValues: (visible: ProfiBazaAPIModels.AreaValueDto[]) => void;
	search?: boolean;
	searchOptions?: SearchOptions;
	hasNationwide?: boolean;
	validTerytAreaLvl?: AreaCodeNames;
	restrictTerytAreas?: string[];
}

const SEARCH_BAR_COLUMN_NAME = '(name|tercCode)';

const TerytAreaModal: React.FC<IProps> = (props) => {
	const {
		visible,
		setVisible,
		values,
		setValues,
		searchOptions,
		search,
		hasNationwide,
		validTerytAreaLvl,
	} = props;

	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const searchRef = useRef<SearchBarElement>(null);

	const [nationWide, setNationWide] = useState<boolean>(false);
	const [levelRestriction, setLevelRestriction] = useState<TerritoryType>();

	const [levelNames, setLevelNames] = useState<string[]>(initialLevelNames);

	const communityIndex = levelNames.indexOf(Levels.Community);

	const [areaState, setAreaState] = useState<AreaState>({
		currentLevel: 0,
		detailsCode: undefined,
		trace: [],
	});

	const [otherFilter, setOtherFilter] = useState<string>();

	const [unstagedValues, setUnstagedValues] = useState<AreaValueDto[]>(
		values ?? []
	);

	const [tableLoaded, setTableLoaded] = useState<boolean>(false);

	const [locationKeys, setLocationKeys] = useState<string[]>([]);
	const history = useHistory();

	useEffect(() => {
		return history.listen((location) => {
			if (history.action === 'PUSH') {
				setLocationKeys([location.key!]);
				handleCancel();
			}

			if (history.action === 'POP') {
				if (locationKeys[1] === location.key) {
					setLocationKeys(([_, ...keys]) => keys);
				} else {
					setLocationKeys((keys) => [location.key!, ...keys]);
				}
				handleCancel();
			}
		});
	}, [locationKeys]);

	useEffect(() => {
		let selectList: HTMLInputElement[] = [];
		Array.from(
			document.getElementsByClassName('ant-modal modal--teryt-area')
		).forEach(
			(el) =>
				(selectList = [
					...selectList,
					...Array.from(el.getElementsByTagName('input')),
				])
		);
		Array.from(selectList).forEach((el) => {
			if (el.getAttribute('role') == 'combobox') {
				el.setAttribute('aria-label', 'Wybierz');
			}
		});
		let buttonList: HTMLButtonElement[] = [];
		Array.from(
			document.getElementsByClassName('ant-modal modal--teryt-area')
		).forEach(
			(el) =>
				(buttonList = [
					...buttonList,
					...Array.from(el.getElementsByTagName('button')),
				])
		);
		Array.from(buttonList).forEach((el) => {
			if (!el.getAttribute('aria-label')) {
				el.setAttribute('aria-label', 'Przycisk');
			}
		});
	}, [tableLoaded]);

	useEffect(() => {
		setUnstagedValues(_.cloneDeep(values));
	}, [values, values.length]);

	useEffect(() => {
		switch (validTerytAreaLvl) {
			case 'ZASIEG_INTERWENCJI_OGOLNOKRAJ':
			case 'ZASIEG_INTERWENCJI_WOJ':
			case 'ZASIEG_INTERWENCJI_WOJ_1':
			case 'ZASIEG_INTERWENCJI_WOJ_2':
				setLevelRestriction(undefined);
				break;
			default:
				setLevelRestriction(undefined);
				break;
		}
	}, [validTerytAreaLvl]);

	const columns: ColumnProps<TerritorialUnitDto>[] = [
		...territorialUnitTableColumns(unstagedValues),
		RenderStaticActions<TerritorialUnitDto>((text, record) => (
			<>
				{areaState.currentLevel < levelNames.length - 1 && (
					<Button
						type="text"
						className="icon-next-level-tab line-height-default"
						icon={<ArrowRightOutlined />}
						onClick={() => {
							if (
								levelNames[areaState.currentLevel] ===
									Levels.Community &&
								record.tercCode?.length ===
									COMMUNITY_TERYT_LENGTH &&
								record.tercCode[COMMUNITY_TERYT_LENGTH - 1] ===
									'3'
							) {
								setLevelNames(urbanRuralCommunityLevelNames);
							}

							setAreaState((state) => ({
								currentLevel: state.currentLevel + 1,
								detailsCode: record.tercCode,
								trace: [...state.trace, record],
							}));

							handleFilterClear();
						}}
					>
					</Button>
				)}
			</>
		)),
	];

	const handleFilterClear = () => {
		if (
			areaState.currentLevel < levelNames.indexOf(Levels.Locality) &&
			searchRef.current
		) {
			searchRef.current.clear();
		}
	};

	let fetchPageResult: (parameters: any) => Promise<any> = (
		parameters: any
	) =>
		getProfiBazaApiClient().then((api) => {
			return api.teryt.voivodeships(parameters);
		});

	useEffect(() => {
		const isNationwideSelected = unstagedValues.some(
			(val) => val.terytCode === NationWideTeryt.terytCode
		);

		setNationWide(isNationwideSelected);
	}, [unstagedValues, visible]);

	useEffect(() => {
		gridStore.searching.set(true);
	}, [areaState.currentLevel, areaState.detailsCode]);

	useEffect(() => {
		if (searchOptions) {
			setLevelNames(
				initialLevelNames.slice(0, searchOptions.detailLevel + 1)
			);
		}
	}, []);

	const handleCancel = () => {
		setVisible(false);
		setUnstagedValues(_.cloneDeep(values));
	};

	const applyChanges = () => {
		setVisible(false);
		setValues(_.cloneDeep(unstagedValues));
	};

	const handleLevelChange = (current: number) => {
		setOtherFilter(undefined);

		if (
			levelNames.length == urbanRuralCommunityLevelNames.length &&
			areaState.currentLevel - 1 <= communityIndex
		) {
			setLevelNames(initialLevelNames);
		}

		setAreaState((state) => ({
			currentLevel: current,
			detailsCode:
				current - 1 >= 0
					? state.trace[current - 1].tercCode
					: undefined,
			trace: state.trace.slice(0, current),
		}));

		if (searchRef.current) {
			searchRef.current.clear();
		}
	};

	const handleChangeTercCode = () => {
		setOtherFilter(undefined);
		setAreaState({
			trace: [],
			detailsCode: undefined,
			currentLevel: 0,
		});
	};

	const searchedItem = (territory: TerritoryDto) => {
		let currentLevel: number = 0;
		let tercCode: string | undefined = '';
		let trace: Array<TerritorialUnitDto> = [];
		if (territory.territorialUnitType == TerritorialUnitType.Voivodeship) {
			currentLevel = 0;
			tercCode = territory.tercCode;
		} else if (
			territory.territorialUnitType == TerritorialUnitType.County
		) {
			currentLevel = 1;
			tercCode = territory.voivodeshipTerc;
			trace = [
				{
					name: territory.voivodeship,
					tercCode: territory.voivodeshipTerc,
				},
			];
		} else if (territory.simcCode) {
			currentLevel = 3;
			tercCode = territory.communityTerc;
			trace = [
				{
					name: territory.voivodeship,
					tercCode: territory.voivodeshipTerc,
				},
				{ name: territory.county, tercCode: territory.countyTerc },
				{
					name: territory.community,
					tercCode: territory.communityTerc,
				},
			];
		} else {
			currentLevel = 2;
			tercCode = territory.countyTerc;
			trace = [
				{
					name: territory.voivodeship,
					tercCode: territory.voivodeshipTerc,
				},
				{ name: territory.county, tercCode: territory.countyTerc },
			];
		}

		getSieveFilterForTeryt(territory);

		setAreaState({
			detailsCode: tercCode,
			currentLevel: currentLevel,
			trace: trace,
		});
	};

	const getSieveFilterForTeryt = (territory: TerritoryDto) => {
		let columnName: string = territory.simcCode ? 'simcCode' : 'tercCode';
		let code: string = '';
		let type = territory.territorialUnitType;

		if (type === TerritorialUnitType.Voivodeship) {
			code = territory.voivodeshipTerc!;
		} else if (type === TerritorialUnitType.County) {
			code = territory.countyTerc!;
		} else if (territory.simcCode) {
			code = territory.simcCode!;
		} else {
			code = territory.communityTerc!;
		}

		if (code.length > 0) {
			setOtherFilter(`(${columnName})_=${code}`);
		} else {
			setOtherFilter('');
		}
	};

	const composeFilters = (
		parameters: Omit<SieveModel, 'filters'> & {
			filters?: string | null;
			abortSignal?: AbortSignal;
		}
	) => {
		const filter = gridStore.getColumnFilter(
			'terytArea',
			SEARCH_BAR_COLUMN_NAME
		)?.value;
		const territoryFilter = filter
			? `${SEARCH_BAR_COLUMN_NAME}@=*${filter}`
			: '';
		const resultFilters = `${territoryFilter}${
			territoryFilter && otherFilter ? '&' : ''
		}${otherFilter ?? ''}`;
		if (resultFilters) parameters.filters = resultFilters;
		else parameters.filters = null;
	};

	const renderTable = (teritoryLevel: number, code: string | undefined) => {
		if (levelNames[teritoryLevel] === Levels.Voivodeship) {
			fetchPageResult = (parameters: TerytVoivodeshipsOptionalParams) => {
				composeFilters(parameters);
				return getProfiBazaApiClient().then((api) => {
					return api.teryt.voivodeships(parameters);
				});
			};
		} else if (levelNames[teritoryLevel] === Levels.County && code) {
			fetchPageResult = (parameters: TerytCountiesOptionalParams) => {
				composeFilters(parameters);
				return getProfiBazaApiClient().then((api) => {
					parameters.voivodeshipTercCode = code;
					return api.teryt.counties(parameters);
				});
			};
		} else if (levelNames[teritoryLevel] === Levels.Community && code) {
			fetchPageResult = (parameters: TerytCommunitiesOptionalParams) => {
				composeFilters(parameters);
				return getProfiBazaApiClient().then((api) => {
					parameters.countyTercCode = code;
					return api.teryt.communities(parameters);
				});
			};
		} else if (levelNames[teritoryLevel] === Levels.Area && code) {
			fetchPageResult = (
				parameters: TerytGetAreasByUrbanRuralCommunityResponse
			) => {
				composeFilters(parameters);
				return getProfiBazaApiClient().then((api) => {
					return api.teryt.getAreasByUrbanRuralCommunity(
						code!,
						parameters
					);
				});
			};
		} else if (levelNames[teritoryLevel] === Levels.Locality && code) {
			fetchPageResult = (
				parameters: TerytGetLocalitiesByCommunityOptionalParams
			) => {
				composeFilters(parameters);
				return getProfiBazaApiClient().then((api) => {
					return api.teryt.getLocalitiesByCommunity(
						code!,
						parameters
					);
				});
			};
		}

		return (
			<>
				<PaginatedProfiBazaTable<TerritorialUnitDto>
					gridName="terytArea"
					columns={columns}
					getRowKey={(r: TerritorialUnitDto) => r.tercCode!}
					hidePersonalizationSettings
					hasRowSelection={true}
					hasSelectAll={false}
					getRowValuesSelection={(values: TerritorialUnitDto[]) => {
						let mappedValues: ProfiBazaAPIModels.AreaValueDto[] = mapTerritorialUnitsToStageAreaValues(
							values,
							areaState,
							levelNames
						);
						let filtredValues: AreaValueDto[] = [];

						if (areaState.currentLevel === 0) {
							filtredValues = unstagedValues.filter(
								(x) => x.county === undefined
							);
						} else if (
							areaState.currentLevel === 1 &&
							areaState?.detailsCode
						) {
							let voivodeship = areaState.trace[0].name;
							filtredValues = unstagedValues.filter(
								(x) =>
									x.county !== undefined &&
									x.voivodeship === voivodeship &&
									_.some(x.terytCode, (el) =>
										_.includes(areaState.detailsCode, el)
									)
							);
						} else if (
							areaState.currentLevel === 2 &&
							areaState?.detailsCode
						) {
							let voivodeship = areaState.trace[0].name;
							let county = areaState.trace[1].name;
							filtredValues = unstagedValues.filter(
								(x) =>
									x.community !== undefined &&
									x.voivodeship === voivodeship &&
									x.county === county &&
									_.some(x.terytCode, (el) =>
										_.includes(areaState.detailsCode, el)
									)
							);
						} else if (
							areaState.currentLevel === 3 &&
							areaState?.detailsCode
						) {
							let voivodeship = areaState.trace[0].name;
							let county = areaState.trace[1].name;
							let community = areaState.trace[2].name;
							filtredValues = unstagedValues.filter(
								(x) =>
									x.locality !== undefined &&
									x.voivodeship === voivodeship &&
									x.county === county &&
									x.community === community &&
									_.some(x.terytCode, (el) =>
										_.includes(areaState.detailsCode, el)
									)
							);
						}

						let uniqAreaValues: ProfiBazaAPIModels.AreaValueDto[] = _.uniqBy(
							[
								..._.difference(unstagedValues, filtredValues),
								...mappedValues,
							],
							'terytCode'
						);
						setUnstagedValues([...uniqAreaValues]);
					}}
					selectedRows={mapStageAreaValuesToTerritorialUnits(
						unstagedValues
					)}
					getPagedResult={(
						sieve: SieveModel,
						filter,
						abortSignal
					) => {
						const parameters: SieveModel & {
							abortSignal?: AbortSignal;
						} = {
							filters: sieve.filters,
							sorts: sieve.sorts,
							page: sieve.page,
							pageSize: sieve.pageSize,
							abortSignal,
						};

						return ajaxCatch(() => fetchPageResult(parameters));
					}}
					isLoaded={() => setTableLoaded(true)}
				/>
			</>
		);
	};

	const handleNationwideSelection = (isChecked: boolean) => {
		setNationWide(isChecked);

		if (isChecked) {
			setUnstagedValues([
				...unstagedValues,
				{
					terytCode: NationWideTeryt.terytCode,
					voivodeship: NationWideTeryt.name,
				},
			]);
		} else {
			const filteredValues = unstagedValues.filter(
				(val) => val.terytCode !== NationWideTeryt.terytCode
			);
			setUnstagedValues(filteredValues);
		}
	};

	const itemToString = (item: any): string => {
		if (!item) return '';

		let result: string = item?.voivodeship ?? '';

		if (item?.territorialUnitType === TerritorialUnitType.Voivodeship)
			return result;
		else {
			const chunks = [item.county, item.community];

			if (item.simcCode) {
				chunks.push(item.name);
			}

			chunks.forEach((chunk) => {
				if (chunk) {
					result += ` > ${chunk}`;
				}
			});
			return result;
		}
	};

	const itemToDisplayValue = (item: any): string => {
		return item?.name ?? '';
	};

	const getTerritory = async (
		page: number,
		searchCriteria: string
	): Promise<any> => {
		return getProfiBazaApiClient().then((api) => {
			return api.teryt.searchTerritories({
				page: page,
				filters: `(name|tercCode)@=*${searchCriteria}`,
			});
		});
	};

	return (
		<>
			<Modal
				visible={visible}
				centered
				destroyOnClose
				className="modal--teryt-area"
				style={{
					marginTop: '64px',
				}}
				bodyStyle={{
					padding: '24px 36px',
				}}
				maskClosable={true}
				onCancel={handleCancel}
				footer={
					search ? (
						<></>
					) : (
						<Button onClick={applyChanges} type="primary">
							Zatwierdź
						</Button>
					)
				}
			>
				<>
					<Row justify="center">
						{hasNationwide && (
							<Col xs={{ span: 24 }} md={{ span: 4 }}>
								<Checkbox
									className="checkbox-customized"
									checked={nationWide}
									onChange={(e) => {
										const { checked } = e.target;
										handleNationwideSelection(checked);
									}}
								>
									{NationWideTeryt.name}
								</Checkbox>
							</Col>
						)}
						<Col xs={{ span: 24 }} md={{ span: 20 }}>
							<Steps
								current={areaState.currentLevel}
								onChange={handleLevelChange}
								progressDot
								className="site-navigation-steps"
							>
								{levelNames.map((level, index) => (
									<Step
										key={`step-${index}`}
										status={
											index <= areaState.currentLevel
												? 'process'
												: 'wait'
										}
										title={levelNames[index]}
										subTitle={
											areaState.trace.length > 0 &&
											areaState.trace.length > index ? (
												areaState.trace[index].name
											) : (
												<></>
											)
										}
										disabled={
											index > areaState.currentLevel
										}
									/>
								))}
							</Steps>
						</Col>
					</Row>
					<Divider />
					<GridRow>
						<DownshiftSearch
							onChange={searchedItem}
							onSearchClear={handleChangeTercCode}
							itemToString={itemToString}
							itemToDisplayValue={itemToDisplayValue}
							getData={getTerritory}
						/>
					</GridRow>

					<CenteredRow>
						<Col span={24}>
							{renderTable(
								areaState.currentLevel,
								areaState.detailsCode
							)}
						</Col>
					</CenteredRow>
				</>
			</Modal>
		</>
	);
};

export default observer(TerytAreaModal);
