import Authorized from '@authorization/authorized';
import * as DictionaryInterventionPathMapper from '@services/mappers/DictionaryInterventionPath';
import { Checkbox, Space } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import { DownloadFile } from 'components/shared/file/FileDownloadFunction';
import { ProfiBazaClearFilters } from 'components/shared/paginatedProfiBazaTable/filters/ProfiBazaTableFilter';
import { AntPagination } from 'components/shared/paginatedProfiBazaTable/SieveModels';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { openNotificationWithIcon } from 'help/NotificationHelper';
import { ajaxByUser } from 'helper/api';
import formatHelpers from 'helper/formatHelpers';
import { useRootData } from 'hooks/hook';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import PrimaryCard from 'layout/PrimaryCard';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	DictionaryInterventionPath,
	DictionaryStatus,
	FileType,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';

import { IDictionaryStore } from '../dictionaryStore';
import DictionaryVersionModal from '../modals/dictionaryVersions/DictionaryVersionModal';
import DictionariesVersionsTableColumn from './DictionariesVersionsTableColumn';

interface IProps {
	dictionaryId: number;
	codeName: string | undefined;
	loading: boolean;
	items?: ProfiBazaAPIModels.DictionaryVersionItemDto[];
	refresh: (abortSignal?: AbortSignal) => void;
	onChanges: () => void;
}

const getExcludedProcesses = (
	interventionPaths: DictionaryInterventionPath[]
) => {
	const processValues = Object.values(DictionaryInterventionPath);
	const usedValues = _.without(processValues, ...interventionPaths);
	return usedValues.map((key) => DictionaryInterventionPathMapper.map(key));
};

const DictionariesVersionsTable: React.FC<IProps> = (props) => {
	const { codeName } = props;

	const [versionId, setVersionId] = useState<number | undefined>();
	const [visible, setVisible] = useState<boolean>(false);
	const [showOnlyActive, setShowOnlyActive] = useState<boolean>(true);
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [tableFilters, setTableFilters] = useState<
		| Record<keyof ProfiBazaAPIModels.DictionaryVersionItemDto, string[]>
		| undefined
	>(undefined);
	const [clearAll, setClearAll] = useState<boolean>(false);
	const [tableSorters, setTableSorters] = useState<
		| Record<
				keyof ProfiBazaAPIModels.DictionaryVersionItemDto,
				'descend' | 'ascend' | null | undefined
		  >
		| undefined
	>(undefined);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const [signal, setSignal] = useState<AbortSignal>();

	useEffect(() => {
		const abortController = new AbortController();
		setSignal(abortController.signal);
		return () => {
			abortController.abort();
		};
	}, []);

	useEffect(() => {
		if (gridStore.getRefreshdictionaryVersion()) {
			gridStore.setRefreshDictionaryVersion(false);
			props.refresh(signal);
		}
	}, [gridStore.getRefreshdictionaryVersion()]);

	gridStore.clearDictionariesVersionsMeta.observe(() => {
		setTableFilters(undefined);
		setTableSorters(undefined);
	});

	const clearFilters = useCallback(() => {
		setTableFilters(undefined);
		setClearAll(true);
		setTableSorters(undefined);
	}, []);

	const cloneDictionaryVersion = (dictionaryVersionId: number) => {
		ajaxByUser('Sklonowano wersję słownika', () =>
			getProfiBazaApiClient().then((api) =>
				api.dictionaryVersion
					.clone({
						body: {
							dictionaryVersionId: dictionaryVersionId,
						},
					})
					.then(() => props.refresh(signal))
			)
		);
	};

	const dictionaryVersionColumns = [
		...DictionariesVersionsTableColumn(
			clearAll,
			setClearAll,
			tableFilters,
			tableSorters,
			codeName
		),
		RenderActions<ProfiBazaAPIModels.DictionaryVersionItemDto>(
			(text, record) => (
				<>
					<ProfiBazaTooltip placement="top" title="Szczegóły">
						<TableActionButton
							label="Szczegóły"
							kind="DETAILS"
							linkTo={`/dictionaries/${props.dictionaryId}/${record.id}`}
						/>
					</ProfiBazaTooltip>
					<Authorized
						permission={
							ProfiBazaAPIModels.Permission
								.DictionariesVersionsModifications
						}
					>
						<Space>
							{((record.status === DictionaryStatus.ACTIVE &&
								record.validFrom! > new Date()) ||
								record.status !== DictionaryStatus.ACTIVE) && (
								<ProfiBazaTooltip
									placement="top"
									title="Edytuj"
								>
									<TableActionButton
										label="Edytuj"
										kind="EDIT"
										onClick={() => {
											setVersionId(record.id);
											setVisible(true);
										}}
									/>
								</ProfiBazaTooltip>
							)}
							{record.status !== DictionaryStatus.ACTIVE &&
								props.items &&
								props.items.length > 1 && (
									<ProfiBazaTooltip
										placement="top"
										title="Usuń"
									>
										<AccessiblePopconfirm
											title={
												<span>
													Czy na pewno chcesz usunąć
													wersję {record.version} ?
												</span>
											}
											onConfirm={() => {
												ajaxByUser(
													'Wersja usunięta',
													() =>
														getProfiBazaApiClient()
															.then((api) =>
																api.dictionaryVersion.deleteMethod(
																	record.id!
																)
															)
															.then(() => {
																props.refresh(
																	signal
																);
																dictionaryStore.wipeCachedDictionary(
																	codeName
																);
																props.onChanges();
															})
												);
											}}
										>
											<TableActionButton
												label="Usuń"
												kind="DELETE"
											/>
										</AccessiblePopconfirm>
									</ProfiBazaTooltip>
								)}

							{record.status === DictionaryStatus.ACTIVE && (
								<ProfiBazaTooltip
									placement="top"
									title="Dezaktywuj"
								>
									<AccessiblePopconfirm
										title={
											<span>
												{`Czy na pewno chcesz dezaktywować wersję obowiązującą w okresie od ${formatHelpers.formatDate(
													record.validFrom
												)}${
													record.validTo
														? ` do ${formatHelpers.formatDate(
																record.validTo
														  )}`
														: ''
												}?`}
											</span>
										}
										onConfirm={() => {
											ajaxByUser(
												'Dezaktywowano wersję',
												() =>
													getProfiBazaApiClient()
														.then((api) =>
															api.dictionaryVersion.deactivate(
																{
																	body: {
																		id: record.id!,
																	},
																}
															)
														)
														.then(() => {
															props.refresh(
																signal
															);
															dictionaryStore.wipeCachedDictionary(
																codeName
															);
														})
											);
										}}
									>
										<TableActionButton
											label="Dezaktywuj"
											kind="DEACTIVATE"
										/>
									</AccessiblePopconfirm>
								</ProfiBazaTooltip>
							)}

							{(record.status === DictionaryStatus.INACTIVE ||
								record.status === DictionaryStatus.DRAFT) && (
								<ProfiBazaTooltip
									placement="top"
									title="Aktywuj"
								>
									<AccessiblePopconfirm
										title={
											<span>
												{record.interventionPaths
													?.length ===
												Object.keys(
													DictionaryInterventionPath
												).length
													? `Czy na pewno chcesz aktywować wersję ${record.version}?`
													: `Czy chcesz aktywować, jeżeli wartości słownikowe nie są przypisane do ścieżek interwencji: ${getExcludedProcesses(
															record.interventionPaths ??
																[]
													  ).join(', ')}`}
											</span>
										}
										onConfirm={() => {
											if (!record.validFrom) {
												openNotificationWithIcon(
													'error',
													'Aktywacja wersji nie powiodła się',
													'Określ datę od której ma obowiązywać wersja słownika'
												);
												return;
											}

											ajaxByUser(
												'Aktywowano wersję',
												() =>
													getProfiBazaApiClient()
														.then((api) =>
															api.dictionaryVersion.activate(
																{
																	body: {
																		id: record.id!,
																	},
																}
															)
														)
														.then(() => {
															props.refresh(
																signal
															);
															dictionaryStore.wipeCachedDictionary(
																codeName
															);
														})
											);
										}}
									>
										<TableActionButton
											label="Aktywuj"
											kind="ACTIVATE"
										/>
									</AccessiblePopconfirm>
								</ProfiBazaTooltip>
							)}

							<ProfiBazaTooltip
								placement="top"
								title="Sklonuj wersję"
							>
								<AccessiblePopconfirm
									title="Czy na pewno chcesz sklonować wersję słownika ?"
									onConfirm={() =>
										cloneDictionaryVersion(record.id!)
									}
								>
									<TableActionButton
										label="Sklonuj"
										kind="CLONE"
									/>
								</AccessiblePopconfirm>
							</ProfiBazaTooltip>

							<ProfiBazaTooltip
								placement="top"
								title="Generuj XLSX"
							>
								<TableActionButton
									label="GENERUJ XLSX"
									kind="FILEXLSX"
									onClick={() => {
										if (!isSubmitting) {
											setSubmitting(true);
											ajaxByUser(
												'Wyeksportowano słownik',
												() =>
													getProfiBazaApiClient()
														.then((api) =>
															api.dictionaryVersion.exportById(
																{
																	id: record.id!,
																	fileType:
																		FileType.XLSX,
																	abortSignal: signal,
																}
															)
														)
														.then((response) => {
															DownloadFile({
																response,
															});
															setSubmitting(
																false
															);
														})
											).then(() => setSubmitting(false));
										}
									}}
								/>
							</ProfiBazaTooltip>
							<ProfiBazaTooltip
								placement="top"
								title="Generuj CSV"
							>
								<TableActionButton
									label="Generuj CSV"
									kind="FILECSV"
									onClick={() => {
										if (!isSubmitting) {
											setSubmitting(true);
											ajaxByUser(
												'Wyeksportowano słownik',
												() =>
													getProfiBazaApiClient()
														.then((api) =>
															api.dictionaryVersion
																.exportById({
																	id: record.id!,
																	fileType:
																		FileType.CSV,
																	abortSignal: signal,
																})
																.then(
																	(
																		response
																	) => {
																		DownloadFile(
																			{
																				response,
																			}
																		);
																		setSubmitting(
																			false
																		);
																	}
																)
														)
														.then(() =>
															setSubmitting(false)
														)
											);
										}
									}}
								/>
							</ProfiBazaTooltip>
						</Space>
					</Authorized>
				</>
			)
		),
	];

	const handleTableChange = useCallback(
		(
			p: AntPagination,
			f: Record<
				keyof ProfiBazaAPIModels.DictionaryVersionItemDto,
				string[]
			>,
			s: SorterResult<ProfiBazaAPIModels.DictionaryVersionItemDto>
		) => {
			setTableFilters(f);
			setTableSorters({
				...tableSorters!,
				[s.field as string]: s.order,
			});
		},
		[]
	);

	return (
		<PrimaryCard>
			<PageHeaderWithContent
				title="WERSJE"
				right={
					<>
						<Space>
							<Checkbox
								defaultChecked={true}
								checked={showOnlyActive}
								onChange={(e) => {
									setShowOnlyActive(e.target.checked);
								}}
							>
								Tylko aktywne
							</Checkbox>
							<DictionaryVersionModal
								refresh={props.refresh}
								dictionaryId={props.dictionaryId}
								visible={visible}
								setVisible={setVisible}
								versionId={versionId}
								setVersionId={setVersionId}
							/>
						</Space>
					</>
				}
			/>
			<ProfiBazaClearFilters handleSearch={clearFilters} />
			<ProfiBazaTable
				loading={props.loading}
				detailsUrl={'/dictionaries/' + props.dictionaryId}
				rowKey={(r: ProfiBazaAPIModels.DictionaryVersionItemDto) =>
					r.id!
				}
				columns={dictionaryVersionColumns}
				pagination={false}
				onChange={handleTableChange}
				dataSource={
					showOnlyActive
						? props.items?.filter(
								(x) => x.status === DictionaryStatus.ACTIVE
						  )
						: props.items
				}
			/>
		</PrimaryCard>
	);
};

export default observer(DictionariesVersionsTable);
