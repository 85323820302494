import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { observer } from 'mobx-react';
import { deepObserve } from 'mobx-utils';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { IGridStore } from '../../../../stores/GridStore';
import { IDictionaryStore } from '../../../dictionary/dictionaryStore';
import { ProfiBazaColumnProps } from '../GridHelper';

const FocusTrap = require('focus-trap-react');

interface IProps<T> {
	column: ProfiBazaColumnProps<T>;
	handleSearch: (value?: string[]) => void;
	gridName: string;
	clearInput?: boolean;
	setClearInput?: () => void;
	filterProps: FilterDropdownProps;
	defaultValue?: any;
}

export const GridFilterDictionarySelect = observer(
	<T extends any>(props: IProps<T> & { children?: ReactNode }) => {
		const { filterProps, defaultValue } = props;

		const [value, setValue] = useState<string[] | undefined>();
		const gridStore: IGridStore = useRootData((store) => store.gridStore);

		const [trapActive, setTrapActive] = useState<boolean>(false);

		const dictionaryStore: IDictionaryStore = useRootData(
			(store) => store.dictionaryStore
		);
		const dict = dictionaryStore!.getBuildInDictionary(
			props.column.filterDictionary!
		);
		const dictValues = dict && dict.values;

		let inputRef = useRef<RefSelectProps>(null);

		useEffect(() => {
			if (props.clearInput) {
				setValue(undefined);
				props.setClearInput?.();
			}
		}, [props.clearInput]);

		const clear = () => {
			setValue(undefined);
			gridStore.clearColumnFilter(props.gridName, props.column.key!);

			props.handleSearch();
		};

		const search = () => {
			gridStore.saveColumnFilter(
				props.gridName,
				props.column.key!,
				value
			);
			props.handleSearch(value);
		};

		useEffect(() => {
			const x = window.scrollX,
				y = window.scrollY;
			window.scrollTo(x, y);
	
			const columnFilters = gridStore.getColumnFilter(
				props.gridName,
				props.column.key!
			)?.value;
	
			setValue(columnFilters ?? defaultValue);
	
			return () => {
				disposer();
			};
		}, []);

		const disposer = deepObserve(
			gridStore.filtersGC.get(props.gridName),
			(change, path) => {
				const status = gridStore.getFilterGCStatus(
					props.gridName,
					props.column.key!
				);
				if (status) {
					const columnFilters = gridStore.getColumnFilter(
						props.gridName,
						props.column.key!
					)?.value;
					setValue(columnFilters);
				}
			}
		);

		const onDeactivatePopover = () => {
			setTrapActive(false);
			filterProps.confirm();
		};

		useEffect(() => {
			if (filterProps.visible) {
				setTrapActive(true);
			}
		}, [filterProps.visible]);

		return trapActive ? (
			<FocusTrap
				focusTrapOptions={{
					onDeactivate: onDeactivatePopover,
					clickOutsideDeactivates: false,
					returnFocusOnDeactivate: true,
					allowOutsideClick: true,
					setReturnFocus: `#filterColumn-${props.column.key}`,
					fallbackFocus: '.filter-block',
				}}
			>
				<div tabIndex={-1} className="filter-block">
					<CenteredRow>
						<Button
							type="primary"
							icon={<SearchOutlined />}
							size="small"
							onClick={() => search()}
							disabled={!value}
						>
							Wyszukaj
						</Button>
						<Button
							size="small"
							style={{ marginLeft: 4 }}
							icon={<DeleteOutlined />}
							onClick={() => clear()}
						>
							Wyczyść
						</Button>
					</CenteredRow>
					<Select<string[]>
						ref={inputRef}
						className="searchSelect"
						mode="multiple"
						size="small"
						placeholder="Wybierz"
						style={{
							width: 300,
							marginTop: 8,
							marginBottom: 8,
							display: 'block',
						}}
						showSearch
						value={value}
						onChange={(val: string[]) => setValue(val)}
						optionFilterProp="children"
					>
						{dictValues &&
							dictValues.map((x) => (
								<Select.Option
									key={x.code}
									value={x.code ?? ''}
								>
									{x.displayName}
								</Select.Option>
							))}
					</Select>
				</div>
			</FocusTrap>
		) : null;
	}
);
