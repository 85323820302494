import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<ProfiBazaAPIModels.StatementArea, string>([
	[ProfiBazaAPIModels.StatementArea.NPZ, 'Sprawozdanie z Zadań Zdrowia Publicznego'],
	[ProfiBazaAPIModels.StatementArea.MZ49, 'MZ-49'],
	[ProfiBazaAPIModels.StatementArea.MZ53, 'MZ-53'],
]);

export const map = (value: ProfiBazaAPIModels.StatementArea): string =>
	mappings.get(value) ?? '';

export const mapToFormType = (
	value: ProfiBazaAPIModels.StatementArea
): ProfiBazaAPIModels.FormType => {
	switch (value) {
		case ProfiBazaAPIModels.StatementArea.MZ49:
			return ProfiBazaAPIModels.FormType.StatementMZ49;
		case ProfiBazaAPIModels.StatementArea.MZ53:
			return ProfiBazaAPIModels.FormType.StatementMZ53;
		case ProfiBazaAPIModels.StatementArea.NPZ:
			return ProfiBazaAPIModels.FormType.StatementNPZ;
	}
};
