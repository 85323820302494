import '../../../shared/comments/comment.less';

import {
	CheckCircleTwoTone,
	DeleteOutlined,
	DragOutlined,
	EditOutlined,
	ExclamationCircleTwoTone,
	HomeOutlined,
	InfoCircleTwoTone,
	SmallDashOutlined,
	UserOutlined,
} from '@ant-design/icons';
import ReakitMenuButton from '@components/shared/buttons/ReakitMenuButton';
import { getCommentFieldName } from '@components/shared/comments/util/util';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import {
	InterventionCommentStatus,
	InterventionCommentType,
	InterventionPath,
} from '@services/src/models';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Button, Space, Tooltip } from 'antd';
import { ajaxByUser } from 'helper/api';
import { useRootData } from 'hooks/hook';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import Moment from 'react-moment';
import { Menu, MenuItem, useMenuState } from 'reakit/Menu';
import * as InterventionCommentTypeMapper from 'services/mappers/InterventionCommentType';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';

import { InterventionCommentDto } from '../commands/InterventionCommentsCommand';
import InterventionResolveCommentModal, {
	IResolveCommentModal,
} from '../modals/InterventionResolveCommentModal';
import {
	ActivityTab,
	getCommentFieldsAccordingToPath,
} from '../store/InterventionCommentStore';

interface IProps {
	refresh: () => void;
	data: InterventionCommentDto | undefined;
	interventionSubjectId: string;
	interventionPath: InterventionPath;
	setEditable: (
		value: boolean,
		id: string | undefined,
		visible: boolean
	) => void;
	checkOpinion: (comment: InterventionCommentDto) => boolean;
	readonlyMode: boolean;
}

const getCommentClass = (
	status: InterventionCommentStatus,
	type: InterventionCommentType
): string => {
	if (type === InterventionCommentType.IMPORTANT) {
		return 'antsay-comment important';
	}

	switch (status) {
		case InterventionCommentStatus.PROCESSED:
			return 'antsay-comment processed';
		default:
			return 'antsay-comment added';
	}
};

const getCommentIcon = (
	type: InterventionCommentType,
	title: string
): JSX.Element => {
	switch (type) {
		case InterventionCommentType.PUBLIC:
			return (
				<InfoCircleTwoTone
					twoToneColor="#40a9c7"
					aria-label={title}
					role="img"
				/>
			);
		case InterventionCommentType.PRIVATE:
			return <UserOutlined aria-label={title} role="img" />;
		case InterventionCommentType.IMPORTANT:
			return (
				<ExclamationCircleTwoTone
					twoToneColor="red"
					aria-label={title}
					role="img"
				/>
			);
	}
};

const getCommentContentClass = (status: InterventionCommentStatus): string => {
	switch (status) {
		case InterventionCommentStatus.PROCESSED:
			return 'content';
		default:
			return 'content important';
	}
};

const Comment = (props: IProps) => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const resolveCommentModalRef = useRef<
		IResolveCommentModal
	>() as React.RefObject<IResolveCommentModal>;

	const menuState = useMenuState();

	const [deletePopconfirmVisible, setDeletePopconfirmVisible] = useState<
		boolean
	>(false);

	const hasMenuAccess =
		props.data?.author?.id ==
		accountDetailsStore.account.get()?.subject?.id;

	const canResolveComment = (comment: InterventionCommentDto) => {
		const userSubjectId = accountDetailsStore.account.get()?.subject?.id;

		if (
			comment.interventionSubjectId === userSubjectId ||
			comment.interventionCoownersSubjectIds?.some(
				(x) => x === userSubjectId
			)
		) {
			return true;
		}

		return false;
	};

	const menu = (
		<Menu
			{...menuState}
			aria-label="Menu komentarza"
			className="ant-menu ant-menu-sub account-menu-vertical z-index-up"
		>
			<MenuItem
				key="edit"
				className="ant-menu-item sub-menu-item"
				{...menuState}
				onClick={() => props.setEditable(true, props.data?.id!, true)}
			>
				<EditOutlined />
				Edytuj
			</MenuItem>

			<MenuItem
				key="delete"
				className="ant-menu-item sub-menu-item"
				{...menuState}
				onClick={() => {
					setDeletePopconfirmVisible(true);
				}}
			>
				<DeleteOutlined />
				Usuń
			</MenuItem>
		</Menu>
	);

	const { data } = props;

	const CommentIcon = () => {
		const title = InterventionCommentTypeMapper.map(props.data?.type!);
		return (
			<div>
				<Tooltip title={title}>
					{getCommentIcon(props.data?.type!, title)}
				</Tooltip>
			</div>
		);
	};

	const setCommentState = (
		commentId: string,
		resolveComments: boolean = false
	): void => {
		ajaxByUser('Rozpatrzono komentarz', () =>
			getProfiBazaApiClient().then((api) =>
				api.interventionComments
					.setStatus({
						body: {
							commentId: commentId,
							commentStatus: InterventionCommentStatus.PROCESSED,
							resolveAotmitComments: resolveComments,
						},
					})
					.finally(props.refresh)
			)
		);
	};

	const processComment = (comment: InterventionCommentDto) => {
		if (comment.createdByAotmit && props.checkOpinion(comment)) {
			resolveCommentModalRef.current?.showModal(comment.id!);
		} else {
			setCommentState(comment.id!);
		}
	};

	const CommentAction = (comment: InterventionCommentDto): JSX.Element => {
		if (comment.status === InterventionCommentStatus.PROCESSED) {
			return (
				<Space>
					<span>Rozpatrzono komentarz</span>
					<CheckCircleTwoTone
						twoToneColor="#52c41a"
						className="icon"
					/>
				</Space>
			);
		}

		if (canResolveComment(comment)) {
			return (
				<Button type="primary" onClick={() => processComment(data!)}>
					Rozpatrzono
				</Button>
			);
		}

		return <></>;
	};

	const getFieldDescription = (isActivity: boolean) => {
		const fieldName = getCommentFieldName(
			data?.fieldName!,
			getCommentFieldsAccordingToPath(
				props.interventionPath,
				!!data?.activityNumber ? ActivityTab.ALL : null
			)
		);

		if (isActivity) {
			return `${data?.activityNumber} (${data?.activityTabName}), ${fieldName}`;
		}

		return `${fieldName}`;
	};

	const ProperDate = (date: any) => {
		const now = moment();
		if (now.isBefore(date)) return now.fromNow();

		return date.fromNow();
	};

	const CommentBody = () => {
		return (
			<div>
				<div className={getCommentContentClass(props.data?.status!)}>
					{data?.body || ''}
				</div>

				<div className="footer-row">
					<UserOutlined />
					<p>
						{data?.createdByAotmit ? 'AOTMiT' : data?.author?.name}
					</p>
					<p>
						{data?.updatedAt ? (
							<Tooltip title={data?.updatedAtFormatted}>
								<span>{ProperDate(data?.updatedAt)}</span>
							</Tooltip>
						) : (
							<Tooltip title={data?.createdAtFormatted}>
								<span>{ProperDate(data?.createdAt)}</span>
							</Tooltip>
						)}
					</p>
				</div>
				<div className="footer-row">
					<HomeOutlined />
					<p>{`Podmiot (${data?.author.intervention})`}</p>
				</div>
				<div className="field-desc">
					<DragOutlined />
					<p>{getFieldDescription(!!data?.activityNumber)}</p>
				</div>

				<div className="action">{CommentAction(data!)}</div>
			</div>
		);
	};

	const CommentMenu = (
		<>
			<ReakitMenuButton menuState={menuState} ariaLabel="Menu">
				<SmallDashOutlined />
			</ReakitMenuButton>
			{menu}
		</>
	);

	const setDeletePopconfirmVisibleCallback = (visible: boolean) => {
		setDeletePopconfirmVisible(visible);
	};

	return (
		<AccessiblePopconfirm
			visible={deletePopconfirmVisible}
			title="Czy na pewno chcesz usunąć komentarz?"
			okText="Tak"
			cancelText="Nie"
			visibleCallback={setDeletePopconfirmVisibleCallback}
			onConfirm={() => {
				ajaxByUser(
					'Usunięto komentarz',
					() =>
						getProfiBazaApiClient().then((api) =>
							api.interventionComments.deleteMethod({
								body: {
									commentId: props.data?.id!,
								},
							})
						),
					() => {
						props.refresh();
					}
				);
			}}
			onCancel={() => {
				setDeletePopconfirmVisible(false);
			}}
		>
			<div
				className={getCommentClass(
					props.data?.status!,
					props.data?.type!
				)}
			>
				<CommentIcon />
				<CommentBody />
				{hasMenuAccess && CommentMenu}
				<InterventionResolveCommentModal
					ref={resolveCommentModalRef}
					onAccept={(commentId: string) => {
						setCommentState(commentId, true);
					}}
				/>
			</div>
		</AccessiblePopconfirm>
	);
};
export default Comment;
