import { useLayoutEffect } from 'react';

const mutationConfig = { attributes: true, childList: false, subtree: false };

const useTabAccessibility = () => {
	useLayoutEffect(() => {
		const buttons = document.querySelectorAll(
			'[aria-controls$="-more-popup"]'
		);

		const mutationCallback: MutationCallback = (
			mutationsList,
			observer
		) => {
			for (const mutation of mutationsList) {
				if (
					mutation.type === 'attributes' &&
					mutation.attributeName === 'aria-controls'
				) {
					const matchedElement = document.querySelector(
						mutation.attributeName
					);
					if (!matchedElement) {
						(mutation.target as Element).removeAttribute(
							'aria-controls'
						);
					}
				}
			}
		};

		const observers = new Array<MutationObserver>();
		buttons.forEach((button) => {
			const observer = new MutationObserver(mutationCallback);
			observer.observe(button, mutationConfig);
			observers.push(observer);
		});

		return () => {
			observers.forEach((observer) => {
				if (observer) {
					observer.disconnect();
				}
			});
		};
	}, []);
};

export default useTabAccessibility;
