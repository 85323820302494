import { ColumnProps } from 'antd/lib/table';
import React, { useState } from 'react';
import DataNode from 'rc-tree/lib';
import { Tree, Drawer, Popover, Button, Tooltip } from 'antd';
import { AntTreeNodeCheckedEvent } from 'antd/lib/tree';
import { ColumnWidthOutlined } from '@ant-design/icons';
import { AntTreeNodeDropEvent } from 'antd/lib/tree/Tree';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import ColumnHelper from './ColumnHelper';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { useRootData } from 'hooks/hook';
import { GridSettingsDto } from 'services/src/models';
import { observer } from 'mobx-react';
import arrayHelpers from 'helper/arrayHelpers';
import { ITableColumnStore, GridNameType } from 'stores/TableColumnStore';
const { TreeNode } = Tree;

interface IProps {
	allColumns: ColumnProps<any>[];
	gridSettings?: GridSettingsDto;
	gridName: GridNameType;
}

const ColumnSelector: React.FC<IProps> = (props) => {
	const [visible, setVisible] = useState(false);
	const [checkedKeys, setCheckedKeys] = useState<string[]>([]);

	const { allColumns, gridName } = props;

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const tableKeys: string[] =
		accountDetailsStore.account?.get()?.gridSettings![gridName]
			?.visibleColumnKeys ?? [];

	let dataNodes = allColumns
		.filter((a) => a.key !== 'actions')
		.map((column) => {
			return {
				selectable: false,
				disableCheckbox:
					column.key != null
						? checkedKeys.length <= 1 &&
						  checkedKeys.includes(column.key.toString())
						: false,
				title: column.title,
				key: column.key != null ? column.key!.toString() : '',
			};
		});

	dataNodes.sort((a, b) => {
		let indexA = tableKeys.indexOf(a.key);
		let indexB = tableKeys.indexOf(b.key);
		if (indexA >= 0 && indexB >= 0) return indexA - indexB;
		return indexB - indexA;
	});

	const onDrop = (info: AntTreeNodeDropEvent | any) => {
		const dropKey = info.node.props.eventKey;
		const dragKey = info.dragNode.props.eventKey;
		let dropPosition = info.dropPosition;

		if (info.dropToGap) {
			const data: string[] = [...tableKeys];
			if (dropPosition < 0) dropPosition = 0;
			if (dropPosition >= data.length) dropPosition = data.length - 1;

			arrayHelpers.moveArrayElement(
				data,
				data.indexOf(dragKey),
				dropPosition
			);

			accountDetailsStore.saveVisibleColumns(gridName, data);
			tableColumnStore.applyOrder(gridName, data);
		}
	};

	return (
		<Popover
			placement="bottomLeft"
			content={
				<Tree
					blockNode
					checkable
					draggable
					defaultCheckedKeys={ColumnHelper.getVisibleColumnsKeys(
						props.gridSettings,
						props.allColumns
					)}
					onCheck={(checkedKeys: string[] | any, e: any) => {
						setCheckedKeys(checkedKeys);
						accountDetailsStore.saveVisibleColumns(
							props.gridName,
							checkedKeys
						);
					}}
					onDrop={onDrop}
					treeData={dataNodes}
				/>
			}
			trigger="click"
			title="Widoczne kolumny"
			visible={visible}
			onVisibleChange={(v) => setVisible(v)}
		>
			<ProfiBazaTooltip title="Widoczność kolumn" placement="topRight">
				<Button
					size="small"
					shape="circle"
					type="ghost"
					icon={<ColumnWidthOutlined />}
					onClick={() => setVisible(true)}
				/>
			</ProfiBazaTooltip>
		</Popover>
	);
};

export default observer(ColumnSelector);
