import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<
	ProfiBazaAPIModels.DictionaryInterventionPath,
	string
>([
	[
		ProfiBazaAPIModels.DictionaryInterventionPath.PROHEALTHACTIVITIES,
		'Działania prozdrowotne',
	],
	[ProfiBazaAPIModels.DictionaryInterventionPath.RESEARCH, 'Badania naukowe'],
	[
		ProfiBazaAPIModels.DictionaryInterventionPath.SUPERVISIONMONITORING,
		'Nadzór monit.',
	],
	[
		ProfiBazaAPIModels.DictionaryInterventionPath.SUPERVISIONSANITARY,
		'Nadzór sanit-epid.', 
	],
	[
		ProfiBazaAPIModels.DictionaryInterventionPath.STAFFEDUCATION,
		'Kształcenie Kadr',
	],
	[
		ProfiBazaAPIModels.DictionaryInterventionPath.STAFFMONITORING,
		'Kadry monit.',
	],
	[ProfiBazaAPIModels.DictionaryInterventionPath.LEGISLATION, 'Legislacja'],
]);

export const map = (
	value: ProfiBazaAPIModels.DictionaryInterventionPath
): string => mappings.get(value) ?? '';

export const procesTypesTotal = mappings.size;
