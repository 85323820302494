import moment, { Moment } from 'moment';
import { RoleCategory } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export class RoleDto {
	roleId?: string;
	roleName?: string;
	validFrom?: Moment;
	validTo?: Moment;
	category?: RoleCategory;

	constructor(model?: ProfiBazaAPIModels.RoleValueDto) {
		if (model) {
			this.roleId = model.roleId;
			this.roleName = model.roleName;
			this.category = model.category;
			if (model.validFrom) {
				this.validFrom = moment(model.validFrom);
			}

			if (model.validTo) {
				this.validTo = moment(model.validTo);
			}
		} else {
			this.roleId = undefined;
		}
	}

	static toCommand(model: RoleDto): ProfiBazaAPIModels.RoleValueDto {
		const command: ProfiBazaAPIModels.RoleValueDto = {
			roleId: model.roleId,
			roleName: model.roleName,
			validFrom: model.validFrom?.toDate(),
			validTo: model.validTo?.toDate(),
			category: model.category,
		};
		return command;
	}
}
