import Authorized from '@authorization/authorized';
import PermissionValidator from '@authorization/permissionValidator';
import { ajaxByUser } from '@helper/api';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import Modal from 'antd/lib/modal/Modal';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import ExportButtons from 'components/shared/paginatedProfiBazaTable/actions/ExportButtons';
import ProfiBazaTableActions from 'components/shared/paginatedProfiBazaTable/actions/ProfiBazaTableActions';
import IRenderActionModel from 'components/shared/paginatedProfiBazaTable/actions/RenderAction';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { useRootData } from 'hooks/hook';
import { showErrorModal } from 'layout/Modals';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	FileType,
	GuidIIdDto,
	SubjectAllOptionalParams,
	SubjectListItemDto,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

import PaginatedProfiBazaTable from '../shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import { SieveModel } from '../shared/paginatedProfiBazaTable/SieveModels';
import SubjectMergeModal from './modals/SubjectMergeModal';
import { exportActions } from './SubjectExportActions';
import { SubjectMergeListItemDto } from './SubjectMergeTableColumns';

interface IProps {}

interface IState {
	confirmMergeVisible: boolean;
	isMerging: boolean;
	mergingSubject: SubjectMergeListItemDto | undefined;
	selectedSubject: SubjectListItemDto | undefined;
	subjectMergeVisible: boolean;
	loadedTable: boolean;
}

const SubjectsTable: React.FC<IProps & RouteComponentProps> = (props) => {
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const permissionValidator = new PermissionValidator(
		accountDetailsStore!.account.get()!
	);

	const [state, setState] = useState<IState>({
		confirmMergeVisible: false,
		isMerging: false,
		mergingSubject: undefined,
		selectedSubject: undefined,
		subjectMergeVisible: false,
		loadedTable: false,
	});

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const [signal, setSignal] = useState<AbortSignal>();
	useEffect(() => {
		const abortController = new AbortController();
		setSignal(abortController.signal);
		return () => {
			abortController.abort();
		};
	}, []);

	useEffect(() => {
		if (state.isMerging && state.mergingSubject) {
			handleMergeSubjects();
		}
	}, [state.isMerging]);

	const handleMergeSubjects = () => {
		if (state.isMerging) {
			if (
				state.selectedSubject?.id &&
				state.mergingSubject?.id &&
				state.selectedSubject?.id != state.mergingSubject?.id
			) {
				ajaxByUser('Podmioty zostały scalone', () =>
					getProfiBazaApiClient()
						.then((api) =>
							api.subject.mergeSubjects({
								body: {
									subjectId: state.selectedSubject?.id,
									mergingSubjectId: state.mergingSubject?.id,
								},
							})
						)
						.then((value: GuidIIdDto) => {
							gridStore.searching.set(true);
							props.history.push(
								`/subject/edit/${state.mergingSubject?.id}`
							);
							setState({
								...state,
								isMerging: false,
								mergingSubject: undefined,
								subjectMergeVisible: false,
								selectedSubject: undefined,
								confirmMergeVisible: false,
							});
						})
				);
			} else {
				setState({
					...state,
					mergingSubject: undefined,
					subjectMergeVisible: false,
					selectedSubject: undefined,
					confirmMergeVisible: false,
					isMerging: false,
				});
				showErrorModal('Nie można scalić z tym samym podmiotem');
			}
		}
	};

	const columns: ProfiBazaColumnProps<SubjectListItemDto>[] = [
		...tableColumnStore.getTable('subject', permissionValidator),
		RenderActions<SubjectListItemDto>((text, record) => (
			<>
				<ProfiBazaTooltip placement="top" title="Szczegóły">
					<TableActionButton
						label="Szczegóły"
						kind="DETAILS"
						linkTo={`/subject/details/${record.id}`}
					/>
				</ProfiBazaTooltip>
				<Authorized
					permissions={[
						ProfiBazaAPIModels.Permission.SubjectsModificationsAll,
						ProfiBazaAPIModels.Permission.SubjectsModificationsMine,
					]}
				>
					{record.canEdit && (
						<>
							<ProfiBazaTooltip placement="top" title="Edytuj">
								<TableActionButton
									label="Edytuj"
									kind="EDIT"
									linkTo={'/subject/edit/' + record.id}
								/>
							</ProfiBazaTooltip>
						</>
					)}
				</Authorized>

				<Authorized
					permission={ProfiBazaAPIModels.Permission.SubjectsMerge}
				>
					<ProfiBazaTooltip placement="top" title="Scal">
						<TableActionButton
							label="Scal"
							kind="SELECT"
							onClick={() => {
								setState({
									...state,
									selectedSubject: record,
									subjectMergeVisible: true,
								});
							}}
						/>
					</ProfiBazaTooltip>
				</Authorized>
			</>
		)),
	];

	const renderAction = (
		actionProps: IRenderActionModel<SubjectListItemDto>
	) => (
		<ProfiBazaTableActions<SubjectListItemDto>
			getRowKey={actionProps.getRowKey}
			results={actionProps.results}
			actions={[
				{
					singleAction: (record: SubjectListItemDto) => (
						<Authorized
							permissions={[
								ProfiBazaAPIModels.Permission
									.SubjectsModificationsAll,
								ProfiBazaAPIModels.Permission
									.SubjectsModificationsMine,
							]}
						>
							{record.canEdit && (
								<>
									{!record.fromCRUIP && (
										<ProfiBazaTooltip
											placement="top"
											title="Usuń"
										>
											<AccessiblePopconfirm
												title={`Czy na pewno chcesz
												usunąć ${record.name}?`}
												onConfirm={() => {
													ajaxByUser(
														'Usunięto podmiot',
														() =>
															getProfiBazaApiClient()
																.then((api) =>
																	api.subject.deleteMethod(
																		record.id!
																	)
																)
																.then(() => {
																	gridStore.searching.set(
																		true
																	);
																})
													);
												}}
											>
												<TableActionButton
													kind="DELETE"
													label="Usuń"
												/>
											</AccessiblePopconfirm>
										</ProfiBazaTooltip>
									)}
								</>
							)}
						</Authorized>
					),
				},
			]}
		>
			<ExportButtons<SubjectListItemDto>
				fileType={FileType.XLSX}
				getSieveModel={actionProps.getSieveModel}
				supportedExportTypes={['default', 'full']}
				getFilePromise={exportActions}
				abortSignal={signal}
			/>

			<ExportButtons<SubjectListItemDto>
				fileType={FileType.PDF}
				supportedExportTypes={['default', 'full']}
				getSieveModel={actionProps.getSieveModel}
				getFilePromise={exportActions}
				abortSignal={signal}
			/>

			<ExportButtons<SubjectListItemDto>
				fileType={FileType.CSV}
				getSieveModel={actionProps.getSieveModel}
				supportedExportTypes={['default', 'full']}
				getFilePromise={exportActions}
				abortSignal={signal}
			/>

			<ExportButtons<SubjectListItemDto>
				fileType={FileType.JSON}
				supportedExportTypes={['full']}
				getSieveModel={actionProps.getSieveModel}
				getFilePromise={exportActions}
				abortSignal={signal}
			/>
		</ProfiBazaTableActions>
	);

	return (
		<>
			<Modal
				visible={state.confirmMergeVisible}
				maskClosable={false}
				centered
				okButtonProps={{
					disabled: state.isMerging,
				}}
				cancelButtonProps={{
					disabled: state.isMerging,
				}}
				destroyOnClose
				title="Scalanie podmiotów"
				onOk={() => setState({ ...state, isMerging: true })}
				onCancel={() => {
					setState({
						...state,
						mergingSubject: undefined,
						subjectMergeVisible: false,
						selectedSubject: undefined,
						confirmMergeVisible: false,
						isMerging: false,
					});
				}}
				okText="Potwierdź"
				cancelText="Anuluj"
			>
				<>
					Czy na pewno chcesz scalić z {state.mergingSubject?.name}?{' '}
					<p>{state.selectedSubject?.name} zostanie usunięty.</p>
				</>
			</Modal>
			<SubjectMergeModal
				subjectName={state.selectedSubject?.name}
				visible={state.subjectMergeVisible}
				mode="radio"
				onCancel={() => {
					setState({
						...state,
						mergingSubject: undefined,
						subjectMergeVisible: false,
						selectedSubject: undefined,
						confirmMergeVisible: false,
						isMerging: false,
					});
				}}
				acceptSubject={(value) => {
					setState({
						...state,
						confirmMergeVisible: true,
						mergingSubject: value,
					});
				}}
			/>
			<PaginatedProfiBazaTable<SubjectListItemDto>
				className="main-view"
				gridName="subject"
				detailsUrl="/subject/details"
				hasRowSelection
				columns={columns}
				getRowKey={(r: SubjectListItemDto) => r.id!}
				getPagedResult={(sieve: SieveModel, filter, abortSignal) => {
					const parameters: SubjectAllOptionalParams = {
						filters: sieve.filters,
						sorts: sieve.sorts,
						page: sieve.page,
						pageSize: sieve.pageSize,
						abortSignal,
					};
					return getProfiBazaApiClient().then((api) => {
						return api.subject
							.all(parameters)
							.then((result) => result);
					});
				}}
				isLoaded={() => setState({ ...state, loadedTable: true })}
				renderActions={renderAction}
			/>
		</>
	);
};

export default observer(SubjectsTable);
