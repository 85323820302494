import { InterventionPatternType } from '@services/src/models';
import { Col } from 'antd';
import { InterventionPathContextType } from 'components/intervention/context/InterventionPathContext';
import React from 'react';
import {
	InterventionCreatedAt,
	InterventionNumber,
	InterventionOwner,
	InterventionPath,
	InterventionPattern,
	InterventionPlannedBudget,
	InterventionRealizationBudget,
	InterventionRealizationDate,
	InterventionStatus,
	InterventionType,
} from '../InterventionFields';
import { DictionariesConstants } from 'services/DictionariesConstants';

const OtherInfoFieldGroup: React.FC<InterventionPathContextType> = (props) => {
	return (
		<>
			<Col span={12}>{InterventionPath(props)}</Col>
			<Col span={12}>{InterventionCreatedAt(props)}</Col>

			<Col span={12}>{InterventionType(props)}</Col>
			<Col span={12}>{InterventionRealizationDate(props)}</Col>
			
			<Col span={12}>{InterventionOwner(props)}</Col>
			<Col span={12}>{InterventionPlannedBudget(props)}</Col>
			
			<Col span={12}>{InterventionNumber(props)}</Col>
			<Col span={12}>{InterventionRealizationBudget(props)}</Col>
						
			<Col span={12}>{InterventionStatus(props)}</Col>
			{props.data?.interventionTypeDictionaryCode == DictionariesConstants.InterventionType.AotmitRecomendation &&  
			<Col span={12}>{InterventionPattern(props)}</Col>}
		</>
	);
};

export default OtherInfoFieldGroup;
