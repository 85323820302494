import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';

export class UpdateCommentDto {
	commentId?: string;
	statementPartInterventionCode?: string;
	column?: string;
	row?: string;
	description?: string;
	status?: ProfiBazaAPIModels.CommentStatus;

	constructor(model: ProfiBazaAPIModels.StatementCommentDto, id: string) {
		this.commentId = id;
		this.statementPartInterventionCode =
			model.statementPartInterventionCode;
		this.column = model.column;
		this.row = model.row;
		this.description = model.description;
		this.status = model.status;
	}

	static toCommand(
		model: UpdateCommentDto
	): ProfiBazaAPIModels.UpdateCommentCommand {
		const command: ProfiBazaAPIModels.UpdateCommentCommand = {
			commentId: model.commentId,
			statementPartInterventionCode: model.statementPartInterventionCode,
			column: model.column,
			row: model.row,
			description: model.description,
		};
		return command;
	}
}
