import { IInterventionData } from '@components/intervention/comments/InterventionCommentButton';
import AddButton from '@components/shared/buttons/AddButton';
import AccessibleDrawer from '@components/shared/modals/AccessibleDrawer';
import { useFocusTrap } from '@hooks/accessibility/useFocusTrapVisibleHook';
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffectHook';
import { useDrawer } from '@hooks/useDrawerHook';
import {
	InterventionCommentStatus,
	InterventionCommentType,
	OpinionAOTMiTType,
} from '@services/src/models';
import React, { useState } from 'react';

import {
	InterventionCommentDto,
	InterventionCommentsCommand,
} from './commands/InterventionCommentsCommand';
import InterventionComment from './InterventionComment';
import InterventionCommentModal from './modals/InterventionCommentModal';

interface ICommentState {
	isModalVisible: boolean;
	commentId: string | undefined;
	editableComment: boolean;
}

interface IProps {
	interventionData: IInterventionData;
	commentData: InterventionCommentsCommand;
	loadComments: (
		interventionId: string,
		activityId: string | null,
		populationId: string | null,
		sponsoringCompanyId: string | null
	) => void;
	showComments: boolean;
	readonlyMode: boolean;
}

export const InterventionCommentSidebar: React.FC<IProps> = (props) => {
	const [addCommentVisible, setAddCommentVisible] = useState<boolean>(false);
	const [commentVisible, showComments, hideComments] = useDrawer();
	const [focusTrapActive] = useFocusTrap(commentVisible, addCommentVisible);

	useDidUpdateEffect(() => {
		showComments();
	}, [props.showComments]);

	const [commentState, setCommentState] = useState<ICommentState>({
		isModalVisible: false,
		commentId: undefined,
		editableComment: false,
	});

	const checkOpinionModal = (comment: InterventionCommentDto): boolean => {
		const isLastComment =
			props.commentData?.comments.filter(
				(x) =>
					x.type === InterventionCommentType.IMPORTANT &&
					x.createdByAotmit === true &&
					x.status !== InterventionCommentStatus.PROCESSED
			).length === 1;

		if (
			isLastComment &&
			comment.type === InterventionCommentType.IMPORTANT &&
			props.interventionData.opinionAOTMiT ===
				OpinionAOTMiTType.CONDITIONALLYPOSITIVE
		) {
			return true;
		}

		return false;
	};

	return (
		<AccessibleDrawer
			focusTrapActive={focusTrapActive}
			title="Komentarze"
			placement="right"
			closable={false}
			onClose={hideComments}
			visible={commentVisible}
			getContainer={false}
			width={400}
			className="comment-drawer"
		>
			{!props.readonlyMode && (
				<AddButton
					onClick={() => {
						setAddCommentVisible(true);
						setCommentState({
							...commentState,
							editableComment: true,
						});
					}}
				>
					Dodaj komentarz
				</AddButton>
			)}
			<InterventionCommentModal
				interventionId={props.interventionData.id!}
				commentId={commentState.commentId}
				interventionSubjectId={props.interventionData.subjectId!}
				interventionPath={props.interventionData.interventionPath!}
				visible={addCommentVisible}
				setVisible={(visible: boolean) => {
					setCommentState({
						...commentState,
						editableComment: false,
						commentId: undefined,
					});
					setAddCommentVisible(visible);
				}}
				onSave={() =>
					props.loadComments(
						props.interventionData.id,
						null,
						null,
						null
					)
				}
				editable={commentState.editableComment}
			/>

			{props.commentData && (
				<InterventionComment
					interventionSubjectId={props.interventionData.subjectId!}
					interventionPath={props.interventionData.interventionPath!}
					data={props.commentData}
					refresh={() =>
						props.loadComments(
							props.interventionData.id,
							null,
							null,
							null
						)
					}
					setEditable={(value, id, visible) => {
						setCommentState({
							...commentState,
							editableComment: value,
							commentId: id!,
						});
						setAddCommentVisible(visible);
					}}
					editable={commentState.editableComment}
					checkOpinion={checkOpinionModal}
					readonlyMode={props.readonlyMode}
				/>
			)}
		</AccessibleDrawer>
	);
};
