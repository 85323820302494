import { isFirefox } from '@helper/deviceHelpers';
import { renderShortcut } from '@helper/renderHelper';
import useLocalStorage from '@rehooks/local-storage';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Button, Descriptions, Divider, Modal, Radio, Tag } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import formatHelpers from 'helper/formatHelpers';
import { useRootData } from 'hooks/hook';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import darkVars from '../dark.json';
import lightVars from '../light.json';
import ProfiBazaTooltip from './ProfiBazaTooltip';
import ZoomConfiguration, { DEFAULT_ZOOM_LEVEL } from './ZoomConfiguration';

export enum Theme {
	Default = 'Default',
	HighContrast = 'HighContrast',
}
interface IAccessibilitySettingsProps {}

const AccessibilitySettings: React.FC<IAccessibilitySettingsProps> = () => {
	const [visible, setVisible] = useState(false);
	const [theme, setTheme] = useLocalStorage<Theme>(
		'user_settings_theme',
		Theme.Default
	);
	const [zoomLevel, setZoomLevel] = useLocalStorage<number>(
		'zoomLevel',
		DEFAULT_ZOOM_LEVEL
	);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	useEffect(() => {
		modifyTheme(theme);
	}, []);

	useEffect(() => {
		if (!isFirefox) {
			document.body.style.zoom = formatHelpers.formatPercent(zoomLevel);
			if (zoomLevel === null || zoomLevel === DEFAULT_ZOOM_LEVEL) {
				document.body.removeAttribute('zoom');
			}
		}
	}, [zoomLevel]);

	let onThemeChange = (e: RadioChangeEvent) => modifyTheme(e.target.value);

	function modifyTheme(theme: Theme) {
		setTheme(theme);
		accountDetailsStore.setTheme(theme);
		window.less.modifyVars(
			theme == Theme.HighContrast ? { ...darkVars } : { ...lightVars }
		);
	}

	const modal = (
		<Modal
			title="Ustawienia dostępności"
			visible={visible}
			footer={null}
			onCancel={() => setVisible(false)}
			centered
			width={650}
		>
			<section>
				<Divider className="mt-none">Kontrast</Divider>
				<Radio.Group
					className="theme-radio-group"
					onChange={onThemeChange}
					buttonStyle="outline"
					defaultValue={theme}
				>
					<Radio.Button value={Theme.Default} tabIndex={0}>
						Domyślny
					</Radio.Button>
					<Radio.Button value={Theme.HighContrast} tabIndex={0}>
						Wysoki kontrast
					</Radio.Button>
				</Radio.Group>
			</section>

			<section>
				<Divider>Rozmiar strony</Divider>
				{!isFirefox ? (
					<ZoomConfiguration
						zoomLevel={zoomLevel}
						setZoomLevel={setZoomLevel}
					/>
				) : (
					<Descriptions>
						<Descriptions.Item label="Powiększ">
							{renderShortcut('CTRL', '+')}
						</Descriptions.Item>
						<Descriptions.Item label="Pomniejsz">
							{renderShortcut('CTRL', '-')}
						</Descriptions.Item>
						<Descriptions.Item label="Przywróć">
							{renderShortcut('CTRL', '0')}
						</Descriptions.Item>
					</Descriptions>
				)}
			</section>
		</Modal>
	);

	return (
		<>
			{modal}
			<ProfiBazaTooltip placement="bottom" title="Ułatwienia dostępności">
				<Button
					size="large"
					shape="circle"
					aria-label="ułatwienia dostępności"
					onClick={() => setVisible(true)}
					icon={
						<span className="material-icons material-icon-fix">
							accessible
						</span>
					}
				/>
			</ProfiBazaTooltip>
		</>
	);
};

export default observer(AccessibilitySettings);
