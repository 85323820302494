import { useLayoutEffect } from 'react';

const useSelectsAccessibility = () => {
	useLayoutEffect(() => {
		const select = document.querySelector('.ant-select input');
		if (select) {
			const ariaControls = select.getAttribute('aria-controls');
			if (ariaControls) {
				const controlledItem = document.getElementById(ariaControls);
				if (!controlledItem) {
					select.removeAttribute('aria-owns');
					select.removeAttribute('aria-controls');
					select.removeAttribute('aria-activedescendant');
				}
			}
		}
		return () => {};
	}, []);
};

export default useSelectsAccessibility;
