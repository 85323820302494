import _ from 'lodash';
import { TerritorialUnitDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export type AreaCodeNames =
	| 'ZASIEG_INTERWENCJI_OGOLNOKRAJ'
	| 'ZASIEG_INTERWENCJI_WOJ'
	| 'ZASIEG_INTERWENCJI_WOJ_1'
	| 'ZASIEG_INTERWENCJI_WOJ_2'
	| 'ZASIEG_INTERWENCJI_BAD_1'
	| 'ZASIEG_INTERWENCJI_BAD_2';

export enum TerritoryType {
	Voivodeship,
	County,
	Community,
	Area,
	Locality,
}

export const NationWideTeryt = {
	terytCode: '00',
	name: 'Ogólnokrajowy',
};

export const Levels = {
	Voivodeship: 'Województwo',
	County: 'Powiat',
	Community: 'Gmina',
	Area: 'Obszar',
	Locality: 'Miejscowość',
};

export let initialLevelNames = [
	Levels.Voivodeship,
	Levels.County,
	Levels.Community,
	Levels.Locality,
];

export let urbanRuralCommunityLevelNames = [
	Levels.Voivodeship,
	Levels.County,
	Levels.Community,
	Levels.Area,
	Levels.Locality,
];

export interface AreaState {
	currentLevel: number;
	detailsCode: string | undefined;
	trace: Array<TerritorialUnitDto>;
}

export const mapTerritorialUnitToStageAreaValue = (
	val: TerritorialUnitDto,
	area: AreaState,
	levelNames: string[]
): ProfiBazaAPIModels.AreaValueDto => {
	const traceLength = area.trace.length;
	let result: ProfiBazaAPIModels.AreaValueDto = {
		terytCode: val.tercCode,
	};
	const voivodeshipIndex = levelNames.indexOf(Levels.Voivodeship),
		countyIndex = levelNames.indexOf(Levels.County),
		communityIndex = levelNames.indexOf(Levels.Community),
		areaIndex = levelNames.indexOf(Levels.Area),
		localityIndex = levelNames.indexOf(Levels.Locality);

	switch (traceLength) {
		case voivodeshipIndex:
			result.voivodeship = val.name;
			break;
		case countyIndex:
			result.county = val.name;
			break;
		case communityIndex:
			result.community = val.name;
			break;
		case areaIndex:
			result.area = val.name;
			break;
		case localityIndex:
			result.locality = val.name;
			break;
	}

	if (traceLength > voivodeshipIndex)
		result.voivodeship = area.trace[voivodeshipIndex].name;
	if (countyIndex != -1 && traceLength > countyIndex)
		result.county = area.trace[countyIndex].name;
	if (communityIndex != -1 && traceLength > communityIndex)
		result.community = area.trace[communityIndex].name;
	if (areaIndex != -1 && traceLength > areaIndex)
		result.area = area.trace[areaIndex].name;
	if (localityIndex != -1 && traceLength > localityIndex)
		result.locality = area.trace[localityIndex].name;

	return result;
};

export const mapTerritorialUnitsToStageAreaValues = (
	vals: TerritorialUnitDto[],
	area: AreaState,
	levelNames: string[]
): ProfiBazaAPIModels.AreaValueDto[] => {
	const traceLength = area.trace.length;
	let elements: ProfiBazaAPIModels.AreaValueDto[] = [];

	vals.forEach((val) => {
		let result: ProfiBazaAPIModels.AreaValueDto = {
			terytCode: val.tercCode,
		};
		const voivodeshipIndex = levelNames.indexOf(Levels.Voivodeship),
			countyIndex = levelNames.indexOf(Levels.County),
			communityIndex = levelNames.indexOf(Levels.Community),
			areaIndex = levelNames.indexOf(Levels.Area),
			localityIndex = levelNames.indexOf(Levels.Locality);

		switch (traceLength) {
			case voivodeshipIndex:
				result.voivodeship = val.name;
				break;
			case countyIndex:
				result.county = val.name;
				break;
			case communityIndex:
				result.community = val.name;
				break;
			case areaIndex:
				result.area = val.name;
				break;
			case localityIndex:
				result.locality = val.name;
				break;
		}

		if (traceLength > voivodeshipIndex)
			result.voivodeship = area.trace[voivodeshipIndex].name;
		if (countyIndex != -1 && traceLength > countyIndex)
			result.county = area.trace[countyIndex].name;
		if (communityIndex != -1 && traceLength > communityIndex)
			result.community = area.trace[communityIndex].name;
		if (areaIndex != -1 && traceLength > areaIndex)
			result.area = area.trace[areaIndex].name;
		if (localityIndex != -1 && traceLength > localityIndex)
			result.locality = area.trace[localityIndex].name;

		elements.push(result);
	});

	return elements;
};

export const mapStageAreaValuesToTerritorialUnits = (
	vals: ProfiBazaAPIModels.AreaValueDto[]
): TerritorialUnitDto[] => {
	let result: TerritorialUnitDto[] = [];

	vals.forEach((val) => {
		result.push({
			tercCode: val.terytCode,
			name:
				val.locality ??
				val.area ??
				val.community ??
				val.county ??
				val.voivodeship,
		});
	});

	return result;
};

export const getRecordValue = (record: any): string => {
	const chunks = [
		record.county,
		record.community,
		record.area,
		record.locality,
	];
	let result: string = record.voivodeship;
	chunks.forEach((chunk) => {
		if (chunk) {
			result += ` > ${chunk}`;
		}
	});
	return result;
};

export const deleteRecordValue = (
	array: ProfiBazaAPIModels.AreaValueDto[],
	value: string
) => {
	let list = value.split(' > ');
	if (list.length === 1) {
		const index = array.findIndex(
			(x) =>
				x.voivodeship == list[0] &&
				!x.county &&
				!x.community &&
				!x.locality
		);
		array.splice(index, 1);
	} else if (list.length === 2) {
		const index = array.findIndex(
			(x) =>
				x.voivodeship == list[0] &&
				x.county == list[1] &&
				!x.community &&
				!x.locality
		);
		array.splice(index, 1);
	} else if (list.length === 3) {
		const index = array.findIndex(
			(x) =>
				x.voivodeship == list[0] &&
				x.county == list[1] &&
				x.community == list[2] &&
				!x.locality
		);
		array.splice(index, 1);
	} else if (list.length === 4) {
		const index = array.findIndex((x) => {
			const ambigulousArea = list[3];
			const areaTypes = ['(obszar wiejski)', '(miasto)']; //rural area or locality in urban-rural area
			const hasArea = areaTypes.some((x) => ambigulousArea.includes(x));

			return (
				x.voivodeship == list[0] &&
				x.county == list[1] &&
				x.community == list[2] &&
				(hasArea
					? x.area == ambigulousArea
					: x.locality === ambigulousArea)
			);
		});
		array.splice(index, 1);
	} else if (list.length === 5) {
		const index = array.findIndex(
			(x) =>
				x.voivodeship == list[0] &&
				x.county == list[1] &&
				x.community == list[2] &&
				x.area == list[3] &&
				x.locality === list[4]
		);
		array.splice(index, 1);
	}
	return array;
};

export function getSieveFilterForTerytAreas(
	restrictTerytAreas: string[] | undefined,
	level: TerritoryType
): string | null {
	if (
		!restrictTerytAreas ||
		restrictTerytAreas.includes(NationWideTeryt.terytCode)
	) {
		return null;
	}

	const terytCodes =
		restrictTerytAreas?.map((code) =>
			code.slice(0, getTerytTerritoryLength(level))
		) ?? [];
	const uniqueeCodes = _.uniq(terytCodes);
	const joinedCodes = uniqueeCodes.join('|');
	return `tercCode_=${joinedCodes}`;
}

export const getTerytTerritoryLength = (
	territoryType: TerritoryType
): number => {
	switch (territoryType) {
		case TerritoryType.Voivodeship:
			return 2;
		case TerritoryType.County:
			return 4;
		case TerritoryType.Community:
			return 7;
		default:
			return 7;
	}
};

export const COMMUNITY_TERYT_LENGTH = getTerytTerritoryLength(
	TerritoryType.Community
);
