import { Space } from 'antd';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import React from 'react';

const RenderActions = <T extends any>(
	render: (text: string, record: T, index: number) => React.ReactElement,
	columnProps: ProfiBazaColumnProps<T> = {}
) => {
	const { key, render: renderColumn, title, width, ...others } = columnProps;

	return {
		key: 'actions',
		title: 'Kolumna akcji',
		render: (text: string, record: T, index: number) => (
			<div className="actions-table-column">
				<Space className="actions-toolbox">
					{render(text, record, index)}
				</Space>
			</div>
		),
		className: 'actionstd',
		width: 0,
		...others,
	};
};

export const RenderStaticActions = <T extends any>(
	render: (text: string, record: T, index: number) => React.ReactElement,
	columnProps: ProfiBazaColumnProps<T> = {}
) => {
	const { key, render: renderColumn, title, width, ...others } = columnProps;

	return {
		key: 'actions',
		title: 'Kolumna akcji',
		render: (text: string, record: T, index: number) => (
			<Space className="actions-table-column">
				{render(text, record, index)}
			</Space>
		),
		width: 0,
		...others,
	};
};

export default RenderActions;
