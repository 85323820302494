import { ajaxCatch } from 'helper/api';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import FormsValuesTable from './table/FormsValuesTable';
import FormsVersionsTable from './table/FormsVersionsTable';

interface IProps {
	formId: string;
	formVersionId?: string;
}

interface IState {
	form: ProfiBazaAPIModels.FormDto | undefined;
	loading: boolean;
}

const FormDetails: React.FC<IProps> = (props) => {

	const history = useHistory();

	const [state, setState] = useState<IState>({
		form: undefined,
		loading: true,
	});

	useEffect(() => {
		const abortController = new AbortController();
		props.formId && refresh(abortController.signal);

		return () => {
			abortController.abort();
		};
	}, [props.formId]);

	const setDescription = (newDescription: string) =>
		setState({
			...state,
			form: {
				...state.form,
				description: newDescription,
			},
		});

	const refresh = (abortSignal?: AbortSignal) => {
		setState({
			...state,
			loading: true,
		});
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.form.getById(props.formId, { abortSignal }))
				.then((response: ProfiBazaAPIModels.FormDto) => {
					setState({ form: response!, loading: false });
				})
		);
	};

	const handleVersionsTableChanges = () => {
		if (props.formId) {
			history.push(`/forms/${props.formId}`);
		}
	};

	return (
		<>
			<FormsVersionsTable
				formId={props.formId}
				items={state.form?.versions}
				loading={state.loading}
				refresh={refresh}
				onChanges={handleVersionsTableChanges}
			/>
			{props.formVersionId && (
				<FormsValuesTable
					formVersionId={props.formVersionId}
					type={state.form?.type!}
					formVersion={state.form?.versions?.find(
						(ver) => ver.id === props.formVersionId
					)}
				/>
			)}
		</>
	);
};

export default observer(FormDetails);
