import { MessageOutlined } from '@ant-design/icons';
import { useRootData } from '@hooks/hook';
import { InterventionCommentType } from '@services/src/models';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useState } from 'react';

import { InterventionCommentDto } from './commands/InterventionCommentsCommand';
import { IInterventionCommentStore } from './store/InterventionCommentStore';

interface ICommentIconProps {
	fieldName: string;
	style?: React.CSSProperties;
	text?: string;
}

const getIconClassByCommentType = (type: InterventionCommentType) => {
	if (type === InterventionCommentType.IMPORTANT) {
		return 'comment-icon important';
	}

	return 'comment-icon info';
};
export const InterventionCommentIcon = (props: ICommentIconProps) => {
	const interventionCommentStore: IInterventionCommentStore = useRootData(
		(store) => store.interventionCommentStore
	);

	const [state, setState] = useState<
		Record<string, InterventionCommentDto> | undefined
	>(interventionCommentStore.marks.get());

	interventionCommentStore.marks.observe(() => {
		const marks = interventionCommentStore.marks.get();
		if (marks) {
			setState(marks);
		}
	});

	if (state) {
		const comment: InterventionCommentDto | undefined = state![
			props.fieldName
		];

		if (comment?.body) {
			return (
				<ProfiBazaTooltip
					aria-label="komentarz"
					aria-roledescription={comment?.body}
					placement="left"
					title={comment.body}
					className={getIconClassByCommentType(comment.type!)}
				>
					<span>{props.text} </span>
					<MessageOutlined style={props.style} />
				</ProfiBazaTooltip>
			);
		}
	}
	return <></>;
};
