import { ajaxCatch } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { CreateTooltipCommand } from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { observable } from 'mobx';

const initialize = (field: string, defaultValue: string) => {
	const values = tooltipStore.values.get();
	if (values[field] !== undefined) return;

	ajaxCatch(() =>
		getProfiBazaApiClient().then((api) =>
			api.intervention
				.getTooltipByName(field)
				.then((response: ProfiBazaAPIModels.TooltipDto) => {
					values[field] =
						response.value !== null ? response.value : defaultValue;
				})
		)
	);
};

const create = (
	createDto: Required<CreateTooltipCommand>,
	callback: () => void
) => {
	const { field, value } = createDto;
	ajaxCatch(() =>
		getProfiBazaApiClient().then((api) =>
			api.intervention
				.defineTooltip({
					body: createDto,
				})
				.then((response: any) => {
					const values = tooltipStore.values.get();
					values[field] = value;
					callback();
				})
		)
	);
};

export const tooltipStore = {
	values: observable.box<Record<string, string | undefined>>({}),

	initialize: initialize,
	create: create,
	getField: (field: string) => tooltipStore.values.get()[field],
};

export const createTooltipStore = () => {
	return tooltipStore;
};

export type ITooltipStore = ReturnType<typeof createTooltipStore>;
