import React from 'react';
import { Space } from 'antd';

type ITableAction = <T extends any>(record: T) => JSX.Element;

export interface ITableActions<T> {
	singleAction?: ITableAction;
	action?: (selected: Array<string | number>) => JSX.Element;
	isVisible?: (selectedValues: T[]) => boolean;
}

interface IProps<T> {
	selectedKeys: Array<string | number>;
	excludedKeys: Array<string | number>;
	selectedValues: Array<T>;
	allRowsSelected: boolean;
	actions?: Array<ITableActions<T>>;
	results: T[] | undefined;
	getRowKey: (record: T) => string | number | undefined;
	children?: React.ReactNode;
}

const TableActions = <T extends any>(props: IProps<T>) => {
	const {
		selectedKeys,
		selectedValues,
		excludedKeys,
		allRowsSelected,
		actions,
		results,
		getRowKey,
	} = props;

	const isVisible =
		selectedKeys.length || (allRowsSelected && excludedKeys.length);

	const actionReducer = (action: ITableAction) => {
		if (!results?.length) return null;

		const idToFind = selectedKeys[0] as any;
		const recordIndex = results
			?.map(getRowKey)
			.findIndex((id) => id === idToFind);
		if (recordIndex === -1) return null;

		return action(results[recordIndex]);
	};

	return (
		<>
			{isVisible && (
				<Space className="selection-actions-container">
					{props.children}

					{!allRowsSelected &&
						actions &&
						actions.map((x) => {
							if (
								selectedKeys.length > 0 &&
								x.action !== undefined &&
								(x.isVisible === undefined ||
									x.isVisible(selectedValues))
							) {
								return x.action(selectedKeys);
							} else if (
								selectedKeys.length == 1 &&
								x.singleAction !== undefined
							) {
								return actionReducer(x.singleAction);
							}
						})}
				</Space>
			)}
		</>
	);
};

export default TableActions;
