import Authorized from '@authorization/authorized';
import { useRootData } from '@hooks/hook';
import { Checkbox, Space } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import { ProfiBazaClearFilters } from 'components/shared/paginatedProfiBazaTable/filters/ProfiBazaTableFilter';
import { AntPagination } from 'components/shared/paginatedProfiBazaTable/SieveModels';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { openNotificationWithIcon } from 'help/NotificationHelper';
import { ajaxByUser } from 'helper/api';
import formatHelpers from 'helper/formatHelpers';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import PrimaryCard from 'layout/PrimaryCard';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { FormStatus } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';

import CloneFormVersionModal, {
	ICloneFormVersionModal,
} from '../modals/formsVersions/CloneFormVersionModal';
import FormVersionModal from '../modals/formsVersions/FormVersionModal';
import FormsVersionsTableColumn from './FormsVersionsTableColumn';

interface IProps {
	formId: string;
	loading: boolean;
	items?: ProfiBazaAPIModels.FormVersionItemDto[];
	refresh: () => void;
	onChanges: () => void;
}

const FormsVersionsTable: React.FC<IProps> = (props) => {
	const [versionId, setVersionId] = useState<string | undefined>();
	const [xlsxDefinition, setXlsxDefinition] = useState<
		Uint8Array | undefined
	>();
	const [xsltDefinition, setXSLTDefinition] = useState<
		Uint8Array | undefined
	>();
	const [visible, setVisible] = useState<boolean>(false);
	const [showOnlyActive, setShowOnlyActive] = useState<boolean>(true);
	const [tableSorters, setTableSorters] = useState<
		| Record<
				keyof ProfiBazaAPIModels.FormVersionItemDto,
				'descend' | 'ascend' | null | undefined
		  >
		| undefined
	>(undefined);

	const [tableFilters, setTableFilters] = useState<
		| Record<keyof ProfiBazaAPIModels.FormVersionItemDto, string[]>
		| undefined
	>(undefined);

	const [clearAll, setClearAll] = useState<boolean>(false);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	gridStore.clearFormsVersionsMeta.observe(() => {
		setTableFilters(undefined);
		setTableSorters(undefined);
	});

	const formVersionColumns = [
		...FormsVersionsTableColumn(
			clearAll,
			setClearAll,
			tableFilters,
			tableSorters
		),
		RenderActions<ProfiBazaAPIModels.FormVersionItemDto>((text, record) => (
			<>
				<ProfiBazaTooltip placement="top" title="Szczegóły">
					<TableActionButton
						label="Szczegóły"
						kind="DETAILS"
						linkTo={`/forms/${props.formId}/${record.id}`}
					/>
				</ProfiBazaTooltip>
				{record.status == FormStatus.DRAFT && (
					<ProfiBazaTooltip placement="top" title="Edytuj">
						<TableActionButton
							label="Edytuj"
							kind="EDIT"
							onClick={() => {
								setVersionId(record.id);
								setXlsxDefinition(record.xlsxDefinition);

								if (record.xsltDefinition) {
									const encoder = new TextEncoder(); // always utf-8
									const encodedArray = encoder.encode(
										record.xsltDefinition!
									);

									setXSLTDefinition(encodedArray);
								}

								setVisible(true);
							}}
						/>
					</ProfiBazaTooltip>
				)}
				<ProfiBazaTooltip placement="top" title="Sklonuj wersję">
					<TableActionButton
						label="Sklonuj"
						kind="CLONE"
						onClick={() =>
							cloneFormVersionModal.current?.showModal(record.id!)
						}
					/>
				</ProfiBazaTooltip>
				{record.status !== FormStatus.ACTIVE &&
					props.items &&
					props.items.length > 1 && (
						<ProfiBazaTooltip placement="top" title="Usuń">
							<AccessiblePopconfirm
								title={
									<span>
										Czy na pewno chcesz usunąć wersję{' '}
										{record.version} ?
									</span>
								}
								onConfirm={() => {
									ajaxByUser('Wersja usunięta', () =>
										getProfiBazaApiClient()
											.then((api) =>
												api.formVersion.deleteMethod(
													record.id!
												)
											)
											.then(() => {
												props.refresh();
											})
									);
								}}
							>
								<TableActionButton label="Usuń" kind="DELETE" />
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					)}

				{record.status === FormStatus.ACTIVE &&
					props.items?.filter((x) => x.status == FormStatus.ACTIVE)
						?.length! > 1 && (
						<ProfiBazaTooltip placement="top" title="Dezaktywuj">
							<AccessiblePopconfirm
								title={
									<span>
										{`Czy na pewno chcesz dezaktywować wersję obowiązującą w okresie od ${formatHelpers.formatDate(
											record.validFrom
										)}${
											record.validTo
												? ` do ${formatHelpers.formatDate(
														record.validTo
												  )}`
												: ''
										}?`}
									</span>
								}
								onConfirm={() => {
									ajaxByUser('Dezaktywowano wersję', () =>
										getProfiBazaApiClient()
											.then((api) =>
												api.formVersion.deactivate({
													body: {
														id: record.id!,
													},
												})
											)
											.then(() => {
												props.refresh();
											})
									);
								}}
							>
								<TableActionButton
									label="Dezaktywuj"
									kind="DEACTIVATE"
								/>
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					)}

				{(record.status === FormStatus.INACTIVE ||
					record.status === FormStatus.DRAFT) && (
					<ProfiBazaTooltip placement="top" title="Aktywuj">
						<AccessiblePopconfirm
							title={
								<span>
									{`Czy na pewno chcesz aktywować wersję ${record.version}?`}
								</span>
							}
							onConfirm={() => {
								if (!record.validFrom) {
									openNotificationWithIcon(
										'error',
										'Aktywacja wersji nie powiodła się',
										'Określ datę od której ma obowiązywać wersja formularza'
									);
									return;
								}

								ajaxByUser('Aktywowano wersję', () =>
									getProfiBazaApiClient()
										.then((api) =>
											api.formVersion.activate({
												body: {
													id: record.id!,
												},
											})
										)
										.then(() => {
											props.refresh();
										})
								);
							}}
						>
							<TableActionButton
								label="Aktywuj"
								kind="ACTIVATE"
							/>
						</AccessiblePopconfirm>
					</ProfiBazaTooltip>
				)}
			</>
		)),
	];

	const cloneFormVersionModal = useRef<
		ICloneFormVersionModal
	>() as React.RefObject<ICloneFormVersionModal>;

	return (
		<PrimaryCard>
			<Authorized
				permission={ProfiBazaAPIModels.Permission.FormsManagement}
			>
				<CloneFormVersionModal
					ref={cloneFormVersionModal}
					onCreate={() => {
						props.refresh();
					}}
				/>
			</Authorized>
			<PageHeaderWithContent
				title="WERSJE"
				right={
					<>
						<Space>
							<Checkbox
								defaultChecked={true}
								checked={showOnlyActive}
								onChange={(e) => {
									setShowOnlyActive(e.target.checked);
									props.onChanges();
								}}
							>
								Tylko aktywne
							</Checkbox>

							<FormVersionModal
								refresh={props.refresh}
								formId={props.formId}
								visible={visible}
								setVisible={setVisible}
								versionId={versionId}
								xlsxDefinition={xlsxDefinition}
								xsltDefinition={xsltDefinition}
								setVersionId={setVersionId}
							/>
						</Space>
					</>
				}
			/>
			<ProfiBazaClearFilters
				handleSearch={() => {
					setTableFilters(undefined);
					setClearAll(true);
					setTableSorters(undefined);
				}}
			/>
			<ProfiBazaTable
				loading={props.loading}
				detailsUrl={'/forms/' + props.formId}
				rowKey={(r: ProfiBazaAPIModels.FormVersionItemDto) => r.id!}
				onChange={(
					p: AntPagination,
					f: Record<
						keyof ProfiBazaAPIModels.FormVersionItemDto,
						string[]
					>,
					s: SorterResult<ProfiBazaAPIModels.FormPartDto>
				) => {
					setTableFilters(f);
					setTableSorters({
						...tableSorters!,
						[s.field as string]: s.order,
					});
					props.onChanges();
				}}
				columns={formVersionColumns}
				pagination={false}
				dataSource={
					showOnlyActive
						? props.items?.filter(
								(x) => x.status === FormStatus.ACTIVE
						  )
						: props.items
				}
			/>
		</PrimaryCard>
	);
};

export default observer(FormsVersionsTable);
