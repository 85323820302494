import { useRootData } from '@hooks/hook';
import { Button, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ActivityContext } from 'components/intervention/context/ActivityContext';
import AddButton from 'components/shared/buttons/AddButton';
import SubjectDetails from 'components/subjects/SubjectDetails';
import produce from 'immer';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BusinessObjectType, ImplementingCompanyDto, SubjectIdNameDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IRizpDictionaryStore } from 'stores/RizpDictionaryStore';

import { ImplementersValidationSchema } from '../../model/InterventionActivityValidation';
import {
	IImplementersForm,
	ImplementersForm,
	columnsImplementingCompanies,
} from '../../model/InterventionActiviyTableColumns';
import ActivityDetailsTableTemplate from '../ActivityDetailsTableTemplate';
import CustomImplementingCompanyModal from './CustomImplementingCompanyModal';

interface IProps {
	editable: boolean;
	mergedSubjectsMap: Map<string, SubjectIdNameDto>;
	activityId: string | undefined;
	changeData: (value: ImplementingCompanyDto[]) => void;
}

export type MergedImplementingCompanyDto = ImplementingCompanyDto & {
	prevSubjectId?: string;
	prevSubjectName?: string;
};

interface IState {
	array: MergedImplementingCompanyDto[];
	selectedRows: ImplementingCompanyDto[];
	subjectIdPreview: string | undefined;
	visibleTable: boolean;
}

const ImplementingCompanyTable = (props: IProps) => {
	const [state, setState] = useState<IState>({
		array: [],
		selectedRows: [],
		subjectIdPreview: undefined,
		visibleTable: false,
	});

	const [isCustomModalVisible, setIsCustomModalVisible] = useState<boolean>(
		false
	);

	const activityContext = useContext(ActivityContext);
	const { formik } = activityContext;

	const rizpDictionaryStore: IRizpDictionaryStore = useRootData(
		(store) => store.rizpDictionaryStore
	);

	useEffect(() => {
		const formikValue = formik?.values['implementingCompanies'] ?? [];

		const merged = formikValue.map(
			(x): MergedImplementingCompanyDto => {
				if (x.subjectId) {
					const merged = props.mergedSubjectsMap.get(x.subjectId);
					if (merged) {
						return {
							...x,
							prevSubjectId: x.subjectId,
							prevSubjectName: x.subjectName,
							subjectId: merged.id,
							subjectName: merged.name,
						};
					}
				}
				return x;
			}
		);

		if (!_.isEqual(formikValue, merged)) {
			formik?.setFieldValue('implementingCompanies', merged);
		}

		setState((prevState) => ({
			...prevState,
			array: merged,
			selectedRows: formikValue,
			subjectIdPreview: undefined,
		}));
	}, [formik?.values['implementingCompanies']]);

	const childRef = useRef<IImplementersForm>() as React.RefObject<
		IImplementersForm
	>;

	const cardContent = () => (
		<ImplementersForm
			visible={state.visibleTable}
			setVisible={(value) => setState({ ...state, visibleTable: value })}
			selectedRows={state.selectedRows}
			editable={props.editable!}
			ref={childRef}
			setList={(list: ImplementingCompanyDto[]) => {
				childRef.current?.resetForm();
				props.changeData(list);
				formik?.setFieldValue('implementingCompanies', list);

				setState({
					...state,
					selectedRows: [...list],
					array: list,
				});
			}}
		/>
	);
	return (
		<>
			<ActivityDetailsTableTemplate<MergedImplementingCompanyDto>
				array={state.array}
				setVisible={() => setState({ ...state, visibleTable: true })}
				addButtonLabel="Dodaj podmiot realizujący"
				validationScheme={ImplementersValidationSchema}
				columns={columnsImplementingCompanies}
				editable={props.editable}
				canEdit={false}
				getItemId={(item) => item.subjectId}
				initRizpDictionary={(objectId: string) => rizpDictionaryStore.fetch(props.activityId!, BusinessObjectType.Activity)}
				patternCollection={
					activityContext.patternActivity?.implementingCompanies!
				}
				isPattern={activityContext.isPattern}
				canPreview={(item: ImplementingCompanyDto) => !!item.subjectId}
				onPreview={(item: ImplementingCompanyDto) => {
					if (item.subjectId) {
						setState({
							...state,
							subjectIdPreview: item.subjectId,
						});
					}
				}}
				onCancel={() => {
					childRef.current?.resetForm();
					const formikValue =
						formik?.values['implementingCompanies'] ?? [];

					setState({
						...state,
						array: formikValue,
						selectedRows: [...formikValue],
					});
				}}
				onCreate={(value: ImplementingCompanyDto) => {
					childRef.current?.resetForm();
					const formikValue =
						formik?.values['implementingCompanies'] ?? [];

					props.changeData(state.array);
					let list = state.selectedRows;

					formik?.setFieldValue(
						'implementingCompanies',
						produce(formikValue, (draft) => {
							draft.push(value);
						})
					);

					setState({
						...state,
						selectedRows: [...list],
						array: list,
					});
					formik?.setFieldTouched('implementingCompanies');
				}}
				onRemove={(values: ImplementingCompanyDto[]) => {
					props.changeData(values);

					formik?.setFieldValue('implementingCompanies', values);

					setState({
						...state,
						array: values,
						selectedRows: values,
					});
					childRef.current?.resetForm();
					formik?.setFieldTouched('implementingCompanies');
				}}
				onUpdate={() => {
					formik?.setFieldTouched('implementingCompanies');
				}}
				cardContent={cardContent}
				customHeaderButton={
					<AddButton
						type="default"
						onClick={() => setIsCustomModalVisible(true)}
					>
						Dodaj z opisem
					</AddButton>
				}
			/>

			<Modal
				title=""
				visible={state.subjectIdPreview !== undefined}
				width={'80%'}
				style={{ maxWidth: '1500px' }}
				onCancel={() => {
					setState({
						...state,
						subjectIdPreview: undefined,
					});
				}}
				footer={
					<Space>
						<Button
							key="close"
							onClick={() =>
								setState({
									...state,
									subjectIdPreview: undefined,
								})
							}
						>
							{'Wróć'}
						</Button>
					</Space>
				}
			>
				<SubjectDetails
					subjectId={state.subjectIdPreview!}
					modal={true}
					{...props}
					editable={false}
					simpleTabChange={true}
					span={24}
				/>
			</Modal>

			<CustomImplementingCompanyModal
				isVisible={isCustomModalVisible}
				onCancel={() => setIsCustomModalVisible(false)}
				onSubmit={(
					value: ProfiBazaAPIModels.ImplementingCompanyDto
				) => {
					const formikValue =
						formik?.values['implementingCompanies'] ?? [];
					formik?.setFieldValue('implementingCompanies', [
						...formikValue,
						value,
					]);
					setState((prevState) => ({
						...prevState,
						array: [...prevState.array, value],
					}));
					setIsCustomModalVisible(false);
				}}
			/>
		</>
	);
};

export default ImplementingCompanyTable;
