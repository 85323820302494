import React, { useEffect } from 'react';

const useIsNotEmptyArray = <T extends any>(array: T[]) => {
	const [levelStackLength, setLevelStackLength] = React.useState<number>(
		array.length
	);
	useEffect(() => {
		setLevelStackLength(array.length);
	}, [array.length]);

	return levelStackLength > 0;
};

export default useIsNotEmptyArray;
