import { WarningTwoTone } from '@ant-design/icons';
import { InterventionCommentArea } from '@components/intervention/comments/InterventionCommentArea';
import { InterventionCommentIcon } from '@components/intervention/comments/InterventionCommentIcon';
import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { DictionaryValueDto } from '@services/src/models/mappers';
import { Col, Row, Space } from 'antd';
import clsx from 'clsx';
import { IDictionaryStore } from 'components/dictionary/dictionaryStore';
import { ChangedVersionDictionaryDto } from 'components/intervention/model/InterventionBaseCommand';
import AddButton from 'components/shared/buttons/AddButton';
import { FFieldLabel, IFormikInputProps } from 'forms/FormikFormItems';
import { ajaxCatch } from 'helper/api';
import { useRootData } from 'hooks/hook';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	CheckDictionaryValueChangeVersionQuery,
	DictionaryInterventionPath,
	DictionaryValueChangeVersionDto,
	DictionaryValueItemDto,
	RizpDictionaryValueDto,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IRizpDictionaryStore } from 'stores/RizpDictionaryStore';

import MultiLevelDictionary from '../../multiLevelDictionary/MultiLevelDictionary';
import MultiLevelPickerField from './MultiLevelPickerField';
import OtherValueModal, { IOtherValueModal } from './OtherValueModal';
import { getOtherValueName } from '.';

interface IProps<T> {
	label?: string;
	disabled?: boolean;
	readOnly?: boolean;
	name: string;
	codeName: string;
	multiple?: boolean;
	values: DictionaryValueItemDto[];
	setValues: React.Dispatch<React.SetStateAction<DictionaryValueItemDto[]>>;
	checkChanges?: (key: any, value?: any, parent?: any) => void;
	dictionaryValues: DictionaryValueItemDto[];
	inline?: boolean;
	fetchInitialValues?: boolean;
	otherValue?: string;
	otherName?: string;
	onChangeCallback?: (item: DictionaryValueItemDto) => void;
	interventionPath: DictionaryInterventionPath;
	changedVersion?: ChangedVersionDictionaryDto | undefined;
	dateRange?: [Moment | undefined, Moment | undefined];
	small?: boolean;
	disableBreadcrumbs?: boolean;
	helpField?: string;
	commentField?: string;
	previewValues: DictionaryValueItemDto[];
}

const MultiLevelDictionaryPicker = <T extends {}>(
	props: IProps<T> & IFormikInputProps
) => {
	const {
		label,
		values,
		setValues,
		disabled,
		readOnly,
		checkChanges,
		codeName,
		multiple,
		dictionaryValues,
		inline,
		form: formikProps,
		field,
		otherName,
		fetchInitialValues,
		otherValue,
		onChangeCallback,
		interventionPath,
		changedVersion,
		dateRange,
		small,
		disableBreadcrumbs,
		helpField,
		commentField,
		previewValues,
	} = props;

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const name = field.name;

	const [visible, setVisible] = useState<boolean>(false);
	const [parents, setParents] = useState<
		(RizpDictionaryValueDto | undefined)[]
	>([]);
	const [initialization, setInitialization] = useState<boolean>(true);
	const [
		isChangedDictionaryVersion,
		setIsChangedDictionaryVersion,
	] = useState<boolean>(false);
	const otherValueModalRef = useRef<IOtherValueModal>(null);

	const rizpDictionaryStore: IRizpDictionaryStore = useRootData(
		(store) => store.rizpDictionaryStore
	);

	useEffect(() => {
		if (field.value) {
			if (initialization) {
				setInitialization(false);
				if (multiple) {
					let newValues: DictionaryValueItemDto[] = field.value;
					if (fetchInitialValues) {
						const wantedIds = newValues.map((val) => val.id!);
						ajaxCatch(() =>
							getProfiBazaApiClient().then((api) =>
								api.dictionaryValue
									.getValuesByIds({ ids: wantedIds })
									.then(
										(
											response: DictionaryValueItemDto[]
										) => {
											setValues(response);
										}
									)
							)
						);
					} else {
						newValues.forEach((item: RizpDictionaryValueDto) => {
							if (item.otherValue) {
								item.value = getOtherValueName(
									item.value!,
									item.otherValue
								);
							}
						});
						setValues(newValues);
					}
				} else {
					ajaxCatch(() =>
						getProfiBazaApiClient().then((api) =>
							api.dictionaryValue
								.getById(field.value)
								.then(
									(
										response: ProfiBazaAPIModels.DictionaryValueItemDto
									) => {
										if (
											otherValue &&
											formikProps.values[otherValue]
										) {
											response.value = getOtherValueName(
												response.value!,
												formikProps.values[otherValue]
											);
										}
										if (response) setValues([response]);
									}
								)
						)
					);
				}
			}
		} else if (values.length) {
			//clear out component state when formik state is empty
			setValues([]);
			setInitialization(true);
		}
	}, [field.value, initialization, values]);

	useEffect(() => {
		checkDictionaryVersionChanges();
	}, [props.dateRange]);

	const checkDictionaryVersionChanges = () => {
		if (props.readOnly || props.disabled || !props.field.value) return;

		let originValue: number[];

		if (Number(props.field.value)) {
			originValue = [Number(props.field.value)];
		} else {
			originValue = props.field.value.map(
				(x: RizpDictionaryValueDto) => x.id
			);
		}

		let payload: CheckDictionaryValueChangeVersionQuery = {
			interventionPaths: props.interventionPath,
			dictionaryValueIds: originValue,
			dateFrom: props.dateRange?.[0]?.utc(true).toDate(),
			dateTo: props.dateRange?.[1]?.utc(true).toDate(),
		};

		getProfiBazaApiClient()
			.then((api) =>
				api.dictionaryValue.checkDictionaryValueChangeVersion({
					body: payload,
				})
			)
			.then((response: DictionaryValueChangeVersionDto[]) => {
				const anyDictionaryValueChanged = response.length > 0;

				if (anyDictionaryValueChanged) {
					let responseWithNewValues = response.filter(
						(x) => x.newDictionaryValue != null
					);

					if (responseWithNewValues.length == 0) {
						setValues([]);
						handleFormikValueUpdate([]);
					} else {
						let newValues = responseWithNewValues.map((x) => ({
							id: x.newDictionaryValue?.id,
							code: x.newDictionaryValue?.code,
							value: x.newDictionaryValue?.value,
							canAddTextValue:
								x.newDictionaryValue?.canAddTextValue,
							canBeSelected: x.newDictionaryValue?.canBeSelected,
							codeName: x.newDictionaryValue?.codeName,
							status: x.newDictionaryValue?.status,
							order: x.newDictionaryValue?.order,
							parentId: x.newDictionaryValue?.parentId,
							validFrom: x.newDictionaryValue?.validFrom,
							validTo: x.newDictionaryValue?.validTo,
							interventionPaths:
								x.newDictionaryValue?.interventionPaths,
							breadcrumb: x.newDictionaryValue?.breadcrumb,
						})) as DictionaryValueItemDto[];
						setValues(newValues);
						handleFormikValueUpdate(newValues);
						rizpDictionaryStore.valueChangedInActivity.set(true);
					}
					setIsChangedDictionaryVersion(true);
				}
			});
	};

	const isChangedVersion = () => {
		if (props.changedVersion !== undefined && !disabled) {
			return true;
		} else if (isChangedDictionaryVersion && !disabled) {
			return true;
		}
		return false;
	};

	const handleFormikValueUpdate = (
		newValues: DictionaryValueItemDto[] | DictionaryValueItemDto | undefined
	) => {
		setInitialization(false);
		let mappedValues;
		let fieldValue;
		if (multiple) {
			mappedValues = (newValues as DictionaryValueItemDto[]).map((val) =>
				mapItem(val)
			);
			fieldValue = (newValues as DictionaryValueItemDto[]).map((val) =>
				getItemValue(val)
			);
			if (otherName) {
				formikProps?.setFieldValue(otherName, fieldValue, true);
			}
		} else {
			mappedValues = mapItem(newValues as DictionaryValueItemDto)?.id;
			fieldValue = getItemValue(newValues as DictionaryValueItemDto);
			if (otherName)
				formikProps?.setFieldValue(otherName, fieldValue?.value, true);
		}
		formikProps?.setFieldValue(name as any, mappedValues, true);
		formikProps?.setFieldTouched(name, true);
		formikProps?.validateField(name);
		checkChanges && checkChanges(name, fieldValue, parents);
	};

	const getItemValue = (
		item: (DictionaryValueItemDto & RizpDictionaryValueDto) | undefined
	) => {
		if (!item) return undefined;

		const mappedItem: RizpDictionaryValueDto = { value: item.value };
		return mappedItem;
	};

	const mapItem = (
		item: (DictionaryValueItemDto & RizpDictionaryValueDto) | undefined
	) => {
		if (!item) return undefined;
		const mappedItem: RizpDictionaryValueDto = {
			id: item.id,
			value: item.value,
			codeName: item.codeName,
			breadcrumb: item.breadcrumb,
		};
		if (item.canAddTextValue || item.otherValue) {
			mappedItem.otherValue = item.otherValue;
		} else if (otherValue) {
			formikProps?.setFieldValue(otherValue, null); //reset other value (if we got other value field name)
		}
		return mappedItem;
	};

	const handleItemDeletion = (selectedId: number) => {
		if (multiple) {
			const updatedValues = values.filter((x) => x.id !== selectedId);
			handleFormikValueUpdate(updatedValues);
			setValues(updatedValues);
		} else {
			handleFormikValueUpdate(undefined);
			setValues([]);
		}
	};

	const renderAddButton = () => (
		<>
			{!disabled && !readOnly && (
				<AddButton
					className={clsx(inline && !small && 'ml-xs')}
					small={small}
					onClick={() => {
						setVisible(true);
						formikProps?.setFieldTouched(name, true);
					}}
				>
					Dodaj
				</AddButton>
			)}
		</>
	);

	const refreshProcess = (
		interventionPaths?: DictionaryInterventionPath[]
	) => {
		dictionaryStore.initializeDictionary(
			codeName,
			interventionPath,
			dateRange?.[0],
			dateRange?.[1]
		);
	};

	const renderPickerField = () => (
		<InterventionCommentArea fieldName={commentField}>
			<MultiLevelPickerField
				handleItemDeletion={handleItemDeletion}
				previewValues={previewValues}
				name={field.name}
				values={values}
				multiple={multiple}
				inline={inline}
				disabled={disabled}
				small={small}
				disableBreadcrumbs={disableBreadcrumbs}
				readOnly={readOnly}
				field={field}
				form={formikProps}
			/>
		</InterventionCommentArea>
	);

	const renderHelpField = () =>
		helpField ? (
			<LabelHelpTooltip field={helpField} defaultValue={label!} />
		) : undefined;

	const renderCommentIcon = () =>
		commentField ? (
			<InterventionCommentIcon fieldName={commentField} />
		) : undefined;

	return (
		<>
			{inline ? (
				<>
					<Space>
						{label && <FFieldLabel label={label} />}
						{renderHelpField()}
						{renderCommentIcon()}
					</Space>
					{isChangedVersion() && (
						<ProfiBazaTooltip
							placement="top"
							title="Zmieniona wersja słownikowa"
						>
							<WarningTwoTone twoToneColor="#ff0000" />
						</ProfiBazaTooltip>
					)}
					<Row>
						<Col xs={21}>{renderPickerField()}</Col>
						<Col xs={3}>{renderAddButton()}</Col>
					</Row>
				</>
			) : (
				<>
					<Space>
						{label && (
							<FFieldLabel label={label} for={field.name} />
						)}
						{renderHelpField()}
						{renderCommentIcon()}
						{!disabled && isChangedVersion() && (
							<ProfiBazaTooltip
								placement="top"
								title="Zmieniona wersja słownikowa"
							>
								<WarningTwoTone twoToneColor="#ff0000" />
							</ProfiBazaTooltip>
						)}
						{renderAddButton()}
					</Space>
					{renderPickerField()}
				</>
			)}

			<InterventionCommentArea fieldName={commentField}>
				{visible ? (
					<MultiLevelDictionary
						visible={visible}
						setVisible={setVisible}
						dictionaryValues={dictionaryValues}
						updateValuePosition={() => {}} //adapter
						isEditable={false}
						setParents={
							checkChanges
								? (value) => setParents([...parents, value])
								: undefined
						}
						selectableMode={!disabled}
						dateRange={dateRange}
						refreshBaseItems={refreshProcess}
						selection={{
							codeName: codeName,
							interventionPath: interventionPath,
							setValues: setValues,
							setFormValues: (
								newFormValue: DictionaryValueItemDto
							) => {
								let newValues: DictionaryValueItemDto[];
								const handleSelection = (
									customValue: string | undefined = undefined
								) => {
									if (multiple) {
										const valueAlreadyExists = values?.some(
											(val) => val.id === newFormValue.id
										);
										if (valueAlreadyExists) return;
										newValues = [...values, newFormValue];
										handleFormikValueUpdate(newValues);
									} else {
										newValues = [newFormValue];
										handleFormikValueUpdate(newFormValue);
									}
									setValues(newValues);

									onChangeCallback &&
										onChangeCallback(newFormValue);
								};

								if (newFormValue.canAddTextValue) {
									otherValueModalRef.current?.showModal(
										newFormValue
									);
								} else {
									handleSelection();
								}
							},
						}}
					/>
				) : (
					<></>
				)}
			</InterventionCommentArea>

			<OtherValueModal
				ref={otherValueModalRef}
				form={formikProps}
				field={field}
				valueName={otherName}
				multiple={multiple}
				parents={parents}
				setValues={setValues}
				checkChanges={(key, value, parent) => {
					checkChanges?.(key, value, parent);
					setInitialization(false);
				}}
				otherName={otherValue}
			/>
		</>
	);
};

export default MultiLevelDictionaryPicker;
