import {
	AreaCodeNames,
	NationWideTeryt,
} from 'components/shared/teryt/TerytAreaPicker/TeryAreaMappers';
import { Moment } from 'moment';
import { DictionariesConstants } from 'services/DictionariesConstants';
import {
	DictionaryInterventionPath,
	InterventionCoownerDto,
	InterventionPartnerDto,
	InterventionPatternType,
	OpinionAOTMiTType,
	RizpDictionaryValueDto,
} from 'services/src/models';
import * as Yup from 'yup';
import { string } from 'yup';

export const InterventionValidationSchema = (
	areaCodeName: AreaCodeNames | undefined,
	interventionPath: DictionaryInterventionPath,
	interventionPatternType: InterventionPatternType,
	subjectId?: string,
	interventionTypeCodeName?: string | undefined,
) =>
	Yup.object().shape({
		interventionTypeDictionaryValueId:
			interventionPath == DictionaryInterventionPath.PROHEALTHACTIVITIES
				? Yup.string()
					.nullable()
					.required('Typ interwencji jest wymagany!')
				: Yup.string().nullable(),
		interventionKindDictionaryValues:
			interventionPath !== DictionaryInterventionPath.PROHEALTHACTIVITIES
				? interventionPath !== DictionaryInterventionPath.RESEARCH
					? Yup.string().required('Rodzaj interwencji jest wymagany!')
					: Yup.string().required('Rodzaj projektu jest wymagany!')
				: Yup.string().nullable(),

		interventionAreaDictionaryValueId: Yup.number()
			.nullable()
			.required('Zasięg interwencji jest wymagany!'),
		interventionAreaDictionaryOtherValue: Yup.string().nullable(),
		name: Yup.string()
			.nullable()
			.required('Nazwa interwencji jest wymagana!')
			.max(128, `Nazwa inwerwencji może mieć maksymalnie 128 znaków`)
			.test(
				'no-leading-spaces-or-special-chars',
				'Nazwa nie może zaczynać się od spacji ani znaku specjalnego',
				function (value) {
					if (!value) return true;
					const firstChar = value.charAt(0);
					return /^[a-zA-Z0-9ĄąĆćĘęŁłŃńÓóŚśŹźŻż]/.test(firstChar);
				}
			),
		implementationPeriodDictionaryValueId:
			interventionPath == DictionaryInterventionPath.LEGISLATION
				? Yup.number().nullable()
				: Yup.number()
					.nullable()
					.required('Typ okresu realizacji jest wymagany!'),
		implementationPeriodDictionaryOtherValue: Yup.string().nullable(),
		plannedDateRange: Yup.array().test(
			'required',
			'Data planowana jest wymagana!',
			(x) =>
				interventionPath !=
				DictionaryInterventionPath.PROHEALTHACTIVITIES ||
				(!!x && !!x[0] && !!x[1])
		),
		dataSourceDictionaryValues:
			interventionPath ==
				DictionaryInterventionPath.SUPERVISIONMONITORING ||
				interventionPath ==
				DictionaryInterventionPath.SUPERVISIONSANITARY ||
				interventionPath == DictionaryInterventionPath.STAFFMONITORING
				? Yup.array().required('Źródła danych są wymagane')
				: Yup.array().nullable(),
		educationImplementationModeDictionaryValueId:
			interventionPath == DictionaryInterventionPath.STAFFEDUCATION
				? Yup.number()
					.nullable()
					.required('Tryb realizacji jest wymagany')
				: Yup.number().nullable(),
		interventionEffectDictionaryValues:
			interventionPath != DictionaryInterventionPath.PROHEALTHACTIVITIES
				? Yup.array().required('Efekty interwencji są wymagane')
				: Yup.array().nullable(),
		areaTerytCodes:
			interventionPath != DictionaryInterventionPath.RESEARCH
				? Yup.array()
					.required('Teren interwencji jest wymagany')
					.min(1, 'Teren interwencji jest wymagany')
					.of(string())
					.test(
						'teryt-area-range-test',
						'Zdefiniowane tereny są niezgodne z zasięgiem interwencji',
						function (values: string[]) {
							if (!values?.length) return false;
							switch (areaCodeName) {
								case 'ZASIEG_INTERWENCJI_OGOLNOKRAJ':
									return true;
								case 'ZASIEG_INTERWENCJI_WOJ':
								case 'ZASIEG_INTERWENCJI_WOJ_1':
								case 'ZASIEG_INTERWENCJI_WOJ_2':
									return values.every(
										(val) =>
											val !==
											NationWideTeryt.terytCode
									);
								default:
									return true;
							}
						}
					)
				: Yup.array().nullable(),
		contractorsQty:
			interventionPath == DictionaryInterventionPath.RESEARCH
				? Yup.number()
					.nullable()
					.required('Liczba wykonawców projektu jest wymagana')
				: Yup.number().nullable(),
		ncnClassificationDictionaryValues:
			interventionPath == DictionaryInterventionPath.RESEARCH
				? Yup.array().required('Klasyfikacja NCN jest wymagana')
				: Yup.array().nullable(),
		gBoardClassificationDictionaryValues:
			interventionPath == DictionaryInterventionPath.RESEARCH
				? Yup.array().required('Klasyfikacja GBAORD jest wymagana')
				: Yup.array().nullable(),
		disciplineAreaClassificationDictionaryValues:
			interventionPath == DictionaryInterventionPath.RESEARCH
				? Yup.array().required(
					'Klasyfikacja obszar-dyscyplina jest wymagana'
				)
				: Yup.array().nullable(),
		interventionPartners: Yup.array().test(
			'intervention-partners-test',
			'Brak możliwości wybrania edytowanego podmiotu',
			function (values: InterventionPartnerDto[]) {
				if (subjectId!)
					return !values.some((v) => v.subjectId == subjectId);

				return true;
			}
		),
		interventionCoownerNames: Yup.array().test(
			'intervention-coowners-test',
			'Brak możliwości wybrania edytowanego podmiotu',
			function (values: any[]) {
				if (subjectId!) {
					let realValues = this.resolve(
						Yup.ref('interventionCoowners')
					) as InterventionCoownerDto[];
					return !realValues.some((v) => v.id == subjectId);
				}
				return true;
			}
		),
		opinionAOTMiT: Yup.string().nullable().test(
			'opinionAOTMiT-test',
			'Opinia Prezesa jest wymagana',
			function (value: string) {
				if (!interventionTypeCodeName)
					return true;

				if (interventionTypeCodeName === DictionariesConstants.InterventionType.AotmitReported)
					return value != undefined;

				return true;
			}
		),
		presidentAotmitNumber: Yup.string().nullable().test(
			'presidentAotmitNumber-test',
			'Numer opinii Prezesa jest wymagany',
			function (value: string) {
				if (!interventionTypeCodeName)
					return true;

				if (interventionTypeCodeName === DictionariesConstants.InterventionType.AotmitReported)
					return value != undefined;

				return true;
			}
		)
			.test(
				'presidentAotmitNumber-test-format',
				'Poprawne formaty numeru opinii Prezesa to: 000/0000, 00/0000, 0/0000. W miejscu zer(0) należy podać właściwe cyfry z numeru opinii prezesa.',
				function (value: string) {
					if (!interventionTypeCodeName || interventionTypeCodeName != DictionariesConstants.InterventionType.AotmitReported)
						return true;

					const regex = /^[0-9]{1,3}\/[0-9]{4}$/;

					if (interventionTypeCodeName === DictionariesConstants.InterventionType.AotmitReported && !value)
						return false;

					return regex.test(value);
				}
			),
		dateOfDecisionAOTMiT: Yup.date().nullable().test(
			'dateOfDecisionAOTMiT-test',
			'Data wydania opinii jest wymagana',
			function (value: Moment) {
				if (!interventionTypeCodeName)
					return true;

				if (interventionTypeCodeName === DictionariesConstants.InterventionType.AotmitReported)
					return value != undefined;

				return true;
			}
		),
		legalBases: Yup.array()
			.required('Podstawa prawna jest wymagana')
			.min(1, 'Podstawa prawna jest wymagana')
			.test(
				'legal-basis-path-test',
				interventionPatternType ==
					InterventionPatternType.AOTMITRECOMENDATION
					? 'Wymagana podstawa prawna: Ustawa z dnia 27 sierpnia 2004 r. o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych (Dz.U. z 2016 r. poz. 1793, z późn. zm.)'
					: 'Wymagana podstawa prawna: Ustawa o Państwowej Inspekcji Sanitarnej',
				function (values: RizpDictionaryValueDto[]) {
					if (!values?.length) return false;
					switch (interventionPath) {
						case DictionaryInterventionPath.PROHEALTHACTIVITIES:
							switch (interventionPatternType) {
								case InterventionPatternType.PROGRAM:
								case InterventionPatternType.NOTPROGRAM:
									return values.some(
										(v) =>
											v.codeName ==
											DictionariesConstants.LegalBasis
												.StateSanitaryInspection
									);

								case InterventionPatternType.AOTMITRECOMENDATION:
									return values.some(
										(v) =>
											v.codeName ==
											DictionariesConstants.LegalBasis
												.HealthCareServicesFinancedFromPublicFunds
									);

								default:
									return true;
							}
						// case DictionaryInterventionPath.SUPERVISIONSANITARY:
						// 	return values.some(
						// 		(v) =>
						// 			v.codeName ==
						// 			DictionariesConstants.LegalBasis
						// 				.StateSanitaryInspection
						// 	);
						default:
							return true;
					}
				}
			),
		numberOfPersonAction: Yup.number()
			.nullable()
			.test(
				'number-of-person-action-test',
				'Liczba osobodziałań nie może być mniejsza niż liczba odbiorców.',
				function (value: number) {
					if (interventionPath != DictionaryInterventionPath.PROHEALTHACTIVITIES)
						return true;

					return (this.parent['numberOfRecipients'] <= value);
				}),
	});
