import { AutoComplete, Input } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { options } from 'less';
import React, { useState } from 'react';
const { Option } = AutoComplete;

export interface IOption {
	key: string;
	value: string;
	description: string;
}

export interface IAsyncAutocompleteProps {
	loadData: (data: string) => Promise<(IOption & {})[]>;
	onSelect: (option: IOption & {}) => void;
	onClear: () => void;
}

interface IState {}

const AsyncAutocomplete: React.FC<
	IAsyncAutocompleteProps & AutoCompleteProps
> = (props) => {
	const { onClear, loadData, onSelect, ...rest } = props;
	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [options, setOptions] = useState<(IOption & {})[]>();

	const onItemSelect = (data: string, option: any) => {
		var item = options?.find((x) => x.key == option.key);
		if (item) {
			onSelect(item);
		}
	};

	const onChange = (data: string) => {
		if (!data) {
			onClear();
			setOptions([]);
			if (timer) {
				clearTimeout(timer);
			}
		} else if (data.length > 2) {
			if (timer) {
				clearTimeout(timer);
			}
			setTimer(
				setTimeout(() => {
					loadData(data).then((results: (IOption & {})[]) => {
						setOptions(results);
					});
				}, 400)
			);
		}
	};

	return (
		<AutoComplete {...rest} onChange={onChange} onSelect={onItemSelect}>
			{options?.map((option) => (
				<Option key={option.key} value={option.key}>
					{option.description}
				</Option>
			))}
		</AutoComplete>
	);
};

export default AsyncAutocomplete;
