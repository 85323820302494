import { RestResponse } from '@azure/ms-rest-js';
import {
	IInterventionData,
	InterventionCommentButton,
} from '@components/intervention/comments/InterventionCommentButton';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import InterventionTags from '@components/tags/InterventionTags';
import UserManualViewButton from '@components/userManual/UserManualViewButton';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Button, Col, Row, Select, Space, Typography } from 'antd';
import PermissionValidator from 'authorization/permissionValidator';
import { DictionaryValueItemDto } from 'components/dictionary/DictionaryValueItemDto';
import BackButtons from 'components/shared/buttons/BackButtons';
import { AreaCodeNames } from 'components/shared/teryt/TerytAreaPicker/TeryAreaMappers';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import arrayHelpers from 'helper/arrayHelpers';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { showWarning } from 'layout/Modals';
import PrimaryCard from 'layout/PrimaryCard';
import _ from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { DictionariesConstants } from 'services/DictionariesConstants';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	ActivitiesSummaryDto,
	BusinessObjectType,
	DictionaryInterventionPath,
	InterventionCreateResponse,
	InterventionPatternType,
	UserManualInterventionPath,
	UserManualType,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';
import { IRizpDictionaryStore } from 'stores/RizpDictionaryStore';

import { IInterventionCommentStore } from './comments/store/InterventionCommentStore';
import InterventionContactTable from './contacts/InterventionContactTable';
import { InterventionPathContext } from './context/InterventionPathContext';
import InterventionFields from './fields/InterventionFieldsCommand';
import InterventionPattern from './InterventionPattern';
import InterventionSkeleton from './InterventionSkeleton';
import {
	InterventionBaseCommand,
	InterventionDictionaries,
} from './model/InterventionBaseCommand';
import { InterventionCommand } from './model/InterventionCommand';
import { InterventionPatternValidationSchema } from './validators/InterventionPatternValidation';
import { InterventionValidationSchema } from './validators/InterventionValidation';

const { Option } = Select;
interface IProps {
	interventionId?: any;
	editable?: boolean;
	isPattern?: boolean;
	patternId?: any;
	activitiesSummary?: ActivitiesSummaryDto | undefined;
	onChangeAreaTerytCodes?: (isActivitesTabDisabled: boolean) => void;
}

const InterventionData: React.FC<RouteComponentProps & IProps> = (props) => {
	const [data, setData] = useState<InterventionCommand>(
		new InterventionCommand()
	);
	const [toList, setToList] = useState<boolean>(false);
	const [isSendingToAotmit, setIsSendingToAotmit] = useState<boolean>(false);
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const rizpDictionaryStore: IRizpDictionaryStore = useRootData(
		(store) => store.rizpDictionaryStore
	);

	const [loaded, setLoaded] = useState<boolean>(false);
	const [changed, setChanged] = useState<boolean>(false);
	const [changedData, setChangedData] = useState<InterventionCommand>(
		new InterventionCommand()
	);
	const [validTerytAreaLvl, setValidTerytAreaLvl] = useState<
		AreaCodeNames | undefined
	>();

	const formikRef = useRef<Formik<InterventionCommand>>(null);

	const [coownersVisible, setCoownersVisible] = useState<boolean>(false);
	const [coownerNames, setCoownerNames] = useState<string[]>([]);

	const [pattern, setPattern] = useState<InterventionCommand>();
	const [unchangedData, setUnchangedData] = useState<
		InterventionBaseCommand | undefined
	>(undefined);

	const [isAotmit, setIsAotmit] = useState<boolean>(false);
	const [interventionPatternType, setInterventionPatternType] = useState<
		InterventionPatternType
	>();

	const interventionCommentStore: IInterventionCommentStore = useRootData(
		(store) => store.interventionCommentStore
	);

	useEffect(() => {
		refresh();
		if (props.patternId) {
			loadPattern(props.patternId);
		}

		if (props.isPattern) {
			interventionCommentStore.filterParams.set({
				activityId: null,
				populationId: null,
				sponsoringCompanyId: null,
			});

			interventionCommentStore.marks.set(undefined);
		}

		getInterventionType(data.interventionTypeDictionaryValueId);
	}, [props.interventionId]);

	const loadWarnings = () => {
		if (!props.isPattern) {
			ajaxCatch(async () => {
				const api = await getProfiBazaApiClient();
				const response = await api.intervention.getValidationWarnings(
					props.interventionId
				);
				const errors = response
					.filter((field) => field.errors)
					.map((field) => field.errors);
				const mappedErrors = _.union(...errors).map((error) => (
					<p>{error}</p>
				));
				if (mappedErrors.length) {
					showWarning(<>{mappedErrors}</>);
				}
			});
		}
	};

	useEffect(() => {
		if (props.editable && !props.interventionId) {
			const obj = new InterventionCommand({
				subjectName: accountDetailsStore.account.get()?.subject?.name,
			});
			setData(obj);
			setChangedData(obj);
		}
	}, [accountDetailsStore.account.get()?.subject?.name]);

	useEffect(() => {
		if (props.activitiesSummary) {
			refreshActivitiesSummary(props.activitiesSummary);
		}
	}, [props.activitiesSummary]);

	const refreshActivitiesSummary = (
		acitivitiesSummary: ActivitiesSummaryDto
	) => {
		formikRef.current?.setFieldValue(
			'realizationBudget',
			acitivitiesSummary.realizationBudget
		);
		formikRef.current?.setFieldValue(
			'plannedBudget',
			acitivitiesSummary.plannedBudget
		);

		formikRef.current?.setFieldValue(
			'realizationDate[0]',
			acitivitiesSummary?.realizationDateFrom
				? moment(acitivitiesSummary?.realizationDateFrom)
				: undefined
		);
		formikRef.current?.setFieldValue(
			'realizationDate[1]',
			acitivitiesSummary?.realizationDateTo
				? moment(acitivitiesSummary?.realizationDateTo)
				: undefined
		);
	};

	const getInterventionType = (
		interventionTypeDictionaryValueId?: number
	) => {
		if (interventionTypeDictionaryValueId) {
			ajaxCatch(() =>
				getProfiBazaApiClient()
					.then((api) =>
						api.dictionaryValue.getValuesByIds({
							ids: [interventionTypeDictionaryValueId!],
						})
					)
					.then((response: DictionaryValueItemDto[]) => {
						switch (response[0].codeName) {
							case DictionariesConstants.InterventionType
								.AotmitOpinion:
								setInterventionPatternType(
									InterventionPatternType.AOTMITRECOMENDATION
								);
								break;

							case DictionariesConstants.InterventionType.Program:
								setInterventionPatternType(
									InterventionPatternType.PROGRAM
								);
								break;

							case DictionariesConstants.InterventionType
								.NotProgram:
								setInterventionPatternType(
									InterventionPatternType.NOTPROGRAM
								);
								break;
						}
						setIsAotmit(
							response[0].codeName ===
								DictionariesConstants.InterventionType
									.AotmitOpinion ||
								response[0].codeName ===
									DictionariesConstants.InterventionType
										.AotmitReported
						);
					})
			);
		}
	};

	const checkChanges = (value: any, key?: any) => {
		if (props.editable) {
			let obj: InterventionCommand = InterventionCommand.changeData(
				changedData!,
				key,
				value
			);
			if (arrayHelpers.isEquivalent(data, obj)) {
				setChanged(false);
			} else {
				setChanged(true);
			}

			setChangedData(obj);
		}
	};

	const isChangedDictionariesValues = (obj: InterventionBaseCommand) => {
		let list = InterventionDictionaries.toCommand(
			new InterventionDictionaries(obj)
		);

		setLoaded(false);

		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.dictionaryValue.checkDictionaryValueChangeVersion({
						body: list,
					})
				)
				.then((response) => {
					InterventionDictionaries.updateDictionaryValues(
						obj,
						response
					);
					setData(obj);
					setLoaded(true);
				})
		);
	};

	const checkIfObjectValue = (object: any, key?: any) => {
		return (
			object != null &&
			object[key] !== undefined &&
			object[key] !== null &&
			(!Array.isArray(object[key]) ||
				(Array.isArray(object[key]) && object[key]?.length > 0))
		);
	};

	const checkIfDateRangeValue = (object: any, key?: any) => {
		if (object == null) return false;

		return (object[key] as Array<Moment>).every((x) => x !== undefined);
	};

	function getPattern(
		patternId: string,
		interventionId?: string
	): Promise<ProfiBazaAPIModels.PatternDto> {
		return new Promise<ProfiBazaAPIModels.PatternDto>((resolve, reject) => {
			ajaxCatch(() =>
				getProfiBazaApiClient()
					.then((api) =>
						api.pattern.getById(patternId, {
							interventionId: interventionId,
						})
					)
					.then((intervention: ProfiBazaAPIModels.PatternDto) => {
						resolve(intervention);
					})
			);
		});
	}

	const loadPattern = (patternId?: string) => {
		if (patternId) {
			getPattern(patternId).then((intervention) => {
				let obj = new InterventionCommand(intervention);
				setPattern(obj);
				setData(InterventionCommand.toInterventionFromPattern(obj));
			});
		}
	};

	const refresh = async () => {
		setLoaded(false);
		if (props.interventionId) {
			await rizpDictionaryStore.fetch(
				props.interventionId,
				BusinessObjectType.Intervention
			);

			ajaxCatch(() =>
				getProfiBazaApiClient()
					.then((api) =>
						props.isPattern
							? api.pattern.getById(props.interventionId)
							: api.intervention.getById(props.interventionId)
					)
					.then(
						(
							intervention:
								| ProfiBazaAPIModels.InterventionDetailsDto
								| ProfiBazaAPIModels.PatternDto
						) => {
							let obj = new InterventionCommand(intervention);
							setData(obj);
							intervention.patternId &&
								getPattern(
									intervention.patternId,
									intervention.id
								).then((pattern) => {
									let patternObj = new InterventionCommand(
										pattern
									);
									setPattern(patternObj);
								});

							let copiedObject = JSON.parse(JSON.stringify(obj));
							setUnchangedData(copiedObject);
							setChanged(false);
							setChangedData(obj);
							setLoaded(true);
							setCoownerNames(
								intervention.interventionCoowners?.map(
									(x) => x.name!
								)!
							);
							if (props.editable)
								isChangedDictionariesValues(obj);
							else setData(obj);

							if (!props.isPattern) {
								getInterventionType(
									obj.interventionTypeDictionaryValueId
								);
							}
							loadWarnings();
						}
					)
			);
		} else {
			setData(new InterventionCommand());
			setChangedData(new InterventionCommand());
			setLoaded(true);
		}
	};

	const onFinish = (
		value: InterventionCommand,
		interventionId: any,
		backToList: boolean,
		actions: FormikActions<InterventionCommand>
	) => {
		if (interventionId) {
			ajaxByUser(
				props.isPattern
					? 'Zapisano wzorzec interwencji'
					: 'Zapisano interwencję',
				() =>
					getProfiBazaApiClient()
						.then((api) =>
							props.isPattern
								? api.pattern.update({
										body: InterventionCommand.toUpdatePatternCommand(
											value,
											props.interventionId!
										),
								  })
								: api.intervention.update({
										body: InterventionCommand.toUpdateInterventionCommand(
											value,
											props.interventionId!
										),
								  })
						)
						.then((intervention: RestResponse) => {
							setToList(false);
							setLoaded(false);
							if (isSendingToAotmit) {
								sendToAotmit(interventionId);
							}
							setChanged(false);
							if (backToList) {
								props.history.push(
									props.isPattern ? '/rizp/patterns' : '/rizp'
								);
							} else {
								props.history.push(
									props.isPattern
										? `/rizp/pattern/edit/${interventionId}`
										: `/rizp/edit/${interventionId}`
								);
								refresh();
							}
							props.onChangeAreaTerytCodes?.(false);
						})
			).then(() => actions.setSubmitting(false));
		} else {
			ajaxByUser(
				props.isPattern
					? 'Zapisano wzorzec interwencji'
					: 'Zapisano interwencję',
				() =>
					getProfiBazaApiClient()
						.then((api) =>
							props.isPattern
								? api.pattern.create({
										body: InterventionCommand.toCreatePattern(
											value
										),
								  })
								: api.intervention.create({
										body: InterventionCommand.toCreateIntervention(
											value
										),
								  })
						)
						.then((intervention: InterventionCreateResponse) => {
							setToList(false);
							setLoaded(false);
							setChanged(false);
							if (backToList) {
								props.history.push(
									props.isPattern ? '/rizp/patterns' : '/rizp'
								);
							} else {
								props.history.push(
									props.isPattern
										? `/rizp/pattern/edit/${intervention.id}`
										: `/rizp/edit/${intervention.id}`
								);
								refresh();
							}
						})
			).then(() => actions.setSubmitting(false));
		}
	};

	const permissionValidator = new PermissionValidator(
		accountDetailsStore!.account.get()!
	);

	const filterInterventionAreaForPattern = (
		value: ProfiBazaAPIModels.DictionaryValueItemDto
	) => {
		return (
			(permissionValidator.has(
				ProfiBazaAPIModels.Permission
					.RIZPPatternsModificationsNationwide
			) &&
				value.codeName ===
					DictionariesConstants.InterventionArea.Nationwide) ||
			(permissionValidator.has(
				ProfiBazaAPIModels.Permission
					.RIZPPatternsModificationsVoivodeship
			) &&
				value.codeName ===
					DictionariesConstants.InterventionArea.Voivodeship)
		);
	};

	const sendToAotmit = (interventionId: any) => {
		ajaxByUser('Interwencja została wysłana do AOTMiT', () =>
			getProfiBazaApiClient()
				.then((api) =>
					api.intervention.sendInterventionToAOTMIT({
						body: {
							id: interventionId,
						},
					})
				)
				.then((intervention: RestResponse) => {
					setToList(false);
					setLoaded(false);
					setChanged(false);
					setIsSendingToAotmit(false);
					props.history.push('/rizp');
				})
		).then(() =>
			formikRef.current?.getFormikActions().setSubmitting(false)
		);
	};

	return (
		<>
			<CenteredRow>
				<Col span={20} xs={23} xxl={20}>
					{loaded ? (
						<>
							{!props.isPattern && (
								<>
									<Row>
										<Col span={20}>
											<InterventionTags
												interventionId={
													props.interventionId
												}
												tags={data.tags!}
											/>
										</Col>
										<Col span={4}>
											<Space className="user-manual-header">
												<InterventionCommentButton
													label="Komentarze interwencji"
													interventionData={
														{
															id: data.id,
															interventionPath:
																data.interventionPath,
															subjectId:
																data.subjectId,
															opinionAOTMiT:
																data.opinionAOTMiT,
														} as IInterventionData
													}
													readonlyMode={false}
												/>
												<UserManualViewButton
													userManual={{
														manualType:
															UserManualType.Intervention,
														interventionPath: (data.interventionPath as string) as UserManualInterventionPath,
													}}
												/>
											</Space>
										</Col>
									</Row>
								</>
							)}

							<Formik
								initialValues={data}
								validationSchema={
									props.isPattern
										? InterventionPatternValidationSchema
										: InterventionValidationSchema(
												validTerytAreaLvl,
												(data.interventionPath as string) as DictionaryInterventionPath,
												interventionPatternType as InterventionPatternType,
												data.subjectId,
												data?.interventionTypeDictionaryCode
										  )
								}
								validateOnChange={false}
								enableReinitialize
								onSubmit={(value, actions) => {
									if (loaded)
										onFinish(
											value,
											data.id,
											toList,
											actions
										);
								}}
								ref={formikRef}
								render={(
									formik: FormikProps<InterventionCommand>
								) => (
									<>
										{props.isPattern && (
											<InterventionPattern
												editable={props.editable}
												formik={formik}
												checkChanges={checkChanges}
											/>
										)}
										<PrimaryCard>
											<InterventionPathContext.Provider
												value={{
													data: data,
													formik: formik,
													editable: props.editable!,
													setValidTerytAreaLvl: setValidTerytAreaLvl,
													unchangedData: unchangedData,
													isPattern: props.isPattern!,
													filterInterventionAreaForPattern: filterInterventionAreaForPattern,
													pattern: pattern,
													validTerytAreaLvl: validTerytAreaLvl,
													checkIfDateRangeValue: checkIfDateRangeValue,
													checkIfObjectValue: checkIfObjectValue,
													setCoownerNames: setCoownerNames,
													setCoownersVisible: setCoownersVisible,
													setIsAotmit: setIsAotmit,
													coownerNames: coownerNames,
													coownersVisible: coownersVisible,
													checkChanges: checkChanges,
													isAotmit: isAotmit,
													setDateRange: (value) => {
														setData({
															...formik.values,
															plannedDateRange: value,
														});
													},
													onChangeAreaTerytCodes:
														props.onChangeAreaTerytCodes,
												}}
											>
												<InterventionFields
													isPattern={props.isPattern!}
													interventionPath={
														data.interventionPath!
													}
												/>
											</InterventionPathContext.Provider>
											{(data.interventionPath ||
												props.isPattern) && (
												<InterventionContactTable
													changeList={(value) => {
														checkChanges(value);
													}}
													contacts={
														formik.values?.contacts!
													}
													patternContacts={
														pattern?.contacts
													}
													editable={props.editable}
												/>
											)}
										</PrimaryCard>

										<Row className="float-right">
											<Space>
												<BackButtons
													link={
														props.isPattern
															? '/rizp/patterns'
															: `/rizp/${
																	gridStore.getBackTab() ??
																	'intervention'
															  }`
													}
													onClick={() => {
														setToList(true);
														formik.submitForm();
													}}
													size="large"
													disabled={
														formik.isSubmitting
													}
													backToCurrentPlace={true}
													visible={
														changed &&
														props.editable!
													}
												/>

												{props.editable && (
													<>
														<Button
															type="primary"
															size="large"
															disabled={
																formik.isSubmitting
															}
															onClick={() => {
																formik.submitForm();
															}}
														>
															Zapisz
														</Button>
														<Button
															type="primary"
															size="large"
															htmlType="submit"
															disabled={
																formik.isSubmitting
															}
															onClick={() => {
																setToList(true);
																formik.submitForm();
															}}
														>
															Zapisz i wyjdź
														</Button>
														{isAotmit &&
															data.status ==
																ProfiBazaAPIModels
																	.PublicHealthInterventionStatus
																	.PLANNED &&
															!data.opinionAOTMiT && (
																<>
																	<AccessiblePopconfirm
																		title={
																			<>
																				<p>
																					Czy
																					napewno
																					chcesz
																					wysłać
																					interwencję
																					do
																					oceny?
																				</p>
																				<p>
																					Na
																					czas
																					oceny
																					projektu
																					przez
																					AOTMiT
																					edycja
																					danych
																					nie
																					będzie
																					możliwa.
																				</p>
																			</>
																		}
																		okText="Tak"
																		cancelText="Nie"
																		placement="bottomRight"
																		onConfirm={() => {
																			setToList(
																				false
																			);
																			setIsSendingToAotmit(
																				true
																			);
																			formik.submitForm();
																		}}
																		disabled={
																			formik.isSubmitting
																		}
																	>
																		<Button
																			type="primary"
																			size="large"
																			disabled={
																				formik.isSubmitting
																			}
																		>
																			Zapisz
																			i
																			wyślij
																			do
																			oceny
																		</Button>
																	</AccessiblePopconfirm>
																</>
															)}
													</>
												)}
											</Space>
										</Row>
									</>
								)}
							/>
						</>
					) : (
						<InterventionSkeleton />
					)}
				</Col>
			</CenteredRow>
		</>
	);
};

export default observer(InterventionData);
