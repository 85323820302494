import './ProfiBazaColumnExtender.less';

import { Space } from 'antd';
import { FilterDropdownProps, SorterResult } from 'antd/lib/table/interface';
import GridPersonalizationSettings from 'components/menu/GridPersonalizationSettings';
import React, { Key, ReactNode } from 'react';
import { GridSettingsDto } from 'services/src/models';
import { GridNameType } from 'stores/TableColumnStore';

import FilterButton from '../buttons/FilterButton';
import ColumnSelector from './ColumnSelector';
import GridClearFilters from './filters/GridClearFilters';
import { GridFilterDateRange } from './filters/GridFilterDateRange';
import { GridFilterDictionarySelect } from './filters/GridFilterDictionarySelect';
import GridFilterInput from './filters/GridFilterInput';
import GridTerytFilters from './filters/GridTerytFilters';
import { ProfiBazaColumnProps } from './GridHelper';

export class ProfiBazaColumnExtender<T> {
	private gridName: GridNameType;
	private columnsSelector: boolean;
	private hidePersonalizationSettings: boolean;
	private filterByTeryt: boolean;
	private showClearFilterButton: boolean;

	constructor(
		gridName: GridNameType,
		columnsSelector: boolean | undefined,
		hidePersonalizationSettings: boolean | undefined,
		filterByTeryt: boolean | undefined,
		showClearFilterButton: boolean | undefined
	) {
		this.gridName = gridName;
		this.columnsSelector = columnsSelector ? columnsSelector : false;
		this.hidePersonalizationSettings = hidePersonalizationSettings ?? false;
		this.filterByTeryt = filterByTeryt ?? false;
		this.showClearFilterButton = showClearFilterButton ?? false;
	}

	public getActionButtons(
		gridSettings: GridSettingsDto | undefined,
		columns: ProfiBazaColumnProps<T>[],
		search?: () => void
	) {
		if (columns === undefined || columns.length === 0) return <></>;
		return (
			<Space className="actions-container">
				{this.columnsSelector === true && (
					<ColumnSelector
						allColumns={columns}
						gridName={this.gridName}
						gridSettings={gridSettings}
					/>
				)}

				{search !== undefined &&
					columns.some((a) => a.filter !== undefined) && (
						<GridClearFilters
							gridName={this.gridName}
							handleSearch={search}
						/>
					)}

				{search !== undefined &&
					!columns.some((a) => a.filter !== undefined) &&
					this.showClearFilterButton && (
						<GridClearFilters
							gridName={this.gridName}
							handleSearch={search}
						/>
					)}

				{!this.hidePersonalizationSettings && (
					<GridPersonalizationSettings
						gridName={this.gridName}
						gridSettings={gridSettings}
					/>
				)}

				{this.filterByTeryt && search !== undefined && (
					<GridTerytFilters
						gridName={this.gridName}
						handleSearch={search}
					/>
				)}
			</Space>
		);
	}

	public applyFiltersAndSorter(
		columns: ProfiBazaColumnProps<T>[],
		antSorter: SorterResult<T>,
		search: () => void,
		isColumnFiltered: (columnKey: Key) => boolean
	): ProfiBazaColumnProps<T>[] {
		if (columns === undefined || columns.length === 0) return [];

		let columnsWithFiltersProps: ProfiBazaColumnProps<T>[] = [];

		columns.forEach((column) => {
			let columnWithFilterProps: ProfiBazaColumnProps<T>;

			if (column.filter !== undefined) {
				columnWithFilterProps = {
					...column,
					...this.getColumnFilterProps(
						column,
						search,
						isColumnFiltered
					),
				};
			} else {
				columnWithFilterProps = { ...column };
			}

			if (column.key === antSorter?.columnKey) {
				columnWithFilterProps.sortOrder = antSorter?.order;
			} else {
				columnWithFilterProps.sortOrder = null;
			}

			if (columnWithFilterProps.children) {
				columnWithFilterProps.children = this.applyFiltersAndSorter(
					columnWithFilterProps.children,
					antSorter,
					search,
					isColumnFiltered
				);
			}

			columnsWithFiltersProps.push(columnWithFilterProps);
		});

		return columnsWithFiltersProps;
	}

	private getColumnFilterProps = (
		column: ProfiBazaColumnProps<T>,
		search: () => void,
		isColumnFiltered: (columnKey: Key) => boolean
	) => ({
		filterDropdown: (filterProps: FilterDropdownProps): ReactNode => {
			return (
				<div style={{ padding: 8 }} className="filter-dropdown">
					{this.getFilterDropdownInput(column, filterProps, search)}
				</div>
			);
		},
		filterIcon: () => (
			<FilterButton
				id={`filterColumn-${column.key}`}
				filtered={isColumnFiltered(column.key!)}
			/>
		),
	});

	private getFilterDropdownInput(
		column: ProfiBazaColumnProps<T>,
		filterProps: FilterDropdownProps,
		search: () => void
	): ReactNode {
		if (column.filter === 'contains' || column.filter === 'equals') {
			return (
				<GridFilterInput
					column={column}
					filterProps={filterProps}
					handleSearch={() => {
						search();
					}}
					gridName={this.gridName}
				/>
			);
		} else if (column.filter === 'dictionary') {
			if (column.filterDictionary === undefined)
				throw 'Typ słownika jest wymagany w przypadku użycia filtra słownikowego';

			return (
				<GridFilterDictionarySelect
					column={column}
					filterProps={filterProps}
					handleSearch={() => {
						search();
					}}
					gridName={this.gridName}
				/>
			);
		} else if (column.filter === 'dateRange') {
			return (
				<GridFilterDateRange
					column={column}
					filterProps={filterProps}
					handleSearch={() => {
						search();
					}}
					gridName={this.gridName}
				/>
			);
		} else {
			throw `Filtr ${column.filter} nie jest obsługiwany`;
		}
	}
}
