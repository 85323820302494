import { InterventionCommentListItemDtoPagedResult } from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import formatHelpers, { DateFormat } from 'helper/formatHelpers';
import moment, { Moment } from 'moment';

export class InterventionCommentsCommand {
	comments: InterventionCommentDto[];
	more: boolean;
	pagination: PaginationInterventionCommentsCommand;
	constructor(
		comment: ProfiBazaAPIModels.InterventionCommentListItemDto[],
		pagination: PaginationInterventionCommentsCommand
	) {
		this.comments = comment.map(
			(x, index) => new InterventionCommentDto(x, index)
		)!;
		this.pagination = pagination;
		this.more = true;
	}
}

export class AuthorCommand {
	id: string;
	name: string;
	intervention: string;
	constructor(id: string, name: string, intervention: string) {
		this.id = id;
		this.name = name;
		this.intervention = intervention;
	}
}

export class InterventionCommentDto {
	author: AuthorCommand;
	body: string;
	top: number;
	id?: string;
	status: ProfiBazaAPIModels.InterventionCommentStatus | undefined;
	createdAt?: Moment | undefined;
	updatedAt?: Moment | undefined;
	createdAtFormatted?: string | null;
	updatedAtFormatted?: string | null;
	type: ProfiBazaAPIModels.InterventionCommentType | undefined;
	fieldName?: string;
	createdByAotmit?: boolean;
	interventionSubjectId?: string;
	interventionCoownersSubjectIds?: string[];
	activityId?: string;
	activityNumber?: string;
	activityPopulationId?: string;
	activitySponsoringCompanyId?: string;
	activityTabName?: string;
	constructor(
		comment: ProfiBazaAPIModels.InterventionCommentListItemDto,
		index: number
	) {
		this.author = new AuthorCommand(
			comment.createdBySubjectId!,
			`${comment.createdBy}`,
			comment.createdBySubject!
		);
		this.body = comment.description!;
		this.fieldName = comment.fieldName;
		this.top = index;
		this.id = comment.id;
		this.type = comment.commentType;
		this.status = comment.status;
		this.createdAt = comment?.createdAt
			? moment(comment?.createdAt)
			: undefined;
		this.updatedAt = comment?.updatedAt
			? moment(comment.updatedAt)
			: undefined;
		this.createdAtFormatted = formatHelpers.formatDate(
			comment?.createdAt,
			DateFormat.DateTime
		);
		this.updatedAtFormatted = formatHelpers.formatDate(
			comment?.updatedAt,
			DateFormat.DateTime
		);
		this.createdByAotmit = comment.createdByAotmit;
		this.interventionSubjectId = comment.interventionSubjectId;
		this.interventionCoownersSubjectIds = comment.interventionCoownersSubjectIds;
		this.activityId = comment.activityId;
		this.activityNumber = comment.activityNumber;
		this.activityPopulationId = comment.activityPopulationId;
		this.activitySponsoringCompanyId = comment.activitySponsoringCompanyId;
		this.activityTabName = comment.activityTabName;
	}
}

export class PaginationInterventionCommentsCommand {
	total: number;
	current: number;
	pageSize: number;
	hasNextPage: boolean;
	constructor(model?: InterventionCommentListItemDtoPagedResult) {
		this.total = model?.rowCount!;
		this.pageSize = model?.pageSize!;
		this.current = model?.currentPage!;
		this.hasNextPage = model?.hasNextPage!;
	}
}
