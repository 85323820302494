import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Col } from 'antd';
import Authorized from 'authorization/authorized';
import { interventionTableColumns } from 'components/intervention/table/InterventionTableColumns';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import RenderActions from 'components/statements/table/RenderActions';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { PublicHealthInterventionStatus } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

interface IProps {}

const DashboardInterventions: React.FC<IProps> = (props) => {
	const [data, setData] = useState<
		ProfiBazaAPIModels.InterventionListDtoPagedResult
	>();

	const [subjectId, setSubjectId] = useState<string | undefined>();

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const cols = interventionTableColumns
		.filter((x) => !x.defaultHidden)
		.map((interventionColumn) => ({
			...interventionColumn,
			sorter: false,
		}));

	const tableColumns: ProfiBazaColumnProps<
		ProfiBazaAPIModels.InterventionListDto
	>[] = [
		...cols,
		RenderActions<ProfiBazaAPIModels.InterventionListDto>(
			(text, record) => (
				<Fragment>
					<PreviewItemTableButton {...record} />
					<EditInterventionTableButton {...record} />
				</Fragment>
			)
		),
	];

	const PreviewItemTableButton: React.FC<ProfiBazaAPIModels.InterventionListDto> = (
		props
	) => {
		return (
			<ProfiBazaTooltip placement="top" title="Podgląd">
				<TableActionButton
					label="Podgląd"
					kind="DETAILS"
					linkTo={
						(props.patternStatus
							? '/rizp/pattern/details/'
							: '/rizp/details/') + props.id
					}
				/>
			</ProfiBazaTooltip>
		);
	};

	const EditInterventionTableButton: React.FC<ProfiBazaAPIModels.InterventionListDto> = (
		props
	) => {
		return (
			<Fragment>
				{props.status != PublicHealthInterventionStatus.CANCELED &&
					(props.coowners?.includes(subjectId!) ||
						props.subjectId === subjectId) && (
						<Fragment>
							{props.status !==
								PublicHealthInterventionStatus.ARCHIVED &&
								props.status !=
									PublicHealthInterventionStatus.SUSPENDED &&
								props.status !=
									PublicHealthInterventionStatus.SENDEDTOOPINIONAOTMIT &&
								props.status !=
									PublicHealthInterventionStatus.COMPLETEDUNREALIZED &&
								!props.patternStatus && (
									<Authorized
										permission={
											ProfiBazaAPIModels.Permission
												.RIZPModifications
										}
									>
										<ProfiBazaTooltip
											placement="top"
											title="Edytuj"
										>
											<TableActionButton
												label="Edytuj"
												kind="EDIT"
												linkTo={
													'/rizp/edit/' + props.id
												}
											/>
										</ProfiBazaTooltip>
									</Authorized>
								)}
						</Fragment>
					)}
			</Fragment>
		);
	};

	useEffect(() => {
		const account = accountDetailsStore.account.get();

		if (account?.subject?.id) {
			setIsLoading(true);
			const subjectId = account?.subject?.id as string;
			setSubjectId(subjectId);

			getProfiBazaApiClient()
				.then((api) =>
					api.intervention.all({
						page: 1,
						pageSize: 5,
						filters: `subjectId==${subjectId}`,
						sorts: '-modifiedDate',
					})
				)
				.then(
					(
						result: ProfiBazaAPIModels.InterventionListDtoPagedResult
					) => {
						setData(result);
					}
				)
				.finally(() => setIsLoading(false));
		}
	}, []);

	return (
		<CenteredRow>
			<Col span={23}>
				<ProfiBazaTable
					loading={isLoading}
					columns={tableColumns}
					dataSource={data?.results}
					pagination={false}
					detailsUrl={'/rizp/details'}
					rowKey={(r: ProfiBazaAPIModels.InterventionListDto) =>
						r.id!
					}
				/>
			</Col>
		</CenteredRow>
	);
};

export default DashboardInterventions;
