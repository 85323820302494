import { Drawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import React from 'react';

const FocusTrap = require('focus-trap-react');

export interface AccessibleDrawerProps extends DrawerProps {
	id?: string;
	focusTrapActive: boolean;
}

const AccessibleDrawer: React.FC<React.PropsWithChildren<
	AccessibleDrawerProps
>> = (props) => {
	const { id, children, focusTrapActive, ...drawerProps } = props;

	const renderChildren = () => <div id={id}>{children}</div>;

	return (
		<Drawer {...drawerProps}>
			{focusTrapActive ? (
				<FocusTrap
					focusTrapOptions={{
						returnFocusOnDeactivate: true,
						allowOutsideClick: true,
					}}
				>
					{renderChildren()}
				</FocusTrap>
			) : (
				renderChildren()
			)}
		</Drawer>
	);
};

AccessibleDrawer.defaultProps = {
	destroyOnClose: true,
};

export default AccessibleDrawer;
