import { AreaCodeNames } from 'components/shared/teryt/TerytAreaPicker/TeryAreaMappers';
import { FormikProps } from 'formik';
import { ViewModel } from 'mobx-utils';
import { Moment } from 'moment';
import React from 'react';
import { createContext } from 'react';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { InterventionCommand } from '../model/InterventionCommand';

export type InterventionPathContextType = {
	data: InterventionCommand | undefined;
	formik: FormikProps<InterventionCommand> | undefined;
	editable: boolean;
	pattern: InterventionCommand | undefined;
	unchangedData: InterventionCommand | undefined;
	checkChanges?: (value: any, key: string) => void | undefined;
	setValidTerytAreaLvl?: (code: AreaCodeNames) => void | undefined;
	isPattern: boolean;
	filterInterventionAreaForPattern: (
		value: ProfiBazaAPIModels.DictionaryValueItemDto
	) => boolean;
	setIsAotmit?: (value: React.SetStateAction<boolean>) => void | undefined;
	checkIfObjectValue?: (object: any, key?: any) => boolean;
	checkIfDateRangeValue?: (object: any, key?: any) => boolean;
	validTerytAreaLvl: AreaCodeNames | undefined;
	setCoownersVisible?: (value: boolean) => void;
	coownersVisible: boolean;
	coownerNames: string[];
	setCoownerNames?: (names: string[]) => void;
	isAotmit: boolean;
	setDateRange?: (value: [Moment?, Moment?] | undefined) => void;
	onChangeAreaTerytCodes?: (isAcitvitiesTabDisable: boolean) => void;
};

export const InterventionPathContext = createContext<
	InterventionPathContextType
>({
	data: undefined,
	formik: undefined,
	editable: false,
	pattern: undefined,
	unchangedData: undefined,
	checkChanges: undefined,
	setValidTerytAreaLvl: undefined,
	isPattern: false,
	filterInterventionAreaForPattern: () => false,
	setIsAotmit: undefined,
	checkIfDateRangeValue: undefined,
	checkIfObjectValue: undefined,
	setDateRange: undefined,
	validTerytAreaLvl: undefined,
	setCoownerNames: undefined,
	setCoownersVisible: undefined,
	coownerNames: [],
	coownersVisible: false,
	isAotmit: false,
	onChangeAreaTerytCodes: undefined,
});
