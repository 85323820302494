import { QuestionCircleOutlined } from '@ant-design/icons';
import PermissionValidator from '@authorization/permissionValidator';
import { useRootData } from '@hooks/hook';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { TooltipProps } from 'antd/lib/tooltip';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { ITooltipStore } from 'stores/TooltipStore';

import EditableTooltipContent, {
	IEditableTooltipContentReset,
} from './EditableTooltipContent';

type IconMargin = 'horizontal' | 'left' | 'right';

const getMarginClass = (marginType: IconMargin | undefined) => {
	switch (marginType) {
		case 'left':
			return 'ml-xxs';
		case 'right':
			return 'mr-xxs';
		case 'horizontal':
		default:
			return 'mh-xxs';
	}
};

export type LabelHelpTooltipPublicProps = {
	field: string;
	defaultValue: string;
	iconMargin?: IconMargin;
};

type LabelHelpTooltipProps = React.PropsWithChildren<
	LabelHelpTooltipPublicProps & Partial<TooltipProps>
>;

const LabelHelpTooltip: React.FC<LabelHelpTooltipProps> = (props) => {
	const {
		children,
		field,
		title,
		iconMargin,
		defaultValue,
		...restProps
	} = props;

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const tooltipStore: ITooltipStore = useRootData(
		(store) => store.tooltipStore
	);

	const permissionValidator = new PermissionValidator(
		accountDetailsStore!.account.get()!
	);

	const editable = permissionValidator.has(
		ProfiBazaAPIModels.Permission.TooltipManagement
	);

	const visible = editable || (!editable && !!tooltipStore.getField(field));
	const helpAreaId = `helpTooltipFor-${field}`;

	React.useEffect(() => {
		tooltipStore.initialize(field, defaultValue);
	}, []);

	const editableTooltipForm = useRef<IEditableTooltipContentReset>(null);

	const content = editable ? (
		<EditableTooltipContent
			ref={editableTooltipForm}
			field={field}
			initialValue={tooltipStore.getField(field)}
		/>
	) : (
		<span id={helpAreaId}>{tooltipStore.getField(field)}</span>
	);

	return (
		<>
			{visible && (
				<ProfiBazaTooltip
					title={content}
					trigger={
						editable
							? ['hover', 'click']
							: ['hover', 'focus', 'click']
					}
					mouseLeaveDelay={undefined}
					{...restProps}
					onVisibleChange={(visible) => {
						if (!visible) {
							editableTooltipForm?.current?.reset();
						}
					}}
				>
					{children ? (
						children
					) : (
						<QuestionCircleOutlined
							aria-label={tooltipStore.getField(field)}
							role="tooltip"
							aria-roledescription="podpowiedź"
							className={getMarginClass(iconMargin)}
							tabIndex={0}
						/>
					)}
				</ProfiBazaTooltip>
			)}
		</>
	);
};

LabelHelpTooltip.defaultProps = {
	iconMargin: 'horizontal',
};

export default observer(LabelHelpTooltip);
