/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/kindSubjectMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a KindSubject. */
export class KindSubject {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a KindSubject.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param subjectKind Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL',
   * 'CONTRACTOR', 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW',
   * 'IC', 'M', 'EDUCATIONALUNIT'
   * @param [options] The optional parameters
   * @returns Promise<Models.KindSubjectGetBySubjectKindResponse>
   */
  getBySubjectKind(subjectKind: Models.SubjectKind, options?: msRest.RequestOptionsBase): Promise<Models.KindSubjectGetBySubjectKindResponse>;
  /**
   * @param subjectKind Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL',
   * 'CONTRACTOR', 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW',
   * 'IC', 'M', 'EDUCATIONALUNIT'
   * @param callback The callback
   */
  getBySubjectKind(subjectKind: Models.SubjectKind, callback: msRest.ServiceCallback<Models.KindSubjectDto>): void;
  /**
   * @param subjectKind Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL',
   * 'CONTRACTOR', 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW',
   * 'IC', 'M', 'EDUCATIONALUNIT'
   * @param options The optional parameters
   * @param callback The callback
   */
  getBySubjectKind(subjectKind: Models.SubjectKind, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.KindSubjectDto>): void;
  getBySubjectKind(subjectKind: Models.SubjectKind, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.KindSubjectDto>, callback?: msRest.ServiceCallback<Models.KindSubjectDto>): Promise<Models.KindSubjectGetBySubjectKindResponse> {
    return this.client.sendOperationRequest(
      {
        subjectKind,
        options
      },
      getBySubjectKindOperationSpec,
      callback) as Promise<Models.KindSubjectGetBySubjectKindResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addRole(options?: Models.KindSubjectAddRoleOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addRole(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addRole(options: Models.KindSubjectAddRoleOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addRole(options?: Models.KindSubjectAddRoleOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      addRoleOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteRole(options?: Models.KindSubjectDeleteRoleOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteRole(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteRole(options: Models.KindSubjectDeleteRoleOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteRole(options?: Models.KindSubjectDeleteRoleOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteRoleOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getBySubjectKindOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "authorization/KindSubject/{subjectKind}",
  urlParameters: [
    Parameters.subjectKind
  ],
  responses: {
    200: {
      bodyMapper: Mappers.KindSubjectDto
    },
    default: {}
  },
  serializer
};

const addRoleOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "authorization/KindSubject/role",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.AddKindSubjectRoleCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteRoleOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "authorization/KindSubject/role",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeleteKindSubjectRoleCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
