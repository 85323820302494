import { WarningTwoTone } from '@ant-design/icons';
import { Select } from 'antd';
import { LabeledValue, SelectProps } from 'antd/lib/select';
import { ChangedVersionDictionaryDto } from 'components/intervention/model/InterventionBaseCommand';
import { FieldProps } from 'formik';
import { ErrorPopover } from 'forms/ErrorPopover';
import { IFSelectProps, IFormikInputProps } from 'forms/FormikFormItems';
import { ajaxCatch } from 'helper/api';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { inject, observer } from 'mobx-react';
import { Moment } from 'moment';
import React, { createRef } from 'react';
import { findDOMNode } from 'react-dom';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { DictionaryValueItemDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { getOtherValueName } from './DictionaryPicker';
import OtherValueModal, {
	IOtherValueModal,
} from './DictionaryPicker/OtherValueModal';

interface IFSelectDictionaryProps {
	codeName: string;
	valueId?: number;
	otherValue?: string;
	otherName?: string;
	changeData?: (key: any, value?: any, parent?: any) => void;
	dictionary: ProfiBazaAPIModels.DictionaryInfoDto | undefined;
	onChangeCallback?: (item: DictionaryValueItemDto) => void;
	onChangeEnd?: () => void;
	dateRange?: [(Moment | undefined)?, (Moment | undefined)?] | undefined;
	changedVersion?: ChangedVersionDictionaryDto | undefined;
	filter?: (value: DictionaryValueItemDto) => boolean;
	disabledCodeName?: string[];
	setChanged: (state: boolean) => void;
	previewValues: DictionaryValueItemDto[];
}

interface IFSelectDictionaryState {
	dictionary: ProfiBazaAPIModels.DictionaryInfoDto | undefined;
	showTextField: boolean;
	value: any;
	deprecatedValueUpdated: boolean;
}

export class FSelectDictionary extends React.Component<
	IFSelectProps &
		FieldProps<any> &
		IFSelectDictionaryProps &
		SelectProps<any>,
	IFSelectDictionaryState
> {
	otherValueModalRef: React.RefObject<IOtherValueModal>;

	constructor(
		props: IFSelectProps & FieldProps<any> & IFSelectDictionaryProps
	) {
		super(props);
		this.state = {
			dictionary: {},
			showTextField: false,
			value: undefined,
			deprecatedValueUpdated: false,
		};

		this.otherValueModalRef = React.createRef<IOtherValueModal>();
	}
	componentDidMount() {
		this.getValue();
	}

	private ref = createRef<HTMLSelectElement>();

	componentDidUpdate(
		oldProps: IFSelectProps &
			IFormikInputProps &
			IFSelectDictionaryProps &
			SelectProps<any>
	) {
		if (
			this.ref.current &&
			(findDOMNode(this.ref.current!) as HTMLElement)
		) {
			(findDOMNode(this.ref.current!) as HTMLElement).removeAttribute(
				'aria-label'
			);
		}
		if (
			oldProps.dictionary?.items !== this.props.dictionary?.items ||
			oldProps.field.value != this.props.field.value
		) {
			this.getValue();
		}

		//Map deprecated values to a new dictionary version if code names match
		if (this.props.field.value && this.props.onChangeCallback) {
			const foundValue = this.props.dictionary?.items?.find(
				(item) => item.id === this.props.field.value
			);
			foundValue && this.props.onChangeCallback(foundValue);
		}
	}

	mapItemValueInMultipleMode(
		item: ProfiBazaAPIModels.DictionaryValueItemDto
	) {
		if (
			!this.props.valueId ||
			!this.props.otherValue ||
			!this.props.field.value
		)
			return '';

		if (this.props.otherValue && Array.isArray(this.props.field.value)) {
			const other = (this.props.field.value as any[]).find(
				(x) => x[this.props.valueId!] === item.id
			);
			if (!other) return '';
			return getOtherValueName(item.value!, other[this.props.otherValue]);
		}
		return this.props.field.value[this.props.valueId!];
	}

	getOtherValue(
		otherValue: ProfiBazaAPIModels.DictionaryValueItemDto
	): string | undefined {
		if (otherValue) {
			if (this.props.otherValue) {
				const other = this.props.form.values[this.props.otherValue];
				return other
					? getOtherValueName(otherValue.value!, other ?? 'brak')
					: undefined;
			} else return otherValue.value;
		}
		return undefined;
	}

	getValue() {
		if (
			this.props.changedVersion &&
			this.props.changedVersion.oldId !== this.props.changedVersion.newId
		) {
			this.props.setChanged(true);
		}

		if (this.props.field.value && this.props.dictionary?.items) {
			if (Array.isArray(this.props.field.value) && this.props.valueId) {
				const deprecatedMap = new Map<number, string | undefined>();
				const deprecatedValues: number[] = this.props.field.value
					.filter(
						(formikItem) =>
							!this.props.dictionary?.items?.some(
								(item) =>
									item.id === formikItem[this.props.valueId!]
							)
					)
					.map((item) => {
						deprecatedMap.set(
							item[this.props.valueId!],
							item[this.props.otherValue ?? '']
						);
						return item[this.props.valueId!];
					});
				if (deprecatedValues.length) {
					ajaxCatch(() =>
						getProfiBazaApiClient().then((api) =>
							api.dictionaryValue
								.getValuesByIds({ ids: deprecatedValues })
								.then(
									(
										response: ProfiBazaAPIModels.DictionaryValueItemDto[]
									) => {
										const itemsMap = new Map(
											this.props.dictionary?.items?.map(
												(item) => [item.codeName, item]
											)
										);
										const updatedValues = response
											.map((item) => {
												const foundItem = itemsMap.get(
													item.codeName
												);
												const id = foundItem?.id;
												if (
													!id ||
													(foundItem?.canAddTextValue &&
														!deprecatedMap.get(id))
												)
													return undefined;
												const result: any = {
													[this.props.valueId!]: id,
												};
												if (this.props.otherValue)
													result[
														this.props.otherValue
													] = deprecatedMap.get(id);

												result.value = item.value;
												result.codeName = item.codeName;
												return result;
											})
											.filter(
												(item) => item !== undefined
											);

										if (!this.props.readOnly) {
											this.props.form.setFieldValue(
												this.props.field.name,
												updatedValues
											);

											this.setState((state) => ({
												...state,
												deprecatedValueUpdated: true,
											}));

											this.props.setChanged(true);
										}
									}
								)
						)
					);
				}
			} else {
				const isValueAvailable = this.props.dictionary.items.some(
					(item) => item.id === this.props.field.value
				);
				if (!isValueAvailable && !this.props.disabled) {
					ajaxCatch(() =>
						getProfiBazaApiClient().then((api) =>
							api.dictionaryValue
								.getById(this.props.field.value)
								.then(
									(
										response: ProfiBazaAPIModels.DictionaryValueItemDto
									) => {
										const foundValue = this.props.dictionary?.items?.find(
											(x) =>
												x.codeName === response.codeName
										);
										this.props.form.setFieldValue(
											this.props.field.name,
											foundValue?.id
										);
										this.setState((state) => ({
											...state,
											deprecatedValueUpdated: true,
										}));
										this.props.setChanged(true);
									}
								)
						)
					);
				}
			}
		}
	}

	public render() {
		const {
			dictionary,
			codeName,
			otherValue,
			field,
			form,
			valueId,
			changeData,
			otherName,
			onChangeCallback,
			onChangeEnd,
			dateRange,
			changedVersion,
			disabledCodeName,
			...restProps
		} = this.props;

		const getFieldValue = (originalValue: any): string | string[] => {
			const isArray = Array.isArray(originalValue);
			
			let identifierPropertyName = this.props.valueId ?? 'id';

			if (this.props.readOnly || this.props.disabled) {
				if (this.props.previewValues) {
					return this.props.previewValues.map((x) => x.value!);
				} else {
					identifierPropertyName = this.props.otherName ?? 'value';
				}
			}

			if (isArray) {
				return originalValue.map((x: any) => {
					return x[identifierPropertyName];
				});
			}

			return originalValue;
		};

		const input = (error?: string) => {
			return (
				(dictionary || this.props.readOnly) && (
					<Select
						optionLabelProp="label"
						allowClear={this.props.mode !== 'multiple'}
						placeholder="Wybierz"
						{...restProps}
						id={this.props.field.name}
						optionFilterProp="children"
						aria-label={this.props.label}
						aria-owns={this.props.field.name}
						aria-controls={this.props.field.name}
						aria-activedescendant={this.props.field.name}
						showArrow
						listHeight={300}
						ref={this.ref}
						value={getFieldValue(field.value)}
						disabled={this.props.disabled}
						onBlur={() => {
							this.props.form.setFieldTouched(
								this.props.field.name,
								true
							);
						}}
						onChange={(value: string | number | any[], option) => {
							if (
								this.props.onChangeAttempt === undefined ||
								this.props.onChangeAttempt(value as string)
							) {
								if (this.props.mode !== 'multiple') {
									this.props.form.setFieldValue(
										this.props.field.name,
										value
									);
								}
								this.props.otherName &&
									this.props.form.setFieldValue(
										this.props.otherName,
										dictionary?.items?.find(
											(x) => x.id == (value as any)
										)?.value
									);

								this.props.form.setFieldTouched(
									this.props.field.name,
									true
								);
							}
							if (changeData) {
								changeData(value, option);
							}
							onChangeEnd && onChangeEnd();
						}}
						onSelect={(
							value: string | number | LabeledValue,
							option: any
						) => {
							if (this.props.mode === 'multiple') {
								const oldValues = field.value ?? [];

								if (valueId && !option.canAddTextValue) {
									this.props.form.setFieldValue(
										this.props.field.name,
										[
											...oldValues,
											{
												[valueId]: value,
												[this.props.otherName
													? this.props.otherName
													: '']: option.children,
												['codeName']: option.codeName,
											},
										]
									);
								}
							}

							if (option.canAddTextValue) {
								this.otherValueModalRef.current?.showModal({
									id: option.value,
									value: option.children,
								});
							} else {
								otherValue &&
									form.setFieldValue(otherValue, null);
							}
						}}
						onDeselect={(value: any, data) => {
							if (this.props.mode === 'multiple') {
								const oldValues: any[] = field.value ?? [];
								if (valueId !== undefined) {
									const filteredValues = oldValues.filter(
										(x) => {
											return x[valueId!] !== value;
										}
									);
									this.props.form.setFieldValue(
										this.props.field.name,
										filteredValues
									);
								}
							} else {
								otherValue &&
									form.setFieldValue(otherValue, null);
							}
						}}
					>
						{dictionary?.items &&
							(this.props.filter !== undefined
								? dictionary.items.filter((x) =>
										this.props.filter!(x)
								  )
								: dictionary.items
							)?.map(
								(
									p: ProfiBazaAPIModels.DictionaryValueItemDto
								) => {
									return (
										<>
											<Select.Option
												key={p.id}
												aria-label={p.value}
												value={p.id!}
												disabled={
													!p.canBeSelected ||
													(this.props
														.disabledCodeName &&
														this.props.disabledCodeName.includes(
															p.codeName!
														))
												}
												label={
													p.canAddTextValue
														? this.props.mode ===
														  'multiple'
															? this.mapItemValueInMultipleMode(
																	p
															  )
															: this.getOtherValue(
																	p
															  )
														: p.value
												}
												canAddTextValue={
													p.canAddTextValue
												}
												codeName={p.codeName}
											>
												{p.value}
											</Select.Option>
										</>
									);
								}
							)}
					</Select>
				)
			);
		};

		return (
			<>
				<ErrorPopover field={this.props.field} form={this.props.form}>
					{input()}
				</ErrorPopover>

				<OtherValueModal
					ref={this.otherValueModalRef}
					form={this.props.form}
					field={this.props.field}
					multiple={this.props.mode === 'multiple'}
					setValues={() => {}}
					checkChanges={changeData}
					valueId={valueId}
					otherName={this.props.otherValue}
					valueName={this.props.otherName}
					singleLevel
				/>
			</>
		);
	}
}
