import { RestResponse } from '@azure/ms-rest-js';
import { IsFileSizeValid } from '@components/shared/file/IsFileSizeValid';
import RODOManualModal, {
	IRODOManualModal,
} from '@components/userManual/modals/RODOManualModal';
import * as ProposalAccessType from '@services/mappers/ProposalAccessType';
import { RODOManualType, UserManualType } from '@services/src/models';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Col, Modal, Select } from 'antd';
import SubjectsTableModal from 'components/shared/SubjectsTableModal';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FFieldLabel,
	FInput,
	FInputFile,
	FSelect,
	FSelectSubjects,
} from 'forms/FormikFormItems';
import { ajaxByUser } from 'helper/api';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useRef, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';
import * as Yup from 'yup';

import {
	ProposalAccessCommand,
	file_extensions,
} from './model/ProposalAccessCommand';

interface IProps {
	visible: boolean;
	setVisible: (value: boolean) => void;
}

interface IState {
	value: ProposalAccessCommand;
	actions: FormikActions<ProposalAccessCommand>;
}

let fileType: string = '';

const ProposalAccessValidationSchema = Yup.object().shape({
	email: Yup.string()
		.email('Wpisz poprawny email')
		.required('E-mail jest wymagany.'),
	proposalAccesType: Yup.string().required(
		'Rodzaj wnioskowanego dostępu jest wymagany.'
	),
	subjectName: Yup.string().required('Podmiot jest wymagany.'),
	file: Yup.string().test(
		'fileType',
		'Złe rozszerzenie załącznika',
		(value) => !value || file_extensions.includes(fileType)
	),
});

const ProposalCreate = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>(false);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const elRef = useRef<Formik<ProposalAccessCommand>>(null);

	const [file, setFile] = useState<File>();
	const [state, setState] = useState<IState>();

	const rodoManualModalRef = useRef<IRODOManualModal>() as React.RefObject<
		IRODOManualModal
	>;

	const createProposal = (
		value: ProposalAccessCommand,
		actions: FormikActions<ProposalAccessCommand>
	): void => {

		if(file) {
			if(!IsFileSizeValid(file!)) return;	
		}

		ajaxByUser('Utworzono wniosek', () =>
			getProfiBazaApiClient()
				.then((api) =>
					api.proposal.create({
						email: value.email,
						subjectId: value.subjectId,
						proposalAccesType: value.proposalAccesType,
						file: file,
					})
				)
				.then((proposal: RestResponse) => {
					setVisible(false);
					gridStore.searching.set(true);
					props.setVisible(false);
					setFile(undefined);
				})
		).then(() => {
			actions.setSubmitting(false);
			actions.resetForm();
		});
	};

	const onSubmit = (): void => {
		if (state?.value && state.actions) {
			createProposal(state?.value, state?.actions);
		}
	};

	return (
		<>
			<CenteredRow>
				<Col span={18}>
					<Formik
						validateOnChange={false}
						validateOnBlur={true}
						initialValues={{
							firstName: accountDetailsStore.account.get()
								?.firstName,
							lastName: accountDetailsStore.account.get()
								?.lastName,
							proposalAccesType:
								ProfiBazaAPIModels.ProposalAccessType.Normal,
						}}
						validationSchema={ProposalAccessValidationSchema}
						enableReinitialize
						ref={elRef}
						onSubmit={(value, actions) => {
							setState({
								value: value,
								actions: actions,
							});
							rodoManualModalRef.current?.showModal({
								manualType: UserManualType.RODO,
								rodoManualType: RODOManualType.ProposalAccess,
							});
						}}
						render={(
							formik: FormikProps<ProposalAccessCommand>
						) => (
							<Modal
								visible={props.visible}
								destroyOnClose
								centered
								maskClosable={false}
								width={'50%'}
								title={'Tworzenie nowego wniosku'}
								onOk={() => {
									formik.submitForm();
								}}
								okButtonProps={{
									disabled: formik.isSubmitting,
								}}
								cancelButtonProps={{
									disabled: formik.isSubmitting,
								}}
								onCancel={() => {
									props.setVisible(false);
									elRef.current!.resetForm();
									setFile(undefined);
								}}
								okText={'Zatwierdź'}
							>
								<CenteredRow>
									<Col span={20}>
										<FFieldLabel label="Rodzaj wnioskowanego dostępu" />
										<Field
											label="Rodzaj wnioskowanego dostępu"
											component={FSelect}
											name="proposalAccesType"
											aria-owns="proposalAccesType"
											aria-controls="proposalAccesType"
											aria-activedescendant="proposalAccesType"
											changeData={() => {
												formik.setFieldValue(
													'subjectId',
													undefined
												);
												formik.setFieldValue(
													'subjectName',
													undefined
												);
											}}
											children={Array.from(
												ProposalAccessType.mapings.entries()
											).map((x, i) => (
												<Select.Option
													key={i}
													value={x[0]}
												>
													{x[1]}
												</Select.Option>
											))}
										/>
									</Col>
								</CenteredRow>
								<CenteredRow>
									<Col span={20}>
										<FFieldLabel label="Imię" />
										<Field
											label="Imię"
											component={FInput}
											readOnly
											name="firstName"
										/>
									</Col>
								</CenteredRow>
								<CenteredRow>
									<Col span={20}>
										<FFieldLabel label="Nazwisko" />
										<Field
											label="Nazwisko"
											component={FInput}
											readOnly
											name="lastName"
										/>
									</Col>
								</CenteredRow>
								<CenteredRow>
									<Col span={20}>
										<FFieldLabel label="Email" />
										<Field
											label="Email"
											component={FInput}
											name="email"
										/>
									</Col>
								</CenteredRow>
								<CenteredRow>
									<Col span={20}>
										<FFieldLabel label="Podmiot" />
										<Field
											label="Podmiot"
											component={FSelectSubjects}
											name="subjectName"
											aria-owns="subjectName"
											aria-controls="subjectName"
											aria-activedescendant="subjectName"
											onKeyUp={(
												e: React.KeyboardEvent<
													HTMLDivElement
												>
											) => {
												if (
													e.key === 'Enter' &&
													formik.values
														.proposalAccesType
												) {
													setVisible(true);
												}
											}}
											onClick={() => {
												if (
													formik.values
														.proposalAccesType
												) {
													setVisible(true);
												}
											}}
											dropDown
											readOnly={
												!formik.values.proposalAccesType
											}
										/>
									</Col>
								</CenteredRow>
								<CenteredRow>
									<Col span={20}>
										<FFieldLabel label="Załącznik" />
										<Field
											label="Załącznik"
											component={FInputFile}
											name="file"
											accept=".jpg, .bmp, .pdf, .png, .jpeg"
											getFile={(file: File) => {
												fileType = file?.type;
												setFile(file);
											}}
										/>
									</Col>
								</CenteredRow>
								<SubjectsTableModal
									visible={visible}
									mode="radio"
									filters={`isActiveAdminAccount==${
										formik.values['proposalAccesType'] !==
										'Administration'
									}`}
									proposal={true}
									onCancel={() => {
										setVisible(false);
									}}
									acceptSubject={(value) => {
										setVisible(false);
										formik.setFieldValue(
											'subjectId',
											value?.id
										);
										formik.setFieldValue(
											'subjectName',
											value?.name
										);
									}}
									showImplementerSponsorFilter={true}
								/>
							</Modal>
						)}
					/>
				</Col>
			</CenteredRow>
			<RODOManualModal
				ref={rodoManualModalRef}
				onAccept={onSubmit}
				onCancel={() => elRef.current!.setSubmitting(false)}
			/>
		</>
	);
};
export default ProposalCreate;
