import * as signalR from '@microsoft/signalr';
import { IAccountDetailsStore } from 'account/accountDetailsStore';

export const getSignalRConnection = (
	accountDetailsStore: IAccountDetailsStore
) => {
	// @ts-ignore
	const baseURI = window._env_.REACT_APP_BASE_URI;
	const loggingEnabled =
		// @ts-ignore
		window._env_.REACT_APP_SIGNALR_LOGGING_ENABLED === '1';

	const connection = new signalR.HubConnectionBuilder()
		.withUrl(`${baseURI}/hubs/notifications`, {
			accessTokenFactory: () =>
				accountDetailsStore.oidcUser.get()?.access_token!,
		})
		.withAutomaticReconnect()
		.configureLogging(
			loggingEnabled ? signalR.LogLevel.Debug : signalR.LogLevel.None
		)
		.build();

	return connection;
};
