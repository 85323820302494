import React, { useState, useEffect } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { ColumnProps } from 'antd/lib/table';
import { Button, Modal } from 'antd';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { useRootData } from 'hooks/hook';
import arrayHelpers from 'helper/arrayHelpers';
import { ITableColumnStore, GridNameType } from 'stores/TableColumnStore';
import { ColumnTitle } from 'antd/lib/table/interface';
import DraggableProfiBazaTable from 'layout/DraggableProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { GridSettingsDto } from 'services/src/models';
import PermissionValidator from '@authorization/permissionValidator';

interface IProps {
	gridName: GridNameType;
	gridSettings: GridSettingsDto | undefined;
}

interface IDataNode {
	title: ColumnTitle<any>;
	key: string;
}

const GridPersonalizationSettings: React.FC<IProps> = (props) => {
	const { gridName, gridSettings } = props;

	const [isVisible, setVisible] = useState<boolean>(false);

	const [baseCheckedKeys, setBaseCheckedKeys] = useState<string[]>([]);
	const [baseOrderedVisibleKeys, setBaseOrderedVisibleKeys] = useState<string[]>([]);

	const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);
	
	const permissionValidator = new PermissionValidator(
		accountDetailsStore!.account.get()!
	);

	const [allColumns, setAllColumns] = useState<ColumnProps<any>[]>(
		tableColumnStore.getTable(gridName, permissionValidator)
	);

	const [dataNodes, setDataNodes] = useState<IDataNode[]>([]);
	let allColumnsFlatten: string[] = dataNodes.map((x) => x.key);

	useEffect(() => {
		setDataNodes([]);
		setCheckedKeys([]);
		setBaseCheckedKeys(gridSettings!.visibleColumnKeys! ?? [])
		setBaseOrderedVisibleKeys([...gridSettings!.visibleColumnKeys!]);
		setAllColumns(tableColumnStore.getTable(gridName, permissionValidator));
	}, [gridName]);

	useEffect(() => {
		let temp: IDataNode[] =
			allColumns && allColumns.length
				? allColumns
						.filter(
							(a) =>
								a.key !== 'actions' && a.dataIndex !== undefined
						)
						.map((column) => {
							return {
								disableCheckbox:
									column.key != null
										? checkedKeys.length <= 1 &&
										  checkedKeys.includes(
												column.key.toString()
										  )
										: false,
								title: column.title,
								key:
									column.key != null
										? column.key!.toString()
										: '',
							};
						})
				: [];

		if (dataNodes.length == 0) {
			arrayHelpers.sortByColumnReference(
				temp,
				gridSettings?.visibleColumnKeys ?? [],
				'key'
			);
		}
		setDataNodes(temp);
	}, [allColumns]);

	const showModal = () => {
		setVisible(true);
	};

	const revertColumnsVisibility = () =>
	{
		accountDetailsStore.saveVisibleColumns(
			gridName,
			baseCheckedKeys
		);
	}

	const revertColumnsOrder = () =>
	{
		arrayHelpers.sortByReference(allColumnsFlatten, baseOrderedVisibleKeys);

		accountDetailsStore.saveVisibleColumns(gridName!, baseOrderedVisibleKeys);
		tableColumnStore.applyOrder(gridName!, allColumnsFlatten);

		const data: IDataNode[] = [...dataNodes];
		arrayHelpers.sortByColumnReference(
			data,
			baseOrderedVisibleKeys,
			'key'
		)	
		setDataNodes(data);
	}

	const revertAndCloseModal = () => 
	{
		revertColumnsVisibility();
		revertColumnsOrder();
		
		setVisible(false);
	};

	const applyAndCloseModal = () =>
	{
		setBaseCheckedKeys(gridSettings?.visibleColumnKeys ?? []);
		setBaseOrderedVisibleKeys([...gridSettings!.visibleColumnKeys!]);

		setVisible(false);
	}

	const handleMove = (dragIndex: number, hoverIndex: number) => {
		const data: IDataNode[] = [...dataNodes];
		if (hoverIndex < 0) hoverIndex = 0;
		if (hoverIndex >= data.length) hoverIndex = data.length - 1;
		arrayHelpers.moveArrayElement(data, dragIndex, hoverIndex);
		allColumnsFlatten = data.map((x) => x.key);
		const visibleColumns = gridSettings!.visibleColumnKeys!;
		const orderedVisibleKeys = [...visibleColumns];
		arrayHelpers.sortByReference(orderedVisibleKeys, allColumnsFlatten);
		accountDetailsStore.saveVisibleColumns(gridName!, orderedVisibleKeys);
		tableColumnStore.applyOrder(gridName!, allColumnsFlatten);
		setDataNodes(data);
	};

	return (
		<>
			<ProfiBazaTooltip title="Pokaż/ukryj kolumny" placement="topRight">
				<Button
					aria-label="Pokaż/ukryj kolumny"
					type="ghost"
					size="small"
					shape="circle"
					icon={<SettingOutlined />}
					onClick={showModal}
				/>
			</ProfiBazaTooltip>
			<Modal
				title="Pokaż/ukryj kolumny"
				visible={isVisible}
				onOk={applyAndCloseModal}
				onCancel={revertAndCloseModal}
				destroyOnClose
			>
				{gridName && (
					<DraggableProfiBazaTable
						showHeader={false}
						handleMove={handleMove}
						columns={[
							{
								dataIndex: 'title',
								key: 'title',
							},
						]}
						rowSelection={{
							selectedRowKeys:
								gridSettings?.visibleColumnKeys ?? [],
							onChange: (selectedRowKeys: string[]) => {
								arrayHelpers.sortByReference(
									selectedRowKeys,
									allColumnsFlatten
								);
								setCheckedKeys(checkedKeys);
								accountDetailsStore.saveVisibleColumns(
									gridName,
									selectedRowKeys
								);
							},
						}}
						pagination={false}
						dataSource={dataNodes}
						setDataSource={setDataNodes}
						rowKey={(record: IDataNode) => record.key}
					/>
				)}
			</Modal>
		</>
	);
};

export default observer(GridPersonalizationSettings);
