import * as Yup from 'yup';

const requiredMessage: string = 'Wartość jest wymagana';

export const SubjectReportingProcessValidationSchema = () => 
{
	let thisYear = new Date().getFullYear() - 1;
	let submissionFromYearMax = thisYear + 4;
	let submissionFormYearValidationMessage = `Można wprawadzić tylko lata z przedziału ${thisYear} - ${submissionFromYearMax}`;

	return Yup.object().shape(
		{
			subjectName: Yup.string().when('subjectKind', {
				is: (subjectKind) => !subjectKind,
				then: Yup.string().required(requiredMessage),
				otherwise: Yup.string().nullable(),
			}),
			subjectKind: Yup.string().when('subjectName', {
				is: (subjectName) => !subjectName,
				then: Yup.string().required(requiredMessage),
				otherwise: Yup.string().nullable(),
			}),
			process: Yup.string().required(requiredMessage),
			valid: Yup.array().test(
				'my-test',
				'Data ważności od jest wymagana',
				function (value) {
					return this.parent.validFrom;
				}
			),
			submissionDate: Yup.date().test('my-test', requiredMessage, function (
				value
			) {
				return this.parent.submissionDay && this.parent.submissionMonth;
			}),
			submissionCycleLength: Yup.number().required(requiredMessage),
			submissionFromYear: Yup.number().required(requiredMessage)
											.max(submissionFromYearMax, submissionFormYearValidationMessage)
											.min(thisYear, submissionFormYearValidationMessage),
			description: Yup.string()
				.nullable()
				.max(256, 'Opis nie może być dłuższy niż 256 znaków'),
		},
		[['subjectName', 'subjectKind']]
	);
}


