import * as Yup from 'yup';

export const annualNumberOfRecipientsValidationSchema = () =>
	Yup.object().shape({
		year: Yup.number()
			.required('Wartość jest wymagana')
			.min(2016, 'Wartość nie może być mniejsza niż 2016')
			.max(2100, 'Wartość nie może być większa niż 2100'),
		numberOfRecipients: Yup.number()
			.required('Wartość jest wymagana')
			.min(0, 'Wartość musi być dodatnia')
            .max(35000000, 'Wartość nie może być większa niż 35000000'),
	});
