/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/calendarEventsMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a CalendarEvents. */
export class CalendarEvents {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a CalendarEvents.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CalendarEventsGetCalendarEventsResponse>
   */
  getCalendarEvents(options?: Models.CalendarEventsGetCalendarEventsOptionalParams): Promise<Models.CalendarEventsGetCalendarEventsResponse>;
  /**
   * @param callback The callback
   */
  getCalendarEvents(callback: msRest.ServiceCallback<Models.CalendarEventDTO[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCalendarEvents(options: Models.CalendarEventsGetCalendarEventsOptionalParams, callback: msRest.ServiceCallback<Models.CalendarEventDTO[]>): void;
  getCalendarEvents(options?: Models.CalendarEventsGetCalendarEventsOptionalParams | msRest.ServiceCallback<Models.CalendarEventDTO[]>, callback?: msRest.ServiceCallback<Models.CalendarEventDTO[]>): Promise<Models.CalendarEventsGetCalendarEventsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getCalendarEventsOperationSpec,
      callback) as Promise<Models.CalendarEventsGetCalendarEventsResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getCalendarEventsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "notifications/CalendarEvents",
  queryParameters: [
    Parameters.dateFrom0,
    Parameters.dateTo0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CalendarEventDTO"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};
