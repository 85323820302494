import { MessageOutlined } from '@ant-design/icons';
import { InterventionCommentsCommand } from '@components/intervention/comments/commands/InterventionCommentsCommand';
import { CommentsCommand } from '@components/statements/comments/Commands/CommentsCommand';
import { SubjectCommentsCommand } from '@components/subjects/comments/commands/SubjectCommentsCommand';
import { Badge, Button } from 'antd';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React from 'react';

interface IProps {
	showComments: () => void;
	commentData:
		| InterventionCommentsCommand
		| SubjectCommentsCommand
		| CommentsCommand;
	label: string;
}

const countUnprocessedComments = (
	commentData:
		| InterventionCommentsCommand
		| SubjectCommentsCommand
		| CommentsCommand
): number => {
	if (commentData?.comments) {
		return (commentData.comments as []).filter(
			(x: any) => x.status !== 'PROCESSED'
		).length;
	}
	return 0;
};

export const CommentButton: React.FC<IProps> = (props) => {
	return (
		<ProfiBazaTooltip title={props.label}>
			<Badge
				count={countUnprocessedComments(props.commentData)}
				className="comments-badge"
			>
				<Button
					aria-label={props.label}
					shape="circle"
					className="manual-menu-item"
					onClick={props.showComments}
					icon={<MessageOutlined />}
				/>
			</Badge>
		</ProfiBazaTooltip>
	);
};
