import { UploadOutlined } from '@ant-design/icons';
import { Col, Upload } from 'antd';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { IsRCFileSizeValid } from 'components/shared/file/IsFileSizeValid';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useRef, useState } from 'react';
import { DictionariesConstants } from 'services/DictionariesConstants';
import { InterventionPath } from 'services/src/models';

import SourceDocumentDetails, {
	ISourceDocumentDetails,
} from '../documents/SourceDocumentDetails';
import InterventionDocumentsCategorySelect from './InterventionDocumentsCategorySelect';

const { Dragger } = Upload;

interface IProps {
	interventionId: string;
	sourceDocumentId?: string;
	interventionPath?: InterventionPath;
	editable?: boolean;
	isPattern: boolean;
	refresh: () => void;
}

const InterventionDocuments: React.FC<IProps> = (props) => {
	const sourceDocumentDetailsRef = useRef<ISourceDocumentDetails>(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [fileOptions, setFileOptions] = useState<RcCustomRequestOptions>();

	const refreshDocumentDetailsRef = (): void => {
		sourceDocumentDetailsRef.current?.forceRefresh();
	};

	const fileUploadProps = {
		name: 'file',
		multiple: false,
		customRequest: (options: RcCustomRequestOptions) => {
			if (!IsRCFileSizeValid(options)) return;
			setFileOptions(options);
			setIsModalVisible(true);
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 1,
			format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
		},
		showUploadList: {
			showPreviewIcon: false,
			showDownloadIcon: false,
			showRemoveIcon: false,
		},
		accept:
			'.pdf, .xlsx, .xls, .doc, .docx, .jpg, .jpeg, .png, .gif, .rtf, .ppt, .txt',
	};

	return (
		<>
			{isModalVisible && fileOptions && (
				<InterventionDocumentsCategorySelect
					interventionId={props.interventionId}
					interventionPath={props.interventionPath}
					options={fileOptions}
					setSourceDocumentId={props.refresh}
					refreshDocumentDetailsRef={refreshDocumentDetailsRef}
					onDestroy={() => {
						setIsModalVisible(false);
					}}
					codeName={
						DictionariesConstants.AttachmentCategories
							.InterventionAttachmentCategory
					}
				/>
			)}
			{props.editable && (
				<CenteredRow>
					<Col span={20}>
						<div style={{ margin: '20px 0px' }}>
							<Dragger
								aria-label="Załącznik"
								{...fileUploadProps}
								showUploadList={false}
							>
								<p className="ant-upload-drag-icon">
									<UploadOutlined />
								</p>
								<p className="ant-upload-text">
									Przeciągnij i upuść plik tutaj aby dodać
									plik lub kliknij aby dodać pliki ręcznie
								</p>
							</Dragger>
						</div>
					</Col>
				</CenteredRow>
			)}
			{props.sourceDocumentId && (
				<SourceDocumentDetails
					ref={sourceDocumentDetailsRef}
					documentId={props.sourceDocumentId}
					interventionPath={props.interventionPath}
					baseUrl={`/rizp/${
						props.editable === true ? 'edit' : 'details'
					}/${props.interventionId}/documents`}
					backPath={props.isPattern ? '/rizp/patterns' : '/rizp'}
					onEmptyDocumentsTree={props.refresh}
					codeName={
						DictionariesConstants.AttachmentCategories
							.InterventionAttachmentCategory
                    }
                    span={18}
				/>
			)}
		</>
	);
};

export default InterventionDocuments;
