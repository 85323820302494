import './SubjectReportingProcess.less';

import { Col } from 'antd';
import AddButton from 'components/shared/buttons/AddButton';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import PaginatedProfiBazaTable from 'components/shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	SubjectReportingProcessListItemDto,
	SubjectReportingProcessStatus,
	UserListDto,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

import { SieveModel } from '../../shared/paginatedProfiBazaTable/SieveModels';
import SubjectReportingProcessModal from './SubjectReportingProcessModal';
import { SkipLinkContent } from '@components/skipLinks/SkipLinks';

interface IProps {}

interface IState {
	visible: boolean;
	selectedRecord: ProfiBazaAPIModels.SubjectReportingProcessDto | undefined;
	editable: boolean;
	mode: 'subject' | 'category' | undefined;
}
const SubjectReportingProcessTable: React.FC<IProps> = (props) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);
	const [state, setState] = useState<IState>({
		visible: false,
		selectedRecord: undefined,
		editable: false,
		mode: undefined,
	});

	const setSelectedRecord = (id: string, editable: boolean) => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.subjectReportingProcess.getById(id!))
				.then(
					(
						subjectReporting: ProfiBazaAPIModels.SubjectReportingProcessDto
					) => {
						setState({
							selectedRecord: subjectReporting,
							editable: editable,
							visible: true,
							mode: subjectReporting.subjectKind
								? 'category'
								: 'subject',
						});
					}
				)
		);
	};

	const columns: ProfiBazaColumnProps<UserListDto>[] = [
		...tableColumnStore.getTable('subjectReportingProcess'),
		RenderActions<ProfiBazaAPIModels.SubjectReportingProcessListItemDto>(
			(text, record) => (
				<>
					<ProfiBazaTooltip placement="top" title="Szczegóły">
						<TableActionButton
							kind="DETAILS"
							label="Szczegóły"
							onClick={() => {
								setSelectedRecord(record.id!, false);
							}}
						/>
					</ProfiBazaTooltip>

					{
						<ProfiBazaTooltip placement="top" title="Edytuj">
							<TableActionButton
								kind="EDIT"
								label="Edytuj"
								onClick={() => {
									setSelectedRecord(record.id!, true);
								}}
							/>
						</ProfiBazaTooltip>
					}

					{
						<ProfiBazaTooltip placement="top" title="Usuń">
							<AccessiblePopconfirm
								title={'Czy na pewno chcesz usunąć element?'}
								onConfirm={() => {
									ajaxCatch(() =>
										getProfiBazaApiClient()
											.then((api) =>
												api.subjectReportingProcess.deleteMethod(
													record.id!
												)
											)
											.then(() => {
												gridStore.searching.set(true);
											})
									);
								}}
							>
								<TableActionButton kind="DELETE" label="Usuń" />
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					}
					{record.status === SubjectReportingProcessStatus.ACTIVE ? (
						<ProfiBazaTooltip placement="top" title="Dezaktywuj">
							<AccessiblePopconfirm
								title={
									<>
										Czy na pewno chcesz dezaktywować{' '}
										{record.identifier}?
									</>
								}
								onConfirm={() => {
									ajaxByUser(
										'Dezaktywowano proces sprawozdawczy',
										() =>
											getProfiBazaApiClient()
												.then((api) =>
													api.subjectReportingProcess.deactivate(
														record.id!
													)
												)
												.then(() => {
													gridStore.searching.set(
														true
													);
												})
									);
								}}
							>
								<TableActionButton
									kind="DEACTIVATE"
									label="Dezaktywuj"
								/>
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					) : (
						<ProfiBazaTooltip placement="top" title="Aktywuj">
							<AccessiblePopconfirm
								title={
									<>
										Czy na pewno chcesz aktywować{' '}
										{record.identifier}?
									</>
								}
								onConfirm={() => {
									ajaxByUser(
										'Aktywowano proces sprawozdawczy',
										() =>
											getProfiBazaApiClient()
												.then((api) =>
													api.subjectReportingProcess.activate(
														{
															body: {
																id: record.id!,
															},
														}
													)
												)
												.then((x) => {
													gridStore.searching.set(
														true
													);
													return x;
												})
									);
								}}
							>
								<TableActionButton
									kind="ACTIVATE"
									label="Aktywuj"
								/>
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					)}
				</>
			)
		),
	];

	return (
		<>
			<CenteredRow style={{ marginTop: 32 }}>
				<Col span={5}>
					<AddButton
						tall
						onClick={() =>
							setState({
								...state,
								editable: true,
								visible: true,
							})
						}
					>
						Dodaj
					</AddButton>
				</Col>
				<Col span={13}>
					<PageHeaderWithContent
						title="Przypisanie  procesu sprawozdawczego do podmiotu"
						wideTitle={true}
					/>
				</Col>
				<Col span={5}></Col>
			</CenteredRow>
			<CenteredRow>
				<Col span={23}>
					<SubjectReportingProcessModal
						visible={state.visible}
						editable={state.editable}
						isSubjectEditable={true}
						data={state.selectedRecord}
						onCancel={() => {
							setState({
								mode: undefined,
								selectedRecord: undefined,
								editable: false,
								visible: false,
							});
						}}
						id={state.selectedRecord?.id}
						mode={state.mode}
						onCreate={() => {
							setState({
								mode: undefined,
								selectedRecord: undefined,
								editable: false,
								visible: false,
							});
							gridStore.searching.set(true);
						}}
					></SubjectReportingProcessModal>
					<SkipLinkContent />
					<PaginatedProfiBazaTable<SubjectReportingProcessListItemDto>
						gridName="subjectReportingProcess"
						onRow={(
							record: SubjectReportingProcessListItemDto,
							rowIndex: number
						) => {
							return {
								onDoubleClick: (
									event: React.MouseEvent<
										HTMLElement,
										MouseEvent
									>
								) => {
									setSelectedRecord(record.id!, false);
								},
							};
						}}
						columns={columns}
						getRowKey={(r: SubjectReportingProcessListItemDto) =>
							r.id!
						}
						getPagedResult={(
							sieve: SieveModel,
							filters,
							abortSignal
						) => {
							const parameters: ProfiBazaAPIModels.SubjectReportingProcessAllOptionalParams = {
								filters: sieve.filters,
								sorts: sieve.sorts,
								page: sieve.page,
								pageSize: sieve.pageSize,
								abortSignal,
							};
							return getProfiBazaApiClient().then((api) =>
								api.subjectReportingProcess
									.all(parameters)
									.then((result) => result)
							);
						}}
					/>
				</Col>
			</CenteredRow>
		</>
	);
};

export default observer(SubjectReportingProcessTable);
