import {
	FileExcelOutlined,
	FilePdfOutlined,
	FileTextOutlined,
} from '@ant-design/icons';
import ReakitMenuButton from '@components/shared/buttons/ReakitMenuButton';
import { useRootData } from 'hooks/hook';
import { ExportBlobType, FileExportMenuItem } from 'layout/FileExportMenuItem';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Menu, useMenuState } from 'reakit/Menu';
import { FileType } from 'services/src/models';
import { IGridStore } from 'stores/GridStore';

import { SieveModel } from '../SieveModels';

export type SupportedExportType =
	| 'default'
	| 'flat'
	| 'technicalFlat'
	| 'full'
	| 'single';
export type GetFileType = (
	sieve: SieveModel,
	allRowsSelected: boolean,
	filter?: string,
	checkedIds?: string[],
	unCheckedIds?: string[],
	fileType?: FileType
) => Promise<ExportBlobType>;

interface IProps<T> {
	getFilePromise: (
		exportType: SupportedExportType,
		abortSignal: AbortSignal | undefined
	) => GetFileType;
	fileType: FileType;
	supportedExportTypes: SupportedExportType[];
	getSieveModel: () => SieveModel;
	conditionalVisibility?: (items: T[]) => boolean;
	abortSignal: AbortSignal | undefined;
}

const renderIcon = (fileType: FileType): JSX.Element => {
	switch (fileType) {
		case FileType.CSV:
			return <FileTextOutlined />;

		case FileType.JSON:
			return <FileTextOutlined />;

		case FileType.PDF:
			return <FilePdfOutlined />;

		case FileType.XLSX:
			return <FileExcelOutlined />;
		default:
			return <FileTextOutlined />;
	}
};

const getOptionTextValue = (
	exportType: SupportedExportType,
	fileType: FileType
) => {
	const base = `Pobierz ${fileType}`;
	let suffix: string;
	switch (exportType) {
		case 'flat':
			suffix = ' (forma spłaszczona)';
			break;
		case 'technicalFlat':
			suffix = ' (forma spłaszczona - wersja techniczna)';
			break;
		case 'full':
			suffix = ' (forma pełna)';
			break;
		case 'single':
			suffix = ' (pojedynczo)';
			break;
		default:
			suffix = '';
			break;
	}

	return base + suffix;
};

const ExportButtons = <T extends any>(props: IProps<T>) => {
	const {
		supportedExportTypes,
		getFilePromise,
		fileType,
		abortSignal,
	} = props;

	const menu = useMenuState();
	const [loading, setLoading] = useState<boolean>(false);
	const [buttonTooltipVisible, setButtonTooltipVisible] = useState<boolean>(
		false
	);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	let match = useRouteMatch<{ filter: string }>();

	const getFileFunction = (exportType: SupportedExportType) => {
		const fetchFile = getFilePromise(exportType, abortSignal);

		// let filter = undefined;
		// sideBarFilters?.filters.find((x) => {
		// 	if (x.subItems) {
		// 		var subItem = x.subItems?.find(
		// 			(s) => s.key === match.params.filter
		// 		);
		// 		if (subItem) {
		// 			filter = subItem?.filter;
		// 		}
		// 	} else {
		// 		if (x.key === match.params.filter) filter = x?.filter;
		// 	}
		// });

		return fetchFile(
			props.getSieveModel(),
			gridStore.allRowsSelected.get(),
			match.params.filter,
			(gridStore.allRowsSelected.get()
				? []
				: gridStore.selectedKeys) as string[],
			gridStore.excludedKeys as string[],
			fileType
		);
	};

	const title = `Eksport ${props.fileType}`;

	const button = (
		<div
			onFocus={(event) => {
				setButtonTooltipVisible(true);
			}}
			onBlur={(event) => {
				setButtonTooltipVisible(false);
			}}
		>
			<ReakitMenuButton menuState={menu} circle loading={loading} ariaLabel={title}>
				<ProfiBazaTooltip
					title={title}
					visible={buttonTooltipVisible}
					onVisibleChange={(visible) => {
						setButtonTooltipVisible(visible);
					}}
				>
					{renderIcon(props.fileType)}
				</ProfiBazaTooltip>
			</ReakitMenuButton>
		</div>
	);

	return (
		<>
			{(props.conditionalVisibility === undefined ||
				props.conditionalVisibility(
					gridStore.selectedValues as T[]
				)) && (
				<>
					{button}
					<Menu
						{...menu}
						aria-label={title}
						className="ant-menu ant-menu-sub account-menu-vertical z-index-up"
					>
						{supportedExportTypes.map((exportType) => (
							<FileExportMenuItem
								key={exportType}
								text={getOptionTextValue(exportType, fileType)}
								loading={loading}
								setLoading={setLoading}
								menuSpread={menu}
								fileType={fileType}
								getFile={() => getFileFunction(exportType)}
							/>
						))}
					</Menu>
				</>
			)}
		</>
	);
};

ExportButtons.defaultProps = {};

export default ExportButtons;
