/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/statementCommentsMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a StatementComments. */
export class StatementComments {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a StatementComments.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementCommentsGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.StatementCommentsGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.StatementCommentDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.StatementCommentDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.StatementCommentDto>, callback?: msRest.ServiceCallback<Models.StatementCommentDto>): Promise<Models.StatementCommentsGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.StatementCommentsGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementCommentsAllResponse>
   */
  all(options?: Models.StatementCommentsAllOptionalParams): Promise<Models.StatementCommentsAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.StatementCommentListItemDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.StatementCommentsAllOptionalParams, callback: msRest.ServiceCallback<Models.StatementCommentListItemDtoPagedResult>): void;
  all(options?: Models.StatementCommentsAllOptionalParams | msRest.ServiceCallback<Models.StatementCommentListItemDtoPagedResult>, callback?: msRest.ServiceCallback<Models.StatementCommentListItemDtoPagedResult>): Promise<Models.StatementCommentsAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.StatementCommentsAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementCommentsCreateResponse>
   */
  create(options?: Models.StatementCommentsCreateOptionalParams): Promise<Models.StatementCommentsCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.StatementCommentsCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.StatementCommentsCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.StatementCommentsCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.StatementCommentsCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.StatementCommentsUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.StatementCommentsUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.StatementCommentsUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(options?: Models.StatementCommentsDeleteMethodOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteMethod(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(options: Models.StatementCommentsDeleteMethodOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(options?: Models.StatementCommentsDeleteMethodOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setAsRead(options?: Models.StatementCommentsSetAsReadOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setAsRead(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setAsRead(options: Models.StatementCommentsSetAsReadOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setAsRead(options?: Models.StatementCommentsSetAsReadOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setAsReadOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setAsProcessed(options?: Models.StatementCommentsSetAsProcessedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setAsProcessed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setAsProcessed(options: Models.StatementCommentsSetAsProcessedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setAsProcessed(options?: Models.StatementCommentsSetAsProcessedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setAsProcessedOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/StatementComments/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.StatementCommentDto
    },
    default: {}
  },
  serializer
};

const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/StatementComments",
  queryParameters: [
    Parameters.statementId,
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.StatementCommentListItemDtoPagedResult
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "statements/StatementComments",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateCommentCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "statements/StatementComments",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateCommentCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "statements/StatementComments",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeleteCommentCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setAsReadOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "statements/StatementComments/setRead",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetCommentReadCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setAsProcessedOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "statements/StatementComments/setProcessed",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetCommentProcessedCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
