/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/terytMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Teryt. */
export class Teryt {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Teryt.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TerytAllResponse>
   */
  all(options?: Models.TerytAllOptionalParams): Promise<Models.TerytAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.LocalityDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.TerytAllOptionalParams, callback: msRest.ServiceCallback<Models.LocalityDtoPagedResult>): void;
  all(options?: Models.TerytAllOptionalParams | msRest.ServiceCallback<Models.LocalityDtoPagedResult>, callback?: msRest.ServiceCallback<Models.LocalityDtoPagedResult>): Promise<Models.TerytAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.TerytAllResponse>;
  }

  /**
   * @param tercCode
   * @param [options] The optional parameters
   * @returns Promise<Models.TerytGetLocalitiesByCommunityResponse>
   */
  getLocalitiesByCommunity(tercCode: string, options?: Models.TerytGetLocalitiesByCommunityOptionalParams): Promise<Models.TerytGetLocalitiesByCommunityResponse>;
  /**
   * @param tercCode
   * @param callback The callback
   */
  getLocalitiesByCommunity(tercCode: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param tercCode
   * @param options The optional parameters
   * @param callback The callback
   */
  getLocalitiesByCommunity(tercCode: string, options: Models.TerytGetLocalitiesByCommunityOptionalParams, callback: msRest.ServiceCallback<any>): void;
  getLocalitiesByCommunity(tercCode: string, options?: Models.TerytGetLocalitiesByCommunityOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.TerytGetLocalitiesByCommunityResponse> {
    return this.client.sendOperationRequest(
      {
        tercCode,
        options
      },
      getLocalitiesByCommunityOperationSpec,
      callback) as Promise<Models.TerytGetLocalitiesByCommunityResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TerytGetByLocalityResponse>
   */
  getByLocality(options?: Models.TerytGetByLocalityOptionalParams): Promise<Models.TerytGetByLocalityResponse>;
  /**
   * @param callback The callback
   */
  getByLocality(callback: msRest.ServiceCallback<Models.StreetDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getByLocality(options: Models.TerytGetByLocalityOptionalParams, callback: msRest.ServiceCallback<Models.StreetDtoPagedResult>): void;
  getByLocality(options?: Models.TerytGetByLocalityOptionalParams | msRest.ServiceCallback<Models.StreetDtoPagedResult>, callback?: msRest.ServiceCallback<Models.StreetDtoPagedResult>): Promise<Models.TerytGetByLocalityResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getByLocalityOperationSpec,
      callback) as Promise<Models.TerytGetByLocalityResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TerytVoivodeshipsResponse>
   */
  voivodeships(options?: Models.TerytVoivodeshipsOptionalParams): Promise<Models.TerytVoivodeshipsResponse>;
  /**
   * @param callback The callback
   */
  voivodeships(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  voivodeships(options: Models.TerytVoivodeshipsOptionalParams, callback: msRest.ServiceCallback<any>): void;
  voivodeships(options?: Models.TerytVoivodeshipsOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.TerytVoivodeshipsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      voivodeshipsOperationSpec,
      callback) as Promise<Models.TerytVoivodeshipsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TerytCountiesResponse>
   */
  counties(options?: Models.TerytCountiesOptionalParams): Promise<Models.TerytCountiesResponse>;
  /**
   * @param callback The callback
   */
  counties(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  counties(options: Models.TerytCountiesOptionalParams, callback: msRest.ServiceCallback<any>): void;
  counties(options?: Models.TerytCountiesOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.TerytCountiesResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      countiesOperationSpec,
      callback) as Promise<Models.TerytCountiesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TerytCommunitiesResponse>
   */
  communities(options?: Models.TerytCommunitiesOptionalParams): Promise<Models.TerytCommunitiesResponse>;
  /**
   * @param callback The callback
   */
  communities(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  communities(options: Models.TerytCommunitiesOptionalParams, callback: msRest.ServiceCallback<any>): void;
  communities(options?: Models.TerytCommunitiesOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.TerytCommunitiesResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      communitiesOperationSpec,
      callback) as Promise<Models.TerytCommunitiesResponse>;
  }

  /**
   * @param tercCode
   * @param [options] The optional parameters
   * @returns Promise<Models.TerytGetAreasByUrbanRuralCommunityResponse>
   */
  getAreasByUrbanRuralCommunity(tercCode: string, options?: Models.TerytGetAreasByUrbanRuralCommunityOptionalParams): Promise<Models.TerytGetAreasByUrbanRuralCommunityResponse>;
  /**
   * @param tercCode
   * @param callback The callback
   */
  getAreasByUrbanRuralCommunity(tercCode: string, callback: msRest.ServiceCallback<Models.TerritorialUnitDtoPagedResult>): void;
  /**
   * @param tercCode
   * @param options The optional parameters
   * @param callback The callback
   */
  getAreasByUrbanRuralCommunity(tercCode: string, options: Models.TerytGetAreasByUrbanRuralCommunityOptionalParams, callback: msRest.ServiceCallback<Models.TerritorialUnitDtoPagedResult>): void;
  getAreasByUrbanRuralCommunity(tercCode: string, options?: Models.TerytGetAreasByUrbanRuralCommunityOptionalParams | msRest.ServiceCallback<Models.TerritorialUnitDtoPagedResult>, callback?: msRest.ServiceCallback<Models.TerritorialUnitDtoPagedResult>): Promise<Models.TerytGetAreasByUrbanRuralCommunityResponse> {
    return this.client.sendOperationRequest(
      {
        tercCode,
        options
      },
      getAreasByUrbanRuralCommunityOperationSpec,
      callback) as Promise<Models.TerytGetAreasByUrbanRuralCommunityResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TerytSearchTerritoriesResponse>
   */
  searchTerritories(options?: Models.TerytSearchTerritoriesOptionalParams): Promise<Models.TerytSearchTerritoriesResponse>;
  /**
   * @param callback The callback
   */
  searchTerritories(callback: msRest.ServiceCallback<Models.TerritoryDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  searchTerritories(options: Models.TerytSearchTerritoriesOptionalParams, callback: msRest.ServiceCallback<Models.TerritoryDtoPagedResult>): void;
  searchTerritories(options?: Models.TerytSearchTerritoriesOptionalParams | msRest.ServiceCallback<Models.TerritoryDtoPagedResult>, callback?: msRest.ServiceCallback<Models.TerritoryDtoPagedResult>): Promise<Models.TerytSearchTerritoriesResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      searchTerritoriesOperationSpec,
      callback) as Promise<Models.TerytSearchTerritoriesResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teryt/Teryt/Locality",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.LocalityDtoPagedResult
    },
    default: {}
  },
  serializer
};

const getLocalitiesByCommunityOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teryt/Teryt/Locality/{tercCode}",
  urlParameters: [
    Parameters.tercCode0
  ],
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TerritorialUnitDtoPagedResult
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getByLocalityOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teryt/Teryt/Streets",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.simcCode,
    Parameters.tercCode1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.StreetDtoPagedResult
    },
    default: {}
  },
  serializer
};

const voivodeshipsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teryt/Teryt/Voivodeship",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TerritorialUnitDtoPagedResult
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const countiesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teryt/Teryt/County",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0,
    Parameters.voivodeshipTercCode
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TerritorialUnitDtoPagedResult
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const communitiesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teryt/Teryt/Community",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0,
    Parameters.countyTercCode
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TerritorialUnitDtoPagedResult
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getAreasByUrbanRuralCommunityOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teryt/Teryt/UrbanRuralCommunity/{tercCode}",
  urlParameters: [
    Parameters.tercCode0
  ],
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TerritorialUnitDtoPagedResult
    },
    default: {}
  },
  serializer
};

const searchTerritoriesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "teryt/Teryt/SearchTerritories",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TerritoryDtoPagedResult
    },
    default: {}
  },
  serializer
};
