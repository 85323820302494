/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/orbeonMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Orbeon. */
export class Orbeon {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Orbeon.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param type Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53',
   * 'AOTMiTFinalReport', 'ActivityProhealthActivities', 'ActivityResearch',
   * 'ActivitySupervisionMonitoring', 'ActivitySupervisionSanitary', 'ActivityStaffEducation',
   * 'ActivityStaffMonitoring', 'ActivityLegislation', 'InterventionProhealthActivities',
   * 'InterventionResearch', 'InterventionSupervisionMonitoring', 'InterventionSupervisionSanitary',
   * 'InterventionStaffEducation', 'InterventionStaffMonitoring', 'InterventionLegislation'
   * @param [options] The optional parameters
   * @returns Promise<Models.OrbeonGetByTypeResponse>
   */
  getByType(type: Models.FormType, options?: msRest.RequestOptionsBase): Promise<Models.OrbeonGetByTypeResponse>;
  /**
   * @param type Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53',
   * 'AOTMiTFinalReport', 'ActivityProhealthActivities', 'ActivityResearch',
   * 'ActivitySupervisionMonitoring', 'ActivitySupervisionSanitary', 'ActivityStaffEducation',
   * 'ActivityStaffMonitoring', 'ActivityLegislation', 'InterventionProhealthActivities',
   * 'InterventionResearch', 'InterventionSupervisionMonitoring', 'InterventionSupervisionSanitary',
   * 'InterventionStaffEducation', 'InterventionStaffMonitoring', 'InterventionLegislation'
   * @param callback The callback
   */
  getByType(type: Models.FormType, callback: msRest.ServiceCallback<Models.OrbeonFormDefinition[]>): void;
  /**
   * @param type Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53',
   * 'AOTMiTFinalReport', 'ActivityProhealthActivities', 'ActivityResearch',
   * 'ActivitySupervisionMonitoring', 'ActivitySupervisionSanitary', 'ActivityStaffEducation',
   * 'ActivityStaffMonitoring', 'ActivityLegislation', 'InterventionProhealthActivities',
   * 'InterventionResearch', 'InterventionSupervisionMonitoring', 'InterventionSupervisionSanitary',
   * 'InterventionStaffEducation', 'InterventionStaffMonitoring', 'InterventionLegislation'
   * @param options The optional parameters
   * @param callback The callback
   */
  getByType(type: Models.FormType, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.OrbeonFormDefinition[]>): void;
  getByType(type: Models.FormType, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.OrbeonFormDefinition[]>, callback?: msRest.ServiceCallback<Models.OrbeonFormDefinition[]>): Promise<Models.OrbeonGetByTypeResponse> {
    return this.client.sendOperationRequest(
      {
        type,
        options
      },
      getByTypeOperationSpec,
      callback) as Promise<Models.OrbeonGetByTypeResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByTypeOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "forms/Orbeon/{type}",
  urlParameters: [
    Parameters.type
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrbeonFormDefinition"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};
