/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/fileMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a File. */
export class File {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a File.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.FileDownloadResponse>
   */
  download(id: string, options?: msRest.RequestOptionsBase): Promise<Models.FileDownloadResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  download(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  download(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  download(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.FileDownloadResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      downloadOperationSpec,
      callback) as Promise<Models.FileDownloadResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.FileDeleteMethodResponse>
   */
  deleteMethod(id: string, options?: msRest.RequestOptionsBase): Promise<Models.FileDeleteMethodResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteMethod(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.FileDeleteMethodResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteMethodOperationSpec,
      callback) as Promise<Models.FileDeleteMethodResponse>;
  }

  /**
   * @param id
   * @param onlyMetadata
   * @param [options] The optional parameters
   * @returns Promise<Models.FilePreviewResponse>
   */
  preview(id: string, onlyMetadata: boolean, options?: msRest.RequestOptionsBase): Promise<Models.FilePreviewResponse>;
  /**
   * @param id
   * @param onlyMetadata
   * @param callback The callback
   */
  preview(id: string, onlyMetadata: boolean, callback: msRest.ServiceCallback<Models.FileDto>): void;
  /**
   * @param id
   * @param onlyMetadata
   * @param options The optional parameters
   * @param callback The callback
   */
  preview(id: string, onlyMetadata: boolean, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.FileDto>): void;
  preview(id: string, onlyMetadata: boolean, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.FileDto>, callback?: msRest.ServiceCallback<Models.FileDto>): Promise<Models.FilePreviewResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        onlyMetadata,
        options
      },
      previewOperationSpec,
      callback) as Promise<Models.FilePreviewResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setCategory(options?: Models.FileSetCategoryOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setCategory(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setCategory(options: Models.FileSetCategoryOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setCategory(options?: Models.FileSetCategoryOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setCategoryOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const downloadOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "DocumentsRepository/File/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "DocumentsRepository/File/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const previewOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "DocumentsRepository/File/preview/{id}/{onlyMetadata}",
  urlParameters: [
    Parameters.id0,
    Parameters.onlyMetadata
  ],
  responses: {
    200: {
      bodyMapper: Mappers.FileDto
    },
    default: {}
  },
  serializer
};

const setCategoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "DocumentsRepository/File/category",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetFileCategoryCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
