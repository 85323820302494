import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';

export class UpdateInterventionCommentDto {
	commentId?: string;
	fieldName?: string;
	description?: string;
	status?: ProfiBazaAPIModels.InterventionCommentStatus;

	constructor(model: ProfiBazaAPIModels.InterventionCommentDto, id: string) {
		this.commentId = id;
		this.fieldName = model.fieldName;
		this.description = model.description;
		this.status = model.status;
	}

	static toCommand(
		model: UpdateInterventionCommentDto
	): ProfiBazaAPIModels.UpdateInterventionCommentCommand {
		const command: ProfiBazaAPIModels.UpdateInterventionCommentCommand = {
			commentId: model.commentId,
			description: model.description,
		};
		return command;
	}
}
