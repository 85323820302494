import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import moment, { Moment } from 'moment';
import {
	CreatePatternCommand,
	InterventionPatternType,
	PatternStatus,
	PublicHealthInterventionStatus,
} from 'services/src/models';

import {
	InterventionActivityCommand,
	InterventionBaseCommand,
} from './InterventionBaseCommand';

export class InterventionCommand extends InterventionBaseCommand {
	patternName?: string;
	validDateRange?: [Moment?, Moment?];
	/**
	 * Possible values include: 'PROGRAM', 'NOT_PROGRAM', 'HEALTH_POLICY_PROGRAM'
	 */
	patternType?: InterventionPatternType;
	patternStatus?: PatternStatus;
	status?: PublicHealthInterventionStatus;
	recommendationDocumentId?: string;
	recommendationDocumentName?: string;
	isCompliantWithRecommendation?: boolean;

	constructor(model?: ProfiBazaAPIModels.PatternDto) {
		super(model);
		//pattern
		this.patternName = model?.patternName;
		this.validDateRange = [
			model?.vaildFrom ? moment(model?.vaildFrom) : undefined,
			model?.vaildTo ? moment(model?.vaildTo) : undefined,
		];
		this.patternType = model?.patternType;
		this.patternStatus = model?.patternStatus;
		this.status = model?.status ?? PublicHealthInterventionStatus.PLANNED;
		this.recommendationDocumentId = model?.recommendationDocumentId;
		this.recommendationDocumentName = model?.recommendationDocumentName;
		this.isCompliantWithRecommendation =
			model?.isCompliantWithRecommendation;
	}

	static toCommandPattern(
		model: InterventionCommand
	): ProfiBazaAPIModels.PatternDto {
		const command: ProfiBazaAPIModels.PatternDto = {
			...this.toCommand(model),
			patternName: model.patternName,
			vaildFrom: model.validDateRange
				? model.validDateRange[0]?.utc(true).toDate()
				: undefined,
			vaildTo: model.validDateRange
				? model.validDateRange[1]?.utc(true).toDate()
				: undefined,
			patternType: model.patternType,
			recommendationDocumentId: model.recommendationDocumentId,
			isCompliantWithRecommendation: model.isCompliantWithRecommendation,
		};
		return command;
	}

	static toUpdatePatternCommand(
		value: InterventionCommand,
		id: any
	): ProfiBazaAPIModels.UpdatePatternCommand {
		const command: ProfiBazaAPIModels.UpdatePatternCommand = {
			...this.toUpdateInterventionCommand(value, id),
			patternName: value.patternName,
			vaildFrom: value.validDateRange
				? value.validDateRange[0]?.utc(true).toDate()
				: undefined,
			vaildTo: value.validDateRange
				? value.validDateRange[1]?.utc(true).toDate()
				: undefined,
			patternType: value.patternType,
			recommendationDocumentId: value.recommendationDocumentId,
			isCompliantWithRecommendation: value.isCompliantWithRecommendation,
			interventionTypeDictionaryValueId: value.interventionTypeDictionaryValueId,
			interventionTypeDictionaryOtherValue: value.interventionTypeDictionaryOtherValue,
			name: value.name && value.name != '' ? value.name : undefined,
		};
		return command;
	}

	static toCreatePattern(value: InterventionCommand): CreatePatternCommand {
		const body: CreatePatternCommand = {
			...this.toCreateIntervention(value),
			implementationPeriodDictionaryValueId:
				value.implementationPeriodDictionaryValueId,
			implementationPeriodDictionaryOtherValue:
				value.implementationPeriodDictionaryOtherValue,
			interventionAreaDictionaryValueId:
				value.interventionAreaDictionaryValueId,
			interventionAreaDictionaryOtherValue:
				value.interventionAreaDictionaryOtherValue,
			name: value.name && value.name != '' ? value.name : undefined,
			interventionPartners: value.interventionPartners,
			plannedDateFrom: value.plannedDateRange?.[0]?.utc(true).toDate(),
			plannedDateTo: value.plannedDateRange?.[1]?.utc(true).toDate(),
			legalBases: value.legalBases,
			plannedBudget: value.plannedBudget,
			contacts: value.contacts,
			areaTerytCodes: value.areaTerytCodes,
			interventionCoownerIds: value.interventionCoowners?.map(
				(x) => x.id!
			),
			patternName: value.patternName,
			vaildFrom: value.validDateRange
				? value.validDateRange[0]?.toDate()
				: undefined,
			vaildTo: value.validDateRange
				? value.validDateRange[1]?.toDate()
				: undefined,
			patternType: value.patternType,
			recommendationDocumentId: value.recommendationDocumentId,
			isCompliantWithRecommendation: value.isCompliantWithRecommendation,
			annualNumberOfRecipients: value.annualNumberOfRecipients,
			numberOfPersonAction: value.numberOfPersonAction,
			numberOfRecipients: value.numberOfRecipients,
		};
		return body;
	}

	static changeData(
		model: InterventionCommand,
		key: string,
		value: any
	): InterventionCommand {
		let command: InterventionCommand = {
			validDateRange: model?.validDateRange,
			patternName: model?.patternName,
			patternType: model?.patternType,
		};
		if (key) {
			command = {
				...command,
				[key]: value ? value : undefined,
			};
		}
		return command;
	}
}
export const numberOfPeople: number = 40000000;
export const maxCosts: number = 100000000;
