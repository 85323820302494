import React, { useEffect, useState } from 'react';
import * as InterventionPatternTypeMap from '@services/mappers/InterventionPatternType';
import { FormikProps, Field } from 'formik';
import {
	FSelect,
	FInput,
	FRangePicker,
	FFieldLabel,
	FCheckbox,
} from 'forms/FormikFormItems';
import { Moment } from 'moment';
import { InterventionCommand } from './model/InterventionCommand';
import { PatternToInterventionTypeMapper } from './model/map/InterventionMappers';
import { Select, Row, Col } from 'antd';
import PrimaryCard from 'layout/PrimaryCard';
import { IDictionaryStore } from 'components/dictionary/dictionaryStore';
import { useRootData } from 'hooks/hook';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { ajaxCatch } from '@helper/api';
import { InterventionPatternType, RecommendationIdNameDto } from '@services/src/models';
import { observer } from 'mobx-react-lite';

const { Option } = Select;

interface IProps {
	editable?: boolean;
	formik: FormikProps<InterventionCommand>;
	checkChanges: (value: any, key?: any) => void;
}

const InterventionPattern: React.FC<IProps> = (props) => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const getRecommendationDocumentName =(
		value: string
	): string => {
		if(!value)
			return '';

		return recommendations?.filter(
			(x) => x.id === value
		)[0].recommendationName!;
	};

	const getInterventionTypeDictId = (
		value: ProfiBazaAPIModels.InterventionPatternType
	): number | undefined => {
		if(!value)
			return undefined;

		const dictionary = dictionaryStore.getDictionary(
			'TYP_INTERWENCJI',
			ProfiBazaAPIModels.DictionaryInterventionPath.PROHEALTHACTIVITIES
		);

		return dictionary?.items?.filter(
			(x) => x.codeName === PatternToInterventionTypeMapper.get(value)
		)[0].id!;
	};

	const [recommendations, setRecommendations] = useState<RecommendationIdNameDto[]>([]);

	const loadRecommendations = () =>
		ajaxCatch(() =>
			getProfiBazaApiClient().then((api) => {
				api.document
					.getAotmitRecommendationFileList()
					.then((result: RecommendationIdNameDto[] | any) => {
						setRecommendations(result);
					});
			})
		);

	useEffect(() => {
		loadRecommendations();

		dictionaryStore.initializeDictionary(
			'TYP_INTERWENCJI',
			ProfiBazaAPIModels.DictionaryInterventionPath.PROHEALTHACTIVITIES,
		);
	}, []);

	return (
		<PrimaryCard>
			<Row gutter={[16, 0]}>
				<Col span={6}>
					<FFieldLabel label="Typ wzorca" />
					<Field
						component={FSelect}
						name="patternType"
						label="Typ wzorca"
						readOnly={!props.editable}
						interventionPath={ProfiBazaAPIModels.DictionaryInterventionPath.PROHEALTHACTIVITIES}
						changeData={(value: any) => {
							props.checkChanges(value, 'subjectKind');

							props.formik.setFieldValue(
								'interventionTypeDictionaryValueId',
								getInterventionTypeDictId(value)
							);

							props.formik.setFieldValue(
								'interventionPath',
								value! 
									? ProfiBazaAPIModels.DictionaryInterventionPath.PROHEALTHACTIVITIES 
									: undefined
							);
						}}
					>
						{Array.from(
							InterventionPatternTypeMap.mappings.entries()
						).map((x, i) => (
							<Option key={i} value={x[0]}>
								{x[1]}
							</Option>
						))}
					</Field>
				</Col>
				<Col span={6}>
					<FFieldLabel label="Nr wzorca" />
					<Field component={FInput} name="codeName" readOnly label="Nr wzorca" />
				</Col>
				<Col span={12}>
					<FFieldLabel label={'Data ważności'} />
					<Field
						component={FRangePicker}
						style={{
							width: '100%',
						}}
						inputReadOnly={!props.editable}
						name="validDateRange"
						onChange={(
							value: [Moment | undefined, Moment | undefined]
						) => {
							props.checkChanges(value, 'validDateRange');
						}}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 0]}>
					<Col span={12}>
						<FFieldLabel label="Właściciel wzorca" />
						<Field component={FInput} name="subjectName" readOnly label="Właściciel wzorca" />
					</Col>
					<Col span={12}>
						<FFieldLabel label="Nazwa wzorca" />
						<Field
							component={FInput}
							name="patternName"
							label="Nazwa wzorca"
							changeData={(value: any) =>
								props.checkChanges(value, 'patternName')
							}
							readOnly={!props.editable}
						/>
					</Col>
				</Row>

			{props.formik.values.patternType == InterventionPatternType.AOTMITRECOMENDATION &&
			<>
				<Row gutter={[16, 0]}>
					<Col span={12}>
						<FFieldLabel label="Rekomendacja AOTMiT" />
						<Field
							component={FSelect}
							name="recommendationDocumentId"
							label="Rekomendacja AOTMiT"
							children={recommendations && recommendations.map((x) => (
								<Option key={x.id} value={x.id!}>
									{x.recommendationName}
								</Option>
							))}
							readOnly={!props.editable}
							changeData={(value: any) => {
								props.formik.setFieldValue(
									'recommendationDocumentName',
									getRecommendationDocumentName(value)
								);
							}}
						/>
					</Col>
					<Col span={12}>
						<FFieldLabel label="Oświadczam zgodność tworzonego wzorca z rekomendacją" />
						<Field
							component={FCheckbox}
							name="isCompliantWithRecommendation"
							label="Oświadczam zgodność tworzonego wzorca z rekomendacją"
							changeData={(value: any) =>
								props.checkChanges(value, 'isCompliantWithRecommendation')
							}
							disabled={!props.editable}
						/>
					</Col>
				</Row>
			</>
			}
		</PrimaryCard>
	);
};

export default observer(InterventionPattern);
