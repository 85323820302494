import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<
	ProfiBazaAPIModels.PublicHealthInterventionStatus,
	string
>([
	[
		ProfiBazaAPIModels.PublicHealthInterventionStatus.REALIZATION,
		'w trakcie realizacji',
	],
	[
		ProfiBazaAPIModels.PublicHealthInterventionStatus.COMPLETEDREALIZED,
		'zakończona zrealizowana',
	],
	[
		ProfiBazaAPIModels.PublicHealthInterventionStatus.COMPLETEDUNREALIZED,
		'zakończona niezrealizowana',
	],
	[ProfiBazaAPIModels.PublicHealthInterventionStatus.DRAFT, 'robocza'],
	[ProfiBazaAPIModels.PublicHealthInterventionStatus.PLANNED, 'planowana'],
	[ProfiBazaAPIModels.PublicHealthInterventionStatus.SUSPENDED, 'zawieszona'],
	[ProfiBazaAPIModels.PublicHealthInterventionStatus.DELETED, 'usunięta'],
	[ProfiBazaAPIModels.PublicHealthInterventionStatus.CANCELED, 'anulowana'],

	[
		ProfiBazaAPIModels.PublicHealthInterventionStatus.ARCHIVED,
		'zarchiwizowana',
	],
	[ProfiBazaAPIModels.PublicHealthInterventionStatus.CANCELED, 'anulowana'],
	[ProfiBazaAPIModels.PublicHealthInterventionStatus.SENDEDTOOPINIONAOTMIT, 'wysłano do oceny AOTMiT']
]);

export const map = (
	value: ProfiBazaAPIModels.PublicHealthInterventionStatus
): string => mappings.get(value) ?? '';
