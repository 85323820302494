/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/subjectCommentsMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a SubjectComments. */
export class SubjectComments {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a SubjectComments.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectCommentsGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.SubjectCommentsGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.SubjectCommentDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SubjectCommentDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SubjectCommentDto>, callback?: msRest.ServiceCallback<Models.SubjectCommentDto>): Promise<Models.SubjectCommentsGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.SubjectCommentsGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectCommentsCreateResponse>
   */
  create(options?: Models.SubjectCommentsCreateOptionalParams): Promise<Models.SubjectCommentsCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.SubjectCommentsCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.SubjectCommentsCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.SubjectCommentsCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.SubjectCommentsCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.SubjectCommentsUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.SubjectCommentsUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.SubjectCommentsUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(options?: Models.SubjectCommentsDeleteMethodOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteMethod(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(options: Models.SubjectCommentsDeleteMethodOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(options?: Models.SubjectCommentsDeleteMethodOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectCommentsAllResponse>
   */
  all(options?: Models.SubjectCommentsAllOptionalParams): Promise<Models.SubjectCommentsAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.SubjectCommentListItemDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.SubjectCommentsAllOptionalParams, callback: msRest.ServiceCallback<Models.SubjectCommentListItemDtoPagedResult>): void;
  all(options?: Models.SubjectCommentsAllOptionalParams | msRest.ServiceCallback<Models.SubjectCommentListItemDtoPagedResult>, callback?: msRest.ServiceCallback<Models.SubjectCommentListItemDtoPagedResult>): Promise<Models.SubjectCommentsAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.SubjectCommentsAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setStatus(options?: Models.SubjectCommentsSetStatusOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setStatus(options: Models.SubjectCommentsSetStatusOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setStatus(options?: Models.SubjectCommentsSetStatusOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setStatusOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/SubjectComments/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectCommentDto
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "subjects/SubjectComments",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateSubjectCommentCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "subjects/SubjectComments",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateSubjectCommentCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "subjects/SubjectComments",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeleteSubjectCommentCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/SubjectComments",
  queryParameters: [
    Parameters.subjectId1,
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectCommentListItemDtoPagedResult
    },
    default: {}
  },
  serializer
};

const setStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "subjects/SubjectComments/setStatus",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetSubjectCommentStatusCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
