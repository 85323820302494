import { ActivityListItemDto } from '@services/src/models';
import React, { useCallback, useState } from 'react';

const useActivityState = () => {
	const [activities, setActivities] = useState<ActivityListItemDto[]>([]);
	const [activityVisible, setActivityVisible] = useState<boolean>(false);
	const [relatedActivities, setRelatedActivities] = useState<string[]>([]);
	const [activityId, setActivityId] = useState<string>();
	const [mode, setMode] = useState<
		'new' | 'edit' | 'clone' | 'clone_continuations' | 'preview'
	>('preview');
	const [cloneVisible, setCloneVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const handleCloseModal = useCallback(() => {
		setActivityVisible(false);
		setActivityId(undefined);
	}, []);

	const handleCloseCloneModal = useCallback(() => {
		setCloneVisible(false);
		setActivityId(undefined);
	}, []);

	const getState = () => ({
		activities,
		setActivities,
		activityVisible,
		setActivityVisible,
		relatedActivities,
		setRelatedActivities,
		activityId,
		setActivityId,
		mode,
		setMode,
		cloneVisible,
		setCloneVisible,
		handleCloseModal,
		loading,
		setLoading,
	});

	const getActions = () => ({
		handleCloseModal,
		handleCloseCloneModal,
	});

	const resp: [ReturnType<typeof getState>, ReturnType<typeof getActions>] = [
		getState(),
		getActions(),
	];

	return resp;
};

export default useActivityState;
