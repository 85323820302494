import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { DictionaryName } from 'services/mappers/DictionaryName';
import * as DocumentSubjectKind from 'services/mappers/DocumentSubjectKind';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const columns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.DocumentListItemDto
>[] = [
	{
		dataIndex: 'year',
		key: 'year',
		title: 'Rok przesłania Oświadczenia',
		filter: 'equals',
		sorter: true,
	},
	{
		dataIndex: 'number',
		key: 'number',
		title: 'Numer rekomendacji',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'fileName',
		key: 'fileName',
		title: 'Nazwa rekomendacji',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'voivodeship',
		key: 'voivodeship',
		title: 'Województwo',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'county',
		key: 'county',
		title: 'Powiat',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'decisionProblem',
		key: 'decisionProblem',
		title: 'Problem decyzyjny',
		filter: 'contains',
		sorter: true,
		defaultHidden: true,
	},
	{
		dataIndex: 'programYearFrom',
		key: 'programYearFrom',
		title: 'Rok rozpoczęcia',
		filter: 'equals',
		sorter: true,
		defaultHidden: true,
	},
	{
		dataIndex: 'programYearTo',
		key: 'programYearTo',
		title: 'Rok zakończenia',
		filter: 'equals',
		sorter: true,
		defaultHidden: true,
	},
];

export default columns;
