import { ajaxCatch } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { observable } from 'mobx';

export const notificationStore = {
	loading: observable.box<boolean>(true),
	data: observable(
		new Array<ProfiBazaAPIModels.UserNotificationSettingsDTO>()
	),
	activenessMap: observable(new Map<string, boolean>()),
	initialize: (callback: (result: any) => void, signal?: AbortSignal) => {
		notificationStore.loading.set(true);

		ajaxCatch(() =>
			getProfiBazaApiClient().then((api) =>
				api.userNotificationSettings
					.getAll({ abortSignal: signal })
					.then(
						(
							result: ProfiBazaAPIModels.UserNotificationSettingsDTO[]
						) => {
							notificationStore.loading.set(false);
							notificationStore.data.replace(result);

							notificationStore.activenessMap.clear();
							result.forEach(
								(
									item: ProfiBazaAPIModels.UserNotificationSettingsDTO
								) => {
									if (item.notificationDefinitionId) {
										notificationStore.activenessMap.set(
											item.notificationDefinitionId,
											item.isActive!
										);
									}
								}
							);
							callback(result);
						}
					)
			)
		);
	},
	toggleActive: (notificationId: string | undefined) => {
		if (notificationId !== undefined) {
			const previousActive = notificationStore.activenessMap.get(
				notificationId
			);
			notificationStore.activenessMap.set(
				notificationId,
				!previousActive
			);
		}
	},
	getActiveState: (notificationDefinitionId: string | undefined) => {
		return notificationStore.data.some(
			(item: ProfiBazaAPIModels.UserNotificationSettingsDTO) =>
				item.notificationDefinitionId === notificationDefinitionId &&
				!item.isActive
		);
	},
};

export const createNotificationStore = () => {
	return notificationStore;
};

export type INotificationStore = ReturnType<typeof createNotificationStore>;
