import useSelectsAccessibility from '@hooks/accessibility/useSelectsAccessibilityHook';
import { Col, Row } from 'antd';
import AsyncAutocomplete, { IOption } from 'forms/AsyncAutocomplete';
import { FFieldLabel } from 'forms/FormikFormItems';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import * as H from 'history';
import React, { useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { UserListDto, UserListResponse } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import RolesTable from './common/RolesTable';
import { RoleDto } from './common/UpdateUserRolesCommand';

interface IState {
	selectedUserDescription?: string;
	user?: ProfiBazaAPIModels.UserDto;
}

interface IProps {
	userId?: string;
	history: H.History;
}

const UserPermissions: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({});

	useEffect(() => {
		if (!props.userId) {
			setState({
				selectedUserDescription: '',
			});
			return;
		}
		reload();
	}, [props.userId]);

	const reload = () =>
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.user.getById(props.userId!))
				.then((response) => {
					setState({
						selectedUserDescription: `${response.firstName} ${response.lastName} (${response.email}, ${response.subject})`,
						user: response,
					});
				})
		);

	useSelectsAccessibility();

	return (
		<>
			<Row>
				<FFieldLabel
					label="Wybierz użytkownika"
					for="selectedUserDescription"
				/>
				<AsyncAutocomplete
					id="selectedUserDescription"
					placeholder="Wyszukaj użytkownika"
					onSearch={() => {
						setState({
							...state,
							selectedUserDescription: undefined,
						});
					}}
					loadData={(data: string) => {
						return getProfiBazaApiClient()
							.then((api) =>
								api.user.list({
									sievePage: 1,
									sievePageSize: 100,
									filters: `(fullName|email|subject)@=*${data}`,
								})
							)
							.then((response: UserListResponse) =>
								response.results!.map(
									(x: UserListDto): IOption => ({
										key: x.id!,
										description: `${x.fullName} (${x.email}, ${x.subject})`,
										value: x.id!,
									})
								)
							);
					}}
					value={state.selectedUserDescription}
					onSelect={(user: any) => {
						setState({
							...state,
							selectedUserDescription: user.description,
						});

						if (props.userId !== user.value) {
							props.history.push(
								`/admin/permissions/users/${user.value}`
							);
						}
					}}
					onClear={() => {}}
				/>
			</Row>
			{state.user && (
				<Row>
					<Col span={24}>
						<RolesTable
							roles={state.user.roles!}
							rolePermissions={state.user.rolePermissions!}
							addRole={(role: RoleDto) =>
								ajaxByUser('Zaktualizowano role.', () =>
									getProfiBazaApiClient().then((api) =>
										api.user.addRole({
											body: {
												userId: props.userId,
												roleId: role.roleId,
												validFrom: role.validFrom
													?.utc(true)
													.toDate(),
												validTo: role.validTo
													?.utc(true)
													.toDate(),
											},
										})
									)
								).then(() => reload())
							}
							deleteRole={(role: RoleDto) =>
								ajaxByUser('Skasowano rolę.', () =>
									getProfiBazaApiClient().then((api) =>
										api.user.deleteRole({
											body: {
												userId: props.userId,
												roleId: role.roleId,
											},
										})
									)
								).then(() => reload())
							}
						></RolesTable>
					</Col>
				</Row>
			)}
		</>
	);
};

export default UserPermissions;
