import { Col, Row, Space } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import GridClearFilters from 'components/shared/paginatedProfiBazaTable/filters/GridClearFilters';
import { ProfiBazaClearFilters } from 'components/shared/paginatedProfiBazaTable/filters/ProfiBazaTableFilter';
import { AntPagination } from 'components/shared/paginatedProfiBazaTable/SieveModels';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { openNotificationWithIcon } from 'help/NotificationHelper';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import DraggableProfiBazaTable from 'layout/DraggableProfiBazaTable';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import PrimaryCard from 'layout/PrimaryCard';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { FormPartDto, FormStatus, FormType } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import FormValuesModal from '../modals/formsValues/FormValuesModal';
import DictionariesValuesTableColumn from './FormsValuesTableColumn';

interface IProps {
	formVersionId: string;
	formVersion: ProfiBazaAPIModels.FormVersionItemDto | undefined;
	type: ProfiBazaAPIModels.FormType;
}

interface IState {
	formValues: ProfiBazaAPIModels.FormPartDto[] | undefined;
	loading: boolean;
}

const FormsValuesTable: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({
		formValues: undefined,
		loading: true,
	});

	const { formVersion } = props;
	const [tableFilters, setTableFilters] = useState<
		Record<keyof ProfiBazaAPIModels.FormPartDto, string[]> | undefined
	>(undefined);
	const [tableSorters, setTableSorters] = useState<
		| Record<
				keyof ProfiBazaAPIModels.FormPartDto,
				'descend' | 'ascend' | null | undefined
		  >
		| undefined
	>(undefined);
	const [clearAll, setClearAll] = useState<boolean>(false);

	const [valueEditModalVisible, setValueEditModalVisible] = useState<boolean>(
		false
	);
	const [editedValueId, setEditedValueId] = useState<string | undefined>();

	const isDraft = formVersion?.status === FormStatus.DRAFT;

	useEffect(() => {
		refresh();
	}, [props.formVersionId]);

	const refresh = () => {
		setState({ ...state, loading: true });

		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.formVersion.getById(props.formVersionId))
				.then((result: ProfiBazaAPIModels.FormVersionDto) => {
					if (result) {
						setState({
							...state,
							formValues: result.parts?.sort(
								(a, b) => a.order! - b.order!
							),
							loading: false,
						});
					}
				})
		);
	};

	const setDataSource = (data: ProfiBazaAPIModels.FormPartDto[]) =>
		setState({ ...state, formValues: data });

	const handleMove = (dragIndex: number, hoverIndex: number) => {
		const movedValue = state.formValues![dragIndex];
		updateValuePosition(dragIndex, hoverIndex, movedValue);
	};

	const updateValuePosition = (
		dragIndex: number,
		hoverIndex: number,
		movedValue: FormPartDto
	) => {
		const movedValueId = movedValue.id;

		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.formPart.order({
						body: { id: movedValueId, order: hoverIndex },
					})
				)
				.then(() => {
					openNotificationWithIcon(
						'success',
						'Kolejność zmieniona pomyślnie',
						`Wartość 
					została przeniesiona z pozycji ${dragIndex + 1} na ${hoverIndex + 1}`
					);
					refresh();
				})
		);
	};

	const columns = [
		...DictionariesValuesTableColumn(
			clearAll,
			setClearAll,
			tableFilters,
			tableSorters
		),
	];
	const actionColumn = RenderActions<ProfiBazaAPIModels.FormPartDto>(
		(text, record) => (
			<>
				{isDraft ? (
					<Space>
						<ProfiBazaTooltip placement="top" title="Edytuj">
							<TableActionButton
								label="Edytuj"
								kind="EDIT"
								onClick={() => {
									setValueEditModalVisible(true);
									setEditedValueId(record.id);
								}}
							/>
						</ProfiBazaTooltip>

						<ProfiBazaTooltip placement="top" title="Usuń">
							<AccessiblePopconfirm
								title={
									<span>
										Czy na pewno chcesz usunąć wskazaną
										wartość?
									</span>
								}
								onConfirm={() => {
									ajaxByUser('Usunięto wartość', () =>
										getProfiBazaApiClient()
											.then((api) =>
												api.formPart.deleteMethod(
													record.id!
												)
											)
											.then(() => {
												refresh();
											})
									);
								}}
							>
								<TableActionButton label="Usuń" kind="DELETE" />
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					</Space>
				) : (
					<></>
				)}
			</>
		)
	);
	columns.push(actionColumn);

	const canAddMoreThanOnePart = [
		FormType.StatementMZ49,
		FormType.AOTMiTFinalReport,
		FormType.StatementMZ53,
		FormType.StatementNPZ,
	];

	return (
		<>
			{state.formValues && (
				<PrimaryCard>
					<div className="mt-small">
						<PageHeaderWithContent
							title="CZĘŚCI FORMULARZA"
							right={
								<>
									{isDraft && (
										<FormValuesModal
											formVersionId={props.formVersionId}
											refresh={refresh}
											id={editedValueId}
											setId={setEditedValueId}
											visible={valueEditModalVisible}
											setVisible={
												setValueEditModalVisible
											}
											type={props.type}
											noButton={
												!(
													(!canAddMoreThanOnePart.includes(
														props.type
													) &&
														state.formValues
															.length === 0) ||
													canAddMoreThanOnePart.includes(
														props.type
													)
												)
											}
										/>
									)}
								</>
							}
						/>
						<ProfiBazaClearFilters
							handleSearch={() => {
								setTableFilters(undefined);
								setClearAll(true);
								setTableSorters(undefined);
							}}
						/>
						{isDraft ? (
							<>
								<DraggableProfiBazaTable
									loading={state.loading}
									columns={columns}
									handleMove={handleMove}
									pagination={false}
									getFilters={(
										f: Record<
											keyof ProfiBazaAPIModels.FormPartDto,
											string[]
										>,
										s: SorterResult<
											ProfiBazaAPIModels.FormPartDto
										>
									) => {
										setTableFilters(f);
										setTableSorters({
											...tableSorters!,
											[s.field as string]: s.order,
										});
									}}
									dataSource={state.formValues}
									setDataSource={setDataSource}
									rowKey={(
										record: ProfiBazaAPIModels.FormPartDto
									) => record.id}
								/>
							</>
						) : (
							<>
								<ProfiBazaTable
									loading={state.loading}
									columns={columns}
									pagination={false}
									onChange={(
										p: AntPagination,
										f: Record<
											keyof ProfiBazaAPIModels.FormPartDto,
											string[]
										>,
										s: SorterResult<
											ProfiBazaAPIModels.FormPartDto
										>
									) => {
										setTableFilters(f);
										setTableSorters({
											...tableSorters!,
											[s.field as string]: s.order,
										});
									}}
									dataSource={state.formValues}
									setDataSource={setDataSource}
									rowKey={(
										record: ProfiBazaAPIModels.FormPartDto
									) => record.id!}
								/>
							</>
						)}
					</div>
				</PrimaryCard>
			)}
		</>
	);
};

export default observer(FormsValuesTable);
