import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<
    ProfiBazaAPIModels.ProposalAccessType,
    string
>([
    [ProfiBazaAPIModels.ProposalAccessType.Administration, 'Administracyjny'],
    [ProfiBazaAPIModels.ProposalAccessType.Normal, 'Normalny'],
]);

export const map = (
    value: ProfiBazaAPIModels.ProposalAccessType
): string => mapings.get(value) ?? '';