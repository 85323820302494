import { StatusCode } from '@helper/statusCode';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import {
	UserManualDownloadResponse,
	UserManualDto,
	UserManualGetManualResponse,
} from '@services/src/models';
import { ProfiBazaAPI } from '@services/src/profiBazaAPI';
import { Button, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import FileSaver from 'file-saver';
import { CenteredRow } from 'layout/CenteredRow';
import { showNotExpectedErrorModal, showWarning } from 'layout/Modals';
import React, { useImperativeHandle, useState } from 'react';

import { getFileNameFromHeaders } from '../helpers/UserManualQueryHelper';
import * as UserManualScrollHelper from '../helpers/UserManualScrollHelper';

interface IProps {
	isPreview: boolean;
}

interface IState {
	blob: Blob | null;
	fileName: string | undefined;
}

export interface IViewUserManualModal {
	showModal: (userManual: UserManualDto) => void;
}

const ViewUserManualModal = React.forwardRef<IViewUserManualModal, IProps>(
	(props, ref) => {
		const [visible, setVisible] = useState<boolean>(false);
		const [pdfFrameKey] = useState<number>(Math.random());
		const [isBusy, setIsBusy] = useState<boolean>(false);
		const [state, setState] = useState<IState>();
		const [abort, setAbort] = useState<AbortController>();

		useImperativeHandle(ref, () => ({
			showModal: (userManual: UserManualDto) => {
				setVisible(true);
				setIsBusy(true);
				getManual(userManual);
			},
		}));

		const chooseGetMethodByMode = (
			api: ProfiBazaAPI,
			userManual: UserManualDto
		): Promise<
			UserManualDownloadResponse | UserManualGetManualResponse
		> => {
			if (props.isPreview) {
				return api.userManual.download(userManual.id!);
			} else {
				const abortController = new AbortController();
				setAbort(abortController);
				return api.userManual.getManual({
					...userManual,
					abortSignal: abortController.signal,
				});
			}
		};

		const getManual = (userManual: UserManualDto) => {
			getProfiBazaApiClient().then((api) =>
				chooseGetMethodByMode(api, userManual)
					.then(
						(result) => {
							if (result && result.blobBody) {
								result.blobBody?.then((blob) => {
									setState({
										fileName: getFileNameFromHeaders(
											result._response.headers
										),
										blob: blob,
									});
								});
							}
						},
						(err) => {
							if (err.statusCode === StatusCode.NotFound) {
								showWarning(
									'Nie znaleziono instrukcji użytkownika dla tego obszaru, proszę upewnić się, że instrukcja została dodana.'
								);
							} else {
								showNotExpectedErrorModal(err);
							}
							setVisible(false);
						}
					)
					.finally(() => {
						setIsBusy(false);
					})
			);
		};

		const Footer = (
			<Button
				hidden={!state?.blob}
				type="primary"
				size="large"
				onClick={() => {
					FileSaver.saveAs(state?.blob!, state?.fileName);
				}}
			>
				Pobierz instrukcję
			</Button>
		);

		return (
			<Modal
				visible={visible}
				maskClosable
				centered
				destroyOnClose
				width="80vw"
				title="Instrukcja użytkownika"
				onCancel={() => {
					setVisible(false);
					abort?.abort();
					setState({ fileName: state?.fileName, blob: null });
				}}
				footer={Footer}
			>
				<Spin tip="Trwa ładowanie.." spinning={isBusy || !state?.blob}>
					<CenteredRow>
						{state?.blob && (
							<iframe
								onLoad={UserManualScrollHelper.resetScroll}
								key={pdfFrameKey}
								src={`/pdfjs/web/viewer.html?file=${URL.createObjectURL(
									state?.blob
								)}`}
								width="100%"
								height="700px"
							></iframe>
						)}
					</CenteredRow>
				</Spin>
			</Modal>
		);
	}
);

export default ViewUserManualModal;
