import useSelectsAccessibility from '@hooks/accessibility/useSelectsAccessibilityHook';
import { Col, Row, Select } from 'antd';
import { FFieldLabel } from 'forms/FormikFormItems';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import * as H from 'history';
import React, { useEffect, useState } from 'react';
import * as SubjectKind from 'services/mappers/SubjectKind';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import RolesTable from './common/RolesTable';
import { RoleDto } from './common/UpdateUserRolesCommand';

interface IState {
	kindSubject?: ProfiBazaAPIModels.KindSubjectDto;
}

interface IProps {
	subjectKind?: ProfiBazaAPIModels.SubjectKind;
	history: H.History;
}

const SubjectKindPermissions: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({});

	useEffect(() => {
		if (!props.subjectKind) {
			setState({});
			return;
		}
		reload();
	}, [props.subjectKind]);

	const reload = () =>
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.kindSubject.getBySubjectKind(props.subjectKind!)
				)
				.then((response) => {
					setState({ kindSubject: response });
				})
		);

	useSelectsAccessibility();

	return (
		<>
			<Row>
				<FFieldLabel
					label="Wybierz kategorię podmiotu"
					for="subjectCategory"
				/>
				<Select
					id="subjectCategory"
					placeholder="Wybierz kategorię"
					value={props.subjectKind}
					onSelect={(category: any) => {
						props.history.push(
							`/admin/permissions/categories/${category}`
						);
					}}
				>
					{Array.from(SubjectKind.mappings.entries()).map((x, i) => (
						<Select.Option key={i} value={x[0]}>
							{x[1]}
						</Select.Option>
					))}
				</Select>
			</Row>
			{state.kindSubject && (
				<Row>
					<Col span={24}>
						<RolesTable
							roles={state.kindSubject.roles!}
							rolePermissions={state.kindSubject.rolePermissions!}
							addRole={(role: RoleDto) =>
								ajaxByUser('Zaktualizowano role.', () =>
									getProfiBazaApiClient().then((api) =>
										api.kindSubject.addRole({
											body: {
												subjectKind: props.subjectKind,
												roleId: role.roleId,
												validFrom: role.validFrom
													?.utc(true)
													.toDate(),
												validTo: role.validTo
													?.utc(true)
													.toDate(),
											},
										})
									)
								).then(() => reload())
							}
							deleteRole={(role: RoleDto) =>
								ajaxByUser('Skasowano rolę.', () =>
									getProfiBazaApiClient().then((api) =>
										api.kindSubject.deleteRole({
											body: {
												subjectKind: props.subjectKind,
												roleId: role.roleId,
											},
										})
									)
								).then(() => reload())
							}
						></RolesTable>
					</Col>
				</Row>
			)}
		</>
	);
};

export default SubjectKindPermissions;
