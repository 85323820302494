import PermissionValidator from '@authorization/permissionValidator';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import Authorized from 'authorization/authorized';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import ProfiBazaTableActions from 'components/shared/paginatedProfiBazaTable/actions/ProfiBazaTableActions';
import IRenderActionModel from 'components/shared/paginatedProfiBazaTable/actions/RenderAction';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { ajaxByUser } from 'helper/api';
import { useRootData } from 'hooks/hook';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { StatementArea, StatementListItemDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

import PaginatedProfiBazaTable from '../../shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import { SieveModel } from '../../shared/paginatedProfiBazaTable/SieveModels';
import { hasEditStatementPermission } from '../helper/EditStatementPermission';
import AccessiblePopconfirm from './AccessiblePopconfirm';
import RenderActions from './RenderActions';
import { StatementExportButtons } from './StatementExportButtons';
import { sideBarFilters } from './StatementTableColumns';

interface IProps {}

const StatementsTable: React.FC<IProps> = (props) => {
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const [loadedTable, setLoadedTable] = useState<boolean>(false);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const [signal, setSignal] = useState<AbortSignal>();
	useEffect(() => {
		const abortController = new AbortController();
		setSignal(abortController.signal);
		return () => {
			abortController.abort();
		};
	}, []);

	const permissionValidator = new PermissionValidator(
		accountDetailsStore.account.get()!
	);

	const columns: ProfiBazaColumnProps<StatementListItemDto>[] = [
		RenderActions<StatementListItemDto>((text, record) => (
			<>
				<ProfiBazaTooltip placement="top" title="Szczegóły">
					<TableActionButton
						label="Szczegóły"
						kind="DETAILS"
						linkTo={`/statement/details/${record.id}`}
					/>
				</ProfiBazaTooltip>
				{record.statementStatus ===
					ProfiBazaAPIModels.StatementStatus.DRAFT &&
					record.subjectId ===
						accountDetailsStore.account.get()?.subject?.id &&
					hasEditStatementPermission(
						permissionValidator,
						record.statementArea!
					) && (
						<ProfiBazaTooltip placement="top" title="Edycja">
							<TableActionButton
								kind="EDIT"
								label="Edycja"
								linkTo={`/statement/edit/${record.id}`}
							/>
						</ProfiBazaTooltip>
					)}
				{(record.statementStatus ==
					ProfiBazaAPIModels.StatementStatus.ACCEPTED ||
					record.statementStatus ==
						ProfiBazaAPIModels.StatementStatus.REJECTED) &&
					hasEditStatementPermission(
						permissionValidator,
						record.statementArea!
					) &&
					record.statementArea == StatementArea.MZ53 && (
						<ProfiBazaTooltip title="Kopiuj sprawozdanie">
							<AccessiblePopconfirm
								title="Czy na pewno chcesz skopiować sprawozdanie do bieżącego roku sprawozdawczego?"
								onConfirm={() => {
									ajaxByUser(
										'Sprawozdanie zostało skopiowane',
										() =>
											getProfiBazaApiClient()
												.then((api) =>
													api.statements.copy({
														body: {
															statementId:
																record.id,
														},
													})
												)
												.then(() => {
													gridStore.searching.set(
														true
													);
												})
									);
								}}
							>
								<TableActionButton
									kind="CLONE"
									label="Kopiuj sprawozdanie"
								/>
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					)}
			</>
		)),
		{
			key: 'preview',
			render: (text: string, record: StatementListItemDto) => (
				<>{'podgląd'}</>
			),
			title: 'Podgląd',
		},
		...tableColumnStore.getTable('statement'),
	];

	const renderAction = (
		actionProps: IRenderActionModel<StatementListItemDto>
	) => (
		<ProfiBazaTableActions<StatementListItemDto>
			actions={[
				{
					action: (selected: Array<string | number>) => (
						<Authorized
							permission={
								ProfiBazaAPIModels.Permission.StatementsArchive
							}
						>
							<ProfiBazaTooltip title="Archiwizuj">
								<AccessiblePopconfirm
									title="Tylko sprawozdania w statusach 'zamknięte', 'odrzucone', 'ponownie zamknięte', 'zaakceptowane' zostaną zarchiwizowane."
									onConfirm={() => {
										ajaxByUser(
											'Zarchiwizowano sprawozdania',
											() =>
												getProfiBazaApiClient()
													.then((api) =>
														api.statements.archive({
															body: {
																ids: selected as string[],
															},
														})
													)
													.then(() => {
														gridStore.searching.set(
															true
														);
													})
										);
									}}
								>
									<TableActionButton
										kind="ARCHIVE"
										label="Archiwizuj"
									/>
								</AccessiblePopconfirm>
							</ProfiBazaTooltip>
						</Authorized>
					),
					isVisible: (
						selectedItems: ProfiBazaAPIModels.StatementListItemDto[]
					) =>
						selectedItems.every(
							(x) =>
								x.statementStatus ===
									ProfiBazaAPIModels.StatementStatus.CLOSED ||
								x.statementStatus ===
									ProfiBazaAPIModels.StatementStatus
										.RECLOSED ||
								x.statementStatus ===
									ProfiBazaAPIModels.StatementStatus
										.REJECTED ||
								x.statementStatus ===
									ProfiBazaAPIModels.StatementStatus.ACCEPTED
						),
				},
				{
					action: (selected: Array<string | number>) => (
						<Authorized
							permission={
								ProfiBazaAPIModels.Permission.StatementsArchive
							}
						>
							<ProfiBazaTooltip title="Przywróć z archiwum">
								<AccessiblePopconfirm
									title="Tylko sprawozdania w statusie 'archiwalne' zostaną przywrócone z archiwum"
									onConfirm={() => {
										ajaxByUser(
											'Sprawozdania zostały przywrócone z archiwum',
											() =>
												getProfiBazaApiClient()
													.then((api) =>
														api.statements.unarchive(
															{
																body: {
																	ids: selected as string[],
																},
															}
														)
													)
													.then(() => {
														gridStore.searching.set(
															true
														);
													})
										);
									}}
								>
									<TableActionButton
										kind="UNARCHIVE"
										label="Przywróć z archiwum"
									/>
								</AccessiblePopconfirm>
							</ProfiBazaTooltip>
						</Authorized>
					),
					isVisible: (
						selectedItems: ProfiBazaAPIModels.StatementListItemDto[]
					) =>
						selectedItems.every(
							(x) =>
								x.statementStatus ===
								ProfiBazaAPIModels.StatementStatus.ARCHIVED
						),
				},
			]}
			getRowKey={actionProps.getRowKey}
			results={actionProps.results}
		>
			<StatementExportButtons actionProps={actionProps} signal={signal} />
		</ProfiBazaTableActions>
	);

	return (
		<PaginatedProfiBazaTable<StatementListItemDto>
			className="main-view"
			gridName="statement"
			detailsUrl="/statement/details"
			columns={columns}
			getRowKey={(r: StatementListItemDto) => r.id!}
			hasRowSelection
			getPagedResult={(
				sieve: SieveModel,
				filter: ProfiBazaAPIModels.StatementFilter,
				abortSignal
			) => {
				const parameters = {
					filters: sieve.filters,
					sorts: sieve.sorts,
					page: sieve.page,
					pageSize: sieve.pageSize,
					filter: filter,
					labels: sieve.labels,
					abortSignal: abortSignal,
				};
				return getProfiBazaApiClient().then((api) => {
					return api.statements
						.all(parameters)
						.then((result: any) => result);
				});
			}}
			renderActions={renderAction}
			isLoaded={() => setLoadedTable(true)}
			sideBarFilters={{
				filters: sideBarFilters(),
				path: 'statements',
			}}
		/>
	);
};

export default StatementsTable;
