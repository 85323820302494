/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/interventionCommentsMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a InterventionComments. */
export class InterventionComments {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a InterventionComments.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionCommentsGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.InterventionCommentsGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.InterventionCommentDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.InterventionCommentDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.InterventionCommentDto>, callback?: msRest.ServiceCallback<Models.InterventionCommentDto>): Promise<Models.InterventionCommentsGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.InterventionCommentsGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionCommentsCreateResponse>
   */
  create(options?: Models.InterventionCommentsCreateOptionalParams): Promise<Models.InterventionCommentsCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.InterventionCommentsCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.InterventionCommentsCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.InterventionCommentsCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.InterventionCommentsCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.InterventionCommentsUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.InterventionCommentsUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.InterventionCommentsUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(options?: Models.InterventionCommentsDeleteMethodOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteMethod(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(options: Models.InterventionCommentsDeleteMethodOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(options?: Models.InterventionCommentsDeleteMethodOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionCommentsAllResponse>
   */
  all(options?: Models.InterventionCommentsAllOptionalParams): Promise<Models.InterventionCommentsAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.InterventionCommentListItemDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.InterventionCommentsAllOptionalParams, callback: msRest.ServiceCallback<Models.InterventionCommentListItemDtoPagedResult>): void;
  all(options?: Models.InterventionCommentsAllOptionalParams | msRest.ServiceCallback<Models.InterventionCommentListItemDtoPagedResult>, callback?: msRest.ServiceCallback<Models.InterventionCommentListItemDtoPagedResult>): Promise<Models.InterventionCommentsAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.InterventionCommentsAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setStatus(options?: Models.InterventionCommentsSetStatusOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setStatus(options: Models.InterventionCommentsSetStatusOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setStatus(options?: Models.InterventionCommentsSetStatusOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setStatusOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/InterventionComments/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.InterventionCommentDto
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "rizp/InterventionComments",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateInterventionCommentCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "rizp/InterventionComments",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateInterventionCommentCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "rizp/InterventionComments",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeleteInterventionCommentCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/InterventionComments",
  queryParameters: [
    Parameters.interventionId0,
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize
  ],
  responses: {
    200: {
      bodyMapper: Mappers.InterventionCommentListItemDtoPagedResult
    },
    default: {}
  },
  serializer
};

const setStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "rizp/InterventionComments/setStatus",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetInterventionCommentStatusCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
