import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const activatePromise = (
	record: ProfiBazaAPIModels.UserNotificationSettingsDTO
) =>
	getProfiBazaApiClient().then((api) =>
		api.userNotificationSettings.activate({
			body: {
				notificationId: record.notificationDefinitionId!,
			},
		})
	);

export const deactivatePromise = (
	record: ProfiBazaAPIModels.UserNotificationSettingsDTO
) =>
	getProfiBazaApiClient().then((api) =>
		api.userNotificationSettings.deactivate({
			body: {
				notificationId: record.notificationDefinitionId!,
			},
		})
	);
export const updateChannelsPromise = (
	record: ProfiBazaAPIModels.UserNotificationSettingsDTO
) =>
	getProfiBazaApiClient().then((api) =>
		api.userNotificationSettings.updateChannels({
			body: {
				notificationId: record.notificationDefinitionId,
				systemChannelActive: record.systemChannelActive,
				emailChannelActive: record.emailChannelActive,
			},
		})
	);
