import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { ProfiBazaAPIModels, Dictionary } from 'services/src/profiBazaAPI';
import { DictionaryName } from 'services/mappers/DictionaryName';
import * as ProposalStatus from 'services/mappers/ProposalStatus';
import React from 'react';
import * as ProposalAccessType from 'services/mappers/ProposalAccessType';
import formatHelpers from 'helper/formatHelpers';

export const columns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.ProposalItemDto
>[] = [
	{
		dataIndex: 'proposalAccesType',
		key: 'proposalAccesType',
		title: 'Rodzaj wniosku',
		filter: 'dictionary',
		filterDictionary: DictionaryName.ProposalAccessType,
		render: (value: ProfiBazaAPIModels.ProposalAccessType) =>
			ProposalAccessType.map(value),
	},
	{
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Podmiot',
		filter: 'contains',
	},
	{
		dataIndex: 'subjectCity',
		key: 'subjectCity',
		title: 'Adres',
		filter: 'contains',
	},
	{
		dataIndex: 'insertDate',
		key: 'insertDate',
		title: 'Data złożenia wniosku',
		filter: 'dateRange',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		dataIndex: 'isAttachment',
		key: 'isAttachment',
		title: 'Czy zawiera załącznik',
		render: (value: boolean) => (value ? 'Tak' : 'Nie'),
	},
	{
		dataIndex: 'status',
		key: 'status',
		title: 'Status',
		filter: 'dictionary',
		filterDictionary: DictionaryName.ProposalStatus,
		render: (value: ProfiBazaAPIModels.ProposalStatus) =>
			ProposalStatus.map(value),
	},
];

export const columnsAdmin: ProfiBazaColumnProps<
	ProfiBazaAPIModels.ProposalItemDto
>[] = [
	{
		sorter: true,
		dataIndex: 'insertDate',
		key: 'insertDate',
		title: 'Data zgłoszenia',
		filter: 'dateRange',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: true,
		dataIndex: 'proposalAccesType',
		key: 'proposalAccesType',
		title: 'Rodzaj wniosku',
		filter: 'dictionary',
		filterDictionary: DictionaryName.ProposalAccessType,
		render: (value: ProfiBazaAPIModels.ProposalAccessType) =>
			ProposalAccessType.map(value),
	},
	{
		sorter: true,
		dataIndex: 'firstName',
		key: 'firstName',
		title: 'Imię',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'lastName',
		key: 'lastName',
		title: 'Nazwisko',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Podmiot',
		filter: 'contains',
	},
	{
		dataIndex: 'isAttachment',
		key: 'isAttachment',
		title: 'Czy zawiera załącznik',
		render: (value: boolean) => (value ? 'Tak' : 'Nie'),
	},
	{
		sorter: true,
		dataIndex: 'status',
		key: 'status',
		title: 'Status',
		filter: 'dictionary',
		filterDictionary: DictionaryName.ProposalStatus,
		render: (value: ProfiBazaAPIModels.ProposalStatus) =>
			ProposalStatus.map(value),
	},
];
