import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<ProfiBazaAPIModels.UserManualInterventionPath, string>([
    [ProfiBazaAPIModels.UserManualInterventionPath.PROHEALTHACTIVITIES, 'Działania prozdrowotne'],
	[ProfiBazaAPIModels.UserManualInterventionPath.RESEARCH, 'Badania naukowe'],
	[ProfiBazaAPIModels.UserManualInterventionPath.SUPERVISIONSANITARY, 'Nadzór sanitarno-epidemiologiczny'],
	[ProfiBazaAPIModels.UserManualInterventionPath.SUPERVISIONMONITORING, 'Monitorowanie i ocena stanu zdrowia populacji'],
	[ProfiBazaAPIModels.UserManualInterventionPath.STAFFEDUCATION, 'Kształcenie kadr ochrony zdrowia'],
	[ProfiBazaAPIModels.UserManualInterventionPath.STAFFMONITORING, 'Monitorowanie i zarządzanie zasobami kadrowymi ochrony zdrowia'],
	[ProfiBazaAPIModels.UserManualInterventionPath.LEGISLATION, 'Legislacja'],
]);

export const map = (value: ProfiBazaAPIModels.UserManualInterventionPath): string =>
	mapings.get(value) ?? '';
