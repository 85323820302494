import {
	SubjectCommentListItemDto,
	SubjectCommentStatus,
} from '@services/src/models';
import _ from 'lodash';
import { observable } from 'mobx';

export const SubjectCommentFields: Record<string, Record<string, string>> = {
	'Ogólne': {
		general: 'Ogólny',
	},
	'Dane podstawowe': {
		subjectName: 'Nazwa podmiotu',
		subjectKind: 'Kategoria',
		parentSubjectName: 'Podlega pod',
		validFrom: 'Data aktywności od',
		validTo: 'Data aktywności do',
		nip: 'NIP',
		regon: 'REGON',
		krsCode: 'KRS',
		cruipCode: 'Kod CRUiP',
		tercCode: 'Kod Teryt',
	},
	'Dane adresowe': {
		addresscity: 'Miejscowość',
		addresszipCode: 'Kod pocztowy',
		addressvoivodeship: 'Województwo',
		addresscommunity: 'Gmina',
		addresscounty: 'Powiat',
		addressstreet: 'Ulica',
		addresspropertyNumber: 'Nr budynku',
		addressplaceNumber: 'Nr lokalu',
	},
	'Dane kontaktowe': {
		firstName: 'Imię',
		lastName: 'Nazwisko',
		position: 'Stanowisko',
		email: 'E-mail',
		phoneNumber: 'Telefon',
	},
};

export const subjectCommentStore = {
	commentRecords: observable.box<
		Record<string, SubjectCommentListItemDto> | undefined
	>(undefined),
	getRecords(): Record<string, SubjectCommentListItemDto> {
		return subjectCommentStore.commentRecords.get()!;
	},
	setRecords(value: SubjectCommentListItemDto[]) {
		subjectCommentStore.commentRecords.set(getUniqueRecords(value));
	},
};

export const createSubjectCommentStore = () => {
	return subjectCommentStore;
};

export type ISubjectCommentStore = ReturnType<typeof createSubjectCommentStore>;

const getUniqueRecords = (
	comments: SubjectCommentListItemDto[]
): Record<string, SubjectCommentListItemDto> => {
	const filteredComments = _.uniqBy(
		_.orderBy(
			comments.filter((x) => x.status !== SubjectCommentStatus.PROCESSED),
			['commentType', 'createdAt'],
			['asc', 'desc']
		),
		'fieldName'
	);

	return filteredComments.reduce(
		(prev: Record<string, SubjectCommentListItemDto>, curr) => {
			prev[curr.fieldName!] = curr;

			return prev;
		},
		{}
	);
};
