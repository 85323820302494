export class SortHelper {
	static compareString(a?: string, b?: string): number {
		if (a && b) {
			return b.localeCompare(a);
		}
		return 0;
	}

	static compareDates(a?: Date, b?: Date) {
		if (a && b) {
			return b.getTime() - a.getTime();
		}
		return 0;
	}

	static compareNumbers(a?: number, b?: number) {
		if (a !== null && a !== undefined && b !== null && b !== undefined) {
			return b - a;
		}

		return 0;
	}
}
