import { Col, Dropdown, Menu, Row, notification } from 'antd';
import { RowProps } from 'antd/lib/row';
import clsx from 'clsx';
import { ajaxByUser } from 'helper/api';
import arrayHelpers from 'helper/arrayHelpers';
import React, { useContext, useRef, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';

import { MultiDictionaryContext } from './contexts/MultiDictionaryContext';
import { DictionaryValuesList, SortableList } from './DictionaryList';

interface IProps {}

const contentRowProps: RowProps = {
	justify: 'space-between',
	gutter: 24,
	style: { margin: 0, minHeight: '100%' },
};

const SortableDictionaryComponent: React.FC<IProps> = (props) => {
	const dictionaryContext = useContext(MultiDictionaryContext);
	const {
		values,
		setValues,
		isEditable,
		levels,
		updateValuePosition,
		globalContextMenuVisible,
		setGlobalContextMenuVisible,
		detachedValue,
		setDetachedValue,
		valueModalProps,
		setActionBaseId,
		rootValue,
		detachedLevel,
		setDetachedLevel,
		updateValuesCollection,
		selectableMode,
		lastSelectedValueId,
	} = dictionaryContext;

	const onSortEnd = ({ oldIndex, newIndex }: any) => {
		if (oldIndex === newIndex) return;

		const tmpArray = [...values];
		updateValuePosition(oldIndex, newIndex, values[oldIndex]);
		arrayHelpers.moveArrayElement(tmpArray, oldIndex, newIndex);
		updateValuesCollection(rootValue?.id, tmpArray);
	};

	const menu = (
		<Menu>
			<Menu.Item
				key="1"
				onClick={(param) => {
					setGlobalContextMenuVisible(false);

					if (valueModalProps) {
						setActionBaseId(rootValue?.id);
						valueModalProps.setVisible(true);
					}
				}}
			>
				Dodaj
			</Menu.Item>
			{detachedValue && rootValue?.id !== detachedLevel?.id && (
				<Menu.Item
					key="2"
					onClick={(param) => {
						setGlobalContextMenuVisible(false);

						ajaxByUser('Wartość została przeniesiona', () => {
							const newParentIndex = rootValue?.id;
							return getProfiBazaApiClient()
								.then((api) =>
									api.dictionaryValue.updateParent({
										body: {
											id: detachedValue?.id,
											parentId: newParentIndex,
										},
									})
								)
								.then(() => {
									const key =
										detachedValue?.id?.toString() ?? 'none';
									notification.close(key);

									const sourceLvlValues = levels
										.getCachedValues(detachedLevel?.id)
										.filter(
											(val) =>
												val.id !== detachedValue?.id
										);
									levels.cacheValues(
										detachedLevel?.id,
										sourceLvlValues
									);

									let updatedValues = [...values];
									const detachedLevelIndex = updatedValues.findIndex(
										(val) => val.id === detachedLevel?.id
									);
									if (detachedLevelIndex !== -1) {
										updatedValues[
											detachedLevelIndex
										].hasChildren =
											sourceLvlValues.length > 0;
									}
									updatedValues.push(detachedValue);
									updateValuesCollection(
										newParentIndex,
										updatedValues
									);
									setDetachedValue(undefined);
									setDetachedLevel(undefined);
								});
						});
					}}
				>
					Przypnij
				</Menu.Item>
			)}
		</Menu>
	);

	const containerRef = useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		containerRef.current?.scrollTo(0, 0);
	}, [values]);

	const previousLevel =
		levels.getPreviousLevel().length >= 1 ? (
			<Col span={8}>
				<DictionaryValuesList
					className="dictionary-list-container"
					items={levels.getPreviousLevel(lastSelectedValueId)}
					previousLevel
				/>
			</Col>
		) : (
			<Col span={0} />
		);

	return (
		<div
			className={clsx(
				'dictionary-container',
				!selectableMode && 'dictionary-container--admin-mode'
			)}
			ref={containerRef}
		>
			{isEditable ? (
				<Dropdown
					overlay={menu}
					trigger={['contextMenu']}
					onVisibleChange={(visible: boolean) => {
						setGlobalContextMenuVisible(visible);
					}}
					visible={globalContextMenuVisible}
				>
					<Row {...contentRowProps}>
						{previousLevel}
						<Col span={16}>
							<SortableList
								className="dictionary-list-container"
								items={values}
								helperClass="z-index-force"
								onSortEnd={onSortEnd}
								isEditable
								pressDelay={150}
							/>
						</Col>
					</Row>
				</Dropdown>
			) : (
				<Row {...contentRowProps}>
					{previousLevel}
					<Col span={16}>
						<DictionaryValuesList
							className="dictionary-list-container"
							items={values}
						/>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default SortableDictionaryComponent;
