import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import moment, { Moment } from 'moment';
import * as DocumentActionKind from 'services/mappers/DocumentActionKind';
import * as DocumentChannelKind from 'services/mappers/DocumentChannelKind';
import { AotmitDetailsDto, DocumentAotmitType,  DocumentDetailsDto, FileTreeItemDto } from 'services/src/models';

export class SourceDocumentDetailsModel {
	createdDate?: Moment;
	/**
	 * Possible values include: 'Added', 'Downloaded', 'Deleted'
	 */
	actionKind?: string;
	subjectName?: string;
	createdBy?: string;
	categoryCodeName?: string;
	/**
	 * Possible values include: 'Form', 'External_System'
	 */
	channelKind?: string;
	fileId?: string;
	file: FileTreeItemDto | undefined;
	numberOfFiles: number = 0;
	tags?: string[] = [];
	documentAotmitType?: DocumentAotmitType;
	aotmitDetails?: AotmitDetailsDto;
	details?: DocumentDetailsDto;
	isVoivodeOpinion?: boolean;
	isArchive?: boolean;

	constructor(model?: ProfiBazaAPIModels.DocumentDto) {
		if (model) {
			if (model?.createdDate) {
				this.createdDate = moment(model.createdDate);
			}
			this.file = model?.file;
			this.numberOfFiles = model?.numberOfFiles!;
			this.actionKind = DocumentActionKind.map(model?.actionKind!);
			this.subjectName = model?.subjectName;
			this.createdBy = model?.createdBy;
			this.channelKind = DocumentChannelKind.map(model?.channelKind!);
			this.fileId = model?.fileId;
			this.categoryCodeName = model?.categoryCodeName;
			this.tags = model.tags ?? [];
			this.aotmitDetails = model.aotmitDetails;
			this.documentAotmitType = model.documentAotmitType;
			this.details = model.details;
			this.isVoivodeOpinion = model.isVoivodeOpinion;
			this.isArchive = model.isArchive;
		}
	}
}
