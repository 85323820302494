/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/aOTMITMockMappers";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a AOTMITMock. */
export class AOTMITMock {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a AOTMITMock.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  createOpinion(options?: Models.AOTMITMockCreateOpinionOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  createOpinion(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createOpinion(options: Models.AOTMITMockCreateOpinionOptionalParams, callback: msRest.ServiceCallback<void>): void;
  createOpinion(options?: Models.AOTMITMockCreateOpinionOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOpinionOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const createOpinionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "external/aotmitmock/CreateOpinion",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.AOTMiTCreatorRequest
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
