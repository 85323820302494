import PermissionValidator from '@authorization/permissionValidator';
import { CommentButton } from '@components/shared/comments/CommentButton';
import AccessibleDrawer from '@components/shared/modals/AccessibleDrawer';
import StatementTags from '@components/tags/StatementTags';
import UserManualViewButton from '@components/userManual/UserManualViewButton';
import { useFocusTrap } from '@hooks/accessibility/useFocusTrapVisibleHook';
import { handleSvgAccessibility } from '@hooks/accessibility/useIconAccessibilityHook';
import { useDrawer } from '@hooks/useDrawerHook';
import * as UserManualStatetmentReportTypeMapper from '@services/mappers/UserManualStatetmentReportType';
import { UserManualType } from '@services/src/models';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Alert, Button, Col, DatePicker, Modal, Row, Space, Spin } from 'antd';
import FormRunner from 'components/orbeon/FormRunner';
import AddButton from 'components/shared/buttons/AddButton';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { FFieldLabel, FInput, FTextArea } from 'forms/FormikFormItems';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import { DateFormat } from 'helper/formatHelpers';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import PrimaryCard from 'layout/PrimaryCard';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as StatementArea from 'services/mappers/StatementArea';
import * as StatementStatus from 'services/mappers/StatementStatus';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { StatementsCorrectResponse } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';
import * as Yup from 'yup';

import {
	CommentsCommand,
	PaginationCommentsCommand,
} from './comments/Commands/CommentsCommand';
import CommentModal from './comments/CommentModal';
import StatementComment from './comments/StatementComment';
import {
	hasCriticalWarningPermission,
	hasEditStatementPermission,
	hasVerifyStatementPermission,
} from './helper/EditStatementPermission';
import { StatementDetailsModel } from './model/StatementDetailsModel';
import AccessiblePopconfirm from './table/AccessiblePopconfirm';

interface IProps {
	statementId: string;
	editable: boolean;
}

interface IState {
	isMzModalVisible: boolean;
	isNpzModalVisible: boolean;
	isModalVisible: boolean;
	commentId: string | undefined;
	editableComment: boolean;
	isBusy: boolean;
}

const StatementData: React.FC<IProps> = (props) => {
	const [statementDetails, setStatementDetails] = useState<
		StatementDetailsModel
	>();
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const [state, setState] = useState<IState>({
		isMzModalVisible: false,
		isNpzModalVisible: false,
		isModalVisible: false,
		commentId: undefined,
		editableComment: false,
		isBusy: false,
	});

	const [selectedPartId, setSelectedPartId] = useState<string>();

	const [commentVisible, showComments, hideComments] = useDrawer();
	const [showGeneratePdfButton, setShowGeneratePdfButton] = useState<boolean>(
		false
	);
	const [addCommentVisible, setAddCommentVisible] = useState<boolean>(false);
	const [commentData, setCommentData] = useState<CommentsCommand>();

	const [focusTrapActive] = useFocusTrap(commentVisible, addCommentVisible);

	const history = useHistory();
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const abortController = new AbortController();
	const signal = abortController.signal;

	const permissionValidator = new PermissionValidator(
		accountDetailsStore.account.get()!
	);

	useEffect(() => {
		refresh();

		return () => {
			accountDetailsStore.preloader.set(undefined);
			abortController.abort();
		};
	}, []);

	const refresh = () => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.statements.getById(props.statementId, {
						abortSignal: signal,
					})
				)
				.then((result) => {
					setStatementDetails(new StatementDetailsModel(result));

					handleSvgAccessibility();
				})
		);
	};

	const loadComments = () => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.statementComments.all({
						sievePageSize: 9999,
						sievePage: 1,
						statementId: props.statementId,
						abortSignal: signal,
					})
				)
				.then(
					(
						response: ProfiBazaAPIModels.StatementCommentListItemDtoPagedResult
					) => {
						setCommentData(
							new CommentsCommand(
								response.results!,
								new PaginationCommentsCommand(response)
							)
						);
					}
				)
		);
	};

	useEffect(() => {
		loadComments();
	}, [props.statementId]);

	return (
		<>
			<CenteredRow>
				<Col span={18}>
					<PageHeaderWithContent
						title={
							statementDetails?.statementArea! ==
							ProfiBazaAPIModels.StatementArea.NPZ
								? StatementArea.map(
										statementDetails?.statementArea!
								  ) +
								  ' ' +
								  statementDetails?.year
								: 'Sprawozdanie ' +
								  StatementArea.map(
										statementDetails?.statementArea!
								  ) +
								  ' ' +
								  statementDetails?.year
						}
						right={
							<Space className="user-manual-header">
								<AccessibleDrawer
									focusTrapActive={focusTrapActive}
									title="Komentarze"
									placement="right"
									closable={false}
									onClose={hideComments}
									visible={commentVisible}
									getContainer={false}
									width={400}
									destroyOnClose
									className="comment-drawer"
								>
									<AddButton
										onClick={() => {
											setAddCommentVisible(true);
											setState({
												...state,
												editableComment: true,
											});
										}}
									>
										Dodaj komentarz
									</AddButton>

									<CommentModal
										statementId={props.statementId}
										commentId={state.commentId}
										statementSubjectId={
											statementDetails?.subjectId!
										}
										visible={addCommentVisible}
										setVisible={(visible: boolean) => {
											setState({
												...state,
												editableComment: false,
												commentId: undefined,
											});
											setAddCommentVisible(visible);
										}}
										onSave={loadComments}
										editable={state.editableComment}
									></CommentModal>
									{commentData && (
										<StatementComment
											statementSubjectId={
												statementDetails?.subjectId!
											}
											data={commentData}
											refresh={loadComments}
											setEditable={(
												value,
												id,
												visible
											) => {
												setState({
													...state,
													editableComment: value,
													commentId: id!,
												});
												setAddCommentVisible(visible);
											}}
											editable={state.editableComment}
										/>
									)}
								</AccessibleDrawer>
								<Link to="/statements">
									<Button aria-label="Wróć" size="large">
										Wróć
									</Button>
								</Link>

								{commentData && (
									<CommentButton
										label="Komentarze sprawozdania"
										commentData={commentData}
										showComments={showComments}
									/>
								)}

								<UserManualViewButton
									userManual={{
										manualType: UserManualType.Statement,
										reportType: UserManualStatetmentReportTypeMapper.mapToUserManualStatetmentReportType(
											statementDetails?.statementArea!
										),
										formVersionId:
											statementDetails?.formVersionId,
									}}
								/>
							</Space>
						}
						wideTitle={true}
					/>

					{statementDetails && (
						<>
							{statementDetails.hasPreview && (
								<Row className="float-right">
									<Col span={24}>
										<Space>
											{showGeneratePdfButton && (
												<Button
													type="primary"
													size="large"
													onClick={() => {
														accountDetailsStore.preloader.set(
															'Trwa generowanie podglądu...'
														);
														ajaxByUser(
															'Pobrano',
															() =>
																getProfiBazaApiClient()
																	.then(
																		(api) =>
																			api.statements.getStatementSummaryPdfById(
																				props.statementId,
																				{
																					abortSignal: signal,
																				}
																			)
																	)
																	.then(
																		(
																			result
																		) => {
																			var blob = new Blob(
																				[
																					result.body,
																				],
																				{
																					type:
																						'application/pdf',
																				}
																			);
																			const obj_url = URL.createObjectURL(
																				blob
																			);
																			window.open(
																				obj_url,
																				'_blank'
																			);
																		}
																	)
																	.finally(
																		() =>
																			accountDetailsStore.preloader.set(
																				undefined
																			)
																	)
														);
													}}
												>
													Podgląd
												</Button>
											)}
											<Link to="/statements">
												<Button
													aria-label="Wróć"
													size="large"
													onClick={() =>
														gridStore.searching.set(
															false
														)
													}
												>
													Wróć
												</Button>
											</Link>
										</Space>
									</Col>
								</Row>
							)}

							<StatementTags
								statementId={props.statementId}
								tags={statementDetails.tags!}
							/>

							<Formik
								initialValues={{ id: statementDetails.id }}
								validateOnChange={false}
								validateOnBlur={true}
								enableReinitialize
								validationSchema={Yup.object().shape({
									statementRejectionComment: Yup.string()
										.nullable(true)
										.required('Komentarz jest wymagany')
										.max(
											1000,
											'Komentarz może mieć maksymalnie 1000 znaków'
										),
								})}
								onSubmit={(
									values: ProfiBazaAPIModels.SetStatementMZRejectedCommand,
									actions: FormikActions<
										ProfiBazaAPIModels.SetStatementMZRejectedCommand
									>
								) => {
									ajaxByUser(
										'Sprawozdanie zostało odrzucone',
										() =>
											getProfiBazaApiClient()
												.then((api) =>
													api.statements.setMzRejected(
														{
															body: values,
														}
													)
												)
												.then(() => {
													actions.resetForm();
													setState({
														...state,
														isMzModalVisible: false,
													});
													setStatementDetails(
														(previousState) => ({
															...previousState,
															statementStatus: StatementStatus.map(
																ProfiBazaAPIModels
																	.StatementStatus
																	.REJECTED
															),
															statementRejectionComment:
																values.statementRejectionComment,
														})
													);
												})
									);
								}}
								render={(
									props: FormikProps<
										ProfiBazaAPIModels.SetStatementMZRejectedCommand
									>
								) => (
									<Modal
										title={'Powód odrzucenia'}
										visible={state.isMzModalVisible}
										okText="Odrzuć"
										onOk={() => {
											props.submitForm();
										}}
										centered
										cancelText="Anuluj"
										onCancel={() => {
											setState({
												...state,
												isMzModalVisible: false,
											});
											props.resetForm();
										}}
										okButtonProps={{
											disabled: props.isSubmitting,
										}}
										cancelButtonProps={{
											disabled: props.isSubmitting,
										}}
									>
										<Row>
											<Col span={24}>
												<FFieldLabel label="Komentarz odrzucenia" />
												<Field
													component={FTextArea}
													label="Komentarz odrzucenia"
													rows={4}
													placeholder="Treść"
													name="statementRejectionComment"
													autoFocus={true}
												/>
											</Col>
										</Row>
									</Modal>
								)}
							></Formik>
							<Formik
								initialValues={{ id: statementDetails.id }}
								validateOnChange={true}
								validateOnBlur={true}
								enableReinitialize
								validationSchema={Yup.object().shape({
									statementRejectionComment: Yup.string()
										.nullable(true)
										.required('Komentarz jest wymagany')
										.max(
											1000,
											'Komentarz może mieć maksymalnie 1000 znaków'
										),
								})}
								onSubmit={(
									values: ProfiBazaAPIModels.SetStatementNPZRejectedCommand,
									actions: FormikActions<
										ProfiBazaAPIModels.SetStatementNPZRejectedCommand
									>
								) => {
									ajaxByUser(
										'Sprawozdanie zostało odrzucone',
										() =>
											getProfiBazaApiClient()
												.then((api) =>
													api.statements.setNpzRejected(
														{
															body: values,
														}
													)
												)
												.then(() => {
													actions.resetForm();
													setState({
														...state,
														isNpzModalVisible: false,
													});
													setStatementDetails(
														(previousState) => ({
															...previousState,
															statementStatus: StatementStatus.map(
																ProfiBazaAPIModels
																	.StatementStatus
																	.REJECTED
															),
															statementRejectionComment:
																values.statementRejectionComment,
														})
													);
												})
									);
								}}
								render={(
									props: FormikProps<
										ProfiBazaAPIModels.SetStatementNPZRejectedCommand
									>
								) => (
									<Modal
										title={'Powód odrzucenia'}
										visible={state.isNpzModalVisible}
										okText="Odrzuć"
										onOk={() => {
											props.submitForm();
										}}
										centered
										cancelText="Anuluj"
										onCancel={() => {
											setState({
												...state,
												isNpzModalVisible: false,
											});
											props.resetForm();
										}}
										okButtonProps={{
											disabled: props.isSubmitting,
										}}
										cancelButtonProps={{
											disabled: props.isSubmitting,
										}}
									>
										<Row>
											<Col span={24}>
												<FFieldLabel label="Komentarz odrzucenia" />
												<Field
													component={FTextArea}
													label="Komentarz odrzucenia"
													rows={4}
													placeholder="Treść"
													name="statementRejectionComment"
													autoFocus={true}
												/>
											</Col>
										</Row>
									</Modal>
								)}
							></Formik>
							{statementDetails.statementStatus ===
								StatementStatus.map(
									ProfiBazaAPIModels.StatementStatus.REJECTED
								) && (
								<PrimaryCard>
									<CenteredRow>
										<Col md={24}>
											<Alert
												message={
													statementDetails.statementRejectionComment
												}
												type="error"
											/>
										</Col>
									</CenteredRow>
								</PrimaryCard>
							)}
							{!statementDetails.hasPreview &&
								!props.editable &&
								statementDetails.statementArea !=
									ProfiBazaAPIModels.StatementArea.MZ53 && (
									<CenteredRow>
										<Row>
											<Col md={24}>
												<Alert
													message="Dokument będzie widoczny po jego zapisaniu przez właściciela sprawozdania."
													type="warning"
												/>
											</Col>
										</Row>
									</CenteredRow>
								)}
							{!props.editable &&
								statementDetails.statementArea ===
									ProfiBazaAPIModels.StatementArea.MZ53 &&
								!statementDetails?.sections?.some(
									(x) => x.documentId === selectedPartId
								) && (
									<CenteredRow>
										<Row>
											<Col md={24}>
												<Alert
													message="Dany dział sprawozdania MZ-53 będzie widoczny po jego zapisaniu przez właściciela sprawozdania."
													type="warning"
												/>
											</Col>
										</Row>
									</CenteredRow>
								)}

							<FormRunner
								mode={props.editable ? 'edit' : 'preview'}
								formVersionId={statementDetails?.formVersionId!}
								documents={statementDetails.sections!.map(
									(x) => ({
										documentId: x.documentId!,
										formPartId: x.formPartId!,
									})
								)}
								pathBase={
									!props.editable
										? `/statement/details/${props.statementId}`
										: `/statement/edit/${props.statementId}`
								}
								query={{ statementId: props.statementId }}
								onFormAdded={refresh}
								onFormPartLoaded={setShowGeneratePdfButton}
								onSelectPart={(partId: string) => {
									setSelectedPartId(partId);
									refresh();
								}}
							></FormRunner>
							<Formik
								initialValues={statementDetails}
								enableReinitialize
								validateOnChange={false}
								validateOnBlur={true}
								onSubmit={(values, actions) => {}}
								render={(
									props: FormikProps<StatementDetailsModel>
								) => (
									<PrimaryCard>
										<CenteredRow>
											<Col md={6}>
												<FFieldLabel
													label="Data utworzenia"
													for="createdAt"
												/>
												<DatePicker
													aria-label="Data utworzenia"
													id="createdAt"
													name="createdAt"
													value={
														props.values.createdAt
													}
													format={DateFormat.Date}
													style={{
														width: '100%',
													}}
													disabled
												/>
											</Col>
											<Col md={6}>
												<FFieldLabel
													label="Data aktualizacji"
													for="updatedAt"
												/>
												<DatePicker
													aria-label="Data aktualizacji"
													placeholder="Brak daty aktualizacji"
													id="updatedAt"
													name="updatedAt"
													value={
														props.values.updatedAt
													}
													format={DateFormat.Date}
													style={{
														width: '100%',
													}}
													showTime
													disabled
												/>
											</Col>
											<Col md={4}>
												<FFieldLabel
													label="Autor"
													for="statementModificationAuthorFullName"
												/>
												<Field
													component={FInput}
													id="statementModificationAuthorFullName"
													name="statementModificationAuthorFullName"
													readOnly
												/>
											</Col>
											<Col md={4}>
												<FFieldLabel
													label="Rodzaj zdarzenia"
													for="statementEventType"
												/>
												<Field
													component={FInput}
													id="statementEventType"
													name="statementEventType"
													readOnly
													validateOnChange
												/>
											</Col>
											<Col md={4}>
												<FFieldLabel
													label="Kanał utworzenia"
													for="creationChannel"
												/>
												<Field
													label="Kanał utworzenia"
													component={FInput}
													id="creationChannel"
													name="creationChannel"
													readOnly
												/>
											</Col>
										</CenteredRow>
									</PrimaryCard>
								)}
							/>
						</>
					)}
					<Row className="float-right">
						<Space>
							<Link to="/statements">
								<Button
									aria-label="Wróć"
									size="large"
									onClick={() =>
										gridStore.searching.set(false)
									}
								>
									Wróć
								</Button>
							</Link>

							{statementDetails?.statementStatus ==
								StatementStatus.map(
									ProfiBazaAPIModels.StatementStatus.DRAFT
								) &&
								(statementDetails?.statementArea ===
									ProfiBazaAPIModels.StatementArea.MZ49 ||
									statementDetails?.statementArea ===
										ProfiBazaAPIModels.StatementArea
											.MZ53) &&
								accountDetailsStore.account.get()?.subject
									?.id === statementDetails.subjectId &&
								hasEditStatementPermission(
									permissionValidator,
									statementDetails.statementArea!
								) && (
									<>
										<AccessiblePopconfirm
											title="Czy chcesz zamknąć sprawozdanie? Zamkniętego sprawozdania nie można już edytować"
											onConfirm={() => {
												accountDetailsStore.preloader.set(
													'Trwa zamykanie sprawozdania...'
												);
												ajaxByUser(
													'Sprawozdanie zostało zamknięte',
													() =>
														getProfiBazaApiClient()
															.then((api) =>
																api.statements.setMzCompleted(
																	{
																		body: {
																			id:
																				props.statementId,
																		},
																		abortSignal: signal,
																	}
																)
															)
															.then(() => {
																setStatementDetails(
																	(
																		previousState
																	) => ({
																		...previousState,
																		statementStatus: StatementStatus.map(
																			ProfiBazaAPIModels
																				.StatementStatus
																				.CLOSED
																		),
																	})
																);
															})
															.then(() => {
																history.replace(
																	`/statement/details/${props.statementId}`
																);
																history.go(0);
															})
															.finally(() =>
																accountDetailsStore.preloader.set(
																	undefined
																)
															)
												);
											}}
											okText="Tak"
											cancelText="Nie"
										>
											<Button
												type="primary"
												aria-label="Zamknij"
												size="large"
											>
												Zamknij
											</Button>
										</AccessiblePopconfirm>
									</>
								)}

							{statementDetails?.statementStatus ==
								StatementStatus.map(
									ProfiBazaAPIModels.StatementStatus.DRAFT
								) &&
								statementDetails?.statementArea ===
									ProfiBazaAPIModels.StatementArea.NPZ &&
								accountDetailsStore.account.get()?.subject
									?.id === statementDetails.subjectId &&
								hasEditStatementPermission(
									permissionValidator,
									statementDetails.statementArea!
								) && (
									<>
										<AccessiblePopconfirm
											title="Czy chcesz zamknąć sprawozdanie? Zamkniętego sprawozdania nie można już edytować"
											onConfirm={() => {
												accountDetailsStore.preloader.set(
													'Trwa zamykanie sprawozdania...'
												);
												ajaxByUser(
													'Sprawozdanie zostało zamknięte',
													() =>
														getProfiBazaApiClient()
															.then((api) =>
																api.statements.setNpzCompleted(
																	{
																		body: {
																			id:
																				props.statementId,
																		},
																		abortSignal: signal,
																	}
																)
															)
															.then(() => {
																setStatementDetails(
																	(
																		previousState
																	) => ({
																		...previousState,
																		statementStatus: StatementStatus.map(
																			ProfiBazaAPIModels
																				.StatementStatus
																				.CLOSED
																		),
																	})
																);
															})
															.then(() => {
																history.replace(
																	`/statement/details/${props.statementId}`
																);
																history.go(0);
															})
															.finally(() =>
																accountDetailsStore.preloader.set(
																	undefined
																)
															)
												);
											}}
											okText="Tak"
											cancelText="Nie"
										>
											<Button
												type="primary"
												aria-label="Zamknij"
												size="large"
											>
												Zamknij
											</Button>
										</AccessiblePopconfirm>
									</>
								)}

							{(statementDetails?.statementArea ===
								ProfiBazaAPIModels.StatementArea.MZ49 ||
								statementDetails?.statementArea ===
									ProfiBazaAPIModels.StatementArea.MZ53) && (
								<>
									{(statementDetails?.statementStatus ===
										StatementStatus.map(
											ProfiBazaAPIModels.StatementStatus
												.CLOSED
										) ||
										statementDetails?.statementStatus ===
											StatementStatus.map(
												ProfiBazaAPIModels
													.StatementStatus.RECLOSED
											) ||
										statementDetails?.statementStatus ===
											StatementStatus.map(
												ProfiBazaAPIModels
													.StatementStatus.REJECTED
											)) &&
										accountDetailsStore.account.get()
											?.subject?.id ===
											statementDetails?.parentSubjectId &&
										(statementDetails?.rejectedBySubjectId ===
											null ||
											accountDetailsStore.account.get()
												?.subject?.id ===
												statementDetails?.rejectedBySubjectId) &&
										hasVerifyStatementPermission(
											permissionValidator,
											statementDetails.statementArea
										) && (
											<Button
												type="primary"
												size="large"
												onClick={() => {
													ajaxByUser(
														'Sprawozdanie zostało zaakceptowane',
														() =>
															getProfiBazaApiClient()
																.then((api) =>
																	api.statements.setMzAccepted(
																		{
																			body: {
																				id:
																					props.statementId,
																			},
																		}
																	)
																)
																.then(() => {
																	setStatementDetails(
																		(
																			previousState
																		) => ({
																			...previousState,
																			statementStatus: StatementStatus.map(
																				ProfiBazaAPIModels
																					.StatementStatus
																					.ACCEPTED
																			),
																		})
																	);
																})
													);
												}}
											>
												Zaakceptuj
											</Button>
										)}

									{(statementDetails?.statementStatus ===
										StatementStatus.map(
											ProfiBazaAPIModels.StatementStatus
												.CLOSED
										) ||
										statementDetails?.statementStatus ===
											StatementStatus.map(
												ProfiBazaAPIModels
													.StatementStatus.RECLOSED
											) ||
										statementDetails?.statementStatus ===
											StatementStatus.map(
												ProfiBazaAPIModels
													.StatementStatus.ACCEPTED
											)) &&
										(hasVerifyStatementPermission(
											permissionValidator,
											statementDetails.statementArea
										) ||
										hasCriticalWarningPermission(
											permissionValidator
										)) && (
											<Button
												type="primary"
												size="large"
												onClick={() => {
													setState({
														...state,
														isMzModalVisible: true,
													});
												}}
											>
												Odrzuć
											</Button>
										)}
								</>
							)}

							{statementDetails?.statementArea ===
								ProfiBazaAPIModels.StatementArea.NPZ && (
								<>
									{(statementDetails?.statementStatus ===
										StatementStatus.map(
											ProfiBazaAPIModels.StatementStatus
												.CLOSED
										) ||
										statementDetails?.statementStatus ===
											StatementStatus.map(
												ProfiBazaAPIModels
													.StatementStatus.RECLOSED
											) ||
										statementDetails?.statementStatus ===
											StatementStatus.map(
												ProfiBazaAPIModels
													.StatementStatus.REJECTED
											)) &&
										accountDetailsStore.account.get()
											?.subject?.id ===
											statementDetails?.parentSubjectId &&
										(statementDetails?.rejectedBySubjectId ===
											null ||
											accountDetailsStore.account.get()
												?.subject?.id ===
												statementDetails?.rejectedBySubjectId) &&
										hasVerifyStatementPermission(
											permissionValidator,
											statementDetails.statementArea
										) && (
											<Button
												type="primary"
												size="large"
												onClick={() => {
													ajaxByUser(
														'Sprawozdanie zostało zaakceptowane',
														() =>
															getProfiBazaApiClient()
																.then((api) =>
																	api.statements.setNpzAccepted(
																		{
																			body: {
																				id:
																					props.statementId,
																			},
																		}
																	)
																)
																.then(() => {
																	setStatementDetails(
																		(
																			previousState
																		) => ({
																			...previousState,
																			statementStatus: StatementStatus.map(
																				ProfiBazaAPIModels
																					.StatementStatus
																					.ACCEPTED
																			),
																		})
																	);
																})
													);
												}}
											>
												Zaakceptuj
											</Button>
										)}

									{(statementDetails?.statementStatus ===
										StatementStatus.map(
											ProfiBazaAPIModels.StatementStatus
												.CLOSED
										) ||
										statementDetails?.statementStatus ===
											StatementStatus.map(
												ProfiBazaAPIModels
													.StatementStatus.RECLOSED
											) ||
										statementDetails?.statementStatus ===
											StatementStatus.map(
												ProfiBazaAPIModels
													.StatementStatus.ACCEPTED
											)) &&
										(hasVerifyStatementPermission(
											permissionValidator,
											statementDetails.statementArea
										) ||
										hasCriticalWarningPermission(
											permissionValidator
										)) && (
											<Button
												type="primary"
												size="large"
												onClick={() => {
													setState({
														...state,
														isNpzModalVisible: true,
													});
												}}
											>
												Odrzuć
											</Button>
										)}
								</>
							)}
							{accountDetailsStore.account.get()?.subject?.id ===
								statementDetails?.subjectId &&
								statementDetails?.statementStatus ===
									StatementStatus.map(
										ProfiBazaAPIModels.StatementStatus
											.REJECTED
									) && (
									<Button
										type="primary"
										size="large"
										onClick={() => {
											ajaxByUser(
												'Sprawozdanie zostało utworzone',
												() =>
													getProfiBazaApiClient()
														.then((api) =>
															api.statements.correct(
																{
																	body: {
																		statementId:
																			props.statementId,
																	},
																}
															)
														)
														.then(
															(
																statement: StatementsCorrectResponse
															) => {
																history.replace(
																	`/statement/edit/${statement.id}`
																);
																history.go(0);
															}
														)
											);
										}}
									>
										Popraw
									</Button>
								)}
						</Space>
					</Row>
				</Col>
			</CenteredRow>
		</>
	);
};

export default StatementData;
