import { Button } from 'antd';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface IProps {
	title: string;
	right?: ReactNode;
	left?: ReactNode;
	goBack?: string;
	wideTitle?: boolean;
}
export class PageHeaderWithContent extends React.PureComponent<IProps> {
	constructor(props: IProps) {
		super(props);
	}

	render() {
		return (
			<div
				className={
					this.props.wideTitle
						? 'page-title page-title-wide'
						: 'page-title'
				}
			>
				<div className="left-content">{this.props.left}</div>
				<h1 style={{fontSize: 20}}>{this.props.title}</h1>
				<div className="right-content">
					{this.props.goBack ? (
						<>
							<Link to={this.props.goBack!}>
								<Button icon={<ArrowLeftOutlined />}>
									Powrót
								</Button>
							</Link>
							{this.props.right}
						</>
					) : (
						this.props.right
					)}
				</div>
			</div>
		);
	}
}