import { Activations } from 'components/notifications/types/Activations';
import { Moment } from 'moment';
import { NotificationDefinitionActivationDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export class CreateNotificationOccurrenceCommand {
	header?: string;
	triggeredWeekBefore?: boolean;
	triggeredDayBefore?: boolean;
	triggeredExactDay?: boolean;
	customTriggerActivation?: boolean;
	customTrigger?: number;
	validDateRange?: [Moment?, Moment?];

	static toCreateCommand(
		model?: CreateNotificationOccurrenceCommand
	): ProfiBazaAPIModels.CreateNotificationOccurrenceCommand {
		let tmpActivations = Array<NotificationDefinitionActivationDto>();
		if (model?.triggeredWeekBefore)
			tmpActivations.push({ dayDifference: Activations.WeekBefore });
		if (model?.triggeredDayBefore)
			tmpActivations.push({ dayDifference: Activations.DayBefore });
		if (model?.triggeredExactDay)
			tmpActivations.push({ dayDifference: Activations.ExactDay });
		if (model?.customTrigger !== undefined && model?.customTrigger !== null)
			tmpActivations.push({
				dayDifference: -Math.abs(model?.customTrigger),
			});

		const command: ProfiBazaAPIModels.CreateNotificationOccurrenceCommand = {
			description: model?.header,
			activations: tmpActivations,
			dateFrom: model?.validDateRange
				? model.validDateRange[0]?.utc(true).toDate()
				: undefined,
			dateTo: model?.validDateRange
				? model.validDateRange[1]?.utc(true).toDate()
				: undefined,
		};

		return command;
	}
}
