import { DictionariesConstants } from 'services/DictionariesConstants';
import { InterventionPath } from 'services/src/models';
import * as Yup from 'yup';

export const InterventionValidationSchema = (
	interventionTypeCodeName: string,
	interventionPath: InterventionPath,
	isPattern: boolean,
	isCloning?: boolean
) =>
	Yup.object().shape({
		confirmation: Yup.boolean().test(
			'confirmation',
			'Pole wymagane',
			(value) => {
				if (
					(interventionTypeCodeName ===
						DictionariesConstants.InterventionType
							.AotmitRecomendation ||
						interventionTypeCodeName ===
							DictionariesConstants.InterventionType
								.AotmitReported) &&
					!isCloning
				) {
					return !!value;
				} else {
					return true;
				}
			}
		),
		interventionPath: Yup.string().required(
			'Ścieżka interwencji jest wymagana!'
		),

		interventionTypeDictionaryValueId: Yup.number().test(
			'interventionTypeDictionaryValueId',
			'Typ interwencji jest wymagany!',
			(value) => {
				if (interventionPath === InterventionPath.PROHEALTHACTIVITIES) {
					return !!value;
				} else {
					return true;
				}
			}
		),
		patternId: isPattern
			? Yup.string().required(
					interventionTypeCodeName ===
						DictionariesConstants.InterventionType
							.AotmitRecomendation
						? 'Tytuł rekomendacji jest wymagany!'
						: 'Tytuł wzorca jest wymagany!'
			  )
			: Yup.string(),
	});
