import moment from 'moment';

const formatPercent = (value: number, approximate: boolean = false) => {
	const formattedValue = approximate ? parseFloat(value.toFixed(2)) : value;
	return `${formattedValue}%`;
};

export const DateFormat = {
	Date: 'DD.MM.YYYY',
	DateTime: 'DD.MM.YYYY, HH:mm:ss',
	Month: 'MM.YYYY',
	DayAndMonth: 'DD.MM'
};

const formatDate = (date?: Date, formatType: string = DateFormat.Date) =>
	date ? moment(date).format(formatType) : null;

const numberRegex = /^-?[0-9][0-9,\.]*$/;

export default {
	formatPercent,
	formatDate,
	numberRegex
};
