import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import moment, { Moment } from 'moment';
import * as StatementStatus from 'services/mappers/StatementStatus';
import { StatementArea, StatementSectionDto } from 'services/src/models';
export class StatementDetailsModel {
	id?: string;
	updatedAt?: Moment;
	createdAt?: Moment;
	codeName?: string;
	year?: number;
	/**
	 * Possible values include: 'NPZ', 'MZ_49', 'MZ_53'
	 */
	statementArea?: StatementArea;
	/**
	 * Possible values include: 'IN_PROGRESS', 'ACCEPTED', 'COMPLETED', 'IN_VERIFICATION',
	 * 'DUPLICATE', 'TO_COMPLETE', 'TO_IMPROVE', 'INCORRECT'
	 */
	statementStatus?: string;
	subjectName?: string;
	subjectId?: string;
	parentSubjectId?: string;
	subjectRegon?: string;
	creationChannel?: string;
	formVersionId?: string;
	sections?: StatementSectionDto[];
	hasPreview?: boolean;
	statementRejectionComment?: string;
	statementModificationAuthorFullName?: string;
	statementEventType?: string;
	rejectedBySubjectId?: string;
	tags?: string[];

	constructor(model?: ProfiBazaAPIModels.StatementDetailsDto) {
		this.id = model?.id;
		this.formVersionId = model?.formVersionId;
		this.updatedAt = model?.updatedAt ? moment(model.updatedAt) : undefined;
		this.createdAt = model?.createdAt ? moment(model.createdAt) : undefined;
		this.codeName = model?.codeName;
		this.year = model?.year;
		this.statementArea = model?.statementArea;
		this.statementStatus = StatementStatus.map(model?.statementStatus!);
		this.subjectName = model?.subjectName;
		this.subjectRegon = model?.subjectRegon;
		this.subjectId = model?.subjectId;
		this.parentSubjectId = model?.parentSubjectId;
		this.sections = model?.sections;
		this.hasPreview = model?.hasPreview;
		this.creationChannel = 'Formularz';
		this.statementRejectionComment = model?.statementRejectionComment;
		this.statementModificationAuthorFullName =
			model?.statementModificationAuthorFullName;
		this.statementEventType = model?.updatedAt
			? 'Aktualizacja'
			: 'Utworzenie';
		this.rejectedBySubjectId = model?.rejectedBySubjectId;
		this.tags = model?.tags ?? [];
	}
}
