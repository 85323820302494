import clsx from 'clsx';
import React from 'react';

interface IProps {
	class: string;
	daysToDeadline: number;
	text: string;
	size?: string;
}

const DashboardCounterItem: React.FC<IProps> = (props) => {
	return props.size === 'large' ? (
		<div className={clsx(props.class, 'counter-item-large')}>
			<p>
				{props.daysToDeadline} dni do {props.text}
			</p>
		</div>
	) : (
		<div className={clsx(props.class, 'counter-item')}>
			<p>
				{props.daysToDeadline} dni do {props.text}
			</p>
		</div>
	);
};

export default DashboardCounterItem;
