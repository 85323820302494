import { handleOnPressEnter } from '@helper/accessibilityHelpers';
import { Col, Modal, Row, Select } from 'antd';
import SubjectsTableModal from 'components/shared/SubjectsTableModal';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FFieldLabel,
	FInputNumber,
	FSelect,
	FSelectSubjects,
} from 'forms/FormikFormItems';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import { useRootData } from 'hooks/hook';
import React, { useState } from 'react';
import * as StatementAreaMap from 'services/mappers/StatementArea';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { FormVersionItemDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';

import { CreateStatementTestCommand } from '../model/CreateStatementTestCommand';
import { addStatementTestValidationSchema } from './AddStatementTestValidationSchema';
const { Option } = Select;

interface IProps {
	visible: boolean;
	onCancel: () => void;
	onCreate: (values: any) => void;
	setVisible: (value: boolean) => void;
}

export const AddStatementTestModal = (props: IProps) => {
	const [subjectVisible, setSubjectVisible] = useState<boolean>(false);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const [formVersions, setFormVersions] = useState<FormVersionItemDto[]>([]);

	const addStatement = (
		values: CreateStatementTestCommand,
		actions: FormikActions<CreateStatementTestCommand>
	) => {
		ajaxByUser('Sprawozdanie zostało dodane', () =>
			getProfiBazaApiClient()
				.then((api) =>
					api.statementsTest.create({
						body: CreateStatementTestCommand.toCommand(values),
					})
				)
				.then(() => {
					props.setVisible(false);
					actions.resetForm();
					gridStore.searching.set(true);
				})
		);
	};

	const onStatementAreaChanged = (
		value: ProfiBazaAPIModels.StatementArea,
		formik: FormikProps<CreateStatementTestCommand>
	) => {
		formik.setFieldValue('formVersionId', undefined);
		setFormVersions([]);
		ajaxCatch(() =>
			getProfiBazaApiClient().then((api) =>
				api.form
					.getByType(StatementAreaMap.mapToFormType(value))
					.then((result) => {
						setFormVersions(result.versions!);
					})
			)
		);
	};

	return (
		<Col span={24}>
			<Formik
				validateOnChange={false}
				validateOnBlur={true}
				initialValues={{}}
				enableReinitialize
				onSubmit={(value, actions) => {
					addStatement(value, actions);
				}}
				validationSchema={addStatementTestValidationSchema}
				render={(formik: FormikProps<CreateStatementTestCommand>) => (
					<Modal
						visible={props.visible}
						destroyOnClose
						centered
						width={'450px'}
						title={'Nowe sprawozdanie testowe'}
						onOk={formik.submitForm}
						onCancel={() => {
							props.setVisible(false);
							formik.resetForm();
						}}
						okText={'Utwórz'}
					>
						<Row>
							<Col span={24}>
								<FFieldLabel label="Rok" />
								<Field
									label="Rok"
									component={FInputNumber}
									precision={0}
									name="year"
								/>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<FFieldLabel label="Rodzaj sprawozdania" />
								<Field
									label="Rodzaj sprawozdania"
									component={FSelect}
									name="statementArea"
									children={Array.from(
										StatementAreaMap.mappings.entries()
									).map((x, i) => (
										<Option key={i} value={x[0]}>
											{x[1]}
										</Option>
									))}
									changeData={(
										v: ProfiBazaAPIModels.StatementArea
									) => onStatementAreaChanged(v, formik)}
								/>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<FFieldLabel label="Wersja formularza" />
								<Field
									label="Wersja formularza"
									component={FSelect}
									name="formVersionId"
									children={formVersions.map((x, i) => (
										<Option key={x.id} value={x.id!}>
											{x.version}
										</Option>
									))}
								/>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<FFieldLabel label="Podmiot" />
								<Field
									label="Podmiot"
									component={FSelectSubjects}
									name="subjectName"
									onClick={() => {
										setSubjectVisible(true);
                                    }}
                                    onKeyUp={handleOnPressEnter(() => {
                                        setSubjectVisible(true)
                                    })}
									dropDown
								/>
							</Col>
						</Row>
						<SubjectsTableModal
							visible={subjectVisible}
							mode="radio"
							proposal={true}
							onCancel={() => {
								setSubjectVisible(false);
							}}
							acceptSubject={(value) => {
								setSubjectVisible(false);
								formik.setFieldValue('subjectId', value?.id);
								formik.setFieldValue(
									'subjectName',
									value?.name
								);
							}}
							showImplementerSponsorFilter={true}
						/>
					</Modal>
				)}
			/>
		</Col>
	);
};
