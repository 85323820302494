import FilterButton from '@components/shared/buttons/FilterButton';
import * as DictionaryInterventionPathMapper from '@services/mappers/DictionaryInterventionPath';
import * as DictionaryStatusMapper from '@services/mappers/DictionaryStatus';
import { Button, Tag } from 'antd';
import {
	ProfiBazaDateRangeFilter,
	ProfiBazaDictionaryFilter,
	ProfiBazaInputFilter,
} from 'components/shared/paginatedProfiBazaTable/filters/ProfiBazaTableFilter';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import formatHelpers from 'helper/formatHelpers';
import React from 'react';
import { DictionaryName } from 'services/mappers/DictionaryName';
import {
	DictionaryInterventionPath,
	DictionaryStatus,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const columns = (
	clearAll: boolean,
	setClearAll: (value: boolean) => void,
	tableFilters:
		| Record<keyof ProfiBazaAPIModels.DictionaryVersionItemDto, string[]>
		| undefined,
	tableSorters:
		| Record<
				keyof ProfiBazaAPIModels.DictionaryVersionItemDto,
				'ascend' | 'descend' | null | undefined
		  >
		| undefined,
	gridName?: string | undefined
) => {
	const columns: ProfiBazaColumnProps<
		ProfiBazaAPIModels.DictionaryVersionItemDto
	>[] = [
		{
			dataIndex: 'version',
			key: 'version',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Wersja
				</Button>
			),
			sorter: (
				a: ProfiBazaAPIModels.DictionaryVersionItemDto,
				b: ProfiBazaAPIModels.DictionaryVersionItemDto
			): number => a.version?.localeCompare(b.version ?? '') ?? 0,
			sortOrder: tableSorters?.version || null,
			filterDropdown: (filterProps) => (
				<ProfiBazaInputFilter<
					ProfiBazaAPIModels.DictionaryVersionItemDto
				>
					columns={columns}
					column="version"
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					filterProps={filterProps}
					gridName={gridName}
				/>
			),
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			filteredValue: tableFilters?.version || null,
			onFilter: (value, record) => {
				return record.version == value;
			},
		},
		{
			dataIndex: 'createdAt',
			key: 'createdAt',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Data utworzenia
				</Button>
			),
			render: (value?: Date) => formatHelpers.formatDate(value),
			width: 150,
			sorter: (
				a: ProfiBazaAPIModels.DictionaryVersionItemDto,
				b: ProfiBazaAPIModels.DictionaryVersionItemDto
			): number => b.createdAt!.getTime() - a.createdAt!.getTime(),
			sortOrder: tableSorters?.createdAt || null,
			filterDropdown: (filterProps) => (
				<ProfiBazaDateRangeFilter<
					ProfiBazaAPIModels.DictionaryVersionItemDto
				>
					columns={columns}
					column="createdAt"
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					filterProps={filterProps}
					gridName={gridName}
				/>
			),
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			filteredValue: tableFilters?.createdAt || null,
			onFilter: (value, record) => {
				return (
					new Date(
						new Date(record.createdAt!).toDateString()
					).getTime() >=
						new Date(
							new Date(
								columns
									.find((x) => x.key == 'createdAt')
									?.filteredValue?.[0]?.toString()!
							).toDateString()
						).getTime() &&
					new Date(
						new Date(record.createdAt!).toDateString()
					).getTime() <=
						new Date(
							new Date(
								columns
									.find((x) => x.key == 'createdAt')
									?.filteredValue?.[1]?.toString()!
							).toDateString()
						).getTime()
				);
			},
		},
		{
			dataIndex: 'validFrom',
			key: 'validFrom',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Data od
				</Button>
			),
			render: (value?: Date) => formatHelpers.formatDate(value),
			sortOrder: tableSorters?.validFrom || null,
			sorter: (
				a: ProfiBazaAPIModels.DictionaryVersionItemDto,
				b: ProfiBazaAPIModels.DictionaryVersionItemDto
			): number =>
				(b.validFrom?.getTime() ?? 0) - (a.validFrom?.getTime() ?? 0),
			filterDropdown: (filterProps) => (
				<ProfiBazaDateRangeFilter<
					ProfiBazaAPIModels.DictionaryVersionItemDto
				>
					columns={columns}
					column="validFrom"
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					filterProps={filterProps}
					gridName={gridName}
				/>
			),
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			filteredValue: tableFilters?.validFrom || null,
			onFilter: (value, record) => {
				return (
					new Date(
						new Date(record.validFrom!).toDateString()
					).getTime() >=
						new Date(
							new Date(
								columns
									.find((x) => x.key == 'validFrom')
									?.filteredValue?.[0]?.toString()!
							).toDateString()
						).getTime() &&
					new Date(
						new Date(record.validFrom!).toDateString()
					).getTime() <=
						new Date(
							new Date(
								columns
									.find((x) => x.key == 'validFrom')
									?.filteredValue?.[1]?.toString()!
							).toDateString()
						).getTime()
				);
			},
		},
		{
			dataIndex: 'validTo',
			key: 'validTo',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Data do
				</Button>
			),
			sortOrder: tableSorters?.validTo || null,
			render: (value?: Date) => formatHelpers.formatDate(value),
			sorter: (
				a: ProfiBazaAPIModels.DictionaryVersionDto,
				b: ProfiBazaAPIModels.DictionaryVersionDto
			): number =>
				(b.validTo?.getTime() ?? 0) - (a.validTo?.getTime() ?? 0),
			filterDropdown: (filterProps) => (
				<ProfiBazaDateRangeFilter<
					ProfiBazaAPIModels.DictionaryVersionDto
				>
					columns={columns}
					column="validTo"
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					filterProps={filterProps}
					gridName={gridName}
				/>
			),
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			filteredValue: tableFilters?.validTo || null,
			onFilter: (value, record) => {
				return (
					new Date(
						new Date(record.validTo!).toDateString()
					).getTime() >=
						new Date(
							new Date(
								columns
									.find((x) => x.key == 'validTo')
									?.filteredValue?.[0]?.toString()!
							).toDateString()
						).getTime() &&
					new Date(
						new Date(record.validTo!).toDateString()
					).getTime() <=
						new Date(
							new Date(
								columns
									.find((x) => x.key == 'validTo')
									?.filteredValue?.[1]?.toString()!
							).toDateString()
						).getTime()
				);
			},
		},
		{
			dataIndex: 'status',
			key: 'status',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Status
				</Button>
			),
			sortOrder: tableSorters?.status || null,
			width: 100,
			render: (value?: DictionaryStatus) => {
				let color =
					value === DictionaryStatus.ACTIVE
						? 'green'
						: value === DictionaryStatus.INACTIVE
						? 'volcano'
						: 'blue';
				const statusValue = DictionaryStatusMapper.map(value!);
				return <Tag color={color}>{statusValue}</Tag>;
			},
			filterDictionary: DictionaryName.DictionaryVersionStatus,
			onFilter: (
				value,
				record: ProfiBazaAPIModels.DictionaryVersionDto
			) => {
				return record.status === value;
			},
			filterDropdown: (filterProps) => (
				<ProfiBazaDictionaryFilter<
					ProfiBazaAPIModels.DictionaryVersionDto
				>
					columns={columns}
					column="status"
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					filterProps={filterProps}
					gridName={gridName}
				/>
			),
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			filteredValue: tableFilters?.status || null,
		},
		{
			dataIndex: 'interventionPaths',
			key: 'interventionPaths',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Ścieżki interwencji
				</Button>
			),
			render: (values?: DictionaryInterventionPath[]) => {
				return (
					<>
						{values?.map((interventionPath, i) => (
							<Tag key={`interventionPath-${i}`}>
								{DictionaryInterventionPathMapper.map(
									interventionPath!
								)}
							</Tag>
						))}
					</>
				);
			},
			onFilter: (
				value,
				record: ProfiBazaAPIModels.DictionaryVersionItemDto
			) => {
				return record.interventionPaths
					? record.interventionPaths.some((x) => x == value)
					: false;
			},
			filterDictionary: DictionaryName.DictionaryInterventionPath,
			filterDropdown: (filterProps) => (
				<ProfiBazaDictionaryFilter<
					ProfiBazaAPIModels.DictionaryVersionDto
				>
					columns={columns}
					column="interventionPaths"
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					filterProps={filterProps}
					gridName={gridName}
				/>
			),
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			filteredValue: tableFilters?.interventionPaths || null,
		},
	];
	return columns;
};

export default columns;
