import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import * as DictionaryInterventionPathMapper from '@services/mappers/DictionaryInterventionPath';

const initializeProcessColumns = () => {
	const initializedColumns: ProfiBazaColumnProps<
		ProfiBazaAPIModels.DictionaryValueItemDto
	>[] = [];
	DictionaryInterventionPathMapper.mappings.forEach((value, key) => {
		const stringifiedKey = key.toString();
		initializedColumns.push({
			dataIndex: stringifiedKey,
			key: stringifiedKey,
			title: value,
			render: (
				text: string,
				record: ProfiBazaAPIModels.DictionaryValueItemDto,
				index: number
			) => {
				const hasValueInProcess = record.interventionPaths?.includes(
					key
				);
				return hasValueInProcess ? 'x' : null;
			},
		});
	});
	return initializedColumns;
};

const columns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.DictionaryValueItemDto
>[] = [
	{
		dataIndex: 'codeName',
		key: 'codeName',
		title: 'Id',
		width: 100,
	},
	{
		dataIndex: 'value',
		key: 'value',
		title: 'Wartość',
	},
	{
		dataIndex: 'code',
		key: 'code',
		title: 'Numeracja',
	},
	{
		dataIndex: 'canAddTextValue',
		key: 'canAddTextValue',
		title: 'Wartość inna',
		render: (value?: boolean) => (value ? 'tak' : 'nie'),
	},
	...initializeProcessColumns(),
];

export default columns;
