import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { CommentType } from 'services/src/models';

export class AddCommentDto {
	statementId?: string;
	statementPartInterventionCode?: string;
	column?: string;
	row?: string;
	description?: string;
	commentType?: CommentType;

	constructor(model: ProfiBazaAPIModels.CreateCommentCommand) {
		this.statementId = model.statementId;
		this.statementPartInterventionCode =
			model.statementPartInterventionCode;
		this.column = model.column;
		this.row = model.row;
		this.description = model.description;
		this.commentType = model.commentType;
	}

	static toCommand(
		model: AddCommentDto
	): ProfiBazaAPIModels.CreateCommentCommand {
		const command: ProfiBazaAPIModels.CreateCommentCommand = {
			statementId: model.statementId,
			statementPartInterventionCode: model.statementPartInterventionCode,
			column: model.column,
			row: model.row,
			description: model.description,
			commentType: model.commentType,
		};
		return command;
	}
}
