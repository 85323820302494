import PermissionValidator from '@authorization/permissionValidator';
import { notificationStore } from '@components/notifications/notificationStore';
import { Permission } from '@services/src/models';
import { autorun } from 'mobx';
import { useLocalStore } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { interventionCommentStore } from '../../src/components/intervention/comments/store/InterventionCommentStore';
import { subjectCommentStore } from '../../src/components/subjects/comments/SubjectCommentStore';
import { accountDetailsStore } from '../account/accountDetailsStore';
import { calendarStore } from '../components/calendar/calendarStore';
import { dictionaryStore } from '../components/dictionary/dictionaryStore';
import { gridStore } from './GridStore';
import { rizpDictionaryStore } from './RizpDictionaryStore';
import { tableColumnStore } from './TableColumnStore';
import { tagStore } from './TagStore';
import { tooltipStore } from './TooltipStore';

const createStores = () => ({
	accountDetailsStore,
	gridStore,
	tableColumnStore,
	dictionaryStore,
	calendarStore,
	tooltipStore,
	subjectCommentStore,
	interventionCommentStore,
	tagStore,
	notificationStore,
	rizpDictionaryStore
});

export type IStores = ReturnType<typeof createStores>;
export const storeContext = React.createContext<IStores | null>(null);

export const StoreProvider: React.FC = ({ children }) => {
	const store = useLocalStore(createStores);

	useEffect(() => {
		autorun(() => {
			if (store.accountDetailsStore.oidcUser.get()) {
				store.accountDetailsStore.initialize(() => {
					const permissionValidator = new PermissionValidator(
						accountDetailsStore!.account.get()!
					);
					if (
						permissionValidator.hasAny([
							Permission.TagsManagementGlobal,
							Permission.TagsManagementLocal,
						])
					) {
						store.tagStore.initialize();
					}
				});
			}
		});
	}, [store.accountDetailsStore.oidcUser.get()]);

	return (
		<storeContext.Provider value={store}>{children}</storeContext.Provider>
	);
};

export default StoreProvider;
