import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';

export class UpdateSubjectCommentDto {
	commentId?: string;
	fieldName?: string;
	description?: string;
	status?: ProfiBazaAPIModels.SubjectCommentStatus;

	constructor(model: ProfiBazaAPIModels.SubjectCommentDto, id: string) {
		this.commentId = id;
		this.fieldName = model.fieldName;
		this.description = model.description;
		this.status = model.status;
	}

	static toCommand(
		model: UpdateSubjectCommentDto
	): ProfiBazaAPIModels.UpdateSubjectCommentCommand {
		const command: ProfiBazaAPIModels.UpdateSubjectCommentCommand = {
			commentId: model.commentId,
			fieldName: model.fieldName,
			description: model.description,
		};
		return command;
	}
}
