import NotificationSidebar from '@components/notifications/sidebar/NotificationSidebar';
import UserManualMenu from '@components/userManual/UserManualMenu';
import { useRootData } from 'hooks/hook';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { IAccountDetailsStore } from '../../account/accountDetailsStore';
import AccessibilitySettings from '../AccessibilitySettings';
import AccountMenu from './AccountMenu';

interface IAccountProps {}
interface IState {
	userFullName?: string;
}
const Account: React.FC<IAccountProps & any> = observer(() => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const [state, setState] = useState<IState>({
		userFullName: undefined
	});

	useEffect(() => {
		setState({userFullName: accountDetailsStore.account.get()?.displayName})
	},[accountDetailsStore.account.get()])

	function handleUserFullNameChange(newFullName: string): void{
		setState({userFullName: newFullName})
	}

	return (
		<div className="account">
			<UserManualMenu />
			{accountDetailsStore.oidcUser.get() && <NotificationSidebar />}
			<AccessibilitySettings />
			<AccountMenu
				userFullName={state.userFullName}
				onUserFullNameChange={handleUserFullNameChange}
			/>
		</div>
	);
});

export default Account;
