/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/statementsMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Statements. */
export class Statements {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Statements.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.StatementsGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.StatementDetailsDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.StatementDetailsDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.StatementDetailsDto>, callback?: msRest.ServiceCallback<Models.StatementDetailsDto>): Promise<Models.StatementsGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.StatementsGetByIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsGetStatementSummaryPdfByIdResponse>
   */
  getStatementSummaryPdfById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.StatementsGetStatementSummaryPdfByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getStatementSummaryPdfById(id: string, callback: msRest.ServiceCallback<Uint8Array>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getStatementSummaryPdfById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Uint8Array>): void;
  getStatementSummaryPdfById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Uint8Array>, callback?: msRest.ServiceCallback<Uint8Array>): Promise<Models.StatementsGetStatementSummaryPdfByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getStatementSummaryPdfByIdOperationSpec,
      callback) as Promise<Models.StatementsGetStatementSummaryPdfByIdResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsAllResponse>
   */
  all(options?: Models.StatementsAllOptionalParams): Promise<Models.StatementsAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.StatementListItemDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.StatementsAllOptionalParams, callback: msRest.ServiceCallback<Models.StatementListItemDtoPagedResult>): void;
  all(options?: Models.StatementsAllOptionalParams | msRest.ServiceCallback<Models.StatementListItemDtoPagedResult>, callback?: msRest.ServiceCallback<Models.StatementListItemDtoPagedResult>): Promise<Models.StatementsAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.StatementsAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsCreateResponse>
   */
  create(options?: Models.StatementsCreateOptionalParams): Promise<Models.StatementsCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.StatementsCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.StatementsCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.StatementsCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.StatementsCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  archive(options?: Models.StatementsArchiveOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  archive(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  archive(options: Models.StatementsArchiveOptionalParams, callback: msRest.ServiceCallback<void>): void;
  archive(options?: Models.StatementsArchiveOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      archiveOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unarchive(options?: Models.StatementsUnarchiveOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  unarchive(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  unarchive(options: Models.StatementsUnarchiveOptionalParams, callback: msRest.ServiceCallback<void>): void;
  unarchive(options?: Models.StatementsUnarchiveOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      unarchiveOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setMzCompleted(options?: Models.StatementsSetMzCompletedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setMzCompleted(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setMzCompleted(options: Models.StatementsSetMzCompletedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setMzCompleted(options?: Models.StatementsSetMzCompletedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setMzCompletedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setMzAccepted(options?: Models.StatementsSetMzAcceptedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setMzAccepted(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setMzAccepted(options: Models.StatementsSetMzAcceptedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setMzAccepted(options?: Models.StatementsSetMzAcceptedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setMzAcceptedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setMzRejected(options?: Models.StatementsSetMzRejectedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setMzRejected(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setMzRejected(options: Models.StatementsSetMzRejectedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setMzRejected(options?: Models.StatementsSetMzRejectedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setMzRejectedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setNpzCompleted(options?: Models.StatementsSetNpzCompletedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setNpzCompleted(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setNpzCompleted(options: Models.StatementsSetNpzCompletedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setNpzCompleted(options?: Models.StatementsSetNpzCompletedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setNpzCompletedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setNpzAccepted(options?: Models.StatementsSetNpzAcceptedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setNpzAccepted(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setNpzAccepted(options: Models.StatementsSetNpzAcceptedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setNpzAccepted(options?: Models.StatementsSetNpzAcceptedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setNpzAcceptedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setNpzRejected(options?: Models.StatementsSetNpzRejectedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setNpzRejected(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setNpzRejected(options: Models.StatementsSetNpzRejectedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setNpzRejected(options?: Models.StatementsSetNpzRejectedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setNpzRejectedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsUploadAttachmentResponse>
   */
  uploadAttachment(options?: Models.StatementsUploadAttachmentOptionalParams): Promise<Models.StatementsUploadAttachmentResponse>;
  /**
   * @param callback The callback
   */
  uploadAttachment(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadAttachment(options: Models.StatementsUploadAttachmentOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  uploadAttachment(options?: Models.StatementsUploadAttachmentOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.StatementsUploadAttachmentResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      uploadAttachmentOperationSpec,
      callback) as Promise<Models.StatementsUploadAttachmentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  importStatus(options?: Models.StatementsImportStatusOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  importStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  importStatus(options: Models.StatementsImportStatusOptionalParams, callback: msRest.ServiceCallback<void>): void;
  importStatus(options?: Models.StatementsImportStatusOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      importStatusOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsGetFileDetailsResponse>
   */
  getFileDetails(options?: Models.StatementsGetFileDetailsOptionalParams): Promise<Models.StatementsGetFileDetailsResponse>;
  /**
   * @param callback The callback
   */
  getFileDetails(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFileDetails(options: Models.StatementsGetFileDetailsOptionalParams, callback: msRest.ServiceCallback<any>): void;
  getFileDetails(options?: Models.StatementsGetFileDetailsOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.StatementsGetFileDetailsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getFileDetailsOperationSpec,
      callback) as Promise<Models.StatementsGetFileDetailsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsGetFileListResponse>
   */
  getFileList(options?: Models.StatementsGetFileListOptionalParams): Promise<Models.StatementsGetFileListResponse>;
  /**
   * @param callback The callback
   */
  getFileList(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFileList(options: Models.StatementsGetFileListOptionalParams, callback: msRest.ServiceCallback<any>): void;
  getFileList(options?: Models.StatementsGetFileListOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.StatementsGetFileListResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getFileListOperationSpec,
      callback) as Promise<Models.StatementsGetFileListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsGetFileFlatListResponse>
   */
  getFileFlatList(options?: Models.StatementsGetFileFlatListOptionalParams): Promise<Models.StatementsGetFileFlatListResponse>;
  /**
   * @param callback The callback
   */
  getFileFlatList(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFileFlatList(options: Models.StatementsGetFileFlatListOptionalParams, callback: msRest.ServiceCallback<any>): void;
  getFileFlatList(options?: Models.StatementsGetFileFlatListOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.StatementsGetFileFlatListResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getFileFlatListOperationSpec,
      callback) as Promise<Models.StatementsGetFileFlatListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsGetSingleStatementExportResponse>
   */
  getSingleStatementExport(options?: Models.StatementsGetSingleStatementExportOptionalParams): Promise<Models.StatementsGetSingleStatementExportResponse>;
  /**
   * @param callback The callback
   */
  getSingleStatementExport(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getSingleStatementExport(options: Models.StatementsGetSingleStatementExportOptionalParams, callback: msRest.ServiceCallback<any>): void;
  getSingleStatementExport(options?: Models.StatementsGetSingleStatementExportOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.StatementsGetSingleStatementExportResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getSingleStatementExportOperationSpec,
      callback) as Promise<Models.StatementsGetSingleStatementExportResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsCopyResponse>
   */
  copy(options?: Models.StatementsCopyOptionalParams): Promise<Models.StatementsCopyResponse>;
  /**
   * @param callback The callback
   */
  copy(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  copy(options: Models.StatementsCopyOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  copy(options?: Models.StatementsCopyOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.StatementsCopyResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      copyOperationSpec,
      callback) as Promise<Models.StatementsCopyResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsCorrectResponse>
   */
  correct(options?: Models.StatementsCorrectOptionalParams): Promise<Models.StatementsCorrectResponse>;
  /**
   * @param callback The callback
   */
  correct(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  correct(options: Models.StatementsCorrectOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  correct(options?: Models.StatementsCorrectOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.StatementsCorrectResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      correctOperationSpec,
      callback) as Promise<Models.StatementsCorrectResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/Statements/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.StatementDetailsDto
    },
    default: {}
  },
  serializer
};

const getStatementSummaryPdfByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/Statements/summary/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "ByteArray"
        }
      }
    },
    default: {}
  },
  serializer
};

const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/Statements",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0,
    Parameters.filter4,
    Parameters.labels0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.StatementListItemDtoPagedResult
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "statements/Statements",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateStatementCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const archiveOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "statements/Statements/archive",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ArchiveStatementCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const unarchiveOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "statements/Statements/unarchive",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UnarchiveStatementCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setMzCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "statements/Statements/completeMz",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetStatementMZCompletedCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setMzAcceptedOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "statements/Statements/acceptMz",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetStatementMZAcceptedCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setMzRejectedOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "statements/Statements/rejectMz",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetStatementMZRejectedCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setNpzCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "statements/Statements/completeNpz",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetStatementNPZCompletedCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setNpzAcceptedOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "statements/Statements/acceptNpz",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetStatementNPZAcceptedCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setNpzRejectedOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "statements/Statements/rejectNpz",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetStatementNPZRejectedCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadAttachmentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "statements/Statements/uploadAttachment",
  formDataParameters: [
    Parameters.id3,
    Parameters.file,
    Parameters.fileCategoryDictionaryValueId,
    Parameters.fileCategoryDictionaryOtherValue
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const importStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "statements/Statements/importStatus",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ImportStatusCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getFileDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/Statements/export/details",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.labels1,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    400: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getFileListOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/Statements/export/list",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.labels1,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    400: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getFileFlatListOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/Statements/export/flat",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.labels1,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    400: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getSingleStatementExportOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/Statements/export/single",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.labels1,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    400: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const copyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "statements/Statements/copy",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CopyStatementCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const correctOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "statements/Statements/correct",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CorrectStatementCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};
