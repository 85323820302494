import { ajaxCatchSilently } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import React from 'react';

import Tags from './Tags';

interface IProps {
	statementId: string;
	tags: string[];
}

const StatementTags: React.FC<IProps> = (props) => {
	const addTag = (
		tag: string,
		onSuccess: () => void,
		onError: () => void
	) => {
		ajaxCatchSilently(
			() =>
				getProfiBazaApiClient().then((api) =>
					api.statementsTag.addTag({
						body: {
							statementId: props.statementId,
							tagName: tag,
						},
					})
				),
			onSuccess,
			onError
		);
	};

	const removeTag = (tag: string, onError: () => void) => {
		ajaxCatchSilently(
			() =>
				getProfiBazaApiClient().then((api) =>
					api.statementsTag.deleteTag({
						body: {
							statementId: props.statementId,
							tagName: tag,
						},
					})
				),
			undefined,
			onError
		);
	};

	return (
		<Tags addTag={addTag} removeTag={removeTag} tags={props.tags}></Tags>
	);
};

export default StatementTags;
