import { Col, Modal, Select } from 'antd';
import { DictionaryValueType } from 'components/dictionary/dictionaryIntializer';
import { IDictionaryStore } from 'components/dictionary/dictionaryStore';
import SubjectsTableModal from 'components/shared/SubjectsTableModal';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FDatePicker,
	FFieldLabel,
	FInputNumber,
	FRangePicker,
	FSelect,
	FSelectSubjects,
	FTextArea,
} from 'forms/FormikFormItems';
import { ajaxByUser } from 'helper/api';
import { DateFormat } from 'helper/formatHelpers';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DictionaryName } from 'services/mappers/DictionaryName';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { SubjectReportingProcessDto } from 'services/src/models';

import {
	SubjectReportingProcessCommand,
	createSubjectReportingProcess,
	updateSubjectReportingProcess,
} from '../model/SubjectReportingProcessCommands';
import { SubjectReportingProcessValidationSchema } from '../Validators/SubjectReportingProcessValidation';

interface IProps {
	visible: boolean;
	editable?: boolean;
	isSubjectEditable: boolean;
	data?: SubjectReportingProcessDto;
	id?: string;
	onCreate: () => void;
	mode?: 'subject' | 'category';
	onCancel: () => void;
}

const SubjectReportingProcessModal: React.FC<IProps> = (props) => {
	const { visible, onCancel, editable, onCreate } = props; //, onCreate, onChange
	const [subjectVisible, setSubjectVisible] = useState<boolean>(false);
	const [mode, setMode] = useState<'subject' | 'category'>('subject');
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);
	const subjectDic = dictionaryStore.getBuildInDictionary(
		DictionaryName.SubjectKind
	).values;
	const reportingDic = dictionaryStore.getBuildInDictionary(
		DictionaryName.ReportingArea
	).values;

	//ReportingArea
	const formikRef = useRef<Formik<SubjectReportingProcessDto>>(null); //SubjectReportingProcessDto//SubjectReportingProcessCommand

	useEffect(() => {
		if (props.mode) setMode(props.mode!);
	}, [props.mode]);

	const onSubmit = (
		values: SubjectReportingProcessDto,
		actions: FormikActions<SubjectReportingProcessCommand>
	) => {
		if (values.id) {
			ajaxByUser(
				'Zmieniono obowiązek sprawozdawczy',
				() =>
					getProfiBazaApiClient()
						.then((api) =>
							api.subjectReportingProcess.update({
								body: updateSubjectReportingProcess(values),
							})
						)
						.then(() => {
							onCreate();
							setMode('subject');
							actions.resetForm();
						}),
				() => {},
				(errors) => {
					actions.setErrors(errors);
				}
			).then(() => actions.setSubmitting(false));
		} else {
			ajaxByUser(
				'Dodano obowiązek sprawozdawczy',
				() =>
					getProfiBazaApiClient()
						.then((api) =>
							api.subjectReportingProcess.create({
								body: createSubjectReportingProcess(values),
							})
						)
						.then(() => {
							onCreate();
							setMode('subject');
							actions.resetForm();
						}),
				() => {},
				(errors) => {
					actions.setErrors(errors);
				}
			).then(() => actions.setSubmitting(false));
		}
	};

	const getModalTitle = (): string => {
		if (!props.editable) {
			return 'Podgląd';
		}

		return props?.id ? 'Edycja' : 'Dodawanie';
	};

	return (
		<>
			<Formik
				validateOnChange={false}
				initialValues={props.data || {}}
				enableReinitialize={true}
				validationSchema={SubjectReportingProcessValidationSchema}
				onSubmit={(values, actions) => {
					onSubmit(values, actions);
					//actions.resetForm();
				}}
				render={(formik: FormikProps<SubjectReportingProcessDto>) => (
					<>
						<Modal
							visible={visible}
							destroyOnClose
							centered
							okButtonProps={{ disabled: formik.isSubmitting }}
							cancelButtonProps={{
								disabled: formik.isSubmitting,
							}}
							width={'50%'}
							title={getModalTitle()}
							onOk={() => {
								if (props.editable) formik.submitForm();
								else {
									onCancel();
									formik.resetForm();
									setMode('subject');
								}
							}}
							onCancel={() => {
								onCancel();
								formik.resetForm();
								setMode('subject');
							}}
							okText={props.editable ? 'Zatwierdź' : 'Ok'}
						>
							<CenteredRow>
								<Col span={10}>
									{!props.mode && !props.id && (
										<CenteredRow>
											<Col span={24}>
												<FFieldLabel
													label="Typ"
													for="type"
												/>
												<Select
													defaultValue={mode}
													id="type"
													aria-label="Typ"
													onChange={(
														value:
															| 'subject'
															| 'category'
													) => {
														setMode(value);
														formik.setFieldValue(
															'subjectName',
															undefined
														);
														formik.setFieldValue(
															'subjectKind',
															undefined
														);
													}}
												>
													<Select.Option value="subject">
														Podmiot
													</Select.Option>
													<Select.Option value="category">
														Kategorie podmiotów
													</Select.Option>
												</Select>
											</Col>
										</CenteredRow>
									)}
									{mode == 'subject' && (
										<CenteredRow>
											<Col span={24}>
												<FFieldLabel
													label="Podmiot"
													for="subjectName"
												/>
												<Field
													label="Podmiot"
													component={FSelectSubjects}
													name="subjectName"
													disabled={
														!props.isSubjectEditable
													}
													onClick={() => {
														if (
															editable &&
															props.isSubjectEditable
														) {
															setSubjectVisible(
																true
															);
														}
													}}
													array={
														formik.values
															?.subjectName
													}
													editable={editable}
													readOnly={!editable}
													dropDown={true}
												/>
											</Col>
										</CenteredRow>
									)}
									{mode == 'category' && (
										<CenteredRow>
											<Col span={24}>
												<FFieldLabel
													label="Kategoria podmiotu"
													for="subjectKind"
												/>
												<Field
													component={FSelect}
													name="subjectKind"
													readOnly={
														!editable ||
														props.data?.subjectKind
													}
													children={
														subjectDic &&
														subjectDic.map(
															(
																p: DictionaryValueType
															) => {
																return (
																	<Select.Option
																		key={
																			p.displayName
																		}
																		value={
																			p.code
																		}
																	>
																		{
																			p.displayName
																		}
																	</Select.Option>
																);
															}
														)
													}
												/>
											</Col>
										</CenteredRow>
									)}
									<CenteredRow>
										<Col span={24}>
											<FFieldLabel
												label="Proces"
												for="process"
											/>
											<Field
												component={FSelect}
												name="process"
												label="Proces"
												readOnly={!editable}
												children={
													reportingDic &&
													reportingDic.map(
														(
															p: DictionaryValueType
														) => {
															return (
																<Select.Option
																	key={
																		p.displayName
																	}
																	value={
																		p.code
																	}
																>
																	{
																		p.displayName
																	}
																</Select.Option>
															);
														}
													)
												}
											/>
										</Col>
									</CenteredRow>
									<CenteredRow>
										<Col span={24}>
											<FFieldLabel
												label="Data ważności"
												for="valid"
											/>
											<Field
												component={FRangePicker}
												label="Data ważności"
												name="valid"
												allowEmpty={[true, true]}
												style={{
													width: '100%',
												}}
												inputReadOnly={!editable}
												value={[
													formik.values['validFrom']
														? moment(
																formik.values[
																	'validFrom'
																]
														  )
														: null,
													formik.values['validTo']
														? moment(
																formik.values[
																	'validTo'
																]
														  )
														: null,
												]}
												setValue={(
													val: [
														Moment | undefined,
														Moment | undefined
													]
												) => {
													formik.setFieldValue(
														'validFrom',
														val?.[0]
													);
													formik.setFieldValue(
														'validTo',
														val?.[1]
													);
												}}
											/>
											<Field
												hidden
												name="validFrom"
												aria-hidden="true"
											/>
											<Field
												hidden
												name="validTo"
												aria-hidden="true"
											/>
										</Col>
									</CenteredRow>
								</Col>

								<Col span={10}>
									<CenteredRow>
										<Col span={24}>
											<FFieldLabel
												label="Data wymagalności sprawozdania"
												for="submissionDate"
											/>
											<Field
												component={FDatePicker}
												name="submissionDate"
												format="DD.MM"
												defaultPickerValue={
													props.data?.submissionDay &&
													props.data?.submissionMonth
														? moment(
																`${props.data.submissionDay}.${props.data.submissionMonth}.${new Date().getFullYear()}`,
																DateFormat.Date
														  )
														: moment(
																`${new Date().getDate()}.${
																	new Date().getMonth() +
																	1
																}.${new Date().getFullYear()}`,
																DateFormat.Date
														  )
												}
												value={
													formik.values[
														'submissionDay'
													] &&
													formik.values[
														'submissionMonth'
													]
														? moment(
																`${formik.values['submissionDay']}.${formik.values['submissionMonth']}.${new Date().getFullYear()}`,
																DateFormat.Date
														  )
														: undefined
												}
												className="subject-reporting-date-picker"
												setValue={(val: Moment) => {
													formik.setFieldValue(
														'submissionDay',
														val?.toDate().getDate()
													);
													formik.setFieldValue(
														'submissionMonth',
														val
															?.toDate()
															.getMonth() + 1
													);
												}}
												showToday={false}
												style={{
													width: '100%',
												}}
												inputReadOnly={!editable}
											/>
											<Field
												hidden
												name="submissionDay"
												aria-hidden="true"
											/>
											<Field
												hidden
												name="submissionMonth"
												aria-hidden="true"
											/>
										</Col>
									</CenteredRow>
									<CenteredRow>
										<Col span={24}>
											<FFieldLabel
												label="Co ile lat"
												for="submissionCycleLength"
											/>
											<Field
												component={FInputNumber}
												precision={0}
												style={{ width: '100%' }}
												readOnly={!editable}
												name="submissionCycleLength"
											/>
										</Col>
									</CenteredRow>
									<CenteredRow>
										<Col span={24}>
											<FFieldLabel
												label="Od roku"
												for="submissionFromYear"
											/>
											<Field
												component={FInputNumber}
												precision={0}
												style={{ width: '100%' }}
												readOnly={!editable}
												name="submissionFromYear"
											/>
										</Col>
									</CenteredRow>
									<CenteredRow>
										<Col span={24}>
											<FFieldLabel
												label="Opis"
												for="description"
											/>
											<Field
												component={FTextArea}
												rows={1}
												placeholder="Opis"
												readOnly={!editable}
												name="description"
											/>
										</Col>
									</CenteredRow>
								</Col>
							</CenteredRow>
						</Modal>
						<SubjectsTableModal
							visible={subjectVisible}
							mode="radio"
							onCancel={() => {
								setSubjectVisible(false);
							}}
							acceptSubject={(value) => {
								formik.setFieldValue('subjectId', value?.id);
								formik.setFieldValue(
									'subjectName',
									value?.name
								);
								setSubjectVisible(false);
							}}
							showImplementerSponsorFilter={true}
						/>
					</>
				)}
			/>
		</>
	);
};
export default SubjectReportingProcessModal;
