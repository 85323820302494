import { InterventionCommentType } from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';

export class AddInterventionCommentDto {
	interventionId?: string;
	fieldName?: string;
	column?: string;
	row?: string;
	description?: string;
	commentType?: InterventionCommentType;
	activityId?: string;
	activityPopulationId?: string;
	activitySponsoringCompanyId?: string;
	activityTabName?: string;

	constructor(model: ProfiBazaAPIModels.CreateInterventionCommentCommand) {
		this.interventionId = model.interventionId;
		this.fieldName = model.fieldName;
		this.description = model.description;
		this.commentType = model.commentType;
	}

	static toCommand(
		model: AddInterventionCommentDto
	): ProfiBazaAPIModels.CreateInterventionCommentCommand {
		const command: ProfiBazaAPIModels.CreateInterventionCommentCommand = {
			interventionId: model.interventionId,
			fieldName: model.fieldName,
			description: model.description,
			commentType: model.commentType,
			activityId: model.activityId,
			activityPopulationId: model.activityPopulationId,
			activitySponsoringCompanyId: model.activitySponsoringCompanyId,
			activityTabName: model.activityTabName
		};
		return command;
	}
}
