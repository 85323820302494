/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from "@azure/ms-rest-js";


export const CalendarEventDTO: msRest.CompositeMapper = {
  serializedName: "CalendarEventDTO",
  type: {
    name: "Composite",
    className: "CalendarEventDTO",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      header: {
        serializedName: "header",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      isUserEvent: {
        serializedName: "isUserEvent",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ContactDto: msRest.CompositeMapper = {
  serializedName: "ContactDto",
  type: {
    name: "Composite",
    className: "ContactDto",
    modelProperties: {
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "String"
        }
      },
      fullName: {
        readOnly: true,
        serializedName: "fullName",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DictionaryViewDto: msRest.CompositeMapper = {
  serializedName: "DictionaryViewDto",
  type: {
    name: "Composite",
    className: "DictionaryViewDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      isHierarchical: {
        serializedName: "isHierarchical",
        type: {
          name: "Boolean"
        }
      },
      isCodeNameInValuesRequired: {
        serializedName: "isCodeNameInValuesRequired",
        type: {
          name: "Boolean"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DictionaryValueItemDto: msRest.CompositeMapper = {
  serializedName: "DictionaryValueItemDto",
  type: {
    name: "Composite",
    className: "DictionaryValueItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      canAddTextValue: {
        serializedName: "canAddTextValue",
        type: {
          name: "Boolean"
        }
      },
      canBeSelected: {
        serializedName: "canBeSelected",
        type: {
          name: "Boolean"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "ACTIVE",
            "INACTIVE",
            "DELETED"
          ]
        }
      },
      order: {
        serializedName: "order",
        type: {
          name: "Number"
        }
      },
      hasChildren: {
        serializedName: "hasChildren",
        type: {
          name: "Boolean"
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      interventionPaths: {
        serializedName: "interventionPaths",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "PROHEALTH_ACTIVITIES",
                "RESEARCH",
                "SUPERVISION_MONITORING",
                "SUPERVISION_SANITARY",
                "STAFF_EDUCATION",
                "STAFF_MONITORING",
                "LEGISLATION"
              ]
            }
          }
        }
      },
      breadcrumb: {
        serializedName: "breadcrumb",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DictionaryOtherValueHistoryDto: msRest.CompositeMapper = {
  serializedName: "DictionaryOtherValueHistoryDto",
  type: {
    name: "Composite",
    className: "DictionaryOtherValueHistoryDto",
    modelProperties: {
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      dictionaryValueId: {
        serializedName: "dictionaryValueId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DictionaryValueDto: msRest.CompositeMapper = {
  serializedName: "DictionaryValueDto",
  type: {
    name: "Composite",
    className: "DictionaryValueDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      canAddTextValue: {
        serializedName: "canAddTextValue",
        type: {
          name: "Boolean"
        }
      },
      canBeSelected: {
        serializedName: "canBeSelected",
        type: {
          name: "Boolean"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "ACTIVE",
            "INACTIVE",
            "DELETED"
          ]
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      order: {
        serializedName: "order",
        type: {
          name: "Number"
        }
      },
      dictionaryVersionId: {
        serializedName: "dictionaryVersionId",
        type: {
          name: "Number"
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      interventionPaths: {
        serializedName: "interventionPaths",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "PROHEALTH_ACTIVITIES",
                "RESEARCH",
                "SUPERVISION_MONITORING",
                "SUPERVISION_SANITARY",
                "STAFF_EDUCATION",
                "STAFF_MONITORING",
                "LEGISLATION"
              ]
            }
          }
        }
      },
      breadcrumb: {
        serializedName: "breadcrumb",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DictionaryValueChangeVersionDto: msRest.CompositeMapper = {
  serializedName: "DictionaryValueChangeVersionDto",
  type: {
    name: "Composite",
    className: "DictionaryValueChangeVersionDto",
    modelProperties: {
      oldDictionaryValueId: {
        serializedName: "oldDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      newDictionaryValue: {
        serializedName: "newDictionaryValue",
        type: {
          name: "Composite",
          className: "DictionaryValueDto"
        }
      }
    }
  }
};

export const RecommendationIdNameDto: msRest.CompositeMapper = {
  serializedName: "RecommendationIdNameDto",
  type: {
    name: "Composite",
    className: "RecommendationIdNameDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      recommendationName: {
        serializedName: "recommendationName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FormItemDto: msRest.CompositeMapper = {
  serializedName: "FormItemDto",
  type: {
    name: "Composite",
    className: "FormItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "StatementNPZ",
            "StatementMZ49",
            "StatementMZ53",
            "AOTMiTFinalReport",
            "ActivityProhealthActivities",
            "ActivityResearch",
            "ActivitySupervisionMonitoring",
            "ActivitySupervisionSanitary",
            "ActivityStaffEducation",
            "ActivityStaffMonitoring",
            "ActivityLegislation",
            "InterventionProhealthActivities",
            "InterventionResearch",
            "InterventionSupervisionMonitoring",
            "InterventionSupervisionSanitary",
            "InterventionStaffEducation",
            "InterventionStaffMonitoring",
            "InterventionLegislation"
          ]
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RizpDictionaryValueDto: msRest.CompositeMapper = {
  serializedName: "RizpDictionaryValueDto",
  type: {
    name: "Composite",
    className: "RizpDictionaryValueDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      otherValue: {
        serializedName: "otherValue",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      breadcrumb: {
        serializedName: "breadcrumb",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ActivityListItemDto: msRest.CompositeMapper = {
  serializedName: "ActivityListItemDto",
  type: {
    name: "Composite",
    className: "ActivityListItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      numberOfPersonAction: {
        nullable: true,
        serializedName: "numberOfPersonAction",
        type: {
          name: "Number"
        }
      },
      numberOfRecipients: {
        nullable: true,
        serializedName: "numberOfRecipients",
        type: {
          name: "Number"
        }
      },
      activityCategory: {
        serializedName: "activityCategory",
        type: {
          name: "Composite",
          className: "RizpDictionaryValueDto"
        }
      },
      nzpTaskNumberDictionaryValues: {
        serializedName: "nzpTaskNumberDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      primaryActivityId: {
        serializedName: "primaryActivityId",
        type: {
          name: "Uuid"
        }
      },
      activityPatternId: {
        serializedName: "activityPatternId",
        type: {
          name: "Uuid"
        }
      },
      implementingCompanies: {
        nullable: true,
        serializedName: "implementingCompanies",
        type: {
          name: "Number"
        }
      },
      activityNumber: {
        serializedName: "activityNumber",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ValidationWarningDto: msRest.CompositeMapper = {
  serializedName: "ValidationWarningDto",
  type: {
    name: "Composite",
    className: "ValidationWarningDto",
    modelProperties: {
      fieldName: {
        serializedName: "fieldName",
        type: {
          name: "String"
        }
      },
      errors: {
        serializedName: "errors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const InterventionsAggregateDto: msRest.CompositeMapper = {
  serializedName: "InterventionsAggregateDto",
  type: {
    name: "Composite",
    className: "InterventionsAggregateDto",
    modelProperties: {
      terytCode: {
        serializedName: "terytCode",
        type: {
          name: "String"
        }
      },
      activityCategoryCodeName: {
        serializedName: "activityCategoryCodeName",
        type: {
          name: "String"
        }
      },
      activityCategoryValue: {
        serializedName: "activityCategoryValue",
        type: {
          name: "String"
        }
      },
      year: {
        nullable: true,
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const NotificationDefinitionActivationDto: msRest.CompositeMapper = {
  serializedName: "NotificationDefinitionActivationDto",
  type: {
    name: "Composite",
    className: "NotificationDefinitionActivationDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      dayDifference: {
        serializedName: "dayDifference",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const NotificationDefinitionDTO: msRest.CompositeMapper = {
  serializedName: "NotificationDefinitionDTO",
  type: {
    name: "Composite",
    className: "NotificationDefinitionDTO",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      event: {
        serializedName: "event",
        type: {
          name: "String"
        }
      },
      header: {
        serializedName: "header",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      systemChannelActive: {
        serializedName: "systemChannelActive",
        type: {
          name: "Boolean"
        }
      },
      emailChannelActive: {
        serializedName: "emailChannelActive",
        type: {
          name: "Boolean"
        }
      },
      sendToAll: {
        serializedName: "sendToAll",
        type: {
          name: "Boolean"
        }
      },
      activations: {
        serializedName: "activations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NotificationDefinitionActivationDto"
            }
          }
        }
      }
    }
  }
};

export const OrbeonFormDefinition: msRest.CompositeMapper = {
  serializedName: "OrbeonFormDefinition",
  type: {
    name: "Composite",
    className: "OrbeonFormDefinition",
    modelProperties: {
      applicationName: {
        serializedName: "applicationName",
        type: {
          name: "String"
        }
      },
      formName: {
        serializedName: "formName",
        type: {
          name: "String"
        }
      },
      formVersion: {
        serializedName: "formVersion",
        type: {
          name: "String"
        }
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        type: {
          name: "DateTime"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PatternDigestDto: msRest.CompositeMapper = {
  serializedName: "PatternDigestDto",
  type: {
    name: "Composite",
    className: "PatternDigestDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      patternName: {
        serializedName: "patternName",
        type: {
          name: "String"
        }
      },
      patternType: {
        serializedName: "patternType",
        type: {
          name: "Enum",
          allowedValues: [
            "PROGRAM",
            "NOT_PROGRAM",
            "AOTMIT_RECOMENDATION"
          ]
        }
      }
    }
  }
};

export const PermissionDto: msRest.CompositeMapper = {
  serializedName: "PermissionDto",
  type: {
    name: "Composite",
    className: "PermissionDto",
    modelProperties: {
      permission: {
        serializedName: "permission",
        type: {
          name: "Enum",
          allowedValues: [
            "Dictionaries_List_Preview",
            "Dictionaries_Details_Preview",
            "Dictionaries_Versions_Modifications",
            "Admin_Roles_Preview",
            "Admin_Roles_Modifications",
            "Admin_Roles_Assignment_Global",
            "Admin_Roles_Assignment_Local",
            "Admin_ApiKeys_Management",
            "RIZP_Preview_Mine",
            "RIZP_Preview_Subordinate",
            "RIZP_Preview_All",
            "RIZP_Modifications",
            "RIZP_Patterns_Modifications_Nationwide",
            "RIZP_Patterns_Modifications_Voivodeship",
            "RIZP_Archive",
            "Subjects_List_Preview",
            "Subjects_Details_Preview",
            "Subjects_Modifications_Mine",
            "Subjects_Modifications_With_Teryt_Mine",
            "Subjects_Modifications_All",
            "Subjects_Modifications_With_Teryt_All",
            "Subjects_Modifications_Statement_Obligation",
            "Subjects_Merge",
            "Subjects_Proposal_Possibility_Handling",
            "Statements_Archive",
            "Statements_Generation_MZ53",
            "Statements_Generation_MZ49",
            "Statements_Generation_NPZ",
            "Statement_Preview_Mine_MZ49",
            "Statement_Preview_Mine_MZ53",
            "Statement_Preview_Mine_NPZ",
            "Statement_Preview_Subordinate_MZ49",
            "Statement_Preview_Subordinate_MZ53",
            "Statement_Preview_Subordinate_NPZ",
            "Statement_Preview_All_MZ49",
            "Statement_Preview_All_MZ53",
            "Statement_Preview_All_NPZ",
            "Statement_Verification_MZ49",
            "Statement_Verification_MZ53",
            "Statement_Verification_NPZ",
            "Statement_Critical_Warning",
            "Proposals_Handling_All",
            "Proposals_Handling_Mine",
            "DocumentsRepository_List_Preview",
            "DocumentsRepository_Preview",
            "DocumentsRepository_VoivodeOpinions_Preview",
            "DocumentsRepository_AllVoivodeOpinions_Preview",
            "DocumentsRepository_VoivodeOpinions_Modifications",
            "Reports_MZ_Preview",
            "Reports_Programy_Zdrowotne_Preview",
            "Reports_AOTMiT_Preview",
            "Reports_RP_Preview",
            "Reports_SZ_Chorobowosc_Preview",
            "Reports_SZ_Umieralnosc_Preview",
            "Reports_SZ_Deprywacja_Preview",
            "Reports_SZ_Statusy_Preview",
            "Reports_SZ_Demografia_Preview",
            "Reports_SZ_Preview",
            "Reports_All_Preview",
            "Reports_Technical",
            "Aotmit_Add_Recommendations",
            "Aotmit_Add_Comments",
            "Aotmit_Add_DetailedReportAnnex",
            "Aotmit_Projects",
            "Forms_Management",
            "Notifications_Management",
            "UserManual_Management",
            "Tooltip_Management",
            "Tags_Management_Global",
            "Tags_Management_Local",
            "Aggregate_Data",
            "IKP"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RoleItemDto: msRest.CompositeMapper = {
  serializedName: "RoleItemDto",
  type: {
    name: "Composite",
    className: "RoleItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "USER",
            "ADMIN"
          ]
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Enum",
          allowedValues: [
            "LOCAL",
            "CENTRAL"
          ]
        }
      }
    }
  }
};

export const DateRangeDto: msRest.CompositeMapper = {
  serializedName: "DateRangeDto",
  type: {
    name: "Composite",
    className: "DateRangeDto",
    modelProperties: {
      dataOd: {
        serializedName: "DataOd",
        type: {
          name: "DateTime"
        }
      },
      dataDo: {
        serializedName: "DataDo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const NpzImplementingCompanyDto: msRest.CompositeMapper = {
  serializedName: "NpzImplementingCompanyDto",
  type: {
    name: "Composite",
    className: "NpzImplementingCompanyDto",
    modelProperties: {
      realZadNazwJedn: {
        serializedName: "RealZad_NazwJedn",
        type: {
          name: "String"
        }
      },
      realZadAdrJedn: {
        serializedName: "RealZad_AdrJedn",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MainSponsoringSubjectDto: msRest.CompositeMapper = {
  serializedName: "MainSponsoringSubjectDto",
  type: {
    name: "Composite",
    className: "MainSponsoringSubjectDto",
    modelProperties: {
      podmFinansZadNazwPodm: {
        serializedName: "PodmFinansZad_NazwPodm",
        type: {
          name: "String"
        }
      },
      podmFinansZadUdzFinansPLN: {
        nullable: true,
        serializedName: "PodmFinansZad_UdzFinans_PLN",
        type: {
          name: "Number"
        }
      },
      podmFinansZadUdzFinansProcent: {
        serializedName: "PodmFinansZad_UdzFinans_Procent",
        type: {
          name: "Number"
        }
      },
      podmFinansZadZrodlFinans: {
        serializedName: "PodmFinansZad_ZrodlFinans",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OtherSponsoringSubjectDto: msRest.CompositeMapper = {
  serializedName: "OtherSponsoringSubjectDto",
  type: {
    name: "Composite",
    className: "OtherSponsoringSubjectDto",
    modelProperties: {
      podmWspolFinansZadNazwPodm: {
        serializedName: "PodmWspolFinansZad_NazwPodm",
        type: {
          name: "String"
        }
      },
      podmWspolFinansZadUdzFinansPLN: {
        nullable: true,
        serializedName: "PodmWspolFinansZad_UdzFinans_PLN",
        type: {
          name: "Number"
        }
      },
      podmWspolFinansZadUdzFinansProcent: {
        serializedName: "PodmWspolFinansZad_UdzFinans_Procent",
        type: {
          name: "Number"
        }
      },
      podmWspolFinansZadZrodlFinans: {
        serializedName: "PodmWspolFinansZad_ZrodlFinans",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DictionaryStmtValueDto: msRest.CompositeMapper = {
  serializedName: "DictionaryStmtValueDto",
  type: {
    name: "Composite",
    className: "DictionaryStmtValueDto",
    modelProperties: {
      identyfikatorRodzica: {
        serializedName: "IdentyfikatorRodzica",
        type: {
          name: "String"
        }
      },
      identyfikator: {
        serializedName: "Identyfikator",
        type: {
          name: "String"
        }
      },
      wartosc: {
        serializedName: "Wartosc",
        type: {
          name: "String"
        }
      },
      kod: {
        serializedName: "Kod",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PopulationDto: msRest.CompositeMapper = {
  serializedName: "PopulationDto",
  type: {
    name: "Composite",
    className: "PopulationDto",
    modelProperties: {
      populacjaDocelowa: {
        serializedName: "PopulacjaDocelowa",
        type: {
          name: "Composite",
          className: "DictionaryStmtValueDto"
        }
      },
      miejsceInterwencji: {
        serializedName: "MiejsceInterwencji",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryStmtValueDto"
            }
          }
        }
      },
      stanZdrowia: {
        serializedName: "StanZdrowia",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryStmtValueDto"
            }
          }
        }
      },
      wykluczenieSpoleczne: {
        serializedName: "WykluczenieSpoleczne",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryStmtValueDto"
            }
          }
        }
      },
      inneSzczegolneCechy: {
        serializedName: "InneSzczegolneCechy",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryStmtValueDto"
            }
          }
        }
      },
      plec: {
        serializedName: "Plec",
        type: {
          name: "Composite",
          className: "DictionaryStmtValueDto"
        }
      },
      zakresWieku: {
        serializedName: "ZakresWieku",
        type: {
          name: "String"
        }
      },
      liczbaOdbiorcow: {
        nullable: true,
        serializedName: "LiczbaOdbiorcow",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DictionaryStmtDto: msRest.CompositeMapper = {
  serializedName: "DictionaryStmtDto",
  type: {
    name: "Composite",
    className: "DictionaryStmtDto",
    modelProperties: {
      identyfikator: {
        serializedName: "Identyfikator",
        type: {
          name: "String"
        }
      },
      wartosci: {
        serializedName: "Wartosci",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryStmtValueDto"
            }
          }
        }
      }
    }
  }
};

export const NPZAutocompletionDto: msRest.CompositeMapper = {
  serializedName: "NPZAutocompletionDto",
  type: {
    name: "Composite",
    className: "NPZAutocompletionDto",
    modelProperties: {
      interwencjaId: {
        serializedName: "InterwencjaId",
        type: {
          name: "Uuid"
        }
      },
      dzialanieId: {
        serializedName: "DzialanieId",
        type: {
          name: "Uuid"
        }
      },
      lp: {
        serializedName: "LP",
        type: {
          name: "String"
        }
      },
      nazwZad: {
        serializedName: "NazwZad",
        type: {
          name: "String"
        }
      },
      opis: {
        serializedName: "Opis",
        type: {
          name: "String"
        }
      },
      podmRealizZadTERYT: {
        serializedName: "PodmRealizZad_TERYT",
        type: {
          name: "String"
        }
      },
      podmRealizZadNazw: {
        serializedName: "PodmRealizZad_Nazw",
        type: {
          name: "String"
        }
      },
      podmRealizZadAdrPodm: {
        serializedName: "PodmRealizZad_AdrPodm",
        type: {
          name: "String"
        }
      },
      czasTrwZad: {
        serializedName: "Czas_trwZad",
        type: {
          name: "Composite",
          className: "DateRangeDto"
        }
      },
      terRealizZad: {
        serializedName: "TerRealizZad",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      popObjZadLiczbaOsObjZad: {
        nullable: true,
        serializedName: "PopObjZad_liczbaOsObjZad",
        type: {
          name: "Number"
        }
      },
      realZad: {
        serializedName: "RealZad",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NpzImplementingCompanyDto"
            }
          }
        }
      },
      calkKosztZad: {
        serializedName: "CalkKosztZad",
        type: {
          name: "Number"
        }
      },
      uwagi: {
        serializedName: "Uwagi",
        type: {
          name: "String"
        }
      },
      podmFinansZad: {
        serializedName: "PodmFinansZad",
        type: {
          name: "Composite",
          className: "MainSponsoringSubjectDto"
        }
      },
      podmWspolFinansZad: {
        serializedName: "PodmWspolFinansZad",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OtherSponsoringSubjectDto"
            }
          }
        }
      },
      temat: {
        serializedName: "Temat",
        type: {
          name: "String"
        }
      },
      popObjZadCharPop: {
        serializedName: "PopObjZad_charPop",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PopulationDto"
            }
          }
        }
      },
      slowniki: {
        serializedName: "Slowniki",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryStmtDto"
            }
          }
        }
      }
    }
  }
};

export const StatementPartDto: msRest.CompositeMapper = {
  serializedName: "StatementPartDto",
  type: {
    name: "Composite",
    className: "StatementPartDto",
    modelProperties: {
      applicationName: {
        serializedName: "applicationName",
        type: {
          name: "String"
        }
      },
      formName: {
        serializedName: "formName",
        type: {
          name: "String"
        }
      },
      documentId: {
        serializedName: "documentId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SubjectIdNameDto: msRest.CompositeMapper = {
  serializedName: "SubjectIdNameDto",
  type: {
    name: "Composite",
    className: "SubjectIdNameDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SubjectsAggregateDto: msRest.CompositeMapper = {
  serializedName: "SubjectsAggregateDto",
  type: {
    name: "Composite",
    className: "SubjectsAggregateDto",
    modelProperties: {
      terc: {
        serializedName: "terc",
        type: {
          name: "String"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const UserManualDto: msRest.CompositeMapper = {
  serializedName: "UserManualDto",
  type: {
    name: "Composite",
    className: "UserManualDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      manualType: {
        serializedName: "manualType",
        type: {
          name: "Enum",
          allowedValues: [
            "Main",
            "Administrative",
            "Intervention",
            "Statement",
            "Subject",
            "RODO"
          ]
        }
      },
      interventionPath: {
        serializedName: "interventionPath",
        type: {
          name: "Enum",
          allowedValues: [
            "PROHEALTH_ACTIVITIES",
            "RESEARCH",
            "SUPERVISION_MONITORING",
            "SUPERVISION_SANITARY",
            "STAFF_EDUCATION",
            "STAFF_MONITORING",
            "LEGISLATION"
          ]
        }
      },
      userManualAdministrativeType: {
        serializedName: "userManualAdministrativeType",
        type: {
          name: "Enum",
          allowedValues: [
            "Global",
            "Local"
          ]
        }
      },
      reportType: {
        serializedName: "reportType",
        type: {
          name: "Enum",
          allowedValues: [
            "StatementNPZ",
            "StatementMZ49",
            "StatementMZ53",
            "AOTMiTFinalReport",
            "ActivityProhealthActivities",
            "ActivityResearch",
            "ActivitySupervisionMonitoring",
            "ActivitySupervisionSanitary",
            "ActivityStaffEducation",
            "ActivityStaffMonitoring",
            "ActivityLegislation",
            "InterventionProhealthActivities",
            "InterventionResearch",
            "InterventionSupervisionMonitoring",
            "InterventionSupervisionSanitary",
            "InterventionStaffEducation",
            "InterventionStaffMonitoring",
            "InterventionLegislation"
          ]
        }
      },
      formVersionId: {
        serializedName: "formVersionId",
        type: {
          name: "Uuid"
        }
      },
      formVersion: {
        serializedName: "formVersion",
        type: {
          name: "String"
        }
      },
      formValidFrom: {
        serializedName: "formValidFrom",
        type: {
          name: "DateTime"
        }
      },
      rodoManualType: {
        serializedName: "rodoManualType",
        type: {
          name: "Enum",
          allowedValues: [
            "ProposalAccess",
            "CRUIP"
          ]
        }
      }
    }
  }
};

export const UserNotificationSettingsDTO: msRest.CompositeMapper = {
  serializedName: "UserNotificationSettingsDTO",
  type: {
    name: "Composite",
    className: "UserNotificationSettingsDTO",
    modelProperties: {
      notificationDefinitionId: {
        serializedName: "notificationDefinitionId",
        type: {
          name: "Uuid"
        }
      },
      event: {
        serializedName: "event",
        type: {
          name: "String"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      systemChannelActive: {
        serializedName: "systemChannelActive",
        type: {
          name: "Boolean"
        }
      },
      emailChannelActive: {
        serializedName: "emailChannelActive",
        type: {
          name: "Boolean"
        }
      },
      definitionSystemChannelActive: {
        serializedName: "definitionSystemChannelActive",
        type: {
          name: "Boolean"
        }
      },
      definitionEmailChannelActive: {
        serializedName: "definitionEmailChannelActive",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SaveDashboardSettingsCommand: msRest.CompositeMapper = {
  serializedName: "SaveDashboardSettingsCommand",
  type: {
    name: "Composite",
    className: "SaveDashboardSettingsCommand",
    modelProperties: {
      showInterventions: {
        serializedName: "showInterventions",
        type: {
          name: "Boolean"
        }
      },
      showStatements: {
        serializedName: "showStatements",
        type: {
          name: "Boolean"
        }
      },
      notepadContent: {
        serializedName: "notepadContent",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SaveGridSettingsCommand: msRest.CompositeMapper = {
  serializedName: "SaveGridSettingsCommand",
  type: {
    name: "Composite",
    className: "SaveGridSettingsCommand",
    modelProperties: {
      gridName: {
        serializedName: "gridName",
        type: {
          name: "String"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      visibleColumnKeys: {
        serializedName: "visibleColumnKeys",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const GridSettingsDto: msRest.CompositeMapper = {
  serializedName: "GridSettingsDto",
  type: {
    name: "Composite",
    className: "GridSettingsDto",
    modelProperties: {
      gridName: {
        serializedName: "gridName",
        type: {
          name: "String"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      visibleColumnKeys: {
        serializedName: "visibleColumnKeys",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const DashboardSettingsDto: msRest.CompositeMapper = {
  serializedName: "DashboardSettingsDto",
  type: {
    name: "Composite",
    className: "DashboardSettingsDto",
    modelProperties: {
      showInterventions: {
        serializedName: "showInterventions",
        type: {
          name: "Boolean"
        }
      },
      showStatements: {
        serializedName: "showStatements",
        type: {
          name: "Boolean"
        }
      },
      notepadContent: {
        serializedName: "notepadContent",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Subject: msRest.CompositeMapper = {
  serializedName: "Subject",
  type: {
    name: "Composite",
    className: "Subject",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      regon: {
        serializedName: "regon",
        type: {
          name: "String"
        }
      },
      permissions: {
        serializedName: "permissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "Dictionaries_List_Preview",
                "Dictionaries_Details_Preview",
                "Dictionaries_Versions_Modifications",
                "Admin_Roles_Preview",
                "Admin_Roles_Modifications",
                "Admin_Roles_Assignment_Global",
                "Admin_Roles_Assignment_Local",
                "Admin_ApiKeys_Management",
                "RIZP_Preview_Mine",
                "RIZP_Preview_Subordinate",
                "RIZP_Preview_All",
                "RIZP_Modifications",
                "RIZP_Patterns_Modifications_Nationwide",
                "RIZP_Patterns_Modifications_Voivodeship",
                "RIZP_Archive",
                "Subjects_List_Preview",
                "Subjects_Details_Preview",
                "Subjects_Modifications_Mine",
                "Subjects_Modifications_With_Teryt_Mine",
                "Subjects_Modifications_All",
                "Subjects_Modifications_With_Teryt_All",
                "Subjects_Modifications_Statement_Obligation",
                "Subjects_Merge",
                "Subjects_Proposal_Possibility_Handling",
                "Statements_Archive",
                "Statements_Generation_MZ53",
                "Statements_Generation_MZ49",
                "Statements_Generation_NPZ",
                "Statement_Preview_Mine_MZ49",
                "Statement_Preview_Mine_MZ53",
                "Statement_Preview_Mine_NPZ",
                "Statement_Preview_Subordinate_MZ49",
                "Statement_Preview_Subordinate_MZ53",
                "Statement_Preview_Subordinate_NPZ",
                "Statement_Preview_All_MZ49",
                "Statement_Preview_All_MZ53",
                "Statement_Preview_All_NPZ",
                "Statement_Verification_MZ49",
                "Statement_Verification_MZ53",
                "Statement_Verification_NPZ",
                "Statement_Critical_Warning",
                "Proposals_Handling_All",
                "Proposals_Handling_Mine",
                "DocumentsRepository_List_Preview",
                "DocumentsRepository_Preview",
                "DocumentsRepository_VoivodeOpinions_Preview",
                "DocumentsRepository_AllVoivodeOpinions_Preview",
                "DocumentsRepository_VoivodeOpinions_Modifications",
                "Reports_MZ_Preview",
                "Reports_Programy_Zdrowotne_Preview",
                "Reports_AOTMiT_Preview",
                "Reports_RP_Preview",
                "Reports_SZ_Chorobowosc_Preview",
                "Reports_SZ_Umieralnosc_Preview",
                "Reports_SZ_Deprywacja_Preview",
                "Reports_SZ_Statusy_Preview",
                "Reports_SZ_Demografia_Preview",
                "Reports_SZ_Preview",
                "Reports_All_Preview",
                "Reports_Technical",
                "Aotmit_Add_Recommendations",
                "Aotmit_Add_Comments",
                "Aotmit_Add_DetailedReportAnnex",
                "Aotmit_Projects",
                "Forms_Management",
                "Notifications_Management",
                "UserManual_Management",
                "Tooltip_Management",
                "Tags_Management_Global",
                "Tags_Management_Local",
                "Aggregate_Data",
                "IKP"
              ]
            }
          }
        }
      }
    }
  }
};

export const AccountDetailsDto: msRest.CompositeMapper = {
  serializedName: "AccountDetailsDto",
  type: {
    name: "Composite",
    className: "AccountDetailsDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        readOnly: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      existsInProfiBaza: {
        serializedName: "existsInProfiBaza",
        type: {
          name: "Boolean"
        }
      },
      permissions: {
        serializedName: "permissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "Dictionaries_List_Preview",
                "Dictionaries_Details_Preview",
                "Dictionaries_Versions_Modifications",
                "Admin_Roles_Preview",
                "Admin_Roles_Modifications",
                "Admin_Roles_Assignment_Global",
                "Admin_Roles_Assignment_Local",
                "Admin_ApiKeys_Management",
                "RIZP_Preview_Mine",
                "RIZP_Preview_Subordinate",
                "RIZP_Preview_All",
                "RIZP_Modifications",
                "RIZP_Patterns_Modifications_Nationwide",
                "RIZP_Patterns_Modifications_Voivodeship",
                "RIZP_Archive",
                "Subjects_List_Preview",
                "Subjects_Details_Preview",
                "Subjects_Modifications_Mine",
                "Subjects_Modifications_With_Teryt_Mine",
                "Subjects_Modifications_All",
                "Subjects_Modifications_With_Teryt_All",
                "Subjects_Modifications_Statement_Obligation",
                "Subjects_Merge",
                "Subjects_Proposal_Possibility_Handling",
                "Statements_Archive",
                "Statements_Generation_MZ53",
                "Statements_Generation_MZ49",
                "Statements_Generation_NPZ",
                "Statement_Preview_Mine_MZ49",
                "Statement_Preview_Mine_MZ53",
                "Statement_Preview_Mine_NPZ",
                "Statement_Preview_Subordinate_MZ49",
                "Statement_Preview_Subordinate_MZ53",
                "Statement_Preview_Subordinate_NPZ",
                "Statement_Preview_All_MZ49",
                "Statement_Preview_All_MZ53",
                "Statement_Preview_All_NPZ",
                "Statement_Verification_MZ49",
                "Statement_Verification_MZ53",
                "Statement_Verification_NPZ",
                "Statement_Critical_Warning",
                "Proposals_Handling_All",
                "Proposals_Handling_Mine",
                "DocumentsRepository_List_Preview",
                "DocumentsRepository_Preview",
                "DocumentsRepository_VoivodeOpinions_Preview",
                "DocumentsRepository_AllVoivodeOpinions_Preview",
                "DocumentsRepository_VoivodeOpinions_Modifications",
                "Reports_MZ_Preview",
                "Reports_Programy_Zdrowotne_Preview",
                "Reports_AOTMiT_Preview",
                "Reports_RP_Preview",
                "Reports_SZ_Chorobowosc_Preview",
                "Reports_SZ_Umieralnosc_Preview",
                "Reports_SZ_Deprywacja_Preview",
                "Reports_SZ_Statusy_Preview",
                "Reports_SZ_Demografia_Preview",
                "Reports_SZ_Preview",
                "Reports_All_Preview",
                "Reports_Technical",
                "Aotmit_Add_Recommendations",
                "Aotmit_Add_Comments",
                "Aotmit_Add_DetailedReportAnnex",
                "Aotmit_Projects",
                "Forms_Management",
                "Notifications_Management",
                "UserManual_Management",
                "Tooltip_Management",
                "Tags_Management_Global",
                "Tags_Management_Local",
                "Aggregate_Data",
                "IKP"
              ]
            }
          }
        }
      },
      gridSettings: {
        serializedName: "gridSettings",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Composite",
              className: "GridSettingsDto"
            }
          }
        }
      },
      dashboardSettings: {
        serializedName: "dashboardSettings",
        type: {
          name: "Composite",
          className: "DashboardSettingsDto"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "Composite",
          className: "Subject"
        }
      },
      acceptedRODO: {
        serializedName: "acceptedRODO",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UserProfileDto: msRest.CompositeMapper = {
  serializedName: "UserProfileDto",
  type: {
    name: "Composite",
    className: "UserProfileDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      isFromCRUiP: {
        serializedName: "isFromCRUiP",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UpdateUserInCRUiPCommand: msRest.CompositeMapper = {
  serializedName: "UpdateUserInCRUiPCommand",
  type: {
    name: "Composite",
    className: "UpdateUserInCRUiPCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RizpDictionaryDto: msRest.CompositeMapper = {
  serializedName: "RizpDictionaryDto",
  type: {
    name: "Composite",
    className: "RizpDictionaryDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      otherValue: {
        serializedName: "otherValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ActivityPopulationValueDto: msRest.CompositeMapper = {
  serializedName: "ActivityPopulationValueDto",
  type: {
    name: "Composite",
    className: "ActivityPopulationValueDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      activityPopulationPatternId: {
        serializedName: "activityPopulationPatternId",
        type: {
          name: "Uuid"
        }
      },
      genderDictionaryValueId: {
        serializedName: "genderDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      genderDictionaryOtherValue: {
        serializedName: "genderDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      genderDictionaryValue: {
        serializedName: "genderDictionaryValue",
        type: {
          name: "String"
        }
      },
      targetPopulationDictionaryValueId: {
        serializedName: "targetPopulationDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      targetPopulationDictionaryOtherValue: {
        serializedName: "targetPopulationDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      targetPopulationDictionaryValue: {
        serializedName: "targetPopulationDictionaryValue",
        type: {
          name: "String"
        }
      },
      minAge: {
        serializedName: "minAge",
        type: {
          name: "Number"
        }
      },
      maxAge: {
        nullable: true,
        serializedName: "maxAge",
        type: {
          name: "Number"
        }
      },
      ageUnit: {
        serializedName: "ageUnit",
        type: {
          name: "Enum",
          allowedValues: [
            "Years",
            "Weeks"
          ]
        }
      },
      totalRecipients: {
        nullable: true,
        serializedName: "totalRecipients",
        type: {
          name: "Number"
        }
      },
      interventionLocations: {
        serializedName: "interventionLocations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      healthConditions: {
        serializedName: "healthConditions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      socialExclusions: {
        serializedName: "socialExclusions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      otherSpecialFeatures: {
        serializedName: "otherSpecialFeatures",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      }
    }
  }
};

export const ImplementingCompanyDto: msRest.CompositeMapper = {
  serializedName: "ImplementingCompanyDto",
  type: {
    name: "Composite",
    className: "ImplementingCompanyDto",
    modelProperties: {
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      number: {
        nullable: true,
        serializedName: "number",
        type: {
          name: "Number"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      }
    }
  }
};

export const FinancingSourceDto: msRest.CompositeMapper = {
  serializedName: "FinancingSourceDto",
  type: {
    name: "Composite",
    className: "FinancingSourceDto",
    modelProperties: {
      financingSourceDictionaryValueId: {
        serializedName: "financingSourceDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      financingSourceDictionaryValue: {
        serializedName: "financingSourceDictionaryValue",
        type: {
          name: "String"
        }
      },
      financingSourceDictionaryOtherValue: {
        serializedName: "financingSourceDictionaryOtherValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SponsoringCompanyValueDto: msRest.CompositeMapper = {
  serializedName: "SponsoringCompanyValueDto",
  type: {
    name: "Composite",
    className: "SponsoringCompanyValueDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      plannedCosts: {
        serializedName: "plannedCosts",
        type: {
          name: "Number"
        }
      },
      realizationCosts: {
        nullable: true,
        serializedName: "realizationCosts",
        type: {
          name: "Number"
        }
      },
      financingSourceDictionaryValues: {
        serializedName: "financingSourceDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FinancingSourceDto"
            }
          }
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      isMainSubject: {
        serializedName: "isMainSubject",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const IkpDto: msRest.CompositeMapper = {
  serializedName: "IkpDto",
  type: {
    name: "Composite",
    className: "IkpDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      recrutationDateFrom: {
        serializedName: "recrutationDateFrom",
        type: {
          name: "DateTime"
        }
      },
      recrutationDateTo: {
        serializedName: "recrutationDateTo",
        type: {
          name: "DateTime"
        }
      },
      includeCriteria: {
        serializedName: "includeCriteria",
        type: {
          name: "String"
        }
      },
      excludeCriteria: {
        serializedName: "excludeCriteria",
        type: {
          name: "String"
        }
      },
      benefits: {
        serializedName: "benefits",
        type: {
          name: "String"
        }
      },
      sourceOfKnowledge: {
        serializedName: "sourceOfKnowledge",
        type: {
          name: "String"
        }
      },
      ikpStateDictionaryValueId: {
        serializedName: "ikpStateDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      ikpStateDictionaryOtherValue: {
        serializedName: "ikpStateDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      ikpStateDictionaryValue: {
        serializedName: "ikpStateDictionaryValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ActivityDetailsDto: msRest.CompositeMapper = {
  serializedName: "ActivityDetailsDto",
  type: {
    name: "Composite",
    className: "ActivityDetailsDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      activityNumber: {
        serializedName: "activityNumber",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      topic: {
        serializedName: "topic",
        type: {
          name: "String"
        }
      },
      nzpTaskNumberDictionaryValues: {
        serializedName: "nzpTaskNumberDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      activityCategory: {
        serializedName: "activityCategory",
        type: {
          name: "Composite",
          className: "RizpDictionaryValueDto"
        }
      },
      prpzDictionaryValues: {
        serializedName: "prpzDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      publicHealthTaskDictionaryValues: {
        serializedName: "publicHealthTaskDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      comment: {
        serializedName: "comment",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      numberOfPersonAction: {
        nullable: true,
        serializedName: "numberOfPersonAction",
        type: {
          name: "Number"
        }
      },
      numberOfRecipients: {
        nullable: true,
        serializedName: "numberOfRecipients",
        type: {
          name: "Number"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      primaryActivityId: {
        serializedName: "primaryActivityId",
        type: {
          name: "Uuid"
        }
      },
      formVersionId: {
        serializedName: "formVersionId",
        type: {
          name: "Uuid"
        }
      },
      documentId: {
        serializedName: "documentId",
        type: {
          name: "String"
        }
      },
      activityPopulations: {
        serializedName: "activityPopulations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ActivityPopulationValueDto"
            }
          }
        }
      },
      implementingCompanies: {
        serializedName: "implementingCompanies",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImplementingCompanyDto"
            }
          }
        }
      },
      sponsoringCompanies: {
        serializedName: "sponsoringCompanies",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SponsoringCompanyValueDto"
            }
          }
        }
      },
      activityPatternId: {
        serializedName: "activityPatternId",
        type: {
          name: "Uuid"
        }
      },
      careAreasDictionaryValues: {
        serializedName: "careAreasDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      chosenPopulationHealthsDictionaryValues: {
        serializedName: "chosenPopulationHealthsDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      diseasesMortalityPrevalencesDictionaryValues: {
        serializedName: "diseasesMortalityPrevalencesDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      ikp: {
        serializedName: "ikp",
        type: {
          name: "Composite",
          className: "IkpDto"
        }
      }
    }
  }
};

export const ActivityPopulationDto: msRest.CompositeMapper = {
  serializedName: "ActivityPopulationDto",
  type: {
    name: "Composite",
    className: "ActivityPopulationDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      genderDictionaryValueId: {
        serializedName: "genderDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      genderDictionaryOtherValue: {
        serializedName: "genderDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      targetPopulationDictionaryValueId: {
        serializedName: "targetPopulationDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      targetPopulationDictionaryOtherValue: {
        serializedName: "targetPopulationDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      minAge: {
        serializedName: "minAge",
        type: {
          name: "Number"
        }
      },
      maxAge: {
        nullable: true,
        serializedName: "maxAge",
        type: {
          name: "Number"
        }
      },
      ageUnit: {
        serializedName: "ageUnit",
        type: {
          name: "Enum",
          allowedValues: [
            "Years",
            "Weeks"
          ]
        }
      },
      totalRecipients: {
        nullable: true,
        serializedName: "totalRecipients",
        type: {
          name: "Number"
        }
      },
      interventionLocations: {
        serializedName: "interventionLocations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      healthConditions: {
        serializedName: "healthConditions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      socialExclusions: {
        serializedName: "socialExclusions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      otherSpecialFeatures: {
        serializedName: "otherSpecialFeatures",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      }
    }
  }
};

export const SponsoringCompanyDto: msRest.CompositeMapper = {
  serializedName: "SponsoringCompanyDto",
  type: {
    name: "Composite",
    className: "SponsoringCompanyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      plannedCosts: {
        serializedName: "plannedCosts",
        type: {
          name: "Number"
        }
      },
      realizationCosts: {
        nullable: true,
        serializedName: "realizationCosts",
        type: {
          name: "Number"
        }
      },
      financingSourceDictionaryValues: {
        serializedName: "financingSourceDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FinancingSourceDto"
            }
          }
        }
      },
      isMainSubject: {
        serializedName: "isMainSubject",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const IntervetionActivityDto: msRest.CompositeMapper = {
  serializedName: "IntervetionActivityDto",
  type: {
    name: "Composite",
    className: "IntervetionActivityDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      topic: {
        serializedName: "topic",
        type: {
          name: "String"
        }
      },
      activityNumber: {
        serializedName: "activityNumber",
        type: {
          name: "String"
        }
      },
      comment: {
        serializedName: "comment",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      nzpTaskNumberDictionaryValues: {
        serializedName: "nzpTaskNumberDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      activityCategoryDictionaryValueId: {
        serializedName: "activityCategoryDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      activityCategoryDictionaryOtherValue: {
        serializedName: "activityCategoryDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      prpzDictionaryValues: {
        serializedName: "prpzDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      publicHealthTaskDictionaryValues: {
        serializedName: "publicHealthTaskDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      activityPopulations: {
        serializedName: "activityPopulations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ActivityPopulationDto"
            }
          }
        }
      },
      implementingCompanies: {
        serializedName: "implementingCompanies",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImplementingCompanyDto"
            }
          }
        }
      },
      sponsoringCompanies: {
        serializedName: "sponsoringCompanies",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SponsoringCompanyDto"
            }
          }
        }
      },
      numberOfPersonAction: {
        nullable: true,
        serializedName: "numberOfPersonAction",
        type: {
          name: "Number"
        }
      },
      numberOfRecipients: {
        nullable: true,
        serializedName: "numberOfRecipients",
        type: {
          name: "Number"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      primaryActivityId: {
        serializedName: "primaryActivityId",
        type: {
          name: "Uuid"
        }
      },
      chosenPopulationHealthsDictionaryValues: {
        serializedName: "chosenPopulationHealthsDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      careAreasDictionaryValues: {
        serializedName: "careAreasDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      diseasesMortalityPrevalencesDictionaryValues: {
        serializedName: "diseasesMortalityPrevalencesDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      ikp: {
        serializedName: "ikp",
        type: {
          name: "Composite",
          className: "IkpDto"
        }
      }
    }
  }
};

export const CreateActivityCommand: msRest.CompositeMapper = {
  serializedName: "CreateActivityCommand",
  type: {
    name: "Composite",
    className: "CreateActivityCommand",
    modelProperties: {
      interventionId: {
        serializedName: "interventionId",
        type: {
          name: "Uuid"
        }
      },
      activity: {
        serializedName: "activity",
        type: {
          name: "Composite",
          className: "IntervetionActivityDto"
        }
      }
    }
  }
};

export const GuidIIdDto: msRest.CompositeMapper = {
  serializedName: "GuidIIdDto",
  type: {
    name: "Composite",
    className: "GuidIIdDto",
    modelProperties: {
      id: {
        readOnly: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const UpdateActivityCommand: msRest.CompositeMapper = {
  serializedName: "UpdateActivityCommand",
  type: {
    name: "Composite",
    className: "UpdateActivityCommand",
    modelProperties: {
      interventionId: {
        serializedName: "interventionId",
        type: {
          name: "Uuid"
        }
      },
      activity: {
        serializedName: "activity",
        type: {
          name: "Composite",
          className: "IntervetionActivityDto"
        }
      }
    }
  }
};

export const ProjectRegisteredCommand: msRest.CompositeMapper = {
  serializedName: "ProjectRegisteredCommand",
  type: {
    name: "Composite",
    className: "ProjectRegisteredCommand",
    modelProperties: {
      interventionId: {
        serializedName: "interventionId",
        type: {
          name: "Uuid"
        }
      },
      aotmitNumber: {
        serializedName: "aotmitNumber",
        type: {
          name: "String"
        }
      },
      aotmitCreatorUrl: {
        serializedName: "aotmitCreatorUrl",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SetStatusCommand: msRest.CompositeMapper = {
  serializedName: "SetStatusCommand",
  type: {
    name: "Composite",
    className: "SetStatusCommand",
    modelProperties: {
      interventionId: {
        serializedName: "interventionId",
        type: {
          name: "Uuid"
        }
      },
      aotMiTStatus: {
        serializedName: "aotMiTStatus",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AOTMiTFile: msRest.CompositeMapper = {
  serializedName: "AOTMiTFile",
  type: {
    name: "Composite",
    className: "AOTMiTFile",
    modelProperties: {
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileExtension: {
        serializedName: "fileExtension",
        type: {
          name: "String"
        }
      },
      mimeType: {
        serializedName: "mimeType",
        type: {
          name: "String"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "ByteArray"
        }
      },
      fileCategory: {
        serializedName: "fileCategory",
        type: {
          name: "Enum",
          allowedValues: [
            "REPORT",
            "OPINION_RP",
            "OPINION_PRESIDENT",
            "FINAL_REPORT"
          ]
        }
      }
    }
  }
};

export const OpinionAOTMiTCommand: msRest.CompositeMapper = {
  serializedName: "OpinionAOTMiTCommand",
  type: {
    name: "Composite",
    className: "OpinionAOTMiTCommand",
    modelProperties: {
      interventionId: {
        serializedName: "interventionId",
        type: {
          name: "Uuid"
        }
      },
      opinion: {
        serializedName: "opinion",
        type: {
          name: "Enum",
          allowedValues: [
            "POSITIVE",
            "NEGATIVE",
            "CONDITIONALLY_POSITIVE",
            "NOT_SUBJECT_TO_OPINION",
            "FINAL_REPORT"
          ]
        }
      },
      files: {
        serializedName: "files",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AOTMiTFile"
            }
          }
        }
      }
    }
  }
};

export const FileDto: msRest.CompositeMapper = {
  serializedName: "FileDto",
  type: {
    name: "Composite",
    className: "FileDto",
    modelProperties: {
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileExtension: {
        serializedName: "fileExtension",
        type: {
          name: "String"
        }
      },
      mimeType: {
        serializedName: "mimeType",
        type: {
          name: "String"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "ByteArray"
        }
      },
      rootDirectoryId: {
        serializedName: "rootDirectoryId",
        type: {
          name: "Uuid"
        }
      },
      sourceDocumentId: {
        serializedName: "sourceDocumentId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const AddRecommendationsCommand: msRest.CompositeMapper = {
  serializedName: "AddRecommendationsCommand",
  type: {
    name: "Composite",
    className: "AddRecommendationsCommand",
    modelProperties: {
      recommendationId: {
        serializedName: "recommendationId",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      recommendationFile: {
        serializedName: "recommendationFile",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      },
      transparencyCouncilOpinion: {
        serializedName: "transparencyCouncilOpinion",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      },
      analyticalReport: {
        serializedName: "analyticalReport",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const AddDetailedReportAnnexCommand: msRest.CompositeMapper = {
  serializedName: "AddDetailedReportAnnexCommand",
  type: {
    name: "Composite",
    className: "AddDetailedReportAnnexCommand",
    modelProperties: {
      detailedReportAnnexId: {
        serializedName: "detailedReportAnnexId",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const AOTMiTSubjectAddressDto: msRest.CompositeMapper = {
  serializedName: "AOTMiTSubjectAddressDto",
  type: {
    name: "Composite",
    className: "AOTMiTSubjectAddressDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      zipCode: {
        serializedName: "zipCode",
        type: {
          name: "String"
        }
      },
      isAddressManual: {
        serializedName: "isAddressManual",
        type: {
          name: "Boolean"
        }
      },
      simcCode: {
        serializedName: "simcCode",
        type: {
          name: "String"
        }
      },
      tercCode: {
        serializedName: "tercCode",
        type: {
          name: "String"
        }
      },
      streetCode: {
        serializedName: "streetCode",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      propertyNumber: {
        serializedName: "propertyNumber",
        type: {
          name: "String"
        }
      },
      placeNumber: {
        serializedName: "placeNumber",
        type: {
          name: "String"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      voivodeship: {
        serializedName: "voivodeship",
        type: {
          name: "String"
        }
      },
      county: {
        serializedName: "county",
        type: {
          name: "String"
        }
      },
      community: {
        serializedName: "community",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SubjectContactDto: msRest.CompositeMapper = {
  serializedName: "SubjectContactDto",
  type: {
    name: "Composite",
    className: "SubjectContactDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      connectedWithIkpRecrutation: {
        serializedName: "connectedWithIkpRecrutation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AOTMiTPopulationDto: msRest.CompositeMapper = {
  serializedName: "AOTMiTPopulationDto",
  type: {
    name: "Composite",
    className: "AOTMiTPopulationDto",
    modelProperties: {
      gender: {
        serializedName: "gender",
        type: {
          name: "String"
        }
      },
      targetPopulation: {
        serializedName: "targetPopulation",
        type: {
          name: "String"
        }
      },
      interventionLocation: {
        serializedName: "interventionLocation",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      healthCondition: {
        serializedName: "healthCondition",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      socialExclusion: {
        serializedName: "socialExclusion",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      otherSpecialFeature: {
        serializedName: "otherSpecialFeature",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      minAge: {
        serializedName: "minAge",
        type: {
          name: "Number"
        }
      },
      maxAge: {
        nullable: true,
        serializedName: "maxAge",
        type: {
          name: "Number"
        }
      },
      ageUnit: {
        serializedName: "ageUnit",
        type: {
          name: "Enum",
          allowedValues: [
            "Years",
            "Weeks"
          ]
        }
      }
    }
  }
};

export const ActivityAOTMiTDto: msRest.CompositeMapper = {
  serializedName: "ActivityAOTMiTDto",
  type: {
    name: "Composite",
    className: "ActivityAOTMiTDto",
    modelProperties: {
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const AOTMiTBaseFile: msRest.CompositeMapper = {
  serializedName: "AOTMiTBaseFile",
  type: {
    name: "Composite",
    className: "AOTMiTBaseFile",
    modelProperties: {
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileExtension: {
        serializedName: "fileExtension",
        type: {
          name: "String"
        }
      },
      mimeType: {
        serializedName: "mimeType",
        type: {
          name: "String"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const AOTMiTCreatorRequest: msRest.CompositeMapper = {
  serializedName: "AOTMiTCreatorRequest",
  type: {
    name: "Composite",
    className: "AOTMiTCreatorRequest",
    modelProperties: {
      interventionId: {
        serializedName: "interventionId",
        type: {
          name: "Uuid"
        }
      },
      aotMiTNumber: {
        serializedName: "aotMiTNumber",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AOTMiTSubjectAddressDto"
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectContactDto"
            }
          }
        }
      },
      plannedDateFrom: {
        serializedName: "plannedDateFrom",
        type: {
          name: "DateTime"
        }
      },
      plannedDateTo: {
        serializedName: "plannedDateTo",
        type: {
          name: "DateTime"
        }
      },
      populations: {
        serializedName: "populations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AOTMiTPopulationDto"
            }
          }
        }
      },
      plannedPopulation: {
        serializedName: "plannedPopulation",
        type: {
          name: "Number"
        }
      },
      plannedPopulationYear: {
        serializedName: "plannedPopulationYear",
        type: {
          name: "Number"
        }
      },
      activities: {
        serializedName: "activities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ActivityAOTMiTDto"
            }
          }
        }
      },
      totalPalannedYearCost: {
        serializedName: "totalPalannedYearCost",
        type: {
          name: "Number"
        }
      },
      totalPlannedCost: {
        serializedName: "totalPlannedCost",
        type: {
          name: "Number"
        }
      },
      financingSources: {
        serializedName: "financingSources",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      chosenPopulationHealths: {
        serializedName: "chosenPopulationHealths",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      careAreas: {
        serializedName: "careAreas",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      diseasesMortalityPrevalences: {
        serializedName: "diseasesMortalityPrevalences",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      files: {
        serializedName: "files",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AOTMiTBaseFile"
            }
          }
        }
      }
    }
  }
};

export const GenerateApiKeyCommand: msRest.CompositeMapper = {
  serializedName: "GenerateApiKeyCommand",
  type: {
    name: "Composite",
    className: "GenerateApiKeyCommand",
    modelProperties: {
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ApiKeyDto: msRest.CompositeMapper = {
  serializedName: "ApiKeyDto",
  type: {
    name: "Composite",
    className: "ApiKeyDto",
    modelProperties: {
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      apiKey: {
        serializedName: "apiKey",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RegenerateApiKeyCommand: msRest.CompositeMapper = {
  serializedName: "RegenerateApiKeyCommand",
  type: {
    name: "Composite",
    className: "RegenerateApiKeyCommand",
    modelProperties: {
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ISubject: msRest.CompositeMapper = {
  serializedName: "ISubject",
  type: {
    name: "Composite",
    className: "ISubject",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      regon: {
        serializedName: "regon",
        type: {
          name: "String"
        }
      },
      permissions: {
        serializedName: "permissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "Dictionaries_List_Preview",
                "Dictionaries_Details_Preview",
                "Dictionaries_Versions_Modifications",
                "Admin_Roles_Preview",
                "Admin_Roles_Modifications",
                "Admin_Roles_Assignment_Global",
                "Admin_Roles_Assignment_Local",
                "Admin_ApiKeys_Management",
                "RIZP_Preview_Mine",
                "RIZP_Preview_Subordinate",
                "RIZP_Preview_All",
                "RIZP_Modifications",
                "RIZP_Patterns_Modifications_Nationwide",
                "RIZP_Patterns_Modifications_Voivodeship",
                "RIZP_Archive",
                "Subjects_List_Preview",
                "Subjects_Details_Preview",
                "Subjects_Modifications_Mine",
                "Subjects_Modifications_With_Teryt_Mine",
                "Subjects_Modifications_All",
                "Subjects_Modifications_With_Teryt_All",
                "Subjects_Modifications_Statement_Obligation",
                "Subjects_Merge",
                "Subjects_Proposal_Possibility_Handling",
                "Statements_Archive",
                "Statements_Generation_MZ53",
                "Statements_Generation_MZ49",
                "Statements_Generation_NPZ",
                "Statement_Preview_Mine_MZ49",
                "Statement_Preview_Mine_MZ53",
                "Statement_Preview_Mine_NPZ",
                "Statement_Preview_Subordinate_MZ49",
                "Statement_Preview_Subordinate_MZ53",
                "Statement_Preview_Subordinate_NPZ",
                "Statement_Preview_All_MZ49",
                "Statement_Preview_All_MZ53",
                "Statement_Preview_All_NPZ",
                "Statement_Verification_MZ49",
                "Statement_Verification_MZ53",
                "Statement_Verification_NPZ",
                "Statement_Critical_Warning",
                "Proposals_Handling_All",
                "Proposals_Handling_Mine",
                "DocumentsRepository_List_Preview",
                "DocumentsRepository_Preview",
                "DocumentsRepository_VoivodeOpinions_Preview",
                "DocumentsRepository_AllVoivodeOpinions_Preview",
                "DocumentsRepository_VoivodeOpinions_Modifications",
                "Reports_MZ_Preview",
                "Reports_Programy_Zdrowotne_Preview",
                "Reports_AOTMiT_Preview",
                "Reports_RP_Preview",
                "Reports_SZ_Chorobowosc_Preview",
                "Reports_SZ_Umieralnosc_Preview",
                "Reports_SZ_Deprywacja_Preview",
                "Reports_SZ_Statusy_Preview",
                "Reports_SZ_Demografia_Preview",
                "Reports_SZ_Preview",
                "Reports_All_Preview",
                "Reports_Technical",
                "Aotmit_Add_Recommendations",
                "Aotmit_Add_Comments",
                "Aotmit_Add_DetailedReportAnnex",
                "Aotmit_Projects",
                "Forms_Management",
                "Notifications_Management",
                "UserManual_Management",
                "Tooltip_Management",
                "Tags_Management_Global",
                "Tags_Management_Local",
                "Aggregate_Data",
                "IKP"
              ]
            }
          }
        }
      }
    }
  }
};

export const CreateDictionaryCommand: msRest.CompositeMapper = {
  serializedName: "CreateDictionaryCommand",
  type: {
    name: "Composite",
    className: "CreateDictionaryCommand",
    modelProperties: {
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isHierarchical: {
        serializedName: "isHierarchical",
        type: {
          name: "Boolean"
        }
      },
      isCodeNameInValuesRequired: {
        serializedName: "isCodeNameInValuesRequired",
        type: {
          name: "Boolean"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Int32IIdDto: msRest.CompositeMapper = {
  serializedName: "Int32IIdDto",
  type: {
    name: "Composite",
    className: "Int32IIdDto",
    modelProperties: {
      id: {
        readOnly: true,
        serializedName: "id",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const UpdateDictionaryCommand: msRest.CompositeMapper = {
  serializedName: "UpdateDictionaryCommand",
  type: {
    name: "Composite",
    className: "UpdateDictionaryCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DictionaryViewDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "DictionaryViewDtoPagedResult",
  type: {
    name: "Composite",
    className: "DictionaryViewDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryViewDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DictionaryVersionItemDto: msRest.CompositeMapper = {
  serializedName: "DictionaryVersionItemDto",
  type: {
    name: "Composite",
    className: "DictionaryVersionItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "ACTIVE",
            "INACTIVE",
            "DELETED"
          ]
        }
      },
      lastStatusModifiedDate: {
        serializedName: "lastStatusModifiedDate",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      version: {
        serializedName: "version",
        type: {
          name: "String"
        }
      },
      interventionPaths: {
        serializedName: "interventionPaths",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "PROHEALTH_ACTIVITIES",
                "RESEARCH",
                "SUPERVISION_MONITORING",
                "SUPERVISION_SANITARY",
                "STAFF_EDUCATION",
                "STAFF_MONITORING",
                "LEGISLATION"
              ]
            }
          }
        }
      }
    }
  }
};

export const DictionaryDto: msRest.CompositeMapper = {
  serializedName: "DictionaryDto",
  type: {
    name: "Composite",
    className: "DictionaryDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      isHierarchical: {
        serializedName: "isHierarchical",
        type: {
          name: "Boolean"
        }
      },
      isCodeNameInValuesRequired: {
        serializedName: "isCodeNameInValuesRequired",
        type: {
          name: "Boolean"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      versions: {
        serializedName: "versions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryVersionItemDto"
            }
          }
        }
      }
    }
  }
};

export const DictionaryInfoDto: msRest.CompositeMapper = {
  serializedName: "DictionaryInfoDto",
  type: {
    name: "Composite",
    className: "DictionaryInfoDto",
    modelProperties: {
      dictionaryId: {
        serializedName: "dictionaryId",
        type: {
          name: "Number"
        }
      },
      isHierarchical: {
        serializedName: "isHierarchical",
        type: {
          name: "Boolean"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueItemDto"
            }
          }
        }
      }
    }
  }
};

export const DictionaryValueWithPathViewDto: msRest.CompositeMapper = {
  serializedName: "DictionaryValueWithPathViewDto",
  type: {
    name: "Composite",
    className: "DictionaryValueWithPathViewDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      pathIds: {
        serializedName: "pathIds",
        type: {
          name: "String"
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      dictionaryVersionId: {
        serializedName: "dictionaryVersionId",
        type: {
          name: "Number"
        }
      },
      level: {
        serializedName: "level",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DictionaryValueWithPathViewDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "DictionaryValueWithPathViewDtoPagedResult",
  type: {
    name: "Composite",
    className: "DictionaryValueWithPathViewDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueWithPathViewDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CreateDictionaryValueCommand: msRest.CompositeMapper = {
  serializedName: "CreateDictionaryValueCommand",
  type: {
    name: "Composite",
    className: "CreateDictionaryValueCommand",
    modelProperties: {
      dictionaryVersionId: {
        serializedName: "dictionaryVersionId",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      canAddTextValue: {
        serializedName: "canAddTextValue",
        type: {
          name: "Boolean"
        }
      },
      canBeSelected: {
        serializedName: "canBeSelected",
        type: {
          name: "Boolean"
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      interventionPaths: {
        serializedName: "interventionPaths",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "PROHEALTH_ACTIVITIES",
                "RESEARCH",
                "SUPERVISION_MONITORING",
                "SUPERVISION_SANITARY",
                "STAFF_EDUCATION",
                "STAFF_MONITORING",
                "LEGISLATION"
              ]
            }
          }
        }
      }
    }
  }
};

export const UpdateDictionaryValueCommand: msRest.CompositeMapper = {
  serializedName: "UpdateDictionaryValueCommand",
  type: {
    name: "Composite",
    className: "UpdateDictionaryValueCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      canAddTextValue: {
        serializedName: "canAddTextValue",
        type: {
          name: "Boolean"
        }
      },
      canBeSelected: {
        serializedName: "canBeSelected",
        type: {
          name: "Boolean"
        }
      },
      interventionPaths: {
        serializedName: "interventionPaths",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "PROHEALTH_ACTIVITIES",
                "RESEARCH",
                "SUPERVISION_MONITORING",
                "SUPERVISION_SANITARY",
                "STAFF_EDUCATION",
                "STAFF_MONITORING",
                "LEGISLATION"
              ]
            }
          }
        }
      }
    }
  }
};

export const SetOrderValueCommand: msRest.CompositeMapper = {
  serializedName: "SetOrderValueCommand",
  type: {
    name: "Composite",
    className: "SetOrderValueCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      order: {
        serializedName: "order",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const UpdateDictionaryValueParentCommand: msRest.CompositeMapper = {
  serializedName: "UpdateDictionaryValueParentCommand",
  type: {
    name: "Composite",
    className: "UpdateDictionaryValueParentCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CheckDictionaryValueChangeVersionQuery: msRest.CompositeMapper = {
  serializedName: "CheckDictionaryValueChangeVersionQuery",
  type: {
    name: "Composite",
    className: "CheckDictionaryValueChangeVersionQuery",
    modelProperties: {
      interventionPaths: {
        serializedName: "interventionPaths",
        type: {
          name: "Enum",
          allowedValues: [
            "PROHEALTH_ACTIVITIES",
            "RESEARCH",
            "SUPERVISION_MONITORING",
            "SUPERVISION_SANITARY",
            "STAFF_EDUCATION",
            "STAFF_MONITORING",
            "LEGISLATION"
          ]
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      dictionaryValueIds: {
        nullable: true,
        serializedName: "dictionaryValueIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number"
            }
          }
        }
      }
    }
  }
};

export const CreateDictionaryVersionCommand: msRest.CompositeMapper = {
  serializedName: "CreateDictionaryVersionCommand",
  type: {
    name: "Composite",
    className: "CreateDictionaryVersionCommand",
    modelProperties: {
      dictionaryId: {
        serializedName: "dictionaryId",
        type: {
          name: "Number"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const UpdateDictionaryVersionCommand: msRest.CompositeMapper = {
  serializedName: "UpdateDictionaryVersionCommand",
  type: {
    name: "Composite",
    className: "UpdateDictionaryVersionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const ActivateDictionaryVersionCommand: msRest.CompositeMapper = {
  serializedName: "ActivateDictionaryVersionCommand",
  type: {
    name: "Composite",
    className: "ActivateDictionaryVersionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DeactivateDictionaryVersionCommand: msRest.CompositeMapper = {
  serializedName: "DeactivateDictionaryVersionCommand",
  type: {
    name: "Composite",
    className: "DeactivateDictionaryVersionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DictionaryVersionDto: msRest.CompositeMapper = {
  serializedName: "DictionaryVersionDto",
  type: {
    name: "Composite",
    className: "DictionaryVersionDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      dictionaryId: {
        serializedName: "dictionaryId",
        type: {
          name: "Number"
        }
      },
      dictionaryName: {
        serializedName: "dictionaryName",
        type: {
          name: "String"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      version: {
        serializedName: "version",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "ACTIVE",
            "INACTIVE",
            "DELETED"
          ]
        }
      },
      lastStatusModifiedDate: {
        serializedName: "lastStatusModifiedDate",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const CloneDictionaryVersionCommand: msRest.CompositeMapper = {
  serializedName: "CloneDictionaryVersionCommand",
  type: {
    name: "Composite",
    className: "CloneDictionaryVersionCommand",
    modelProperties: {
      dictionaryVersionId: {
        serializedName: "dictionaryVersionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DocumentListItemDto: msRest.CompositeMapper = {
  serializedName: "DocumentListItemDto",
  type: {
    name: "Composite",
    className: "DocumentListItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      source: {
        serializedName: "source",
        type: {
          name: "Enum",
          allowedValues: [
            "OTHER",
            "AOTMiT",
            "INTERVENTION",
            "MZ_49",
            "MZ_53",
            "NPZ",
            "SUBJECT"
          ]
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Enum",
          allowedValues: [
            "Attachment",
            "Statement",
            "VoivodeOpinion"
          ]
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "ACCEPTED",
            "COMPLETED",
            "IN_VERIFICATION",
            "DUPLICATE",
            "TO_COMPLETE",
            "TO_IMPROVE",
            "INCORRECT",
            "ARCHIVED",
            "REJECTED",
            "RECLOSED",
            "NEW",
            "DELETED",
            "DRAFT",
            "PLANNED",
            "SUSPENDED",
            "REALIZATION",
            "PATTERN",
            "CLOSED",
            "CANCELED",
            "SENDED_TO_OPINION_AOTMIT",
            "COMPLETED_UNREALIZED",
            "COMPLETED_REALIZED",
            "MERGED",
            "MODIFIED",
            "ADDED"
          ]
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      year: {
        nullable: true,
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      county: {
        serializedName: "county",
        type: {
          name: "String"
        }
      },
      voivodeship: {
        serializedName: "voivodeship",
        type: {
          name: "String"
        }
      },
      commune: {
        serializedName: "commune",
        type: {
          name: "String"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      programYearFrom: {
        nullable: true,
        serializedName: "programYearFrom",
        type: {
          name: "Number"
        }
      },
      programYearTo: {
        nullable: true,
        serializedName: "programYearTo",
        type: {
          name: "Number"
        }
      },
      decisionProblem: {
        serializedName: "decisionProblem",
        type: {
          name: "String"
        }
      },
      presidentOpinionKind: {
        serializedName: "presidentOpinionKind",
        type: {
          name: "String"
        }
      },
      opinionPresidentYear: {
        nullable: true,
        serializedName: "opinionPresidentYear",
        type: {
          name: "Number"
        }
      },
      documentSubjectKind: {
        serializedName: "documentSubjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "MZ",
            "JST"
          ]
        }
      },
      number: {
        serializedName: "number",
        type: {
          name: "String"
        }
      },
      fileId: {
        serializedName: "fileId",
        type: {
          name: "Uuid"
        }
      },
      fileType: {
        serializedName: "fileType",
        type: {
          name: "String"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const DocumentListItemDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "DocumentListItemDtoPagedResult",
  type: {
    name: "Composite",
    className: "DocumentListItemDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentListItemDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FileTreeItemDto: msRest.CompositeMapper = {
  serializedName: "FileTreeItemDto",
  type: {
    name: "Composite",
    className: "FileTreeItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileType: {
        serializedName: "fileType",
        type: {
          name: "String"
        }
      },
      creationDate: {
        serializedName: "creationDate",
        type: {
          name: "DateTime"
        }
      },
      fileCategoryDictionaryValueId: {
        nullable: true,
        serializedName: "fileCategoryDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      fileCategoryDictionaryValue: {
        serializedName: "fileCategoryDictionaryValue",
        type: {
          name: "String"
        }
      },
      fileCategoryDictionaryOrder: {
        serializedName: "fileCategoryDictionaryOrder",
        type: {
          name: "Number"
        }
      },
      canBeModified: {
        serializedName: "canBeModified",
        type: {
          name: "Boolean"
        }
      },
      importErrorMessage: {
        serializedName: "importErrorMessage",
        type: {
          name: "String"
        }
      },
      files: {
        serializedName: "files",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FileTreeItemDto"
            }
          }
        }
      }
    }
  }
};

export const AotmitDetailsDto: msRest.CompositeMapper = {
  serializedName: "AotmitDetailsDto",
  type: {
    name: "Composite",
    className: "AotmitDetailsDto",
    modelProperties: {
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      year: {
        nullable: true,
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      voivodeship: {
        serializedName: "voivodeship",
        type: {
          name: "String"
        }
      },
      county: {
        serializedName: "county",
        type: {
          name: "String"
        }
      },
      programYearFrom: {
        nullable: true,
        serializedName: "programYearFrom",
        type: {
          name: "Number"
        }
      },
      programYearTo: {
        nullable: true,
        serializedName: "programYearTo",
        type: {
          name: "Number"
        }
      },
      decisionProblem: {
        serializedName: "decisionProblem",
        type: {
          name: "String"
        }
      },
      presidentOpinionKind: {
        serializedName: "presidentOpinionKind",
        type: {
          name: "String"
        }
      },
      opinionPresidentYear: {
        nullable: true,
        serializedName: "opinionPresidentYear",
        type: {
          name: "Number"
        }
      },
      documentSubjectKind: {
        serializedName: "documentSubjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "MZ",
            "JST"
          ]
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      number: {
        serializedName: "number",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DocumentDetailsDto: msRest.CompositeMapper = {
  serializedName: "DocumentDetailsDto",
  type: {
    name: "Composite",
    className: "DocumentDetailsDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      voivodeship: {
        serializedName: "voivodeship",
        type: {
          name: "String"
        }
      },
      county: {
        serializedName: "county",
        type: {
          name: "String"
        }
      },
      commune: {
        serializedName: "commune",
        type: {
          name: "String"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      opinionPresidentYear: {
        nullable: true,
        serializedName: "opinionPresidentYear",
        type: {
          name: "Number"
        }
      },
      number: {
        serializedName: "number",
        type: {
          name: "String"
        }
      },
      processKind: {
        serializedName: "processKind",
        type: {
          name: "String"
        }
      },
      year: {
        nullable: true,
        serializedName: "year",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DocumentDto: msRest.CompositeMapper = {
  serializedName: "DocumentDto",
  type: {
    name: "Composite",
    className: "DocumentDto",
    modelProperties: {
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      actionKind: {
        serializedName: "actionKind",
        type: {
          name: "Enum",
          allowedValues: [
            "Added",
            "Downloaded",
            "Deleted"
          ]
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      channelKind: {
        serializedName: "channelKind",
        type: {
          name: "Enum",
          allowedValues: [
            "Form",
            "External_System",
            "External_System_PZH"
          ]
        }
      },
      fileId: {
        serializedName: "fileId",
        type: {
          name: "Uuid"
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileTreeItemDto"
        }
      },
      numberOfFiles: {
        serializedName: "numberOfFiles",
        type: {
          name: "Number"
        }
      },
      categoryCodeName: {
        serializedName: "categoryCodeName",
        type: {
          name: "String"
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      documentAotmitType: {
        serializedName: "documentAotmitType",
        type: {
          name: "Enum",
          allowedValues: [
            "PPZ",
            "Recommendation",
            "DetailedReportAnnex",
            "FinalReport",
            "Pronouncement"
          ]
        }
      },
      aotmitDetails: {
        serializedName: "aotmitDetails",
        type: {
          name: "Composite",
          className: "AotmitDetailsDto"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "Composite",
          className: "DocumentDetailsDto"
        }
      },
      isVoivodeOpinion: {
        serializedName: "isVoivodeOpinion",
        type: {
          name: "Boolean"
        }
      },
      isArchive: {
        serializedName: "isArchive",
        type: {
          name: "Boolean"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      number: {
        serializedName: "number",
        type: {
          name: "String"
        }
      },
      opinionPresidentYear: {
        nullable: true,
        serializedName: "opinionPresidentYear",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AddSourceDocumentTagCommand: msRest.CompositeMapper = {
  serializedName: "AddSourceDocumentTagCommand",
  type: {
    name: "Composite",
    className: "AddSourceDocumentTagCommand",
    modelProperties: {
      sourceDocumentId: {
        serializedName: "sourceDocumentId",
        type: {
          name: "Uuid"
        }
      },
      tagName: {
        serializedName: "tagName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeleteSourceDocumentTagCommand: msRest.CompositeMapper = {
  serializedName: "DeleteSourceDocumentTagCommand",
  type: {
    name: "Composite",
    className: "DeleteSourceDocumentTagCommand",
    modelProperties: {
      sourceDocumentId: {
        serializedName: "sourceDocumentId",
        type: {
          name: "Uuid"
        }
      },
      tagName: {
        serializedName: "tagName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ProblemDetails: msRest.CompositeMapper = {
  serializedName: "ProblemDetails",
  type: {
    name: "Composite",
    className: "ProblemDetails",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      status: {
        nullable: true,
        serializedName: "status",
        type: {
          name: "Number"
        }
      },
      detail: {
        serializedName: "detail",
        type: {
          name: "String"
        }
      },
      instance: {
        serializedName: "instance",
        type: {
          name: "String"
        }
      }
    },
    additionalProperties: {
      type: {
        name: "Object"
      }
    }
  }
};

export const SetFileCategoryCommand: msRest.CompositeMapper = {
  serializedName: "SetFileCategoryCommand",
  type: {
    name: "Composite",
    className: "SetFileCategoryCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      fileCategoryDictionaryValueId: {
        serializedName: "fileCategoryDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      fileCategoryDictionaryOtherValue: {
        serializedName: "fileCategoryDictionaryOtherValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FormVersionItemDto: msRest.CompositeMapper = {
  serializedName: "FormVersionItemDto",
  type: {
    name: "Composite",
    className: "FormVersionItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "ACTIVE",
            "INACTIVE",
            "DELETED"
          ]
        }
      },
      version: {
        serializedName: "version",
        type: {
          name: "String"
        }
      },
      xsltDefinition: {
        serializedName: "xsltDefinition",
        type: {
          name: "String"
        }
      },
      xlsxDefinition: {
        serializedName: "xlsxDefinition",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const FormDto: msRest.CompositeMapper = {
  serializedName: "FormDto",
  type: {
    name: "Composite",
    className: "FormDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "StatementNPZ",
            "StatementMZ49",
            "StatementMZ53",
            "AOTMiTFinalReport",
            "ActivityProhealthActivities",
            "ActivityResearch",
            "ActivitySupervisionMonitoring",
            "ActivitySupervisionSanitary",
            "ActivityStaffEducation",
            "ActivityStaffMonitoring",
            "ActivityLegislation",
            "InterventionProhealthActivities",
            "InterventionResearch",
            "InterventionSupervisionMonitoring",
            "InterventionSupervisionSanitary",
            "InterventionStaffEducation",
            "InterventionStaffMonitoring",
            "InterventionLegislation"
          ]
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      versions: {
        serializedName: "versions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormVersionItemDto"
            }
          }
        }
      }
    }
  }
};

export const FormPartDto: msRest.CompositeMapper = {
  serializedName: "FormPartDto",
  type: {
    name: "Composite",
    className: "FormPartDto",
    modelProperties: {
      applicationName: {
        serializedName: "applicationName",
        type: {
          name: "String"
        }
      },
      formName: {
        serializedName: "formName",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        type: {
          name: "Number"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      pageOrientation: {
        serializedName: "pageOrientation",
        type: {
          name: "Enum",
          allowedValues: [
            "Landscape",
            "Portrait"
          ]
        }
      },
      isVisibleInViewModel: {
        nullable: true,
        serializedName: "isVisibleInViewModel",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CreateFormPartCommand: msRest.CompositeMapper = {
  serializedName: "CreateFormPartCommand",
  type: {
    name: "Composite",
    className: "CreateFormPartCommand",
    modelProperties: {
      formVersionId: {
        serializedName: "formVersionId",
        type: {
          name: "Uuid"
        }
      },
      applicationName: {
        serializedName: "applicationName",
        type: {
          name: "String"
        }
      },
      formName: {
        serializedName: "formName",
        type: {
          name: "String"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      pageOrientation: {
        serializedName: "pageOrientation",
        type: {
          name: "Enum",
          allowedValues: [
            "Landscape",
            "Portrait"
          ]
        }
      },
      isVisibleInViewModel: {
        nullable: true,
        serializedName: "isVisibleInViewModel",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UpdateFormPartCommand: msRest.CompositeMapper = {
  serializedName: "UpdateFormPartCommand",
  type: {
    name: "Composite",
    className: "UpdateFormPartCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      pageOrientation: {
        serializedName: "pageOrientation",
        type: {
          name: "Enum",
          allowedValues: [
            "Landscape",
            "Portrait"
          ]
        }
      },
      isVisibleInViewModel: {
        nullable: true,
        serializedName: "isVisibleInViewModel",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SetOrderFormPartCommand: msRest.CompositeMapper = {
  serializedName: "SetOrderFormPartCommand",
  type: {
    name: "Composite",
    className: "SetOrderFormPartCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      order: {
        serializedName: "order",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FormVersionDto: msRest.CompositeMapper = {
  serializedName: "FormVersionDto",
  type: {
    name: "Composite",
    className: "FormVersionDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      version: {
        serializedName: "version",
        type: {
          name: "String"
        }
      },
      xsltDefinition: {
        serializedName: "xsltDefinition",
        type: {
          name: "String"
        }
      },
      xlsxDefinition: {
        serializedName: "xlsxDefinition",
        type: {
          name: "ByteArray"
        }
      },
      parts: {
        serializedName: "parts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormPartDto"
            }
          }
        }
      },
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className: "FormDto"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const ActivateFormVersionCommand: msRest.CompositeMapper = {
  serializedName: "ActivateFormVersionCommand",
  type: {
    name: "Composite",
    className: "ActivateFormVersionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DeactivateFormVersionCommand: msRest.CompositeMapper = {
  serializedName: "DeactivateFormVersionCommand",
  type: {
    name: "Composite",
    className: "DeactivateFormVersionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const CloneFormVersionCommand: msRest.CompositeMapper = {
  serializedName: "CloneFormVersionCommand",
  type: {
    name: "Composite",
    className: "CloneFormVersionCommand",
    modelProperties: {
      formVersionId: {
        serializedName: "formVersionId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DictionaryValueIKPDto: msRest.CompositeMapper = {
  serializedName: "DictionaryValueIKPDto",
  type: {
    name: "Composite",
    className: "DictionaryValueIKPDto",
    modelProperties: {
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SubjectIKPDto: msRest.CompositeMapper = {
  serializedName: "SubjectIKPDto",
  type: {
    name: "Composite",
    className: "SubjectIKPDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      zipCode: {
        serializedName: "zipCode",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      voivodeship: {
        serializedName: "voivodeship",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PopulationIKPDto: msRest.CompositeMapper = {
  serializedName: "PopulationIKPDto",
  type: {
    name: "Composite",
    className: "PopulationIKPDto",
    modelProperties: {
      minAge: {
        nullable: true,
        serializedName: "minAge",
        type: {
          name: "Number"
        }
      },
      maxAge: {
        nullable: true,
        serializedName: "maxAge",
        type: {
          name: "Number"
        }
      },
      ageUnit: {
        serializedName: "ageUnit",
        type: {
          name: "Enum",
          allowedValues: [
            "Years",
            "Weeks"
          ]
        }
      },
      gender: {
        serializedName: "gender",
        type: {
          name: "Composite",
          className: "DictionaryValueIKPDto"
        }
      },
      targetPopulations: {
        serializedName: "targetPopulations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueIKPDto"
            }
          }
        }
      },
      interventionLocations: {
        serializedName: "interventionLocations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueIKPDto"
            }
          }
        }
      },
      healthConditions: {
        serializedName: "healthConditions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueIKPDto"
            }
          }
        }
      },
      socialExclusions: {
        serializedName: "socialExclusions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueIKPDto"
            }
          }
        }
      },
      otherSpecialFeatures: {
        serializedName: "otherSpecialFeatures",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueIKPDto"
            }
          }
        }
      },
      numberOfRecipients: {
        serializedName: "numberOfRecipients",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ActivityIKPDto: msRest.CompositeMapper = {
  serializedName: "ActivityIKPDto",
  type: {
    name: "Composite",
    className: "ActivityIKPDto",
    modelProperties: {
      number: {
        serializedName: "number",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Composite",
          className: "DictionaryValueIKPDto"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      healthProblems: {
        serializedName: "healthProblems",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueIKPDto"
            }
          }
        }
      },
      populations: {
        serializedName: "populations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PopulationIKPDto"
            }
          }
        }
      },
      implementingSubjects: {
        serializedName: "implementingSubjects",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectIKPDto"
            }
          }
        }
      },
      recruitmentDateFrom: {
        serializedName: "recruitmentDateFrom",
        type: {
          name: "DateTime"
        }
      },
      recruitmentDateTo: {
        serializedName: "recruitmentDateTo",
        type: {
          name: "DateTime"
        }
      },
      inclusionCriteria: {
        serializedName: "inclusionCriteria",
        type: {
          name: "String"
        }
      },
      exclusionCriteria: {
        serializedName: "exclusionCriteria",
        type: {
          name: "String"
        }
      },
      benefits: {
        serializedName: "benefits",
        type: {
          name: "String"
        }
      },
      programKnowledgeSource: {
        serializedName: "programKnowledgeSource",
        type: {
          name: "String"
        }
      },
      recruitmentStatus: {
        serializedName: "recruitmentStatus",
        type: {
          name: "Composite",
          className: "DictionaryValueIKPDto"
        }
      }
    }
  }
};

export const ContactIKPDto: msRest.CompositeMapper = {
  serializedName: "ContactIKPDto",
  type: {
    name: "Composite",
    className: "ContactIKPDto",
    modelProperties: {
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PublicHealthInterventionIKPDto: msRest.CompositeMapper = {
  serializedName: "PublicHealthInterventionIKPDto",
  type: {
    name: "Composite",
    className: "PublicHealthInterventionIKPDto",
    modelProperties: {
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      area: {
        serializedName: "area",
        type: {
          name: "Composite",
          className: "DictionaryValueIKPDto"
        }
      },
      owner: {
        serializedName: "owner",
        type: {
          name: "Composite",
          className: "SubjectIKPDto"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      areaTercCodes: {
        serializedName: "areaTercCodes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      legalBases: {
        serializedName: "legalBases",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueIKPDto"
            }
          }
        }
      },
      activities: {
        serializedName: "activities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ActivityIKPDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactIKPDto"
            }
          }
        }
      }
    }
  }
};

export const PublicHealthInterventionIKPDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "PublicHealthInterventionIKPDtoPagedResult",
  type: {
    name: "Composite",
    className: "PublicHealthInterventionIKPDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PublicHealthInterventionIKPDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AnnualNumberOfRecipientsDto: msRest.CompositeMapper = {
  serializedName: "AnnualNumberOfRecipientsDto",
  type: {
    name: "Composite",
    className: "AnnualNumberOfRecipientsDto",
    modelProperties: {
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      numberOfRecipients: {
        serializedName: "numberOfRecipients",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InterventionCoownerDto: msRest.CompositeMapper = {
  serializedName: "InterventionCoownerDto",
  type: {
    name: "Composite",
    className: "InterventionCoownerDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InterventionPartnerDto: msRest.CompositeMapper = {
  serializedName: "InterventionPartnerDto",
  type: {
    name: "Composite",
    className: "InterventionPartnerDto",
    modelProperties: {
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      number: {
        nullable: true,
        serializedName: "number",
        type: {
          name: "Number"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      }
    }
  }
};

export const PublicHealthInterventionModificationDto: msRest.CompositeMapper = {
  serializedName: "PublicHealthInterventionModificationDto",
  type: {
    name: "Composite",
    className: "PublicHealthInterventionModificationDto",
    modelProperties: {
      userId: {
        serializedName: "userId",
        type: {
          name: "Uuid"
        }
      },
      userFullname: {
        serializedName: "userFullname",
        type: {
          name: "String"
        }
      },
      publicHealthInterventionId: {
        serializedName: "publicHealthInterventionId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const AreaValueDto: msRest.CompositeMapper = {
  serializedName: "AreaValueDto",
  type: {
    name: "Composite",
    className: "AreaValueDto",
    modelProperties: {
      terytCode: {
        serializedName: "terytCode",
        type: {
          name: "String"
        }
      },
      locality: {
        serializedName: "locality",
        type: {
          name: "String"
        }
      },
      county: {
        serializedName: "county",
        type: {
          name: "String"
        }
      },
      voivodeship: {
        serializedName: "voivodeship",
        type: {
          name: "String"
        }
      },
      community: {
        serializedName: "community",
        type: {
          name: "String"
        }
      },
      area: {
        serializedName: "area",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CoProducerRoleDto: msRest.CompositeMapper = {
  serializedName: "CoProducerRoleDto",
  type: {
    name: "Composite",
    className: "CoProducerRoleDto",
    modelProperties: {
      roleDictionaryValueId: {
        serializedName: "roleDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      roleDictionaryOtherValue: {
        serializedName: "roleDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      roleDictionaryValue: {
        serializedName: "roleDictionaryValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CoProducerValueDto: msRest.CompositeMapper = {
  serializedName: "CoProducerValueDto",
  type: {
    name: "Composite",
    className: "CoProducerValueDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CoProducerRoleDto"
            }
          }
        }
      }
    }
  }
};

export const InterventionDetailsDto: msRest.CompositeMapper = {
  serializedName: "InterventionDetailsDto",
  type: {
    name: "Composite",
    className: "InterventionDetailsDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      plannedDateFrom: {
        serializedName: "plannedDateFrom",
        type: {
          name: "DateTime"
        }
      },
      plannedDateTo: {
        serializedName: "plannedDateTo",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      contractorsQty: {
        nullable: true,
        serializedName: "contractorsQty",
        type: {
          name: "Number"
        }
      },
      modifiedAt: {
        serializedName: "modifiedAt",
        type: {
          name: "DateTime"
        }
      },
      aotmitNumber: {
        serializedName: "aotmitNumber",
        type: {
          name: "String"
        }
      },
      aotmitStatus: {
        serializedName: "aotmitStatus",
        type: {
          name: "String"
        }
      },
      linkToWizard: {
        serializedName: "linkToWizard",
        type: {
          name: "String"
        }
      },
      dateOfDecisionAOTMiT: {
        serializedName: "dateOfDecisionAOTMiT",
        type: {
          name: "DateTime"
        }
      },
      opinionAOTMiT: {
        serializedName: "opinionAOTMiT",
        type: {
          name: "Enum",
          allowedValues: [
            "POSITIVE",
            "NEGATIVE",
            "CONDITIONALLY_POSITIVE",
            "NOT_SUBJECT_TO_OPINION",
            "FINAL_REPORT"
          ]
        }
      },
      presidentAotmitNumber: {
        serializedName: "presidentAotmitNumber",
        type: {
          name: "String"
        }
      },
      interventionPath: {
        serializedName: "interventionPath",
        type: {
          name: "Enum",
          allowedValues: [
            "PROHEALTH_ACTIVITIES",
            "RESEARCH",
            "SUPERVISION_MONITORING",
            "SUPERVISION_SANITARY",
            "STAFF_EDUCATION",
            "STAFF_MONITORING",
            "LEGISLATION"
          ]
        }
      },
      interventionTypeDictionaryValueId: {
        nullable: true,
        serializedName: "interventionTypeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      interventionTypeDictionaryOtherValue: {
        serializedName: "interventionTypeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      interventionTypeDictionaryValue: {
        serializedName: "interventionTypeDictionaryValue",
        type: {
          name: "String"
        }
      },
      interventionTypeDictionaryCode: {
        serializedName: "interventionTypeDictionaryCode",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "PLANNED",
            "SUSPENDED",
            "COMPLETED_REALIZED",
            "COMPLETED_UNREALIZED",
            "ARCHIVED",
            "REALIZATION",
            "DELETED",
            "CANCELED",
            "SENDED_TO_OPINION_AOTMIT"
          ]
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      recommendationTypeDictionaryValueId: {
        nullable: true,
        serializedName: "recommendationTypeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      recommendationTypeDictionaryOtherValue: {
        serializedName: "recommendationTypeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      interventionAreaDictionaryValueId: {
        nullable: true,
        serializedName: "interventionAreaDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      interventionAreaDictionaryOtherValue: {
        serializedName: "interventionAreaDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      interventionAreaDictionaryValue: {
        serializedName: "interventionAreaDictionaryValue",
        type: {
          name: "String"
        }
      },
      implementationPeriodDictionaryValueId: {
        nullable: true,
        serializedName: "implementationPeriodDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      implementationPeriodDictionaryOtherValue: {
        serializedName: "implementationPeriodDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      implementationPeriodDictionaryValue: {
        serializedName: "implementationPeriodDictionaryValue",
        type: {
          name: "String"
        }
      },
      plannedBudget: {
        serializedName: "plannedBudget",
        type: {
          name: "Number"
        }
      },
      realizationBudget: {
        nullable: true,
        serializedName: "realizationBudget",
        type: {
          name: "Number"
        }
      },
      realizationDateFrom: {
        serializedName: "realizationDateFrom",
        type: {
          name: "DateTime"
        }
      },
      realizationDateTo: {
        serializedName: "realizationDateTo",
        type: {
          name: "DateTime"
        }
      },
      numberOfPersonAction: {
        serializedName: "numberOfPersonAction",
        type: {
          name: "Number"
        }
      },
      numberOfRecipients: {
        serializedName: "numberOfRecipients",
        type: {
          name: "Number"
        }
      },
      annualNumberOfRecipients: {
        serializedName: "annualNumberOfRecipients",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AnnualNumberOfRecipientsDto"
            }
          }
        }
      },
      patternId: {
        serializedName: "patternId",
        type: {
          name: "Uuid"
        }
      },
      interventionCoowners: {
        serializedName: "interventionCoowners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InterventionCoownerDto"
            }
          }
        }
      },
      interventionPartners: {
        serializedName: "interventionPartners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InterventionPartnerDto"
            }
          }
        }
      },
      modifications: {
        serializedName: "modifications",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PublicHealthInterventionModificationDto"
            }
          }
        }
      },
      legalBases: {
        serializedName: "legalBases",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectContactDto"
            }
          }
        }
      },
      areas: {
        serializedName: "areas",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AreaValueDto"
            }
          }
        }
      },
      dataSourceDictionaryValues: {
        serializedName: "dataSourceDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      interventionEffectDictionaryValues: {
        serializedName: "interventionEffectDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      interventionKindDictionaryValues: {
        serializedName: "interventionKindDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      ncnClassificationDictionaryValues: {
        serializedName: "ncnClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      gBaordClassificationDictionaryValues: {
        serializedName: "gBaordClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      disciplineAreaClassificationDictionaryValues: {
        serializedName: "disciplineAreaClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      educationImplementationModeDictionaryValueId: {
        nullable: true,
        serializedName: "educationImplementationModeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      educationImplementationModeDictionaryOtherValue: {
        serializedName: "educationImplementationModeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      educationImplementationModeDictionaryValue: {
        serializedName: "educationImplementationModeDictionaryValue",
        type: {
          name: "String"
        }
      },
      coProducers: {
        serializedName: "coProducers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CoProducerValueDto"
            }
          }
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const InterventionDetailsTinyDto: msRest.CompositeMapper = {
  serializedName: "InterventionDetailsTinyDto",
  type: {
    name: "Composite",
    className: "InterventionDetailsTinyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      sourceDocumentId: {
        serializedName: "sourceDocumentId",
        type: {
          name: "Uuid"
        }
      },
      interventionPath: {
        serializedName: "interventionPath",
        type: {
          name: "Enum",
          allowedValues: [
            "PROHEALTH_ACTIVITIES",
            "RESEARCH",
            "SUPERVISION_MONITORING",
            "SUPERVISION_SANITARY",
            "STAFF_EDUCATION",
            "STAFF_MONITORING",
            "LEGISLATION"
          ]
        }
      },
      formVersionId: {
        serializedName: "formVersionId",
        type: {
          name: "Uuid"
        }
      },
      documentId: {
        serializedName: "documentId",
        type: {
          name: "String"
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      opinionAOTMiT: {
        serializedName: "opinionAOTMiT",
        type: {
          name: "Enum",
          allowedValues: [
            "POSITIVE",
            "NEGATIVE",
            "CONDITIONALLY_POSITIVE",
            "NOT_SUBJECT_TO_OPINION",
            "FINAL_REPORT"
          ]
        }
      },
      interventionStatus: {
        serializedName: "interventionStatus",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "PLANNED",
            "SUSPENDED",
            "COMPLETED_REALIZED",
            "COMPLETED_UNREALIZED",
            "ARCHIVED",
            "REALIZATION",
            "DELETED",
            "CANCELED",
            "SENDED_TO_OPINION_AOTMIT"
          ]
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const InterventionListDto: msRest.CompositeMapper = {
  serializedName: "InterventionListDto",
  type: {
    name: "Composite",
    className: "InterventionListDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      plannedDateFrom: {
        serializedName: "plannedDateFrom",
        type: {
          name: "DateTime"
        }
      },
      plannedDateTo: {
        serializedName: "plannedDateTo",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      aotmitNumber: {
        serializedName: "aotmitNumber",
        type: {
          name: "String"
        }
      },
      interventionPath: {
        serializedName: "interventionPath",
        type: {
          name: "Enum",
          allowedValues: [
            "PROHEALTH_ACTIVITIES",
            "RESEARCH",
            "SUPERVISION_MONITORING",
            "SUPERVISION_SANITARY",
            "STAFF_EDUCATION",
            "STAFF_MONITORING",
            "LEGISLATION"
          ]
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "PLANNED",
            "SUSPENDED",
            "COMPLETED_REALIZED",
            "COMPLETED_UNREALIZED",
            "ARCHIVED",
            "REALIZATION",
            "DELETED",
            "CANCELED",
            "SENDED_TO_OPINION_AOTMIT"
          ]
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      coowners: {
        serializedName: "coowners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      patternStatus: {
        serializedName: "patternStatus",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "ACTIVE",
            "INACTIVE"
          ]
        }
      },
      aotmitOpinion: {
        serializedName: "aotmitOpinion",
        type: {
          name: "Enum",
          allowedValues: [
            "POSITIVE",
            "NEGATIVE",
            "CONDITIONALLY_POSITIVE",
            "NOT_SUBJECT_TO_OPINION",
            "FINAL_REPORT"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InterventionListDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "InterventionListDtoPagedResult",
  type: {
    name: "Composite",
    className: "InterventionListDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InterventionListDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CreateInterventionCommand: msRest.CompositeMapper = {
  serializedName: "CreateInterventionCommand",
  type: {
    name: "Composite",
    className: "CreateInterventionCommand",
    modelProperties: {
      interventionPath: {
        serializedName: "interventionPath",
        type: {
          name: "Enum",
          allowedValues: [
            "PROHEALTH_ACTIVITIES",
            "RESEARCH",
            "SUPERVISION_MONITORING",
            "SUPERVISION_SANITARY",
            "STAFF_EDUCATION",
            "STAFF_MONITORING",
            "LEGISLATION"
          ]
        }
      },
      interventionTypeDictionaryValueId: {
        nullable: true,
        serializedName: "interventionTypeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      interventionTypeDictionaryOtherValue: {
        serializedName: "interventionTypeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      patternId: {
        serializedName: "patternId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const CoProducerDto: msRest.CompositeMapper = {
  serializedName: "CoProducerDto",
  type: {
    name: "Composite",
    className: "CoProducerDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CoProducerRoleDto"
            }
          }
        }
      }
    }
  }
};

export const UpdateInterventionCommand: msRest.CompositeMapper = {
  serializedName: "UpdateInterventionCommand",
  type: {
    name: "Composite",
    className: "UpdateInterventionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      plannedDateFrom: {
        serializedName: "plannedDateFrom",
        type: {
          name: "DateTime"
        }
      },
      plannedDateTo: {
        serializedName: "plannedDateTo",
        type: {
          name: "DateTime"
        }
      },
      plannedBudget: {
        nullable: true,
        serializedName: "plannedBudget",
        type: {
          name: "Number"
        }
      },
      contractorsQty: {
        nullable: true,
        serializedName: "contractorsQty",
        type: {
          name: "Number"
        }
      },
      recommendationTypeDictionaryValueId: {
        nullable: true,
        serializedName: "recommendationTypeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      recommendationTypeDictionaryOtherValue: {
        serializedName: "recommendationTypeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      interventionAreaDictionaryValueId: {
        nullable: true,
        serializedName: "interventionAreaDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      interventionAreaDictionaryOtherValue: {
        serializedName: "interventionAreaDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      implementationPeriodDictionaryValueId: {
        nullable: true,
        serializedName: "implementationPeriodDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      implementationPeriodDictionaryOtherValue: {
        serializedName: "implementationPeriodDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      educationImplementationModeDictionaryValueId: {
        nullable: true,
        serializedName: "educationImplementationModeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      educationImplementationModeDictionaryOtherValue: {
        serializedName: "educationImplementationModeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      numberOfPersonAction: {
        nullable: true,
        serializedName: "numberOfPersonAction",
        type: {
          name: "Number"
        }
      },
      numberOfRecipients: {
        nullable: true,
        serializedName: "numberOfRecipients",
        type: {
          name: "Number"
        }
      },
      annualNumberOfRecipients: {
        serializedName: "annualNumberOfRecipients",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AnnualNumberOfRecipientsDto"
            }
          }
        }
      },
      legalBases: {
        serializedName: "legalBases",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectContactDto"
            }
          }
        }
      },
      areaTerytCodes: {
        serializedName: "areaTerytCodes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      interventionCoownerIds: {
        serializedName: "interventionCoownerIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      interventionCoProducers: {
        serializedName: "interventionCoProducers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CoProducerDto"
            }
          }
        }
      },
      interventionPartners: {
        serializedName: "interventionPartners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InterventionPartnerDto"
            }
          }
        }
      },
      dataSourceDictionaryValues: {
        serializedName: "dataSourceDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      interventionEffectDictionaryValues: {
        serializedName: "interventionEffectDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      interventionKindDictionaryValues: {
        serializedName: "interventionKindDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      ncnClassificationDictionaryValues: {
        serializedName: "ncnClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      gBaordClassificationDictionaryValues: {
        serializedName: "gBaordClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      disciplineAreaClassificationDictionaryValues: {
        serializedName: "disciplineAreaClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      dateOfDecisionAOTMiT: {
        serializedName: "dateOfDecisionAOTMiT",
        type: {
          name: "DateTime"
        }
      },
      opinionAOTMiT: {
        serializedName: "opinionAOTMiT",
        type: {
          name: "Enum",
          allowedValues: [
            "POSITIVE",
            "NEGATIVE",
            "CONDITIONALLY_POSITIVE",
            "NOT_SUBJECT_TO_OPINION",
            "FINAL_REPORT"
          ]
        }
      },
      presidentAotmitNumber: {
        serializedName: "presidentAotmitNumber",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateInterventionOnExistingCommand: msRest.CompositeMapper = {
  serializedName: "CreateInterventionOnExistingCommand",
  type: {
    name: "Composite",
    className: "CreateInterventionOnExistingCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      interventionTypeDictionaryValueId: {
        nullable: true,
        serializedName: "interventionTypeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      interventionTypeDictionaryOtherValue: {
        serializedName: "interventionTypeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      plannedDateFrom: {
        serializedName: "plannedDateFrom",
        type: {
          name: "DateTime"
        }
      },
      plannedDateTo: {
        serializedName: "plannedDateTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SuspendInterventionCommand: msRest.CompositeMapper = {
  serializedName: "SuspendInterventionCommand",
  type: {
    name: "Composite",
    className: "SuspendInterventionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const UnsuspendInterventionCommand: msRest.CompositeMapper = {
  serializedName: "UnsuspendInterventionCommand",
  type: {
    name: "Composite",
    className: "UnsuspendInterventionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ArchiveInterventionCommand: msRest.CompositeMapper = {
  serializedName: "ArchiveInterventionCommand",
  type: {
    name: "Composite",
    className: "ArchiveInterventionCommand",
    modelProperties: {
      ids: {
        serializedName: "ids",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const UnarchiveInterventionCommand: msRest.CompositeMapper = {
  serializedName: "UnarchiveInterventionCommand",
  type: {
    name: "Composite",
    className: "UnarchiveInterventionCommand",
    modelProperties: {
      ids: {
        serializedName: "ids",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const CancelInterventionCommand: msRest.CompositeMapper = {
  serializedName: "CancelInterventionCommand",
  type: {
    name: "Composite",
    className: "CancelInterventionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SendInterventionCommand: msRest.CompositeMapper = {
  serializedName: "SendInterventionCommand",
  type: {
    name: "Composite",
    className: "SendInterventionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const TooltipDto: msRest.CompositeMapper = {
  serializedName: "TooltipDto",
  type: {
    name: "Composite",
    className: "TooltipDto",
    modelProperties: {
      field: {
        serializedName: "field",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateTooltipCommand: msRest.CompositeMapper = {
  serializedName: "CreateTooltipCommand",
  type: {
    name: "Composite",
    className: "CreateTooltipCommand",
    modelProperties: {
      field: {
        serializedName: "field",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ActivitiesSummaryDto: msRest.CompositeMapper = {
  serializedName: "ActivitiesSummaryDto",
  type: {
    name: "Composite",
    className: "ActivitiesSummaryDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      plannedBudget: {
        serializedName: "plannedBudget",
        type: {
          name: "Number"
        }
      },
      realizationBudget: {
        nullable: true,
        serializedName: "realizationBudget",
        type: {
          name: "Number"
        }
      },
      realizationDateFrom: {
        serializedName: "realizationDateFrom",
        type: {
          name: "DateTime"
        }
      },
      realizationDateTo: {
        serializedName: "realizationDateTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const CreateInterventionCommentCommand: msRest.CompositeMapper = {
  serializedName: "CreateInterventionCommentCommand",
  type: {
    name: "Composite",
    className: "CreateInterventionCommentCommand",
    modelProperties: {
      interventionId: {
        serializedName: "interventionId",
        type: {
          name: "Uuid"
        }
      },
      activityId: {
        serializedName: "activityId",
        type: {
          name: "Uuid"
        }
      },
      activityPopulationId: {
        serializedName: "activityPopulationId",
        type: {
          name: "Uuid"
        }
      },
      activitySponsoringCompanyId: {
        serializedName: "activitySponsoringCompanyId",
        type: {
          name: "Uuid"
        }
      },
      fieldName: {
        serializedName: "fieldName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      commentType: {
        serializedName: "commentType",
        type: {
          name: "Enum",
          allowedValues: [
            "PUBLIC",
            "PRIVATE",
            "IMPORTANT"
          ]
        }
      },
      activityTabName: {
        serializedName: "activityTabName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateInterventionAOTMiTCommentCommand: msRest.CompositeMapper = {
  serializedName: "CreateInterventionAOTMiTCommentCommand",
  type: {
    name: "Composite",
    className: "CreateInterventionAOTMiTCommentCommand",
    modelProperties: {
      comments: {
        serializedName: "comments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateInterventionCommentCommand"
            }
          }
        }
      }
    }
  }
};

export const InterventionCommentDto: msRest.CompositeMapper = {
  serializedName: "InterventionCommentDto",
  type: {
    name: "Composite",
    className: "InterventionCommentDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      interventionId: {
        serializedName: "interventionId",
        type: {
          name: "Uuid"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      createdByUserId: {
        serializedName: "createdByUserId",
        type: {
          name: "Uuid"
        }
      },
      createdBySubjectId: {
        serializedName: "createdBySubjectId",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      fieldName: {
        serializedName: "fieldName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "ADDED",
            "READ",
            "PROCESSED",
            "DELETED"
          ]
        }
      },
      commentType: {
        serializedName: "commentType",
        type: {
          name: "Enum",
          allowedValues: [
            "PUBLIC",
            "PRIVATE",
            "IMPORTANT"
          ]
        }
      }
    }
  }
};

export const UpdateInterventionCommentCommand: msRest.CompositeMapper = {
  serializedName: "UpdateInterventionCommentCommand",
  type: {
    name: "Composite",
    className: "UpdateInterventionCommentCommand",
    modelProperties: {
      commentId: {
        serializedName: "commentId",
        type: {
          name: "Uuid"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeleteInterventionCommentCommand: msRest.CompositeMapper = {
  serializedName: "DeleteInterventionCommentCommand",
  type: {
    name: "Composite",
    className: "DeleteInterventionCommentCommand",
    modelProperties: {
      commentId: {
        serializedName: "commentId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const InterventionCommentListItemDto: msRest.CompositeMapper = {
  serializedName: "InterventionCommentListItemDto",
  type: {
    name: "Composite",
    className: "InterventionCommentListItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      createdBySubjectId: {
        serializedName: "createdBySubjectId",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      createdBySubject: {
        serializedName: "createdBySubject",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "ADDED",
            "READ",
            "PROCESSED",
            "DELETED"
          ]
        }
      },
      commentType: {
        serializedName: "commentType",
        type: {
          name: "Enum",
          allowedValues: [
            "PUBLIC",
            "PRIVATE",
            "IMPORTANT"
          ]
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      fieldName: {
        serializedName: "fieldName",
        type: {
          name: "String"
        }
      },
      createdByAotmit: {
        serializedName: "createdByAotmit",
        type: {
          name: "Boolean"
        }
      },
      interventionSubjectId: {
        serializedName: "interventionSubjectId",
        type: {
          name: "Uuid"
        }
      },
      interventionCoownersSubjectIds: {
        serializedName: "interventionCoownersSubjectIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      activityId: {
        serializedName: "activityId",
        type: {
          name: "Uuid"
        }
      },
      activityPopulationId: {
        serializedName: "activityPopulationId",
        type: {
          name: "Uuid"
        }
      },
      activitySponsoringCompanyId: {
        serializedName: "activitySponsoringCompanyId",
        type: {
          name: "Uuid"
        }
      },
      activityNumber: {
        serializedName: "activityNumber",
        type: {
          name: "String"
        }
      },
      activityTabName: {
        serializedName: "activityTabName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InterventionCommentListItemDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "InterventionCommentListItemDtoPagedResult",
  type: {
    name: "Composite",
    className: "InterventionCommentListItemDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InterventionCommentListItemDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SetInterventionCommentStatusCommand: msRest.CompositeMapper = {
  serializedName: "SetInterventionCommentStatusCommand",
  type: {
    name: "Composite",
    className: "SetInterventionCommentStatusCommand",
    modelProperties: {
      commentId: {
        serializedName: "commentId",
        type: {
          name: "Uuid"
        }
      },
      commentStatus: {
        serializedName: "commentStatus",
        type: {
          name: "Enum",
          allowedValues: [
            "ADDED",
            "READ",
            "PROCESSED",
            "DELETED"
          ]
        }
      },
      resolveAotmitComments: {
        serializedName: "resolveAotmitComments",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AddInterventionTagCommand: msRest.CompositeMapper = {
  serializedName: "AddInterventionTagCommand",
  type: {
    name: "Composite",
    className: "AddInterventionTagCommand",
    modelProperties: {
      interventionId: {
        serializedName: "interventionId",
        type: {
          name: "Uuid"
        }
      },
      tagName: {
        serializedName: "tagName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeleteInterventionTagCommand: msRest.CompositeMapper = {
  serializedName: "DeleteInterventionTagCommand",
  type: {
    name: "Composite",
    className: "DeleteInterventionTagCommand",
    modelProperties: {
      interventionId: {
        serializedName: "interventionId",
        type: {
          name: "Uuid"
        }
      },
      tagName: {
        serializedName: "tagName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RoleValueDto: msRest.CompositeMapper = {
  serializedName: "RoleValueDto",
  type: {
    name: "Composite",
    className: "RoleValueDto",
    modelProperties: {
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Uuid"
        }
      },
      roleName: {
        serializedName: "roleName",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Enum",
          allowedValues: [
            "LOCAL",
            "CENTRAL"
          ]
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const RolePermissionDto: msRest.CompositeMapper = {
  serializedName: "RolePermissionDto",
  type: {
    name: "Composite",
    className: "RolePermissionDto",
    modelProperties: {
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Uuid"
        }
      },
      roleName: {
        serializedName: "roleName",
        type: {
          name: "String"
        }
      },
      permission: {
        serializedName: "permission",
        type: {
          name: "Enum",
          allowedValues: [
            "Dictionaries_List_Preview",
            "Dictionaries_Details_Preview",
            "Dictionaries_Versions_Modifications",
            "Admin_Roles_Preview",
            "Admin_Roles_Modifications",
            "Admin_Roles_Assignment_Global",
            "Admin_Roles_Assignment_Local",
            "Admin_ApiKeys_Management",
            "RIZP_Preview_Mine",
            "RIZP_Preview_Subordinate",
            "RIZP_Preview_All",
            "RIZP_Modifications",
            "RIZP_Patterns_Modifications_Nationwide",
            "RIZP_Patterns_Modifications_Voivodeship",
            "RIZP_Archive",
            "Subjects_List_Preview",
            "Subjects_Details_Preview",
            "Subjects_Modifications_Mine",
            "Subjects_Modifications_With_Teryt_Mine",
            "Subjects_Modifications_All",
            "Subjects_Modifications_With_Teryt_All",
            "Subjects_Modifications_Statement_Obligation",
            "Subjects_Merge",
            "Subjects_Proposal_Possibility_Handling",
            "Statements_Archive",
            "Statements_Generation_MZ53",
            "Statements_Generation_MZ49",
            "Statements_Generation_NPZ",
            "Statement_Preview_Mine_MZ49",
            "Statement_Preview_Mine_MZ53",
            "Statement_Preview_Mine_NPZ",
            "Statement_Preview_Subordinate_MZ49",
            "Statement_Preview_Subordinate_MZ53",
            "Statement_Preview_Subordinate_NPZ",
            "Statement_Preview_All_MZ49",
            "Statement_Preview_All_MZ53",
            "Statement_Preview_All_NPZ",
            "Statement_Verification_MZ49",
            "Statement_Verification_MZ53",
            "Statement_Verification_NPZ",
            "Statement_Critical_Warning",
            "Proposals_Handling_All",
            "Proposals_Handling_Mine",
            "DocumentsRepository_List_Preview",
            "DocumentsRepository_Preview",
            "DocumentsRepository_VoivodeOpinions_Preview",
            "DocumentsRepository_AllVoivodeOpinions_Preview",
            "DocumentsRepository_VoivodeOpinions_Modifications",
            "Reports_MZ_Preview",
            "Reports_Programy_Zdrowotne_Preview",
            "Reports_AOTMiT_Preview",
            "Reports_RP_Preview",
            "Reports_SZ_Chorobowosc_Preview",
            "Reports_SZ_Umieralnosc_Preview",
            "Reports_SZ_Deprywacja_Preview",
            "Reports_SZ_Statusy_Preview",
            "Reports_SZ_Demografia_Preview",
            "Reports_SZ_Preview",
            "Reports_All_Preview",
            "Reports_Technical",
            "Aotmit_Add_Recommendations",
            "Aotmit_Add_Comments",
            "Aotmit_Add_DetailedReportAnnex",
            "Aotmit_Projects",
            "Forms_Management",
            "Notifications_Management",
            "UserManual_Management",
            "Tooltip_Management",
            "Tags_Management_Global",
            "Tags_Management_Local",
            "Aggregate_Data",
            "IKP"
          ]
        }
      },
      permissionName: {
        serializedName: "permissionName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const KindSubjectDto: msRest.CompositeMapper = {
  serializedName: "KindSubjectDto",
  type: {
    name: "Composite",
    className: "KindSubjectDto",
    modelProperties: {
      apiKey: {
        serializedName: "apiKey",
        type: {
          name: "String"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleValueDto"
            }
          }
        }
      },
      rolePermissions: {
        serializedName: "rolePermissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RolePermissionDto"
            }
          }
        }
      }
    }
  }
};

export const AddKindSubjectRoleCommand: msRest.CompositeMapper = {
  serializedName: "AddKindSubjectRoleCommand",
  type: {
    name: "Composite",
    className: "AddKindSubjectRoleCommand",
    modelProperties: {
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Uuid"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const DeleteKindSubjectRoleCommand: msRest.CompositeMapper = {
  serializedName: "DeleteKindSubjectRoleCommand",
  type: {
    name: "Composite",
    className: "DeleteKindSubjectRoleCommand",
    modelProperties: {
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const UpdateNotificationDefinionCommand: msRest.CompositeMapper = {
  serializedName: "UpdateNotificationDefinionCommand",
  type: {
    name: "Composite",
    className: "UpdateNotificationDefinionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      systemChannelActive: {
        serializedName: "systemChannelActive",
        type: {
          name: "Boolean"
        }
      },
      emailChannelActive: {
        serializedName: "emailChannelActive",
        type: {
          name: "Boolean"
        }
      },
      activations: {
        serializedName: "activations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NotificationDefinitionActivationDto"
            }
          }
        }
      },
      sendToAll: {
        serializedName: "sendToAll",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ActivateNotificationDefinionCommand: msRest.CompositeMapper = {
  serializedName: "ActivateNotificationDefinionCommand",
  type: {
    name: "Composite",
    className: "ActivateNotificationDefinionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DeactivateNotificationDefinionCommand: msRest.CompositeMapper = {
  serializedName: "DeactivateNotificationDefinionCommand",
  type: {
    name: "Composite",
    className: "DeactivateNotificationDefinionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const UpdateNotificationDefinionChannelsCommand: msRest.CompositeMapper = {
  serializedName: "UpdateNotificationDefinionChannelsCommand",
  type: {
    name: "Composite",
    className: "UpdateNotificationDefinionChannelsCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      systemChannelActive: {
        serializedName: "systemChannelActive",
        type: {
          name: "Boolean"
        }
      },
      emailChannelActive: {
        serializedName: "emailChannelActive",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const NotificationOccurrenceDto: msRest.CompositeMapper = {
  serializedName: "NotificationOccurrenceDto",
  type: {
    name: "Composite",
    className: "NotificationOccurrenceDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      header: {
        serializedName: "header",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      creationDate: {
        serializedName: "creationDate",
        type: {
          name: "DateTime"
        }
      },
      occurrenceDomainType: {
        serializedName: "occurrenceDomainType",
        type: {
          name: "Enum",
          allowedValues: [
            "Intervention",
            "Statement",
            "User",
            "ReportingProcess",
            "Activity"
          ]
        }
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid"
        }
      },
      activations: {
        serializedName: "activations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NotificationDefinitionActivationDto"
            }
          }
        }
      }
    }
  }
};

export const CreateNotificationOccurrenceCommand: msRest.CompositeMapper = {
  serializedName: "CreateNotificationOccurrenceCommand",
  type: {
    name: "Composite",
    className: "CreateNotificationOccurrenceCommand",
    modelProperties: {
      userId: {
        serializedName: "userId",
        type: {
          name: "Uuid"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      activations: {
        serializedName: "activations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NotificationDefinitionActivationDto"
            }
          }
        }
      }
    }
  }
};

export const UpdateNotificationOccurrenceCommand: msRest.CompositeMapper = {
  serializedName: "UpdateNotificationOccurrenceCommand",
  type: {
    name: "Composite",
    className: "UpdateNotificationOccurrenceCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      activations: {
        serializedName: "activations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NotificationDefinitionActivationDto"
            }
          }
        }
      }
    }
  }
};

export const CreatePatternCommand: msRest.CompositeMapper = {
  serializedName: "CreatePatternCommand",
  type: {
    name: "Composite",
    className: "CreatePatternCommand",
    modelProperties: {
      interventionPath: {
        serializedName: "interventionPath",
        type: {
          name: "Enum",
          allowedValues: [
            "PROHEALTH_ACTIVITIES",
            "RESEARCH",
            "SUPERVISION_MONITORING",
            "SUPERVISION_SANITARY",
            "STAFF_EDUCATION",
            "STAFF_MONITORING",
            "LEGISLATION"
          ]
        }
      },
      interventionTypeDictionaryValueId: {
        nullable: true,
        serializedName: "interventionTypeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      interventionTypeDictionaryOtherValue: {
        serializedName: "interventionTypeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      patternId: {
        serializedName: "patternId",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      plannedDateFrom: {
        serializedName: "plannedDateFrom",
        type: {
          name: "DateTime"
        }
      },
      plannedDateTo: {
        serializedName: "plannedDateTo",
        type: {
          name: "DateTime"
        }
      },
      plannedBudget: {
        nullable: true,
        serializedName: "plannedBudget",
        type: {
          name: "Number"
        }
      },
      numberOfPersonAction: {
        nullable: true,
        serializedName: "numberOfPersonAction",
        type: {
          name: "Number"
        }
      },
      numberOfRecipients: {
        nullable: true,
        serializedName: "numberOfRecipients",
        type: {
          name: "Number"
        }
      },
      annualNumberOfRecipients: {
        serializedName: "annualNumberOfRecipients",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AnnualNumberOfRecipientsDto"
            }
          }
        }
      },
      recommendationTypeDictionaryValueId: {
        nullable: true,
        serializedName: "recommendationTypeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      recommendationTypeDictionaryOtherValue: {
        serializedName: "recommendationTypeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      interventionAreaDictionaryValueId: {
        nullable: true,
        serializedName: "interventionAreaDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      interventionAreaDictionaryOtherValue: {
        serializedName: "interventionAreaDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      implementationPeriodDictionaryValueId: {
        nullable: true,
        serializedName: "implementationPeriodDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      implementationPeriodDictionaryOtherValue: {
        serializedName: "implementationPeriodDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      educationImplementationModeDictionaryValueId: {
        nullable: true,
        serializedName: "educationImplementationModeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      educationImplementationModeDictionaryOtherValue: {
        serializedName: "educationImplementationModeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      legalBases: {
        serializedName: "legalBases",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectContactDto"
            }
          }
        }
      },
      areaTerytCodes: {
        serializedName: "areaTerytCodes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      interventionCoownerIds: {
        serializedName: "interventionCoownerIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      interventionPartners: {
        serializedName: "interventionPartners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InterventionPartnerDto"
            }
          }
        }
      },
      dataSourceDictionaryValues: {
        serializedName: "dataSourceDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      interventionEffectDictionaryValues: {
        serializedName: "interventionEffectDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      ncnClassificationDictionaryValues: {
        serializedName: "ncnClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      gBoardClassificationDictionaryValues: {
        serializedName: "gBoardClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      disciplineAreaClassificationDictionaryValues: {
        serializedName: "disciplineAreaClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      patternName: {
        serializedName: "patternName",
        type: {
          name: "String"
        }
      },
      vaildFrom: {
        serializedName: "vaildFrom",
        type: {
          name: "DateTime"
        }
      },
      vaildTo: {
        serializedName: "vaildTo",
        type: {
          name: "DateTime"
        }
      },
      patternType: {
        serializedName: "patternType",
        type: {
          name: "Enum",
          allowedValues: [
            "PROGRAM",
            "NOT_PROGRAM",
            "AOTMIT_RECOMENDATION"
          ]
        }
      },
      recommendationDocumentId: {
        serializedName: "recommendationDocumentId",
        type: {
          name: "Uuid"
        }
      },
      isCompliantWithRecommendation: {
        nullable: true,
        serializedName: "isCompliantWithRecommendation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UpdatePatternCommand: msRest.CompositeMapper = {
  serializedName: "UpdatePatternCommand",
  type: {
    name: "Composite",
    className: "UpdatePatternCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      plannedDateFrom: {
        serializedName: "plannedDateFrom",
        type: {
          name: "DateTime"
        }
      },
      plannedDateTo: {
        serializedName: "plannedDateTo",
        type: {
          name: "DateTime"
        }
      },
      plannedBudget: {
        nullable: true,
        serializedName: "plannedBudget",
        type: {
          name: "Number"
        }
      },
      contractorsQty: {
        nullable: true,
        serializedName: "contractorsQty",
        type: {
          name: "Number"
        }
      },
      recommendationTypeDictionaryValueId: {
        nullable: true,
        serializedName: "recommendationTypeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      recommendationTypeDictionaryOtherValue: {
        serializedName: "recommendationTypeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      interventionAreaDictionaryValueId: {
        nullable: true,
        serializedName: "interventionAreaDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      interventionAreaDictionaryOtherValue: {
        serializedName: "interventionAreaDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      implementationPeriodDictionaryValueId: {
        nullable: true,
        serializedName: "implementationPeriodDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      implementationPeriodDictionaryOtherValue: {
        serializedName: "implementationPeriodDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      educationImplementationModeDictionaryValueId: {
        nullable: true,
        serializedName: "educationImplementationModeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      educationImplementationModeDictionaryOtherValue: {
        serializedName: "educationImplementationModeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      numberOfPersonAction: {
        nullable: true,
        serializedName: "numberOfPersonAction",
        type: {
          name: "Number"
        }
      },
      numberOfRecipients: {
        nullable: true,
        serializedName: "numberOfRecipients",
        type: {
          name: "Number"
        }
      },
      annualNumberOfRecipients: {
        serializedName: "annualNumberOfRecipients",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AnnualNumberOfRecipientsDto"
            }
          }
        }
      },
      legalBases: {
        serializedName: "legalBases",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectContactDto"
            }
          }
        }
      },
      areaTerytCodes: {
        serializedName: "areaTerytCodes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      interventionCoownerIds: {
        serializedName: "interventionCoownerIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      interventionCoProducers: {
        serializedName: "interventionCoProducers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CoProducerDto"
            }
          }
        }
      },
      interventionPartners: {
        serializedName: "interventionPartners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InterventionPartnerDto"
            }
          }
        }
      },
      dataSourceDictionaryValues: {
        serializedName: "dataSourceDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      interventionEffectDictionaryValues: {
        serializedName: "interventionEffectDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      interventionKindDictionaryValues: {
        serializedName: "interventionKindDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      ncnClassificationDictionaryValues: {
        serializedName: "ncnClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      gBaordClassificationDictionaryValues: {
        serializedName: "gBaordClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      disciplineAreaClassificationDictionaryValues: {
        serializedName: "disciplineAreaClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryDto"
            }
          }
        }
      },
      dateOfDecisionAOTMiT: {
        serializedName: "dateOfDecisionAOTMiT",
        type: {
          name: "DateTime"
        }
      },
      opinionAOTMiT: {
        serializedName: "opinionAOTMiT",
        type: {
          name: "Enum",
          allowedValues: [
            "POSITIVE",
            "NEGATIVE",
            "CONDITIONALLY_POSITIVE",
            "NOT_SUBJECT_TO_OPINION",
            "FINAL_REPORT"
          ]
        }
      },
      presidentAotmitNumber: {
        serializedName: "presidentAotmitNumber",
        type: {
          name: "String"
        }
      },
      patternName: {
        serializedName: "patternName",
        type: {
          name: "String"
        }
      },
      vaildFrom: {
        serializedName: "vaildFrom",
        type: {
          name: "DateTime"
        }
      },
      vaildTo: {
        serializedName: "vaildTo",
        type: {
          name: "DateTime"
        }
      },
      patternType: {
        serializedName: "patternType",
        type: {
          name: "Enum",
          allowedValues: [
            "PROGRAM",
            "NOT_PROGRAM",
            "AOTMIT_RECOMENDATION"
          ]
        }
      },
      interventionTypeDictionaryValueId: {
        nullable: true,
        serializedName: "interventionTypeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      interventionTypeDictionaryOtherValue: {
        serializedName: "interventionTypeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      recommendationDocumentId: {
        serializedName: "recommendationDocumentId",
        type: {
          name: "Uuid"
        }
      },
      isCompliantWithRecommendation: {
        nullable: true,
        serializedName: "isCompliantWithRecommendation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PatternDto: msRest.CompositeMapper = {
  serializedName: "PatternDto",
  type: {
    name: "Composite",
    className: "PatternDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      plannedDateFrom: {
        serializedName: "plannedDateFrom",
        type: {
          name: "DateTime"
        }
      },
      plannedDateTo: {
        serializedName: "plannedDateTo",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      contractorsQty: {
        nullable: true,
        serializedName: "contractorsQty",
        type: {
          name: "Number"
        }
      },
      modifiedAt: {
        serializedName: "modifiedAt",
        type: {
          name: "DateTime"
        }
      },
      aotmitNumber: {
        serializedName: "aotmitNumber",
        type: {
          name: "String"
        }
      },
      aotmitStatus: {
        serializedName: "aotmitStatus",
        type: {
          name: "String"
        }
      },
      linkToWizard: {
        serializedName: "linkToWizard",
        type: {
          name: "String"
        }
      },
      dateOfDecisionAOTMiT: {
        serializedName: "dateOfDecisionAOTMiT",
        type: {
          name: "DateTime"
        }
      },
      opinionAOTMiT: {
        serializedName: "opinionAOTMiT",
        type: {
          name: "Enum",
          allowedValues: [
            "POSITIVE",
            "NEGATIVE",
            "CONDITIONALLY_POSITIVE",
            "NOT_SUBJECT_TO_OPINION",
            "FINAL_REPORT"
          ]
        }
      },
      presidentAotmitNumber: {
        serializedName: "presidentAotmitNumber",
        type: {
          name: "String"
        }
      },
      interventionPath: {
        serializedName: "interventionPath",
        type: {
          name: "Enum",
          allowedValues: [
            "PROHEALTH_ACTIVITIES",
            "RESEARCH",
            "SUPERVISION_MONITORING",
            "SUPERVISION_SANITARY",
            "STAFF_EDUCATION",
            "STAFF_MONITORING",
            "LEGISLATION"
          ]
        }
      },
      interventionTypeDictionaryValueId: {
        nullable: true,
        serializedName: "interventionTypeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      interventionTypeDictionaryOtherValue: {
        serializedName: "interventionTypeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      interventionTypeDictionaryValue: {
        serializedName: "interventionTypeDictionaryValue",
        type: {
          name: "String"
        }
      },
      interventionTypeDictionaryCode: {
        serializedName: "interventionTypeDictionaryCode",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "PLANNED",
            "SUSPENDED",
            "COMPLETED_REALIZED",
            "COMPLETED_UNREALIZED",
            "ARCHIVED",
            "REALIZATION",
            "DELETED",
            "CANCELED",
            "SENDED_TO_OPINION_AOTMIT"
          ]
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      recommendationTypeDictionaryValueId: {
        nullable: true,
        serializedName: "recommendationTypeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      recommendationTypeDictionaryOtherValue: {
        serializedName: "recommendationTypeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      interventionAreaDictionaryValueId: {
        nullable: true,
        serializedName: "interventionAreaDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      interventionAreaDictionaryOtherValue: {
        serializedName: "interventionAreaDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      interventionAreaDictionaryValue: {
        serializedName: "interventionAreaDictionaryValue",
        type: {
          name: "String"
        }
      },
      implementationPeriodDictionaryValueId: {
        nullable: true,
        serializedName: "implementationPeriodDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      implementationPeriodDictionaryOtherValue: {
        serializedName: "implementationPeriodDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      implementationPeriodDictionaryValue: {
        serializedName: "implementationPeriodDictionaryValue",
        type: {
          name: "String"
        }
      },
      plannedBudget: {
        serializedName: "plannedBudget",
        type: {
          name: "Number"
        }
      },
      realizationBudget: {
        nullable: true,
        serializedName: "realizationBudget",
        type: {
          name: "Number"
        }
      },
      realizationDateFrom: {
        serializedName: "realizationDateFrom",
        type: {
          name: "DateTime"
        }
      },
      realizationDateTo: {
        serializedName: "realizationDateTo",
        type: {
          name: "DateTime"
        }
      },
      numberOfPersonAction: {
        serializedName: "numberOfPersonAction",
        type: {
          name: "Number"
        }
      },
      numberOfRecipients: {
        serializedName: "numberOfRecipients",
        type: {
          name: "Number"
        }
      },
      annualNumberOfRecipients: {
        serializedName: "annualNumberOfRecipients",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AnnualNumberOfRecipientsDto"
            }
          }
        }
      },
      patternId: {
        serializedName: "patternId",
        type: {
          name: "Uuid"
        }
      },
      interventionCoowners: {
        serializedName: "interventionCoowners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InterventionCoownerDto"
            }
          }
        }
      },
      interventionPartners: {
        serializedName: "interventionPartners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InterventionPartnerDto"
            }
          }
        }
      },
      modifications: {
        serializedName: "modifications",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PublicHealthInterventionModificationDto"
            }
          }
        }
      },
      legalBases: {
        serializedName: "legalBases",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectContactDto"
            }
          }
        }
      },
      areas: {
        serializedName: "areas",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AreaValueDto"
            }
          }
        }
      },
      dataSourceDictionaryValues: {
        serializedName: "dataSourceDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      interventionEffectDictionaryValues: {
        serializedName: "interventionEffectDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      interventionKindDictionaryValues: {
        serializedName: "interventionKindDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      ncnClassificationDictionaryValues: {
        serializedName: "ncnClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      gBaordClassificationDictionaryValues: {
        serializedName: "gBaordClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      disciplineAreaClassificationDictionaryValues: {
        serializedName: "disciplineAreaClassificationDictionaryValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RizpDictionaryValueDto"
            }
          }
        }
      },
      educationImplementationModeDictionaryValueId: {
        nullable: true,
        serializedName: "educationImplementationModeDictionaryValueId",
        type: {
          name: "Number"
        }
      },
      educationImplementationModeDictionaryOtherValue: {
        serializedName: "educationImplementationModeDictionaryOtherValue",
        type: {
          name: "String"
        }
      },
      educationImplementationModeDictionaryValue: {
        serializedName: "educationImplementationModeDictionaryValue",
        type: {
          name: "String"
        }
      },
      coProducers: {
        serializedName: "coProducers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CoProducerValueDto"
            }
          }
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      patternName: {
        serializedName: "patternName",
        type: {
          name: "String"
        }
      },
      vaildFrom: {
        serializedName: "vaildFrom",
        type: {
          name: "DateTime"
        }
      },
      vaildTo: {
        serializedName: "vaildTo",
        type: {
          name: "DateTime"
        }
      },
      patternStatus: {
        serializedName: "patternStatus",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "ACTIVE",
            "INACTIVE"
          ]
        }
      },
      patternType: {
        serializedName: "patternType",
        type: {
          name: "Enum",
          allowedValues: [
            "PROGRAM",
            "NOT_PROGRAM",
            "AOTMIT_RECOMENDATION"
          ]
        }
      },
      recommendationDocumentId: {
        serializedName: "recommendationDocumentId",
        type: {
          name: "Uuid"
        }
      },
      recommendationDocumentName: {
        serializedName: "recommendationDocumentName",
        type: {
          name: "String"
        }
      },
      isCompliantWithRecommendation: {
        nullable: true,
        serializedName: "isCompliantWithRecommendation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ActivatePatternCommand: msRest.CompositeMapper = {
  serializedName: "ActivatePatternCommand",
  type: {
    name: "Composite",
    className: "ActivatePatternCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DeactivatePatternCommand: msRest.CompositeMapper = {
  serializedName: "DeactivatePatternCommand",
  type: {
    name: "Composite",
    className: "DeactivatePatternCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const CreatePatternOnExistingCommand: msRest.CompositeMapper = {
  serializedName: "CreatePatternOnExistingCommand",
  type: {
    name: "Composite",
    className: "CreatePatternOnExistingCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ProposalItemDto: msRest.CompositeMapper = {
  serializedName: "ProposalItemDto",
  type: {
    name: "Composite",
    className: "ProposalItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      proposalAccesType: {
        serializedName: "proposalAccesType",
        type: {
          name: "Enum",
          allowedValues: [
            "Administration",
            "Normal"
          ]
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      subjectCity: {
        serializedName: "subjectCity",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "Accepted",
            "Sended",
            "Rejected",
            "Canceled"
          ]
        }
      },
      insertDate: {
        serializedName: "insertDate",
        type: {
          name: "DateTime"
        }
      },
      isAttachment: {
        serializedName: "isAttachment",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ProposalItemDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "ProposalItemDtoPagedResult",
  type: {
    name: "Composite",
    className: "ProposalItemDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProposalItemDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ConsiderProposalCommand: msRest.CompositeMapper = {
  serializedName: "ConsiderProposalCommand",
  type: {
    name: "Composite",
    className: "ConsiderProposalCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "Accepted",
            "Sended",
            "Rejected",
            "Canceled"
          ]
        }
      },
      rejectReason: {
        serializedName: "rejectReason",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AddressDto: msRest.CompositeMapper = {
  serializedName: "AddressDto",
  type: {
    name: "Composite",
    className: "AddressDto",
    modelProperties: {
      zipCode: {
        serializedName: "zipCode",
        type: {
          name: "String"
        }
      },
      isAddressManual: {
        serializedName: "isAddressManual",
        type: {
          name: "Boolean"
        }
      },
      simcCode: {
        serializedName: "simcCode",
        type: {
          name: "String"
        }
      },
      tercCode: {
        serializedName: "tercCode",
        type: {
          name: "String"
        }
      },
      streetCode: {
        serializedName: "streetCode",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      propertyNumber: {
        serializedName: "propertyNumber",
        type: {
          name: "String"
        }
      },
      placeNumber: {
        serializedName: "placeNumber",
        type: {
          name: "String"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      voivodeship: {
        serializedName: "voivodeship",
        type: {
          name: "String"
        }
      },
      county: {
        serializedName: "county",
        type: {
          name: "String"
        }
      },
      community: {
        serializedName: "community",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SubjectBaseDto: msRest.CompositeMapper = {
  serializedName: "SubjectBaseDto",
  type: {
    name: "Composite",
    className: "SubjectBaseDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressDto"
        }
      }
    }
  }
};

export const ProposalDetailsDto: msRest.CompositeMapper = {
  serializedName: "ProposalDetailsDto",
  type: {
    name: "Composite",
    className: "ProposalDetailsDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      proposalAccesType: {
        serializedName: "proposalAccesType",
        type: {
          name: "Enum",
          allowedValues: [
            "Administration",
            "Normal"
          ]
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "Composite",
          className: "SubjectBaseDto"
        }
      },
      userId: {
        serializedName: "userId",
        type: {
          name: "Uuid"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "Accepted",
            "Sended",
            "Rejected",
            "Canceled"
          ]
        }
      },
      rejectReason: {
        serializedName: "rejectReason",
        type: {
          name: "String"
        }
      },
      insertDate: {
        serializedName: "insertDate",
        type: {
          name: "DateTime"
        }
      },
      lastModificationDate: {
        serializedName: "lastModificationDate",
        type: {
          name: "DateTime"
        }
      },
      fileId: {
        serializedName: "fileId",
        type: {
          name: "Uuid"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateRoleCommand: msRest.CompositeMapper = {
  serializedName: "CreateRoleCommand",
  type: {
    name: "Composite",
    className: "CreateRoleCommand",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "USER",
            "ADMIN"
          ]
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Enum",
          allowedValues: [
            "LOCAL",
            "CENTRAL"
          ]
        }
      }
    }
  }
};

export const UpdateRoleCommand: msRest.CompositeMapper = {
  serializedName: "UpdateRoleCommand",
  type: {
    name: "Composite",
    className: "UpdateRoleCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "USER",
            "ADMIN"
          ]
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Enum",
          allowedValues: [
            "LOCAL",
            "CENTRAL"
          ]
        }
      },
      permissions: {
        serializedName: "permissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "Dictionaries_List_Preview",
                "Dictionaries_Details_Preview",
                "Dictionaries_Versions_Modifications",
                "Admin_Roles_Preview",
                "Admin_Roles_Modifications",
                "Admin_Roles_Assignment_Global",
                "Admin_Roles_Assignment_Local",
                "Admin_ApiKeys_Management",
                "RIZP_Preview_Mine",
                "RIZP_Preview_Subordinate",
                "RIZP_Preview_All",
                "RIZP_Modifications",
                "RIZP_Patterns_Modifications_Nationwide",
                "RIZP_Patterns_Modifications_Voivodeship",
                "RIZP_Archive",
                "Subjects_List_Preview",
                "Subjects_Details_Preview",
                "Subjects_Modifications_Mine",
                "Subjects_Modifications_With_Teryt_Mine",
                "Subjects_Modifications_All",
                "Subjects_Modifications_With_Teryt_All",
                "Subjects_Modifications_Statement_Obligation",
                "Subjects_Merge",
                "Subjects_Proposal_Possibility_Handling",
                "Statements_Archive",
                "Statements_Generation_MZ53",
                "Statements_Generation_MZ49",
                "Statements_Generation_NPZ",
                "Statement_Preview_Mine_MZ49",
                "Statement_Preview_Mine_MZ53",
                "Statement_Preview_Mine_NPZ",
                "Statement_Preview_Subordinate_MZ49",
                "Statement_Preview_Subordinate_MZ53",
                "Statement_Preview_Subordinate_NPZ",
                "Statement_Preview_All_MZ49",
                "Statement_Preview_All_MZ53",
                "Statement_Preview_All_NPZ",
                "Statement_Verification_MZ49",
                "Statement_Verification_MZ53",
                "Statement_Verification_NPZ",
                "Statement_Critical_Warning",
                "Proposals_Handling_All",
                "Proposals_Handling_Mine",
                "DocumentsRepository_List_Preview",
                "DocumentsRepository_Preview",
                "DocumentsRepository_VoivodeOpinions_Preview",
                "DocumentsRepository_AllVoivodeOpinions_Preview",
                "DocumentsRepository_VoivodeOpinions_Modifications",
                "Reports_MZ_Preview",
                "Reports_Programy_Zdrowotne_Preview",
                "Reports_AOTMiT_Preview",
                "Reports_RP_Preview",
                "Reports_SZ_Chorobowosc_Preview",
                "Reports_SZ_Umieralnosc_Preview",
                "Reports_SZ_Deprywacja_Preview",
                "Reports_SZ_Statusy_Preview",
                "Reports_SZ_Demografia_Preview",
                "Reports_SZ_Preview",
                "Reports_All_Preview",
                "Reports_Technical",
                "Aotmit_Add_Recommendations",
                "Aotmit_Add_Comments",
                "Aotmit_Add_DetailedReportAnnex",
                "Aotmit_Projects",
                "Forms_Management",
                "Notifications_Management",
                "UserManual_Management",
                "Tooltip_Management",
                "Tags_Management_Global",
                "Tags_Management_Local",
                "Aggregate_Data",
                "IKP"
              ]
            }
          }
        }
      }
    }
  }
};

export const RoleDto: msRest.CompositeMapper = {
  serializedName: "RoleDto",
  type: {
    name: "Composite",
    className: "RoleDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "USER",
            "ADMIN"
          ]
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Enum",
          allowedValues: [
            "LOCAL",
            "CENTRAL"
          ]
        }
      },
      permissions: {
        serializedName: "permissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "Dictionaries_List_Preview",
                "Dictionaries_Details_Preview",
                "Dictionaries_Versions_Modifications",
                "Admin_Roles_Preview",
                "Admin_Roles_Modifications",
                "Admin_Roles_Assignment_Global",
                "Admin_Roles_Assignment_Local",
                "Admin_ApiKeys_Management",
                "RIZP_Preview_Mine",
                "RIZP_Preview_Subordinate",
                "RIZP_Preview_All",
                "RIZP_Modifications",
                "RIZP_Patterns_Modifications_Nationwide",
                "RIZP_Patterns_Modifications_Voivodeship",
                "RIZP_Archive",
                "Subjects_List_Preview",
                "Subjects_Details_Preview",
                "Subjects_Modifications_Mine",
                "Subjects_Modifications_With_Teryt_Mine",
                "Subjects_Modifications_All",
                "Subjects_Modifications_With_Teryt_All",
                "Subjects_Modifications_Statement_Obligation",
                "Subjects_Merge",
                "Subjects_Proposal_Possibility_Handling",
                "Statements_Archive",
                "Statements_Generation_MZ53",
                "Statements_Generation_MZ49",
                "Statements_Generation_NPZ",
                "Statement_Preview_Mine_MZ49",
                "Statement_Preview_Mine_MZ53",
                "Statement_Preview_Mine_NPZ",
                "Statement_Preview_Subordinate_MZ49",
                "Statement_Preview_Subordinate_MZ53",
                "Statement_Preview_Subordinate_NPZ",
                "Statement_Preview_All_MZ49",
                "Statement_Preview_All_MZ53",
                "Statement_Preview_All_NPZ",
                "Statement_Verification_MZ49",
                "Statement_Verification_MZ53",
                "Statement_Verification_NPZ",
                "Statement_Critical_Warning",
                "Proposals_Handling_All",
                "Proposals_Handling_Mine",
                "DocumentsRepository_List_Preview",
                "DocumentsRepository_Preview",
                "DocumentsRepository_VoivodeOpinions_Preview",
                "DocumentsRepository_AllVoivodeOpinions_Preview",
                "DocumentsRepository_VoivodeOpinions_Modifications",
                "Reports_MZ_Preview",
                "Reports_Programy_Zdrowotne_Preview",
                "Reports_AOTMiT_Preview",
                "Reports_RP_Preview",
                "Reports_SZ_Chorobowosc_Preview",
                "Reports_SZ_Umieralnosc_Preview",
                "Reports_SZ_Deprywacja_Preview",
                "Reports_SZ_Statusy_Preview",
                "Reports_SZ_Demografia_Preview",
                "Reports_SZ_Preview",
                "Reports_All_Preview",
                "Reports_Technical",
                "Aotmit_Add_Recommendations",
                "Aotmit_Add_Comments",
                "Aotmit_Add_DetailedReportAnnex",
                "Aotmit_Projects",
                "Forms_Management",
                "Notifications_Management",
                "UserManual_Management",
                "Tooltip_Management",
                "Tags_Management_Global",
                "Tags_Management_Local",
                "Aggregate_Data",
                "IKP"
              ]
            }
          }
        }
      }
    }
  }
};

export const ActivateRoleCommand: msRest.CompositeMapper = {
  serializedName: "ActivateRoleCommand",
  type: {
    name: "Composite",
    className: "ActivateRoleCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DeactivateRoleCommand: msRest.CompositeMapper = {
  serializedName: "DeactivateRoleCommand",
  type: {
    name: "Composite",
    className: "DeactivateRoleCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const StatementDetailsExternalDto: msRest.CompositeMapper = {
  serializedName: "StatementDetailsExternalDto",
  type: {
    name: "Composite",
    className: "StatementDetailsExternalDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "Object"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "Enum",
          allowedValues: [
            "JSON",
            "PDF"
          ]
        }
      },
      statementStatus: {
        serializedName: "statementStatus",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "ACCEPTED",
            "CLOSED",
            "RECLOSED",
            "IN_VERIFICATION",
            "DUPLICATE",
            "TO_COMPLETE",
            "TO_IMPROVE",
            "INCORRECT",
            "ARCHIVED",
            "REJECTED"
          ]
        }
      }
    }
  }
};

export const StatementCommentDto: msRest.CompositeMapper = {
  serializedName: "StatementCommentDto",
  type: {
    name: "Composite",
    className: "StatementCommentDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      statementId: {
        serializedName: "statementId",
        type: {
          name: "Uuid"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      createdByUserId: {
        serializedName: "createdByUserId",
        type: {
          name: "Uuid"
        }
      },
      createdBySubjectId: {
        serializedName: "createdBySubjectId",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      statementPartInterventionCode: {
        serializedName: "statementPartInterventionCode",
        type: {
          name: "String"
        }
      },
      column: {
        serializedName: "column",
        type: {
          name: "String"
        }
      },
      row: {
        serializedName: "row",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "ADDED",
            "READ",
            "PROCESSED",
            "DELETED"
          ]
        }
      },
      commentType: {
        serializedName: "commentType",
        type: {
          name: "Enum",
          allowedValues: [
            "IMPORTANT",
            "PUBLIC",
            "PRIVATE"
          ]
        }
      }
    }
  }
};

export const StatementCommentListItemDto: msRest.CompositeMapper = {
  serializedName: "StatementCommentListItemDto",
  type: {
    name: "Composite",
    className: "StatementCommentListItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      createdByUserId: {
        serializedName: "createdByUserId",
        type: {
          name: "Uuid"
        }
      },
      createdBySubjectId: {
        serializedName: "createdBySubjectId",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      createdBySubject: {
        serializedName: "createdBySubject",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "ADDED",
            "READ",
            "PROCESSED",
            "DELETED"
          ]
        }
      },
      commentType: {
        serializedName: "commentType",
        type: {
          name: "Enum",
          allowedValues: [
            "IMPORTANT",
            "PUBLIC",
            "PRIVATE"
          ]
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      statementPartInterventionCode: {
        serializedName: "statementPartInterventionCode",
        type: {
          name: "String"
        }
      },
      column: {
        serializedName: "column",
        type: {
          name: "String"
        }
      },
      row: {
        serializedName: "row",
        type: {
          name: "String"
        }
      },
      statementSubjectId: {
        serializedName: "statementSubjectId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const StatementCommentListItemDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "StatementCommentListItemDtoPagedResult",
  type: {
    name: "Composite",
    className: "StatementCommentListItemDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StatementCommentListItemDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CreateCommentCommand: msRest.CompositeMapper = {
  serializedName: "CreateCommentCommand",
  type: {
    name: "Composite",
    className: "CreateCommentCommand",
    modelProperties: {
      statementId: {
        serializedName: "statementId",
        type: {
          name: "Uuid"
        }
      },
      statementPartInterventionCode: {
        serializedName: "statementPartInterventionCode",
        type: {
          name: "String"
        }
      },
      column: {
        serializedName: "column",
        type: {
          name: "String"
        }
      },
      row: {
        serializedName: "row",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      commentType: {
        serializedName: "commentType",
        type: {
          name: "Enum",
          allowedValues: [
            "IMPORTANT",
            "PUBLIC",
            "PRIVATE"
          ]
        }
      }
    }
  }
};

export const UpdateCommentCommand: msRest.CompositeMapper = {
  serializedName: "UpdateCommentCommand",
  type: {
    name: "Composite",
    className: "UpdateCommentCommand",
    modelProperties: {
      commentId: {
        serializedName: "commentId",
        type: {
          name: "Uuid"
        }
      },
      statementPartInterventionCode: {
        serializedName: "statementPartInterventionCode",
        type: {
          name: "String"
        }
      },
      column: {
        serializedName: "column",
        type: {
          name: "String"
        }
      },
      row: {
        serializedName: "row",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeleteCommentCommand: msRest.CompositeMapper = {
  serializedName: "DeleteCommentCommand",
  type: {
    name: "Composite",
    className: "DeleteCommentCommand",
    modelProperties: {
      commentId: {
        serializedName: "commentId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SetCommentReadCommand: msRest.CompositeMapper = {
  serializedName: "SetCommentReadCommand",
  type: {
    name: "Composite",
    className: "SetCommentReadCommand",
    modelProperties: {
      commentId: {
        serializedName: "commentId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SetCommentProcessedCommand: msRest.CompositeMapper = {
  serializedName: "SetCommentProcessedCommand",
  type: {
    name: "Composite",
    className: "SetCommentProcessedCommand",
    modelProperties: {
      commentId: {
        serializedName: "commentId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const StatementSectionDto: msRest.CompositeMapper = {
  serializedName: "StatementSectionDto",
  type: {
    name: "Composite",
    className: "StatementSectionDto",
    modelProperties: {
      formPartId: {
        serializedName: "formPartId",
        type: {
          name: "Uuid"
        }
      },
      documentId: {
        serializedName: "documentId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StatementDetailsDto: msRest.CompositeMapper = {
  serializedName: "StatementDetailsDto",
  type: {
    name: "Composite",
    className: "StatementDetailsDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      statementArea: {
        serializedName: "statementArea",
        type: {
          name: "Enum",
          allowedValues: [
            "NPZ",
            "MZ_49",
            "MZ_53"
          ]
        }
      },
      statementStatus: {
        serializedName: "statementStatus",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "ACCEPTED",
            "CLOSED",
            "RECLOSED",
            "IN_VERIFICATION",
            "DUPLICATE",
            "TO_COMPLETE",
            "TO_IMPROVE",
            "INCORRECT",
            "ARCHIVED",
            "REJECTED"
          ]
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      parentSubjectId: {
        serializedName: "parentSubjectId",
        type: {
          name: "Uuid"
        }
      },
      formVersionId: {
        serializedName: "formVersionId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      subjectRegon: {
        serializedName: "subjectRegon",
        type: {
          name: "String"
        }
      },
      hasPreview: {
        serializedName: "hasPreview",
        type: {
          name: "Boolean"
        }
      },
      statementRejectionComment: {
        serializedName: "statementRejectionComment",
        type: {
          name: "String"
        }
      },
      sourceDocumentId: {
        serializedName: "sourceDocumentId",
        type: {
          name: "Uuid"
        }
      },
      statementModificationAuthorFullName: {
        serializedName: "statementModificationAuthorFullName",
        type: {
          name: "String"
        }
      },
      rejectedBySubjectId: {
        serializedName: "rejectedBySubjectId",
        type: {
          name: "Uuid"
        }
      },
      sections: {
        serializedName: "sections",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StatementSectionDto"
            }
          }
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const StatementListItemDto: msRest.CompositeMapper = {
  serializedName: "StatementListItemDto",
  type: {
    name: "Composite",
    className: "StatementListItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      statementName: {
        serializedName: "statementName",
        type: {
          name: "String"
        }
      },
      statementArea: {
        serializedName: "statementArea",
        type: {
          name: "Enum",
          allowedValues: [
            "NPZ",
            "MZ_49",
            "MZ_53"
          ]
        }
      },
      statementStatus: {
        serializedName: "statementStatus",
        type: {
          name: "Enum",
          allowedValues: [
            "DRAFT",
            "ACCEPTED",
            "CLOSED",
            "RECLOSED",
            "IN_VERIFICATION",
            "DUPLICATE",
            "TO_COMPLETE",
            "TO_IMPROVE",
            "INCORRECT",
            "ARCHIVED",
            "REJECTED"
          ]
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      subjectRegon: {
        serializedName: "subjectRegon",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const StatementListItemDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "StatementListItemDtoPagedResult",
  type: {
    name: "Composite",
    className: "StatementListItemDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StatementListItemDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CreateStatementCommand: msRest.CompositeMapper = {
  serializedName: "CreateStatementCommand",
  type: {
    name: "Composite",
    className: "CreateStatementCommand",
    modelProperties: {
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      statementArea: {
        serializedName: "statementArea",
        type: {
          name: "Enum",
          allowedValues: [
            "NPZ",
            "MZ_49",
            "MZ_53"
          ]
        }
      }
    }
  }
};

export const ArchiveStatementCommand: msRest.CompositeMapper = {
  serializedName: "ArchiveStatementCommand",
  type: {
    name: "Composite",
    className: "ArchiveStatementCommand",
    modelProperties: {
      ids: {
        serializedName: "ids",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const UnarchiveStatementCommand: msRest.CompositeMapper = {
  serializedName: "UnarchiveStatementCommand",
  type: {
    name: "Composite",
    className: "UnarchiveStatementCommand",
    modelProperties: {
      ids: {
        serializedName: "ids",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const SetStatementMZCompletedCommand: msRest.CompositeMapper = {
  serializedName: "SetStatementMZCompletedCommand",
  type: {
    name: "Composite",
    className: "SetStatementMZCompletedCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SetStatementMZAcceptedCommand: msRest.CompositeMapper = {
  serializedName: "SetStatementMZAcceptedCommand",
  type: {
    name: "Composite",
    className: "SetStatementMZAcceptedCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SetStatementMZRejectedCommand: msRest.CompositeMapper = {
  serializedName: "SetStatementMZRejectedCommand",
  type: {
    name: "Composite",
    className: "SetStatementMZRejectedCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      statementRejectionComment: {
        serializedName: "statementRejectionComment",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SetStatementNPZCompletedCommand: msRest.CompositeMapper = {
  serializedName: "SetStatementNPZCompletedCommand",
  type: {
    name: "Composite",
    className: "SetStatementNPZCompletedCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      importProperty: {
        serializedName: "import",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SetStatementNPZAcceptedCommand: msRest.CompositeMapper = {
  serializedName: "SetStatementNPZAcceptedCommand",
  type: {
    name: "Composite",
    className: "SetStatementNPZAcceptedCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SetStatementNPZRejectedCommand: msRest.CompositeMapper = {
  serializedName: "SetStatementNPZRejectedCommand",
  type: {
    name: "Composite",
    className: "SetStatementNPZRejectedCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      statementRejectionComment: {
        serializedName: "statementRejectionComment",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FileImportStatusDto: msRest.CompositeMapper = {
  serializedName: "FileImportStatusDto",
  type: {
    name: "Composite",
    className: "FileImportStatusDto",
    modelProperties: {
      fileId: {
        serializedName: "fileId",
        type: {
          name: "Uuid"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ImportStatusCommand: msRest.CompositeMapper = {
  serializedName: "ImportStatusCommand",
  type: {
    name: "Composite",
    className: "ImportStatusCommand",
    modelProperties: {
      errors: {
        serializedName: "errors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FileImportStatusDto"
            }
          }
        }
      }
    }
  }
};

export const CopyStatementCommand: msRest.CompositeMapper = {
  serializedName: "CopyStatementCommand",
  type: {
    name: "Composite",
    className: "CopyStatementCommand",
    modelProperties: {
      statementId: {
        serializedName: "statementId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const CorrectStatementCommand: msRest.CompositeMapper = {
  serializedName: "CorrectStatementCommand",
  type: {
    name: "Composite",
    className: "CorrectStatementCommand",
    modelProperties: {
      statementId: {
        serializedName: "statementId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const StatementHeaderDto: msRest.CompositeMapper = {
  serializedName: "StatementHeaderDto",
  type: {
    name: "Composite",
    className: "StatementHeaderDto",
    modelProperties: {
      rEGON: {
        serializedName: "REGON",
        type: {
          name: "String"
        }
      },
      rokSpraw: {
        serializedName: "rok_spraw",
        type: {
          name: "Number"
        }
      },
      nazwaPodmSpraw: {
        serializedName: "nazwa_podmSpraw",
        type: {
          name: "String"
        }
      },
      adresPodmSpraw: {
        serializedName: "adres_podmSpraw",
        type: {
          name: "String"
        }
      },
      identyfikator: {
        serializedName: "identyfikator",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ProgramInterventionDto: msRest.CompositeMapper = {
  serializedName: "ProgramInterventionDto",
  type: {
    name: "Composite",
    className: "ProgramInterventionDto",
    modelProperties: {
      dz3PrNazwaInterw: {
        serializedName: "dz3_pr_nazwaInterw",
        type: {
          name: "String"
        }
      },
      dz3PrZasInterw: {
        serializedName: "dz3_pr_zasInterw",
        type: {
          name: "String"
        }
      },
      dz3PrLiczbaRealiz: {
        serializedName: "dz3_pr_liczbaRealiz",
        type: {
          name: "Number"
        }
      },
      dz3PrLiczbaOdbior: {
        serializedName: "dz3_pr_liczbaOdbior",
        type: {
          name: "Number"
        }
      },
      dz3PrNrIdInterw: {
        serializedName: "dz3_pr_nr_id_interw",
        type: {
          name: "Uuid"
        }
      },
      dz3PrNrIdWzorcaInterw: {
        serializedName: "dz3_pr_nr_id_wzorca_interw",
        type: {
          name: "Uuid"
        }
      },
      dz3PrRealizRokSz: {
        serializedName: "dz3_pr_realizRokSz",
        type: {
          name: "Boolean"
        }
      },
      dz3PrIdIterw: {
        serializedName: "dz3_pr_id_iterw",
        type: {
          name: "String"
        }
      },
      dz3PrIdWzorcaInterw: {
        serializedName: "dz3_pr_id_wzorca_interw",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const NonProgramInterventionDto: msRest.CompositeMapper = {
  serializedName: "NonProgramInterventionDto",
  type: {
    name: "Composite",
    className: "NonProgramInterventionDto",
    modelProperties: {
      dz3NieprNazwaInterw: {
        serializedName: "dz3_niepr_nazwaInterw",
        type: {
          name: "String"
        }
      },
      dz3NieprZasInterw: {
        serializedName: "dz3_niepr_zasInterw",
        type: {
          name: "String"
        }
      },
      dz3NieprLiczbaRealiz: {
        serializedName: "dz3_niepr_liczbaRealiz",
        type: {
          name: "Number"
        }
      },
      dz3NieprLiczbaOdbior: {
        serializedName: "dz3_niepr_liczbaOdbior",
        type: {
          name: "Number"
        }
      },
      dz3NieprNrIdInterw: {
        serializedName: "dz3_niepr_nr_id_interw",
        type: {
          name: "Uuid"
        }
      },
      dz3NieprNrIdWzorcaInterw: {
        serializedName: "dz3_niepr_nr_id_wzorca_interw",
        type: {
          name: "Uuid"
        }
      },
      dz3NieprRealizRokSz: {
        serializedName: "dz3_niepr_realizRokSz",
        type: {
          name: "Boolean"
        }
      },
      dz3NieprIdIterw: {
        serializedName: "dz3_niepr_id_iterw",
        type: {
          name: "String"
        }
      },
      dz3NieprIdWzorcaInterw: {
        serializedName: "dz3_niepr_id_wzorca_interw",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MZ49AutocompletionDto: msRest.CompositeMapper = {
  serializedName: "MZ49AutocompletionDto",
  type: {
    name: "Composite",
    className: "MZ49AutocompletionDto",
    modelProperties: {
      dz3PrInterwencje: {
        serializedName: "dz3_pr_interwencje",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProgramInterventionDto"
            }
          }
        }
      },
      dz3NieprInterwencje: {
        serializedName: "dz3_niepr_interwencje",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NonProgramInterventionDto"
            }
          }
        }
      },
      kategoriaPodmiotu: {
        serializedName: "kategoria_podmiotu",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AddStatementTagCommand: msRest.CompositeMapper = {
  serializedName: "AddStatementTagCommand",
  type: {
    name: "Composite",
    className: "AddStatementTagCommand",
    modelProperties: {
      statementId: {
        serializedName: "statementId",
        type: {
          name: "Uuid"
        }
      },
      tagName: {
        serializedName: "tagName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeleteStatementTagCommand: msRest.CompositeMapper = {
  serializedName: "DeleteStatementTagCommand",
  type: {
    name: "Composite",
    className: "DeleteStatementTagCommand",
    modelProperties: {
      statementId: {
        serializedName: "statementId",
        type: {
          name: "Uuid"
        }
      },
      tagName: {
        serializedName: "tagName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StatementTestDetailsDto: msRest.CompositeMapper = {
  serializedName: "StatementTestDetailsDto",
  type: {
    name: "Composite",
    className: "StatementTestDetailsDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      statementArea: {
        serializedName: "statementArea",
        type: {
          name: "Enum",
          allowedValues: [
            "NPZ",
            "MZ_49",
            "MZ_53"
          ]
        }
      },
      formVersionId: {
        serializedName: "formVersionId",
        type: {
          name: "Uuid"
        }
      },
      hasPreview: {
        serializedName: "hasPreview",
        type: {
          name: "Boolean"
        }
      },
      statementModificationAuthorFullName: {
        serializedName: "statementModificationAuthorFullName",
        type: {
          name: "String"
        }
      },
      sections: {
        serializedName: "sections",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StatementSectionDto"
            }
          }
        }
      }
    }
  }
};

export const StatementTestListItemDto: msRest.CompositeMapper = {
  serializedName: "StatementTestListItemDto",
  type: {
    name: "Composite",
    className: "StatementTestListItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      formVersion: {
        serializedName: "formVersion",
        type: {
          name: "String"
        }
      },
      statementArea: {
        serializedName: "statementArea",
        type: {
          name: "Enum",
          allowedValues: [
            "NPZ",
            "MZ_49",
            "MZ_53"
          ]
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const StatementTestListItemDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "StatementTestListItemDtoPagedResult",
  type: {
    name: "Composite",
    className: "StatementTestListItemDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StatementTestListItemDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CreateStatementTestCommand: msRest.CompositeMapper = {
  serializedName: "CreateStatementTestCommand",
  type: {
    name: "Composite",
    className: "CreateStatementTestCommand",
    modelProperties: {
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      statementArea: {
        serializedName: "statementArea",
        type: {
          name: "Enum",
          allowedValues: [
            "NPZ",
            "MZ_49",
            "MZ_53"
          ]
        }
      },
      formVersionId: {
        serializedName: "formVersionId",
        type: {
          name: "Uuid"
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SubjectListItemDto: msRest.CompositeMapper = {
  serializedName: "SubjectListItemDto",
  type: {
    name: "Composite",
    className: "SubjectListItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      cruipCode: {
        serializedName: "cruipCode",
        type: {
          name: "String"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      nip: {
        serializedName: "nip",
        type: {
          name: "String"
        }
      },
      regon: {
        serializedName: "regon",
        type: {
          name: "String"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "NEW",
            "MODIFIED",
            "DELETED",
            "MERGED"
          ]
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      isLaboratory: {
        serializedName: "isLaboratory",
        type: {
          name: "Boolean"
        }
      },
      fromCRUIP: {
        serializedName: "fromCRUIP",
        type: {
          name: "Boolean"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      krsCode: {
        serializedName: "krsCode",
        type: {
          name: "String"
        }
      },
      voivodeship: {
        serializedName: "voivodeship",
        type: {
          name: "String"
        }
      },
      county: {
        serializedName: "county",
        type: {
          name: "String"
        }
      },
      canEdit: {
        serializedName: "canEdit",
        type: {
          name: "Boolean"
        }
      },
      isActiveAdminAccount: {
        nullable: true,
        serializedName: "isActiveAdminAccount",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SubjectListItemDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "SubjectListItemDtoPagedResult",
  type: {
    name: "Composite",
    className: "SubjectListItemDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectListItemDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AddressValueDto: msRest.CompositeMapper = {
  serializedName: "AddressValueDto",
  type: {
    name: "Composite",
    className: "AddressValueDto",
    modelProperties: {
      zipCode: {
        serializedName: "zipCode",
        type: {
          name: "String"
        }
      },
      isAddressManual: {
        serializedName: "isAddressManual",
        type: {
          name: "Boolean"
        }
      },
      simcCode: {
        serializedName: "simcCode",
        type: {
          name: "String"
        }
      },
      tercCode: {
        serializedName: "tercCode",
        type: {
          name: "String"
        }
      },
      streetCode: {
        serializedName: "streetCode",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      propertyNumber: {
        serializedName: "propertyNumber",
        type: {
          name: "String"
        }
      },
      placeNumber: {
        serializedName: "placeNumber",
        type: {
          name: "String"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      voivodeship: {
        serializedName: "voivodeship",
        type: {
          name: "String"
        }
      },
      county: {
        serializedName: "county",
        type: {
          name: "String"
        }
      },
      community: {
        serializedName: "community",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ContactValueDto: msRest.CompositeMapper = {
  serializedName: "ContactValueDto",
  type: {
    name: "Composite",
    className: "ContactValueDto",
    modelProperties: {
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "String"
        }
      },
      fullName: {
        readOnly: true,
        serializedName: "fullName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateSubjectApiCommand: msRest.CompositeMapper = {
  serializedName: "CreateSubjectApiCommand",
  type: {
    name: "Composite",
    className: "CreateSubjectApiCommand",
    modelProperties: {
      krsCode: {
        serializedName: "krsCode",
        type: {
          name: "String"
        }
      },
      tercCode: {
        serializedName: "tercCode",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      nip: {
        serializedName: "nip",
        type: {
          name: "String"
        }
      },
      regon: {
        serializedName: "regon",
        type: {
          name: "String"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      parentSubjectId: {
        serializedName: "parentSubjectId",
        type: {
          name: "Uuid"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressValueDto"
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactValueDto"
            }
          }
        }
      },
      isProposalPossibility: {
        serializedName: "isProposalPossibility",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UpdateSubjectApiCommand: msRest.CompositeMapper = {
  serializedName: "UpdateSubjectApiCommand",
  type: {
    name: "Composite",
    className: "UpdateSubjectApiCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      krsCode: {
        serializedName: "krsCode",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tercCode: {
        serializedName: "tercCode",
        type: {
          name: "String"
        }
      },
      nip: {
        serializedName: "nip",
        type: {
          name: "String"
        }
      },
      regon: {
        serializedName: "regon",
        type: {
          name: "String"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "NEW",
            "MODIFIED",
            "DELETED",
            "MERGED"
          ]
        }
      },
      parentSubjectId: {
        serializedName: "parentSubjectId",
        type: {
          name: "Uuid"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressDto"
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactDto"
            }
          }
        }
      },
      isProposalPossibility: {
        serializedName: "isProposalPossibility",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const DeadlineProcessDto: msRest.CompositeMapper = {
  serializedName: "DeadlineProcessDto",
  type: {
    name: "Composite",
    className: "DeadlineProcessDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      process: {
        serializedName: "process",
        type: {
          name: "String"
        }
      },
      day: {
        serializedName: "day",
        type: {
          name: "Number"
        }
      },
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      cycleLength: {
        serializedName: "cycleLength",
        type: {
          name: "Number"
        }
      },
      daysToDeadline: {
        nullable: true,
        serializedName: "daysToDeadline",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SubjectDetailsDto: msRest.CompositeMapper = {
  serializedName: "SubjectDetailsDto",
  type: {
    name: "Composite",
    className: "SubjectDetailsDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      cruipCode: {
        serializedName: "cruipCode",
        type: {
          name: "String"
        }
      },
      nip: {
        serializedName: "nip",
        type: {
          name: "String"
        }
      },
      regon: {
        serializedName: "regon",
        type: {
          name: "String"
        }
      },
      krsCode: {
        serializedName: "krsCode",
        type: {
          name: "String"
        }
      },
      tercCode: {
        serializedName: "tercCode",
        type: {
          name: "String"
        }
      },
      parentCodeName: {
        readOnly: true,
        serializedName: "parentCodeName",
        type: {
          name: "String"
        }
      },
      parentSubjectName: {
        serializedName: "parentSubjectName",
        type: {
          name: "String"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressDto"
        }
      },
      deadlineProcess: {
        serializedName: "deadlineProcess",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DeadlineProcessDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactDto"
            }
          }
        }
      },
      primarySubjectId: {
        serializedName: "primarySubjectId",
        type: {
          name: "Uuid"
        }
      },
      parentSubjectId: {
        serializedName: "parentSubjectId",
        type: {
          name: "Uuid"
        }
      },
      parentSubject: {
        serializedName: "parentSubject",
        type: {
          name: "Composite",
          className: "SubjectDetailsDto"
        }
      },
      isLaboratory: {
        serializedName: "isLaboratory",
        type: {
          name: "Boolean"
        }
      },
      isProposalPossibility: {
        serializedName: "isProposalPossibility",
        type: {
          name: "Boolean"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "NEW",
            "MODIFIED",
            "DELETED",
            "MERGED"
          ]
        }
      },
      isActiveAdminAccount: {
        serializedName: "isActiveAdminAccount",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const MergeSubjectsApiCommand: msRest.CompositeMapper = {
  serializedName: "MergeSubjectsApiCommand",
  type: {
    name: "Composite",
    className: "MergeSubjectsApiCommand",
    modelProperties: {
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      mergingSubjectId: {
        serializedName: "mergingSubjectId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SubjectDetailsTinyDto: msRest.CompositeMapper = {
  serializedName: "SubjectDetailsTinyDto",
  type: {
    name: "Composite",
    className: "SubjectDetailsTinyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      sourceDocumentId: {
        serializedName: "sourceDocumentId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SubjectPermissionListItemDto: msRest.CompositeMapper = {
  serializedName: "SubjectPermissionListItemDto",
  type: {
    name: "Composite",
    className: "SubjectPermissionListItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      cruipCode: {
        serializedName: "cruipCode",
        type: {
          name: "String"
        }
      },
      codeName: {
        serializedName: "codeName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      nip: {
        serializedName: "nip",
        type: {
          name: "String"
        }
      },
      regon: {
        serializedName: "regon",
        type: {
          name: "String"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "NEW",
            "MODIFIED",
            "DELETED",
            "MERGED"
          ]
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      isLaboratory: {
        serializedName: "isLaboratory",
        type: {
          name: "Boolean"
        }
      },
      fromCRUIP: {
        serializedName: "fromCRUIP",
        type: {
          name: "Boolean"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      krsCode: {
        serializedName: "krsCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SubjectPermissionListItemDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "SubjectPermissionListItemDtoPagedResult",
  type: {
    name: "Composite",
    className: "SubjectPermissionListItemDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectPermissionListItemDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SubjectRolesDto: msRest.CompositeMapper = {
  serializedName: "SubjectRolesDto",
  type: {
    name: "Composite",
    className: "SubjectRolesDto",
    modelProperties: {
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      apiKey: {
        serializedName: "apiKey",
        type: {
          name: "String"
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleValueDto"
            }
          }
        }
      },
      rolePermissions: {
        serializedName: "rolePermissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RolePermissionDto"
            }
          }
        }
      }
    }
  }
};

export const AddSubjectRoleCommand: msRest.CompositeMapper = {
  serializedName: "AddSubjectRoleCommand",
  type: {
    name: "Composite",
    className: "AddSubjectRoleCommand",
    modelProperties: {
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Uuid"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const DeleteSubjectRoleCommand: msRest.CompositeMapper = {
  serializedName: "DeleteSubjectRoleCommand",
  type: {
    name: "Composite",
    className: "DeleteSubjectRoleCommand",
    modelProperties: {
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SubjectCommentDto: msRest.CompositeMapper = {
  serializedName: "SubjectCommentDto",
  type: {
    name: "Composite",
    className: "SubjectCommentDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      createdByUserId: {
        serializedName: "createdByUserId",
        type: {
          name: "Uuid"
        }
      },
      createdBySubjectId: {
        serializedName: "createdBySubjectId",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      fieldName: {
        serializedName: "fieldName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "ADDED",
            "READ",
            "PROCESSED",
            "DELETED"
          ]
        }
      },
      commentType: {
        serializedName: "commentType",
        type: {
          name: "Enum",
          allowedValues: [
            "PUBLIC",
            "PRIVATE",
            "IMPORTANT"
          ]
        }
      }
    }
  }
};

export const CreateSubjectCommentCommand: msRest.CompositeMapper = {
  serializedName: "CreateSubjectCommentCommand",
  type: {
    name: "Composite",
    className: "CreateSubjectCommentCommand",
    modelProperties: {
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      fieldName: {
        serializedName: "fieldName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      commentType: {
        serializedName: "commentType",
        type: {
          name: "Enum",
          allowedValues: [
            "PUBLIC",
            "PRIVATE",
            "IMPORTANT"
          ]
        }
      }
    }
  }
};

export const UpdateSubjectCommentCommand: msRest.CompositeMapper = {
  serializedName: "UpdateSubjectCommentCommand",
  type: {
    name: "Composite",
    className: "UpdateSubjectCommentCommand",
    modelProperties: {
      commentId: {
        serializedName: "commentId",
        type: {
          name: "Uuid"
        }
      },
      fieldName: {
        serializedName: "fieldName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeleteSubjectCommentCommand: msRest.CompositeMapper = {
  serializedName: "DeleteSubjectCommentCommand",
  type: {
    name: "Composite",
    className: "DeleteSubjectCommentCommand",
    modelProperties: {
      commentId: {
        serializedName: "commentId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SubjectCommentListItemDto: msRest.CompositeMapper = {
  serializedName: "SubjectCommentListItemDto",
  type: {
    name: "Composite",
    className: "SubjectCommentListItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      createdByUserId: {
        serializedName: "createdByUserId",
        type: {
          name: "Uuid"
        }
      },
      createdBySubjectId: {
        serializedName: "createdBySubjectId",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      },
      createdBySubject: {
        serializedName: "createdBySubject",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "ADDED",
            "READ",
            "PROCESSED",
            "DELETED"
          ]
        }
      },
      commentType: {
        serializedName: "commentType",
        type: {
          name: "Enum",
          allowedValues: [
            "PUBLIC",
            "PRIVATE",
            "IMPORTANT"
          ]
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      },
      fieldName: {
        serializedName: "fieldName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SubjectCommentListItemDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "SubjectCommentListItemDtoPagedResult",
  type: {
    name: "Composite",
    className: "SubjectCommentListItemDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectCommentListItemDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SetSubjectCommentStatusCommand: msRest.CompositeMapper = {
  serializedName: "SetSubjectCommentStatusCommand",
  type: {
    name: "Composite",
    className: "SetSubjectCommentStatusCommand",
    modelProperties: {
      commentId: {
        serializedName: "commentId",
        type: {
          name: "Uuid"
        }
      },
      commentStatus: {
        serializedName: "commentStatus",
        type: {
          name: "Enum",
          allowedValues: [
            "ADDED",
            "READ",
            "PROCESSED",
            "DELETED"
          ]
        }
      }
    }
  }
};

export const SubjectReportingProcessListItemDto: msRest.CompositeMapper = {
  serializedName: "SubjectReportingProcessListItemDto",
  type: {
    name: "Composite",
    className: "SubjectReportingProcessListItemDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      identifier: {
        serializedName: "identifier",
        type: {
          name: "String"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      process: {
        serializedName: "process",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "ACTIVE",
            "INACTIVE",
            "DELETED"
          ]
        }
      },
      deadline: {
        serializedName: "deadline",
        type: {
          name: "DateTime"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SubjectReportingProcessListItemDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "SubjectReportingProcessListItemDtoPagedResult",
  type: {
    name: "Composite",
    className: "SubjectReportingProcessListItemDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectReportingProcessListItemDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CreateSubjectReportingProcessCommand: msRest.CompositeMapper = {
  serializedName: "CreateSubjectReportingProcessCommand",
  type: {
    name: "Composite",
    className: "CreateSubjectReportingProcessCommand",
    modelProperties: {
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      process: {
        serializedName: "process",
        type: {
          name: "Enum",
          allowedValues: [
            "MZ49",
            "MZ53",
            "NPZ"
          ]
        }
      },
      submissionDay: {
        serializedName: "submissionDay",
        type: {
          name: "Number"
        }
      },
      submissionMonth: {
        serializedName: "submissionMonth",
        type: {
          name: "Number"
        }
      },
      submissionFromYear: {
        serializedName: "submissionFromYear",
        type: {
          name: "Number"
        }
      },
      submissionCycleLength: {
        serializedName: "submissionCycleLength",
        type: {
          name: "Number"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const UpdateSubjectReportingProcessCommand: msRest.CompositeMapper = {
  serializedName: "UpdateSubjectReportingProcessCommand",
  type: {
    name: "Composite",
    className: "UpdateSubjectReportingProcessCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      process: {
        serializedName: "process",
        type: {
          name: "Enum",
          allowedValues: [
            "MZ49",
            "MZ53",
            "NPZ"
          ]
        }
      },
      submissionDay: {
        serializedName: "submissionDay",
        type: {
          name: "Number"
        }
      },
      submissionMonth: {
        serializedName: "submissionMonth",
        type: {
          name: "Number"
        }
      },
      submissionFromYear: {
        serializedName: "submissionFromYear",
        type: {
          name: "Number"
        }
      },
      submissionCycleLength: {
        serializedName: "submissionCycleLength",
        type: {
          name: "Number"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const SubjectReportingProcessDto: msRest.CompositeMapper = {
  serializedName: "SubjectReportingProcessDto",
  type: {
    name: "Composite",
    className: "SubjectReportingProcessDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      },
      subjectKind: {
        serializedName: "subjectKind",
        type: {
          name: "Enum",
          allowedValues: [
            "PSSE",
            "WSSE",
            "GSSE",
            "PZH",
            "GIS",
            "HOSPITAL",
            "CONTRACTOR",
            "WCZP",
            "DEPARTMENT",
            "DISTRICT",
            "GM",
            "GMW",
            "GW",
            "MNP",
            "SP",
            "UM",
            "UW",
            "IC",
            "M",
            "EDUCATIONALUNIT"
          ]
        }
      },
      process: {
        serializedName: "process",
        type: {
          name: "Enum",
          allowedValues: [
            "MZ49",
            "MZ53",
            "NPZ"
          ]
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "ACTIVE",
            "INACTIVE",
            "DELETED"
          ]
        }
      },
      submissionDay: {
        serializedName: "submissionDay",
        type: {
          name: "Number"
        }
      },
      submissionMonth: {
        serializedName: "submissionMonth",
        type: {
          name: "Number"
        }
      },
      submissionFromYear: {
        serializedName: "submissionFromYear",
        type: {
          name: "Number"
        }
      },
      submissionCycleLength: {
        serializedName: "submissionCycleLength",
        type: {
          name: "Number"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const ActivateSubjectReportingProcessCommand: msRest.CompositeMapper = {
  serializedName: "ActivateSubjectReportingProcessCommand",
  type: {
    name: "Composite",
    className: "ActivateSubjectReportingProcessCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const TagDto: msRest.CompositeMapper = {
  serializedName: "TagDto",
  type: {
    name: "Composite",
    className: "TagDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      tagName: {
        serializedName: "tagName",
        type: {
          name: "String"
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Uuid"
        }
      },
      subjectName: {
        serializedName: "subjectName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TagDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "TagDtoPagedResult",
  type: {
    name: "Composite",
    className: "TagDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TagDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DeleteTagSuggestionCommand: msRest.CompositeMapper = {
  serializedName: "DeleteTagSuggestionCommand",
  type: {
    name: "Composite",
    className: "DeleteTagSuggestionCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const LocalityDto: msRest.CompositeMapper = {
  serializedName: "LocalityDto",
  type: {
    name: "Composite",
    className: "LocalityDto",
    modelProperties: {
      simcCode: {
        serializedName: "simcCode",
        type: {
          name: "String"
        }
      },
      tercCode: {
        serializedName: "tercCode",
        type: {
          name: "String"
        }
      },
      locality: {
        serializedName: "locality",
        type: {
          name: "String"
        }
      },
      county: {
        serializedName: "county",
        type: {
          name: "String"
        }
      },
      voivodeship: {
        serializedName: "voivodeship",
        type: {
          name: "String"
        }
      },
      community: {
        serializedName: "community",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      hasAnyStreets: {
        serializedName: "hasAnyStreets",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const LocalityDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "LocalityDtoPagedResult",
  type: {
    name: "Composite",
    className: "LocalityDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocalityDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TerritorialUnitDto: msRest.CompositeMapper = {
  serializedName: "TerritorialUnitDto",
  type: {
    name: "Composite",
    className: "TerritorialUnitDto",
    modelProperties: {
      tercCode: {
        serializedName: "tercCode",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TerritorialUnitDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "TerritorialUnitDtoPagedResult",
  type: {
    name: "Composite",
    className: "TerritorialUnitDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TerritorialUnitDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const StreetDto: msRest.CompositeMapper = {
  serializedName: "StreetDto",
  type: {
    name: "Composite",
    className: "StreetDto",
    modelProperties: {
      streetCode: {
        serializedName: "streetCode",
        type: {
          name: "String"
        }
      },
      name1: {
        serializedName: "name1",
        type: {
          name: "String"
        }
      },
      name2: {
        serializedName: "name2",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StreetDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "StreetDtoPagedResult",
  type: {
    name: "Composite",
    className: "StreetDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StreetDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TerritoryDto: msRest.CompositeMapper = {
  serializedName: "TerritoryDto",
  type: {
    name: "Composite",
    className: "TerritoryDto",
    modelProperties: {
      tercCode: {
        serializedName: "tercCode",
        type: {
          name: "String"
        }
      },
      simcCode: {
        serializedName: "simcCode",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      voivodeship: {
        serializedName: "voivodeship",
        type: {
          name: "String"
        }
      },
      voivodeshipTerc: {
        serializedName: "voivodeshipTerc",
        type: {
          name: "String"
        }
      },
      county: {
        serializedName: "county",
        type: {
          name: "String"
        }
      },
      countyTerc: {
        serializedName: "countyTerc",
        type: {
          name: "String"
        }
      },
      community: {
        serializedName: "community",
        type: {
          name: "String"
        }
      },
      communityTerc: {
        serializedName: "communityTerc",
        type: {
          name: "String"
        }
      },
      territorialUnitType: {
        serializedName: "territorialUnitType",
        type: {
          name: "Enum",
          allowedValues: [
            "Voivodeship",
            "County",
            "UrbanCommunity",
            "CountryCommunity",
            "CountryArea",
            "City",
            "CityOnCountyStatus",
            "CapitalCity",
            "District",
            "Delegacy",
            "UrbanCountryCommunity"
          ]
        }
      }
    }
  }
};

export const TerritoryDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "TerritoryDtoPagedResult",
  type: {
    name: "Composite",
    className: "TerritoryDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TerritoryDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const UserDto: msRest.CompositeMapper = {
  serializedName: "UserDto",
  type: {
    name: "Composite",
    className: "UserDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleValueDto"
            }
          }
        }
      },
      rolePermissions: {
        serializedName: "rolePermissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RolePermissionDto"
            }
          }
        }
      }
    }
  }
};

export const UserListDto: msRest.CompositeMapper = {
  serializedName: "UserListDto",
  type: {
    name: "Composite",
    className: "UserListDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      fullName: {
        serializedName: "fullName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UserListDtoPagedResult: msRest.CompositeMapper = {
  serializedName: "UserListDtoPagedResult",
  type: {
    name: "Composite",
    className: "UserListDtoPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UserListDto"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AddUserRoleCommand: msRest.CompositeMapper = {
  serializedName: "AddUserRoleCommand",
  type: {
    name: "Composite",
    className: "AddUserRoleCommand",
    modelProperties: {
      userId: {
        serializedName: "userId",
        type: {
          name: "Uuid"
        }
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Uuid"
        }
      },
      validFrom: {
        serializedName: "validFrom",
        type: {
          name: "DateTime"
        }
      },
      validTo: {
        serializedName: "validTo",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const DeleteUserRoleCommand: msRest.CompositeMapper = {
  serializedName: "DeleteUserRoleCommand",
  type: {
    name: "Composite",
    className: "DeleteUserRoleCommand",
    modelProperties: {
      userId: {
        serializedName: "userId",
        type: {
          name: "Uuid"
        }
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const UserManualFileDto: msRest.CompositeMapper = {
  serializedName: "UserManualFileDto",
  type: {
    name: "Composite",
    className: "UserManualFileDto",
    modelProperties: {
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileExtension: {
        serializedName: "fileExtension",
        type: {
          name: "String"
        }
      },
      mimeType: {
        serializedName: "mimeType",
        type: {
          name: "String"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const UserNotificationDTO: msRest.CompositeMapper = {
  serializedName: "UserNotificationDTO",
  type: {
    name: "Composite",
    className: "UserNotificationDTO",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      occurrenceId: {
        serializedName: "occurrenceId",
        type: {
          name: "Uuid"
        }
      },
      header: {
        serializedName: "header",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      dateFrom: {
        serializedName: "dateFrom",
        type: {
          name: "DateTime"
        }
      },
      dateTo: {
        serializedName: "dateTo",
        type: {
          name: "DateTime"
        }
      },
      creationDate: {
        serializedName: "creationDate",
        type: {
          name: "DateTime"
        }
      },
      occurrenceDomainType: {
        serializedName: "occurrenceDomainType",
        type: {
          name: "Enum",
          allowedValues: [
            "Intervention",
            "Statement",
            "User",
            "ReportingProcess",
            "Activity"
          ]
        }
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid"
        }
      },
      notificationDefinitionId: {
        serializedName: "notificationDefinitionId",
        type: {
          name: "Uuid"
        }
      },
      occurrenceType: {
        serializedName: "occurrenceType",
        type: {
          name: "Enum",
          allowedValues: [
            "PositiveAotmitDecisionReceived",
            "NegativeAotmitDecisionReceived",
            "ConditionallyPositiveAotmitDecisionReceived",
            "NPZStatementRejection",
            "MZ49StatementRejection",
            "MZ53StatementRejection",
            "NPZCriticalStatementSendDate",
            "MZ49CriticalStatementSendDate",
            "MZ53CriticalStatementSendDate",
            "InterventionRealizationStartDateChanged",
            "InterventionRealizationEndDateChanged",
            "StatementAttachmentImportSucedded",
            "StatementAttachmentImportFailed",
            "UserPrivateEvent",
            "NPZStatementClosed",
            "MZ49StatementClosed",
            "MZ53StatementClosed",
            "NPZStatementReClosed",
            "MZ49StatementReClosed",
            "MZ53StatementReClosed",
            "NPZStatementAccepted",
            "MZ49StatementAccepted",
            "MZ53StatementAccepted",
            "IkpRecrutationStart",
            "IkpRecrutationEnd",
            "ExportFinished"
          ]
        }
      },
      additionalData: {
        serializedName: "additionalData",
        type: {
          name: "String"
        }
      },
      hasActiveChannel: {
        serializedName: "hasActiveChannel",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UserNotificationDTOPagedResult: msRest.CompositeMapper = {
  serializedName: "UserNotificationDTOPagedResult",
  type: {
    name: "Composite",
    className: "UserNotificationDTOPagedResult",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UserNotificationDTO"
            }
          }
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      currentPage: {
        serializedName: "currentPage",
        type: {
          name: "Number"
        }
      },
      totalPagesNumber: {
        readOnly: true,
        serializedName: "totalPagesNumber",
        type: {
          name: "Number"
        }
      },
      hasPreviousPage: {
        readOnly: true,
        serializedName: "hasPreviousPage",
        type: {
          name: "Boolean"
        }
      },
      hasNextPage: {
        readOnly: true,
        serializedName: "hasNextPage",
        type: {
          name: "Boolean"
        }
      },
      firstRowOnPage: {
        readOnly: true,
        serializedName: "firstRowOnPage",
        type: {
          name: "Number"
        }
      },
      lastRowOnPage: {
        readOnly: true,
        serializedName: "lastRowOnPage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MarkAsReadCommand: msRest.CompositeMapper = {
  serializedName: "MarkAsReadCommand",
  type: {
    name: "Composite",
    className: "MarkAsReadCommand",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ToggleActiveOfSameKindCommand: msRest.CompositeMapper = {
  serializedName: "ToggleActiveOfSameKindCommand",
  type: {
    name: "Composite",
    className: "ToggleActiveOfSameKindCommand",
    modelProperties: {
      userNotificationId: {
        serializedName: "userNotificationId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ActivateUserNotificationCommand: msRest.CompositeMapper = {
  serializedName: "ActivateUserNotificationCommand",
  type: {
    name: "Composite",
    className: "ActivateUserNotificationCommand",
    modelProperties: {
      notificationId: {
        serializedName: "notificationId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DeactivateUserNotificationCommand: msRest.CompositeMapper = {
  serializedName: "DeactivateUserNotificationCommand",
  type: {
    name: "Composite",
    className: "DeactivateUserNotificationCommand",
    modelProperties: {
      notificationId: {
        serializedName: "notificationId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const UpdateUserNotificationChannelsCommand: msRest.CompositeMapper = {
  serializedName: "UpdateUserNotificationChannelsCommand",
  type: {
    name: "Composite",
    className: "UpdateUserNotificationChannelsCommand",
    modelProperties: {
      notificationId: {
        serializedName: "notificationId",
        type: {
          name: "Uuid"
        }
      },
      systemChannelActive: {
        serializedName: "systemChannelActive",
        type: {
          name: "Boolean"
        }
      },
      emailChannelActive: {
        serializedName: "emailChannelActive",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};
