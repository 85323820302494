import FormRunner from '@components/orbeon/FormRunner';
import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { markErrors } from '@components/shared/tabs/markErrors';
import { Checkbox, Col, Row, Space, Tabs } from 'antd';
import DictionaryPicker from 'components/dictionary/picker/DictionaryPicker';
import { Field } from 'formik';
import {
	FDatePicker,
	FFieldLabel,
	FInput,
	FInputNumber,
	FRangePicker,
	FTextArea,
} from 'forms/FormikFormItems';
import _ from 'lodash';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
	DictionaryInterventionPath,
	InterventionPath,
} from 'services/src/models';

import ImplementingCompanyTable from '../activity/details/table/ImplementingCompanyTable';
import PopulationTable from '../activity/details/table/PopulationTable';
import SponsoringCompanyTable from '../activity/details/table/SponsoringCompanyTable';
import { InterventionCommentArea } from '../comments/InterventionCommentArea';
import { InterventionCommentIcon } from '../comments/InterventionCommentIcon';
import { InterventionAddCommentModal } from '../comments/modals/InterventionAddCommentModal';
import { ActivityTab } from '../comments/store/InterventionCommentStore';
import { ActivityContextType } from '../context/ActivityContext';
import { ChangedVersionDictionaryDto } from '../model/InterventionBaseCommand';

const { TabPane } = Tabs;

export const getDateRange = (
	context: ActivityContextType
): [(Moment | undefined)?, (Moment | undefined)?] | Moment[] => {
	if (context.editable) {
		return context.dateRange!;
	} else {
		if (context.activity?.updatedAt) {
			return [context.activity?.updatedAt!, context.activity?.updatedAt!];
		} else {
			return [
				context.interventionCreatedAt,
				context.interventionCreatedAt,
			];
		}
	}
};

export const AddComment = (
	context: ActivityContextType,
	activityTab: ActivityTab
) => {
	return (
		<Col span={24}>
			<InterventionAddCommentModal
				activityTab={activityTab}
				activityId={context.activity?.id!}
				interventionId={context.interventionId!}
				interventionPath={
					(context.interventionPath as string) as InterventionPath
				}
			/>
		</Col>
	);
};

export const ActivityName = (context: ActivityContextType) => {
	return (
		<Col xs={24} sm={21}>
			<Space>
				<FFieldLabel label="Nazwa" />
				<InterventionCommentIcon fieldName="activityName" />
			</Space>
			<InterventionCommentArea fieldName="activityName">
				<Field
					label="Nazwa"
					component={FTextArea}
					name="name"
					style={{ width: '100%' }}
					readOnly={
						!context.editable || !!context.patternActivity?.name
					}
					rows={1}
				/>
			</InterventionCommentArea>
		</Col>
	);
};

export const ActivityNumber = (context: ActivityContextType) => {
	return (
		<Col xs={5}>
			<Space>
				<FFieldLabel label="Numer" />
				<InterventionCommentIcon fieldName="activityNumber" />
			</Space>
			<InterventionCommentArea fieldName="activityNumber">
				<Field
					readOnly
					label="Numer"
					component={FInput}
					name="activityNumber"
					style={{ width: '100%' }}
				/>
			</InterventionCommentArea>
		</Col>
	);
};

export const ActivityCategory = (context: ActivityContextType) => {
	return (
		<Col span={24}>
			<Row gutter={16}>
				<Col xs={24}>
					<DictionaryPicker
						label="Kategoria działania"
						commentField="activityCategory"
						helpField="categoryDictionaryValues"
						interventionPath={context.interventionPath}
						disabled={
							!context.editable ||
							context.checkIfObjectValue?.(
								context.patternActivity,
								'activityCategory'
							)
						}
						readOnly={
							!context.editable ||
							context.checkIfObjectValue?.(
								context.patternActivity,
								'activityCategory'
							)
						}
						checkChanges={context.checkChanges}
						dateRange={getDateRange(context)}
						codeName="KATEGORIA_DZIALANIA"
						name="activityCategoryDictionaryValueId"
						otherValue="activityCategoryDictionaryOtherValue"
						inline
						changedVersion={
							context.editable &&
							context.unchangedData
								?.activityCategoryDictionaryValueId &&
							!_.isEqual(
								context.unchangedData
									?.activityCategoryDictionaryValueId!,
								context.activity
									?.activityCategoryDictionaryValueId!
							)
								? new ChangedVersionDictionaryDto(
										context.unchangedData?.activityCategoryDictionaryValueId!,
										context.activity?.activityCategoryDictionaryValueId!,
										context.unchangedData?.activityCategoryDictionaryOtherValue
								  )
								: undefined
						}
					/>
				</Col>
			</Row>
		</Col>
	);
};

export const ActivityNPZ = (context: ActivityContextType) => {
	return (
		<Col xs={24} className="mt-xs">
			<DictionaryPicker
				label="Numer zadania NPZ"
				commentField="activityNPZTaskNumber"
				helpField="nzpTaskNumberDictionaryValues"
				interventionPath={context.interventionPath}
				multiple
				inline
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.patternActivity,
						'nzpTaskNumberDictionaryValues'
					)
				}
				readOnly={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.patternActivity,
						'nzpTaskNumberDictionaryValues'
					)
				}
				name="nzpTaskNumberDictionaryValues"
				codeName="ZADANIA_NPZ"
				disableBreadcrumbs={false}
				checkChanges={context.checkChanges}
				dateRange={getDateRange(context)}
				changedVersion={
					context.editable &&
					context.unchangedData?.nzpTaskNumberDictionaryValues &&
					_.differenceWith(
						context.unchangedData?.nzpTaskNumberDictionaryValues!,
						context.activity?.nzpTaskNumberDictionaryValues!,
						_.isEqual
					)?.length > 0
						? new ChangedVersionDictionaryDto(
								context.activity?.nzpTaskNumberDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.nzpTaskNumberDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.nzpTaskNumberDictionaryValues?.map(
									(x) => x.value!
								)!
						  )
						: undefined
				}
			/>
		</Col>
	);
};

export const ActivityPublicHealthTask = (context: ActivityContextType) => {
	return (
		<Col xs={24} sm={21} className="mt-xs">
			<DictionaryPicker
				label="Zadania zdrowia publicznego"
				interventionPath={context.interventionPath}
				commentField="activityPublicHealthTask"
				multiple
				inline
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.patternActivity,
						'publicHealthTaskDictionaryValues'
					)
				}
				readOnly={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.patternActivity,
						'publicHealthTaskDictionaryValues'
					)
				}
				name="publicHealthTaskDictionaryValues"
				valueId="id"
				otherName="value"
				otherValue="otherValue"
				codeName="ZADANIA_ZDROWIA_PUBLICZNEGO"
				checkChanges={context.checkChanges}
				dateRange={getDateRange(context)}
				changedVersion={
					context.editable &&
					context.unchangedData?.publicHealthTaskDictionaryValues &&
					_.differenceWith(
						context.unchangedData
							?.publicHealthTaskDictionaryValues!,
						context.activity?.publicHealthTaskDictionaryValues!,
						_.isEqual
					)?.length > 0
						? new ChangedVersionDictionaryDto(
								context.activity?.publicHealthTaskDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.publicHealthTaskDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.publicHealthTaskDictionaryValues?.map(
									(x) => x.value!
								)!
						  )
						: undefined
				}
			/>
		</Col>
	);
};

export const ActivityPRPZ = (context: ActivityContextType) => {
	return (
		<Col xs={24} className="mt-xs">
			<DictionaryPicker
				label="Priorytety dla Regionalnej Polityki Zdrowotnej/Wojewódzki Plan Transformacji"
				commentField="activityPRPZ"
				interventionPath={context.interventionPath}
				multiple
				inline
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.patternActivity,
						'prpzDictionaryValues'
					)
				}
				readOnly={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.patternActivity,
						'prpzDictionaryValues'
					)
				}
				name="prpzDictionaryValues"
				codeName="PRPZ"
				checkChanges={context.checkChanges}
				dateRange={getDateRange(context)}
				changedVersion={
					context.editable &&
					context.unchangedData?.prpzDictionaryValues &&
					_.differenceWith(
						context.unchangedData?.prpzDictionaryValues!,
						context.activity?.prpzDictionaryValues!,
						_.isEqual
					)?.length > 0
						? new ChangedVersionDictionaryDto(
								context.activity?.prpzDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.prpzDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.prpzDictionaryValues?.map(
									(x) => x.value!
								)!
						  )
						: undefined
				}
			/>
		</Col>
	);
};

export const ActivityChosenPopulationsHealths = (
	context: ActivityContextType
) => {
	return (
		<Row>
			{AddComment(context, ActivityTab.HealthProblem)}
			<Col xs={24} className="mt-xs">
				<DictionaryPicker
					label="Zdrowie wybranych populacji"
					interventionPath={context.interventionPath}
					commentField="activityChosenPopulationHealths"
					multiple
					inline
					disabled={
						!context.editable ||
						context.checkIfObjectValue?.(
							context.patternActivity,
							'chosenPopulationHealthsDictionaryValues'
						)
					}
					readOnly={
						!context.editable ||
						context.checkIfObjectValue?.(
							context.patternActivity,
							'chosenPopulationHealthsDictionaryValues'
						)
					}
					name="chosenPopulationHealthsDictionaryValues"
					valueId="id"
					otherValue="otherValue"
					codeName="PROBLEM_DZIALANIA_S1"
					checkChanges={context.checkChanges}
					dateRange={getDateRange(context)}
					changedVersion={
						context.editable &&
						context.unchangedData
							?.chosenPopulationHealthsDictionaryValues &&
						_.differenceWith(
							context.unchangedData
								?.chosenPopulationHealthsDictionaryValues!,
							context.activity
								?.chosenPopulationHealthsDictionaryValues!,
							_.isEqual
						)?.length > 0
							? new ChangedVersionDictionaryDto(
									context.activity?.chosenPopulationHealthsDictionaryValues?.map(
										(x) => x.id!
									)!,
									context.unchangedData?.chosenPopulationHealthsDictionaryValues?.map(
										(x) => x.id!
									)!,
									context.unchangedData?.chosenPopulationHealthsDictionaryValues?.map(
										(x) => x.value!
									)!
							  )
							: undefined
					}
				/>
			</Col>
		</Row>
	);
};

export const ActivityCareAreas = (context: ActivityContextType) => {
	return (
		<Col xs={24} className="mt-xs">
			<DictionaryPicker
				label="Obszary opieki"
				commentField="activityCareAreas"
				interventionPath={context.interventionPath}
				multiple
				inline
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.patternActivity,
						'careAreasDictionaryValues'
					)
				}
				readOnly={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.patternActivity,
						'careAreasDictionaryValues'
					)
				}
				name="careAreasDictionaryValues"
				codeName="PROBLEM_DZIALANIA_S2"
				valueId="id"
				otherValue="otherValue"
				checkChanges={context.checkChanges}
				dateRange={getDateRange(context)}
				changedVersion={
					context.editable &&
					context.unchangedData?.careAreasDictionaryValues &&
					_.differenceWith(
						context.unchangedData?.careAreasDictionaryValues!,
						context.activity?.careAreasDictionaryValues!,
						_.isEqual
					)?.length > 0
						? new ChangedVersionDictionaryDto(
								context.activity?.careAreasDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.careAreasDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.careAreasDictionaryValues?.map(
									(x) => x.value!
								)!
						  )
						: undefined
				}
			/>
		</Col>
	);
};

export const ActivityDiseasesMortalityPrevalences = (
	context: ActivityContextType
) => {
	return (
		<Col xs={24} className="mt-xs">
			<DictionaryPicker
				label="Międzynarodowa Statystyczna Klasyfikacja Chorób i Problemów Zdrowotnych ICD-10"
				commentField="activityDiseasesMortalityPrevalences"
				interventionPath={context.interventionPath}
				multiple
				inline
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.patternActivity,
						'diseasesMortalityPrevalencesDictionaryValues'
					)
				}
				readOnly={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.patternActivity,
						'diseasesMortalityPrevalencesDictionaryValues'
					)
				}
				name="diseasesMortalityPrevalencesDictionaryValues"
				codeName="PROBLEM_DZIALANIA_S3"
				checkChanges={context.checkChanges}
				dateRange={getDateRange(context)}
				changedVersion={
					context.editable &&
					context.unchangedData
						?.diseasesMortalityPrevalencesDictionaryValues &&
					_.differenceWith(
						context.unchangedData
							?.diseasesMortalityPrevalencesDictionaryValues!,
						context.activity
							?.diseasesMortalityPrevalencesDictionaryValues!,
						_.isEqual
					)?.length > 0
						? new ChangedVersionDictionaryDto(
								context.activity?.diseasesMortalityPrevalencesDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.diseasesMortalityPrevalencesDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.diseasesMortalityPrevalencesDictionaryValues?.map(
									(x) => x.value!
								)!
						  )
						: undefined
				}
			/>
		</Col>
	);
};

export const ActivityDescription = (context: ActivityContextType) => {
	return (
		<Col xs={24} sm={21}>
			<Space>
				<FFieldLabel label="Opis" />
				<LabelHelpTooltip field="description" defaultValue="Opis" />
				<InterventionCommentIcon fieldName="activityDescription" />
			</Space>
			<InterventionCommentArea fieldName="activityDescription">
				<Field
					readOnly={
						!context.editable ||
						context.checkIfObjectValue?.(
							context.patternActivity,
							'description'
						)
					}
					component={FTextArea}
					label="Opis"
					changeData={(value: any) =>
						context.checkChanges(value, 'description')
					}
					name="description"
					rows={1}
				/>
			</InterventionCommentArea>
		</Col>
	);
};

export const ActivityComment = (context: ActivityContextType) => {
	return (
		<Col xs={24} sm={21}>
			<Space>
				<FFieldLabel label="Uwagi" />
				<LabelHelpTooltip field="comment" defaultValue="Uwagi" />
				<InterventionCommentIcon fieldName="activityComment" />
			</Space>
			<InterventionCommentArea fieldName="activityComment">
				<Field
					readOnly={
						!context.editable ||
						context.checkIfObjectValue?.(
							context.patternActivity,
							'comment'
						)
					}
					component={FTextArea}
					label="Uwagi"
					changeData={(value: any) =>
						context.checkChanges(value, 'comment')
					}
					name="comment"
					rows={1}
				/>
			</InterventionCommentArea>
		</Col>
	);
};

export const ActivityTopic = (context: ActivityContextType) => {
	const [value, setValue] = useState<string | undefined>(
		context.activity?.topic
	);
	let label =
		context.interventionPath ==
		DictionaryInterventionPath.PROHEALTHACTIVITIES
			? 'Temat/tytuł'
			: 'Temat (Efekty interwencji)';
	return (
		<Col xs={24} sm={21}>
			<Space>
				<FFieldLabel label={label} />
				<InterventionCommentIcon fieldName="activityTopic" />
				<Checkbox
					disabled={
						!value ||
						!context.editable ||
						context.checkIfObjectValue?.(
							context.patternActivity,
							'topic'
						)
					}
					className="ant-table-pagination d-flex align-center"
					checked={!value || value?.trim() == ''}
					onClick={() => {
						setValue('');
						context.checkChanges(value, 'topic');
						context.formik?.setFieldValue('topic', undefined);
					}}
				>
					nie dotyczy
				</Checkbox>
			</Space>
			<InterventionCommentArea fieldName="activityTopic">
				<Field
					readOnly={
						!context.editable || !!context.patternActivity?.topic
					}
					value={value}
					component={FTextArea}
					label={label}
					changeData={(value: any) => {
						setValue(value);
						context.checkChanges(value, 'topic');
					}}
					name="topic"
					rows={1}
				/>
			</InterventionCommentArea>
		</Col>
	);
};

export const ActivityNumberOfRecipients = (context: ActivityContextType) => {
	return (
		<Col xs={24} sm={5}>
			<Space>
				<FFieldLabel label="Liczba odbiorców" />
				<LabelHelpTooltip
					field="numberOfRecipients"
					defaultValue="Liczba odbiorców"
				/>
				<InterventionCommentIcon fieldName="activityNumberOfRecipients" />
			</Space>
			<InterventionCommentArea fieldName="activityNumberOfRecipients">
				<Field
					readOnly={
						!context.editable ||
						context.checkIfObjectValue?.(
							context.patternActivity,
							'numberOfRecipients'
						)
					}
					component={FInputNumber}
					label="Liczba odbiorców działania"
					changeData={(value: any) =>
						context.checkChanges(value, 'numberOfRecipients')
					}
					name="numberOfRecipients"
					precision={0}
				/>
			</InterventionCommentArea>
		</Col>
	);
};
export const ActivityNumberOfPersonAction = (context: ActivityContextType) => {
	return (
		<Col xs={24} sm={5}>
			<Space>
				<FFieldLabel label="Liczba osobodziałań" />
				<LabelHelpTooltip
					field="numberOfPersonActionActivity"
					defaultValue="Liczba osobodziałań"
				/>
				<InterventionCommentIcon fieldName="activityNumberOfPersonAction" />
			</Space>
			<InterventionCommentArea fieldName="activityNumberOfPersonAction">
				<Field
					readOnly={
						!context.editable ||
						context.checkIfObjectValue?.(
							context.patternActivity,
							'numberOfPersonAction'
						)
					}
					label="Liczba osobodziałań działania"
					component={FInputNumber}
					changeData={(value: any) =>
						context.checkChanges(value, 'numberOfPersonAction')
					}
					name="numberOfPersonAction"
					precision={0}
				/>
			</InterventionCommentArea>
		</Col>
	);
};

export const ActivityRealizationDate = (context: ActivityContextType) => {
	return (
		<Col xs={24} sm={6}>
			<Space>
				<FFieldLabel label="Data realizacji" for="realizationDate" />
				<LabelHelpTooltip
					field="realizationDate"
					defaultValue="Data realizacji"
				/>
				<InterventionCommentIcon fieldName="activityRealizationDate" />
			</Space>
			<InterventionCommentArea fieldName="activityRealizationDate">
				<Field
					inputReadOnly={
						!context.editable ||
						(!!context.patternActivity?.dateFrom &&
							!!context.patternActivity?.dateTo)
					}
					component={FRangePicker}
					placeholder={[
						'Data początkowa realizacji działania',
						'Data końcowa realizacji działania',
					]}
					changeData={(value: any) => {
						context.checkChanges(value, 'realizationDate');
						context.setDateRange?.(value);
					}}
					name="realizationDate"
				/>
			</InterventionCommentArea>
		</Col>
	);
};

export const ActivityPopulation = (context: ActivityContextType) => {
	const tab = (
		<>
			<span>Populacja</span>
			<LabelHelpTooltip
				field="activityPopulationsTab"
				defaultValue="Populacja"
			/>
		</>
	);
	return (
		<TabPane
			tab={markErrors(context.formik?.errors, 'population', tab)}
			key="population"
		>
			<Row>
				{AddComment(context, ActivityTab.Population)}
				<Col span={24}>
					<PopulationTable
						changedDictionariesValues={
							context.unchangedData?.activityPopulations
						}
						dateRange={getDateRange(context)}
						changeData={(value: any) =>
							context.checkChanges(
								_.clone(value),
								'activityPopulations'
							)
						}
						editable={context.editable}
					></PopulationTable>
				</Col>
			</Row>
		</TabPane>
	);
};

export const ActivityImplementationCompanies = (
	context: ActivityContextType
) => {
	const tab = (
		<>
			<span>Podmioty realizujące</span>
			<LabelHelpTooltip
				field="realizationSubjectsTab"
				defaultValue="Podmioty realizujące"
			/>
		</>
	);

	return (
		<TabPane
			tab={markErrors(
				context.formik?.errors,
				'implementingCompanies',
				tab
			)}
			key="implementationCompanies"
		>
			<Row>
				<Col span={5}>
					<InterventionCommentIcon
						fieldName="implementingCompany"
						text="Istnieje komentarz do tego obszaru"
					/>
				</Col>
				<Col span={19}>
					{AddComment(context, ActivityTab.ImplementingCompanies)}
				</Col>

				<Col span={24}>
					<ImplementingCompanyTable
						activityId={context.activity?.id}
						changeData={(value: any) =>
							context.checkChanges(
								_.clone(value),
								'implementingCompanies'
							)
						}
						mergedSubjectsMap={context.mergedSubjectMap}
						editable={context.editable}
					></ImplementingCompanyTable>
				</Col>
			</Row>
		</TabPane>
	);
};

export const ActivitySponsoringCompanies = (context: ActivityContextType) => {
	const tab = (
		<>
			<span>Podmioty finansujące</span>
			<LabelHelpTooltip
				field="financingSubjectsTab"
				defaultValue="Podmioty finansujące"
			/>
		</>
	);
	return (
		<TabPane
			tab={markErrors(context.formik?.errors, 'sponsoringCompanies', tab)}
			key="sponsoringCompanies"
		>
			<Row>
				{AddComment(context, ActivityTab.SponsoringCompanies)}
				<Col span={24}>
					<SponsoringCompanyTable
						dateRange={getDateRange(context)}
						changedDictionariesValues={
							context.unchangedData?.sponsoringCompanies
						}
						changeData={(value: any) =>
							context.checkChanges(
								_.clone(value),
								'sponsoringCompanies'
							)
						}
						mergedSubjectsMap={context.mergedSubjectMap}
						editable={context.editable}
					></SponsoringCompanyTable>
				</Col>
			</Row>
		</TabPane>
	);
};

export const ActivityAdditionalDetails = (context: ActivityContextType) => {
	const match = useRouteMatch<{ mode?: string; id?: string }>();
	return (
		<Row style={{ width: '100%' }}>
			<Col span={5}>
				<InterventionCommentIcon
					fieldName="additionalData"
					text="Istnieje komentarz do tego obszaru"
				/>
			</Col>
			<Col span={19}>
				{AddComment(context, ActivityTab.AdditionalData)}
			</Col>

			<Col span={24}>
				<FormRunner
					formVersionId={context.activity!.formVersionId!}
					mode={context.editable ? 'edit' : 'preview'}
					documentId={context.activity!.documentId}
					pathBase={`/rizp/${
						match.path.includes('/rizp/edit') ? 'edit' : 'details'
					}/${match.params.id}/activities/${match.params.mode}/${
						context.activity?.id
					}`}
					onFormAdded={() => {}}
					onFormPartLoaded={() => {}}
					query={{ activityId: context.activity!.id }}
				></FormRunner>
			</Col>
		</Row>
	);
};

export const IkpTab = (context: ActivityContextType) => {
	const [ikpEnabled, setIkpEnabled] = useState<boolean>(
		!!context.activity?.ikpId
	);

	return (
		<Row>
			{AddComment(context, ActivityTab.IKP)}
			<Col span={24}>
				<Space>
					<InterventionCommentIcon fieldName="publishedInIkp" />
					<Checkbox
						disabled={
							!context.editable ||
							context.checkIfObjectValue?.(
								context.patternActivity,
								'ikp'
							)
						}
						className="d-flex align-center"
						checked={ikpEnabled}
						onClick={() => {
							let newEnabled = !ikpEnabled;
							setIkpEnabled(newEnabled);
							context.checkChanges(newEnabled, 'publishedInIkp');
							context.formik?.setFieldValue(
								'publishedInIkp',
								newEnabled
							);
						}}
					>
						działanie publikowane w IKP
					</Checkbox>
				</Space>
			</Col>
			<Col span={24}>
				<Space>
					<FFieldLabel label="Data rekrutacji" />
					<LabelHelpTooltip
						field="ikpRecrutationDate"
						defaultValue="Data rekrutacji"
					/>
					<InterventionCommentIcon fieldName="ikpRecrutationDate" />
				</Space>
				<InterventionCommentArea fieldName="ikpRecrutationDate">
					<Field
						inputReadOnly={
							!context.editable ||
							!ikpEnabled ||
							context.checkIfObjectValue?.(
								context.patternActivity,
								'ikpRecrutationDate'
							)
						}
						component={FRangePicker}
						placeholder={[
							'Data rekrutacji od',
							'Data rekrutacji do',
						]}
						label="Data rekrutacji"
						name="ikpRecrutationDate"
						changeData={(value: any) => {
							context.checkChanges(value, 'ikpRecrutationDate');
							context.setDateRange?.(value);
						}}
					/>
				</InterventionCommentArea>
			</Col>
			<Col span={24}>
				<DictionaryPicker
					label="Status rekrutacji"
					commentField="ikpState"
					interventionPath={context.interventionPath}
					disabled={
						!context.editable ||
						!ikpEnabled ||
						context.checkIfObjectValue?.(
							context.patternActivity,
							'ikpStateDictionaryValueId'
						)
					}
					readOnly={
						!context.editable ||
						!ikpEnabled ||
						context.checkIfObjectValue?.(
							context.patternActivity,
							'ikpStateDictionaryValueId'
						)
					}
					checkChanges={context.checkChanges}
					dateRange={getDateRange(context)}
					codeName="STATUS_REKRUTACJI_IKP"
					name="ikpStateDictionaryValueId"
					otherValue="ikpStateDictionaryOtherValue"
					inline
					changedVersion={
						context.editable &&
						context.unchangedData?.ikpStateDictionaryValueId &&
						!_.isEqual(
							context.unchangedData?.ikpStateDictionaryValueId!,
							context.activity?.ikpStateDictionaryValueId!
						)
							? new ChangedVersionDictionaryDto(
									context.activity?.ikpStateDictionaryValueId!,
									context.unchangedData?.ikpStateDictionaryValueId!,
									context.unchangedData?.ikpStateDictionaryOtherValue
							  )
							: undefined
					}
				/>
			</Col>
			<Col span={24}>
				<Space>
					<FFieldLabel label="Kryteria włączenia" />
					<LabelHelpTooltip
						field="includeCriteria"
						defaultValue="Kryteria włączenia"
					/>
					<InterventionCommentIcon fieldName="ikpIncludeCriteria" />
				</Space>
				<InterventionCommentArea fieldName="ikpIncludeCriteria">
					<Field
						readOnly={
							!context.editable ||
							!ikpEnabled ||
							context.checkIfObjectValue?.(
								context.patternActivity,
								'ikpIncludeCriteria'
							)
						}
						component={FTextArea}
						label="Kryteria włączenia"
						changeData={(value: any) =>
							context.checkChanges(value, 'ikpIncludeCriteria')
						}
						name="ikpIncludeCriteria"
						rows={1}
					/>
				</InterventionCommentArea>
			</Col>
			<Col span={24}>
				<Space>
					<FFieldLabel label="Kryteria wyłączenia" />
					<LabelHelpTooltip
						field="excludeCriteria"
						defaultValue="Kryteria wyłączenia"
					/>
					<InterventionCommentIcon fieldName="ikpExcludeCriteria" />
				</Space>
				<InterventionCommentArea fieldName="ikpExcludeCriteria">
					<Field
						readOnly={
							!context.editable ||
							!ikpEnabled ||
							context.checkIfObjectValue?.(
								context.patternActivity,
								'ikpExcludeCriteria'
							)
						}
						component={FTextArea}
						label="Kryteria wyłączenia"
						changeData={(value: any) =>
							context.checkChanges(value, 'ikpExcludeCriteria')
						}
						name="ikpExcludeCriteria"
						rows={1}
					/>
				</InterventionCommentArea>
			</Col>
			<Col span={24}>
				<Space>
					<FFieldLabel label="Korzyści dla uczestnika programu" />
					<LabelHelpTooltip
						field="benefits"
						defaultValue="Korzyści dla uczestnika programu"
					/>
					<InterventionCommentIcon fieldName="ikpBenefits" />
				</Space>
				<InterventionCommentArea fieldName="ikpBenefits">
					<Field
						readOnly={
							!context.editable ||
							!ikpEnabled ||
							context.checkIfObjectValue?.(
								context.patternActivity,
								'ikpBenefits'
							)
						}
						component={FTextArea}
						label="Korzyści dla uczestnika programu"
						changeData={(value: any) =>
							context.checkChanges(value, 'ikpBenefits')
						}
						name="ikpBenefits"
						rows={1}
					/>
				</InterventionCommentArea>
			</Col>
			<Col span={24}>
				<Space>
					<FFieldLabel label="Źródło wiedzy o programie" />
					<LabelHelpTooltip
						field="sourceOfKnowledge"
						defaultValue="Źródło wiedzy o programie"
					/>
					<InterventionCommentIcon fieldName="ikpSourceOfKnowledge" />
				</Space>
				<InterventionCommentArea fieldName="ikpSourceOfKnowledge">
					<Field
						readOnly={
							!context.editable ||
							!ikpEnabled ||
							context.checkIfObjectValue?.(
								context.patternActivity,
								'ikpSourceOfKnowledge'
							)
						}
						component={FTextArea}
						label="Źródło wiedzy o programie"
						changeData={(value: any) =>
							context.checkChanges(value, 'ikpSourceOfKnowledge')
						}
						name="ikpSourceOfKnowledge"
						rows={1}
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	);
};
