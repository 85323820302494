import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useRootData } from 'hooks/hook';
import { observer } from 'mobx-react';
import { deepObserve } from 'mobx-utils';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { CenteredRow } from '../../../../layout/CenteredRow';
import { IGridStore } from '../../../../stores/GridStore';
import { ProfiBazaColumnProps } from '../GridHelper';

const FocusTrap = require('focus-trap-react');

interface IProps<T> {
	column: ProfiBazaColumnProps<T>;
	handleSearch: (value?: string) => void;
	gridName: string;
	clearInput?: boolean;
	setClearInput?: () => void;
	filterProps: FilterDropdownProps;
	defaultValue?: any;
}

const GridFilterInput = <T extends any>(
	props: IProps<T> & { children?: ReactNode }
) => {
	const { filterProps, defaultValue } = props;

	const [value, setValue] = useState<string | undefined>(undefined);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	let inputRef = useRef<Input>(null);

	const [trapActive, setTrapActive] = useState<boolean>(false);

	useEffect(() => {
		const x = window.scrollX,
			y = window.scrollY;
		window.scrollTo(x, y);

		const columnFilters = gridStore.getColumnFilter(
			props.gridName,
			props.column.key!
		)?.value;

		setValue(columnFilters ?? defaultValue);

		return () => {
			disposer();
		};
	}, []);

	useEffect(() => {
		if (props.clearInput) {
			setValue(undefined);
			props.setClearInput?.();
		}
	}, [props.clearInput]);

	const disposer = deepObserve(
		gridStore.filtersGC.get(props.gridName),
		(change, path) => {
			const status = gridStore.getFilterGCStatus(
				props.gridName,
				props.column.key!
			);
			if (status) {
				const columnFilters = gridStore.getColumnFilter(
					props.gridName,
					props.column.key!
				)?.value;
				setValue(columnFilters);
			}
		}
	);

	const onDeactivatePopover = () => {
		setTrapActive(false);
		filterProps.confirm();
	};

	const clear = () => {
		setValue(undefined);
		gridStore.clearColumnFilter(props.gridName, props.column.key!);

		props.handleSearch();
	};

	const search = () => {
		gridStore.saveColumnFilter(props.gridName, props.column.key!, value);
		props.handleSearch(value);
	};
	
	useEffect(() => {
		if (filterProps.visible) {
			setTrapActive(true);
		}
	}, [filterProps.visible]);

	return trapActive ? (
		<FocusTrap
			focusTrapOptions={{
				onDeactivate: onDeactivatePopover,
				clickOutsideDeactivates: true,
				returnFocusOnDeactivate: true,
				allowOutsideClick: true,
				setReturnFocus: `#filterColumn-${props.column.key}`,
				fallbackFocus: '.filter-block',
			}}
		>
			<div tabIndex={-1} className="filter-block">
				<CenteredRow>
					<Button
						aria-label="Wyszukaj"
						type="primary"
						icon={<SearchOutlined />}
						size="small"
						onClick={() => search()}
						disabled={!value}
					>
						Wyszukaj
					</Button>
					<Button
						aria-label="Wyczyść"
						size="small"
						style={{ marginLeft: 4 }}
						icon={<DeleteOutlined />}
						onClick={() => clear()}
					>
						Wyczyść
					</Button>
				</CenteredRow>
				<Input
					aria-label="Wyszukaj po frazie"
					className="searchInput"
					ref={inputRef}
					type={props.column.isNumeric ? 'number' : 'text'}
					onChange={(e) => setValue(e.target.value)}
					value={value}
					onPressEnter={() => value && search()}
					size="small"
					placeholder={
						props.column.filter === 'equals'
							? 'Wpisz całą frazę'
							: 'Wpisz część frazy'
					}
					style={{
						width: 200,
						marginTop: 8,
						marginBottom: 8,
						display: 'block',
					}}
				/>
			</div>
		</FocusTrap>
	) : null;
};

export default observer(GridFilterInput);
