/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/accountMappers";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Account. */
export class Account {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Account.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  saveDashboardSettings(options?: Models.AccountSaveDashboardSettingsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  saveDashboardSettings(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  saveDashboardSettings(options: Models.AccountSaveDashboardSettingsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  saveDashboardSettings(options?: Models.AccountSaveDashboardSettingsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      saveDashboardSettingsOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  saveGridSettings(options?: Models.AccountSaveGridSettingsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  saveGridSettings(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  saveGridSettings(options: Models.AccountSaveGridSettingsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  saveGridSettings(options?: Models.AccountSaveGridSettingsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      saveGridSettingsOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  acceptRODO(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  acceptRODO(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  acceptRODO(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  acceptRODO(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      acceptRODOOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AccountMeResponse>
   */
  me(options?: msRest.RequestOptionsBase): Promise<Models.AccountMeResponse>;
  /**
   * @param callback The callback
   */
  me(callback: msRest.ServiceCallback<Models.AccountDetailsDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  me(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.AccountDetailsDto>): void;
  me(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AccountDetailsDto>, callback?: msRest.ServiceCallback<Models.AccountDetailsDto>): Promise<Models.AccountMeResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      meOperationSpec,
      callback) as Promise<Models.AccountMeResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AccountProfileResponse>
   */
  profile(options?: msRest.RequestOptionsBase): Promise<Models.AccountProfileResponse>;
  /**
   * @param callback The callback
   */
  profile(callback: msRest.ServiceCallback<Models.UserProfileDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  profile(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.UserProfileDto>): void;
  profile(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.UserProfileDto>, callback?: msRest.ServiceCallback<Models.UserProfileDto>): Promise<Models.AccountProfileResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      profileOperationSpec,
      callback) as Promise<Models.AccountProfileResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateProfile(options?: Models.AccountUpdateProfileOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateProfile(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateProfile(options: Models.AccountUpdateProfileOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateProfile(options?: Models.AccountUpdateProfileOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateProfileOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const saveDashboardSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Account/dashboard",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SaveDashboardSettingsCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const saveGridSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Account/saveGridSettings",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SaveGridSettingsCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const acceptRODOOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Account/acceptRODO",
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const meOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "Account/me",
  responses: {
    200: {
      bodyMapper: Mappers.AccountDetailsDto
    },
    default: {}
  },
  serializer
};

const profileOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "Account/profile",
  responses: {
    200: {
      bodyMapper: Mappers.UserProfileDto
    },
    default: {}
  },
  serializer
};

const updateProfileOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "Account/profile",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateUserInCRUiPCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
