import { ajaxByUser, ajaxCatch } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { SubjectCommentType } from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Col, Row, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FFieldLabel,
	FRadioGroup,
	FSelect,
	FTextArea,
} from 'forms/FormikFormItems';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { AddSubjectCommentDto } from './commands/AddSubjectCommentCommand';
import { UpdateSubjectCommentDto } from './commands/UpdateSubjectCommentCommand';
import { SubjectCommentFields } from './SubjectCommentStore';

export interface SubjectCommentModalProps {
	subjectId: string;
	commentId: string | undefined;
	editable: boolean;
	visible: boolean;
	setVisible: (visible: boolean) => void;
	onSave: () => void;
	subjectSubjectId: string;
}

const CommentModal: React.FC<SubjectCommentModalProps> = (props) => {
	const {
		subjectId,
		commentId,
		visible,
		setVisible,
		onSave,
		subjectSubjectId,
	} = props;

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const [isProcessed, setIsProcessed] = useState<boolean>(false);
	const [updateModel, setUpdateModel] = useState<UpdateSubjectCommentDto>();

	useEffect(() => {
		if (commentId) {
			ajaxCatch(() =>
				getProfiBazaApiClient().then((api) => {
					return api.subjectComments
						.getById(commentId)
						.then(
							(result: ProfiBazaAPIModels.SubjectCommentDto) => {
								setIsProcessed(
									ProfiBazaAPIModels.SubjectCommentStatus
										.PROCESSED == result?.status!
								);
								setUpdateModel(
									new UpdateSubjectCommentDto(
										result,
										commentId
									)
								);
							}
						);
				})
			);
		}
	}, [commentId]);

	const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setVisible(false);
	};

	const handleCreate = (
		values: AddSubjectCommentDto,
		actions: FormikActions<ProfiBazaAPIModels.CreateSubjectCommentCommand>
	) => {
		const command = AddSubjectCommentDto.toCommand(values);
		ajaxByUser(
			'Dodano komentarz',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.subjectComments
						.create({
							body: command,
						})
						.then((response) => {
							return response;
						})
				),
			() => {
				setVisible(false);
				actions.resetForm();
				onSave();
			},
			(errors) => {
				actions.setErrors(errors);
			}
		).then(() => actions.setSubmitting(false));
	};

	const renderBody = (formikProps: FormikProps<any>) => {
		return (
			<>
				{accountDetailsStore.account.get()?.subject?.id ==
					subjectSubjectId &&
					!props.editable && (
						<CenteredRow>
							<Col span={20}>
								{isProcessed && (
									<p style={{ fontWeight: 'bold' }}>
										Komentarz rozpatrzony
									</p>
								)}
							</Col>
						</CenteredRow>
					)}
				<CenteredRow>
					<Col span={20}>
						<FFieldLabel label="Nazwa pola" />
						<Field
							label="Nazwa pola"
							component={FSelect}
							name="fieldName"
							showSearch={true}
							children={Object.entries(SubjectCommentFields).map(
								(group, i) => (
									<Select.OptGroup label={group[0]}>
										{Object.entries(group[1]).map(
											(item, i) => (
												<Select.Option
													key={item[0]}
													value={item[0]}
												>
													{item[1]}
												</Select.Option>
											)
										)}
									</Select.OptGroup>
								)
							)}
						/>
					</Col>
				</CenteredRow>
				<CenteredRow>
					<Col span={20}>
						<FFieldLabel label="Komentarz" />
						<Field
							component={FTextArea}
							label="Tutaj wpisz swój komentarz"
							name="description"
							placeholder="Tutaj wpisz swój komentarz"
							readOnly={!props.editable}
						/>
					</Col>
				</CenteredRow>
				{!props.commentId && (
					<Row>
						<Col span={2}></Col>
						<Col span={22} className="commentType">
							<FFieldLabel label="Typ komentarza" />
							<Field
								readOnly={false}
								style={{ width: '100%' }}
								defaultValue={SubjectCommentType.PUBLIC}
								values={[
									SubjectCommentType.PUBLIC,
									SubjectCommentType.PRIVATE,
								]}
								labels={[
									'Informacyjny publiczny',
									'Informacyjny w ramach podmiotu',
								]}
								component={FRadioGroup}
								name="commentType"
							/>
						</Col>
					</Row>
				)}
			</>
		);
	};

	const handleUpdate = (
		values: UpdateSubjectCommentDto,
		actions: FormikActions<UpdateSubjectCommentDto>
	) => {
		const command = UpdateSubjectCommentDto.toCommand(values);
		ajaxByUser(
			'Zaktualizowano komentarz',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.subjectComments.update({
						body: command,
					})
				),
			() => {
				setVisible(false);
				actions.resetForm();
				setUpdateModel(undefined);
				onSave();
			},
			(errors) => {
				actions.setErrors(errors);
			}
		).then(() => actions.setSubmitting(false));
	};

	const validationSchema = Yup.object().shape({
		description: Yup.string()
			.nullable()
			.required("Pole 'Komentarz' jest wymagane.")
			.max(
				1000,
				"Pole 'Komentarz' nie może być dłuższe niż 1000 znaków."
			),
		fieldName: Yup.string()
			.nullable()
			.required("Pole 'Nazwa pola' nie może być puste."),
	});

	return (
		<>
			{props.commentId === undefined ? (
				<Formik
					validateOnChange
					validateOnBlur
					initialValues={{
						subjectId: subjectId,
						fieldName: undefined,
						column: undefined,
						row: undefined,
						description: undefined,
						commentType:
							ProfiBazaAPIModels.SubjectCommentType.PUBLIC,
					}}
					validationSchema={validationSchema}
					enableReinitialize
					isInitialValid
					onSubmit={handleCreate}
					render={(
						formikProps: FormikProps<
							ProfiBazaAPIModels.CreateSubjectCommentCommand
						>
					) => (
						<Modal
							title="Dodawanie komentarza"
							visible={visible}
							onOk={(e) => {
								formikProps.submitForm();
							}}
							okButtonProps={{
								disabled: formikProps.isSubmitting,
							}}
							cancelButtonProps={{
								disabled: formikProps.isSubmitting,
							}}
							cancelText="Anuluj"
							centered
							onCancel={handleCancel}
							okText="Dodaj"
							destroyOnClose
						>
							{renderBody(formikProps)}
						</Modal>
					)}
				/>
			) : (
				<Formik
					validateOnChange
					validateOnBlur
					initialValues={updateModel || {}}
					enableReinitialize
					isInitialValid
					onSubmit={handleUpdate}
					validationSchema={validationSchema}
					render={(
						formikProps: FormikProps<UpdateSubjectCommentDto>
					) => (
						<Modal
							title={
								props.editable
									? 'Zaktualizuj komentarz'
									: 'Szczegóły komentarza'
							}
							visible={visible}
							onOk={() => {
								formikProps.submitForm();
							}}
							okButtonProps={{
								disabled: formikProps.isSubmitting,
								hidden: props.editable ? false : true,
							}}
							cancelButtonProps={{
								disabled: formikProps.isSubmitting,
							}}
							cancelText={props.editable ? 'Anuluj' : 'Zamknij'}
							centered
							onCancel={handleCancel}
							okText={'Aktualizuj'}
							destroyOnClose
						>
							{renderBody(formikProps)}
						</Modal>
					)}
				/>
			)}
		</>
	);
};

export default observer(CommentModal);
