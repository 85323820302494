import React, { useState } from 'react';
import { Col, Select, Modal, Button, Space, Row } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import { FormikProps, Formik, Field } from 'formik';
import { FFieldLabel, FInput, FSelect, FTextArea } from 'forms/FormikFormItems';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import * as ProposalStatus from '@services/mappers/ProposalStatus';
import * as ProposalAccessType from '@services/mappers/ProposalAccessType';
import { ExpandOutlined } from '@ant-design/icons';
import FilePreview from 'components/shared/file/FilePreview';
import FileDownload from 'components/shared/file/FileDownload';

interface IProps {
	isAdmin: boolean;
	visible: boolean;
	setVisible: (value: boolean) => void;
	data: ProfiBazaAPIModels.ProposalDetailsDto | undefined;
}

const ProposalDetails = (props: IProps) => {
	const [visibleFilePreview, setVisibleFilePreview] = useState<boolean>(
		false
	);
	const [isDownloading, setDownloading] = useState<boolean>(false);
	return (
		<>
			<CenteredRow>
				<Col span={18}>
					<Modal
						visible={props.visible}
						destroyOnClose
						centered
						width={'50%'}
						title={'Szczegóły'}
						onOk={() => props.setVisible(false)}
						onCancel={() => props.setVisible(false)}
						okText={'Ok'}
					>
						<Formik
							initialValues={
								{
									...props.data,
									address: `${
										props.data?.subject?.address?.street
									} ${
										props.data?.subject?.address
											?.propertyNumber
									}${
										props.data?.subject?.address
											?.placeNumber
											? '\\' +
											  props.data?.subject?.address
													?.placeNumber
											: ''
									}, ${
										props.data?.subject?.address?.zipCode
									} ${props.data?.subject?.address?.city}`,
								} || {}
							}
							enableReinitialize
							onSubmit={(value, actions) => {}}
							render={(
								formik: FormikProps<
									ProfiBazaAPIModels.ProposalDetailsDto
								>
							) => (
								<>
									<CenteredRow>
										<Col span={20}>
											<FFieldLabel label="Rodzaj wnioskowanego dostępu" />
											<Field
												label="Rodzaj wnioskowanego dostępu"
												component={FSelect}
												name="proposalAccesType"
												aria-owns="proposalAccesType"
												aria-controls="proposalAccesType"
												aria-activedescendant="proposalAccesType"
												disabled
												children={Array.from(
													ProposalAccessType.mapings.entries()
												).map((x, i) => (
													<Select.Option
														key={i}
														value={x[0]}
													>
														{x[1]}
													</Select.Option>
												))}
											/>
										</Col>
										<Col span={20}>
											<FFieldLabel label="Email" />
											<Field
												label="Email"
												readOnly
												component={FInput}
												name="email"
											/>
										</Col>
										{props.isAdmin && (
											<>
												<Col span={20}>
													<FFieldLabel label="Imię" />
													<Field
														label="Imię"
														component={FInput}
														name="firstName"
														readOnly
													/>
												</Col>
												<Col span={20}>
													<FFieldLabel label="Nazwisko" />
													<Field
														label="Nazwisko"
														component={FInput}
														name="lastName"
														readOnly
													/>
												</Col>
											</>
										)}
										<Col span={20}>
											<FFieldLabel label="Podmiot" />
											<Field
												label="Podmiot"
												component={FInput}
												name="subject.name"
												readOnly
											/>
										</Col>
										<Col span={20}>
											<FFieldLabel label="Adres" />
											<Field
												label="Adres"
												component={FInput}
												name="address"
												readOnly
											/>
										</Col>
										<Col span={20}>
											<FFieldLabel label="Status" />
											<Field
												label="Status"
												component={FSelect}
												name="status"
												children={Array.from(
													ProposalStatus.mapings.entries()
												).map((x, i) => (
													<Select.Option
														key={i}
														value={x[0]}
													>
														{x[1]}
													</Select.Option>
												))}
												disabled={true}
											/>
										</Col>
										{props.data?.status ==
											ProfiBazaAPIModels.ProposalStatus
												.Rejected && (
											<Col span={20}>
												<FFieldLabel label="Powód odrzucenia" />
												<Field
													label="Powód odrzucenia"
													component={FTextArea}
													name="rejectReason"
													disabled={true}
												/>
											</Col>
										)}
										{props.data?.fileId && (
											<Col span={20}>
												<FFieldLabel label="Załącznik" />
												<Row>
													<Space>
														<FFieldLabel
															label={
																props.data
																	?.fileName!
															}
														/>
														<FileDownload
															disabled={
																isDownloading
															}
															fileId={
																props.data
																	?.fileId!
															}
															setDisabled={(
																value
															) =>
																setDownloading(
																	value!
																)
															}
														></FileDownload>
														<Button
															aria-label="Podgląd"
															icon={
																<ExpandOutlined />
															}
															onClick={() => {
																setVisibleFilePreview(
																	true
																);
															}}
														>
															Podgląd
														</Button>
													</Space>
												</Row>
											</Col>
										)}
									</CenteredRow>
								</>
							)}
						/>
					</Modal>
				</Col>
			</CenteredRow>

			{visibleFilePreview && (
				<Modal
					visible={visibleFilePreview}
					destroyOnClose
					centered
					width={'60%'}
					title={'Podgląd pliku'}
					onOk={() => setVisibleFilePreview(false)}
					onCancel={() => setVisibleFilePreview(false)}
					okText={'Ok'}
				>
					<FilePreview fileId={props.data?.fileId!}></FilePreview>
				</Modal>
			)}
		</>
	);
};
export default ProposalDetails;
