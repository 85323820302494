import { Spin } from 'antd';
import React from 'react';

interface IProps {}

const DashboardNotepadSpinner: React.FC<IProps> = (props) => {
	return (
		<div className="spinner">
			<Spin spinning={true} />
			<span className="text">Trwa zapisywanie danych</span>
		</div>
	);
};

export default DashboardNotepadSpinner;
