import {
	DeleteOutlined,
	DownloadOutlined,
	EditOutlined,
	WarningTwoTone,
	ExpandOutlined
} from '@ant-design/icons';
import useFileTreeAccessibility from '@hooks/accessibility/useFileTreeAccessibilityHook';
import { Button, Modal, Space, Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { IDictionaryStore } from 'components/dictionary/dictionaryStore';
import DictionaryPicker from 'components/dictionary/picker/DictionaryPicker';
import { InterventionToDictionaryPathMapper } from 'components/intervention/model/map/InterventionMappers';
import { DownloadFile } from 'components/shared/file/FileDownloadFunction';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import { useRootData } from 'hooks/hook';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DictionariesConstants } from 'services/DictionariesConstants';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	DictionaryInterventionPath,
	InterventionPath,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

interface IProps {
	fileTree: ProfiBazaAPIModels.FileTreeItemDto;
	numberOfFiles: number;
	baseUrl: string;
	selectedFileId?: string;
	onFileChanged?: (isEmptyTree: boolean) => void;
	codeName: string;
	interventionPath?: InterventionPath;
	isArchive?: boolean;
}

interface IState {
	isModalVisible: boolean;
	file?: ProfiBazaAPIModels.FileTreeItemDto;
}

const FileTree: React.FC<IProps> = (props) => {
	const history = useHistory();
	const [state, setState] = useState<IState>({ isModalVisible: false });

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	useEffect(() => {
		if (props.codeName == '') return;

		dictionaryStore.initializeDictionary(
			props.codeName,
			InterventionToDictionaryPathMapper.get(props.interventionPath!) ??
				DictionaryInterventionPath.PROHEALTHACTIVITIES
		);
	}, []);

	useFileTreeAccessibility();

	const mapToDataNode = (
		node: ProfiBazaAPIModels.FileTreeItemDto
	): DataNode => {
		return {
			key: node.id!,
			title:
				node.fileType === 'FILE' ? (
					<>
						<Space className="document">
							{node.importErrorMessage && (
								<ProfiBazaTooltip
									placement="top"
									title="Załącznik nie został zaimportowany."
								>
									<WarningTwoTone
										style={{ fontSize: '18px' }}
										twoToneColor="red"
									/>
								</ProfiBazaTooltip>
							)}

							<span className="category">
								{node.fileCategoryDictionaryValue}
							</span>
							<span>{node.fileName}</span>
						</Space>

						<Space
							direction="horizontal"
							style={{ float: 'right' }}
						>
							<ProfiBazaTooltip placement="top" title="Szczegóły">
								<Button
									shape="circle"
									aria-label="Szczegóły"
									icon={<ExpandOutlined />}
									size="small"
								/>
							</ProfiBazaTooltip>
							<ProfiBazaTooltip placement="top" title="Pobierz">
								<Button
									shape="circle"
									aria-label="Pobierz"
									icon={<DownloadOutlined />}
									size="small"
									onClick={(e) => {
										ajaxCatch(() =>
											getProfiBazaApiClient()
												.then((api) =>
													api.file.download(node.id!)
												)
												.then((response) => {
													DownloadFile({
														response,
													});
												})
										);
										e.stopPropagation();
									}}
								/>
							</ProfiBazaTooltip>

							<ProfiBazaTooltip
								placement="top"
								title="Zmień kategorię"
							>
								<Button
									shape="circle"
									icon={<EditOutlined />}
									size="small"
									aria-label="Zmień kategorię"
									disabled={
										!node.canBeModified ||
										!canChangeCategory(node)
									}
									onClick={(e) => {
										setState({
											isModalVisible: true,
											file: node,
										});
										e.stopPropagation();
									}}
								/>
							</ProfiBazaTooltip>

							{node.canBeModified && !props.isArchive && (
								<AccessiblePopconfirm
									title={
										'Czy na pewno chcesz usunąć element?'
									}
									onCancel={(e) => {
										e?.stopPropagation();
									}}
									onConfirm={(e) => {
										e?.stopPropagation();
										ajaxByUser('Skasowano dokument', () =>
											getProfiBazaApiClient().then(
												(api) =>
													api.file
														.deleteMethod(node.id!)
														.then(() => {
															if (
																props.onFileChanged
															) {
																props.onFileChanged(
																	props.numberOfFiles ===
																		1
																);
															}
														})
											)
										);
									}}
								>
									<ProfiBazaTooltip
										placement="top"
										title="Usuń"
									>
										<Button
											shape="circle"
											aria-label="Usuń"
											icon={<DeleteOutlined />}
											size="small"
											type="primary"
											danger
											onClick={(e) => e.stopPropagation()}
										/>
									</ProfiBazaTooltip>
								</AccessiblePopconfirm>
							)}
						</Space>
					</>
				) : (
					`${
						node.fileName
					} (Data utworzenia: ${node.creationDate?.toLocaleString()})`
				),
			isLeaf: node.fileType === 'FILE',
			children: node.files
				?.sort(
					(a, b) =>
						b.creationDate!.getTime() - a.creationDate!.getTime()
				)
				?.map((x) => mapToDataNode(x)),
		};
	};

	const canChangeCategory = (file: ProfiBazaAPIModels.FileTreeItemDto) => {
		if (file.fileCategoryDictionaryValueId === undefined) {
			return true;
		}

		const dictionary = dictionaryStore.getDictionary(
			props.codeName,
			DictionaryInterventionPath.PROHEALTHACTIVITIES
		);

		const disallowedCategories = dictionary?.items?.filter(
			(dictionaryItem) =>
				dictionaryItem.codeName &&
				DictionariesConstants.MZ53Categories.includes(
					dictionaryItem.codeName
				)
		);

		if (
			disallowedCategories?.some(
				(x) => x.id == file.fileCategoryDictionaryValueId
			)
		) {
			return false;
		}

		return true;
	};

	const onSelect = (keys: any, event: { node: DataNode }) => {
		if (event.node.isLeaf) {
			history.push(`${props.baseUrl}/${keys[0]}`);
		}
	};

	return (
		<>
			<Tree.DirectoryTree
				className="fileTreeContainer"
				multiple
				defaultExpandAll
				icon={undefined}
				treeData={mapToDataNode(props.fileTree).children}
				onSelect={onSelect}
				selectedKeys={
					props.selectedFileId ? [props.selectedFileId] : []
				}
			></Tree.DirectoryTree>

			<Formik
				validateOnChange={true}
				validateOnBlur={false}
				initialValues={{
					id: state.file?.id,
					fileCategoryDictionaryValueId:
						state.file?.fileCategoryDictionaryValueId,
					fileCategoryDictionaryOtherValue:
						state.file?.fileCategoryDictionaryValue,
				}}
				enableReinitialize={true}
				onSubmit={(
					values: ProfiBazaAPIModels.SetFileCategoryCommand,
					actions: FormikActions<
						ProfiBazaAPIModels.SetFileCategoryCommand
					>
				) => {
					ajaxByUser(
						'Zapisano kategorię',
						() =>
							getProfiBazaApiClient().then((api) =>
								api.file.setCategory({
									body: values,
								})
							),
						() => {
							setState({
								isModalVisible: false,
								file: undefined,
							});
						},
						(errors) => {
							actions.setErrors(errors);
						}
					).then(() => {
						actions.setSubmitting(false);
						if (props.onFileChanged) {
							props.onFileChanged(false);
						}
					});
				}}
				render={(
					formikProps: FormikProps<
						ProfiBazaAPIModels.SetFileCategoryCommand
					>
				) => (
					<Modal
						visible={state.isModalVisible}
						maskClosable={false}
						centered
						title="Ustaw kategorię"
						onOk={() => {
							if (!formikProps.isSubmitting)
								formikProps.submitForm();
						}}
						onCancel={() =>
							setState({
								isModalVisible: false,
								file: undefined,
							})
						}
						okButtonProps={{ disabled: formikProps.isSubmitting }}
						cancelButtonProps={{
							disabled: formikProps.isSubmitting,
						}}
						okText="Zapisz"
					>
						<DictionaryPicker
							label="Kategoria załącznika"
							codeName={props.codeName}
							otherValue="fileCategoryDictionaryOtherValue"
							name="fileCategoryDictionaryValueId"
							interventionPath={
								InterventionToDictionaryPathMapper.get(
									props.interventionPath!
								) ??
								DictionaryInterventionPath.PROHEALTHACTIVITIES
							}
						/>
					</Modal>
				)}
			/>
		</>
	);
};

export default observer(FileTree);
