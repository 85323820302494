import * as Yup from 'yup';

const requiredMessage: string = 'Wartość jest wymagana';
const laterThanMessage: string =
	'Data końcowa musi być późniejsza od początkowej';
const noLongerThanMessage = (value: number) =>
	`Wartość nie może być dłuższa niż ${value} znaków`;

export const createDictionaryValueValidationSchema = (
	isCodeNameInValuesRequired: boolean
) =>
	Yup.object().shape({
		code: Yup.string().nullable().max(128, noLongerThanMessage(128)),
		codeName: (isCodeNameInValuesRequired
			? Yup.string().required(requiredMessage)
			: Yup.string()
		)
			.nullable()
			.max(100, noLongerThanMessage(100)),
		value: Yup.string()
			.nullable()
			.required(requiredMessage)
			.max(5000, noLongerThanMessage(5000)),
	});
