import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<
    ProfiBazaAPIModels.SubjectKind,
    string
>([
    [ProfiBazaAPIModels.SubjectKind.PSSE, 'PSSE'],
    [ProfiBazaAPIModels.SubjectKind.WSSE, 'WSSE'],
    [ProfiBazaAPIModels.SubjectKind.GSSE, 'GSSE'],
    [ProfiBazaAPIModels.SubjectKind.PZH, 'PZH'],
    [ProfiBazaAPIModels.SubjectKind.GIS, 'GIS'],
    [ProfiBazaAPIModels.SubjectKind.HOSPITAL, 'Szpital'],
    [ProfiBazaAPIModels.SubjectKind.CONTRACTOR, 'Kontrahent'],
    [ProfiBazaAPIModels.SubjectKind.WCZP, 'WCZP'],
    [ProfiBazaAPIModels.SubjectKind.DEPARTMENT, 'Dział'],
    [ProfiBazaAPIModels.SubjectKind.DISTRICT, 'Urząd Dzielnicy'],
    [ProfiBazaAPIModels.SubjectKind.EDUCATIONALUNIT, 'Jednostka oświatowa'],
    [ProfiBazaAPIModels.SubjectKind.GM, 'Gmina Miejska'],
    [ProfiBazaAPIModels.SubjectKind.GMW, 'Gmina Miejsko-Wiejska'],
    [ProfiBazaAPIModels.SubjectKind.GW, 'Gmina Wiejska'],
    [ProfiBazaAPIModels.SubjectKind.MNP, 'Miasto na pr. powiatu'],
    [ProfiBazaAPIModels.SubjectKind.SP, 'Starostwo Powiatowe'],
    [ProfiBazaAPIModels.SubjectKind.UW, 'Urząd Wojewódzki'],
    [ProfiBazaAPIModels.SubjectKind.UM, 'Urząd Marszałkowski'],
    [ProfiBazaAPIModels.SubjectKind.IC, 'Instytucja centralna'],
    [ProfiBazaAPIModels.SubjectKind.M, 'Ministerstwo'],
]);

export const map = (
    value: ProfiBazaAPIModels.SubjectKind
): string => mappings.get(value) ?? '';