import { NotificationDefinitionDTO } from '@services/src/models';
import Modal from 'antd/lib/modal/Modal';
import { NotificationDefinitionCommand } from 'components/notifications/commands/NotificationDefinitionMapper';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ajaxByUser } from 'helper/api';
import _ from 'lodash';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import EditNotificationForm from './EditNotificationForm';
import { EditNotificationValidationSchema } from './EditNotificationValidationSchema';

interface IProps {
	onCreate: () => void;
}

interface IState {
	visible: boolean;
	notificationId: string | undefined;
	loading: boolean;
	data: NotificationDefinitionDTO | undefined;
}

export interface IEditNotificationModal {
	showModal: (notificationId: string) => void;
}

const EditNotificationModal = React.forwardRef<IEditNotificationModal, IProps>(
	(props, ref) => {
		const [state, setState] = useState<IState>({
			visible: false,
			notificationId: undefined,
			loading: true,
			data: undefined,
		});

		useImperativeHandle(ref, () => ({
			showModal: (notificationId: string) => {
				setState({
					...state,
					visible: true,
					notificationId: notificationId,
				});
			},
		}));

		useEffect(() => {
			if (!state.notificationId) return;

			const abortController = new AbortController();
			const signal = abortController.signal;

			const fetchData = async () => {
				const client = await getProfiBazaApiClient();
				await client.notificationDefinition
					.getById(state.notificationId!, { abortSignal: signal })
					.then((result) => {
						setState((st) => ({
							...st,
							loading: false,
							data: result,
						}));
					});
			};

			fetchData();

			return () => {
				abortController.abort();
			};
		}, [state.notificationId]);

		return (
			<Formik
				validateOnChange={true}
				validateOnBlur={false}
				initialValues={new NotificationDefinitionCommand(state.data)}
				enableReinitialize
				validationSchema={EditNotificationValidationSchema}
				onSubmit={(
					values: NotificationDefinitionCommand,
					actions: FormikActions<
						ProfiBazaAPIModels.UpdateNotificationDefinionCommand
					>
				) => {
					const updateCommandDto = NotificationDefinitionCommand.toUpdateCommand(
						values
					);
					ajaxByUser(
						'Zaktualizowano definicję powiadomienia',
						() =>
							getProfiBazaApiClient().then((api) =>
								api.notificationDefinition.update({
									body: updateCommandDto,
								})
							),
						() => {
							setState({
								...state,
								visible: false,
								data: undefined,
								notificationId: undefined,
							});
							actions.resetForm({});
							props.onCreate();
						},
						(errors) => {
							actions.setErrors(errors);
						}
					).then(() => actions.setSubmitting(false));
				}}
				render={(
					formikProps: FormikProps<NotificationDefinitionCommand>
				) => (
					<Modal
						title="Aktualizacja definicji powiadomienia"
						closable={false}
						maskClosable={false}
						destroyOnClose
						visible={state.visible}
						centered
						onOk={() => {
							if (!formikProps.isSubmitting)
								formikProps.submitForm();
						}}
						onCancel={() => {
							setState({
								...state,
								visible: false,
								data: undefined,
								notificationId: undefined,
							});
							formikProps.resetForm({});
						}}
						okButtonProps={{ disabled: formikProps.isSubmitting }}
						cancelButtonProps={{
							disabled: formikProps.isSubmitting,
						}}
						okText="Zapisz"
						cancelText="Anuluj"
					>
						{<EditNotificationForm formikProps={formikProps} />}
					</Modal>
				)}
			/>
		);
	}
);

export default EditNotificationModal;
