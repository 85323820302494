/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import { ServiceClientOptions } from "@azure/ms-rest-js";
import * as msRest from "@azure/ms-rest-js";

/**
 * An interface representing CalendarEventDTO.
 */
export interface CalendarEventDTO {
  id?: string;
  header?: string;
  description?: string;
  dateFrom?: Date;
  dateTo?: Date;
  isUserEvent?: boolean;
}

/**
 * An interface representing ContactDto.
 */
export interface ContactDto {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  position?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly fullName?: string;
  id?: string;
}

/**
 * An interface representing DictionaryViewDto.
 */
export interface DictionaryViewDto {
  id?: number;
  codeName?: string;
  name?: string;
  description?: string;
  isHierarchical?: boolean;
  isCodeNameInValuesRequired?: boolean;
  label?: string;
}

/**
 * An interface representing DictionaryValueItemDto.
 */
export interface DictionaryValueItemDto {
  id?: number;
  code?: string;
  value?: string;
  canAddTextValue?: boolean;
  canBeSelected?: boolean;
  codeName?: string;
  /**
   * Possible values include: 'DRAFT', 'ACTIVE', 'INACTIVE', 'DELETED'
   */
  status?: DictionaryStatus;
  order?: number;
  hasChildren?: boolean;
  parentId?: number;
  validFrom?: Date;
  validTo?: Date;
  interventionPaths?: DictionaryInterventionPath[];
  breadcrumb?: string;
}

/**
 * An interface representing DictionaryOtherValueHistoryDto.
 */
export interface DictionaryOtherValueHistoryDto {
  value?: string;
  dictionaryValueId?: number;
}

/**
 * An interface representing DictionaryValueDto.
 */
export interface DictionaryValueDto {
  id?: number;
  code?: string;
  value?: string;
  codeName?: string;
  canAddTextValue?: boolean;
  canBeSelected?: boolean;
  /**
   * Possible values include: 'DRAFT', 'ACTIVE', 'INACTIVE', 'DELETED'
   */
  status?: DictionaryStatus;
  validFrom?: Date;
  validTo?: Date;
  order?: number;
  dictionaryVersionId?: number;
  parentId?: number;
  interventionPaths?: DictionaryInterventionPath[];
  breadcrumb?: string;
}

/**
 * An interface representing DictionaryValueChangeVersionDto.
 */
export interface DictionaryValueChangeVersionDto {
  oldDictionaryValueId?: number;
  newDictionaryValue?: DictionaryValueDto;
}

/**
 * An interface representing RecommendationIdNameDto.
 */
export interface RecommendationIdNameDto {
  id?: string;
  recommendationName?: string;
}

/**
 * An interface representing FormItemDto.
 */
export interface FormItemDto {
  id?: string;
  /**
   * Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53',
   * 'AOTMiTFinalReport', 'ActivityProhealthActivities', 'ActivityResearch',
   * 'ActivitySupervisionMonitoring', 'ActivitySupervisionSanitary', 'ActivityStaffEducation',
   * 'ActivityStaffMonitoring', 'ActivityLegislation', 'InterventionProhealthActivities',
   * 'InterventionResearch', 'InterventionSupervisionMonitoring',
   * 'InterventionSupervisionSanitary', 'InterventionStaffEducation',
   * 'InterventionStaffMonitoring', 'InterventionLegislation'
   */
  type?: FormType;
  description?: string;
}

/**
 * An interface representing RizpDictionaryValueDto.
 */
export interface RizpDictionaryValueDto {
  id?: number;
  otherValue?: string;
  value?: string;
  code?: string;
  codeName?: string;
  breadcrumb?: string;
}

/**
 * An interface representing ActivityListItemDto.
 */
export interface ActivityListItemDto {
  id?: string;
  dateFrom?: Date;
  dateTo?: Date;
  numberOfPersonAction?: number;
  numberOfRecipients?: number;
  activityCategory?: RizpDictionaryValueDto;
  nzpTaskNumberDictionaryValues?: RizpDictionaryValueDto[];
  primaryActivityId?: string;
  activityPatternId?: string;
  implementingCompanies?: number;
  activityNumber?: string;
}

/**
 * An interface representing ValidationWarningDto.
 */
export interface ValidationWarningDto {
  fieldName?: string;
  errors?: string[];
}

/**
 * An interface representing InterventionsAggregateDto.
 */
export interface InterventionsAggregateDto {
  terytCode?: string;
  activityCategoryCodeName?: string;
  activityCategoryValue?: string;
  year?: number;
  totalCount?: number;
}

/**
 * An interface representing NotificationDefinitionActivationDto.
 */
export interface NotificationDefinitionActivationDto {
  id?: string;
  dayDifference?: number;
}

/**
 * An interface representing NotificationDefinitionDTO.
 */
export interface NotificationDefinitionDTO {
  id?: string;
  event?: string;
  header?: string;
  content?: string;
  isActive?: boolean;
  systemChannelActive?: boolean;
  emailChannelActive?: boolean;
  sendToAll?: boolean;
  activations?: NotificationDefinitionActivationDto[];
}

/**
 * An interface representing OrbeonFormDefinition.
 */
export interface OrbeonFormDefinition {
  applicationName?: string;
  formName?: string;
  formVersion?: string;
  lastModifiedTime?: Date;
  title?: string;
}

/**
 * An interface representing PatternDigestDto.
 */
export interface PatternDigestDto {
  id?: string;
  patternName?: string;
  /**
   * Possible values include: 'PROGRAM', 'NOT_PROGRAM', 'AOTMIT_RECOMENDATION'
   */
  patternType?: InterventionPatternType;
}

/**
 * An interface representing PermissionDto.
 */
export interface PermissionDto {
  /**
   * Possible values include: 'Dictionaries_List_Preview', 'Dictionaries_Details_Preview',
   * 'Dictionaries_Versions_Modifications', 'Admin_Roles_Preview', 'Admin_Roles_Modifications',
   * 'Admin_Roles_Assignment_Global', 'Admin_Roles_Assignment_Local', 'Admin_ApiKeys_Management',
   * 'RIZP_Preview_Mine', 'RIZP_Preview_Subordinate', 'RIZP_Preview_All', 'RIZP_Modifications',
   * 'RIZP_Patterns_Modifications_Nationwide', 'RIZP_Patterns_Modifications_Voivodeship',
   * 'RIZP_Archive', 'Subjects_List_Preview', 'Subjects_Details_Preview',
   * 'Subjects_Modifications_Mine', 'Subjects_Modifications_With_Teryt_Mine',
   * 'Subjects_Modifications_All', 'Subjects_Modifications_With_Teryt_All',
   * 'Subjects_Modifications_Statement_Obligation', 'Subjects_Merge',
   * 'Subjects_Proposal_Possibility_Handling', 'Statements_Archive', 'Statements_Generation_MZ53',
   * 'Statements_Generation_MZ49', 'Statements_Generation_NPZ', 'Statement_Preview_Mine_MZ49',
   * 'Statement_Preview_Mine_MZ53', 'Statement_Preview_Mine_NPZ',
   * 'Statement_Preview_Subordinate_MZ49', 'Statement_Preview_Subordinate_MZ53',
   * 'Statement_Preview_Subordinate_NPZ', 'Statement_Preview_All_MZ49',
   * 'Statement_Preview_All_MZ53', 'Statement_Preview_All_NPZ', 'Statement_Verification_MZ49',
   * 'Statement_Verification_MZ53', 'Statement_Verification_NPZ', 'Statement_Critical_Warning',
   * 'Proposals_Handling_All', 'Proposals_Handling_Mine', 'DocumentsRepository_List_Preview',
   * 'DocumentsRepository_Preview', 'DocumentsRepository_VoivodeOpinions_Preview',
   * 'DocumentsRepository_AllVoivodeOpinions_Preview',
   * 'DocumentsRepository_VoivodeOpinions_Modifications', 'Reports_MZ_Preview',
   * 'Reports_Programy_Zdrowotne_Preview', 'Reports_AOTMiT_Preview', 'Reports_RP_Preview',
   * 'Reports_SZ_Chorobowosc_Preview', 'Reports_SZ_Umieralnosc_Preview',
   * 'Reports_SZ_Deprywacja_Preview', 'Reports_SZ_Statusy_Preview',
   * 'Reports_SZ_Demografia_Preview', 'Reports_SZ_Preview', 'Reports_All_Preview',
   * 'Reports_Technical', 'Aotmit_Add_Recommendations', 'Aotmit_Add_Comments',
   * 'Aotmit_Add_DetailedReportAnnex', 'Aotmit_Projects', 'Forms_Management',
   * 'Notifications_Management', 'UserManual_Management', 'Tooltip_Management',
   * 'Tags_Management_Global', 'Tags_Management_Local', 'Aggregate_Data', 'IKP'
   */
  permission?: Permission;
  name?: string;
}

/**
 * An interface representing RoleItemDto.
 */
export interface RoleItemDto {
  id?: string;
  name?: string;
  isActive?: boolean;
  /**
   * Possible values include: 'USER', 'ADMIN'
   */
  type?: RoleType;
  /**
   * Possible values include: 'LOCAL', 'CENTRAL'
   */
  category?: RoleCategory;
}

/**
 * An interface representing DateRangeDto.
 */
export interface DateRangeDto {
  dataOd?: Date;
  dataDo?: Date;
}

/**
 * An interface representing NpzImplementingCompanyDto.
 */
export interface NpzImplementingCompanyDto {
  realZadNazwJedn?: string;
  realZadAdrJedn?: string;
}

/**
 * An interface representing MainSponsoringSubjectDto.
 */
export interface MainSponsoringSubjectDto {
  podmFinansZadNazwPodm?: string;
  podmFinansZadUdzFinansPLN?: number;
  podmFinansZadUdzFinansProcent?: number;
  podmFinansZadZrodlFinans?: string;
}

/**
 * An interface representing OtherSponsoringSubjectDto.
 */
export interface OtherSponsoringSubjectDto {
  podmWspolFinansZadNazwPodm?: string;
  podmWspolFinansZadUdzFinansPLN?: number;
  podmWspolFinansZadUdzFinansProcent?: number;
  podmWspolFinansZadZrodlFinans?: string;
}

/**
 * An interface representing DictionaryStmtValueDto.
 */
export interface DictionaryStmtValueDto {
  identyfikatorRodzica?: string;
  identyfikator?: string;
  wartosc?: string;
  kod?: string;
}

/**
 * An interface representing PopulationDto.
 */
export interface PopulationDto {
  populacjaDocelowa?: DictionaryStmtValueDto;
  miejsceInterwencji?: DictionaryStmtValueDto[];
  stanZdrowia?: DictionaryStmtValueDto[];
  wykluczenieSpoleczne?: DictionaryStmtValueDto[];
  inneSzczegolneCechy?: DictionaryStmtValueDto[];
  plec?: DictionaryStmtValueDto;
  zakresWieku?: string;
  liczbaOdbiorcow?: number;
}

/**
 * An interface representing DictionaryStmtDto.
 */
export interface DictionaryStmtDto {
  identyfikator?: string;
  wartosci?: DictionaryStmtValueDto[];
}

/**
 * An interface representing NPZAutocompletionDto.
 */
export interface NPZAutocompletionDto {
  interwencjaId?: string;
  dzialanieId?: string;
  lp?: string;
  nazwZad?: string;
  opis?: string;
  podmRealizZadTERYT?: string;
  podmRealizZadNazw?: string;
  podmRealizZadAdrPodm?: string;
  czasTrwZad?: DateRangeDto;
  terRealizZad?: string[];
  popObjZadLiczbaOsObjZad?: number;
  realZad?: NpzImplementingCompanyDto[];
  calkKosztZad?: number;
  uwagi?: string;
  podmFinansZad?: MainSponsoringSubjectDto;
  podmWspolFinansZad?: OtherSponsoringSubjectDto[];
  temat?: string;
  popObjZadCharPop?: PopulationDto[];
  slowniki?: DictionaryStmtDto[];
}

/**
 * An interface representing StatementPartDto.
 */
export interface StatementPartDto {
  applicationName?: string;
  formName?: string;
  documentId?: string;
}

/**
 * An interface representing SubjectIdNameDto.
 */
export interface SubjectIdNameDto {
  id?: string;
  name?: string;
}

/**
 * An interface representing SubjectsAggregateDto.
 */
export interface SubjectsAggregateDto {
  terc?: string;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  totalCount?: number;
}

/**
 * An interface representing UserManualDto.
 */
export interface UserManualDto {
  id?: string;
  fileName?: string;
  /**
   * Possible values include: 'Main', 'Administrative', 'Intervention', 'Statement', 'Subject',
   * 'RODO'
   */
  manualType?: UserManualType;
  /**
   * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
   * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
   */
  interventionPath?: UserManualInterventionPath;
  /**
   * Possible values include: 'Global', 'Local'
   */
  userManualAdministrativeType?: UserManualAdministrativeType;
  /**
   * Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53',
   * 'AOTMiTFinalReport', 'ActivityProhealthActivities', 'ActivityResearch',
   * 'ActivitySupervisionMonitoring', 'ActivitySupervisionSanitary', 'ActivityStaffEducation',
   * 'ActivityStaffMonitoring', 'ActivityLegislation', 'InterventionProhealthActivities',
   * 'InterventionResearch', 'InterventionSupervisionMonitoring',
   * 'InterventionSupervisionSanitary', 'InterventionStaffEducation',
   * 'InterventionStaffMonitoring', 'InterventionLegislation'
   */
  reportType?: UserManualStatetmentReportType;
  formVersionId?: string;
  formVersion?: string;
  formValidFrom?: Date;
  /**
   * Possible values include: 'ProposalAccess', 'CRUIP'
   */
  rodoManualType?: RODOManualType;
}

/**
 * An interface representing UserNotificationSettingsDTO.
 */
export interface UserNotificationSettingsDTO {
  notificationDefinitionId?: string;
  event?: string;
  isActive?: boolean;
  systemChannelActive?: boolean;
  emailChannelActive?: boolean;
  definitionSystemChannelActive?: boolean;
  definitionEmailChannelActive?: boolean;
}

/**
 * An interface representing SaveDashboardSettingsCommand.
 */
export interface SaveDashboardSettingsCommand {
  showInterventions?: boolean;
  showStatements?: boolean;
  notepadContent?: string;
}

/**
 * An interface representing SaveGridSettingsCommand.
 */
export interface SaveGridSettingsCommand {
  gridName?: string;
  pageSize?: number;
  visibleColumnKeys?: string[];
}

/**
 * An interface representing GridSettingsDto.
 */
export interface GridSettingsDto {
  gridName?: string;
  pageSize?: number;
  visibleColumnKeys?: string[];
}

/**
 * An interface representing DashboardSettingsDto.
 */
export interface DashboardSettingsDto {
  showInterventions?: boolean;
  showStatements?: boolean;
  notepadContent?: string;
}

/**
 * An interface representing Subject.
 */
export interface Subject {
  id?: string;
  codeName?: string;
  name?: string;
  regon?: string;
  permissions?: Permission[];
}

/**
 * An interface representing AccountDetailsDto.
 */
export interface AccountDetailsDto {
  id?: string;
  firstName?: string;
  lastName?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly displayName?: string;
  existsInProfiBaza?: boolean;
  permissions?: Permission[];
  gridSettings?: { [propertyName: string]: GridSettingsDto };
  dashboardSettings?: DashboardSettingsDto;
  subject?: Subject;
  acceptedRODO?: boolean;
}

/**
 * An interface representing UserProfileDto.
 */
export interface UserProfileDto {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isFromCRUiP?: boolean;
}

/**
 * An interface representing UpdateUserInCRUiPCommand.
 */
export interface UpdateUserInCRUiPCommand {
  id?: string;
  firstName?: string;
  lastName?: string;
}

/**
 * An interface representing RizpDictionaryDto.
 */
export interface RizpDictionaryDto {
  id?: number;
  otherValue?: string;
}

/**
 * An interface representing ActivityPopulationValueDto.
 */
export interface ActivityPopulationValueDto {
  id?: string;
  activityPopulationPatternId?: string;
  genderDictionaryValueId?: number;
  genderDictionaryOtherValue?: string;
  genderDictionaryValue?: string;
  targetPopulationDictionaryValueId?: number;
  targetPopulationDictionaryOtherValue?: string;
  targetPopulationDictionaryValue?: string;
  minAge?: number;
  maxAge?: number;
  /**
   * Possible values include: 'Years', 'Weeks'
   */
  ageUnit?: AgeUnit;
  totalRecipients?: number;
  interventionLocations?: RizpDictionaryValueDto[];
  healthConditions?: RizpDictionaryDto[];
  socialExclusions?: RizpDictionaryDto[];
  otherSpecialFeatures?: RizpDictionaryValueDto[];
}

/**
 * An interface representing ImplementingCompanyDto.
 */
export interface ImplementingCompanyDto {
  subjectId?: string;
  subjectName?: string;
  number?: number;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: RIZPSubjectKind;
}

/**
 * An interface representing FinancingSourceDto.
 */
export interface FinancingSourceDto {
  financingSourceDictionaryValueId?: number;
  financingSourceDictionaryValue?: string;
  financingSourceDictionaryOtherValue?: string;
}

/**
 * An interface representing SponsoringCompanyValueDto.
 */
export interface SponsoringCompanyValueDto {
  id?: string;
  subjectId?: string;
  plannedCosts?: number;
  realizationCosts?: number;
  financingSourceDictionaryValues?: FinancingSourceDto[];
  subjectName?: string;
  isMainSubject?: boolean;
}

/**
 * An interface representing IkpDto.
 */
export interface IkpDto {
  id?: string;
  recrutationDateFrom?: Date;
  recrutationDateTo?: Date;
  includeCriteria?: string;
  excludeCriteria?: string;
  benefits?: string;
  sourceOfKnowledge?: string;
  ikpStateDictionaryValueId?: number;
  ikpStateDictionaryOtherValue?: string;
  ikpStateDictionaryValue?: string;
}

/**
 * An interface representing ActivityDetailsDto.
 */
export interface ActivityDetailsDto {
  id?: string;
  activityNumber?: string;
  name?: string;
  topic?: string;
  nzpTaskNumberDictionaryValues?: RizpDictionaryValueDto[];
  activityCategory?: RizpDictionaryValueDto;
  prpzDictionaryValues?: RizpDictionaryValueDto[];
  publicHealthTaskDictionaryValues?: RizpDictionaryValueDto[];
  comment?: string;
  description?: string;
  numberOfPersonAction?: number;
  numberOfRecipients?: number;
  dateFrom?: Date;
  dateTo?: Date;
  updatedAt?: Date;
  primaryActivityId?: string;
  formVersionId?: string;
  documentId?: string;
  activityPopulations?: ActivityPopulationValueDto[];
  implementingCompanies?: ImplementingCompanyDto[];
  sponsoringCompanies?: SponsoringCompanyValueDto[];
  activityPatternId?: string;
  careAreasDictionaryValues?: RizpDictionaryValueDto[];
  chosenPopulationHealthsDictionaryValues?: RizpDictionaryValueDto[];
  diseasesMortalityPrevalencesDictionaryValues?: RizpDictionaryValueDto[];
  ikp?: IkpDto;
}

/**
 * An interface representing ActivityPopulationDto.
 */
export interface ActivityPopulationDto {
  id?: string;
  genderDictionaryValueId?: number;
  genderDictionaryOtherValue?: string;
  targetPopulationDictionaryValueId?: number;
  targetPopulationDictionaryOtherValue?: string;
  minAge?: number;
  maxAge?: number;
  /**
   * Possible values include: 'Years', 'Weeks'
   */
  ageUnit?: AgeUnit;
  totalRecipients?: number;
  interventionLocations?: RizpDictionaryDto[];
  healthConditions?: RizpDictionaryDto[];
  socialExclusions?: RizpDictionaryDto[];
  otherSpecialFeatures?: RizpDictionaryDto[];
}

/**
 * An interface representing SponsoringCompanyDto.
 */
export interface SponsoringCompanyDto {
  id?: string;
  subjectId?: string;
  plannedCosts?: number;
  realizationCosts?: number;
  financingSourceDictionaryValues?: FinancingSourceDto[];
  isMainSubject?: boolean;
}

/**
 * An interface representing IntervetionActivityDto.
 */
export interface IntervetionActivityDto {
  id?: string;
  name?: string;
  topic?: string;
  activityNumber?: string;
  comment?: string;
  description?: string;
  nzpTaskNumberDictionaryValues?: RizpDictionaryDto[];
  activityCategoryDictionaryValueId?: number;
  activityCategoryDictionaryOtherValue?: string;
  prpzDictionaryValues?: RizpDictionaryDto[];
  publicHealthTaskDictionaryValues?: RizpDictionaryDto[];
  activityPopulations?: ActivityPopulationDto[];
  implementingCompanies?: ImplementingCompanyDto[];
  sponsoringCompanies?: SponsoringCompanyDto[];
  numberOfPersonAction?: number;
  numberOfRecipients?: number;
  dateFrom?: Date;
  dateTo?: Date;
  primaryActivityId?: string;
  chosenPopulationHealthsDictionaryValues?: RizpDictionaryDto[];
  careAreasDictionaryValues?: RizpDictionaryDto[];
  diseasesMortalityPrevalencesDictionaryValues?: RizpDictionaryDto[];
  ikp?: IkpDto;
}

/**
 * An interface representing CreateActivityCommand.
 */
export interface CreateActivityCommand {
  interventionId?: string;
  activity?: IntervetionActivityDto;
}

/**
 * An interface representing GuidIIdDto.
 */
export interface GuidIIdDto {
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly id?: string;
}

/**
 * An interface representing UpdateActivityCommand.
 */
export interface UpdateActivityCommand {
  interventionId?: string;
  activity?: IntervetionActivityDto;
}

/**
 * An interface representing ProjectRegisteredCommand.
 */
export interface ProjectRegisteredCommand {
  interventionId?: string;
  aotmitNumber?: string;
  aotmitCreatorUrl?: string;
}

/**
 * An interface representing SetStatusCommand.
 */
export interface SetStatusCommand {
  interventionId?: string;
  aotMiTStatus?: string;
}

/**
 * An interface representing AOTMiTFile.
 */
export interface AOTMiTFile {
  fileName?: string;
  fileExtension?: string;
  mimeType?: string;
  data?: Uint8Array;
  /**
   * Possible values include: 'REPORT', 'OPINION_RP', 'OPINION_PRESIDENT', 'FINAL_REPORT'
   */
  fileCategory?: FileCategoryAOTMiT;
}

/**
 * An interface representing OpinionAOTMiTCommand.
 */
export interface OpinionAOTMiTCommand {
  interventionId?: string;
  /**
   * Possible values include: 'POSITIVE', 'NEGATIVE', 'CONDITIONALLY_POSITIVE',
   * 'NOT_SUBJECT_TO_OPINION', 'FINAL_REPORT'
   */
  opinion?: OpinionAOTMiTEnum;
  files?: AOTMiTFile[];
}

/**
 * An interface representing FileDto.
 */
export interface FileDto {
  fileName?: string;
  fileExtension?: string;
  mimeType?: string;
  data?: Uint8Array;
  rootDirectoryId?: string;
  sourceDocumentId?: string;
}

/**
 * An interface representing AddRecommendationsCommand.
 */
export interface AddRecommendationsCommand {
  recommendationId?: string;
  name?: string;
  validFrom?: Date;
  validTo?: Date;
  recommendationFile?: FileDto;
  transparencyCouncilOpinion?: FileDto;
  analyticalReport?: FileDto;
}

/**
 * An interface representing AddDetailedReportAnnexCommand.
 */
export interface AddDetailedReportAnnexCommand {
  detailedReportAnnexId?: string;
  name?: string;
  validFrom?: Date;
  validTo?: Date;
  file?: FileDto;
}

/**
 * An interface representing AOTMiTSubjectAddressDto.
 */
export interface AOTMiTSubjectAddressDto {
  id?: string;
  zipCode?: string;
  isAddressManual?: boolean;
  simcCode?: string;
  tercCode?: string;
  streetCode?: string;
  city?: string;
  propertyNumber?: string;
  placeNumber?: string;
  street?: string;
  voivodeship?: string;
  county?: string;
  community?: string;
}

/**
 * An interface representing SubjectContactDto.
 */
export interface SubjectContactDto {
  id?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  email?: string;
  phoneNumber?: string;
  connectedWithIkpRecrutation?: boolean;
}

/**
 * An interface representing AOTMiTPopulationDto.
 */
export interface AOTMiTPopulationDto {
  gender?: string;
  targetPopulation?: string;
  interventionLocation?: string[];
  healthCondition?: string[];
  socialExclusion?: string[];
  otherSpecialFeature?: string[];
  minAge?: number;
  maxAge?: number;
  /**
   * Possible values include: 'Years', 'Weeks'
   */
  ageUnit?: AgeUnit;
}

/**
 * An interface representing ActivityAOTMiTDto.
 */
export interface ActivityAOTMiTDto {
  description?: string;
  dateFrom?: Date;
  dateTo?: Date;
}

/**
 * An interface representing AOTMiTBaseFile.
 */
export interface AOTMiTBaseFile {
  fileName?: string;
  fileExtension?: string;
  mimeType?: string;
  data?: Uint8Array;
}

/**
 * An interface representing AOTMiTCreatorRequest.
 */
export interface AOTMiTCreatorRequest {
  interventionId?: string;
  aotMiTNumber?: string;
  name?: string;
  address?: AOTMiTSubjectAddressDto;
  contacts?: SubjectContactDto[];
  plannedDateFrom?: Date;
  plannedDateTo?: Date;
  populations?: AOTMiTPopulationDto[];
  plannedPopulation?: number;
  plannedPopulationYear?: number;
  activities?: ActivityAOTMiTDto[];
  totalPalannedYearCost?: number;
  totalPlannedCost?: number;
  financingSources?: string[];
  chosenPopulationHealths?: string[];
  careAreas?: string[];
  diseasesMortalityPrevalences?: string[];
  files?: AOTMiTBaseFile[];
}

/**
 * An interface representing GenerateApiKeyCommand.
 */
export interface GenerateApiKeyCommand {
  subjectId?: string;
}

/**
 * An interface representing ApiKeyDto.
 */
export interface ApiKeyDto {
  subjectId?: string;
  apiKey?: string;
}

/**
 * An interface representing RegenerateApiKeyCommand.
 */
export interface RegenerateApiKeyCommand {
  subjectId?: string;
}

/**
 * An interface representing ISubject.
 */
export interface ISubject {
  id?: string;
  codeName?: string;
  name?: string;
  regon?: string;
  permissions?: Permission[];
}

/**
 * An interface representing CreateDictionaryCommand.
 */
export interface CreateDictionaryCommand {
  codeName?: string;
  name?: string;
  isHierarchical?: boolean;
  isCodeNameInValuesRequired?: boolean;
  label?: string;
}

/**
 * An interface representing Int32IIdDto.
 */
export interface Int32IIdDto {
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly id?: number;
}

/**
 * An interface representing UpdateDictionaryCommand.
 */
export interface UpdateDictionaryCommand {
  id?: number;
  name?: string;
  description?: string;
  label?: string;
}

/**
 * An interface representing DictionaryViewDtoPagedResult.
 */
export interface DictionaryViewDtoPagedResult {
  results?: DictionaryViewDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing DictionaryVersionItemDto.
 */
export interface DictionaryVersionItemDto {
  id?: number;
  validFrom?: Date;
  validTo?: Date;
  /**
   * Possible values include: 'DRAFT', 'ACTIVE', 'INACTIVE', 'DELETED'
   */
  status?: DictionaryStatus;
  lastStatusModifiedDate?: Date;
  createdAt?: Date;
  version?: string;
  interventionPaths?: DictionaryInterventionPath[];
}

/**
 * An interface representing DictionaryDto.
 */
export interface DictionaryDto {
  id?: number;
  codeName?: string;
  name?: string;
  description?: string;
  isHierarchical?: boolean;
  isCodeNameInValuesRequired?: boolean;
  label?: string;
  versions?: DictionaryVersionItemDto[];
}

/**
 * An interface representing DictionaryInfoDto.
 */
export interface DictionaryInfoDto {
  dictionaryId?: number;
  isHierarchical?: boolean;
  items?: DictionaryValueItemDto[];
}

/**
 * An interface representing DictionaryValueWithPathViewDto.
 */
export interface DictionaryValueWithPathViewDto {
  id?: number;
  value?: string;
  path?: string;
  pathIds?: string;
  parentId?: number;
  dictionaryVersionId?: number;
  level?: number;
}

/**
 * An interface representing DictionaryValueWithPathViewDtoPagedResult.
 */
export interface DictionaryValueWithPathViewDtoPagedResult {
  results?: DictionaryValueWithPathViewDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing CreateDictionaryValueCommand.
 */
export interface CreateDictionaryValueCommand {
  dictionaryVersionId?: number;
  code?: string;
  codeName?: string;
  value?: string;
  canAddTextValue?: boolean;
  canBeSelected?: boolean;
  parentId?: number;
  interventionPaths?: DictionaryInterventionPath[];
}

/**
 * An interface representing UpdateDictionaryValueCommand.
 */
export interface UpdateDictionaryValueCommand {
  id?: number;
  code?: string;
  codeName?: string;
  value?: string;
  canAddTextValue?: boolean;
  canBeSelected?: boolean;
  interventionPaths?: DictionaryInterventionPath[];
}

/**
 * An interface representing SetOrderValueCommand.
 */
export interface SetOrderValueCommand {
  id?: number;
  order?: number;
}

/**
 * An interface representing UpdateDictionaryValueParentCommand.
 */
export interface UpdateDictionaryValueParentCommand {
  id?: number;
  parentId?: number;
}

/**
 * An interface representing CheckDictionaryValueChangeVersionQuery.
 */
export interface CheckDictionaryValueChangeVersionQuery {
  /**
   * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
   * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
   */
  interventionPaths?: DictionaryInterventionPath;
  dateFrom?: Date;
  dateTo?: Date;
  dictionaryValueIds?: number[];
}

/**
 * An interface representing CreateDictionaryVersionCommand.
 */
export interface CreateDictionaryVersionCommand {
  dictionaryId?: number;
  validFrom?: Date;
}

/**
 * An interface representing UpdateDictionaryVersionCommand.
 */
export interface UpdateDictionaryVersionCommand {
  id?: number;
  validFrom?: Date;
}

/**
 * An interface representing ActivateDictionaryVersionCommand.
 */
export interface ActivateDictionaryVersionCommand {
  id?: number;
}

/**
 * An interface representing DeactivateDictionaryVersionCommand.
 */
export interface DeactivateDictionaryVersionCommand {
  id?: number;
}

/**
 * An interface representing DictionaryVersionDto.
 */
export interface DictionaryVersionDto {
  id?: number;
  dictionaryId?: number;
  dictionaryName?: string;
  validFrom?: Date;
  validTo?: Date;
  version?: string;
  /**
   * Possible values include: 'DRAFT', 'ACTIVE', 'INACTIVE', 'DELETED'
   */
  status?: DictionaryStatus;
  lastStatusModifiedDate?: Date;
  createdAt?: Date;
}

/**
 * An interface representing CloneDictionaryVersionCommand.
 */
export interface CloneDictionaryVersionCommand {
  dictionaryVersionId?: number;
}

/**
 * An interface representing DocumentListItemDto.
 */
export interface DocumentListItemDto {
  id?: string;
  /**
   * Possible values include: 'OTHER', 'AOTMiT', 'INTERVENTION', 'MZ_49', 'MZ_53', 'NPZ', 'SUBJECT'
   */
  source?: DocumentSource;
  /**
   * Possible values include: 'Attachment', 'Statement', 'VoivodeOpinion'
   */
  category?: DocumentCategory;
  /**
   * Possible values include: 'ACCEPTED', 'COMPLETED', 'IN_VERIFICATION', 'DUPLICATE',
   * 'TO_COMPLETE', 'TO_IMPROVE', 'INCORRECT', 'ARCHIVED', 'REJECTED', 'RECLOSED', 'NEW',
   * 'DELETED', 'DRAFT', 'PLANNED', 'SUSPENDED', 'REALIZATION', 'PATTERN', 'CLOSED', 'CANCELED',
   * 'SENDED_TO_OPINION_AOTMIT', 'COMPLETED_UNREALIZED', 'COMPLETED_REALIZED', 'MERGED',
   * 'MODIFIED', 'ADDED'
   */
  status?: DocumentStatus;
  createdDate?: Date;
  year?: number;
  county?: string;
  voivodeship?: string;
  commune?: string;
  subjectName?: string;
  fileName?: string;
  programYearFrom?: number;
  programYearTo?: number;
  decisionProblem?: string;
  presidentOpinionKind?: string;
  opinionPresidentYear?: number;
  /**
   * Possible values include: 'MZ', 'JST'
   */
  documentSubjectKind?: DocumentSubjectKind;
  number?: string;
  fileId?: string;
  fileType?: string;
  dateFrom?: Date;
  dateTo?: Date;
}

/**
 * An interface representing DocumentListItemDtoPagedResult.
 */
export interface DocumentListItemDtoPagedResult {
  results?: DocumentListItemDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing FileTreeItemDto.
 */
export interface FileTreeItemDto {
  id?: string;
  fileName?: string;
  fileType?: string;
  creationDate?: Date;
  fileCategoryDictionaryValueId?: number;
  fileCategoryDictionaryValue?: string;
  fileCategoryDictionaryOrder?: number;
  canBeModified?: boolean;
  importErrorMessage?: string;
  files?: FileTreeItemDto[];
}

/**
 * An interface representing AotmitDetailsDto.
 */
export interface AotmitDetailsDto {
  fileName?: string;
  year?: number;
  voivodeship?: string;
  county?: string;
  programYearFrom?: number;
  programYearTo?: number;
  decisionProblem?: string;
  presidentOpinionKind?: string;
  opinionPresidentYear?: number;
  /**
   * Possible values include: 'MZ', 'JST'
   */
  documentSubjectKind?: DocumentSubjectKind;
  subjectName?: string;
  number?: string;
}

/**
 * An interface representing DocumentDetailsDto.
 */
export interface DocumentDetailsDto {
  name?: string;
  status?: string;
  subjectName?: string;
  voivodeship?: string;
  county?: string;
  commune?: string;
  dateFrom?: Date;
  dateTo?: Date;
  createdDate?: Date;
  opinionPresidentYear?: number;
  number?: string;
  processKind?: string;
  year?: number;
}

/**
 * An interface representing DocumentDto.
 */
export interface DocumentDto {
  createdDate?: Date;
  /**
   * Possible values include: 'Added', 'Downloaded', 'Deleted'
   */
  actionKind?: ActionKind;
  subjectName?: string;
  createdBy?: string;
  /**
   * Possible values include: 'Form', 'External_System', 'External_System_PZH'
   */
  channelKind?: ChannelKind;
  fileId?: string;
  file?: FileTreeItemDto;
  numberOfFiles?: number;
  categoryCodeName?: string;
  tags?: string[];
  /**
   * Possible values include: 'PPZ', 'Recommendation', 'DetailedReportAnnex', 'FinalReport',
   * 'Pronouncement'
   */
  documentAotmitType?: DocumentAotmitType;
  aotmitDetails?: AotmitDetailsDto;
  details?: DocumentDetailsDto;
  isVoivodeOpinion?: boolean;
  isArchive?: boolean;
  dateFrom?: Date;
  dateTo?: Date;
  number?: string;
  opinionPresidentYear?: number;
}

/**
 * An interface representing AddSourceDocumentTagCommand.
 */
export interface AddSourceDocumentTagCommand {
  sourceDocumentId?: string;
  tagName?: string;
}

/**
 * An interface representing DeleteSourceDocumentTagCommand.
 */
export interface DeleteSourceDocumentTagCommand {
  sourceDocumentId?: string;
  tagName?: string;
}

/**
 * An interface representing ProblemDetails.
 */
export interface ProblemDetails {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  /**
   * Describes unknown properties. The value of an unknown property can be of "any" type.
   */
  [property: string]: any;
}

/**
 * An interface representing SetFileCategoryCommand.
 */
export interface SetFileCategoryCommand {
  id?: string;
  fileCategoryDictionaryValueId?: number;
  fileCategoryDictionaryOtherValue?: string;
}

/**
 * An interface representing FormVersionItemDto.
 */
export interface FormVersionItemDto {
  id?: string;
  createdAt?: Date;
  validFrom?: Date;
  validTo?: Date;
  /**
   * Possible values include: 'DRAFT', 'ACTIVE', 'INACTIVE', 'DELETED'
   */
  status?: FormStatus;
  version?: string;
  xsltDefinition?: string;
  xlsxDefinition?: Uint8Array;
}

/**
 * An interface representing FormDto.
 */
export interface FormDto {
  id?: string;
  /**
   * Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53',
   * 'AOTMiTFinalReport', 'ActivityProhealthActivities', 'ActivityResearch',
   * 'ActivitySupervisionMonitoring', 'ActivitySupervisionSanitary', 'ActivityStaffEducation',
   * 'ActivityStaffMonitoring', 'ActivityLegislation', 'InterventionProhealthActivities',
   * 'InterventionResearch', 'InterventionSupervisionMonitoring',
   * 'InterventionSupervisionSanitary', 'InterventionStaffEducation',
   * 'InterventionStaffMonitoring', 'InterventionLegislation'
   */
  type?: FormType;
  description?: string;
  versions?: FormVersionItemDto[];
}

/**
 * An interface representing FormPartDto.
 */
export interface FormPartDto {
  applicationName?: string;
  formName?: string;
  order?: number;
  label?: string;
  id?: string;
  /**
   * Possible values include: 'Landscape', 'Portrait'
   */
  pageOrientation?: PageOrientation;
  isVisibleInViewModel?: boolean;
}

/**
 * An interface representing CreateFormPartCommand.
 */
export interface CreateFormPartCommand {
  formVersionId?: string;
  applicationName?: string;
  formName?: string;
  label?: string;
  /**
   * Possible values include: 'Landscape', 'Portrait'
   */
  pageOrientation?: PageOrientation;
  isVisibleInViewModel?: boolean;
}

/**
 * An interface representing UpdateFormPartCommand.
 */
export interface UpdateFormPartCommand {
  id?: string;
  label?: string;
  /**
   * Possible values include: 'Landscape', 'Portrait'
   */
  pageOrientation?: PageOrientation;
  isVisibleInViewModel?: boolean;
}

/**
 * An interface representing SetOrderFormPartCommand.
 */
export interface SetOrderFormPartCommand {
  id?: string;
  order?: number;
}

/**
 * An interface representing FormVersionDto.
 */
export interface FormVersionDto {
  id?: string;
  version?: string;
  xsltDefinition?: string;
  xlsxDefinition?: Uint8Array;
  parts?: FormPartDto[];
  form?: FormDto;
  validFrom?: Date;
  validTo?: Date;
}

/**
 * An interface representing ActivateFormVersionCommand.
 */
export interface ActivateFormVersionCommand {
  id?: string;
}

/**
 * An interface representing DeactivateFormVersionCommand.
 */
export interface DeactivateFormVersionCommand {
  id?: string;
}

/**
 * An interface representing CloneFormVersionCommand.
 */
export interface CloneFormVersionCommand {
  formVersionId?: string;
}

/**
 * An interface representing DictionaryValueIKPDto.
 */
export interface DictionaryValueIKPDto {
  codeName?: string;
  value?: string;
}

/**
 * An interface representing SubjectIKPDto.
 */
export interface SubjectIKPDto {
  name?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  voivodeship?: string;
}

/**
 * An interface representing PopulationIKPDto.
 */
export interface PopulationIKPDto {
  minAge?: number;
  maxAge?: number;
  /**
   * Possible values include: 'Years', 'Weeks'
   */
  ageUnit?: AgeUnit;
  gender?: DictionaryValueIKPDto;
  targetPopulations?: DictionaryValueIKPDto[];
  interventionLocations?: DictionaryValueIKPDto[];
  healthConditions?: DictionaryValueIKPDto[];
  socialExclusions?: DictionaryValueIKPDto[];
  otherSpecialFeatures?: DictionaryValueIKPDto[];
  numberOfRecipients?: number;
}

/**
 * An interface representing ActivityIKPDto.
 */
export interface ActivityIKPDto {
  number?: string;
  name?: string;
  dateFrom?: Date;
  dateTo?: Date;
  category?: DictionaryValueIKPDto;
  description?: string;
  healthProblems?: DictionaryValueIKPDto[];
  populations?: PopulationIKPDto[];
  implementingSubjects?: SubjectIKPDto[];
  recruitmentDateFrom?: Date;
  recruitmentDateTo?: Date;
  inclusionCriteria?: string;
  exclusionCriteria?: string;
  benefits?: string;
  programKnowledgeSource?: string;
  recruitmentStatus?: DictionaryValueIKPDto;
}

/**
 * An interface representing ContactIKPDto.
 */
export interface ContactIKPDto {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

/**
 * An interface representing PublicHealthInterventionIKPDto.
 */
export interface PublicHealthInterventionIKPDto {
  codeName?: string;
  area?: DictionaryValueIKPDto;
  owner?: SubjectIKPDto;
  name?: string;
  areaTercCodes?: string[];
  legalBases?: DictionaryValueIKPDto[];
  activities?: ActivityIKPDto[];
  contacts?: ContactIKPDto[];
}

/**
 * An interface representing PublicHealthInterventionIKPDtoPagedResult.
 */
export interface PublicHealthInterventionIKPDtoPagedResult {
  results?: PublicHealthInterventionIKPDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing AnnualNumberOfRecipientsDto.
 */
export interface AnnualNumberOfRecipientsDto {
  year?: number;
  numberOfRecipients?: number;
}

/**
 * An interface representing InterventionCoownerDto.
 */
export interface InterventionCoownerDto {
  id?: string;
  name?: string;
}

/**
 * An interface representing InterventionPartnerDto.
 */
export interface InterventionPartnerDto {
  subjectId?: string;
  subjectName?: string;
  number?: number;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: RIZPSubjectKind;
}

/**
 * An interface representing PublicHealthInterventionModificationDto.
 */
export interface PublicHealthInterventionModificationDto {
  userId?: string;
  userFullname?: string;
  publicHealthInterventionId?: string;
}

/**
 * An interface representing AreaValueDto.
 */
export interface AreaValueDto {
  terytCode?: string;
  locality?: string;
  county?: string;
  voivodeship?: string;
  community?: string;
  area?: string;
}

/**
 * An interface representing CoProducerRoleDto.
 */
export interface CoProducerRoleDto {
  roleDictionaryValueId?: number;
  roleDictionaryOtherValue?: string;
  roleDictionaryValue?: string;
}

/**
 * An interface representing CoProducerValueDto.
 */
export interface CoProducerValueDto {
  id?: string;
  subjectId?: string;
  subjectName?: string;
  roles?: CoProducerRoleDto[];
}

/**
 * An interface representing InterventionDetailsDto.
 */
export interface InterventionDetailsDto {
  id?: string;
  plannedDateFrom?: Date;
  plannedDateTo?: Date;
  createdAt?: Date;
  codeName?: string;
  name?: string;
  contractorsQty?: number;
  modifiedAt?: Date;
  aotmitNumber?: string;
  aotmitStatus?: string;
  linkToWizard?: string;
  dateOfDecisionAOTMiT?: Date;
  /**
   * Possible values include: 'POSITIVE', 'NEGATIVE', 'CONDITIONALLY_POSITIVE',
   * 'NOT_SUBJECT_TO_OPINION', 'FINAL_REPORT'
   */
  opinionAOTMiT?: OpinionAOTMiTType;
  presidentAotmitNumber?: string;
  /**
   * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
   * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
   */
  interventionPath?: InterventionPath;
  interventionTypeDictionaryValueId?: number;
  interventionTypeDictionaryOtherValue?: string;
  interventionTypeDictionaryValue?: string;
  interventionTypeDictionaryCode?: string;
  /**
   * Possible values include: 'DRAFT', 'PLANNED', 'SUSPENDED', 'COMPLETED_REALIZED',
   * 'COMPLETED_UNREALIZED', 'ARCHIVED', 'REALIZATION', 'DELETED', 'CANCELED',
   * 'SENDED_TO_OPINION_AOTMIT'
   */
  status?: PublicHealthInterventionStatus;
  subjectId?: string;
  subjectName?: string;
  recommendationTypeDictionaryValueId?: number;
  recommendationTypeDictionaryOtherValue?: string;
  interventionAreaDictionaryValueId?: number;
  interventionAreaDictionaryOtherValue?: string;
  interventionAreaDictionaryValue?: string;
  implementationPeriodDictionaryValueId?: number;
  implementationPeriodDictionaryOtherValue?: string;
  implementationPeriodDictionaryValue?: string;
  plannedBudget?: number;
  realizationBudget?: number;
  realizationDateFrom?: Date;
  realizationDateTo?: Date;
  numberOfPersonAction?: number;
  numberOfRecipients?: number;
  annualNumberOfRecipients?: AnnualNumberOfRecipientsDto[];
  patternId?: string;
  interventionCoowners?: InterventionCoownerDto[];
  interventionPartners?: InterventionPartnerDto[];
  modifications?: PublicHealthInterventionModificationDto[];
  legalBases?: RizpDictionaryValueDto[];
  contacts?: SubjectContactDto[];
  areas?: AreaValueDto[];
  dataSourceDictionaryValues?: RizpDictionaryValueDto[];
  interventionEffectDictionaryValues?: RizpDictionaryValueDto[];
  interventionKindDictionaryValues?: RizpDictionaryValueDto[];
  ncnClassificationDictionaryValues?: RizpDictionaryValueDto[];
  gBaordClassificationDictionaryValues?: RizpDictionaryValueDto[];
  disciplineAreaClassificationDictionaryValues?: RizpDictionaryValueDto[];
  educationImplementationModeDictionaryValueId?: number;
  educationImplementationModeDictionaryOtherValue?: string;
  educationImplementationModeDictionaryValue?: string;
  coProducers?: CoProducerValueDto[];
  tags?: string[];
}

/**
 * An interface representing InterventionDetailsTinyDto.
 */
export interface InterventionDetailsTinyDto {
  id?: string;
  sourceDocumentId?: string;
  /**
   * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
   * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
   */
  interventionPath?: InterventionPath;
  formVersionId?: string;
  documentId?: string;
  subjectId?: string;
  /**
   * Possible values include: 'POSITIVE', 'NEGATIVE', 'CONDITIONALLY_POSITIVE',
   * 'NOT_SUBJECT_TO_OPINION', 'FINAL_REPORT'
   */
  opinionAOTMiT?: OpinionAOTMiTType;
  /**
   * Possible values include: 'DRAFT', 'PLANNED', 'SUSPENDED', 'COMPLETED_REALIZED',
   * 'COMPLETED_UNREALIZED', 'ARCHIVED', 'REALIZATION', 'DELETED', 'CANCELED',
   * 'SENDED_TO_OPINION_AOTMIT'
   */
  interventionStatus?: PublicHealthInterventionStatus;
  createdAt?: Date;
}

/**
 * An interface representing InterventionListDto.
 */
export interface InterventionListDto {
  id?: string;
  plannedDateFrom?: Date;
  plannedDateTo?: Date;
  createdAt?: Date;
  codeName?: string;
  name?: string;
  aotmitNumber?: string;
  /**
   * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
   * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
   */
  interventionPath?: InterventionPath;
  /**
   * Possible values include: 'DRAFT', 'PLANNED', 'SUSPENDED', 'COMPLETED_REALIZED',
   * 'COMPLETED_UNREALIZED', 'ARCHIVED', 'REALIZATION', 'DELETED', 'CANCELED',
   * 'SENDED_TO_OPINION_AOTMIT'
   */
  status?: PublicHealthInterventionStatus;
  subjectId?: string;
  subjectName?: string;
  coowners?: string[];
  /**
   * Possible values include: 'DRAFT', 'ACTIVE', 'INACTIVE'
   */
  patternStatus?: PatternStatus;
  /**
   * Possible values include: 'POSITIVE', 'NEGATIVE', 'CONDITIONALLY_POSITIVE',
   * 'NOT_SUBJECT_TO_OPINION', 'FINAL_REPORT'
   */
  aotmitOpinion?: OpinionAOTMiTType;
  type?: string;
}

/**
 * An interface representing InterventionListDtoPagedResult.
 */
export interface InterventionListDtoPagedResult {
  results?: InterventionListDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing CreateInterventionCommand.
 */
export interface CreateInterventionCommand {
  /**
   * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
   * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
   */
  interventionPath?: InterventionPath;
  interventionTypeDictionaryValueId?: number;
  interventionTypeDictionaryOtherValue?: string;
  patternId?: string;
}

/**
 * An interface representing CoProducerDto.
 */
export interface CoProducerDto {
  id?: string;
  subjectId?: string;
  roles?: CoProducerRoleDto[];
}

/**
 * An interface representing UpdateInterventionCommand.
 */
export interface UpdateInterventionCommand {
  id?: string;
  name?: string;
  plannedDateFrom?: Date;
  plannedDateTo?: Date;
  plannedBudget?: number;
  contractorsQty?: number;
  recommendationTypeDictionaryValueId?: number;
  recommendationTypeDictionaryOtherValue?: string;
  interventionAreaDictionaryValueId?: number;
  interventionAreaDictionaryOtherValue?: string;
  implementationPeriodDictionaryValueId?: number;
  implementationPeriodDictionaryOtherValue?: string;
  educationImplementationModeDictionaryValueId?: number;
  educationImplementationModeDictionaryOtherValue?: string;
  numberOfPersonAction?: number;
  numberOfRecipients?: number;
  annualNumberOfRecipients?: AnnualNumberOfRecipientsDto[];
  legalBases?: RizpDictionaryDto[];
  contacts?: SubjectContactDto[];
  areaTerytCodes?: string[];
  interventionCoownerIds?: string[];
  interventionCoProducers?: CoProducerDto[];
  interventionPartners?: InterventionPartnerDto[];
  dataSourceDictionaryValues?: RizpDictionaryDto[];
  interventionEffectDictionaryValues?: RizpDictionaryDto[];
  interventionKindDictionaryValues?: RizpDictionaryDto[];
  ncnClassificationDictionaryValues?: RizpDictionaryDto[];
  gBaordClassificationDictionaryValues?: RizpDictionaryDto[];
  disciplineAreaClassificationDictionaryValues?: RizpDictionaryDto[];
  dateOfDecisionAOTMiT?: Date;
  /**
   * Possible values include: 'POSITIVE', 'NEGATIVE', 'CONDITIONALLY_POSITIVE',
   * 'NOT_SUBJECT_TO_OPINION', 'FINAL_REPORT'
   */
  opinionAOTMiT?: OpinionAOTMiTType;
  presidentAotmitNumber?: string;
}

/**
 * An interface representing CreateInterventionOnExistingCommand.
 */
export interface CreateInterventionOnExistingCommand {
  id?: string;
  interventionTypeDictionaryValueId?: number;
  interventionTypeDictionaryOtherValue?: string;
  plannedDateFrom?: Date;
  plannedDateTo?: Date;
}

/**
 * An interface representing SuspendInterventionCommand.
 */
export interface SuspendInterventionCommand {
  id?: string;
}

/**
 * An interface representing UnsuspendInterventionCommand.
 */
export interface UnsuspendInterventionCommand {
  id?: string;
}

/**
 * An interface representing ArchiveInterventionCommand.
 */
export interface ArchiveInterventionCommand {
  ids?: string[];
}

/**
 * An interface representing UnarchiveInterventionCommand.
 */
export interface UnarchiveInterventionCommand {
  ids?: string[];
}

/**
 * An interface representing CancelInterventionCommand.
 */
export interface CancelInterventionCommand {
  id?: string;
}

/**
 * An interface representing SendInterventionCommand.
 */
export interface SendInterventionCommand {
  id?: string;
}

/**
 * An interface representing TooltipDto.
 */
export interface TooltipDto {
  field?: string;
  value?: string;
}

/**
 * An interface representing CreateTooltipCommand.
 */
export interface CreateTooltipCommand {
  field?: string;
  value?: string;
}

/**
 * An interface representing ActivitiesSummaryDto.
 */
export interface ActivitiesSummaryDto {
  id?: string;
  plannedBudget?: number;
  realizationBudget?: number;
  realizationDateFrom?: Date;
  realizationDateTo?: Date;
}

/**
 * An interface representing CreateInterventionCommentCommand.
 */
export interface CreateInterventionCommentCommand {
  interventionId?: string;
  activityId?: string;
  activityPopulationId?: string;
  activitySponsoringCompanyId?: string;
  fieldName?: string;
  description?: string;
  /**
   * Possible values include: 'PUBLIC', 'PRIVATE', 'IMPORTANT'
   */
  commentType?: InterventionCommentType;
  activityTabName?: string;
}

/**
 * An interface representing CreateInterventionAOTMiTCommentCommand.
 */
export interface CreateInterventionAOTMiTCommentCommand {
  comments?: CreateInterventionCommentCommand[];
}

/**
 * An interface representing InterventionCommentDto.
 */
export interface InterventionCommentDto {
  id?: string;
  interventionId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdByUserId?: string;
  createdBySubjectId?: string;
  createdBy?: string;
  fieldName?: string;
  description?: string;
  /**
   * Possible values include: 'ADDED', 'READ', 'PROCESSED', 'DELETED'
   */
  status?: InterventionCommentStatus;
  /**
   * Possible values include: 'PUBLIC', 'PRIVATE', 'IMPORTANT'
   */
  commentType?: InterventionCommentType;
}

/**
 * An interface representing UpdateInterventionCommentCommand.
 */
export interface UpdateInterventionCommentCommand {
  commentId?: string;
  description?: string;
}

/**
 * An interface representing DeleteInterventionCommentCommand.
 */
export interface DeleteInterventionCommentCommand {
  commentId?: string;
}

/**
 * An interface representing InterventionCommentListItemDto.
 */
export interface InterventionCommentListItemDto {
  id?: string;
  createdAt?: Date;
  createdBySubjectId?: string;
  createdBy?: string;
  createdBySubject?: string;
  description?: string;
  /**
   * Possible values include: 'ADDED', 'READ', 'PROCESSED', 'DELETED'
   */
  status?: InterventionCommentStatus;
  /**
   * Possible values include: 'PUBLIC', 'PRIVATE', 'IMPORTANT'
   */
  commentType?: InterventionCommentType;
  updatedAt?: Date;
  fieldName?: string;
  createdByAotmit?: boolean;
  interventionSubjectId?: string;
  interventionCoownersSubjectIds?: string[];
  activityId?: string;
  activityPopulationId?: string;
  activitySponsoringCompanyId?: string;
  activityNumber?: string;
  activityTabName?: string;
}

/**
 * An interface representing InterventionCommentListItemDtoPagedResult.
 */
export interface InterventionCommentListItemDtoPagedResult {
  results?: InterventionCommentListItemDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing SetInterventionCommentStatusCommand.
 */
export interface SetInterventionCommentStatusCommand {
  commentId?: string;
  /**
   * Possible values include: 'ADDED', 'READ', 'PROCESSED', 'DELETED'
   */
  commentStatus?: InterventionCommentStatus;
  resolveAotmitComments?: boolean;
}

/**
 * An interface representing AddInterventionTagCommand.
 */
export interface AddInterventionTagCommand {
  interventionId?: string;
  tagName?: string;
}

/**
 * An interface representing DeleteInterventionTagCommand.
 */
export interface DeleteInterventionTagCommand {
  interventionId?: string;
  tagName?: string;
}

/**
 * An interface representing RoleValueDto.
 */
export interface RoleValueDto {
  roleId?: string;
  roleName?: string;
  /**
   * Possible values include: 'LOCAL', 'CENTRAL'
   */
  category?: RoleCategory;
  validFrom?: Date;
  validTo?: Date;
}

/**
 * An interface representing RolePermissionDto.
 */
export interface RolePermissionDto {
  roleId?: string;
  roleName?: string;
  /**
   * Possible values include: 'Dictionaries_List_Preview', 'Dictionaries_Details_Preview',
   * 'Dictionaries_Versions_Modifications', 'Admin_Roles_Preview', 'Admin_Roles_Modifications',
   * 'Admin_Roles_Assignment_Global', 'Admin_Roles_Assignment_Local', 'Admin_ApiKeys_Management',
   * 'RIZP_Preview_Mine', 'RIZP_Preview_Subordinate', 'RIZP_Preview_All', 'RIZP_Modifications',
   * 'RIZP_Patterns_Modifications_Nationwide', 'RIZP_Patterns_Modifications_Voivodeship',
   * 'RIZP_Archive', 'Subjects_List_Preview', 'Subjects_Details_Preview',
   * 'Subjects_Modifications_Mine', 'Subjects_Modifications_With_Teryt_Mine',
   * 'Subjects_Modifications_All', 'Subjects_Modifications_With_Teryt_All',
   * 'Subjects_Modifications_Statement_Obligation', 'Subjects_Merge',
   * 'Subjects_Proposal_Possibility_Handling', 'Statements_Archive', 'Statements_Generation_MZ53',
   * 'Statements_Generation_MZ49', 'Statements_Generation_NPZ', 'Statement_Preview_Mine_MZ49',
   * 'Statement_Preview_Mine_MZ53', 'Statement_Preview_Mine_NPZ',
   * 'Statement_Preview_Subordinate_MZ49', 'Statement_Preview_Subordinate_MZ53',
   * 'Statement_Preview_Subordinate_NPZ', 'Statement_Preview_All_MZ49',
   * 'Statement_Preview_All_MZ53', 'Statement_Preview_All_NPZ', 'Statement_Verification_MZ49',
   * 'Statement_Verification_MZ53', 'Statement_Verification_NPZ', 'Statement_Critical_Warning',
   * 'Proposals_Handling_All', 'Proposals_Handling_Mine', 'DocumentsRepository_List_Preview',
   * 'DocumentsRepository_Preview', 'DocumentsRepository_VoivodeOpinions_Preview',
   * 'DocumentsRepository_AllVoivodeOpinions_Preview',
   * 'DocumentsRepository_VoivodeOpinions_Modifications', 'Reports_MZ_Preview',
   * 'Reports_Programy_Zdrowotne_Preview', 'Reports_AOTMiT_Preview', 'Reports_RP_Preview',
   * 'Reports_SZ_Chorobowosc_Preview', 'Reports_SZ_Umieralnosc_Preview',
   * 'Reports_SZ_Deprywacja_Preview', 'Reports_SZ_Statusy_Preview',
   * 'Reports_SZ_Demografia_Preview', 'Reports_SZ_Preview', 'Reports_All_Preview',
   * 'Reports_Technical', 'Aotmit_Add_Recommendations', 'Aotmit_Add_Comments',
   * 'Aotmit_Add_DetailedReportAnnex', 'Aotmit_Projects', 'Forms_Management',
   * 'Notifications_Management', 'UserManual_Management', 'Tooltip_Management',
   * 'Tags_Management_Global', 'Tags_Management_Local', 'Aggregate_Data', 'IKP'
   */
  permission?: Permission;
  permissionName?: string;
}

/**
 * An interface representing KindSubjectDto.
 */
export interface KindSubjectDto {
  apiKey?: string;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  roles?: RoleValueDto[];
  rolePermissions?: RolePermissionDto[];
}

/**
 * An interface representing AddKindSubjectRoleCommand.
 */
export interface AddKindSubjectRoleCommand {
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  roleId?: string;
  validFrom?: Date;
  validTo?: Date;
}

/**
 * An interface representing DeleteKindSubjectRoleCommand.
 */
export interface DeleteKindSubjectRoleCommand {
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  roleId?: string;
}

/**
 * An interface representing UpdateNotificationDefinionCommand.
 */
export interface UpdateNotificationDefinionCommand {
  id?: string;
  content?: string;
  systemChannelActive?: boolean;
  emailChannelActive?: boolean;
  activations?: NotificationDefinitionActivationDto[];
  sendToAll?: boolean;
}

/**
 * An interface representing ActivateNotificationDefinionCommand.
 */
export interface ActivateNotificationDefinionCommand {
  id?: string;
}

/**
 * An interface representing DeactivateNotificationDefinionCommand.
 */
export interface DeactivateNotificationDefinionCommand {
  id?: string;
}

/**
 * An interface representing UpdateNotificationDefinionChannelsCommand.
 */
export interface UpdateNotificationDefinionChannelsCommand {
  id?: string;
  systemChannelActive?: boolean;
  emailChannelActive?: boolean;
}

/**
 * An interface representing NotificationOccurrenceDto.
 */
export interface NotificationOccurrenceDto {
  id?: string;
  header?: string;
  description?: string;
  dateFrom?: Date;
  dateTo?: Date;
  creationDate?: Date;
  /**
   * Possible values include: 'Intervention', 'Statement', 'User', 'ReportingProcess', 'Activity'
   */
  occurrenceDomainType?: OccurrenceDomainType;
  instanceId?: string;
  activations?: NotificationDefinitionActivationDto[];
}

/**
 * An interface representing CreateNotificationOccurrenceCommand.
 */
export interface CreateNotificationOccurrenceCommand {
  userId?: string;
  dateFrom?: Date;
  dateTo?: Date;
  description?: string;
  activations?: NotificationDefinitionActivationDto[];
}

/**
 * An interface representing UpdateNotificationOccurrenceCommand.
 */
export interface UpdateNotificationOccurrenceCommand {
  id?: string;
  dateFrom?: Date;
  dateTo?: Date;
  description?: string;
  activations?: NotificationDefinitionActivationDto[];
}

/**
 * An interface representing CreatePatternCommand.
 */
export interface CreatePatternCommand {
  /**
   * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
   * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
   */
  interventionPath?: InterventionPath;
  interventionTypeDictionaryValueId?: number;
  interventionTypeDictionaryOtherValue?: string;
  patternId?: string;
  name?: string;
  plannedDateFrom?: Date;
  plannedDateTo?: Date;
  plannedBudget?: number;
  numberOfPersonAction?: number;
  numberOfRecipients?: number;
  annualNumberOfRecipients?: AnnualNumberOfRecipientsDto[];
  recommendationTypeDictionaryValueId?: number;
  recommendationTypeDictionaryOtherValue?: string;
  interventionAreaDictionaryValueId?: number;
  interventionAreaDictionaryOtherValue?: string;
  implementationPeriodDictionaryValueId?: number;
  implementationPeriodDictionaryOtherValue?: string;
  educationImplementationModeDictionaryValueId?: number;
  educationImplementationModeDictionaryOtherValue?: string;
  legalBases?: RizpDictionaryDto[];
  contacts?: SubjectContactDto[];
  areaTerytCodes?: string[];
  interventionCoownerIds?: string[];
  interventionPartners?: InterventionPartnerDto[];
  dataSourceDictionaryValues?: RizpDictionaryDto[];
  interventionEffectDictionaryValues?: RizpDictionaryDto[];
  ncnClassificationDictionaryValues?: RizpDictionaryDto[];
  gBoardClassificationDictionaryValues?: RizpDictionaryDto[];
  disciplineAreaClassificationDictionaryValues?: RizpDictionaryDto[];
  patternName?: string;
  vaildFrom?: Date;
  vaildTo?: Date;
  /**
   * Possible values include: 'PROGRAM', 'NOT_PROGRAM', 'AOTMIT_RECOMENDATION'
   */
  patternType?: InterventionPatternType;
  recommendationDocumentId?: string;
  isCompliantWithRecommendation?: boolean;
}

/**
 * An interface representing UpdatePatternCommand.
 */
export interface UpdatePatternCommand {
  id?: string;
  name?: string;
  plannedDateFrom?: Date;
  plannedDateTo?: Date;
  plannedBudget?: number;
  contractorsQty?: number;
  recommendationTypeDictionaryValueId?: number;
  recommendationTypeDictionaryOtherValue?: string;
  interventionAreaDictionaryValueId?: number;
  interventionAreaDictionaryOtherValue?: string;
  implementationPeriodDictionaryValueId?: number;
  implementationPeriodDictionaryOtherValue?: string;
  educationImplementationModeDictionaryValueId?: number;
  educationImplementationModeDictionaryOtherValue?: string;
  numberOfPersonAction?: number;
  numberOfRecipients?: number;
  annualNumberOfRecipients?: AnnualNumberOfRecipientsDto[];
  legalBases?: RizpDictionaryDto[];
  contacts?: SubjectContactDto[];
  areaTerytCodes?: string[];
  interventionCoownerIds?: string[];
  interventionCoProducers?: CoProducerDto[];
  interventionPartners?: InterventionPartnerDto[];
  dataSourceDictionaryValues?: RizpDictionaryDto[];
  interventionEffectDictionaryValues?: RizpDictionaryDto[];
  interventionKindDictionaryValues?: RizpDictionaryDto[];
  ncnClassificationDictionaryValues?: RizpDictionaryDto[];
  gBaordClassificationDictionaryValues?: RizpDictionaryDto[];
  disciplineAreaClassificationDictionaryValues?: RizpDictionaryDto[];
  dateOfDecisionAOTMiT?: Date;
  /**
   * Possible values include: 'POSITIVE', 'NEGATIVE', 'CONDITIONALLY_POSITIVE',
   * 'NOT_SUBJECT_TO_OPINION', 'FINAL_REPORT'
   */
  opinionAOTMiT?: OpinionAOTMiTType;
  presidentAotmitNumber?: string;
  patternName?: string;
  vaildFrom?: Date;
  vaildTo?: Date;
  /**
   * Possible values include: 'PROGRAM', 'NOT_PROGRAM', 'AOTMIT_RECOMENDATION'
   */
  patternType?: InterventionPatternType;
  interventionTypeDictionaryValueId?: number;
  interventionTypeDictionaryOtherValue?: string;
  recommendationDocumentId?: string;
  isCompliantWithRecommendation?: boolean;
}

/**
 * An interface representing PatternDto.
 */
export interface PatternDto {
  id?: string;
  plannedDateFrom?: Date;
  plannedDateTo?: Date;
  createdAt?: Date;
  codeName?: string;
  name?: string;
  contractorsQty?: number;
  modifiedAt?: Date;
  aotmitNumber?: string;
  aotmitStatus?: string;
  linkToWizard?: string;
  dateOfDecisionAOTMiT?: Date;
  /**
   * Possible values include: 'POSITIVE', 'NEGATIVE', 'CONDITIONALLY_POSITIVE',
   * 'NOT_SUBJECT_TO_OPINION', 'FINAL_REPORT'
   */
  opinionAOTMiT?: OpinionAOTMiTType;
  presidentAotmitNumber?: string;
  /**
   * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
   * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
   */
  interventionPath?: InterventionPath;
  interventionTypeDictionaryValueId?: number;
  interventionTypeDictionaryOtherValue?: string;
  interventionTypeDictionaryValue?: string;
  interventionTypeDictionaryCode?: string;
  /**
   * Possible values include: 'DRAFT', 'PLANNED', 'SUSPENDED', 'COMPLETED_REALIZED',
   * 'COMPLETED_UNREALIZED', 'ARCHIVED', 'REALIZATION', 'DELETED', 'CANCELED',
   * 'SENDED_TO_OPINION_AOTMIT'
   */
  status?: PublicHealthInterventionStatus;
  subjectId?: string;
  subjectName?: string;
  recommendationTypeDictionaryValueId?: number;
  recommendationTypeDictionaryOtherValue?: string;
  interventionAreaDictionaryValueId?: number;
  interventionAreaDictionaryOtherValue?: string;
  interventionAreaDictionaryValue?: string;
  implementationPeriodDictionaryValueId?: number;
  implementationPeriodDictionaryOtherValue?: string;
  implementationPeriodDictionaryValue?: string;
  plannedBudget?: number;
  realizationBudget?: number;
  realizationDateFrom?: Date;
  realizationDateTo?: Date;
  numberOfPersonAction?: number;
  numberOfRecipients?: number;
  annualNumberOfRecipients?: AnnualNumberOfRecipientsDto[];
  patternId?: string;
  interventionCoowners?: InterventionCoownerDto[];
  interventionPartners?: InterventionPartnerDto[];
  modifications?: PublicHealthInterventionModificationDto[];
  legalBases?: RizpDictionaryValueDto[];
  contacts?: SubjectContactDto[];
  areas?: AreaValueDto[];
  dataSourceDictionaryValues?: RizpDictionaryValueDto[];
  interventionEffectDictionaryValues?: RizpDictionaryValueDto[];
  interventionKindDictionaryValues?: RizpDictionaryValueDto[];
  ncnClassificationDictionaryValues?: RizpDictionaryValueDto[];
  gBaordClassificationDictionaryValues?: RizpDictionaryValueDto[];
  disciplineAreaClassificationDictionaryValues?: RizpDictionaryValueDto[];
  educationImplementationModeDictionaryValueId?: number;
  educationImplementationModeDictionaryOtherValue?: string;
  educationImplementationModeDictionaryValue?: string;
  coProducers?: CoProducerValueDto[];
  tags?: string[];
  patternName?: string;
  vaildFrom?: Date;
  vaildTo?: Date;
  /**
   * Possible values include: 'DRAFT', 'ACTIVE', 'INACTIVE'
   */
  patternStatus?: PatternStatus;
  /**
   * Possible values include: 'PROGRAM', 'NOT_PROGRAM', 'AOTMIT_RECOMENDATION'
   */
  patternType?: InterventionPatternType;
  recommendationDocumentId?: string;
  recommendationDocumentName?: string;
  isCompliantWithRecommendation?: boolean;
}

/**
 * An interface representing ActivatePatternCommand.
 */
export interface ActivatePatternCommand {
  id?: string;
}

/**
 * An interface representing DeactivatePatternCommand.
 */
export interface DeactivatePatternCommand {
  id?: string;
}

/**
 * An interface representing CreatePatternOnExistingCommand.
 */
export interface CreatePatternOnExistingCommand {
  id?: string;
}

/**
 * An interface representing ProposalItemDto.
 */
export interface ProposalItemDto {
  id?: string;
  /**
   * Possible values include: 'Administration', 'Normal'
   */
  proposalAccesType?: ProposalAccessType;
  firstName?: string;
  lastName?: string;
  subjectId?: string;
  subjectName?: string;
  subjectCity?: string;
  /**
   * Possible values include: 'Accepted', 'Sended', 'Rejected', 'Canceled'
   */
  status?: ProposalStatus;
  insertDate?: Date;
  isAttachment?: boolean;
}

/**
 * An interface representing ProposalItemDtoPagedResult.
 */
export interface ProposalItemDtoPagedResult {
  results?: ProposalItemDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing ConsiderProposalCommand.
 */
export interface ConsiderProposalCommand {
  id?: string;
  /**
   * Possible values include: 'Accepted', 'Sended', 'Rejected', 'Canceled'
   */
  status?: ProposalStatus;
  rejectReason?: string;
}

/**
 * An interface representing AddressDto.
 */
export interface AddressDto {
  zipCode?: string;
  isAddressManual?: boolean;
  simcCode?: string;
  tercCode?: string;
  streetCode?: string;
  city?: string;
  propertyNumber?: string;
  placeNumber?: string;
  street?: string;
  voivodeship?: string;
  county?: string;
  community?: string;
  id?: string;
}

/**
 * An interface representing SubjectBaseDto.
 */
export interface SubjectBaseDto {
  name?: string;
  address?: AddressDto;
}

/**
 * An interface representing ProposalDetailsDto.
 */
export interface ProposalDetailsDto {
  id?: string;
  /**
   * Possible values include: 'Administration', 'Normal'
   */
  proposalAccesType?: ProposalAccessType;
  firstName?: string;
  lastName?: string;
  email?: string;
  subjectId?: string;
  subject?: SubjectBaseDto;
  userId?: string;
  /**
   * Possible values include: 'Accepted', 'Sended', 'Rejected', 'Canceled'
   */
  status?: ProposalStatus;
  rejectReason?: string;
  insertDate?: Date;
  lastModificationDate?: Date;
  fileId?: string;
  fileName?: string;
}

/**
 * An interface representing CreateRoleCommand.
 */
export interface CreateRoleCommand {
  name?: string;
  /**
   * Possible values include: 'USER', 'ADMIN'
   */
  type?: RoleType;
  /**
   * Possible values include: 'LOCAL', 'CENTRAL'
   */
  category?: RoleCategory;
}

/**
 * An interface representing UpdateRoleCommand.
 */
export interface UpdateRoleCommand {
  id?: string;
  name?: string;
  /**
   * Possible values include: 'USER', 'ADMIN'
   */
  type?: RoleType;
  /**
   * Possible values include: 'LOCAL', 'CENTRAL'
   */
  category?: RoleCategory;
  permissions?: Permission[];
}

/**
 * An interface representing RoleDto.
 */
export interface RoleDto {
  id?: string;
  name?: string;
  isActive?: boolean;
  /**
   * Possible values include: 'USER', 'ADMIN'
   */
  type?: RoleType;
  /**
   * Possible values include: 'LOCAL', 'CENTRAL'
   */
  category?: RoleCategory;
  permissions?: Permission[];
}

/**
 * An interface representing ActivateRoleCommand.
 */
export interface ActivateRoleCommand {
  id?: string;
}

/**
 * An interface representing DeactivateRoleCommand.
 */
export interface DeactivateRoleCommand {
  id?: string;
}

/**
 * An interface representing StatementDetailsExternalDto.
 */
export interface StatementDetailsExternalDto {
  id?: string;
  codeName?: string;
  content?: any;
  /**
   * Possible values include: 'JSON', 'PDF'
   */
  contentType?: ContentType;
  /**
   * Possible values include: 'DRAFT', 'ACCEPTED', 'CLOSED', 'RECLOSED', 'IN_VERIFICATION',
   * 'DUPLICATE', 'TO_COMPLETE', 'TO_IMPROVE', 'INCORRECT', 'ARCHIVED', 'REJECTED'
   */
  statementStatus?: StatementStatus;
}

/**
 * An interface representing StatementCommentDto.
 */
export interface StatementCommentDto {
  id?: string;
  statementId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdByUserId?: string;
  createdBySubjectId?: string;
  createdBy?: string;
  statementPartInterventionCode?: string;
  column?: string;
  row?: string;
  description?: string;
  /**
   * Possible values include: 'ADDED', 'READ', 'PROCESSED', 'DELETED'
   */
  status?: CommentStatus;
  /**
   * Possible values include: 'IMPORTANT', 'PUBLIC', 'PRIVATE'
   */
  commentType?: CommentType;
}

/**
 * An interface representing StatementCommentListItemDto.
 */
export interface StatementCommentListItemDto {
  id?: string;
  createdAt?: Date;
  createdByUserId?: string;
  createdBySubjectId?: string;
  createdBy?: string;
  createdBySubject?: string;
  description?: string;
  /**
   * Possible values include: 'ADDED', 'READ', 'PROCESSED', 'DELETED'
   */
  status?: CommentStatus;
  /**
   * Possible values include: 'IMPORTANT', 'PUBLIC', 'PRIVATE'
   */
  commentType?: CommentType;
  updatedAt?: Date;
  statementPartInterventionCode?: string;
  column?: string;
  row?: string;
  statementSubjectId?: string;
}

/**
 * An interface representing StatementCommentListItemDtoPagedResult.
 */
export interface StatementCommentListItemDtoPagedResult {
  results?: StatementCommentListItemDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing CreateCommentCommand.
 */
export interface CreateCommentCommand {
  statementId?: string;
  statementPartInterventionCode?: string;
  column?: string;
  row?: string;
  description?: string;
  /**
   * Possible values include: 'IMPORTANT', 'PUBLIC', 'PRIVATE'
   */
  commentType?: CommentType;
}

/**
 * An interface representing UpdateCommentCommand.
 */
export interface UpdateCommentCommand {
  commentId?: string;
  statementPartInterventionCode?: string;
  column?: string;
  row?: string;
  description?: string;
}

/**
 * An interface representing DeleteCommentCommand.
 */
export interface DeleteCommentCommand {
  commentId?: string;
}

/**
 * An interface representing SetCommentReadCommand.
 */
export interface SetCommentReadCommand {
  commentId?: string;
}

/**
 * An interface representing SetCommentProcessedCommand.
 */
export interface SetCommentProcessedCommand {
  commentId?: string;
}

/**
 * An interface representing StatementSectionDto.
 */
export interface StatementSectionDto {
  formPartId?: string;
  documentId?: string;
}

/**
 * An interface representing StatementDetailsDto.
 */
export interface StatementDetailsDto {
  id?: string;
  updatedAt?: Date;
  createdAt?: Date;
  codeName?: string;
  year?: number;
  /**
   * Possible values include: 'NPZ', 'MZ_49', 'MZ_53'
   */
  statementArea?: StatementArea;
  /**
   * Possible values include: 'DRAFT', 'ACCEPTED', 'CLOSED', 'RECLOSED', 'IN_VERIFICATION',
   * 'DUPLICATE', 'TO_COMPLETE', 'TO_IMPROVE', 'INCORRECT', 'ARCHIVED', 'REJECTED'
   */
  statementStatus?: StatementStatus;
  subjectId?: string;
  parentSubjectId?: string;
  formVersionId?: string;
  subjectName?: string;
  subjectRegon?: string;
  hasPreview?: boolean;
  statementRejectionComment?: string;
  sourceDocumentId?: string;
  statementModificationAuthorFullName?: string;
  rejectedBySubjectId?: string;
  sections?: StatementSectionDto[];
  tags?: string[];
}

/**
 * An interface representing StatementListItemDto.
 */
export interface StatementListItemDto {
  id?: string;
  codeName?: string;
  statementName?: string;
  /**
   * Possible values include: 'NPZ', 'MZ_49', 'MZ_53'
   */
  statementArea?: StatementArea;
  /**
   * Possible values include: 'DRAFT', 'ACCEPTED', 'CLOSED', 'RECLOSED', 'IN_VERIFICATION',
   * 'DUPLICATE', 'TO_COMPLETE', 'TO_IMPROVE', 'INCORRECT', 'ARCHIVED', 'REJECTED'
   */
  statementStatus?: StatementStatus;
  subjectId?: string;
  subjectName?: string;
  subjectRegon?: string;
  createdAt?: Date;
  year?: number;
  updatedAt?: Date;
}

/**
 * An interface representing StatementListItemDtoPagedResult.
 */
export interface StatementListItemDtoPagedResult {
  results?: StatementListItemDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing CreateStatementCommand.
 */
export interface CreateStatementCommand {
  year?: number;
  /**
   * Possible values include: 'NPZ', 'MZ_49', 'MZ_53'
   */
  statementArea?: StatementArea;
}

/**
 * An interface representing ArchiveStatementCommand.
 */
export interface ArchiveStatementCommand {
  ids?: string[];
}

/**
 * An interface representing UnarchiveStatementCommand.
 */
export interface UnarchiveStatementCommand {
  ids?: string[];
}

/**
 * An interface representing SetStatementMZCompletedCommand.
 */
export interface SetStatementMZCompletedCommand {
  id?: string;
}

/**
 * An interface representing SetStatementMZAcceptedCommand.
 */
export interface SetStatementMZAcceptedCommand {
  id?: string;
}

/**
 * An interface representing SetStatementMZRejectedCommand.
 */
export interface SetStatementMZRejectedCommand {
  id?: string;
  statementRejectionComment?: string;
}

/**
 * An interface representing SetStatementNPZCompletedCommand.
 */
export interface SetStatementNPZCompletedCommand {
  id?: string;
  importProperty?: boolean;
}

/**
 * An interface representing SetStatementNPZAcceptedCommand.
 */
export interface SetStatementNPZAcceptedCommand {
  id?: string;
}

/**
 * An interface representing SetStatementNPZRejectedCommand.
 */
export interface SetStatementNPZRejectedCommand {
  id?: string;
  statementRejectionComment?: string;
}

/**
 * An interface representing FileImportStatusDto.
 */
export interface FileImportStatusDto {
  fileId?: string;
  date?: Date;
  message?: string;
  status?: boolean;
}

/**
 * An interface representing ImportStatusCommand.
 */
export interface ImportStatusCommand {
  errors?: FileImportStatusDto[];
}

/**
 * An interface representing CopyStatementCommand.
 */
export interface CopyStatementCommand {
  statementId?: string;
}

/**
 * An interface representing CorrectStatementCommand.
 */
export interface CorrectStatementCommand {
  statementId?: string;
}

/**
 * An interface representing StatementHeaderDto.
 */
export interface StatementHeaderDto {
  rEGON?: string;
  rokSpraw?: number;
  nazwaPodmSpraw?: string;
  adresPodmSpraw?: string;
  identyfikator?: string;
}

/**
 * An interface representing ProgramInterventionDto.
 */
export interface ProgramInterventionDto {
  dz3PrNazwaInterw?: string;
  dz3PrZasInterw?: string;
  dz3PrLiczbaRealiz?: number;
  dz3PrLiczbaOdbior?: number;
  dz3PrNrIdInterw?: string;
  dz3PrNrIdWzorcaInterw?: string;
  dz3PrRealizRokSz?: boolean;
  dz3PrIdIterw?: string;
  dz3PrIdWzorcaInterw?: string;
}

/**
 * An interface representing NonProgramInterventionDto.
 */
export interface NonProgramInterventionDto {
  dz3NieprNazwaInterw?: string;
  dz3NieprZasInterw?: string;
  dz3NieprLiczbaRealiz?: number;
  dz3NieprLiczbaOdbior?: number;
  dz3NieprNrIdInterw?: string;
  dz3NieprNrIdWzorcaInterw?: string;
  dz3NieprRealizRokSz?: boolean;
  dz3NieprIdIterw?: string;
  dz3NieprIdWzorcaInterw?: string;
}

/**
 * An interface representing MZ49AutocompletionDto.
 */
export interface MZ49AutocompletionDto {
  dz3PrInterwencje?: ProgramInterventionDto[];
  dz3NieprInterwencje?: NonProgramInterventionDto[];
  kategoriaPodmiotu?: string;
}

/**
 * An interface representing AddStatementTagCommand.
 */
export interface AddStatementTagCommand {
  statementId?: string;
  tagName?: string;
}

/**
 * An interface representing DeleteStatementTagCommand.
 */
export interface DeleteStatementTagCommand {
  statementId?: string;
  tagName?: string;
}

/**
 * An interface representing StatementTestDetailsDto.
 */
export interface StatementTestDetailsDto {
  id?: string;
  updatedAt?: Date;
  createdAt?: Date;
  codeName?: string;
  year?: number;
  /**
   * Possible values include: 'NPZ', 'MZ_49', 'MZ_53'
   */
  statementArea?: StatementArea;
  formVersionId?: string;
  hasPreview?: boolean;
  statementModificationAuthorFullName?: string;
  sections?: StatementSectionDto[];
}

/**
 * An interface representing StatementTestListItemDto.
 */
export interface StatementTestListItemDto {
  id?: string;
  formVersion?: string;
  /**
   * Possible values include: 'NPZ', 'MZ_49', 'MZ_53'
   */
  statementArea?: StatementArea;
  subjectId?: string;
  subjectName?: string;
  createdAt?: Date;
  year?: number;
}

/**
 * An interface representing StatementTestListItemDtoPagedResult.
 */
export interface StatementTestListItemDtoPagedResult {
  results?: StatementTestListItemDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing CreateStatementTestCommand.
 */
export interface CreateStatementTestCommand {
  year?: number;
  /**
   * Possible values include: 'NPZ', 'MZ_49', 'MZ_53'
   */
  statementArea?: StatementArea;
  formVersionId?: string;
  subjectId?: string;
}

/**
 * An interface representing SubjectListItemDto.
 */
export interface SubjectListItemDto {
  id?: string;
  cruipCode?: string;
  codeName?: string;
  name?: string;
  nip?: string;
  regon?: string;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  /**
   * Possible values include: 'NEW', 'MODIFIED', 'DELETED', 'MERGED'
   */
  status?: SubjectStatus;
  validTo?: Date;
  validFrom?: Date;
  isLaboratory?: boolean;
  fromCRUIP?: boolean;
  address?: string;
  krsCode?: string;
  voivodeship?: string;
  county?: string;
  canEdit?: boolean;
  isActiveAdminAccount?: boolean;
}

/**
 * An interface representing SubjectListItemDtoPagedResult.
 */
export interface SubjectListItemDtoPagedResult {
  results?: SubjectListItemDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing AddressValueDto.
 */
export interface AddressValueDto {
  zipCode?: string;
  isAddressManual?: boolean;
  simcCode?: string;
  tercCode?: string;
  streetCode?: string;
  city?: string;
  propertyNumber?: string;
  placeNumber?: string;
  street?: string;
  voivodeship?: string;
  county?: string;
  community?: string;
}

/**
 * An interface representing ContactValueDto.
 */
export interface ContactValueDto {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  position?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly fullName?: string;
}

/**
 * An interface representing CreateSubjectApiCommand.
 */
export interface CreateSubjectApiCommand {
  krsCode?: string;
  tercCode?: string;
  name?: string;
  nip?: string;
  regon?: string;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  validTo?: Date;
  validFrom?: Date;
  parentSubjectId?: string;
  address?: AddressValueDto;
  contacts?: ContactValueDto[];
  isProposalPossibility?: boolean;
}

/**
 * An interface representing UpdateSubjectApiCommand.
 */
export interface UpdateSubjectApiCommand {
  id?: string;
  krsCode?: string;
  name?: string;
  tercCode?: string;
  nip?: string;
  regon?: string;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  validTo?: Date;
  validFrom?: Date;
  /**
   * Possible values include: 'NEW', 'MODIFIED', 'DELETED', 'MERGED'
   */
  status?: SubjectStatus;
  parentSubjectId?: string;
  address?: AddressDto;
  contacts?: ContactDto[];
  isProposalPossibility?: boolean;
}

/**
 * An interface representing DeadlineProcessDto.
 */
export interface DeadlineProcessDto {
  id?: string;
  process?: string;
  day?: number;
  month?: number;
  year?: number;
  cycleLength?: number;
  daysToDeadline?: number;
}

/**
 * An interface representing SubjectDetailsDto.
 */
export interface SubjectDetailsDto {
  id?: string;
  codeName?: string;
  name?: string;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  cruipCode?: string;
  nip?: string;
  regon?: string;
  krsCode?: string;
  tercCode?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly parentCodeName?: string;
  parentSubjectName?: string;
  validTo?: Date;
  validFrom?: Date;
  address?: AddressDto;
  deadlineProcess?: DeadlineProcessDto[];
  contacts?: ContactDto[];
  primarySubjectId?: string;
  parentSubjectId?: string;
  parentSubject?: SubjectDetailsDto;
  isLaboratory?: boolean;
  isProposalPossibility?: boolean;
  /**
   * Possible values include: 'NEW', 'MODIFIED', 'DELETED', 'MERGED'
   */
  status?: SubjectStatus;
  isActiveAdminAccount?: boolean;
}

/**
 * An interface representing MergeSubjectsApiCommand.
 */
export interface MergeSubjectsApiCommand {
  subjectId?: string;
  mergingSubjectId?: string;
}

/**
 * An interface representing SubjectDetailsTinyDto.
 */
export interface SubjectDetailsTinyDto {
  id?: string;
  sourceDocumentId?: string;
}

/**
 * An interface representing SubjectPermissionListItemDto.
 */
export interface SubjectPermissionListItemDto {
  id?: string;
  cruipCode?: string;
  codeName?: string;
  name?: string;
  nip?: string;
  regon?: string;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  /**
   * Possible values include: 'NEW', 'MODIFIED', 'DELETED', 'MERGED'
   */
  status?: SubjectStatus;
  validTo?: Date;
  validFrom?: Date;
  isLaboratory?: boolean;
  fromCRUIP?: boolean;
  address?: string;
  krsCode?: string;
}

/**
 * An interface representing SubjectPermissionListItemDtoPagedResult.
 */
export interface SubjectPermissionListItemDtoPagedResult {
  results?: SubjectPermissionListItemDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing SubjectRolesDto.
 */
export interface SubjectRolesDto {
  subjectId?: string;
  subjectName?: string;
  apiKey?: string;
  roles?: RoleValueDto[];
  rolePermissions?: RolePermissionDto[];
}

/**
 * An interface representing AddSubjectRoleCommand.
 */
export interface AddSubjectRoleCommand {
  subjectId?: string;
  roleId?: string;
  validFrom?: Date;
  validTo?: Date;
}

/**
 * An interface representing DeleteSubjectRoleCommand.
 */
export interface DeleteSubjectRoleCommand {
  subjectId?: string;
  roleId?: string;
}

/**
 * An interface representing SubjectCommentDto.
 */
export interface SubjectCommentDto {
  id?: string;
  subjectId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdByUserId?: string;
  createdBySubjectId?: string;
  createdBy?: string;
  fieldName?: string;
  description?: string;
  /**
   * Possible values include: 'ADDED', 'READ', 'PROCESSED', 'DELETED'
   */
  status?: SubjectCommentStatus;
  /**
   * Possible values include: 'PUBLIC', 'PRIVATE', 'IMPORTANT'
   */
  commentType?: SubjectCommentType;
}

/**
 * An interface representing CreateSubjectCommentCommand.
 */
export interface CreateSubjectCommentCommand {
  subjectId?: string;
  fieldName?: string;
  description?: string;
  /**
   * Possible values include: 'PUBLIC', 'PRIVATE', 'IMPORTANT'
   */
  commentType?: SubjectCommentType;
}

/**
 * An interface representing UpdateSubjectCommentCommand.
 */
export interface UpdateSubjectCommentCommand {
  commentId?: string;
  fieldName?: string;
  description?: string;
}

/**
 * An interface representing DeleteSubjectCommentCommand.
 */
export interface DeleteSubjectCommentCommand {
  commentId?: string;
}

/**
 * An interface representing SubjectCommentListItemDto.
 */
export interface SubjectCommentListItemDto {
  id?: string;
  createdAt?: Date;
  createdByUserId?: string;
  createdBySubjectId?: string;
  createdBy?: string;
  createdBySubject?: string;
  description?: string;
  /**
   * Possible values include: 'ADDED', 'READ', 'PROCESSED', 'DELETED'
   */
  status?: SubjectCommentStatus;
  /**
   * Possible values include: 'PUBLIC', 'PRIVATE', 'IMPORTANT'
   */
  commentType?: SubjectCommentType;
  updatedAt?: Date;
  fieldName?: string;
}

/**
 * An interface representing SubjectCommentListItemDtoPagedResult.
 */
export interface SubjectCommentListItemDtoPagedResult {
  results?: SubjectCommentListItemDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing SetSubjectCommentStatusCommand.
 */
export interface SetSubjectCommentStatusCommand {
  commentId?: string;
  /**
   * Possible values include: 'ADDED', 'READ', 'PROCESSED', 'DELETED'
   */
  commentStatus?: SubjectCommentStatus;
}

/**
 * An interface representing SubjectReportingProcessListItemDto.
 */
export interface SubjectReportingProcessListItemDto {
  id?: string;
  identifier?: string;
  subjectName?: string;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  process?: string;
  /**
   * Possible values include: 'ACTIVE', 'INACTIVE', 'DELETED'
   */
  status?: SubjectReportingProcessStatus;
  deadline?: Date;
  validFrom?: Date;
  validTo?: Date;
}

/**
 * An interface representing SubjectReportingProcessListItemDtoPagedResult.
 */
export interface SubjectReportingProcessListItemDtoPagedResult {
  results?: SubjectReportingProcessListItemDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing CreateSubjectReportingProcessCommand.
 */
export interface CreateSubjectReportingProcessCommand {
  subjectId?: string;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  /**
   * Possible values include: 'MZ49', 'MZ53', 'NPZ'
   */
  process?: ReportingArea;
  submissionDay?: number;
  submissionMonth?: number;
  submissionFromYear?: number;
  submissionCycleLength?: number;
  description?: string;
  validFrom?: Date;
  validTo?: Date;
}

/**
 * An interface representing UpdateSubjectReportingProcessCommand.
 */
export interface UpdateSubjectReportingProcessCommand {
  id?: string;
  /**
   * Possible values include: 'MZ49', 'MZ53', 'NPZ'
   */
  process?: ReportingArea;
  submissionDay?: number;
  submissionMonth?: number;
  submissionFromYear?: number;
  submissionCycleLength?: number;
  description?: string;
  validFrom?: Date;
  validTo?: Date;
}

/**
 * An interface representing SubjectReportingProcessDto.
 */
export interface SubjectReportingProcessDto {
  id?: string;
  subjectId?: string;
  subjectName?: string;
  /**
   * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR',
   * 'WCZP', 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
   * 'EDUCATIONALUNIT'
   */
  subjectKind?: SubjectKind;
  /**
   * Possible values include: 'MZ49', 'MZ53', 'NPZ'
   */
  process?: ReportingArea;
  /**
   * Possible values include: 'ACTIVE', 'INACTIVE', 'DELETED'
   */
  status?: SubjectReportingProcessStatus;
  submissionDay?: number;
  submissionMonth?: number;
  submissionFromYear?: number;
  submissionCycleLength?: number;
  description?: string;
  validFrom?: Date;
  validTo?: Date;
}

/**
 * An interface representing ActivateSubjectReportingProcessCommand.
 */
export interface ActivateSubjectReportingProcessCommand {
  id?: string;
}

/**
 * An interface representing TagDto.
 */
export interface TagDto {
  id?: string;
  tagName?: string;
  subjectId?: string;
  subjectName?: string;
}

/**
 * An interface representing TagDtoPagedResult.
 */
export interface TagDtoPagedResult {
  results?: TagDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing DeleteTagSuggestionCommand.
 */
export interface DeleteTagSuggestionCommand {
  id?: string;
}

/**
 * An interface representing LocalityDto.
 */
export interface LocalityDto {
  simcCode?: string;
  tercCode?: string;
  locality?: string;
  county?: string;
  voivodeship?: string;
  community?: string;
  type?: string;
  hasAnyStreets?: boolean;
}

/**
 * An interface representing LocalityDtoPagedResult.
 */
export interface LocalityDtoPagedResult {
  results?: LocalityDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing TerritorialUnitDto.
 */
export interface TerritorialUnitDto {
  tercCode?: string;
  name?: string;
}

/**
 * An interface representing TerritorialUnitDtoPagedResult.
 */
export interface TerritorialUnitDtoPagedResult {
  results?: TerritorialUnitDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing StreetDto.
 */
export interface StreetDto {
  streetCode?: string;
  name1?: string;
  name2?: string;
}

/**
 * An interface representing StreetDtoPagedResult.
 */
export interface StreetDtoPagedResult {
  results?: StreetDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing TerritoryDto.
 */
export interface TerritoryDto {
  tercCode?: string;
  simcCode?: string;
  name?: string;
  voivodeship?: string;
  voivodeshipTerc?: string;
  county?: string;
  countyTerc?: string;
  community?: string;
  communityTerc?: string;
  /**
   * Possible values include: 'Voivodeship', 'County', 'UrbanCommunity', 'CountryCommunity',
   * 'CountryArea', 'City', 'CityOnCountyStatus', 'CapitalCity', 'District', 'Delegacy',
   * 'UrbanCountryCommunity'
   */
  territorialUnitType?: TerritorialUnitType;
}

/**
 * An interface representing TerritoryDtoPagedResult.
 */
export interface TerritoryDtoPagedResult {
  results?: TerritoryDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing UserDto.
 */
export interface UserDto {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  subject?: string;
  roles?: RoleValueDto[];
  rolePermissions?: RolePermissionDto[];
}

/**
 * An interface representing UserListDto.
 */
export interface UserListDto {
  id?: string;
  email?: string;
  fullName?: string;
  subject?: string;
}

/**
 * An interface representing UserListDtoPagedResult.
 */
export interface UserListDtoPagedResult {
  results?: UserListDto[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing AddUserRoleCommand.
 */
export interface AddUserRoleCommand {
  userId?: string;
  roleId?: string;
  validFrom?: Date;
  validTo?: Date;
}

/**
 * An interface representing DeleteUserRoleCommand.
 */
export interface DeleteUserRoleCommand {
  userId?: string;
  roleId?: string;
}

/**
 * An interface representing UserManualFileDto.
 */
export interface UserManualFileDto {
  fileName?: string;
  fileExtension?: string;
  mimeType?: string;
  data?: Uint8Array;
}

/**
 * An interface representing UserNotificationDTO.
 */
export interface UserNotificationDTO {
  id?: string;
  occurrenceId?: string;
  header?: string;
  description?: string;
  dateFrom?: Date;
  dateTo?: Date;
  creationDate?: Date;
  /**
   * Possible values include: 'Intervention', 'Statement', 'User', 'ReportingProcess', 'Activity'
   */
  occurrenceDomainType?: OccurrenceDomainType;
  instanceId?: string;
  notificationDefinitionId?: string;
  /**
   * Possible values include: 'PositiveAotmitDecisionReceived', 'NegativeAotmitDecisionReceived',
   * 'ConditionallyPositiveAotmitDecisionReceived', 'NPZStatementRejection',
   * 'MZ49StatementRejection', 'MZ53StatementRejection', 'NPZCriticalStatementSendDate',
   * 'MZ49CriticalStatementSendDate', 'MZ53CriticalStatementSendDate',
   * 'InterventionRealizationStartDateChanged', 'InterventionRealizationEndDateChanged',
   * 'StatementAttachmentImportSucedded', 'StatementAttachmentImportFailed', 'UserPrivateEvent',
   * 'NPZStatementClosed', 'MZ49StatementClosed', 'MZ53StatementClosed', 'NPZStatementReClosed',
   * 'MZ49StatementReClosed', 'MZ53StatementReClosed', 'NPZStatementAccepted',
   * 'MZ49StatementAccepted', 'MZ53StatementAccepted', 'IkpRecrutationStart', 'IkpRecrutationEnd',
   * 'ExportFinished'
   */
  occurrenceType?: OccurrenceType;
  additionalData?: string;
  hasActiveChannel?: boolean;
}

/**
 * An interface representing UserNotificationDTOPagedResult.
 */
export interface UserNotificationDTOPagedResult {
  results?: UserNotificationDTO[];
  rowCount?: number;
  pageSize?: number;
  currentPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalPagesNumber?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasPreviousPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hasNextPage?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly firstRowOnPage?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRowOnPage?: number;
}

/**
 * An interface representing MarkAsReadCommand.
 */
export interface MarkAsReadCommand {
  id?: string;
}

/**
 * An interface representing ToggleActiveOfSameKindCommand.
 */
export interface ToggleActiveOfSameKindCommand {
  userNotificationId?: string;
}

/**
 * An interface representing ActivateUserNotificationCommand.
 */
export interface ActivateUserNotificationCommand {
  notificationId?: string;
}

/**
 * An interface representing DeactivateUserNotificationCommand.
 */
export interface DeactivateUserNotificationCommand {
  notificationId?: string;
}

/**
 * An interface representing UpdateUserNotificationChannelsCommand.
 */
export interface UpdateUserNotificationChannelsCommand {
  notificationId?: string;
  systemChannelActive?: boolean;
  emailChannelActive?: boolean;
}

/**
 * An interface representing ProfiBazaAPIOptions.
 */
export interface ProfiBazaAPIOptions extends ServiceClientOptions {
  baseUri?: string;
}

/**
 * Optional Parameters.
 */
export interface AccountSaveDashboardSettingsOptionalParams extends msRest.RequestOptionsBase {
  body?: SaveDashboardSettingsCommand;
}

/**
 * Optional Parameters.
 */
export interface AccountSaveGridSettingsOptionalParams extends msRest.RequestOptionsBase {
  body?: SaveGridSettingsCommand;
}

/**
 * Optional Parameters.
 */
export interface AccountUpdateProfileOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateUserInCRUiPCommand;
}

/**
 * Optional Parameters.
 */
export interface ActivityCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateActivityCommand;
}

/**
 * Optional Parameters.
 */
export interface ActivityUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateActivityCommand;
}

/**
 * Optional Parameters.
 */
export interface ActivityFormSaveOrbeonActivityFormOptionalParams extends msRest.RequestOptionsBase {
  activityId?: string;
  formPartId?: string;
  document?: string;
}

/**
 * Optional Parameters.
 */
export interface AOTMiTApiProjectRegisteredAOTMITOptionalParams extends msRest.RequestOptionsBase {
  body?: ProjectRegisteredCommand;
}

/**
 * Optional Parameters.
 */
export interface AOTMiTApiSetAOTMiTStatusOptionalParams extends msRest.RequestOptionsBase {
  body?: SetStatusCommand;
}

/**
 * Optional Parameters.
 */
export interface AOTMiTApiOpinionAOTMiTOptionalParams extends msRest.RequestOptionsBase {
  body?: OpinionAOTMiTCommand;
}

/**
 * Optional Parameters.
 */
export interface AOTMiTApiUploadRecommendationsOptionalParams extends msRest.RequestOptionsBase {
  body?: AddRecommendationsCommand;
}

/**
 * Optional Parameters.
 */
export interface AOTMiTApiUploadDetailedReportAnnexOptionalParams extends msRest.RequestOptionsBase {
  body?: AddDetailedReportAnnexCommand;
}

/**
 * Optional Parameters.
 */
export interface AOTMITMockCreateOpinionOptionalParams extends msRest.RequestOptionsBase {
  body?: AOTMiTCreatorRequest;
}

/**
 * Optional Parameters.
 */
export interface ApiKeyGenerateOptionalParams extends msRest.RequestOptionsBase {
  body?: GenerateApiKeyCommand;
}

/**
 * Optional Parameters.
 */
export interface ApiKeyRegenerateOptionalParams extends msRest.RequestOptionsBase {
  body?: RegenerateApiKeyCommand;
}

/**
 * Optional Parameters.
 */
export interface CalendarEventsGetCalendarEventsOptionalParams extends msRest.RequestOptionsBase {
  dateFrom?: Date;
  dateTo?: Date;
}

/**
 * Optional Parameters.
 */
export interface DictionaryCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateDictionaryCommand;
}

/**
 * Optional Parameters.
 */
export interface DictionaryUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateDictionaryCommand;
}

/**
 * Optional Parameters.
 */
export interface DictionaryListOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface DictionaryGetItemsByCodeNameAndProcessOptionalParams extends msRest.RequestOptionsBase {
  dateFrom?: Date;
  dateTo?: Date;
  parentId?: number;
  /**
   * Default value: false.
   */
  flatDictionary?: boolean;
}

/**
 * Optional Parameters.
 */
export interface DictionarySearchOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  dictionaryVersionId?: number;
  codeName?: string;
  /**
   * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
   * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
   */
  interventionPath?: DictionaryInterventionPath;
  dateFrom?: Date;
  dateTo?: Date;
}

/**
 * Optional Parameters.
 */
export interface DictionaryValueCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateDictionaryValueCommand;
}

/**
 * Optional Parameters.
 */
export interface DictionaryValueUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateDictionaryValueCommand;
}

/**
 * Optional Parameters.
 */
export interface DictionaryValueOrderOptionalParams extends msRest.RequestOptionsBase {
  body?: SetOrderValueCommand;
}

/**
 * Optional Parameters.
 */
export interface DictionaryValueDeleteMethodOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: false.
   */
  deleteDescendants?: boolean;
}

/**
 * Optional Parameters.
 */
export interface DictionaryValueGetChildrensOptionalParams extends msRest.RequestOptionsBase {
  interventionPaths?: DictionaryInterventionPath[];
}

/**
 * Optional Parameters.
 */
export interface DictionaryValueGetValuesByIdsOptionalParams extends msRest.RequestOptionsBase {
  ids?: number[];
}

/**
 * Optional Parameters.
 */
export interface DictionaryValueUpdateParentOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateDictionaryValueParentCommand;
}

/**
 * Optional Parameters.
 */
export interface DictionaryValueCheckDictionaryValueChangeVersionOptionalParams extends msRest.RequestOptionsBase {
  body?: CheckDictionaryValueChangeVersionQuery;
}

/**
 * Optional Parameters.
 */
export interface DictionaryValueGetValuesByObjectOptionalParams extends msRest.RequestOptionsBase {
  objectId?: string;
  /**
   * Possible values include: 'Intervention', 'Activity', 'Population', 'SponsoringCompany'
   */
  objectType?: BusinessObjectType;
}

/**
 * Optional Parameters.
 */
export interface DictionaryVersionCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateDictionaryVersionCommand;
}

/**
 * Optional Parameters.
 */
export interface DictionaryVersionUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateDictionaryVersionCommand;
}

/**
 * Optional Parameters.
 */
export interface DictionaryVersionActivateOptionalParams extends msRest.RequestOptionsBase {
  body?: ActivateDictionaryVersionCommand;
}

/**
 * Optional Parameters.
 */
export interface DictionaryVersionDeactivateOptionalParams extends msRest.RequestOptionsBase {
  body?: DeactivateDictionaryVersionCommand;
}

/**
 * Optional Parameters.
 */
export interface DictionaryVersionGetItemsOptionalParams extends msRest.RequestOptionsBase {
  interventionPaths?: DictionaryInterventionPath[];
}

/**
 * Optional Parameters.
 */
export interface DictionaryVersionCloneOptionalParams extends msRest.RequestOptionsBase {
  body?: CloneDictionaryVersionCommand;
}

/**
 * Optional Parameters.
 */
export interface DictionaryVersionExportByIdOptionalParams extends msRest.RequestOptionsBase {
  id?: number;
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface DictionaryVersionImportMethodOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
  isCodeName?: boolean;
  dictionaryId?: number;
}

/**
 * Optional Parameters.
 */
export interface DocumentAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  /**
   * Possible values include: 'All', 'Interventions', 'ArchiveInterventions', 'ArchiveStatements',
   * 'Statements', 'VoivodeOpinion', 'AOTMiTPPZ', 'AOTMiTRecommendations', 'AOTMiTAnnex',
   * 'AOTMiTPronouncements', 'AOTMiTFinalReport'
   */
  filter?: SourceDocumentFilter;
  labels?: string[];
}

/**
 * Optional Parameters.
 */
export interface DocumentUploadVoivodeOpinionOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface DocumentGetFileListOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  /**
   * Possible values include: 'All', 'Interventions', 'ArchiveInterventions', 'ArchiveStatements',
   * 'Statements', 'VoivodeOpinion', 'AOTMiTPPZ', 'AOTMiTRecommendations', 'AOTMiTAnnex',
   * 'AOTMiTPronouncements', 'AOTMiTFinalReport'
   */
  filter?: SourceDocumentFilter;
  labels?: string[];
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface DocumentTagAddTagOptionalParams extends msRest.RequestOptionsBase {
  body?: AddSourceDocumentTagCommand;
}

/**
 * Optional Parameters.
 */
export interface DocumentTagDeleteTagOptionalParams extends msRest.RequestOptionsBase {
  body?: DeleteSourceDocumentTagCommand;
}

/**
 * Optional Parameters.
 */
export interface FileSetCategoryOptionalParams extends msRest.RequestOptionsBase {
  body?: SetFileCategoryCommand;
}

/**
 * Optional Parameters.
 */
export interface FormPartCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateFormPartCommand;
}

/**
 * Optional Parameters.
 */
export interface FormPartUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateFormPartCommand;
}

/**
 * Optional Parameters.
 */
export interface FormPartOrderOptionalParams extends msRest.RequestOptionsBase {
  body?: SetOrderFormPartCommand;
}

/**
 * Optional Parameters.
 */
export interface FormVersionCreateOptionalParams extends msRest.RequestOptionsBase {
  formId?: string;
  validFrom?: Date;
  xSLTDefinition?: msRest.HttpRequestBody;
  xLSXDefinition?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface FormVersionUpdateOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
  validFrom?: Date;
  xSLTDefinition?: msRest.HttpRequestBody;
  xLSXDefinition?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface FormVersionActivateOptionalParams extends msRest.RequestOptionsBase {
  body?: ActivateFormVersionCommand;
}

/**
 * Optional Parameters.
 */
export interface FormVersionDeactivateOptionalParams extends msRest.RequestOptionsBase {
  body?: DeactivateFormVersionCommand;
}

/**
 * Optional Parameters.
 */
export interface FormVersionCloneOptionalParams extends msRest.RequestOptionsBase {
  body?: CloneFormVersionCommand;
}

/**
 * Optional Parameters.
 */
export interface IKPProjectRegisteredAOTMITOptionalParams extends msRest.RequestOptionsBase {
  minAge?: number;
  maxAge?: number;
  /**
   * Possible values include: 'Years', 'Weeks'
   */
  ageUnit?: AgeUnit;
  /**
   * Possible values include: 'Male', 'Female', 'All'
   */
  gender?: Gender;
  areaTercCodes?: string[];
  iCD10Codes?: string[];
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface InterventionAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  /**
   * Possible values include: 'Intervention', 'Patterns', 'Archive', 'Canceled', 'SelectedIds'
   */
  filter?: InterventionFilter;
  labels?: string[];
  selectedIds?: string[];
}

/**
 * Optional Parameters.
 */
export interface InterventionCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateInterventionCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateInterventionCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionCreateOnExistingOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateInterventionOnExistingCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionSuspendOptionalParams extends msRest.RequestOptionsBase {
  body?: SuspendInterventionCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionUnsuspendOptionalParams extends msRest.RequestOptionsBase {
  body?: UnsuspendInterventionCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionGetFileListOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  labels?: string[];
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
   */
  fileType?: FileType;
  /**
   * Possible values include: 'Intervention', 'Patterns', 'Archive', 'Canceled', 'SelectedIds'
   */
  filter?: InterventionFilter;
}

/**
 * Optional Parameters.
 */
export interface InterventionGetFileDetailsOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  labels?: string[];
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
   */
  fileType?: FileType;
  /**
   * Possible values include: 'Intervention', 'Patterns', 'Archive', 'Canceled', 'SelectedIds'
   */
  filter?: InterventionFilter;
}

/**
 * Optional Parameters.
 */
export interface InterventionGetFileFlatListOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  labels?: string[];
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
   */
  fileType?: FileType;
  isTechnicalVersion?: boolean;
  /**
   * Possible values include: 'Intervention', 'Patterns', 'Archive', 'Canceled', 'SelectedIds'
   */
  filter?: InterventionFilter;
}

/**
 * Optional Parameters.
 */
export interface InterventionArchiveOptionalParams extends msRest.RequestOptionsBase {
  body?: ArchiveInterventionCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionUnarchiveOptionalParams extends msRest.RequestOptionsBase {
  body?: UnarchiveInterventionCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionUploadAttachmentOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
  file?: msRest.HttpRequestBody;
  fileCategoryDictionaryValueId?: number;
  fileCategoryDictionaryOtherValue?: string;
}

/**
 * Optional Parameters.
 */
export interface InterventionCancelOptionalParams extends msRest.RequestOptionsBase {
  body?: CancelInterventionCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionSendInterventionToAOTMITOptionalParams extends msRest.RequestOptionsBase {
  body?: SendInterventionCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionDefineTooltipOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateTooltipCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionApiGetAggregateSubjectOptionalParams extends msRest.RequestOptionsBase {
  dateFrom?: Date;
  dateTo?: Date;
}

/**
 * Optional Parameters.
 */
export interface InterventionApiCreateCommentOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateInterventionAOTMiTCommentCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionCommentsCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateInterventionCommentCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionCommentsUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateInterventionCommentCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionCommentsDeleteMethodOptionalParams extends msRest.RequestOptionsBase {
  body?: DeleteInterventionCommentCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionCommentsAllOptionalParams extends msRest.RequestOptionsBase {
  interventionId?: string;
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface InterventionCommentsSetStatusOptionalParams extends msRest.RequestOptionsBase {
  body?: SetInterventionCommentStatusCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionFormSaveOrbeonInterventionFormOptionalParams extends msRest.RequestOptionsBase {
  interventionId?: string;
  document?: string;
}

/**
 * Optional Parameters.
 */
export interface InterventionTagAddTagOptionalParams extends msRest.RequestOptionsBase {
  body?: AddInterventionTagCommand;
}

/**
 * Optional Parameters.
 */
export interface InterventionTagDeleteTagOptionalParams extends msRest.RequestOptionsBase {
  body?: DeleteInterventionTagCommand;
}

/**
 * Optional Parameters.
 */
export interface KindSubjectAddRoleOptionalParams extends msRest.RequestOptionsBase {
  body?: AddKindSubjectRoleCommand;
}

/**
 * Optional Parameters.
 */
export interface KindSubjectDeleteRoleOptionalParams extends msRest.RequestOptionsBase {
  body?: DeleteKindSubjectRoleCommand;
}

/**
 * Optional Parameters.
 */
export interface NotificationDefinitionUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateNotificationDefinionCommand;
}

/**
 * Optional Parameters.
 */
export interface NotificationDefinitionActivateOptionalParams extends msRest.RequestOptionsBase {
  body?: ActivateNotificationDefinionCommand;
}

/**
 * Optional Parameters.
 */
export interface NotificationDefinitionDeactivateOptionalParams extends msRest.RequestOptionsBase {
  body?: DeactivateNotificationDefinionCommand;
}

/**
 * Optional Parameters.
 */
export interface NotificationDefinitionUpdateChannelsOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateNotificationDefinionChannelsCommand;
}

/**
 * Optional Parameters.
 */
export interface NotificationOccurrenceCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateNotificationOccurrenceCommand;
}

/**
 * Optional Parameters.
 */
export interface NotificationOccurrenceUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateNotificationOccurrenceCommand;
}

/**
 * Optional Parameters.
 */
export interface PatternCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreatePatternCommand;
}

/**
 * Optional Parameters.
 */
export interface PatternUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdatePatternCommand;
}

/**
 * Optional Parameters.
 */
export interface PatternGetByIdOptionalParams extends msRest.RequestOptionsBase {
  interventionId?: string;
}

/**
 * Optional Parameters.
 */
export interface PatternActivateOptionalParams extends msRest.RequestOptionsBase {
  body?: ActivatePatternCommand;
}

/**
 * Optional Parameters.
 */
export interface PatternDeactivateOptionalParams extends msRest.RequestOptionsBase {
  body?: DeactivatePatternCommand;
}

/**
 * Optional Parameters.
 */
export interface PatternCreateOnExistingOptionalParams extends msRest.RequestOptionsBase {
  body?: CreatePatternOnExistingCommand;
}

/**
 * Optional Parameters.
 */
export interface ProposalGetProposalsOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface ProposalConsiderProposalOptionalParams extends msRest.RequestOptionsBase {
  body?: ConsiderProposalCommand;
}

/**
 * Optional Parameters.
 */
export interface ProposalCreateOptionalParams extends msRest.RequestOptionsBase {
  proposalAccesType?: any;
  email?: string;
  subjectId?: string;
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface ProposalGetProposalsAdminOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface ProposalAddAttachmentProposalOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface RoleCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateRoleCommand;
}

/**
 * Optional Parameters.
 */
export interface RoleUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateRoleCommand;
}

/**
 * Optional Parameters.
 */
export interface RoleActivateOptionalParams extends msRest.RequestOptionsBase {
  body?: ActivateRoleCommand;
}

/**
 * Optional Parameters.
 */
export interface RoleDeactivateOptionalParams extends msRest.RequestOptionsBase {
  body?: DeactivateRoleCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementApiGetByIdOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Possible values include: 'JSON', 'PDF'
   */
  contentType?: ContentType;
}

/**
 * Optional Parameters.
 */
export interface StatementCommentsAllOptionalParams extends msRest.RequestOptionsBase {
  statementId?: string;
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface StatementCommentsCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateCommentCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementCommentsUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateCommentCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementCommentsDeleteMethodOptionalParams extends msRest.RequestOptionsBase {
  body?: DeleteCommentCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementCommentsSetAsReadOptionalParams extends msRest.RequestOptionsBase {
  body?: SetCommentReadCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementCommentsSetAsProcessedOptionalParams extends msRest.RequestOptionsBase {
  body?: SetCommentProcessedCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  /**
   * Possible values include: 'Statement', 'Archive'
   */
  filter?: StatementFilter;
  labels?: string[];
}

/**
 * Optional Parameters.
 */
export interface StatementsCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateStatementCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsArchiveOptionalParams extends msRest.RequestOptionsBase {
  body?: ArchiveStatementCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsUnarchiveOptionalParams extends msRest.RequestOptionsBase {
  body?: UnarchiveStatementCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsSetMzCompletedOptionalParams extends msRest.RequestOptionsBase {
  body?: SetStatementMZCompletedCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsSetMzAcceptedOptionalParams extends msRest.RequestOptionsBase {
  body?: SetStatementMZAcceptedCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsSetMzRejectedOptionalParams extends msRest.RequestOptionsBase {
  body?: SetStatementMZRejectedCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsSetNpzCompletedOptionalParams extends msRest.RequestOptionsBase {
  body?: SetStatementNPZCompletedCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsSetNpzAcceptedOptionalParams extends msRest.RequestOptionsBase {
  body?: SetStatementNPZAcceptedCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsSetNpzRejectedOptionalParams extends msRest.RequestOptionsBase {
  body?: SetStatementNPZRejectedCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsUploadAttachmentOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
  file?: msRest.HttpRequestBody;
  fileCategoryDictionaryValueId?: number;
  fileCategoryDictionaryOtherValue?: string;
}

/**
 * Optional Parameters.
 */
export interface StatementsImportStatusOptionalParams extends msRest.RequestOptionsBase {
  body?: ImportStatusCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsGetFileDetailsOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  labels?: string[];
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface StatementsGetFileListOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  labels?: string[];
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface StatementsGetFileFlatListOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  labels?: string[];
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface StatementsGetSingleStatementExportOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  labels?: string[];
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface StatementsCopyOptionalParams extends msRest.RequestOptionsBase {
  body?: CopyStatementCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsCorrectOptionalParams extends msRest.RequestOptionsBase {
  body?: CorrectStatementCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsFormSaveStatementSectionOptionalParams extends msRest.RequestOptionsBase {
  statementId?: string;
  formPartId?: string;
  document?: string;
}

/**
 * Optional Parameters.
 */
export interface StatementsTagAddTagOptionalParams extends msRest.RequestOptionsBase {
  body?: AddStatementTagCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsTagDeleteTagOptionalParams extends msRest.RequestOptionsBase {
  body?: DeleteStatementTagCommand;
}

/**
 * Optional Parameters.
 */
export interface StatementsTestAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface StatementsTestCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateStatementTestCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  /**
   * Default value: false.
   */
  onlyActiveSubjects?: boolean;
}

/**
 * Optional Parameters.
 */
export interface SubjectCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateSubjectApiCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateSubjectApiCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectGetImplementingCompanySubjectsOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface SubjectGetSponsoringCompanySubjectsOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface SubjectMergeSubjectsOptionalParams extends msRest.RequestOptionsBase {
  body?: MergeSubjectsApiCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectGetProposalPossibilitySubjectsOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface SubjectIsMergedSubjectOptionalParams extends msRest.RequestOptionsBase {
  body?: string[];
}

/**
 * Optional Parameters.
 */
export interface SubjectGetFileListOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface SubjectGetFileDetailsOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  isCheckedAll?: boolean;
  checked?: string[];
  unchecked?: string[];
  /**
   * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
   */
  fileType?: FileType;
}

/**
 * Optional Parameters.
 */
export interface SubjectUploadAttachmentOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
  file?: msRest.HttpRequestBody;
  fileCategoryDictionaryValueId?: number;
  fileCategoryDictionaryOtherValue?: string;
}

/**
 * Optional Parameters.
 */
export interface SubjectPermissionListOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface SubjectAddRoleOptionalParams extends msRest.RequestOptionsBase {
  body?: AddSubjectRoleCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectDeleteRoleOptionalParams extends msRest.RequestOptionsBase {
  body?: DeleteSubjectRoleCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectCommentsCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateSubjectCommentCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectCommentsUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateSubjectCommentCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectCommentsDeleteMethodOptionalParams extends msRest.RequestOptionsBase {
  body?: DeleteSubjectCommentCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectCommentsAllOptionalParams extends msRest.RequestOptionsBase {
  subjectId?: string;
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface SubjectCommentsSetStatusOptionalParams extends msRest.RequestOptionsBase {
  body?: SetSubjectCommentStatusCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectReportingProcessAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface SubjectReportingProcessCreateOptionalParams extends msRest.RequestOptionsBase {
  body?: CreateSubjectReportingProcessCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectReportingProcessUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateSubjectReportingProcessCommand;
}

/**
 * Optional Parameters.
 */
export interface SubjectReportingProcessActivateOptionalParams extends msRest.RequestOptionsBase {
  body?: ActivateSubjectReportingProcessCommand;
}

/**
 * Optional Parameters.
 */
export interface TagGetTagSuggestionsOptionalParams extends msRest.RequestOptionsBase {
  query?: any;
}

/**
 * Optional Parameters.
 */
export interface TagAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface TagDeleteMethodOptionalParams extends msRest.RequestOptionsBase {
  body?: DeleteTagSuggestionCommand;
}

/**
 * Optional Parameters.
 */
export interface TerytAllOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface TerytGetLocalitiesByCommunityOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface TerytGetByLocalityOptionalParams extends msRest.RequestOptionsBase {
  sieveFilters?: string;
  sieveSorts?: string;
  sievePage?: number;
  sievePageSize?: number;
  simcCode?: string;
  tercCode?: string;
}

/**
 * Optional Parameters.
 */
export interface TerytVoivodeshipsOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface TerytCountiesOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  voivodeshipTercCode?: string;
}

/**
 * Optional Parameters.
 */
export interface TerytCommunitiesOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
  countyTercCode?: string;
}

/**
 * Optional Parameters.
 */
export interface TerytGetAreasByUrbanRuralCommunityOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface TerytSearchTerritoriesOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface UserListOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface UserAddRoleOptionalParams extends msRest.RequestOptionsBase {
  body?: AddUserRoleCommand;
}

/**
 * Optional Parameters.
 */
export interface UserDeleteRoleOptionalParams extends msRest.RequestOptionsBase {
  body?: DeleteUserRoleCommand;
}

/**
 * Optional Parameters.
 */
export interface UserManualGetManualOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Possible values include: 'Main', 'Administrative', 'Intervention', 'Statement', 'Subject',
   * 'RODO'
   */
  manualType?: UserManualType;
  formVersionId?: string;
  /**
   * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
   * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
   */
  interventionPath?: UserManualInterventionPath;
  /**
   * Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53',
   * 'AOTMiTFinalReport', 'ActivityProhealthActivities', 'ActivityResearch',
   * 'ActivitySupervisionMonitoring', 'ActivitySupervisionSanitary', 'ActivityStaffEducation',
   * 'ActivityStaffMonitoring', 'ActivityLegislation', 'InterventionProhealthActivities',
   * 'InterventionResearch', 'InterventionSupervisionMonitoring',
   * 'InterventionSupervisionSanitary', 'InterventionStaffEducation',
   * 'InterventionStaffMonitoring', 'InterventionLegislation'
   */
  reportType?: UserManualStatetmentReportType;
  /**
   * Possible values include: 'Global', 'Local'
   */
  userManualAdministrativeType?: UserManualAdministrativeType;
  /**
   * Possible values include: 'ProposalAccess', 'CRUIP'
   */
  rODOManualType?: RODOManualType;
}

/**
 * Optional Parameters.
 */
export interface UserManualCreateOptionalParams extends msRest.RequestOptionsBase {
  file?: msRest.HttpRequestBody;
  manualType?: any;
  interventionPath?: any;
  reportType?: any;
  userManualAdministrativeType?: any;
  formVersionId?: string;
  formValidFrom?: Date;
  rODOManualType?: any;
}

/**
 * Optional Parameters.
 */
export interface UserManualUpdateOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
  file?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface UserNotificationGetReadUserNotificationsOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface UserNotificationGetUnreadUserNotificationsOptionalParams extends msRest.RequestOptionsBase {
  filters?: string;
  sorts?: string;
  page?: number;
  pageSize?: number;
}

/**
 * Optional Parameters.
 */
export interface UserNotificationMarkAsReadOptionalParams extends msRest.RequestOptionsBase {
  body?: MarkAsReadCommand;
}

/**
 * Optional Parameters.
 */
export interface UserNotificationToggleActiveOfSameKindOptionalParams extends msRest.RequestOptionsBase {
  body?: ToggleActiveOfSameKindCommand;
}

/**
 * Optional Parameters.
 */
export interface UserNotificationSettingsActivateOptionalParams extends msRest.RequestOptionsBase {
  body?: ActivateUserNotificationCommand;
}

/**
 * Optional Parameters.
 */
export interface UserNotificationSettingsDeactivateOptionalParams extends msRest.RequestOptionsBase {
  body?: DeactivateUserNotificationCommand;
}

/**
 * Optional Parameters.
 */
export interface UserNotificationSettingsUpdateChannelsOptionalParams extends msRest.RequestOptionsBase {
  body?: UpdateUserNotificationChannelsCommand;
}

/**
 * Defines values for DictionaryInterventionPath.
 * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
 * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
 * @readonly
 * @enum {string}
 */
export enum DictionaryInterventionPath {
  PROHEALTHACTIVITIES = 'PROHEALTH_ACTIVITIES',
  RESEARCH = 'RESEARCH',
  SUPERVISIONMONITORING = 'SUPERVISION_MONITORING',
  SUPERVISIONSANITARY = 'SUPERVISION_SANITARY',
  STAFFEDUCATION = 'STAFF_EDUCATION',
  STAFFMONITORING = 'STAFF_MONITORING',
  LEGISLATION = 'LEGISLATION',
}

/**
 * Defines values for DictionaryStatus.
 * Possible values include: 'DRAFT', 'ACTIVE', 'INACTIVE', 'DELETED'
 * @readonly
 * @enum {string}
 */
export enum DictionaryStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

/**
 * Defines values for FormType.
 * Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53', 'AOTMiTFinalReport',
 * 'ActivityProhealthActivities', 'ActivityResearch', 'ActivitySupervisionMonitoring',
 * 'ActivitySupervisionSanitary', 'ActivityStaffEducation', 'ActivityStaffMonitoring',
 * 'ActivityLegislation', 'InterventionProhealthActivities', 'InterventionResearch',
 * 'InterventionSupervisionMonitoring', 'InterventionSupervisionSanitary',
 * 'InterventionStaffEducation', 'InterventionStaffMonitoring', 'InterventionLegislation'
 * @readonly
 * @enum {string}
 */
export enum FormType {
  StatementNPZ = 'StatementNPZ',
  StatementMZ49 = 'StatementMZ49',
  StatementMZ53 = 'StatementMZ53',
  AOTMiTFinalReport = 'AOTMiTFinalReport',
  ActivityProhealthActivities = 'ActivityProhealthActivities',
  ActivityResearch = 'ActivityResearch',
  ActivitySupervisionMonitoring = 'ActivitySupervisionMonitoring',
  ActivitySupervisionSanitary = 'ActivitySupervisionSanitary',
  ActivityStaffEducation = 'ActivityStaffEducation',
  ActivityStaffMonitoring = 'ActivityStaffMonitoring',
  ActivityLegislation = 'ActivityLegislation',
  InterventionProhealthActivities = 'InterventionProhealthActivities',
  InterventionResearch = 'InterventionResearch',
  InterventionSupervisionMonitoring = 'InterventionSupervisionMonitoring',
  InterventionSupervisionSanitary = 'InterventionSupervisionSanitary',
  InterventionStaffEducation = 'InterventionStaffEducation',
  InterventionStaffMonitoring = 'InterventionStaffMonitoring',
  InterventionLegislation = 'InterventionLegislation',
}

/**
 * Defines values for InterventionPatternType.
 * Possible values include: 'PROGRAM', 'NOT_PROGRAM', 'AOTMIT_RECOMENDATION'
 * @readonly
 * @enum {string}
 */
export enum InterventionPatternType {
  PROGRAM = 'PROGRAM',
  NOTPROGRAM = 'NOT_PROGRAM',
  AOTMITRECOMENDATION = 'AOTMIT_RECOMENDATION',
}

/**
 * Defines values for Permission.
 * Possible values include: 'Dictionaries_List_Preview', 'Dictionaries_Details_Preview',
 * 'Dictionaries_Versions_Modifications', 'Admin_Roles_Preview', 'Admin_Roles_Modifications',
 * 'Admin_Roles_Assignment_Global', 'Admin_Roles_Assignment_Local', 'Admin_ApiKeys_Management',
 * 'RIZP_Preview_Mine', 'RIZP_Preview_Subordinate', 'RIZP_Preview_All', 'RIZP_Modifications',
 * 'RIZP_Patterns_Modifications_Nationwide', 'RIZP_Patterns_Modifications_Voivodeship',
 * 'RIZP_Archive', 'Subjects_List_Preview', 'Subjects_Details_Preview',
 * 'Subjects_Modifications_Mine', 'Subjects_Modifications_With_Teryt_Mine',
 * 'Subjects_Modifications_All', 'Subjects_Modifications_With_Teryt_All',
 * 'Subjects_Modifications_Statement_Obligation', 'Subjects_Merge',
 * 'Subjects_Proposal_Possibility_Handling', 'Statements_Archive', 'Statements_Generation_MZ53',
 * 'Statements_Generation_MZ49', 'Statements_Generation_NPZ', 'Statement_Preview_Mine_MZ49',
 * 'Statement_Preview_Mine_MZ53', 'Statement_Preview_Mine_NPZ',
 * 'Statement_Preview_Subordinate_MZ49', 'Statement_Preview_Subordinate_MZ53',
 * 'Statement_Preview_Subordinate_NPZ', 'Statement_Preview_All_MZ49', 'Statement_Preview_All_MZ53',
 * 'Statement_Preview_All_NPZ', 'Statement_Verification_MZ49', 'Statement_Verification_MZ53',
 * 'Statement_Verification_NPZ', 'Statement_Critical_Warning', 'Proposals_Handling_All',
 * 'Proposals_Handling_Mine', 'DocumentsRepository_List_Preview', 'DocumentsRepository_Preview',
 * 'DocumentsRepository_VoivodeOpinions_Preview', 'DocumentsRepository_AllVoivodeOpinions_Preview',
 * 'DocumentsRepository_VoivodeOpinions_Modifications', 'Reports_MZ_Preview',
 * 'Reports_Programy_Zdrowotne_Preview', 'Reports_AOTMiT_Preview', 'Reports_RP_Preview',
 * 'Reports_SZ_Chorobowosc_Preview', 'Reports_SZ_Umieralnosc_Preview',
 * 'Reports_SZ_Deprywacja_Preview', 'Reports_SZ_Statusy_Preview', 'Reports_SZ_Demografia_Preview',
 * 'Reports_SZ_Preview', 'Reports_All_Preview', 'Reports_Technical', 'Aotmit_Add_Recommendations',
 * 'Aotmit_Add_Comments', 'Aotmit_Add_DetailedReportAnnex', 'Aotmit_Projects', 'Forms_Management',
 * 'Notifications_Management', 'UserManual_Management', 'Tooltip_Management',
 * 'Tags_Management_Global', 'Tags_Management_Local', 'Aggregate_Data', 'IKP'
 * @readonly
 * @enum {string}
 */
export enum Permission {
  DictionariesListPreview = 'Dictionaries_List_Preview',
  DictionariesDetailsPreview = 'Dictionaries_Details_Preview',
  DictionariesVersionsModifications = 'Dictionaries_Versions_Modifications',
  AdminRolesPreview = 'Admin_Roles_Preview',
  AdminRolesModifications = 'Admin_Roles_Modifications',
  AdminRolesAssignmentGlobal = 'Admin_Roles_Assignment_Global',
  AdminRolesAssignmentLocal = 'Admin_Roles_Assignment_Local',
  AdminApiKeysManagement = 'Admin_ApiKeys_Management',
  RIZPPreviewMine = 'RIZP_Preview_Mine',
  RIZPPreviewSubordinate = 'RIZP_Preview_Subordinate',
  RIZPPreviewAll = 'RIZP_Preview_All',
  RIZPModifications = 'RIZP_Modifications',
  RIZPPatternsModificationsNationwide = 'RIZP_Patterns_Modifications_Nationwide',
  RIZPPatternsModificationsVoivodeship = 'RIZP_Patterns_Modifications_Voivodeship',
  RIZPArchive = 'RIZP_Archive',
  SubjectsListPreview = 'Subjects_List_Preview',
  SubjectsDetailsPreview = 'Subjects_Details_Preview',
  SubjectsModificationsMine = 'Subjects_Modifications_Mine',
  SubjectsModificationsWithTerytMine = 'Subjects_Modifications_With_Teryt_Mine',
  SubjectsModificationsAll = 'Subjects_Modifications_All',
  SubjectsModificationsWithTerytAll = 'Subjects_Modifications_With_Teryt_All',
  SubjectsModificationsStatementObligation = 'Subjects_Modifications_Statement_Obligation',
  SubjectsMerge = 'Subjects_Merge',
  SubjectsProposalPossibilityHandling = 'Subjects_Proposal_Possibility_Handling',
  StatementsArchive = 'Statements_Archive',
  StatementsGenerationMZ53 = 'Statements_Generation_MZ53',
  StatementsGenerationMZ49 = 'Statements_Generation_MZ49',
  StatementsGenerationNPZ = 'Statements_Generation_NPZ',
  StatementPreviewMineMZ49 = 'Statement_Preview_Mine_MZ49',
  StatementPreviewMineMZ53 = 'Statement_Preview_Mine_MZ53',
  StatementPreviewMineNPZ = 'Statement_Preview_Mine_NPZ',
  StatementPreviewSubordinateMZ49 = 'Statement_Preview_Subordinate_MZ49',
  StatementPreviewSubordinateMZ53 = 'Statement_Preview_Subordinate_MZ53',
  StatementPreviewSubordinateNPZ = 'Statement_Preview_Subordinate_NPZ',
  StatementPreviewAllMZ49 = 'Statement_Preview_All_MZ49',
  StatementPreviewAllMZ53 = 'Statement_Preview_All_MZ53',
  StatementPreviewAllNPZ = 'Statement_Preview_All_NPZ',
  StatementVerificationMZ49 = 'Statement_Verification_MZ49',
  StatementVerificationMZ53 = 'Statement_Verification_MZ53',
  StatementVerificationNPZ = 'Statement_Verification_NPZ',
  StatementCriticalWarning = 'Statement_Critical_Warning',
  ProposalsHandlingAll = 'Proposals_Handling_All',
  ProposalsHandlingMine = 'Proposals_Handling_Mine',
  DocumentsRepositoryListPreview = 'DocumentsRepository_List_Preview',
  DocumentsRepositoryPreview = 'DocumentsRepository_Preview',
  DocumentsRepositoryVoivodeOpinionsPreview = 'DocumentsRepository_VoivodeOpinions_Preview',
  DocumentsRepositoryAllVoivodeOpinionsPreview = 'DocumentsRepository_AllVoivodeOpinions_Preview',
  DocumentsRepositoryVoivodeOpinionsModifications = 'DocumentsRepository_VoivodeOpinions_Modifications',
  ReportsMZPreview = 'Reports_MZ_Preview',
  ReportsProgramyZdrowotnePreview = 'Reports_Programy_Zdrowotne_Preview',
  ReportsAOTMiTPreview = 'Reports_AOTMiT_Preview',
  ReportsRPPreview = 'Reports_RP_Preview',
  ReportsSZChorobowoscPreview = 'Reports_SZ_Chorobowosc_Preview',
  ReportsSZUmieralnoscPreview = 'Reports_SZ_Umieralnosc_Preview',
  ReportsSZDeprywacjaPreview = 'Reports_SZ_Deprywacja_Preview',
  ReportsSZStatusyPreview = 'Reports_SZ_Statusy_Preview',
  ReportsSZDemografiaPreview = 'Reports_SZ_Demografia_Preview',
  ReportsSZPreview = 'Reports_SZ_Preview',
  ReportsAllPreview = 'Reports_All_Preview',
  ReportsTechnical = 'Reports_Technical',
  AotmitAddRecommendations = 'Aotmit_Add_Recommendations',
  AotmitAddComments = 'Aotmit_Add_Comments',
  AotmitAddDetailedReportAnnex = 'Aotmit_Add_DetailedReportAnnex',
  AotmitProjects = 'Aotmit_Projects',
  FormsManagement = 'Forms_Management',
  NotificationsManagement = 'Notifications_Management',
  UserManualManagement = 'UserManual_Management',
  TooltipManagement = 'Tooltip_Management',
  TagsManagementGlobal = 'Tags_Management_Global',
  TagsManagementLocal = 'Tags_Management_Local',
  AggregateData = 'Aggregate_Data',
  IKP = 'IKP',
}

/**
 * Defines values for RoleType.
 * Possible values include: 'USER', 'ADMIN'
 * @readonly
 * @enum {string}
 */
export enum RoleType {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

/**
 * Defines values for RoleCategory.
 * Possible values include: 'LOCAL', 'CENTRAL'
 * @readonly
 * @enum {string}
 */
export enum RoleCategory {
  LOCAL = 'LOCAL',
  CENTRAL = 'CENTRAL',
}

/**
 * Defines values for SubjectKind.
 * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR', 'WCZP',
 * 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
 * 'EDUCATIONALUNIT'
 * @readonly
 * @enum {string}
 */
export enum SubjectKind {
  PSSE = 'PSSE',
  WSSE = 'WSSE',
  GSSE = 'GSSE',
  PZH = 'PZH',
  GIS = 'GIS',
  HOSPITAL = 'HOSPITAL',
  CONTRACTOR = 'CONTRACTOR',
  WCZP = 'WCZP',
  DEPARTMENT = 'DEPARTMENT',
  DISTRICT = 'DISTRICT',
  GM = 'GM',
  GMW = 'GMW',
  GW = 'GW',
  MNP = 'MNP',
  SP = 'SP',
  UM = 'UM',
  UW = 'UW',
  IC = 'IC',
  M = 'M',
  EDUCATIONALUNIT = 'EDUCATIONALUNIT',
}

/**
 * Defines values for UserManualType.
 * Possible values include: 'Main', 'Administrative', 'Intervention', 'Statement', 'Subject',
 * 'RODO'
 * @readonly
 * @enum {string}
 */
export enum UserManualType {
  Main = 'Main',
  Administrative = 'Administrative',
  Intervention = 'Intervention',
  Statement = 'Statement',
  Subject = 'Subject',
  RODO = 'RODO',
}

/**
 * Defines values for UserManualInterventionPath.
 * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
 * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
 * @readonly
 * @enum {string}
 */
export enum UserManualInterventionPath {
  PROHEALTHACTIVITIES = 'PROHEALTH_ACTIVITIES',
  RESEARCH = 'RESEARCH',
  SUPERVISIONMONITORING = 'SUPERVISION_MONITORING',
  SUPERVISIONSANITARY = 'SUPERVISION_SANITARY',
  STAFFEDUCATION = 'STAFF_EDUCATION',
  STAFFMONITORING = 'STAFF_MONITORING',
  LEGISLATION = 'LEGISLATION',
}

/**
 * Defines values for UserManualAdministrativeType.
 * Possible values include: 'Global', 'Local'
 * @readonly
 * @enum {string}
 */
export enum UserManualAdministrativeType {
  Global = 'Global',
  Local = 'Local',
}

/**
 * Defines values for UserManualStatetmentReportType.
 * Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53', 'AOTMiTFinalReport',
 * 'ActivityProhealthActivities', 'ActivityResearch', 'ActivitySupervisionMonitoring',
 * 'ActivitySupervisionSanitary', 'ActivityStaffEducation', 'ActivityStaffMonitoring',
 * 'ActivityLegislation', 'InterventionProhealthActivities', 'InterventionResearch',
 * 'InterventionSupervisionMonitoring', 'InterventionSupervisionSanitary',
 * 'InterventionStaffEducation', 'InterventionStaffMonitoring', 'InterventionLegislation'
 * @readonly
 * @enum {string}
 */
export enum UserManualStatetmentReportType {
  StatementNPZ = 'StatementNPZ',
  StatementMZ49 = 'StatementMZ49',
  StatementMZ53 = 'StatementMZ53',
  AOTMiTFinalReport = 'AOTMiTFinalReport',
  ActivityProhealthActivities = 'ActivityProhealthActivities',
  ActivityResearch = 'ActivityResearch',
  ActivitySupervisionMonitoring = 'ActivitySupervisionMonitoring',
  ActivitySupervisionSanitary = 'ActivitySupervisionSanitary',
  ActivityStaffEducation = 'ActivityStaffEducation',
  ActivityStaffMonitoring = 'ActivityStaffMonitoring',
  ActivityLegislation = 'ActivityLegislation',
  InterventionProhealthActivities = 'InterventionProhealthActivities',
  InterventionResearch = 'InterventionResearch',
  InterventionSupervisionMonitoring = 'InterventionSupervisionMonitoring',
  InterventionSupervisionSanitary = 'InterventionSupervisionSanitary',
  InterventionStaffEducation = 'InterventionStaffEducation',
  InterventionStaffMonitoring = 'InterventionStaffMonitoring',
  InterventionLegislation = 'InterventionLegislation',
}

/**
 * Defines values for RODOManualType.
 * Possible values include: 'ProposalAccess', 'CRUIP'
 * @readonly
 * @enum {string}
 */
export enum RODOManualType {
  ProposalAccess = 'ProposalAccess',
  CRUIP = 'CRUIP',
}

/**
 * Defines values for AgeUnit.
 * Possible values include: 'Years', 'Weeks'
 * @readonly
 * @enum {string}
 */
export enum AgeUnit {
  Years = 'Years',
  Weeks = 'Weeks',
}

/**
 * Defines values for RIZPSubjectKind.
 * Possible values include: 'PSSE', 'WSSE', 'GSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR', 'WCZP',
 * 'DEPARTMENT', 'DISTRICT', 'GM', 'GMW', 'GW', 'MNP', 'SP', 'UM', 'UW', 'IC', 'M',
 * 'EDUCATIONALUNIT'
 * @readonly
 * @enum {string}
 */
export enum RIZPSubjectKind {
  PSSE = 'PSSE',
  WSSE = 'WSSE',
  GSSE = 'GSSE',
  PZH = 'PZH',
  GIS = 'GIS',
  HOSPITAL = 'HOSPITAL',
  CONTRACTOR = 'CONTRACTOR',
  WCZP = 'WCZP',
  DEPARTMENT = 'DEPARTMENT',
  DISTRICT = 'DISTRICT',
  GM = 'GM',
  GMW = 'GMW',
  GW = 'GW',
  MNP = 'MNP',
  SP = 'SP',
  UM = 'UM',
  UW = 'UW',
  IC = 'IC',
  M = 'M',
  EDUCATIONALUNIT = 'EDUCATIONALUNIT',
}

/**
 * Defines values for OpinionAOTMiTEnum.
 * Possible values include: 'POSITIVE', 'NEGATIVE', 'CONDITIONALLY_POSITIVE',
 * 'NOT_SUBJECT_TO_OPINION', 'FINAL_REPORT'
 * @readonly
 * @enum {string}
 */
export enum OpinionAOTMiTEnum {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  CONDITIONALLYPOSITIVE = 'CONDITIONALLY_POSITIVE',
  NOTSUBJECTTOOPINION = 'NOT_SUBJECT_TO_OPINION',
  FINALREPORT = 'FINAL_REPORT',
}

/**
 * Defines values for FileCategoryAOTMiT.
 * Possible values include: 'REPORT', 'OPINION_RP', 'OPINION_PRESIDENT', 'FINAL_REPORT'
 * @readonly
 * @enum {string}
 */
export enum FileCategoryAOTMiT {
  REPORT = 'REPORT',
  OPINIONRP = 'OPINION_RP',
  OPINIONPRESIDENT = 'OPINION_PRESIDENT',
  FINALREPORT = 'FINAL_REPORT',
}

/**
 * Defines values for BusinessObjectType.
 * Possible values include: 'Intervention', 'Activity', 'Population', 'SponsoringCompany'
 * @readonly
 * @enum {string}
 */
export enum BusinessObjectType {
  Intervention = 'Intervention',
  Activity = 'Activity',
  Population = 'Population',
  SponsoringCompany = 'SponsoringCompany',
}

/**
 * Defines values for FileType.
 * Possible values include: 'XLSX', 'PDF', 'CSV', 'JSON'
 * @readonly
 * @enum {string}
 */
export enum FileType {
  XLSX = 'XLSX',
  PDF = 'PDF',
  CSV = 'CSV',
  JSON = 'JSON',
}

/**
 * Defines values for SourceDocumentFilter.
 * Possible values include: 'All', 'Interventions', 'ArchiveInterventions', 'ArchiveStatements',
 * 'Statements', 'VoivodeOpinion', 'AOTMiTPPZ', 'AOTMiTRecommendations', 'AOTMiTAnnex',
 * 'AOTMiTPronouncements', 'AOTMiTFinalReport'
 * @readonly
 * @enum {string}
 */
export enum SourceDocumentFilter {
  All = 'All',
  Interventions = 'Interventions',
  ArchiveInterventions = 'ArchiveInterventions',
  ArchiveStatements = 'ArchiveStatements',
  Statements = 'Statements',
  VoivodeOpinion = 'VoivodeOpinion',
  AOTMiTPPZ = 'AOTMiTPPZ',
  AOTMiTRecommendations = 'AOTMiTRecommendations',
  AOTMiTAnnex = 'AOTMiTAnnex',
  AOTMiTPronouncements = 'AOTMiTPronouncements',
  AOTMiTFinalReport = 'AOTMiTFinalReport',
}

/**
 * Defines values for DocumentSource.
 * Possible values include: 'OTHER', 'AOTMiT', 'INTERVENTION', 'MZ_49', 'MZ_53', 'NPZ', 'SUBJECT'
 * @readonly
 * @enum {string}
 */
export enum DocumentSource {
  OTHER = 'OTHER',
  AOTMiT = 'AOTMiT',
  INTERVENTION = 'INTERVENTION',
  MZ49 = 'MZ_49',
  MZ53 = 'MZ_53',
  NPZ = 'NPZ',
  SUBJECT = 'SUBJECT',
}

/**
 * Defines values for DocumentCategory.
 * Possible values include: 'Attachment', 'Statement', 'VoivodeOpinion'
 * @readonly
 * @enum {string}
 */
export enum DocumentCategory {
  Attachment = 'Attachment',
  Statement = 'Statement',
  VoivodeOpinion = 'VoivodeOpinion',
}

/**
 * Defines values for DocumentStatus.
 * Possible values include: 'ACCEPTED', 'COMPLETED', 'IN_VERIFICATION', 'DUPLICATE', 'TO_COMPLETE',
 * 'TO_IMPROVE', 'INCORRECT', 'ARCHIVED', 'REJECTED', 'RECLOSED', 'NEW', 'DELETED', 'DRAFT',
 * 'PLANNED', 'SUSPENDED', 'REALIZATION', 'PATTERN', 'CLOSED', 'CANCELED',
 * 'SENDED_TO_OPINION_AOTMIT', 'COMPLETED_UNREALIZED', 'COMPLETED_REALIZED', 'MERGED', 'MODIFIED',
 * 'ADDED'
 * @readonly
 * @enum {string}
 */
export enum DocumentStatus {
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  INVERIFICATION = 'IN_VERIFICATION',
  DUPLICATE = 'DUPLICATE',
  TOCOMPLETE = 'TO_COMPLETE',
  TOIMPROVE = 'TO_IMPROVE',
  INCORRECT = 'INCORRECT',
  ARCHIVED = 'ARCHIVED',
  REJECTED = 'REJECTED',
  RECLOSED = 'RECLOSED',
  NEW = 'NEW',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  PLANNED = 'PLANNED',
  SUSPENDED = 'SUSPENDED',
  REALIZATION = 'REALIZATION',
  PATTERN = 'PATTERN',
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED',
  SENDEDTOOPINIONAOTMIT = 'SENDED_TO_OPINION_AOTMIT',
  COMPLETEDUNREALIZED = 'COMPLETED_UNREALIZED',
  COMPLETEDREALIZED = 'COMPLETED_REALIZED',
  MERGED = 'MERGED',
  MODIFIED = 'MODIFIED',
  ADDED = 'ADDED',
}

/**
 * Defines values for DocumentSubjectKind.
 * Possible values include: 'MZ', 'JST'
 * @readonly
 * @enum {string}
 */
export enum DocumentSubjectKind {
  MZ = 'MZ',
  JST = 'JST',
}

/**
 * Defines values for ActionKind.
 * Possible values include: 'Added', 'Downloaded', 'Deleted'
 * @readonly
 * @enum {string}
 */
export enum ActionKind {
  Added = 'Added',
  Downloaded = 'Downloaded',
  Deleted = 'Deleted',
}

/**
 * Defines values for ChannelKind.
 * Possible values include: 'Form', 'External_System', 'External_System_PZH'
 * @readonly
 * @enum {string}
 */
export enum ChannelKind {
  Form = 'Form',
  ExternalSystem = 'External_System',
  ExternalSystemPZH = 'External_System_PZH',
}

/**
 * Defines values for DocumentAotmitType.
 * Possible values include: 'PPZ', 'Recommendation', 'DetailedReportAnnex', 'FinalReport',
 * 'Pronouncement'
 * @readonly
 * @enum {string}
 */
export enum DocumentAotmitType {
  PPZ = 'PPZ',
  Recommendation = 'Recommendation',
  DetailedReportAnnex = 'DetailedReportAnnex',
  FinalReport = 'FinalReport',
  Pronouncement = 'Pronouncement',
}

/**
 * Defines values for FormStatus.
 * Possible values include: 'DRAFT', 'ACTIVE', 'INACTIVE', 'DELETED'
 * @readonly
 * @enum {string}
 */
export enum FormStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

/**
 * Defines values for PageOrientation.
 * Possible values include: 'Landscape', 'Portrait'
 * @readonly
 * @enum {string}
 */
export enum PageOrientation {
  Landscape = 'Landscape',
  Portrait = 'Portrait',
}

/**
 * Defines values for Gender.
 * Possible values include: 'Male', 'Female', 'All'
 * @readonly
 * @enum {string}
 */
export enum Gender {
  Male = 'Male',
  Female = 'Female',
  All = 'All',
}

/**
 * Defines values for OpinionAOTMiTType.
 * Possible values include: 'POSITIVE', 'NEGATIVE', 'CONDITIONALLY_POSITIVE',
 * 'NOT_SUBJECT_TO_OPINION', 'FINAL_REPORT'
 * @readonly
 * @enum {string}
 */
export enum OpinionAOTMiTType {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  CONDITIONALLYPOSITIVE = 'CONDITIONALLY_POSITIVE',
  NOTSUBJECTTOOPINION = 'NOT_SUBJECT_TO_OPINION',
  FINALREPORT = 'FINAL_REPORT',
}

/**
 * Defines values for InterventionPath.
 * Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH', 'SUPERVISION_MONITORING',
 * 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING', 'LEGISLATION'
 * @readonly
 * @enum {string}
 */
export enum InterventionPath {
  PROHEALTHACTIVITIES = 'PROHEALTH_ACTIVITIES',
  RESEARCH = 'RESEARCH',
  SUPERVISIONMONITORING = 'SUPERVISION_MONITORING',
  SUPERVISIONSANITARY = 'SUPERVISION_SANITARY',
  STAFFEDUCATION = 'STAFF_EDUCATION',
  STAFFMONITORING = 'STAFF_MONITORING',
  LEGISLATION = 'LEGISLATION',
}

/**
 * Defines values for PublicHealthInterventionStatus.
 * Possible values include: 'DRAFT', 'PLANNED', 'SUSPENDED', 'COMPLETED_REALIZED',
 * 'COMPLETED_UNREALIZED', 'ARCHIVED', 'REALIZATION', 'DELETED', 'CANCELED',
 * 'SENDED_TO_OPINION_AOTMIT'
 * @readonly
 * @enum {string}
 */
export enum PublicHealthInterventionStatus {
  DRAFT = 'DRAFT',
  PLANNED = 'PLANNED',
  SUSPENDED = 'SUSPENDED',
  COMPLETEDREALIZED = 'COMPLETED_REALIZED',
  COMPLETEDUNREALIZED = 'COMPLETED_UNREALIZED',
  ARCHIVED = 'ARCHIVED',
  REALIZATION = 'REALIZATION',
  DELETED = 'DELETED',
  CANCELED = 'CANCELED',
  SENDEDTOOPINIONAOTMIT = 'SENDED_TO_OPINION_AOTMIT',
}

/**
 * Defines values for InterventionFilter.
 * Possible values include: 'Intervention', 'Patterns', 'Archive', 'Canceled', 'SelectedIds'
 * @readonly
 * @enum {string}
 */
export enum InterventionFilter {
  Intervention = 'Intervention',
  Patterns = 'Patterns',
  Archive = 'Archive',
  Canceled = 'Canceled',
  SelectedIds = 'SelectedIds',
}

/**
 * Defines values for PatternStatus.
 * Possible values include: 'DRAFT', 'ACTIVE', 'INACTIVE'
 * @readonly
 * @enum {string}
 */
export enum PatternStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/**
 * Defines values for InterventionCommentType.
 * Possible values include: 'PUBLIC', 'PRIVATE', 'IMPORTANT'
 * @readonly
 * @enum {string}
 */
export enum InterventionCommentType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  IMPORTANT = 'IMPORTANT',
}

/**
 * Defines values for InterventionCommentStatus.
 * Possible values include: 'ADDED', 'READ', 'PROCESSED', 'DELETED'
 * @readonly
 * @enum {string}
 */
export enum InterventionCommentStatus {
  ADDED = 'ADDED',
  READ = 'READ',
  PROCESSED = 'PROCESSED',
  DELETED = 'DELETED',
}

/**
 * Defines values for OccurrenceDomainType.
 * Possible values include: 'Intervention', 'Statement', 'User', 'ReportingProcess', 'Activity'
 * @readonly
 * @enum {string}
 */
export enum OccurrenceDomainType {
  Intervention = 'Intervention',
  Statement = 'Statement',
  User = 'User',
  ReportingProcess = 'ReportingProcess',
  Activity = 'Activity',
}

/**
 * Defines values for ProposalAccessType.
 * Possible values include: 'Administration', 'Normal'
 * @readonly
 * @enum {string}
 */
export enum ProposalAccessType {
  Administration = 'Administration',
  Normal = 'Normal',
}

/**
 * Defines values for ProposalStatus.
 * Possible values include: 'Accepted', 'Sended', 'Rejected', 'Canceled'
 * @readonly
 * @enum {string}
 */
export enum ProposalStatus {
  Accepted = 'Accepted',
  Sended = 'Sended',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
}

/**
 * Defines values for ContentType.
 * Possible values include: 'JSON', 'PDF'
 * @readonly
 * @enum {string}
 */
export enum ContentType {
  JSON = 'JSON',
  PDF = 'PDF',
}

/**
 * Defines values for StatementStatus.
 * Possible values include: 'DRAFT', 'ACCEPTED', 'CLOSED', 'RECLOSED', 'IN_VERIFICATION',
 * 'DUPLICATE', 'TO_COMPLETE', 'TO_IMPROVE', 'INCORRECT', 'ARCHIVED', 'REJECTED'
 * @readonly
 * @enum {string}
 */
export enum StatementStatus {
  DRAFT = 'DRAFT',
  ACCEPTED = 'ACCEPTED',
  CLOSED = 'CLOSED',
  RECLOSED = 'RECLOSED',
  INVERIFICATION = 'IN_VERIFICATION',
  DUPLICATE = 'DUPLICATE',
  TOCOMPLETE = 'TO_COMPLETE',
  TOIMPROVE = 'TO_IMPROVE',
  INCORRECT = 'INCORRECT',
  ARCHIVED = 'ARCHIVED',
  REJECTED = 'REJECTED',
}

/**
 * Defines values for CommentStatus.
 * Possible values include: 'ADDED', 'READ', 'PROCESSED', 'DELETED'
 * @readonly
 * @enum {string}
 */
export enum CommentStatus {
  ADDED = 'ADDED',
  READ = 'READ',
  PROCESSED = 'PROCESSED',
  DELETED = 'DELETED',
}

/**
 * Defines values for CommentType.
 * Possible values include: 'IMPORTANT', 'PUBLIC', 'PRIVATE'
 * @readonly
 * @enum {string}
 */
export enum CommentType {
  IMPORTANT = 'IMPORTANT',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

/**
 * Defines values for StatementArea.
 * Possible values include: 'NPZ', 'MZ_49', 'MZ_53'
 * @readonly
 * @enum {string}
 */
export enum StatementArea {
  NPZ = 'NPZ',
  MZ49 = 'MZ_49',
  MZ53 = 'MZ_53',
}

/**
 * Defines values for StatementFilter.
 * Possible values include: 'Statement', 'Archive'
 * @readonly
 * @enum {string}
 */
export enum StatementFilter {
  Statement = 'Statement',
  Archive = 'Archive',
}

/**
 * Defines values for SubjectStatus.
 * Possible values include: 'NEW', 'MODIFIED', 'DELETED', 'MERGED'
 * @readonly
 * @enum {string}
 */
export enum SubjectStatus {
  NEW = 'NEW',
  MODIFIED = 'MODIFIED',
  DELETED = 'DELETED',
  MERGED = 'MERGED',
}

/**
 * Defines values for SubjectCommentStatus.
 * Possible values include: 'ADDED', 'READ', 'PROCESSED', 'DELETED'
 * @readonly
 * @enum {string}
 */
export enum SubjectCommentStatus {
  ADDED = 'ADDED',
  READ = 'READ',
  PROCESSED = 'PROCESSED',
  DELETED = 'DELETED',
}

/**
 * Defines values for SubjectCommentType.
 * Possible values include: 'PUBLIC', 'PRIVATE', 'IMPORTANT'
 * @readonly
 * @enum {string}
 */
export enum SubjectCommentType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  IMPORTANT = 'IMPORTANT',
}

/**
 * Defines values for SubjectReportingProcessStatus.
 * Possible values include: 'ACTIVE', 'INACTIVE', 'DELETED'
 * @readonly
 * @enum {string}
 */
export enum SubjectReportingProcessStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

/**
 * Defines values for ReportingArea.
 * Possible values include: 'MZ49', 'MZ53', 'NPZ'
 * @readonly
 * @enum {string}
 */
export enum ReportingArea {
  MZ49 = 'MZ49',
  MZ53 = 'MZ53',
  NPZ = 'NPZ',
}

/**
 * Defines values for TerritorialUnitType.
 * Possible values include: 'Voivodeship', 'County', 'UrbanCommunity', 'CountryCommunity',
 * 'CountryArea', 'City', 'CityOnCountyStatus', 'CapitalCity', 'District', 'Delegacy',
 * 'UrbanCountryCommunity'
 * @readonly
 * @enum {string}
 */
export enum TerritorialUnitType {
  Voivodeship = 'Voivodeship',
  County = 'County',
  UrbanCommunity = 'UrbanCommunity',
  CountryCommunity = 'CountryCommunity',
  CountryArea = 'CountryArea',
  City = 'City',
  CityOnCountyStatus = 'CityOnCountyStatus',
  CapitalCity = 'CapitalCity',
  District = 'District',
  Delegacy = 'Delegacy',
  UrbanCountryCommunity = 'UrbanCountryCommunity',
}

/**
 * Defines values for OccurrenceType.
 * Possible values include: 'PositiveAotmitDecisionReceived', 'NegativeAotmitDecisionReceived',
 * 'ConditionallyPositiveAotmitDecisionReceived', 'NPZStatementRejection',
 * 'MZ49StatementRejection', 'MZ53StatementRejection', 'NPZCriticalStatementSendDate',
 * 'MZ49CriticalStatementSendDate', 'MZ53CriticalStatementSendDate',
 * 'InterventionRealizationStartDateChanged', 'InterventionRealizationEndDateChanged',
 * 'StatementAttachmentImportSucedded', 'StatementAttachmentImportFailed', 'UserPrivateEvent',
 * 'NPZStatementClosed', 'MZ49StatementClosed', 'MZ53StatementClosed', 'NPZStatementReClosed',
 * 'MZ49StatementReClosed', 'MZ53StatementReClosed', 'NPZStatementAccepted',
 * 'MZ49StatementAccepted', 'MZ53StatementAccepted', 'IkpRecrutationStart', 'IkpRecrutationEnd',
 * 'ExportFinished'
 * @readonly
 * @enum {string}
 */
export enum OccurrenceType {
  PositiveAotmitDecisionReceived = 'PositiveAotmitDecisionReceived',
  NegativeAotmitDecisionReceived = 'NegativeAotmitDecisionReceived',
  ConditionallyPositiveAotmitDecisionReceived = 'ConditionallyPositiveAotmitDecisionReceived',
  NPZStatementRejection = 'NPZStatementRejection',
  MZ49StatementRejection = 'MZ49StatementRejection',
  MZ53StatementRejection = 'MZ53StatementRejection',
  NPZCriticalStatementSendDate = 'NPZCriticalStatementSendDate',
  MZ49CriticalStatementSendDate = 'MZ49CriticalStatementSendDate',
  MZ53CriticalStatementSendDate = 'MZ53CriticalStatementSendDate',
  InterventionRealizationStartDateChanged = 'InterventionRealizationStartDateChanged',
  InterventionRealizationEndDateChanged = 'InterventionRealizationEndDateChanged',
  StatementAttachmentImportSucedded = 'StatementAttachmentImportSucedded',
  StatementAttachmentImportFailed = 'StatementAttachmentImportFailed',
  UserPrivateEvent = 'UserPrivateEvent',
  NPZStatementClosed = 'NPZStatementClosed',
  MZ49StatementClosed = 'MZ49StatementClosed',
  MZ53StatementClosed = 'MZ53StatementClosed',
  NPZStatementReClosed = 'NPZStatementReClosed',
  MZ49StatementReClosed = 'MZ49StatementReClosed',
  MZ53StatementReClosed = 'MZ53StatementReClosed',
  NPZStatementAccepted = 'NPZStatementAccepted',
  MZ49StatementAccepted = 'MZ49StatementAccepted',
  MZ53StatementAccepted = 'MZ53StatementAccepted',
  IkpRecrutationStart = 'IkpRecrutationStart',
  IkpRecrutationEnd = 'IkpRecrutationEnd',
  ExportFinished = 'ExportFinished',
}

/**
 * Contains response data for the me operation.
 */
export type AccountMeResponse = AccountDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: AccountDetailsDto;
    };
};

/**
 * Contains response data for the profile operation.
 */
export type AccountProfileResponse = UserProfileDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserProfileDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type ActivityGetByIdResponse = ActivityDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ActivityDetailsDto;
    };
};

/**
 * Contains response data for the create operation.
 */
export type ActivityCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the generate operation.
 */
export type ApiKeyGenerateResponse = ApiKeyDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiKeyDto;
    };
};

/**
 * Contains response data for the regenerate operation.
 */
export type ApiKeyRegenerateResponse = ApiKeyDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiKeyDto;
    };
};

/**
 * Contains response data for the getSubject operation.
 */
export type AuthorizationInternalGetSubjectResponse = ISubject & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ISubject;
    };
};

/**
 * Contains response data for the getCalendarEvents operation.
 */
export type CalendarEventsGetCalendarEventsResponse = Array<CalendarEventDTO> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CalendarEventDTO[];
    };
};

/**
 * Contains response data for the getById operation.
 */
export type ContactGetByIdResponse = Array<ContactDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ContactDto[];
    };
};

/**
 * Contains response data for the create operation.
 */
export type DictionaryCreateResponse = Int32IIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Int32IIdDto;
    };
};

/**
 * Contains response data for the list operation.
 */
export type DictionaryListResponse = DictionaryViewDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryViewDtoPagedResult;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type DictionaryGetByIdResponse = DictionaryDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryDto;
    };
};

/**
 * Contains response data for the listAll operation.
 */
export type DictionaryListAllResponse = Array<DictionaryViewDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryViewDto[];
    };
};

/**
 * Contains response data for the getItemsByCodeNameAndProcess operation.
 */
export type DictionaryGetItemsByCodeNameAndProcessResponse = DictionaryInfoDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryInfoDto;
    };
};

/**
 * Contains response data for the search operation.
 */
export type DictionarySearchResponse = DictionaryValueWithPathViewDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryValueWithPathViewDtoPagedResult;
    };
};

/**
 * Contains response data for the create operation.
 */
export type DictionaryValueCreateResponse = Int32IIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Int32IIdDto;
    };
};

/**
 * Contains response data for the update operation.
 */
export type DictionaryValueUpdateResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the order operation.
 */
export type DictionaryValueOrderResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type DictionaryValueGetByIdResponse = DictionaryValueDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryValueDto;
    };
};

/**
 * Contains response data for the getChildrens operation.
 */
export type DictionaryValueGetChildrensResponse = Array<DictionaryValueItemDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryValueItemDto[];
    };
};

/**
 * Contains response data for the getValuesByIds operation.
 */
export type DictionaryValueGetValuesByIdsResponse = Array<DictionaryValueItemDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryValueItemDto[];
    };
};

/**
 * Contains response data for the updateParent operation.
 */
export type DictionaryValueUpdateParentResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getOtherValueHistory operation.
 */
export type DictionaryValueGetOtherValueHistoryResponse = Array<DictionaryOtherValueHistoryDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryOtherValueHistoryDto[];
    };
};

/**
 * Contains response data for the checkDictionaryValueChangeVersion operation.
 */
export type DictionaryValueCheckDictionaryValueChangeVersionResponse = Array<DictionaryValueChangeVersionDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryValueChangeVersionDto[];
    };
};

/**
 * Contains response data for the getValuesByObject operation.
 */
export type DictionaryValueGetValuesByObjectResponse = Array<DictionaryValueItemDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryValueItemDto[];
    };
};

/**
 * Contains response data for the create operation.
 */
export type DictionaryVersionCreateResponse = Int32IIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Int32IIdDto;
    };
};

/**
 * Contains response data for the update operation.
 */
export type DictionaryVersionUpdateResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the activate operation.
 */
export type DictionaryVersionActivateResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the deactivate operation.
 */
export type DictionaryVersionDeactivateResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the deleteMethod operation.
 */
export type DictionaryVersionDeleteMethodResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type DictionaryVersionGetByIdResponse = DictionaryVersionDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryVersionDto;
    };
};

/**
 * Contains response data for the getItems operation.
 */
export type DictionaryVersionGetItemsResponse = Array<DictionaryValueItemDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DictionaryValueItemDto[];
    };
};

/**
 * Contains response data for the clone operation.
 */
export type DictionaryVersionCloneResponse = Int32IIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Int32IIdDto;
    };
};

/**
 * Contains response data for the exportById operation.
 */
export type DictionaryVersionExportByIdResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the importMethod operation.
 */
export type DictionaryVersionImportMethodResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getAotmitRecommendationFileList operation.
 */
export type DocumentGetAotmitRecommendationFileListResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the all operation.
 */
export type DocumentAllResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type DocumentGetByIdResponse = DocumentDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: DocumentDto;
    };
};

/**
 * Contains response data for the uploadVoivodeOpinion operation.
 */
export type DocumentUploadVoivodeOpinionResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getFileList operation.
 */
export type DocumentGetFileListResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getById operation.
 */
export type ExporterGetByIdResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the download operation.
 */
export type FileDownloadResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the deleteMethod operation.
 */
export type FileDeleteMethodResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the preview operation.
 */
export type FilePreviewResponse = FileDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FileDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type FormAllResponse = Array<FormItemDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FormItemDto[];
    };
};

/**
 * Contains response data for the getById operation.
 */
export type FormGetByIdResponse = FormDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FormDto;
    };
};

/**
 * Contains response data for the getByType operation.
 */
export type FormGetByTypeResponse = FormDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FormDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type FormPartGetByIdResponse = FormPartDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FormPartDto;
    };
};

/**
 * Contains response data for the create operation.
 */
export type FormPartCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type FormVersionGetByIdResponse = FormVersionDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FormVersionDto;
    };
};

/**
 * Contains response data for the create operation.
 */
export type FormVersionCreateResponse = Int32IIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Int32IIdDto;
    };
};

/**
 * Contains response data for the clone operation.
 */
export type FormVersionCloneResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the projectRegisteredAOTMIT operation.
 */
export type IKPProjectRegisteredAOTMITResponse = PublicHealthInterventionIKPDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PublicHealthInterventionIKPDtoPagedResult;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type InterventionGetByIdResponse = InterventionDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: InterventionDetailsDto;
    };
};

/**
 * Contains response data for the getTinyById operation.
 */
export type InterventionGetTinyByIdResponse = InterventionDetailsTinyDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: InterventionDetailsTinyDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type InterventionAllResponse = InterventionListDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: InterventionListDtoPagedResult;
    };
};

/**
 * Contains response data for the create operation.
 */
export type InterventionCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getActivities operation.
 */
export type InterventionGetActivitiesResponse = Array<ActivityListItemDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ActivityListItemDto[];
    };
};

/**
 * Contains response data for the createOnExisting operation.
 */
export type InterventionCreateOnExistingResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getFileList operation.
 */
export type InterventionGetFileListResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getFileDetails operation.
 */
export type InterventionGetFileDetailsResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getFileFlatList operation.
 */
export type InterventionGetFileFlatListResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the uploadAttachment operation.
 */
export type InterventionUploadAttachmentResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getTooltipByName operation.
 */
export type InterventionGetTooltipByNameResponse = TooltipDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TooltipDto;
    };
};

/**
 * Contains response data for the getValidationWarnings operation.
 */
export type InterventionGetValidationWarningsResponse = Array<ValidationWarningDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ValidationWarningDto[];
    };
};

/**
 * Contains response data for the getActivitiesSummary operation.
 */
export type InterventionGetActivitiesSummaryResponse = ActivitiesSummaryDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ActivitiesSummaryDto;
    };
};

/**
 * Contains response data for the getAggregateSubject operation.
 */
export type InterventionApiGetAggregateSubjectResponse = Array<InterventionsAggregateDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: InterventionsAggregateDto[];
    };
};

/**
 * Contains response data for the getFields operation.
 */
export type InterventionApiGetFieldsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type InterventionCommentsGetByIdResponse = InterventionCommentDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: InterventionCommentDto;
    };
};

/**
 * Contains response data for the create operation.
 */
export type InterventionCommentsCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type InterventionCommentsAllResponse = InterventionCommentListItemDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: InterventionCommentListItemDtoPagedResult;
    };
};

/**
 * Contains response data for the getBySubjectKind operation.
 */
export type KindSubjectGetBySubjectKindResponse = KindSubjectDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: KindSubjectDto;
    };
};

/**
 * Contains response data for the getAll operation.
 */
export type NotificationDefinitionGetAllResponse = Array<NotificationDefinitionDTO> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: NotificationDefinitionDTO[];
    };
};

/**
 * Contains response data for the update operation.
 */
export type NotificationDefinitionUpdateResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type NotificationDefinitionGetByIdResponse = NotificationDefinitionDTO & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: NotificationDefinitionDTO;
    };
};

/**
 * Contains response data for the activate operation.
 */
export type NotificationDefinitionActivateResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the deactivate operation.
 */
export type NotificationDefinitionDeactivateResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updateChannels operation.
 */
export type NotificationDefinitionUpdateChannelsResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type NotificationOccurrenceGetByIdResponse = NotificationOccurrenceDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: NotificationOccurrenceDto;
    };
};

/**
 * Contains response data for the create operation.
 */
export type NotificationOccurrenceCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getByType operation.
 */
export type OrbeonGetByTypeResponse = Array<OrbeonFormDefinition> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrbeonFormDefinition[];
    };
};

/**
 * Contains response data for the all operation.
 */
export type PatternAllResponse = Array<PatternDigestDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PatternDigestDto[];
    };
};

/**
 * Contains response data for the create operation.
 */
export type PatternCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type PatternGetByIdResponse = PatternDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PatternDto;
    };
};

/**
 * Contains response data for the createOnExisting operation.
 */
export type PatternCreateOnExistingResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type PermissionAllResponse = Array<PermissionDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PermissionDto[];
    };
};

/**
 * Contains response data for the getProposals operation.
 */
export type ProposalGetProposalsResponse = ProposalItemDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProposalItemDtoPagedResult;
    };
};

/**
 * Contains response data for the considerProposal operation.
 */
export type ProposalConsiderProposalResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the create operation.
 */
export type ProposalCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getProposalsAdmin operation.
 */
export type ProposalGetProposalsAdminResponse = ProposalItemDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProposalItemDtoPagedResult;
    };
};

/**
 * Contains response data for the addAttachmentProposal operation.
 */
export type ProposalAddAttachmentProposalResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getProposal operation.
 */
export type ProposalGetProposalResponse = ProposalDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProposalDetailsDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type RoleAllResponse = Array<RoleItemDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: RoleItemDto[];
    };
};

/**
 * Contains response data for the create operation.
 */
export type RoleCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the update operation.
 */
export type RoleUpdateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type RoleGetByIdResponse = RoleDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: RoleDto;
    };
};

/**
 * Contains response data for the deleteMethod operation.
 */
export type RoleDeleteMethodResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type StatementApiGetByIdResponse = StatementDetailsExternalDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: StatementDetailsExternalDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type StatementCommentsGetByIdResponse = StatementCommentDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: StatementCommentDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type StatementCommentsAllResponse = StatementCommentListItemDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: StatementCommentListItemDtoPagedResult;
    };
};

/**
 * Contains response data for the create operation.
 */
export type StatementCommentsCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type StatementsGetByIdResponse = StatementDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: StatementDetailsDto;
    };
};

/**
 * Contains response data for the getStatementSummaryPdfById operation.
 */
export type StatementsGetStatementSummaryPdfByIdResponse = {
  /**
   * The parsed response body.
   */
  body: Uint8Array;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Uint8Array;
    };
};

/**
 * Contains response data for the all operation.
 */
export type StatementsAllResponse = StatementListItemDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: StatementListItemDtoPagedResult;
    };
};

/**
 * Contains response data for the create operation.
 */
export type StatementsCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the uploadAttachment operation.
 */
export type StatementsUploadAttachmentResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getFileDetails operation.
 */
export type StatementsGetFileDetailsResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getFileList operation.
 */
export type StatementsGetFileListResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getFileFlatList operation.
 */
export type StatementsGetFileFlatListResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getSingleStatementExport operation.
 */
export type StatementsGetSingleStatementExportResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the copy operation.
 */
export type StatementsCopyResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the correct operation.
 */
export type StatementsCorrectResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getHeaderByStatementId operation.
 */
export type StatementsFormGetHeaderByStatementIdResponse = StatementHeaderDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: StatementHeaderDto;
    };
};

/**
 * Contains response data for the getInterventionSectionByStatementId operation.
 */
export type StatementsFormGetInterventionSectionByStatementIdResponse = MZ49AutocompletionDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: MZ49AutocompletionDto;
    };
};

/**
 * Contains response data for the getNPZStatementByStatementId operation.
 */
export type StatementsFormGetNPZStatementByStatementIdResponse = Array<NPZAutocompletionDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: NPZAutocompletionDto[];
    };
};

/**
 * Contains response data for the getStatementParts operation.
 */
export type StatementsFormGetStatementPartsResponse = Array<StatementPartDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: StatementPartDto[];
    };
};

/**
 * Contains response data for the getById operation.
 */
export type StatementsTestGetByIdResponse = StatementTestDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: StatementTestDetailsDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type StatementsTestAllResponse = StatementTestListItemDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: StatementTestListItemDtoPagedResult;
    };
};

/**
 * Contains response data for the create operation.
 */
export type StatementsTestCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type SubjectAllResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the create operation.
 */
export type SubjectCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type SubjectGetByIdResponse = SubjectDetailsDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SubjectDetailsDto;
    };
};

/**
 * Contains response data for the getImplementingCompanySubjects operation.
 */
export type SubjectGetImplementingCompanySubjectsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getSponsoringCompanySubjects operation.
 */
export type SubjectGetSponsoringCompanySubjectsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the mergeSubjects operation.
 */
export type SubjectMergeSubjectsResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getProposalPossibilitySubjects operation.
 */
export type SubjectGetProposalPossibilitySubjectsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the isMergedSubject operation.
 */
export type SubjectIsMergedSubjectResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: SubjectIdNameDto;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: { [propertyName: string]: SubjectIdNameDto };
    };
};

/**
 * Contains response data for the getFileList operation.
 */
export type SubjectGetFileListResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getFileDetails operation.
 */
export type SubjectGetFileDetailsResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the getTinyById operation.
 */
export type SubjectGetTinyByIdResponse = SubjectDetailsTinyDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SubjectDetailsTinyDto;
    };
};

/**
 * Contains response data for the uploadAttachment operation.
 */
export type SubjectUploadAttachmentResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the permissionList operation.
 */
export type SubjectPermissionListResponse = SubjectPermissionListItemDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SubjectPermissionListItemDtoPagedResult;
    };
};

/**
 * Contains response data for the getById1 operation.
 */
export type SubjectGetById1Response = SubjectRolesDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SubjectRolesDto;
    };
};

/**
 * Contains response data for the getAggregateSubject operation.
 */
export type SubjectApiGetAggregateSubjectResponse = Array<SubjectsAggregateDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SubjectsAggregateDto[];
    };
};

/**
 * Contains response data for the getById operation.
 */
export type SubjectCommentsGetByIdResponse = SubjectCommentDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SubjectCommentDto;
    };
};

/**
 * Contains response data for the create operation.
 */
export type SubjectCommentsCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the all operation.
 */
export type SubjectCommentsAllResponse = SubjectCommentListItemDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SubjectCommentListItemDtoPagedResult;
    };
};

/**
 * Contains response data for the all operation.
 */
export type SubjectReportingProcessAllResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the create operation.
 */
export type SubjectReportingProcessCreateResponse = GuidIIdDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: GuidIIdDto;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type SubjectReportingProcessGetByIdResponse = SubjectReportingProcessDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SubjectReportingProcessDto;
    };
};

/**
 * Contains response data for the getTagSuggestions operation.
 */
export type TagGetTagSuggestionsResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the all operation.
 */
export type TagAllResponse = TagDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TagDtoPagedResult;
    };
};

/**
 * Contains response data for the all operation.
 */
export type TerytAllResponse = LocalityDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: LocalityDtoPagedResult;
    };
};

/**
 * Contains response data for the getLocalitiesByCommunity operation.
 */
export type TerytGetLocalitiesByCommunityResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getByLocality operation.
 */
export type TerytGetByLocalityResponse = StreetDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: StreetDtoPagedResult;
    };
};

/**
 * Contains response data for the voivodeships operation.
 */
export type TerytVoivodeshipsResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the counties operation.
 */
export type TerytCountiesResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the communities operation.
 */
export type TerytCommunitiesResponse = {
  /**
   * The parsed response body.
   */
  body: any;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: any;
    };
};

/**
 * Contains response data for the getAreasByUrbanRuralCommunity operation.
 */
export type TerytGetAreasByUrbanRuralCommunityResponse = TerritorialUnitDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TerritorialUnitDtoPagedResult;
    };
};

/**
 * Contains response data for the searchTerritories operation.
 */
export type TerytSearchTerritoriesResponse = TerritoryDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TerritoryDtoPagedResult;
    };
};

/**
 * Contains response data for the getById operation.
 */
export type UserGetByIdResponse = UserDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserDto;
    };
};

/**
 * Contains response data for the list operation.
 */
export type UserListResponse = UserListDtoPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserListDtoPagedResult;
    };
};

/**
 * Contains response data for the getManual operation.
 */
export type UserManualGetManualResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the create operation.
 */
export type UserManualCreateResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getManualCategory operation.
 */
export type UserManualGetManualCategoryResponse = Array<UserManualDto> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserManualDto[];
    };
};

/**
 * Contains response data for the download operation.
 */
export type UserManualDownloadResponse = {
  /**
   * BROWSER ONLY
   *
   * The response body as a browser Blob.
   * Always undefined in node.js.
   */
  blobBody?: Promise<Blob>;

  /**
   * NODEJS ONLY
   *
   * The response body as a node.js Readable stream.
   * Always undefined in the browser.
   */
  readableStreamBody?: NodeJS.ReadableStream;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse;
};

/**
 * Contains response data for the preview operation.
 */
export type UserManualPreviewResponse = UserManualFileDto & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserManualFileDto;
    };
};

/**
 * Contains response data for the getReadUserNotifications operation.
 */
export type UserNotificationGetReadUserNotificationsResponse = UserNotificationDTOPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserNotificationDTOPagedResult;
    };
};

/**
 * Contains response data for the getUnreadUserNotifications operation.
 */
export type UserNotificationGetUnreadUserNotificationsResponse = UserNotificationDTOPagedResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserNotificationDTOPagedResult;
    };
};

/**
 * Contains response data for the markAsRead operation.
 */
export type UserNotificationMarkAsReadResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the toggleActiveOfSameKind operation.
 */
export type UserNotificationToggleActiveOfSameKindResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getAll operation.
 */
export type UserNotificationSettingsGetAllResponse = Array<UserNotificationSettingsDTO> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: UserNotificationSettingsDTO[];
    };
};

/**
 * Contains response data for the activate operation.
 */
export type UserNotificationSettingsActivateResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the deactivate operation.
 */
export type UserNotificationSettingsDeactivateResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updateChannels operation.
 */
export type UserNotificationSettingsUpdateChannelsResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};
