import useSelectsAccessibility from '@hooks/accessibility/useSelectsAccessibilityHook';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Col, Modal, Select, Space } from 'antd';
import { Field, Formik, FormikProps } from 'formik';
import { FCheckbox, FFieldLabel, FInput } from 'forms/FormikFormItems';
import { ajaxCatch } from 'helper/api';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { showErrorModal } from 'layout/Modals';
import React, { useEffect, useRef, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { SubjectContactDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { InterventionCommentArea } from '../comments/InterventionCommentArea';
import { InterventionCommentIcon } from '../comments/InterventionCommentIcon';
import { InterventionContactValidationSchema } from '../validators/InterventionContactValidation';

interface IProps {
	visible: boolean;
	editable?: boolean;
	data?: SubjectContactDto;
	onCreate: (values: any) => void;
	onChange: (values: any) => void;
	onCancel: () => void;
	selectFromList?: boolean;
	checkDuplicates: (contact: any, subjectContacts: any) => boolean;
	contactsIds?: (string | undefined)[];
}
const InterventionContactModal: React.FC<IProps> = (props) => {
	const {
		visible,
		onCancel,
		onCreate,
		onChange,
		data,
		selectFromList,
		contactsIds,
	} = props;

	const formikRef = useRef<Formik<SubjectContactDto>>(null);
	const [contacts, setContacts] = useState<SubjectContactDto[]>();
	const [contact, setContact] = useState<SubjectContactDto | undefined>(data);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	useEffect(() => {
		setContact(data);
	}, [data]);

	useEffect(() => {
		if (!props.editable || !selectFromList) return;

		if (accountDetailsStore.account.get()?.subject?.id) {
			ajaxCatch(() =>
				getProfiBazaApiClient()
					.then((api) =>
						api.contact.getById(
							accountDetailsStore.account.get()?.subject?.id!
						)
					)
					.then((contacts: SubjectContactDto[]) => {
						setContacts(contacts);
					})
			);
		}
	}, [accountDetailsStore.account.get()?.subject?.id!]);

	return (
		<>
			<Formik
				validateOnChange={false}
				validateOnBlur={true}
				initialValues={contact || {}}
				enableReinitialize={true}
				ref={formikRef}
				validationSchema={InterventionContactValidationSchema}
				onSubmit={(values, actions) => {
					if (
						props.checkDuplicates(values, contact ? [] : contacts)
					) {
						showErrorModal(
							'Kontakt z takimi danymi został już dodany.'
						);
						return;
					}

					if (props.data) {
						onChange(values);
					} else {
						onCreate(values);
					}
					actions.resetForm();
					setContact(undefined);
				}}
				render={(props: FormikProps<any>) => (
					<Modal
						visible={visible}
						maskClosable={false}
						centered
						title={data ? 'Edycja kontaktu' : 'Dodawanie kontaktu'}
						onOk={() => {
							props.submitForm();
						}}
						onCancel={() => {
							onCancel();
							formikRef.current?.resetForm();
							setContact(undefined);
						}}
						okText="Zatwierdź"
					>
						{selectFromList && (
							<CenteredRow>
								<Col span={20}>
									<FFieldLabel
										label="Wybierz z RP"
										for="contact"
									/>
									<Select
										placeholder="Wybierz"
										id="contact"
										value={
											contact
												? `${contact?.firstName} ${contact?.lastName}`
												: undefined
										}
										onChange={(value: string) => {
											setContact(
												contacts?.find(
													(x) => x.id == value
												)
											);
										}}
									>
										{contacts
											?.filter(
												(contact) =>
													!contactsIds?.includes(
														contact.id
													)
											)
											.map(
												(
													element: SubjectContactDto,
													index: number
												) => {
													return (
														<Select.Option
															key={index}
															value={element.id!}
														>{`${element.firstName} ${element.lastName}`}</Select.Option>
													);
												}
											)}
									</Select>
								</Col>
							</CenteredRow>
						)}
						<CenteredRow>
							<Col span={20}>
								<Space>
									<FFieldLabel label="Imię" for="firstName" />
									<InterventionCommentIcon fieldName="firstName" />
								</Space>
								<InterventionCommentArea fieldName="firstName">
									<Field
										component={FInput}
										name="firstName"
									/>
								</InterventionCommentArea>
							</Col>
						</CenteredRow>
						<CenteredRow>
							<Col span={20}>
								<Space>
									<FFieldLabel
										label="Nazwisko"
										for="lastName"
									/>
									<InterventionCommentIcon fieldName="lastName" />
								</Space>
								<InterventionCommentArea fieldName="lastName">
									<Field component={FInput} name="lastName" />
								</InterventionCommentArea>
							</Col>
						</CenteredRow>
						<CenteredRow>
							<Col span={20}>
								<Space>
									<FFieldLabel
										label="Stanowisko"
										for="position"
									/>
									<InterventionCommentIcon fieldName="position" />
								</Space>
								<InterventionCommentArea fieldName="position">
									<Field component={FInput} name="position" />
								</InterventionCommentArea>
							</Col>
						</CenteredRow>
						<CenteredRow>
							<Col span={20}>
								<Space>
									<FFieldLabel label="E-mail" for="email" />
									<InterventionCommentIcon fieldName="email" />
								</Space>
								<InterventionCommentArea fieldName="email">
									<Field component={FInput} name="email" />
								</InterventionCommentArea>
							</Col>
						</CenteredRow>
						<CenteredRow>
							<Col span={20}>
								<Space>
									<FFieldLabel
										label="Telefon"
										for="phoneNumber"
									/>
									<InterventionCommentIcon fieldName="phoneNumber" />
								</Space>
								<InterventionCommentArea fieldName="phoneNumber">
									<Field
										component={FInput}
										name="phoneNumber"
									/>
								</InterventionCommentArea>
							</Col>
						</CenteredRow>
						<CenteredRow>
							<Col span={20}>
								<Space>
									<FFieldLabel
										label="Kontakt związany z rekrutacją do programu"
										for="connectedWithIkpRecrutation"
									/>
									<InterventionCommentIcon fieldName="connectedWithIkpRecrutation" />
								</Space>
								<InterventionCommentArea fieldName="connectedWithIkpRecrutation">
									<Field
										component={FCheckbox}
										name="connectedWithIkpRecrutation"
										id="connectedWithIkpRecrutation"
									/>
								</InterventionCommentArea>
							</Col>
						</CenteredRow>
					</Modal>
				)}
			/>
		</>
	);
};
export default InterventionContactModal;
