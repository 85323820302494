import React from 'react';
import { Button } from 'antd';
import { ajaxCatch } from 'helper/api';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { DownloadOutlined } from '@ant-design/icons';
import { DownloadFile } from './FileDownloadFunction';

interface IProps {
	fileId: any;
	large?: boolean;
	action?: () => void;
	disabled?: boolean;
	setDisabled?: (value?: boolean) => void;
}

const FileDownload = (props: IProps) => {
	return (
		<>
			<Button
				disabled={props.disabled}
				type="primary"
				size={props.large ? 'large' : 'middle'}
				icon={<DownloadOutlined />}
				onClick={() => {
					props.setDisabled && props.setDisabled(true)
					ajaxCatch(() =>
						getProfiBazaApiClient()
							.then((api) => api.file.download(props.fileId!))
							.then((response) => {
								DownloadFile({
									response: response,
									action: props.action,
								});
							})
					).then(() => props.setDisabled && props.setDisabled(false));
				}}
			>
				Pobierz
			</Button>
		</>
	);
};
export default FileDownload;
