import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<ProfiBazaAPIModels.DictionaryStatus, string>([
	[ProfiBazaAPIModels.DictionaryStatus.ACTIVE, 'aktywna'],
	[ProfiBazaAPIModels.DictionaryStatus.INACTIVE, 'nieaktywna'],
	[ProfiBazaAPIModels.DictionaryStatus.DRAFT, 'robocza'],
	[ProfiBazaAPIModels.DictionaryStatus.DELETED, 'usunięta'],
]);

export const map = (value: ProfiBazaAPIModels.DictionaryStatus): string =>
	mappings.get(value) ?? '';
