import * as Yup from 'yup';
import { FormType } from 'services/src/models';

const requiredMessage: string = 'Wartość jest wymagana';
const noLongerThanMessage = (value: number) =>
	`Wartość nie może być dłuższa niż ${value} znaków`;

export const createFormValueValidationSchema = (isCreation: boolean, type: FormType) =>
	Yup.object().shape({
		label: Yup.string()
			.nullable()
			.required(requiredMessage)
			.max(128, noLongerThanMessage(128)),
		title: isCreation
			? Yup.string().nullable().required(requiredMessage)
			: Yup.string().nullable(),
		pageOrientation: (type === FormType.StatementMZ49 || type === FormType.StatementMZ53 || type === FormType.StatementNPZ) 
			? Yup.string().nullable().required(requiredMessage)
			: Yup.string().nullable()
	});
