import React from 'react';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { StatementListItemDto } from 'services/src/models';
import * as StatementStatus from 'services/mappers/StatementStatus';
import * as StatementArea from 'services/mappers/StatementArea';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { DictionaryName } from 'services/mappers/DictionaryName';
import { Tag } from 'antd';
import formatHelpers from 'helper/formatHelpers';
import { ISideFilter } from '@components/shared/paginatedProfiBazaTable/TableSideBar';

const columns: ProfiBazaColumnProps<StatementListItemDto>[] = [
	{
		sorter: true,
		dataIndex: 'codeName',
		key: 'codeName',
		title: 'Identyfikator',
		filter: 'contains'
	},
	{
		sorter: true,
		dataIndex: 'statementName',
		key: 'statementName',
		title: 'Nazwa',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'statementArea',
		key: 'statementArea',
		title: 'Rodzaj',
		filter: 'dictionary',
		filterDictionary: DictionaryName.StatementArea,
		render: (value: ProfiBazaAPIModels.StatementArea) =>
			StatementArea.map(value),
	},
	{
		sorter: true,
		dataIndex: 'statementStatus',
		key: 'statementStatus',
		title: 'Status',
		width: 100,
		filter: 'dictionary',
		filterDictionary: DictionaryName.StatementsStatusTableFilter,
		render: (value: ProfiBazaAPIModels.StatementStatus) => {
			let color =
				value === ProfiBazaAPIModels.StatementStatus.ACCEPTED
					? 'green'
					: value === ProfiBazaAPIModels.StatementStatus.REJECTED
					? 'red'
					: 'default';
			const statusValue = StatementStatus.map(value!);
			return <Tag color={color}>{statusValue}</Tag>;
		},
	},
	{
		sorter: true,
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Właściciel',
		filter: 'contains',
	},
	{
		sorter: false,
		dataIndex: 'subjectRegon',
		key: 'subjectRegon',
		title: 'REGON',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'year',
		key: 'year',
		title: 'Rok',
		filter: 'equals',
		isNumeric: true
	},
	{
		sorter: true,
		dataIndex: 'createdAt',
		key: 'createdAt',
		title: 'Data utworzenia',
		filter: 'dateRange',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
];

export default columns;


export const sideBarFilters = (): ISideFilter[] => {
	return [
		{
			key: 'statements',
			label: 'Sprawozdania',
			filter: ProfiBazaAPIModels.StatementFilter.Statement,
			defaultSelected: true,
		},
		{
			key: 'archive',
			label: 'Archiwum',
			filter: ProfiBazaAPIModels.StatementFilter.Archive,
		}
	];

};