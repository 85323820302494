import AddButton from '@components/shared/buttons/AddButton';
import { ajaxByUser } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import {
	InterventionCommentType,
	InterventionPath,
} from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { Col, Row, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FFieldLabel,
	FRadioGroup,
	FSelect,
	FTextArea,
} from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import { showWarning } from 'layout/Modals';
import React, { Fragment, useState } from 'react';
import * as ActivityTabMapper from 'services/mappers/ActivityTab';
import * as Yup from 'yup';

import { AddInterventionCommentDto } from '../commands/AddInterventionCommentCommand';
import {
	ActivityTab,
	IInterventionCommentStore,
	IMarksFilterParams,
	getCommentFieldsAccordingToPath,
} from '../store/InterventionCommentStore';

interface IProps {
	interventionId: string;
	interventionPath: InterventionPath;
	activityId: string;
	activityTab: ActivityTab;
}

export const InterventionAddCommentModal: React.FC<IProps> = (props) => {
	const [visible, setVisible] = useState<boolean>(false);

	const interventionCommentStore: IInterventionCommentStore = useRootData(
		(store) => store.interventionCommentStore
	);

	const [commentsFilterParams, setCommentsFilterParams] = useState<
		IMarksFilterParams
	>(interventionCommentStore.filterParams.get());

	interventionCommentStore.filterParams.observe((change) => {
		setCommentsFilterParams(change.newValue);
	});

	const handleCreate = (
		values: AddInterventionCommentDto,
		actions: FormikActions<
			ProfiBazaAPIModels.CreateInterventionCommentCommand
		>
	) => {
		const command = AddInterventionCommentDto.toCommand(values);
		ajaxByUser(
			'Dodano komentarz',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.interventionComments
						.create({
							body: command,
						})
						.then((response) => {
							return response;
						})
				),
			() => {
				setVisible(false);
				actions.resetForm();
				interventionCommentStore.setComments(props.interventionId);
			},
			(errors) => {
				actions.setErrors(errors);
			}
		).then(() => actions.setSubmitting(false));
	};

	const renderBody = (formikProps: FormikProps<any>) => {
		return (
			<>
				<CenteredRow>
					<Col span={20}>
						<FFieldLabel label="Nazwa pola" for="fieldName" />
						<Field
							label="Nazwa pola"
							component={FSelect}
							name="fieldName"
							showSearch={true}
							children={Object.entries(
								getCommentFieldsAccordingToPath(
									props.interventionPath,
									props.activityTab
								)
							).map((group, i) => (
								<Select.OptGroup label={group[0]}>
									{Object.entries(group[1]).map((item, i) => (
										<Select.Option
											key={item[0]}
											value={item[0]}
										>
											{item[1]}
										</Select.Option>
									))}
								</Select.OptGroup>
							))}
						/>
					</Col>
				</CenteredRow>
				<CenteredRow>
					<Col span={20}>
						<FFieldLabel label="Komentarz" for="description" />
						<Field
							component={FTextArea}
							label="Tutaj wpisz swój komentarz"
							name="description"
							placeholder="Tutaj wpisz swój komentarz"
							readOnly={false}
						/>
					</Col>
				</CenteredRow>
				<Row>
					<Col span={2}></Col>
					<Col span={22} className="commentType">
						<FFieldLabel label="Typ komentarza" for="commentType" />
						<Field
							readOnly={false}
							style={{ width: '100%' }}
							defaultValue={InterventionCommentType.PUBLIC}
							values={[
								InterventionCommentType.PUBLIC,
								InterventionCommentType.PRIVATE,
								InterventionCommentType.IMPORTANT,
							]}
							labels={[
								'Informacyjny publiczny',
								'Informacyjny w ramach podmiotu',
								'Ważny',
							]}
							component={FRadioGroup}
							name="commentType"
						/>
					</Col>
				</Row>
			</>
		);
	};

	const handleAddComment = () => {
		if (
			props.activityTab === ActivityTab.Population &&
			!commentsFilterParams.populationId
		) {
			showWarning(
				'Aby dodać komentarz wybierz zapisany rekord klikając w jego szczegóły lub edycje.'
			);
		} else if (
			props.activityTab === ActivityTab.SponsoringCompanies &&
			!commentsFilterParams.sponsoringCompanyId
		) {
			showWarning(
				'Aby dodać komentarz wybierz zapisany rekord klikając w jego szczegóły lub edycje.'
			);
		} else {
			setVisible(true);
		}
	};

	return (
		<Fragment>
			{props.activityId && (
				<AddButton
					style={{ float: 'right' }}
					onClick={handleAddComment}
				>
					Dodaj komentarz
				</AddButton>
			)}
			{visible && (
				<Formik
					validateOnChange
					validateOnBlur
					initialValues={{
						interventionId: props.interventionId,
						fieldName: undefined,
						description: undefined,
						commentType:
							ProfiBazaAPIModels.InterventionCommentType.PUBLIC,
						activityId: props.activityId,
						activityPopulationId:
							commentsFilterParams.populationId === null
								? undefined
								: commentsFilterParams.populationId,
						activitySponsoringCompanyId:
							commentsFilterParams.sponsoringCompanyId === null
								? undefined
								: commentsFilterParams.sponsoringCompanyId,
						activityTabName: ActivityTabMapper.map(
							props.activityTab
						),
					}}
					validationSchema={Yup.object().shape({
						description: Yup.string()
							.nullable()
							.required("Pole 'Komentarz' jest wymagane.")
							.max(
								1000,
								"Pole 'Komentarz' nie może być dłuższe niż 1000 znaków."
							),
						fieldName: Yup.string()
							.nullable()
							.required("Pole 'Nazwa pola' nie może być puste."),
					})}
					enableReinitialize
					isInitialValid
					onSubmit={handleCreate}
					render={(
						formikProps: FormikProps<
							ProfiBazaAPIModels.CreateInterventionCommentCommand
						>
					) => (
						<Modal
							title="Dodawanie komentarza"
							visible={visible}
							onOk={(e) => {
								formikProps.submitForm();
							}}
							okButtonProps={{
								disabled: formikProps.isSubmitting,
							}}
							cancelButtonProps={{
								disabled: formikProps.isSubmitting,
							}}
							cancelText="Anuluj"
							centered
							onCancel={() => setVisible(false)}
							okText="Dodaj"
							destroyOnClose
						>
							{renderBody(formikProps)}
						</Modal>
					)}
				/>
			)}
		</Fragment>
	);
};
