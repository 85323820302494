import React from 'react';
import { notification, Modal } from 'antd';
import { IconType } from 'antd/lib/notification';
import { ReactNode } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

export const openNotificationWithIcon = (
	type: IconType,
	message: ReactNode,
	description: ReactNode
) => {
	notification[type]({
		message: message,
		description: description,
		placement: 'bottomRight',
	});
};

export const openValidationErrorNotification = (
	errorMessage: string = 'W formularzu wystąpiły błędy walidacji.'
) => {
	openNotificationWithIcon('warning', 'Błąd walidacji', errorMessage);
};

export function showConfirm(title: string, onOk: (...args: any[]) => any) {
	confirm({
		title,
		icon: <ExclamationCircleOutlined />,
		onOk,
		onCancel() {},
		okText: 'Tak',
		cancelText: 'Nie',
	});
}
