import { ajaxCatch } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import {
	InterventionCommentStatus,
	InterventionPath,
} from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import _ from 'lodash';
import { observable } from 'mobx';

import {
	InterventionCommentDto,
	InterventionCommentsCommand,
	PaginationInterventionCommentsCommand,
} from '../commands/InterventionCommentsCommand';
import {
	Legislation,
	ProHealthActivities,
	Research,
	StaffEducation,
	StaffMonitoring,
	SupervisionMonitoring,
	SupervisionSanitary,
} from './InterventionCommentFields';

export enum ActivityTab {
	ALL,
	Basic,
	Population,
	ImplementingCompanies,
	SponsoringCompanies,
	HealthProblem,
	IKP,
	AdditionalData,
}

export const getCommentFieldsAccordingToPath = (
	interventionPath: InterventionPath,
	activityTab: ActivityTab | null = null
): Record<string, Record<string, string>> => {
	switch (interventionPath) {
		case InterventionPath.PROHEALTHACTIVITIES:
			switch (activityTab) {
				case ActivityTab.ALL:
					return {
						...ProHealthActivities.ActivityBasic,
						...ProHealthActivities.ActivityPopulation,
						...ProHealthActivities.ActivityHealthProblem,
						...ProHealthActivities.ActivityImplementingCompany,
						...ProHealthActivities.ActivitySponsoringCompany,
						...ProHealthActivities.ActivityIKP,
						...ProHealthActivities.ActivityAdditionalData,
					};
				case ActivityTab.Basic:
					return ProHealthActivities.ActivityBasic;
				case ActivityTab.Population:
					return ProHealthActivities.ActivityPopulation;
				case ActivityTab.HealthProblem:
					return ProHealthActivities.ActivityHealthProblem;
				case ActivityTab.ImplementingCompanies:
					return ProHealthActivities.ActivityImplementingCompany;
				case ActivityTab.SponsoringCompanies:
					return ProHealthActivities.ActivitySponsoringCompany;
				case ActivityTab.IKP:
					return ProHealthActivities.ActivityIKP;
				case ActivityTab.AdditionalData:
					return ProHealthActivities.ActivityAdditionalData;
				default:
					return ProHealthActivities.Intervention;
			}
		case InterventionPath.RESEARCH:
			switch (activityTab) {
				case ActivityTab.ALL:
					return {
						...Research.ActivityBasic,
						...Research.ActivityHealthProblem,
						...Research.ActivityImplementingCompany,
						...Research.ActivitySponsoringCompany,
						...Research.ActivityIKP,
						...Research.ActivityAdditionalData,
					};
				case ActivityTab.Basic:
					return Research.ActivityBasic;
				case ActivityTab.HealthProblem:
					return Research.ActivityHealthProblem;
				case ActivityTab.ImplementingCompanies:
					return Research.ActivityImplementingCompany;
				case ActivityTab.SponsoringCompanies:
					return Research.ActivitySponsoringCompany;
				case ActivityTab.IKP:
					return Research.ActivityIKP;
				case ActivityTab.AdditionalData:
					return Research.ActivityAdditionalData;
				default:
					return Research.Intervention;
			}
		case InterventionPath.SUPERVISIONSANITARY:
			switch (activityTab) {
				case ActivityTab.ALL:
					return {
						...SupervisionSanitary.ActivityBasic,
						...SupervisionSanitary.ActivityHealthProblem,
						...SupervisionSanitary.ActivityImplementingCompany,
						...SupervisionSanitary.ActivitySponsoringCompany,
						...SupervisionSanitary.ActivityIKP,
						...SupervisionSanitary.ActivityAdditionalData,
					};
				case ActivityTab.Basic:
					return SupervisionSanitary.ActivityBasic;
				case ActivityTab.HealthProblem:
					return SupervisionSanitary.ActivityHealthProblem;
				case ActivityTab.ImplementingCompanies:
					return SupervisionSanitary.ActivityImplementingCompany;
				case ActivityTab.SponsoringCompanies:
					return SupervisionSanitary.ActivitySponsoringCompany;
				case ActivityTab.IKP:
					return SupervisionSanitary.ActivityIKP;
				case ActivityTab.AdditionalData:
					return SupervisionSanitary.ActivityAdditionalData;
				default:
					return SupervisionSanitary.Intervention;
			}
		case InterventionPath.SUPERVISIONMONITORING:
			switch (activityTab) {
				case ActivityTab.ALL:
					return {
						...SupervisionMonitoring.ActivityBasic,
						...SupervisionMonitoring.ActivityHealthProblem,
						...SupervisionMonitoring.ActivityImplementingCompany,
						...SupervisionMonitoring.ActivitySponsoringCompany,
						...SupervisionMonitoring.ActivityIKP,
						...SupervisionMonitoring.ActivityAdditionalData,
					};
				case ActivityTab.Basic:
					return SupervisionMonitoring.ActivityBasic;
				case ActivityTab.HealthProblem:
					return SupervisionMonitoring.ActivityHealthProblem;
				case ActivityTab.ImplementingCompanies:
					return SupervisionMonitoring.ActivityImplementingCompany;
				case ActivityTab.SponsoringCompanies:
					return SupervisionMonitoring.ActivitySponsoringCompany;
				case ActivityTab.IKP:
					return SupervisionMonitoring.ActivityIKP;
				case ActivityTab.AdditionalData:
					return SupervisionMonitoring.ActivityAdditionalData;
				default:
					return SupervisionMonitoring.Intervention;
			}
		case InterventionPath.STAFFEDUCATION:
			switch (activityTab) {
				case ActivityTab.ALL:
					return {
						...StaffEducation.ActivityBasic,
						...StaffEducation.ActivityImplementingCompany,
						...StaffEducation.ActivitySponsoringCompany,
						...StaffEducation.ActivityAdditionalData,
					};
				case ActivityTab.Basic:
					return StaffEducation.ActivityBasic;
				case ActivityTab.ImplementingCompanies:
					return StaffEducation.ActivityImplementingCompany;
				case ActivityTab.SponsoringCompanies:
					return StaffEducation.ActivitySponsoringCompany;
				case ActivityTab.AdditionalData:
					return StaffEducation.ActivityAdditionalData;
				default:
					return StaffEducation.Intervention;
			}
		case InterventionPath.STAFFMONITORING:
			switch (activityTab) {
				case ActivityTab.ALL:
					return {
						...StaffMonitoring.ActivityBasic,
						...StaffMonitoring.ActivityHealthProblem,
						...StaffMonitoring.ActivityImplementingCompany,
						...StaffMonitoring.ActivitySponsoringCompany,
						...StaffMonitoring.ActivityIKP,
						...StaffMonitoring.ActivityAdditionalData,
					};
				case ActivityTab.Basic:
					return StaffMonitoring.ActivityBasic;
				case ActivityTab.HealthProblem:
					return StaffMonitoring.ActivityHealthProblem;
				case ActivityTab.ImplementingCompanies:
					return StaffMonitoring.ActivityImplementingCompany;
				case ActivityTab.SponsoringCompanies:
					return StaffMonitoring.ActivitySponsoringCompany;
				case ActivityTab.IKP:
					return StaffMonitoring.ActivityIKP;
				case ActivityTab.AdditionalData:
					return StaffMonitoring.ActivityAdditionalData;
				default:
					return StaffMonitoring.Intervention;
			}
		case InterventionPath.LEGISLATION:
			switch (activityTab) {
				case ActivityTab.ALL:
					return {
						...Legislation.ActivityBasic,
						...Legislation.ActivityHealthProblem,
						...Legislation.ActivityImplementingCompany,
						...Legislation.ActivitySponsoringCompany,
						...Legislation.ActivityIKP,
						...Legislation.ActivityAdditionalData,
					};
				case ActivityTab.Basic:
					return Legislation.ActivityBasic;
				case ActivityTab.HealthProblem:
					return Legislation.ActivityHealthProblem;
				case ActivityTab.ImplementingCompanies:
					return Legislation.ActivityImplementingCompany;
				case ActivityTab.SponsoringCompanies:
					return Legislation.ActivitySponsoringCompany;
				case ActivityTab.IKP:
					return Legislation.ActivityIKP;
				case ActivityTab.AdditionalData:
					return Legislation.ActivityAdditionalData;
				default:
					return Legislation.Intervention;
			}
		default:
			return {};
	}
};

export interface IMarksFilterParams {
	activityId: string | null;
	populationId: string | null;
	sponsoringCompanyId: string | null;
}

let lastFilterParams = {
	populationId: '',
	sponsoringCompanyId: '',
};

export const interventionCommentStore = {
	marks: observable.box<Record<string, InterventionCommentDto> | undefined>(
		undefined
	),
	comments: observable.box<InterventionCommentsCommand>(),
	filterParams: observable.box<IMarksFilterParams>(),
	setMarks(value: InterventionCommentDto[]) {
		const filterParams = this.filterParams.get();

		interventionCommentStore.marks.set(
			getUniqueMarks(
				value,
				filterParams.activityId,
				filterParams.populationId,
				filterParams.sponsoringCompanyId
			)
		);
	},
	setComments: (interventionId: string) => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.interventionComments.all({
						sievePageSize: 9999,
						sievePage: 1,
						interventionId: interventionId,
					})
				)
				.then(
					(
						response: ProfiBazaAPIModels.InterventionCommentListItemDtoPagedResult
					) => {
						const command = new InterventionCommentsCommand(
							response.results!,
							new PaginationInterventionCommentsCommand(response)
						);

						interventionCommentStore.setMarks(command.comments);
						interventionCommentStore.comments.set(command);
					}
				)
		);
	},
	getLastPopulationId: () => {
		return lastFilterParams.populationId;
	},
	getLastSponsoringCompanyId: () => {
		return lastFilterParams.sponsoringCompanyId;
	},
	resetLastFilterParams: () => {
		lastFilterParams = {
			populationId: '',
			sponsoringCompanyId: '',
		};
	},
};

interventionCommentStore.filterParams.observe((change) => {
	const comments = interventionCommentStore.comments.get();

	if (change.newValue.populationId) {
		lastFilterParams.populationId = change.newValue.populationId;
	}

	if (change.newValue.sponsoringCompanyId) {
		lastFilterParams.sponsoringCompanyId =
			change.newValue.sponsoringCompanyId;
	}

	if (comments) {
		interventionCommentStore.setMarks(comments.comments);
	}
});

const getUniqueMarks = (
	comments: InterventionCommentDto[],
	activityId: string | null,
	populationId: string | null,
	sponsoringCompanyId: string | null
): Record<string, InterventionCommentDto> => {
	const filteredComments = _.uniqBy(
		_.orderBy(
			comments.filter(
				(x) =>
					x.status !== InterventionCommentStatus.PROCESSED &&
					x.activityId == activityId &&
					x.activityPopulationId == populationId &&
					x.activitySponsoringCompanyId == sponsoringCompanyId
			),
			['commentType', 'createdAt'],
			['asc', 'desc']
		),
		'fieldName'
	);

	return filteredComments.reduce(
		(prev: Record<string, InterventionCommentDto>, curr) => {
			prev[curr.fieldName!] = curr;

			return prev;
		},
		{}
	);
};

export const createInterventionCommentStore = () => {
	return interventionCommentStore;
};

export type IInterventionCommentStore = ReturnType<
	typeof createInterventionCommentStore
>;
