import { DownloadOutlined } from '@ant-design/icons';
import Authorized from '@authorization/authorized';
import AddButton from '@components/shared/buttons/AddButton';
import TableActionButton from '@components/shared/buttons/TableActionButton';
import { DownloadFile } from '@components/shared/file/FileDownloadFunction';
import ExportButtons from '@components/shared/paginatedProfiBazaTable/actions/ExportButtons';
import ProfiBazaTableActions from '@components/shared/paginatedProfiBazaTable/actions/ProfiBazaTableActions';
import { ProfiBazaColumnProps } from '@components/shared/paginatedProfiBazaTable/GridHelper';
import PaginatedProfiBazaTable from '@components/shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import SearchBar from '@components/shared/paginatedProfiBazaTable/SearchBar';
import { SieveModel } from '@components/shared/paginatedProfiBazaTable/SieveModels';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import RenderActions from '@components/statements/table/RenderActions';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { FileType } from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Button, Col, Form, Row, Space, Tag, Typography } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { IGridStore } from 'stores/GridStore';
import { GridNameType, ITableColumnStore } from 'stores/TableColumnStore';

import CreateVoivodeOpinionModal, {
	ICreateVoivodeOpinionModal,
} from '../modals/CreateVoivodeOpinionModal';
import { sideBarFilters } from './DocumentSidebarFilters';
import { exportActions } from './SourceDocumentsExportActions';

export const DOCUMENTS_SIDEBAR_WIDTH = 180;

interface IProps {
	gridName: GridNameType;
}

const DefaultDocumentTable: React.FC<IProps> = ({ gridName }) => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const [signal, setSignal] = useState<AbortSignal>();
	const location = useLocation();
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);
	const [isDownloading, setDownloading] = useState<boolean>(false);
	const createVoiveOpinionModalRef = useRef<
		ICreateVoivodeOpinionModal
	>() as React.RefObject<ICreateVoivodeOpinionModal>;

	useEffect(() => {
		const abortController = new AbortController();
		setSignal(abortController.signal);
		return () => {
			abortController.abort();
		};
	}, []);

	const columns: ProfiBazaColumnProps<
		ProfiBazaAPIModels.DocumentListItemDto
	>[] = [
		RenderActions<ProfiBazaAPIModels.DocumentListItemDto>(
			(text, record) => (
				<>
					<Authorized
						permissions={[
							ProfiBazaAPIModels.Permission
								.DocumentsRepositoryPreview,
						]}
					>
						<Space>
							{record.fileType === 'FILE' ? (
								<>
									<ProfiBazaTooltip
										placement="top"
										title="Pobierz"
									>
										<Button
											type="ghost"
											disabled={isDownloading}
											size="small"
											shape="circle"
											aria-label="Pobierz"
											icon={<DownloadOutlined />}
											onClick={() => {
												setDownloading(true);
												ajaxCatch(() =>
													getProfiBazaApiClient()
														.then((api) =>
															api.file.download(
																record.fileId!,
																{
																	abortSignal: signal,
																}
															)
														)
														.then((response) => {
															DownloadFile({
																response,
															});
														})
												).then(() =>
													setDownloading(false)
												);
											}}
										/>
									</ProfiBazaTooltip>
									<ProfiBazaTooltip
										placement="top"
										title="Podgląd"
									>
										<TableActionButton
											kind="DETAILS"
											linkTo={`/documents/details/${record.id}/${record.fileId}`}
											label="Podgląd"
										/>
									</ProfiBazaTooltip>
								</>
							) : (
								<ProfiBazaTooltip
									placement="top"
									title="Podgląd"
								>
									<TableActionButton
										kind="DETAILS"
										linkTo={`/documents/details/${record.id}`}
										label="Podgląd"
									/>
								</ProfiBazaTooltip>
							)}
						</Space>
					</Authorized>
				</>
			)
		),
		...tableColumnStore.getTable(gridName),
	];

	return (
		<>
			<CenteredRow gutter={[16, 24]} style={{ marginTop: 32 }}>
				<Col span={5}>
					{location.pathname == '/documents/voivodeOpinion' && (
						<Authorized
							permission={
								ProfiBazaAPIModels.Permission
									.DocumentsRepositoryVoivodeOpinionsModifications
							}
						>
							<AddButton
								tall
								onClick={() =>
									createVoiveOpinionModalRef.current?.showModal()
								}
								label="Dodaj opinię"
							>
								Dodaj opinię
							</AddButton>
							<CreateVoivodeOpinionModal
								ref={createVoiveOpinionModalRef}
								onCreate={() => {}}
							/>
						</Authorized>
					)}
				</Col>
				<Col span={13}>
					<Form size="large">
						<SearchBar
							allowTags={true}
							column="(fileName)"
							filter="contains"
							gridName={gridName}
							defaultValue={
								gridStore.getColumnFilter(gridName, 'fileName')
									?.value
							}
						/>
					</Form>
				</Col>
				<Col span={5}></Col>
			</CenteredRow>

			<CenteredRow>
				<Col span={23}>
					{accountDetailsStore.account.get()?.gridSettings && (
						<PaginatedProfiBazaTable<
							ProfiBazaAPIModels.DocumentListItemDto
						>
							className="main-view-doc"
							gridName={gridName}
							detailsUrl="/documents/details"
							hidePersonalizationSettings={
								!accountDetailsStore.account.get()
									?.existsInProfiBaza
							}
							getRowKey={(
								r: ProfiBazaAPIModels.DocumentListItemDto
							) =>
								r.fileType === 'FILE'
									? `${r.id}/${r.fileId}`
									: `${r.id}`
							}
							columns={columns}
							hasRowSelection
							getPagedResult={(
								sieve: SieveModel,
								filter:
									| ProfiBazaAPIModels.SourceDocumentFilter
									| undefined,
								abortSignal
							) => {
								const parameters: ProfiBazaAPIModels.DocumentAllOptionalParams = {
									filters: sieve.filters,
									sorts: sieve.sorts,
									page: sieve.page,
									pageSize: sieve.pageSize,
									filter: filter,
									labels: sieve.labels,
									abortSignal,
								};
								return getProfiBazaApiClient().then((api) => {
									return api.document
										.all(parameters)
										.then((result) => result);
								});
							}}
							sideBarFilters={{
								filters: sideBarFilters,
								path: 'documents',
								siderWidth: DOCUMENTS_SIDEBAR_WIDTH,
							}}
							renderActions={(actionProps) => (
								<ProfiBazaTableActions<
									ProfiBazaAPIModels.DocumentListItemDto
								>
									actions={[
										{
											singleAction: (
												record: ProfiBazaAPIModels.DocumentListItemDto
											) =>
												record.category ==
												ProfiBazaAPIModels
													.DocumentCategory
													.VoivodeOpinion ? (
													<Authorized
														permission={
															ProfiBazaAPIModels
																.Permission
																.DocumentsRepositoryVoivodeOpinionsModifications
														}
													>
														<ProfiBazaTooltip title="Usuń">
															<AccessiblePopconfirm
																title="Czy na pewno chcesz usunąć opinie wojewody?"
																onConfirm={() => {
																	ajaxByUser(
																		'Usunięto opinie wojewody.',
																		() =>
																			getProfiBazaApiClient()
																				.then(
																					(
																						api
																					) =>
																						api.document.deleteVoivodeOpinion(
																							record.id!
																						)
																				)
																				.then(
																					() => {
																						gridStore.searching.set(
																							true
																						);
																					}
																				)
																	);
																}}
															>
																<TableActionButton
																	label="Usuń"
																	kind="DELETE"
																/>
															</AccessiblePopconfirm>
														</ProfiBazaTooltip>
													</Authorized>
												) : (
													<></>
												),
										},
									]}
									{...actionProps}
								>
									<ExportButtons<
										ProfiBazaAPIModels.DocumentListItemDto
									>
										fileType={FileType.XLSX}
										supportedExportTypes={['default']}
										getSieveModel={
											actionProps.getSieveModel
										}
										getFilePromise={exportActions}
										key="list_export"
										abortSignal={signal}
									/>

									<ExportButtons<
										ProfiBazaAPIModels.DocumentListItemDto
									>
										fileType={FileType.PDF}
										supportedExportTypes={['default']}
										getSieveModel={
											actionProps.getSieveModel
										}
										getFilePromise={exportActions}
										key="flat_export"
										abortSignal={signal}
									/>

									<ExportButtons<
										ProfiBazaAPIModels.DocumentListItemDto
									>
										fileType={FileType.CSV}
										supportedExportTypes={['default']}
										getSieveModel={
											actionProps.getSieveModel
										}
										getFilePromise={exportActions}
										key="flat_technical_export"
										abortSignal={signal}
									/>
								</ProfiBazaTableActions>
							)}
						/>
					)}
				</Col>
			</CenteredRow>
		</>
	);
};
export default DefaultDocumentTable;
