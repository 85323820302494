import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<
	ProfiBazaAPIModels.UserManualStatetmentReportType,
	string
>([
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType.StatementNPZ,
		'Sprawozdanie z Zadań Zdrowia Publicznego',
	],
	[ProfiBazaAPIModels.UserManualStatetmentReportType.StatementMZ49, 'MZ-49'],
	[ProfiBazaAPIModels.UserManualStatetmentReportType.StatementMZ53, 'MZ-53'],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType.StatementNPZ,
		'SPRAWOZDANIE NPZ',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType.StatementMZ49,
		'SPRAWOZDANIE MZ-49',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType.StatementMZ53,
		'SPRAWOZDANIE MZ-53',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType.AOTMiTFinalReport,
		'RAPORT KONCOWY AOTMiT',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType
			.ActivityProhealthActivities,
		'DZIALANIE DZIALANIA PROZDROWOTNE',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType.ActivityResearch,
		'DZIALANIE BADANIA NAUKOWE',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType
			.ActivitySupervisionMonitoring,
		'DZIALANIE NADZOR MONITORING',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType
			.ActivitySupervisionSanitary,
		'DZIALANIE NADZOR SAN-EPI',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType
			.ActivityStaffEducation,
		'DZIALANIE KSZTALCENIE KADR',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType
			.ActivityStaffMonitoring,
		'DZIALANIE MONITOROWANIE KADR',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType.ActivityLegislation,
		'DZIALANIE LEGISLACJA',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType
			.InterventionProhealthActivities,
		'INTERWENCJA DZIALANIA PROZDROWOTNE',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType.InterventionResearch,
		'INTERWENCJA BADANIA NAUKOWE',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType
			.InterventionSupervisionMonitoring,
		'INTERWENCJA NADZOR MONITORING',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType
			.InterventionSupervisionSanitary,
		'INTERWENCJA NADZOR SAN-EPI',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType
			.InterventionStaffEducation,
		'INTERWENCJA KSZTALCENIE KADR',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType
			.InterventionStaffMonitoring,
		'INTERWENCJA MONITOROWANIE KADR',
	],
	[
		ProfiBazaAPIModels.UserManualStatetmentReportType
			.InterventionLegislation,
		'INTERWENCJA LEGISLACJA',
	],
]);

export const map = (
	value: ProfiBazaAPIModels.UserManualStatetmentReportType
): string => mapings.get(value) ?? '';

export const mapToUserManualStatetmentReportType = (
	value: ProfiBazaAPIModels.StatementArea
): ProfiBazaAPIModels.UserManualStatetmentReportType => {
	switch (value) {
		case ProfiBazaAPIModels.StatementArea.MZ49:
			return ProfiBazaAPIModels.UserManualStatetmentReportType
				.StatementMZ49;
		case ProfiBazaAPIModels.StatementArea.MZ53:
			return ProfiBazaAPIModels.UserManualStatetmentReportType
				.StatementMZ53;
		case ProfiBazaAPIModels.StatementArea.NPZ:
			return ProfiBazaAPIModels.UserManualStatetmentReportType
				.StatementNPZ;
	}
};