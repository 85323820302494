import * as DictionaryInterventionPath from '@services/mappers/DictionaryInterventionPath';
import * as DictionaryReportingArea from '@services/mappers/DictionaryReportingArea';
import * as DictionaryStatus from '@services/mappers/DictionaryStatus';
import * as DocumentCategory from '@services/mappers/DocumentCategory';
import * as DocumentSource from '@services/mappers/DocumentSource';
import * as DocumentStatus from '@services/mappers/DocumentStatus';
import * as FormStatus from '@services/mappers/FormStatus';
import * as InterventionPath from '@services/mappers/InterventionPath';
import * as InterventionStatus from '@services/mappers/InterventionStatus';
import * as InterventionStatusTableFilter from '@services/mappers/InterventionStatusTableFilter';
import * as InterventionStatusWithoutArchived from '@services/mappers/InterventionStatusWithoutArchived';
import * as OpinionAOTMiTType from '@services/mappers/OpinionAOTMiTType';
import * as PageOrientation from '@services/mappers/PageOrientation';
import * as PatternStatus from '@services/mappers/PatternStatus';
import * as ProposalAccessType from '@services/mappers/ProposalAccessType';
import * as ProposalStatus from '@services/mappers/ProposalStatus';
import * as StatementArea from '@services/mappers/StatementArea';
import * as StatementStatus from '@services/mappers/StatementStatus';
import * as StatementStatusWithoutArchived from '@services/mappers/StatementStatusWithoutArchived';
import * as StatementsStatusTableFilter from '@services/mappers/StatementsStatusTableFilter';
import * as SubjectKind from '@services/mappers/SubjectKind';
import * as SubjectReportingProcessStatus from '@services/mappers/SubjectReportingProcessStatus';
import * as SubjectStatus from '@services/mappers/SubjectStatus';
import { DictionaryName } from 'services/mappers/DictionaryName';
import * as DocumentSubjectKind from 'services/mappers/DocumentSubjectKind';

export interface BuildInDictionaryType {
	name: DictionaryName;
	values: DictionaryValueType[];
}
export interface DictionaryValueType {
	displayName: string;
	code: string;
}

export const createDictionaryValues = (
	enumMap: Map<any, string>
): DictionaryValueType[] => {
	const values: DictionaryValueType[] = [];
	enumMap.forEach((value, key, map) => {
		values.push({
			displayName: value,
			code: key,
		});
	});
	return values;
};

export const initializeDictionaries = (): BuildInDictionaryType[] => {
	return [
		{
			name: DictionaryName.TrueOrFalse,
			values: [
				{
					displayName: 'Tak',
					code: 'true',
				},
				{
					displayName: 'Nie',
					code: 'false',
				},
			],
		},
		{
			name: DictionaryName.SubjectStatus,
			values: createDictionaryValues(SubjectStatus.mapings),
		},
		{
			name: DictionaryName.SubjectKind,
			values: createDictionaryValues(SubjectKind.mappings),
		},
		{
			name: DictionaryName.StatementStatus,
			values: createDictionaryValues(StatementStatus.mapings),
		},
		{
			name: DictionaryName.StatementStatusWithoutArchived,
			values: createDictionaryValues(StatementStatusWithoutArchived.mappings),
		},
		{
			name: DictionaryName.StatementsStatusTableFilter,
			values: createDictionaryValues(
				StatementsStatusTableFilter.mappings
			),
		},
		{
			name: DictionaryName.StatementArea,
			values: createDictionaryValues(StatementArea.mappings),
		},
		{
			name: DictionaryName.InterventionStatus,
			values: createDictionaryValues(InterventionStatus.mappings),
		},
		{
			name: DictionaryName.InterventionStatusTableFilter,
			values: createDictionaryValues(
				InterventionStatusTableFilter.mappings
			),
		},
		{
			name: DictionaryName.InterventionStatusWithoutArchived,
			values: createDictionaryValues(
				InterventionStatusWithoutArchived.mappings
			),
		},
		{
			name: DictionaryName.PatternStatus,
			values: createDictionaryValues(PatternStatus.mappings),
		},
		{
			name: DictionaryName.InterventionPath,
			values: createDictionaryValues(InterventionPath.mappings),
		},
		{
			name: DictionaryName.DictionaryInterventionPath,
			values: createDictionaryValues(DictionaryInterventionPath.mappings),
		},
		{
			name: DictionaryName.DictionaryVersionStatus,
			values: createDictionaryValues(DictionaryStatus.mappings),
		},
		{
			name: DictionaryName.ReportingArea,
			values: createDictionaryValues(DictionaryReportingArea.mapings),
		},
		{
			name: DictionaryName.SubjectReportingProcessStatus,
			values: createDictionaryValues(
				SubjectReportingProcessStatus.mapings
			),
		},
		{
			name: DictionaryName.ProposalStatus,
			values: createDictionaryValues(ProposalStatus.mapings),
		},
		{
			name: DictionaryName.ProposalAccessType,
			values: createDictionaryValues(ProposalAccessType.mapings),
		},
		{
			name: DictionaryName.DocumentProcessKind,
			values: createDictionaryValues(DocumentSource.mappings),
		},
		{
			name: DictionaryName.DocumentKind,
			values: createDictionaryValues(DocumentCategory.mappings),
		},
		{
			name: DictionaryName.DocumentStatus,
			values: createDictionaryValues(DocumentStatus.mapings),
		},
		{
			name: DictionaryName.PageOrientation,
			values: createDictionaryValues(PageOrientation.mapings),
		},
		{
			name: DictionaryName.FormStatus,
			values: createDictionaryValues(FormStatus.mapings),
		},
		{
			name: DictionaryName.AotmitOpinion,
			values: createDictionaryValues(OpinionAOTMiTType.mapings),
		},
		{
			name: DictionaryName.DocumentSubjectKind,
			values: createDictionaryValues(DocumentSubjectKind.mapings),
		},
	];
};
