// @ts-ignore
import React from 'react';

interface IProps {}

const Reports: React.FC<IProps> = (props) => {
	const iframe = {
		// @ts-ignore
		__html: `<iframe title="iframe" src="${window._env_.REACT_APP_REPORT_APP_URI}" id="iFrameResizer0" scrolling="yes" style="width: 1px; min-width: 100%; border-width: 0px; overflow-y:auto; height: calc(100vh - 150px);"></iframe>`,
	};

	return <div dangerouslySetInnerHTML={iframe}></div>;
};
export default Reports;
