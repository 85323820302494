import { ajaxCatchSilently } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import React from 'react';

import Tags from './Tags';

interface IProps {
	interventionId: string;
	tags: string[];
}

const InterventionTags: React.FC<IProps> = (props) => {
	const addTag = (
		tag: string,
		onSuccess: () => void,
		onError: () => void
	) => {
		ajaxCatchSilently(
			() =>
				getProfiBazaApiClient().then((api) =>
					api.interventionTag.addTag({
						body: {
							interventionId: props.interventionId,
							tagName: tag,
						},
					})
				),
			onSuccess,
			onError
		);
	};

	const removeTag = (tag: string, onError: () => void) => {
		ajaxCatchSilently(
			() =>
				getProfiBazaApiClient().then((api) =>
					api.interventionTag.deleteTag({
						body: {
							interventionId: props.interventionId,
							tagName: tag,
						},
					})
				),
			undefined,
			onError
		);
	};

	return (
		<Tags addTag={addTag} removeTag={removeTag} tags={props.tags}></Tags>
	);
};

export default InterventionTags;
