/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/proposalMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Proposal. */
export class Proposal {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Proposal.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ProposalGetProposalsResponse>
   */
  getProposals(options?: Models.ProposalGetProposalsOptionalParams): Promise<Models.ProposalGetProposalsResponse>;
  /**
   * @param callback The callback
   */
  getProposals(callback: msRest.ServiceCallback<Models.ProposalItemDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getProposals(options: Models.ProposalGetProposalsOptionalParams, callback: msRest.ServiceCallback<Models.ProposalItemDtoPagedResult>): void;
  getProposals(options?: Models.ProposalGetProposalsOptionalParams | msRest.ServiceCallback<Models.ProposalItemDtoPagedResult>, callback?: msRest.ServiceCallback<Models.ProposalItemDtoPagedResult>): Promise<Models.ProposalGetProposalsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getProposalsOperationSpec,
      callback) as Promise<Models.ProposalGetProposalsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ProposalConsiderProposalResponse>
   */
  considerProposal(options?: Models.ProposalConsiderProposalOptionalParams): Promise<Models.ProposalConsiderProposalResponse>;
  /**
   * @param callback The callback
   */
  considerProposal(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  considerProposal(options: Models.ProposalConsiderProposalOptionalParams, callback: msRest.ServiceCallback<string>): void;
  considerProposal(options?: Models.ProposalConsiderProposalOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.ProposalConsiderProposalResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      considerProposalOperationSpec,
      callback) as Promise<Models.ProposalConsiderProposalResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ProposalCreateResponse>
   */
  create(options?: Models.ProposalCreateOptionalParams): Promise<Models.ProposalCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.ProposalCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.ProposalCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.ProposalCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.ProposalCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ProposalGetProposalsAdminResponse>
   */
  getProposalsAdmin(options?: Models.ProposalGetProposalsAdminOptionalParams): Promise<Models.ProposalGetProposalsAdminResponse>;
  /**
   * @param callback The callback
   */
  getProposalsAdmin(callback: msRest.ServiceCallback<Models.ProposalItemDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getProposalsAdmin(options: Models.ProposalGetProposalsAdminOptionalParams, callback: msRest.ServiceCallback<Models.ProposalItemDtoPagedResult>): void;
  getProposalsAdmin(options?: Models.ProposalGetProposalsAdminOptionalParams | msRest.ServiceCallback<Models.ProposalItemDtoPagedResult>, callback?: msRest.ServiceCallback<Models.ProposalItemDtoPagedResult>): Promise<Models.ProposalGetProposalsAdminResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getProposalsAdminOperationSpec,
      callback) as Promise<Models.ProposalGetProposalsAdminResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ProposalAddAttachmentProposalResponse>
   */
  addAttachmentProposal(options?: Models.ProposalAddAttachmentProposalOptionalParams): Promise<Models.ProposalAddAttachmentProposalResponse>;
  /**
   * @param callback The callback
   */
  addAttachmentProposal(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addAttachmentProposal(options: Models.ProposalAddAttachmentProposalOptionalParams, callback: msRest.ServiceCallback<string>): void;
  addAttachmentProposal(options?: Models.ProposalAddAttachmentProposalOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.ProposalAddAttachmentProposalResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      addAttachmentProposalOperationSpec,
      callback) as Promise<Models.ProposalAddAttachmentProposalResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.ProposalGetProposalResponse>
   */
  getProposal(id: string, options?: msRest.RequestOptionsBase): Promise<Models.ProposalGetProposalResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getProposal(id: string, callback: msRest.ServiceCallback<Models.ProposalDetailsDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getProposal(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ProposalDetailsDto>): void;
  getProposal(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ProposalDetailsDto>, callback?: msRest.ServiceCallback<Models.ProposalDetailsDto>): Promise<Models.ProposalGetProposalResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getProposalOperationSpec,
      callback) as Promise<Models.ProposalGetProposalResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getProposalsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/Proposal",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ProposalItemDtoPagedResult
    },
    default: {}
  },
  serializer
};

const considerProposalOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "subjects/Proposal",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ConsiderProposalCommand
  },
  responses: {
    200: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "subjects/Proposal",
  formDataParameters: [
    Parameters.proposalAccesType,
    Parameters.email,
    Parameters.subjectId1,
    Parameters.file
  ],
  contentType: "multipart/form-data",
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const getProposalsAdminOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/Proposal/ProposalsAdmin",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ProposalItemDtoPagedResult
    },
    default: {}
  },
  serializer
};

const addAttachmentProposalOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "subjects/Proposal/AddAttachment",
  formDataParameters: [
    Parameters.id3,
    Parameters.file
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getProposalOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/Proposal/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ProposalDetailsDto
    },
    default: {}
  },
  serializer
};
