import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MoveFocusToElement } from '@helper/focusHelpers';
import { Button, Popconfirm, Space } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import React, { useState } from 'react';
import { useEffect } from 'react';

const FocusTrap = require('focus-trap-react');

interface IProps {
	visibleCallback?: (visible: boolean) => void;
	moveFocusTo?: string;
}

export type AccessiblePopconfirmProps = React.PropsWithChildren<
	IProps & PopconfirmProps
>;

const AccessiblePopconfirm: React.FC<AccessiblePopconfirmProps> = (props) => {
	const {
		okText,
		cancelText,
		placement,
		trigger,
		title,
		onConfirm,
		onCancel,
		icon,
		...otherProps
	} = props;

	const [visible, setVisible] = useState<boolean>(false);

	const handleVisibleChange = (visibleChange: boolean) => {
		setVisible(visibleChange);
		if (props.visibleCallback) {
			props.visibleCallback(visibleChange);
		}
		MoveFocusToElement();
	};

	useEffect(() => {
		if (props.visibleCallback) {
			setVisible(props.visible!);
		}
	}, [props.visible]);

	const renderIcon = icon ?? (
		<ExclamationCircleOutlined className="warning-color" />
	);

	return (
		<>
			<Popconfirm
				overlayClassName="popconfirm--accessible"
				title={
					<>
						{visible && (
							<FocusTrap
								focusTrapOptions={{
									onDeactivate: () => {
										handleVisibleChange(false);
									},
									clickOutsideDeactivates: true,
									returnFocusOnDeactivate: true,
									allowOutsideClick: true,
									fallbackFocus: '.profibaza-popconfirm',
									preventScroll: true,
								}}
							>
								<div
									tabIndex={-1}
									className="profibaza-popconfirm text-right"
								>
									<div>
										{renderIcon} {title}
									</div>
									<Space className="popover--btn-section">
										<Button
											size="small"
											onClick={() => {
												onCancel?.();
												handleVisibleChange(false);
											}}
										>
											{cancelText ?? 'Nie'}
										</Button>
										<Button
											size="small"
											type="primary"
											onClick={() => {
												onConfirm?.();
												handleVisibleChange(false);
											}}
										>
											{okText ?? 'Tak'}
										</Button>
									</Space>
								</div>
							</FocusTrap>
						)}
					</>
				}
				placement={placement ?? 'bottomRight'}
				okText={undefined}
				okButtonProps={{ className: 'd-none' }}
				cancelText={undefined}
				cancelButtonProps={{ className: 'd-none' }}
				trigger={['click']}
				visible={visible}
				icon={<></>}
				onVisibleChange={
					props.visibleCallback ? () => {} : handleVisibleChange
				}
				destroyTooltipOnHide={{ keepParent: false }}
				{...otherProps}
			>
				{props.children}
			</Popconfirm>
		</>
	);
};

export default AccessiblePopconfirm;
