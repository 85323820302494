import { IDictionaryStore } from '@components/dictionary/dictionaryStore';
import AddButton from '@components/shared/buttons/AddButton';
import TableActionButton from '@components/shared/buttons/TableActionButton';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import RenderActions from '@components/statements/table/RenderActions';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import {
	AnnualNumberOfRecipientsDto,
	DictionaryInterventionPath,
} from '@services/src/models';
import { Button, Col, Modal, Row, Table } from 'antd';
import { Space, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FFieldLabel,
	FInputNumber,
	IFormikInputProps,
} from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { annualNumberOfRecipientsValidationSchema } from './AnnualNumberOfRecipientsValidationSchema';

const { Text } = Typography;

interface IProps {
	implementationPeriodDictionaryValueId?: number;
	readOnly: boolean;
}

const AnnualNumberOfRecipients: React.FC<IFormikInputProps & IProps> = (
	props
) => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);
	const [enabled, setEnabled] = useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [items, setItems] = useState<AnnualNumberOfRecipientsDto[]>([]);

	const dictionaryValues = dictionaryStore.getDictionary(
		'TYP_OKRESU_REALIZACJI',
		DictionaryInterventionPath.PROHEALTHACTIVITIES
	);
	useEffect(() => {
		if (dictionaryValues !== undefined) {
			const item = dictionaryValues.items?.find(
				(x) => x.id === props.implementationPeriodDictionaryValueId
			);
			if (item !== undefined) {
				if (
					item.codeName ===
					DictionariesConstants.ImplementationPeriod.Multiannual
				) {
					setEnabled(true);
					return;
				}
			}
			setEnabled(false);
			props.form.setFieldValue(props.field.name, []);
		}
	}, [props.implementationPeriodDictionaryValueId, dictionaryValues]);

	const columns: ColumnProps<AnnualNumberOfRecipientsDto>[] = [
		{
			dataIndex: 'lp',
			key: 'lp',
			title: 'Lp.',
			render: (value: any, _, index: number) => index + 1,
		},
		{
			dataIndex: 'year',
			key: 'year',
			title: 'Rok',
		},
		{
			dataIndex: 'numberOfRecipients',
			key: 'numberOfRecipients',
			title: 'Liczba odbiorców',
		},
	];

	if (!props.readOnly) {
		columns.push(
			RenderActions<AnnualNumberOfRecipientsDto>(
				(text, record, index) => (
					<>
						<AccessiblePopconfirm
							title={
								<span>
									Czy na pewno chcesz usunąć wartość dla roku{' '}
									{record.year} ?
								</span>
							}
							onConfirm={() => {
								setItems(
									items.filter((x) => x.year !== record.year)
								);
							}}
						>
							<TableActionButton label="Usuń" kind="DELETE" />
						</AccessiblePopconfirm>
					</>
				)
			)
		);
	}

	return (
		<>
			<CenteredRow>
				<Button
					style={{ marginTop: -1 }}
					size="middle"
					type="primary"
					disabled={!enabled}
					onClick={() => {
						setItems(props.field.value);
						setIsModalVisible(true);
					}}
				>
					dodaj / zmień
				</Button>
			</CenteredRow>

			<Formik
				validateOnBlur={true}
				initialValues={{}}
				validationSchema={annualNumberOfRecipientsValidationSchema}
				onSubmit={(
					values: AnnualNumberOfRecipientsDto,
					actions: FormikActions<AnnualNumberOfRecipientsDto>
				) => {
					if (items.some((x) => x.year === values.year)) {
						actions.setFieldError(
							'year',
							'Wartość dla tego roku istnieje już w tabeli'
						);
						actions.setSubmitting(false);
						return;
					}

					setItems([...items, values].sort((x) => x.year!));
					actions.resetForm();
				}}
				render={(
					formikProps: FormikProps<AnnualNumberOfRecipientsDto>
				) => (
					<Modal
						visible={isModalVisible}
						title={
							<Space direction="vertical" size={0}>
								<Text>Liczba odbiorców</Text>
								<Text
									type="secondary"
									style={{ fontSize: '9pt' }}
								>
									w poszczególnych latach realizacji
									interwencji
								</Text>
							</Space>
						}
						destroyOnClose
						onCancel={() => setIsModalVisible(false)}
						okText="Zatwierdź"
						okButtonProps={{
							disabled: props.readOnly,
						}}
						onOk={() => {
							props.form.setFieldValue(props.field.name, items);
							setIsModalVisible(false);
						}}
						footer={[
							<Button
								key="back"
								onClick={() => setIsModalVisible(false)}
							>
								Anuluj
							</Button>,
							!props.readOnly &&
								(formikProps.values.numberOfRecipients !==
									undefined ||
								formikProps.values.year !== undefined ? (
									<AccessiblePopconfirm
										title="Masz niezapisane dane. Wciśnij przycisk 'dodaj do tabeli' aby dane nie zostały utracone. Czy kontynuować?"
										placement="top"
										onConfirm={() => {
											props.form.setFieldValue(
												props.field.name,
												items
											);
											setIsModalVisible(false);
										}}
									>
										<Button key="submit" type="primary">
											Zatwierdź
										</Button>
									</AccessiblePopconfirm>
								) : (
									<Button
										key="submit"
										type="primary"
										onClick={() => {
											props.form.setFieldValue(
												props.field.name,
												items
											);
											setIsModalVisible(false);
										}}
									>
										Zatwierdź
									</Button>
								)),
						]}
					>
						<>
							{!props.readOnly && (
								<Row gutter={[4, 4]}>
									<Col span={8}>
										<FFieldLabel label={'Rok'} for="year" />
										<Field
											component={FInputNumber}
											style={{
												width: '100%',
											}}
											inputReadOnly={false}
											name="year"
											precision={0}
										/>
									</Col>
									<Col span={8}>
										<FFieldLabel
											label={'Liczba odbiorców'}
										/>
										<Field
											component={FInputNumber}
											style={{
												width: '100%',
											}}
											inputReadOnly={false}
											name="numberOfRecipients"
											precision={0}
											aria-hidden={true}
										/>
									</Col>
									<Col span={8}>
										<AddButton
											disabled={formikProps.isSubmitting}
											style={{ marginTop: 24 }}
											onClick={() =>
												formikProps.submitForm()
											}
											aria-label="Dodaj do tabeli"
										>
											dodaj do tabeli
										</AddButton>
									</Col>
								</Row>
							)}

							<Table<AnnualNumberOfRecipientsDto>
								size="small"
								columns={columns}
								dataSource={[...items]}
								pagination={false}
							></Table>
						</>
					</Modal>
				)}
			/>
		</>
	);
};

export default AnnualNumberOfRecipients;
