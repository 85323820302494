import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import { Col, Space } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { ajaxByUser } from 'helper/api';
import produce from 'immer';
import { CenteredRow } from 'layout/CenteredRow';
import { showNotExpectedErrorModal } from 'layout/Modals';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import EditNotificationModal, {
	IEditNotificationModal,
} from './modals/editNotificationModal/EditNotificationModal';
import notificationTableColumns from './tableColumns/notificationTableColumns';
import ChannelState from './types/ChannelState';

interface IProps {}

const NotificationsView: React.FC<IProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<
		ProfiBazaAPIModels.NotificationDefinitionDTO[]
	>();
	const [channelState, setChannelState] = useState<Map<string, ChannelState>>(
		new Map<string, ChannelState>()
	);
	const [lastChangedId, setLastChangedId] = useState<string | undefined>(
		undefined
	);

	const notificationModalRef = useRef<
		IEditNotificationModal
	>() as React.RefObject<IEditNotificationModal>;

	const fetchData = async (signal?: AbortSignal) => {
		try {
			const client = await getProfiBazaApiClient();
			await client.notificationDefinition
				.getAll({ abortSignal: signal })
				.then((result) => {
					setLoading(false);
					setData(result);

					const newChannelState = produce(channelState, (draft) => {
						result.forEach((x) =>
							draft.set(x.id!, {
								systemChannelActive:
									x.systemChannelActive ?? false,
								emailChannelActive:
									x.emailChannelActive ?? false,
								loading: false,
							})
						);
					});
					setChannelState(newChannelState);
				});
		} catch (err) {
			showNotExpectedErrorModal(err);
		}
	};

	useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;
		fetchData(signal);

		return () => {
			abortController.abort();
		};
	}, []);

	useEffect(() => {
		if (!lastChangedId) return;
		const updatedItem = channelState.get(lastChangedId!);
		if (!updatedItem) return;
		ajaxByUser('Zaktualizowano definicję powiadomienia', () =>
			getProfiBazaApiClient().then((api) =>
				api.notificationDefinition.updateChannels({
					body: {
						id: lastChangedId,
						systemChannelActive: updatedItem.systemChannelActive,
						emailChannelActive: updatedItem.emailChannelActive,
					},
				})
			)
		).then(() => {
			if (
				!updatedItem.systemChannelActive &&
				!updatedItem.emailChannelActive
			) {
				setData((currData) =>
					produce(currData, (draft) => {
						const changedItem = draft?.find(
							(x) => x.id === lastChangedId
						);
						if (changedItem) {
							changedItem.isActive = false;
						}
					})
				);
			}
			setLastChangedId(undefined);
		});

		return () => {};
	}, [lastChangedId, channelState]);

	const tableColumns = [
		...notificationTableColumns,
		{
			dataIndex: 'visibility',
			key: 'visibility',
			title: '',
			width: 250,
			render: (
				text: string,
				record: ProfiBazaAPIModels.NotificationDefinitionDTO,
				index: number
			) => (
				<>
					{record.isActive && (
						<fieldset>
							<legend className="sr-only">
								Widoczność globalna: {record.event}
							</legend>
							<Space>
								<Checkbox
									checked={
										channelState.get(record.id!)
											?.systemChannelActive
									}
									onChange={(e: CheckboxChangeEvent) => {
										const newChannelState = produce(
											channelState,
											(draft) => {
												const previous = draft.get(
													record.id!
												);
												if (previous) {
													previous.systemChannelActive =
														e.target.checked;
													previous.loading = true;
												}
											}
										);
										setChannelState(newChannelState);
										setLastChangedId(record.id);
									}}
								>
									System
								</Checkbox>
								<Checkbox
									checked={
										channelState.get(record.id!)
											?.emailChannelActive
									}
									onChange={(e: CheckboxChangeEvent) => {
										const newChannelState = produce(
											channelState,
											(draft) => {
												const previous = draft.get(
													record.id!
												);
												if (previous) {
													previous.emailChannelActive =
														e.target.checked;
													previous.loading = true;
												}
											}
										);
										setChannelState(newChannelState);
										setLastChangedId(record.id);
									}}
								>
									Email
								</Checkbox>
							</Space>
						</fieldset>
					)}
				</>
			),
		},
		RenderActions<ProfiBazaAPIModels.NotificationDefinitionDTO>(
			(text, record) => (
				<>
					{record.isActive ? (
						<ProfiBazaTooltip placement="top" title="Dezaktywuj">
							<AccessiblePopconfirm
								title={
									<span>
										Czy na pewno chcesz dezaktywować
										powiadomienie{' '}
										<strong>{record.event}</strong>?
									</span>
								}
								onConfirm={() => {
									ajaxByUser(
										'Dezaktywowano definicję powiadomienia',
										() =>
											getProfiBazaApiClient()
												.then((api) =>
													api.notificationDefinition.deactivate(
														{
															body: {
																id: record.id!,
															},
														}
													)
												)
												.then(async (resp) => {
													await fetchData();
												})
									);
								}}
							>
								<TableActionButton
									label="Dezaktywuj"
									kind="DEACTIVATE"
								/>
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					) : (
						<ProfiBazaTooltip placement="top" title="Aktywuj">
							<AccessiblePopconfirm
								title={
									<span>
										Czy na pewno chcesz aktywować
										powiadomienie{' '}
										<strong>{record.event}</strong>?
									</span>
								}
								onConfirm={() => {
									ajaxByUser(
										'Aktywowano definicję powiadomienia',
										() =>
											getProfiBazaApiClient()
												.then((api) =>
													api.notificationDefinition.activate(
														{
															body: {
																id: record.id!,
															},
														}
													)
												)
												.then(async (resp) => {
													await fetchData();
												})
									);
								}}
							>
								<TableActionButton
									label="Aktywuj"
									kind="ACTIVATE"
								/>
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					)}

					<ProfiBazaTooltip placement="top" title="Edytuj">
						<TableActionButton
							label="Edytuj"
							kind="EDIT"
							onClick={() => {
								notificationModalRef.current?.showModal(
									record.id!
								);
							}}
						/>
					</ProfiBazaTooltip>
				</>
			)
		),
	];

	const handleTableRefresh = () => {
		fetchData();
	};

	return (
		<>
			<PageHeaderWithContent title="Zarządzanie powiadomieniami" />
			<SkipLinkContent />
			<CenteredRow>
				<Col span={23}>
					<ProfiBazaTable
						loading={loading}
						rowKey={(
							r: ProfiBazaAPIModels.NotificationDefinitionDTO
						) => r.id!}
						columns={tableColumns}
						pagination={false}
						dataSource={data}
					/>
				</Col>
			</CenteredRow>

			<EditNotificationModal
				ref={notificationModalRef}
				onCreate={handleTableRefresh}
			/>
		</>
	);
};

export default NotificationsView;
