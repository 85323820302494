import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import React from 'react';

const { Dragger } = Upload;

interface IProps {
	uploadProps: any;
}

const FileUploadArea: React.FC<IProps> = (props) => {
	return (
		<div style={{ margin: '20px 0px' }}>
			<Dragger aria-label="Załącznik" {...props.uploadProps}>
				<p className="ant-upload-drag-icon">
					<UploadOutlined />
				</p>
				<p className="ant-upload-text">
					Przeciągnij i upuść plik tutaj aby dodać plik lub kliknij
					aby dodać pliki ręcznie
				</p>
				<p>(.pdf)</p>
			</Dragger>
		</div>
	);
};

export default FileUploadArea;
