import { Col, Input, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { IFormikInputProps } from 'forms/FormikFormItems';
import { showWarning } from 'layout/Modals';
import React, { useImperativeHandle, useState } from 'react';
import {
	DictionaryValueItemDto,
	RizpDictionaryValueDto,
} from 'services/src/models';

interface IProps {
	multiple?: boolean;
	parents?: (RizpDictionaryValueDto | undefined)[];
	setValues?: React.Dispatch<React.SetStateAction<DictionaryValueItemDto[]>>;
	checkChanges?: (key: any, value?: any, parent?: any) => void;
	singleLevel?: boolean;
	otherName?: string;
	valueId?: number;
	valueName?: string;
}

export interface IOtherValueModal {
	showModal: (item: DictionaryValueItemDto) => void;
}

const OtherValueModal = React.forwardRef<
	IOtherValueModal,
	IProps & IFormikInputProps
>((props, ref) => {
	const {
		form,
		field,
		multiple,
		setValues,
		checkChanges,
		singleLevel,
		otherName,
		parents,
		valueName,
		valueId,
	} = props;

	const [visible, setVisible] = useState<boolean>(false);
	const [value, setValue] = useState<string | undefined>(undefined);
	const [item, setItem] = useState<DictionaryValueItemDto | undefined>(
		undefined
	);

	useImperativeHandle(ref, () => ({
		showModal: (item: DictionaryValueItemDto) => {
			setVisible(true);
			setItem(item);
		},
	}));

	const handleClose = () => {
		setVisible(false);
		setValue(undefined);
		setItem(undefined);
	};

	const dictionaryAlreadyContainsOtherValue = (values: any) => {
		if (Array.isArray(values) && values.some((x: any) => x.otherValue)) {
			showWarning(`Wartość 'inne, jakie?' istnieje w słowniku.`);
			return true;
		}
		return false;
	};

	return (
		<Modal
			visible={visible}
			maskClosable={false}
			centered
			destroyOnClose
			title="Wartość inna"
			onOk={() => {
				if (value) {
					if (!dictionaryAlreadyContainsOtherValue(field.value)) {
						const otherItem: RizpDictionaryValueDto = {
							id: item?.id,
							otherValue: value,
							code: item?.code,
							codeName: item?.codeName,
							value: `${item?.value}: ${value}`,
							breadcrumb: item?.breadcrumb,
						};
						let newValues;
						if (singleLevel) {
							if (multiple) {
								const oldValues = field.value ?? [];
								otherName &&
									valueId &&
									form.setFieldValue(field.name, [
										...oldValues,
										{
											[valueId]: otherItem.id,
											[otherName]: value,
											[valueName!]: item?.value,
										},
									]);
							} else {
								valueName &&
									form.setFieldValue(
										valueName,
										otherItem.value
									);
								if (otherName) {
									form.setFieldValue(otherName, value);
								}
							}
						} else {
							if (multiple) {
								if (Array.isArray(field.value)) {
									newValues = [...field.value, otherItem];
									form.setFieldValue(field.name, newValues);
								} else {
									newValues = [otherItem];
									form.setFieldValue(field.name, newValues);
								}
								setValues &&
									setValues((items) => [...items, otherItem]);
							} else {
								newValues = otherItem;
								form.setFieldValue(field.name, newValues.id);
								otherName &&
									form.setFieldValue(
										otherName,
										newValues.otherValue
									);
								valueName &&
									form.setFieldValue(valueName, item?.value);
								setValues && setValues((items) => [otherItem]);
							}
						}

						form.setFieldTouched(field.name, true);
						form.validateField(field.name);
						checkChanges &&
							checkChanges(field.name, otherItem, parents);
					}

					handleClose();
				}
			}}
			onCancel={handleClose}
			okText="Dodaj"
			cancelText="Anuluj"
		>
			<Row>
				<Col span={24}>
					<Input
						name="otherValue"
						onChange={(e) => setValue(e.target.value)}
						value={value}
					/>
				</Col>
			</Row>
		</Modal>
	);
});

export default OtherValueModal;
