import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import useTabAccessibility from '@hooks/accessibility/useTabAccessibilityHook';
import { Col } from 'antd';
import { Tabs } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';

import FormDetails from './FormDetails';
import FormsTable from './table/FormsTable';
import StatementTestRegistry from './test/StatementTestRegistry';
const { TabPane } = Tabs;

interface IProps {
	formId?: string;
	formVersionId?: string;
}

const Forms: React.FC<IProps & RouteComponentProps> = (props) => {
	const history = useHistory();

	const location = useLocation();
	const [activeTab, setActiveTab] = useState<string>('main');

	const onTabChanged = (activeKey: string) => {
		if (activeKey === 'main') {
			history.push(`/forms`);
		} else {
			history.push(`/forms/${activeKey}`);
		}
	};

	useEffect(() => {
		if (location.pathname.startsWith('/forms/test')) {
			setActiveTab('test');
		} else {
			setActiveTab('main');
		}
	}, [location]);

	useTabAccessibility();

	return (
		<>
			<Tabs
				onChange={onTabChanged}
				activeKey={activeTab}
				defaultActiveKey="main"
				centered
			>
				<TabPane tab="Formularze" key="main">
				<SkipLinkContent />
					<CenteredRow>
						<Col span={22}>
							<FormsTable {...props} />
						</Col>
						<Col span={22}>
							{props.formId && (
								<FormDetails
									formId={props.formId}
									formVersionId={props.formVersionId}
								></FormDetails>
							)}
						</Col>
					</CenteredRow>
				</TabPane>
				<TabPane tab="Sprawozdania testowe" key="test">
					<StatementTestRegistry />
				</TabPane>
			</Tabs>
		</>
	);
};

export default Forms;
