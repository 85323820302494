import { ProfiBazaColumnProps } from '@components/shared/paginatedProfiBazaTable/GridHelper';
import {
	UserManualDto,
	UserManualInterventionPath,
} from '@services/src/models';
import * as UserManualInterventionPathMapper from '@services/mappers/UserManualInterventionPath';

export const InterventionColumns: ProfiBazaColumnProps<UserManualDto>[] = [
	{
		sorter: false,
		dataIndex: 'interventionPath',
		key: 'interventionPath',
		title: 'Ścieżka interwencji',
		render: (interventionPath: UserManualInterventionPath) =>
			UserManualInterventionPathMapper.map(interventionPath),
	},
];
