import TableActionButton from 'components/shared/buttons/TableActionButton';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { ajaxByUser } from 'helper/api';
import { useRootData } from 'hooks/hook';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { StatementTestListItemDto } from 'services/src/models';
import { IGridStore } from 'stores/GridStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

import PaginatedProfiBazaTable from '../../shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import { SieveModel } from '../../shared/paginatedProfiBazaTable/SieveModels';

interface IProps {}

const StatementsTestTable: React.FC<IProps> = (props) => {
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const [loadedTable, setLoadedTable] = useState<boolean>(false);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const columns: ProfiBazaColumnProps<StatementTestListItemDto>[] = [
		RenderActions<StatementTestListItemDto>((text, record) => (
			<>
				<ProfiBazaTooltip placement="top" title="Podgląd">
					<TableActionButton
						label="Podgląd"
						kind="DETAILS"
						linkTo={`/forms/test/details/${record.id}`}
					/>
				</ProfiBazaTooltip>

				<ProfiBazaTooltip placement="top" title="Edycja">
					<TableActionButton
						kind="EDIT"
						label="Edycja"
						linkTo={`/forms/test/edit/${record.id}`}
					/>
				</ProfiBazaTooltip>

				<ProfiBazaTooltip placement="top" title="Usuń">
					<AccessiblePopconfirm
						title={
							<span>
								{`Czy na pewno chcesz usunąć sprawozdanie testowe ${record.statementArea?.replace(
									'_',
									' '
								)} za rok ${record.year} wersja ${
									record.formVersion
								}?`}
							</span>
						}
						onConfirm={() => {
							ajaxByUser('Sprawozdanie testowe usunięte', () =>
								getProfiBazaApiClient()
									.then((api) =>
										api.statementsTest.deleteMethod(
											record.id!
										)
									)
									.then(() => {
										gridStore.searching.set(true);
									})
							);
						}}
					>
						<TableActionButton label="Usuń" kind="DELETE" />
					</AccessiblePopconfirm>
				</ProfiBazaTooltip>
			</>
		)),

		...tableColumnStore.getTable('statementsTest'),
	];

	return (
		<PaginatedProfiBazaTable<StatementTestListItemDto>
			gridName="statementsTest"
			detailsUrl="/forms/test/details"
			columns={columns}
			getRowKey={(r: StatementTestListItemDto) => r.id!}
			hasRowSelection
			getPagedResult={(sieve: SieveModel, filter, abortSignal) => {
				const parameters = {
					filters: sieve.filters,
					sorts: sieve.sorts,
					page: sieve.page,
					pageSize: sieve.pageSize,
					abortSignal,
				};
				return getProfiBazaApiClient().then((api) => {
					return api.statementsTest
						.all(parameters)
						.then((result: any) => result);
				});
			}}
			isLoaded={() => setLoadedTable(true)}
		/>
	);
};

export default observer(StatementsTestTable);
