import React from 'react';

export interface ProfiBazaTableEvents {
	onDoubleClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	onMouseEnter?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	onMouseLeave?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	onContextMenu?: (event: any) => void;
}

export const getTableEvents = (): ProfiBazaTableEvents => {
	const tableEvents: ProfiBazaTableEvents = {
		onMouseEnter: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {},
		onMouseLeave: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {},
	};

	return tableEvents;
};

export const findChildBySelector = (
	element: EventTarget & HTMLElement,
	selector: string
) => element?.querySelector(selector);
