import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { useRootData } from 'hooks/hook';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';

export default class PermissionValidator {
	private account: ProfiBazaAPIModels.AccountDetailsDto;

	constructor(account: ProfiBazaAPIModels.AccountDetailsDto) {
		this.account = account;
	}

	public has(permission: ProfiBazaAPIModels.Permission): boolean {
		return (
			this.account !== undefined &&
			this.account.permissions !== undefined &&
			this.account.permissions!.includes(permission)
		);
	}

	public hasAny(permissions: ProfiBazaAPIModels.Permission[]): boolean {
		var isValid = false;
		for (let permission of permissions) {
			isValid =
				this.account !== undefined &&
				this.account.permissions !== undefined &&
				this.account.permissions!.includes(permission);
			if (isValid) {
				return isValid;
			}
		}
		return isValid;
	}

	public hasAnyPermission(): boolean {
		var isValid =
			this.account !== undefined &&
			this.account.permissions !== undefined &&
			this.account.permissions!.length > 0;

		return isValid;
	}
}
