import { Button, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import { Field, Formik, FormikProps } from 'formik';
import { FFieldLabel, FInputFile } from 'forms/FormikFormItems';
import { ajaxByUser } from 'helper/api';
import { useState } from 'react';
import React from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import * as Yup from 'yup';

import { file_extensions } from '../model/ProposalAccessCommand';

interface IProps {
	visible: boolean;
	selectedRecord: ProfiBazaAPIModels.ProposalItemDto | undefined;
	onCreate: (refresh: boolean) => void;
}

let fileType: string = '';

const validation = Yup.object().shape({
	file: Yup.string()
		.test('isFile', 'Załącznik jest wymagany', (value) => !!value)
		.test(
			'fileType',
			'Złe rozszerzenie załącznika',
			(value) => !value || file_extensions.includes(fileType)
		),
});

const AddProposalFileModal = (props: IProps) => {
	const [file, setFile] = useState<File | undefined>(undefined);

	return (
		<Formik
			initialValues={{ file: undefined }}
			enableReinitialize
			onSubmit={(value, actions) => {
				ajaxByUser('Załącznik dodany', () =>
					getProfiBazaApiClient()
						.then((api) =>
							api.proposal.addAttachmentProposal({
								id: props.selectedRecord!.id,
								file: file,
							})
						)
						.then(() => {
							props.onCreate(true);
							setFile(undefined);
						})
				);
			}}
			validationSchema={validation}
			render={(formik: FormikProps<any>) => (
				<Modal
					visible={props.visible}
					destroyOnClose
					centered
					width={'50%'}
					title={'Dodanie załącznika'}
					onCancel={() => {
						formik.resetForm();
						props.onCreate(false);
						setFile(undefined);
					}}
					footer={
						<Space>
							<AccessiblePopconfirm
								title="Czy na pewno chcesz dodać załącznik?"
								onConfirm={() => {
									formik.submitForm();
								}}
							>
								<Button
									key="submit"
									type="primary"
									aria-label="Zatwierdź"
									disabled={!formik.isValid}
								>
									Zatwierdź
								</Button>
							</AccessiblePopconfirm>
							<Button
								aria-label="Anuluj"
								key="cancel"
								type="default"
								onClick={() => {
									formik.resetForm();
									props.onCreate(false);
									setFile(undefined);
								}}
							>
								Anuluj
							</Button>
						</Space>
					}
				>
					<>
						<FFieldLabel label="Załącznik" />
						<Field
							label="Załącznik"
							component={FInputFile}
							name="file"
							accept=".jpg, .bmp, .pdf, .png, .jpeg"
							getFile={(file: File) => {
								fileType = file?.type;
								setFile(file);
							}}
						/>
					</>
				</Modal>
			)}
		/>
	);
};
export default AddProposalFileModal;
