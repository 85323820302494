import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { StatementArea } from 'services/src/models';

export class CreateStatementTestCommand {
	year?: string;
	statementArea?: StatementArea;
	subjectId?: string;
	subjectName?: string;
	formVersionId?: string;

	constructor(model: ProfiBazaAPIModels.CreateStatementTestCommand) {
		this.year = model.year?.toString();
		this.statementArea = model.statementArea;
		this.subjectId = model.subjectId;
		this.formVersionId = model.formVersionId;
	}
	static toCommand(
		model: CreateStatementTestCommand
	): ProfiBazaAPIModels.CreateStatementTestCommand {
		const command: ProfiBazaAPIModels.CreateStatementTestCommand = {
			year: Number(model.year),
			statementArea: model.statementArea,
			subjectId: model.subjectId,
			formVersionId: model.formVersionId,
		};
		return command;
	}
}
