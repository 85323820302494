import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<ProfiBazaAPIModels.PatternStatus, string>([
	[ProfiBazaAPIModels.PatternStatus.DRAFT, 'roboczy'],
	[ProfiBazaAPIModels.PatternStatus.ACTIVE, 'aktywny'],
	[ProfiBazaAPIModels.PatternStatus.INACTIVE, 'nieaktywny'],
]);

export const map = (value: ProfiBazaAPIModels.PatternStatus): string =>
	mappings.get(value) ?? '';
