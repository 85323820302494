import { ajaxCatch } from 'helper/api';
import {
    CalendarEventDTO,
} from 'services/src/models';
import { observable } from 'mobx';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { Moment } from 'moment';
import moment from 'moment';

export const calendarStore = {
	events: observable(new Array<CalendarEventDTO>()),
    startDate: observable.box<Moment | undefined>(undefined),
	endDate: observable.box<Moment | undefined>(undefined),

	initialize: () => {
        var startDate = moment().startOf('month').add(-7, "day");
        var endDate = moment().endOf('month').add(7, "day");

        calendarStore.startDate.set(startDate);
        calendarStore.endDate.set(endDate);

        calendarStore.events.clear();
        calendarStore.loadCalendarEvents(calendarStore.startDate.get()!, calendarStore.endDate.get()!);
    },
    loadCalendarEvents(startDate: Moment, endDate: Moment) {
        getProfiBazaApiClient().then((api) => {
            api.calendarEvents.getCalendarEvents({ dateFrom: startDate.toDate(), dateTo: endDate.toDate() })
                .then((result: ProfiBazaAPIModels.CalendarEventsGetCalendarEventsResponse) => {
                    result._response.parsedBody.forEach(x => calendarStore.events.push(x));
                });
        });
    },
    loadNextMonth(value: Moment) {
        var firstDayOfMonth = value.add(1, 'month').startOf('month');
        var dateFrom = firstDayOfMonth.clone().add(7, 'day');
        if (dateFrom.isAfter(calendarStore.endDate.get())) {
            var endDate = firstDayOfMonth.clone().endOf('month').add(7, "day");
            calendarStore.endDate.set(endDate);
            calendarStore.loadCalendarEvents(dateFrom!, calendarStore.endDate.get()!);
        }
    },
    loadPreviousMonth(value: Moment) {
        var lastDayOfMonth = value.subtract(1, 'month').endOf('month');
        var dateTo = lastDayOfMonth.clone().add(-7, 'day');
        if (dateTo.isBefore(calendarStore.startDate.get())) {
            var startDate = lastDayOfMonth.clone().startOf('month').add(-7, 'day');
            calendarStore.startDate.set(startDate);
            calendarStore.loadCalendarEvents(calendarStore.startDate.get()!, dateTo!);
        }
    }
};

export const createCalendarStore = () => {
	return calendarStore;
};

export type ICalendarStore = ReturnType<typeof createCalendarStore>;
