import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import useTabAccessibility from '@hooks/accessibility/useTabAccessibilityHook';
import { Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import {
	RouteComponentProps,
	useHistory,
	useLocation,
	withRouter,
} from 'react-router';

import SubjectData from './SubjectData';
import SubjectDocuments from './SubjectDocuments';

const { TabPane } = Tabs;

interface IProps {
	subjectId?: any;
	modal?: boolean;
	editable?: boolean;
	simpleTabChange?: boolean;
	span?: number;
}

const SubjectDetails: React.FC<RouteComponentProps & IProps> = (props) => {
	const [activeTab, setActiveTab] = useState<string>('main');
	const location = useLocation();
	const history = useHistory();

	const onTabChanged = (activeKey: string) => {
		if (activeKey === 'main') {
			history.push(
				`/subject/${props.editable === true ? 'edit' : 'details'}/${
					props.subjectId
				}`
			);
		} else {
			history.push(
				`/subject/${props.editable === true ? 'edit' : 'details'}/${
					props.subjectId
				}/${activeKey}`
			);
		}
	};

	useEffect(() => {
		if (location.pathname.includes('/documents')) {
			setActiveTab('documents');
		} else {
			setActiveTab('main');
		}
	}, [location]);

	useTabAccessibility();

	return (
		<Tabs
			onChange={props.simpleTabChange ? setActiveTab : onTabChanged}
			activeKey={activeTab}
			defaultActiveKey="main"
			centered
		>
			<SkipLinkContent />
			<TabPane tab="Podmiot" key="main">
				<SubjectData {...props} />
			</TabPane>	
			<TabPane tab="Dokumenty" key="documents">
				{props.subjectId && (
					<SubjectDocuments
						subjectId={props.subjectId}
						editable={props.editable}
						{...props}
					/>
				)}
			</TabPane>
		</Tabs>
	);
};

export default withRouter(observer(SubjectDetails));
