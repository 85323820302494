import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import { ColumnProps } from 'antd/lib/table';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import RenderActions from 'components/statements/table/RenderActions';
import PrimaryCard from 'layout/PrimaryCard';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { ContactDto } from 'services/src/models';

import SubjectContactModal from './SubjectContactModal';
import { CheckIfContactAlreadyExists } from './SubjectDuplicateValidator';

interface IProps {
	contacts: ContactDto[];
	editable?: boolean;
	visible: boolean;
	setVisible: (visible: boolean) => void;
	setContacts: (values: ContactDto[]) => void;
}

const SubjectContact: React.FC<IProps> = (props) => {
	const [selectedRecord, setSelectedRecord] = useState<
		ContactDto | undefined
	>(undefined);

	const columns: ColumnProps<ContactDto>[] = [
		{
			dataIndex: 'firstName',
			key: 'firstName',
			title: 'Imię',
		},
		{
			dataIndex: 'lastName',
			key: 'lastName',
			title: 'Nazwisko',
		},
		{
			dataIndex: 'position',
			key: 'position',
			title: 'Stanowisko',
		},
		{
			dataIndex: 'email',
			key: 'email',
			title: 'E-mail',
		},
		{
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			title: 'Telefon',
		},
		RenderActions<ContactDto>((text, record) => (
			<>
				{props.editable && (
					<>
						<ProfiBazaTooltip placement="top" title="Edytuj">
							<TableActionButton
								kind="EDIT"
								label="Edytuj"
								onClick={() => {
									props.setVisible(true);
									setSelectedRecord(record);
								}}
							/>
						</ProfiBazaTooltip>
						<ProfiBazaTooltip placement="top" title="Usuń">
							<AccessiblePopconfirm
								placement="bottomRight"
								title={
									<>
										Czy na pewno chcesz usunąć{' '}
										{record.firstName} {record.lastName}?
									</>
								}
								onConfirm={() => {
									let contact = [...props.contacts];
									const index = contact.indexOf(record);

									if (index >= 0) {
										contact.splice(index, 1);
										props.setContacts(contact);
									}
								}}
							>
								<TableActionButton kind="DELETE" label="Usuń" />
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					</>
				)}
			</>
		)),
	];

	return (
		<>
			<PrimaryCard>
				<ProfiBazaTable
					columns={columns}
					pagination={false}
					rowKey={(r: ContactDto) => r?.id?.toString()}
					dataSource={props.contacts}
				/>
			</PrimaryCard>

			<SubjectContactModal
				editable={props.editable}
				visible={props.visible}
				data={selectedRecord}
				checkDuplicates={(newContact) =>
					CheckIfContactAlreadyExists(
						newContact,
						props.contacts,
						false
					)
				}
				onCreate={(values) => {
					let contact = [...props.contacts];
					contact?.push({
						id: values?.id,
						firstName: values?.firstName,
						lastName: values?.lastName,
						position: values?.position,
						email: values?.email,
						phoneNumber: values?.phoneNumber,
					});

					props.setContacts(contact);
					props.setVisible(false);
					setSelectedRecord(undefined);
				}}
				onChange={(values) => {
					var contact = props.contacts.find(
						(x) => x.id === values?.id
					);
					if (contact) {
						contact.firstName = values?.firstName;
						contact.lastName = values?.lastName;
						contact.position = values?.position;
						contact.email = values?.email;
						contact.phoneNumber = values?.phoneNumber;
					}
					props.setVisible(false);
					props.setContacts(props.contacts);
					setSelectedRecord(undefined);
				}}
				onCancel={() => {
					props.setVisible(false);
					setSelectedRecord(undefined);
				}}
			></SubjectContactModal>
		</>
	);
};
export default observer(SubjectContact);
