import { ajaxCatch } from '@helper/api';
import { Col, Form, Modal } from 'antd';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import SearchBar from 'components/shared/paginatedProfiBazaTable/SearchBar';
import RenderActions from 'components/statements/table/RenderActions';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	SubjectAllOptionalParams,
	SubjectListItemDto,
} from 'services/src/models';
import { IGridStore } from 'stores/GridStore';

import PaginatedProfiBazaTable from '../../shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import { SieveModel } from '../../shared/paginatedProfiBazaTable/SieveModels';
import columns from '../../subjects/SubjectMergeTableColumns';
import { SubjectMergeListItemDto } from '../SubjectMergeTableColumns';

interface IProps {
	subjectName: string | undefined;
	mode: 'checkbox' | 'radio' | undefined;
	visible: boolean;
	acceptList?: (list: SubjectMergeListItemDto[]) => void;
	acceptSubject?: (value: SubjectMergeListItemDto | undefined) => void;
	onCancel: () => void;
	selectedRows?: Array<SubjectListItemDto>;
}

export interface ISubjectTableModal {
	resetForm: () => void;
}

interface IState {
	list: SubjectMergeListItemDto[];
	subject: SubjectMergeListItemDto | undefined;
	selectedList: 'all' | 'implementer' | 'sponsor';
}

const SubjectMergeModal: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({
		list: [],
		selectedList: 'all',
		subject: undefined,
	});
	const [tableLoaded, setTableLoaded] = useState<boolean>(false);

	const selectOne = props.mode === 'radio';

	useEffect(() => {
		let selectList: HTMLInputElement[] = [];
		Array.from(document.getElementsByClassName('ant-modal')).forEach(
			(el) =>
				(selectList = [
					...selectList,
					...Array.from(el.getElementsByTagName('input')),
				])
		);
		Array.from(selectList).forEach((el) => {
			if (el.getAttribute('role') == 'combobox') {
				el.setAttribute('aria-label', 'Wybierz');
			}
		});
		let buttonList: HTMLButtonElement[] = [];
		Array.from(document.getElementsByClassName('ant-modal')).forEach(
			(el) =>
				(buttonList = [
					...buttonList,
					...Array.from(el.getElementsByTagName('button')),
				])
		);
		Array.from(buttonList).forEach((el) => {
			if (!el.getAttribute('aria-label')) {
				el.setAttribute('aria-label', 'Przycisk');
			}
		});
	}, [tableLoaded]);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const columnsButtons: ProfiBazaColumnProps<SubjectMergeListItemDto>[] = [
		...columns,
	];

	return (
		<Modal
			destroyOnClose
			afterClose={() => gridStore.clearGridFilter('mergeSubject')}
			title={
				'Wybierz podmiot, z którym chcesz scalić: ' + props.subjectName
			}
			visible={props.visible}
			width={'80%'}
			onCancel={() => {
				props.onCancel();
			}}
			onOk={() => {
				if (props.acceptList) props.acceptList(state.list);
				else if (props.acceptSubject && state.list.length) {
					props.acceptSubject(state.list[0]);
				} else props.onCancel();
			}}
			okButtonProps={{
				disabled: props.mode !== undefined && !state.list.length,
			}}
			okText="Scal"
		>
			<CenteredRow gutter={[16, 24]} style={{ marginTop: 32 }}>
				<Col span={5}></Col>
				<Col span={13}>
					<Form size="large">
						<SearchBar
							placeholder="Wyszukaj podmiot"
							column="(name|codeName)"
							filter="contains"
							gridName="mergeSubject"
						/>
					</Form>
				</Col>
				<Col span={5}></Col>
			</CenteredRow>
			<CenteredRow>
				<Col span={23}>
					<PaginatedProfiBazaTable<SubjectMergeListItemDto>
						gridName="mergeSubject"
						columns={columnsButtons}
						hasRowSelection={props.mode !== undefined}
						getRowSelection={(list: SubjectMergeListItemDto[]) => {
							setState({ ...state, list: list });
						}}
						selectedOne={(list: SubjectListItemDto[]) => {
							if (selectOne) {
								setState({ ...state, list: list });
							}
						}}
						selectedRows={
							props.mode == 'checkbox'
								? props.selectedRows!.map((x) => {
										return { id: x.id, name: x.name };
								  })
								: undefined
						}
						getRowKey={(r: SubjectMergeListItemDto) => r.id!}
						selectedList={
							props.mode == 'checkbox'
								? state.selectedList
								: undefined
						}
						rowSelectionType={props.mode}
						getPagedResult={(
							sieve: SieveModel,
							filters,
							abortSignal
						) => {
							const parameters: SubjectAllOptionalParams = {
								filters: sieve.filters,
								sorts: sieve.sorts,
								page: sieve.page,
								pageSize: sieve.pageSize,
								abortSignal,
							};
							return ajaxCatch(() => {
								return getProfiBazaApiClient().then((api) => {
									return api.subject
										.all(parameters)
										.then((result) => {
											return result;
										});
								});
							});
						}}
						isLoaded={() => setTableLoaded(true)}
					/>
				</Col>
			</CenteredRow>
		</Modal>
	);
};

export default observer(SubjectMergeModal);
