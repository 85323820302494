import { useLayoutEffect } from 'react';

export const usePaginatedTableAccessibility = (dependency: any) => {
	useLayoutEffect(() => {
		if (dependency) {
			removeRoleFromFilterSpanAncestors();
			makeCellsFocusable();
			setPaginationRoles();
			setPaginationOptionsLabel();
			removeAriaDependenciesIfSelectNotInitialized();
			removeLabelForCheckboxesWrappers();
			removeLabelForRadioButtonsWrappers();
			addLabelToTextArea();
		}
		return () => {};
	}, [dependency]);
};

export const useTableAccessibility = (dependency: any) => {
	useLayoutEffect(() => {
		if (dependency) {
			makeCellsFocusable();
			setPaginationRoles();
			setPaginationOptionsLabel();
			removeRoleFromFilterSpanAncestors();
			removeLabelForCheckboxesWrappers();
			removeLabelForRadioButtonsWrappers();
			addLabelToTextArea();
		}
		return () => {};
	}, [dependency]);
};


const removeLabelForCheckboxesWrappers = () => {
	const checkBoxesLabels = document.querySelectorAll(
		'label.ant-checkbox-wrapper'
	);

	Array.from(checkBoxesLabels).forEach((item) => {
		unwrap(item);
	});

	const checkBoxes = document.querySelectorAll('input[type="checkbox"]');

	Array.from(checkBoxes).forEach((item) => {
		item.setAttribute('aria-label', 'Zaznacz wiersz');
	});
};

const removeLabelForRadioButtonsWrappers = () => {
	const checkBoxesLabels = document.querySelectorAll(
		'label.ant-radio-wrapper'
	);

	Array.from(checkBoxesLabels).forEach((item) => {
		unwrap(item);
	});

	const checkBoxes = document.querySelectorAll('input[type="radio"]');

	Array.from(checkBoxes).forEach((item) => {
		item.setAttribute('aria-label', 'Zaznacz wiersz');
	});
};

const addLabelToTextArea = () => {
	const textareas = document.querySelectorAll("textarea[tab-index='-1']");
	Array.from(textareas).forEach((item) => {
		item.setAttribute('aria-label', 'textarea');
	});
};

const unwrap = (wrapper: any) => {
	const docFrag = document.createDocumentFragment();

	while (wrapper.firstChild) {
		var child = wrapper.removeChild(wrapper.firstChild);
		docFrag.appendChild(child);
	}

	wrapper.parentNode.replaceChild(docFrag, wrapper);
};

const setPaginationRoles = () => {
	const paginationItems = document.querySelectorAll(
		'.ant-pagination-item, .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-next, .ant-pagination-jump-prev'
	);

	Array.from(paginationItems).forEach((item) => {
		item.setAttribute('role', 'button');
		if (!item.getAttribute('aria-label')) {
			const titleAttr = item.getAttribute('title');
			item.setAttribute('aria-label', titleAttr ?? 'Przycisk');
		}
		const button = item.querySelector('button');
		if (button) {
			if (!button.getAttribute('aria-label')) {
				button.setAttribute(
					'aria-label',
					item.getAttribute('title') ?? 'Przycisk'
				);
			}
		}
	});
};

const setPaginationOptionsLabel = () => {
	const paginationOptions = document.querySelector(
		'.ant-pagination-options input'
	);
	if (paginationOptions) {
		paginationOptions.setAttribute(
			'aria-label',
			'Wybór liczby rekordów na stronę'
		);
	}
};

const makeCellsFocusable = () => {
	const queriedCells = document.querySelectorAll('td.ant-table-cell');
	const cellsArray = Array.from(queriedCells);
	if (cellsArray) {
		cellsArray.forEach((cell) => {
			cell.setAttribute('tabindex', '0');
			cell.setAttribute('role', 'cell');
		});
	}
};

const removeRoleFromFilterSpanAncestors = () => {
	let list = document.querySelectorAll('.ant-table-filter-trigger');
	Array.from(list).forEach((el: Element) => {
		if (el.getAttribute('role') === 'button') {
			el.removeAttribute('role');
		}
	});
};

const removeAriaDependenciesIfSelectNotInitialized = () => {
	const paginationOptions = document.querySelector(
		'.ant-pagination-options input'
	);
	if (paginationOptions) {
		const ariaControls = paginationOptions.getAttribute('aria-controls');
		if (ariaControls) {
			const controlledItem = document.getElementById(ariaControls);
			if (!controlledItem) {
				paginationOptions.removeAttribute('aria-owns');
				paginationOptions.removeAttribute('aria-controls');
				paginationOptions.removeAttribute('aria-activedescendant');
			}
		}
	}
};
