import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import {
	DictionaryValueItemDto,
	DictionaryInterventionPath,
} from 'services/src/models';

export class DictionaryValueDto {
	dictionaryVersionId?: number;
	code?: string;
	value?: string;
	canAddTextValue?: boolean;
	canBeSelected?: boolean;
	codeName?: string;
	parentId?: number;
	interventionPaths?: DictionaryInterventionPath[];
	breadcrumb?: string;

	constructor(model: ProfiBazaAPIModels.CreateDictionaryValueCommand) {
		this.dictionaryVersionId = model.dictionaryVersionId;
		this.code = model.code;
		this.value = model.value;
		this.canAddTextValue = model.canAddTextValue;
		this.canBeSelected = model.canBeSelected;
		this.codeName = model.codeName;
		this.parentId = model.parentId;
		this.interventionPaths = model.interventionPaths;
	}

	static toCommand(
		model: DictionaryValueDto
	): ProfiBazaAPIModels.CreateDictionaryValueCommand {
		const command: ProfiBazaAPIModels.CreateDictionaryValueCommand = {
			dictionaryVersionId: model.dictionaryVersionId,
			code: model.code,
			value: model.value,
			canAddTextValue: model.canAddTextValue,
			canBeSelected: model.canBeSelected,
			codeName: model.codeName,
			parentId: model.parentId,
			interventionPaths: model.interventionPaths,
		};
		return command;
	}

	static mapToDictionaryValueItem(
		model: DictionaryValueDto,
		createdId?: number
	): DictionaryValueItemDto {
		return {
			id: createdId,
			code: model.code,
			value: model.value,
			canAddTextValue: model.canAddTextValue,
			canBeSelected: model.canBeSelected,
			codeName: model.codeName,
			interventionPaths: model.interventionPaths,
			hasChildren: false,
			breadcrumb: model.breadcrumb,
		};
	}
}
