import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<ProfiBazaAPIModels.OpinionAOTMiTType, string>([
	[ProfiBazaAPIModels.OpinionAOTMiTType.POSITIVE, 'Decyzja pozytywna'],
	[ProfiBazaAPIModels.OpinionAOTMiTType.NEGATIVE, 'Decyzja negatywna'],
	[ProfiBazaAPIModels.OpinionAOTMiTType.CONDITIONALLYPOSITIVE, 'Decyzja warunkowo pozytywna'],
	[ProfiBazaAPIModels.OpinionAOTMiTType.NOTSUBJECTTOOPINION, 'Nie podlega ocenie'],
	[ProfiBazaAPIModels.OpinionAOTMiTType.FINALREPORT, 'Raport końcowy'],
]);

export const map = (value: ProfiBazaAPIModels.OpinionAOTMiTType): string =>
	mapings.get(value) ?? '';


