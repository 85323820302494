import { menuItems } from 'layout/Menu/MenuInitializer';
import { MenuItemSchema, SubMenuItemSchema } from 'layout/Menu/MenuItems';
import React from 'react';
import { useHistory } from 'react-router';

export const SkipLinks = () => {
	const adminMenu = menuItems.find(
		(x) => x.key === 'admin'
	) as SubMenuItemSchema;

	const adminMenuItems = adminMenu.items;

	const history = useHistory();

	return (
		<ul id="skipLinks">
			<li>
				<a href={`${history.location.pathname}#skipLinkContent`}>
					Przejdź do treści
				</a>
			</li>
			{[...menuItems, ...adminMenuItems].map((x) => {
				const item = x as MenuItemSchema;

				return (
					<li>
						<a href={item.path}>{item.text}</a>
					</li>
				);
			})}
		</ul>
	);
};

export const SkipLinkContent = () => {
	return <div id="skipLinkContent" />;
};
