import './MiniCalendar.less';

import {
	CalendarTwoTone,
	DeleteOutlined,
	EditOutlined,
	LeftOutlined,
	LockTwoTone,
	RightOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import ReakitMenuButton from '@components/shared/buttons/ReakitMenuButton';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import { DateFormat } from '@helper/formatHelpers';
import useCalendarAccessibility from '@hooks/accessibility/useCalendarAccessibilityHook';
import { Button, Calendar, Card, Dropdown, Modal, Row, Space } from 'antd';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import { useRootData } from 'hooks/hook';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Menu, MenuButton, MenuItem, useMenuState } from 'reakit/Menu';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { CalendarEventDTO } from 'services/src/models';

import { ICalendarStore } from './calendarStore';
import EventActionMenu from './EventActionMenu';
import CreateUserPrivateOccurrenceModal, {
	ICreateUserPrivateOccurrenceModal,
} from './UserPrivateOccurrence/CreateUserPrivateOccurrenceModal';
import EditUserPrivateOccurrenceModal, {
	IEditUserPrivateOccurrenceModal,
} from './UserPrivateOccurrence/EditUserPrivateOccurrenceModal';

interface IMiniCalendarProps {}

const MiniCalendar: React.FC<IMiniCalendarProps> = () => {
	const [selectedDay, setSelectedDay] = useState<Moment>(moment());

	const calendarStore: ICalendarStore = useRootData(
		(store) => store.calendarStore
	);

	useEffect(() => {
		calendarStore.initialize();
	}, []);

	useCalendarAccessibility();

	const createUserPrivateOccurrenceModal = useRef<
		ICreateUserPrivateOccurrenceModal
	>() as React.RefObject<ICreateUserPrivateOccurrenceModal>;

	const editUserPrivateOccurrenceModal = useRef<
		IEditUserPrivateOccurrenceModal
	>() as React.RefObject<IEditUserPrivateOccurrenceModal>;

	const eventMenu = (eventId: string) => {
		return (
			<EventActionMenu
				eventId={eventId}
				menuItems={[
					{
						label: (
							<>
								<EditOutlined /> Edytuj
							</>
						),
						onChange: () => {
							editUserPrivateOccurrenceModal.current?.showModal(
								eventId
							);
						},
					},
					{
						label: (
							<>
								<DeleteOutlined /> Usuń
							</>
						),
						onChange: () => {
							Modal.confirm({
								title:
									'Czy na pewno chcesz usunąć prywatne wydarzenie?',
								okText: 'Tak',
								onOk: () => deleteOccurrence(eventId),
							});
						},
					},
				]}
			/>
		);
	};

	const deleteOccurrence = (id: string): void => {
		ajaxByUser('Usunięto zdarzenie prywatne', () =>
			getProfiBazaApiClient().then((api) =>
				api.notificationOccurrence.deleteMethod(id).then(() => {
					calendarStore.initialize();
				})
			)
		);
	};

	function dateCellRender(value: Moment) {
		var containsEvents = calendarStore.events?.some((x) =>
			value.isBetween(x.dateFrom, x.dateTo, 'day', '[]')
		);
		return containsEvents && <div className="event-block"></div>;
	}

	function EventCards(events: CalendarEventDTO[]) {
		return (
			<div className="calendar-events">
				{events?.length <= 0 && 'Brak wydarzeń'}
				{events?.map((item, index) => (
					<Card key={item.id}>
						<div className="d-flex space-between">
							<span>
								{moment(item.dateFrom).isSame(
									item.dateTo,
									'day'
								) ? (
									<>
										{moment(item.dateFrom).format(
											DateFormat.Date
										)}
									</>
								) : (
									<>
										{moment(item.dateFrom).format(
											DateFormat.DayAndMonth
										)}
										-
										{moment(item.dateTo).format(
											DateFormat.Date
										)}
									</>
								)}
							</span>
							{item.isUserEvent! && eventMenu(item.id!)}
						</div>
						<div>
							{item.isUserEvent && (
								<LockTwoTone twoToneColor="#52c41a" />
							)}
							{renderHeader(item)}
						</div>
					</Card>
				))}
			</div>
		);
	}

	const renderHeader = (item: CalendarEventDTO) => (
		<>
			{item.isUserEvent! && <span>{item.description}</span>}
			{!item.isUserEvent! && <span>{item.header}</span>}
		</>
	);

	return (
		<>
			<div className="calendar">
				<Calendar
					fullscreen={false}
					dateCellRender={dateCellRender}
					className="mini-calendar"
					headerRender={({ value, type, onChange, onTypeChange }) => {
						const current = value.clone();
						const localeData = value.localeData();
						const months = [];
						for (let i = 0; i < 12; i++) {
							current.month(i);
							months.push(localeData.months(current));
						}
						const month = value.month();
						return (
							<Row justify="space-between">
								<Button
									className="header-button"
									type="text"
									aria-label="Poprzedni miesiąc"
									onClick={() => {
										onChange(
											value.clone().month(month - 1)
										);
										calendarStore.loadPreviousMonth(value);
									}}
								>
									<LeftOutlined />
								</Button>
								<strong className="calendar-header">
									{months[month]} {value.year()}
								</strong>
								<Button
									className="header-button"
									type="text"
									aria-label="Następny miesiąc"
									onClick={() => {
										onChange(
											value.clone().month(month + 1)
										);
										calendarStore.loadNextMonth(value);
									}}
								>
									<RightOutlined />
								</Button>
							</Row>
						);
					}}
					onSelect={(value) => setSelectedDay(value)}
				/>
				<Row justify="space-between">
					<Button
						type="primary"
						ghost={true}
						style={{ margin: 'auto', marginBottom: '15px' }}
						onClick={() =>
							createUserPrivateOccurrenceModal.current?.showModal()
						}
					>
						+ dodaj zdarzenie prywatne
					</Button>
				</Row>
				<CreateUserPrivateOccurrenceModal
					ref={createUserPrivateOccurrenceModal}
					onCreate={() => {
						calendarStore.initialize();
					}}
				/>
				<EditUserPrivateOccurrenceModal
					ref={editUserPrivateOccurrenceModal}
					onCreate={() => {
						calendarStore.initialize();
					}}
				/>
				<div className="event-card-list minimal-scroll">
					<h3>
						{moment().isSame(selectedDay, 'day')
							? 'Dziś'
							: selectedDay?.format('dddd, ' + DateFormat.Date)}
					</h3>
					{EventCards(
						calendarStore.events?.filter((x) =>
							isExactDay(selectedDay!, x)
						)!
					)}

					<h3>Najbliższe zdarzenia</h3>
					{selectedDay &&
						EventCards(
							calendarStore.events
								?.sort((a, b) =>
									a.dateFrom! > b.dateFrom! ? 1 : -1
								)
								?.filter((x) => isProperWeek(selectedDay!, x))!
						)}
				</div>
			</div>
		</>
	);
};

export default observer(MiniCalendar);

const isExactDay = (day: Moment, event: CalendarEventDTO) => {
	var dateFrom = moment(event?.dateFrom);
	var dateTo = moment(event?.dateTo);
	return day?.isBetween(dateFrom, dateTo, 'day', '[]');
};

const isProperWeek = (date: Moment, event: CalendarEventDTO) => {
	var dateFrom = moment(event?.dateFrom);
	var dateTo = moment(event?.dateTo);
	var weekLater = date.clone().add(7, 'days');
	return (
		dateFrom.isBetween(date, weekLater, 'day', '[]') ||
		dateTo.isBetween(date, weekLater, 'day', '[]')
	);
};
