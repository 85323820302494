import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const columns: ProfiBazaColumnProps<ProfiBazaAPIModels.DictionaryViewDto>[] = [
    {
        sorter: true,
        dataIndex: 'codeName',
        key: 'codeName',
        title: 'Id',
        filter: 'contains',
    },
    {
        sorter: true,
        dataIndex: 'name',
        key: 'name',
        title: 'Nazwa',
        filter: 'contains',
    },
];

export default columns;
