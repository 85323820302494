import { Button, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import { Field, Formik, FormikProps } from 'formik';
import { FFieldLabel, FTextArea } from 'forms/FormikFormItems';
import { ajaxByUser } from 'helper/api';
import React from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProposalStatus } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import * as Yup from 'yup';

interface IProps {
	visible: boolean;
	selectedRecord: ProfiBazaAPIModels.ProposalItemDto | undefined;
	onReject: (refresh: boolean) => void;
}

const validation = Yup.object().shape({
	rejectReason: Yup.string()
		.required('Powód odrzucenia jest wymagany')
		.max(1000, 'Maksymalna długość to 1000 znaków'),
});

const RejectProposalModal = (props: IProps) => {
	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={true}
			initialValues={{ rejectReason: '' }}
			enableReinitialize
			onSubmit={(value, actions) => {
				ajaxByUser('Wniosek odrzuony', () =>
					getProfiBazaApiClient()
						.then((api) =>
							api.proposal.considerProposal({
								body: {
									id: props.selectedRecord!.id,
									status: ProposalStatus.Rejected,
									rejectReason: value.rejectReason,
								},
							})
						)
						.then(() => {
							props.onReject(true);
						})
				);
			}}
			validationSchema={validation}
			render={(formik: FormikProps<any>) => (
				<Modal
					visible={props.visible}
					destroyOnClose
					centered
					width={'50%'}
					title={'Odrzucenie wniosku'}
					footer={
						<Space>
							<AccessiblePopconfirm
								title="Czy na pewno chcesz odrzucić wniosek?"
								onConfirm={formik.submitForm}
							>
								<Button
									key="submit"
									type="primary"
									aria-label="Zatwierdź"
									disabled={!formik.isValid}
								>
									Zatwierdź
								</Button>
							</AccessiblePopconfirm>
							<Button
								aria-label="Anuluj"
								key="cancel"
								type="default"
								onClick={() => {
									formik.resetForm();
									props.onReject(false);
								}}
							>
								Anuluj
							</Button>
						</Space>
					}
				>
					<>
						<FFieldLabel label="Powód odrzucenia" />
						<Field
							component={FTextArea}
							name="rejectReason"
							label="Powód odrzucenia"
						/>
					</>
				</Modal>
			)}
		/>
	);
};
export default RejectProposalModal;
