/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/exporterMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Exporter. */
export class Exporter {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Exporter.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param exportId
   * @param [options] The optional parameters
   * @returns Promise<Models.ExporterGetByIdResponse>
   */
  getById(exportId: string, options?: msRest.RequestOptionsBase): Promise<Models.ExporterGetByIdResponse>;
  /**
   * @param exportId
   * @param callback The callback
   */
  getById(exportId: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param exportId
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(exportId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  getById(exportId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.ExporterGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        exportId,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.ExporterGetByIdResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "exporter/{exportId}",
  urlParameters: [
    Parameters.exportId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    404: {
      bodyMapper: Mappers.ProblemDetails
    },
    default: {}
  },
  serializer
};
