import React, { useState, useImperativeHandle } from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { ajaxByUser } from 'helper/api';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import Modal from 'antd/lib/modal/Modal';
import { CloneFormVersionValidationSchema } from './CloneFormVersionValidationSchema';

interface IProps {
	onCreate: () => void;
}

interface IState {
	visible: boolean;
	formVersionId: string | undefined;
}

export interface ICloneFormVersionModal {
	showModal: (formVersionId: string) => void;
}

const CloneFormVersionModal = React.forwardRef<
	ICloneFormVersionModal,
	IProps
>((props, ref) => {
	const [state, setState] = useState<IState>({
		visible: false,
		formVersionId: undefined,
	});

	useImperativeHandle(ref, () => ({
		showModal: (formVersionId: string) => {
			setState({
				...state,
				visible: true,
				formVersionId: formVersionId,
			});
		},
	}));

	return (
		<Formik
			validateOnChange={true}
			validateOnBlur={false}
			initialValues={{ formVersionId: state.formVersionId }}
			enableReinitialize
			validationSchema={CloneFormVersionValidationSchema}
			onSubmit={(
				values: ProfiBazaAPIModels.CloneFormVersionCommand,
				actions: FormikActions<
					ProfiBazaAPIModels.CloneFormVersionCommand
				>
			) => {
				ajaxByUser(
					'Skopiowano wersję formularza',
					() =>
						getProfiBazaApiClient().then((api) =>
							api.formVersion.clone({
								body: values,
							})
						),
					() => {
						setState({
							...state,
							visible: false,
							formVersionId: undefined,
						});
						actions.resetForm();
						props.onCreate();
					},
					(errors) => {
						actions.setErrors(errors);
					}
				).then(() => actions.setSubmitting(false));
			}}
			render={(
				props: FormikProps<
					ProfiBazaAPIModels.CloneFormVersionCommand
				>
			) => (
				<Modal
					closable={false}
					visible={state.visible}
					maskClosable={false}
					centered					
					onOk={() => {
						if (!props.isSubmitting) props.submitForm();
					}}
					onCancel={() => {
						setState({
							...state,
							visible: false,
							formVersionId: undefined,
						});
						props.resetForm();
					}}
					okButtonProps={{ disabled: props.isSubmitting }}
					cancelButtonProps={{ disabled: props.isSubmitting }}
					okText="Tak"
					cancelText="Nie"
				>
					Czy na pewno chcesz skopiować wersję formularza ?
				</Modal>
			)}
		/>
	);
});

export default CloneFormVersionModal;