import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<
	ProfiBazaAPIModels.InterventionPatternType,
	string
>([
	[
		ProfiBazaAPIModels.InterventionPatternType.AOTMITRECOMENDATION,
		'Program polityki zdrowotnej - na podstawie rekomendacji​ AOTMiT',
	],
	[
		ProfiBazaAPIModels.InterventionPatternType.NOTPROGRAM,
		'Interwencja nieprogramowa jednostek PIS',
	],
	[
		ProfiBazaAPIModels.InterventionPatternType.PROGRAM,
		'Interwencja programowa jednostek PIS',
	],
]);

export const map = (
	value: ProfiBazaAPIModels.InterventionPatternType
): string => mappings.get(value) ?? '';
