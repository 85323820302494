import React from 'react';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import FileSaver, { saveAs } from 'file-saver';
import _ from 'lodash';

interface IProps {
	response: ProfiBazaAPIModels.DictionaryVersionExportByIdResponse;
	action?: () => void;
}

export const DownloadFile = (props: IProps) => {
	var fileName = props.response._response.headers
		.get('content-disposition')
		?.split('filename=')[1]
		.split(';')[0];

	if (
		props.response._response.headers.get('content-type') ==
		'application/pdf'
	)
		if (fileName?.includes('"')) fileName = fileName?.split('"')[1];

	if (props.response.blobBody) {
		props.response.blobBody.then((x) => {
			FileSaver.saveAs(x, _.replace(fileName!, /"/g, ''));
			props.action && props.action();
		});
	}
};
