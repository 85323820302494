import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import { CheckIfContactAlreadyExists } from '@components/subjects/contact/SubjectDuplicateValidator';
import { Col, Row, Space } from 'antd';
import AddButton from 'components/shared/buttons/AddButton';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import RenderActions from 'components/statements/table/RenderActions';
import { CenteredRow } from 'layout/CenteredRow';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useEffect, useState } from 'react';
import { SubjectContactDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import InterventionContactModal from './InterventionContactModal';
import { columnsContact } from './InterventionContactTableColumns';

interface IProps {
	contacts: SubjectContactDto[];
	patternContacts?: SubjectContactDto[];
	editable?: boolean;
	changeList: (value: SubjectContactDto[]) => void;
}

interface IState {
	contacts: SubjectContactDto[];
	contact: SubjectContactDto | undefined;
	visible: boolean;
}

const InterventionContactTable: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({
		contacts: props?.contacts!,
		contact: undefined,
		visible: false,
	});

	useEffect(() => {
		if (props.contacts) {
			setState({
				...state,
				contacts: props.contacts!,
			});
		}
	}, [props.contacts, props.contacts?.length]);

	const columns = props.editable
		? [
				...columnsContact,
				RenderActions<ProfiBazaAPIModels.SubjectContactDto>(
					(text, record) => (
						<>
							<ProfiBazaTooltip placement="top" title="Usuń">
								<AccessiblePopconfirm
									title={
										'Czy na pewno chcesz usunąć element?'
									}
									onConfirm={() => {
										const index = props.contacts.indexOf(
											record
										);
										if (index >= 0) {
											props.contacts.splice(index, 1);
											setState({
												...state,
												contacts: [...state.contacts],
											});
											props.changeList(state.contacts);
										}
									}}
								>
									<TableActionButton
										kind="DELETE"
										label="Usuń"
									/>
								</AccessiblePopconfirm>
							</ProfiBazaTooltip>
							<ProfiBazaTooltip placement="top" title="Edytuj">
								<TableActionButton
									kind="EDIT"
									label="Edytuj"
									onClick={() => {
										setState({
											...state,
											contact: record,
											visible: true,
										});
									}}
								/>
							</ProfiBazaTooltip>
						</>
					)
				),
		  ]
		: columnsContact;

	return (
		<CenteredRow style={{ marginTop: '20px' }}>
			<Col span={24}>
				<Row style={{ marginBottom: '10px' }}>
					<Space align="center">
						<h2 className="m-none">Dane kontaktowe</h2>
						<LabelHelpTooltip
							field="contact"
							defaultValue="Dane kontaktowe"
						/>
						{props.editable ? (
							<AddButton
								onClick={() => {
									setState({
										...state,
										contact: undefined,
										visible: true,
									});
								}}
							>
								Dodaj kontakt
							</AddButton>
						) : (
							<></>
						)}
					</Space>
				</Row>
				<InterventionContactModal
					editable={props.editable}
					visible={state.visible}
					data={state.contact}
					contactsIds={props.contacts.map(({ id }) => id)}
					checkDuplicates={(newContact, subjectContacts) => {
						return CheckIfContactAlreadyExists(
							newContact,
							props.contacts.concat(subjectContacts),
							state.contact ? true : false
						);
					}}
					onChange={(values) => {
						var contact = props.contacts.find(
							(x) => x.id === values?.id
						);
						if (contact) {
							contact.firstName = values?.firstName;
							contact.lastName = values?.lastName;
							contact.position = values?.position;
							contact.email = values?.email;
							contact.phoneNumber = values?.phoneNumber;
							contact.connectedWithIkpRecrutation =
								values?.connectedWithIkpRecrutation;
						}
						setState({
							contacts: [...state.contacts],
							contact: undefined,
							visible: false,
						});
						props.changeList(state.contacts);
					}}
					selectFromList
					onCreate={(values) => {
						let contact = props.contacts;

						contact?.push({
							id: values?.id,
							firstName: values?.firstName,
							lastName: values?.lastName,
							position: values?.position,
							email: values?.email,
							phoneNumber: values?.phoneNumber,
							connectedWithIkpRecrutation:
								values?.connectedWithIkpRecrutation,
						});

						setState({
							contacts: [...state.contacts],
							contact: undefined,
							visible: false,
						});

						props.changeList(state.contacts);
					}}
					onCancel={() => {
						setState({
							contacts: [...state.contacts],
							contact: undefined,
							visible: false,
						});
					}}
				/>

				<ProfiBazaTable
					columns={columns}
					pagination={false}
					rowKey={(r: SubjectContactDto) => r?.id!?.toString()}
					dataSource={state.contacts}
				/>
			</Col>
		</CenteredRow>
	);
};

export default InterventionContactTable;
