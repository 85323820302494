import { HttpHeaders } from '@azure/ms-rest-js';
import { StatusCode } from '@helper/statusCode';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { UserManualDto, UserManualType } from '@services/src/models';
import { Button, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CenteredRow } from 'layout/CenteredRow';
import { showNotExpectedErrorModal, showWarning } from 'layout/Modals';
import { observer } from 'mobx-react';
import React, { ReactNode, useImperativeHandle, useState } from 'react';

import { getFileNameFromHeaders } from '../helpers/UserManualQueryHelper';
import * as UserManualScrollHelper from '../helpers/UserManualScrollHelper';

interface IProps {
	onAccept?: () => void;
	onCancel?: () => void;
	allowCancel?: boolean;
}

export interface IRODOManualModal {
	showModal: (userManual: UserManualDto) => void;
}

interface IState {
	blob: Blob;
	fileName: string | undefined;
}

const RODOManualModal = React.forwardRef<IRODOManualModal, IProps>(
	(props, ref) => {
		const [visible, setVisible] = useState<boolean>(false);
		const [pdfFrameKey] = useState<number>(Math.random());
		const [isBusy, setIsBusy] = useState<boolean>(false);
		const [state, setState] = useState<IState>();

		useImperativeHandle(ref, () => ({
			showModal: (userManual: UserManualDto) => {
				setVisible(true);
				setIsBusy(true);
				getProfiBazaApiClient().then((api) =>
					api.userManual
						.getManual({
							rODOManualType: userManual.rodoManualType,
							manualType: UserManualType.RODO,
						})
						.then(
							(result) => {
								if (result && result.blobBody) {
									result.blobBody?.then((blob) => {
										setState({
											fileName: getFileNameFromHeaders(
												result._response.headers
											),
											blob: blob,
										});
									});
								}
							},
							(err) => {
								if (err.statusCode === StatusCode.NotFound) {
									showWarning(
										'Nie znaleziono klauzuli informacyjnej RODO, skontaktuj się z administratorem.'
									);
								} else {
									showNotExpectedErrorModal(err);
								}
								if (props.onCancel) {
									props.onCancel();
								}
								setVisible(false);
							}
						)
						.finally(() => {
							setIsBusy(false);
						})
				);
			},
		}));

		function GetOnAccept(): ReactNode {
			if (props.onAccept) {
				return (
					<Button
						type="primary"
						size="large"
						onClick={() => {
							if (props.onAccept) {
								props.onAccept();
							}
							setVisible(false);
						}}
					>
						Potwierdzam przeczytanie informacji RODO
					</Button>
				);
			}
			return <></>;
		}

		return (
			<Modal
				visible={visible}
				maskClosable={false}
				centered
				closable={props.allowCancel}
				destroyOnClose
				keyboard={false}
				width="80vw"
				title="Klauzula informacyjna RODO"
				onCancel={() => {
					setVisible(false);
					if (props.onCancel) {
						props.onCancel();
					}
				}}
				footer={GetOnAccept()}
			>
				<Spin tip="Trwa ładowanie.." spinning={isBusy}>
					<CenteredRow>
						{state?.blob && (
							<iframe
								onLoad={UserManualScrollHelper.resetScroll}
								key={pdfFrameKey}
								src={`/pdfjs/web/viewer.html?file=${URL.createObjectURL(
									state?.blob
								)}`}
								width="100%"
								height="700px"
							></iframe>
						)}
					</CenteredRow>
				</Spin>
			</Modal>
		);
	}
);

export default observer(RODOManualModal);
