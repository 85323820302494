import { createContext } from 'react';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export type NotificationContextType = {
	markAsRead?: (notificationId: string) => Promise<void>;
	deactivateSameKind?: (
		notificationId: string,
		kind: ProfiBazaAPIModels.OccurrenceDomainType,
		callback: () => void
	) => Promise<void>;
};

export const NotificationContext = createContext<NotificationContextType>({});
