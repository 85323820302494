import { useEffect, useState } from 'react';

type FocusTrapReturnType = [boolean, () => void, () => void];

export const useFocusTrap = (
	activateVisibility: boolean,
	shouldReturnFocus: boolean = false
): FocusTrapReturnType => {
	const [visible, setVisible] = useState<boolean>(false);

	const activateFocusTrap = () => {
		setVisible(true);
	};
	const deactivateFocusTrap = () => {
		setVisible(false);
	};

	useEffect(() => {
		if (activateVisibility && !shouldReturnFocus) {
			activateFocusTrap();
		} else {
			deactivateFocusTrap();
		}
	}, [activateVisibility, shouldReturnFocus]);

	return [visible, activateFocusTrap, deactivateFocusTrap];
};
