import { DictionariesConstants } from "services/DictionariesConstants";
import { InterventionPatternType, DictionaryInterventionPath, InterventionPath } from "services/src/models";

export const PatternToInterventionTypeMapper = new Map<InterventionPatternType, string>([
    [InterventionPatternType.PROGRAM, DictionariesConstants.InterventionType.Program],
    [InterventionPatternType.NOTPROGRAM, DictionariesConstants.InterventionType.NotProgram],
    [InterventionPatternType.AOTMITRECOMENDATION, DictionariesConstants.InterventionType.AotmitRecomendation],
  ]);

export const InterventionToPatternTypeMapper = new Map<string, InterventionPatternType>([
    [DictionariesConstants.InterventionType.Program, InterventionPatternType.PROGRAM],
    [DictionariesConstants.InterventionType.NotProgram, InterventionPatternType.NOTPROGRAM],
    [DictionariesConstants.InterventionType.AotmitRecomendation, InterventionPatternType.AOTMITRECOMENDATION],
  ]);

export const InterventionToDictionaryPathMapper = new Map<InterventionPath, DictionaryInterventionPath>([
	[InterventionPath.PROHEALTHACTIVITIES, DictionaryInterventionPath.PROHEALTHACTIVITIES],
	[InterventionPath.RESEARCH, DictionaryInterventionPath.RESEARCH],
    [InterventionPath.SUPERVISIONMONITORING, DictionaryInterventionPath.SUPERVISIONMONITORING],
	[InterventionPath.SUPERVISIONSANITARY, DictionaryInterventionPath.SUPERVISIONSANITARY],
	[InterventionPath.STAFFEDUCATION,DictionaryInterventionPath.STAFFEDUCATION],
	[InterventionPath.STAFFMONITORING, DictionaryInterventionPath.STAFFMONITORING],
	[InterventionPath.LEGISLATION, DictionaryInterventionPath.LEGISLATION],
]);