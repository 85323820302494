import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { Button, Col, Modal, Select, Space } from 'antd';
import SubjectsTableModal from 'components/shared/SubjectsTableModal';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FFieldLabel,
	FInput,
	FInputNumber,
	FSelect,
	FTextArea,
} from 'forms/FormikFormItems';
import { FSubjectsPicker } from 'forms/FSubjectPicker';
import React, { useRef, useState } from 'react';
import * as RIZPSubjectKindMappers from 'services/mappers/RIZPSubjectKind';
import { InterventionPartnerDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import * as Yup from 'yup';

import { InterventionCommentArea } from '../comments/InterventionCommentArea';
import { InterventionCommentIcon } from '../comments/InterventionCommentIcon';
import { InterventionPathContextType } from '../context/InterventionPathContext';
import { interventionRealizationSubjectColumns } from '../../subjects/SubjectTableColumns';

const FocusTrap = require('focus-trap-react');

interface IState {
	subjectModalVisible: boolean;
	customModalVisible: boolean;
}

export const InterventionPartners = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	const [state, setState] = useState<IState>({
		customModalVisible: false,
		subjectModalVisible: false,
	});

	let btnRef = useRef<HTMLElement>(null);

	const handleOpenPartners = (
		e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
	) => {
		if (context.editable) setState({ ...state, subjectModalVisible: true });
	};

	const moveFocusBack = () => {
		if (btnRef.current) {
			btnRef.current.focus();
		}
	};

	const setFieldValue = (value: any) => {
		context.formik?.setFieldValue('interventionPartners', value);
		context.checkChanges?.(value, 'interventionPartners');
	};

	return (
		<>
			<Col md={colSpan}>
				<Space>
					<FFieldLabel label="Partnerzy" />
					<LabelHelpTooltip
						field="interventionPartners"
						defaultValue="Partnerzy"
					/>
					<InterventionCommentIcon fieldName="interventionPartners" />
				</Space>

				{context.editable && (
						<Button
							id="testId"
							ref={btnRef}
							style={{ marginLeft: 10, marginTop: '-4px' }}
							size="small"
							onClick={() =>
								setState({ ...state, customModalVisible: true })
							}
						>
							+ dodaj z opisem
						</Button>
					)}
				<InterventionCommentArea fieldName="interventionPartners">
					<Field
						label="Partnerzy"
						mode="multiple"
						component={FSubjectsPicker}
						name="interventionPartners"
						onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
							handleOpenPartners(e);
						}}
						changeData={(v: any) => {
							context.checkChanges?.(v, 'interventionPartners');
						}}
						onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
							if (
								e.key === 'Enter' &&
								!state.subjectModalVisible
							) {
								handleOpenPartners(e);
							}
						}}
						editable={
							context.editable
						}
						readOnly={
							!context.editable
						}
						renderTagLabel={(item: InterventionPartnerDto) =>
							item.subjectId
								? item.subjectName
								: item.subjectKind!
								? `${
										item.subjectName
								  } (${RIZPSubjectKindMappers.map(
										item.subjectKind!
								  )}, ${item.number})`
								: `${item.subjectName} (${item.number})`
						}
					/>
				</InterventionCommentArea>
			</Col>

			{context.editable && (
					<>
					<SubjectsTableModal
						mode="checkbox"
						visible={state.subjectModalVisible}
						onCancel={() => {
							setState({ ...state, subjectModalVisible: false });
						}}
						clearSelectionOnSearching={false}
						onlyActiveSubjects={true}
						columns={interventionRealizationSubjectColumns}
						selectedRows={context.formik?.values.interventionPartners
							?.filter((x) => x.subjectId)
							?.map(
								(x): ProfiBazaAPIModels.SubjectListItemDto => ({
									id: x.subjectId!,
									name: x.subjectName,
									subjectKind: x.subjectKind as
										| ProfiBazaAPIModels.SubjectKind
										| undefined,
								})
							)}
						acceptList={(value) => {
							const list = [
								...context.formik?.values.interventionPartners!?.filter(
									(x) => !x.subjectId
								),
								...value.map((x) => {
									return {
										subjectId: x?.id,
										subjectName: x?.name,
										subjectKind: x?.subjectKind,
									};
								}),
							];
							setFieldValue(list);
							setState({ ...state, subjectModalVisible: false });
						}}
						showImplementerSponsorFilter={false}
					/>
					<CustomInterventionPartnerModal
						isVisible={state.customModalVisible}
						onCancel={() => {
							setState({ ...state, customModalVisible: false });
							moveFocusBack();
						}}
						onSubmit={(item: InterventionPartnerDto) => {
							const list = [
								...context.formik?.values!.interventionPartners!,
								item,
							];
							setFieldValue(list);
							setState({ ...state, customModalVisible: false });
							moveFocusBack();
						}}
					></CustomInterventionPartnerModal>
					</>
				)}
		</>
	);
};

interface IProps {
	isVisible: boolean;
	onCancel: () => void;
	onSubmit: (value: ProfiBazaAPIModels.ImplementingCompanyDto) => void;
}

const validationSchema = Yup.object().shape({
	subjectName: Yup.string()
		.nullable(false)
		.required('Nazwa jest wymagana')
		.max(256, 'Nazwa może mieć maksymalnie 256 znaków'),
	number: Yup.number()
		.nullable(false)
		.required('Liczba partnerów jest wymagana')
		.min(1, 'Liczba partnerów musi być większa od 0'),
});

const CustomInterventionPartnerModal = (props: IProps) => {
	if (!props.isVisible) return <></>;
	return (
		<>
			<Formik
				validateOnChange={true}
				initialValues={{}}
				validationSchema={validationSchema}
				onSubmit={(
					value: ProfiBazaAPIModels.InterventionPartnerDto,
					actions: FormikActions<
						ProfiBazaAPIModels.InterventionPartnerDto
					>
				) => {
					value.number = parseInt(value.number as any);
					props.onSubmit(value);
					actions.resetForm();
				}}
				render={(
					formik: FormikProps<
						ProfiBazaAPIModels.InterventionPartnerDto
					>
				) => (
					<>
						<Modal
							title=""
							visible={props.isVisible}
							okText={'Ok'}
							onOk={formik.submitForm}
							cancelText="Wróć"
							onCancel={() => {
								props.onCancel();
								formik.resetForm();
							}}
						>
							<FFieldLabel label="Nazwa" />
							<Field
								label="Nazwa"
								component={FTextArea}
								name="subjectName"
								autoFocus={true}
								rows={1}
							/>

							<FFieldLabel label="Liczba partnerów" />
							<Field
								label="Liczba partnerów"
								component={FInputNumber}
								precision={0}
								name="number"
							/>

							<FFieldLabel label="Kategoria" />
							<Field
								label="Kategoria"
								component={FSelect}
								name="subjectKind"
								children={Array.from(
									RIZPSubjectKindMappers.mappings.entries()
								).map((x, i) => (
									<Select.Option key={i} value={x[0]}>
										{x[1]}
									</Select.Option>
								))}
							/>
						</Modal>
					</>
				)}
			></Formik>
		</>
	);
};
