import { ExclamationOutlined } from '@ant-design/icons';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import Authorized from 'authorization/authorized';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import ExportButtons from 'components/shared/paginatedProfiBazaTable/actions/ExportButtons';
import ProfiBazaTableActions from 'components/shared/paginatedProfiBazaTable/actions/ProfiBazaTableActions';
import IRenderActionModel from 'components/shared/paginatedProfiBazaTable/actions/RenderAction';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { SieveModel } from 'components/shared/paginatedProfiBazaTable/SieveModels';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { ajaxByUser } from 'helper/api';
import { useRootData } from 'hooks/hook';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	FileType,
	InterventionListDto,
	PatternCreateOnExistingResponse,
	PatternStatus,
	PublicHealthInterventionStatus,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

import PaginatedProfiBazaTable from '../../shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import InterventionValidationErrors from '../model/InterventionValidationErrors';
import CloneAction from './CloneAction';
import { exportActions } from './InterventionExportActions';
import { sideBarFilters } from './InterventionTableColumns';

interface IProps {}

const InterventionTable: React.FC<IProps & RouteComponentProps> = (props) => {
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const [loadedTable, setLoadedTable] = useState<boolean>(false);

	const location = useLocation();
	const isPattern = location.pathname === '/rizp/patterns';

	const [signal, setSignal] = useState<AbortSignal>();
	useEffect(() => {
		const abortController = new AbortController();
		setSignal(abortController.signal);
		return () => {
			abortController.abort();
		};
	}, []);

	const userSubjectId = accountDetailsStore!.account.get()?.subject?.id;

	const validationErrors = InterventionValidationErrors.getErrors();

	const canModify = (
		intervention: ProfiBazaAPIModels.InterventionListDto
	) => {
		if (!isPattern) {
			if (
				intervention.status ===
					PublicHealthInterventionStatus.ARCHIVED ||
				intervention.status ===
					PublicHealthInterventionStatus.SUSPENDED ||
				intervention.status ===
					PublicHealthInterventionStatus.SENDEDTOOPINIONAOTMIT ||
				intervention.status ===
					PublicHealthInterventionStatus.COMPLETEDUNREALIZED
			) {
				return false;
			}

			if (
				intervention.subjectId !== userSubjectId &&
				!intervention.coowners?.some((x) => x == userSubjectId)
			) {
				return false;
			}

			return true;
		} else {
			return intervention.patternStatus != PatternStatus.ACTIVE;
		}
	};

	const canDelete = (
		intervention: ProfiBazaAPIModels.InterventionListDto
	) => {
		if (!isPattern) return canModify(intervention);

		return intervention.patternStatus == PatternStatus.DRAFT;
	};

	let columns: ProfiBazaColumnProps<
		ProfiBazaAPIModels.InterventionListDto
	>[] = [
		RenderActions<ProfiBazaAPIModels.InterventionListDto>(
			(text, record) => (
				<>
					<ProfiBazaTooltip placement="top" title="Podgląd">
						<TableActionButton
							label="Podgląd"
							kind="DETAILS"
							linkTo={
								(record.patternStatus
									? '/rizp/pattern/details/'
									: '/rizp/details/') + record.id
							}
						/>
					</ProfiBazaTooltip>
					<Authorized
						permissions={[
							ProfiBazaAPIModels.Permission
								.RIZPPatternsModificationsNationwide,
							ProfiBazaAPIModels.Permission
								.RIZPPatternsModificationsVoivodeship,
						]}
					>
						{record.subjectId === userSubjectId &&
							record.patternStatus === PatternStatus.DRAFT && (
								<ProfiBazaTooltip
									placement="top"
									title="Edytuj"
								>
									<TableActionButton
										kind="EDIT"
										label="Edytuj"
										linkTo={
											'/rizp/pattern/edit/' + record.id
										}
									/>
								</ProfiBazaTooltip>
							)}
					</Authorized>
					{record.patternStatus && (
						<>
							<Authorized
								permissions={[
									ProfiBazaAPIModels.Permission
										.RIZPPatternsModificationsNationwide,
									ProfiBazaAPIModels.Permission
										.RIZPPatternsModificationsVoivodeship,
								]}
							>
								<ProfiBazaTooltip
									placement="top"
									title="Sklonuj"
								>
									<AccessiblePopconfirm
										title={
											<>
												Czy na pewno chcesz utworzyć
												nowy wzorzec interwencji na
												podstawie {record.name}?
											</>
										}
										onConfirm={() => {
											ajaxByUser(
												'Sklonowano wzorzec interwencji. Zostałeś przekierowany do edycji wzorca interwencji.',
												() =>
													getProfiBazaApiClient()
														.then((api) =>
															api.pattern.createOnExisting(
																{
																	body: {
																		id:
																			record.id,
																	},
																}
															)
														)
														.then(
															(
																response: PatternCreateOnExistingResponse
															) => {
																props.history.push(
																	`/rizp/pattern/edit/${response.id}`
																);
															}
														)
											);
										}}
									>
										<TableActionButton
											kind="CLONE"
											label="Klonuj"
										/>
									</AccessiblePopconfirm>
								</ProfiBazaTooltip>
							</Authorized>
							{record.patternStatus !== PatternStatus.ACTIVE ? (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.RIZPPatternsModificationsNationwide,
										ProfiBazaAPIModels.Permission
											.RIZPPatternsModificationsVoivodeship,
									]}
								>
									<ProfiBazaTooltip
										placement="top"
										title="Aktywuj"
									>
										<AccessiblePopconfirm
											title={
												<>
													Czy na pewno chcesz
													aktywować wzorzec{' '}
													{record.name}?
												</>
											}
											onConfirm={() => {
												ajaxByUser(
													'Aktywowano wzorzec interwencji',
													() =>
														getProfiBazaApiClient()
															.then((api) =>
																api.pattern.activate(
																	{
																		body: {
																			id:
																				record.id,
																		},
																	}
																)
															)
															.then(() => {
																gridStore.searching.set(
																	true
																);
															})
												);
											}}
										>
											<TableActionButton
												kind="ACTIVATE"
												label="Aktywuj"
											/>
										</AccessiblePopconfirm>
									</ProfiBazaTooltip>
								</Authorized>
							) : (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.RIZPPatternsModificationsNationwide,
										ProfiBazaAPIModels.Permission
											.RIZPPatternsModificationsVoivodeship,
									]}
								>
									<ProfiBazaTooltip
										placement="top"
										title="Dezaktywuj"
									>
										<AccessiblePopconfirm
											title={
												<>
													Czy na pewno chcesz
													dezaktywować wzorzec{' '}
													{record.name}?
												</>
											}
											onConfirm={() => {
												ajaxByUser(
													'Dezaktywowano wzorzec interwencji',
													() =>
														getProfiBazaApiClient()
															.then((api) =>
																api.pattern.deactivate(
																	{
																		body: {
																			id:
																				record.id,
																		},
																	}
																)
															)
															.then(() => {
																gridStore.searching.set(
																	true
																);
															})
												);
											}}
										>
											<TableActionButton
												kind="ACTIVATE"
												label="Aktywuj"
											/>
										</AccessiblePopconfirm>
									</ProfiBazaTooltip>
								</Authorized>
							)}
						</>
					)}
					{canModify(record) && !record.patternStatus && (
						<Authorized
							permission={
								ProfiBazaAPIModels.Permission.RIZPModifications
							}
						>
							<ProfiBazaTooltip placement="top" title="Edytuj">
								<TableActionButton
									label="Edytuj"
									kind="EDIT"
									linkTo={'/rizp/edit/' + record.id}
								/>
							</ProfiBazaTooltip>
						</Authorized>
					)}

					{record.status != PublicHealthInterventionStatus.CANCELED &&
						(record.coowners?.includes(userSubjectId!) ||
							record.subjectId === userSubjectId) && (
							<>
								{!record.patternStatus && (
									<Authorized
										permission={
											ProfiBazaAPIModels.Permission
												.RIZPModifications
										}
									>
										<CloneAction
											{...props}
											record={record}
										></CloneAction>
									</Authorized>
								)}

								{record.status !==
									PublicHealthInterventionStatus.ARCHIVED &&
									!record.patternStatus &&
									record.status !==
										PublicHealthInterventionStatus.COMPLETEDREALIZED &&
									record.status !==
										PublicHealthInterventionStatus.COMPLETEDUNREALIZED && (
										<>
											{record.status ===
											PublicHealthInterventionStatus.SUSPENDED ? (
												<Authorized
													permission={
														ProfiBazaAPIModels
															.Permission
															.RIZPModifications
													}
												>
													<ProfiBazaTooltip
														placement="top"
														title="Odwieś"
													>
														<AccessiblePopconfirm
															title={
																<>
																	Czy na pewno
																	chcesz
																	odwiesić{' '}
																	{
																		record.name
																	}
																	?
																</>
															}
															onConfirm={() => {
																ajaxByUser(
																	'Odwieszono interwencję',
																	() =>
																		getProfiBazaApiClient()
																			.then(
																				(
																					api
																				) =>
																					api.intervention.unsuspend(
																						{
																							body: {
																								id:
																									record.id,
																							},
																						}
																					)
																			)
																			.then(
																				() => {
																					gridStore.searching.set(
																						true
																					);
																				}
																			)
																);
															}}
														>
															<TableActionButton
																kind="DEACTIVATE"
																label="Odwieś"
															/>
														</AccessiblePopconfirm>
													</ProfiBazaTooltip>
												</Authorized>
											) : (
												<Authorized
													permission={
														ProfiBazaAPIModels
															.Permission
															.RIZPModifications
													}
												>
													<ProfiBazaTooltip
														placement="top"
														title="Zawieś"
													>
														<AccessiblePopconfirm
															title={
																<>
																	Czy na pewno
																	chcesz
																	zawiesić{' '}
																	{
																		record.name
																	}
																	?
																</>
															}
															onConfirm={() => {
																ajaxByUser(
																	'Zawieszono interwencję',
																	() =>
																		getProfiBazaApiClient()
																			.then(
																				(
																					api
																				) =>
																					api.intervention.suspend(
																						{
																							body: {
																								id:
																									record.id,
																							},
																						}
																					)
																			)
																			.then(
																				(
																					x
																				) => {
																					gridStore.searching.set(
																						true
																					);
																					return x;
																				}
																			)
																);
															}}
														>
															<TableActionButton
																kind="ACTIVATE"
																label="Zawieś"
															/>
														</AccessiblePopconfirm>
													</ProfiBazaTooltip>
												</Authorized>
											)}
											{record.status ===
												PublicHealthInterventionStatus.PLANNED && (
												<Authorized
													permission={
														ProfiBazaAPIModels
															.Permission
															.RIZPModifications
													}
												>
													<ProfiBazaTooltip
														placement="top"
														title="Anuluj"
													>
														<AccessiblePopconfirm
															title={
																<>
																	Czy na pewno
																	chcesz
																	anulować{' '}
																	{
																		record.name
																	}
																	?
																</>
															}
															onConfirm={() => {
																ajaxByUser(
																	'Anulowano interwencje',
																	() =>
																		getProfiBazaApiClient()
																			.then(
																				(
																					api
																				) =>
																					api.intervention.cancel(
																						{
																							body: {
																								id:
																									record.id,
																							},
																						}
																					)
																			)
																			.then(
																				() => {
																					gridStore.searching.set(
																						true
																					);
																				}
																			)
																);
															}}
														>
															<TableActionButton
																kind="CLOSE"
																label="Anuluj interwencje"
															/>
														</AccessiblePopconfirm>
													</ProfiBazaTooltip>
												</Authorized>
											)}
										</>
									)}
							</>
						)}
				</>
			)
		),
		...tableColumnStore.getTable(isPattern ? 'patterns' : 'intervention'),
	];

	if (validationErrors !== null) {
		const codeNameColumn = columns.find((x) => x.key === 'codeName');

		columns = [
			...columns.filter((x) => x !== codeNameColumn),
			{
				...codeNameColumn,
				render: (value: string, record: InterventionListDto) => {
					const errors = validationErrors.hasInterventionError(
						record.id!
					);
					return errors.hasError ? (
						<>
							<ProfiBazaTooltip
								placement="top"
								title={errors.message!}
							>
								<ExclamationOutlined className="exclamation-mark" />
							</ProfiBazaTooltip>
							{value}
						</>
					) : (
						value
					);
				},
			},
		];
	}

	const renderDeletionBtn = (
		record: ProfiBazaAPIModels.InterventionListDto
	) => (
		<>
			{canDelete(record) && (
				<ProfiBazaTooltip title="Usuń">
					<AccessiblePopconfirm
						title={
							!isPattern
								? 'Czy na pewno chcesz usunąć interwencję?'
								: 'Czy na pewno chcesz usunąć wzorzec interwencji?'
						}
						onConfirm={() => {
							if (!isPattern) {
								ajaxByUser('Usunięto interwencję', () =>
									getProfiBazaApiClient()
										.then((api) =>
											api.intervention.deleteMethod(
												record.id!
											)
										)
										.then(() => {
											gridStore.searching.set(true);
										})
								);
							} else {
								ajaxByUser('Usunięto wzorzec interwencji', () =>
									getProfiBazaApiClient()
										.then((api) =>
											api.pattern.deleteMethod(record.id!)
										)
										.then(() => {
											gridStore.searching.set(true);
										})
								);
							}
						}}
					>
						<TableActionButton label="Usuń" kind="DELETE" />
					</AccessiblePopconfirm>
				</ProfiBazaTooltip>
			)}
		</>
	);

	const renderAction = (
		actionProps: IRenderActionModel<ProfiBazaAPIModels.InterventionListDto>
	) => (
		<ProfiBazaTableActions<ProfiBazaAPIModels.InterventionListDto>
			getRowKey={actionProps.getRowKey}
			results={actionProps.results}
			actions={[
				{
					action: (selected: Array<string | number>) => (
						<Authorized
							permission={
								ProfiBazaAPIModels.Permission.RIZPArchive
							}
						>
							<ProfiBazaTooltip title="Archiwizuj">
								<AccessiblePopconfirm
									title="Czy na pewno chcesz przenieść interwencję do archiwum?"
									onConfirm={() => {
										ajaxByUser(
											'Zarchiwizowano interwencje',
											() =>
												getProfiBazaApiClient()
													.then((api) =>
														api.intervention.archive(
															{
																body: {
																	ids: selected as string[],
																},
															}
														)
													)
													.then(() => {
														gridStore.searching.set(
															true
														);
													})
										);
									}}
								>
									<TableActionButton
										label="Archiwizuj"
										kind="ARCHIVE"
									/>
								</AccessiblePopconfirm>
							</ProfiBazaTooltip>
						</Authorized>
					),
					isVisible: (
						selectedItems: ProfiBazaAPIModels.InterventionListDto[]
					) =>
						selectedItems.every(
							(x) =>
								x.status ===
									ProfiBazaAPIModels
										.PublicHealthInterventionStatus
										.COMPLETEDREALIZED ||
								x.status ===
									ProfiBazaAPIModels
										.PublicHealthInterventionStatus
										.COMPLETEDUNREALIZED
						),
				},
				{
					action: (selected: Array<string | number>) => (
						<Authorized
							permission={
								ProfiBazaAPIModels.Permission.RIZPArchive
							}
						>
							<ProfiBazaTooltip title="Przywróć z archiwum">
								<AccessiblePopconfirm
									title="Czy na pewno chcesz przywrócić interwencję z archiwum?"
									onConfirm={() => {
										ajaxByUser(
											'Interwencje zostały przywrócone',
											() =>
												getProfiBazaApiClient()
													.then((api) =>
														api.intervention.unarchive(
															{
																body: {
																	ids: selected as string[],
																},
															}
														)
													)
													.then(() => {
														gridStore.searching.set(
															true
														);
													})
										);
									}}
								>
									<TableActionButton
										label="Przywróć z archiwum"
										kind="UNARCHIVE"
									/>
								</AccessiblePopconfirm>
							</ProfiBazaTooltip>
						</Authorized>
					),
					isVisible: (
						selectedItems: ProfiBazaAPIModels.InterventionListDto[]
					) =>
						selectedItems.every(
							(x) =>
								x.status ===
								ProfiBazaAPIModels
									.PublicHealthInterventionStatus.ARCHIVED
						),
				},
				{
					singleAction: (
						record: ProfiBazaAPIModels.InterventionListDto
					) => renderDeletionBtn(record),
				},
			]}
		>
			<ExportButtons<ProfiBazaAPIModels.InterventionListDto>
				fileType={FileType.XLSX}
				supportedExportTypes={
					isPattern
						? ['default']
						: ['default', 'flat', 'technicalFlat']
				}
				getSieveModel={actionProps.getSieveModel}
				getFilePromise={exportActions}
				key="list_export"
				abortSignal={signal}
			/>

			<ExportButtons<ProfiBazaAPIModels.InterventionListDto>
				fileType={FileType.PDF}
				supportedExportTypes={
					isPattern ? ['default'] : ['default', 'full']
				}
				getSieveModel={actionProps.getSieveModel}
				getFilePromise={exportActions}
				key="flat_export"
				abortSignal={signal}
			/>

			<ExportButtons<ProfiBazaAPIModels.InterventionListDto>
				fileType={FileType.CSV}
				supportedExportTypes={
					isPattern
						? ['default']
						: ['default', 'flat', 'technicalFlat']
				}
				getSieveModel={actionProps.getSieveModel}
				getFilePromise={exportActions}
				key="flat_technical_export"
				abortSignal={signal}
			/>
			{!isPattern && (
				<ExportButtons<ProfiBazaAPIModels.InterventionListDto>
					fileType={FileType.JSON}
					supportedExportTypes={['full']}
					getSieveModel={actionProps.getSieveModel}
					getFilePromise={exportActions}
					key="details_export"
					abortSignal={signal}
				/>
			)}
		</ProfiBazaTableActions>
	);

	return (
		<PaginatedProfiBazaTable<ProfiBazaAPIModels.InterventionListDto>
			className="main-view"
			gridName={isPattern ? 'patterns' : 'intervention'}
			detailsUrl={isPattern ? '/rizp/pattern/details' : '/rizp/details'}
			filterByTeryt
			columns={columns}
			getRowKey={(r: ProfiBazaAPIModels.InterventionListDto) => r.id!}
			hasRowSelection
			getPagedResult={(
				sieve: SieveModel,
				filter: ProfiBazaAPIModels.InterventionFilter | undefined,
				abortSignal
			) =>
				getProfiBazaApiClient()
					.then((api) =>
						api.intervention.all({
							filters: sieve.filters,
							page: sieve.page,
							pageSize: sieve.pageSize,
							sorts: sieve.sorts,
							filter: filter,
							labels: sieve.labels,
							selectedIds:
								filter ===
								ProfiBazaAPIModels.InterventionFilter
									.SelectedIds
									? validationErrors?.errors.map(
											(x) => x.interventionId
									  )
									: undefined,
							abortSignal: abortSignal,
						})
					)
					.then(
						(
							result: ProfiBazaAPIModels.InterventionListDtoPagedResult
						) => ({
							results: result.results,
							currentPage: result.currentPage,
							pageSize: result.pageSize,
							rowCount: result.rowCount,
						})
					)
			}
			isLoaded={() => setLoadedTable(true)}
			renderActions={renderAction}
			sideBarFilters={{
				filters: sideBarFilters(!!validationErrors),
				path: 'rizp',
			}}
		/>
	);
};

export default InterventionTable;
