/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/userMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a User. */
export class User {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a User.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.UserGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.UserGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.UserDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.UserDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.UserDto>, callback?: msRest.ServiceCallback<Models.UserDto>): Promise<Models.UserGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.UserGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UserListResponse>
   */
  list(options?: Models.UserListOptionalParams): Promise<Models.UserListResponse>;
  /**
   * @param callback The callback
   */
  list(callback: msRest.ServiceCallback<Models.UserListDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  list(options: Models.UserListOptionalParams, callback: msRest.ServiceCallback<Models.UserListDtoPagedResult>): void;
  list(options?: Models.UserListOptionalParams | msRest.ServiceCallback<Models.UserListDtoPagedResult>, callback?: msRest.ServiceCallback<Models.UserListDtoPagedResult>): Promise<Models.UserListResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      listOperationSpec,
      callback) as Promise<Models.UserListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addRole(options?: Models.UserAddRoleOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addRole(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addRole(options: Models.UserAddRoleOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addRole(options?: Models.UserAddRoleOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      addRoleOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteRole(options?: Models.UserDeleteRoleOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteRole(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteRole(options: Models.UserDeleteRoleOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteRole(options?: Models.UserDeleteRoleOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteRoleOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "authorization/User/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.UserDto
    },
    default: {}
  },
  serializer
};

const listOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "authorization/User",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.UserListDtoPagedResult
    },
    default: {}
  },
  serializer
};

const addRoleOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "authorization/User/role",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.AddUserRoleCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteRoleOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "authorization/User/role",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeleteUserRoleCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
