import { ajaxCatch } from '@helper/api';
import { Col, Form, Modal } from 'antd';
import SearchBar from 'components/shared/paginatedProfiBazaTable/SearchBar';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	SubjectAllOptionalParams,
	SubjectListItemDto,
} from 'services/src/models';
import { IGridStore } from 'stores/GridStore';

import PaginatedProfiBazaTable from '../shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import { SieveModel } from '../shared/paginatedProfiBazaTable/SieveModels';
import { defaultColumns } from '../subjects/SubjectTableColumns';

interface IProps {
	visible: boolean;
	acceptList?: (list: SubjectListItemDto[]) => void;
	acceptSubject?: (value: SubjectListItemDto | undefined) => void;
	onCancel: () => void;
	filters?: string;
}

interface IState {
	tableLoaded: boolean;
	subject: SubjectListItemDto | undefined;
}

const SubjectPermissionsTableModal: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({
		tableLoaded: false,
		subject: undefined,
	});

	useEffect(() => {
		let selectList: HTMLInputElement[] = [];
		Array.from(document.getElementsByClassName('ant-modal')).forEach(
			(el) =>
				(selectList = [
					...selectList,
					...Array.from(el.getElementsByTagName('input')),
				])
		);
		Array.from(selectList).forEach((el) => {
			if (el.getAttribute('role') == 'combobox') {
				el.setAttribute('aria-label', 'Wybierz');
			}
		});
		let buttonList: HTMLButtonElement[] = [];
		Array.from(document.getElementsByClassName('ant-modal')).forEach(
			(el) =>
				(buttonList = [
					...buttonList,
					...Array.from(el.getElementsByTagName('button')),
				])
		);
		Array.from(buttonList).forEach((el) => {
			if (!el.getAttribute('aria-label')) {
				el.setAttribute('aria-label', 'Przycisk');
			}
		});
	}, [state.tableLoaded]);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	return (
		<Modal
			visible={props.visible}
			width={'80%'}
			cancelText="Wróć"
			destroyOnClose
			onCancel={() => {
				props.onCancel();
				gridStore.setSelectedKeys([]);
				gridStore.setSelectedValues([]);
			}}
			onOk={() => {
				if (state.subject && props.acceptSubject) {
					props.acceptSubject(state.subject);
				} else {
					props.onCancel();
				}
				gridStore.setSelectedKeys([]);
				gridStore.setSelectedValues([]);
			}}
		>
			<CenteredRow gutter={[16, 24]} style={{ marginTop: 32 }}>
				<Col span={5}></Col>
				<Col span={13}>
					<Form size="large">
						<SearchBar
							placeholder="Wyszukaj podmiot"
							column="(name|codeName)"
							filter="contains"
							gridName="activitySubjects"
						/>
					</Form>
				</Col>
				<Col span={5}></Col>
			</CenteredRow>
			<CenteredRow>
				<Col span={23}>
					<PaginatedProfiBazaTable<SubjectListItemDto>
						gridName="activitySubjects"
						columns={defaultColumns}
						hasRowSelection={true}
						rowSelectionType={'radio'}
						hasSelectAll={false}
						hidePersonalizationSettings
						onRow={(
							record: SubjectListItemDto,

							rowIndex: number
						) => {
							return {
								onClick: (
									event: React.MouseEvent<
										HTMLElement,
										MouseEvent
									>
								) => {
									if (props.acceptSubject)
										props.acceptSubject(record);
								},
							};
						}}
						selectedOne={(subject: SubjectListItemDto[]) => {
							setState({ ...state, subject: subject[0] });
						}}
						getRowKey={(r: SubjectListItemDto) => r.id!}
						getPagedResult={(sieve: SieveModel, _, abortSignal) => {
							const filters = props.filters
								? sieve.filters
									? sieve.filters + ',' + props.filters
									: props.filters
								: sieve.filters;

							const parameters: SubjectAllOptionalParams = {
								filters: filters,
								sorts: sieve.sorts,
								page: sieve.page,
								pageSize: sieve.pageSize,
								abortSignal,
							};
							return ajaxCatch(() => {
								return getProfiBazaApiClient().then((api) => {
									return api.subject
										.permissionList(parameters)
										.then((result) => {
											return result;
										});
								});
							});
						}}
						isLoaded={() =>
							setState({ ...state, tableLoaded: true })
						}
					/>
				</Col>
			</CenteredRow>
		</Modal>
	);
};

export default observer(SubjectPermissionsTableModal);
