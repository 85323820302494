import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Space } from 'antd';
import PermissionValidator from 'authorization/permissionValidator';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { openNotificationWithIcon } from 'help/NotificationHelper';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import { useRootData } from 'hooks/hook';
import DraggableProfiBazaTable from 'layout/DraggableProfiBazaTable';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import PrimaryCard from 'layout/PrimaryCard';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { procesTypesTotal } from 'services/mappers/DictionaryInterventionPath';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	DictionaryInterventionPath,
	DictionaryStatus,
} from 'services/src/models';
import { DictionaryValueItemDto as DictionaryValueItemModelDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { DictionaryValueItemDto } from '../DictionaryValueItemDto';
import DictionaryValuesModal from '../modals/dictionaryValues/DictionaryValuesModal';
import MultiLevelDictionary from '../multiLevelDictionary/MultiLevelDictionary';
import OtherValueHistoryModal, {
	IOtherValueHistoryModal,
} from '../multiLevelDictionary/OtherValueHistoryModal';
import DictionariesValuesTableColumn from './DictionariesValuesTableColumn';

interface IProps {
	dictionaryVersionId: number;
	tree: boolean;
	isCodeNameInValuesRequired: boolean;
	dictionaryVersion: ProfiBazaAPIModels.DictionaryVersionItemDto | undefined;
}

interface IState {
	dictionaryValues: DictionaryValueItemDto[] | undefined;
	loading: boolean;
}

const DictionariesValuesTable: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({
		dictionaryValues: undefined,
		loading: true,
	});

	const [multiLevelModalVisible, setMultiLevelModalVisible] = useState<
		boolean
	>(false);
	const [valueEditModalVisible, setValueEditModalVisible] = useState<boolean>(
		false
	);
	const [editedValueId, setEditedValueId] = useState<number | undefined>();

	const { isCodeNameInValuesRequired, tree, dictionaryVersion } = props;

	const otherValueHistoryModalRef = useRef<IOtherValueHistoryModal>(null);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const permissionValidator = new PermissionValidator(
		accountDetailsStore.account.get()!
	);

	const isDraft = dictionaryVersion?.status === DictionaryStatus.DRAFT;

	const isEditable =
		isDraft &&
		permissionValidator.has(
			ProfiBazaAPIModels.Permission.DictionariesVersionsModifications
		);

	const [previousAbort, setPreviousAbort] = useState<AbortController>();

	useEffect(() => {
		if (previousAbort) {
			previousAbort.abort();
		}
		const abortController = new AbortController();
		setPreviousAbort(abortController);
		refresh(abortController.signal);

		return () => {
			abortController.abort();
		};
	}, [props.dictionaryVersionId]);

	const refreshProcess = (
		interventionPaths?: DictionaryInterventionPath[]
	) => {
		setState({ ...state, loading: true });

		const reducedIntervetionPaths =
			interventionPaths?.length === procesTypesTotal
				? undefined
				: interventionPaths;

		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.dictionaryVersion.getItems(props.dictionaryVersionId, {
						interventionPaths: reducedIntervetionPaths,
					})
				)
				.then(
					(response: ProfiBazaAPIModels.DictionaryValueItemDto[]) => {
						setState({
							dictionaryValues: response.map((x) =>
								mapToTreeNode(x)
							),
							loading: false,
						});
					}
				)
		);
	};

	const refresh = (abortSignal?: AbortSignal) => {
		refreshProcess(undefined);
	};

	const mapToTreeNode = (item: ProfiBazaAPIModels.DictionaryValueItemDto) => {
		const treeNode = item as DictionaryValueItemDto;
		if (props.tree) {
			treeNode.children = [];
			treeNode.dataLoaded = false;
		}
		return treeNode;
	};

	const setDataSource = (data: DictionaryValueItemDto[]) =>
		setState({ ...state, dictionaryValues: data });

	const handleMove = (dragIndex: number, hoverIndex: number) => {
		const movedValue = state.dictionaryValues![dragIndex];
		updateValuePosition(dragIndex, hoverIndex, movedValue);
	};

	const updateValuePosition = (
		dragIndex: number,
		hoverIndex: number,
		movedValue: DictionaryValueItemModelDto
	) => {
		const movedValueId = movedValue.id;

		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.dictionaryValue.order({
						body: { id: movedValueId, order: hoverIndex },
					})
				)
				.then(() => {
					openNotificationWithIcon(
						'success',
						'Kolejność zmieniona pomyślnie',
						`Wartość ${
							movedValue.codeName
								? ` o identyfikatorze ${movedValue.codeName}`
								: ''
						} 
					została przeniesiona z pozycji ${dragIndex + 1} na ${hoverIndex + 1}`
					);
				})
		);
	};

	const columns = [...DictionariesValuesTableColumn];

	const actionColumn = RenderActions<
		ProfiBazaAPIModels.DictionaryValueItemDto
	>((text, record) => (
		<>
			{isEditable ? (
				<Space>
					<ProfiBazaTooltip placement="top" title="Edytuj">
						<TableActionButton
							label="Edytuj"
							kind="EDIT"
							onClick={() => {
								setValueEditModalVisible(true);
								setEditedValueId(record.id);
							}}
						/>
					</ProfiBazaTooltip>

					<ProfiBazaTooltip placement="top" title="Usuń">
						<AccessiblePopconfirm
							title={
								<span>
									Czy na pewno chcesz usunąć wskazaną wartość?
								</span>
							}
							onConfirm={() => {
								ajaxByUser('Usunięto wartość', () =>
									getProfiBazaApiClient()
										.then((api) =>
											api.dictionaryValue.deleteMethod(
												record.id!
											)
										)
										.then(() => {
											refresh();
										})
								);
							}}
						>
							<TableActionButton label="Usuń" kind="DELETE" />
						</AccessiblePopconfirm>
					</ProfiBazaTooltip>
				</Space>
			) : !isDraft ? (
				<>
					{record.canAddTextValue && (
						<ProfiBazaTooltip placement="top" title="Wartości inne">
							<TableActionButton
								label="Wartości inne"
								kind="VIEW"
								onClick={() =>
									otherValueHistoryModalRef.current?.showModal(
										record.id!
									)
								}
							/>
						</ProfiBazaTooltip>
					)}
				</>
			) : (
				<></>
			)}
		</>
	));
	columns.push(actionColumn);
	return (
		<>
			{state.dictionaryValues && (
				<PrimaryCard>
					<div className="mt-small">
						<PageHeaderWithContent
							title="WARTOŚCI SŁOWNIKA"
							right={
								<>
									{!tree && isEditable && (
										<DictionaryValuesModal
											dictionaryVersionId={
												props.dictionaryVersionId
											}
											isCodeNameInValuesRequired={
												isCodeNameInValuesRequired
											}
											refresh={refresh as () => void}
											id={editedValueId}
											setId={setEditedValueId}
											visible={valueEditModalVisible}
											setVisible={
												setValueEditModalVisible
											}
										/>
									)}
								</>
							}
						/>
						{tree ? (
							<>
								<MultiLevelDictionary
									notModal={true}
									visible={!!props.dictionaryVersionId}
									setVisible={setMultiLevelModalVisible}
									dictionaryValues={state.dictionaryValues}
									updateValuePosition={updateValuePosition}
									isEditable={isEditable}
									isDraft={isDraft}
									refreshBaseItems={refreshProcess}
									valueModalProps={{
										dictionaryVersionId:
											props.dictionaryVersionId,
										isCodeNameInValuesRequired: isCodeNameInValuesRequired,
										refresh: () => {},
										id: editedValueId,
										setId: setEditedValueId,
										visible: valueEditModalVisible,
										setVisible: setValueEditModalVisible,
									}}
								/>
							</>
						) : isEditable ? (
							<DraggableProfiBazaTable
								handleMove={handleMove}
								loading={state.loading}
								columns={columns}
								pagination={false}
								dataSource={state.dictionaryValues}
								setDataSource={setDataSource}
								rowKey={(
									record: ProfiBazaAPIModels.DictionaryValueItemDto
								) => record.id}
							/>
						) : (
							<>
								<ProfiBazaTable
									loading={state.loading}
									columns={columns}
									pagination={false}
									dataSource={state.dictionaryValues}
									setDataSource={setDataSource}
									rowKey={(
										record: ProfiBazaAPIModels.DictionaryValueItemDto
									) => record.id!}
								/>
								{!isDraft && (
									<OtherValueHistoryModal
										ref={otherValueHistoryModalRef}
									/>
								)}
							</>
						)}
					</div>
				</PrimaryCard>
			)}
		</>
	);
};

export default observer(DictionariesValuesTable);
