import { ajaxCatch } from 'helper/api';
import { action, observable } from 'mobx';
import { Moment } from 'moment';
import { DictionaryName } from 'services/mappers/DictionaryName';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { DictionaryInterventionPath } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import {
	BuildInDictionaryType,
	initializeDictionaries,
} from './dictionaryIntializer';

export const dictionaryStore = {
	buildInDictionaries: initializeDictionaries(),
	dictionaries: observable(
		new Map<string, ProfiBazaAPIModels.DictionaryInfoDto>() //key is result of concatanation of codeName with process type
	),

	setBuildInDictionaries: (dictionaries: BuildInDictionaryType[]) => {
		dictionaryStore.buildInDictionaries = dictionaries;
	},

	getBuildInDictionary: (dictName: DictionaryName) => {
		let dictionaries = dictionaryStore.buildInDictionaries!.filter(
			(a) => a.name == dictName
		);

		if (dictionaries.length !== 1) {
			throw `Nie odnaleziono słownika ${dictName}`;
		}
		return dictionaries[0];
	},

	initializeDictionary: action(
		(
			codeName: string,
			interventionPath: DictionaryInterventionPath = DictionaryInterventionPath.PROHEALTHACTIVITIES,
			dateFrom?: Moment | undefined,
			dateTo?: Moment | undefined
		) => {
			const key = dictionaryStore.getKey(codeName, interventionPath);
			ajaxCatch(() =>
				getProfiBazaApiClient().then((api) =>
					api.dictionary
						.getItemsByCodeNameAndProcess(
							codeName,
							interventionPath,
							{
								dateFrom: dateFrom?.utc(true).toDate()!,
								dateTo: dateTo?.utc(true).toDate()!,
							}
						)
						.then(
							(
								response: ProfiBazaAPIModels.DictionaryInfoDto
							) => {
								if (response) {
									dictionaryStore.dictionaries.set(
										key,
										response
									);
									return response;
								} else {
									throw `Nie odnaleziono słownika ${codeName}`;
								}
							}
						)
				)
			);
		}
	),

	getDictionary: (
		codeName: string,
		interventionPath: DictionaryInterventionPath = DictionaryInterventionPath.PROHEALTHACTIVITIES
	): ProfiBazaAPIModels.DictionaryInfoDto | undefined => {
		return dictionaryStore.dictionaries.get(
			dictionaryStore.getKey(codeName, interventionPath)
		);
	},

	wipeCachedDictionary: action(
		(
			codeName: string | undefined,
			interventionPath: DictionaryInterventionPath = DictionaryInterventionPath.PROHEALTHACTIVITIES
		) => {
			if (!codeName) return;
			dictionaryStore.dictionaries.delete(
				dictionaryStore.getKey(codeName, interventionPath)
			);
		}
	),
	getKey: (
		codeName: string,
		interventionPath: DictionaryInterventionPath
	): string => `${codeName},${interventionPath}`,
};

export const createDictionaryStore = () => {
	return dictionaryStore;
};

export type IDictionaryStore = ReturnType<typeof createDictionaryStore>;
