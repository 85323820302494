import * as Yup from 'yup';

export const InterventionContactValidationSchema = Yup.object().shape({
	email: Yup.string()
		.email('Wpisz poprawny email')
		.required('E-mail jest wymagany.'),
	firstName: Yup.string().required('Imię jest wynagane.'),
	lastName: Yup.string().required('Nazwisko jest wynagane.'),
	phoneNumber: Yup.string()
		.test('phone_umber', 'Numer telefonu musi być liczbą.', (val) =>
			!val ? true : /^\d+$/.test(val)
		)
		.required('Numer telefonu jest wymagany.'),
	position: Yup.string().required('Stanowisko jest wymagane.').nullable(),
});
