import { HubConnection } from '@microsoft/signalr';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { getSignalRConnection } from 'components/notifications/hubs/signalRConnection';
import { useEffect, useState } from 'react';

const useNotificationHub = (
	accountDetailsStore: IAccountDetailsStore
): HubConnection | undefined => {
	const [notificationHub, setNotificationHub] = useState<HubConnection>();

	accountDetailsStore.oidcUser.observe((change => {
		if (change.oldValue !== change.newValue) {
			const newSignalConnection = getSignalRConnection(
				accountDetailsStore
			);
			setNotificationHub(newSignalConnection);
		}
	}));

	return notificationHub;
};

export default useNotificationHub;
