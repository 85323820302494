import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<
	ProfiBazaAPIModels.SubjectReportingProcessStatus,
	string
>([
	[ProfiBazaAPIModels.SubjectReportingProcessStatus.ACTIVE, 'aktywny'],
	[ProfiBazaAPIModels.SubjectReportingProcessStatus.INACTIVE, 'nieaktywny'],
]);

export const map = (
	value: ProfiBazaAPIModels.SubjectReportingProcessStatus
): string => mapings.get(value) ?? '';
