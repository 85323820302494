import { ProfiBazaColumnProps } from '@components/shared/paginatedProfiBazaTable/GridHelper';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';

const columns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.DocumentListItemDto
>[] = [
	{
		dataIndex: 'year',
		key: 'year',
		title: 'Rok Przysłania Raportu ',
		filter: 'equals',
		sorter: true,
	},
	{
		dataIndex: 'number',
		key: 'number',
		title: 'Numer Raportu',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'fileName',
		key: 'fileName',
		title: 'Nazwa Programu',
		filter: 'contains',
		sorter: true,
	},
];

export default columns;
