import FormRunner from '@components/orbeon/FormRunner';
import { ajaxCatch } from '@helper/api';
import useTabAccessibility from '@hooks/accessibility/useTabAccessibilityHook';
import { useRootData } from '@hooks/hook';
import useTabPaneDisableTabStop from '@hooks/tabPaneDisableTabStopHook';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import {
	ActivitiesSummaryDto,
	InterventionDetailsTinyDto,
	PublicHealthInterventionStatus,
} from '@services/src/models';
import { Tabs } from 'antd';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router';
import { IRizpDictionaryStore } from 'stores/RizpDictionaryStore';

import InterventionActivityData from './activity/InterventionActivityData';
import { IInterventionCommentStore } from './comments/store/InterventionCommentStore';
import InterventionData from './InterventionData';
import InterventionDocuments from './InterventionDocuments';

const { TabPane } = Tabs;

interface IProps {
	interventionId: string;
	editable?: boolean;
	isPattern: boolean;
	patternId?: any;
}

const InterventionDetails: React.FC<RouteComponentProps & IProps> = (props) => {
	const [activeTab, setActiveTab] = useState<string>('main');
	const [interventionTiny, setInterventionTiny] = useState<
		InterventionDetailsTinyDto | undefined
	>(undefined);

	const [activitiesSummary, setActivitiesSummary] = useState<
		ActivitiesSummaryDto | undefined
	>(undefined);

	const history = useHistory();
	let match = useRouteMatch<{ tab?: string }>();

	const rizpDictionaryStore: IRizpDictionaryStore = useRootData(
		(store) => store.rizpDictionaryStore
	);

	const interventionCommentStore: IInterventionCommentStore = useRootData(
		(store) => store.interventionCommentStore
	);

	const [isActivitiesTabDisabled, setIsActivitiesTabDisabled] = useState<
		boolean
	>(false);

	useTabPaneDisableTabStop();

	useLayoutEffect(() => {
		// initial set
		let c = document.querySelector(
			'.ant-tabs-tabpane-active'
		) as HTMLDivElement;
		c?.setAttribute('tabindex', '-1');
	}, []);

	const onTabChanged = (activeKey: string) => {
		if (activeKey === 'main') {
			history.push(
				`/rizp/${props.isPattern ? 'pattern/' : ''}${
					props.editable === true ? 'edit' : 'details'
				}/${props.interventionId}`
			);
		} else {
			history.push(
				`/rizp/${props.isPattern ? 'pattern/' : ''}${
					props.editable === true ? 'edit' : 'details'
				}/${props.interventionId}/${activeKey}`
			);
		}

		if (activeKey === 'orbeon') {
			refresh();
		}
	};

	useEffect(() => {
		if (match.params.tab) {
			setActiveTab(match.params.tab);
		} else {
			setActiveTab('main');
		}
	}, [match.params.tab]);

	useEffect(() => {
		interventionCommentStore.filterParams.set({
			activityId: null,
			populationId: null,
			sponsoringCompanyId: null,
		});

		interventionCommentStore.setComments(props.interventionId);
	}, [props.interventionId]);

	useTabAccessibility();

	useEffect(() => {
		if (!history.location.pathname.includes('activities/preview')) {
			refresh();
		}
	}, [history.location]);

	const refresh = () => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.intervention.getTinyById(props.interventionId)
				)
				.then((result: InterventionDetailsTinyDto) => {
					setInterventionTiny(result);
					rizpDictionaryStore.lastFetchObjectId.set(
						props.interventionId
					);
				})
		);
	};

	const onChangeActivities = () => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.intervention.getActivitiesSummary(props.interventionId)
				)
				.then((result: ActivitiesSummaryDto) => {
					setActivitiesSummary(result);
				})
		);
	};

	return (
		<Tabs
			onChange={onTabChanged}
			activeKey={activeTab}
			defaultActiveKey="main"
			centered
		>
			<TabPane tab="Dane podstawowe" key="main">
				<InterventionData
					onChangeAreaTerytCodes={function (
						isAcitvitiesTabDisable: boolean
					): void {
						setIsActivitiesTabDisabled(isAcitvitiesTabDisable);
					}}
					{...props}
					activitiesSummary={activitiesSummary}
				></InterventionData>
			</TabPane>
			<TabPane
				tab="Działania"
				key="activities"
				disabled={
					interventionTiny?.interventionStatus ===
						PublicHealthInterventionStatus.DRAFT ||
					isActivitiesTabDisabled
				}
			>
				{interventionTiny?.interventionPath && (
					<InterventionActivityData
						{...props}
						interventionPath={interventionTiny.interventionPath}
						interventionSubjectId={interventionTiny.subjectId!}
						opinionAOTMiT={interventionTiny.opinionAOTMiT!}
						onChangeActivities={onChangeActivities}
						interventionCreatedAt={moment(
							interventionTiny.createdAt
						)}
					></InterventionActivityData>
				)}
			</TabPane>
			{!props.isPattern && (
				<>
					<TabPane
						tab="Dokumenty"
						key="documents"
						disabled={
							interventionTiny?.interventionStatus ===
							PublicHealthInterventionStatus.DRAFT
						}
					>
						{props.interventionId && (
							<InterventionDocuments
								refresh={refresh}
								sourceDocumentId={
									interventionTiny?.sourceDocumentId
								}
								interventionPath={
									interventionTiny?.interventionPath
								}
								interventionId={props.interventionId}
								editable={props.editable}
								isPattern={props.isPattern}
							/>
						)}
					</TabPane>
					{interventionTiny?.formVersionId &&
						(props.editable || interventionTiny.documentId) && (
							<TabPane
								tab="Dane dodatkowe"
								key="orbeon"
								disabled={
									interventionTiny?.interventionStatus ===
									PublicHealthInterventionStatus.DRAFT
								}
							>
								{activeTab === 'orbeon' && (
									<FormRunner
										formVersionId={
											interventionTiny.formVersionId
										}
										mode={
											props.editable ? 'edit' : 'preview'
										}
										documentId={interventionTiny.documentId}
										pathBase={`/rizp/${
											props.editable === true
												? 'edit'
												: 'details'
										}/${props.interventionId}/${activeTab}`}
										onFormAdded={() => {}}
										onFormPartLoaded={() => {}}
										query={{
											interventionId:
												props.interventionId,
										}}
									></FormRunner>
								)}
							</TabPane>
						)}
				</>
			)}
		</Tabs>
	);
};

export default InterventionDetails;
