import TableActionButton from '@components/shared/buttons/TableActionButton';
import { UserManualDto } from '@services/src/models';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { Fragment, useRef } from 'react';
import AddUserManualModal, { IAddUserManualModal } from '../modals/AddUserManualModal';

interface IProps {
	refreshCallback(): void;
	userManual: UserManualDto;
}

const AddManualButton: React.FC<IProps> = (props) => {
	const addUserManualModalRef = useRef<
		IAddUserManualModal
	>() as React.RefObject<IAddUserManualModal>;

	return (
		<Fragment>
			<ProfiBazaTooltip placement="top" title="Dodaj instrukcję">
				<TableActionButton
					label="Dodaj instrukcję"
					kind="ADD"
					onClick={() => addUserManualModalRef.current?.showModal()}
				/>
			</ProfiBazaTooltip>

			<AddUserManualModal
				ref={addUserManualModalRef}
				userManual={props.userManual}
				onCreate={props.refreshCallback}
			/>
		</Fragment>
	);
};

export default AddManualButton;
