import { Card, Col, Radio, Row, Space } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { NotificationDefinitionCommand } from 'components/notifications/commands/NotificationDefinitionMapper';
import { Field, FormikProps } from 'formik';
import {
	FCheckbox,
	FFieldLabel,
	FInputNumber,
	FTextArea,
} from 'forms/FormikFormItems';
import React, { useEffect } from 'react';

interface IProps {
	formikProps: FormikProps<NotificationDefinitionCommand>;
}

const EditNotificationForm: React.FC<IProps> = ({ formikProps }) => {
	const handleDistributionRangeFormikUpdate = (e: RadioChangeEvent) => {
		formikProps.setFieldValue('sendToAll', e.target.value);
	};

	useEffect(() => {
		if (!formikProps.values['customTriggerActivation']) {
			formikProps.setFieldValue('customTrigger', undefined);
		}
	}, [formikProps.values['customTriggerActivation']]);

	return (
		<>
			<Row>
				<Col span={24}>
					<FFieldLabel label="Zdarzenie" />
					<Field
						label="Zdarzenie"
						component={FTextArea}
						placeholder="Zdarzenie"
						name="event"
						rows={2}
						readOnly
					/>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<FFieldLabel label="Nagłówek powiadomienia" />
					<Field
						label="Nagłówek powiadomienia"
						component={FTextArea}
						placeholder="Nagłówek powiadomienia"
						name="header"
						rows={2}
						readOnly
					/>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<FFieldLabel label="Treść powiadomienia" />
					<Field
						label="Treść powiadomienia"
						component={FTextArea}
						rows={4}
						placeholder="Treść powiadomienia"
						name="content"
					/>
				</Col>
			</Row>
			<Row className="mt-small">
				<Col span={24}>
					<FFieldLabel label="Data wysyłki" />
					<Card>
						<Row>
							<Col span={8}>
								<Space>
									<Field
										label="7 dni przed"
										component={FCheckbox}
										name="triggeredWeekBefore"
										id="triggeredWeekBefore"
									/>
									<FFieldLabel
										label="7 dni przed"
										for="triggeredWeekBefore"
									/>
								</Space>
							</Col>
							<Col span={8}>
								<Space>
									<Field
										label="1 dzień przed"
										component={FCheckbox}
										name="triggeredDayBefore"
										id="triggeredDayBefore"
									/>
									<FFieldLabel
										label="1 dzień przed"
										for="triggeredDayBefore"
									/>
								</Space>
							</Col>

							<Col span={8}>
								<Space>
									<Field
										label="w dniu zdarzenia"
										component={FCheckbox}
										name="triggeredExactDay"
										id="triggeredExactDay"
									/>
									<FFieldLabel
										label="w dniu zdarzenia"
										for="triggeredExactDay"
									/>
								</Space>
							</Col>
						</Row>
						<Row>
							<Col span={10}>
								<Space>
									<Field
										component={FCheckbox}
										name="customTriggerActivation"
										id="customTriggerActivation"
									/>
									<Field
										component={FInputNumber}
										disabled={
											!formikProps.values[
												'customTriggerActivation'
											]
										}
										size="small"
										precision={0}
										style={{ width: 50 }}
										name="customTrigger"
										label="Inna liczba dni"
									/>
									<FFieldLabel
										label="dni przed"
										for="customTriggerActivation"
									/>
								</Space>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row className="mt-small">
				<Col span={24}>
					<FFieldLabel label="Kanał dystrybucji" />
					<Card>
						<Row>
							<Col span={8}>
								<Space>
									<Field
										label="System"
										component={FCheckbox}
										name="systemChannelActive"
										id="systemChannelActive"
									/>
									<FFieldLabel
										label="System"
										for="systemChannelActive"
									/>
								</Space>
							</Col>
							<Col span={8}>
								<Space>
									<Field
										label="Email"
										component={FCheckbox}
										name="emailChannelActive"
										id="emailChannelActive"
									/>
									<FFieldLabel
										label="Email"
										for="emailChannelActive"
									/>
								</Space>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row className="mt-small">
				<Col span={24}>
					<FFieldLabel label="Odbiorcy" />
					<Card>
						<Row>
							<Col span={8}>
								<Radio.Group
									onChange={
										handleDistributionRangeFormikUpdate
									}
									value={formikProps.values['sendToAll']}
								>
									<Radio value={false}>
										Wyślij do
										właściciela/współwłaścicieli/partnerów
									</Radio>
									<Radio value={true}>
										Wyślij do wszystkich w strukturze
									</Radio>
								</Radio.Group>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default EditNotificationForm;
