import DictionaryPicker from '@components/dictionary/picker/DictionaryPicker';
import { InterventionPathContextType } from '@components/intervention/context/InterventionPathContext';
import SubjectsTableModal from '@components/shared/SubjectsTableModal';
import {
	DictionaryInterventionPath,
	SubjectListItemDto,
} from '@services/src/models';
import Modal from 'antd/lib/modal/Modal';
import { Field } from 'formik';
import { FFieldLabel, FSelectSubjects } from 'forms/FormikFormItems';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { interventionRealizationSubjectColumns } from '../../../subjects/SubjectTableColumns';

interface IProps {
	visible: boolean;
	context: InterventionPathContextType;
	onConfirm: () => void;
	onCancel: () => void;
}

const RoleField = (context: InterventionPathContextType) => (
	<DictionaryPicker
		codeName="ROLA_JEDNOSTKI_W_PROJEKCIE"
		label="Rola współrealizatora"
		helpField="coownerRoles"
		dateRange={
			!context.editable
				? [context.data?.createdAt!, context.data?.createdAt!]
				: undefined
		}
		name="coProducerSubjectRoles"
		valueId="roleDictionaryValueId"
		otherName="roleDictionaryValue"
		otherValue="roleDictionaryOtherValue"
		interventionPath={
			(context.data
				?.interventionPath! as string) as DictionaryInterventionPath
		}
		disabled={!context.editable}
		inline
		multiple={true}
	/>
);

const InterventionCoProducersModal: React.FC<IProps> = (props) => {
	const context = { ...props.context };
	const [subjectsVisible, setSubjectsVisible] = useState<boolean>(false);

	const SubjectField = (
		<>
			<FFieldLabel label="Podmiot" />
			<Field
				label="Podmiot"
				mode="single"
				component={FSelectSubjects}
				name="subjectId"
				onClick={() => {
					setSubjectsVisible(true);
				}}
				onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
					if (e.key === 'Enter') {
						setSubjectsVisible(true);
					}
				}}
				array={undefined}
				editable={context.editable}
				dropDown={true}
				othername="subjectName"
				allowClear={false}
			/>

			<Field hidden value="subjectName" aria-hidden="true" />

			<SubjectsTableModal
				visible={subjectsVisible}
				mode="radio"
				columns={interventionRealizationSubjectColumns}
				onCancel={() => {
					setSubjectsVisible(false);
				}}
				acceptSubject={(value: SubjectListItemDto | undefined) => {
					context.formik?.setFieldValue('subjectId', value?.id);
					context.formik?.setFieldValue('subjectName', value?.name);
					setSubjectsVisible(false);
				}}
			/>
		</>
	);

	useEffect(() => {
		context.formik?.setFieldValue('subjectId', undefined);
		context.formik?.setFieldValue('subjectName', undefined);
		context.formik?.setFieldValue('coProducerSubjectRoles', []);
	}, [props.visible]);

	const canSaveCoProducer = (): boolean => {
		if (
			context.formik?.values &&
			context.formik.values.coProducerSubjectRoles
		) {
			return (
				context.formik.values.subjectId !== undefined &&
				context.formik.values.coProducerSubjectRoles?.length > 0
			);
		}

		return false;
	};

	return (
		<Modal
			title="Współrealizatorzy"
			visible={props.visible}
			okText="Zatwierdź"
			cancelText="Wróć"
			width="40vw"
			okButtonProps={{ disabled: !canSaveCoProducer() }}
			closable={true}
			maskClosable={true}
			destroyOnClose
			centered
			onOk={() => {
				let value = [...context.formik!.values.coProducers!];

				value.push({
					subjectId: context.formik?.values.subjectId,
					subjectName: context.formik?.values.subjectName,
					roles: [
						...(context.formik?.values.coProducerSubjectRoles ??
							[]),
					],
				});

				context.formik?.setFieldValue('coProducers', value);
				context.checkChanges?.(
					value,
					'coProducers'
				)
				props.onConfirm();
			}}
			onCancel={() => {
				props.onCancel();
			}}
		>
			{SubjectField}
			{RoleField(context)}
		</Modal>
	);
};

export default InterventionCoProducersModal;
