import { AimOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { handleOnPressEnter } from '@helper/accessibilityHelpers';
import { Button, Card, Dropdown, List, Menu, Modal, notification } from 'antd';
import clsx from 'clsx';
import { ajaxByUser } from 'helper/api';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { DictionaryStatus, DictionaryValueItemDto } from 'services/src/models';

import { MultiDictionaryContext } from './contexts/MultiDictionaryContext';

interface IItemProps {
	item: DictionaryValueItemDto;
	previousLevel?: boolean;
}

export const DictionaryValueItem = (props: IItemProps) => {
	const { item, previousLevel } = props;

	const dictionaryContext = useContext(MultiDictionaryContext);
	const {
		values,
		rootValue,
		setRootValue,
		getChildren,
		currentLevel,
		setCurrentLevel,
		lastSelectedValueId,
		setLastSelectedValueId,
		searchPhrase,
		loading,
		valueModalProps,
		setActionBaseId,
		detachedValue,
		setDetachedValue,
		globalContextMenuVisible,
		setGlobalContextMenuVisible,
		setDetachedLevel,
		setKeyStack,
		deleteModalRef,
		isEditable,
		detachedLevel,
		levels,
		selectableMode,
		selection,
		handleCancel,
		otherValueHistoryModalRef,
		isDraft,
	} = dictionaryContext;

	const isActive = item.hasChildren && !previousLevel;
	const selectable = selectableMode && item.canBeSelected && !previousLevel;
	const [enteredNextArea, setEnteredNextArea] = useState<boolean>(false);

	const containsPhrase = useMemo(
		() => (value: string | undefined, searchPhrase: string | undefined) => {
			if (!searchPhrase || previousLevel) return false;
			return value?.toLowerCase().includes(searchPhrase.toLowerCase());
		},
		[searchPhrase, previousLevel]
	);

	const getTableWithUpdatedStatus = (
		newValueId: number,
		newStatus: DictionaryStatus
	) => {
		const foundIndex = values.map((val) => val.id).indexOf(newValueId);
		let newValues: DictionaryValueItemDto[] = [...values];
		newValues[foundIndex].status = newStatus;
		return newValues;
	};

	const openLvlSwitchingNotification = (valueId: number | undefined) => {
		const key = valueId?.toString() ?? 'none';

		notification.open({
			message: 'Przenoszenie wartości',
			description:
				item.value && item.value.length > 50
					? `${item.value?.substring(0, 50)}...`
					: item.value,
			key,
			onClose: () => {
				setDetachedValue(undefined);
				setDetachedLevel(undefined);
			},
			placement: 'bottomRight',
			duration: null,
		});
	};

	const editMenu = (
		<Menu>
			{isEditable && (
				<Menu.Item
					key="1"
					onClick={(param) => {
						if (valueModalProps) {
							setActionBaseId(item.id);
							valueModalProps.setVisible(true);
						}
					}}
				>
					Dodaj potomka
				</Menu.Item>
			)}
			{isEditable && (
				<Menu.Item
					key="2"
					onClick={(param) => {
						if (valueModalProps) {
							valueModalProps.setId(item.id);
							valueModalProps.setVisible(true);
						}
					}}
				>
					Edytuj
				</Menu.Item>
			)}
			{isEditable && (
				<Menu.Item
					key="3"
					onClick={(param) => {
						deleteModalRef?.current?.showModal(item.id!);
					}}
				>
					Usuń
				</Menu.Item>
			)}
			{isEditable && !detachedValue && (
				<Menu.Item
					key="5"
					onClick={(param) => {
						setDetachedValue(item);
						setDetachedLevel(rootValue);
						openLvlSwitchingNotification(item.id);
					}}
				>
					Odepnij
				</Menu.Item>
			)}
			{isEditable && detachedValue && detachedValue.id !== item.id && (
				<Menu.Item
					key="6"
					onClick={(param) => {
						ajaxByUser('Wartość została przeniesiona', () => {
							const newParentIndex = item.id;
							return getProfiBazaApiClient()
								.then((api) =>
									api.dictionaryValue.updateParent({
										body: {
											id: detachedValue?.id,
											parentId: newParentIndex,
										},
									})
								)
								.then(() => {
									const key =
										detachedValue?.id?.toString() ?? 'none';
									notification.close(key);
									const sourceLvlValues = levels
										.getCachedValues(detachedLevel?.id)
										.filter(
											(val) =>
												val.id !== detachedValue?.id
										);
									levels.cacheValues(
										detachedLevel?.id,
										sourceLvlValues
									);

									levels.switchToLevel(currentLevel);

									if (!item.hasChildren) {
										item.hasChildren = true;
									}
									levels.removeCachedValue(item.id);
									setDetachedValue(undefined);
									setDetachedLevel(undefined);
								});
						});
					}}
				>
					Przypnij
				</Menu.Item>
			)}
		</Menu>
	);

	const viewMenu = (
		<Menu>
			{item.canAddTextValue && (
				<Menu.Item
					key="7"
					onClick={(param) => {
						otherValueHistoryModalRef?.current?.showModal(item.id!);
					}}
				>
					Pokaż inne
				</Menu.Item>
			)}
		</Menu>
	);

	const contextMenuItems = isEditable ? (
		editMenu
	) : !isDraft ? (
		viewMenu
	) : (
		<></>
	);

	const handleSelect = () => {
		if (selectable && selection && !enteredNextArea) {
			selection.setFormValues(item);

			handleCancel();
		}
	};

	const goToNextLevel = () => {
		setLastSelectedValueId(item.id);
		setRootValue(item);
		setKeyStack((stack) => [...stack, item]);
		getChildren(item.id!);
		setCurrentLevel(currentLevel + 1);
	};

	const card = (
		<Card
			className={clsx(
				item.id === lastSelectedValueId && 'card-distinguished',
				(!item.hasChildren || previousLevel) && 'card-inactive',
				containsPhrase(item.value, searchPhrase) && 'bg-highlight',
				detachedValue?.id === item.id && 'card-detached'
			)}
			style={{ width: '100%', position: 'relative' }}
			hoverable={selectable}
			tabIndex={selectable ? 0 : -1}
			loading={loading}
			onContextMenu={(event) => {
				if (globalContextMenuVisible) {
					setGlobalContextMenuVisible(false);
				}
				event.stopPropagation();
			}}
			onKeyUp={handleOnPressEnter(handleSelect)}
			onClick={(e) => {
				handleSelect();
			}}
		>
			<div className="dictionary-grid-container">
				<div>
					<span style={{ marginRight: 10 }}>
						{item.canBeSelected && (
							<AimOutlined className="pr-xxs" />
						)}
						<strong>{item.code}</strong>
					</span>
					{item.value}
				</div>
				<div>
					{isActive && (
						<span
							className="icon-next-level icon-next-level--btn icon-next-level--tr pointer"
							tabIndex={0}
							role="button"
							aria-label="Przejdź dalej"
							onMouseEnter={(event) => {
								setEnteredNextArea(true);
							}}
							onMouseLeave={(event) => {
								setEnteredNextArea(false);
							}}
							onKeyUp={handleOnPressEnter(goToNextLevel)}
							onClick={goToNextLevel}
						>
							<span className="icon-next-level--text">dalej</span>
							<ArrowRightOutlined />
						</span>
					)}
				</div>
			</div>
		</Card>
	);

	const dropDownEnhanced = (content: JSX.Element): JSX.Element =>
		!previousLevel && !selectable && (isEditable || !isDraft) ? (
			<Dropdown overlay={contextMenuItems} trigger={['contextMenu']}>
				{content}
			</Dropdown>
		) : (
			content
		);

	return <List.Item key={item.id}>{dropDownEnhanced(card)}</List.Item>;
};

export const SortableItem = SortableElement(DictionaryValueItem);
