/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/apiKeyMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a ApiKey. */
export class ApiKey {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a ApiKey.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ApiKeyGenerateResponse>
   */
  generate(options?: Models.ApiKeyGenerateOptionalParams): Promise<Models.ApiKeyGenerateResponse>;
  /**
   * @param callback The callback
   */
  generate(callback: msRest.ServiceCallback<Models.ApiKeyDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  generate(options: Models.ApiKeyGenerateOptionalParams, callback: msRest.ServiceCallback<Models.ApiKeyDto>): void;
  generate(options?: Models.ApiKeyGenerateOptionalParams | msRest.ServiceCallback<Models.ApiKeyDto>, callback?: msRest.ServiceCallback<Models.ApiKeyDto>): Promise<Models.ApiKeyGenerateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      generateOperationSpec,
      callback) as Promise<Models.ApiKeyGenerateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ApiKeyRegenerateResponse>
   */
  regenerate(options?: Models.ApiKeyRegenerateOptionalParams): Promise<Models.ApiKeyRegenerateResponse>;
  /**
   * @param callback The callback
   */
  regenerate(callback: msRest.ServiceCallback<Models.ApiKeyDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  regenerate(options: Models.ApiKeyRegenerateOptionalParams, callback: msRest.ServiceCallback<Models.ApiKeyDto>): void;
  regenerate(options?: Models.ApiKeyRegenerateOptionalParams | msRest.ServiceCallback<Models.ApiKeyDto>, callback?: msRest.ServiceCallback<Models.ApiKeyDto>): Promise<Models.ApiKeyRegenerateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      regenerateOperationSpec,
      callback) as Promise<Models.ApiKeyRegenerateResponse>;
  }

  /**
   * @param subjectId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(subjectId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param subjectId
   * @param callback The callback
   */
  deleteMethod(subjectId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param subjectId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(subjectId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(subjectId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        subjectId,
        options
      },
      deleteMethodOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const generateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "authorization/ApiKey",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.GenerateApiKeyCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.ApiKeyDto
    },
    default: {}
  },
  serializer
};

const regenerateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "authorization/ApiKey",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.RegenerateApiKeyCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.ApiKeyDto
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "authorization/ApiKey/{subjectId}",
  urlParameters: [
    Parameters.subjectId0
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};
