import React, { useState, useEffect } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { FFieldLabel } from 'forms/FormikFormItems';
import * as H from 'history';
import { ajaxCatch, ajaxByUser } from 'helper/api';
import { Row, Col, Input } from 'antd';
import RolesTable from './common/RolesTable';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import SubjectPermissionsTableModal from './SubjectPermissionsTableModal';
import { SubjectListItemDto, SubjectRolesDto } from 'services/src/models';
import ApiKeyForm from './ApiKeyForm';
import Authorized from 'authorization/authorized';
import { RoleDto } from './common/UpdateUserRolesCommand';
import { handleOnPressEnter } from '@helper/accessibilityHelpers';

interface IState {
	selectedSubjectName?: string;
	subject?: SubjectRolesDto;
	subjectModalVisible: boolean;
}

interface IProps {
	subjectId?: string;
	history: H.History;
}

const SubjectPermissions: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({ subjectModalVisible: false });

	useEffect(() => {
		if (!props.subjectId) {
			setState({ subjectModalVisible: false });
			return;
		}
		reload();
	}, [props.subjectId]);

	const reload = () =>
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.subject.getById1(props.subjectId!))
				.then((response) => {
					setState({
						...state,
						selectedSubjectName: response.subjectName,
						subject: response,
					});
				})
		);

	return (
		<>
			<Row>
				<FFieldLabel label="Wybierz Podmiot" />
				<Input
					aria-label="Wyszukaj podmiot"
					placeholder="Wyszukaj podmiot"
					value={state.selectedSubjectName}
					onClick={() =>
						setState({ ...state, subjectModalVisible: true })
                    }
                    onKeyUp={handleOnPressEnter(() => {
                        setState({ ...state, subjectModalVisible: true })
                    })}
				/>

				<SubjectPermissionsTableModal
                    visible={state.subjectModalVisible}
					onCancel={() =>
						setState({ ...state, subjectModalVisible: false })
					}
					acceptSubject={(value: SubjectListItemDto | undefined) => {
						setState({
							...state,
							subjectModalVisible: false,
							selectedSubjectName: value?.name,
						});
						props.history.push(
							`/admin/permissions/subjects/${value?.id}`
						);
					}}
				></SubjectPermissionsTableModal>
			</Row>

			{state.subject && (
				<>
					<Authorized
						permission={
							ProfiBazaAPIModels.Permission.AdminApiKeysManagement
						}
					>
						<ApiKeyForm
							apiKey={state.subject?.apiKey}
							id={state.subject.subjectId!}
						></ApiKeyForm>
					</Authorized>
					<Row>
						<Col span={24}>
							<RolesTable
								roles={state.subject.roles!}
								rolePermissions={state.subject.rolePermissions!}
								addRole={(role: RoleDto) =>
									ajaxByUser('Zaktualizowano role.', () =>
										getProfiBazaApiClient().then((api) =>
											api.subject.addRole({
												body: {
													subjectId: props.subjectId,
													roleId: role.roleId,
													validFrom: role.validFrom
														?.utc(true)
														.toDate(),
													validTo: role.validTo
														?.utc(true)
														.toDate(),
												},
											})
										)
									).then(() => reload())
								}
								deleteRole={(role: RoleDto) =>
									ajaxByUser('Skasowano rolę.', () =>
										getProfiBazaApiClient().then((api) =>
											api.subject.deleteRole({
												body: {
													subjectId: props.subjectId,
													roleId: role.roleId,
												},
											})
										)
									).then(() => reload())
								}
							></RolesTable>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default SubjectPermissions;
