import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<ProfiBazaAPIModels.UserManualType, string>([
	[ProfiBazaAPIModels.UserManualType.Main, 'Ogólny'],
	[ProfiBazaAPIModels.UserManualType.Administrative, 'Administracyjny'],
	[ProfiBazaAPIModels.UserManualType.Intervention, 'Interwencja'],
    [ProfiBazaAPIModels.UserManualType.Statement, 'Sprawozdanie'],
	[ProfiBazaAPIModels.UserManualType.Subject, 'Podmiot'],
	[ProfiBazaAPIModels.UserManualType.RODO, "RODO"]
]);

export const map = (value: ProfiBazaAPIModels.UserManualType): string =>
	mapings.get(value) ?? '';
