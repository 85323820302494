import ExportButtons from '@components/shared/paginatedProfiBazaTable/actions/ExportButtons';
import IRenderActionModel from '@components/shared/paginatedProfiBazaTable/actions/RenderAction';
import { useRootData } from '@hooks/hook';
import {
	FileType,
	StatementArea,
	StatementListItemDto,
} from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { Space } from 'antd';
import React from 'react';
import { IGridStore } from 'stores/GridStore';

import { exportActions } from './StatementExportActions';

interface IProps {
	actionProps: IRenderActionModel<StatementListItemDto>;
	signal: AbortSignal | undefined;
}

export const StatementExportButtons: React.FC<IProps> = (props) => {
	const { actionProps, signal } = props;

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const allRowsSelected = gridStore.allRowsSelected.get();

	const getXLSXExportButtons = (
		selectedItems: ProfiBazaAPIModels.StatementListItemDto[]
	) => {
		const flatCondition =
			selectedItems.every(
				(x) =>
					x.statementStatus !==
						ProfiBazaAPIModels.StatementStatus.DRAFT &&
					x.statementArea !== StatementArea.MZ53
			) || allRowsSelected;

		const singleCondition =
			selectedItems.every(
				(x) =>
					x.statementStatus !==
						ProfiBazaAPIModels.StatementStatus.DRAFT &&
					x.statementArea === StatementArea.MZ53
			) && !allRowsSelected;

		if (flatCondition) {
			return (
				<ExportButtons<StatementListItemDto>
					fileType={FileType.XLSX}
					supportedExportTypes={['default', 'flat']}
					getSieveModel={actionProps.getSieveModel}
					getFilePromise={exportActions}
					abortSignal={signal}
				/>
			);
		} else if (singleCondition) {
			return (
				<ExportButtons<StatementListItemDto>
					fileType={FileType.XLSX}
					supportedExportTypes={['default', 'single']}
					getSieveModel={actionProps.getSieveModel}
					getFilePromise={exportActions}
					abortSignal={signal}
				/>
			);
		} else {
			return (
				<ExportButtons<StatementListItemDto>
					fileType={FileType.XLSX}
					supportedExportTypes={['default']}
					getSieveModel={actionProps.getSieveModel}
					getFilePromise={exportActions}
					abortSignal={signal}
				/>
			);
		}
	};

	return (
		<Space>
			<ExportButtons<StatementListItemDto>
				fileType={FileType.PDF}
				supportedExportTypes={['default', 'full']}
				getSieveModel={actionProps.getSieveModel}
				getFilePromise={exportActions}
				conditionalVisibility={(
					selectedItems: ProfiBazaAPIModels.StatementListItemDto[]
				) =>
					selectedItems.every(
						(x) =>
							x.statementStatus !==
							ProfiBazaAPIModels.StatementStatus.DRAFT
					) || allRowsSelected
				}
				abortSignal={signal}
			/>

			<ExportButtons<StatementListItemDto>
				fileType={FileType.PDF}
				supportedExportTypes={['default']}
				getSieveModel={actionProps.getSieveModel}
				getFilePromise={exportActions}
				conditionalVisibility={(
					selectedItems: ProfiBazaAPIModels.StatementListItemDto[]
				) =>
					!selectedItems.every(
						(x) =>
							x.statementStatus !==
							ProfiBazaAPIModels.StatementStatus.DRAFT
					) && !allRowsSelected
				}
				abortSignal={signal}
			/>

			<ExportButtons<StatementListItemDto>
				fileType={FileType.JSON}
				supportedExportTypes={['full']}
				getSieveModel={actionProps.getSieveModel}
				getFilePromise={exportActions}
				conditionalVisibility={(
					selectedItems: ProfiBazaAPIModels.StatementListItemDto[]
				) =>
					selectedItems.every(
						(x) =>
							x.statementStatus !==
							ProfiBazaAPIModels.StatementStatus.DRAFT
					) || allRowsSelected
				}
				abortSignal={signal}
			/>

			{getXLSXExportButtons(gridStore.getSelectedValues())}

			<ExportButtons<StatementListItemDto>
				fileType={FileType.CSV}
				supportedExportTypes={['default', 'flat']}
				getSieveModel={actionProps.getSieveModel}
				getFilePromise={exportActions}
				conditionalVisibility={(
					selectedItems: ProfiBazaAPIModels.StatementListItemDto[]
				) =>
					selectedItems.every(
						(x) =>
							x.statementStatus !==
								ProfiBazaAPIModels.StatementStatus.DRAFT &&
							x.statementArea === StatementArea.NPZ
					) || allRowsSelected
				}
				abortSignal={signal}
			/>
			<ExportButtons<StatementListItemDto>
				fileType={FileType.CSV}
				supportedExportTypes={['default']}
				getSieveModel={actionProps.getSieveModel}
				getFilePromise={exportActions}
				conditionalVisibility={(
					selectedItems: ProfiBazaAPIModels.StatementListItemDto[]
				) =>
					!selectedItems.every(
						(x) =>
							x.statementStatus !==
								ProfiBazaAPIModels.StatementStatus.DRAFT &&
							x.statementArea === StatementArea.NPZ
					) && !allRowsSelected
				}
				abortSignal={signal}
			/>
		</Space>
	);
};
