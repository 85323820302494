import {
	FileExcelTwoTone,
	FileTextTwoTone,
	FolderOpenTwoTone,
	IdcardTwoTone,
	ProfileTwoTone,
	SettingTwoTone,
} from '@ant-design/icons';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import PermissionValidator from 'authorization/permissionValidator';
import React from 'react';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { MenuItemSchema, SubMenuItemSchema } from './MenuItems';

export const menuItems: Array<MenuItemSchema | SubMenuItemSchema> = [
	{
		permissions: [
			ProfiBazaAPIModels.Permission.RIZPPreviewMine,
			ProfiBazaAPIModels.Permission.RIZPPreviewSubordinate,
			ProfiBazaAPIModels.Permission.RIZPPreviewAll,
		],
		path: 'rizp/intervention',
		icon: <ProfileTwoTone aria-hidden={true}/>,
		text: 'Interwencje',
		key: 'rizp_intervention',
		activePrefix: 'rizp',
	},
	{
		permissions: [
			ProfiBazaAPIModels.Permission.StatementPreviewMineNPZ,
			ProfiBazaAPIModels.Permission.StatementPreviewMineMZ49,
			ProfiBazaAPIModels.Permission.StatementPreviewMineMZ53,
			ProfiBazaAPIModels.Permission.StatementPreviewAllNPZ,
			ProfiBazaAPIModels.Permission.StatementPreviewAllMZ49,
			ProfiBazaAPIModels.Permission.StatementPreviewAllMZ53,
			ProfiBazaAPIModels.Permission.StatementPreviewSubordinateNPZ,
			ProfiBazaAPIModels.Permission.StatementPreviewSubordinateMZ49,
			ProfiBazaAPIModels.Permission.StatementPreviewSubordinateMZ53,
		],
		path: 'statements',
		icon: <FileTextTwoTone aria-hidden={true}/>,
		text: 'Sprawozdania',
		key: 'statements',
		activePrefix: ['statements', 'statement'],
	},
	{
		permissions: [ProfiBazaAPIModels.Permission.SubjectsListPreview],
		path: 'subject',
		icon: <IdcardTwoTone aria-hidden={true}/>,
		text: 'Podmioty',
		key: 'subject',
	},
	{
		key: 'reports',
		path: 'reports',
		icon: <FileExcelTwoTone aria-hidden={true}/>,
		text: 'Raporty',
		permissions: [
			ProfiBazaAPIModels.Permission.ReportsAOTMiTPreview,
			ProfiBazaAPIModels.Permission.ReportsAllPreview,
			ProfiBazaAPIModels.Permission.ReportsMZPreview,
			ProfiBazaAPIModels.Permission.ReportsProgramyZdrowotnePreview,
			ProfiBazaAPIModels.Permission.ReportsRPPreview,
			ProfiBazaAPIModels.Permission.ReportsSZChorobowoscPreview,
			ProfiBazaAPIModels.Permission.ReportsSZDemografiaPreview,
			ProfiBazaAPIModels.Permission.ReportsSZDeprywacjaPreview,
			ProfiBazaAPIModels.Permission.ReportsSZPreview,
			ProfiBazaAPIModels.Permission.ReportsSZStatusyPreview,
			ProfiBazaAPIModels.Permission.ReportsSZUmieralnoscPreview,
		],
	},
	{
		permissions: [
			ProfiBazaAPIModels.Permission.DocumentsRepositoryListPreview,
		],
		activePrefix: ['documents'],
		path: 'documents/interventions',
		icon: <FolderOpenTwoTone aria-hidden={true}/>,
		text: 'Dokumenty',
		key: 'documents',
	},
	{
		permissions: [
			ProfiBazaAPIModels.Permission.AdminRolesAssignmentGlobal,
			ProfiBazaAPIModels.Permission.AdminRolesAssignmentLocal,
			ProfiBazaAPIModels.Permission.AdminRolesModifications,
			ProfiBazaAPIModels.Permission.AdminRolesPreview,
			ProfiBazaAPIModels.Permission
				.SubjectsModificationsStatementObligation,
		],
		key: 'admin',
		text: 'Administracja',
		icon: <SettingTwoTone aria-hidden={true}/>,
		activePrefix: ['admin', 'dictionaries', 'forms'],
		items: [
			{
				key: 'admin/permissions/users',
				path: 'admin/permissions/users',
				text: 'Zarządzanie uprawnieniami',
				permissions: [
					ProfiBazaAPIModels.Permission.AdminRolesAssignmentGlobal,
					ProfiBazaAPIModels.Permission.AdminRolesAssignmentLocal,
				],
			},
			{
				key: 'admin/roles',
				path: 'admin/roles',
				text: 'Role',
				permissions: [
					ProfiBazaAPIModels.Permission.AdminRolesModifications,
					ProfiBazaAPIModels.Permission.AdminRolesPreview,
				],
			},
			{
				permissions: [
					ProfiBazaAPIModels.Permission
						.SubjectsModificationsStatementObligation,
				],
				key: 'admin/subjectReportingProcess',
				path: 'admin/subjectReportingProcess',
				text: 'Obowiązek sprawozdawczości',
			},
			{
				permissions: [
					ProfiBazaAPIModels.Permission.DictionariesListPreview,
				],
				key: 'dictionaries',
				path: 'dictionaries',
				text: 'Słowniki',
			},
			{
				permissions: [ProfiBazaAPIModels.Permission.FormsManagement],
				key: 'forms',
				path: 'forms',
				text: 'Formularze',
			},
			{
				permissions: [
					ProfiBazaAPIModels.Permission.NotificationsManagement,
				],
				key: 'admin/notifications',
				path: 'admin/notifications',
				text: 'Zarządzanie powiadomieniami',
			},
			{
				permissions: [
					ProfiBazaAPIModels.Permission.UserManualManagement,
				],
				path: 'admin/userManuals',
				text: 'Instrukcje użytkownika',
			},
			{
				permissions: [
					ProfiBazaAPIModels.Permission.ProposalsHandlingMine,
					ProfiBazaAPIModels.Permission.ProposalsHandlingAll,
				],
				key: 'admin/proposal',
				path: 'admin/proposal',
				text: 'Zarządzanie wnioskami',
			},
			{
				permissions: [
					ProfiBazaAPIModels.Permission.TagsManagementGlobal,
					ProfiBazaAPIModels.Permission.TagsManagementLocal,
				],
				key: 'admin/tags',
				path: 'admin/tags',
				text: 'Zarządzanie tagami',
			},
		],
	},
];

export const setDefaultRoute = (
	permissionValidator: PermissionValidator,
	accountDetailsStore: IAccountDetailsStore
) => {
	const firstAvailableRoute: any = menuItems!.find(
		(item: any) =>
			item.permissions === undefined ||
			permissionValidator.hasAny(item.permissions) ||
			(item.items?.length &&
				(item.items[0].permissions === undefined ||
					(item.items[0].permissions &&
						permissionValidator.hasAny(item.items[0].permissions))))
	);
	if (firstAvailableRoute) {
		if (firstAvailableRoute.path) {
			accountDetailsStore.defaultRoute.set(firstAvailableRoute.path);
		} else if (firstAvailableRoute.items) {
			const firstSubitem = firstAvailableRoute.items!.find(
				(item: any) =>
					(item.path && item.permissions === undefined) ||
					permissionValidator.hasAny(item.permissions)
			);
			if (firstSubitem.path) {
				accountDetailsStore.defaultRoute.set(firstSubitem.path);
			}
		}
	}
};
