import { DictionaryValuesModalProps } from 'components/dictionary/modals/dictionaryValues/DictionaryValuesModal';
import React, { createContext } from 'react';
import {
	DictionaryInterventionPath,
	DictionaryValueItemDto,
} from 'services/src/models';

import { IDeleteValueModal } from '../DeleteValueModal';
import { DictionaryLevelHistory } from '../DictionaryLevelHistory';
import { IOtherValueHistoryModal } from '../OtherValueHistoryModal';

export type MultiDictionaryFooterContextType = {
	isEditable: boolean | undefined;
	values: DictionaryValueItemDto[];
	notModal: boolean | undefined;
	valueModalProps: DictionaryValuesModalProps | undefined;
	setActionBaseId: (value: React.SetStateAction<number | undefined>) => void;
	rootValue: DictionaryValueItemDto | undefined;
	restValueModalProps: {
		dictionaryVersionId: number;
		isCodeNameInValuesRequired: boolean;
		id?: number | undefined;
		setId: (id: number | undefined) => void;
		visible: boolean;
		setVisible: (visible: boolean) => void;
		smallButton?: boolean | undefined;
		parentId?: number | undefined;
		noButton?: boolean | undefined;
	};
	actionBaseId: number | undefined;
	levels: DictionaryLevelHistory;
	updateValuesCollection: (
		id: number | undefined,
		items: DictionaryValueItemDto[]
	) => void;
	deleteModalRef?: React.RefObject<IDeleteValueModal>;
	refreshBaseItems:
		| ((list?: DictionaryInterventionPath[]) => void)
		| undefined;
	getChildren?: (id: number, isUpdate?: boolean) => Promise<void>;
	otherValueHistoryModalRef?: React.RefObject<IOtherValueHistoryModal>;
	handleCancel: () => void;
	processFilters: DictionaryInterventionPath[] | undefined;
};

export const MultiDictionaryFooterContext = createContext<
	MultiDictionaryFooterContextType
>({
	isEditable: undefined,
	values: [],
	notModal: undefined,
	valueModalProps: undefined,
	setActionBaseId: () => {},
	rootValue: undefined,
	restValueModalProps: {
		dictionaryVersionId: 0,
		isCodeNameInValuesRequired: false,
		id: undefined,
		setId: () => {},
		visible: false,
		setVisible: () => {},
		smallButton: undefined,
		parentId: undefined,
		noButton: undefined,
	},
	actionBaseId: undefined,
	levels: new DictionaryLevelHistory(),
	updateValuesCollection: (
		id: number | undefined,
		items: DictionaryValueItemDto[]
	) => {},
	deleteModalRef: undefined,
	refreshBaseItems: undefined,
	getChildren: undefined,
	otherValueHistoryModalRef: undefined,
	handleCancel: () => {},
	processFilters: undefined,
});
