import { ProposalAccessType } from 'services/src/models';

export class ProposalAccessCommand {
	firstName?: string;
	lastName?: string;
	proposalAccesType?: ProposalAccessType;
	email?: string;
    subjectId?: string;
}

export const file_extensions: string[] = ['image/jpg', 'image/jpeg', 'image/bmp', 'image/png', 'application/pdf'];
