import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<ProfiBazaAPIModels.DocumentSource, string>([
	[ProfiBazaAPIModels.DocumentSource.MZ49, 'MZ49'],
	[ProfiBazaAPIModels.DocumentSource.MZ53, 'MZ53'],
	[ProfiBazaAPIModels.DocumentSource.NPZ, 'Sprawozdanie z Zadań Zdrowia Publicznego'],
	[ProfiBazaAPIModels.DocumentSource.AOTMiT, 'AOTMiT'],
	[ProfiBazaAPIModels.DocumentSource.OTHER, 'Inny'],
	[ProfiBazaAPIModels.DocumentSource.INTERVENTION, 'Interwencja'],
	[ProfiBazaAPIModels.DocumentSource.SUBJECT, 'Podmiot'],
]);

export const map = (value: ProfiBazaAPIModels.DocumentSource): string =>
	mappings.get(value) ?? '';
