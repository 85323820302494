import { getFullItemWidth } from '@helper/domHelpers';
import PermissionValidator from 'authorization/permissionValidator';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ToolbarItem, ToolbarStateReturn } from 'reakit/Toolbar';

import { MenuItemSchema } from './MenuItems';

export interface IApplicationMenuItem {
	item: MenuItemSchema;
	permissionValidator: PermissionValidator;
	toolbar: ToolbarStateReturn;
	setMenuItemWidth: (key: string, width: number) => void;
}

const ApplicationMenuItem: React.FC<IApplicationMenuItem> = (props) => {
	const location = useLocation();

	const targetRef = useRef<HTMLDivElement>(null);

	const isVisible =
		props.item.permissions === undefined ||
		props.permissionValidator.hasAny(props.item.permissions);

	useEffect(() => {
		if (isVisible && targetRef.current) {
			props.setMenuItemWidth(
				targetRef.current.id,
				getFullItemWidth(targetRef.current)
			);
		}
	}, [isVisible]);

	const isActive = () => {
		if (Array.isArray(props.item.activePrefix)) {
			return props.item.activePrefix.some((prefix) =>
				location.pathname.startsWith(`/${prefix}`)
			);
		}
		const slashedPrefix =
			props.item.activePrefix === undefined
				? props.item.path
				: props.item.activePrefix;
		return location.pathname.startsWith(`/${slashedPrefix}`);
	};
	return isVisible ? (
		<ToolbarItem
			{...(props.toolbar as any)}
			ref={targetRef}
			as={Link}
			id={props.item.key}
			to={`/${props.item.path}`}
			className={clsx(
				'ant-menu-item main-menu',
				isActive() && 'menu-item--selected'
			)}
			onClick={(event: any) => {
				if (props.item.disabled === true) {
					event.preventDefault();
					return;
				}

				if (props.item.onSelectItem) {
					props.item.onSelectItem(props.item.id);
				}
			}}
		>
			{props.item.icon}
			<span>{props.item.text}</span>
		</ToolbarItem>
	) : (
		<></>
	);
};

export default ApplicationMenuItem;
