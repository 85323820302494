import { SettingOutlined } from '@ant-design/icons';
import ReakitMenuButton from '@components/shared/buttons/ReakitMenuButton';
import React from 'react';
import { Menu, MenuItem, useMenuState } from 'reakit/Menu';
interface EventAction {
	onChange: () => void;
	label: React.ReactNode;
}

interface IProps {
	eventId: string;
	menuItems: EventAction[];
}

const EventActionMenu: React.FC<IProps> = (props) => {
	const { eventId, menuItems } = props;

	const menu = useMenuState({
		placement: 'bottom-end',
		unstable_fixed: true,
	});

	return (
		<div>
			<ReakitMenuButton
				menuState={menu}
				ariaLabel="Menu kontekstowe zdarzenia"
			>
				<SettingOutlined />
			</ReakitMenuButton>
			<Menu
				{...menu}
				className="ant-menu ant-menu-sub account-menu-vertical"
			>
				{menuItems.map((item, index) => (
					<MenuItem
						className="ant-menu-item sub-menu-item"
						{...menu}
						onClick={item.onChange}
						key={index}
					>
						{item.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

export default EventActionMenu;
