import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { FormPartDto, FormType } from 'services/src/models';

export class FormValueDto {
	formVersionId?: string;
	applicationName?: string;
	formName?: string;
	label?: string;
	pageOrientation?: ProfiBazaAPIModels.PageOrientation;
	isVisibleInViewModel?: boolean;

	constructor(model: ProfiBazaAPIModels.CreateFormPartCommand) {
		this.formVersionId = model.formVersionId;
		this.applicationName = model.applicationName;
		this.formName = model.formName;
		this.label = model.label;
		this.pageOrientation = model.pageOrientation;
		this.isVisibleInViewModel = model.isVisibleInViewModel;
	}

	static toCommand(
		model: FormValueDto,
		formType: FormType
	): ProfiBazaAPIModels.CreateFormPartCommand {
		const command: ProfiBazaAPIModels.CreateFormPartCommand = {
			formVersionId: model.formVersionId,
			applicationName: model.applicationName,
			formName: model.formName,
			label: model.label,
			pageOrientation: model.pageOrientation,
			isVisibleInViewModel: model.isVisibleInViewModel === undefined ? 
				((formType == FormType.StatementMZ49 || formType == FormType.StatementMZ53 || formType == FormType.StatementNPZ) ? false : undefined)
				: model.isVisibleInViewModel,
		};
		return command;
	}

	static mapToFormValueItem(
		model: FormValueDto,
		createdId?: string,
		order?: number
	): FormPartDto {
		return {
			id: createdId,
			applicationName: model.applicationName,
			formName: model.formName,
			order: order,
			label: model.label,
			pageOrientation: model.pageOrientation,
			isVisibleInViewModel: model.isVisibleInViewModel,
		};
	}
}
