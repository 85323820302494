import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';
import React from 'react';

interface IProps {}
export type ProfiBazaTooltipProps = IProps & TooltipProps;

const ProfiBazaTooltip: React.FC<React.PropsWithChildren<
	ProfiBazaTooltipProps
>> = (props) => {
	const { trigger, children, ...restProps } = props;

	return (
		<Tooltip {...restProps} trigger={trigger} mouseEnterDelay={0.7}>
			{children}
		</Tooltip>
	);
};

ProfiBazaTooltip.defaultProps = {
	trigger: ['hover', 'focus', 'click'],
};

export default ProfiBazaTooltip;
