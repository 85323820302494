import { UploadOutlined } from '@ant-design/icons';
import { Col, Upload } from 'antd';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { IsRCFileSizeValid } from 'components/shared/file/IsFileSizeValid';
import { ajaxCatch } from 'helper/api';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useEffect, useRef, useState } from 'react';
import { DictionariesConstants } from 'services/DictionariesConstants';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';

import SourceDocumentDetails, {
	ISourceDocumentDetails,
} from '../documents/SourceDocumentDetails';
import StatementDocumentsCategorySelect from './StatementDocumentsCategorySelect';

const { Dragger } = Upload;

interface IProps {
	statementId: string;
	editable?: boolean;
}

const StatementDocuments: React.FC<IProps> = (props) => {
	const [sourceDocumentId, setSourceDocumentId] = useState<
		string | undefined
	>();

	const sourceDocumentDetailsRef = useRef<ISourceDocumentDetails>(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [fileOptions, setFileOptions] = useState<RcCustomRequestOptions>();

	useEffect(() => {
		refresh();
	}, [props.statementId]);

	const refresh = () =>
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.statements.getById(props.statementId))
				.then((result) => {
					setSourceDocumentId(result.sourceDocumentId);
				})
		);

	const setSourceDocumentIdCallback = (
		documentId: string | undefined
	): void => {
		setSourceDocumentId(documentId);
	};

	const refreshDocumentDetailsRef = (): void => {
		sourceDocumentDetailsRef.current?.forceRefresh();
	};

	const fileUploadProps = {
		name: 'file',
		multiple: false,
		customRequest: (options: RcCustomRequestOptions) => {
			if (!IsRCFileSizeValid(options)) return;
			setFileOptions(options);
			setIsModalVisible(true);
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 1,
			format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
		},
		showUploadList: {
			showPreviewIcon: false,
			showDownloadIcon: false,
			showRemoveIcon: false,
		},
		accept:
			'.pdf, .xlsx, .xls, .doc, .docx, .jpg, .jpeg, .png, .gif, .rtf, .ppt, .txt',
	};

	return (
		<>
			{isModalVisible && fileOptions && (
				<StatementDocumentsCategorySelect
					statementId={props.statementId}
					options={fileOptions}
					setSourceDocumentId={setSourceDocumentIdCallback}
					refreshDocumentDetailsRef={refreshDocumentDetailsRef}
					onDestroy={() => {
						setIsModalVisible(false);
					}}
					codeName={
						DictionariesConstants.AttachmentCategories
							.StatementAttachmentCategory
					}
				/>
			)}
			<CenteredRow>
				<Col span={20}>
					<div style={{ margin: '20px 0px' }}>
						<Dragger
							aria-label="Załącznik"
							{...fileUploadProps}
							showUploadList={false}
						>
							<p className="ant-upload-drag-icon">
								<UploadOutlined />
							</p>
							<p className="ant-upload-text">
								Przeciągnij i upuść plik tutaj aby dodać plik
								lub kliknij aby dodać pliki ręcznie
							</p>
						</Dragger>
					</div>
				</Col>
			</CenteredRow>
			{sourceDocumentId && (
				<SourceDocumentDetails
					ref={sourceDocumentDetailsRef}
					documentId={sourceDocumentId}
					baseUrl={`/statement/${
						props.editable === true ? 'edit' : 'details'
					}/${props.statementId}/documents`}
					onEmptyDocumentsTree={refresh}
					backPath= '/statements'
					codeName={
						DictionariesConstants.AttachmentCategories
							.StatementAttachmentCategory
                    }
                    span={18}
				/>
			)}
		</>
	);
};

export default StatementDocuments;
