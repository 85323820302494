import React, { createContext } from 'react';
import { DictionaryValueItemDto } from 'services/src/models';

import { DictionaryValuesModalProps } from '../../modals/dictionaryValues/DictionaryValuesModal';
import { IDeleteValueModal } from '../DeleteValueModal';
import { DictionaryLevelHistory } from '../DictionaryLevelHistory';
import { ISelectionProps } from '../MultiLevelDictionary';
import { IOtherValueHistoryModal } from '../OtherValueHistoryModal';

export type MultiDictionaryContextType = {
	values: DictionaryValueItemDto[];
	setValues: (newValues: DictionaryValueItemDto[]) => void;
	currentLevel: number;
	setCurrentLevel: (value: number) => void;

	levels: DictionaryLevelHistory;

	rootValue: DictionaryValueItemDto | undefined;
	setRootValue: (value: DictionaryValueItemDto | undefined) => void;

	lastSelectedValueId: number | undefined;
	setLastSelectedValueId: (value: number | undefined) => void;

	loading: boolean;
	getChildren: (id: number, isUpdate?: boolean) => Promise<any>;
	isEditable?: boolean;
	searchPhrase: string | undefined;
	searchModalVisible: boolean;
	updateValuePosition: (
		dragIndex: number,
		hoverIndex: number,
		movedValue: DictionaryValueItemDto
	) => void;
	valueModalProps?: DictionaryValuesModalProps;
	updateValuesCollection: (
		id: number | undefined,
		items: DictionaryValueItemDto[]
	) => void;
	actionBaseId: number | undefined;
	setActionBaseId: (baseId: number | undefined) => void;
	detachedValue: DictionaryValueItemDto | undefined;
	setDetachedValue: (value: DictionaryValueItemDto | undefined) => void;
	detachedLevel: DictionaryValueItemDto | undefined;
	setDetachedLevel: (value: DictionaryValueItemDto | undefined) => void;
	globalContextMenuVisible: boolean;
	setGlobalContextMenuVisible: (newState: boolean) => void;
	setKeyStack: React.Dispatch<
		React.SetStateAction<(DictionaryValueItemDto | undefined)[]>
	>;
	deleteModalRef?: React.RefObject<IDeleteValueModal>;
	otherValueHistoryModalRef?: React.RefObject<IOtherValueHistoryModal>;
	selectableMode?: boolean;
	selection?: ISelectionProps;
	handleCancel: () => void;
	isDraft?: boolean;
};

export const MultiDictionaryContext = createContext<MultiDictionaryContextType>(
	{
		values: [],
		setValues: () => {},

		currentLevel: 0,
		setCurrentLevel: () => {},

		levels: new DictionaryLevelHistory(),

		rootValue: undefined,
		setRootValue: () => {},

		lastSelectedValueId: undefined,
		setLastSelectedValueId: () => {},

		loading: true,
		getChildren: (): Promise<any> => {
			return new Promise(() => {});
		},
		isEditable: false,
		searchPhrase: undefined,
		searchModalVisible: false,
		updateValuePosition: () => {},
		updateValuesCollection: () => {},
		valueModalProps: undefined,
		actionBaseId: undefined,
		setActionBaseId: () => {},
		detachedValue: undefined,
		setDetachedValue: () => {},
		globalContextMenuVisible: true,
		setGlobalContextMenuVisible: () => {},
		detachedLevel: undefined,
		setDetachedLevel: () => {},
		setKeyStack: () => {},
		deleteModalRef: undefined,
		otherValueHistoryModalRef: undefined,
		selectableMode: false,
		selection: undefined,
		handleCancel: () => {},
		isDraft: undefined,
	}
);
