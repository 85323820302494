import TableActionButton from '@components/shared/buttons/TableActionButton';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import { ajaxByUser } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import {
	InterventionCreateResponse,
	InterventionDetailsDto,
	InterventionPath,
} from '@services/src/models';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import AddInterventionModal from '../create/AddInterventionModal';
import { AddInterventionModalForm } from '../model/AddInterventionModalForm';

interface IProps {
	record: InterventionDetailsDto;
}

const CloneAction: React.FC<IProps & RouteComponentProps> = (props) => {
	const [visible, setVisible] = useState<boolean>(false);

	const cloneIntervention = (
		interventionTypeDictionaryValueId?: number,
		interventionTypeDictionaryOtherValue?: string,
		plannedDateFrom?:Date,
		plannedDateTo?:Date
	) => {
		ajaxByUser(
			'Sklonowano interwencję. Zostałeś przekierowany do edycji sklonowanej interwencji.',
			() =>
				getProfiBazaApiClient()
					.then((api) =>
						api.intervention.createOnExisting({
							body: {
								id: props.record.id,
								interventionTypeDictionaryValueId: interventionTypeDictionaryValueId,
								interventionTypeDictionaryOtherValue: interventionTypeDictionaryOtherValue,
								plannedDateFrom: plannedDateFrom,
								plannedDateTo: plannedDateTo
							},
						})
					)
					.then((response: InterventionCreateResponse) => {
						props.history.push(`/rizp/edit/${response.id}`);
					})
		);
	};

	return (
		<>
			{props.record.interventionPath ==
			InterventionPath.PROHEALTHACTIVITIES ? (
				<ProfiBazaTooltip placement="top" title="Sklonuj">
					<TableActionButton
						kind="CLONE"
						label="Sklonuj"
						onClick={() => {
							setVisible(true);
						}}
					/>
				</ProfiBazaTooltip>
			) : (
				<AccessiblePopconfirm
					title={
						<>
							Czy na pewno chcesz utworzyć nową interwencję na
							podstawie <b>{props.record.name}</b>?
						</>
					}
					onConfirm={() => {
						cloneIntervention();
					}}
					moveFocusTo="createInterventionButton"
				>
					<ProfiBazaTooltip placement="top" title="Sklonuj">
						<TableActionButton kind="CLONE" label="Sklonuj" />
					</ProfiBazaTooltip>
				</AccessiblePopconfirm>
			)}
			<AddInterventionModal
				visible={visible}
				setVisible={(visible: boolean) => setVisible(visible)}
				record={props.record}
				isCloning={true}
				create={(values: AddInterventionModalForm) => {
					cloneIntervention(
						values.interventionTypeDictionaryValueId,
						values.interventionTypeDictionaryOtherValue,
						values.plannedDateFrom,
						values.plannedDateTo
					);
				}}
			></AddInterventionModal>
		</>
	);
};

export default CloneAction;
