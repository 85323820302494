import { ProfiBazaColumnProps } from '@components/shared/paginatedProfiBazaTable/GridHelper';
import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import RenderActions from '@components/statements/table/RenderActions';
import useSelectsAccessibility from '@hooks/accessibility/useSelectsAccessibilityHook';
import * as UserManualTypeMapper from '@services/mappers/UserManualType';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import {
	RODOManualType,
	UserManualDto,
	UserManualType,
} from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { Col, Select } from 'antd';
import { FFieldLabel } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import PrimaryCard from 'layout/PrimaryCard';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import React, { Fragment, useEffect, useState } from 'react';

import AddManualButton from './tableButtons/AddManualButton';
import DeleteManualButton from './tableButtons/DeleteManualButton';
import DownloadManualButton from './tableButtons/DownloadManualButton';
import EditManualButton from './tableButtons/EditManualButton';
import PreviewManualButton from './tableButtons/PreviewManualButton';
import { AdministrativeColumns } from './tableColumns/AdministrativeColumns';
import { InterventionColumns } from './tableColumns/InterventionColumns';
import { RODOColumns } from './tableColumns/RODOColumns';
import { StatementColumns } from './tableColumns/StatementColumns';

interface IProps {}

interface IState {
	selectedManualType: UserManualType | undefined;
	manualTypes: UserManualType[];
	manualItems: UserManualDto[] | undefined;
	isBusy: boolean;
}

const UserManualView: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({
		selectedManualType: undefined,
		manualTypes: Object.values(UserManualType),
		manualItems: undefined,
		isBusy: false,
	});

	const getColumns = (): ProfiBazaColumnProps<UserManualDto>[] => {
		let columns: ProfiBazaColumnProps<UserManualDto>[] = [
			{
				sorter: false,
				dataIndex: 'manualType',
				key: 'manualType',
				title: 'Obszar instrukcji',
				render: (manualType) => UserManualTypeMapper.map(manualType),
			},
		];

		switch (state.selectedManualType) {
			case UserManualType.Intervention:
				columns.push(...InterventionColumns);
				break;
			case UserManualType.Statement:
				columns.push(...StatementColumns);
				break;
			case UserManualType.Administrative:
				columns.push(...AdministrativeColumns);
				break;
			case UserManualType.RODO:
				columns.push(...RODOColumns);
		}

		columns.push(
			RenderActions<ProfiBazaAPIModels.UserManualDto>((text, record) => (
				<Fragment>
					{!record.id && (
						<AddManualButton
							userManual={record}
							refreshCallback={fetchCategories}
						/>
					)}

					{record.id && (
						<Fragment>
							<PreviewManualButton {...record} />
							<DownloadManualButton {...record} />
							<EditManualButton
								record={record}
								refreshCallback={fetchCategories}
							/>
							<DeleteManualButton
								record={record}
								refreshCallback={fetchCategories}
							/>
						</Fragment>
					)}
				</Fragment>
			))
		);

		return columns;
	};

	const fetchCategories = (abortSignal?: AbortSignal): void => {
		if (state.selectedManualType) {
			setState({
				...state,
				isBusy: true,
				manualItems: [],
			});
			getProfiBazaApiClient().then((api) =>
				api.userManual
					.getManualCategory(state.selectedManualType!, {
						abortSignal,
					})
					.then(
						(result) => {
							setState({
								...state,
								manualItems: result,
								isBusy: false,
							});
						},
						() => {
							setState({
								...state,
								manualItems: [],
								isBusy: false,
							});
						}
					)
			);
		}
	};

	useEffect(() => {
		const abortController = new AbortController();
		fetchCategories(abortController.signal);

		return () => {
			abortController.abort();
		};
	}, [state.selectedManualType]);

	useSelectsAccessibility();

	return (
		<>
			<PageHeaderWithContent title="Zarządzanie instrukcjami" />
			<CenteredRow>
				<Col span={23}>
					<PrimaryCard>
						<CenteredRow>
							<Col span={8}>
								<FFieldLabel
									label="Wybierz obszar"
									for="manualType"
								/>
								<SkipLinkContent />
								<Select
									id="manualType"
									disabled={state.isBusy}
									onChange={(value) =>
										setState({
											...state,
											selectedManualType: state.manualTypes.find(
												(x) => x === value
											),
										})
									}
								>
									{state.manualTypes.map((x, i) => (
										<Select.Option value={x}>
											{UserManualTypeMapper.map(x)}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col span={16} />
						</CenteredRow>
					</PrimaryCard>
				</Col>
				<Col span={23}>
					<ProfiBazaTable
						loading={state.isBusy}
						columns={getColumns()}
						dataSource={state.manualItems}
						pagination={false}
					/>
				</Col>
			</CenteredRow>
		</>
	);
};

export default UserManualView;
