import Authorized from '@authorization/authorized';
import AddButton from '@components/shared/buttons/AddButton';
import TableActionButton from '@components/shared/buttons/TableActionButton';
import { ProfiBazaColumnProps } from '@components/shared/paginatedProfiBazaTable/GridHelper';
import PaginatedProfiBazaTable from '@components/shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import { SieveModel } from '@components/shared/paginatedProfiBazaTable/SieveModels';
import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import RenderActions from '@components/statements/table/RenderActions';
import { ajaxByUser } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { TagAllOptionalParams, TagDto } from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { Button, Col, Modal, Row, Space, Tag } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { FFieldLabel, FInput } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useState } from 'react';
import { IGridStore } from 'stores/GridStore';
import { ITableColumnStore } from 'stores/TableColumnStore';
import { ITagStore } from 'stores/TagStore';
import * as Yup from 'yup';

interface ICreateTag {
	tagName?: string;
}

interface IProps {}

export const tagsColumns: ProfiBazaColumnProps<TagDto>[] = [
	{
		sorter: true,
		dataIndex: 'tagName',
		key: 'tagName',
		title: 'Tag',
		filter: 'contains',
		defaultHidden: false,
		render: (value) => <Tag>{value} </Tag>,
	},
	{
		sorter: true,
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Nazwa podmiotu',
		filter: 'contains',
		defaultHidden: false,
	},
];
const TagList: React.FC<IProps> = (props) => {
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const tagStore: ITagStore = useRootData((store) => store.tagStore);

	const [modalVisible, setModalVisible] = useState<boolean>(false);

	const columns = [
		RenderActions<TagDto>((text, record) => {
			return (
				<>
					<ProfiBazaTooltip placement="top" title="Usuń">
						<AccessiblePopconfirm
							title={
								<span>
									Czy na pewno chcesz usunąć wskazaną wartość?
								</span>
							}
							onConfirm={() => {
								ajaxByUser('Usunięto wartość', () =>
									getProfiBazaApiClient().then((api) =>
										api.tag.deleteMethod({
											body: {
												id: record.id,
											},
										})
									)
								).then(() => {
									gridStore.searching.set(true);

									tagStore.removeTag(record.tagName!);
								});
							}}
						>
							<TableActionButton label="Usuń" kind="DELETE" />
						</AccessiblePopconfirm>
					</ProfiBazaTooltip>
				</>
			);
		}),
		...tableColumnStore.getTable('tags'),
	];

	return (
		<>
			<SkipLinkContent />
			<CenteredRow>
				<Col span={20} style={{ marginTop: 32 }}>
					<PageHeaderWithContent
						title="Tagi"
						right={
							<>
								<Space>
									<Authorized
										permission={
											ProfiBazaAPIModels.Permission
												.TagsManagementGlobal
										}
									>
										<AddButton
											tall
											onClick={() =>
												setModalVisible(true)
											}
											label="Dodaj"
										>
											Dodaj
										</AddButton>
									</Authorized>
								</Space>
							</>
						}
					/>
				</Col>
			</CenteredRow>
			<CenteredRow gutter={[16, 24]}>
				<Col span={20}>
					<PaginatedProfiBazaTable<TagDto>
						gridName="tags"
						hidePersonalizationSettings={false}
						getRowKey={(x) => x.tagName!}
						columns={columns}
						getPagedResult={(
							sieve: SieveModel,
							filters,
							abortSignal
						) => {
							const parameters: TagAllOptionalParams = {
								filters: sieve.filters,
								sorts: sieve.sorts,
								page: sieve.page,
								pageSize: sieve.pageSize,
								abortSignal,
							};
							return getProfiBazaApiClient().then((api) => {
								return api.tag
									.all(parameters)
									.then((result) => {
										return result;
									});
							});
						}}
					/>
				</Col>
			</CenteredRow>

			<Formik
				validateOnChange={false}
				validateOnBlur={true}
				initialValues={{}}
				validationSchema={Yup.object().shape({
					tagName: Yup.string()
						.nullable(false)
						.required('Wartość jest wymagana')
						.max(128, 'Wartość może mieć maksymalnie 128 znaków')
						.test(
							'cannotContainWhiteSpaces',
							'Tag nie może zawierać pustych znaków',
							(value: string) => {
								if (value) {
									return !value.includes(' ');
								}
								return false;
							}
						),
				})}
				onSubmit={(
					values: ICreateTag,
					actions: FormikActions<ICreateTag>
				) => {
					ajaxByUser(
						'Zaktualizowano opis słownika',
						() =>
							getProfiBazaApiClient().then((api) =>
								api.tag.create(values.tagName!)
							),
						() => {
							setModalVisible(false);
							actions.resetForm();
							gridStore.searching.set(true);
							tagStore.insertTag(values.tagName!);
						},
						(errors) => {
							actions.setErrors(errors);
						}
					);
				}}
				render={(props: FormikProps<ICreateTag>) => (
					<Modal
						title={'Dodaj tag'}
						visible={modalVisible}
						onOk={(e) => {
							if (props.isValid) {
								props.submitForm();
							}
						}}
						cancelText="Anuluj"
						centered
						onCancel={() => {
							setModalVisible(false);
							props.resetForm();
						}}
						okText="Zapisz"
					>
						<Row>
							<Col span={24}>
								<FFieldLabel label="Nazwa" for="tagName" />
								<Field
									component={FInput}
									name="tagName"
									autoFocus={true}
								/>
							</Col>
						</Row>
					</Modal>
				)}
			/>
		</>
	);
};

export default TagList;
