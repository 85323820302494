/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/interventionApiMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a InterventionApi. */
export class InterventionApi {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a InterventionApi.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @summary (external) API Key
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionApiGetAggregateSubjectResponse>
   */
  getAggregateSubject(options?: Models.InterventionApiGetAggregateSubjectOptionalParams): Promise<Models.InterventionApiGetAggregateSubjectResponse>;
  /**
   * @param callback The callback
   */
  getAggregateSubject(callback: msRest.ServiceCallback<Models.InterventionsAggregateDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAggregateSubject(options: Models.InterventionApiGetAggregateSubjectOptionalParams, callback: msRest.ServiceCallback<Models.InterventionsAggregateDto[]>): void;
  getAggregateSubject(options?: Models.InterventionApiGetAggregateSubjectOptionalParams | msRest.ServiceCallback<Models.InterventionsAggregateDto[]>, callback?: msRest.ServiceCallback<Models.InterventionsAggregateDto[]>): Promise<Models.InterventionApiGetAggregateSubjectResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getAggregateSubjectOperationSpec,
      callback) as Promise<Models.InterventionApiGetAggregateSubjectResponse>;
  }

  /**
   * @summary (external) API Key
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  createComment(options?: Models.InterventionApiCreateCommentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  createComment(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createComment(options: Models.InterventionApiCreateCommentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  createComment(options?: Models.InterventionApiCreateCommentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createCommentOperationSpec,
      callback);
  }

  /**
   * @summary (external) API Key
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionApiGetFieldsResponse>
   */
  getFields(options?: msRest.RequestOptionsBase): Promise<Models.InterventionApiGetFieldsResponse>;
  /**
   * @param callback The callback
   */
  getFields(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFields(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  getFields(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.InterventionApiGetFieldsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getFieldsOperationSpec,
      callback) as Promise<Models.InterventionApiGetFieldsResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getAggregateSubjectOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "external/rizp/intervention/aggregate",
  queryParameters: [
    Parameters.dateFrom1,
    Parameters.dateTo1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InterventionsAggregateDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const createCommentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "external/rizp/intervention/comment",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateInterventionAOTMiTCommentCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getFieldsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "external/rizp/intervention/fields",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Object"
        }
      }
    },
    default: {}
  },
  serializer
};
