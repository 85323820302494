import * as OpinionAOTMiTType from '@services/mappers/OpinionAOTMiTType';
import { Tag } from 'antd';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { ISideFilter } from 'components/shared/paginatedProfiBazaTable/TableSideBar';
import formatHelpers, { DateFormat } from 'helper/formatHelpers';
import React from 'react';
import { DictionaryName } from 'services/mappers/DictionaryName';
import * as InterventionPath from 'services/mappers/InterventionPath';
import * as InterventionStatus from 'services/mappers/InterventionStatusTableFilter';
import * as PatternStatus from 'services/mappers/PatternStatus';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const commonColumns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.InterventionListDto
>[] = [
	{
		sorter: true,
		dataIndex: 'codeName',
		key: 'codeName',
		title: 'Id',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'name',
		key: 'name',
		title: 'Nazwa',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Właściciel',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'plannedDateFrom',
		key: 'plannedDateFrom',
		title: 'Data od',
		filter: 'dateRange',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: true,
		dataIndex: 'plannedDateTo',
		key: 'plannedDateTo',
		title: 'Data do',
		filter: 'dateRange',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: true,
		dataIndex: 'createdAt',
		key: 'createdAt',
		title: 'Data utworzenia',
		filter: 'dateRange',
		render: (value?: Date) =>
			formatHelpers.formatDate(value, DateFormat.DateTime),
	},
	{
		sorter: true,
		dataIndex: 'aotmitNumber',
		key: 'aotmitNumber',
		title: 'Numer AOTMiT',
		filter: 'contains',
		defaultHidden: true,
	},
	{
		sorter: true,
		dataIndex: 'interventionPath',
		key: 'interventionPath',
		title: 'Ścieżka interwencji',
		filter: 'dictionary',
		filterDictionary: DictionaryName.InterventionPath,
		render: (value: ProfiBazaAPIModels.InterventionPath) =>
			InterventionPath.map(value),
	},
	{
		dataIndex: 'aotmitOpinion',
		key: 'aotmitOpinion',
		title: 'Opinia AOTMIT',
		sorter: true,
		filter: 'dictionary',
		filterDictionary: DictionaryName.AotmitOpinion,
		render: (value: ProfiBazaAPIModels.OpinionAOTMiTType) =>
			OpinionAOTMiTType.map(value),
	},
];

export const interventionTableColumns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.InterventionListDto
>[] = [
	...commonColumns,
	{
		sorter: true,
		dataIndex: 'status',
		key: 'status',
		title: 'Status',
		filter: 'dictionary',
		filterDictionary: DictionaryName.InterventionStatusTableFilter,
		render: (
			value: ProfiBazaAPIModels.PublicHealthInterventionStatus,
			record: ProfiBazaAPIModels.InterventionListDto
		) => {
			const statusValue = InterventionStatus.map(value);
			return <Tag>{statusValue}</Tag>;
		},
	},
];

export const patternTableColumns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.InterventionListDto
>[] = [
	...commonColumns,
	{
		sorter: true,
		dataIndex: 'patternStatus',
		key: 'patternStatus',
		title: 'Status wzorca',
		filter: 'dictionary',
		filterDictionary: DictionaryName.PatternStatus,
		render: (value: ProfiBazaAPIModels.PatternStatus) => {
			let color =
				value === ProfiBazaAPIModels.PatternStatus.ACTIVE
					? 'green'
					: 'default';
			const statusValue = PatternStatus.map(value!);
			return <Tag color={color}>{statusValue}</Tag>;
		},
	},
];

export const sideBarFilters = (showToFixTab: boolean): ISideFilter[] => {
	const tabs = [
		{
			key: 'intervention',
			label: 'Interwencje',
			filter: ProfiBazaAPIModels.InterventionFilter.Intervention,
			defaultSelected: true,
		},
		{
			key: 'patterns', //Patterns
			label: 'Wzorce',
			filter: ProfiBazaAPIModels.InterventionFilter.Patterns,
		},
		{
			key: 'archive', //Archive
			label: 'Archiwum',
			filter: ProfiBazaAPIModels.InterventionFilter.Archive,
		},
		{
			key: 'canceled', //Canceled
			label: 'Anulowane',
			filter: ProfiBazaAPIModels.InterventionFilter.Canceled,
		},
	];
	if (showToFixTab) {
		tabs.push({
			key: 'selected', // SelectedIds,
			label: 'Do poprawy',
			filter: ProfiBazaAPIModels.InterventionFilter.SelectedIds,
		});
	}
	return tabs;
};
