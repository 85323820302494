import TableActionButton from '@components/shared/buttons/TableActionButton';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import { ajaxByUser } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { message } from 'antd';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React from 'react';

interface IProps {
	refreshCallback(): void;
	record: ProfiBazaAPIModels.UserManualDto;
}

const DeleteManualButton: React.FC<IProps> = (props) => {
	const deleteUserManual = (): void => {
		if (props.record.id) {
			ajaxByUser('Poprawnie usunięto instrukcję', () =>
				getProfiBazaApiClient().then((api) =>
					api.userManual.deleteMethod(props.record.id!).then(() => {
						props.refreshCallback();
					})
				)
			);
		}
	};

	return (
		<ProfiBazaTooltip placement="top" title="Usuń instrukcję">
			<AccessiblePopconfirm
				title="Czy na pewno chcesz usunąć instrukcję ?"
				onConfirm={deleteUserManual}
			>
				<TableActionButton label="Usuń instrukcję" kind="DELETE" />
			</AccessiblePopconfirm>
		</ProfiBazaTooltip>
	);
};

export default DeleteManualButton;
