import React, { ReactNode } from 'react';
import ProfiBazaTable from './ProfiBazaTable';
import 'antd/dist/antd.css';
import arrayHelpers from '@helper/arrayHelpers';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { AntPagination } from 'components/shared/paginatedProfiBazaTable/SieveModels';
import { SorterResult } from 'antd/lib/table/interface';

const type = 'DragbleBodyRow';

interface IProps<T> {
	dataSource: T[];
	setDataSource: (dataSource: T[]) => void;
	handleMove: (dragIndex: number, hoverIndex: number) => void;
	getFilters?: (
		antFilters: Record<keyof T, string[]>,
		s: SorterResult<T>
	) => void;
}

const DraggableProfiBazaTable = <T extends any>(
	props: IProps<T> & { children?: ReactNode } & any
) => {
	const { dataSource, setDataSource, handleMove, ...otherProps } = props;

	const moveRow = (dragIndex: number, hoverIndex: number) => {
		if (dragIndex === hoverIndex) return;

		const data = [...dataSource];
		handleMove(dragIndex, hoverIndex);
		arrayHelpers.moveArrayElement<T>(data, dragIndex, hoverIndex);
		props.setDataSource(data);
	};

	const DragableBodyRow = ({
		index,
		moveRow,
		className,
		style,
		...restProps
	}: any) => {
		const ref = React.useRef();
		const [{ isOver, dropClassName }, drop] = useDrop({
			accept: type,
			collect: (monitor) => {
				const { index: dragIndex } = monitor.getItem() || {};
				if (dragIndex === index) {
					return {};
				}
				return {
					isOver: monitor.isOver(),
					dropClassName:
						dragIndex < index
							? ' drop-over-downward'
							: ' drop-over-upward',
				};
			},
			drop: (item: any) => {
				moveRow(item.index, index);
			},
		});
		const [, drag] = useDrag({
			item: { type, index },
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
		});
		drop(drag(ref));
		return (
			<tr
				ref={ref}
				className={`${className}${isOver ? dropClassName : ''}`}
				style={{ cursor: 'move', ...style }}
				{...restProps}
			/>
		);
	};

	const components = {
		body: {
			row: DragableBodyRow,
		},
	};

	return (
		<DndProvider backend={HTML5Backend}>
			<ProfiBazaTable
				{...otherProps}
				className="draggableTable"
				dataSource={dataSource}
				components={components}
				onChange={(
					p: AntPagination,
					f: Record<keyof T, string[]>,
					s: SorterResult<T>
				) => {
					props.getFilters && props.getFilters(f, s);
				}}
				onRow={(record: T, index: number) => ({
					index,
					moveRow: moveRow,
				})}
			/>
		</DndProvider>
	);
};

export default DraggableProfiBazaTable;
