import { GlobalUserManager } from '@authentication/userManager';
import { baseAppUrl } from '@helper/api';
import ErrorCode from '@helper/errorCode';
import { StatusCode } from '@helper/statusCode';
import { Modal } from 'antd';
import React from 'react';

const DataObjectParser = require('dataobject-parser');
let isModalOpen = false;

export function showFormIsInvalidModal() {
	Modal.warning({
		centered: true,
		maskClosable: true,
		title: 'Formularz zawiera błędy!',
	});
}

export function showMessageIsValidModal() {
	Modal.success({
		centered: true,
		maskClosable: true,
		title: 'Komunikat jest poprawny.',
	});
}

export function showSuccess(message: string | React.ReactNode) {
	Modal.success({
		centered: true,
		maskClosable: true,
		title: message,
	});
}

export function showWarning(message: string | React.ReactNode) {
	Modal.warn({
		centered: true,
		maskClosable: true,
		title: message,
	});
}

export function showErrorModal(message: string | React.ReactNode) {
	Modal.error({
		centered: true,
		title: message,
	});
}

export function showNotExpectedErrorModal(
	error: any,
	onNotExpectedModalConfirmation?: () => void
) {
	console.log(error);
	if (error && error!.statusCode === StatusCode.Forbidden) {
		Modal.warning({
			centered: true,
			title: 'Nie posiadasz wystarczających uprawnień',
		});
	} else if (error && error!.statusCode === StatusCode.BadRequest) {
		const errors = JSON.parse(error.response.body);
		const validationMessages: string[] = [];
		Object.keys(errors).map((key) => {
			errors[key].map((message: string) =>
				validationMessages.push(message)
			);
		});

		Modal.warning({
			centered: true,
			title: 'Błędy walidacji',
			onOk: onNotExpectedModalConfirmation,
			content: validationMessages.map((msg: string, index: number) => {
				return <p key={index}>{msg}</p>;
			}),
		});
		return DataObjectParser.transpose(errors).data();
	} else if (error && error!.statusCode === StatusCode.Unauthorized) {
		GlobalUserManager.UserManager().signinSilent();
		Modal.warning({
			centered: true,
			title: 'Wystąpił błąd, spróbuj ponownie',
		});
	} else if (error?.code === ErrorCode.AbortError) {
		return;
	} else if (error && error!.statusCode === StatusCode.NotFound) {
		if (!isModalOpen) {
			isModalOpen = true;
			Modal.error({
				centered: true,
				title:
					'Nie znaleziono szukanego zasobu, zostaniesz przekierowany na stronę główną.',
				onOk: () => {
					window.location.href = baseAppUrl;
					isModalOpen = false;
				},
				onCancel: () => {
					isModalOpen = false;
				},
			});
		}
	} else {
		var requestId =
			error?.response?.headers?._headersMap['request-id']?.value;
		Modal.error({
			centered: true,
			title: `Wystąpił niespodziewany błąd. Skontaktuj się z administratorem (${requestId}).`,
		});
	}
}

export function showMessageAddedAndPreparedToSendModal() {
	Modal.success({
		centered: true,
		maskClosable: true,
		title: 'Komunikat został zapisany i przekazany do wysłania.',
	});
}
