import { ColumnProps } from 'antd/lib/table';
import { Key } from 'react';
import { ProfiBazaColumnProps } from './GridHelper';
import { GridSettingsDto } from 'services/src/models';

export default class ColumnHelper {
    public static findColumnByKey<T>(
        columns: ProfiBazaColumnProps<T>[],
        key: Key
    ): ProfiBazaColumnProps<T> | undefined {
        for (let column of columns) {
            if (column.key === key) return column;

            if (column.children !== undefined) {
                let foundInChildren = this.findColumnByKey(
                    column.children,
                    key
                );

                //todo
                // if (foundInChildren !== undefined) return foundInChildren;
            }
        }

        return undefined;
    }

    public static getFlattenColumnKeys(columns: ColumnProps<any>[]): string[] {
        let visibleColumnsKeys: string[] = [];
        if (!columns) return visibleColumnsKeys;

        visibleColumnsKeys = columns
            .filter(column => column.key !== undefined && column.key !== 'actions' && column.key !== 'preview')
            .map(column => column.key!.toString());

        return visibleColumnsKeys;
        
    }

    public static getFlattenKeys(columns: ColumnProps<any>[]): string[] {
        let visibleColumnsKeys: string[] = [];

        if (columns) {
            columns.forEach((column) => {
                if (column.key != undefined) {
                    visibleColumnsKeys.push(column.key.toString());
                }
            });
        }
        return visibleColumnsKeys;
    }

    public static getVisibleColumnsKeys(
        gridSettings: GridSettingsDto | undefined,
        allColumns: ColumnProps<any>[]
    ): string[] {
        if (!gridSettings) return this.getFlattenKeys(allColumns);

        let filteredColumns = this.getVisibleColumns(gridSettings, allColumns);
        let visibleColumnsKeys: string[] = this.getFlattenKeys(filteredColumns);

        return visibleColumnsKeys;
    }

    public static getVisibleColumns(
        gridSettings: GridSettingsDto | undefined,
        allColumns: ColumnProps<any>[]
    ): ColumnProps<any>[] {
        if (!gridSettings) return allColumns;

        let visibleColumns: ColumnProps<any>[] = [];
        const visibleColumnKeys = gridSettings.visibleColumnKeys;

        allColumns.forEach((column) => {
            if (
                column.key === 'actions' ||
                visibleColumnKeys!.includes(column.key!.toString())
            ) {
                visibleColumns.push(column);
            }
        });

        if (visibleColumnKeys) {
            visibleColumns.sort(function (a, b) {
                return (
                    visibleColumnKeys.indexOf(a.key!.toString()) -
                    visibleColumnKeys.indexOf(b.key!.toString())
                );
            });
        }

        return visibleColumns;
    }
}
