import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import App from './App';
import { ConfigProvider } from 'antd';
import pl_PL from 'antd/lib/locale-provider/pl_PL';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import { BrowserRouter } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { GlobalUserManager } from '@authentication/userManager';
import StoreProvider from 'stores/StoreProvider';
import { enableMapSet } from 'immer';
import '@styles/theme/index.less';
import '@styles/components/forms.less';
import '@styles/components/nprogress.less';
import 'material-design-icons/iconfont/material-icons.css';

enableMapSet();

// HACK: klienty generowane przez nswag-a serializują daty przy użyciu metody toISOString,
// co sprawia że daty w otrzymane w kontrolerach są w utc
moment.fn.toISOString = function (this: any) {
	return this.format('YYYY-MM-DDTHH:mm:ss');
};

//rozszerzenie obiektów Moment o metody z Date na potrzeby react-day-picker
(moment.fn as any).getDate = function (this: Moment) {
	return this.date();
};
(moment.fn as any).getMonth = function (this: Moment) {
	return this.month();
};
(moment.fn as any).getFullYear = function (this: Moment) {
	return this.year();
};

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
// registerServiceWorker(); // można włączyć  - cachowanie

GlobalUserManager.Init();

StartApp();

function StartApp() {
	moment.locale('pl');
	ReactDOM.render(
		<ConfigProvider locale={pl_PL}>
			<BrowserRouter basename={baseUrl!}>
				<StoreProvider>
					<App />
				</StoreProvider>
			</BrowserRouter>
		</ConfigProvider>,
		rootElement
	);
}
