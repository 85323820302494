import { Tag } from 'antd';
import React from 'react';

export const renderShortcut = (...keys: string[]) => (
	<>
		{keys.map((key, index, array) => (
			<>
				<Tag className="m-none">
					<kbd>{key}</kbd>
				</Tag>
				{index < array.length - 1 && <span className="ph-xxs">+</span>}
			</>
		))}
	</>
);
