import { Col } from 'antd';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import RenderActions from 'components/statements/table/RenderActions';
import columns from 'components/statements/table/StatementTableColumns';
import { CenteredRow } from 'layout/CenteredRow';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { Fragment, useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { StatementListItemDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

interface IProps {}

const DashboardStatements: React.FC<IProps> = (props) => {
	const [data, setData] = useState<
		ProfiBazaAPIModels.StatementListItemDtoPagedResult
	>();

	const cols = columns.map((statementColumn) => ({
		...statementColumn,
		sorter: false,
	}));

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const tableColumns: ProfiBazaColumnProps<StatementListItemDto>[] = [
		...cols,
		RenderActions<ProfiBazaAPIModels.StatementListItemDto>(
			(text, record) => (
				<Fragment>
					<ProfiBazaTooltip placement="top" title="Szczegóły">
						<TableActionButton
							label="Szczegóły"
							kind="DETAILS"
							linkTo={`/statement/details/${record.id}`}
						/>
					</ProfiBazaTooltip>
					{record.statementStatus ===
						ProfiBazaAPIModels.StatementStatus.DRAFT && (
						<ProfiBazaTooltip placement="top" title="Edycja">
							<TableActionButton
								kind="EDIT"
								label="Edycja"
								linkTo={`/statement/edit/${record.id}`}
							/>
						</ProfiBazaTooltip>
					)}
				</Fragment>
			)
		),
	];

	useEffect(() => {
		setIsLoading(true);
		getProfiBazaApiClient()
			.then((api) =>
				api.statements.all({
					page: 1,
					pageSize: 5,
					sorts: '-updatedAt',
				})
			)
			.then(
				(
					result: ProfiBazaAPIModels.StatementListItemDtoPagedResult
				) => {
					setData(result);
				}
			)
			.finally(() => setIsLoading(false));
	}, []);

	return (
		<CenteredRow>
			<Col span={23}>
				<ProfiBazaTable
					loading={isLoading}
					columns={tableColumns}
					dataSource={data?.results}
					pagination={false}
					detailsUrl="/statement/details"
					rowKey={(r: StatementListItemDto) => r.id!}
				/>
			</Col>
		</CenteredRow>
	);
};

export default DashboardStatements;
