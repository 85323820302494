import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Popover, Space } from 'antd';
import FocusTrap from 'focus-trap-react';
import { useRootData } from 'hooks/hook';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { IGridStore } from 'stores/GridStore';

interface IProps {
	visible: boolean;
	link?: string;
	onClick: () => void;
	closeModal?: () => void;
	title?: string;
	size: 'large' | 'small' | 'middle';
	cancel?: boolean | undefined;
	setCancel?: (value: boolean) => void;
	backToCurrentPlace?: boolean;
	disabled?: boolean;
}

const BackButtons = (props: IProps) => {
	const [visible, setVisible] = useState<boolean>(false);
	const elRef = useRef<HTMLDivElement>(null);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	useEffect(() => {
		if (props.cancel) {
			elRef.current?.scrollIntoView({ behavior: 'smooth' });
			setTimeout(() => {
				setVisible(true);
			}, 500);
		}
	}, [props.cancel]);

	return (
		<div style={{ display: 'inline' }} ref={elRef}>
			{props.visible ? (
				<Popover
					style={{ height: '30px' }}
					destroyTooltipOnHide
					title={
						props.title ??
						'Zostały wprowadzone zmiany. Czy chcesz je zapisać?'
					}
					content={
						<FocusTrap
							focusTrapOptions={{
								onDeactivate: () => {
									setVisible(false);
								},
								clickOutsideDeactivates: true,
								returnFocusOnDeactivate: true,
								allowOutsideClick: true,
								fallbackFocus: '.profibaza-popconfirm',
								preventScroll: true,
							}}
						>
							<div
								style={{
									textAlign: 'right',
								}}
							>
								<Space>
									{props.link ? (
										<Link to={props.link}>
											<Button
												className="save-changes-no-answer-button"
												size="small"
												type="primary"
												disabled={props.disabled}
											>
												Nie
											</Button>
										</Link>
									) : (
										<Button
											className="save-changes-no-answer-button"
											onClick={() => {
												props.closeModal &&
													props.closeModal();
												setVisible(false);
											}}
											disabled={props.disabled}
											size="small"
											type="primary"
										>
											Nie
										</Button>
									)}
									<Button
										type="primary"
										htmlType="submit"
										size="small"
										disabled={props.disabled}
										onClick={() => {
											props.onClick();
											setVisible(false);
										}}
									>
										Tak
									</Button>
								</Space>
							</div>
						</FocusTrap>
					}
					placement={'bottomRight'}
					visible={visible}
					onVisibleChange={(value) => {
						setVisible(true);
					}}
					trigger={'click'}
				>
					<Button size={props.size} disabled={props.disabled}>
						Wróć
					</Button>
				</Popover>
			) : props.link ? (
				<Link to={props.link}>
					<Button
						size={props.size}
						disabled={props.disabled}
						onClick={() =>
							props.backToCurrentPlace &&
							gridStore.searching.set(false)
						}
					>
						Wróć
					</Button>
				</Link>
			) : (
				<Button
					onClick={props.closeModal}
					size={props.size}
					disabled={props.disabled}
				>
					Wróć
				</Button>
			)}
		</div>
	);
};

export default BackButtons;
