/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/permissionMappers";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Permission. */
export class Permission {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Permission.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PermissionAllResponse>
   */
  all(options?: msRest.RequestOptionsBase): Promise<Models.PermissionAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.PermissionDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PermissionDto[]>): void;
  all(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PermissionDto[]>, callback?: msRest.ServiceCallback<Models.PermissionDto[]>): Promise<Models.PermissionAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.PermissionAllResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "authorization/Permission",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PermissionDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};
