/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/statementApiMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a StatementApi. */
export class StatementApi {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a StatementApi.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @summary (external) API Key
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementApiGetByIdResponse>
   */
  getById(id: string, options?: Models.StatementApiGetByIdOptionalParams): Promise<Models.StatementApiGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.StatementDetailsExternalDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: Models.StatementApiGetByIdOptionalParams, callback: msRest.ServiceCallback<Models.StatementDetailsExternalDto>): void;
  getById(id: string, options?: Models.StatementApiGetByIdOptionalParams | msRest.ServiceCallback<Models.StatementDetailsExternalDto>, callback?: msRest.ServiceCallback<Models.StatementDetailsExternalDto>): Promise<Models.StatementApiGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.StatementApiGetByIdResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "external/statements/{id}",
  urlParameters: [
    Parameters.id0
  ],
  queryParameters: [
    Parameters.contentType
  ],
  responses: {
    200: {
      bodyMapper: Mappers.StatementDetailsExternalDto
    },
    default: {}
  },
  serializer
};
