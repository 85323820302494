import { CloseOutlined } from '@ant-design/icons';
import { handleOnPressEnter } from '@helper/accessibilityHelpers';
import { List, Popover } from 'antd';
import clsx from 'clsx';
import { ErrorPopover } from 'forms/ErrorPopover';
import { IFormikInputProps } from 'forms/FormikFormItems';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React from 'react';
import { DictionaryValueItemDto } from 'services/src/models';

interface IProps {
	values: DictionaryValueItemDto[];
	handleItemDeletion: (selectedId: number) => void;
	multiple?: boolean;
	inline?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	name?: string;
	small?: boolean;
	disableBreadcrumbs?: boolean;
	previewValues: DictionaryValueItemDto[];
}

const MultiLevelPickerField: React.FC<IProps & IFormikInputProps> = (props) => {
	const {
		values,
		handleItemDeletion,
		multiple,
		inline,
		disabled,
		readOnly,
		small,
		previewValues,
	} = props;
	
	const renderItemReadOnlyMode = () => {
		return previewValues.map((x) => {
			return (
				<p style={{margin: 2}}>
					<strong className="pr-xxs">{x.code}</strong>
					{props.disableBreadcrumbs! ? '' : x.breadcrumb}
					{x.value}
				</p>
			);
		});
	};

	const renderItemParagraph = (item: DictionaryValueItemDto) => (
		<>
			<strong className="pr-xxs">{item.code}</strong>
			{props.disableBreadcrumbs! ? '' : item.breadcrumb}
			{item.value}
		</>
	);

	const renderItemContent = (item: DictionaryValueItemDto) => (
		<>
			{item.value && item.value?.length > 50 ? (
				<ProfiBazaTooltip placement="top" title={item.value}>
					<p
						className={clsx(
							'ellipsis',
							'm-none',
							!multiple && 'item__paragraph'
						)}
					>
						{renderItemParagraph(item)}
					</p>
				</ProfiBazaTooltip>
			) : (
				<p className={clsx('m-none')}>{renderItemParagraph(item)}</p>
			)}
		</>
	);

	const renderDeletionBtn = (item: DictionaryValueItemDto) => (
		<>
			{!disabled && !readOnly && (
				<span
					className="btn--focusable"
					style={{ cursor: 'pointer' }}
					tabIndex={0}
					role="button"
					aria-label="Usuń wartość słownikową"
					onKeyUp={handleOnPressEnter(() =>
						handleItemDeletion(item.id!)
					)}
					onClick={() => handleItemDeletion(item.id!)}
				>
					<CloseOutlined />
				</span>
			)}
		</>
	);

	const input = () =>
		(readOnly || disabled) ? (
			renderItemReadOnlyMode()
		) : (
			<List
				size="small"
				locale={{ emptyText: <></> }}
				dataSource={values}
				renderItem={(item) => (
					<List.Item
						key={item.id}
						className="dictionary-picker__item hidable-container"
					>
						{renderItemContent(item)}
						{renderDeletionBtn(item)}
					</List.Item>
				)}
			/>
		);

	return (
		<ErrorPopover field={props.field} form={props.form}>
			<div
				className={clsx(
					'ant-input',
					'dictionary-picker',
					!inline && !small && 'mt-xs',
					(disabled || readOnly) && 'ant-input-disabled'
				)}
			>
				{input()}
			</div>
		</ErrorPopover>
	);
};

export default MultiLevelPickerField;
