/**
 * Helper to remove commas from search string due to issue in Sieve library, also provides method for undo changes.
 */
export class SearchCommaHelper {
	static applyChanges(value: string | undefined, columnName: string): string {
		if (!value) {
			return '';
		}

		return value.replace(new RegExp(',', 'g'), `,${columnName}@=*`);
	}
	static undoChanges(value: string, columnName: string): string {
		let index = 0;
		do {
			value = value.replace(`,${columnName}@=*`, ',');
		} while ((index = value.indexOf(`,${columnName}@=*`, index + 1)) > -1);
		return value;
	}
}


export const isEmptyString = (value: string) => {
    return (value.length === 0 || !value.trim());
};