import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import moment, { Moment } from 'moment';

export class DictionaryVersionDto {
	dictionaryId?: number;
	validFrom?: Moment;
	validTo?: Moment;
	version?: string;
	isCodeName?: boolean | undefined;

	constructor(model: ProfiBazaAPIModels.CreateDictionaryVersionCommand) {
		this.dictionaryId = model.dictionaryId;
		this.validFrom = moment(model.validFrom);
	}

	static toCommand(
		model: DictionaryVersionDto
	): ProfiBazaAPIModels.CreateDictionaryVersionCommand {
		const command: ProfiBazaAPIModels.CreateDictionaryVersionCommand = {
			dictionaryId: model.dictionaryId,
			validFrom: model.validFrom?.utc(true).toDate(),
		};
		return command;
	}
}
