import { Col, Row, Space } from 'antd';
import AddButton from 'components/shared/buttons/AddButton';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useState } from 'react';
import { ContactDto } from 'services/src/models';

import SubjectContact from './SubjectContact';

interface IProps {
	contacts: ContactDto[];
	setContacts: (values: ContactDto[]) => void;
	editable?: boolean;
}

const SubjectContactTable: React.FC<IProps> = (props) => {
	const [visible, setVisible] = useState<boolean>(false);

	const { editable, contacts, setContacts } = props;
	return (
		<>
			<CenteredRow style={{ marginTop: '20px' }}>
				<Col span={24}>
					<Row style={{ marginBottom: '10px' }}>
						<Space align="center">
							<h2 className="m-none">Dane kontaktowe</h2>
							{props.editable ? (
								<AddButton
									onClick={() => {
										setVisible(true);
									}}
								>
									Dodaj kontakt
								</AddButton>
							) : (
								<></>
							)}
						</Space>
					</Row>

					<SubjectContact
						editable={editable}
						contacts={contacts!}
						visible={visible}
						setContacts={setContacts}
						setVisible={(visible: boolean) => {
							setVisible(visible);
						}}
					></SubjectContact>
				</Col>
			</CenteredRow>
		</>
	);
};
export default SubjectContactTable;
