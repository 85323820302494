import { NotificationDefinitionActivationDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { Activations } from '../types/Activations';

export class NotificationDefinitionCommand {
	id?: string;
	event?: string;
	header?: string;
	content?: string;
	isActive?: boolean;
	systemChannelActive?: boolean;
	emailChannelActive?: boolean;
	triggeredWeekBefore?: boolean;
	triggeredDayBefore?: boolean;
	triggeredExactDay?: boolean;
	customTriggerActivation?: boolean;
	customTrigger?: number;
	sendToAll?: boolean;

	constructor(model?: ProfiBazaAPIModels.NotificationDefinitionDTO) {
		this.id = model?.id;
		this.event = model?.event;
		this.header = model?.header;
		this.content = model?.content;
		this.isActive = model?.isActive;
		this.systemChannelActive = model?.systemChannelActive;
		this.emailChannelActive = model?.emailChannelActive;
		this.triggeredWeekBefore =
			model?.activations?.find(
				(x) => x.dayDifference === Activations.WeekBefore
			) != null ?? false;
		this.triggeredDayBefore =
			model?.activations?.find(
				(x) => x.dayDifference === Activations.DayBefore
			) != null ?? false;
		this.triggeredExactDay =
			model?.activations?.find(
				(x) => x.dayDifference === Activations.ExactDay
			) != null ?? false;

		const predefinedActivations = Object.keys(Activations).map((key) =>
			parseInt(key)
		);
		const customTriggers = model?.activations?.filter(
			(a) => !predefinedActivations.includes(a.dayDifference!)
		);

		this.customTriggerActivation = !!customTriggers?.length;
		this.customTrigger = customTriggers?.length
			? -customTriggers[0].dayDifference!
			: undefined;

		this.sendToAll = model?.sendToAll;
	}

	static toUpdateCommand(
		model?: NotificationDefinitionCommand
	): ProfiBazaAPIModels.UpdateNotificationDefinionCommand {
		let tmpActivations = Array<NotificationDefinitionActivationDto>();
		if (model?.triggeredWeekBefore)
			tmpActivations.push({ dayDifference: Activations.WeekBefore });
		if (model?.triggeredDayBefore)
			tmpActivations.push({ dayDifference: Activations.DayBefore });
		if (model?.triggeredExactDay)
			tmpActivations.push({ dayDifference: Activations.ExactDay });
		if (model?.customTrigger)
			tmpActivations.push({ dayDifference: -model?.customTrigger });

		const command: ProfiBazaAPIModels.UpdateNotificationDefinionCommand = {
			id: model?.id,
			content: model?.content,
			systemChannelActive: model?.systemChannelActive,
			emailChannelActive: model?.emailChannelActive,
			activations: tmpActivations,
			sendToAll: model?.sendToAll,
		};

		return command;
	}
}
