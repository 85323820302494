import { IInterventionCommentStore } from '@components/intervention/comments/store/InterventionCommentStore';
import { useRootData } from '@hooks/hook';
import { ActivityContext } from 'components/intervention/context/ActivityContext';
import { FormikActions } from 'formik';
import { produce } from 'immer';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import React, { useContext } from 'react';
import { ActivityPopulationValueDto, BusinessObjectType } from 'services/src/models';
import { IRizpDictionaryStore } from 'stores/RizpDictionaryStore';

import { PopulationValidationSchema } from '../../model/InterventionActivityValidation';
import {
	PopulationForm,
	getPopulationsColumns,
} from '../../model/InterventionActiviyTableColumns';
import ActivityDetailsTableTemplate from '../ActivityDetailsTableTemplate';

interface IProps {
	editable: boolean;
	changeData: (value: ActivityPopulationValueDto[]) => void;
	dateRange:
		| [(Moment | undefined)?, (Moment | undefined)?]
		| Moment[]
		| undefined;
	changedDictionariesValues: ActivityPopulationValueDto[] | undefined;
}

export type MergedActivityPopulationValueDto = ActivityPopulationValueDto & {
	prevSubjectId?: string;
	prevSubjectName?: string;
};

const PopulationTable = (props: IProps) => {
	const activityContext = useContext(ActivityContext);
	const { formik } = activityContext;

	const interventionCommentStore: IInterventionCommentStore = useRootData(
		(store) => store.interventionCommentStore
	);

	const rizpDictionaryStore: IRizpDictionaryStore = useRootData(
		(store) => store.rizpDictionaryStore
	);

	const cardContent = (
		item?: ActivityPopulationValueDto,
		formikActions?: FormikActions<any>,
		previewMode?: boolean
	) => (
		<PopulationForm
			interventionPath={activityContext.interventionPath}
			dateRange={props.dateRange}
			editable={props.editable!}
			value={item}
			previewMode={previewMode}
		/>
	);

	return (
		<>
			<ActivityDetailsTableTemplate<MergedActivityPopulationValueDto>
				array={formik?.values['activityPopulations'] ?? []}
				addButtonLabel="Dodaj populację"
				validationScheme={PopulationValidationSchema(
					activityContext.isPattern,
					activityContext.interventionPath
				)}
				initRizpDictionary={(objectId: string) => rizpDictionaryStore.fetch(objectId, BusinessObjectType.Population)}
				canPreview={true}
				columns={getPopulationsColumns(
					interventionCommentStore.comments.get()
				)}
				editable={props.editable}
				onCancel={() => {
					interventionCommentStore.filterParams.set({
						activityId: null,
						populationId: null,
						sponsoringCompanyId: null,
					});
				}}
				getItemId={(item) => item.id}
				patternCollection={
					activityContext.patternActivity?.activityPopulations!
				}
				isPattern={activityContext.isPattern}
				changedDictionariesValues={props.changedDictionariesValues}
				onCreate={(value: ActivityPopulationValueDto) => {
					const formikValue =
						formik?.values['activityPopulations'] ?? [];
					props.changeData(formikValue);
					formik?.setFieldValue('activityPopulations', [
						...formikValue,
						value,
					]);
					formik?.setFieldTouched('activityPopulations');
				}}
				onUpdate={(
					oldValue: ActivityPopulationValueDto,
					updatedValue: ActivityPopulationValueDto
				) => {
					const formikValues =
						formik?.values['activityPopulations'] ?? [];
					props.changeData(formikValues);

					const updatedItems = produce(formikValues, (draft) => {
						const indexToUpdate = formikValues.findIndex(
							(value) => value.id === updatedValue.id
						);
						if (indexToUpdate !== -1) {
							draft[indexToUpdate] = updatedValue;
						}
					});

					formik?.setFieldValue('activityPopulations', updatedItems);
					props.changeData(formikValues);
					formik?.setFieldTouched('activityPopulations');
				}}
				onRecordSelect={(record: any) => {
					interventionCommentStore.filterParams.set({
						activityId: activityContext.activity?.id!,
						populationId: record.id,
						sponsoringCompanyId: null,
					});
				}}
				onRemove={(values) => {
					props.changeData(values);
					formik?.setFieldValue('activityPopulations', values);
					formik?.setFieldTouched('activityPopulations');
				}}
				cardContent={cardContent}
				cleanSideEffects={[
					{ key: 'genderDictionaryValueId', value: undefined },
					{ key: 'genderDictionaryOtherValue', value: undefined },
					{ key: 'genderDictionaryValue', value: undefined },
					{
						key: 'targetPopulationDictionaryValueId',
						value: undefined,
					},
					{
						key: 'targetPopulationDictionaryOtherValue',
						value: undefined,
					},
					{
						key: 'targetPopulationDictionaryValue',
						value: undefined,
					},
					{ key: 'minAge', value: undefined },
					{ key: 'maxAge', value: undefined },
					{ key: 'totalRecipients', value: undefined },
					{ key: 'interventionLocations', value: undefined },
					{ key: 'healthConditions', value: undefined },
					{ key: 'socialExclusions', value: undefined },
					{ key: 'otherSpecialFeatures', value: undefined },
				]}
			/>
		</>
	);
};

export default observer(PopulationTable);
