import { ajaxCatch } from '@helper/api';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Row, Space } from 'antd';
import { useRootData } from 'hooks/hook';
import React, { useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { DeadlineProcessDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import DashboardCounterItem from './DashboardCounterItem';

interface IProps {}

interface IDeadline {
	days?: number;
}

const DashboardCounter: React.FC<IProps> = (props) => {
	const [deadlines, setDeadlines] = useState<{
		MZ49: IDeadline;
		NPZ: IDeadline;
		MZ53: IDeadline;
	}>();

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const processDeadlines = (
		deadlineProcesses: DeadlineProcessDto[] | undefined
	) => {
		if (deadlineProcesses) {
			deadlineProcesses
				.filter((x) => x.daysToDeadline)
				.map((x) => {
					switch (x.process) {
						case 'MZ49':
							setDeadlines((state) => ({
								...state!,
								MZ49: {
									days: x.daysToDeadline,
								},
							}));
							break;
						case 'NPZ':
							setDeadlines((state) => ({
								...state!,
								NPZ: {
									days: x.daysToDeadline,
								},
							}));
							break;
						case 'MZ53':
							setDeadlines((state) => ({
								...state!,
								MZ53: {
									days: x.daysToDeadline,
								},
							}));
							break;
					}
				});
		}
	};

	useEffect(() => {
		const account = accountDetailsStore.account.get();

		if (account?.subject?.id) {
			ajaxCatch(() =>
				getProfiBazaApiClient().then((api) => {
					api.subject.getById(account?.subject?.id as string).then(
						(result: ProfiBazaAPIModels.SubjectDetailsDto) => {
							processDeadlines(result.deadlineProcess);
						},
						() => {}
					);
				})
			);
		}
	}, []);

	return (
		<Space className="counters" direction="vertical">
			{deadlines?.NPZ?.days !== undefined && (
				<Row>
					<DashboardCounterItem
						daysToDeadline={deadlines.NPZ.days}
						class="npz-dashboard-counter"
						text="Sprawozdania z Zadań Zdrowia Publicznego"
						size="large"
					/>
				</Row>
			)}

			{deadlines?.MZ49?.days !== undefined && (
				<Row>
					<DashboardCounterItem
						daysToDeadline={deadlines.MZ49.days}
						class="mz49-dashboard-counter"
						text="MZ-49"
					/>
				</Row>
			)}

			{deadlines?.MZ53?.days !== undefined && (
				<Row>
					<DashboardCounterItem
						daysToDeadline={deadlines.MZ53.days}
						class="mz53-dashboard-counter"
						text="MZ-53"
					/>
				</Row>
			)}
		</Space>
	);
};

export default DashboardCounter;
