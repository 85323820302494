/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/dictionaryMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Dictionary. */
export class Dictionary {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Dictionary.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryCreateResponse>
   */
  create(options?: Models.DictionaryCreateOptionalParams): Promise<Models.DictionaryCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.Int32IIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.DictionaryCreateOptionalParams, callback: msRest.ServiceCallback<Models.Int32IIdDto>): void;
  create(options?: Models.DictionaryCreateOptionalParams | msRest.ServiceCallback<Models.Int32IIdDto>, callback?: msRest.ServiceCallback<Models.Int32IIdDto>): Promise<Models.DictionaryCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.DictionaryCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.DictionaryUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.DictionaryUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.DictionaryUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryListResponse>
   */
  list(options?: Models.DictionaryListOptionalParams): Promise<Models.DictionaryListResponse>;
  /**
   * @param callback The callback
   */
  list(callback: msRest.ServiceCallback<Models.DictionaryViewDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  list(options: Models.DictionaryListOptionalParams, callback: msRest.ServiceCallback<Models.DictionaryViewDtoPagedResult>): void;
  list(options?: Models.DictionaryListOptionalParams | msRest.ServiceCallback<Models.DictionaryViewDtoPagedResult>, callback?: msRest.ServiceCallback<Models.DictionaryViewDtoPagedResult>): Promise<Models.DictionaryListResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      listOperationSpec,
      callback) as Promise<Models.DictionaryListResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryGetByIdResponse>
   */
  getById(id: number, options?: msRest.RequestOptionsBase): Promise<Models.DictionaryGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: number, callback: msRest.ServiceCallback<Models.DictionaryDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.DictionaryDto>): void;
  getById(id: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DictionaryDto>, callback?: msRest.ServiceCallback<Models.DictionaryDto>): Promise<Models.DictionaryGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.DictionaryGetByIdResponse>;
  }

  /**
   * @summary (external) API Key
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryListAllResponse>
   */
  listAll(options?: msRest.RequestOptionsBase): Promise<Models.DictionaryListAllResponse>;
  /**
   * @param callback The callback
   */
  listAll(callback: msRest.ServiceCallback<Models.DictionaryViewDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  listAll(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.DictionaryViewDto[]>): void;
  listAll(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DictionaryViewDto[]>, callback?: msRest.ServiceCallback<Models.DictionaryViewDto[]>): Promise<Models.DictionaryListAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      listAllOperationSpec,
      callback) as Promise<Models.DictionaryListAllResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param codeName
   * @param interventionPath Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH',
   * 'SUPERVISION_MONITORING', 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING',
   * 'LEGISLATION'
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryGetItemsByCodeNameAndProcessResponse>
   */
  getItemsByCodeNameAndProcess(codeName: string, interventionPath: Models.DictionaryInterventionPath, options?: Models.DictionaryGetItemsByCodeNameAndProcessOptionalParams): Promise<Models.DictionaryGetItemsByCodeNameAndProcessResponse>;
  /**
   * @param codeName
   * @param interventionPath Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH',
   * 'SUPERVISION_MONITORING', 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING',
   * 'LEGISLATION'
   * @param callback The callback
   */
  getItemsByCodeNameAndProcess(codeName: string, interventionPath: Models.DictionaryInterventionPath, callback: msRest.ServiceCallback<Models.DictionaryInfoDto>): void;
  /**
   * @param codeName
   * @param interventionPath Possible values include: 'PROHEALTH_ACTIVITIES', 'RESEARCH',
   * 'SUPERVISION_MONITORING', 'SUPERVISION_SANITARY', 'STAFF_EDUCATION', 'STAFF_MONITORING',
   * 'LEGISLATION'
   * @param options The optional parameters
   * @param callback The callback
   */
  getItemsByCodeNameAndProcess(codeName: string, interventionPath: Models.DictionaryInterventionPath, options: Models.DictionaryGetItemsByCodeNameAndProcessOptionalParams, callback: msRest.ServiceCallback<Models.DictionaryInfoDto>): void;
  getItemsByCodeNameAndProcess(codeName: string, interventionPath: Models.DictionaryInterventionPath, options?: Models.DictionaryGetItemsByCodeNameAndProcessOptionalParams | msRest.ServiceCallback<Models.DictionaryInfoDto>, callback?: msRest.ServiceCallback<Models.DictionaryInfoDto>): Promise<Models.DictionaryGetItemsByCodeNameAndProcessResponse> {
    return this.client.sendOperationRequest(
      {
        codeName,
        interventionPath,
        options
      },
      getItemsByCodeNameAndProcessOperationSpec,
      callback) as Promise<Models.DictionaryGetItemsByCodeNameAndProcessResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionarySearchResponse>
   */
  search(options?: Models.DictionarySearchOptionalParams): Promise<Models.DictionarySearchResponse>;
  /**
   * @param callback The callback
   */
  search(callback: msRest.ServiceCallback<Models.DictionaryValueWithPathViewDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  search(options: Models.DictionarySearchOptionalParams, callback: msRest.ServiceCallback<Models.DictionaryValueWithPathViewDtoPagedResult>): void;
  search(options?: Models.DictionarySearchOptionalParams | msRest.ServiceCallback<Models.DictionaryValueWithPathViewDtoPagedResult>, callback?: msRest.ServiceCallback<Models.DictionaryValueWithPathViewDtoPagedResult>): Promise<Models.DictionarySearchResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      searchOperationSpec,
      callback) as Promise<Models.DictionarySearchResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "dictionaries/Dictionary",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateDictionaryCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.Int32IIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "dictionaries/Dictionary",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateDictionaryCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const listOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/Dictionary",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.DictionaryViewDtoPagedResult
    },
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/Dictionary/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.DictionaryDto
    },
    default: {}
  },
  serializer
};

const listAllOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/Dictionary/all",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryViewDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getItemsByCodeNameAndProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/Dictionary/{codeName}/process/{interventionPath}",
  urlParameters: [
    Parameters.codeName0,
    Parameters.interventionPath0
  ],
  queryParameters: [
    Parameters.dateFrom0,
    Parameters.dateTo0,
    Parameters.parentId,
    Parameters.flatDictionary
  ],
  responses: {
    200: {
      bodyMapper: Mappers.DictionaryInfoDto
    },
    default: {}
  },
  serializer
};

const searchOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/Dictionary/search",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0,
    Parameters.dictionaryVersionId,
    Parameters.codeName1,
    Parameters.interventionPath1,
    Parameters.dateFrom1,
    Parameters.dateTo1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.DictionaryValueWithPathViewDtoPagedResult
    },
    default: {}
  },
  serializer
};
