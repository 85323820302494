import { FilterFilled } from '@ant-design/icons';
import FilterButton from '@components/shared/buttons/FilterButton';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { Button } from 'antd';
import clsx from 'clsx';
import { ProfiBazaInputFilter } from 'components/shared/paginatedProfiBazaTable/filters/ProfiBazaTableFilter';
import React from 'react';
import { OrbeonFormDefinition } from 'services/src/models';

import { ProfiBazaColumnProps } from '../../../components/shared/paginatedProfiBazaTable/GridHelper';

const columns = (
	clearAll: boolean,
	setClearAll: (value: boolean) => void,
	tableFilters: Record<keyof OrbeonFormDefinition, string[]> | undefined,
	tableSorters:
		| Record<
				keyof ProfiBazaAPIModels.OrbeonFormDefinition,
				'descend' | 'ascend' | null | undefined
		  >
		| undefined
) => {
	let columns: ProfiBazaColumnProps<OrbeonFormDefinition>[] = [
		{
			dataIndex: 'formName',
			key: 'formName',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Nazwa formularza
				</Button>
			),
			sorter: (
				a: ProfiBazaAPIModels.OrbeonFormDefinition,
				b: ProfiBazaAPIModels.OrbeonFormDefinition
			): number => a.formName?.localeCompare(b.formName ?? '') ?? 0,
			filterDropdown: (filterProps) => (
				<ProfiBazaInputFilter<ProfiBazaAPIModels.FormPartDto>
					columns={columns}
					column="formName"
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					filterProps={filterProps}
				/>
			),
			filteredValue: tableFilters?.formName || null,
			sortOrder: tableSorters?.formName || null,
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			onFilter: (value, record) => {
				return record.formName
					?.toLocaleLowerCase()
					.includes(value.toString().toLocaleLowerCase())!;
			}
		},
		{
			dataIndex: 'applicationName',
			key: 'applicationName',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Nazwa
				</Button>
			),
			sortOrder: tableSorters?.applicationName || null,
			sorter: (
				a: ProfiBazaAPIModels.OrbeonFormDefinition,
				b: ProfiBazaAPIModels.OrbeonFormDefinition
			): number =>
				a.applicationName?.localeCompare(b.applicationName ?? '') ?? 0,
			filterDropdown: (filterProps) => (
				<ProfiBazaInputFilter<ProfiBazaAPIModels.FormPartDto>
					columns={columns}
					column="applicationName"
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					filterProps={filterProps}
				/>
			),
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			filteredValue: tableFilters?.applicationName || null,
			onFilter: (value, record) => {
				return record.applicationName
					?.toLocaleLowerCase()
					.includes(value.toString().toLocaleLowerCase())!;
			},
		},
		{
			dataIndex: 'title',
			key: 'title',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Tytuł
				</Button>
			),
			sortOrder: tableSorters?.title || null,
			sorter: (
				a: ProfiBazaAPIModels.OrbeonFormDefinition,
				b: ProfiBazaAPIModels.OrbeonFormDefinition
			): number => a.title?.localeCompare(b.title ?? '') ?? 0,
			filterDropdown: (filterProps) => {
				return (
					<ProfiBazaInputFilter<ProfiBazaAPIModels.FormPartDto>
						columns={columns}
						column="title"
						clearAll={clearAll}
						setClearAll={() => setClearAll(false)}
						filterProps={filterProps}
					/>
				);
			},
			filteredValue: tableFilters?.title || null,
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			onFilter: (value, record) => {
				return record.title
					?.toLocaleLowerCase()
					.includes(value.toString().toLocaleLowerCase())!;
			},
		},
	];
	return columns;
};

export default columns;
