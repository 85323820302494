/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/documentMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Document. */
export class Document {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Document.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DocumentGetAotmitRecommendationFileListResponse>
   */
  getAotmitRecommendationFileList(options?: msRest.RequestOptionsBase): Promise<Models.DocumentGetAotmitRecommendationFileListResponse>;
  /**
   * @param callback The callback
   */
  getAotmitRecommendationFileList(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAotmitRecommendationFileList(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<any>): void;
  getAotmitRecommendationFileList(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DocumentGetAotmitRecommendationFileListResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getAotmitRecommendationFileListOperationSpec,
      callback) as Promise<Models.DocumentGetAotmitRecommendationFileListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DocumentAllResponse>
   */
  all(options?: Models.DocumentAllOptionalParams): Promise<Models.DocumentAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.DocumentAllOptionalParams, callback: msRest.ServiceCallback<any>): void;
  all(options?: Models.DocumentAllOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.DocumentAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.DocumentAllResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DocumentGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.DocumentGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.DocumentDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.DocumentDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DocumentDto>, callback?: msRest.ServiceCallback<Models.DocumentDto>): Promise<Models.DocumentGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.DocumentGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DocumentUploadVoivodeOpinionResponse>
   */
  uploadVoivodeOpinion(options?: Models.DocumentUploadVoivodeOpinionOptionalParams): Promise<Models.DocumentUploadVoivodeOpinionResponse>;
  /**
   * @param callback The callback
   */
  uploadVoivodeOpinion(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadVoivodeOpinion(options: Models.DocumentUploadVoivodeOpinionOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  uploadVoivodeOpinion(options?: Models.DocumentUploadVoivodeOpinionOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.DocumentUploadVoivodeOpinionResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      uploadVoivodeOpinionOperationSpec,
      callback) as Promise<Models.DocumentUploadVoivodeOpinionResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteVoivodeOpinion(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteVoivodeOpinion(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteVoivodeOpinion(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteVoivodeOpinion(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteVoivodeOpinionOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DocumentGetFileListResponse>
   */
  getFileList(options?: Models.DocumentGetFileListOptionalParams): Promise<Models.DocumentGetFileListResponse>;
  /**
   * @param callback The callback
   */
  getFileList(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFileList(options: Models.DocumentGetFileListOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getFileList(options?: Models.DocumentGetFileListOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.DocumentGetFileListResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getFileListOperationSpec,
      callback) as Promise<Models.DocumentGetFileListResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getAotmitRecommendationFileListOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "documentsRepository/Document/AotmitRecommendationFileList",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RecommendationIdNameDto"
            }
          }
        }
      }
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "documentsRepository/Document",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0,
    Parameters.filter0,
    Parameters.labels0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.DocumentListItemDtoPagedResult
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "documentsRepository/Document/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.DocumentDto
    },
    default: {}
  },
  serializer
};

const uploadVoivodeOpinionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "documentsRepository/Document/VoivodeOpinion",
  formDataParameters: [
    Parameters.file
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const deleteVoivodeOpinionOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "documentsRepository/Document/VoivodeOpinion/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getFileListOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "documentsRepository/Document/export/list",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.filter1,
    Parameters.labels1,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};
