import { mapTerritorialUnitToStageAreaValue } from '@components/shared/teryt/TerytAreaPicker/TeryAreaMappers';
import * as OpinionAotmitTypeMap from '@services/mappers/OpinionAOTMiTType';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import {
	ActivityDetailsDto,
	ActivityPopulationValueDto,
	AnnualNumberOfRecipientsDto,
	AreaValueDto,
	CheckDictionaryValueChangeVersionQuery,
	CoProducerDto,
	CoProducerRoleDto,
	CoProducerValueDto,
	CreateInterventionCommand,
	DictionaryInterventionPath,
	DictionaryValueChangeVersionDto,
	IkpDto,
	ImplementingCompanyDto,
	InterventionCoownerDto,
	InterventionPartnerDto,
	InterventionPath,
	IntervetionActivityDto,
	OpinionAOTMiTEnum,
	OpinionAOTMiTType,
	PublicHealthInterventionStatus,
	RizpDictionaryDto,
	RizpDictionaryValueDto,
	SponsoringCompanyValueDto,
	SubjectContactDto,
	SubjectIdNameDto,
} from 'services/src/models';

import { InterventionPathContextType } from '../context/InterventionPathContext';

export class InterventionBaseCommand {
	id?: string;
	plannedBudget?: number | undefined;
	realizationBudget?: number;
	plannedDateRange?: [Moment?, Moment?];
	realizationDate?: [Moment?, Moment?];
	createdAt?: Moment;
	modifiedAt?: Moment;
	codeName?: string;
	name?: string;
	aotmitNumber?: string;
	presidentAotmitNumber?: string;
	/**
	 * Possible values include: 'AOTMIT', 'PROGRAM', 'NOT_PROGRAM', 'OTHER'
	 */
	aotmitStatus?: string;
	linkToWizard?: string;
	opinionAOTMiT?: OpinionAOTMiTType;
	opinionAOTMiTText?: string;
	dateOfDecisionAOTMiT?: Moment;
	interventionPath?: InterventionPath;
	interventionTypeDictionaryValueId?: number;
	interventionTypeDictionaryOtherValue?: string;
	interventionTypeDictionaryCode?: string;

	/**
	 * Possible values include: 'Draft', 'Planned', 'Suspended', 'Completed', 'Active', 'Deleted'
	 */
	status?: PublicHealthInterventionStatus;
	subjectId?: string;
	subjectName?: string;
	coProducerSubjectRoles?: CoProducerRoleDto[];
	recommendationTypeDictionaryValueId?: number;
	recommendationTypeDictionaryOtherValue?: string;
	interventionAreaDictionaryValueId?: number;
	interventionAreaDictionaryOtherValue?: string;
	implementationPeriodDictionaryValueId?: number;
	implementationPeriodDictionaryOtherValue?: string;
	contacts?: SubjectContactDto[];
	legalBases?: RizpDictionaryValueDto[];
	areas?: AreaValueDto[];
	areaTerytCodes?: string[];
	interventionCoowners?: InterventionCoownerDto[];
	interventionCoownersId?: string[];
	interventionCoownersName?: string[];
	interventionPartners?: InterventionPartnerDto[];
	numberOfPersonAction?: number;
	numberOfRecipients?: number;
	patternId?: string;
	dataSourceDictionaryValues?: RizpDictionaryValueDto[];
	interventionEffectDictionaryValues?: RizpDictionaryValueDto[];
	interventionKindDictionaryValues?: RizpDictionaryValueDto[];

	educationImplementationModeDictionaryValueId?: number;
	educationImplementationModeDictionaryOtherValue?: string;
	ncnClassificationDictionaryValues?: RizpDictionaryValueDto[];
	gBoardClassificationDictionaryValues?: RizpDictionaryValueDto[];
	disciplineAreaClassificationDictionaryValues?: RizpDictionaryValueDto[];
	contractorsQty?: number;
	coProducers?: CoProducerValueDto[];
	tags?: string[];
	annualNumberOfRecipients?: AnnualNumberOfRecipientsDto[];

	constructor(model?: ProfiBazaAPIModels.InterventionDetailsDto) {
		this.id = model?.id;
		this.plannedBudget = model?.plannedBudget;
		this.realizationBudget = model?.realizationBudget;
		this.plannedDateRange = [
			model?.plannedDateFrom ? moment(model?.plannedDateFrom) : undefined,
			model?.plannedDateTo ? moment(model?.plannedDateTo) : undefined,
		];
		this.createdAt = model?.createdAt
			? moment(model?.createdAt)
			: undefined;
		this.modifiedAt = model?.modifiedAt
			? moment(model?.modifiedAt)
			: undefined;
		this.codeName = model?.codeName;
		this.realizationDate = [
			model?.realizationDateFrom
				? moment(model?.realizationDateFrom)
				: undefined,
			model?.realizationDateTo
				? moment(model?.realizationDateTo)
				: undefined,
		];
		this.name = model?.name;
		this.subjectId = model?.subjectId;
		this.subjectName = model?.subjectName;
		this.aotmitNumber = model?.aotmitNumber;
		this.presidentAotmitNumber = model?.presidentAotmitNumber;
		this.status = model?.status ?? PublicHealthInterventionStatus.DRAFT;
		this.aotmitStatus = model?.aotmitStatus;
		this.linkToWizard = model?.linkToWizard;
		this.opinionAOTMiT = model?.opinionAOTMiT;
		this.opinionAOTMiTText = OpinionAotmitTypeMap.map(this.opinionAOTMiT!);
		this.dateOfDecisionAOTMiT = model?.dateOfDecisionAOTMiT
			? moment(model?.dateOfDecisionAOTMiT)
			: undefined;
		this.status = model?.status;
		this.interventionPath = model?.interventionPath;
		this.interventionTypeDictionaryValueId =
			model?.interventionTypeDictionaryValueId;
		this.interventionTypeDictionaryCode =
			model?.interventionTypeDictionaryCode;
		this.interventionTypeDictionaryOtherValue =
			model?.interventionTypeDictionaryOtherValue;
		this.recommendationTypeDictionaryOtherValue =
			model?.recommendationTypeDictionaryOtherValue;
		this.recommendationTypeDictionaryValueId =
			model?.recommendationTypeDictionaryValueId;
		this.interventionAreaDictionaryOtherValue =
			model?.interventionAreaDictionaryOtherValue;
		this.interventionAreaDictionaryValueId =
			model?.interventionAreaDictionaryValueId;
		this.implementationPeriodDictionaryOtherValue =
			model?.implementationPeriodDictionaryOtherValue;
		this.implementationPeriodDictionaryValueId =
			model?.implementationPeriodDictionaryValueId;
		this.contacts = model?.contacts ? model.contacts : [];
		this.legalBases = model?.legalBases ?? [];
		this.areas = model?.areas ?? [];
		this.coProducers = model?.coProducers ?? [];
		this.areaTerytCodes = model?.areas?.map((x) => x.terytCode!);
		this.interventionCoowners = model?.interventionCoowners
			? model?.interventionCoowners
			: [];
		this.interventionCoownersId = model?.interventionCoowners?.map((x) => {
			return x.id!;
		});
		this.interventionCoownersName = model?.interventionCoowners?.map(
			(x) => {
				return x.name!;
			}
		);
		this.coProducers = model?.coProducers?.map((x) => {
			const producer: CoProducerValueDto = {
				id: x.id,
				subjectId: x.subjectId,
				subjectName: x.subjectName,
				roles: x.roles,
			};

			return producer;
		});

		this.interventionPartners = model?.interventionPartners
			? model?.interventionPartners
			: [];
		this.numberOfPersonAction = model?.numberOfPersonAction;
		this.numberOfRecipients = model?.numberOfRecipients;
		this.educationImplementationModeDictionaryOtherValue =
			model?.educationImplementationModeDictionaryOtherValue;
		this.educationImplementationModeDictionaryValueId =
			model?.educationImplementationModeDictionaryValueId;
		this.interventionEffectDictionaryValues =
			model?.interventionEffectDictionaryValues;
		this.interventionKindDictionaryValues =
			model?.interventionKindDictionaryValues;
		this.dataSourceDictionaryValues = model?.dataSourceDictionaryValues;
		this.educationImplementationModeDictionaryOtherValue =
			model?.educationImplementationModeDictionaryOtherValue;
		this.educationImplementationModeDictionaryValueId =
			model?.educationImplementationModeDictionaryValueId;
		this.interventionEffectDictionaryValues =
			model?.interventionEffectDictionaryValues;
		this.dataSourceDictionaryValues = model?.dataSourceDictionaryValues;
		this.ncnClassificationDictionaryValues =
			model?.ncnClassificationDictionaryValues;
		this.gBoardClassificationDictionaryValues =
			model?.gBaordClassificationDictionaryValues;
		this.disciplineAreaClassificationDictionaryValues =
			model?.disciplineAreaClassificationDictionaryValues;
		this.contractorsQty = model?.contractorsQty;

		const path = (this
			.interventionPath! as string) as DictionaryInterventionPath;

		if (path == DictionaryInterventionPath.RESEARCH) {
			this.plannedBudget = model?.plannedBudget;
		}
		this.tags = model?.tags ?? [];
		this.annualNumberOfRecipients = model?.annualNumberOfRecipients ?? [];
		this.patternId = model?.patternId;
	}

	static toInterventionFromPattern(
		model: InterventionBaseCommand
	): InterventionBaseCommand {
		let command: InterventionBaseCommand = {
			...model,
		};
		command.id = undefined;
		command.createdAt = undefined;
		command.subjectId = undefined;
		command.subjectName = undefined;
		command.codeName = undefined;
		command.patternId = model.id;
		return command;
	}

	static toCommand(
		model: InterventionBaseCommand
	): ProfiBazaAPIModels.InterventionDetailsDto {
		const command: ProfiBazaAPIModels.InterventionDetailsDto = {
			id: model.id,
			plannedBudget: model.plannedBudget,
			plannedDateFrom: model.plannedDateRange
				? model.plannedDateRange[0]?.utc(true).toDate()
				: undefined,
			plannedDateTo: model.plannedDateRange
				? model.plannedDateRange[1]?.utc(true).toDate()
				: undefined,
			createdAt: model.createdAt?.utc(true).toDate(),
			codeName: model.codeName,
			name: model.name,
			aotmitNumber: model.aotmitNumber,
			presidentAotmitNumber: model.presidentAotmitNumber,
			status: model.status,
			interventionAreaDictionaryValueId:
				model.interventionAreaDictionaryValueId,
			interventionAreaDictionaryOtherValue:
				model.interventionAreaDictionaryOtherValue,
			implementationPeriodDictionaryValueId:
				model.implementationPeriodDictionaryValueId,
			implementationPeriodDictionaryOtherValue:
				model.implementationPeriodDictionaryOtherValue,
			interventionCoowners: model.interventionCoowners,
			interventionPartners: model.interventionPartners,
			// activities: model.activities,
			interventionEffectDictionaryValues:
				model.interventionEffectDictionaryValues,
			educationImplementationModeDictionaryOtherValue:
				model.educationImplementationModeDictionaryOtherValue,
			educationImplementationModeDictionaryValueId:
				model.educationImplementationModeDictionaryValueId,
			dataSourceDictionaryValues: model.dataSourceDictionaryValues,
			interventionKindDictionaryValues:
				model.interventionKindDictionaryValues,
			gBaordClassificationDictionaryValues:
				model.gBoardClassificationDictionaryValues,
			contractorsQty: model.contractorsQty,
			ncnClassificationDictionaryValues:
				model.ncnClassificationDictionaryValues,
			disciplineAreaClassificationDictionaryValues:
				model.disciplineAreaClassificationDictionaryValues,
		};
		return command;
	}

	static toUpdateInterventionCommand(
		value: InterventionBaseCommand,
		id: any
	): ProfiBazaAPIModels.UpdateInterventionCommand {
		const command: ProfiBazaAPIModels.UpdateInterventionCommand = {
			id: id,
			implementationPeriodDictionaryValueId:
				value.implementationPeriodDictionaryValueId,
			implementationPeriodDictionaryOtherValue:
				value.implementationPeriodDictionaryOtherValue,
			interventionAreaDictionaryValueId:
				value.interventionAreaDictionaryValueId,
			interventionAreaDictionaryOtherValue:
				value.interventionAreaDictionaryOtherValue,
			name: value.name,
			plannedDateFrom: value.plannedDateRange?.[0]?.utc(true).toDate(),
			plannedDateTo: value.plannedDateRange?.[1]?.utc(true).toDate(),
			legalBases: value.legalBases,
			plannedBudget:
				((value.interventionPath! as string) as DictionaryInterventionPath) ==
					DictionaryInterventionPath.PROHEALTHACTIVITIES ||
				((value.interventionPath! as string) as DictionaryInterventionPath) ==
					DictionaryInterventionPath.RESEARCH
					? value.plannedBudget
					: undefined,
			contacts: value.contacts,
			areaTerytCodes: value.areaTerytCodes,
			interventionCoownerIds: value.interventionCoowners?.map(
				(x) => x.id!
			),
			interventionCoProducers: value.coProducers?.map((x) => {
				const producer: CoProducerDto = {
					id: x.id,
					subjectId: x.subjectId,
					roles: x.roles,
				};
				return producer;
			}),
			interventionPartners: value.interventionPartners,
			interventionEffectDictionaryValues:
				value.interventionEffectDictionaryValues,
			educationImplementationModeDictionaryOtherValue:
				value.educationImplementationModeDictionaryOtherValue,
			educationImplementationModeDictionaryValueId:
				value.educationImplementationModeDictionaryValueId,
			dataSourceDictionaryValues: value.dataSourceDictionaryValues,
			interventionKindDictionaryValues:
				value.interventionKindDictionaryValues,
			gBaordClassificationDictionaryValues:
				value.gBoardClassificationDictionaryValues,
			contractorsQty: value.contractorsQty,
			ncnClassificationDictionaryValues:
				value.ncnClassificationDictionaryValues,
			disciplineAreaClassificationDictionaryValues:
				value.disciplineAreaClassificationDictionaryValues,
			numberOfRecipients: value.numberOfRecipients,
			numberOfPersonAction: value.numberOfPersonAction,
			annualNumberOfRecipients: value.annualNumberOfRecipients,
			dateOfDecisionAOTMiT: value.dateOfDecisionAOTMiT
				?.utc(true)
				.toDate(),
			presidentAotmitNumber: value.presidentAotmitNumber,
			opinionAOTMiT: value.opinionAOTMiT,
		};

		return command;
	}

	static toCreateIntervention = (value: InterventionBaseCommand) => {
		const body: CreateInterventionCommand = {
			interventionPath: value.interventionPath,
			interventionTypeDictionaryValueId:
				value.interventionTypeDictionaryValueId,
			interventionTypeDictionaryOtherValue:
				value.interventionTypeDictionaryOtherValue,
			patternId: value.patternId,
		};
		return body;
	};

	static changeData(
		model: InterventionBaseCommand,
		key: string,
		value: any
	): InterventionBaseCommand {
		let command: InterventionBaseCommand = {
			id: model?.id,
			plannedBudget: model?.plannedBudget,
			plannedDateRange: model?.plannedDateRange,
			createdAt: model?.createdAt,
			codeName: model?.codeName,
			name: model?.name,
			subjectId: model?.subjectId,
			subjectName: model?.subjectName,
			aotmitNumber: model?.aotmitNumber,
			presidentAotmitNumber: model?.presidentAotmitNumber,
			status: model?.status,
			interventionTypeDictionaryValueId:
				model?.interventionTypeDictionaryValueId,
			interventionTypeDictionaryOtherValue:
				model?.interventionTypeDictionaryOtherValue,
			recommendationTypeDictionaryOtherValue:
				model?.recommendationTypeDictionaryOtherValue,
			recommendationTypeDictionaryValueId:
				model?.recommendationTypeDictionaryValueId,
			interventionAreaDictionaryOtherValue:
				model?.interventionAreaDictionaryOtherValue,
			interventionAreaDictionaryValueId:
				model?.interventionAreaDictionaryValueId,
			implementationPeriodDictionaryOtherValue:
				model?.implementationPeriodDictionaryOtherValue,
			implementationPeriodDictionaryValueId:
				model?.implementationPeriodDictionaryValueId,
			contacts: model?.contacts,
			legalBases: model?.legalBases,
			// activities: model?.activities,
			interventionEffectDictionaryValues:
				model?.interventionEffectDictionaryValues,
			educationImplementationModeDictionaryOtherValue:
				model?.educationImplementationModeDictionaryOtherValue,
			educationImplementationModeDictionaryValueId:
				model?.educationImplementationModeDictionaryValueId,
			dataSourceDictionaryValues: model?.dataSourceDictionaryValues,
			interventionKindDictionaryValues:
				model?.interventionKindDictionaryValues,
			tags: model?.tags,
		};
		if (key) {
			command = {
				...command,
				[key]: value ? value : undefined,
			};
		}
		return command;
	}
}

export class InterventionActivityCommand {
	id?: string;
	interventionId?: string;
	name?: string;
	activityNumber?: string;
	numberOfRecipients?: number;
	nzpTaskNumberDictionaryValues?: RizpDictionaryValueDto[];
	publicHealthTaskDictionaryValues?: RizpDictionaryValueDto[];
	prpzDictionaryValues?: RizpDictionaryValueDto[];
	activityCategoryDictionaryValueId?: number;
	activityCategoryDictionaryOtherValue?: string;
	isPlannedActivity?: boolean;
	activityPopulations?: ActivityPopulationValueDto[];
	chosenPopulationHealthsDictionaryValues?: RizpDictionaryValueDto[];
	careAreasDictionaryValues?: RizpDictionaryValueDto[];
	diseasesMortalityPrevalencesDictionaryValues?: RizpDictionaryValueDto[];
	implementingCompanies?: ImplementingCompanyDto[];
	sponsoringCompanies?: SponsoringCompanyValueDto[];
	comment?: string;
	topic?: string;
	description?: string;
	implementingCompaniesId?: string[];
	implementingCompaniesName?: string[];
	sponsorCompaniesId?: string[];
	sponsorCompaniesName?: string[];
	numberOfPersonAction?: number;
	realizationDate?: Moment[] | undefined;
	dateFrom?: Date | undefined;
	dateTo?: Date | undefined;
	updatedAt?: Moment | undefined;
	primaryActivityId?: string;
	activityPatternId?: string;
	coProducers?: ProfiBazaAPIModels.CoProducerValueDto[];
	formVersionId?: string;
	documentId?: string;

	publishedInIkp?: boolean;
	ikpId?: string;
	ikpRecrutationDate?: [Moment?, Moment?];
	ikpIncludeCriteria?: string;
	ikpExcludeCriteria?: string;
	ikpBenefits?: string;
	ikpSourceOfKnowledge?: string;
	ikpStateDictionaryValueId?: number;
	ikpStateDictionaryOtherValue?: string;

	constructor(model?: ActivityDetailsDto, interventionId?: string) {
		this.id = model?.id;
		this.interventionId = interventionId;
		this.name = model?.name;
		this.updatedAt = model?.updatedAt
			? moment(model?.updatedAt)
			: undefined;
		this.numberOfRecipients = model?.numberOfRecipients;
		this.nzpTaskNumberDictionaryValues =
			model?.nzpTaskNumberDictionaryValues;
		this.activityCategoryDictionaryValueId = model?.activityCategory?.id;
		this.activityCategoryDictionaryOtherValue =
			model?.activityCategory?.otherValue;
		this.activityPopulations = model?.activityPopulations
			? model.activityPopulations
			: [];
		this.chosenPopulationHealthsDictionaryValues = model?.chosenPopulationHealthsDictionaryValues
			? model.chosenPopulationHealthsDictionaryValues
			: [];
		this.careAreasDictionaryValues = model?.careAreasDictionaryValues
			? model.careAreasDictionaryValues
			: [];
		this.diseasesMortalityPrevalencesDictionaryValues = model?.diseasesMortalityPrevalencesDictionaryValues
			? model.diseasesMortalityPrevalencesDictionaryValues
			: [];
		this.implementingCompanies = model?.implementingCompanies
			? model.implementingCompanies
			: [];
		this.sponsoringCompanies = model?.sponsoringCompanies
			? model.sponsoringCompanies
			: [];
		this.comment = model?.comment;
		this.topic = model?.topic;
		this.implementingCompaniesId = model?.implementingCompanies?.map(
			(x) => {
				return x.subjectId!;
			}
		);
		this.implementingCompaniesName = model?.implementingCompanies?.map(
			(x) => {
				return x.subjectName!;
			}
		);
		this.sponsorCompaniesId = model?.sponsoringCompanies?.map((x) => {
			return x.subjectId!;
		});
		this.sponsorCompaniesName = model?.sponsoringCompanies?.map((x) => {
			return x.subjectName!;
		});
		this.numberOfPersonAction = model?.numberOfPersonAction;
		this.numberOfRecipients = model?.numberOfRecipients;

		this.description = model?.description;
		this.realizationDate =
			model?.dateFrom && model?.dateTo
				? [moment(model.dateFrom), moment(model.dateTo)]
				: undefined;
		this.activityPatternId = model?.activityPatternId;
		this.publicHealthTaskDictionaryValues =
			model?.publicHealthTaskDictionaryValues;
		this.prpzDictionaryValues = model?.prpzDictionaryValues;
		this.activityNumber = model?.activityNumber;
		this.formVersionId = model?.formVersionId;
		this.documentId = model?.documentId;

		this.publishedInIkp = model?.ikp && model?.ikp! ? true : false;
		this.ikpId = model?.ikp?.id;
		this.ikpRecrutationDate =
			model?.ikp?.recrutationDateFrom && model?.ikp?.recrutationDateTo
				? [
						moment(model?.ikp?.recrutationDateFrom),
						moment(model?.ikp?.recrutationDateTo),
				  ]
				: [undefined, undefined];

		this.ikpIncludeCriteria = model?.ikp?.includeCriteria;
		this.ikpExcludeCriteria = model?.ikp?.excludeCriteria;
		this.ikpBenefits = model?.ikp?.benefits;
		this.ikpSourceOfKnowledge = model?.ikp?.sourceOfKnowledge;
		this.ikpStateDictionaryValueId = model?.ikp?.ikpStateDictionaryValueId;
		this.ikpStateDictionaryOtherValue =
			model?.ikp?.ikpStateDictionaryOtherValue;
		this.primaryActivityId = model?.primaryActivityId;
	}

	static toCommand(
		model: InterventionActivityCommand,
		interventionPath?: DictionaryInterventionPath
	): IntervetionActivityDto {
		const command: IntervetionActivityDto = {
			id: model.id,
			activityPopulations:
				!interventionPath ||
				interventionPath ===
					DictionaryInterventionPath.PROHEALTHACTIVITIES ||
				interventionPath ===
					DictionaryInterventionPath.SUPERVISIONMONITORING ||
				interventionPath ===
					DictionaryInterventionPath.SUPERVISIONSANITARY ||
				interventionPath == DictionaryInterventionPath.STAFFMONITORING
					? mapActivityPopulationToValueDto(model.activityPopulations)
					: [],
			name: model.name,
			sponsoringCompanies: model.sponsoringCompanies ?? [],
			nzpTaskNumberDictionaryValues: model.nzpTaskNumberDictionaryValues,
			activityCategoryDictionaryValueId:
				model.activityCategoryDictionaryValueId,
			activityCategoryDictionaryOtherValue:
				model.activityCategoryDictionaryOtherValue,
			comment: model.comment,
			topic: model.topic,
			description: model.description,
			numberOfRecipients: model.numberOfRecipients,
			numberOfPersonAction: model.numberOfPersonAction,
			chosenPopulationHealthsDictionaryValues:
				model.chosenPopulationHealthsDictionaryValues,
			careAreasDictionaryValues: model.careAreasDictionaryValues,
			diseasesMortalityPrevalencesDictionaryValues:
				model.diseasesMortalityPrevalencesDictionaryValues,
			implementingCompanies: model.implementingCompanies ?? [],
			dateFrom: model.realizationDate
				? model.realizationDate[0]?.utc(true).toDate()
				: model.dateFrom,
			dateTo: model.realizationDate
				? moment(model.realizationDate[1]).utc(true).toDate()
				: model.dateTo,
			prpzDictionaryValues: model.prpzDictionaryValues,
			publicHealthTaskDictionaryValues:
				model.publicHealthTaskDictionaryValues,
			primaryActivityId: model.primaryActivityId,
			ikp: mapIkpToDto(model),
		};

		return command;
	}
}

export const returnMergedSubjectListFromActivities = (
	activity: ActivityDetailsDto,
	response: ProfiBazaAPIModels.SubjectIsMergedSubjectResponse,
	x: string
): Array<ActivitySubjectMergedDto> => {
	let mapActivity: Array<ActivitySubjectMergedDto> = [];
	let indexIC = activity?.implementingCompanies?.findIndex(
		(y) => y.subjectId == x
	);
	if (Number(indexIC) > -1) {
		mapActivity.push(
			new ActivitySubjectMergedDto(
				response[x],
				'implementingCompanies',
				indexIC!,
				activity.id!
			)
		);
	}
	let indexSC = activity.sponsoringCompanies?.findIndex(
		(y) => y.subjectId == x
	);
	if (Number(indexSC) > -1) {
		mapActivity.push(
			new ActivitySubjectMergedDto(
				response[x],
				'sponsoringCompanies',
				indexSC!,
				activity.id!
			)
		);
	}
	return mapActivity;
};

export class ActivitySubjectMergedDto {
	activityId: string;
	subject: SubjectIdNameDto;
	type: 'implementingCompanies' | 'sponsoringCompanies' | 'coProducers';
	index: number;
	constructor(
		subject: SubjectIdNameDto,
		type: 'implementingCompanies' | 'sponsoringCompanies' | 'coProducers',
		index: number,
		activityId: string
	) {
		this.activityId = activityId;
		this.subject = subject;
		this.index = index;
		this.type = type;
	}
}

export class InterventionDictionaries {
	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	interventionPath: DictionaryInterventionPath | undefined;
	dictionaryValueIds?: number[];
	constructor(model: InterventionBaseCommand) {
		this.interventionPath = (model.interventionPath as string) as DictionaryInterventionPath;

		this.dateFrom =
			model.plannedDateRange && model.plannedDateRange[0]
				? model.plannedDateRange[0].utc(true).toDate()
				: undefined;
		this.dateTo =
			model.plannedDateRange && model.plannedDateRange[1]
				? model.plannedDateRange[1].utc(true).toDate()
				: undefined;

		// this.dateFrom = model.plannedDateRange?.[0]?.toDate();
		// this.dateTo = model.plannedDateRange?.[1]?.toDate();

		const dictionaries = [
			model.interventionAreaDictionaryValueId,
			model.interventionTypeDictionaryValueId,
			model.recommendationTypeDictionaryValueId,
			model.implementationPeriodDictionaryValueId,
			model.educationImplementationModeDictionaryValueId,
			...model.legalBases?.map((x) => x.id!)!,
			...[
				...model.dataSourceDictionaryValues!,
				...model.gBoardClassificationDictionaryValues!,
				...model.ncnClassificationDictionaryValues!,
				...model.interventionEffectDictionaryValues!,
				...model.interventionKindDictionaryValues!,
				...model.disciplineAreaClassificationDictionaryValues!,
			].map((x) => x.id),
		];

		this.dictionaryValueIds = dictionaries.filter((x) => x) as number[];
	}

	static toCommand(
		model: InterventionDictionaries
	): CheckDictionaryValueChangeVersionQuery {
		let obj: CheckDictionaryValueChangeVersionQuery = {
			interventionPaths: model.interventionPath,
			dictionaryValueIds: model.dictionaryValueIds,
			dateFrom: model.dateFrom,
			dateTo: model.dateTo,
		};
		return obj;
	}

	static updateDictionaryValues(
		intervention: InterventionBaseCommand,
		value: DictionaryValueChangeVersionDto[]
	) {
		let dict = value.find(
			(x) =>
				x.oldDictionaryValueId ===
				intervention.interventionAreaDictionaryValueId
		);
		if (dict) {
			intervention.interventionAreaDictionaryValueId =
				dict.newDictionaryValue?.id;
		}

		dict = value.find(
			(x) =>
				x.oldDictionaryValueId ===
				intervention.interventionTypeDictionaryValueId
		);
		if (dict) {
			intervention.interventionTypeDictionaryValueId =
				dict.newDictionaryValue?.id;
		}

		dict = value.find(
			(x) =>
				x.oldDictionaryValueId ===
				intervention.recommendationTypeDictionaryValueId
		);
		if (dict) {
			intervention.recommendationTypeDictionaryValueId =
				dict.newDictionaryValue?.id;
		}

		if (intervention.patternId == null) {
			dict = value.find(
				(x) =>
					x.oldDictionaryValueId ===
					intervention.implementationPeriodDictionaryValueId
			);
			if (dict) {
				intervention.implementationPeriodDictionaryValueId =
					dict.newDictionaryValue?.id;
			}
		}

		dict = value.find(
			(x) =>
				x.oldDictionaryValueId ===
				intervention.educationImplementationModeDictionaryValueId
		);
		if (dict) {
			intervention.educationImplementationModeDictionaryValueId =
				dict.newDictionaryValue?.id;
		}

		const mapDictionaries = (
			items: RizpDictionaryValueDto[] | undefined
		): RizpDictionaryValueDto[] | undefined =>
			items
				?.map((dict): RizpDictionaryValueDto | undefined => {
					const item = value.find(
						(x) => x.oldDictionaryValueId === dict.id
					);
					if (item) {
						return item.newDictionaryValue
							? {
									id: item.newDictionaryValue.id,
									code: item.newDictionaryValue.code,
									value: item.newDictionaryValue.value,
									codeName: item.newDictionaryValue.codeName,
									breadcrumb:
										item.newDictionaryValue.breadcrumb,
									otherValue: item.newDictionaryValue
										.canAddTextValue
										? dict.otherValue
										: undefined,
							  }
							: undefined;
					}
					return dict;
				})
				.filter((x) => x !== undefined) as RizpDictionaryValueDto[];

		intervention.dataSourceDictionaryValues = mapDictionaries(
			intervention.dataSourceDictionaryValues
		);

		intervention.gBoardClassificationDictionaryValues = mapDictionaries(
			intervention.gBoardClassificationDictionaryValues
		);
		intervention.ncnClassificationDictionaryValues = mapDictionaries(
			intervention.ncnClassificationDictionaryValues
		);
		intervention.interventionEffectDictionaryValues = mapDictionaries(
			intervention.interventionEffectDictionaryValues
		);
		intervention.interventionKindDictionaryValues = mapDictionaries(
			intervention.interventionKindDictionaryValues
		);
		intervention.disciplineAreaClassificationDictionaryValues = mapDictionaries(
			intervention.disciplineAreaClassificationDictionaryValues
		);

		intervention.legalBases = mapDictionaries(intervention.legalBases);
	}
}

export class ChangedVersionDictionaryDto {
	oldId: number | number[];
	newId: number | number[];
	newValue?: string | string[];
	constructor(
		oldId: number | number[],
		newId: number | number[],
		newValue?: string | string[]
	) {
		this.oldId = oldId;
		this.newId = newId ? newId : 0;
		this.newValue = newValue;
	}
}

const mapActivityPopulationToValueDto = (
	source: ProfiBazaAPIModels.ActivityPopulationValueDto[] | undefined
): ProfiBazaAPIModels.ActivityPopulationDto[] | undefined =>
	source?.map(
		(item) =>
			({
				id: item?.id,
				genderDictionaryValueId: item?.genderDictionaryValueId,
				genderDictionaryOtherValue: item?.genderDictionaryOtherValue,
				minAge: item?.minAge,
				maxAge: item?.maxAge ? Number(item?.maxAge) : undefined,
				ageUnit: item?.ageUnit,
				totalRecipients: item?.totalRecipients
					? Number(item?.totalRecipients)
					: undefined,
				targetPopulationDictionaryValueId:
					item?.targetPopulationDictionaryValueId,
				targetPopulationDictionaryOtherValue:
					item?.targetPopulationDictionaryOtherValue,
				interventionLocations: item?.interventionLocations,
				healthConditions: item?.healthConditions,
				socialExclusions: item?.socialExclusions,
				otherSpecialFeatures: item?.otherSpecialFeatures,
			} as ProfiBazaAPIModels.ActivityPopulationDto)
	);

const mapIkpToDto = (
	model: InterventionActivityCommand | undefined
): IkpDto | undefined => {
	if (!model?.publishedInIkp) return undefined;

	let dto = impl<IkpDto>({
		id: model?.ikpId,
		recrutationDateFrom: model?.ikpRecrutationDate![0]?.utc(true).toDate(),
		recrutationDateTo: model?.ikpRecrutationDate![1]?.utc(true).toDate(),
		includeCriteria: model?.ikpIncludeCriteria,
		excludeCriteria: model?.ikpExcludeCriteria,
		benefits: model?.ikpBenefits,
		sourceOfKnowledge: model?.ikpSourceOfKnowledge,
		ikpStateDictionaryValueId: model?.ikpStateDictionaryValueId,
		ikpStateDictionaryOtherValue: model?.ikpStateDictionaryOtherValue,
	});

	return dto;
};

export function impl<I>(i: I) {
	return i;
}
