import { Skeleton } from 'antd';
import React from 'react';

const InterventionSkeleton = () => {
	return (
		<>
			<Skeleton active round title paragraph={{ rows: 8 }} />
			<div>
				<Skeleton active round title paragraph={{ rows: 0 }} />
				<Skeleton.Input active size="large" />
				<Skeleton.Input active size="large" />
			</div>
			<div>
				<Skeleton active round title paragraph={{ rows: 0 }} />
				<Skeleton.Input active size="large" />
			</div>
		</>
	);
};

export default InterventionSkeleton;
