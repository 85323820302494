import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ajaxByUser } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { Button, Col, Row, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import React, { useState } from 'react';
import { DictionaryDto } from 'services/src/models';
import * as Yup from 'yup';

import { FFieldLabel, FInput, FTextArea } from '../../../forms/FormikFormItems';

interface IProps {
	dictionary: ProfiBazaAPIModels.DictionaryDto | undefined;
	setDescription: (newDescription: string) => void;
}

const DictionaryDescriptionModal: React.FC<IProps> = (props) => {
	const { dictionary, setDescription } = props;

	const [visible, setVisible] = useState<boolean>(false);

	const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setVisible(false);
	};

	return (
		<>
			<Tooltip title={dictionary?.description ? 'Zmodyfikuj' : 'Dodaj'}>
				<Button
					shape="circle"
					size="small"
					type="default"
					aria-label={
						dictionary?.description
							? 'Zmodyfikuj opis'
							: 'Dodaj opis'
					}
					onClick={() => setVisible(true)}
					icon={
						dictionary?.description ? (
							<EditOutlined />
						) : (
							<PlusOutlined />
						)
					}
				/>
			</Tooltip>

			<Formik
				validateOnChange={false}
				validateOnBlur={true}
				initialValues={dictionary ?? { description: undefined }}
				enableReinitialize
				validationSchema={Yup.object().shape({
					description: Yup.string()
						.nullable(true)
						.required('Opis jest wymagany')
						.max(1000, 'Opis może mieć maksymalnie 1000 znaków'),
				})}
				onSubmit={(
					values: DictionaryDto,
					actions: FormikActions<
						ProfiBazaAPIModels.UpdateDictionaryCommand
					>
				) => {
					const data = {
						...dictionary,
						description: values.description,
					};

					ajaxByUser(
						'Zaktualizowano opis słownika',
						() =>
							getProfiBazaApiClient().then((api) =>
								api.dictionary.update({
									body: data,
								})
							),
						() => {
							setDescription(values.description ?? '');
							setVisible(false);
							actions.resetForm();
						},
						(errors) => {
							actions.setErrors(errors);
						}
					);
				}}
				render={(
					props: FormikProps<
						ProfiBazaAPIModels.UpdateDictionaryCommand
					>
				) => (
					<Modal
						title={`${
							dictionary?.description ? 'Zmodyfikuj' : 'Utwórz'
						} opis`}
						visible={visible}
						onOk={(e) => {
							if (props.isValid) {
								props.submitForm();
							}
						}}
						cancelText="Anuluj"
						centered
						onCancel={handleCancel}
						okText="Zapisz"
					>
						<Row>
							<Col span={24}>
								<FFieldLabel label="Opis" />
								<Field
									component={FTextArea}
									rows={4}
									placeholder="Opis"
									name="description"
									autoFocus={true}
								/>
							</Col>
						</Row>
					</Modal>
				)}
			/>
		</>
	);
};

export default DictionaryDescriptionModal;
