import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import moment, { Moment } from 'moment';
import { DictionaryInterventionPath } from 'services/src/models';

export class UpdateDictionaryValueDto {
	id?: number;
	code?: string;
	value?: string;
	codeName?: string;
	canAddTextValue?: boolean;
	canBeSelected?: boolean;
	validFrom?: Moment;
	validTo?: Moment;
	interventionPaths?: DictionaryInterventionPath[];

	constructor(model: ProfiBazaAPIModels.DictionaryValueDto) {
		this.id = model.id;
		this.code = model.code;
		this.codeName = model.codeName;
		this.value = model.value;
		this.canAddTextValue = model.canAddTextValue;
		this.canBeSelected = model.canBeSelected;
		this.validFrom = model.validFrom ? moment(model.validFrom) : undefined;
		this.validTo = model.validTo ? moment(model.validTo) : undefined;
		this.interventionPaths = model.interventionPaths;
	}

	static toCommand(
		model: UpdateDictionaryValueDto
	): ProfiBazaAPIModels.UpdateDictionaryValueCommand {
		const command: ProfiBazaAPIModels.UpdateDictionaryValueCommand = {
			id: model.id,
			code: model.code,
			codeName: model.codeName,
			value: model.value,
			canAddTextValue: model.canAddTextValue,
			canBeSelected: model.canBeSelected,
			interventionPaths: model.interventionPaths,
		};
		return command;
	}
}
