import produce from 'immer';
import { Dispatch, SetStateAction, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { uniqueeNotifications } from '../SidebarUtils';

type UnreadNotificationsReturnType = {
	totalUnread: number;
	setTotalUnread: Dispatch<SetStateAction<number>>;
	loading: boolean;
	fetchData: (page?: number) => Promise<void>;
	handleInfiniteOnLoad: (page: number) => void;
	hasNextPage: boolean;
	data: ProfiBazaAPIModels.UserNotificationDTO[];
	setData: Dispatch<SetStateAction<ProfiBazaAPIModels.UserNotificationDTO[]>>;
	markAsRead: (notificationId: string) => Promise<void>;
	deactivateSameKind: (
		notificationId: string,
		kind: ProfiBazaAPIModels.OccurrenceDomainType,
		callback: () => void
	) => Promise<void>;
};

const useUnreadNotifications = (
	signal: AbortSignal
): UnreadNotificationsReturnType => {
	const [totalUnread, setTotalUnread] = useState<number>(0);

	const [loading, setLoading] = useState<boolean>(true);
	const [hasNextPage, setHasNextPage] = useState<boolean>(false);
	const [data, setData] = useState<ProfiBazaAPIModels.UserNotificationDTO[]>(
		[]
	);

	const fetchData = async (page: number = 1) => {
		setLoading(true);
		const client = await getProfiBazaApiClient();
		await client.userNotification
			.getUnreadUserNotifications({
				page: page,
				abortSignal: signal,
			})
			.then((response) => {
				setLoading(false);
				const newData = response.results ?? [];
				setData((prevData) =>
					uniqueeNotifications(prevData.concat(newData))
				);
				setTotalUnread(response.rowCount ?? 0);
				setHasNextPage(response.hasNextPage ?? false);
			});
	};

	const handleInfiniteOnLoad = (page: number) => {
		if (!hasNextPage) {
			return;
		}

		fetchData(page);
	};

	const markAsRead = async (notificationId: string) => {
		setLoading(true);
		const client = await getProfiBazaApiClient();
		await client.userNotification
			.markAsRead({
				body: {
					id: notificationId,
				},
				abortSignal: signal,
			})
			.then((response) => {
				setLoading(false);
			});
	};

	const deactivateSameKind = async (
		notificationId: string,
		kind: ProfiBazaAPIModels.OccurrenceDomainType,
		callback: () => void
	) => {
		setLoading(true);
		const client = await getProfiBazaApiClient();
		await client.userNotification
			.toggleActiveOfSameKind({
				body: {
					userNotificationId: notificationId,
				},
				abortSignal: signal,
			})
			.then((response) => {
				setLoading(false);
				callback();
				const updatedData = produce(data, (draft) => {
					draft.forEach((notification) => {
						if (notification.occurrenceDomainType === kind) {
							notification.hasActiveChannel = false;
						}
					});
				});
			});
	};

	return {
		totalUnread,
		setTotalUnread,
		loading,
		fetchData,
		handleInfiniteOnLoad,
		hasNextPage,
		data,
		setData,
		markAsRead,
		deactivateSameKind,
	};
};

export default useUnreadNotifications;
