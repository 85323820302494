import { Modal, message } from 'antd';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { showErrorModal } from 'layout/Modals';

export const IsRCFileSizeValid = (options: RcCustomRequestOptions) => {
	
	if (
		options.file.size >
		// @ts-ignore
		(window._env_.REACT_APP_FILE_MAX_UPLOAD_SIZE ?? 52428800)
	) {
		//hack
		setTimeout(() => {
			message.error(
				`Plik ${options.file.name} jest zbyt duży i nie może zostać przesłany.`
			);

			if (options.onError) {
				options.onError(new Error());
			}
		}, 0);
		return false;
	}
	return true;
};

export const IsFileSizeValid = (file: File) => {
	// @ts-ignore
	if (file.size > (window._env_.REACT_APP_FILE_MAX_UPLOAD_SIZE ?? 52428800)) {
		Modal.error({
			centered: true,
			title: `Plik ${file.name} jest zbyt duży i nie może zostać przesłany, maksymalna wielkość pliku to 50MB.`,
		});
		return false;
	}
	return true;
};

export const IsFileExtensionValid = (
	options: RcCustomRequestOptions,
	allowedExtensions: string[]
) => {
	if (!allowedExtensions.includes(options.file.type)) {
		showErrorModal(
			`Plik nie może zostać załadowany ze względu na nieprawidłowe rozszerzenie.`
		);
		return false;
	}
	return true;
};
