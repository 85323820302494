import { promiseToServiceCallback } from '@azure/ms-rest-js';
import DictionaryPicker from '@components/dictionary/picker/DictionaryPicker';
import { IsolatedContainer } from '@components/shared/IsolatedContainer';
import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { ajaxCatch } from '@helper/api';
import { DictionariesConstants } from '@services/DictionariesConstants';
import * as InterventionPathMap from '@services/mappers/InterventionPath';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import {
    DictionaryInterventionPath,
    InterventionDetailsDto,
    InterventionPath,
    PatternDigestDto,
} from '@services/src/models';
import { Alert, Col, Radio, Row, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Field, Formik, FormikProps } from 'formik';
import { FCheckbox, FFieldLabel, FRangePicker, FSelect } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { AddInterventionModalForm } from '../model/AddInterventionModalForm';
import {
    InterventionToDictionaryPathMapper,
    InterventionToPatternTypeMapper,
} from '../model/map/InterventionMappers';
import { InterventionValidationSchema } from '../validators/AddInterventionModalValidation';

const { Option } = Select;

interface IProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    record?: InterventionDetailsDto;
    isCloning?: boolean;
    create: (values: AddInterventionModalForm) => void;
}

const AddInterventionModal: React.FC<IProps> = (props) => {
    const [interventionPath, setInterventionPath] = useState<
        InterventionPath | undefined
    >();

    const [patternFilteredList, setPatternsFiltered] = useState<
        PatternDigestDto[]
    >();
    const [patternList, setPatterns] = useState<PatternDigestDto[]>();
    const [interventionTypeCodeName, setInterventionTypeCodeName] = useState<
        string
    >();
    const [isPattern, setPatternFlag] = useState<boolean>(false);

    const selectRef = useRef<HTMLSelectElement>(null);

    let showWarning: boolean = false;

    useEffect(() => {
        if (props.visible) {
            ajaxCatch(() =>
                getProfiBazaApiClient().then((api) => {
                    return api.pattern
                        .all()
                        .then((result: PatternDigestDto[]) => {
                            setPatterns(result);
                            setPatternsFiltered(result);
                        });
                })
            );
            if (props.isCloning) {
                setInterventionPath(props.record?.interventionPath);
            }
        }
    }, [props.visible]);

    return (
        <Formik
            initialValues={
                props.isCloning
                    ? {
                        interventionPath: props.record?.interventionPath,
                        interventionTypeDictionaryValueId: props.record?.interventionTypeDictionaryValueId,
                        interventionTypeDictionaryOtherValue: props.record?.interventionTypeDictionaryOtherValue,
                        plannedDateFrom: props.record?.plannedDateFrom,
                        plannedDateTo: props.record?.plannedDateTo,
                    }
                    : {}
            }
            validateOnChange={false}
            validationSchema={InterventionValidationSchema(
                interventionTypeCodeName!,
                interventionPath!,
                isPattern,
                props.isCloning
            )}
            isInitialValid
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                props.create(values);
            }}
            render={(formik: FormikProps<AddInterventionModalForm>) => (
                <IsolatedContainer>
                    <Modal
                        title={
                            props.isCloning
                                ? 'Klonowanie interwencji'
                                : 'Tworzenie interwencji'
                        }
                        visible={props.visible}
                        okButtonProps={{ disabled: formik.isSubmitting }}
                        onOk={(e) => {
                            formik.submitForm();
                        }}
                        onCancel={() => {
                            props.setVisible(false);
                            formik.resetForm();
                            setInterventionTypeCodeName(undefined);
                            setInterventionPath(undefined);
                            setPatternFlag(false);
                        }}
                        cancelText="Anuluj"
                        centered
                        okText="Zatwierdź"
                        destroyOnClose
                    >
                        <section>
                            <CenteredRow>
                                <Col md={24}>
                                    <FFieldLabel label="Ścieżka interwencji" />
                                    <LabelHelpTooltip
                                        field="interventionPath"
                                        defaultValue="Typ programu zdrowotnego"
                                    />
                                    <Field
                                        label="Typ programu zdrowotnego"
                                        component={FSelect}
                                        ref={selectRef}
                                        name="interventionPath"
                                        id="interventionPath"
                                        aria-owns="interventionPath"
                                        aria-controls="interventionPath"
                                        aria-activedescendant="interventionPath"
                                        disabled={props.isCloning}
                                        onSelect={(
                                            selected: InterventionPath
                                        ) => {
                                            setInterventionPath(selected);
                                            formik.setFieldValue(
                                                'interventionTypeDictionaryValueId',
                                                undefined
                                            );
                                        }}
                                    >
                                        {Array.from(
                                            InterventionPathMap.mappings.entries()
                                        ).map((x, i) => (
                                            <Option key={i} value={x[0]}>
                                                {x[1]}
                                            </Option>
                                        ))}
                                    </Field>
                                </Col>
                            </CenteredRow>
                            {interventionPath ===
                                InterventionPath.PROHEALTHACTIVITIES && (
                                    <CenteredRow>
                                        <Col md={24}>
                                            <DictionaryPicker
                                                key={interventionPath}
                                                label="Typ interwencji"
                                                helpField="interventionType"
                                                multiple={false}
                                                codeName="TYP_INTERWENCJI"
                                                name="interventionTypeDictionaryValueId"
                                                valueId="interventionTypeDictionaryValueId"
                                                otherValue="interventionTypeDictionaryOtherValue"
                                                checkChanges={(
                                                    key: any,
                                                    value: any,
                                                    parent: any
                                                ) => {
                                                    setInterventionTypeCodeName(
                                                        value.codeName
                                                    );
                                                    setPatternsFiltered(
                                                        patternList?.filter(
                                                            (x) =>
                                                                x.patternType ==
                                                                InterventionToPatternTypeMapper.get(
                                                                    value.codeName
                                                                )
                                                        )
                                                    );
                                                    formik.setFieldValue(
                                                        'patternId',
                                                        undefined
                                                    );
                                                    setPatternFlag(
                                                        value.codeName ===
                                                        DictionariesConstants
                                                            .InterventionType
                                                            .AotmitRecomendation
                                                    );
                                                }}
                                                interventionPath={
                                                    InterventionToDictionaryPathMapper.get(
                                                        interventionPath!
                                                    ) ??
                                                    DictionaryInterventionPath.PROHEALTHACTIVITIES
                                                }
                                                disabledCodeName={
                                                    props.isCloning
                                                        ? [
                                                            DictionariesConstants
                                                                .InterventionType
                                                                .AotmitRecomendation,
                                                        ]
                                                        : []
                                                }
                                            />
                                        </Col>
                                    </CenteredRow>
                                )}

                            {interventionPath ===
                                InterventionPath.PROHEALTHACTIVITIES && (
                                    <>
                                        <CenteredRow>
                                            {(interventionTypeCodeName ===
                                                DictionariesConstants
                                                    .InterventionType.Program ||
                                                interventionTypeCodeName ===
                                                DictionariesConstants
                                                    .InterventionType
                                                    .NotProgram) &&
                                                !props.isCloning && (
                                                    <Radio.Group
                                                        className="theme-radio-group"
                                                        onChange={(e) => {
                                                            setPatternFlag(
                                                                e.target.value
                                                            );
                                                            formik.setFieldValue(
                                                                'patternId',
                                                                undefined
                                                            );
                                                        }}
                                                        defaultValue={isPattern}
                                                        value={isPattern}
                                                        style={{
                                                            margin: '10px',
                                                        }}
                                                    >
                                                        <Radio value={false}>
                                                            bez wzorca
                                                        </Radio>
                                                        <Radio value={true}>
                                                            na podstawie wzorca
                                                        </Radio>
                                                    </Radio.Group>
                                                )}
                                            {isPattern && !props.isCloning && (
                                                <Col md={24}>
                                                    <FFieldLabel
                                                        label={
                                                            interventionTypeCodeName ===
                                                                DictionariesConstants
                                                                    .InterventionType
                                                                    .AotmitRecomendation
                                                                ? 'Tytuł rekomendacji'
                                                                : 'Tytuł wzorca'
                                                        }
                                                    />
                                                    <Field
                                                        label="wzorzec"
                                                        component={FSelect}
                                                        name="patternId"
                                                        children={patternFilteredList?.map(
                                                            (
                                                                p: PatternDigestDto
                                                            ) => (
                                                                <Select.Option
                                                                    key={
                                                                        p.id!
                                                                    }
                                                                    value={p.id!}
                                                                >
                                                                    {p.patternName}
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    />
                                                </Col>
                                            )}
                                        </CenteredRow>
                                    </>
                                )}

                            {interventionPath ===
                                InterventionPath.PROHEALTHACTIVITIES && props.isCloning && (
                                    <>
                                        <CenteredRow>
                                            <Col md={24}>

                                                <FFieldLabel label="Data planowana" for="plannedDateRange" />
                                                <LabelHelpTooltip
                                                    field="plannedDateRange"
                                                    defaultValue="Data planowana"
                                                />
                                                <Field
                                                    component={FRangePicker}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    inputReadOnly={
                                                        false
                                                    }
                                                    placeholder={[
                                                        'Data początkowa planowana',
                                                        'Data końcowa planowana',
                                                    ]}
                                                    name="plannedDateRange"
                                                    changeData={(
                                                        value: [Moment | undefined, Moment | undefined]
                                                    ) => {
                                                        if (value) {
                                                            formik.setFieldValue(
                                                                'plannedDateFrom',
                                                                value[0]?.utc(true).toDate());

                                                            formik.setFieldValue(
                                                                'plannedDateTo',
                                                                value[1]?.utc(true).toDate());
                                                        }
                                                        showWarning = !!value;
                                                    }}
                                                />
                                            </Col>
                                        </CenteredRow>
                                    </>
                                )}
                            {showWarning && (
                                <CenteredRow>
                                    <Row>
                                        <Col md={24}>
                                            <Alert
                                                message='Wprowadzone powyżej daty zastąpią wpisane w interwencji "Datę planowaną" oraz "Datę realizacji" w działaniach.'
                                                type="warning"
                                            />
                                        </Col>
                                    </Row>
                                </CenteredRow>
                            )}
                            {interventionTypeCodeName ===
                                DictionariesConstants.InterventionType
                                    .AotmitRecomendation &&
                                !props.isCloning && (
                                    <>
                                        <Row>
                                            <Field
                                                component={FCheckbox}
                                                name="confirmation"
                                            ></Field>
                                            <FFieldLabel
                                                for="confirmation"
                                                label="* Oświadczam realizację interwencji zgodnie z wybraną rekomendacją"
                                            />
                                        </Row>
                                    </>
                                )}
                            {interventionTypeCodeName ===
                                DictionariesConstants.InterventionType
                                    .AotmitReported &&
                                !props.isCloning && (
                                    <>
                                        <Row>
                                            <Field
                                                component={FCheckbox}
                                                name="confirmation"
                                            ></Field>
                                            <FFieldLabel
                                                for="confirmation"
                                                label="* Oświadczam realizację interwencji na podstawie pozytywnej lub warunkowo-pozytywnej opinii AOTMiT po uwzględnieniu uwag AOTMiT"
                                            />
                                        </Row>
                                    </>
                                )}
                        </section>
                    </Modal>
                </IsolatedContainer>
            )}
        ></Formik>
    );
};

export default AddInterventionModal;
