import './NotepadHelpTooltip.less';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { renderShortcut } from '@helper/renderHelper';
import React from 'react';
import { Button } from 'reakit/Button';
import { Tooltip, TooltipReference, useTooltipState } from 'reakit/Tooltip';

const NotepadHelpTooltip = () => {
	const tooltip = useTooltipState({ placement: 'right' });

	const title = (
		<div
			className="tooltip"
			aria-label="Aby przenieść focus na menu kontekstowe notatnika, należy ustawić
		focus na polu tekstowym i wcisnąć skrót ALT + F10"
		>
			Aby przenieść focus na menu kontekstowe notatnika, należy ustawić
			focus na polu tekstowym i wcisnąć skrót:{' '}
			{renderShortcut('ALT', 'F10')}
		</div>
	);

	return (
		<>
			<TooltipReference
				{...tooltip}
				as={Button}
				className="tooltipButton"
			>
				<QuestionCircleOutlined
					style={{ fontSize: '16px' }}
					aria-hidden={true}
					role="tooltip"
					tabIndex={1}
				/>
			</TooltipReference>
			<Tooltip {...tooltip}>{title}</Tooltip>
		</>
	);
};

export default NotepadHelpTooltip;
