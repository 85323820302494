import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import formatHelpers from 'helper/formatHelpers';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const columns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.DocumentListItemDto
>[] = [
	{
		dataIndex: 'fileName',
		key: 'fileName',
		title: 'Nazwa',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Podmiot',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'voivodeship',
		key: 'voivodeship',
		title: 'Województwo',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'dateFrom',
		key: 'dateFrom',
		title: 'Data od',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		dataIndex: 'dateTo',
		key: 'dateTo',
		title: 'Data do',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		dataIndex: 'createdDate',
		key: 'createdDate',
		title: 'Data utworzenia',
		filter: 'dateRange',
		sorter: true,
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		dataIndex: 'year',
		key: 'year',
		title: 'Rok wydania opinii',
		filter: 'equals',
		sorter: true,
		defaultHidden: true,
	},
	{
		dataIndex: 'aotmitNumber',
		key: 'aotmitNumber',
		title: 'Numer AOTMIT',
		filter: 'contains',
		sorter: true,
		defaultHidden: true,
	},
	{
		dataIndex: 'county',
		key: 'county',
		title: 'Powiat',
		filter: 'contains',
		sorter: true,
		defaultHidden: true,
	},
	{
		dataIndex: 'commune',
		key: 'commune',
		title: 'Gmina',
		filter: 'contains',
		sorter: true,
		defaultHidden: true,
	},
];

export default columns;
