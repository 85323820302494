import { FormikProps } from 'formik';
import { Moment } from 'moment';
import { createContext } from 'react';
import {
	ActivityDetailsDto,
	DictionaryInterventionPath,
	SubjectIdNameDto,
} from 'services/src/models';

import { InterventionActivityCommand } from '../model/InterventionBaseCommand';

export type ActivityContextType = {
	isPattern: boolean;
	editable: boolean;
	patternActivity: ActivityDetailsDto | undefined;
	checkChanges: (value?: any, key?: any) => void;
	dateRange:
		| [(Moment | undefined)?, (Moment | undefined)?]
		| Moment[]
		| undefined;
	unchangedData: InterventionActivityCommand | undefined;
	formik: FormikProps<InterventionActivityCommand> | undefined;
	numberOfPeople: number;
	setDateRange?: (value: [Moment?, Moment?] | undefined) => void;
	activity: InterventionActivityCommand | undefined;
	interventionId: string | undefined;
	interventionPath: DictionaryInterventionPath;
	mergedSubjectMap: Map<string, SubjectIdNameDto>;
	checkIfObjectValue?: (object: any, key?: any) => boolean;
	interventionCreatedAt: Moment | undefined;
};

export const ActivityContext = createContext<ActivityContextType>({
	isPattern: false,
	checkChanges: () => {},
	editable: false,
	patternActivity: undefined,
	dateRange: undefined,
	unchangedData: undefined,
	formik: undefined,
	numberOfPeople: 0,
	setDateRange: undefined,
	activity: undefined,
	mergedSubjectMap: new Map(),
	interventionId: undefined,
	interventionPath: DictionaryInterventionPath.PROHEALTHACTIVITIES,
	checkIfObjectValue: undefined,
	interventionCreatedAt: undefined
});
