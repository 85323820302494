import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import { Form } from 'antd';
import { Col } from 'antd';
import Authorized from 'authorization/authorized';
import AddButton from 'components/shared/buttons/AddButton';
import GridRow from 'components/shared/GridRow';
import SearchBar from 'components/shared/paginatedProfiBazaTable/SearchBar';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useState } from 'react';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';

import { AddStatementModal } from '../AddStatementModal';
import StatementsTable from './StatementsTable';

interface IProps {}

const StatementRegistry: React.FC<IProps> = (props) => {
	const [visible, setVisible] = useState<boolean>(false);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	return (
		<>
			<GridRow
				left={
					<Authorized
						permissions={[
							ProfiBazaAPIModels.Permission
								.StatementsGenerationMZ49,
							ProfiBazaAPIModels.Permission
								.StatementsGenerationMZ53,
							ProfiBazaAPIModels.Permission
								.StatementsGenerationNPZ,
						]}
					>
						<AddButton tall onClick={() => setVisible(true)}>
							Nowe sprawozdanie
						</AddButton>
					</Authorized>
				}
			>
				<AddStatementModal
					visible={visible}
					onCreate={(values) => {
						setVisible(false);
						gridStore.searching.set(true);
					}}
					onCancel={() => {
						setVisible(false);
					}}
				/>
				<Form size="large">
					<SearchBar
						allowTags={true}
						column="(codeName|statementName)"
						filter="contains"
						gridName="statement"
					/>
				</Form>
			</GridRow>
			<CenteredRow>
				<Col span={23}>
					<SkipLinkContent />
					<StatementsTable {...props} />
				</Col>
			</CenteredRow>
		</>
	);
};

export default StatementRegistry;
