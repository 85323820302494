import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<
	ProfiBazaAPIModels.InterventionPath,
	string
>([
	[ProfiBazaAPIModels.InterventionPath.PROHEALTHACTIVITIES, 'Działania prozdrowotne'],
	[ProfiBazaAPIModels.InterventionPath.RESEARCH, 'Badania naukowe'],
	[ProfiBazaAPIModels.InterventionPath.SUPERVISIONSANITARY, 'Nadzór sanitarno-epidemiologiczny'],
	[ProfiBazaAPIModels.InterventionPath.SUPERVISIONMONITORING, 'Monitorowanie i ocena stanu zdrowia populacji'],
	[ProfiBazaAPIModels.InterventionPath.STAFFEDUCATION, 'Kształcenie kadr ochrony zdrowia'],
	[ProfiBazaAPIModels.InterventionPath.STAFFMONITORING, 'Monitorowanie i zarządzanie zasobami kadrowymi ochrony zdrowia'],
	[ProfiBazaAPIModels.InterventionPath.LEGISLATION, 'Legislacja'],
]);

export const map = (value: ProfiBazaAPIModels.InterventionPath): string =>
	mappings.get(value) ?? '';

export const procesTypesTotal = mappings.size;
