import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';

export class UpdateProfileCommand {
	id?: string;
	firstName?: string;
	lastName?: string;

	constructor(model: ProfiBazaAPIModels.UserProfileDto) {
		this.id = model.id;
		this.firstName = model.firstName;
		this.lastName = model.lastName;
	}

	static toUpdate(
		model: UpdateProfileCommand
	): ProfiBazaAPIModels.UpdateUserInCRUiPCommand {
		const command: ProfiBazaAPIModels.UpdateUserInCRUiPCommand = {
			id: model.id,
			firstName: model.firstName,
			lastName: model.lastName,
		};  
		return command;
	}
}
