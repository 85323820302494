import { ProfiBazaColumnProps } from '@components/shared/paginatedProfiBazaTable/GridHelper';
import {
	UserManualAdministrativeType,
	UserManualDto,
} from '@services/src/models';
import * as UserManualAdministrativeTypeMapper from '@services/mappers/UserManualAdministrativeType';

export const AdministrativeColumns: ProfiBazaColumnProps<UserManualDto>[] = [
	{
		sorter: false,
		dataIndex: 'userManualAdministrativeType',
		key: 'userManualAdministrativeType',
		title: 'Typ administracyjny',
		render: (userManualAdministrativeType: UserManualAdministrativeType) =>
			UserManualAdministrativeTypeMapper.map(
				userManualAdministrativeType
			),
	},
];
