import React from 'react';

export const handleFileTreeAccessibility = () => {
	document.querySelectorAll('[role="tree"]').forEach((tree) => {
		if (tree && tree.children) {
			for (let i = 0; i < tree.children.length; i++) {
				tree.children[i].setAttribute(
					'aria-label',
					`Kategoria plików ${i}`
				);
			}
		}
	});
};

const useFileTreeAccessibility = () => {
	React.useLayoutEffect(() => {
		handleFileTreeAccessibility();
	}, []);
};

export default useFileTreeAccessibility;
