interface IInterventionError {
	interventionId: string;
	activityId: string;
	message: string;
}

export default class InterventionValidationErrors {
	errors: IInterventionError[];

	constructor() {
		this.errors = [];
	}

	addError(interventionId: string, activityId: string, errorMessage: string) {
		const error = this.errors.find(
			(x) =>
				x.interventionId === interventionId &&
				x.activityId === activityId
		);
		if (error === undefined) {
			this.errors.push({
				interventionId: interventionId,
				activityId: activityId,
				message: errorMessage,
			});
		} else {
			error.message = errorMessage;
		}
	}

	hasInterventionError(
		interventionId: string
	): { hasError: boolean; message?: string } {
		const errors = this.errors.filter(
			(x) => x.interventionId === interventionId
		);
		return errors.length === 0
			? { hasError: false }
			: {
					hasError: true,
					message: [errors.map((x) => x.message)].join(', '),
			  };
	}

	hasActivityError(
		interventionId: string,
		activityId?: string
	): { hasError: boolean; message?: string } {
		if (!activityId) return { hasError: false };

		const error = this.errors.find(
			(x) =>
				x.interventionId === interventionId &&
				x.activityId === activityId
		);
		return error
			? { hasError: true, message: error.message }
			: { hasError: false };
	}

	toJSON() {
		return JSON.stringify(this.errors);
	}

	static readonly InterventionValidationErrorsKey =
		'InterventionValidationErrors';

	static getErrors() {
		const item = localStorage.getItem(
			this.InterventionValidationErrorsKey
		);
		if (item === null) return null;

		const result = new InterventionValidationErrors();
		result.errors = JSON.parse(item) as IInterventionError[];
		return result;
	}

	static clear() {
		localStorage.removeItem(this.InterventionValidationErrorsKey);
	}

	static addError(
		interventionId: string,
		activityId: string,
		errorMessage: string
	) {
		let interventionError = InterventionValidationErrors.getErrors();

		if (!interventionError) {
			interventionError = new InterventionValidationErrors();
		}

		interventionError.addError(interventionId, activityId, errorMessage);
		localStorage.setItem(
			this.InterventionValidationErrorsKey,
			interventionError.toJSON()
		);
	}
}
