import { Popover, Tag } from 'antd';
import Select, { SelectProps } from 'antd/lib/select';
import { FieldAttributes, FieldProps } from 'formik';
import React, { createRef } from 'react';
import { findDOMNode } from 'react-dom';

import { ErrorPopover } from './ErrorPopover';
import {
	FErrorMessage,
	IFormikInputProps,
	isNotValidAndTouched,
	shouldShowErrorMessage,
} from './FormikFormItems';

export interface IFSelectAreaProps extends FieldProps<any> {
	onChangeAttempt?: (newValue: string) => boolean;
	search?: (val: string) => any;
	mode?: 'multiple' | undefined;
	array?: any;
	editable?: boolean;
	dropDown?: boolean;
	tagRender?: boolean;
	label?: string;
	onClose?: (val: string) => boolean;
}

interface IFSelectState {
	array: any;
}

type IProps = IFSelectAreaProps &
	IFormikInputProps &
	SelectProps<any> &
	FieldAttributes<any>;

export class FSelectArea extends React.Component<IProps, IFSelectState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			array: this.props.children,
		};
		this.tagRender = this.tagRender.bind(this);
	}

	componentDidUpdate(oldProps: IProps) {}

	tagRender(tagProps: any) {
		const { label, onClose } = tagProps;
		return this.props.tagRender ? (
			<Tag
				{...tagProps}
				closable={this.props.editable}
				onClose={() => {
					let list = this.props.onClose?.(tagProps.value);
					this.setValue(list);
				}}
			>
				{label}
			</Tag>
		) : (
			<Tag closable={false} style={{ marginRight: 3 }}>
				{label}
			</Tag>
		);
	}

	componentDidMount() {
		this.setValue();
	}

	setValue(list?: any) {
		this.props.form.setFieldValue(
			this.props.field.name,
			list
				? Array.isArray(list)
					? list.length > 0
						? list
						: undefined
					: list.toString()
				: this.props.array
				? Array.isArray(this.props.array)
					? this.props.array
					: this.props.array.toString()
				: Array.isArray(this.props.field.value)
				? this.props.field.value
				: this.props.field.value
				? this.props.field.value.toString()
				: undefined
		);
		if (this.props.field.value) {
			this.props.form.setFieldError(this.props.field.name, '');
		}
	}

	public render() {
		const { editable, innerRef, ...otherProps } = this.props;

		const input = () => {
			return (
				<Select
					{...otherProps}
					ref={innerRef}
					showSearch={typeof this.props.search == 'function'}
					mode={this.props.mode}
					allowClear={false}
					tagRender={this.tagRender}
					placeholder="Wybierz"
					dropdownRender={(originNode) => <></>}
					dropdownStyle={{
						display: 'none',
					}}
					value={
						this.props.array
							? Array.isArray(this.props.array)
								? this.props.array
								: this.props.array.toString()
							: Array.isArray(this.props.field.value)
							? this.props.field.value
							: this.props.field.value
							? this.props.field.value.toString()
							: undefined
					}
					disabled={this.props.disabled}
					onBlur={(v: any) => {
						this.props.form.setFieldTouched(
							this.props.field.name,
							true
						);
					}}
					onChange={(val: any, option: any) => {
						if (
							this.props.onChangeAttempt === undefined ||
							this.props.onChangeAttempt(val)
						) {
							this.props.form.setFieldTouched(
								this.props.field.name,
								true
							);
						}
					}}
					id={this.props.field.name}
					aria-owns={this.props.field.name}
					aria-controls={this.props.field.name}
					aria-activedescendant={this.props.field.name}
				>
					{this.props.children}
				</Select>
			);
		};

		return (
			<ErrorPopover field={this.props.field} form={this.props.form}>
				{input()}
			</ErrorPopover>
		);
	}
}
