import { ISideFilter } from '@components/shared/paginatedProfiBazaTable/TableSideBar';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';

import { DocumentSideBarConsts } from './DocumentSideBarConsts';

export const sideBarFilters: ISideFilter[] = [
	{
		key: 'interventions',
		label: 'Interwencje',
		filter: ProfiBazaAPIModels.SourceDocumentFilter.Interventions,
		defaultSelected: true,
	},
	{
		key: 'aotmit',
		label: 'AOTMiT',
		filter: undefined,
		subItems: [
			{
				key: DocumentSideBarConsts.AOTMiTPPZ,
				label: 'PPZ',
				filter: ProfiBazaAPIModels.SourceDocumentFilter.AOTMiTPPZ,
			},
			{
				key: DocumentSideBarConsts.AOTMiTRecommendations,
				label: 'Rekomendacje',
				filter:
					ProfiBazaAPIModels.SourceDocumentFilter
						.AOTMiTRecommendations,
			},
			{
				key: DocumentSideBarConsts.AOTMiTAnnex,
				label: 'Aneksy',
				filter: ProfiBazaAPIModels.SourceDocumentFilter.AOTMiTAnnex,
			},
			{
				key: DocumentSideBarConsts.AOTMiTPronouncement,
				label: 'Oświadczenia',
				filter:
					ProfiBazaAPIModels.SourceDocumentFilter
						.AOTMiTPronouncements,
			},
			{
				key: DocumentSideBarConsts.AOTMiTFinalReport,
				label: 'Raporty końcowe',
				filter:
					ProfiBazaAPIModels.SourceDocumentFilter.AOTMiTFinalReport,
			},
		],
	},
	{
		key: 'statements',
		label: 'Sprawozdania',
		filter: ProfiBazaAPIModels.SourceDocumentFilter.Statements,
	},
	{
		key: 'archiveStatements',
		label: 'Archiwum sprawozdań',
		filter: ProfiBazaAPIModels.SourceDocumentFilter.ArchiveStatements,
	},
	{
		key: 'archiveInterventions',
		label: 'Archiwum interwencji',
		filter: ProfiBazaAPIModels.SourceDocumentFilter.ArchiveInterventions,
	},
	{
		permissions: [
			ProfiBazaAPIModels.Permission
				.DocumentsRepositoryVoivodeOpinionsPreview,
			ProfiBazaAPIModels.Permission
				.DocumentsRepositoryAllVoivodeOpinionsPreview,
		],
		key: 'voivodeOpinion',
		label: 'Opinia wojewody',
		filter: ProfiBazaAPIModels.SourceDocumentFilter.VoivodeOpinion,
	},
];
