import { ColumnProps } from 'antd/lib/table/Column';
import { SubjectContactDto } from 'services/src/models';

export const columnsContact: ColumnProps<SubjectContactDto>[] = [
	{
		dataIndex: 'firstName',
		key: 'firstName',
		title: 'Imię',
	},
	{
		dataIndex: 'lastName',
		key: 'lastName',
		title: 'Nazwisko',
	},
	{
		dataIndex: 'position',
		key: 'position',
		title: 'Stanowisko',
	},
	{
		dataIndex: 'email',
		key: 'email',
		title: 'E-mail',
	},
	{
		dataIndex: 'phoneNumber',
		key: 'phoneNumber',
		title: 'Telefon',
	},
	{
		dataIndex: 'connectedWithIkpRecrutation',
		key: 'connectedWithIkpRecrutation',
		title: 'Kontakt związany z rekrutacją do programu',
		render: (value?: boolean) => (value ? 'tak' : 'nie'),
	},
];
