import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const columns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.NotificationDefinitionDTO
>[] = [
	{
		dataIndex: 'event',
		key: 'event',
		title: 'Zdarzenie',
	},
];

export default columns;
