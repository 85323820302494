import React from "react";

import { Row, Col, Card } from "antd";

export default class PrimaryCard extends React.Component {
    public render() {
        return <Row justify="center" className="primary-card">
            <Col xl={24} xs={24}>
                <Card>
                    {this.props.children}
                </Card>
            </Col>
        </Row>
    }
}

