import * as InterventionStatusWithoutArchived from '@services/mappers/InterventionStatusWithoutArchived';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import formatHelpers from 'helper/formatHelpers';
import { DictionaryName } from 'services/mappers/DictionaryName';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const columns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.DocumentListItemDto
>[] = [
	{
		dataIndex: 'fileName',
		key: 'fileName',
		title: 'Nazwa',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'status',
		key: 'status',
		title: 'Status',
		filter: 'dictionary',
		sorter: true,
		filterDictionary: DictionaryName.InterventionStatusWithoutArchived,
		render: (value: ProfiBazaAPIModels.PublicHealthInterventionStatus) =>
			InterventionStatusWithoutArchived.map(value),
	},
	{
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Podmiot',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'voivodeship',
		key: 'voivodeship',
		title: 'Województwo',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'dateFrom',
		key: 'dateFrom',
		title: 'Data od',
		sorter: true,
		filter: 'dateRange',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		dataIndex: 'dateTo',
		key: 'dateTo',
		title: 'Data do',
		sorter: true,
		filter: 'dateRange',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		dataIndex: 'createdDate',
		key: 'createdDate',
		title: 'Data utworzenia',
		filter: 'dateRange',
		sorter: true,
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		dataIndex: 'opinionPresidentYear',
		key: 'opinionPresidentYear',
		title: 'Rok wydania opinii',
		filter: 'equals',
		sorter: true,
		defaultHidden: true,
	},
	{
		dataIndex: 'aotmitNumber',
		key: 'aotmitNumber',
		title: 'Numer AOTMIT',
		filter: 'contains',
		sorter: true,
		defaultHidden: true,
	},
	{
		dataIndex: 'county',
		key: 'county',
		title: 'Powiat',
		filter: 'contains',
		sorter: true,
		defaultHidden: true,
	},
	{
		dataIndex: 'commune',
		key: 'commune',
		title: 'Gmina',
		filter: 'contains',
		sorter: true,
		defaultHidden: true,
	},
];

export default columns;
