import formatHelpers from '@helper/formatHelpers';
import { UserManualDto } from '@services/src/models';
import { Col, notification } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { IsRCFileSizeValid } from 'components/shared/file/IsFileSizeValid';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { showNotExpectedErrorModal } from 'layout/Modals';
import React, { useImperativeHandle, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { IGridStore } from 'stores/GridStore';

import { setDelayedNotificationLinksName } from '../../../helper/accessibilityHelpers';
import FileUploadArea from '../FileUploadArea';

interface IProps {
	onCreate: () => void;
	userManual: UserManualDto;
}

export interface IAddUserManualModal {
	showModal: () => void;
}

const AddUserManualModal = React.forwardRef<IAddUserManualModal, IProps>(
	(props, ref) => {
		const [visible, setVisible] = useState<boolean>(false);

		const gridStore: IGridStore = useRootData((store) => store.gridStore);

		useImperativeHandle(ref, () => ({
			showModal: () => {
				setVisible(true);
			},
		}));

		const fileUploadProps = {
			name: 'file',
			multiple: false,
			customRequest: (options: RcCustomRequestOptions) => {
				const { onSuccess, onError, file, onProgress } = options;

				if (!IsRCFileSizeValid(options)) return;

				getProfiBazaApiClient()
					.then((api) =>
						api.userManual.create({
							file: options.file,
							onUploadProgress: (event) => {
								const percent = Math.floor(
									(event.loadedBytes / file.size) * 100
								);

								onProgress(
									{
										percent: percent,
									},
									file
								);
							},
							manualType: props.userManual.manualType,
							interventionPath: props.userManual.interventionPath,
							reportType: props.userManual.reportType,
							userManualAdministrativeType:
								props.userManual.userManualAdministrativeType,
							formVersionId: props.userManual.formVersionId,
							formValidFrom: props.userManual.formValidFrom,
							rODOManualType: props.userManual.rodoManualType,
						})
					)
					.then((result) => {
						notification.success({
							placement: 'bottomRight',
							message: `Plik ${file.name} wysłano pomyślnie`,
						});
						setDelayedNotificationLinksName();
						onSuccess(result, file);
						props.onCreate();
						setVisible(false);
						gridStore.searching.set(true);
					})
					.catch((err) => {
						showNotExpectedErrorModal(err);
						onError(err);
					});
			},
			progress: {
				strokeColor: {
					'0%': '#108ee9',
					'100%': '#87d068',
				},
				strokeWidth: 1,
				format: (percent: any) =>
					formatHelpers.formatPercent(percent, true),
			},
			showUploadList: {
				showPreviewIcon: false,
				showDownloadIcon: false,
				showRemoveIcon: false,
			},
			accept: '.pdf',
		};

		return (
			<Modal
				visible={visible}
				maskClosable
				centered
				destroyOnClose
				title="Tworzenie instrukcji użytkownika"
				onCancel={() => {
					setVisible(false);
				}}
				footer={<></>}
			>
				<CenteredRow>
					<Col span={20}>
						<FileUploadArea uploadProps={fileUploadProps} />
					</Col>
				</CenteredRow>
			</Modal>
		);
	}
);

export default AddUserManualModal;
