import * as Yup from 'yup';

const requiredMessage: string = 'Wartość jest wymagana';

export const FormVersionValidationSchema = (
	isCreation: boolean,
) =>
	Yup.object().shape({
		validFrom: Yup.date().when('validTo', {
			is: (validTo) => validTo != null,
			then: Yup.date()
				.nullable()
				.required(requiredMessage)
				.test(
					'less than validTo',
					'Data musi być wcześniejsza niż Data Do',
					function (validFrom) {
						return !validFrom || validFrom < this.parent.validTo;
					}
				),
			otherwise: isCreation
				? Yup.date().nullable()
				: Yup.date().nullable().required(requiredMessage),
		}),
	});
