import { map } from '@services/mappers/DictionaryStatementArea';
import { Button, Form, Input, Modal, Select, Space, notification } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { ajaxByUser } from 'helper/api';
import React, { useRef, useState } from 'react';
import * as StatementAreaMap from 'services/mappers/StatementArea';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { StatementArea } from 'services/src/models';
const { Option } = Select;

interface IProps {
	visible: boolean;
	onCancel: () => void;
	onCreate: (values: any) => void;
}

const layout = {
	labelCol: {
		span: 10,
	},
	wrapperCol: {
		span: 14,
	},
};

export const AddStatementModal = (props: IProps) => {
	const [form] = Form.useForm();
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [years, setYears] = useState<Array<number>>();

	const okButtonRef = useRef<HTMLElement>(null);

	const onChangeStatementArea = (selected: SelectValue) => {
		form.setFieldsValue({ year: undefined });

		var actualYear = new Date().getFullYear();
		switch (selected.toString()) {
			case StatementArea.MZ49:
				if (actualYear % 2 === 1) {
					var year = actualYear - 1;
					setYears([year]);
					setDefaultYear(year);
				} else {
					var year = actualYear - 2;
					setYears([year]);
					setDefaultYear(year);
				}

				break;
			case (StatementArea.MZ53, StatementArea.NPZ):
				var year = actualYear - 1;
				setYears([year]);
				setDefaultYear(year);

				break;
			case StatementArea.MZ53:
				var year = actualYear - 1;
				setYears([year]);
				setDefaultYear(year);
				break;
		}
	};
	const addStatement = (year: number, statementArea: StatementArea) => {
		const request = () =>
			getProfiBazaApiClient()
				.then((api) =>
					api.statements.create({
						body: {
							statementArea: statementArea,
							year: year,
						},
					})
				)
				.then(() => {
					form.resetFields();
					props.onCreate({ year, statementArea });
				});

		ajaxByUser(
			`Sprawozdanie ${map(
				statementArea
			)} za rok ${year} zostało poprawnie utworzone`,
			request,
			undefined,
			undefined,
			() => {
				okButtonRef.current?.focus();
			}
		).then(() => setSubmitting(false));
	};
	const setDefaultYear = (year: number) => {
		form.setFieldsValue({
			year: year,
		});
	};

	return (
		<Modal
			title="Wybierz sprawozdanie"
			visible={props.visible}
			width={600}
			footer={
				<Space>
					<Button
						disabled={isSubmitting}
						onClick={() => {
							props.onCancel();
							form.resetFields();
						}}
					>
						Anuluj
					</Button>
					<Button
						disabled={isSubmitting}
						ref={okButtonRef}
						type="primary"
						onClick={() => {
							form.validateFields()
								.then((values) => {
									if (!isSubmitting) {
										addStatement(
											parseInt(values.year),
											values.statementArea
										);
										setSubmitting(true);
									}
								})
								.catch((info) => {
									notification.error({
										placement: 'bottomRight',
										message:
											'Sprawozdanie zweryfikowane negatywnie',
									});
								});
						}}
					>
						Utwórz
					</Button>
				</Space>
			}
			onCancel={() => {
				props.onCancel();
				form.resetFields();
			}}
		>
			<Form form={form} {...layout}>
				<Form.Item noStyle name="id">
					<Input hidden aria-hidden="true" />
				</Form.Item>
				<Form.Item
					label="Rodzaj sprawozdania"
					colon={false}
					labelAlign="right"
				>
					<Form.Item
						noStyle
						name="statementArea"
						rules={[
							{
								required: true,
								message: 'Rodzaj sprawozdania jest wymagany!',
							},
						]}
					>
						<Select
							onChange={(e) => onChangeStatementArea(e)}
							aria-label="Rodzaj sprawozdania"
							id="statementArea"
							aria-activedescendant="statementArea"
							aria-owns="statementArea"
							aria-controls="statementArea"
						>
							{Array.from(
								StatementAreaMap.mappings.entries()
							).map((x, i) => (
								<Option key={i} value={x[0]}>
									{x[1]}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Form.Item>
				<Form.Item label="Za rok" colon={false} labelAlign="right">
					<Form.Item
						noStyle
						name="year"
						rules={[
							{
								required: true,
								message: 'Rok jest wymagany!',
							},
						]}
					>
						<Select
							aria-label="Za rok"
							id="year"
							aria-activedescendant="year"
							aria-owns="year"
							aria-controls="year"
							disabled
						>
							{years &&
								years.map((x) => (
									<Option key={x} value={x}>
										{x}
									</Option>
								))}
						</Select>
					</Form.Item>
				</Form.Item>
				<input type="submit" hidden aria-hidden="true" />
			</Form>
		</Modal>
	);
};
