import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { useRootData } from 'hooks/hook';
import { observer } from 'mobx-react';
import React, {  } from 'react';

import PermissionValidator from './permissionValidator';

interface IProps {
	permission?: ProfiBazaAPIModels.Permission | undefined;
	permissions?: ProfiBazaAPIModels.Permission[] | undefined;
}

const Authorized: React.FC<IProps> = (props) => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	let authorized = isAuthorized();

	return (
		<>
			{authorized && props.children}
		</>
	);

	function isAuthorized(): boolean {
		if (!accountDetailsStore) return false;

		const permissionValidator = new PermissionValidator(
			accountDetailsStore.account.get()!
		);

		if (
			props.permissions !== undefined &&
			permissionValidator.hasAny(props.permissions)
		) {
			return true;
		}

		if (
			props.permission !== undefined &&
			permissionValidator.has(props.permission)
		) {
			return true;
		}
		return false;
	}
};

export default observer(Authorized);
