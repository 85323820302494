import React from 'react';

export const handleSvgAccessibility = () => {
	const els = document.getElementsByTagName('svg');
	Array.from(els).forEach((el) => {
		if (!el.getAttribute('role')) {
			el.setAttribute('role', 'img');
			el.setAttribute('aria-label', 'Ikona');
		}
	});
};

const useIconAccessibility = () => {
	React.useLayoutEffect(() => {
		handleSvgAccessibility();
	}, []);
};

export default useIconAccessibility;
