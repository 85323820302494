import { IAccountDetailsStore } from 'account/accountDetailsStore';
import InterventionValidationErrors from 'components/intervention/model/InterventionValidationErrors';
import { useRootData } from 'hooks/hook';
import { CenteredSpin } from 'layout/CenteredSpin';
import { observer } from 'mobx-react';
import { User } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import {
	Redirect,
	Route,
	RouteComponentProps,
	withRouter,
} from 'react-router-dom';

import { GlobalUserManager } from './userManager';

interface IProps {}
type IPropsWithRouter = IProps & RouteComponentProps;

const returnUrl = 'returnUrl';

const AuthenticatedComponent: React.FC<IPropsWithRouter> = (props) => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	useEffect(() => {
		if (
			props.location.pathname === '/authCallback' ||
			props.location.pathname === '/silentRenew'
		) {
			return;
		}
		GlobalUserManager.UserManager()
			.getUser()
			.then((getUser: User | null) => {
				if (!getUser || getUser.expired) {
					sessionStorage.setItem(returnUrl, props.location.pathname);
					GlobalUserManager.UserManager().signinRedirect();
				} else {
					markUserAsLoggedIn(getUser);
				}
			});
	}, []);

	function markUserAsLoggedIn(redirectUser: User) {
		accountDetailsStore.setOidcUser(redirectUser);
	}

	return (
		<>
			<Route
				path="/authCallback"
				component={() => <AuthCallbackView />}
			/>
			<Route path="/silentRenew" component={SilentRenewCallbackView} />
			{!accountDetailsStore.oidcUser ? (
				<CenteredSpin size="large" />
			) : (
				props.children
			)}
		</>
	);
};
const Authenticated = withRouter(AuthenticatedComponent);
export default observer(Authenticated);

interface IAuthCallbackViewProps {}

const AuthCallbackView: React.FC<IAuthCallbackViewProps> = observer((props) => {
	const [user, setUser] = useState<User | undefined>(undefined);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const markUserAsLoggedIn = (redirectUser: User) => {
		setUser(redirectUser);
		accountDetailsStore!.setOidcUser(redirectUser);
	};

	React.useEffect(() => {
		GlobalUserManager.UserManager()
			.signinRedirectCallback()
			.then((user: User) => {
				markUserAsLoggedIn(user);
			});
	}, []);

	let returnAddres = sessionStorage.getItem(returnUrl);
	if (returnAddres === '/') {
		returnAddres = accountDetailsStore.defaultRoute.get() ?? null;
	}

	if (returnAddres && returnAddres.endsWith('silentRenew')) {
		returnAddres = null;
	}
	if (returnAddres) {
		if (user) {
			sessionStorage.removeItem(returnUrl);
			return <Redirect to={returnAddres} />;
		}
		return null;
	}

	if (user) {
		InterventionValidationErrors.clear();
	}

	return user ? <Redirect to="/" /> : <CenteredSpin size="large" />;
});

class SilentRenewCallbackView extends React.Component<RouteComponentProps> {
	componentDidMount() {
		if (
			this.props.location.search &&
			this.props.location.search.includes('error=login_required')
		) {
			GlobalUserManager.UserManager().signinRedirect();
			GlobalUserManager.UserManager().createSigninRequest();
		} else {
			GlobalUserManager.UserManager().signinSilentCallback();
		}
	}

	public render() {
		return <></>;
	}
}
