import { GlobalUserManager } from '@authentication/userManager';
import { ajaxCatch } from 'helper/api';
import { User } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';

interface IProps {
	fileId: any;
}

const FilePreview = (props: IProps) => {
	const [file, setFile] = useState<any>();
	const [mimeType, setMimeType] = useState<string>();
	const [pdfFrameKey, setPdfFrameKey] = useState<number>(Math.random());
	const [userAg, setUserAg] = useState<any>(navigator.userAgent);
	const [token, setToken] = useState<string>();
	const resetIframe = () => setPdfFrameKey(Math.random());

	useEffect(() => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.file.preview(props.fileId, true))
				.then((result) => {
					if (result.mimeType === 'application/pdf') {
						let um = GlobalUserManager.UserManager();
						if (um) {
							um.getUser().then((user: User | null) => {
								if (user) {
									setMimeType(result.mimeType);
									setToken(user.access_token);
								}
							});
						}
					} else {
						getProfiBazaApiClient()
							.then((api) =>
								api.file.preview(props.fileId, false)
							)
							.then((result) => {
								const f = btoa(
									result.data!.reduce(
										(data, byte) =>
											data + String.fromCharCode(byte),
										''
									)
								);
								setMimeType(result.mimeType);

								setFile(f);
							});
					}
				})
		);
	}, [props.fileId]);

	const isImage = (mimeType: string | undefined): boolean => {
		if (mimeType && mimeType.includes('image/')) {
			return true;
		}

		return false;
	};

	var document = undefined;
	if (mimeType == 'application/pdf') {
		document = (
			<iframe
				key={pdfFrameKey}
				src={`/pdfjs/web/viewer.html?file=/api/DocumentsRepository/File/${props.fileId}&token=${token}`}
				width="100%"
				height="800px"
			></iframe>
		);
	} else if (file && isImage(mimeType)) {
		document = <img src={`data:image/png;base64,${file}`} />;
	}
	return <>{document !== undefined && document}</>;
};
export default FilePreview;
