import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { markErrors } from '@components/shared/tabs/markErrors';
import useTabAccessibility from '@hooks/accessibility/useTabAccessibilityHook';
import { useRootData } from '@hooks/hook';
import { BusinessObjectType, DictionaryInterventionPath } from '@services/src/models';
import { Row, Tabs } from 'antd';
import { FormikErrors } from 'formik';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import {
	IRizpDictionaryStore,
	rizpDictionaryStore,
} from 'stores/RizpDictionaryStore';

import { InterventionCommentIcon } from '../comments/InterventionCommentIcon';
import {
	ActivityTab,
	IInterventionCommentStore,
} from '../comments/store/InterventionCommentStore';
import {
	ActivityContext,
	ActivityContextType,
} from '../context/ActivityContext';
import {
	ActivityAdditionalDetails,
	ActivityCareAreas,
	ActivityCategory,
	ActivityChosenPopulationsHealths,
	ActivityComment,
	ActivityDescription,
	ActivityDiseasesMortalityPrevalences,
	ActivityImplementationCompanies,
	ActivityNPZ,
	ActivityName,
	ActivityNumber,
	ActivityNumberOfPersonAction,
	ActivityNumberOfRecipients,
	ActivityPRPZ,
	ActivityPopulation,
	ActivityPublicHealthTask,
	ActivityRealizationDate,
	ActivitySponsoringCompanies,
	ActivityTopic,
	AddComment,
	IkpTab,
} from './ActivityFields';

const { TabPane } = Tabs;

interface IProps {
	interventionId: string;
	interventionPath: ProfiBazaAPIModels.InterventionPath;
}

const mainTab = (formikErrors: FormikErrors<any> | undefined) =>
	markErrors(formikErrors, 'main', <>Dane podstawowe</>);

const healthProblemsTab = (formikErrors: FormikErrors<any> | undefined) => {
	const content = (
		<>
			<span>Problem zdrowotny</span>
			<LabelHelpTooltip
				field="populationHealth"
				defaultValue="Problem zdrowotny"
			/>
		</>
	);
	return markErrors(formikErrors, 'populationHealth', content);
};

const ikpTab = (formikErrors: FormikErrors<any> | undefined) => {
	const content = (
		<>
			<span>Internetowe Konto Pacjenta</span>
			<LabelHelpTooltip field="ikp" defaultValue="IKP" />
		</>
	);
	return markErrors(formikErrors, 'ikp', content);
};

const ActivityFields = (props: IProps) => {
	const history = useHistory();

	const context = useContext(ActivityContext);

	const rizpDictionaryStore: IRizpDictionaryStore = useRootData(
		(store) => store.rizpDictionaryStore
	);

	const interventionCommentStore: IInterventionCommentStore = useRootData(
		(store) => store.interventionCommentStore
	);

	useTabAccessibility();

	if (
		props.interventionPath ==
		ProfiBazaAPIModels.InterventionPath.STAFFEDUCATION
	) {
		return (
			<>
				<Tabs
					defaultActiveKey="main"
					onChange={(activeKey: string) => {
						handleTabChange(
							activeKey,
							interventionCommentStore,
							context
						);
					}}
				>
					<TabPane tab={mainTab(context.formik?.errors)} key="main" forceRender={true}>
						<Row gutter={16}>
							{ActivityStaffPathPrimaryTab(context)}
						</Row>
					</TabPane>
					{CompaniesTabs(context)}
					{context.activity?.formVersionId && (
						<TabPane tab="Dane dodatkowe" key="additional-data" forceRender={true}>
							<Row gutter={16}>
								{ActivityAdditionalDetailsTab(context)}
							</Row>
						</TabPane>
					)}
				</Tabs>
			</>
		);
	} else if (
		props.interventionPath ==
			ProfiBazaAPIModels.InterventionPath.SUPERVISIONSANITARY ||
		props.interventionPath ==
			ProfiBazaAPIModels.InterventionPath.SUPERVISIONMONITORING ||
		props.interventionPath ==
			ProfiBazaAPIModels.InterventionPath.STAFFMONITORING
	) {
		return (
			<>
				<Tabs
					defaultActiveKey="main"
					onChange={(activeKey: string) => {
						handleTabChange(
							activeKey,
							interventionCommentStore,
							context
						);
					}}
				>
					<TabPane tab={mainTab(context.formik?.errors)} key="main" forceRender={true}>
						<Row gutter={16}>
							{ActivityVisionPathPrimaryTab(context)}
						</Row>
					</TabPane>
					<TabPane
						tab={healthProblemsTab(context.formik?.errors)}
						key="populationHealth"
						forceRender={true}
					>
						{PopulationHealthTab(context)}
					</TabPane>
					{CompaniesTabs(context)}
					{((context.editable && context.activity?.formVersionId) ||
						context.activity?.documentId) && (
						<TabPane tab="Dane dodatkowe" key="additional-data" forceRender={true}>
							<Row gutter={16}>
								{ActivityAdditionalDetailsTab(context)}
							</Row>
						</TabPane>
					)}
				</Tabs>
			</>
		);
	} else if (
		props.interventionPath ==
		ProfiBazaAPIModels.InterventionPath.LEGISLATION
	) {
		return (
			<>
				<Tabs
					defaultActiveKey="main"
					onChange={(activeKey: string) => {
						handleTabChange(
							activeKey,
							interventionCommentStore,
							context
						);
					}}
				>
					<TabPane tab={mainTab(context.formik?.errors)} key="main" forceRender={true}>
						<Row gutter={16}>
							{ActivityLegislationPathPrimaryTab(context)}
						</Row>
					</TabPane>
					<TabPane
						tab={healthProblemsTab(context.formik?.errors)}
						key="populationHealth"
						forceRender={true}
					>
						{PopulationHealthTab(context)}
					</TabPane>
					{CompaniesTabs(context)}
					{((context.editable && context.activity?.formVersionId) ||
						context.activity?.documentId) && (
						<TabPane tab="Dane dodatkowe" key="additional-data" forceRender={true}>
							<Row gutter={16}>
								{ActivityAdditionalDetailsTab(context)}
							</Row>
						</TabPane>
					)}
				</Tabs>
			</>
		);
	} else if (
		props.interventionPath == ProfiBazaAPIModels.InterventionPath.RESEARCH
	) {
		return (
			<>
				<Tabs
					defaultActiveKey="main"
					onChange={(activeKey: string) => {
						handleTabChange(
							activeKey,
							interventionCommentStore,
							context
						);
					}}
				>
					<TabPane tab={mainTab(context.formik?.errors)} key="main" forceRender={true}>
						<Row gutter={16}>{ActivityResearchPath(context)}</Row>
					</TabPane>
					<TabPane
						tab={healthProblemsTab(context.formik?.errors)}
						key="populationHealth"
						forceRender={true}
					>
						{PopulationHealthTab(context)}
					</TabPane>
					{CompaniesTabs(context)}
					{((context.editable && context.activity?.formVersionId) ||
						context.activity?.documentId) && (
						<TabPane tab="Dane dodatkowe" key="additional-data" forceRender={true}>
							<Row gutter={16}>
								{ActivityAdditionalDetailsTab(context)}
							</Row>
						</TabPane>
					)}
				</Tabs>
			</>
		);
	} else {
		return (
			<>
				<Tabs
					defaultActiveKey="main"
					onChange={(activeKey: string) => {
						handleTabChange(
							activeKey,
							interventionCommentStore,
							context
						);
					}}
				>
					<TabPane tab={mainTab(context.formik?.errors)} key="main" forceRender={true}>
						<Row gutter={16}>
							{ActivityStandardPathPrimaryTab(context)}
						</Row>
					</TabPane>
					{ActivityPopulation(context)}
					<TabPane
						tab={healthProblemsTab(context.formik?.errors)}
						key="populationHealth"
						forceRender={true}
					>
						{StandardPathPopulationHealthTab(context)}
					</TabPane>
					{CompaniesTabs(context)}
					{context.interventionPath ===
						DictionaryInterventionPath.PROHEALTHACTIVITIES &&
						!context.isPattern && (
							<TabPane
								tab={ikpTab(context.formik?.errors)}
								key="ikp"
								forceRender={true}
							>
								{IkpTab(context)}
							</TabPane>
						)}
					{((context.editable && context.activity?.formVersionId) ||
						context.activity?.documentId) && (
						<TabPane tab="Dane dodatkowe" key="additional-data" forceRender={true}>
							<Row gutter={16}>
								{ActivityAdditionalDetailsTab(context)}
							</Row>
						</TabPane>
					)}
				</Tabs>
			</>
		);
	}
};

const handleTabChange = (
	activeKey: string,
	store: IInterventionCommentStore,
	context: ActivityContextType
) => {
	if (activeKey === 'population') {
		const lastPopulationId = store.getLastPopulationId();

		store.filterParams.set({
			activityId: context.activity?.id!,
			populationId: lastPopulationId,
			sponsoringCompanyId: null,
		});

		if (lastPopulationId) {
			rizpDictionaryStore.lastFetchObjectId.set(lastPopulationId);
		}
	} else if (activeKey === 'sponsoringCompanies') {
		store.filterParams.set({
			activityId: context.activity?.id!,
			populationId: null,
			sponsoringCompanyId: store.getLastSponsoringCompanyId(),
		});

		const lastSponsoringCompanyId = store.getLastSponsoringCompanyId();
		if(lastSponsoringCompanyId) {
			rizpDictionaryStore.fetch(lastSponsoringCompanyId, BusinessObjectType.SponsoringCompany).then();
		}
	} else {
		store.filterParams.set({
			activityId: context.activity?.id!,
			populationId: null,
			sponsoringCompanyId: null,
		});
		rizpDictionaryStore.lastFetchObjectId.set(context.activity?.id!);
	}
};

const PopulationHealthTab = (context: ActivityContextType) => [
	ActivityChosenPopulationsHealths(context),
	ActivityDiseasesMortalityPrevalences(context),
];

const StandardPathPopulationHealthTab = (context: ActivityContextType) => [
	ActivityChosenPopulationsHealths(context),
	ActivityCareAreas(context),
	ActivityDiseasesMortalityPrevalences(context),
];

const CompaniesTabs = (context: ActivityContextType) => [
	ActivityImplementationCompanies(context),
	ActivitySponsoringCompanies(context),
];

const ActivityStandardPathPrimaryTab = (context: ActivityContextType) => [
	AddComment(context, ActivityTab.Basic),
	ActivityName(context),
	ActivityNumber(context),
	ActivityRealizationDate(context),
	ActivityNumberOfRecipients(context),
	ActivityNumberOfPersonAction(context),
	ActivityCategory(context),
	ActivityPublicHealthTask(context),
	ActivityNPZ(context),
	ActivityPRPZ(context),
	ActivityDescription(context),
	ActivityComment(context),
	ActivityTopic(context),
];

const ActivityVisionPathPrimaryTab = (context: ActivityContextType) => [
	AddComment(context, ActivityTab.Basic),
	ActivityName(context),
	ActivityNumber(context),
	ActivityRealizationDate(context),
	ActivityCategory(context),
	ActivityPublicHealthTask(context),
	ActivityNPZ(context),
	ActivityPRPZ(context),
	ActivityDescription(context),
	ActivityComment(context),
	ActivityTopic(context),
];

const ActivityAdditionalDetailsTab = (context: ActivityContextType) => [
	ActivityAdditionalDetails(context),
];

const ActivityLegislationPathPrimaryTab = (context: ActivityContextType) => [
	AddComment(context, ActivityTab.Basic),
	ActivityName(context),
	ActivityNumber(context),
	ActivityRealizationDate(context),
	ActivityCategory(context),
	ActivityPublicHealthTask(context),
	ActivityNPZ(context),
	ActivityPRPZ(context),
	ActivityDescription(context),
	ActivityComment(context),
	ActivityTopic(context),
];

const ActivityStaffPathPrimaryTab = (context: ActivityContextType) => [
	AddComment(context, ActivityTab.Basic),
	ActivityName(context),
	ActivityNumber(context),
	ActivityRealizationDate(context),
	ActivityNumberOfRecipients(context),
	ActivityCategory(context),
	ActivityPublicHealthTask(context),
	ActivityNPZ(context),
	ActivityPRPZ(context),
	ActivityDescription(context),
	ActivityComment(context),
	ActivityTopic(context),
];

const ActivityResearchPath = (context: ActivityContextType) => [
	AddComment(context, ActivityTab.Basic),
	ActivityName(context),
	ActivityNumber(context),
	ActivityRealizationDate(context),
	ActivityNumberOfRecipients(context),
	ActivityCategory(context),
	ActivityPublicHealthTask(context),
	ActivityNPZ(context),
	ActivityPRPZ(context),
	ActivityDescription(context),
	ActivityComment(context),
	ActivityTopic(context),
];

export default ActivityFields;
