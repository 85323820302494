export const handleOnPressEnter = (callback: () => void) => (
	e: React.KeyboardEvent<HTMLDivElement>
) => {
	if (e.key === 'Enter') {
		callback();
	}
	e.stopPropagation();
};

export const setDelayedNotificationLinksName = (delay: number = 500) => {
	setTimeout(() => {
		const notificationLinks = document.querySelectorAll(
			'.ant-notification-notice-close'
		);
		notificationLinks.forEach((link) => {
			link.setAttribute('name', 'Zamknij');
		});
	}, delay);
};
