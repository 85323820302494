import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	CloseCircleTwoTone,
	CopyOutlined,
	DeleteOutlined,
	EditOutlined,
	ExclamationCircleTwoTone,
	ExpandOutlined,
	FileAddOutlined,
	FileExcelOutlined,
	FilePdfOutlined,
	FileTextOutlined,
	InfoCircleOutlined,
	PoweroffOutlined,
	ReadOutlined,
	SelectOutlined,
	UndoOutlined,
	WarningTwoTone,
} from '@ant-design/icons';
import { MoveFocusToElement } from '@helper/focusHelpers';
import { Button } from 'antd';
import { ButtonProps, ButtonType } from 'antd/lib/button';
import React, { ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';

export type TableActionType =
	| 'ADD'
	| 'DETAILS'
	| 'EDIT'
	| 'DELETE'
	| 'ACTIVATE'
	| 'DEACTIVATE'
	| 'ARCHIVE'
	| 'UNARCHIVE'
	| 'CLONE'
	| 'SELECT'
	| 'DESELECT'
	| 'WARNING'
	| 'ACCEPT'
	| 'REJECT'
	| 'VIEW'
	| 'FILEXLSX'
	| 'FILECSV'
	| 'FILEPDF'
	| 'CLOSE'
	| 'EXCLAMATION'
	| 'INFO';

interface IProps {
	onClick?: () => void;
	kind: TableActionType;
	label: string;
	linkTo?: string;
}

export type TableActionButtonProps = IProps & ButtonProps;

function TableActionButton(
	props: React.PropsWithChildren<TableActionButtonProps>
) {
	const {
		type,
		onClick,
		kind,
		linkTo,
		size,
		danger,
		color,
		...others
	} = props;

	const history = useHistory();

	const handleLinkTo = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		history.push(linkTo!);
	};

	return (
		<Button
			aria-label={props.label}
			size={size ? size : 'small'}
			icon={getIcon(kind)}
			shape="circle"
			type={type ? type : getType(kind)}
			onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
				if (linkTo === undefined) {
					if (props.onClick) {
						props.onClick();
					}
				} else {
					handleLinkTo(event);
				}
				MoveFocusToElement();
			}}
			danger={kind === 'DELETE' || kind === 'REJECT' || danger}
			{...others}
		>
			{props.children}
		</Button>
	);
}

function getIcon(kind: TableActionType): ReactElement {
	switch (kind) {
		case 'ADD':
			return <FileAddOutlined />;
		case 'DETAILS':
			return <ExpandOutlined />;
		case 'EDIT':
			return <EditOutlined />;
		case 'ACTIVATE':
			return <PoweroffOutlined />;
		case 'DEACTIVATE':
			return <PoweroffOutlined />;
		case 'ARCHIVE':
			return (
				<span className="material-icons material-icon-small">
					archive
				</span>
			);
		case 'UNARCHIVE':
			return (
				<span className="material-icons material-icon-small">
					unarchive
				</span>
			);
		case 'DELETE':
			return <DeleteOutlined />;
		case 'CLONE':
			return <CopyOutlined />;
		case 'SELECT':
			return <SelectOutlined />;
		case 'DESELECT':
			return <UndoOutlined />;
		case 'WARNING':
			return <WarningTwoTone twoToneColor="yellow" />;
		case 'ACCEPT':
			return <CheckCircleOutlined color="green" />;
		case 'REJECT':
			return <CloseCircleOutlined />;
		case 'VIEW':
			return <ReadOutlined />;
		case 'FILEXLSX':
			return <FileExcelOutlined />;
		case 'FILECSV':
			return <FileTextOutlined />;
		case 'FILEPDF':
			return <FilePdfOutlined />;
		case 'CLOSE':
			return <CloseCircleTwoTone />;
		case 'EXCLAMATION':
			return <ExclamationCircleTwoTone />;
		case 'INFO':
			return <InfoCircleOutlined />;
		default:
			return <ExpandOutlined />;
	}
}

function getType(kind: TableActionType): ButtonType {
	switch (kind) {
		case 'DETAILS':
		case 'EDIT':
		case 'ACTIVATE':
		case 'DELETE':
		case 'CLONE':
		case 'VIEW':
		case 'INFO':
		case 'EXCLAMATION':
			return 'primary';
		case 'DEACTIVATE':
		case 'DESELECT':
		case 'ARCHIVE':
		case 'UNARCHIVE':
			return 'default';
		case 'WARNING':
			return 'link';
		default:
			return 'primary';
	}
}

TableActionButton.defaultProps = {};

export default TableActionButton;
