/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/tagMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Tag. */
export class Tag {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Tag.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TagGetTagSuggestionsResponse>
   */
  getTagSuggestions(options?: Models.TagGetTagSuggestionsOptionalParams): Promise<Models.TagGetTagSuggestionsResponse>;
  /**
   * @param callback The callback
   */
  getTagSuggestions(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getTagSuggestions(options: Models.TagGetTagSuggestionsOptionalParams, callback: msRest.ServiceCallback<string[]>): void;
  getTagSuggestions(options?: Models.TagGetTagSuggestionsOptionalParams | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.TagGetTagSuggestionsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getTagSuggestionsOperationSpec,
      callback) as Promise<Models.TagGetTagSuggestionsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TagAllResponse>
   */
  all(options?: Models.TagAllOptionalParams): Promise<Models.TagAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.TagDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.TagAllOptionalParams, callback: msRest.ServiceCallback<Models.TagDtoPagedResult>): void;
  all(options?: Models.TagAllOptionalParams | msRest.ServiceCallback<Models.TagDtoPagedResult>, callback?: msRest.ServiceCallback<Models.TagDtoPagedResult>): Promise<Models.TagAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.TagAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(options?: Models.TagDeleteMethodOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteMethod(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(options: Models.TagDeleteMethodOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(options?: Models.TagDeleteMethodOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param tag
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  create(tag: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param tag
   * @param callback The callback
   */
  create(tag: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param tag
   * @param options The optional parameters
   * @param callback The callback
   */
  create(tag: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  create(tag: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        tag,
        options
      },
      createOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getTagSuggestionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "tags/Tag/suggestions",
  queryParameters: [
    Parameters.query
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "tags/Tag",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TagDtoPagedResult
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "tags/Tag",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeleteTagSuggestionCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "tags/Tag/{tag}",
  urlParameters: [
    Parameters.tag
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};
