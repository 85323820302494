import './InterventionComment.less';

import React from 'react';

import { InterventionCommentDto, InterventionCommentsCommand } from './commands/InterventionCommentsCommand';
import Comment from './comment/index';
import { InterventionPath } from '@services/src/models';

interface IProps {
	data: InterventionCommentsCommand | undefined;
	refresh: () => void;
	editable: boolean;
	interventionSubjectId: string;
	interventionPath: InterventionPath;
	setEditable: (
		editable: boolean,
		id: string | undefined,
		visible: boolean
	) => void;
	checkOpinion: (comment: InterventionCommentDto) => boolean;
	readonlyMode: boolean;
}

const InterventionComment = (props: IProps) => {
	const { data } = props;

	return (
		<div className="antsay-ui">
			<div className="antsay-commentbox">
				<div className="antsay-commentbox-header">
					<p tabIndex={0}>Liczba komentarzy {data?.pagination.total}</p>
				</div>
			</div>
			<div className="antsay-comments">
				{data?.comments.map((comment, i) => (
					<Comment
						key={`comment-${i}`}
						data={comment}
						interventionSubjectId={props.interventionSubjectId}
						refresh={props.refresh}
						setEditable={props.setEditable}
						checkOpinion={props.checkOpinion}
						interventionPath={props.interventionPath}
						readonlyMode={props.readonlyMode}
					/>
				))}
			</div>

			{data?.pagination.hasNextPage && (
				<div className="antsay-more">Pokaż więcej</div>
			)}
		</div>
	);
};

export default InterventionComment;
