export enum DictionaryName {
	NotificationType,
	TrueOrFalse,
	SubjectStatus,
	SubjectKind,
	StatementStatus,
	StatementStatusWithoutArchived,
	StatementsStatusTableFilter,
	StatementArea,
	InterventionStatus,
	InterventionStatusWithoutArchived,
	InterventionStatusTableFilter,
	PatternStatus,
	InterventionPath,
	DictionaryVersionStatus,
	DictionaryValueStatus,
	DictionaryInterventionPath,
	ReportingArea,
	SubjectReportingProcessStatus,
	ProposalStatus,
	ProposalAccessType,
	DocumentProcessKind,
	DocumentStatus,
	DocumentKind,
	PageOrientation,
	FormStatus,
	AotmitOpinion,
	DocumentSubjectKind,
}
