import { WarningTwoTone } from '@ant-design/icons';
import { IInterventionCommentStore } from '@components/intervention/comments/store/InterventionCommentStore';
import BackButtons from '@components/shared/buttons/BackButtons';
import ValidationWarning from '@components/shared/modals/ValidationWarning';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import arrayHelpers from '@helper/arrayHelpers';
import { useRootData } from '@hooks/hook';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { Button, Modal, Space } from 'antd';
import { ActivityContext } from 'components/intervention/context/ActivityContext';
import ActivityFields from 'components/intervention/fields/ActivityFieldsCommand';
import { numberOfPeople } from 'components/intervention/model/InterventionCommand';
import { Formik, FormikActions, FormikProps } from 'formik';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import moment from 'moment';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import {
	ActivityDetailsDto,
	BusinessObjectType,
	RizpDictionaryValueDto,
	SubjectIdNameDto,
} from 'services/src/models';
import { DictionaryInterventionPath } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IRizpDictionaryStore } from 'stores/RizpDictionaryStore';

import { InterventionActivityCommand } from '../../model/InterventionBaseCommand';
import { ActivitySkeleton } from '../ActivitySkeleton';
import { updateActivityDictionaries } from '../model/ActivityDictionaries';
import {
	InterventionActivityValidationPatternSchema,
	InterventionActivityValidationSchema,
} from '../model/InterventionActivityValidation';

let data: InterventionActivityCommand | undefined = undefined;
let unchangedData: InterventionActivityCommand | undefined = undefined;

interface IProps {
	visible: boolean;
	mode: 'new' | 'edit' | 'clone' | 'clone_continuations' | 'preview';
	interventionId: string;
	activityId?: string;
	onOk: () => void;
	onCancel: () => void;
	interventionCreatedAt: Moment;
	isPattern: boolean;
	interventionPath: ProfiBazaAPIModels.InterventionPath;
	interventionEffectDictionaryValues?: RizpDictionaryValueDto[];
}
export interface IActivityDetailsState {
	activity: InterventionActivityCommand | undefined;
	patternActivity: ActivityDetailsDto | undefined;

	dateRange: [Moment?, Moment?] | undefined;
	loaded: boolean;
}

export const InterventionActivityCreate: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IActivityDetailsState>({
		activity: undefined,
		dateRange: undefined,
		patternActivity: undefined,
		loaded: false,
	});
	const rizpDictionaryStore: IRizpDictionaryStore = useRootData(
		(store) => store.rizpDictionaryStore
	);
	const [changed, setChanged] = useState<boolean>(false);
	const [submitClicked, setSubmitClicked] = useState<boolean>(false);

	const interventionCommentStore: IInterventionCommentStore = useRootData(
		(store) => store.interventionCommentStore
	);

	const [mergedSubjectMap, setMergedSubjectMap] = useState<
		Map<string, SubjectIdNameDto>
	>(new Map());

	useEffect(() => {
		if (state.activity && state.activity?.activityPatternId) {
			getProfiBazaApiClient()
				.then((api) =>
					api.activity.getById(state.activity!.activityPatternId!)
				)
				.then((response) => {
					setState({
						...state,
						patternActivity: response,
						loaded: true,
					});
				});
		}
	}, [state.activity?.id]);

	useEffect(() => {
		setState({ ...state, loaded: false });

		if (props.activityId) {
			ajaxCatch(() =>
				getProfiBazaApiClient()
					.then((pxy) => {
						rizpDictionaryStore.fetch(
							props.activityId!,
							BusinessObjectType.Activity
						);
						return pxy;
					})
					.then((api) => api.activity.getById(props.activityId!))
					.then((response: ActivityDetailsDto) => {
						const model = new InterventionActivityCommand(response);
						let copiedObject = JSON.parse(JSON.stringify(model));
						unchangedData = copiedObject;

						if (props.mode === 'clone') {
							model.primaryActivityId = undefined;
							model.id = undefined;
							model.activityPatternId = undefined;
							model.activityNumber = undefined;
						} else if (props.mode === 'clone_continuations') {
							model.primaryActivityId = model.id;
							model.id = undefined;
							model.activityPatternId = undefined;
							model.activityNumber = undefined;
						}

						const dateRange: [Moment?, Moment?] | undefined = [
							response?.dateFrom
								? moment(response.dateFrom)
								: undefined,
							response?.dateTo
								? moment(response.dateTo)
								: undefined,
						];

						if (props.mode !== 'preview') {
							updateActivityDictionaries(
								model,
								props.interventionPath,
								dateRange
							).then((model) => {
								setState({
									...state,
									activity: model.model,
									dateRange: [
										response?.dateFrom
											? moment(response.dateFrom)
											: undefined,
										response?.dateTo
											? moment(response.dateTo)
											: undefined,
									],
								});
								setChanged(model.changed);
							});
						} else {
							setState({
								...state,
								activity: model,
								loaded: true,
								dateRange: [
									response?.dateFrom
										? moment(response.dateFrom)
										: undefined,
									response?.dateTo
										? moment(response.dateTo)
										: undefined,
								],
							});
						}
					})
			);
		} else {
			setState({
				activity: {},
				dateRange: undefined,
				patternActivity: undefined,
				loaded: true,
			});
			unchangedData = {};
		}
	}, [props.mode, props.interventionId, props.activityId]);

	useEffect(() => {
		if (props.mode === 'preview') {
			setMergedSubjectMap(new Map());
			return;
		}

		if (state.activity?.id) {
			const subjectIds = [
				...(state.activity.sponsoringCompanies?.map(
					(x) => x.subjectId
				) ?? []),
				...(state.activity.implementingCompanies?.map(
					(x) => x.subjectId
				) ?? []),
				...(state.activity.coProducers?.map((x) => x.subjectId) ?? []),
			];

			ajaxCatch(() =>
				getProfiBazaApiClient()
					.then((api) =>
						api.subject.isMergedSubject({
							body: subjectIds.filter((x) => x) as string[],
						})
					)
					.then((response) => {
						const subjectMap = new Map<string, SubjectIdNameDto>();

						Object.keys(response).forEach((x) => {
							subjectMap.set(x, response[x]);
						});
						setMergedSubjectMap(subjectMap);
					})
			);
		}
	}, [state.activity]);

	const checkChanges = (value: any, key?: any) => {
		if (props.mode !== 'preview') {
			let obj: InterventionActivityCommand = {
				...data,
				[key]: value,
			};
			setChanged(!arrayHelpers.isEquivalent(state.activity, obj));
			data = obj;
		}
	};

	const create = (
		values: InterventionActivityCommand,
		actions: FormikActions<InterventionActivityCommand>
	) => {
		ajaxByUser(
			'Zapisano działanie',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.activity.create({
						body: {
							interventionId: props.interventionId,
							activity: InterventionActivityCommand.toCommand(
								values
							),
						},
					})
				),
			() => {
				props.onOk();
				actions.resetForm();
			},
			(errors) => {
				actions.setErrors(errors);
				actions.setSubmitting(false);
			}
		);
	};

	const update = (
		values: InterventionActivityCommand,
		actions: FormikActions<InterventionActivityCommand>
	) => {
		ajaxByUser(
			'Zapisano działanie',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.activity.update({
						body: {
							interventionId: props.interventionId,
							activity: InterventionActivityCommand.toCommand(
								values
							),
						},
					})
				),
			() => {
				props.onOk();
				actions.resetForm();
			},
			(errors) => {
				actions.setErrors(errors);
				actions.setSubmitting(false);
			}
		);
	};

	const handleCancel = (formik: FormikProps<InterventionActivityCommand>) => {
		props.onCancel();
		formik.resetForm();

		interventionCommentStore.filterParams.set({
			activityId: null,
			populationId: null,
			sponsoringCompanyId: null,
		});
		interventionCommentStore.resetLastFilterParams();
	};

	const handleFormSubmit = (
		formik: FormikProps<InterventionActivityCommand>
	) => () => {
		setSubmitClicked(true);
		formik.submitForm();
		interventionCommentStore.filterParams.set({
			activityId: null,
			populationId: null,
			sponsoringCompanyId: null,
		});
		interventionCommentStore.resetLastFilterParams();
	};

	return (
		<>
			<Formik
				initialValues={state.activity ?? {}}
				validateOnChange={false}
				validateOnBlur={true}
				enableReinitialize
				validationSchema={
					props.mode !== 'preview'
						? props.isPattern
							? InterventionActivityValidationPatternSchema(
									(props.interventionPath as string) as DictionaryInterventionPath,
									props.interventionEffectDictionaryValues as RizpDictionaryValueDto[]
							  )
							: InterventionActivityValidationSchema(
									(props.interventionPath as string) as DictionaryInterventionPath,
									props.interventionEffectDictionaryValues as RizpDictionaryValueDto[]
							  )
						: undefined
				}
				onSubmit={(values, actions) => {
					if (
						props.mode === 'new' ||
						props.mode === 'clone' ||
						props.mode === 'clone_continuations'
					) {
						create(values, actions);
					} else if (props.mode === 'edit') {
						update(values, actions);
					} else {
						props.onCancel();
						actions.resetForm();
					}
					setSubmitClicked(false);
				}}
				render={(formik: FormikProps<InterventionActivityCommand>) => (
					<Modal
						title={
							props.mode === 'new'
								? 'Dodaj działanie'
								: props.mode === 'edit'
								? 'Edytuj działanie'
								: 'Działanie'
						}
						destroyOnClose
						maskClosable={false}
						visible={props.visible}
						width="90%"
						style={{ maxWidth: '1366px' }}
						okText={
							props.mode === 'new' || props.mode === 'edit'
								? 'Zapisz'
								: 'Ok'
						}
						onOk={handleFormSubmit(formik)}
						cancelText="Wróć"
						okButtonProps={{
							htmlType: 'submit',
							disabled: formik.isSubmitting,
						}}
						onCancel={() => handleCancel(formik)}
						footer={[
							rizpDictionaryStore.valueChangedInActivity.get() &&
								props.mode !== 'preview' && (
									<Space
										direction="horizontal"
										style={{ float: 'left' }}
									>
										<WarningTwoTone twoToneColor="#ff0000" />
										Zmieniono wartości słowników, przed
										zapisem działania zweryfikuj wartości w
										zakładkach
									</Space>
								),

							<Space>
								<BackButtons
									onClick={() => {
										formik.submitForm();
									}}
									closeModal={() => handleCancel(formik)}
									size="large"
									disabled={formik.isSubmitting}
									backToCurrentPlace={true}
									visible={
										changed && props.mode !== 'preview'
									}
									aria-label="Anuluj"
								/>
								{props.mode !== 'preview' && (
									<Button
										type="primary"
										size="large"
										disabled={formik.isSubmitting}
										onClick={handleFormSubmit(formik)}
										aria-label="Zapisz"
									>
										Zapisz
									</Button>
								)}
							</Space>,
						]}
					>
						{state.loaded ? (
							<>
								<ValidationWarning
									isValidating={formik.isValidating}
									isValid={formik.isValid}
									submitClicked={submitClicked}
									setSubmitClicked={setSubmitClicked}
									errors={formik.errors}
								/>
								<ActivityContext.Provider
									value={{
										isPattern: props.isPattern,
										editable: props.mode !== 'preview',
										dateRange: state.dateRange,
										setDateRange: (value) =>
											setState({
												...state,
												dateRange: value,
											}),
										unchangedData: unchangedData,
										numberOfPeople: numberOfPeople,
										patternActivity: state.patternActivity,
										activity: state.activity,
										formik: formik,
										checkChanges: checkChanges,
										mergedSubjectMap: mergedSubjectMap,
										interventionId: props.interventionId,
										interventionCreatedAt:
											props.interventionCreatedAt,
										interventionPath: (props.interventionPath as string) as DictionaryInterventionPath,
										checkIfObjectValue: checkIfObjectValue,
									}}
								>
									<ActivityFields
										interventionId={props.interventionId}
										interventionPath={
											props.interventionPath
										}
									/>
								</ActivityContext.Provider>
							</>
						) : (
							<ActivitySkeleton />
						)}
					</Modal>
				)}
			/>
		</>
	);
};

const checkIfObjectValue = (object: any, key?: any) => {
	return (
		object != null &&
		object[key] !== undefined &&
		object[key] !== null &&
		(!Array.isArray(object[key]) ||
			(Array.isArray(object[key]) && object[key]?.length > 0))
	);
};

export default InterventionActivityCreate;
