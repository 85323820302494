import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';

const columns: ProfiBazaColumnProps<SubjectMergeListItemDto>[] = [
	{
		sorter: true,
		dataIndex: 'codeName',
		key: 'codeName',
		title: 'Id',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'name',
		key: 'name',
		title: 'Nazwa',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'address',
		key: 'address',
		title: 'Adres',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'nip',
		key: 'nip',
		title: 'NIP',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'regon',
		key: 'regon',
		title: 'REGON',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'krsCode',
		key: 'krsCode',
		title: 'Identyfikator KRS',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'cruipCode',
		key: 'cruipCode',
		title: 'CRUiP',
		filter: 'contains',
	},
];

export default columns;

export interface SubjectMergeListItemDto {
	id?: string;
	codeName?: string;
	name?: string;
	address?: string;
}
