import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<ProfiBazaAPIModels.ReportingArea, string>([
	[ProfiBazaAPIModels.ReportingArea.NPZ, 'Sprawozdanie z Zadań Zdrowia Publicznego'],
	[ProfiBazaAPIModels.ReportingArea.MZ49, 'MZ-49'],
	[ProfiBazaAPIModels.ReportingArea.MZ53, 'MZ-53'],
]);

export const map = (value: ProfiBazaAPIModels.ReportingArea): string =>
	mapings.get(value) ?? '';
