import { Moment } from 'moment';
import { filterType } from '../GridHelper';
import { NationWideTeryt } from 'components/shared/teryt/TerytAreaPicker/TeryAreaMappers';

export default class SieveQueryBuilder {
	protected query: string = '';

	public default(
		key: string,
		value: string | string[] | any,
		filter: filterType
	): void {
		if (key === undefined || key === '' || value === undefined) return;
		if (Array.isArray(value) && value.length === 0) return;
		switch (filter) {
			case 'contains':
				return this.contains(key, value);
			case 'equals':
				return this.equals(key, value);
			case 'dictionary':
				return this.containsAnyElement(key, value);
			case 'dateRange':
				return this.dateBetween(key, value);
			case 'teryt':
				if (value.terytCode === undefined) return;
				return this.contains(
					key,
					`#${value.terytCode}|#${NationWideTeryt.terytCode}`
				);
			default:
				throw `Typ filtrowania ${filter} nie jest domyślnie obsługiwany`;
		}
	}

	public contains(key: string, value: string): void {
		if (value === undefined || value === '') return;
		if (key.includes('|')) this.checkFilter(key, value);
		else this.query += key + '@=*' + value + ',';
	}

	public equals(key: string, value: string): void {
		if (value === undefined || value === '') return;

		this.query += key + '==' + value + ',';
	}

	public containsAnyElement(key: string, values: string[]): void {
		if (values === undefined || values.length == 0) return;

		this.query += key + '==' + values.join('|') + ',';
	}

	public dateBetween(key: string, values: Moment[]): void {
		if (values === undefined || values.length == 0) return;

		if (values.length > 0 && values[0] != null) {
			this.query += key + '>=' + values[0].startOf('day').format() + ',';
		}

		if (values.length > 1 && values[1] != null) {
			this.query += key + '<=' + values[1].endOf('day').format() + ',';
		}
	}

	public checkFilter(key: string, value: string): void {
		if (value === undefined || value.length == 0) return;
		var tmpQuery = '';
		tmpQuery += key + '@=*' + value + ',';
		tmpQuery += this.query;
		this.query = tmpQuery;
	}

	public build(): string {
		this.query = this.query.slice(0, -1);
		return this.query;
	}
}
