/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/notificationDefinitionMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a NotificationDefinition. */
export class NotificationDefinition {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a NotificationDefinition.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.NotificationDefinitionGetAllResponse>
   */
  getAll(options?: msRest.RequestOptionsBase): Promise<Models.NotificationDefinitionGetAllResponse>;
  /**
   * @param callback The callback
   */
  getAll(callback: msRest.ServiceCallback<Models.NotificationDefinitionDTO[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAll(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.NotificationDefinitionDTO[]>): void;
  getAll(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.NotificationDefinitionDTO[]>, callback?: msRest.ServiceCallback<Models.NotificationDefinitionDTO[]>): Promise<Models.NotificationDefinitionGetAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getAllOperationSpec,
      callback) as Promise<Models.NotificationDefinitionGetAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.NotificationDefinitionUpdateResponse>
   */
  update(options?: Models.NotificationDefinitionUpdateOptionalParams): Promise<Models.NotificationDefinitionUpdateResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.NotificationDefinitionUpdateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  update(options?: Models.NotificationDefinitionUpdateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.NotificationDefinitionUpdateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback) as Promise<Models.NotificationDefinitionUpdateResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.NotificationDefinitionGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.NotificationDefinitionGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.NotificationDefinitionDTO>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.NotificationDefinitionDTO>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.NotificationDefinitionDTO>, callback?: msRest.ServiceCallback<Models.NotificationDefinitionDTO>): Promise<Models.NotificationDefinitionGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.NotificationDefinitionGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.NotificationDefinitionActivateResponse>
   */
  activate(options?: Models.NotificationDefinitionActivateOptionalParams): Promise<Models.NotificationDefinitionActivateResponse>;
  /**
   * @param callback The callback
   */
  activate(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  activate(options: Models.NotificationDefinitionActivateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  activate(options?: Models.NotificationDefinitionActivateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.NotificationDefinitionActivateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      activateOperationSpec,
      callback) as Promise<Models.NotificationDefinitionActivateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.NotificationDefinitionDeactivateResponse>
   */
  deactivate(options?: Models.NotificationDefinitionDeactivateOptionalParams): Promise<Models.NotificationDefinitionDeactivateResponse>;
  /**
   * @param callback The callback
   */
  deactivate(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deactivate(options: Models.NotificationDefinitionDeactivateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  deactivate(options?: Models.NotificationDefinitionDeactivateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.NotificationDefinitionDeactivateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deactivateOperationSpec,
      callback) as Promise<Models.NotificationDefinitionDeactivateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.NotificationDefinitionUpdateChannelsResponse>
   */
  updateChannels(options?: Models.NotificationDefinitionUpdateChannelsOptionalParams): Promise<Models.NotificationDefinitionUpdateChannelsResponse>;
  /**
   * @param callback The callback
   */
  updateChannels(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateChannels(options: Models.NotificationDefinitionUpdateChannelsOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateChannels(options?: Models.NotificationDefinitionUpdateChannelsOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.NotificationDefinitionUpdateChannelsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateChannelsOperationSpec,
      callback) as Promise<Models.NotificationDefinitionUpdateChannelsResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getAllOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "notifications/NotificationDefinition",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NotificationDefinitionDTO"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "notifications/NotificationDefinition",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateNotificationDefinionCommand
  },
  responses: {
    204: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "notifications/NotificationDefinition/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.NotificationDefinitionDTO
    },
    default: {}
  },
  serializer
};

const activateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "notifications/NotificationDefinition/activate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ActivateNotificationDefinionCommand
  },
  responses: {
    204: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const deactivateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "notifications/NotificationDefinition/deactivate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeactivateNotificationDefinionCommand
  },
  responses: {
    204: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const updateChannelsOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "notifications/NotificationDefinition/updateChannels",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateNotificationDefinionChannelsCommand
  },
  responses: {
    204: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};
