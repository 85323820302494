export class ProHealthActivities {
	static Intervention: Record<string, Record<string, string>> = {
		Ogólne: {
			general: 'Ogólny',
		},
		'Dane podstawowe': {
			interventionPath: 'Ścieżka interwencji',
			interventionType: 'Typ interwencji',
			interventionOwner: 'Właściciel',
			interventionNumber: 'Numer',
			interventionStatus: 'Status',
			creationDate: 'Data utworzenia',
			realizationDate: 'Data realizacji (od-do)',
			realizationBudget: 'Budżet realizacji',
			plannedBudgetInfo: 'Budżet planowany (pole informacyjne)',
			recommendation: 'Rekomendacja AOTMiT',
			aotmitNumber: 'Numer AOTMiT',
			opinionAOTMiT: 'Decyzja AOTMiT',
			dateOfDecisionAOTMiT: 'Data decyzji AOTMiT',
			name: 'Nazwa interwencji',
			implementationPeriod: 'Typ okresu realizacji',
			interventionArea: 'Zasięg interwencji',
			areaTerytCodes: 'Teren interwencji',
			legalBases: 'Podstawa prawna',
			interventionPartners: 'Partnerzy',
			numberOfRecipients: 'Liczba odbiorców',
			annualNumberOfRecipients: 'Roczna liczba odbiorców',
			numberOfPersonAction: 'Liczba osobodziałań',
			plannedDateRange: 'Data planowana',
			coOwners: 'Współwłaściciele',
		},
		'Dane kontaktowe': {
			firstName: 'Imię',
			lastName: 'Nazwisko',
			position: 'Stanowisko',
			email: 'E-mail',
			phoneNumber: 'Telefon',
		},
	};

	static ActivityBasic: Record<string, Record<string, string>> = {
		'Dane podstawowe (działania)': {
			activityName: 'Nazwa',
			activityNumber: 'Numer',
			activityRealizationDate: 'Data realizacji',
			activityCategory: 'Kategoria działania',
			activityPublicHealthTask: 'Zadania zdrowia publicznego',
			activityNPZTaskNumber: 'Numer zadania NPZ',
			activityPRPZ: 'Priorytety dla Regionalnej Polityki Zdrowotnej/Wojewódzki Plan Transformacji',
			activityDescription: 'Opis',
			activityComment: 'Uwagi',
			activityTopic: 'Temat (Efekty interwencji)',
			activityNumberOfRecipients: 'Liczba odbiorców',
			activityNumberOfPersonAction: 'Liczba osobodziałań',
		},
	};

	static ActivityPopulation: Record<string, Record<string, string>> = {
		'Populacja (działania)': {
			populationGender: 'Cecha populacji - płeć',
			populationAge: 'Cecha populacji - wiek',
			populationMin: 'Wartość min',
			populationMax: 'Wartość max',
			populationNumberOfRecipients: 'Populacja - liczba odbiorców',
			populationTarget:
				'Populacja docelowa ze względu na miejsce zamieszkania',
			populationLocation: 'Cecha populacji - miejsce interwencji',
			populationHealthConditions: 'Cecha populacji - stan zdrowia',
			populationSocialExclusions:
				'Cecha populacji - wykluczenie społeczne/zagrożenie wykluczeniem społecznym',
			populationOtherSpecialFeatures:
				'Cecha populacji - inne szczególne cechy',
		},
	};

	static ActivityHealthProblem: Record<string, Record<string, string>> = {
		'Problem zdrowotny (działania)': {
			activityChosenPopulationHealths: 'Zdrowie wybranych populacji',
			activityCareAreas: 'Obszary opieki',
			activityDiseasesMortalityPrevalences:
				'Międzynarodowa Statystyczna Klasyfikacja Chorób i Problemów Zdrowotnych ICD-10',
		},
	};

	static ActivityImplementingCompany: Record<
		string,
		Record<string, string>
	> = {
			'Podmioty realizujące (działania)': {
				implementingCompany: 'Komentarz ogólny - podmioty realizujące',
			},
		};

	static ActivitySponsoringCompany: Record<string, Record<string, string>> = {
		'Podmioty finansujące (działania)': {
			sponsoringCompanySubject: 'Podmiot',
			sponsoringCompanySource: 'Źródło finansowania',
			sponsoringCompanyPlannedCosts: 'Koszty planowane',
			sponsoringCompanyRealizationCosts: 'Koszty realizacji',
			sponsoringCompanyIsMainSubject: 'Główny podmiot finansujący',
		},
	};

	static ActivityIKP: Record<string, Record<string, string>> = {
		'IKP (działania)': {
			ikpIncludeCriteria: 'Kryteria włączenia',
			ikpExcludeCriteria: 'Kryteria wyłączenia',
			ikpBenefits: 'Korzyści dla uczestnika programu',
			ikpSourceOfKnowledge: 'Źródło wiedzy o programie',
			publishedInIkp: 'działanie publikowane w IKP',
			ikpRecrutationDate: 'Data rekrutacji',
			ikpState: 'Status rekrutacji',
		},
	};

	static ActivityAdditionalData: Record<string, Record<string, string>> = {
		'Dane dodatkowe (działania)': {
			additionalData: 'Komentarz ogólny - dane dodatkowe',
		},
	};
}
export class Research {
	static Intervention: Record<string, Record<string, string>> = {
		Ogólne: {
			general: 'Ogólny',
		},
		'Dane podstawowe': {
			interventionPath: 'Ścieżka interwencji',
			interventionOwner: 'Właściciel',
			interventionNumber: 'Numer',
			interventionStatus: 'Status',
			creationDate: 'Data utworzenia',
			realizationDate: 'Data realizacji (od-do)',
			realizationBudget: 'Budżet realizacji',
			name: 'Nazwa interwencji',
			projectKind: 'Rodzaj projektu',
			projectEffect: 'Efekty projektu',
			implementationPeriod: 'Typ okresu realizacji',
			contractorsQty: 'Liczba wykonawców projektu',
			plannedBudget: 'Budżet planowany',
			interventionArea: 'Zasięg interwencji',
			legalBases: 'Podstawa prawna',
			gbaordClassification: 'Klasyfikacja GBAORD',
			ncnClassification: 'Klasyfikacja NCN',
			disciplineAreaClassification: 'Klasyfikacja obszar dyscyplina',
			coProducers: 'Współrealizatorzy',
		},
		'Dane kontaktowe': {
			firstName: 'Imię',
			lastName: 'Nazwisko',
			position: 'Stanowisko',
			email: 'E-mail',
			phoneNumber: 'Telefon',
		},
	};

	static ActivityBasic: Record<string, Record<string, string>> = {
		'Dane podstawowe (działania)': {
			activityName: 'Nazwa',
			activityNumber: 'Numer',
			activityRealizationDate: 'Data realizacji',
			activityCategory: 'Kategoria działania',
			activityPublicHealthTask: 'Zadania zdrowia publicznego',
			activityNPZTaskNumber: 'Numer zadania NPZ',
			activityPRPZ: 'Priorytety dla Regionalnej Polityki Zdrowotnej/Wojewódzki Plan Transformacji',
			activityDescription: 'Opis',
			activityComment: 'Uwagi',
			activityTopic: 'Temat (Efekty interwencji)',
			activityNumberOfRecipients: 'Liczba odbiorców',
		},
	};

	static ActivityHealthProblem: Record<string, Record<string, string>> = {
		'Problem zdrowotny (działania)': {
			activityChosenPopulationHealths: 'Zdrowie wybranych populacji',
			activityDiseasesMortalityPrevalences:
				'Międzynarodowa Statystyczna Klasyfikacja Chorób i Problemów Zdrowotnych ICD-10',
		},
	};

	static ActivityImplementingCompany: Record<
		string,
		Record<string, string>
	> = {
			'Podmioty realizujące (działania)': {
				implementingCompany: 'Komentarz ogólny - podmioty realizujące',
			},
		};

	static ActivitySponsoringCompany: Record<string, Record<string, string>> = {
		'Podmioty finansujące (działania)': {
			sponsoringCompanySubject: 'Podmiot',
			sponsoringCompanySource: 'Źródło finansowania',
			sponsoringCompanyRealizationCosts: 'Koszty realizacji',
			sponsoringCompanyIsMainSubject: 'Główny podmiot finansujący',
		},
	};

	static ActivityIKP: Record<string, Record<string, string>> = {
		'IKP (działania)': {
			ikpIncludeCriteria: 'Kryteria włączenia',
			ikpExcludeCriteria: 'Kryteria wyłączenia',
			ikpBenefits: 'Korzyści dla uczestnika programu',
			ikpSourceOfKnowledge: 'Źródło wiedzy o programie',
			publishedInIkp: 'działanie publikowane w IKP',
			ikpRecrutationDate: 'Data rekrutacji',
			ikpState: 'Status rekrutacji',
		},
	};

	static ActivityAdditionalData: Record<string, Record<string, string>> = {
		'Dane dodatkowe (działania)': {
			additionalData: 'Komentarz ogólny - dane dodatkowe',
		},
	};
}

export class SupervisionSanitary {
	static Intervention: Record<string, Record<string, string>> = {
		Ogólne: {
			general: 'Ogólny',
		},
		'Dane podstawowe': {
			interventionPath: 'Ścieżka interwencji',
			interventionOwner: 'Właściciel',
			interventionNumber: 'Numer',
			interventionStatus: 'Status',
			creationDate: 'Data utworzenia',
			realizationDate: 'Data realizacji (od-do)',
			realizationBudget: 'Budżet realizacji',
			name: 'Nazwa interwencji',
			interventionKind: 'Rodzaj interwencji',
			implementationPeriod: 'Typ okresu realizacji',
			interventionArea: 'Zasięg interwencji',
			areaTerytCodes: 'Teren interwencji',
			legalBases: 'Podstawa prawna',
			interventionPartners: 'Partnerzy',
			interventionEffect: 'Efekty interwencji',
			dataSource: 'Źródła danych',
		},
		'Dane kontaktowe': {
			firstName: 'Imię',
			lastName: 'Nazwisko',
			position: 'Stanowisko',
			email: 'E-mail',
			phoneNumber: 'Telefon',
		},
	};

	static ActivityBasic: Record<string, Record<string, string>> = {
		'Dane podstawowe (działania)': {
			activityName: 'Nazwa',
			activityNumber: 'Numer',
			activityRealizationDate: 'Data realizacji',
			activityCategory: 'Kategoria działania',
			activityPublicHealthTask: 'Zadania zdrowia publicznego',
			activityNPZTaskNumber: 'Numer zadania NPZ',
			activityPRPZ: 'Priorytety dla Regionalnej Polityki Zdrowotnej/Wojewódzki Plan Transformacji',
			activityDescription: 'Opis',
			activityComment: 'Uwagi',
			activityTopic: 'Temat (Efekty interwencji)',
		},
	};

	static ActivityHealthProblem: Record<string, Record<string, string>> = {
		'Problem zdrowotny (działania)': {
			activityChosenPopulationHealths: 'Zdrowie wybranych populacji',
			activityDiseasesMortalityPrevalences:
				'Międzynarodowa Statystyczna Klasyfikacja Chorób i Problemów Zdrowotnych ICD-10',
		},
	};

	static ActivityImplementingCompany: Record<
		string,
		Record<string, string>
	> = {
			'Podmioty realizujące (działania)': {
				implementingCompany: 'Komentarz ogólny - podmioty realizujące',
			},
		};

	static ActivitySponsoringCompany: Record<string, Record<string, string>> = {
		'Podmioty finansujące (działania)': {
			sponsoringCompanySubject: 'Podmiot',
			sponsoringCompanySource: 'Źródło finansowania',
			sponsoringCompanyRealizationCosts: 'Koszty realizacji',
			sponsoringCompanyIsMainSubject: 'Główny podmiot finansujący',
		},
	};

	static ActivityIKP: Record<string, Record<string, string>> = {
		'IKP (działania)': {
			ikpIncludeCriteria: 'Kryteria włączenia',
			ikpExcludeCriteria: 'Kryteria wyłączenia',
			ikpBenefits: 'Korzyści dla uczestnika programu',
			ikpSourceOfKnowledge: 'Źródło wiedzy o programie',
			publishedInIkp: 'działanie publikowane w IKP',
			ikpRecrutationDate: 'Data rekrutacji',
			ikpState: 'Status rekrutacji',
		},
	};

	static ActivityAdditionalData: Record<string, Record<string, string>> = {
		'Dane dodatkowe (działania)': {
			additionalData: 'Komentarz ogólny - dane dodatkowe',
		},
	};
}

export class SupervisionMonitoring {
	static Intervention: Record<string, Record<string, string>> = {
		Ogólne: {
			general: 'Ogólny',
		},
		'Dane podstawowe': {
			interventionPath: 'Ścieżka interwencji',
			interventionOwner: 'Właściciel',
			interventionNumber: 'Numer',
			interventionStatus: 'Status',
			creationDate: 'Data utworzenia',
			realizationDate: 'Data realizacji (od-do)',
			realizationBudget: 'Budżet realizacji',
			name: 'Nazwa interwencji',
			interventionKind: 'Rodzaj interwencji',
			implementationPeriod: 'Typ okresu realizacji',
			interventionArea: 'Zasięg interwencji',
			areaTerytCodes: 'Teren interwencji',
			legalBases: 'Podstawa prawna',
			interventionPartners: 'Partnerzy',
			interventionEffect: 'Efekty interwencji',
			dataSource: 'Źródła danych',
		},
		'Dane kontaktowe': {
			firstName: 'Imię',
			lastName: 'Nazwisko',
			position: 'Stanowisko',
			email: 'E-mail',
			phoneNumber: 'Telefon',
		},
	};

	static ActivityBasic: Record<string, Record<string, string>> = {
		'Dane podstawowe (działania)': {
			activityName: 'Nazwa',
			activityNumber: 'Numer',
			activityRealizationDate: 'Data realizacji',
			activityCategory: 'Kategoria działania',
			activityPublicHealthTask: 'Zadania zdrowia publicznego',
			activityNPZTaskNumber: 'Numer zadania NPZ',
			activityPRPZ: 'Priorytety dla Regionalnej Polityki Zdrowotnej/Wojewódzki Plan Transformacji',
			activityDescription: 'Opis',
			activityComment: 'Uwagi',
			activityTopic: 'Temat (Efekty interwencji)',
		},
	};

	static ActivityHealthProblem: Record<string, Record<string, string>> = {
		'Problem zdrowotny (działania)': {
			activityChosenPopulationHealths: 'Zdrowie wybranych populacji',
			activityDiseasesMortalityPrevalences:
				'Międzynarodowa Statystyczna Klasyfikacja Chorób i Problemów Zdrowotnych ICD-10',
		},
	};

	static ActivityImplementingCompany: Record<
		string,
		Record<string, string>
	> = {
			'Podmioty realizujące (działania)': {
				implementingCompany: 'Komentarz ogólny - podmioty realizujące',
			},
		};

	static ActivitySponsoringCompany: Record<string, Record<string, string>> = {
		'Podmioty finansujące (działania)': {
			sponsoringCompanySubject: 'Podmiot',
			sponsoringCompanySource: 'Źródło finansowania',
			sponsoringCompanyRealizationCosts: 'Koszty realizacji',
			sponsoringCompanyIsMainSubject: 'Główny podmiot finansujący',
		},
	};

	static ActivityIKP: Record<string, Record<string, string>> = {
		'IKP (działania)': {
			ikpIncludeCriteria: 'Kryteria włączenia',
			ikpExcludeCriteria: 'Kryteria wyłączenia',
			ikpBenefits: 'Korzyści dla uczestnika programu',
			ikpSourceOfKnowledge: 'Źródło wiedzy o programie',
			publishedInIkp: 'działanie publikowane w IKP',
			ikpRecrutationDate: 'Data rekrutacji',
			ikpState: 'Status rekrutacji',
		},
	};

	static ActivityAdditionalData: Record<string, Record<string, string>> = {
		'Dane dodatkowe (działania)': {
			additionalData: 'Komentarz ogólny - dane dodatkowe',
		},
	};
}

export class StaffEducation {
	static Intervention: Record<string, Record<string, string>> = {
		Ogólne: {
			general: 'Ogólny',
		},
		'Dane podstawowe': {
			interventionPath: 'Ścieżka interwencji',
			interventionOwner: 'Właściciel',
			interventionNumber: 'Numer',
			interventionStatus: 'Status',
			creationDate: 'Data utworzenia',
			realizationDate: 'Data realizacji (od-do)',
			realizationBudget: 'Budżet realizacji',
			name: 'Nazwa interwencji',
			interventionKind: 'Rodzaj interwencji',
			implementationPeriod: 'Typ okresu realizacji',
			interventionArea: 'Zasięg interwencji',
			areaTerytCodes: 'Teren interwencji',
			legalBases: 'Podstawa prawna',
			interventionEffect: 'Efekty interwencji',
			coOwners: 'Współwłaściciele',
			educationImplementationMode: 'Tryb realizacji',
		},
		'Dane kontaktowe': {
			firstName: 'Imię',
			lastName: 'Nazwisko',
			position: 'Stanowisko',
			email: 'E-mail',
			phoneNumber: 'Telefon',
		},
	};

	static ActivityBasic: Record<string, Record<string, string>> = {
		'Dane podstawowe (działania)': {
			activityName: 'Nazwa',
			activityNumber: 'Numer',
			activityRealizationDate: 'Data realizacji',
			activityCategory: 'Kategoria działania',
			activityPublicHealthTask: 'Zadania zdrowia publicznego',
			activityNPZTaskNumber: 'Numer zadania NPZ',
			activityPRPZ: 'Priorytety dla Regionalnej Polityki Zdrowotnej/Wojewódzki Plan Transformacji',
			activityDescription: 'Opis',
			activityComment: 'Uwagi',
			activityTopic: 'Temat (Efekty interwencji)',
			activityNumberOfRecipients: 'Liczba odbiorców',
		},
	};

	static ActivityImplementingCompany: Record<
		string,
		Record<string, string>
	> = {
			'Podmioty realizujące (działania)': {
				implementingCompany: 'Komentarz ogólny - podmioty realizujące',
			},
		};

	static ActivitySponsoringCompany: Record<string, Record<string, string>> = {
		'Podmioty finansujące (działania)': {
			sponsoringCompanySubject: 'Podmiot',
			sponsoringCompanySource: 'Źródło finansowania',
			sponsoringCompanyRealizationCosts: 'Koszty realizacji',
			sponsoringCompanyIsMainSubject: 'Główny podmiot finansujący',
		},
	};

	static ActivityAdditionalData: Record<string, Record<string, string>> = {
		'Dane dodatkowe (działania)': {
			additionalData: 'Komentarz ogólny - dane dodatkowe',
		},
	};
}

export class StaffMonitoring {
	static Intervention: Record<string, Record<string, string>> = {
		Ogólne: {
			general: 'Ogólny',
		},
		'Dane podstawowe': {
			interventionPath: 'Ścieżka interwencji',
			interventionOwner: 'Właściciel',
			interventionNumber: 'Numer',
			interventionStatus: 'Status',
			creationDate: 'Data utworzenia',
			realizationDate: 'Data realizacji (od-do)',
			realizationBudget: 'Budżet realizacji',
			name: 'Nazwa interwencji',
			interventionKind: 'Rodzaj interwencji',
			implementationPeriod: 'Typ okresu realizacji',
			interventionArea: 'Zasięg interwencji',
			areaTerytCodes: 'Teren interwencji',
			legalBases: 'Podstawa prawna',
			interventionPartners: 'Partnerzy',
			interventionEffect: 'Efekty interwencji',
			dataSource: 'Źródła danych',
		},
		'Dane kontaktowe': {
			firstName: 'Imię',
			lastName: 'Nazwisko',
			position: 'Stanowisko',
			email: 'E-mail',
			phoneNumber: 'Telefon',
		},
	};

	static ActivityBasic: Record<string, Record<string, string>> = {
		'Dane podstawowe (działania)': {
			activityName: 'Nazwa',
			activityNumber: 'Numer',
			activityRealizationDate: 'Data realizacji',
			activityCategory: 'Kategoria działania',
			activityPublicHealthTask: 'Zadania zdrowia publicznego',
			activityNPZTaskNumber: 'Numer zadania NPZ',
			activityPRPZ: 'Priorytety dla Regionalnej Polityki Zdrowotnej/Wojewódzki Plan Transformacji',
			activityDescription: 'Opis',
			activityComment: 'Uwagi',
			activityTopic: 'Temat (Efekty interwencji)',
		},
	};

	static ActivityHealthProblem: Record<string, Record<string, string>> = {
		'Problem zdrowotny (działania)': {
			activityChosenPopulationHealths: 'Zdrowie wybranych populacji',
			activityDiseasesMortalityPrevalences:
				'Międzynarodowa Statystyczna Klasyfikacja Chorób i Problemów Zdrowotnych ICD-10',
		},
	};

	static ActivityImplementingCompany: Record<
		string,
		Record<string, string>
	> = {
			'Podmioty realizujące (działania)': {
				implementingCompany: 'Komentarz ogólny - podmioty realizujące',
			},
		};

	static ActivitySponsoringCompany: Record<string, Record<string, string>> = {
		'Podmioty finansujące (działania)': {
			sponsoringCompanySubject: 'Podmiot',
			sponsoringCompanySource: 'Źródło finansowania',
			sponsoringCompanyRealizationCosts: 'Koszty realizacji',
			sponsoringCompanyIsMainSubject: 'Główny podmiot finansujący',
		},
	};

	static ActivityIKP: Record<string, Record<string, string>> = {
		'IKP (działania)': {
			ikpIncludeCriteria: 'Kryteria włączenia',
			ikpExcludeCriteria: 'Kryteria wyłączenia',
			ikpBenefits: 'Korzyści dla uczestnika programu',
			ikpSourceOfKnowledge: 'Źródło wiedzy o programie',
			publishedInIkp: 'działanie publikowane w IKP',
			ikpRecrutationDate: 'Data rekrutacji',
			ikpState: 'Status rekrutacji',
		},
	};

	static ActivityAdditionalData: Record<string, Record<string, string>> = {
		'Dane dodatkowe (działania)': {
			additionalData: 'Komentarz ogólny - dane dodatkowe',
		},
	};
}

export class Legislation {
	static Intervention: Record<string, Record<string, string>> = {
		Ogólne: {
			general: 'Ogólny',
		},
		'Dane podstawowe': {
			interventionPath: 'Ścieżka interwencji',
			interventionOwner: 'Właściciel',
			interventionNumber: 'Numer',
			interventionStatus: 'Status',
			creationDate: 'Data utworzenia',
			realizationDate: 'Data realizacji (od-do)',
			name: 'Nazwa interwencji',
			interventionKind: 'Rodzaj interwencji',
			interventionArea: 'Zasięg interwencji',
			areaTerytCodes: 'Teren interwencji',
			legalBases: 'Podstawa prawna',
			interventionEffect: 'Efekty interwencji',
		},
		'Dane kontaktowe': {
			firstName: 'Imię',
			lastName: 'Nazwisko',
			position: 'Stanowisko',
			email: 'E-mail',
			phoneNumber: 'Telefon',
		},
	};

	static ActivityBasic: Record<string, Record<string, string>> = {
		'Dane podstawowe (działania)': {
			activityName: 'Nazwa',
			activityNumber: 'Numer',
			activityRealizationDate: 'Data realizacji',
			activityCategory: 'Kategoria działania',
			activityPublicHealthTask: 'Zadania zdrowia publicznego',
			activityNPZTaskNumber: 'Numer zadania NPZ',
			activityPRPZ: 'Priorytety dla Regionalnej Polityki Zdrowotnej/Wojewódzki Plan Transformacji',
			activityDescription: 'Opis',
			activityComment: 'Uwagi',
			activityTopic: 'Temat (Efekty interwencji)',
		},
	};

	static ActivityHealthProblem: Record<string, Record<string, string>> = {
		'Problem zdrowotny (działania)': {
			activityChosenPopulationHealths: 'Zdrowie wybranych populacji',
			activityDiseasesMortalityPrevalences:
				'Międzynarodowa Statystyczna Klasyfikacja Chorób i Problemów Zdrowotnych ICD-10',
		},
	};

	static ActivityImplementingCompany: Record<
		string,
		Record<string, string>
	> = {
			'Podmioty realizujące (działania)': {
				implementingCompany: 'Komentarz ogólny - podmioty realizujące',
			},
		};

	static ActivitySponsoringCompany: Record<string, Record<string, string>> = {
		'Podmioty finansujące (działania)': {
			sponsoringCompanySubject: 'Podmiot',
			sponsoringCompanySource: 'Źródło finansowania',
			sponsoringCompanyRealizationCosts: 'Koszty realizacji',
			sponsoringCompanyIsMainSubject: 'Główny podmiot finansujący',
		},
	};

	static ActivityIKP: Record<string, Record<string, string>> = {
		'IKP (działania)': {
			ikpIncludeCriteria: 'Kryteria włączenia',
			ikpExcludeCriteria: 'Kryteria wyłączenia',
			ikpBenefits: 'Korzyści dla uczestnika programu',
			ikpSourceOfKnowledge: 'Źródło wiedzy o programie',
			publishedInIkp: 'działanie publikowane w IKP',
			ikpRecrutationDate: 'Data rekrutacji',
			ikpState: 'Status rekrutacji',
		},
	};

	static ActivityAdditionalData: Record<string, Record<string, string>> = {
		'Dane dodatkowe (działania)': {
			additionalData: 'Komentarz ogólny - dane dodatkowe',
		},
	};
}
