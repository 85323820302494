import { Card, Col, Row, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Modal from 'antd/lib/modal/Modal';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FCheckbox,
	FFieldLabel,
	FInputNumber,
	FRangePicker,
	FTextArea,
} from 'forms/FormikFormItems';
import { ajaxByUser } from 'helper/api';
import React, { useImperativeHandle, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';

import { CreateNotificationOccurrenceCommand } from './commands/CreateNotificationOccurrenceCommandMapper';
import { UserPrivateOccurrenceValidationSchema } from './validators/UserPrivateOccurrenceValidationSchema';

interface IProps {
	onCreate: () => void;
}

interface IState {
	visible: boolean;
}

export interface ICreateUserPrivateOccurrenceModal {
	showModal: () => void;
}

const CreateUserPrivateOccurrenceModal = React.forwardRef<
	ICreateUserPrivateOccurrenceModal,
	IProps
>((props, ref) => {
	const [state, setState] = useState<IState>({
		visible: false,
	});

	useImperativeHandle(ref, () => ({
		showModal: () => {
			setState({
				...state,
				visible: true,
			});
		},
	}));

	return (
		<Formik
			validateOnChange={true}
			validateOnBlur={false}
			initialValues={{}}
			enableReinitialize
			validationSchema={UserPrivateOccurrenceValidationSchema}
			onSubmit={(
				values: CreateNotificationOccurrenceCommand,
				actions: FormikActions<CreateNotificationOccurrenceCommand>
			) => {
				ajaxByUser(
					'Stworzono zdarzenie prywatne',
					() =>
						getProfiBazaApiClient().then((api) =>
							api.notificationOccurrence.create({
								body: CreateNotificationOccurrenceCommand.toCreateCommand(
									values
								),
							})
						),
					() => {
						setState({
							...state,
							visible: false,
						});
						actions.resetForm();
						props.onCreate();
					},
					(errors) => {
						actions.setErrors(errors);
					}
				).then(() => actions.setSubmitting(false));
			}}
			render={(
				props: FormikProps<CreateNotificationOccurrenceCommand>
			) => (
				<Modal
					visible={state.visible}
					maskClosable={false}
					centered
					title="Dodaj zdarzenie"
					closable={false}
					onOk={() => {
						if (!props.isSubmitting) props.submitForm();
					}}
					onCancel={() => {
						setState({
							...state,
							visible: false,
						});
						props.resetForm();
					}}
					okButtonProps={{ disabled: props.isSubmitting }}
					cancelButtonProps={{ disabled: props.isSubmitting }}
					cancelText="Anuluj"
					okText="Dodaj"
					destroyOnClose
				>
					<Row>
						<Col span={16}>
							<FFieldLabel label={'Data zdarzenia'} />
							<Field
								component={FRangePicker}
								style={{
									width: '100%',
								}}
								inputReadOnly={false}
								name="validDateRange"
							/>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FFieldLabel label="Tekst powiadomienia" />
							<Field
								component={FTextArea}
								label="Tekst powiadomienia"
								rows={4}
								placeholder="Treść powiadomienia"
								name="header"
								autoFocus={true}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FFieldLabel label="Terminy wysyłki powiadomienia" />
							<Card>
								<Row>
									<Col span={8}>
										<Space>
											<Field
												label="7 dni przed"
												component={FCheckbox}
												name="triggeredWeekBefore"
												id="triggeredWeekBefore"
											/>
											<FFieldLabel
												label="7 dni przed"
												for="triggeredWeekBefore"
											/>
										</Space>
									</Col>
									<Col span={8}>
										<Space>
											<Field
												label="1 dzień przed"
												component={FCheckbox}
												name="triggeredDayBefore"
												id="triggeredDayBefore"
											/>
											<FFieldLabel
												label="1 dzień przed"
												for="triggeredDayBefore"
											/>
										</Space>
									</Col>

									<Col span={8}>
										<Space>
											<Field
												label="w dniu zdarzenia"
												component={FCheckbox}
												name="triggeredExactDay"
												id="triggeredExactDay"
											/>
											<FFieldLabel
												label="w dniu zdarzenia"
												for="triggeredExactDay"
											/>
										</Space>
									</Col>
								</Row>
								<Row>
									<Col span={10}>
										<Space>
											<Field
												component={FCheckbox}
												name="customTriggerActivation"
												id="customTriggerActivation"
												onChange={(
													value: CheckboxChangeEvent
												) => {
													if (!value.target.checked) {
														props.setFieldValue(
															'customTrigger',
															undefined
														);
													}
												}}
											/>
											<Field
												component={FInputNumber}
												disabled={
													!props.values[
														'customTriggerActivation'
													]
												}
												size="small"
												precision={0}
												style={{ width: 50 }}
												name="customTrigger"
												label="Inna liczba dni"
											/>
											<FFieldLabel
												label="dni przed"
												for="customTriggerActivation"
											/>
										</Space>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</Modal>
			)}
		/>
	);
});

export default CreateUserPrivateOccurrenceModal;
