import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import moment, { Moment } from 'moment';

export class UpdateDictionaryVersionDto {
	id?: number;
	validFrom?: Moment;
	validTo?: Moment;
	version?: string;

	constructor(model: ProfiBazaAPIModels.DictionaryVersionDto) {
		this.id = model.id;
		this.validFrom = model.validFrom ? moment(model.validFrom) : undefined;
		this.validTo = model.validTo ? moment(model.validTo) : undefined;
		this.version = model.version;
	}

	static toCommand(
		model: UpdateDictionaryVersionDto
	): ProfiBazaAPIModels.UpdateDictionaryVersionCommand {
		const command: ProfiBazaAPIModels.UpdateDictionaryVersionCommand = {
			id: model.id,
			validFrom: model.validFrom?.utc(true).toDate(),
		};
		return command;
	}
}
