/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/patternMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Pattern. */
export class Pattern {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Pattern.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PatternAllResponse>
   */
  all(options?: msRest.RequestOptionsBase): Promise<Models.PatternAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.PatternDigestDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PatternDigestDto[]>): void;
  all(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PatternDigestDto[]>, callback?: msRest.ServiceCallback<Models.PatternDigestDto[]>): Promise<Models.PatternAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.PatternAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PatternCreateResponse>
   */
  create(options?: Models.PatternCreateOptionalParams): Promise<Models.PatternCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.PatternCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.PatternCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.PatternCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.PatternCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.PatternUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.PatternUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.PatternUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.PatternGetByIdResponse>
   */
  getById(id: string, options?: Models.PatternGetByIdOptionalParams): Promise<Models.PatternGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.PatternDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: Models.PatternGetByIdOptionalParams, callback: msRest.ServiceCallback<Models.PatternDto>): void;
  getById(id: string, options?: Models.PatternGetByIdOptionalParams | msRest.ServiceCallback<Models.PatternDto>, callback?: msRest.ServiceCallback<Models.PatternDto>): Promise<Models.PatternGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.PatternGetByIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteMethod(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  activate(options?: Models.PatternActivateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  activate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  activate(options: Models.PatternActivateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  activate(options?: Models.PatternActivateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      activateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deactivate(options?: Models.PatternDeactivateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deactivate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deactivate(options: Models.PatternDeactivateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deactivate(options?: Models.PatternDeactivateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deactivateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PatternCreateOnExistingResponse>
   */
  createOnExisting(options?: Models.PatternCreateOnExistingOptionalParams): Promise<Models.PatternCreateOnExistingResponse>;
  /**
   * @param callback The callback
   */
  createOnExisting(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createOnExisting(options: Models.PatternCreateOnExistingOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  createOnExisting(options?: Models.PatternCreateOnExistingOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.PatternCreateOnExistingResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOnExistingOperationSpec,
      callback) as Promise<Models.PatternCreateOnExistingResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Pattern",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PatternDigestDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "rizp/Pattern",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreatePatternCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "rizp/Pattern",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdatePatternCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Pattern/{id}",
  urlParameters: [
    Parameters.id0
  ],
  queryParameters: [
    Parameters.interventionId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PatternDto
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "rizp/Pattern/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const activateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "rizp/Pattern/activate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ActivatePatternCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deactivateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "rizp/Pattern/deactivate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeactivatePatternCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const createOnExistingOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "rizp/Pattern/createPatternOnExisting",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreatePatternOnExistingCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};
