import PermissionValidator from '@authorization/permissionValidator';
import { useRootData } from '@hooks/hook';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { ajaxCatch } from 'helper/api';
import ApplicationMenu from 'layout/Menu/ApplicationMenu';
import { MenuItemSchema, SubMenuItemSchema } from 'layout/Menu/MenuItems';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { FormPartDto } from 'services/src/models';

import EmbeddedForm from './EmbeddedForm';

export interface IDocument {
	formPartId: string;
	documentId: string;
}

interface FormPartDtoWithDocumentId extends FormPartDto {
	documentId?: string;
}

interface IProps {
	formVersionId: string;
	mode: 'preview' | 'edit';
	documents?: IDocument[];
	documentId?: string;
	pathBase: string;
	query?: any;
	headers?: any;
	onFormAdded: () => void;
	onFormPartLoaded: (isAnySavedDocumentVisibleInViewModel: boolean) => void;
	onSelectPart?: (partId: string) => void;
}

const FormRunner: React.FC<IProps> = (props) => {
	const [formParts, setFormParts] = useState<FormPartDtoWithDocumentId[]>([]);
	const [menuItems, setMenuItems] = useState<
		Array<MenuItemSchema | SubMenuItemSchema>
	>([]);

	const match = useRouteMatch<{
		applicationName?: string;
		formName?: string;
		formPartId?: string;
		documentId?: string;
	}>();
	const history = useHistory();
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	var permissionValidator = new PermissionValidator(
		accountDetailsStore!.account.get()!
	);

	const refreshMenuItems = () => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.formVersion.getById(props.formVersionId))
				.then((response) =>
					mapToFormPartDtoWithDocumentId(response.parts)
				)
		);
	};

	useEffect(() => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.formVersion.getById(props.formVersionId))
				.then((response) => {
					const orderedParts: Array<FormPartDtoWithDocumentId> = mapToFormPartDtoWithDocumentId(
						response.parts
					);

					if (orderedParts.length > 0) {
						if (match.params.applicationName === undefined) {
							if (orderedParts[0].documentId === undefined) {
								if (props.mode === 'edit') {
									history.push(
										`${props.pathBase}/${orderedParts[0].id}/${orderedParts[0].applicationName}/${orderedParts[0].formName}`
									);
								}
							} else {
								history.push(
									`${props.pathBase}/${orderedParts[0].id}/${orderedParts[0].applicationName}/${orderedParts[0].formName}/${orderedParts[0].documentId}`
								);
							}
						} else if (
							match.params.applicationName === 'interwencja'
						) {
							history.push(
								`${props.pathBase}/${orderedParts[0].id}/${orderedParts[0].applicationName}/${orderedParts[0].formName}/${orderedParts[0].documentId}`
							);
						}
					}

					if (props.onFormPartLoaded) {
						props.onFormPartLoaded(
							orderedParts.filter(
								(x) =>
									x.documentId !== undefined &&
									!!x.isVisibleInViewModel
							).length > 0
						);
					}
				})
		);
	}, [props.formVersionId, props.documentId]);

	const mapToFormPartDtoWithDocumentId = (
		items: FormPartDto[] | undefined
	) => {
		if (items === undefined) {
			setFormParts([]);
			return [];
		}
		let orderedParts: Array<FormPartDtoWithDocumentId>;

		if (props.documentId && items?.length === 1) {
			orderedParts = items
				.sort((a, b) => a.order! - b.order!)
				.map((x) => ({
					...x,
					documentId: props.documentId,
				}));
		} else {
			orderedParts = items!
				.sort((a, b) => a.order! - b.order!)
				.map((x) => ({
					...x,
					documentId: props.documents?.find(
						(y) => y.formPartId === x.id
					)?.documentId,
				}));
		}
		setFormParts(orderedParts);

		return orderedParts;
	};

	useEffect(() => {
		const orderedParts = mapToFormPartDtoWithDocumentId(formParts);

		if (props.onFormPartLoaded) {
			props.onFormPartLoaded(
				orderedParts.filter(
					(x) =>
						x.documentId !== undefined && !!x.isVisibleInViewModel
				).length > 0
			);
		}
	}, [props.documents, props.documentId]);

	useEffect(() => {
		return () => {
			if (
				props.mode === 'edit' &&
				(match.params.documentId === undefined ||
					match.params.documentId === null)
			) {
				props.onFormAdded();
			}
		};
	}, [match.params.formName]);

	useEffect(() => {
		prepareMenuItems();
	}, [formParts]);

	useEffect(() => {
		if (props.onSelectPart && match.params.documentId) {
			props.onSelectPart(match.params.documentId);
		}
	}, [match.params.documentId]);

	const prepareMenuItems = () => {
		const items: Array<MenuItemSchema> = [];
		formParts.length > 1 &&
			formParts.map((x) => {
				const url =
					props.mode === 'edit'
						? `${props.pathBase.substring(1)}/${x.id}/${
								x.applicationName
						  }/${x.formName}${
								x.documentId !== undefined
									? `/${x.documentId}`
									: ''
						  }`
						: `${props.pathBase.substring(1)}/${x.id}/${
								x.applicationName
						  }/${x.formName}/${x.documentId}`;
				const menuItem = {
					path: url,
					text: x.label,
					key: url,
					id: x.documentId,
					onSelectItem: (partId: string) => {
						if (props.onSelectPart) {
							props.onSelectPart(partId);
						}
						refreshMenuItems();
					},
				} as MenuItemSchema;
				items.push(menuItem);
			});
		setMenuItems(items);
	};

	return (
		<>
			{
				<ApplicationMenu
					items={menuItems}
					permissionValidator={permissionValidator}
				/>
			}

			{match.params.applicationName &&
				match.params.formName &&
				(props.mode === 'preview' || match.params.formPartId) &&
				!(
					props.mode === 'preview' &&
					match.params.documentId === 'undefined'
				) && (
					<EmbeddedForm
						key={`${match.params.applicationName}_${match.params.formName}`}
						applicationName={match.params.applicationName}
						formName={match.params.formName}
						documentId={match.params.documentId}
						mode={props.mode}
						query={
							props.mode === 'preview'
								? props.query
								: {
										...props.query,
										formPartId: match.params.formPartId,
								  }
						}
						headers={props.headers}
					></EmbeddedForm>
				)}
		</>
	);
};

FormRunner.defaultProps = {
	query: {},
	headers: {},
};

export default FormRunner;
