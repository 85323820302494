import { HttpHeaders } from '@azure/ms-rest-js';

export const getFileNameFromHeaders = (
	headers: HttpHeaders
): string | undefined => {
	return headers
		.get('content-disposition')
		?.split('filename=')[1]
		?.split(';')[0]?.replace(/['"]+/g, '');
};
