/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "./models";
import * as Mappers from "./models/mappers";
import * as operations from "./operations";
import { ProfiBazaAPIContext } from "./profiBazaAPIContext";

class ProfiBazaAPI extends ProfiBazaAPIContext {
  // Operation groups
  account: operations.Account;
  activity: operations.Activity;
  activityForm: operations.ActivityForm;
  aOTMiTApi: operations.AOTMiTApi;
  aOTMITMock: operations.AOTMITMock;
  apiKey: operations.ApiKey;
  authorizationInternal: operations.AuthorizationInternal;
  calendarEvents: operations.CalendarEvents;
  contact: operations.Contact;
  dictionary: operations.Dictionary;
  dictionaryValue: operations.DictionaryValue;
  dictionaryVersion: operations.DictionaryVersion;
  document: operations.Document;
  documentTag: operations.DocumentTag;
  exporter: operations.Exporter;
  file: operations.File;
  form: operations.Form;
  formPart: operations.FormPart;
  formVersion: operations.FormVersion;
  iKP: operations.IKP;
  intervention: operations.Intervention;
  interventionApi: operations.InterventionApi;
  interventionComments: operations.InterventionComments;
  interventionForm: operations.InterventionForm;
  interventionTag: operations.InterventionTag;
  kindSubject: operations.KindSubject;
  notificationDefinition: operations.NotificationDefinition;
  notificationOccurrence: operations.NotificationOccurrence;
  orbeon: operations.Orbeon;
  pattern: operations.Pattern;
  permission: operations.Permission;
  proposal: operations.Proposal;
  role: operations.Role;
  statementApi: operations.StatementApi;
  statementComments: operations.StatementComments;
  statements: operations.Statements;
  statementsForm: operations.StatementsForm;
  statementsTag: operations.StatementsTag;
  statementsTest: operations.StatementsTest;
  subject: operations.SubjectOperations;
  subjectApi: operations.SubjectApi;
  subjectComments: operations.SubjectComments;
  subjectReportingProcess: operations.SubjectReportingProcess;
  tag: operations.Tag;
  teryt: operations.Teryt;
  user: operations.User;
  userManual: operations.UserManual;
  userNotification: operations.UserNotification;
  userNotificationSettings: operations.UserNotificationSettings;

  /**
   * Initializes a new instance of the ProfiBazaAPI class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, options?: Models.ProfiBazaAPIOptions) {
    super(credentials, options);
    this.account = new operations.Account(this);
    this.activity = new operations.Activity(this);
    this.activityForm = new operations.ActivityForm(this);
    this.aOTMiTApi = new operations.AOTMiTApi(this);
    this.aOTMITMock = new operations.AOTMITMock(this);
    this.apiKey = new operations.ApiKey(this);
    this.authorizationInternal = new operations.AuthorizationInternal(this);
    this.calendarEvents = new operations.CalendarEvents(this);
    this.contact = new operations.Contact(this);
    this.dictionary = new operations.Dictionary(this);
    this.dictionaryValue = new operations.DictionaryValue(this);
    this.dictionaryVersion = new operations.DictionaryVersion(this);
    this.document = new operations.Document(this);
    this.documentTag = new operations.DocumentTag(this);
    this.exporter = new operations.Exporter(this);
    this.file = new operations.File(this);
    this.form = new operations.Form(this);
    this.formPart = new operations.FormPart(this);
    this.formVersion = new operations.FormVersion(this);
    this.iKP = new operations.IKP(this);
    this.intervention = new operations.Intervention(this);
    this.interventionApi = new operations.InterventionApi(this);
    this.interventionComments = new operations.InterventionComments(this);
    this.interventionForm = new operations.InterventionForm(this);
    this.interventionTag = new operations.InterventionTag(this);
    this.kindSubject = new operations.KindSubject(this);
    this.notificationDefinition = new operations.NotificationDefinition(this);
    this.notificationOccurrence = new operations.NotificationOccurrence(this);
    this.orbeon = new operations.Orbeon(this);
    this.pattern = new operations.Pattern(this);
    this.permission = new operations.Permission(this);
    this.proposal = new operations.Proposal(this);
    this.role = new operations.Role(this);
    this.statementApi = new operations.StatementApi(this);
    this.statementComments = new operations.StatementComments(this);
    this.statements = new operations.Statements(this);
    this.statementsForm = new operations.StatementsForm(this);
    this.statementsTag = new operations.StatementsTag(this);
    this.statementsTest = new operations.StatementsTest(this);
    this.subject = new operations.SubjectOperations(this);
    this.subjectApi = new operations.SubjectApi(this);
    this.subjectComments = new operations.SubjectComments(this);
    this.subjectReportingProcess = new operations.SubjectReportingProcess(this);
    this.tag = new operations.Tag(this);
    this.teryt = new operations.Teryt(this);
    this.user = new operations.User(this);
    this.userManual = new operations.UserManual(this);
    this.userNotification = new operations.UserNotification(this);
    this.userNotificationSettings = new operations.UserNotificationSettings(this);
  }
}

// Operation Specifications

export {
  ProfiBazaAPI,
  ProfiBazaAPIContext,
  Models as ProfiBazaAPIModels,
  Mappers as ProfiBazaAPIMappers
};
export * from "./operations";
