import formatHelpers, { DateFormat } from 'helper/formatHelpers';
import moment, { Moment } from 'moment';
import { StatementCommentListItemDtoPagedResult } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export class CommentsCommand {
	comments: CommentDto[];
	more: boolean;
	pagination: PaginationCommentsCommand;
	constructor(
		comment: ProfiBazaAPIModels.StatementCommentListItemDto[],
		pagination: PaginationCommentsCommand
	) {
		this.comments = comment.map((x, index) => new CommentDto(x, index))!;
		this.pagination = pagination;
		this.more = true;
	}
}

export class AuthorCommand {
	id: string;
	name: string;
	subject: string;
	constructor(id: string, name: string, subject: string) {
		this.id = id;
		this.name = name;
		this.subject = subject;
	}
}

export class CommentDto {
	author: AuthorCommand;
	body: string;
	top: number;
	id?: string;
	status: ProfiBazaAPIModels.CommentStatus | undefined;
	createdAt?: Moment | undefined;
	updatedAt?: Moment | undefined;
	createdAtFormatted?: string | null;
	updatedAtFormatted?: string | null;
	type: ProfiBazaAPIModels.CommentType | undefined;
	statementPartInterventionCode?: string;
	column?: string;
	row?: string;
	statementSubjectId?: string;
	constructor(
		comment: ProfiBazaAPIModels.StatementCommentListItemDto,
		index: number
	) {
		this.author = new AuthorCommand(
			comment.createdBySubjectId!,
			`${comment.createdBy}`,
			comment.createdBySubject!
		);
		this.body = comment.description!;

		this.statementPartInterventionCode =
			comment.statementPartInterventionCode;
		this.column = comment.column;
		this.row = comment.row;

		this.top = index;
		this.id = comment.id;
		this.type = comment.commentType;
		this.status = comment.status;
		this.createdAt = comment?.createdAt
			? moment(comment?.createdAt)
			: undefined;
		this.updatedAt = comment?.updatedAt
			? moment(comment.updatedAt)
			: undefined;
		this.createdAtFormatted = formatHelpers.formatDate(
			comment?.createdAt,
			DateFormat.DateTime
		);
		this.updatedAtFormatted = formatHelpers.formatDate(
			comment?.updatedAt,
			DateFormat.DateTime
		);
		this.statementSubjectId = comment.statementSubjectId;
	}
}
export class PaginationCommentsCommand {
	total: number;
	current: number;
	pageSize: number;
	hasNextPage: boolean;
	constructor(model?: StatementCommentListItemDtoPagedResult) {
		this.total = model?.rowCount!;
		this.pageSize = model?.pageSize!;
		this.current = model?.currentPage!;
		this.hasNextPage = model?.hasNextPage!;
	}
}
