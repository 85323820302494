import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { FileType } from '@services/src/models';

import { SupportedExportType } from '../../shared/paginatedProfiBazaTable/actions/ExportButtons';
import { SieveModel } from '../../shared/paginatedProfiBazaTable/SieveModels';

export const exportActions = (
	exportType: SupportedExportType,
	abortSignal: AbortSignal | undefined
) => {
	switch (exportType) {
		case 'default':
			return (
				sieve: SieveModel,
                allRowsSelected: boolean,
                filter?: any,
				checkedIds?: string[],
				unCheckedIds?: string[],
				fileType?: FileType
			) => {
				return getProfiBazaApiClient().then((api) =>
					api.intervention.getFileList({
						sieveFilters: sieve.filters,
						filter: filter,
						sieveSorts: sieve.sorts,
						sievePage: sieve.page,
						sievePageSize: sieve.pageSize,
						labels: sieve.labels,
						isCheckedAll: allRowsSelected,
						checked: checkedIds,
						unchecked: unCheckedIds,
						fileType: fileType,
						abortSignal,
					})
				);
			};

		case 'flat':
			return (
				sieve: SieveModel,
                allRowsSelected: boolean,
                filter?: any,
				checkedIds?: string[],
				unCheckedIds?: string[],
				fileType?: FileType
			) => {
				return getProfiBazaApiClient().then((api) =>
					api.intervention.getFileFlatList({
						sieveFilters: sieve.filters,
						filter: filter,
						sieveSorts: sieve.sorts,
						sievePage: sieve.page,
						sievePageSize: sieve.pageSize,
						isCheckedAll: allRowsSelected,
						labels: sieve.labels,
						checked: checkedIds,
						unchecked: unCheckedIds,
						fileType: fileType,
						isTechnicalVersion: false,
						abortSignal,
					})
				);
			};

		case 'technicalFlat':
			return (
				sieve: SieveModel,
                allRowsSelected: boolean,
                filter?: any,
				checkedIds?: string[],
				unCheckedIds?: string[],
				fileType?: FileType
			) => {
				return getProfiBazaApiClient().then((api) =>
					api.intervention.getFileFlatList({
						sieveFilters: sieve.filters,
						filter: filter,
						sieveSorts: sieve.sorts,
						sievePage: sieve.page,
						sievePageSize: sieve.pageSize,
						isCheckedAll: allRowsSelected,
						labels: sieve.labels,
						checked: checkedIds,
						unchecked: unCheckedIds,
						fileType: fileType,
						isTechnicalVersion: true,
						abortSignal,
					})
				);
			};

		case 'full':
			return (
				sieve: SieveModel,
                allRowsSelected: boolean,
                filter?: any,
				checkedIds?: string[],
				unCheckedIds?: string[],
				fileType?: FileType
			) => {
				return getProfiBazaApiClient().then((api) =>
					api.intervention.getFileDetails({
						sieveFilters: sieve.filters,
						filter: filter,
						sieveSorts: sieve.sorts,
						sievePage: sieve.page,
						sievePageSize: sieve.pageSize,
						isCheckedAll: allRowsSelected,
						checked: checkedIds,
						unchecked: unCheckedIds,
						fileType: fileType,
						isTechnicalVersion: true,
						abortSignal,
					})
				);
			};

		default:
			throw new Error('Not supported export type');
	}
};
