import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<ProfiBazaAPIModels.DocumentStatus, string>([
	[ProfiBazaAPIModels.DocumentStatus.DRAFT, 'Robocze'],
	[ProfiBazaAPIModels.DocumentStatus.ACCEPTED, 'Zaakceptowane'],
	[ProfiBazaAPIModels.DocumentStatus.COMPLETED, 'Zakończone'],
	[ProfiBazaAPIModels.DocumentStatus.INVERIFICATION, 'Weryfikowane'],
	[ProfiBazaAPIModels.DocumentStatus.DUPLICATE, 'Zduplikowane'],
	[ProfiBazaAPIModels.DocumentStatus.TOCOMPLETE, 'Do uzupełnienia'],
	[ProfiBazaAPIModels.DocumentStatus.TOIMPROVE, 'Do poprawy'],
	[ProfiBazaAPIModels.DocumentStatus.INCORRECT, 'Walidacja negatywna'],
	[ProfiBazaAPIModels.DocumentStatus.NEW, 'Nowy'],
	[ProfiBazaAPIModels.DocumentStatus.DELETED, 'Usunięty'],
	[ProfiBazaAPIModels.DocumentStatus.ARCHIVED, 'Zarchiwizowany'],
	[ProfiBazaAPIModels.DocumentStatus.ADDED, 'Dodany'],
	[
		ProfiBazaAPIModels.DocumentStatus.SENDEDTOOPINIONAOTMIT,
		'Wysłany do zaopiniowania',
	],
	[ProfiBazaAPIModels.DocumentStatus.CLOSED, 'Zamknięte'],
	[ProfiBazaAPIModels.DocumentStatus.RECLOSED, 'Ponownie zamknięte'],
	[ProfiBazaAPIModels.DocumentStatus.REJECTED, 'Odrzucone'],
	[ProfiBazaAPIModels.DocumentStatus.REALIZATION, 'Aktywny'],
]);

export const map = (value: ProfiBazaAPIModels.DocumentStatus): string =>
	mapings.get(value) ?? '';
