import * as Yup from 'yup';

export const getEditSubjectValidationSchema = (fromCRUIP: boolean = false) =>
	Yup.object().shape({
		name: Yup.string()
			.required('Nazwa jest wymagana.')
			.max(256, 'Nazwa nie może być dłuższa niż 256 znaków.'),
		nip: Yup.string()
			.test('nip_number', 'NIP musi być liczbą.', (val) =>
				!val ? true : /^\d+$/.test(val)
			)
			.nullable()
			.test(
				'nip_len',
				'NIP musi mieć 10 znaków.',
				(val) => val == null || val.length === 10
			),
		regon: Yup.string()
			.test('regon_number', 'REGON musi być liczbą.', (val) =>
				!val ? true : /^\d+$/.test(val)
			)
			.nullable()
			.test(
				'len',
				'REGON musi mieć 9 lub 14 znaków.',
				(val) => val == null || val.length === 9 || val.length === 14
			),
		parentSubjectName: Yup.string()
			.nullable()
			.when('id', {
				is: (id) => id !== undefined,
				then: Yup.string().test(
					'parentSubject',
					'Brak możliwości wybrania edytowanego podmiotu',
					function () {
						return this.parent.parentSubjectId !== this.parent.id;
					}
				),
			}),

		krsCode: Yup.string()
			.test('krs_number', 'KRS musi być liczbą.', (val) =>
				!val ? true : /^\d+$/.test(val)
			)
			.nullable()
			.test(
				'krsCode_len',
				'KRS musi mieć 10 znaków.',
				(val) => val == null || val.length === 10
			),
		subjectKind: Yup.string().required('Kategoria podmiotu jest wymagana.'),
		validTo: Yup.date().when('validFrom', {
			is: (validTo) => validTo != null,
			then: Yup.date()
				.nullable()
				.test(
					'more than validFrom',
					'Data aktywności do musi być późniejsza niż Data aktywności od',
					function (validTo) {
						return !validTo || validTo >= this.parent.validFrom;
					}
				),
			otherwise: Yup.date().nullable(),
		}),
		address: addressValidation(fromCRUIP),
	});

const addressShape = {
	city: Yup.string().required('To pole jest wymagane.'),
	isAddressManual: Yup.boolean(),

	streetCode: Yup.string().nullable(),
	community: Yup.string().nullable().required('To pole jest wymagane.'),
	voivodeship: Yup.string().nullable().required('To pole jest wymagane.'),
	county: Yup.string().nullable().required('To pole jest wymagane.'),
	propertyNumber: Yup.string().nullable().required('To pole jest wymagane.'),
	zipCode: Yup.string()
		.nullable()
		.required('To pole jest wymagane.')
		.test(
			'zip-code-format',
			'Niepoprawny format kodu pocztowego.',
			function (zipCode) {
				if (!zipCode) return true;
				return zipCode!.match(/^\d\d-\d\d\d$/);
			}
		),
};

const CRUIPAddressShape = {
	city: Yup.string().nullable(),
	isAddressManual: Yup.boolean(),

	streetCode: Yup.string().nullable(),
	community: Yup.string().nullable(),
	voivodeship: Yup.string().nullable(),
	county: Yup.string().nullable(),
	propertyNumber: Yup.string().nullable(),
	zipCode: Yup.string().nullable(),
};

const addressValidation = (fromCRUIP: boolean) =>
	fromCRUIP
		? Yup.object().shape(CRUIPAddressShape)
		: Yup.object().when('isStreetRequired', {
				is: true,
				then: Yup.object().shape({
					...addressShape,
					street: Yup.string()
						.nullable()
						.required('To pole jest wymagane.')
						.test(
							'is-street-choosen',
							'Nie wybrano ulicy ze słownika.',
							function (value) {
								if (
									this.parent['simcCode'] !== undefined &&
									this.parent['streetCode'] === undefined
								) {
									return false;
								}

								return true;
							}
						),
				}),
				otherwise: Yup.object().shape({
					...addressShape,
					street: Yup.string().nullable(),
				}),
		  });
