import { Skeleton, Space } from 'antd';
import React from 'react';

export const ActivitySkeleton = () => {
	return (
		<>
			<Space>
				<Skeleton.Input active size="default" style={{ width: 150 }} />
				<Skeleton.Input active size="default" style={{ width: 150 }} />
				<Skeleton.Input active size="default" style={{ width: 150 }} />
				<Skeleton.Input active size="default" style={{ width: 150 }} />
				<Skeleton.Input active size="default" style={{ width: 150 }} />
			</Space>

			<Skeleton active round title paragraph={{ rows: 8 }} />
			<div>
				<Skeleton active round title paragraph={{ rows: 0 }} />
				<Skeleton.Input active size="large" />
				<Skeleton.Input active size="large" />
			</div>
			<div>
				<Skeleton active round title paragraph={{ rows: 0 }} />
				<Skeleton.Input active size="large" />
			</div>
		</>
	);
};

export default ActivitySkeleton;
