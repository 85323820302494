import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import useTabAccessibility from '@hooks/accessibility/useTabAccessibilityHook';
import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';

import StatementData from './StatementData';
import StatementDocuments from './StatementDocuments';

const { TabPane } = Tabs;

interface IProps {
	statementId: string;
	editable: boolean;
}

const StatementDetails: React.FC<RouteComponentProps & IProps> = (props) => {
	const [activeTab, setActiveTab] = useState<string>('main');
	const history = useHistory();
	const location = useLocation();

	const onTabChanged = (activeKey: string) => {
		if (activeKey === 'main') {
			history.push(
				`/statement/${props.editable ? 'edit' : 'details'}/${
					props.statementId
				}`
			);
		} else {
			history.push(
				`/statement/${props.editable ? 'edit' : 'details'}/${
					props.statementId
				}/${activeKey}`
			);
		}
	};

	useEffect(() => {
		if (location.pathname.includes('/documents')) {
			setActiveTab('documents');
		} else {
			setActiveTab('main');
		}
	}, [location]);

	useTabAccessibility();

	return (
		<Tabs
			onChange={onTabChanged}
			activeKey={activeTab}
			defaultActiveKey="main"
			centered
		>
			<SkipLinkContent />
			<TabPane tab="Sprawozdanie" key="main" id="main">
				{activeTab === 'main' && (
					<StatementData {...props}></StatementData>
				)}
			</TabPane>
			<TabPane tab="Dokumenty" key="documents" id="documents">
				{props.statementId && (
					<StatementDocuments
						statementId={props.statementId}
						editable={props.editable}
					/>
				)}
			</TabPane>
		</Tabs>
	);
};

export default StatementDetails;
