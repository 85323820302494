/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/subjectReportingProcessMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a SubjectReportingProcess. */
export class SubjectReportingProcess {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a SubjectReportingProcess.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectReportingProcessAllResponse>
   */
  all(options?: Models.SubjectReportingProcessAllOptionalParams): Promise<Models.SubjectReportingProcessAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.SubjectReportingProcessAllOptionalParams, callback: msRest.ServiceCallback<any>): void;
  all(options?: Models.SubjectReportingProcessAllOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.SubjectReportingProcessAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.SubjectReportingProcessAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectReportingProcessCreateResponse>
   */
  create(options?: Models.SubjectReportingProcessCreateOptionalParams): Promise<Models.SubjectReportingProcessCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.SubjectReportingProcessCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.SubjectReportingProcessCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.SubjectReportingProcessCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.SubjectReportingProcessCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.SubjectReportingProcessUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.SubjectReportingProcessUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.SubjectReportingProcessUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectReportingProcessGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.SubjectReportingProcessGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.SubjectReportingProcessDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SubjectReportingProcessDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SubjectReportingProcessDto>, callback?: msRest.ServiceCallback<Models.SubjectReportingProcessDto>): Promise<Models.SubjectReportingProcessGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.SubjectReportingProcessGetByIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteMethod(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  activate(options?: Models.SubjectReportingProcessActivateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  activate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  activate(options: Models.SubjectReportingProcessActivateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  activate(options?: Models.SubjectReportingProcessActivateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      activateOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deactivate(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deactivate(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deactivate(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deactivate(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deactivateOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/SubjectReportingProcess",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectReportingProcessListItemDtoPagedResult
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "subjects/SubjectReportingProcess",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateSubjectReportingProcessCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "subjects/SubjectReportingProcess",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateSubjectReportingProcessCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/SubjectReportingProcess/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectReportingProcessDto
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "subjects/SubjectReportingProcess/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const activateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "subjects/SubjectReportingProcess/activate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ActivateSubjectReportingProcessCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deactivateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "subjects/SubjectReportingProcess/{id}/deactivate",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};
