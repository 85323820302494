import React from 'react';
import { Row, Col } from 'antd';
import {
	FFieldLabel,
	FInput,
	FDatePicker,
	FInputFile,
	FCheckbox,
} from 'forms/FormikFormItems';
import { Field } from 'formik';

export const versionsForm = (
	isCreation: boolean,
	isImport?: boolean,
	setFile?: Function
) => (
	<>
		<Row>
			{!isCreation && (
				<Col span={24}>
					<FFieldLabel label="Wersja formularza" />
					<Field
						label="Wersja formularza"
						component={FInput}
						disabled={true}
						placeholder="Wersja"
						name="version"
					/>
				</Col>
			)}
		</Row>
		<Row>
			{isImport ? (
				<>
					<Col span={24}>
						<FFieldLabel label="Wybierz plik" />
						<Field
							label="Wybierz plik"
							component={FInputFile}
							accept="xlsx"
							getFile={(file: File) => {
								setFile?.(file);
							}}
							placeholder="Wybierz plik"
							name="file"
							style={{ width: '100%' }}
						/>
					</Col>
					<Col span={24}>
						<FFieldLabel label="Czy są zdefiniowane identyfikatory?" />
						<Field
							label="Czy są zdefiniowane identyfikatory?"
							component={FCheckbox}
							name="isCodeName"
							style={{ marginLeft: 10 }}
						/>
					</Col>
				</>
			) : (
				<Col span={12}>
					<FFieldLabel label="Data od" />
					<Field
						label="Data od"
						component={FDatePicker}
						name="validFrom"
						mode="date"
						style={{ width: '100%' }}
					/>
				</Col>
			)}
		</Row>
	</>
);
