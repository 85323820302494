function moveArrayElement<T>(arr: T[], fromIndex: number, toIndex: number) {
	var element = arr[fromIndex];
	arr.splice(fromIndex, 1);
	arr.splice(toIndex, 0, element);
}

function sortByReference(arrayToSort: string[], referenceArray: string[]) {
	if (arrayToSort) {
		arrayToSort.sort(function (a, b) {
			return referenceArray.indexOf(a) - referenceArray.indexOf(b);
		});
	}
}

function sortByColumnReference<T extends any, S>(
	arrayToSort: T[],
	referenceArray: S[],
	referenceColumn: string
) {
	if (arrayToSort && referenceArray && referenceColumn) {
		arrayToSort.sort(function (a, b) {
			const indexA = referenceArray.indexOf(a[referenceColumn]);
			const indexB = referenceArray.indexOf(b[referenceColumn]);
			if (indexA >= 0 && indexB >= 0) return indexA - indexB;
			return indexB - indexA;
		});
	}
}

function isEquivalent<T extends any>(a: T, b: T) {
	var aProps = Object.getOwnPropertyNames(a);
	var bProps = Object.getOwnPropertyNames(b);

	if (aProps.length != bProps.length) {
		return false;
	}

	for (var i = 0; i < aProps.length; i++) {
		var propName = aProps[i];

		if (a[propName] !== b[propName]) {
			return false;
		}
	}
	return true;
}

function containsKeyIgnoreCase<T extends Object>(object: T, key: string) {
	return !!Object.keys(object).find(
		(k) => k.toLowerCase() === key.toLowerCase()
	);
}

export default {
	moveArrayElement,
	sortByReference,
	sortByColumnReference,
	isEquivalent,
	containsKeyIgnoreCase,
};
