import { DownloadOutlined } from '@ant-design/icons';
import { INotificationStore } from '@components/notifications/notificationStore';
import { DownloadFile } from '@components/shared/file/FileDownloadFunction';
import { StatusCode } from '@helper/statusCode';
import { useRootData } from '@hooks/hook';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { Badge, Button, Card, Row, Space, Switch, Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import formatHelpers from 'helper/formatHelpers';
import { History } from 'history';
import { ExportBlobType } from 'layout/FileExportMenuItem';
import { showErrorModal } from 'layout/Modals';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { OccurrenceDomainType, OccurrenceType } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { NotificationContext } from '../NotificationContext';

const { Text } = Typography;

interface IProps {
	item: ProfiBazaAPIModels.UserNotificationDTO;
	unread?: boolean;
}

const TileButton = (props: ButtonProps) => {
	return (
		<Button type="default" size="small" {...props}>
			{props.children}
		</Button>
	);
};

const goToButton = (
	history: History,
	occurrenceDomainType?: OccurrenceDomainType | undefined,
	instanceId?: string
) => {
	switch (occurrenceDomainType) {
		case OccurrenceDomainType.User:
			break;

		case OccurrenceDomainType.Intervention:
			return (
				<TileButton
					onClick={() => history.push(`/rizp/details/${instanceId}`)}
					aria-label="przejdź do interwencji"
				>
					przejdź do interwencji
				</TileButton>
			);

		case OccurrenceDomainType.Statement:
			return (
				<TileButton
					onClick={() =>
						history.push(`/statement/details/${instanceId}`)
					}
					aria-label="przejdź do sprawozdania"
				>
					przejdź do sprawozdania
				</TileButton>
			);

		default:
			new Error('Type not mapped');
			break;
	}
	return <span />;
};

const getAsyncExport = (exportId: string) => {
	getProfiBazaApiClient()
		.then((x) => x.exporter.getById(exportId))
		.then((response) => {
			if (response._response.status === StatusCode.NotFound) {
				showErrorModal('Nie można pobrać pliku.');
			} else {
				DownloadFile({
					response: response as ExportBlobType,
				});
			}
		});
};

const NotificationOccurrenceCard: React.FC<IProps> = (props) => {
	const { item, unread } = props;

	const { deactivateSameKind, markAsRead } = useContext(NotificationContext);
	const history = useHistory();

	const notificationStore: INotificationStore = useRootData(
		(store) => store.notificationStore
	);

	const isActive = notificationStore.activenessMap.get(
		item.notificationDefinitionId!
	);

	const handleOnSwitchChange = (checked: boolean, event: MouseEvent) => {
		deactivateSameKind?.(item.id!, item.occurrenceDomainType!, () =>
			notificationStore.toggleActive(item.notificationDefinitionId)
		);
	};

	const handleMarkAsRead = async () => {
		if (markAsRead) {
			await markAsRead(item.id!);
		}
	};

	const descriptionContent = (
		<Space direction="vertical" className="mb-xs">
			<Text>{item.description}</Text>
			<Text strong>{formatHelpers.formatDate(item.creationDate)}</Text>
		</Space>
	);

	const card = (
		<Card size="small" bordered className="notification-occurance-card">
			<Card.Meta title={item.header} description={descriptionContent} />

			<Row justify="space-between">
				{goToButton(
					history,
					item.occurrenceDomainType,
					item.instanceId
				)}
				{item.occurrenceType === OccurrenceType.ExportFinished && (
					<TileButton
						onClick={() => getAsyncExport(item.additionalData!)}
						aria-label="pobierz"
						icon={<DownloadOutlined />}
					>
						Pobierz
					</TileButton>
				)}
				{unread && (
					<TileButton
						onClick={handleMarkAsRead}
						aria-label="Oznacz jako przeczytaną"
					>
						Oznacz jako przeczytaną
					</TileButton>
				)}
			</Row>
			{unread && item.notificationDefinitionId && (
				<Row>
					<Space>
						<Switch
							disabled={!item.hasActiveChannel}
							checked={!item.hasActiveChannel || !isActive}
							onChange={handleOnSwitchChange}
							size="small"
							aria-label="Przełącznik - nie chcę otrzymywać powiadomień tego typu"
						/>
						<Text>Nie chcę otrzymywać powiadomień tego typu</Text>
					</Space>
				</Row>
			)}
		</Card>
	);

	return unread ? (
		card
	) : (
		<Badge.Ribbon text="Odczytane" placement="start" color="blue">
			{card}
		</Badge.Ribbon>
	);
};

export default observer(NotificationOccurrenceCard);
