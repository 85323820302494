import { Modal, notification } from 'antd';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import DictionaryPicker from 'components/dictionary/picker/DictionaryPicker';
import { Formik, FormikActions, FormikProps } from 'formik';
import { showNotExpectedErrorModal } from 'layout/Modals';
import NProgress from 'nprogress';
import React, { useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	DictionaryInterventionPath,
	InterventionPath,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { setDelayedNotificationLinksName } from '../../helper/accessibilityHelpers';
import { InterventionToDictionaryPathMapper } from './model/map/InterventionMappers';

interface IProps {
	interventionId: string;
	options: RcCustomRequestOptions;
	setSourceDocumentId: (value: string | undefined) => void;
	refreshDocumentDetailsRef: () => void;
	onDestroy: () => void;
	codeName: string;
	interventionPath?: InterventionPath;
}

interface IState {
	isModalVisible: boolean;
	file?: ProfiBazaAPIModels.FileTreeItemDto;
}

const InterventionDocumentsCategorySelect: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({ isModalVisible: true });

	return (
		<Formik
			validateOnChange={true}
			validateOnBlur={false}
			initialValues={{
				id: state.file?.id,
				fileCategoryDictionaryValueId:
					state.file?.fileCategoryDictionaryValueId,
				fileCategoryDictionaryOtherValue:
					state.file?.fileCategoryDictionaryValue,
			}}
			enableReinitialize={true}
			onSubmit={(
				values: ProfiBazaAPIModels.InterventionUploadAttachmentOptionalParams,
				actions: FormikActions<
					ProfiBazaAPIModels.InterventionUploadAttachmentOptionalParams
				>
			) => {
				getProfiBazaApiClient()
					.then((api) =>
						api.intervention.uploadAttachment({
							id: props.interventionId,
							file: props.options.file,
							fileCategoryDictionaryValueId:
								values.fileCategoryDictionaryValueId,
							fileCategoryDictionaryOtherValue:
								values.fileCategoryDictionaryOtherValue,
							onUploadProgress: (event) => {
								const loadedProgress =
									event.loadedBytes / props.options.file.size;

								NProgress.set(loadedProgress);
							},
						})
					)
					.then((result) => {
						notification.success({
							placement: 'bottomRight',
							message: `Plik ${props.options.file.name} wysłano pomyślnie`,
						});
						setDelayedNotificationLinksName();
						props.setSourceDocumentId(result.id);
						props.options.onSuccess(result, props.options.file);
						props.refreshDocumentDetailsRef();
						setState({
							isModalVisible: false,
							file: undefined,
						});
						NProgress.done();
					})
					.catch((err) => {
						actions.setErrors(err);
						showNotExpectedErrorModal(err);
						props.options.onError(err);
						NProgress.done();
					})
					.finally(() => {
						actions.setSubmitting(false);
						props.onDestroy();
						NProgress.done();
					});
			}}
			render={(
				formikProps: FormikProps<
					ProfiBazaAPIModels.InterventionUploadAttachmentOptionalParams
				>
			) => (
				<Modal
					visible={state.isModalVisible}
					maskClosable={false}
					centered
					title="Ustaw kategorię"
					onOk={() => {
						if (!formikProps.isSubmitting) formikProps.submitForm();
					}}
					onCancel={() => {
						setState({
							isModalVisible: false,
							file: undefined,
						});
						props.onDestroy();
						props.options.onError(new Error());
					}}
					okButtonProps={{ disabled: formikProps.isSubmitting }}
					cancelButtonProps={{ disabled: formikProps.isSubmitting }}
					okText="Zapisz"
					destroyOnClose
				>
					<DictionaryPicker
						label="Kategoria załącznika interwencja"
						codeName={props.codeName}
						otherValue="fileCategoryDictionaryOtherValue"
						name="fileCategoryDictionaryValueId"
						interventionPath={
							InterventionToDictionaryPathMapper.get(
								props.interventionPath!
							) ?? DictionaryInterventionPath.PROHEALTHACTIVITIES
						}
					/>
				</Modal>
			)}
		/>
	);
};

export default InterventionDocumentsCategorySelect;
