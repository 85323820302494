import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<ProfiBazaAPIModels.StatementStatus, string>([
	[ProfiBazaAPIModels.StatementStatus.DRAFT, 'robocze'],
	[ProfiBazaAPIModels.StatementStatus.ACCEPTED, 'zaakceptowane'],
	[ProfiBazaAPIModels.StatementStatus.CLOSED, 'zamknięte'],
	[ProfiBazaAPIModels.StatementStatus.INVERIFICATION, 'weryfikowane'],
	[ProfiBazaAPIModels.StatementStatus.DUPLICATE, 'zduplikowane'],
	[ProfiBazaAPIModels.StatementStatus.TOCOMPLETE, 'do uzupełnienia'],
	[ProfiBazaAPIModels.StatementStatus.TOIMPROVE, 'do poprawy'],
	[ProfiBazaAPIModels.StatementStatus.INCORRECT, 'walidacja negatywna'],
	[ProfiBazaAPIModels.StatementStatus.ARCHIVED, 'zarchiwizowane'],
	[ProfiBazaAPIModels.StatementStatus.REJECTED, 'odrzucone'],
	[ProfiBazaAPIModels.StatementStatus.RECLOSED, 'ponownie zamknięte'],
]);

export const map = (value: ProfiBazaAPIModels.StatementStatus): string =>
	mapings.get(value) ?? '';
