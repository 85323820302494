import { DeleteOutlined, RedoOutlined } from '@ant-design/icons';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import { Button, Col, Input, Popconfirm, Row, Space } from 'antd';
import { FFieldLabel } from 'forms/FormikFormItems';
import { ajaxByUser } from 'helper/api';
import React, { useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';

interface IProps {
	apiKey?: string;
	id: string;
}

const ApiKeyForm: React.FC<IProps> = (props) => {
	const [apiKey, setApiKey] = useState<string>();

	useEffect(() => {
		setApiKey(props.apiKey);
	}, [props.apiKey]);

	const generateApiKey = () => {
		ajaxByUser('Wygenerowano API KEY', () =>
			getProfiBazaApiClient().then((api) =>
				api.apiKey
					.generate({
						body: {
							subjectId: props.id,
						},
					})
					.then((response) => {
						setApiKey(response.apiKey);
					})
			)
		);
	};

	const deleteApiKey = () => {
		ajaxByUser('Usunięto API KEY', () =>
			getProfiBazaApiClient().then((api) =>
				api.apiKey.deleteMethod(props.id).then((response) => {
					setApiKey(undefined);
				})
			)
		);
	};

	const regenerateApiKey = () => {
		ajaxByUser('Wygenerowano nowy API KEY', () =>
			getProfiBazaApiClient().then((api) =>
				api.apiKey
					.regenerate({
						body: {
							subjectId: props.id,
						},
					})
					.then((response) => {
						setApiKey(response.apiKey);
					})
			)
		);
	};

	return (
		<>
			<FFieldLabel label="API KEY" />
			<Row gutter={[16, 16]}>
				<Col span={20}>
					<Input aria-label="Api-key" readOnly value={apiKey}></Input>
				</Col>
				<Col span={4}>
					<Button.Group size="middle">
						{!apiKey && (
							<Button
								aria-label="Wygeneruj"
								onClick={generateApiKey}
							>
								Wygeneruj
							</Button>
						)}
						{apiKey && (
							<>
								<AccessiblePopconfirm
									title="Czy na pewno chcesz skasować API KEY"
									onConfirm={deleteApiKey}
									okText="Tak"
									cancelText="Nie"
								>
									<Button
										aria-label="Usuń"
										type="primary"
										danger
										icon={<DeleteOutlined />}
									>
										Usuń
									</Button>
								</AccessiblePopconfirm>
								<AccessiblePopconfirm
									title="Czy na pewno chcesz wygenerować nowy API KEY"
									onConfirm={regenerateApiKey}
								>
									<Button
										aria-label="Regeneruj"
										icon={<RedoOutlined />}
									>
										Regeneruj
									</Button>
								</AccessiblePopconfirm>
							</>
						)}
					</Button.Group>
				</Col>
			</Row>
		</>
	);
};

export default ApiKeyForm;
