import { UploadOutlined } from '@ant-design/icons';
import formatHelpers from '@helper/formatHelpers';
import { Col, Upload, notification } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { IsRCFileSizeValid } from 'components/shared/file/IsFileSizeValid';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useImperativeHandle, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { IGridStore } from 'stores/GridStore';

import { setDelayedNotificationLinksName } from '../../../helper/accessibilityHelpers';
const { Dragger } = Upload;

interface IProps {
	onCreate: () => void;
}

interface IState {
	// dictionaryVersionId: number | undefined;
}

export interface ICreateVoivodeOpinionModal {
	showModal: () => void;
}

const CreateVoivodeOpinionModal = React.forwardRef<
	ICreateVoivodeOpinionModal,
	IProps
>((props, ref) => {
	const [visible, setVisible] = useState<boolean>(false);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	useImperativeHandle(ref, () => ({
		showModal: () => {
			setVisible(true);
		},
	}));

	const fileUploadProps = {
		name: 'file',
		multiple: false,
		customRequest: (options: RcCustomRequestOptions) => {
			const { onSuccess, onError, file, onProgress } = options;

			if (!IsRCFileSizeValid(options)) return;

			getProfiBazaApiClient()
				.then((api) =>
					api.document.uploadVoivodeOpinion({
						file: options.file,
						onUploadProgress: (event) => {
							const percent = Math.floor(
								(event.loadedBytes / file.size) * 100
							);

							onProgress(
								{
									percent: percent,
								},
								file
							);
						},
					})
				)
				.then((result) => {
					notification.success({
						placement: 'bottomRight',
						message: `Plik ${file.name} wysłano pomyślnie`,
					});
					setDelayedNotificationLinksName();
					onSuccess(result, file);
					setVisible(false);
					gridStore.searching.set(true);
				})
				.catch((err) => {
					notification.error({
						placement: 'bottomRight',
						message: `Wystąpił błąd podczas przesyłania pliku ${file.name}`,
					});
					onError(err);
				});
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 1,
			format: (percent: any) =>
				formatHelpers.formatPercent(percent, true),
		},
		showUploadList: {
			showPreviewIcon: false,
			showDownloadIcon: false,
			showRemoveIcon: false,
		},
		accept:
			'.pdf, .xlsx, .xls, .doc, .docx, .jpg, .jpeg, .png, .gif, .rtf, .ppt, .txt',
	};

	return (
		<Modal
			visible={visible}
			maskClosable
			centered
			destroyOnClose
			title="Tworzenie opinii wojewody"
			onCancel={() => {
				setVisible(false);
			}}
			footer={<></>}
		>
			<CenteredRow>
				<Col span={20}>
					<div style={{ margin: '20px 0px' }}>
						<Dragger aria-label="Załącznik" {...fileUploadProps}>
							<p className="ant-upload-drag-icon">
								<UploadOutlined />
							</p>
							<p className="ant-upload-text">
								Przeciągnij i upuść plik tutaj aby dodać plik
								lub kliknij aby dodać pliki ręcznie
							</p>
							<p>
								(.pdf, .xlsx, .xls, .doc, .docx, .jpg, .jpeg,
								.png, .gif, .rtf, .ppt, .txt)
							</p>
						</Dragger>
					</div>
				</Col>
			</CenteredRow>
		</Modal>
	);
});

export default CreateVoivodeOpinionModal;
