import { useEffect } from 'react';

const useDescriptionsAccessibilityHook = <T extends any>(dependency: T) => {
	useEffect(() => {
		const descriptionCells = document.getElementsByClassName(
			'ant-descriptions-item'
		);
		Array.from(descriptionCells).forEach((cell) => {
			cell.setAttribute('tabindex', '0');
			cell.setAttribute('role', 'cell');
		});
	}, [dependency]);
};

export default useDescriptionsAccessibilityHook;
