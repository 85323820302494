import { usePrevious } from '@hooks/usePreviousHook';
import { Modal } from 'antd';
import { FormikErrors } from 'formik';
import React, { useEffect } from 'react';

interface IProps {
	isValidating: boolean;
	isValid: boolean;
	submitClicked: boolean;
	action?: () => void;
	setSubmitClicked: React.Dispatch<React.SetStateAction<boolean>>;
	errors: FormikErrors<any>;
}

const showValidationWarning = () => {
	Modal.warning({
		centered: true,
		maskClosable: true,
		title:
			'Należy uzupełnić obowiązkowe dane na zakładkach podświetlonych na czerwono',
	});
};

const ValidationWarning: React.FC<IProps> = ({
	isValidating,
	isValid,
	action,
	submitClicked,
	setSubmitClicked,
	errors,
}) => {
	const previousIsValidating = usePrevious(isValidating);

	useEffect(() => {
		if (
			submitClicked &&
			previousIsValidating &&
			!isValidating &&
			!isValid &&
			Object.keys(errors).length
		) {
			if (action) {
				action();
			} else {
				showValidationWarning();
			}
			setSubmitClicked(false);
		}
	}, [submitClicked, previousIsValidating, isValidating, isValid]);

	return <></>;
};

export default ValidationWarning;
