import { useLayoutEffect } from 'react';

const useIconsAccessibility = () => {
	useLayoutEffect(() => {
		const rootElement = document.querySelector('body');

		const mutationConfig = {
			attributes: false,
			childList: true,
			subtree: true,
		};

		const mutationCallback: MutationCallback = (
			mutationsList,
			observer
		) => {
			for (const mutation of mutationsList) {
				mutation.target.childNodes.forEach((child) => {
					if (child.nodeName === 'SVG') {
						const svgElement = child as Element;
						if (!svgElement.hasAttribute('aria-label')) {
							svgElement.setAttribute('aria-label', 'Ikona');
						}
						if (!svgElement.hasAttribute('role')) {
							svgElement.setAttribute('role', 'img');
						}
					} else if (
						child.nodeName === 'SPAN' ||
						child.nodeName === 'A' ||
						child.nodeName === 'BUTTON' ||
						child.nodeName === 'DIV'
					) {
						const svgElements = (child as Element).querySelectorAll(
							'svg'
						);

						svgElements.forEach((svgElement) => {
							if (svgElement?.tagName === 'svg') {
								if (!svgElement.hasAttribute('aria-label')) {
									svgElement.setAttribute(
										'aria-label',
										'Ikona'
									);
								}
								if (!svgElement.hasAttribute('role')) {
									svgElement.setAttribute('role', 'img');
								}
							}
						});
					}
				});
			}
		};

		const observer = new MutationObserver(mutationCallback);
		if (rootElement) {
			observer.observe(rootElement, mutationConfig);
		}

		return () => {
			observer.disconnect();
		};
	}, []);
};

export default useIconsAccessibility;
