import { UploadOutlined } from '@ant-design/icons';
import { Col, Upload } from 'antd';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import SourceDocumentDetails, {
	ISourceDocumentDetails,
} from 'components/documents/SourceDocumentDetails';
import { IsRCFileSizeValid } from 'components/shared/file/IsFileSizeValid';
import { ajaxCatch } from 'helper/api';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useEffect, useRef, useState } from 'react';
import { DictionariesConstants } from 'services/DictionariesConstants';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';

import SubjectDocumentsCategorySelect from './SubjectDocumentsCategorySelect';

const { Dragger } = Upload;

interface IProps {
	subjectId: string;
    editable?: boolean;
    span?: number;
}

const SubjectDocuments: React.FC<IProps> = (props) => {
	const [sourceDocumentId, setSourceDocumentId] = useState<
		string | undefined
	>();

	const sourceDocumentDetailsRef = useRef<ISourceDocumentDetails>(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [fileOptions, setFileOptions] = useState<RcCustomRequestOptions>();

	useEffect(() => {
		refresh();
	}, [props.subjectId]);

	const refresh = () => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.subject.getTinyById(props.subjectId))
				.then((result) => setSourceDocumentId(result.sourceDocumentId))
		);
	};

	const setSourceDocumentIdCallback = (
		documentId: string | undefined
	): void => {
		setSourceDocumentId(documentId);
	};

	const refreshDocumentDetailsRef = (): void => {
		sourceDocumentDetailsRef.current?.forceRefresh();
	};

	const fileUploadProps = {
		name: 'file',
		multiple: false,
		customRequest: (options: RcCustomRequestOptions) => {
			if (!IsRCFileSizeValid(options)) return;
			setFileOptions(options);
			setIsModalVisible(true);
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 1,
			format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
		},
		showUploadList: {
			showPreviewIcon: false,
			showDownloadIcon: false,
			showRemoveIcon: false,
		},
		accept:
			'.pdf, .xlsx, .xls, .doc, .docx, .jpg, .jpeg, .png, .gif, .rtf, .ppt, .txt',
	};

	return (
		<>
			{isModalVisible && fileOptions && (
				<SubjectDocumentsCategorySelect
					subjectId={props.subjectId}
					options={fileOptions}
					setSourceDocumentId={setSourceDocumentIdCallback}
					refreshDocumentDetailsRef={refreshDocumentDetailsRef}
					onDestroy={() => {
						setIsModalVisible(false);
					}}
					codeName={
						DictionariesConstants.AttachmentCategories
							.SubjectAttachmentCategory
					}
				/>
			)}
			{props.editable && (
				<CenteredRow>
					<Col span={props.span}>
						<div style={{ margin: '20px 0px' }}>
							<Dragger
								aria-label="Załącznik"
								{...fileUploadProps}
								showUploadList={false}
							>
								<p className="ant-upload-drag-icon">
									<UploadOutlined />
								</p>
								<p className="ant-upload-text">
									Przeciągnij i upuść plik tutaj aby dodać
									plik lub kliknij aby dodać pliki ręcznie
								</p>
							</Dragger>
						</div>
					</Col>
				</CenteredRow>
			)}
			{sourceDocumentId && (
				<SourceDocumentDetails
					ref={sourceDocumentDetailsRef}
					documentId={sourceDocumentId}
					baseUrl={`/subject/${
						props.editable === true ? 'edit' : 'details'
					}/${props.subjectId}/documents`}
					onEmptyDocumentsTree={refresh}
					backPath= '/subject'
					codeName={
						DictionariesConstants.AttachmentCategories
							.SubjectAttachmentCategory
                    }
                    span={props.span}
				/>
			)}
		</>
	);
};

export default SubjectDocuments;
