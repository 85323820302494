/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/formPartMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a FormPart. */
export class FormPart {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a FormPart.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.FormPartGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.FormPartGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.FormPartDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.FormPartDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.FormPartDto>, callback?: msRest.ServiceCallback<Models.FormPartDto>): Promise<Models.FormPartGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.FormPartGetByIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteMethod(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.FormPartCreateResponse>
   */
  create(options?: Models.FormPartCreateOptionalParams): Promise<Models.FormPartCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.FormPartCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.FormPartCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.FormPartCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.FormPartCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.FormPartUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.FormPartUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.FormPartUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  order(options?: Models.FormPartOrderOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  order(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  order(options: Models.FormPartOrderOptionalParams, callback: msRest.ServiceCallback<void>): void;
  order(options?: Models.FormPartOrderOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      orderOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "forms/FormPart/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.FormPartDto
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "forms/FormPart/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "forms/FormPart",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateFormPartCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "forms/FormPart",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateFormPartCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const orderOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "forms/FormPart/order",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetOrderFormPartCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
