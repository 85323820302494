import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons';
import { InterventionPathContextType } from '@components/intervention/context/InterventionPathContext';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import {
	CheckDictionaryValueChangeVersionQuery,
	CoProducerRoleDto,
	CoProducerValueDto,
	DictionaryInterventionPath,
	DictionaryValueChangeVersionDto,
} from '@services/src/models';
import { Button, Tag } from 'antd';
import { FieldProps } from 'formik';
import _ from 'lodash';
import { Moment } from 'moment';
import React, { useEffect } from 'react';

import { ErrorPopover } from './ErrorPopover';
import { IFormikInputProps } from './FormikFormItems';

export interface ISubjectsPickerProps extends FieldProps<any> {
	onChangeAttempt?: (newValue: string) => boolean;
	mode?: 'multiple' | undefined;
	editable?: boolean;
	readOnly?: boolean;
	label?: string;
	onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
	renderTagLabel: (item: any) => string;
	changeData?: Function;
	dateRange?: [(Moment | undefined)?, (Moment | undefined)?] | Moment[];
	interventionPath: DictionaryInterventionPath;
	dictionaryVersionChanged: () => void;
	context: InterventionPathContextType;
}

function instanceOfCoProducerValueDto(
	object: any
): object is CoProducerValueDto {
	return 'roles' in object;
}

export const FSubjectsPicker: React.FC<
	ISubjectsPickerProps & IFormikInputProps
> = (props) => {
	useEffect(() => {
		if (props.readOnly || !props.editable) {
			return;
		}

		if (
			!props.field.value.every((x: any) =>
				instanceOfCoProducerValueDto(x)
			)
		) {
			return;
		}

		const originalValues: number[] = props.field.value
			.flatMap((x: CoProducerValueDto) => x.roles)
			.map((x: CoProducerRoleDto) => x.roleDictionaryValueId);

		let obj: CheckDictionaryValueChangeVersionQuery = {
			interventionPaths: props.interventionPath,
			dictionaryValueIds: originalValues,
			dateFrom: props.dateRange?.[0]?.toDate(),
			dateTo: props.dateRange?.[1]?.toDate(),
		};

		getProfiBazaApiClient()
			.then((api) =>
				api.dictionaryValue.checkDictionaryValueChangeVersion({
					body: obj,
				})
			)
			.then((response: DictionaryValueChangeVersionDto[]) => {
				const anyDictionaryValueChanged = response.length > 0;

				if (anyDictionaryValueChanged) {
					props.dictionaryVersionChanged();

					let originalValueFromContext = _.cloneDeep(
						props.context.formik?.values.coProducers
					);

					let coProducersToRemove: string[] = [];

					originalValueFromContext?.forEach((coProducer) => {
						coProducer.roles?.forEach((role) => {
							const foundOldValue = response.find(
								(x) =>
									x.oldDictionaryValueId ===
									role.roleDictionaryValueId
							);

							if (foundOldValue && foundOldValue.newDictionaryValue) {
								//dopasowano wartość starego słownika z nowym, wymieniamy wartości na nowe
								role.roleDictionaryValueId =
									foundOldValue.newDictionaryValue?.id;
								role.roleDictionaryValue =
									foundOldValue.newDictionaryValue?.value;
								role.roleDictionaryOtherValue =
									role.roleDictionaryOtherValue;
							} else {
								//nie dopasowano wartosci, elementu nie ma w nowym slowniku, dodajemy go do listy do usunięcia
								coProducersToRemove.push(coProducer.id!);
							}
						});
					});

					props.context.formik?.setFieldValue(
						'coProducers',
						originalValueFromContext?.filter(
							(x) => !coProducersToRemove.some((y) => y === x.id)
						)
					);
				}
			});
	}, [props.field]);

	const input = () => {
		return (
			<div
				className={`subject-select ant-select ant-select-multiple ant-select-allow-clear ${
					!props.editable || props.readOnly
						? 'ant-select-disabled'
						: ''
				}`}
			>
				<button
					className="ant-select-selector ant-input"
					onClick={props.onClick}
					aria-label={props.label}
					id={props.field.name}
					aria-owns={props.field.name}
					aria-controls={props.field.name}
					aria-activedescendant={props.field.name}
					style={{ width: '100%', minWidth: '100%' }}
				>
					{props.field.value?.map((tag: any) => (
						<Tag
							closable={props.editable && !props.readOnly}
							closeIcon={
								<Button
									type={'text'}
									icon={<CloseOutlined />}
									size="small"
									aria-label="Usuń"
								/>
							}
							className="ant-select-selection-item-content"
							style={{
								padding: '1px 4px',
								marginTop: 2,
								marginBottom: 2,
								marginRight: 4,
							}}
							onClose={() => {
								const values = props.field.value?.filter(
									(x: any) => x != tag
								);
								props.form.setFieldValue(
									props.field.name,
									values
								);

								props.changeData?.(values);
							}}
						>
							<span>{props.renderTagLabel(tag)}</span>
						</Tag>
					))}
				</button>
				{props.editable &&
					!props.readOnly &&
					(props.field.value?.length ?? 0) > 0 && (
						<span
							className="ant-select-clear"
							unselectable="on"
							aria-hidden="true"
						>
							<CloseCircleFilled
								onClick={() => {
									props.form.setFieldValue(
										props.field.name,
										[]
									);
									props.changeData?.([]);
								}}
							/>
						</span>
					)}
			</div>
		);
	};

	return (
		<ErrorPopover field={props.field} form={props.form}>
			{input()}
		</ErrorPopover>
	);
};
