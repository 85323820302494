import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import {
	IsFileExtensionValid,
	IsRCFileSizeValid,
} from '@components/shared/file/IsFileSizeValid';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import formatHelpers from '@helper/formatHelpers';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { xSLTDefinition } from '@services/src/models/parameters';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { Button, Col, Space, Tag } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Dragger from 'antd/lib/upload/Dragger';
import { RcCustomRequestOptions, RcFile } from 'antd/lib/upload/interface';
import { CreateFormVersionDto } from 'components/forms/model/CreateFormVersionCommand';
import { UpdateFormVersionDto } from 'components/forms/model/UpdateFormVersionCommand';
import FileSaver from 'file-saver';
import { Formik, FormikActions, FormikProps } from 'formik';
import { FFieldLabel } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import { showErrorModal } from 'layout/Modals';
import { observer } from 'mobx-react';
import NProgress from 'nprogress';
import React, { useEffect, useState } from 'react';

import AddButton from '../../../shared/buttons/AddButton';
import { FormVersionValidationSchema } from './FormVersionValidationSchema';
import { versionsForm } from './VersionsForm';

interface IProps {
	formId: string;
	refresh: () => void;

	visible: boolean;
	setVisible: (visible: boolean) => void;

	versionId?: string;
	xlsxDefinition?: Uint8Array;
	xsltDefinition?: Uint8Array;
	setVersionId: (id: string | undefined) => void;
}

const FormVersionModal: React.FC<IProps> = (props) => {
	const {
		formId,
		visible,
		setVisible,
		versionId,
		setVersionId,
		xlsxDefinition,
		xsltDefinition
	} = props;

	const [modelState, setModelState] = useState<UpdateFormVersionDto>();
	const [file, setFile] = useState<File | undefined>();
	const [xsltFile, setXSLTFile] = useState<File | undefined>();

	const isCreation = versionId === undefined;

	useEffect(() => {
		if (versionId !== undefined) {
			ajaxCatch(() =>
				getProfiBazaApiClient().then((api) => {
					return api.form
						.getById(formId)
						.then((result: ProfiBazaAPIModels.FormDto) => {
							setModelState(
								new UpdateFormVersionDto(
									result.versions?.find(
										(x) => x.id == versionId
									)!
								)
							);
						});
				})
			);
		}
	}, [versionId]);

	function handleCancel<T>(props: FormikProps<T>) {
		setVisible(false);
		setVersionId(undefined);
		props.resetForm();
	}

	function handleRender<T>(props: FormikProps<T>) {
		return (
			<Modal
				title={`${
					!isCreation ? 'Zaktualizuj' : 'Utwórz'
				} wersję formularza`}
				visible={visible}
				onOk={() => {
					if (!props.isSubmitting) {
						props.submitForm();
					}
				}}
				cancelText="Anuluj"
				width={700}
				centered
				okButtonProps={{ disabled: props.isSubmitting }}
				cancelButtonProps={{ disabled: props.isSubmitting }}
				destroyOnClose
				onCancel={() => handleCancel(props)}
				okText={!isCreation ? 'Zaktualizuj' : 'Utwórz'}
			>
				{versionsForm(isCreation)}
				<CenteredRow>
					<Col span={24}>
						<div style={{ margin: '20px 0px' }}>
							<FFieldLabel label="XLST" />
							<Dragger
								aria-label="Załącznik"
								{...fileUploadPropsXSLT}
							>
								<p className="ant-upload-drag-icon">
									<UploadOutlined />
								</p>
								<p className="ant-upload-text">
									Przeciągnij i upuść plik tutaj aby dodać
									plik lub kliknij aby dodać pliki ręcznie
								</p>
								<p>(.xslt)</p>
							</Dragger>
						</div>
					</Col>

					{!isCreation && xsltDefinition! && xsltDefinition!.length > 0 && (
					<Button
						type="primary"
						size="large"
						icon={<DownloadOutlined />}
						onClick={() => {
							let blob = new Blob([
								new Uint8Array(xsltDefinition!).buffer,
							]);
							FileSaver.saveAs(
								blob,
								'definicja_xslt_formularza.xslt'
							);
						}}
					>
						Pobierz definicję XSLT
					</Button>
				)}
				{!isCreation &&
					(!xsltDefinition || xsltDefinition!.length == 0) && (
						<Tag color="volcano">Brak definicji XSLT</Tag>
					)}

					<Col span={24}>
						<div style={{ margin: '20px 0px' }}>
							<FFieldLabel label="XLSX" />
							<Dragger
								aria-label="Załącznik"
								{...fileUploadProps}
							>
								<p className="ant-upload-drag-icon">
									<UploadOutlined />
								</p>
								<p className="ant-upload-text">
									Przeciągnij i upuść plik tutaj aby dodać
									plik lub kliknij aby dodać pliki ręcznie
								</p>
								<p>(.xlsx)</p>
							</Dragger>
						</div>
					</Col>
				</CenteredRow>
				{!isCreation && xlsxDefinition! && xlsxDefinition!.length > 0 && (
					<Button
						type="primary"
						size="large"
						icon={<DownloadOutlined />}
						onClick={() => {
							let blob = new Blob([
								new Uint8Array(xlsxDefinition!).buffer,
							]);
							FileSaver.saveAs(
								blob,
								'definicja_wersji_formularza.xlsx'
							);
						}}
					>
						Pobierz definicję XLSX
					</Button>
				)}
				{!isCreation &&
					(!xlsxDefinition || xlsxDefinition!.length == 0) && (
						<Tag color="volcano">Brak definicji XLSX</Tag>
					)}
			</Modal>
		);
	}

	const fileUploadPropsXSLT = {
		name: 'file',
		multiple: false,
		customRequest: (options: RcCustomRequestOptions) => {
			const { onSuccess, onError, file, onProgress } = options;

			if (!IsRCFileSizeValid(options)) return;
			if (
				!IsFileExtensionValid(options, [
					'application/xml',
				])
			) {
				onError(
					{ name: 'Error', message: 'Invalid file extension' },
					'Przesyłanie nie powiodło sie',
					options.file
				);
				return;
			}

			setXSLTFile(options.file);
			onProgress({ percent: 100 }, options.file);
			onSuccess({}, options.file);
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 1,
			format: (percent: any) =>
				formatHelpers.formatPercent(percent, true),
		},
		showUploadList: {
			showPreviewIcon: false,
			showDownloadIcon: false,
			showRemoveIcon: false,
		},
		accept: '.xslt',
	};


	const fileUploadProps = {
		name: 'file',
		multiple: false,
		customRequest: (options: RcCustomRequestOptions) => {
			const { onSuccess, onError, file, onProgress } = options;

			if (!IsRCFileSizeValid(options)) return;
			if (
				!IsFileExtensionValid(options, [
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				])
			) {
				onError(
					{ name: 'Error', message: 'Invalid file extension' },
					'Przesyłanie nie powiodło sie',
					options.file
				);
				return;
			}

			setFile(options.file);
			onProgress({ percent: 100 }, options.file);
			onSuccess({}, options.file);
		},
		progress: {
			strokeColor: {
				'0%': '#108ee9',
				'100%': '#87d068',
			},
			strokeWidth: 1,
			format: (percent: any) =>
				formatHelpers.formatPercent(percent, true),
		},
		showUploadList: {
			showPreviewIcon: false,
			showDownloadIcon: false,
			showRemoveIcon: false,
		},
		accept: '.xlsx',
	};

	const handleCreate = (
		values: CreateFormVersionDto,
		actions: FormikActions<CreateFormVersionDto>
	) => {
		ajaxByUser(
			'Utworzono wersję formularza',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.formVersion.create({
						formId: values.formId,
						validFrom: values.validFrom?.utc(true).toDate(),
						xSLTDefinition: xsltFile,
						xLSXDefinition: file,
						onUploadProgress: (event) => {
							if (file) {
								const loadedProgress =
									event.loadedBytes / file.size;

								NProgress.set(loadedProgress);
							}
						},
					})
				),
			() => {
				props.refresh();
				setVisible(false);
				actions.resetForm();
			},
			(errors) => {
				actions.setErrors(errors);
			}
		)
			.then(() => actions.setSubmitting(false))
			.finally(() => NProgress.done());
	};

	const handleUpdate = (
		values: UpdateFormVersionDto,
		actions: FormikActions<UpdateFormVersionDto>
	) => {
		ajaxByUser(
			'Zaktualizowano wersję formularza',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.formVersion.update({
						id: values.id,
						validFrom: values.validFrom?.utc(true).toDate(),
						xSLTDefinition: xsltFile,
						xLSXDefinition: file,
					})
				),
			() => {
				props.refresh();
				setVersionId(undefined);
				setVisible(false);
				setModelState(undefined);
				actions.resetForm();
			},
			(errors) => {
				actions.setErrors(errors);
			}
		).then(() => actions.setSubmitting(false));
	};

	return (
		<>
			<Space>
				<AddButton tall onClick={() => setVisible(true)}>
					Dodaj wersję
				</AddButton>
			</Space>
			{isCreation ? (
				<Formik
					validateOnChange
					validateOnBlur
					enableReinitialize
					isInitialValid={false}
					initialValues={{
						formId,
						validFrom: undefined,
						validTo: undefined,
						version: undefined,
						xsltDefinition: undefined,
						xlsxDefinition: undefined,
					}}
					validationSchema={FormVersionValidationSchema(isCreation)}
					onSubmit={handleCreate}
					render={handleRender}
				/>
			) : (
				<Formik
					validateOnChange
					validateOnBlur
					enableReinitialize
					initialValues={modelState || {}}
					validationSchema={FormVersionValidationSchema(isCreation)}
					onSubmit={handleUpdate}
					render={handleRender}
				/>
			)}
		</>
	);
};

export default observer(FormVersionModal);
