import tinymce, { Editor } from 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';

import './langs/pl.ts';

import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { useRootData } from 'hooks/hook';
import { usePrevious } from 'hooks/usePreviousHook';
import { Theme } from 'layout/AccessibilitySettings';
import React, { useEffect, useState } from 'react';

interface IProps {
	id: string;
	content?: string;
	contentChange(content: string): void;
}

interface ITheme {
	skin: string;
	css: string;
}

interface IState {
	editor: Editor | null;
	lastContent: string | undefined;
	changeTimer: null;
	theme: ITheme;
}

const DashboardNotepad: React.FC<IProps> = (props) => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const getTinyTheme = (theme: Theme | undefined): ITheme => {
		if (theme === Theme.HighContrast) {
			return {
				skin: 'oxide-dark',
				css: 'dark',
			};
		}

		return {
			skin: 'oxide',
			css: 'default',
		};
	};

	const [state, setState] = useState<IState>({
		editor: null,
		lastContent: props.content,
		changeTimer: null,
		theme: getTinyTheme(accountDetailsStore.theme.get()),
	});

	useEffect(() => {
		deInitEditor();
		initEditor();
	}, [state.theme]);

	accountDetailsStore.theme.observe((theme) => {
		if (theme.oldValue !== theme.newValue) {
			setState({
				...state,
				theme: getTinyTheme(theme.newValue),
			});
		}
	});

	const initEditor = (): void => {
		tinymce.init({
			selector: `#${props.id}`,
			skin_url: `${process.env.PUBLIC_URL}/tinymce/skins/ui/${state.theme.skin}`,
			content_css: `${process.env.PUBLIC_URL}/tinymce/skins/content/${state.theme.css}/content.css`,
			language: 'pl',
			branding: false,
			menubar: false,
			statusbar: false,
			width: 'calc(100% - 32px)',
			max_height: 168,
			min_height: 168,
			plugins: 'advlist autolink lists',
			toolbar:
				'undo redo | formatselect | bold italic backcolor | \
			  alignleft aligncenter alignright alignjustify | \
			  bullist numlist outdent indent | removeformat',
			autoresize_bottom_margin: 50,
			setup: (editor) => {
				setState({
					...state,
					editor: editor,
				});
			},
		});
	};

	const deInitEditor = (): void => {
		if (state.editor !== null) {
			tinymce.remove(state.editor);
		}
	};

	const setTinymceIframeMetatags = (frameId: string) => {
		let tinymceIframe = document.getElementById(
			frameId
		) as HTMLIFrameElement;
		if (tinymceIframe && tinymceIframe.contentDocument) {
			tinymceIframe.title = "";
			tinymceIframe.contentDocument.title = 'Notatnik';

			if(tinymceIframe.contentDocument.documentElement) {
				tinymceIframe.contentDocument.documentElement.lang = 'pl';
			}
		}
	};

	useEffect(() => {
		if (state.editor) {
			let timer: NodeJS.Timeout | boolean = false;

			state.editor.on('keyup change', () => {
				if (timer !== false) {
					clearTimeout(timer as NodeJS.Timeout);
				}

				timer = setTimeout(() => {
					if (state.editor) {
						const content = state.editor.getContent();
						setState({
							...state,
							lastContent: content,
						});
					}
					timer = false;
				}, 1500);
			});

			setTinymceIframeMetatags(`${props.id}_ifr`);
		}

		return () => {
			deInitEditor();
		};
	}, [state.editor]);

	const lastContent = usePrevious(state.lastContent);

	useEffect(() => {
		if (state.editor) {
			const content = state.editor.getContent();
			if (lastContent !== content) {
				props.contentChange(content);
			}
		}
	}, [state.lastContent]);

	useEffect(() => {
		if (!props.content) {
			state.editor?.setContent('');
		}
	}, [props.content]);

	return <textarea id={props.id} defaultValue={props.content} aria-label="Notatnik" />;
};

export default DashboardNotepad;
