/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";

export const activityId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "activityId"
  ],
  mapper: {
    serializedName: "ActivityId",
    type: {
      name: "Uuid"
    }
  }
};
export const ageUnit: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "ageUnit"
  ],
  mapper: {
    serializedName: "AgeUnit",
    type: {
      name: "Enum",
      allowedValues: [
        "Years",
        "Weeks"
      ]
    }
  }
};
export const apiKey: msRest.OperationURLParameter = {
  parameterPath: "apiKey",
  mapper: {
    required: true,
    serializedName: "apiKey",
    type: {
      name: "String"
    }
  }
};
export const areaTercCodes: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "areaTercCodes"
  ],
  mapper: {
    serializedName: "AreaTercCodes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};
export const checked: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "checked"
  ],
  mapper: {
    serializedName: "Checked",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};
export const codeName0: msRest.OperationURLParameter = {
  parameterPath: "codeName",
  mapper: {
    required: true,
    serializedName: "codeName",
    type: {
      name: "String"
    }
  }
};
export const codeName1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "codeName"
  ],
  mapper: {
    serializedName: "CodeName",
    type: {
      name: "String"
    }
  }
};
export const contentType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "contentType"
  ],
  mapper: {
    serializedName: "contentType",
    type: {
      name: "Enum",
      allowedValues: [
        "JSON",
        "PDF"
      ]
    }
  }
};
export const countyTercCode: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "countyTercCode"
  ],
  mapper: {
    serializedName: "countyTercCode",
    type: {
      name: "String"
    }
  }
};
export const dateFrom0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "dateFrom"
  ],
  mapper: {
    serializedName: "dateFrom",
    type: {
      name: "DateTime"
    }
  }
};
export const dateFrom1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "dateFrom"
  ],
  mapper: {
    serializedName: "DateFrom",
    type: {
      name: "DateTime"
    }
  }
};
export const dateTo0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "dateTo"
  ],
  mapper: {
    serializedName: "dateTo",
    type: {
      name: "DateTime"
    }
  }
};
export const dateTo1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "dateTo"
  ],
  mapper: {
    serializedName: "DateTo",
    type: {
      name: "DateTime"
    }
  }
};
export const deleteDescendants: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "deleteDescendants"
  ],
  mapper: {
    serializedName: "deleteDescendants",
    defaultValue: false,
    type: {
      name: "Boolean"
    }
  }
};
export const dictionaryId: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "dictionaryId"
  ],
  mapper: {
    serializedName: "DictionaryId",
    type: {
      name: "Number"
    }
  }
};
export const dictionaryVersionId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "dictionaryVersionId"
  ],
  mapper: {
    serializedName: "DictionaryVersionId",
    type: {
      name: "Number"
    }
  }
};
export const document: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "document"
  ],
  mapper: {
    serializedName: "Document",
    type: {
      name: "String"
    }
  }
};
export const email: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "email"
  ],
  mapper: {
    serializedName: "Email",
    type: {
      name: "String"
    }
  }
};
export const exportId: msRest.OperationURLParameter = {
  parameterPath: "exportId",
  mapper: {
    required: true,
    serializedName: "exportId",
    type: {
      name: "Uuid"
    }
  }
};
export const field: msRest.OperationURLParameter = {
  parameterPath: "field",
  mapper: {
    required: true,
    serializedName: "field",
    type: {
      name: "String"
    }
  }
};
export const file: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "file"
  ],
  mapper: {
    serializedName: "File",
    type: {
      name: "Stream"
    }
  }
};
export const fileCategoryDictionaryOtherValue: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "fileCategoryDictionaryOtherValue"
  ],
  mapper: {
    serializedName: "FileCategoryDictionaryOtherValue",
    type: {
      name: "String"
    }
  }
};
export const fileCategoryDictionaryValueId: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "fileCategoryDictionaryValueId"
  ],
  mapper: {
    serializedName: "FileCategoryDictionaryValueId",
    type: {
      name: "Number"
    }
  }
};
export const fileType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "fileType"
  ],
  mapper: {
    serializedName: "FileType",
    type: {
      name: "Enum",
      allowedValues: [
        "XLSX",
        "PDF",
        "CSV",
        "JSON"
      ]
    }
  }
};
export const filter0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filter"
  ],
  mapper: {
    serializedName: "filter",
    type: {
      name: "Enum",
      allowedValues: [
        "All",
        "Interventions",
        "ArchiveInterventions",
        "ArchiveStatements",
        "Statements",
        "VoivodeOpinion",
        "AOTMiTPPZ",
        "AOTMiTRecommendations",
        "AOTMiTAnnex",
        "AOTMiTPronouncements",
        "AOTMiTFinalReport"
      ]
    }
  }
};
export const filter1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filter"
  ],
  mapper: {
    serializedName: "Filter",
    type: {
      name: "Enum",
      allowedValues: [
        "All",
        "Interventions",
        "ArchiveInterventions",
        "ArchiveStatements",
        "Statements",
        "VoivodeOpinion",
        "AOTMiTPPZ",
        "AOTMiTRecommendations",
        "AOTMiTAnnex",
        "AOTMiTPronouncements",
        "AOTMiTFinalReport"
      ]
    }
  }
};
export const filter2: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filter"
  ],
  mapper: {
    serializedName: "filter",
    type: {
      name: "Enum",
      allowedValues: [
        "Intervention",
        "Patterns",
        "Archive",
        "Canceled",
        "SelectedIds"
      ]
    }
  }
};
export const filter3: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filter"
  ],
  mapper: {
    serializedName: "Filter",
    type: {
      name: "Enum",
      allowedValues: [
        "Intervention",
        "Patterns",
        "Archive",
        "Canceled",
        "SelectedIds"
      ]
    }
  }
};
export const filter4: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filter"
  ],
  mapper: {
    serializedName: "filter",
    type: {
      name: "Enum",
      allowedValues: [
        "Statement",
        "Archive"
      ]
    }
  }
};
export const filters: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filters"
  ],
  mapper: {
    serializedName: "Filters",
    type: {
      name: "String"
    }
  }
};
export const flatDictionary: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "flatDictionary"
  ],
  mapper: {
    serializedName: "flatDictionary",
    defaultValue: false,
    type: {
      name: "Boolean"
    }
  }
};
export const formId: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "formId"
  ],
  mapper: {
    serializedName: "FormId",
    type: {
      name: "Uuid"
    }
  }
};
export const formPartId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "formPartId"
  ],
  mapper: {
    serializedName: "FormPartId",
    type: {
      name: "Uuid"
    }
  }
};
export const formValidFrom: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "formValidFrom"
  ],
  mapper: {
    serializedName: "FormValidFrom",
    type: {
      name: "DateTime"
    }
  }
};
export const formVersionId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "formVersionId"
  ],
  mapper: {
    serializedName: "FormVersionId",
    type: {
      name: "Uuid"
    }
  }
};
export const gender: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "gender"
  ],
  mapper: {
    serializedName: "Gender",
    type: {
      name: "Enum",
      allowedValues: [
        "Male",
        "Female",
        "All"
      ]
    }
  }
};
export const iCD10Codes: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "iCD10Codes"
  ],
  mapper: {
    serializedName: "ICD10Codes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};
export const id0: msRest.OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    required: true,
    serializedName: "id",
    type: {
      name: "Uuid"
    }
  }
};
export const id1: msRest.OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    required: true,
    serializedName: "id",
    type: {
      name: "Number"
    }
  }
};
export const id2: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "id"
  ],
  mapper: {
    serializedName: "Id",
    type: {
      name: "Number"
    }
  }
};
export const id3: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "id"
  ],
  mapper: {
    serializedName: "Id",
    type: {
      name: "Uuid"
    }
  }
};
export const ids: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "ids"
  ],
  mapper: {
    serializedName: "ids",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Number"
        }
      }
    }
  }
};
export const interventionId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "interventionId"
  ],
  mapper: {
    serializedName: "InterventionId",
    type: {
      name: "Uuid"
    }
  }
};
export const interventionId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "interventionId"
  ],
  mapper: {
    serializedName: "interventionId",
    type: {
      name: "Uuid"
    }
  }
};
export const interventionPath0: msRest.OperationURLParameter = {
  parameterPath: "interventionPath",
  mapper: {
    required: true,
    serializedName: "interventionPath",
    type: {
      name: "Enum",
      allowedValues: [
        "PROHEALTH_ACTIVITIES",
        "RESEARCH",
        "SUPERVISION_MONITORING",
        "SUPERVISION_SANITARY",
        "STAFF_EDUCATION",
        "STAFF_MONITORING",
        "LEGISLATION"
      ]
    }
  }
};
export const interventionPath1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "interventionPath"
  ],
  mapper: {
    serializedName: "InterventionPath",
    type: {
      name: "Enum",
      allowedValues: [
        "PROHEALTH_ACTIVITIES",
        "RESEARCH",
        "SUPERVISION_MONITORING",
        "SUPERVISION_SANITARY",
        "STAFF_EDUCATION",
        "STAFF_MONITORING",
        "LEGISLATION"
      ]
    }
  }
};
export const interventionPath2: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "interventionPath"
  ],
  mapper: {
    serializedName: "InterventionPath",
    type: {
      name: "Object"
    }
  }
};
export const interventionPaths: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "interventionPaths"
  ],
  mapper: {
    serializedName: "interventionPaths",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Enum",
          allowedValues: [
            "PROHEALTH_ACTIVITIES",
            "RESEARCH",
            "SUPERVISION_MONITORING",
            "SUPERVISION_SANITARY",
            "STAFF_EDUCATION",
            "STAFF_MONITORING",
            "LEGISLATION"
          ]
        }
      }
    }
  }
};
export const isCheckedAll: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "isCheckedAll"
  ],
  mapper: {
    serializedName: "IsCheckedAll",
    type: {
      name: "Boolean"
    }
  }
};
export const isCodeName: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "isCodeName"
  ],
  mapper: {
    serializedName: "IsCodeName",
    type: {
      name: "Boolean"
    }
  }
};
export const isTechnicalVersion: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "isTechnicalVersion"
  ],
  mapper: {
    serializedName: "IsTechnicalVersion",
    type: {
      name: "Boolean"
    }
  }
};
export const labels0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "labels"
  ],
  mapper: {
    serializedName: "labels",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};
export const labels1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "labels"
  ],
  mapper: {
    serializedName: "Labels",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};
export const manualType0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "manualType"
  ],
  mapper: {
    serializedName: "ManualType",
    type: {
      name: "Enum",
      allowedValues: [
        "Main",
        "Administrative",
        "Intervention",
        "Statement",
        "Subject",
        "RODO"
      ]
    }
  }
};
export const manualType1: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "manualType"
  ],
  mapper: {
    serializedName: "ManualType",
    type: {
      name: "Object"
    }
  }
};
export const manualType2: msRest.OperationURLParameter = {
  parameterPath: "manualType",
  mapper: {
    required: true,
    serializedName: "manualType",
    type: {
      name: "Enum",
      allowedValues: [
        "Main",
        "Administrative",
        "Intervention",
        "Statement",
        "Subject",
        "RODO"
      ]
    }
  }
};
export const maxAge: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "maxAge"
  ],
  mapper: {
    serializedName: "MaxAge",
    type: {
      name: "Number"
    }
  }
};
export const minAge: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "minAge"
  ],
  mapper: {
    serializedName: "MinAge",
    type: {
      name: "Number"
    }
  }
};
export const objectId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "objectId"
  ],
  mapper: {
    serializedName: "objectId",
    type: {
      name: "Uuid"
    }
  }
};
export const objectType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "objectType"
  ],
  mapper: {
    serializedName: "objectType",
    type: {
      name: "Enum",
      allowedValues: [
        "Intervention",
        "Activity",
        "Population",
        "SponsoringCompany"
      ]
    }
  }
};
export const onlyActiveSubjects: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "onlyActiveSubjects"
  ],
  mapper: {
    serializedName: "onlyActiveSubjects",
    defaultValue: false,
    type: {
      name: "Boolean"
    }
  }
};
export const onlyMetadata: msRest.OperationURLParameter = {
  parameterPath: "onlyMetadata",
  mapper: {
    required: true,
    serializedName: "onlyMetadata",
    type: {
      name: "Boolean"
    }
  }
};
export const page0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "page"
  ],
  mapper: {
    serializedName: "Page",
    constraints: {
      InclusiveMaximum: 2147483647,
      InclusiveMinimum: 1
    },
    type: {
      name: "Number"
    }
  }
};
export const page1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "page"
  ],
  mapper: {
    serializedName: "Page",
    type: {
      name: "Number"
    }
  }
};
export const pageSize0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "pageSize"
  ],
  mapper: {
    serializedName: "PageSize",
    constraints: {
      InclusiveMaximum: 2147483647,
      InclusiveMinimum: 1
    },
    type: {
      name: "Number"
    }
  }
};
export const pageSize1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "pageSize"
  ],
  mapper: {
    serializedName: "PageSize",
    type: {
      name: "Number"
    }
  }
};
export const parentId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "parentId"
  ],
  mapper: {
    serializedName: "parentId",
    type: {
      name: "Number"
    }
  }
};
export const proposalAccesType: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "proposalAccesType"
  ],
  mapper: {
    serializedName: "ProposalAccesType",
    type: {
      name: "Object"
    }
  }
};
export const query: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "query"
  ],
  mapper: {
    serializedName: "query",
    type: {
      name: "Object"
    }
  }
};
export const reportType0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "reportType"
  ],
  mapper: {
    serializedName: "ReportType",
    type: {
      name: "Enum",
      allowedValues: [
        "StatementNPZ",
        "StatementMZ49",
        "StatementMZ53",
        "AOTMiTFinalReport",
        "ActivityProhealthActivities",
        "ActivityResearch",
        "ActivitySupervisionMonitoring",
        "ActivitySupervisionSanitary",
        "ActivityStaffEducation",
        "ActivityStaffMonitoring",
        "ActivityLegislation",
        "InterventionProhealthActivities",
        "InterventionResearch",
        "InterventionSupervisionMonitoring",
        "InterventionSupervisionSanitary",
        "InterventionStaffEducation",
        "InterventionStaffMonitoring",
        "InterventionLegislation"
      ]
    }
  }
};
export const reportType1: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "reportType"
  ],
  mapper: {
    serializedName: "ReportType",
    type: {
      name: "Object"
    }
  }
};
export const rODOManualType0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "rODOManualType"
  ],
  mapper: {
    serializedName: "RODOManualType",
    type: {
      name: "Enum",
      allowedValues: [
        "ProposalAccess",
        "CRUIP"
      ]
    }
  }
};
export const rODOManualType1: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "rODOManualType"
  ],
  mapper: {
    serializedName: "RODOManualType",
    type: {
      name: "Object"
    }
  }
};
export const selectedIds: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "selectedIds"
  ],
  mapper: {
    serializedName: "selectedIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};
export const sieveFilters: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sieveFilters"
  ],
  mapper: {
    serializedName: "Sieve.Filters",
    type: {
      name: "String"
    }
  }
};
export const sievePage: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sievePage"
  ],
  mapper: {
    serializedName: "Sieve.Page",
    constraints: {
      InclusiveMaximum: 2147483647,
      InclusiveMinimum: 1
    },
    type: {
      name: "Number"
    }
  }
};
export const sievePageSize: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sievePageSize"
  ],
  mapper: {
    serializedName: "Sieve.PageSize",
    constraints: {
      InclusiveMaximum: 2147483647,
      InclusiveMinimum: 1
    },
    type: {
      name: "Number"
    }
  }
};
export const sieveSorts: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sieveSorts"
  ],
  mapper: {
    serializedName: "Sieve.Sorts",
    type: {
      name: "String"
    }
  }
};
export const simcCode: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "simcCode"
  ],
  mapper: {
    serializedName: "SimcCode",
    type: {
      name: "String"
    }
  }
};
export const sorts: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sorts"
  ],
  mapper: {
    serializedName: "Sorts",
    type: {
      name: "String"
    }
  }
};
export const statementId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "statementId"
  ],
  mapper: {
    serializedName: "StatementId",
    type: {
      name: "Uuid"
    }
  }
};
export const subjectId0: msRest.OperationURLParameter = {
  parameterPath: "subjectId",
  mapper: {
    required: true,
    serializedName: "subjectId",
    type: {
      name: "Uuid"
    }
  }
};
export const subjectId1: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "subjectId"
  ],
  mapper: {
    serializedName: "SubjectId",
    type: {
      name: "Uuid"
    }
  }
};
export const subjectKind: msRest.OperationURLParameter = {
  parameterPath: "subjectKind",
  mapper: {
    required: true,
    serializedName: "subjectKind",
    type: {
      name: "Enum",
      allowedValues: [
        "PSSE",
        "WSSE",
        "GSSE",
        "PZH",
        "GIS",
        "HOSPITAL",
        "CONTRACTOR",
        "WCZP",
        "DEPARTMENT",
        "DISTRICT",
        "GM",
        "GMW",
        "GW",
        "MNP",
        "SP",
        "UM",
        "UW",
        "IC",
        "M",
        "EDUCATIONALUNIT"
      ]
    }
  }
};
export const tag: msRest.OperationURLParameter = {
  parameterPath: "tag",
  mapper: {
    required: true,
    serializedName: "tag",
    type: {
      name: "String"
    }
  }
};
export const tercCode0: msRest.OperationURLParameter = {
  parameterPath: "tercCode",
  mapper: {
    required: true,
    serializedName: "tercCode",
    type: {
      name: "String"
    }
  }
};
export const tercCode1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "tercCode"
  ],
  mapper: {
    serializedName: "TercCode",
    type: {
      name: "String"
    }
  }
};
export const type: msRest.OperationURLParameter = {
  parameterPath: "type",
  mapper: {
    required: true,
    serializedName: "type",
    type: {
      name: "Enum",
      allowedValues: [
        "StatementNPZ",
        "StatementMZ49",
        "StatementMZ53",
        "AOTMiTFinalReport",
        "ActivityProhealthActivities",
        "ActivityResearch",
        "ActivitySupervisionMonitoring",
        "ActivitySupervisionSanitary",
        "ActivityStaffEducation",
        "ActivityStaffMonitoring",
        "ActivityLegislation",
        "InterventionProhealthActivities",
        "InterventionResearch",
        "InterventionSupervisionMonitoring",
        "InterventionSupervisionSanitary",
        "InterventionStaffEducation",
        "InterventionStaffMonitoring",
        "InterventionLegislation"
      ]
    }
  }
};
export const unchecked: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "unchecked"
  ],
  mapper: {
    serializedName: "Unchecked",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};
export const userManualAdministrativeType0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "userManualAdministrativeType"
  ],
  mapper: {
    serializedName: "UserManualAdministrativeType",
    type: {
      name: "Enum",
      allowedValues: [
        "Global",
        "Local"
      ]
    }
  }
};
export const userManualAdministrativeType1: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "userManualAdministrativeType"
  ],
  mapper: {
    serializedName: "UserManualAdministrativeType",
    type: {
      name: "Object"
    }
  }
};
export const validFrom: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "validFrom"
  ],
  mapper: {
    serializedName: "ValidFrom",
    type: {
      name: "DateTime"
    }
  }
};
export const voivodeshipTercCode: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "voivodeshipTercCode"
  ],
  mapper: {
    serializedName: "voivodeshipTercCode",
    type: {
      name: "String"
    }
  }
};
export const xLSXDefinition: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "xLSXDefinition"
  ],
  mapper: {
    serializedName: "XLSXDefinition",
    type: {
      name: "Stream"
    }
  }
};
export const xSLTDefinition: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "xSLTDefinition"
  ],
  mapper: {
    serializedName: "XSLTDefinition",
    type: {
      name: "Stream"
    }
  }
};
