import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import useSelectsAccessibility from '@hooks/accessibility/useSelectsAccessibilityHook';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Col, Row, Select, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PermissionValidator from 'authorization/permissionValidator';
import { FFieldLabel } from 'forms/FormikFormItems';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import PrimaryCard from 'layout/PrimaryCard';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { DictionaryDto, DictionaryViewDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';

import DictionaryDescriptionModal from '../modals/DictionaryDescriptionModal';

interface IProps {
	dictionaryId?: number;
}

interface IState {
	dictionariesList: DictionaryViewDto[] | undefined;
	dictionary: DictionaryDto | undefined;
}

const DictionariesTable: React.FC<IProps & RouteComponentProps> = (props) => {
	const [state, setState] = useState<IState>({
		dictionariesList: [],
		dictionary: undefined,
	});

	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const history = useHistory();

	useEffect(() => {
		const abortController = new AbortController();
		const abortSignal = abortController.signal;
		getProfiBazaApiClient()
			.then((api) =>
				api.dictionary.list({
					filters: undefined,
					page: undefined,
					pageSize: 9999,
					sorts: undefined,
					abortSignal,
				})
			)
			.then((result: ProfiBazaAPIModels.DictionaryViewDtoPagedResult) =>
				setState({
					dictionariesList: result.results,
					dictionary: result.results?.find(
						(x) => x.id == props.dictionaryId
					),
				})
			);

		return () => {
			abortController.abort();
		};
	}, []);

	useEffect(() => {
		if (state.dictionary && !props.dictionaryId) {
			setState({ ...state, dictionary: undefined });
		}

		if (
			(!state.dictionary && props.dictionaryId) ||
			(state.dictionary && state.dictionary.id !== props.dictionaryId)
		) {
			setState({
				...state,
				dictionary: state.dictionariesList?.find(
					(x) => x.id == props.dictionaryId
				),
			});
		}
	}, [props.dictionaryId]);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const permissionValidator = new PermissionValidator(
		accountDetailsStore!.account.get()!
	);

	const setDescription = (newDescription: string) =>
		setState({
			...state,
			dictionary: {
				...state.dictionary,
				description: newDescription,
			},
		});

	useSelectsAccessibility();

	return (
		<>
			<PrimaryCard>
				<CenteredRow>
					<Col span={8}>
						<FFieldLabel
							label="Wybierz słownik"
							for="dictionaryId"
						/>
						<SkipLinkContent />
						<Select
							id="dictionaryId"
							value={state.dictionary?.id}
							onChange={(value) => {
								setState({
									...state,
									dictionary: state.dictionariesList?.find(
										(x) => x.id == value
									),
								});
								props.history.push(`/dictionaries/${value}`);
								gridStore.clearDictionariesVersionsMeta.set(
									Date.now()
								);
							}}
						>
							{state.dictionariesList?.map((x, i) => (
								<Select.Option key={i} value={x.id!}>
									{x.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col span={1}></Col>
					<Col span={14}>
						{state.dictionary && (
							<Row>
								<FFieldLabel
									label="Opis słownika"
									style={{ width: '100%' }}
								/>
								<Space
									style={{
										width: '100%',
									}}
									className="dictionary-textarea"
								>
									{permissionValidator.has(
										ProfiBazaAPIModels.Permission
											.DictionariesVersionsModifications
									) && (
										<DictionaryDescriptionModal
											dictionary={state.dictionary}
											setDescription={setDescription}
										/>
									)}
									<TextArea
										aria-label="Opis słownika"
										value={state.dictionary?.description}
										disabled={true}
										placeholder="BRAK"
									/>
								</Space>
							</Row>
						)}
					</Col>
				</CenteredRow>
			</PrimaryCard>
		</>
	);
};

export default DictionariesTable;
