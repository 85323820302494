import React, { createContext, useContext } from 'react';
import { DictionaryLevelHistory } from '../DictionaryLevelHistory';
import {
	DictionaryValueItemDto,
	DictionaryInterventionPath,
} from 'services/src/models';
import { MultiDictionaryContextType } from './MultiDictionaryContext';

export type MultiDictionaryBodyContextType = {
	currentLevel: number;
	handleLevelChange: (level: number) => void;
	levels: DictionaryLevelHistory;
	keysStack: Array<DictionaryValueItemDto | undefined>;
	switchToLevel: (level: number) => void;
	dictionaryState?: MultiDictionaryContextType;
	values: DictionaryValueItemDto[];
	selectableMode: boolean | undefined;
	rootValue: DictionaryValueItemDto | undefined;
	renderSearchButton: () => JSX.Element;
	processFilters: DictionaryInterventionPath[] | undefined;
	setProcessFilters: React.Dispatch<
		React.SetStateAction<DictionaryInterventionPath[] | undefined>
	>;
};

export const MultiDictionaryBodyContext = createContext<
	MultiDictionaryBodyContextType
>({
	currentLevel: 0,
	handleLevelChange: (level: number) => {},
	levels: new DictionaryLevelHistory(),
	keysStack: [],
	switchToLevel: (level: number) => {},
	dictionaryState: undefined,
	values: [],
	selectableMode: undefined,
	rootValue: undefined,
	renderSearchButton: () => <></>,
	processFilters: [],
	setProcessFilters: () => {},
});
