import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';
import * as React from 'react';

export const CenteredSpin = (props = { size: 'large' as SpinSize }) => (
	<div
		style={{
			display: 'flex',
			padding: 64,
			justifyContent: 'center',
			alignContent: 'center',
		}}
	>
		<Spin size={props.size} />
	</div>
);
