import './TableSideBar.less';

import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Button, Layout, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import PermissionValidator from 'authorization/permissionValidator';
import { useRootData } from 'hooks/hook';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';
import { GridNameType } from 'stores/TableColumnStore';

const { Sider } = Layout;
export interface ISideFilter {
	label: string | React.ReactElement;
	key: string;
	filter: string | undefined;
	defaultSelected?: boolean;
	permissions?: ProfiBazaAPIModels.Permission[];
	subItems?: ISideFilter[];
	hint?: string;
}

interface IProps {
	gridName: string;
	filters: ISideFilter[];
	selectedFilter?: string;
	path: string;
	siderWidth?: number;
}

const TableSideBar: React.FC<IProps> = (props) => {
	const { filters, selectedFilter, path, siderWidth, gridName } = props;
	let history = useHistory();

	const defaultKeys = filters
		.filter((filter) => filter.defaultSelected)
		.map((filter) => filter.key);

	const [selectedKeys, setSelectedKeys] = useState<string[]>(defaultKeys);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const permissionValidator = new PermissionValidator(
		accountDetailsStore!.account.get()!
	);

	const pushRouteFilter = (filter: string) =>
		history.push(`/${path}/${filter}`);

	useEffect(() => {
		if (selectedFilter) {
			setSelectedKeys([selectedFilter]);
		}
		gridStore.setBackTab(selectedFilter);
	}, [selectedFilter]);

	const openKeys = filters?.find((f) => {
		if (!f.subItems) return false;
		return f.subItems.some((si) => si?.key === selectedFilter);
	})?.key;

	return (
		<Sider
			width={siderWidth ?? 150}
			className="site-layout-background sidebar-layout"
			style={{ paddingBottom: 48 }}
		>
			<Menu
				mode="inline"
				defaultSelectedKeys={defaultKeys}
				selectedKeys={selectedKeys}
				defaultOpenKeys={openKeys === undefined ? [] : [openKeys]}
				inlineIndent={6}
				onSelect={(param) => {
					const { key, selectedKeys } = param;
					if (selectedKeys) {
						setSelectedKeys(selectedKeys as string[]);
						pushRouteFilter(selectedKeys[0] as string);
					}
				}}
				style={{ height: '100%' }}
			>
				{filters
					.filter(
						(filter) =>
							filter.permissions === undefined ||
							permissionValidator.hasAny(filter.permissions)
					)
					.map((filter) =>
						renderFilter(filter, path, permissionValidator)
					)}
			</Menu>
		</Sider>
	);
};

const renderFilter = (
	filter: ISideFilter,
	path: string,
	permissionValidator: PermissionValidator
) => (
	<>
		{filter.subItems
			? renderSubMenuItem(filter, path, permissionValidator)
			: renderMenuItem(filter, path)}
	</>
);

const renderMenuItem = (filter: ISideFilter, path: string) => {
	const content = <Link to={`/${path}/${filter.key}`}>{filter.label}</Link>;
	return (
		<Menu.Item key={filter.key} role="menuitem">
			{hintItem(content, filter.hint)}
		</Menu.Item>
	);
};

const renderSubMenuItem = (
	filter: ISideFilter,
	path: string,
	permissionValidator: PermissionValidator
) => {
	const renderLink = (subFilter: ISideFilter) => (
		<Link to={`/${path}/${subFilter.key}`}>{subFilter.label}</Link>
	);
	const submenuButton = (
		<Button type="text" className="sidebar-layout-submenu-item">
			{filter.label}
		</Button>
	);

	return (
		<SubMenu key={filter.key} title={hintItem(submenuButton, filter.hint)}>
			{filter
				.subItems!.filter(
					(subFilter) =>
						subFilter.permissions === undefined ||
						permissionValidator.hasAny(subFilter.permissions)
				)
				.map((subFilter) => (
					<Menu.Item role={'button'} key={subFilter.key}>
						{hintItem(renderLink(subFilter), subFilter.hint)}
					</Menu.Item>
				))}
		</SubMenu>
	);
};

const hintItem = (item: JSX.Element, hint: string | undefined) => {
	if (hint) {
		return (
			<ProfiBazaTooltip
				title={hint}
				trigger={['hover', 'focus']}
				placement="right"
				mouseLeaveDelay={undefined}
			>
				{item}
			</ProfiBazaTooltip>
		);
	}
	return item;
};

export default observer(TableSideBar);
