import React, { useLayoutEffect } from 'react';

const useCalendarAccessibility = () => {
	useLayoutEffect(() => {
		const pickerPanel = document.querySelector('.ant-picker-panel');
		pickerPanel?.setAttribute('role', 'group');
		const headerButtons = document.querySelectorAll(
			'[aria-label="left"], [aria-label="right"]'
		);
		headerButtons.forEach((btnContentNode) => {
			const parent = btnContentNode.parentElement;
			const contentLabel = btnContentNode.getAttribute('aria-label');
			if (parent) {
				parent.setAttribute('aria-label', getAriaLabel(contentLabel));
			}
		});
	}, []);
};

const getAriaLabel = (contentLabel: string | null): string => {
	switch (contentLabel) {
		case 'left':
			return 'Poprzedni';
		case 'right':
			return 'Następny';
		default:
			return 'Przycisk nawigacyjny';
	}
};

export default useCalendarAccessibility;
