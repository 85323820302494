import { InterventionCommentArea } from '@components/intervention/comments/InterventionCommentArea';
import { InterventionCommentIcon } from '@components/intervention/comments/InterventionCommentIcon';
import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { handleOnPressEnter } from '@helper/accessibilityHelpers';
import { usePrevious } from '@hooks/usePreviousHook';
import { Space } from 'antd';
import { Field, FormikActions as FormikProps } from 'formik';
import { FSelectArea } from 'forms/FSelectAreaPicker';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { FFieldLabel } from '../../../../forms/FormikFormItems';
import {
	AreaCodeNames,
	TerritoryType,
	deleteRecordValue,
	getRecordValue,
} from './TeryAreaMappers';
import TerytAreaModal from './TerytAreaModal';

export interface SearchOptions {
	detailLevel: TerritoryType;
	onSearch: () => void;
	onClear: () => void;
}

interface IProps<T> {
	label: string;
	name: string;
	editable: boolean;
	initialValues: ProfiBazaAPIModels.AreaValueDto[];

	values: ProfiBazaAPIModels.AreaValueDto[];
	setValues: (areas: ProfiBazaAPIModels.AreaValueDto[]) => void;
	formikActions?: FormikProps<T>;
	search?: boolean;
	searchOptions?: SearchOptions;
	hasNationwide?: boolean;
	validTerytAreaLvl?: AreaCodeNames;
	restrictTerytAreas?: string[];
	checkChanges?: (areas: string[]) => void;
	helpField?: string;
	commentField?: string;
}

const TerytAreaPicker = <T extends {}>(props: IProps<T>) => {
	const {
		name,
		label,
		editable,
		initialValues,
		values,
		setValues,
		formikActions,
		search,
		searchOptions,
		hasNationwide,
		validTerytAreaLvl,
		restrictTerytAreas,
		helpField,
		commentField,
	} = props;

	const [visible, setVisible] = useState<boolean>(false);
	const previousVisible = usePrevious(visible);
	let inputRef = useRef<any>(null);

	useEffect(() => {
		if (initialValues) {
			setValues(initialValues);
			formikActions?.setFieldValue(
				name,
				initialValues.map((area) => area.terytCode)
			);
		}
	}, []);

	const handleOpen = () => {
		if (editable) {
			setVisible(true);
			formikActions?.setFieldTouched(name, true);
		}
	};

	useLayoutEffect(() => {
		if (previousVisible && !visible) {
			inputRef.current?.focus();
		}
		return () => {};
	}, [visible]);

	return (
		<>
			<>
				<Space>
					<FFieldLabel label={label} for={name} />
					{helpField && (
						<LabelHelpTooltip
							field={helpField}
							defaultValue={label}
						/>
					)}
					{commentField && (
						<InterventionCommentIcon fieldName={commentField} />
					)}
				</Space>
				<InterventionCommentArea fieldName={commentField}>
					<Field
						innerRef={(input: any) => {
							inputRef.current = input;
						}}
						className="teryt-input"
						mode="tags"
						label={label}
						component={FSelectArea}
						name={name}
						id={name}
						tagRender={true}
						onClose={(val: string) => {
							let list = deleteRecordValue(values, val);
							props.checkChanges?.(list.map((x) => x.terytCode!));
							setValues(list);
							formikActions?.setFieldTouched(name, true);
							return list.map((val) => val.terytCode!);
						}}
						onClick={handleOpen}
						onKeyDown={handleOnPressEnter(() => {
							if (!visible) {
								handleOpen();
							}
						})}
						array={
							values.length
								? values.map((val) => getRecordValue(val))
								: []
						}
						editable={editable}
						disabled={!editable}
					/>
				</InterventionCommentArea>
			</>

			{visible && (
				<>
					<TerytAreaModal
						visible={visible}
						setVisible={setVisible}
						values={values}
						search={search}
						searchOptions={searchOptions}
						hasNationwide={hasNationwide}
						validTerytAreaLvl={validTerytAreaLvl}
						restrictTerytAreas={restrictTerytAreas}
						setValues={(
							areas: ProfiBazaAPIModels.AreaValueDto[]
						) => {
							setValues(areas);
							formikActions?.setFieldValue(
								name,
								areas.map((area) => area.terytCode)
							);
							props.checkChanges &&
								props.checkChanges(
									areas.map((area) => area.terytCode!)
								);
							formikActions
								?.validateForm()
								.then(() =>
									formikActions.setFieldTouched(name, true)
								);
						}}
					/>
				</>
			)}
		</>
	);
};

export default TerytAreaPicker;
