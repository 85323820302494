import { Col } from 'antd';
import DictionariesTable from './table/DictionariesTable';
import React from 'react';
import Form from 'antd/lib/form/Form';
import SearchBar from 'components/shared/paginatedProfiBazaTable/SearchBar';
import { CenteredRow } from 'layout/CenteredRow';
import AddButton from 'components/shared/buttons/AddButton';
import GridRow from 'components/shared/GridRow';
import DictionaryDetails from './DictionaryDetails';
import { RouteComponentProps } from 'react-router';

interface IProps {
    dictionaryId?: number;
    dictionaryVersionId?: number;
}

const Dictionaries: React.FC<IProps & RouteComponentProps> = (props) => {
    return (
        <>
            <CenteredRow>
                <Col span={22}>
                    <DictionariesTable {...props} />
                </Col>
                <Col span={22}>
                    {props.dictionaryId && (
                        <DictionaryDetails
                            dictionaryId={props.dictionaryId}
                            dictionaryVersionId={props.dictionaryVersionId}
                        ></DictionaryDetails>
                    )}
                </Col>
            </CenteredRow>
        </>
    );
};

export default Dictionaries;
