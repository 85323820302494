import { List } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import NotificationOccurrenceCard from './NotificationOccurrenceCard';

interface IProps {
	data: ProfiBazaAPIModels.UserNotificationDTO[];
	loading: boolean;
	handleInfiniteOnLoad: (page: number) => void;
	hasNextPage: boolean;
	initialLoad?: boolean;
	unread?: boolean;
}

const NotificationOccurrenceList: React.FC<IProps> = (props) => {
	const {
		data,
		loading,
		handleInfiniteOnLoad,
		hasNextPage,
		initialLoad,
		unread,
	} = props;

	return (
		<InfiniteScroll
			initialLoad={initialLoad}
			pageStart={1}
			loadMore={handleInfiniteOnLoad}
			hasMore={!loading && hasNextPage}
			useWindow={false}
		>
			<List
				className="notification-occurance-list"
				itemLayout="horizontal"
				dataSource={data}
				split={false}
				rowKey={(record) => record.id!}
				loading={loading}
				renderItem={(item) => (
					<List.Item>
						<NotificationOccurrenceCard
							item={item}
							unread={unread}
						/>
					</List.Item>
				)}
			/>
		</InfiniteScroll>
	);
};

export default NotificationOccurrenceList;
