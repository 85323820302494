import { DictionariesConstants } from 'services/DictionariesConstants';
import {
	InterventionPatternType,
	RizpDictionaryValueDto,
} from 'services/src/models';
import * as Yup from 'yup';

export const InterventionPatternValidationSchema = () =>
	Yup.object().shape({
		patternName: Yup.string()
			.required('Nazwa wzorca jest wymagana!')
			.max(128, `Nazwa wzorca może mieć maksymalnie 128 znaków`),
		patternType: Yup.string().required('Typ wzorca jest wymagany!'),
		interventionAreaDictionaryValueId: Yup.number()
			.nullable()
			.test(
				'intervention-area-test',
				'Zasięg interwencji jest wymagany!',
				function (value: number) {
					const isAotmit =
						this.parent['patternType'] &&
						this.parent['patternType'] ==
							InterventionPatternType.AOTMITRECOMENDATION;
					if (isAotmit) return true;

					return value != undefined;
				}
			),
		interventionAreaDictionaryOtherValue: Yup.string().nullable(),
		name: Yup.string()
			.nullable()
			.test(
				'intervention-name-test',
				'Nazwa interwencji jest wymagana!',
				function (value: string) {
					const isAotmit =
						this.parent['patternType'] &&
						this.parent['patternType'] ==
							InterventionPatternType.AOTMITRECOMENDATION;
					if (isAotmit) return true;

					return value != undefined;
				}
			)
			.transform((value: string, originalValue: string) =>
				originalValue.trim() === '' ? null : value
			)
			.max(128, `Nazwa inwerwencji może mieć maksymalnie 128 znaków`),
		isCompliantWithRecommendation: Yup.boolean()
			.nullable(true)
			.test(
				'isCompliantWithRecommendation-test-aotmit',
				'Pole jest wymagane',
				function (value: boolean | undefined) {
					switch (
						this.parent.patternType as InterventionPatternType
					) {
						case InterventionPatternType.AOTMITRECOMENDATION:
							return value!;

						default:
							return true;
					}
				}
			),
	});
