import * as Yup from 'yup';

const requiredMessage: string = 'Wartość jest wymagana';

export const UserPrivateOccurrenceValidationSchema = Yup.object().shape({
	validDateRange: Yup.array().test(
		'required',
		'Data zdarzenia jest wymagana!',
		(x) => !!x && !!x[0] && !!x[1]
	),
	header: Yup.string().required(requiredMessage).nullable().max(50),
	triggeredWeekBefore: Yup.bool(),
	triggeredDayBefore: Yup.bool(),
	triggeredExactDay: Yup.bool(),
	customTriggerActivation: Yup.bool(),
	customTrigger: Yup.number().min(0, "Wartość niestandardowego terminu wysyłki musi być dodatnia")
});
