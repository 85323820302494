import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import  * as IntervantionStatus from './InterventionStatus';

const interventionStatusAmended = () => {
	var statuses = IntervantionStatus.mappings;
	statuses.delete(ProfiBazaAPIModels.PublicHealthInterventionStatus.DRAFT)
	statuses.delete(ProfiBazaAPIModels.PublicHealthInterventionStatus.DELETED)
	return statuses;
}

export const mappings = interventionStatusAmended()

export const map = (
	value: ProfiBazaAPIModels.PublicHealthInterventionStatus
): string => mappings.get(value) ?? '';
