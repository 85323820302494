import { IInterventionCommentStore } from '@components/intervention/comments/store/InterventionCommentStore';
import { useRootData } from '@hooks/hook';
import { ActivityContext } from 'components/intervention/context/ActivityContext';
import { FormikActions } from 'formik';
import produce from 'immer';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import React, { useContext, useEffect, useRef } from 'react';
import {
	BusinessObjectType,
	DictionaryInterventionPath,
	SponsoringCompanyValueDto,
	SubjectIdNameDto,
} from 'services/src/models';
import { IRizpDictionaryStore } from 'stores/RizpDictionaryStore';

import { SponsorsValidationSchema } from '../../model/InterventionActivityValidation';
import {
	ISponsorsForm,
	SponsorsForm,
	getSponsoringCompaniesColumns,
} from '../../model/InterventionActiviyTableColumns';
import ActivityDetailsTableTemplate from '../ActivityDetailsTableTemplate';

interface IProps {
	editable: boolean;
	changeData: (value: SponsoringCompanyValueDto[]) => void;
	mergedSubjectsMap: Map<string, SubjectIdNameDto>;
	dateRange:
		| [(Moment | undefined)?, (Moment | undefined)?]
		| Moment[]
		| undefined;
	changedDictionariesValues: SponsoringCompanyValueDto[] | undefined;
}

export type SubjectKeyValue = Pick<
	SponsoringCompanyValueDto,
	'subjectId' | 'subjectName'
>;

export type MergedSponsoringCompanyValueDto = SponsoringCompanyValueDto & {
	prevSubjectId?: string;
	prevSubjectName?: string;
};

const SponsoringCompanyTable = (props: IProps) => {
	const activityContext = useContext(ActivityContext);
	const { formik } = activityContext;

	const childRef = useRef<ISponsorsForm>() as React.RefObject<ISponsorsForm>;

	const interventionCommentStore: IInterventionCommentStore = useRootData(
		(store) => store.interventionCommentStore
	);

	const rizpDictionaryStore: IRizpDictionaryStore = useRootData(
		(store) => store.rizpDictionaryStore
	);

	useEffect(() => {
		const formikValue = formik?.values['sponsoringCompanies'] ?? [];

		const merged = formikValue.map(
			(x): MergedSponsoringCompanyValueDto => {
				if (x.subjectId) {
					const merged = props.mergedSubjectsMap.get(x.subjectId);
					if (merged) {
						return {
							...x,
							prevSubjectId: x.subjectId,
							prevSubjectName: x.subjectName,
							subjectId: merged.id,
							subjectName: merged.name,
						};
					}
				}
				return x;
			}
		);

		if (!_.isEqual(formikValue, merged)) {
			formik?.setFieldValue('sponsoringCompanies', merged);
		}
	}, [formik?.values['sponsoringCompanies']]);

	const getSponsoringCompanies = () =>
		formik?.values['sponsoringCompanies'] ?? [];

	const cardContent = (
		item?: SponsoringCompanyValueDto,
		formikActions?: FormikActions<SponsoringCompanyValueDto>,
		previewMode?: boolean
	) => (
		<SponsorsForm
			interventionPath={activityContext.interventionPath}
			readonly={!props.editable}
			dateRange={props.dateRange}
			editable={props.editable!}
			ref={childRef}
			setList={(list: SubjectKeyValue[]) => {
				if (list.length) {
					const keyValuePair = list[0];
					formikActions?.setFieldValue(
						'subjectId',
						keyValuePair.subjectId
					);
					formikActions?.setFieldValue(
						'subjectName',
						keyValuePair.subjectName
					);
				}
			}}
			item={item}
			canAddMainSubject={true}
			formikActions={formikActions}
			selectedRows={getSponsoringCompanies() as any[]}
			setSelectedRows={(values) => {}}
			previewMode={previewMode}
		/>
	);

	return (
		<ActivityDetailsTableTemplate<MergedSponsoringCompanyValueDto>
			array={getSponsoringCompanies()}
			addButtonLabel="Dodaj podmiot finansujący"
			validationScheme={SponsorsValidationSchema(
				activityContext.isPattern,
				activityContext.interventionPath
			)}
			initRizpDictionary={(objectId: string) => rizpDictionaryStore.fetch(objectId, BusinessObjectType.SponsoringCompany)}
			columns={getSponsoringCompaniesColumns(
				activityContext.interventionPath ===
					DictionaryInterventionPath.PROHEALTHACTIVITIES,
				interventionCommentStore.comments.get()
			)}
			editable={props.editable}
			changedDictionariesValues={props.changedDictionariesValues}
			canPreview={true}
			getItemId={(item) => item.subjectId}
			patternCollection={
				activityContext.patternActivity?.sponsoringCompanies!
			}
			isPattern={activityContext.isPattern}
			onCancel={() => {
				childRef.current?.resetForm();
				interventionCommentStore.filterParams.set({
					activityId: activityContext.activity?.id!,
					populationId: null,
					sponsoringCompanyId: null,
				});
			}}
			onCreate={(value: SponsoringCompanyValueDto) => {
				const formikField = getSponsoringCompanies();
				props.changeData(formikField);

				formik?.setFieldValue(
					'sponsoringCompanies',
					produce(formikField, (draft) => {
						if (value.isMainSubject) {
							draft.forEach((x) => (x.isMainSubject = false));
						}
						draft.push(value);
					})
				);
				formik?.setFieldTouched('sponsoringCompanies');
				childRef.current?.resetForm();
			}}
			onUpdate={(
				oldValue: SponsoringCompanyValueDto,
				updatedValue: SponsoringCompanyValueDto
			) => {
				const formikField = getSponsoringCompanies();

				props.changeData(formikField);

				const updatedItems = produce(formikField, (draft) => {
					const indexToUpdate = formikField.findIndex(
						(value) => value.subjectId === oldValue.subjectId
					);

					if (indexToUpdate !== -1) {
						if (updatedValue.isMainSubject) {
							draft.forEach((x) => (x.isMainSubject = false));
						}

						draft[indexToUpdate] = updatedValue;
					}
				});

				formik?.setFieldValue('sponsoringCompanies', updatedItems);
				childRef.current?.resetForm();
				formik?.setFieldTouched('sponsoringCompanies');
			}}
			onRemove={(values) => {
				props.changeData(values);
				childRef.current?.resetForm();

				formik?.setFieldValue('sponsoringCompanies', values);
				formik?.setFieldTouched('sponsoringCompanies');
			}}
			onRecordSelect={(record: any) => {
				interventionCommentStore.filterParams.set({
					activityId: activityContext.activity?.id!,
					populationId: null,
					sponsoringCompanyId: record.id,
				});
			}}
			cleanSideEffects={[
				{ key: 'isMainSubject', value: false },
				{ key: 'plannedCosts', value: undefined },
				{ key: 'realizationCosts', value: undefined },
				{ key: 'financingSourceDictionaryValues', value: [] },
				{ key: 'subjectId', value: undefined },
				{ key: 'subjectName', value: undefined },
				{ key: 'sponsorCompaniesId', value: undefined },
			]}
			cardContent={cardContent}
		/>
	);
};

export default observer(SponsoringCompanyTable);
