import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { PageOrientation } from 'services/src/models';

export class UpdateFormValueDto {
	id?: string;
	applicationName?: string;
	order?: number;
	label?: string;
	pageOrientation?: PageOrientation;
	isVisibleInViewModel?: boolean;

	constructor(model: ProfiBazaAPIModels.FormPartDto) {
		this.id = model.id;
		this.pageOrientation = model.pageOrientation;
		this.order = model.order;
		this.label = model.label;
		this.pageOrientation = model.pageOrientation;
		this.isVisibleInViewModel = model.isVisibleInViewModel;
	}

	static toCommand(
		model: UpdateFormValueDto
	): ProfiBazaAPIModels.UpdateFormPartCommand {
		const command: ProfiBazaAPIModels.UpdateFormPartCommand = {
			id: model.id,
			label: model.label,
			pageOrientation: model.pageOrientation,
			isVisibleInViewModel: model.isVisibleInViewModel,
		};
		return command;
	}
}
