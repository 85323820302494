import { ExclamationCircleOutlined, MessageOutlined } from '@ant-design/icons';
import {
	InterventionCommentDto,
	InterventionCommentsCommand,
} from '@components/intervention/comments/commands/InterventionCommentsCommand';
import { InterventionCommentIcon } from '@components/intervention/comments/InterventionCommentIcon';
import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { Row, Space } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { ActivityContext } from 'components/intervention/context/ActivityContext';
import {
	Gender,
	HealthConditions,
	IPopulationProps,
	InterventionLocations,
	OtherSpecialFeatures,
	PopulationsMinMax,
	SocialExclusions,
	TargetPopulations,
	TotalRecipients,
} from 'components/intervention/fields/PopulationFields';
import {
	ISponsoringCompaniesProps,
	SponsoringCompaniesFinancingSource,
	SponsoringCompaniesMainSubject,
	SponsoringCompaniesPlannedCosts,
	SponsoringCompaniesRealizationCosts,
	SponsoringCompaniesSubjects,
} from 'components/intervention/fields/SponsoringCompaniesFields';
import { numberOfPeople } from 'components/intervention/model/InterventionCommand';
import { Field, FormikActions } from 'formik';
import { FFieldLabel } from 'forms/FormikFormItems';
import { strongifyText } from 'helper/jsxEnhancers';
import { CenteredRow } from 'layout/CenteredRow';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import { Moment } from 'moment';
import React, {
	useContext,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import * as SubjectKind from 'services/mappers/SubjectKind';
import {
	ActivityPopulationDto,
	ActivityPopulationValueDto,
	AgeUnit,
	CoProducerValueDto,
	ImplementingCompanyDto,
	InterventionCommentStatus,
	SponsoringCompanyValueDto,
	SubjectListItemDto,
} from 'services/src/models';
import { DictionaryInterventionPath } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import SubjectsTableModal from '../../../shared/SubjectsTableModal';
import { interventionRealizationSubjectColumns } from '../../../subjects/SubjectTableColumns';

export const columnsImplementingCompanies: ColumnProps<
	ImplementingCompanyDto
>[] = [
	{
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Nazwa',
		width: '50%',
		ellipsis: true,
	},
	{
		dataIndex: 'number',
		key: 'number',
		title: 'Liczba realizatorów',
		width: '20%',
	},
	{
		dataIndex: 'subjectKind',
		key: 'subjectKind',
		title: 'Kategoria podmiotu',
		render: (value: ProfiBazaAPIModels.SubjectKind) =>
			SubjectKind.map(value),
		width: '30%',
	},
];

const hasSponsoringCompanyComments = (
	record: SponsoringCompanyValueDto,
	comments: InterventionCommentDto[]
): boolean => {
	if (
		comments.some(
			(x) =>
				x.activitySponsoringCompanyId === record.id &&
				x.status !== InterventionCommentStatus.PROCESSED
		)
	) {
		return true;
	}

	return false;
};

const hasPopulationComments = (
	record: ActivityPopulationDto,
	comments: InterventionCommentDto[]
): boolean => {
	if (
		comments.some(
			(x) =>
				x.activityPopulationId === record.id &&
				x.status !== InterventionCommentStatus.PROCESSED
		)
	) {
		return true;
	}

	return false;
};

const CommentIcon = (
	<ProfiBazaTooltip
		placement="top"
		title="Istnieje komentarz do tego rekordu"
	>
		<MessageOutlined />
	</ProfiBazaTooltip>
);

export const getPopulationsColumns = (
	comments: InterventionCommentsCommand
) => {
	const columnsPopulations: ColumnProps<ActivityPopulationValueDto>[] = [
		{
			dataIndex: 'hasComment',
			key: 'hasComment',
			render: (text: any, record: ActivityPopulationDto) => (
				<>
					{hasPopulationComments(record, comments.comments) ? (
						CommentIcon
					) : (
						<></>
					)}
				</>
			),
		},
		{
			dataIndex: 'genderDictionaryValue',
			key: 'genderDictionaryValue',
			title: 'Płeć',
			render: (text: any) => text,
		},
		{
			dataIndex: 'minAge',
			key: 'minAge',
			title: 'Wiek min.',
		},
		{
			dataIndex: 'maxAge',
			key: 'maxAge',
			title: 'Wiek max.',
		},
		{
			dataIndex: 'ageUnit',
			key: 'ageUnit',
			title: 'Jednostka wieku',
			render: (value: ProfiBazaAPIModels.AgeUnit) => {
				if (value === AgeUnit.Weeks) return 'tygodnie';
				else return 'lata';
			},
		},
		{
			dataIndex: 'totalRecipients',
			key: 'totalRecipients',
			title: 'Liczba odbiorców',
		},
	];

	return columnsPopulations;
};

export const getSponsoringCompaniesColumns = (
	hasPlannedCosts: boolean,
	comments: InterventionCommentsCommand
) => {
	const sponsoringCompaniesColumns: ColumnProps<
		SponsoringCompanyValueDto
	>[] = [
		{
			dataIndex: 'hasComment',
			key: 'hasComment',
			className: 'actionstd',
			title: 'Kolumna akcji',
			render: (text: any, record: SponsoringCompanyValueDto) => (
				<>
					{hasSponsoringCompanyComments(record, comments.comments) ? (
						CommentIcon
					) : (
						<></>
					)}
				</>
			),
		},
		{
			dataIndex: 'subjectName',
			key: 'subjectName',
			title: 'Nazwa',
			width: '45%',
			render: (text: string, record: SponsoringCompanyValueDto) => (
				<>
					{record.isMainSubject ? (
						<>
							<strong>{text + ' '}</strong>
							<ProfiBazaTooltip
								placement="top"
								title="Główny podmiot finansujący"
							>
								<ExclamationCircleOutlined />
							</ProfiBazaTooltip>
						</>
					) : (
						text
					)}
				</>
			),
		},
		{
			dataIndex: 'financingSourceDictionaryValues',
			key: 'financingSourceDictionaryValues',
			title: 'Źródło finansowania',
			render: (text: any, record: SponsoringCompanyValueDto) => {
				const content = (
					<>
						{record.financingSourceDictionaryValues
							?.map((x) =>
								x.financingSourceDictionaryOtherValue
									? x.financingSourceDictionaryValue +
									  ': ' +
									  x.financingSourceDictionaryOtherValue
									: x.financingSourceDictionaryValue
							)
							.join(', ')}
					</>
				);
				return <>{strongifyText(content, record.isMainSubject)}</>;
			},
		},
		{
			dataIndex: 'realizationCosts',
			key: 'realizationCosts',
			title: 'Koszty realizacji',
			render: (text: any, record: SponsoringCompanyValueDto) =>
				strongifyText(text, record.isMainSubject),
		},
	];

	if (hasPlannedCosts) {
		sponsoringCompaniesColumns.push({
			dataIndex: 'plannedCosts',
			key: 'plannedCosts',
			title: 'Koszty planowane',
			render: (text: any, record: SponsoringCompanyValueDto) =>
				strongifyText(text, record.isMainSubject),
		});
	}

	return sponsoringCompaniesColumns;
};

export const columnsCoProducers: ColumnProps<CoProducerValueDto>[] = [
	{
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Nazwa',
		width: '45%',
	},
	{
		dataIndex: 'roles',
		key: 'roles',
		title: 'Rola',
		render: (text: any, record: CoProducerValueDto) => {
			const content = (
				<>
					{record.roles
						?.map((x) =>
							x.roleDictionaryOtherValue
								? x.roleDictionaryValue +
								  ': ' +
								  x.roleDictionaryOtherValue
								: x.roleDictionaryValue
						)
						.join(', ')}
				</>
			);
			return content;
		},
	},
];

interface IPopulationFormProps {
	editable: boolean;
	value: ActivityPopulationValueDto | undefined;
	dateRange:
		| [(Moment | undefined)?, (Moment | undefined)?]
		| Moment[]
		| undefined;
	interventionPath: DictionaryInterventionPath;
	previewMode?: boolean;
}

export const PopulationForm = (props: IPopulationFormProps) => {
	const [visible, setVisiblity] = useState<boolean>(false);
	const activityContext = useContext(ActivityContext);

	const checkIfObjectValue = (object: any, key?: any) => {
		return (
			object != null &&
			object[key] !== undefined &&
			object[key] !== null &&
			(!Array.isArray(object[key]) ||
				(Array.isArray(object[key]) && object[key]?.length > 0))
		);
	};

	const shouldBeDisabled = (
		field: keyof ActivityPopulationValueDto
	): boolean => {

		if(activityContext.isPattern)
			return false;

		const patternValueEquivalent = activityContext.patternActivity?.activityPopulations?.find(
			(p) => p.id === props.value?.activityPopulationPatternId
		);

		if (!patternValueEquivalent || !props.value) 
			return false;

		return checkIfObjectValue(patternValueEquivalent, field);
	};

	let populationProps: IPopulationProps = {
		interventionPath: activityContext.interventionPath,
		visible: visible,
		editable: props.editable,
		dateRange: props.dateRange,
		numberOfPeople: numberOfPeople,
		shouldBeDisabled: shouldBeDisabled,
		previewMode: props.previewMode,
	};

	return (
		<>
			{activityContext.interventionPath ==
				DictionaryInterventionPath.SUPERVISIONMONITORING ||
			activityContext.interventionPath ==
				DictionaryInterventionPath.SUPERVISIONSANITARY ||
			activityContext.interventionPath ==
				DictionaryInterventionPath.STAFFMONITORING
				? [
						<CenteredRow gutter={[16, 24]}>
							{InterventionLocations(populationProps)}
						</CenteredRow>,
				  ]
				: [
						<CenteredRow gutter={[16, 24]}>
							{Gender(populationProps)}
						</CenteredRow>,
						<div>
							<Space>
								<FFieldLabel label="Cecha populacji - wiek" />
								<LabelHelpTooltip
									field="populationAge"
									defaultValue="Cecha populacji - wiek"
								/>
								<InterventionCommentIcon fieldName="populationAge" />
							</Space>
						</div>,
						<CenteredRow gutter={[16, 24]}>
							{PopulationsMinMax(populationProps)}
							{TotalRecipients(populationProps)}
						</CenteredRow>,
						<CenteredRow>
							{TargetPopulations(populationProps)}
						</CenteredRow>,
						<CenteredRow gutter={[16, 24]}>
							{InterventionLocations(populationProps)}
						</CenteredRow>,
						<CenteredRow gutter={[16, 24]}>
							{HealthConditions(populationProps)}
						</CenteredRow>,
						<CenteredRow gutter={[16, 24]}>
							{SocialExclusions(populationProps)}
						</CenteredRow>,
						<CenteredRow gutter={[16, 24]}>
							{OtherSpecialFeatures(populationProps)}
						</CenteredRow>,
				  ]}
		</>
	);
};

interface ImplementersFormProps {
	selectedRows: ImplementingCompanyDto[];
	editable: boolean;
	setList: (list: ImplementingCompanyDto[]) => void;
	setVisible: (value: boolean) => void;
	visible: boolean;
}

export interface IImplementersForm {
	resetForm: () => void;
}

export const ImplementersForm = React.forwardRef<
	IImplementersForm,
	ImplementersFormProps
>((props, ref) => {
	const implementingCompanyDtoToSubjectListItemDto = (
		list: ImplementingCompanyDto[]
	): SubjectListItemDto[] =>
		list
			.filter((x) => x.subjectId)
			.map((x) => ({
				id: x.subjectId,
				name: x.subjectName,
				subjectKind: x.subjectKind as
					| ProfiBazaAPIModels.SubjectKind
					| undefined,
			}));

	const [selectedRows, setSelectedRows] = useState<SubjectListItemDto[]>(
		implementingCompanyDtoToSubjectListItemDto(props.selectedRows)
	);

	useEffect(() => {
		setSelectedRows(
			implementingCompanyDtoToSubjectListItemDto(props.selectedRows)
		);
	}, [props.selectedRows, props.selectedRows?.length]);

	useImperativeHandle(ref, () => ({
		resetForm: () => {
			props.setVisible(false);
			setSelectedRows([]);
		},
	}));

	return (
		<>
			<SubjectsTableModal
				mode="checkbox"
				visible={props.visible}
				selectedRows={selectedRows}
				onCancel={() => {
					props.setVisible(false);
				}}
				columns={interventionRealizationSubjectColumns}
				acceptList={(value) => {
					let list = [
						...value.map((x) => ({
							subjectId: x.id,
							subjectName: x.name,
							subjectKind: x.subjectKind as
								| ProfiBazaAPIModels.RIZPSubjectKind
								| undefined,
						})),
						...props.selectedRows.filter((x) => !x.subjectId),
					];

					props.setList(list);
					setSelectedRows(value.filter((x) => x.id));
					props.setVisible(false);
				}}
				clearSelectionOnSearching={false}
			/>
		</>
	);
});
interface SponsorFormProps {
	editable: boolean;
	setList: (list: SponsoringCompanyValueDto[]) => void;
	readonly: boolean;
	dateRange:
		| [(Moment | undefined)?, (Moment | undefined)?]
		| Moment[]
		| undefined;
	item?: SponsoringCompanyValueDto;
	formikActions?: FormikActions<SponsoringCompanyValueDto>;
	canAddMainSubject: boolean;
	interventionPath: DictionaryInterventionPath;
	selectedRows: SubjectListItemDto[];
	setSelectedRows: (value: SubjectListItemDto[]) => void;
	filteredSelectedRows?: (array: Array<SubjectListItemDto>) => void;
	previewMode?: boolean;
}

export interface ISponsorsForm {
	resetForm: () => void;
}

const hasMultipleValues = (names: string[] | undefined): boolean =>
	!!names && names.length > 1;

export const SponsorsForm = React.forwardRef<ISponsorsForm, SponsorFormProps>(
	(props, ref) => {
		const { selectedRows, setSelectedRows } = props;
		const [names, setNames] = useState<string[]>();
		const [visible, setVisible] = useState<boolean>(false);
		const [canAddMainSubject, setCanAddMainSubject] = useState<boolean>();

		const activityContext = useContext(ActivityContext);

		const { formikActions, filteredSelectedRows } = props;

		useEffect(() => {
			if (hasMultipleValues(names)) {
				formikActions?.setFieldValue('isMainSubject', false);
			}
		}, [names]);

		useEffect(() => {
			setCanAddMainSubject(props.canAddMainSubject);
		}, []);

		useImperativeHandle(ref, () => ({
			resetForm: () => {
				setVisible(false);
				setNames([]);
				setSelectedRows([]);
				props.formikActions?.resetForm();
			},
		}));

		const shouldBeDisabled = (
			field: keyof SponsoringCompanyValueDto
		): boolean => {
			const patternValueEquivalent = activityContext.patternActivity?.sponsoringCompanies?.find(
				(p) => p.subjectId === props.item?.subjectId
			);
			if (!patternValueEquivalent || !props.item?.subjectId) return false;

			return !!patternValueEquivalent[field];
		};

		let sponsoringCompaniesProps: ISponsoringCompaniesProps = {
			interventionPath: activityContext.interventionPath,
			visible: visible,
			editable: props.editable,
			dateRange: props.dateRange,
			numberOfPeople: numberOfPeople,
			shouldBeDisabled: shouldBeDisabled,
			readonly: props.readonly,
			selectedRows: selectedRows,
			setList: props.setList,
			setSelectedRows: setSelectedRows,
			setNames: setNames,
			setVisible: setVisible,
			hasMultipleValues: hasMultipleValues,
			canAddMainSubject: canAddMainSubject!,
			names: names!,
			filteredSelectedRows: filteredSelectedRows!,
			previewMode: props.previewMode,
		};
		return (
			<>
				<Row>
					{activityContext.interventionPath !==
					DictionaryInterventionPath.PROHEALTHACTIVITIES
						? [
								SponsoringCompaniesSubjects(
									sponsoringCompaniesProps
								),
								SponsoringCompaniesFinancingSource(
									sponsoringCompaniesProps
								),
								SponsoringCompaniesRealizationCosts(
									sponsoringCompaniesProps
								),
								SponsoringCompaniesMainSubject(
									sponsoringCompaniesProps
								),
						  ]
						: [
								SponsoringCompaniesSubjects(
									sponsoringCompaniesProps
								),
								SponsoringCompaniesFinancingSource(
									sponsoringCompaniesProps
								),
								SponsoringCompaniesPlannedCosts(
									sponsoringCompaniesProps
								),
								SponsoringCompaniesRealizationCosts(
									sponsoringCompaniesProps
								),
								SponsoringCompaniesMainSubject(
									sponsoringCompaniesProps
								),
						  ]}
				</Row>
			</>
		);
	}
);
