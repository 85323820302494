import * as Yup from 'yup';

const requiredMessage: string = 'Wartość jest wymagana';
const laterThanMessage: string =
	'Data końcowa musi być późniejsza od początkowej';
const file_extensions: string = '';

export const DictionaryVersionValidationSchema = (
	isCreation: boolean,
	isImport: boolean
) =>
	Yup.object().shape({
		validFrom: Yup.date().when('validTo', {
			is: (validTo) => validTo != null,
			then: Yup.date()
				.nullable()
				.required(requiredMessage)
				.test(
					'less than validTo',
					'Data musi być wcześniejsza niż Data Do',
					function (validFrom) {
						return !validFrom || validFrom < this.parent.validTo;
					}
				),
			otherwise: isCreation
				? Yup.date().nullable()
				: Yup.date().nullable().required(requiredMessage),
		}),
		file: Yup.string()
			.test(
				'isFile',
				'Plik jest wymagany',
				(value) => !isImport || !!value
			)
			.test('fileType', 'Złe rozszerzenie załącznika', (value) =>
				!isImport || value?.includes('.xlsx')
			),
	});
