import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { AgeUnit } from '@services/src/models';
import { Col, Radio, Space } from 'antd';
import DictionaryPicker from 'components/dictionary/picker/DictionaryPicker';
import { Field } from 'formik';
import {
	FFieldLabel,
	FInput,
	FInputNumber,
	FRadioGroup,
} from 'forms/FormikFormItems';
import React from 'react';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { InterventionCommentArea } from '../comments/InterventionCommentArea';
import { InterventionCommentIcon } from '../comments/InterventionCommentIcon';

export interface IPopulationProps {
	dateRange?:
		| [(moment.Moment | undefined)?, (moment.Moment | undefined)?]
		| moment.Moment[]
		| undefined;
	editable: boolean;
	shouldBeDisabled?: (
		key: keyof ProfiBazaAPIModels.ActivityPopulationValueDto
	) => boolean;
	interventionPath: ProfiBazaAPIModels.DictionaryInterventionPath;
	numberOfPeople?: number;
	visible?: boolean;
	previewMode?: boolean;
}

export const Gender = (props: IPopulationProps) => (
	<Col xs={24}>
		<DictionaryPicker
			codeName="CECHA_POPULACJI_S1.plec"
			label="Cecha populacji - płeć"
			helpField="genderDictionaryValue"
			commentField="populationGender"
			dateRange={props.dateRange}
			name="genderDictionaryValueId"
			otherName="genderDictionaryValue"
			otherValue="genderDictionaryOtherValue"
			interventionPath={props.interventionPath}
			disabled={
				!props.editable ||
				props.shouldBeDisabled?.('genderDictionaryValueId') ||
				props.previewMode
			}
			readOnly={
				!props.editable ||
				props.shouldBeDisabled?.('genderDictionaryValueId') || props.previewMode
			}
		/>
	</Col>
);

export const TargetPopulations = (props: IPopulationProps) => {
	return (
		<Col xs={24}>
			<DictionaryPicker
				codeName="CECHA_POPULACJI_S3.populacja_docelowa"
				label="Populacja docelowa ze względu na miejsce zamieszkania"
				commentField="populationTarget"
				helpField="targetPopulations"
				dateRange={props.dateRange}
				valueId="id"
				name="targetPopulationDictionaryValueId"
				otherName="targetPopulationDictionaryValue"
				otherValue="targetPopulationDictionaryOtherValue"
				interventionPath={props.interventionPath}
				disabled={
					!props.editable ||
					props.shouldBeDisabled?.(
						'targetPopulationDictionaryValueId'
					) ||
					props.previewMode
				}
				readOnly={
					!props.editable ||
					props.shouldBeDisabled?.('targetPopulationDictionaryValueId') || props.previewMode
				}
			/>
		</Col>
	);
};

export const InterventionLocations = (props: IPopulationProps) => {
	return (
		<Col xs={24}>
			<Col xs={24}>
				<DictionaryPicker
					codeName="CECHA_POPULACJI_S4.miejsce_interwencji"
					label="Cecha populacji - miejsce interwencji"
					commentField="populationLocation"
					helpField="interventionLocations"
					dateRange={props.dateRange}
					name="interventionLocations"
					valueId="id"
					otherValue="otherValue"
					interventionPath={props.interventionPath}
					disabled={
						!props.editable ||
						props.shouldBeDisabled?.('interventionLocations') ||
						props.previewMode
					}
					readOnly={
						!props.editable ||
						props.shouldBeDisabled?.('interventionLocations') || props.previewMode
					}
					multiple
				/>
			</Col>
		</Col>
	);
};

export const HealthConditions = (props: IPopulationProps) => {
	return (
		<Col xs={24}>
			<DictionaryPicker
				label="Cecha populacji - stan zdrowia"
				helpField="healthConditions"
				commentField="populationHealthConditions"
				interventionPath={props.interventionPath}
				multiple
				disabled={
					!props.editable ||
					props.shouldBeDisabled?.('healthConditions') ||
					props.previewMode
				}
				readOnly={
					!props.editable ||
					props.shouldBeDisabled?.('healthConditions') || props.previewMode
				}
				name="healthConditions"
				valueId="id"
				otherValue="otherValue"
				codeName="CECHA_POPULACJI_S5.stan_zdrowia"
				dateRange={props.dateRange}
			/>
		</Col>
	);
};

export const SocialExclusions = (props: IPopulationProps) => {
	return (
		<Col xs={24}>
			<DictionaryPicker
				codeName="CECHA_POPULACJI_S6.wykluczenie_spoleczne"
				helpField="socialExclusions"
				label="Cecha populacji - wykluczenie społeczne/zagrożenie wykluczeniem społecznym"
				commentField="populationSocialExclusions"
				dateRange={props.dateRange}
				name="socialExclusions"
				valueId="id"
				otherValue="otherValue"
				interventionPath={props.interventionPath}
				disabled={
					!props.editable ||
					props.shouldBeDisabled?.('socialExclusions') ||
					props.previewMode
				}
				readOnly={
					!props.editable ||
					props.shouldBeDisabled?.('socialExclusions') || props.previewMode
				}
				multiple
			/>
		</Col>
	);
};

export const OtherSpecialFeatures = (props: IPopulationProps) => {
	return (
		<Col xs={24}>
			<DictionaryPicker
				codeName="CECHA_POPULACJI_S7.inne_szczegolne_cechy"
				label="Cecha populacji - inne szczególne cechy"
				commentField="populationOtherSpecialFeatures"
				helpField="otherSpecialFeatures"
				dateRange={props.dateRange}
				name="otherSpecialFeatures"
				valueId="id"
				otherValue="otherValue"
				interventionPath={props.interventionPath}
				disabled={
					!props.editable ||
					props.shouldBeDisabled?.('otherSpecialFeatures') ||
					props.previewMode
				}
				readOnly={
					!props.editable ||
					props.shouldBeDisabled?.('otherSpecialFeatures') || props.previewMode
				}
				multiple
			/>
		</Col>
	);
};

export const TotalRecipients = (props: IPopulationProps) => {
	return (
		<Col xs={8}>
			<Space>
				<FFieldLabel label="Populacja - liczba odbiorców" />
				<LabelHelpTooltip
					field="totalPopulationRecipients"
					defaultValue="Populacja - liczba odbiorców"
				/>
				<InterventionCommentIcon fieldName="populationNumberOfRecipients" />
			</Space>
			<InterventionCommentArea fieldName="populationNumberOfRecipients">
				<Field
					label="Populacja - liczba odbiorców"
					readOnly={
						!props.editable ||
						props.shouldBeDisabled?.('totalRecipients') ||
						props.previewMode
					}
					style={{ width: '100%' }}
					max={props.numberOfPeople}
					className="remove-steps"
					component={FInput}
					name="totalRecipients"
					precision={0}
				/>
			</InterventionCommentArea>
		</Col>
	);
};
export const PopulationsMinMax = (props: IPopulationProps) => {
	return (
		<>
			<Col xs={6}>
				<Field
					disabled={
						!props.editable ||
						props.shouldBeDisabled?.('ageUnit') ||
						props.previewMode
					}
					style={{ width: '100%' }}
					defaultValue={AgeUnit.Years}
					values={Object.values(AgeUnit)}
					labels={['w latach', 'w tygodniach']}
					component={FRadioGroup}
					name="ageUnit"
				/>
			</Col>
			<Col xs={5}>
				<Space>
					<FFieldLabel label="Wartość min" />
					<InterventionCommentIcon fieldName="populationMin" />
				</Space>
				<InterventionCommentArea fieldName="populationMin">
					<Field
						label="Wartość min"
						readOnly={
							!props.editable ||
							props.shouldBeDisabled?.('minAge') ||
							props.previewMode
						}
						style={{ width: '100%' }}
						component={FInputNumber}
						name="minAge"
						precision={0}
					/>
				</InterventionCommentArea>
			</Col>
			<Col xs={5}>
				<Space>
					<FFieldLabel label="Wartość max" />
					<InterventionCommentIcon fieldName="populationMax" />
				</Space>
				<InterventionCommentArea fieldName="populationMax">
					<Field
						label="Wartość max"
						readOnly={
							!props.editable ||
							props.shouldBeDisabled?.('maxAge') ||
							props.previewMode
						}
						style={{ width: '100%' }}
						component={FInput}
						name="maxAge"
						precision={0}
					/>
				</InterventionCommentArea>
			</Col>
		</>
	);
};
