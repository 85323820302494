import React, { useState, useImperativeHandle } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Checkbox } from 'antd';

interface IProps {
	handleDelete: (id: number, deleteDescendants: boolean) => void;
}

export interface IDeleteValueModal {
	showModal: (valueId: number) => void;
}

const DeleteValueModal = React.forwardRef<IDeleteValueModal, IProps>(
	(props, ref) => {
		const [visible, setVisible] = useState<boolean>(false);
		const [checked, setChecked] = useState<boolean>(false);
		const [id, setId] = useState<number | undefined>(undefined);

		useImperativeHandle(ref, () => ({
			showModal: (valueId: number) => {
				setVisible(true);
				setId(valueId);
			},
		}));

		const handleClose = () => {
			setChecked(false);
			setVisible(false);
			setId(undefined);
		};

		return (
			<Modal
				visible={visible}
				maskClosable={false}
				centered
				destroyOnClose
				title="Czy na pewno chcesz usunąć wskazaną wartość?"
				onOk={() => {
					props.handleDelete(id!, checked);
					handleClose();
				}}
				onCancel={handleClose}
				okText="Usuń"
				cancelText="Anuluj"
			>
				<Checkbox
					checked={checked}
					onChange={(e) => {
						setChecked((st) => !st);
					}}
				>
					Usuń wartości potomne
				</Checkbox>
			</Modal>
		);
	}
);

export default DeleteValueModal;
