import * as Yup from 'yup';

const requiredMessage: string = 'Wartość jest wymagana';

export const EditNotificationValidationSchema = Yup.object().shape({
	content: Yup.string().required(requiredMessage).nullable().max(500),
	systemChannelActive: Yup.bool(),
	emailChannelActive: Yup.bool(),
	triggeredWeekBefore: Yup.bool(),
	triggeredDayBefore: Yup.bool(),
	triggeredExactDay: Yup.bool(),
	sendToAll: Yup.bool(),
});
