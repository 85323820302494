import { useLayoutEffect } from 'react';
import { Modal } from 'antd';

export const useOrbeonAccessibilityHook = (dependency: any) => {
	useLayoutEffect(() => {
		if (dependency) {
			removeNoScript();
		}
		return () => {};
	}, [dependency]);
};

const removeNoScript = () => {
	const iframe = document.querySelector(
		'[title="iframe"]'
	) as HTMLIFrameElement;
	
	const errorTitle: string = 'Wystąpił niespodziewany błąd. Skontaktuj się z administratorem.';
	
	if (iframe && iframe.contentDocument) {

		const contentDocumentBody = iframe.contentDocument.body;

		if(contentDocumentBody == null || contentDocumentBody.innerHTML.length == 0 || iframe.contentDocument.title.includes('Error')){
			Modal.error({
				centered: true,
				title: errorTitle,
			});
		}

		if (contentDocumentBody.children) {
			Array.from(contentDocumentBody.children).forEach((item) => {
				if (item.id === 'xf-1') {
					if (item.children) {
						Array.from(item.children).forEach((x) => {
							if (x.className === 'orbeon-portlet-body') {
								if (x.children) {
									Array.from(x.children).forEach((y) => {
										if (y.nodeName === 'NOSCRIPT') {
											y.remove();
										}
									});
								}
							}
						});
					}
				}
			});
		}else{
			Modal.error({
				centered: true,
				title: errorTitle,
			});
		}
	}else{
		Modal.error({
			centered: true,
			title: errorTitle,
		});
	}
};
