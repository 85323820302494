import Tag from 'antd/lib/tag';
import React from 'react';

export const strongifyText = (
	text: string | JSX.Element,
	condition: boolean = false
) => {
	if (condition) return <strong>{text}</strong>;
	return text;
};

export const tagTextAccordingToStatus = (
	text: string | JSX.Element,
	condition: boolean = false
) => {
	let color = condition? 'green' : 'volcano';
	return (<Tag color={color}>{text}</Tag>);
};