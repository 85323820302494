import { INotificationStore } from '@components/notifications/notificationStore';
import { useRootData } from '@hooks/hook';
import { Button, Checkbox, Space, Switch } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Modal from 'antd/lib/modal/Modal';
import ChannelState from 'components/notifications/types/ChannelState';
import { ajaxByUser } from 'helper/api';
import { produce } from 'immer';
import { showNotExpectedErrorModal } from 'layout/Modals';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import userNotificationSettingsTableColumns from '../../tableColumns/userNotificationSettingsTableColumns';
import {
	activatePromise,
	deactivatePromise,
	updateChannelsPromise,
} from './manageNotificationVisibilityApiActions';

interface IProps {}

interface IState {
	visible: boolean;
	notificationId: string | undefined;
	data: ProfiBazaAPIModels.UserNotificationSettingsDTO[] | undefined;
}

export interface IIManageNotificationVisibilityModal {
	showModal: () => void;
}

const checkChannelManagementAvailability = (
	record: ProfiBazaAPIModels.UserNotificationSettingsDTO
): boolean => record.isActive!;

const ManageNotificationVisibilityModal = React.forwardRef<
	IIManageNotificationVisibilityModal,
	IProps
>((props, ref) => {
	const [state, setState] = useState<IState>({
		visible: false,
		notificationId: undefined,
		data: undefined
	});

	const notificationStore: INotificationStore = useRootData(
		(store) => store.notificationStore
	);

	const [channelState, setChannelState] = useState<Map<string, ChannelState>>(
		new Map<string, ChannelState>()
	);
	const [lastChangedId, setLastChangedId] = useState<string | undefined>(
		undefined
	);

	useImperativeHandle(ref, () => ({
		showModal: () => {
			setState({
				...state,
				visible: true,
				notificationId: undefined,
			});
		},
	}));

	const fetchData = async (signal?: AbortSignal) => {
		notificationStore.initialize((result: any) => {
			const newChannelState = produce(channelState, (draft) => {
				result.forEach(
					(x: ProfiBazaAPIModels.UserNotificationSettingsDTO) =>
						draft.set(x.notificationDefinitionId!, {
							systemChannelActive: x.systemChannelActive ?? false,
							emailChannelActive: x.emailChannelActive ?? false,
							loading: false,
						})
				);
			});
			setChannelState(newChannelState);
		}, signal);
	};

	useEffect(() => {
		refreshData();
	}, [state.visible]);

	const refreshData = (() => {
		const abortController = new AbortController();
		const signal = abortController.signal;

		if (state.visible) {
			fetchData(signal);
		}

		return () => {
			abortController.abort();
		};
	});

	useEffect(() => {
		if (!lastChangedId) return;
		const updatedItem = channelState.get(lastChangedId!);
		if (!updatedItem) return;
		ajaxByUser('Zaktualizowano widoczne kanały', () =>
			getProfiBazaApiClient().then((api) =>
				api.userNotificationSettings.updateChannels({
					body: {
						notificationId: lastChangedId,
						systemChannelActive: updatedItem.systemChannelActive,
						emailChannelActive: updatedItem.emailChannelActive,
					},
				})
			)
		).then(() => {
			if (
				!updatedItem.systemChannelActive &&
				!updatedItem.emailChannelActive
			) {
				refreshData();
			}
			setLastChangedId(undefined);
		});

		return () => {};
	}, [lastChangedId, channelState]);

	const handleCancel = () => {
		setState({
			...state,
			visible: false,
			notificationId: undefined,
		});
	};

	const handleChannelUpdate = (
		newChannelState: Map<string, ChannelState>,
		notificationDefinitionId: string | undefined
	) => {
		setChannelState(newChannelState);
		setLastChangedId(notificationDefinitionId);
	};

	const mapModifableRecordChannelToResult = (
		record: ProfiBazaAPIModels.UserNotificationSettingsDTO,
		result: boolean
	) =>
		produce(record, (draft) => {
			if (draft.definitionEmailChannelActive) {
				draft.emailChannelActive = result;
			} else if (draft.definitionSystemChannelActive) {
				draft.systemChannelActive = result;
			}
		});

	const canModify = (
		record: ProfiBazaAPIModels.UserNotificationSettingsDTO
	) =>
		record.definitionEmailChannelActive! &&
		record.definitionSystemChannelActive!;

	const tableColumns = [
		...userNotificationSettingsTableColumns,
		{
			dataIndex: 'visibility',
			key: 'visibility',
			title: '',
			width: 250,
			render: (
				text: string,
				record: ProfiBazaAPIModels.UserNotificationSettingsDTO
			) => (
				<fieldset>
					<legend className="sr-only">{record.event}</legend>
					{checkChannelManagementAvailability(record) && (
						<Space>
							<Checkbox
								disabled={!record.definitionSystemChannelActive}
								checked={
									record.definitionSystemChannelActive &&
									channelState.get(
										record.notificationDefinitionId!
									)?.systemChannelActive
								}
								onChange={(e: CheckboxChangeEvent) => {
									const newChannelState = produce(
										channelState,
										(draft) => {
											const previous = draft.get(
												record.notificationDefinitionId!
											);
											if (previous) {
												previous.systemChannelActive =
													e.target.checked;
												previous.loading = true;
											}
										}
									);
									handleChannelUpdate(
										newChannelState,
										record.notificationDefinitionId
									);
								}}
							>
								System
							</Checkbox>
							<Checkbox
								disabled={!record.definitionEmailChannelActive}
								checked={
									record.definitionEmailChannelActive &&
									channelState.get(
										record.notificationDefinitionId!
									)?.emailChannelActive
								}
								onChange={(e: CheckboxChangeEvent) => {
									const newChannelState = produce(
										channelState,
										(draft) => {
											const previous = draft.get(
												record.notificationDefinitionId!
											);
											if (previous) {
												previous.emailChannelActive =
													e.target.checked;
												previous.loading = true;
											}
										}
									);
									handleChannelUpdate(
										newChannelState,
										record.notificationDefinitionId
									);
								}}
							>
								Email
							</Checkbox>
						</Space>
					)}
				</fieldset>
			),
		},
		{
			dataIndex: 'actions',
			key: 'actions',
			className: 'actionstd',
			title: 'Kolumna akcji',
			render: (
				text: string,
				record: ProfiBazaAPIModels.UserNotificationSettingsDTO
			) => (
				<Space>
					<Switch
						aria-label="Przełącznik widoczności"
						checked={record.isActive}
						onChange={(checked: boolean) => {
							if (checked) {
								let resolver: Promise<any>;
								if (canModify(record)) {
									resolver = activatePromise(record);
								} else {
									const modifiedRecord = mapModifableRecordChannelToResult(
										record,
										true
									);

									resolver = activatePromise(
										modifiedRecord
									).then(() =>
										updateChannelsPromise(modifiedRecord)
									);
								}

								ajaxByUser('Aktywowano powiadomienie', () =>
									resolver.then(async () => {
										await fetchData();
									})
								);
							} else {
								let resolver: Promise<any>;
								if (canModify(record)) {
									resolver = deactivatePromise(record);
								} else {
									const modifiedRecord = mapModifableRecordChannelToResult(
										record,
										false
									);
									resolver = updateChannelsPromise(
										modifiedRecord
									).then(() =>
										deactivatePromise(modifiedRecord)
									);
								}

								ajaxByUser('Dezaktywowano powiadomienie', () =>
									resolver.then(async () => {
										await fetchData();
									})
								);
							}
						}}
					/>
					{record.isActive ? 'Włączone' : 'Wyłączone'}
				</Space>
			),
		},
	];

	return (
		<Modal
			title="Ustawienia powiadomień"
			width="768"
			closable
			visible={state.visible}
			maskClosable
			centered
			destroyOnClose
			onOk={() => {}}
			onCancel={handleCancel}
			footer={<Button title="Zamknij" onClick={handleCancel} />}
		>
			<ProfiBazaTable
				loading={notificationStore.loading.get()}
				rowKey={(r: ProfiBazaAPIModels.UserNotificationSettingsDTO) =>
					r.notificationDefinitionId
				}
				columns={tableColumns}
				pagination={false}
				dataSource={[...notificationStore.data]}
			/>
		</Modal>
	);
});

export default observer(ManageNotificationVisibilityModal);
