import { useRootData } from '@hooks/hook';
import {
	SubjectCommentListItemDto,
	SubjectCommentType,
} from '@services/src/models';
import React, { useState } from 'react';

import { ISubjectCommentStore } from './SubjectCommentStore';

interface ICommentAreaProps {
	fieldName: string;
	children: React.ReactElement;
}

export const SubjectCommentArea = (props: ICommentAreaProps) => {
	const subjectCommentStore: ISubjectCommentStore = useRootData(
		(store) => store.subjectCommentStore
	);

	const [state, setState] = useState<
		Record<string, SubjectCommentListItemDto>
	>(subjectCommentStore.getRecords());

	subjectCommentStore.commentRecords.observe(() => {
		setState(subjectCommentStore.getRecords());
	});

	const getAreaClassByCommentType = () => {
		if (state) {
			const comment: SubjectCommentListItemDto | undefined = state![
				props.fieldName
			];

			if (comment?.description) {
				if (comment.commentType === SubjectCommentType.IMPORTANT) {
					return 'comment-area important';
				}

				return 'comment-area info';
			}
		}
		return '';
	};

	return <div className={getAreaClassByCommentType()}>{props.children}</div>;
};
