import Authorized from '@authorization/authorized';
import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import { Form } from 'antd';
import { Col } from 'antd';
import AddButton from 'components/shared/buttons/AddButton';
import SearchBar from 'components/shared/paginatedProfiBazaTable/SearchBar';
import { CenteredRow } from 'layout/CenteredRow';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import SubjectsTable from './SubjectsTable';

interface IProps {}

const SubjectRegistry: React.FC<RouteComponentProps<IProps>> = (props) => {
	return (
		<>
			<CenteredRow gutter={[16, 24]} style={{ marginTop: 32 }}>
				<Col span={5}>
					<Authorized
						permissions={[
							ProfiBazaAPIModels.Permission
								.SubjectsModificationsAll,
							ProfiBazaAPIModels.Permission
								.SubjectsModificationsMine,
						]}
					>
						<AddButton
							tall
							onClick={() => props.history.push('/subject/add')}
						>
							Utwórz
						</AddButton>
					</Authorized>
				</Col>
				<Col span={13}>
					<Form size="large">
						<SearchBar
							placeholder="Wyszukaj podmiot"
							column="(name|codeName)"
							filter="contains"
							gridName="subject"
						/>
					</Form>
				</Col>
				<Col span={5}></Col>
			</CenteredRow>
			<CenteredRow>
				<Col span={23}>
					<SkipLinkContent />
					<SubjectsTable {...props} />
				</Col>
			</CenteredRow>
		</>
	);
};

export default SubjectRegistry;
