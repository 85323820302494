import { ActivityTab } from '@components/intervention/comments/store/InterventionCommentStore';

export const mappings = new Map<ActivityTab, string>([
	[ActivityTab.Basic, 'Dane podstawowe'],
	[ActivityTab.Population, 'Populacja'],
	[ActivityTab.ImplementingCompanies, 'Podmioty realizujące'],
	[ActivityTab.SponsoringCompanies, 'Podmioty finansujące'],
	[ActivityTab.HealthProblem, 'Problem zdrowotny'],
	[ActivityTab.IKP, 'Internetowe konto pacjenta'],
	[ActivityTab.AdditionalData, 'Dane dodatkowe'],
]);

export const map = (value: ActivityTab): string => mappings.get(value) ?? '';
