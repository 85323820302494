import { Col, Row, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useEffect } from 'react';
import { proposalAccesType } from 'services/src/models/parameters';

import { ProfiBazaColumnProps } from '../GridHelper';
import GridClearFilters from './GridClearFilters';
import { GridFilterDateRange } from './GridFilterDateRange';
import { GridFilterDictionarySelect } from './GridFilterDictionarySelect';
import GridFilterInput from './GridFilterInput';

interface IProps<T> {
	columns: ProfiBazaColumnProps<T>[];
	filterProps: FilterDropdownProps;
	column: string;
	setClearAll?: () => void;
	clearAll?: boolean;
	defaultValue?: any;
	gridName?: string;
}

export interface IClearFilters {
	clearAll: () => void;
}

export const ProfiBazaInputFilter = <T extends any>(props: IProps<T>) => {
	const { filterProps, gridName } = props;

	return (
		<React.Fragment>
			<div style={{ padding: 8 }} className="filter-dropdown">
				<GridFilterInput
					column={
						props.columns.find(
							(x: ProfiBazaColumnProps<T>) =>
								x.key == props.column
						)!
					}
					filterProps={filterProps}
					handleSearch={(value) => {
						if (value) {
							filterProps.setSelectedKeys([value!]);
							filterProps.confirm();
						} else {
							filterProps.clearFilters?.();
						}
					}}
					gridName={gridName ?? props.column}
					clearInput={props.clearAll}
					setClearInput={props.setClearAll}
					defaultValue={props.defaultValue}
				/>
			</div>
		</React.Fragment>
	);
};

export const ProfiBazaDictionaryFilter = <T extends any>(props: IProps<T>) => {
	const { filterProps, gridName } = props;

	return (
		<div style={{ padding: 8 }} className="filter-dropdown">
			<GridFilterDictionarySelect
				column={
					props.columns.find(
						(x: ProfiBazaColumnProps<T>) => x.key == props.column
					)!
				}
				filterProps={filterProps}
				handleSearch={(value) => {
					if (value) {
						filterProps.setSelectedKeys(value);
						filterProps.confirm();
					} else {
						filterProps.clearFilters?.();
					}
				}}
				gridName={gridName ?? props.column}
				clearInput={props.clearAll}
				setClearInput={props.setClearAll}
				defaultValue={props.defaultValue}
			/>
		</div>
	);
};
export const ProfiBazaDateRangeFilter = <T extends any>(props: IProps<T>) => {
	const { filterProps, gridName } = props;

	return (
		<GridFilterDateRange
			column={
				props.columns.find(
					(x: ProfiBazaColumnProps<T>) => x.key == props.column
				)!
			}
			filterProps={filterProps}
			handleSearch={(value) => {
				if (value) {
					filterProps.setSelectedKeys(
						value.map((x) => x.toLocaleString())
					);
					filterProps.confirm();
				} else {
					filterProps.clearFilters?.();
				}
			}}
			gridName={gridName ?? props.column}
			clearInput={props.clearAll}
			setClearInput={props.setClearAll}
			defaultValue={props.defaultValue}
		/>
	);
};

interface IProfiBazaClearFilters {
	handleSearch: () => void;
}

export const ProfiBazaClearFilters = (props: IProfiBazaClearFilters) => {
	return (
		<Row justify="end" gutter={[0, 8]}>
			<Col>
				<Space className="actions-container">
					<GridClearFilters
						gridName={''}
						handleSearch={() => {
							props.handleSearch();
						}}
					/>
				</Space>
			</Col>
		</Row>
	);
};
