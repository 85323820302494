import React, { useState, useEffect } from 'react';
import Authorized from '@authorization/authorized';
import AddButton from '../../../shared/buttons/AddButton';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import Modal from 'antd/lib/modal/Modal';
import { Formik, FormikProps, FormikActions } from 'formik';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import { observer } from 'mobx-react';
import { IDictionaryStore } from '../../dictionaryStore';
import { useRootData } from '@hooks/hook';
import { DictionaryName } from '@services/mappers/DictionaryName';
import { DictionaryVersionDto } from 'components/dictionary/model/CreateDictionaryVersionCommand';
import { DictionaryVersionValidationSchema } from './DictionaryVersionValidationSchema';
import { versionsForm } from './VersionsForm';
import { UpdateDictionaryVersionDto } from 'components/dictionary/model/UpdateDictionaryVersionCommand';
import { openValidationErrorNotification } from 'help/NotificationHelper';
import ImportButton from 'components/shared/buttons/ImportButton';
import { Space } from 'antd';

interface IProps {
	dictionaryId: number;
	refresh: () => void;

	visible: boolean;
	setVisible: (visible: boolean) => void;

	versionId?: number;
	setVersionId: (id: number | undefined) => void;
}

const DictionaryVersionModal: React.FC<IProps> = (props) => {
	const {
		dictionaryId,
		visible,
		setVisible,
		versionId,
		setVersionId,
	} = props;

	const [modelState, setModelState] = useState<UpdateDictionaryVersionDto>();

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);
	const dictionaryInterventionTypes = dictionaryStore!.getBuildInDictionary(
		DictionaryName.DictionaryInterventionPath
	).values;

	const [isImport, setIsImport] = useState<boolean>(false);
	const [file, setFile] = useState<File | undefined>(undefined);

	const isCreation = versionId === undefined;

	useEffect(() => {
		if (versionId !== undefined) {
			ajaxCatch(() =>
				getProfiBazaApiClient().then((api) => {
					return api.dictionaryVersion
						.getById(versionId)
						.then(
							(
								result: ProfiBazaAPIModels.DictionaryVersionDto
							) => {
								setModelState(
									new UpdateDictionaryVersionDto(result)
								);
							}
						);
				})
			);
		}
	}, [versionId]);

	function handleCancel<T>(props: FormikProps<T>) {
		setIsImport(false);
		setFile(undefined);
		setVisible(false);
		setVersionId(undefined);
		props.resetForm();
	}

	function handleRender<T>(props: FormikProps<T>) {
		return (
			<Modal
				title={`${
					!isCreation
						? 'Zaktualizuj'
						: isImport
						? 'Importuj'
						: 'Utwórz'
				} wersję słownika`}
				visible={visible}
				onOk={() => {
					if (!props.isSubmitting) {
						props.submitForm();
					}
				}}
				cancelText="Anuluj"
				centered
				okButtonProps={{ disabled: props.isSubmitting }}
				cancelButtonProps={{ disabled: props.isSubmitting }}
				destroyOnClose
				onCancel={() => handleCancel(props)}
				okText={
					!isCreation
						? 'Zaktualizuj'
						: isImport
						? 'Importuj'
						: 'Utwórz'
				}
			>
				{versionsForm(isCreation, isImport, setFile)}
			</Modal>
		);
	}

	const handleCreate = (
		values: DictionaryVersionDto,
		actions: FormikActions<
			ProfiBazaAPIModels.CreateDictionaryVersionCommand
		>
	) => {
		const command = DictionaryVersionDto.toCommand(values);
		isImport
			? ajaxByUser(
					'Zaimportowano wersję słownika',
					() =>
						getProfiBazaApiClient().then((api) =>
							api.dictionaryVersion.importMethod({
								file: file,
								isCodeName: values.isCodeName,
								dictionaryId: props.dictionaryId,
							})
						),
					() => {
						setIsImport(false);
						props.refresh();
						setVisible(false);
						actions.resetForm();
					},
					(errors) => {
						let importErrors: any = {};
						if (errors.file) {
							Object.keys(errors).forEach((key: string) => {
								if (Array.isArray(errors[key])) {
									importErrors[key] = errors[key]
										.filter((x: string, i: number) => i < 5)
										.map((x: string, i: number) =>
											i < 4 ? (
												<p>{x}</p>
											) : (
												<p>{x + '...'}</p>
											)
										);
								} else {
									importErrors[key] = <p>{errors[key]}</p>;
								}
							});
						}
						actions.setErrors(importErrors);

						openValidationErrorNotification();
					}
			  ).then(() => actions.setSubmitting(false))
			: ajaxByUser(
					'Utworzono wersję słownika',
					() =>
						getProfiBazaApiClient().then((api) =>
							api.dictionaryVersion.create({
								body: command,
							})
						),
					() => {
						setIsImport(false);
						props.refresh();
						setVisible(false);
						actions.resetForm();
					},
					(errors) => {
						actions.setErrors(errors);
						openValidationErrorNotification();
					}
			  ).then(() => actions.setSubmitting(false));
	};

	const handleUpdate = (
		values: UpdateDictionaryVersionDto,
		actions: FormikActions<UpdateDictionaryVersionDto>
	) => {
		const command = UpdateDictionaryVersionDto.toCommand(values);
		ajaxByUser(
			'Zaktualizowano wersję słownika',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.dictionaryVersion.update({
						body: command,
					})
				),
			() => {
				props.refresh();
				setVersionId(undefined);
				setVisible(false);
				setModelState(undefined);
				actions.resetForm();
			},
			(errors) => {
				actions.setErrors(errors);
			}
		).then(() => actions.setSubmitting(false));
	};

	return (
		<>
			<Authorized
				permission={
					ProfiBazaAPIModels.Permission
						.DictionariesVersionsModifications
				}
			>
				<Space>
					<AddButton tall onClick={() => setVisible(true)}>
						Dodaj wersję
					</AddButton>
					<ImportButton
						tall
						onClick={() => {
							setVisible(true);
							setIsImport(true);
						}}
					>
						Importuj wersję
					</ImportButton>
				</Space>

				{isCreation ? (
					<Formik
						validateOnChange
						validateOnBlur
						enableReinitialize
						isInitialValid={false}
						initialValues={{
							dictionaryId,
							validFrom: undefined,
							validTo: undefined,
							version: undefined,
							isCodeName: undefined,
						}}
						validationSchema={DictionaryVersionValidationSchema(
							isCreation,
							isImport
						)}
						onSubmit={handleCreate}
						render={handleRender}
					/>
				) : (
					<Formik
						validateOnChange
						validateOnBlur
						enableReinitialize
						initialValues={modelState || {}}
						validationSchema={DictionaryVersionValidationSchema(
							isCreation,
							isImport
						)}
						onSubmit={handleUpdate}
						render={handleRender}
					/>
				)}
			</Authorized>
		</>
	);
};

export default observer(DictionaryVersionModal);
