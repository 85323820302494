import { IRODOManualModal } from '@components/userManual/modals/RODOManualModal';
import { RODOManualType, UserManualType } from '@services/src/models';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { useEffect } from 'react';

export const useRODO = (
	accountStore: IAccountDetailsStore,
	modalRef: React.RefObject<IRODOManualModal>
): void => {
	useEffect(() => {
		const account = accountStore.account.get();

		if (account && !account.acceptedRODO && account.existsInProfiBaza) {
			modalRef.current?.showModal({
				manualType: UserManualType.RODO,
				rodoManualType: RODOManualType.CRUIP,
			});
		}
	}, [accountStore.account.get()]);
};
