import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { Col, Space } from 'antd';
import DictionaryPicker from 'components/dictionary/picker/DictionaryPicker';
import SubjectsTableModal from 'components/shared/SubjectsTableModal';
import { Field } from 'formik';
import {
	FCheckbox,
	FFieldLabel,
	FInputNumber,
	FSelectSubjects,
} from 'forms/FormikFormItems';
import React from 'react';
import {
	ImplementingCompanyDto,
	SubjectListItemDto,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { interventionRealizationSubjectColumns } from '../../subjects/SubjectTableColumns';
import { InterventionCommentArea } from '../comments/InterventionCommentArea';
import { InterventionCommentIcon } from '../comments/InterventionCommentIcon';

export interface ISponsoringCompaniesProps {
	dateRange:
		| [(moment.Moment | undefined)?, (moment.Moment | undefined)?]
		| moment.Moment[]
		| undefined;
	editable: boolean;
	shouldBeDisabled: (
		key: keyof ProfiBazaAPIModels.SponsoringCompanyValueDto
	) => boolean;
	interventionPath: ProfiBazaAPIModels.DictionaryInterventionPath;
	numberOfPeople?: number;
	visible: boolean;
	readonly: boolean;
	names: string[];
	setNames: (value: string[]) => void;
	setVisible: (value: boolean) => void;
	selectedRows: SubjectListItemDto[];
	setList: (list: ImplementingCompanyDto[]) => void;
	hasMultipleValues: (names: string[] | undefined) => boolean;
	canAddMainSubject: boolean;
	setSelectedRows: (value: SubjectListItemDto[]) => void;
	filteredSelectedRows: (value: SubjectListItemDto[]) => void;
	previewMode?: boolean;
}

export const SponsoringCompaniesSubjects = (
	props: ISponsoringCompaniesProps
) => {
	return (
		<>
			<Col xs={24}>
				<Space>
					<FFieldLabel label="Podmiot" />
					<LabelHelpTooltip
						field="sponsoringCompanySubject"
						defaultValue="Podmiot"
					/>
					<InterventionCommentIcon fieldName="sponsoringCompanySubject" />
				</Space>

				<InterventionCommentArea fieldName="sponsoringCompanySubject">
					<Field
						label="Podmiot"
						mode="multiple"
						component={FSelectSubjects}
						name="sponsorCompaniesId"
						onClick={() => {
							!props.readonly && !props.shouldBeDisabled('subjectId') && props.setVisible(true);
						}}
						array={props.names}
						editable={
							props.editable && !props.shouldBeDisabled('subjectId')
						}
						readOnly={
							props.shouldBeDisabled('subjectId') || props.previewMode
						}
						onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
							if (e.key === 'Enter' && !props.readonly) {
								props.setVisible(true);
							}
						}}
						dropDown={true}
						othername="subjectName"
						allowClear={false}
					/>
				</InterventionCommentArea>
			</Col>

			<SubjectsTableModal
				visible={props.visible!}
				mode="radio"
				selectedRows={props.selectedRows}
				columns={interventionRealizationSubjectColumns}
				onCancel={() => {
					props.setVisible(false);
				}}
				filteredSelectedRows={(array) => {
					props.filteredSelectedRows(array);
					props.setVisible(false);
				}}
				selectedRowColumn={(record: any) => record.subjectId}
				acceptList={(value) => {
					let lists = value.map((x) => {
						return {
							subjectId: x.id,
							subjectName: x.name,
						};
					});

					let nameList = lists.map((x) => x.subjectName!);
					props.setNames(nameList);
					props.setList(lists);
					props.setVisible(false);
				}}
				clearSelectionOnSearching={false}
			/>
		</>
	);
};

export const SponsoringCompaniesFinancingSource = (
	props: ISponsoringCompaniesProps
) => {
	return (
		<Col span={24}>
			<DictionaryPicker
				codeName="ZRODLO_FINANSOWANIA"
				label="Źródło finansowania"
				helpField="sponsoringCompanyFinancingSource"
				commentField="sponsoringCompanySource"
				dateRange={props.dateRange}
				name="financingSourceDictionaryValues"
				valueId="financingSourceDictionaryValueId"
				otherName="financingSourceDictionaryValue"
				otherValue="financingSourceDictionaryOtherValue"
				interventionPath={props.interventionPath}
				disabled={
					props.shouldBeDisabled('financingSourceDictionaryValues') ||
					props.previewMode
				}
				readOnly={
					props.shouldBeDisabled('financingSourceDictionaryValues') ||
					props.previewMode
				}
				inline
				multiple={true}
			/>
		</Col>
	);
};

export const SponsoringCompaniesPlannedCosts = (
	props: ISponsoringCompaniesProps
) => {
	return (
		<Col xs={24} md={24}>
			<Space>
				<FFieldLabel label="Koszty planowane" />
				<InterventionCommentIcon fieldName="sponsoringCompanyPlannedCosts" />
			</Space>

			<LabelHelpTooltip
				field="sponsoringCompanyPlannedCosts"
				defaultValue="Koszty planowane"
			/>
			<InterventionCommentArea fieldName="sponsoringCompanyPlannedCosts">
				<Field
					label="Koszty planowane"
					component={FInputNumber}
					name="plannedCosts"
					style={{ width: '100%' }}
					readOnly={
						props.shouldBeDisabled('plannedCosts') ||
						props.previewMode
					}
				/>
			</InterventionCommentArea>
		</Col>
	);
};

export const SponsoringCompaniesRealizationCosts = (
	props: ISponsoringCompaniesProps
) => {
	return (
		<Col xs={24} md={24}>
			<Space>
				<FFieldLabel label="Koszty realizacji" />

				<LabelHelpTooltip
					field="sponsoringCompanyRealizationCosts"
					defaultValue="Koszty realizacji"
				/>
				<InterventionCommentIcon fieldName="sponsoringCompanyRealizationCosts" />
			</Space>
			<InterventionCommentArea fieldName="sponsoringCompanyRealizationCosts">
				<Field
					label="Koszty realizacji"
					component={FInputNumber}
					name="realizationCosts"
					style={{ width: '100%' }}
					readOnly={
						props.shouldBeDisabled('realizationCosts') ||
						props.previewMode
					}
				/>
			</InterventionCommentArea>
		</Col>
	);
};

export const SponsoringCompaniesMainSubject = (
	props: ISponsoringCompaniesProps
) => {
	return (
		<Col span={12}>
			<Space>
				<Field
					label="Główny podmiot finansujący"
					component={FCheckbox}
					name="isMainSubject"
					id="isMainSubject"
					disabled={
						props.hasMultipleValues(props.names) ||
						!props.canAddMainSubject ||
						props.previewMode
					}
				/>
				<InterventionCommentIcon fieldName="sponsoringCompanyIsMainSubject" />

				<InterventionCommentArea fieldName="sponsoringCompanyIsMainSubject">
					<FFieldLabel
						label="Główny podmiot finansujący"
						for="isMainSubject"
					/>
				</InterventionCommentArea>
			</Space>
		</Col>
	);
};
