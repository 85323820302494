import { Col, Row } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';

interface IPrimaryViewProps {
	content: ReactNode;
	title?: string;
}

const PrimaryView = (props: IPrimaryViewProps) => {
	useEffect(() => {

		const title = props.title ? `ProfiBaza - ${props.title}` : 'ProfiBaza';

		document.title = title;
		setTitle(title);
	}, [props.title]);

	const [title, setTitle] = useState<string>(document.title);

	return (
		<Row>
			<h1 className="sr-only">{title}</h1>
			<Col xs={24}>{props.content}</Col>
		</Row>
	);
};

export default PrimaryView;
