import { WarningTwoTone } from '@ant-design/icons';
import { InterventionCommentArea } from '@components/intervention/comments/InterventionCommentArea';
import { InterventionCommentIcon } from '@components/intervention/comments/InterventionCommentIcon';
import { InterventionPathContextType } from '@components/intervention/context/InterventionPathContext';
import { CoProducerRoleDto, CoProducerValueDto } from '@services/src/models';
import { Col, Space } from 'antd';
import { Field } from 'formik';
import { FFieldLabel } from 'forms/FormikFormItems';
import { FSubjectsPicker } from 'forms/FSubjectPicker';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';

import { getDateRange } from '../InterventionFields';
import InterventionCoProducersModal from './InterventionCoProducersModal';

export const InterventionCoProducers = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	const [producersModalVisible, setProducersModalVisible] = useState<boolean>(
		false
	);

	const [
		isChangedDictionaryVersion,
		setIsChangedDictionaryVersion,
	] = useState<boolean>(false);

	const [coProducersCopy, setCoProducersCopy] = useState<
		CoProducerValueDto[]
	>(_.cloneDeep(context.formik?.values.coProducers!));

	const getRolesLabel = (roles: CoProducerRoleDto[] | undefined): string => {
		return `(${roles?.map((x: any) => {
			const otherValue = x.roleDictionaryOtherValue
				? `:${x.roleDictionaryOtherValue}`
				: '';
			return `${x.roleDictionaryValue}${otherValue}`;
		})})`;
	};

	return (
		<Fragment>
			<Col md={colSpan}>
				<Space>
					<FFieldLabel label="Współrealizatorzy" />
					<InterventionCommentIcon fieldName="coProducers" />
				</Space>
				{isChangedDictionaryVersion && (
					<ProfiBazaTooltip
						placement="top"
						title="Zmieniona wersja słownikowa"
					>
						<WarningTwoTone twoToneColor="#ff0000" />
					</ProfiBazaTooltip>
				)}

				<InterventionCommentArea fieldName="coProducers">
					<Field
						label="Współrealizatorzy"
						mode="multiple"
						component={FSubjectsPicker}
						interventionPath={context.data?.interventionPath}
						context={context}
						dateRange={getDateRange(context)}
						name="coProducers"
						dictionaryVersionChanged={() => {
							setIsChangedDictionaryVersion(true);
						}}
						onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
							if (context.editable) {
								setProducersModalVisible(true);
							}
						}}
						onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
							if (e.key === 'Enter' && context.editable) {
								setProducersModalVisible(true);
							}
						}}
						onClose={(val: string) => {
							context.formik?.setFieldValue(
								'coProducers',
								coProducersCopy
							);
						}}
						renderTagLabel={(x: CoProducerValueDto) =>
							`${x.subjectName} ${getRolesLabel(x.roles)}`
						}
						dropDown={false}
						array={context.formik?.values.coProducers}
						editable={context.editable}
						readOnly={!context.editable}
						changeData={(value: any) =>
							context.checkChanges?.(value, 'coProducers')
						}
					/>
				</InterventionCommentArea>
			</Col>

			<InterventionCoProducersModal
				visible={producersModalVisible}
				context={context}
				onConfirm={() => {
					setProducersModalVisible(false);
				}}
				onCancel={() => {
					setProducersModalVisible(false);
				}}
			/>
		</Fragment>
	);
};
