import React, { useState } from 'react';
import { Col } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import AddButton from 'components/shared/buttons/AddButton';
import GridRow from 'components/shared/GridRow';
import { IGridStore } from 'stores/GridStore';
import { useRootData } from 'hooks/hook';
import { AddStatementTestModal } from './AddStatementTestModal';
import StatementsTestTable from './StatementsTestTable';

interface IProps {}

const StatementTestRegistry: React.FC<IProps> = (props) => {
	const [visible, setVisible] = useState<boolean>(false);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	return (
		<>
			<GridRow
				left={
						<AddButton tall onClick={() => setVisible(true)}>
							Nowe sprawozdanie
						</AddButton>
				}
			>
				<AddStatementTestModal
					visible={visible}
					onCreate={(values) => {
						setVisible(false);
						gridStore.searching.set(true);
					}}
					onCancel={() => {
						setVisible(false);
                    }}
                    setVisible={(visible) =>
                        setVisible(visible)
                    }
				/>
			</GridRow>
			<CenteredRow>
				<Col span={23}>
					<StatementsTestTable {...props} />
				</Col>
			</CenteredRow>
		</>
	);
};

export default StatementTestRegistry;
