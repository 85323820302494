import React from 'react';
import { Input, Button } from 'antd';
import { MonitorOutlined } from '@ant-design/icons';
import { IGridStore } from '../../../../stores/GridStore';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { useRootData } from 'hooks/hook';

interface IProps {
	handleSearch: () => void;
	gridName: string;
}

const GridClearFilters: React.FunctionComponent<IProps> = (props) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	let searchInput: Input | null = null;

	function select() {
		searchInput && searchInput.select();
	}

	function clear() {
		gridStore.resetGrid(props.gridName);
		gridStore.clearSearch.set(Date.now());
		props.handleSearch();
	}

	return (
		<ProfiBazaTooltip
			title="Zresetuj filtry/sortowanie"
			placement="topRight"
		>
			<Button
				aria-label="Zresetuj filtry/sortowanie"
				shape="circle"
				size="small"
				type="ghost"
				icon={<MonitorOutlined />}
				onClick={() => clear()}
			/>
		</ProfiBazaTooltip>
	);
};

export default GridClearFilters;
