import { ajaxCatch } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import {
	BusinessObjectType,
	DictionaryValueItemDto,
} from '@services/src/models';
import _ from 'lodash';
import { observable } from 'mobx';

const fetch = (objectId: string, objectType: BusinessObjectType) => {
	return ajaxCatch(
		() =>
			getProfiBazaApiClient().then((api) =>
				api.dictionaryValue.getValuesByObject({
					objectId: objectId,
					objectType: objectType,
				})
			),
		(result: DictionaryValueItemDto[]) => {
			const values = rizpDictionaryStore.values.get();
			values[objectId!] = result;
			rizpDictionaryStore.lastFetchObjectId.set(objectId);
		}
	);
};

const getByCodeName = (codeName: string) => {
	const values = rizpDictionaryStore.values.get();

	const objectId = rizpDictionaryStore.lastFetchObjectId.get();

	if (objectId) {
		const objectValues = values[objectId];
		return objectValues?.filter((x) => x.codeName === _.deburr(codeName));
	}

	return undefined;
};

export const rizpDictionaryStore = {
	values: observable.box<
		Record<string, DictionaryValueItemDto[] | undefined>
	>({}),
	lastFetchObjectId: observable.box<string | undefined>(),
	getByCodeName: getByCodeName,
	fetch: fetch,
	valueChangedInActivity: observable.box<boolean>(),
};

export const createRizpDictionaryStore = () => {
	return rizpDictionaryStore;
};

export type IRizpDictionaryStore = ReturnType<typeof createRizpDictionaryStore>;
