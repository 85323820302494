import { SubjectCommentListItemDtoPagedResult } from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import formatHelpers, { DateFormat } from 'helper/formatHelpers';
import moment, { Moment } from 'moment';

export class SubjectCommentsCommand {
	comments: SubjectCommentDto[];
	more: boolean;
	pagination: PaginationSubjectCommentsCommand;
	constructor(
		comment: ProfiBazaAPIModels.SubjectCommentListItemDto[],
		pagination: PaginationSubjectCommentsCommand
	) {
		this.comments = comment.map(
			(x, index) => new SubjectCommentDto(x, index)
		)!;
		this.pagination = pagination;
		this.more = true;
	}
}

export class AuthorCommand {
	id: string;
	name: string;
	subject: string;
	constructor(id: string, name: string, subject: string) {
		this.id = id;
		this.name = name;
		this.subject = subject;
	}
}

export class SubjectCommentDto {
	author: AuthorCommand;
	body: string;
	top: number;
	id?: string;
	status: ProfiBazaAPIModels.SubjectCommentStatus | undefined;
	createdAt?: Moment | undefined;
	updatedAt?: Moment | undefined;
	createdAtFormatted?: string | null;
	updatedAtFormatted?: string | null;
	type: ProfiBazaAPIModels.SubjectCommentType | undefined;
	fieldName?: string;
	constructor(
		comment: ProfiBazaAPIModels.SubjectCommentListItemDto,
		index: number
	) {
		this.author = new AuthorCommand(
			comment.createdBySubjectId!,
			`${comment.createdBy}`,
			comment.createdBySubject!
		);
		this.body = comment.description!;
		this.fieldName = comment.fieldName;
		this.top = index;
		this.id = comment.id;
		this.type = comment.commentType;
		this.status = comment.status;
		this.createdAt = comment?.createdAt
			? moment(comment?.createdAt)
			: undefined;
		this.updatedAt = comment?.updatedAt
			? moment(comment.updatedAt)
			: undefined;
		this.createdAtFormatted = formatHelpers.formatDate(
			comment?.createdAt,
			DateFormat.DateTime
		);
		this.updatedAtFormatted = formatHelpers.formatDate(
			comment?.updatedAt,
			DateFormat.DateTime
		);
	}
}

export class PaginationSubjectCommentsCommand {
	total: number;
	current: number;
	pageSize: number;
	hasNextPage: boolean;
	constructor(model?: SubjectCommentListItemDtoPagedResult) {
		this.total = model?.rowCount!;
		this.pageSize = model?.pageSize!;
		this.current = model?.currentPage!;
		this.hasNextPage = model?.hasNextPage!;
	}
}
