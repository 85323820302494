import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { useKeyPress } from '@hooks/useKeyPressHook';
import { Button, DatePicker } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useRootData } from 'hooks/hook';
import { observer } from 'mobx-react';
import { deepObserve } from 'mobx-utils';
import { Moment } from 'moment';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { CenteredRow } from '../../../../layout/CenteredRow';
import { IGridStore } from '../../../../stores/GridStore';
import { ProfiBazaColumnProps } from '../GridHelper';

const FocusTrap = require('focus-trap-react');

interface IProps<T> {
	column: ProfiBazaColumnProps<T>;
	handleSearch: (value?: [Moment, Moment] | undefined) => void;
	gridName: string;
	clearInput?: boolean;
	setClearInput?: () => void;
	filterProps: FilterDropdownProps;
	defaultValue?: any;
}

export const GridFilterDateRange = observer(
	<T extends any>(props: IProps<T> & { children?: ReactNode }) => {
		const { filterProps, defaultValue } = props;

		const inputRef = useRef<any>(null);

		const [value, setValue] = useState<[Moment, Moment] | undefined>();
		const gridStore: IGridStore = useRootData((store) => store.gridStore);

		const [trapActive, setTrapActive] = useState<boolean>(false);

		useEffect(() => {
			let savedValues = gridStore.getColumnFilter(
				props.gridName,
				props.column.key!
			)?.value;
			if (savedValues?.value)
				savedValues = savedValues.filter((a: any) => a !== undefined);
			setValue(savedValues?.value);

			return () => {
				disposer();
			};
		}, []);

		useEffect(() => {
			if (props.clearInput) {
				setValue(undefined);
				props.setClearInput?.();
			}
		}, [props.clearInput]);

		const clear = () => {
			setValue(undefined);
			gridStore.clearColumnFilter(props.gridName, props.column.key!);
			props.handleSearch();
		};

		const search = () => {
			gridStore.saveColumnFilter(
				props.gridName,
				props.column.key!,
				value
			);
			props.handleSearch(value);
		};

		const disposer = deepObserve(
			gridStore.filtersGC.get(props.gridName),
			(change, path) => {
				const status = gridStore.getFilterGCStatus(
					props.gridName,
					props.column.key!
				);
				if (status) {
					const columnFilters = gridStore.getColumnFilter(
						props.gridName,
						props.column.key!
					)?.value;
					setValue(columnFilters);
				}
			}
		);

		const onDeactivatePopover = () => {
			setTrapActive(false);
			filterProps.confirm();
		};

		useEffect(() => {
			if (filterProps.visible) {
				setTrapActive(true);
			}
		}, [filterProps.visible]);

		return trapActive ? (
			<FocusTrap
				focusTrapOptions={{
					onDeactivate: onDeactivatePopover,
					clickOutsideDeactivates: false,
					returnFocusOnDeactivate: true,
					allowOutsideClick: true,
					setReturnFocus: `#filterColumn-${props.column.key}`,
					fallbackFocus: '.filter-block',
				}}
			>
				<div tabIndex={-1} className="filter-block">
					<CenteredRow>
						<Button
							type="primary"
							icon={<SearchOutlined />}
							size="small"
							onClick={() => search()}
							disabled={!value}
						>
							Wyszukaj
						</Button>
						<Button
							size="small"
							style={{ marginLeft: 4 }}
							icon={<DeleteOutlined />}
							onClick={() => clear()}
						>
							Wyczyść
						</Button>
					</CenteredRow>
					<DatePicker.RangePicker
						autoFocus
						ref={inputRef}
						size="small"
						placeholder={['Od', 'Do']}
						style={{ marginTop: 8, marginBottom: 8 }}
						value={value ? value : null}
						onChange={(dates) =>
							setValue(dates as [Moment, Moment])
						}
					/>
				</div>
			</FocusTrap>
		) : null;
	}
);
