import { List } from 'antd';
import { ListProps } from 'antd/lib/list';
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { DictionaryValueItemDto } from 'services/src/models';

import { DictionaryValueItem, SortableItem } from './DictionaryItem';

interface IListProps {
	items: DictionaryValueItemDto[];
	isEditable?: boolean;
	previousLevel?: boolean;
}

export const DictionaryValuesList: React.FC<IListProps & ListProps<any>> = (
	props
) => {
	const { items, isEditable, previousLevel } = props;

	return (
		<List
			className="dictionary-list"
			split={false}
			dataSource={items}
			renderItem={(item, index) =>
				item.id ? (
					isEditable ? (
						<SortableItem
							key={`item-${item.id}`}
							index={index}
							item={item}
						/>
					) : (
						<DictionaryValueItem
							key={`item-${item.id}`}
							item={item}
							previousLevel={previousLevel}
						/>
					)
				) : (
					<></>
				)
			}
		/>
	);
};

export const SortableList = SortableContainer(DictionaryValuesList);
