import { SupportedExportType } from '@components/shared/paginatedProfiBazaTable/actions/ExportButtons';
import { SieveModel } from '@components/shared/paginatedProfiBazaTable/SieveModels';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { FileType } from '@services/src/models';

export const exportActions = (
	exportType: SupportedExportType,
	abortSignal: AbortSignal | undefined
) => {
	switch (exportType) {
		case 'full':
			return (
				sieve: SieveModel,
                allRowsSelected: boolean,
                filter?: any,
				checkedIds?: string[],
				unCheckedIds?: string[],
				fileType?: FileType
			) => {
				return getProfiBazaApiClient().then((api) =>
					api.statements.getFileDetails({
						sieveFilters: sieve.filters,
						sieveSorts: sieve.sorts,
						sievePage: sieve.page,
						sievePageSize: sieve.pageSize,
						labels: sieve.labels,
						isCheckedAll: allRowsSelected,
						checked: checkedIds,
						unchecked: unCheckedIds,
						fileType: fileType,
						abortSignal,
					})
				);
			};

			case 'default':
				return (
					sieve: SieveModel,
                    allRowsSelected: boolean,
                    filter?: any,
					checkedIds?: string[],
					unCheckedIds?: string[],
					fileType?: FileType
				) => {
					return getProfiBazaApiClient().then((api) =>
						api.statements.getFileList({
							sieveFilters: sieve.filters,
							sieveSorts: sieve.sorts,
							sievePage: sieve.page,
							sievePageSize: sieve.pageSize,
							labels: sieve.labels,
							isCheckedAll: allRowsSelected,
							checked: checkedIds,
							unchecked: unCheckedIds,
							fileType: fileType,
							abortSignal,
						})
					);
				};

		case 'flat':
			return (
				sieve: SieveModel,
                allRowsSelected: boolean,
                filter?: any,
				checkedIds?: string[],
				unCheckedIds?: string[],
				fileType?: FileType
			) => {
				return getProfiBazaApiClient().then((api) =>
					api.statements.getFileFlatList({
						sieveFilters: sieve.filters,
						sieveSorts: sieve.sorts,
						sievePage: sieve.page,
						sievePageSize: sieve.pageSize,
						labels: sieve.labels,
						isCheckedAll: allRowsSelected,
						checked: checkedIds,
						unchecked: unCheckedIds,
						fileType: fileType,
						abortSignal,
					})
				);
			};

		case 'single':
			return (
				sieve: SieveModel,
                allRowsSelected: boolean,
                filter?: any,
				checkedIds?: string[],
				unCheckedIds?: string[],
				fileType?: FileType
			) => {
				return getProfiBazaApiClient().then((api) =>
					api.statements.getSingleStatementExport({
						sieveFilters: sieve.filters,
						sieveSorts: sieve.sorts,
						sievePage: sieve.page,
						sievePageSize: sieve.pageSize,
						labels: sieve.labels,
						isCheckedAll: allRowsSelected,
						checked: checkedIds,
						unchecked: unCheckedIds,
						fileType: fileType,
						abortSignal,
					})
				);
			};

		default:
			throw new Error('Not supported export type');
	}
};
