import PermissionValidator from 'authorization/permissionValidator';
import { ajaxCatch } from 'helper/api';
import { Theme } from 'layout/AccessibilitySettings';
import { setDefaultRoute } from 'layout/Menu/MenuInitializer';
import { MenuSectionType } from 'layout/Menu/MenuItems';
import { observable } from 'mobx';
import { User } from 'oidc-client';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	AccountDetailsDto,
	AccountSaveGridSettingsOptionalParams,
	SaveDashboardSettingsCommand,
} from 'services/src/models';

export const accountDetailsStore = {
	account: observable.box<AccountDetailsDto | undefined>(undefined),
	oidcUser: observable.box<User | undefined>(undefined),
	defaultRoute: observable.box<string | undefined>(undefined),
	currentPageSection: observable.box<MenuSectionType | undefined>(undefined),
	theme: observable.box<Theme | undefined>(undefined),
	preloader: observable.box<string | undefined>(undefined),
	initialize: (callback: () => void = () => {}) => {
		if (accountDetailsStore.oidcUser.get()) {
			accountDetailsStore.fetchAccountDetails().then((resp) => {
				const permissionValidator = new PermissionValidator(
					accountDetailsStore!.account.get()!
				);
				setDefaultRoute(permissionValidator, accountDetailsStore);

				callback();
			});
		}
	},

	fetchAccountDetails: () => {
		return ajaxCatch(
			() => getProfiBazaApiClient().then((api) => api.account.me()),
			(account: AccountDetailsDto | null) => {
				if (account) {
					accountDetailsStore.account.set(account);
				}
			}
		);
	},

	setOidcUser: (oidcUser: User) => {
		accountDetailsStore.oidcUser.set(oidcUser);
	},

	setTheme: (theme: Theme) => {
		accountDetailsStore.theme.set(theme);
	},

	saveVisibleColumns: (gridName: string, visibleColumnKeys: string[]) => {
		accountDetailsStore.account.get()?.existsInProfiBaza &&
			ajaxCatch(
				() =>
					getProfiBazaApiClient().then((api) =>
						api.account.saveGridSettings({
							body: {
								gridName: gridName,
								visibleColumnKeys: visibleColumnKeys,
							},
						})
					),
				() => {
					accountDetailsStore.fetchAccountDetails();
				}
			);
	},

	saveDashboardSettings: (command: SaveDashboardSettingsCommand) => {
		const account = accountDetailsStore?.account.get();

		return ajaxCatch(
			() =>
				getProfiBazaApiClient().then((api) =>
					api.account.saveDashboardSettings({
						body: command,
					})
				),
			() => {
				accountDetailsStore.account.set({
					...account,
					dashboardSettings: {
						showInterventions: command.showInterventions,
						showStatements: command.showStatements,
						notepadContent: command.notepadContent,
					},
				});
			},
			undefined,
			false
		);
	},

	savePageSize: (gridName: string, pageSize: number) => {
		accountDetailsStore.account.get()?.existsInProfiBaza &&
			ajaxCatch(
				() =>
					getProfiBazaApiClient().then((api) => {
						const visibleColumnKeys = accountDetailsStore.account.get()
							?.gridSettings![gridName].visibleColumnKeys;
						const options: AccountSaveGridSettingsOptionalParams = {
							body: {
								gridName: gridName,
								visibleColumnKeys: visibleColumnKeys,
								pageSize: pageSize,
							},
						};

						return api.account.saveGridSettings(options);
					}),
				() => {
					accountDetailsStore.fetchAccountDetails();
				}
			);
	},
};

export const createAccountDetailsStore = () => {
	return accountDetailsStore;
};

export type IAccountDetailsStore = ReturnType<typeof createAccountDetailsStore>;
