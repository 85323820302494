export class DictionariesConstants {
	public static /* const */ ImplementationPeriodCodeName: string =
		'TYP_OKRESU_REALIZACJI';

	public static /* const */ InterventionAreaCodeName: string =
		'ZASIEG_INTERWENCJI';

	static ImplementationPeriod = class {
		public static /* const */ SchoolYear: string =
			'TYP_OKRESU_REALIZ_SZKOLNY';
		public static Multiannual: string = 'TYP_OKRESU_REALIZ_WIELOLETNI';
	};

	static InterventionArea = class {
		public static /* const */ Nationwide: string =
			'ZASIEG_INTERWENCJI_OGOLNOKRAJ';

		public static /* const */ Voivodeship: string =
			'ZASIEG_INTERWENCJI_WOJ';
	};

	static InterventionType = class {
		public static /* const */ AotmitOpinion: string =
			'TYP_INTERWENCJI_AOTMIT_OPINIA';
		public static /* const */ AotmitReported: string =
			'TYP_INTERWENCJI_AOTMIT_ZGLASZANY';
		public static /* const */ Program: string = 'TYP_INTERWENCJI_PROGRAM';
		public static /* const */ NotProgram: string =
			'TYP_INTERWENCJI_NIE_PROGRAM';
		public static /* const */ AotmitRecomendation: string =
			'TYP_INTERWENCJI_AOTMIT_REKOM';
	};

	static LegalBasis = class {
		public static /* const */ HealthCareServicesFinancedFromPublicFunds: string =
			'SWIADCZENIE_OPIEKI_ZDROWOTNEJ_FINANSOWANEJ_ZE_SRODKOW_PUBLICZNYCH';
		public static /* const */ StateSanitaryInspection: string =
			'PANSTWOWA_INSPEKCJA_SANITARNA';
	};

	static ActivityCategory = class {
		public static /* const */ EducationMeetingTalkLectureConference: string =
			'SPOTKANIE_EDUKACYJNE_POGADANKA_WYKLAD_KONFERENCJA';
		public static /* const */ Workshops_Training_TeachingSkillsInstruction: string =
			'WARSZTATY_SZKOLENIE_TRENING_UCZENIE_UMIEJETNOSCI_INSTRUKTAZ';
	};

	static InterventionEffect = class {
		public static /* const */ Conference: string = 'KONFERENCJE';
	};

	static AttachmentCategories = class {
		public static /* const */ SubjectAttachmentCategory: string =
			'KATEGORIA_ZAL_PODMIOT';
		public static /* const */ InterventionAttachmentCategory: string =
			'KATEGORIA_ZAL_INTERWENCJA';
		public static /* const */ StatementAttachmentCategory: string =
			'KATEGORIA_ZAL_SPRAWOZDANIE';
	};

	static MZ53Categories = [
		'KATEGORIA_ZAL_MZ-53_zal_1',
		'KATEGORIA_ZAL_MZ-53_zal_2',
		'KATEGORIA_ZAL_MZ-53_zal_3',
		'KATEGORIA_ZAL_MZ-53_zal_4',
		'KATEGORIA_ZAL_MZ-53_zal_5',
		'KATEGORIA_ZAL_MZ-53_zal_6',
	];
}
