import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import  * as StatementStatus from './StatementStatus';

const statementStatusWithoutArchived = () => {
	var statuses = StatementStatus.mapings;
	statuses.delete(ProfiBazaAPIModels.StatementStatus.ARCHIVED)
	return statuses;
}

export const mappings = statementStatusWithoutArchived()

export const map = (
	value: ProfiBazaAPIModels.StatementStatus
): string => mappings.get(value) ?? '';
