import '../../../shared/comments/comment.less';

import {
	CheckCircleTwoTone,
	DeleteOutlined,
	DragOutlined,
	EditOutlined,
	ExclamationCircleTwoTone,
	HomeOutlined,
	InfoCircleTwoTone,
	SmallDashOutlined,
	UserOutlined,
} from '@ant-design/icons';
import ReakitMenuButton from '@components/shared/buttons/ReakitMenuButton';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import { CommentStatus, CommentType } from '@services/src/models';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Button, Space, Tooltip } from 'antd';
import { ajaxByUser } from 'helper/api';
import { useRootData } from 'hooks/hook';
import React, { useState } from 'react';
import { Menu, MenuItem, useMenuState } from 'reakit/Menu';
import * as CommentTypeMapper from 'services/mappers/CommentType';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';

import { CommentDto } from '../Commands/CommentsCommand';
import moment from 'moment';

interface IProps {
	refresh: () => void;
	data: CommentDto | undefined;
	statementSubjectId: string;
	setEditable: (
		value: boolean,
		id: string | undefined,
		visible: boolean
	) => void;
}

const getCommentClass = (status: CommentStatus, type: CommentType): string => {
	if (type === CommentType.IMPORTANT) {
		return 'antsay-comment important';
	}

	switch (status) {
		case CommentStatus.PROCESSED:
			return 'antsay-comment processed';
		default:
			return 'antsay-comment added';
	}
};

const getCommentIcon = (type: CommentType, title: string): JSX.Element => {
	switch (type) {
		case CommentType.PUBLIC:
			return (
				<InfoCircleTwoTone
					twoToneColor="#40a9c7"
					aria-label={title}
					role="img"
				/>
			);
		case CommentType.PRIVATE:
			return <UserOutlined aria-label={title} role="img" />;
		case CommentType.IMPORTANT:
			return (
				<ExclamationCircleTwoTone
					twoToneColor="red"
					aria-label={title}
					role="img"
				/>
			);
	}
};

const getCommentContentClass = (status: CommentStatus): string => {
	switch (status) {
		case CommentStatus.PROCESSED:
			return 'content';
		default:
			return 'content important';
	}
};

const Comment = (props: IProps) => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const menuState = useMenuState();

	const [deletePopconfirmVisible, setDeletePopconfirmVisible] = useState<
		boolean
	>(false);

	const canResolveComment = (comment: CommentDto) => {
		const userSubjectId = accountDetailsStore.account.get()?.subject?.id;

		if (comment.statementSubjectId === userSubjectId) {
			return true;
		}

		return false;
	};

	const hasMenuAccess =
		props.data?.author?.id ==
		accountDetailsStore.account.get()?.subject?.id;

	const menu = (
		<Menu
			{...menuState}
			aria-label="Menu komentarza"
			className="ant-menu ant-menu-sub account-menu-vertical z-index-up"
		>
			<MenuItem
				key="edit"
				className="ant-menu-item sub-menu-item"
				{...menuState}
				onClick={() => props.setEditable(true, props.data?.id!, true)}
			>
				<EditOutlined />
				Edytuj
			</MenuItem>
			<MenuItem
				key="delete"
				className="ant-menu-item sub-menu-item"
				{...menuState}
				onClick={() => {
					setDeletePopconfirmVisible(true);
				}}
			>
				<DeleteOutlined />
				Usuń
			</MenuItem>
		</Menu>
	);

	const { data } = props;

	const CommentIcon = () => {
		const title = CommentTypeMapper.map(props.data?.type!);
		return (
			<div>
				<Tooltip title={title}>
					{getCommentIcon(props.data?.type!, title)}
				</Tooltip>
			</div>
		);
	};

	const processComment = (comment: CommentDto) => {
		ajaxByUser('Rozpatrzono komentarz', () =>
			getProfiBazaApiClient().then((api) =>
				api.statementComments
					.setAsProcessed({
						body: {
							commentId: comment.id,
						},
					})
					.finally(props.refresh)
			)
		);
	};

	const CommentAction = (comment: CommentDto): JSX.Element => {
		if (comment.status === CommentStatus.PROCESSED) {
			return (
				<Space>
					<span>Rozpatrzono komentarz</span>
					<CheckCircleTwoTone
						twoToneColor="#52c41a"
						className="icon"
						role="img"
						aria-label="Ikona rozpatrzono"
					/>
				</Space>
			);
		}

		if (canResolveComment(comment)) {
			return (
				<Button type="primary" onClick={() => processComment(data!)}>
					Rozpatrzono
				</Button>
			);
		}

		return <></>;
	};

	const ProperDate = (date: any) =>{
		const now = moment();
		if(now.isBefore(date))
			return now.fromNow();
		
		return date.fromNow();
	}

	const CommentBody = () => {
		return (
			<div>
				<div className={getCommentContentClass(props.data?.status!)}>
					{data?.body || ''}
				</div>
				<div className="footer-row">
					<UserOutlined />
					<p>{data?.author?.name}</p>
					<p>
						{data?.updatedAt ? (
							<Tooltip title={data?.updatedAtFormatted}>
								<span>{ProperDate(data?.updatedAt)}</span>
							</Tooltip>
						) : (
							<Tooltip title={data?.createdAtFormatted}>
								<span>{ProperDate(data?.createdAt)}</span>
							</Tooltip>
						)}
					</p>
				</div>
				<div className="footer-row">
					<HomeOutlined />
					<p>{`Podmiot (${data?.author.subject})`}</p>
				</div>
				<div className="field-desc">
					<DragOutlined />
					<p>
						{`Dział/ID interwencji: ${
							data?.statementPartInterventionCode ?? 'Brak'
						} Wiersz: ${data?.row ?? 'Brak'} Kolumna: ${
							data?.column ?? 'Brak'
						}`}
					</p>
				</div>

				<div className="action">{CommentAction(data!)}</div>
			</div>
		);
	};

	const CommentMenu = (
		<>
			<ReakitMenuButton menuState={menuState} ariaLabel="Menu">
				<SmallDashOutlined />
			</ReakitMenuButton>
			{menu}
		</>
	);

	return (
		<AccessiblePopconfirm
			visible={deletePopconfirmVisible}
			title="Czy na pewno chcesz usunąć komentarz?"
			onConfirm={() => {
				ajaxByUser(
					'Usunięto komentarz',
					() =>
						getProfiBazaApiClient().then((api) =>
							api.statementComments.deleteMethod({
								body: {
									commentId: props.data?.id!,
								},
							})
						),
					() => {
						setDeletePopconfirmVisible(false);
						props.refresh();
					}
				);
			}}
			onCancel={() => {
				setDeletePopconfirmVisible(false);
			}}
		>
			<div
				className={getCommentClass(
					props.data?.status!,
					props.data?.type!
				)}
			>
				<CommentIcon />
				<CommentBody />
				{hasMenuAccess && CommentMenu}
			</div>
		</AccessiblePopconfirm>
	);
};
export default Comment;
