import { FilterFilled } from '@ant-design/icons';
import FilterButton from '@components/shared/buttons/FilterButton';
import * as PageOrientationMapper from '@services/mappers/PageOrientation';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { Button } from 'antd';
import clsx from 'clsx';
import {
	ProfiBazaDictionaryFilter,
	ProfiBazaInputFilter,
} from 'components/shared/paginatedProfiBazaTable/filters/ProfiBazaTableFilter';
import React from 'react';
import { DictionaryName } from 'services/mappers/DictionaryName';

import { ProfiBazaColumnProps } from '../../../components/shared/paginatedProfiBazaTable/GridHelper';

const columns = (
	clearAll: boolean,
	setClearAll: (value: boolean) => void,
	tableFilters:
		| Record<keyof ProfiBazaAPIModels.FormPartDto, string[]>
		| undefined,
	tableSorters:
		| Record<
				keyof ProfiBazaAPIModels.FormPartDto,
				'ascend' | 'descend' | null | undefined
		  >
		| undefined
) => {
	const columns: ProfiBazaColumnProps<ProfiBazaAPIModels.FormPartDto>[] = [
		{
			dataIndex: 'formName',
			key: 'formName',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Identyfikator
				</Button>
			),
			sorter: (
				a: ProfiBazaAPIModels.FormPartDto,
				b: ProfiBazaAPIModels.FormPartDto
			): number => a.formName?.localeCompare(b.formName ?? '') ?? 0,
			filterDropdown: (filterProps) => (
				<ProfiBazaInputFilter<ProfiBazaAPIModels.FormPartDto>
					columns={columns}
					column="formName"
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					filterProps={filterProps}
				/>
			),
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			sortOrder: tableSorters?.formName || null,
			filteredValue: tableFilters?.formName || null,
			onFilter: (value, record) => {
				return record.formName?.includes(value.toString())!;
			},
		},
		{
			dataIndex: 'label',
			key: 'label',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Nazwa
				</Button>
			),
			sorter: (
				a: ProfiBazaAPIModels.FormPartDto,
				b: ProfiBazaAPIModels.FormPartDto
			): number => a.label?.localeCompare(b.label ?? '') ?? 0,
			filterDropdown: (filterProps) => (
				<ProfiBazaInputFilter<ProfiBazaAPIModels.FormPartDto>
					columns={columns}
					column="label"
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					filterProps={filterProps}
				/>
			),
			sortOrder: tableSorters?.label || null,
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			filteredValue: tableFilters?.label || null,
			onFilter: (value, record) => {
				return record.label
					?.toLocaleLowerCase()
					.includes(value.toString().toLocaleLowerCase())!;
			},
		},
		{
			dataIndex: 'order',
			key: 'order',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Numeracja
				</Button>
			),
			sorter: (
				a: ProfiBazaAPIModels.FormPartDto,
				b: ProfiBazaAPIModels.FormPartDto
			): number => (b.order ?? 0) - (a.order ?? 0),
			render: (value?: number) => (value ?? 0) + 1,
			filterDropdown: (filterProps) => (
				<ProfiBazaInputFilter<ProfiBazaAPIModels.FormPartDto>
					columns={columns}
					column="order"
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					filterProps={filterProps}
				/>
			),
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			sortOrder: tableSorters?.order || null,
			filteredValue: tableFilters?.order || null,
			onFilter: (value, record) => {
				return record.order == +value - 1;
			},
		},
		{
			dataIndex: 'pageOrientation',
			key: 'pageOrientation',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Orientacja
				</Button>
			),
			render: (value: ProfiBazaAPIModels.PageOrientation) => {
				if(!value){
					return <>Brak</>
				}
				const statusValue = PageOrientationMapper.map(value);
				return <>{statusValue}</>;
			},
			sorter: (
				a: ProfiBazaAPIModels.FormPartDto,
				b: ProfiBazaAPIModels.FormPartDto
			): number =>
				a.pageOrientation?.localeCompare(b.pageOrientation ?? '') ?? 0,
			filterDropdown: (filterProps) => (
				<ProfiBazaDictionaryFilter<ProfiBazaAPIModels.FormPartDto>
					columns={columns}
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					column="pageOrientation"
					filterProps={filterProps}
				/>
			),
			sortOrder: tableSorters?.pageOrientation || null,
			filterDictionary: DictionaryName.PageOrientation,
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			filteredValue: tableFilters?.pageOrientation || null,
			onFilter: (value, record) => {
				return value == record.pageOrientation!;
			},
		},
		{
			dataIndex: 'isVisibleInViewModel',
			key: 'isVisibleInViewModel',
			title: (
				<Button className="btn-filter" aria-label="Sortuj kolumnę">
					Widoczna w podglądzie
				</Button>
			),
			render: (value: boolean) => (value ? 'Tak' :  (value == false ? 'Nie' : 'Brak')),
			sorter: (
				a: ProfiBazaAPIModels.FormPartDto,
				b: ProfiBazaAPIModels.FormPartDto
			): number =>
				a.isVisibleInViewModel === b.isVisibleInViewModel
					? 0
					: a.isVisibleInViewModel
					? -1
					: 1,
			filterDropdown: (filterProps) => (
				<ProfiBazaDictionaryFilter<ProfiBazaAPIModels.FormPartDto>
					columns={columns}
					clearAll={clearAll}
					setClearAll={() => setClearAll(false)}
					column="isVisibleInViewModel"
					filterProps={filterProps}
				/>
			),
			sortOrder: tableSorters?.isVisibleInViewModel || null,
			filteredValue: tableFilters?.isVisibleInViewModel || null,
			filterDictionary: DictionaryName.TrueOrFalse,
			filterIcon: (filtered: boolean) => (
				<FilterButton filtered={filtered} />
			),
			onFilter: (value, record) => {
				return value == record.isVisibleInViewModel!.toString();
			},
		},
	];
	return columns;
};

export default columns;
