import React from 'react';
import { Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/lib/button';

interface IProps {
	tall?: boolean;
	onClick?: () => void;
}

function ImportButton(props: React.PropsWithChildren<IProps & ButtonProps>) {
	const { tall, type, onClick, ...others } = props;

	const styleSettings = tall ? { height: '100%' } : {};

	return (
		<Button
			icon={<FileExcelOutlined />}
			size={tall ? 'large' : 'middle'}
			type={type ? type : 'primary'}
			style={styleSettings}
			onClick={props.onClick}
			{...others}
		>
			{props.children}
		</Button>
	);
}

export default ImportButton;
