/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/interventionTagMappers";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a InterventionTag. */
export class InterventionTag {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a InterventionTag.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addTag(options?: Models.InterventionTagAddTagOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addTag(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addTag(options: Models.InterventionTagAddTagOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addTag(options?: Models.InterventionTagAddTagOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      addTagOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteTag(options?: Models.InterventionTagDeleteTagOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteTag(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteTag(options: Models.InterventionTagDeleteTagOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteTag(options?: Models.InterventionTagDeleteTagOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteTagOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const addTagOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "rizp/InterventionTag",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.AddInterventionTagCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteTagOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "rizp/InterventionTag",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeleteInterventionTagCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
