import './Dashboard.less';

import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Button, Col, Space } from 'antd';
import Authorized from 'authorization/authorized';
import { useRootData } from 'hooks/hook';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffectHook';
import { usePrevious } from 'hooks/usePreviousHook';
import { CenteredRow } from 'layout/CenteredRow';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
	DashboardSettingsDto,
	SaveDashboardSettingsCommand,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import MiniCalendar from '../calendar/MiniCalendar';
import DashboardCounter from './DashboardCounter';
import DashboardInterventions from './DashboardInterventions';
import DashboardStatements from './DashboardStatements';
import DashboardNotepad from './notepad/DashboardNotepad';
import DashboardNotepadSpinner from './notepad/DashboardNotepadSpinner';
import NotepadHelpTooltip from './notepad/NotepadHelpTooltip';

interface IProps {}

const Dashboard: React.FC<RouteComponentProps<IProps>> = (props) => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	useEffect(() => {
		const settings = accountDetailsStore?.account.get()?.dashboardSettings;

		setDashboardConfiguration(settings);
	}, []);

	const [dashboardConfiguration, setDashboardConfiguration] = useState<
		DashboardSettingsDto
	>();

	const [isBusy, setIsBusy] = useState<boolean>(false);
	const lastConfiguration = usePrevious(dashboardConfiguration);

	const toggleInterventionsVisibilityRef = useRef<HTMLElement>(null);
	const toggleStatementsVisibilityRef = useRef<HTMLElement>(null);
	const [clickedButton, setClickedButton] = useState<React.RefObject<
		HTMLElement
	> | null>(null);

	useDidUpdateEffect(() => {
		if (lastConfiguration === undefined) {
			return;
		}

		if (!isBusy && dashboardConfiguration) {
			setIsBusy(true);

			const command: SaveDashboardSettingsCommand = {
				showInterventions: dashboardConfiguration.showInterventions,
				showStatements: dashboardConfiguration.showStatements,
				notepadContent: dashboardConfiguration.notepadContent,
			};

			accountDetailsStore.saveDashboardSettings(command).finally(() => {
				setTimeout(() => {
					setIsBusy(false);

					if (clickedButton) {
						clickedButton.current?.focus();
						setClickedButton(null);
					}
				}, 600);
			});
		}
	}, [dashboardConfiguration]);

	const getButtonLabelByState = (state: boolean): string => {
		return state ? 'Ukryj' : 'Pokaż';
	};

	const setContent = (content?: string): void => {
		setDashboardConfiguration({
			...dashboardConfiguration,
			notepadContent: content,
		});
	};

	return (
		<Fragment>
			{dashboardConfiguration && (
				<CenteredRow className="dashboard">
					<Col span={20}>
						<CenteredRow>
							<Col span={20}>
								<div className="notepad">
									<Space>
										<Space align="center">
											<h2 className="title">
												Moje notatki
											</h2>
											<NotepadHelpTooltip />
										</Space>
										<AccessiblePopconfirm
											title="Czy na pewno chcesz wyczyścic notatkę ?"
											onConfirm={() => setContent()}
										>
											<Button disabled={isBusy} aria-label="Wyczyść notatkę">
												Wyczyść notatkę
											</Button>
										</AccessiblePopconfirm>
										{isBusy && <DashboardNotepadSpinner />}
									</Space>

									<DashboardNotepad
										id="dashboardNotepad"
										contentChange={setContent}
										content={
											dashboardConfiguration.notepadContent
										}
									/>
								</div>
							</Col>
							<Col span={4}>
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.StatementPreviewMineNPZ,
										ProfiBazaAPIModels.Permission
											.StatementPreviewMineMZ49,
										ProfiBazaAPIModels.Permission
											.StatementPreviewMineMZ53,
									]}
								>
									<DashboardCounter />
								</Authorized>
							</Col>
						</CenteredRow>

						<Authorized
							permissions={[
								ProfiBazaAPIModels.Permission.RIZPPreviewMine,
							]}
						>
							<Space className="table-container">
								<SkipLinkContent />
								<h2 className="table-title">
									Ostatnie interwencje
								</h2>
								<Button
									ref={toggleInterventionsVisibilityRef}
									onClick={() => {
										setDashboardConfiguration({
											...dashboardConfiguration,
											showInterventions: !dashboardConfiguration.showInterventions,
										});
										setClickedButton(
											toggleInterventionsVisibilityRef
										);
									}}
									disabled={isBusy}
								>
									{getButtonLabelByState(
										!!dashboardConfiguration.showInterventions
									)}
								</Button>
							</Space>

							{dashboardConfiguration.showInterventions && (
								<CenteredRow>
									<Col span={24}>
										<DashboardInterventions />
									</Col>
								</CenteredRow>
							)}
						</Authorized>

						<Authorized
							permissions={[
								ProfiBazaAPIModels.Permission
									.StatementPreviewMineNPZ,
								ProfiBazaAPIModels.Permission
									.StatementPreviewMineMZ49,
								ProfiBazaAPIModels.Permission
									.StatementPreviewMineMZ53,
							]}
						>
							<Space className="table-container">
								<h2 className="table-title">
									Ostatnie sprawozdania
								</h2>
								<Button
									ref={toggleStatementsVisibilityRef}
									onClick={() => {
										setDashboardConfiguration({
											...dashboardConfiguration,
											showStatements: !dashboardConfiguration.showStatements,
										});
										setClickedButton(
											toggleStatementsVisibilityRef
										);
									}}
									disabled={isBusy}
								>
									{getButtonLabelByState(
										!!dashboardConfiguration.showStatements
									)}
								</Button>
							</Space>

							{dashboardConfiguration.showStatements && (
								<CenteredRow>
									<Col span={24}>
										<DashboardStatements />
									</Col>
								</CenteredRow>
							)}
						</Authorized>
					</Col>
					<Col span={4}>
						<MiniCalendar />
					</Col>
				</CenteredRow>
			)}
		</Fragment>
	);
};

export default Dashboard;
