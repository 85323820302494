import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import React, { useContext } from 'react';
import {
	InterventionActivity,
	InterventionAreaDictionary,
	InterventionCoowners,
	InterventionContractorsAmount,
	InterventionDataSource,
	InterventionEffects,
	InterventionImplementationPeriod,
	InterventionIsAotmit,
	InterventionKind,
	InterventionLegalBases,
	InterventionModeOfImplementation,
	InterventionName,
	InterventionPlannedBudget,
	InterventionPlannedDate,
	InterventionTerytArea,
	InterventionClassificationGBOARD,
	InterventionClassificationTeryt,
	InterventionClassificationNCN,
	InterventionInfo,
	InterventionNumberOfRecipients,
	InterventionNumberOfPersonAction,
	InterventionAnnualNumberOfRecipients,
} from './InterventionFields';
import {
	InterventionPathContext,
	InterventionPathContextType,
} from '../context/InterventionPathContext';
import { Row } from 'antd';
import { InterventionPartners } from './InterventionPartnerField';
import { InterventionCoProducers } from './coProducers/InterventionCoProducers';

interface IProps {
	interventionPath: ProfiBazaAPIModels.InterventionPath;
	isPattern: boolean;
}

const InterventionFields = (props: IProps) => {
	const context = useContext(InterventionPathContext);
	if (
		props.interventionPath ==
		ProfiBazaAPIModels.InterventionPath.STAFFEDUCATION
	) {
		return InterventionStaffPath(context);
	} else if (
		props.interventionPath ==
			ProfiBazaAPIModels.InterventionPath.SUPERVISIONMONITORING ||
		props.interventionPath ==
			ProfiBazaAPIModels.InterventionPath.SUPERVISIONSANITARY ||
		props.interventionPath ==
			ProfiBazaAPIModels.InterventionPath.STAFFMONITORING
	) {
		return InterventionVisionPath(context);
	} else if (
		props.interventionPath ==
		ProfiBazaAPIModels.InterventionPath.LEGISLATION
	) {
		return InterventionLegislationPath(context);
	} else if (
		props.interventionPath ==
			ProfiBazaAPIModels.InterventionPath.PROHEALTHACTIVITIES ||
		props.isPattern
	) {
		return InterventionStandardPath(context);
	} else if (
		props.interventionPath == ProfiBazaAPIModels.InterventionPath.RESEARCH
	) {
		return InterventionResearchPath(context);
	} else {
		return <></>;
	}
};

const InterventionStandardPath = (context: InterventionPathContextType) => {
	return (
		<>
			<Row gutter={[16, 6]}>{InterventionInfo(context)}</Row>
			<Row gutter={[16, 6]}>{[InterventionIsAotmit(context)]}</Row>
			<Row gutter={[16, 6]}>{InterventionName(context, 24)}</Row>
			<Row gutter={[8, 6]}>
				{[
					InterventionAnnualNumberOfRecipients(context, 4),
					InterventionNumberOfRecipients(context, 3),
					InterventionNumberOfPersonAction(context, 3),
					InterventionPlannedDate(context, 6),
					InterventionImplementationPeriod(context, 4),
					InterventionAreaDictionary(context, 4),
				]}
			</Row>
			<Row gutter={[16, 6]}>{InterventionTerytArea(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionLegalBases(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionCoowners(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionPartners(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionActivity()}</Row>
		</>
	);
};

const InterventionVisionPath = (context: InterventionPathContextType) => {
	return (
		<>
			<Row gutter={[16, 6]}>{InterventionInfo(context)}</Row>
			<Row gutter={[16, 6]}>{InterventionName(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionKind(context, 24)}</Row>
			<Row gutter={[16, 6]}>
				{[
					InterventionImplementationPeriod(context, 8),
					InterventionEffects(context, 16),
				]}
			</Row>
			<Row gutter={[16, 6]}>
				{[
					InterventionAreaDictionary(context, 8),
					InterventionDataSource(context, 16),
				]}
			</Row>
			<Row gutter={[16, 6]}>{InterventionTerytArea(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionLegalBases(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionPartners(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionActivity()}</Row>
		</>
	);
};

const InterventionLegislationPath = (context: InterventionPathContextType) => {
	return (
		<>
			<Row gutter={[16, 6]}>{InterventionInfo(context)}</Row>
			<Row gutter={[16, 6]}>{InterventionName(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionKind(context, 24)}</Row>
			<Row gutter={[16, 6]}>
				{[
					InterventionAreaDictionary(context, 8),
					InterventionEffects(context, 16),
				]}
			</Row>
			<Row gutter={[16, 6]}>{InterventionTerytArea(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionLegalBases(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionActivity()}</Row>
		</>
	);
};

const InterventionStaffPath = (context: InterventionPathContextType) => {
	return (
		<>
			<Row gutter={[16, 6]}>{InterventionInfo(context)}</Row>
			<Row gutter={[16, 6]}>{InterventionName(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionKind(context, 24)}</Row>
			<Row gutter={[16, 6]}>
				{[
					InterventionImplementationPeriod(context, 8),
					InterventionEffects(context, 16),
				]}
			</Row>
			<Row gutter={[16, 6]}>
				{[
					InterventionAreaDictionary(context, 8),
					InterventionModeOfImplementation(context, 16),
				]}
			</Row>
			<Row gutter={[16, 6]}>{InterventionTerytArea(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionLegalBases(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionCoowners(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionActivity()}</Row>
		</>
	);
};

const InterventionResearchPath = (context: InterventionPathContextType) => {
	return (
		<>
			<Row gutter={[16, 6]}>{InterventionInfo(context)}</Row>
			<Row gutter={[16, 6]}>{InterventionName(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionKind(context, 24)}</Row>
			<Row gutter={[16, 6]}>{InterventionEffects(context, 24)}</Row>
			<Row gutter={[16, 6]}>
				{[
					InterventionImplementationPeriod(context, 8),
					InterventionContractorsAmount(context, 5),
					InterventionPlannedBudget(context, 5),
					InterventionAreaDictionary(context, 6),
				]}
			</Row>
			<Row gutter={[16, 6]}>{InterventionLegalBases(context, 24)}</Row>
			<Row gutter={[16, 6]}>
				{InterventionClassificationGBOARD(context, 24)}
			</Row>
			<Row gutter={[16, 6]}>
				{InterventionClassificationNCN(context, 24)}
			</Row>
			<Row gutter={[16, 6]}>
				{InterventionClassificationTeryt(context, 24)}
			</Row>
			<Row>
				{InterventionCoProducers(context, 24)}
			</Row>
			<Row gutter={[16, 6]}>{InterventionActivity()}</Row>
		</>
	);
};

export default InterventionFields;
