import './MultiLevelDictionary.less';

import { SearchOutlined } from '@ant-design/icons';
import { procesTypesTotal } from '@services/mappers/DictionaryInterventionPath';
import { Badge, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ajaxCatch } from 'helper/api';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	DictionaryInterventionPath,
	DictionaryValueItemDto,
	RizpDictionaryValueDto,
} from 'services/src/models';
import { DictionaryValueWithPathViewDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { DictionaryValueItemDto as DictionaryValueItemDtoLocal } from '../DictionaryValueItemDto';
import {
	DictionaryValuesModalProps,
	modalPropsAdapter,
} from '../modals/dictionaryValues/DictionaryValuesModal';
import {
	MultiDictionaryBodyContext,
	MultiDictionaryBodyContextType,
} from './contexts/MultiDictionaryBodyContext';
import { MultiDictionaryContextType } from './contexts/MultiDictionaryContext';
import {
	MultiDictionaryFooterContext,
	MultiDictionaryFooterContextType,
} from './contexts/MultiDictionaryFooterContext';
import { IDeleteValueModal } from './DeleteValueModal';
import { DictionaryLevelHistory } from './DictionaryLevelHistory';
import DictionarySearchBar from './DictionarySearchBar';
import MultiLevelDictionaryBody from './MultiLevelDictionaryBody';
import MultiLevelDictionaryFooter from './MultiLevelDictionaryFooter';
import { IOtherValueHistoryModal } from './OtherValueHistoryModal';
import moment from 'moment';

export interface ISelectionProps {
	setValues: React.Dispatch<
		React.SetStateAction<ProfiBazaAPIModels.RizpDictionaryValueDto[]>
	>;
	setFormValues: (newFormValue: DictionaryValueItemDto) => void;
	codeName: string;
	interventionPath: DictionaryInterventionPath;
}

interface IProps {
	visible: boolean;
	setVisible: (visible: boolean) => void;
	setParents?: (value: DictionaryValueItemDto | undefined) => void;
	dictionaryValues: DictionaryValueItemDtoLocal[] | undefined;
	isEditable?: boolean;
	updateValuePosition: (
		dragIndex: number,
		hoverIndex: number,
		movedValue: DictionaryValueItemDto
	) => void;
	valueModalProps?: DictionaryValuesModalProps;
	selectableMode?: boolean;
	refreshBaseItems?: (
		interventionPath?:
			| ProfiBazaAPIModels.DictionaryInterventionPath[]
			| undefined
	) => void;
	selection?: ISelectionProps;
	isDraft?: boolean;
	notModal?: boolean;
	dateRange?: [Moment | undefined, Moment | undefined];
	additionalData?: any;
}

const MultiLevelDictionary: React.FC<IProps> = (props) => {
	const {
		visible,
		setVisible,
		dictionaryValues,
		isEditable,
		updateValuePosition,
		valueModalProps,
		refreshBaseItems,
		selectableMode,
		selection,
		isDraft,
		notModal,
	} = props;
	const [validFrom, validTo] = props.dateRange ?? [moment(), moment()];

	const { refresh, ...restValueModalProps } =
		valueModalProps ?? modalPropsAdapter;

	const [values, setValues] = useState<DictionaryValueItemDto[]>(
		dictionaryValues ?? []
	);
	const [rootValue, setRootValue] = useState<
		DictionaryValueItemDto | undefined
	>(undefined);
	const [lastSelectedValueId, setLastSelectedValueId] = useState<
		number | undefined
	>(undefined);
	const [currentLevel, setCurrentLevel] = useState<number>(0);
	const [levels, setLevels] = useState<DictionaryLevelHistory>(
		new DictionaryLevelHistory()
	);
	const [loading, setLoading] = useState<boolean>(false);
	const [keysStack, setKeyStack] = useState<
		Array<DictionaryValueItemDto | undefined>
	>([]);
	const [searchPhrase, setSearchPhrase] = useState<string | undefined>(
		undefined
	);
	const [searchModalVisible, setSearchModalVisible] = useState<boolean>(
		false
	);
	const [actionBaseId, setActionBaseId] = useState<number | undefined>(
		undefined
	);
	const [detachedValue, setDetachedValue] = useState<
		DictionaryValueItemDto | undefined
	>();
	const [detachedLevel, setDetachedLevel] = useState<
		DictionaryValueItemDto | undefined
	>();
	const [globalContextMenuVisible, setGlobalContextMenuVisible] = useState<
		boolean
	>(true);
	const [processFilters, setProcessFilters] = useState<
		DictionaryInterventionPath[]
	>();

	const deleteModalRef = useRef<IDeleteValueModal>(null);
	const otherValueHistoryModalRef = useRef<IOtherValueHistoryModal>(null);

	const updateValuesCollection = (
		id: number | undefined,
		items: DictionaryValueItemDto[]
	) => {
		let list = items.filter(
			(x) =>
				!processFilters ||
				processFilters?.some(
					(pf) =>
						!x.interventionPaths ||
						x.interventionPaths?.includes(pf)
				)
		);
		setValues(list);
		levels.cacheValues(id, list);
	};

	const handleCancel = () => {
		if (!loading) {
			setVisible(false);
			setProcessFilters(undefined);
		}
	};

	const getHigherLevelElements = (currentIndex: number): Promise<void> => {
		setLoading(true);
		const interventionPaths =
			processFilters?.length === procesTypesTotal
				? undefined
				: processFilters;

		let parentElementId = levels.levelStack[currentIndex - 1]!;
		if (parentElementId) {
			return ajaxCatch(() =>
				getProfiBazaApiClient()
					.then((api) =>
						selectableMode && selection
							? api.dictionary
									.getItemsByCodeNameAndProcess(
										selection.codeName,
										selection.interventionPath,
										{
											dateFrom: validFrom
												?.utc(true)
												.toDate()!,
											dateTo: validTo
												?.utc(true)
												.toDate()!,
											parentId: parentElementId!,
											flatDictionary: false,
										}
									)
									.then((response) => response.items!)
							: api.dictionaryValue.getChildrens(
									parentElementId!,
									{
										interventionPaths: interventionPaths,
									}
							  )
					)
					.then((response: DictionaryValueItemDto[]) => {
						setKeyStack(response);
						setLastSelectedValueId(
							levels.levelStack[currentIndex]!
						);
						levels.cacheValues(
							levels.levelStack[currentIndex - 1]!,
							response
						);
						setLoading(false);
					})
			);
		} else {
			return ajaxCatch(() =>
				getProfiBazaApiClient()
					.then((api) =>
						selectableMode && selection
							? api.dictionary
									.getItemsByCodeNameAndProcess(
										selection.codeName,
										selection.interventionPath,
										{
											dateFrom: validFrom
												?.utc(true)
												.toDate()!,
											dateTo: validTo
												?.utc(true)
												.toDate()!,
											parentId: parentElementId!,
											flatDictionary: false,
										}
									)
									.then((response) => response.items!)
							: api.dictionaryVersion.getItems(
									valueModalProps?.dictionaryVersionId!,
									{
										interventionPaths: interventionPaths,
									}
							  )
					)
					.then((response: DictionaryValueItemDto[]) => {
						setKeyStack(response);
						setLastSelectedValueId(
							levels.levelStack[currentIndex]!
						);
						levels.cacheValues(
							levels.levelStack[currentIndex - 1]!,
							response
						);
						setLoading(false);
					})
			);
		}
	};

	const getChildren = (
		id: number,
		isUpdate: boolean = false
	): Promise<void> => {
		if (levels.hasCachedValues(id)) {
			const cachedValues = levels.getCachedValues(id);
			return updateValues(id, cachedValues, isUpdate);
		} else {
			setLoading(true);
			const interventionPaths =
				processFilters?.length === procesTypesTotal
					? undefined
					: processFilters;
			return ajaxCatch(() =>
				getProfiBazaApiClient()
					.then((api) =>
						selectableMode && selection
							? api.dictionary
									.getItemsByCodeNameAndProcess(
										selection.codeName,
										selection.interventionPath,
										{
											dateFrom: validFrom
												?.utc(true)
												.toDate()!,
											dateTo: validTo
												?.utc(true)
												.toDate()!,
											parentId: id,
											flatDictionary: false,
										}
									)
									.then((response) => response.items!)
							: api.dictionaryValue.getChildrens(id, {
									interventionPaths: interventionPaths,
							  })
					)
					.then((response: DictionaryValueItemDto[]) => {
						levels.cacheValues(id, response);
						setLoading(false);
						updateValues(id, response, isUpdate);
						if (
							isUpdate &&
							!response.length &&
							rootValue !== undefined
						) {
							handleDeletionOfLastValue();
						}
					})
			);
		}
	};

	const dictionaryState: MultiDictionaryContextType = {
		values: values,
		setValues: setValues,
		currentLevel: currentLevel,
		setCurrentLevel: setCurrentLevel,
		lastSelectedValueId: lastSelectedValueId,
		setLastSelectedValueId: setLastSelectedValueId,
		levels: levels,
		loading: loading,
		rootValue: rootValue,
		setRootValue: setRootValue,
		getChildren,
		isEditable: isEditable,
		searchPhrase: searchPhrase,
		searchModalVisible: searchModalVisible,
		updateValuePosition: updateValuePosition,
		updateValuesCollection: updateValuesCollection,
		valueModalProps: valueModalProps,
		actionBaseId,
		setActionBaseId,
		detachedValue,
		setDetachedValue,
		detachedLevel,
		setDetachedLevel,
		globalContextMenuVisible,
		setGlobalContextMenuVisible,
		setKeyStack,
		deleteModalRef,
		otherValueHistoryModalRef,
		selectableMode,
		selection,
		handleCancel,
		isDraft,
	};

	const dictionaryFooterState: MultiDictionaryFooterContextType = {
		isEditable,
		values,
		notModal,
		valueModalProps,
		setActionBaseId,
		rootValue,
		restValueModalProps,
		actionBaseId,
		levels,
		updateValuesCollection,
		deleteModalRef,
		refreshBaseItems,
		getChildren,
		otherValueHistoryModalRef,
		handleCancel,
		processFilters,
	};

	const initialize = (data: DictionaryValueItemDto[]) => {
		levels.addLevel(undefined, data);
		levels.cacheValues(undefined, data);
	};

	const loadInitialState = () => {
		const newValues = dictionaryValues ?? [];
		setRootValue(undefined);
		setCurrentLevel(0);
		levels.reset();
		setKeyStack([]);
		setActionBaseId(undefined);
		setLastSelectedValueId(undefined);
		setDetachedValue(undefined);
		setDetachedLevel(undefined);

		setValues(newValues);

		setLevels((prevLevels) => _.cloneDeep(prevLevels));
	};

	useEffect(() => {
		if (!visible && selectableMode) {
			loadInitialState();
		}
	}, [visible, selectableMode]);

	useEffect(() => {
		loadInitialState();
	}, [dictionaryValues]);

	useEffect(() => {
		const newValues = dictionaryValues ?? [];
		initialize(newValues);
	}, [levels]);

	useEffect(() => {
		const rootId = rootValue?.id!;
		levels.reset();
		if (refreshBaseItems) {
			if (!processFilters?.length) {
				refreshBaseItems();
			} else {
				refreshBaseItems(processFilters);
			}
		}
	}, [processFilters]);

	const handleDeletionOfLastValue = () => {
		const previousLevel = levels.getPreviousLevel();
		const updatedLevel = [...previousLevel];
		const foundIndex = updatedLevel.findIndex(
			(val) => val.id === lastSelectedValueId
		);
		updatedLevel[foundIndex].hasChildren = false;
		levels.cacheValues(rootValue?.parentId, updatedLevel);
		switchToLevel(currentLevel > 0 ? currentLevel - 1 : 0);
	};

	const updateValues = (
		id: number | undefined,
		newValues: DictionaryValueItemDto[],
		isUpdate: boolean
	): Promise<any> => {
		if (!isUpdate) {
			levels.addLevel(id, newValues);
		}
		return new Promise(() => {
			props.setParents &&
				props.setParents(
					dictionaryValues?.find(
						(x) => x.id == id
					) as RizpDictionaryValueDto
				);
			setValues(newValues);
		});
	};

	const handleLevelChange = (current: number) => {
		setCurrentLevel(current);
	};

	const switchToLevel = (index: number) => {
		setCurrentLevel(index);

		let data = levels.getLevelValues(index);

		if (levels.getLevelValues(index - 1).length === 0) {
			getHigherLevelElements(index);
		}

		if (data.length === 0) {
			if (index == 0 && refreshBaseItems) {
				refreshBaseItems();
			} else {
				getChildren(levels.levelStack[index]!);
			}
		} else {
			setValues(data);
		}

		levels.switchToLevel(index);
		if (keysStack.length) {
			setRootValue(keysStack[index - 1]);
		}
		setKeyStack((stack) => stack.slice(0, index));
	};

	const switchToLevelAfterSearch = (
		index: number,
		value: DictionaryValueWithPathViewDto
	) => {
		setCurrentLevel(index);
		if (index == 0 && refreshBaseItems) {
			refreshBaseItems();
			setSearchPhrase(value.value!);
		} else {
			getHigherLevelElements(index).then(() => {
				getChildren(levels.levelStack[index]!)?.then(() => {
					setSearchPhrase(value.value!);
				});
				levels.switchToLevel(index - 1);
			});

			if (keysStack.length) {
				setRootValue(keysStack[index - 1]);
			}
		}
	};

	const renderSearchButton = () => {
		const searchButton = (
			<Button
				aria-label="Wyszukaj"
				type="primary"
				shape="circle"
				icon={<SearchOutlined />}
				onClick={() => setSearchModalVisible(true)}
			/>
		);

		return searchPhrase?.length ? (
			<Badge status="warning" dot>
				{searchButton}
			</Badge>
		) : (
			searchButton
		);
	};

	const handleOnSearch = (item: DictionaryValueWithPathViewDto) => {
		levels.reset();
		let ids = item?.pathIds
			?.split(',')
			.map((x) => Number(x))
			.filter((x) => x !== item.id);

		levels.addLevel(undefined, []);

		for (let id of ids!) {
			levels.addLevel(Number(id), []);
		}

		switchToLevelAfterSearch(ids?.length!, item);
	};

	const handleOnClear = () => {
		if (refreshBaseItems) {
			refreshBaseItems();
			setSearchPhrase(undefined);
		}
	};

	const dictionaryBodyState: MultiDictionaryBodyContextType = {
		currentLevel,
		handleLevelChange,
		levels,
		keysStack,
		switchToLevel,
		dictionaryState,
		values,
		selectableMode,
		rootValue,
		renderSearchButton,
		processFilters,
		setProcessFilters,
	};

	return (
		<>
			{notModal ? (
				<>
					<MultiDictionaryBodyContext.Provider
						value={dictionaryBodyState}
					>
						{dictionaryBodyState && <MultiLevelDictionaryBody />}
					</MultiDictionaryBodyContext.Provider>
					<MultiDictionaryFooterContext.Provider
						value={dictionaryFooterState}
					>
						{dictionaryFooterState && (
							<MultiLevelDictionaryFooter />
						)}
					</MultiDictionaryFooterContext.Provider>
				</>
			) : (
				visible && (
					<Modal
						visible={visible}
						centered
						width="90%"
						style={{
							marginTop: '36px',
						}}
						bodyStyle={{
							display: 'flex',
							flexDirection: 'column',
							padding: '24px 16px',
							height: '75vh',
							maxHeight: '80vh',
						}}
						maskClosable
						destroyOnClose
						onCancel={handleCancel}
						footer={
							<MultiDictionaryFooterContext.Provider
								value={dictionaryFooterState}
							>
								{dictionaryFooterState && (
									<MultiLevelDictionaryFooter />
								)}
							</MultiDictionaryFooterContext.Provider>
						}
					>
						<>
							<MultiDictionaryBodyContext.Provider
								value={dictionaryBodyState}
							>
								{dictionaryBodyState && (
									<MultiLevelDictionaryBody />
								)}
							</MultiDictionaryBodyContext.Provider>
						</>
					</Modal>
				)
			)}
			<DictionarySearchBar
				visible={searchModalVisible}
				setVisible={setSearchModalVisible}
				additionalData={{
					dictionaryVersionId: valueModalProps?.dictionaryVersionId,
					codeName: selection?.codeName,
					interventionPath: selection?.interventionPath,
					dateFrom: validFrom?.utc(true).toDate()!,
					dateTo: validTo?.utc(true).toDate()!,
				}}
				onSearch={handleOnSearch}
				onClear={handleOnClear}
			/>
		</>
	);
};

export default observer(MultiLevelDictionary);
