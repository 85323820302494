import { Button, Col, DatePicker, Row, Space } from 'antd';
import FormRunner from 'components/orbeon/FormRunner';
import { Field, Formik, FormikProps } from 'formik';
import { FFieldLabel, FInput } from 'forms/FormikFormItems';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import { DateFormat } from 'helper/formatHelpers';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import PrimaryCard from 'layout/PrimaryCard';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as StatementArea from 'services/mappers/StatementArea';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { IGridStore } from 'stores/GridStore';

import { StatementDetailsModel } from '../../statements/model/StatementDetailsModel';

interface IProps {
	statementId: string;
	editable: boolean;
}

const StatementTestDetails: React.FC<IProps> = (props) => {
	const [statementDetails, setStatementDetails] = useState<
		StatementDetailsModel
	>();
	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const [showGeneratePdfButton, setShowGeneratePdfButton] = useState<boolean>(
		false
	);

	useEffect(() => {
		const abortController = new AbortController();
		refresh(abortController.signal);

		return () => {
			abortController.abort();
		};
	}, []);

	const refresh = (abortSignal?: AbortSignal) => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.statementsTest.getById(props.statementId, {
						abortSignal,
					})
				)
				.then((result) => {
					setStatementDetails(new StatementDetailsModel(result));
				})
		);
	};

	return (
		<>
			<CenteredRow>
				<Col span={18}>
					<PageHeaderWithContent
						title={
							'Sprawozdanie ' +
							StatementArea.map(
								statementDetails?.statementArea!
							) +
							' ' +
							statementDetails?.year
						}
						right={
							<Space>
								<Link to="/forms/test">
									<Button aria-label="Wróć" size="large">
										Wróć
									</Button>
								</Link>
							</Space>
						}
						wideTitle={true}
					/>

					{statementDetails && (
						<>
							{statementDetails.hasPreview && (
								<Row className="float-right">
									<Col>
										<Space>
											{showGeneratePdfButton && (
												<Button
													type="primary"
													size="large"
													onClick={() => {
														ajaxByUser(
															'Pobrano',
															() =>
																getProfiBazaApiClient()
																	.then(
																		(api) =>
																			api.statements.getStatementSummaryPdfById(
																				props.statementId
																			)
																	)
																	.then(
																		(
																			result
																		) => {
																			var blob = new Blob(
																				[
																					result.body,
																				],
																				{
																					type:
																						'application/pdf',
																				}
																			);
																			const obj_url = URL.createObjectURL(
																				blob
																			);
																			window.open(
																				obj_url,
																				'_blank'
																			);
																		}
																	)
														);
													}}
												>
													Podgląd
												</Button>
											)}
											<Link to="/forms/test">
												<Button
													aria-label="Wróć"
													size="large"
													onClick={() =>
														gridStore.searching.set(
															false
														)
													}
												>
													Wróć
												</Button>
											</Link>
										</Space>
									</Col>
								</Row>
							)}

							<FormRunner
								mode={props.editable ? 'edit' : 'preview'}
								formVersionId={statementDetails?.formVersionId!}
								documents={statementDetails.sections!.map(
									(x) => ({
										documentId: x.documentId!,
										formPartId: x.formPartId!,
									})
								)}
								pathBase={
									!props.editable
										? `/forms/test/details/${props.statementId}`
										: `/forms/test/edit/${props.statementId}`
								}
								query={{ statementId: props.statementId }}
								onFormAdded={refresh}
								onFormPartLoaded={setShowGeneratePdfButton}
							></FormRunner>

							<Formik
								initialValues={statementDetails}
								enableReinitialize
								validateOnChange={false}
								validateOnBlur={true}
								onSubmit={(values, actions) => {}}
								render={(
									props: FormikProps<StatementDetailsModel>
								) => (
									<PrimaryCard>
										<CenteredRow>
											<Col md={6}>
												<FFieldLabel
													label="Data utworzenia"
													for="createdAt"
												/>
												<DatePicker
													aria-label="Data utworzenia"
													id="createdAt"
													name="createdAt"
													value={
														props.values.createdAt
													}
													format={DateFormat.Date}
													style={{ width: '100%' }}
													disabled
												/>
											</Col>
											<Col md={6}>
												<FFieldLabel
													label="Data aktualizacji"
													for="updatedAt"
												/>
												<DatePicker
													aria-label="Data aktualizacji"
													placeholder="Brak daty aktualizacji"
													id="updatedAt"
													name="updatedAt"
													value={
														props.values.updatedAt
													}
													format={DateFormat.Date}
													style={{ width: '100%' }}
													showTime
													disabled
												/>
											</Col>
											<Col md={4}>
												<FFieldLabel
													label="Autor"
													for="statementModificationAuthorFullName"
												/>
												<Field
													component={FInput}
													id="statementModificationAuthorFullName"
													name="statementModificationAuthorFullName"
													readOnly
												/>
											</Col>
											<Col md={4}>
												<FFieldLabel
													label="Rodzaj zdarzenia"
													for="statementEventType"
												/>
												<Field
													component={FInput}
													id="statementEventType"
													name="statementEventType"
													readOnly
													validateOnChange
												/>
											</Col>
											<Col md={4}>
												<FFieldLabel
													label="Kanał utworzenia"
													for="creationChannel"
												/>
												<Field
													label="Kanał utworzenia"
													component={FInput}
													id="creationChannel"
													name="creationChannel"
													readOnly
												/>
											</Col>
										</CenteredRow>
									</PrimaryCard>
								)}
							/>
						</>
					)}
					<Row className="float-right">
						<Space>
							<Link to="/forms/test">
								<Button
									aria-label="Wróć"
									size="large"
									onClick={() =>
										gridStore.searching.set(false)
									}
								>
									Wróć
								</Button>
							</Link>
						</Space>
					</Row>
				</Col>
			</CenteredRow>
		</>
	);
};

export default StatementTestDetails;
