import { LinkOutlined } from '@ant-design/icons';
import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import { DictionariesConstants } from '@services/DictionariesConstants';
import * as OpinionAotmitTypeMap from '@services/mappers/OpinionAOTMiTType';
import { Button, Col, Row, Select, Space, Tag, Typography } from 'antd';
import DictionaryPicker from 'components/dictionary/picker/DictionaryPicker';
import SubjectsTableModal from 'components/shared/SubjectsTableModal';
import TerytAreaPicker from 'components/shared/teryt/TerytAreaPicker';
import { AreaCodeNames } from 'components/shared/teryt/TerytAreaPicker/TeryAreaMappers';
import { Field, FieldArray } from 'formik';
import {
	FDatePicker,
	FFieldLabel,
	FFieldValue,
	FInput,
	FInputNumber,
	FRangePicker,
	FSelect,
	FSelectSubjects,
	FTextArea,
} from 'forms/FormikFormItems';
import { ajaxCatch } from 'helper/api';
import formatHelpers from 'helper/formatHelpers';
import PrimaryCard from 'layout/PrimaryCard';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import * as InterventionPathMapper from 'services/mappers/InterventionPath';
import * as InterventionStatusMap from 'services/mappers/InterventionStatus';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	DictionaryInterventionPath,
	OpinionAOTMiTEnum,
	OpinionAOTMiTType,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { interventionRealizationSubjectColumns } from '../../subjects/SubjectTableColumns';
import { InterventionCommentArea } from '../comments/InterventionCommentArea';
import { InterventionCommentIcon } from '../comments/InterventionCommentIcon';
import { InterventionPathContextType } from '../context/InterventionPathContext';
import InterventionDetails from '../InterventionDetails';
import { ChangedVersionDictionaryDto } from '../model/InterventionBaseCommand';
import { InterventionCommand } from '../model/InterventionCommand';
import AnnualNumberOfRecipients from './annualNumberOfRecipients/AnnualNumberOfRecipientsModal';
import LegislationInfoFieldGroup from './fieldGroups/LegislationInfoFieldGroup';
import OtherInfoFieldGroup from './fieldGroups/OtherInfoFieldGroup';
import ResearchInfoFieldGroup from './fieldGroups/ResearchInfoFieldGroup';
import StaffEducationInfoFieldGroup from './fieldGroups/StaffEducationInfoFieldGroup';
import StaffMonitoringInfoFieldGroup from './fieldGroups/StaffMonitoringInfoFieldGroup';
import SupervisionMonitoringInfoFieldGroup from './fieldGroups/SupervisionMonitoringInfoFieldGroup';
import SupervisionSanitaryInfoFieldGroup from './fieldGroups/SupervisionSanitaryInfoFieldGroup';

const { Text } = Typography;

const InterventionIsAotmitOpinion = (context: InterventionPathContextType) => {
	return (
		<>
			<Col span={6}>
				<FFieldLabel label="Numer opinii Prezesa" for="aotmitNumber" />
				<InterventionCommentIcon fieldName="aotmitNumber" />
				<InterventionCommentArea fieldName="aotmitNumber">
					<Field component={FInput} name="aotmitNumber" readOnly />
				</InterventionCommentArea>
			</Col>
			<Col xs={12} md={8} lg={6} xl={5}>
				<Button
					href={context.formik?.values.linkToWizard}
					block
					target="_blank"
					style={{
						marginTop: '30px',
					}}
					disabled={context.formik?.values.linkToWizard == null}
					icon={<LinkOutlined />}
				>
					Przejdź do Kreatora
				</Button>
			</Col>
			<Col span={4}>
				{context.formik?.values.aotmitStatus && (
					<>
						<FFieldLabel label="Status AOTMiT" for="aotmitStatus" />
						<Field
							component={FInput}
							name="aotmitStatus"
							readOnly
						/>
					</>
				)}
			</Col>
			{context.formik?.values.opinionAOTMiT && (
				<>
					<Col span={4}>
						<FFieldLabel
							label="Decyzja AOTMiT"
							for="opinionAOTMiTText"
						/>
						<Field
							component={FInput}
							name="opinionAOTMiTText"
							readOnly
						/>
					</Col>
					<Col span={6}>
						<FFieldLabel
							label="Data wydania opinii"
							for="dateOfDecisionAOTMiT"
						/>
						<Field
							component={FDatePicker}
							style={{
								width: '100%',
							}}
							name="dateOfDecisionAOTMiT"
							disabled={true}
							inputReadOnly
						/>
					</Col>
				</>
			)}
		</>
	);
};

const InterventionIsAotmitReported = (context: InterventionPathContextType) => {
	return (
		<>
			<Col span={6}>
				<FFieldLabel
					label="Numer opinii Prezesa"
					for="presidentAotmitNumber"
				/>
				<LabelHelpTooltip
					field="presidentAotmitNumber"
					defaultValue="Numer opinii Prezesa"
				/>
				<InterventionCommentIcon fieldName="presidentAotmitNumber" />
				<InterventionCommentArea fieldName="presidentAotmitNumber">
					<Field component={FInput} name="presidentAotmitNumber" />
				</InterventionCommentArea>
			</Col>
			<Col span={4}>
				<FFieldLabel label="Opinia Prezesa" for="opinionAOTMiT" />
				<LabelHelpTooltip
					field="opinionAOTMiT"
					defaultValue="Opinia Prezesa"
				/>
				<InterventionCommentIcon fieldName="opinionAOTMiT" />
				<InterventionCommentArea fieldName="opinionAOTMiT">
					<Field
						component={FSelect}
						name="opinionAOTMiT"
						children={Object.values(OpinionAOTMiTType)
							.filter(
								(x) =>
									x != OpinionAOTMiTType.NEGATIVE &&
									x != OpinionAOTMiTType.FINALREPORT &&
									x != OpinionAOTMiTType.NOTSUBJECTTOOPINION
							)
							.map((k, v) => (
								<Select.Option key={k} value={k}>
									{OpinionAotmitTypeMap.map(k)}
								</Select.Option>
							))}
					/>
				</InterventionCommentArea>
			</Col>
			<Col span={6}>
				<FFieldLabel
					label="Data wydania opinii"
					for="dateOfDecisionAOTMiT"
				/>
				<LabelHelpTooltip
					field="dateOfDecisionAOTMiT"
					defaultValue="Data wydania opinii"
				/>
				<InterventionCommentIcon fieldName="dateOfDecisionAOTMiT" />
				<InterventionCommentArea fieldName="dateOfDecisionAOTMiT">
					<Field
						component={FDatePicker}
						style={{
							width: '100%',
						}}
						name="dateOfDecisionAOTMiT"
					/>
				</InterventionCommentArea>
			</Col>
		</>
	);
};

export const InterventionIsAotmit = (context: InterventionPathContextType) => {
	return (
		<>
			{context.isAotmit &&
				context.data?.interventionTypeDictionaryCode ===
					DictionariesConstants.InterventionType.AotmitOpinion &&
				InterventionIsAotmitOpinion(context)}
			{context.isAotmit &&
				context.data?.interventionTypeDictionaryCode ===
					DictionariesConstants.InterventionType.AotmitReported &&
				InterventionIsAotmitReported(context)}
		</>
	);
};

const GetFieldGroupByInterventionPath = (
	context: InterventionPathContextType
) => {
	const dictionaryInterventionPath = (context.data
		?.interventionPath as string) as DictionaryInterventionPath;

	switch (dictionaryInterventionPath) {
		case DictionaryInterventionPath.STAFFEDUCATION:
			return <StaffEducationInfoFieldGroup {...context} />;
		case DictionaryInterventionPath.STAFFMONITORING:
			return <StaffMonitoringInfoFieldGroup {...context} />;
		case DictionaryInterventionPath.SUPERVISIONMONITORING:
			return <SupervisionMonitoringInfoFieldGroup {...context} />;
		case DictionaryInterventionPath.SUPERVISIONSANITARY:
			return <SupervisionSanitaryInfoFieldGroup {...context} />;
		case DictionaryInterventionPath.LEGISLATION:
			return <LegislationInfoFieldGroup {...context} />;
		case DictionaryInterventionPath.RESEARCH:
			return <ResearchInfoFieldGroup {...context} />;
		default:
			return <OtherInfoFieldGroup {...context} />;
	}
};

export const InterventionInfo = (context: InterventionPathContextType) => {
	return (
		<Col span={24}>
			<PrimaryCard>
				<Row style={{ padding: '0 15px' }}>
					{GetFieldGroupByInterventionPath(context)}
				</Row>
			</PrimaryCard>
		</Col>
	);
};

export const InterventionType = (context: InterventionPathContextType) => {
	const [value, setValue] = useState<string | undefined>('');
	useEffect(() => {
		if (context.formik?.values.interventionTypeDictionaryValueId!) {
			ajaxCatch(() =>
				getProfiBazaApiClient().then((api) =>
					api.dictionaryValue
						.getById(
							context.formik?.values
								.interventionTypeDictionaryValueId!
						)
						.then(
							(
								response: ProfiBazaAPIModels.DictionaryValueItemDto
							) => {
								setValue(
									context.formik?.values
										?.interventionTypeDictionaryOtherValue
										? `${response.value}: ${context.formik?.values.interventionTypeDictionaryOtherValue}`
										: response.value
								);
								if (context.data) {
									context.data.interventionTypeDictionaryCode =
										response.codeName;
								}
							}
						)
				)
			);
		} else setValue(undefined);
	}, [context.formik?.values.interventionTypeDictionaryValueId]);

	return (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<Text strong>Typ interwencji</Text>
				<LabelHelpTooltip
					field="interventionTypeLabel"
					defaultValue="Typ interwencji"
				/>
				<InterventionCommentIcon fieldName="interventionType" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="interventionType">
					<FFieldValue
						label={value}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	);
};

export const InterventionKind = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	const dictionaryInterventionPath = (context.data
		?.interventionPath as string) as DictionaryInterventionPath;

	return (
		context.data?.interventionPath !==
			ProfiBazaAPIModels.InterventionPath.PROHEALTHACTIVITIES && (
			<Col span={colSpan}>
				<DictionaryPicker
					label={
						dictionaryInterventionPath ==
						DictionaryInterventionPath.RESEARCH
							? 'Rodzaj projektu'
							: 'Rodzaj interwencji'
					}
					commentField={
						dictionaryInterventionPath ==
						DictionaryInterventionPath.RESEARCH
							? 'projectKind'
							: 'interventionKind'
					}
					helpField={
						dictionaryInterventionPath ==
						DictionaryInterventionPath.RESEARCH
							? 'interventionKindDictionaryValues'
							: undefined
					}
					interventionPath={dictionaryInterventionPath}
					multiple
					disabled={
						!context.editable ||
						context.checkIfObjectValue?.(
							context.pattern,
							'interventionKindDictionaryValues'
						)
					}
					readOnly={!context.editable}
					name="interventionKindDictionaryValues"
					codeName="RODZAJ_INTERWENCJI"
					valueId="id"
					otherValue="otherValue"
					checkChanges={(key: any, value: any, parent: any) =>
						context.checkChanges?.(
							value,
							'interventionKindDictionaryValues'
						)
					}
					dateRange={getDateRange(context)}
					changedVersion={
						context.editable &&
						context.unchangedData
							?.interventionKindDictionaryValues &&
						_.differenceWith(
							context.unchangedData
								?.interventionKindDictionaryValues!,
							context.data?.interventionKindDictionaryValues!,
							_.isEqual
						)?.length > 0
							? new ChangedVersionDictionaryDto(
									context.data?.interventionKindDictionaryValues?.map(
										(x) => x.id!
									)!,
									context.unchangedData?.interventionKindDictionaryValues?.map(
										(x) => x.id!
									)!,
									context.unchangedData?.interventionKindDictionaryValues?.map(
										(x) => x.otherValue!
									)!
							  )
							: undefined
					}
				/>
			</Col>
		)
	);
};

export const InterventionPath = (context: InterventionPathContextType) => {
	return (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<Text strong>Ścieżka interwencji</Text>
				<LabelHelpTooltip
					field="interventionPathLabel"
					defaultValue="Ścieżka interwencji"
				/>
				<InterventionCommentIcon fieldName="interventionPath" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="interventionPath">
					<FFieldValue
						label={InterventionPathMapper.map(
							context.formik?.values.interventionPath!
						)}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	);
};

export const InterventionStatus = (context: InterventionPathContextType) => {
	return ((context.data
		?.interventionPath as string) as DictionaryInterventionPath) ==
		DictionaryInterventionPath.PROHEALTHACTIVITIES || context.isPattern ? (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<Text strong>Status</Text>
				<LabelHelpTooltip
					field="interventionStatusLabel"
					defaultValue="Status"
				/>
				<InterventionCommentIcon fieldName="interventionStatus" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="interventionStatus">
					<FFieldValue
						label={
							<Tag>
								{InterventionStatusMap.map(
									context.formik?.values.status!
								)}
							</Tag>
						}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	) : (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<FFieldLabel label="Status" />
				<LabelHelpTooltip
					field="interventionStatusLabel"
					defaultValue="Status"
				/>
				<InterventionCommentIcon fieldName="interventionStatus" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="interventionStatus">
					<FFieldValue
						label={
							<Tag>
								{InterventionStatusMap.map(
									context.formik?.values.status!
								)}
							</Tag>
						}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	);
};

export const InterventionNumber = (context: InterventionPathContextType) => {
	return (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<Text strong>Numer</Text>
				<LabelHelpTooltip
					field="interventionNumberLabel"
					defaultValue="Numer"
				/>
				<InterventionCommentIcon fieldName="interventionNumber" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="interventionNumber">
					<FFieldValue
						label={context.formik?.values.codeName}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	);
};

export const InterventionPattern = (context: InterventionPathContextType) => {
	return (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<Text strong>Rekomendacja AOTMiT</Text>
				<LabelHelpTooltip
					field="recommendationLabel"
					defaultValue="Rekomendacja AOTMiT"
				/>
				<InterventionCommentIcon fieldName="recommendation" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="recommendation">
					<FFieldValue
						label={
							context.isPattern
								? context.editable
									? context.formik?.values
											.recommendationDocumentName
									: context.data?.recommendationDocumentName
								: context.pattern?.recommendationDocumentName
						}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	);
};

const constructChangedVersion = (
	context: InterventionPathContextType,
	originalValueId: number | undefined,
	changedValueId: number | undefined
) => {
	if (context.editable) {
		if (originalValueId != changedValueId) {
			const change = new ChangedVersionDictionaryDto(
				originalValueId!,
				changedValueId!
			);

			return change;
		} else {
			return undefined;
		}
	}
};

export const InterventionAreaDictionary = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col span={colSpan}>
			<DictionaryPicker
				dateRange={getDateRange(context)}
				label="Zasięg interwencji"
				commentField="interventionArea"
				helpField="interventionAreaDictionaryValue"
				readOnly={!context.editable}
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.pattern,
						'interventionAreaDictionaryValueId'
					)
				}
				codeName="ZASIEG_INTERWENCJI"
				otherValue="interventionAreaDictionaryOtherValue"
				name="interventionAreaDictionaryValueId"
				interventionPath={
					(context.data
						?.interventionPath! as string) as DictionaryInterventionPath
				}
				changedVersion={constructChangedVersion(
					context,
					context.unchangedData?.interventionAreaDictionaryValueId,
					context.data?.interventionAreaDictionaryValueId
				)}
				checkChanges={(key: any, value: any, parent: any) =>
					context.checkChanges?.(
						value,
						'interventionAreaDictionaryValueId'
					)
				}
				onChangeCallback={(item) => {
					const codeName: AreaCodeNames = item.codeName as AreaCodeNames;
					context.setValidTerytAreaLvl?.(codeName);
				}}
				onChangeEnd={() => {
					context.formik?.setFieldTouched('areaTerytCodes', true);
				}}
				filter={
					context.isPattern && context.editable
						? context.filterInterventionAreaForPattern
						: undefined
				}
			/>
		</Col>
	);
};

export const getDateRange = (
	context: InterventionPathContextType
): [(Moment | undefined)?, (Moment | undefined)?] | Moment[] => {
	if (context.editable) {
		return [
			context.data?.plannedDateRange?.[0],
			context.data?.plannedDateRange?.[1],
		];
	} else {
		if (context.data?.modifiedAt) {
			return [context.data?.modifiedAt, context.data?.modifiedAt];
		} else {
			return [context.data?.createdAt, context.data?.createdAt];
		}
	}
};

export const InterventionLegalBases = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col span={colSpan}>
			<DictionaryPicker
				dateRange={getDateRange(context)}
				label="Podstawa prawna"
				commentField="legalBases"
				helpField="legalBases"
				multiple={true}
				readOnly={!context.editable}
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(context.pattern, 'legalBases')
				}
				codeName="PODSTAWA_PRAWNA"
				name="legalBases"
				valueId="id"
				otherValue="otherValue"
				checkChanges={(key: any, value: any, parent: any) =>
					context.checkChanges?.(value, 'legalBases')
				}
				changedVersion={
					context.editable &&
					context.unchangedData?.legalBases &&
					context.data?.legalBases?.length! > 0 &&
					_.differenceWith(
						context.unchangedData?.legalBases,
						context.data?.legalBases!,
						_.isEqual
					)?.length > 0
						? new ChangedVersionDictionaryDto(
								context.unchangedData?.legalBases?.map(
									(x) => x.id!
								)!,
								context.data?.legalBases?.map((x) => x.id!)!
						  )
						: undefined
				}
				interventionPath={
					(context.data
						?.interventionPath! as string) as DictionaryInterventionPath
				}
			/>
		</Col>
	);
};

export const InterventionOwner = (context: InterventionPathContextType) => {
	return (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<Text strong>Właściciel</Text>
				<LabelHelpTooltip
					field="ownerLabel"
					defaultValue="Właściciel"
				/>
				<InterventionCommentIcon fieldName="interventionOwner" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="interventionOwner">
					<FFieldValue
						label={context.data?.subjectName!}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	);
};

export const InterventionName = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col span={colSpan}>
			<Space>
				<SkipLinkContent />
				<FFieldLabel label="Nazwa interwencji" for="name" />
				<InterventionCommentIcon fieldName="name" />
			</Space>
			<InterventionCommentArea fieldName="name">
				<Field
					component={FTextArea}
					label="Nazwa interwencji"
					name="name"
					changeData={(value: any) =>
						context.checkChanges?.(value, 'name')
					}
					readOnly={
						!context.editable ||
						context.checkIfObjectValue?.(context.pattern, 'name')
					}
					rows={1}
				/>
			</InterventionCommentArea>
		</Col>
	);
};

export const InterventionCreatedAt = (context: InterventionPathContextType) => {
	return ((context.data
		?.interventionPath as string) as DictionaryInterventionPath) ==
		DictionaryInterventionPath.PROHEALTHACTIVITIES ? (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<Text strong>Data utworzenia</Text>
				<LabelHelpTooltip
					field="creationDateLabel"
					defaultValue="Data utworzenia"
				/>
				<InterventionCommentIcon fieldName="creationDate" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="creationDate">
					<FFieldValue
						label={formatHelpers.formatDate(
							context.formik?.values.createdAt?.toDate()
						)}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	) : (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<FFieldLabel label="Data utworzenia" />
				<LabelHelpTooltip
					field="creationDateLabel"
					defaultValue="Data utworzenia"
				/>
				<InterventionCommentIcon fieldName="creationDate" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="creationDate">
					<FFieldValue
						label={formatHelpers.formatDate(
							context.formik?.values.createdAt?.toDate()
						)}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	);
};

export const InterventionPlannedDate = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col span={colSpan}>
			<Space>
				<FFieldLabel label="Data planowana" for="plannedDateRange" />
				<LabelHelpTooltip
					field="plannedDateRange"
					defaultValue="Data planowana"
				/>
				<InterventionCommentIcon fieldName="plannedDateRange" />
			</Space>
			<InterventionCommentArea fieldName="plannedDateRange">
				<Field
					component={FRangePicker}
					style={{
						width: '100%',
					}}
					inputReadOnly={
						!context.editable ||
						context.checkIfDateRangeValue?.(
							context.pattern,
							'plannedDateRange'
						)
					}
					placeholder={[
						'Data początkowa planowana',
						'Data końcowa planowana',
					]}
					name="plannedDateRange"
					changeData={(
						value: [Moment | undefined, Moment | undefined]
					) => {
						context.checkChanges?.(value, 'plannedDateRange');
						context.setDateRange?.(value);
					}}
				/>
			</InterventionCommentArea>
		</Col>
	);
};

export const InterventionPlannedBudget = (
	context: InterventionPathContextType,
	colSpan?: number
) => {
	return ((context.data
		?.interventionPath as string) as DictionaryInterventionPath) ==
		DictionaryInterventionPath.RESEARCH ? (
		<Col span={colSpan}>
			<FFieldLabel label="Budżet planowany" for="plannedBudget" />
			<LabelHelpTooltip
				field="plannedBudget"
				defaultValue="Budżet planowany"
			/>
			<InterventionCommentIcon fieldName="plannedBudget" />
			<InterventionCommentArea fieldName="plannedBudget">
				<Field
					component={FInputNumber}
					style={{
						width: '100%',
					}}
					label="Budżet planowany"
					disabled={!context.editable}
					name="plannedBudget"
					min={0}
					changeData={(value: any) =>
						context.checkChanges?.(value, 'plannedBudget')
					}
				/>
			</InterventionCommentArea>
		</Col>
	) : (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<Text strong>Budżet planowany</Text>
				<LabelHelpTooltip
					field="plannedBudget"
					defaultValue="Budżet planowany"
				/>
				<InterventionCommentIcon fieldName="plannedBudgetInfo" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="plannedBudgetInfo">
					<FFieldValue
						label={context.formik?.values.plannedBudget}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	);
};
export const InterventionNumberOfRecipients = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<>
			<Col md={colSpan}>
				<Space size={2}>
					<FFieldLabel
						label="Liczba odbiorców"
						for="numberOfRecipients"
					/>
					<LabelHelpTooltip
						field="numberOfRecipients"
						defaultValue="Liczba odbiorców"
					/>
					<InterventionCommentIcon fieldName="numberOfRecipients" />
				</Space>
				<InterventionCommentArea fieldName="numberOfRecipients">
					<Field
						label="Liczba odbiorców"
						name="numberOfRecipients"
						component={FInputNumber}
						changeData={(value: any) =>
							context.checkChanges?.(value, 'numberOfRecipients')
						}
						precision={0}
						readOnly={!context.editable}
					/>
				</InterventionCommentArea>
			</Col>
		</>
	);
};

export const InterventionAnnualNumberOfRecipients = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<>
			<Col md={colSpan}>
				<Space size={2}>
					<FFieldLabel
						label="Liczba odbiorców w latach"
						for="annualNumberOfRecipients"
					/>
					<LabelHelpTooltip
						field="annualNumberOfRecipients"
						defaultValue="Liczba odbiorców w latach"
					/>
					<InterventionCommentIcon fieldName="annualNumberOfRecipients" />
				</Space>
				<InterventionCommentArea fieldName="numberOfRecipients">
					<Field
						name="annualNumberOfRecipients"
						component={AnnualNumberOfRecipients}
						implementationPeriodDictionaryValueId={
							context.formik?.values
								.implementationPeriodDictionaryValueId
						}
						readOnly={!context.editable}
					/>
				</InterventionCommentArea>
			</Col>
		</>
	);
};

export const InterventionImplementationPeriod = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col span={colSpan}>
			<DictionaryPicker
				label="Typ okresu realizacji"
				commentField="implementationPeriod"
				helpField="implementationPeriodDictionaryValue"
				dateRange={getDateRange(context)}
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.pattern,
						'implementationPeriodDictionaryValueId'
					)
				}
				readOnly={!context.editable}
				codeName="TYP_OKRESU_REALIZACJI"
				otherValue="implementationPeriodDictionaryOtherValue"
				name="implementationPeriodDictionaryValueId"
				checkChanges={(key: any, value: any, parent: any) =>
					context.checkChanges?.(
						value,
						'implementationPeriodDictionaryValueId'
					)
				}
				interventionPath={
					(context.data
						?.interventionPath! as string) as DictionaryInterventionPath
				}
				changedVersion={constructChangedVersion(
					context,
					context.unchangedData
						?.implementationPeriodDictionaryValueId,
					context.data?.implementationPeriodDictionaryValueId
				)}
			/>
		</Col>
	);
};

export const InterventionRealizationDate = (
	context: InterventionPathContextType
) => {
	return (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<Text strong>Data realizacji (od-do)</Text>
				<LabelHelpTooltip
					field="realizationDateLabel"
					defaultValue="Data realizacji (od-do):"
				/>
				<InterventionCommentIcon fieldName="realizationDate" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="realizationDate">
					<FFieldValue
						label={`${
							context.formik?.values.realizationDate &&
							context.formik?.values.realizationDate[0]
								? formatHelpers.formatDate(
										context.formik?.values.realizationDate[0]!.toDate()
								  ) +
								  ' - ' +
								  formatHelpers.formatDate(
										context.formik?.values.realizationDate[1]!.toDate()
								  )
								: 'Brak'
						}`}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	);
};

export const InterventionRealizationBudget = (
	context: InterventionPathContextType
) => {
	return (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<Text strong>Budżet realizacji</Text>
				<LabelHelpTooltip
					field="realizationBudgetLabel"
					defaultValue="Budżet realizacji"
				/>
				<InterventionCommentIcon fieldName="realizationBudget" />
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<InterventionCommentArea fieldName="realizationBudget">
					<FFieldValue
						label={context.formik?.values.realizationBudget}
						className="intervention-info-value"
					/>
				</InterventionCommentArea>
			</Col>
		</Row>
	);
};

export const InterventionNumberOfPersonAction = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<>
			<Col md={colSpan}>
				<Space size={2}>
					<FFieldLabel
						label="Liczba osobodziałań"
						for="numberOfPersonAction"
					/>
					<LabelHelpTooltip
						field="numberOfPersonAction"
						defaultValue="Liczba osobodziałań"
					/>
					<InterventionCommentIcon fieldName="numberOfPersonAction" />
				</Space>
				<InterventionCommentArea fieldName="numberOfPersonAction">
					<Field
						label="Liczba osobodziałań"
						name="numberOfPersonAction"
						component={FInputNumber}
						changeData={(value: any) =>
							context.checkChanges?.(
								value,
								'numberOfPersonAction'
							)
						}
						precision={0}
						readOnly={!context.editable}
					/>
				</InterventionCommentArea>
			</Col>
		</>
	);
};

export const InterventionTerytArea = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col md={colSpan}>
			<TerytAreaPicker<InterventionCommand>
				label="Teren interwencji"
				helpField="areaTerytCodes"
				commentField="areaTerytCodes"
				name="areaTerytCodes"
				editable={
					context.editable! &&
					!context.checkIfObjectValue?.(
						context.pattern,
						'areaTerytCodes'
					)
				}
				initialValues={context.formik?.values?.areas!}
				values={context.formik?.values.areas!}
				checkChanges={(v) => {
					context.checkChanges?.(v, 'areas');
					context.onChangeAreaTerytCodes?.(true);
				}}
				setValues={(v) => {
					context.formik?.setFieldValue('areas', v);
				}}
				formikActions={context.formik}
				hasNationwide
				validTerytAreaLvl={context.validTerytAreaLvl}
			/>
		</Col>
	);
};

export const InterventionCoowners = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	const handleOpenCoowners = (
		e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
	) => {
		if (
			context.editable &&
			e.currentTarget.classList.contains('ant-select-open')
		)
			context.setCoownersVisible?.(true);
		if (!e.currentTarget.classList.contains('ant-select-open')) {
			context.formik?.setFieldValue('interventionCoowners', []);
			context.setCoownerNames?.([]);
		}
	};

	return (
		<>
			<Col md={colSpan}>
				<Space>
					<FFieldLabel label="Współwłaściciele" />
					<LabelHelpTooltip
						field="interventionCoowners"
						defaultValue="Współwłaściciele"
					/>
					<InterventionCommentIcon fieldName="coOwners" />
				</Space>
				<InterventionCommentArea fieldName="coOwners">
					<Field
						label="Współwłaściciele"
						mode="multiple"
						component={FSelectSubjects}
						name="interventionCoownerNames"
						onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
							handleOpenCoowners(e);
						}}
						onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
							if (e.key === 'Enter') {
								handleOpenCoowners(e);
							}
						}}
						tagRender={true}
						onClose={(val: string) => {
							context.formik?.setFieldTouched(
								'interventionCoownerNames',
								true
							);
							const index = context.formik?.values.interventionCoowners!.findIndex(
								(x) => x.name == val
							)!;
							if (index > -1) {
								context.formik?.values.interventionCoowners!.splice(
									index,
									1
								);
								context.formik?.setFieldValue(
									'interventionCoowners',
									context.formik?.values.interventionCoowners!
								);
								context.checkChanges?.(
									context.formik?.values.interventionCoowners,
									'interventionCoowners'
								);
							}
						}}
						dropDown={true}
						array={context.coownerNames}
						editable={context.editable}
						readOnly={!context.editable}
					/>
				</InterventionCommentArea>
			</Col>
			<SubjectsTableModal
				mode="checkbox"
				visible={context.coownersVisible}
				onCancel={() => {
					context.setCoownersVisible?.(false);
				}}
				onlyActiveSubjects={true}
				columns={interventionRealizationSubjectColumns}
				selectedRows={context.formik?.values.interventionCoowners!}
				acceptList={(value) => {
					context.setCoownerNames?.(value.map((x) => x.name!));

					context.formik?.setFieldValue(
						'interventionCoowners',
						value.map((x) => {
							return {
								id: x?.id,
								name: x?.name,
							};
						})
					);
					context.setCoownersVisible?.(false);
				}}
				showImplementerSponsorFilter={false}
				clearSelectionOnSearching={false}
			/>
		</>
	);
};

export const InterventionActivity = () => {
	return <FieldArray name="activity" />;
};

export const InterventionDataSource = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col span={colSpan}>
			<DictionaryPicker
				codeName="ZRODLA_DANYCH"
				label="Źródła danych"
				commentField="dataSource"
				helpField="dataSourceDictionaryValues"
				dateRange={getDateRange(context)}
				name="dataSourceDictionaryValues"
				valueId="id"
				readOnly={!context.editable}
				otherValue="otherValue"
				interventionPath={
					(context.data
						?.interventionPath as string) as DictionaryInterventionPath
				}
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.pattern,
						'dataSourceDictionaryValues'
					)
				}
				inline
				multiple={true}
				changedVersion={
					context.editable &&
					context.unchangedData?.dataSourceDictionaryValues &&
					_.differenceWith(
						context.unchangedData?.dataSourceDictionaryValues!,
						context.data?.dataSourceDictionaryValues!,
						_.isEqual
					)?.length > 0
						? new ChangedVersionDictionaryDto(
								context.data?.dataSourceDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.dataSourceDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.dataSourceDictionaryValues?.map(
									(x) => x.value!
								)!
						  )
						: undefined
				}
			/>
		</Col>
	);
};

export const InterventionEffects = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	const dictionaryInterventionPath = (context.data
		?.interventionPath as string) as DictionaryInterventionPath;

	return (
		<Col span={colSpan}>
			<DictionaryPicker
				label={
					dictionaryInterventionPath ==
					DictionaryInterventionPath.RESEARCH
						? 'Efekty projektu'
						: 'Efekty interwencji'
				}
				commentField={
					dictionaryInterventionPath ==
					DictionaryInterventionPath.RESEARCH
						? 'projectEffect'
						: 'interventionEffect'
				}
				helpField={
					dictionaryInterventionPath ==
					DictionaryInterventionPath.RESEARCH
						? 'projectEffectDictionaryValues'
						: 'interventionEffectDictionaryValues'
				}
				interventionPath={dictionaryInterventionPath}
				multiple
				small={true}
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.pattern,
						'interventionEffectDictionaryValues'
					)
				}
				readOnly={!context.editable}
				name="interventionEffectDictionaryValues"
				codeName="EFEKTY_INTERWENCJI"
				checkChanges={context.checkChanges}
				dateRange={getDateRange(context)}
				changedVersion={
					context.editable &&
					context.unchangedData?.interventionEffectDictionaryValues &&
					_.differenceWith(
						context.unchangedData
							?.interventionEffectDictionaryValues!,
						context.data?.interventionEffectDictionaryValues!,
						_.isEqual
					)?.length > 0
						? new ChangedVersionDictionaryDto(
								context.data?.interventionEffectDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.interventionEffectDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.interventionEffectDictionaryValues?.map(
									(x) => x.value!
								)!
						  )
						: undefined
				}
			/>
		</Col>
	);
};

export const InterventionModeOfImplementation = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col span={colSpan}>
			<DictionaryPicker
				label="Tryb realizacji"
				helpField="educationImplementationModeDictionaryValue"
				commentField="educationImplementationMode"
				dateRange={getDateRange(context)}
				readOnly={!context.editable}
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.pattern,
						'educationImplementationModeDictionaryValueId'
					)
				}
				codeName="TRYB_REALIZACJI_KSZTAŁCENIA"
				otherValue="educationImplementationModeDictionaryOtherValue"
				name="educationImplementationModeDictionaryValueId"
				checkChanges={(key: any, value: any, parent: any) =>
					context.checkChanges?.(
						value,
						'educationImplementationModeDictionaryValueId'
					)
				}
				interventionPath={
					(context.data
						?.interventionPath! as string) as DictionaryInterventionPath
				}
				changedVersion={constructChangedVersion(
					context,
					context.unchangedData
						?.educationImplementationModeDictionaryValueId,
					context.data?.educationImplementationModeDictionaryValueId
				)}
			/>
		</Col>
	);
};

export const InterventionContractorsAmount = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col span={colSpan}>
			<FFieldLabel
				label="Liczba wykonawców projektu"
				for="contractorsQty"
			/>
			<LabelHelpTooltip
				field="contractorsQty"
				defaultValue="Liczba wykonawców projektu"
			/>
			<InterventionCommentIcon fieldName="contractorsQty" />
			<InterventionCommentArea fieldName="contractorsQty">
				<Field
					component={FInputNumber}
					style={{
						width: '100%',
					}}
					label="Liczba wykonawców projektu"
					name="contractorsQty"
					disabled={!context.editable}
					min={0}
					changeData={(value: any) =>
						context.checkChanges?.(value, 'contractorsQty')
					}
				/>
			</InterventionCommentArea>
		</Col>
	);
};
export const InterventionClassificationGBOARD = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col span={colSpan}>
			<DictionaryPicker
				label="Klasyfikacja GBAORD"
				helpField="gBoardClassificationDictionaryValues"
				readOnly={!context.editable}
				commentField="gbaordClassification"
				interventionPath={
					(context.data
						?.interventionPath! as string) as DictionaryInterventionPath
				}
				small={true}
				multiple
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.pattern,
						'gBoardClassificationDictionaryValues'
					)
				}
				name="gBoardClassificationDictionaryValues"
				codeName="KLASYFIKACJA_GBAORD"
				checkChanges={context.checkChanges}
				dateRange={getDateRange(context)}
				changedVersion={
					context.editable &&
					context.unchangedData
						?.gBoardClassificationDictionaryValues &&
					_.differenceWith(
						context.unchangedData
							?.gBoardClassificationDictionaryValues!,
						context.data?.gBoardClassificationDictionaryValues!,
						_.isEqual
					)?.length > 0
						? new ChangedVersionDictionaryDto(
								context.data?.gBoardClassificationDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.gBoardClassificationDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.gBoardClassificationDictionaryValues?.map(
									(x) => x.value!
								)!
						  )
						: undefined
				}
			/>
		</Col>
	);
};
export const InterventionClassificationTeryt = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col span={colSpan}>
			<DictionaryPicker
				label="Klasyfikacja obszar dyscyplina"
				helpField="disciplineAreaClassificationDictionaryValues"
				commentField="disciplineAreaClassification"
				interventionPath={
					(context.data
						?.interventionPath! as string) as DictionaryInterventionPath
				}
				small={true}
				multiple
				readOnly={!context.editable}
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.pattern,
						'disciplineAreaClassificationDictionaryValues'
					)
				}
				name="disciplineAreaClassificationDictionaryValues"
				codeName="OBSZAR_DZIEDZ_DYSCYP"
				checkChanges={context.checkChanges}
				dateRange={getDateRange(context)}
				changedVersion={
					context.editable &&
					context.unchangedData
						?.disciplineAreaClassificationDictionaryValues &&
					_.differenceWith(
						context.unchangedData
							?.disciplineAreaClassificationDictionaryValues!,
						context.data
							?.disciplineAreaClassificationDictionaryValues!,
						_.isEqual
					)?.length > 0
						? new ChangedVersionDictionaryDto(
								context.data?.disciplineAreaClassificationDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.disciplineAreaClassificationDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.disciplineAreaClassificationDictionaryValues?.map(
									(x) => x.value!
								)!
						  )
						: undefined
				}
			/>
		</Col>
	);
};
export const InterventionClassificationNCN = (
	context: InterventionPathContextType,
	colSpan: number
) => {
	return (
		<Col span={colSpan}>
			<DictionaryPicker
				readOnly={!context.editable}
				label="Klasyfikacja NCN"
				helpField="ncnClassificationDictionaryValues"
				commentField="ncnClassification"
				interventionPath={
					(context.data
						?.interventionPath! as string) as DictionaryInterventionPath
				}
				multiple
				disabled={
					!context.editable ||
					context.checkIfObjectValue?.(
						context.pattern,
						'ncnClassificationDictionaryValues'
					)
				}
				small={true}
				name="ncnClassificationDictionaryValues"
				codeName="KLASYFIKACJA_NCN"
				checkChanges={context.checkChanges}
				dateRange={getDateRange(context)}
				changedVersion={
					context.editable &&
					context.unchangedData?.ncnClassificationDictionaryValues &&
					_.differenceWith(
						context.unchangedData
							?.ncnClassificationDictionaryValues!,
						context.data?.ncnClassificationDictionaryValues!,
						_.isEqual
					)?.length > 0
						? new ChangedVersionDictionaryDto(
								context.data?.ncnClassificationDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.ncnClassificationDictionaryValues?.map(
									(x) => x.id!
								)!,
								context.unchangedData?.ncnClassificationDictionaryValues?.map(
									(x) => x.value!
								)!
						  )
						: undefined
				}
			/>
		</Col>
	);
};
