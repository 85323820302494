import { useLayoutEffect } from 'react';

const useTabPaneDisableTabStop = () => {
	useLayoutEffect(() => {
		const rootElements = document.querySelectorAll('.ant-tabs-tabpane');

		const mutationConfig = {
			attributes: true,
			childList: false,
			subtree: false,
			attributeFilter: ['class']
		};

		const mutationCallback: MutationCallback = (
			mutationsList,
			observer
		) => {

			for (const mutation of mutationsList){
				const tabPane = mutation.target as HTMLDivElement;
				if(tabPane?.classList?.contains('ant-tabs-tabpane-active')){
					tabPane.setAttribute("tabindex", "-1");
				}
			}
		};

		const observer = new MutationObserver(mutationCallback);
		if (rootElements) {
			rootElements.forEach((r) =>{
				observer.observe(r, mutationConfig);
			})
		}

		return () => {
			observer.disconnect();
		};
	}, []);
};

export default useTabPaneDisableTabStop;
