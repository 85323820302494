import { DateFormat } from '@helper/formatHelpers';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import moment, { Moment } from 'moment';
import { SubjectKind, ReportingArea } from 'services/src/models';
export class SubjectReportingProcessCommand {
	id?: string;

	subjectId?: string;
	subjectName?: string;

	subjectKind?: SubjectKind;
	valid?: [Moment | undefined, Moment | undefined];
	process?: ReportingArea;
	submissionDate?: Moment;
	submissionFromYear?: number;
	submissionCycleLength?: number;
	description?: string;

	constructor(model?: ProfiBazaAPIModels.SubjectReportingProcessDto) {
		this.id = model?.id;
		this.valid = [
			model?.validFrom ? moment(model.validFrom) : undefined,
			model?.validTo ? moment(model.validTo) : undefined,
		];
		this.subjectKind = model?.subjectKind;
		this.subjectName = model?.subjectName;
		this.submissionDate =
			model?.submissionDay && model?.submissionMonth
				? moment(
						`${model.submissionDay}.${
							model.submissionMonth // - 1
						}.2020`,
						DateFormat.Date
				  )
				: undefined;
		this.submissionCycleLength = model?.submissionCycleLength;
		this.submissionFromYear = model?.submissionFromYear;
		this.description = model?.description;
		this.process = model?.process;
	}
}
export const createSubjectReportingProcess = (
	value: ProfiBazaAPIModels.SubjectReportingProcessDto
) => {
	let validFrom: any = value.validFrom;
	let validTo: any = value.validTo;
	let body: ProfiBazaAPIModels.CreateSubjectReportingProcessCommand = {
		subjectId: value.subjectId,
		subjectKind: value.subjectKind,
		validFrom: validFrom.utc(true).toDate(),
		validTo: validTo?.utc(true).toDate(),
		submissionCycleLength: value.submissionCycleLength,
		description: value.description,
		submissionFromYear: value.submissionFromYear,
		submissionDay: value.submissionDay,
		submissionMonth: value.submissionMonth,
		process: value.process,
	};
	return body;
};

export const updateSubjectReportingProcess = (
	value: ProfiBazaAPIModels.SubjectReportingProcessDto
) => {
	let validFrom: any = value.validFrom;
	let validTo: any = value.validTo;
	let body: ProfiBazaAPIModels.UpdateSubjectReportingProcessCommand = {
		id: value.id,
		validFrom: validFrom?.isValid?.() ? validFrom.utc(true).toDate() : validFrom,
		validTo: validTo?.isValid?.() ? validTo.utc(true).toDate() : validTo,
		submissionCycleLength: value.submissionCycleLength,
		description: value.description,
		submissionFromYear: value.submissionFromYear,
		submissionDay: value.submissionDay,
		submissionMonth: value.submissionMonth,
		process: value.process,
	};
	return body;
};
