import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import moment, { Moment } from 'moment';
import * as msRest from "@azure/ms-rest-js";

export class UpdateFormVersionDto {
	id?: string;
	validFrom?: Moment;
	validTo?: Moment;
	version?: string;
	xsltDefinition?: string;
	xlsxDefinition?: msRest.HttpRequestBody;

	constructor(model: ProfiBazaAPIModels.FormVersionItemDto) {
		this.id = model.id;
		this.validFrom = model.validFrom ? moment(model.validFrom) : undefined;
		this.validTo = model.validTo ? moment(model.validTo) : undefined;
		this.version = model.version;
		this.xsltDefinition = model.xsltDefinition;
		this.xlsxDefinition = model.xlsxDefinition;
	}
}
