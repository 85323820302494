import { ExclamationOutlined } from '@ant-design/icons';
import {
	IInterventionData,
	InterventionCommentButton,
} from '@components/intervention/comments/InterventionCommentButton';
import BackButtons from '@components/shared/buttons/BackButtons';
import UserManualViewButton from '@components/userManual/UserManualViewButton';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { Col, Modal, Row, Space } from 'antd';
import AddButton from 'components/shared/buttons/AddButton';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { Field, Formik, FormikProps } from 'formik';
import { FCheckbox, FFieldLabel } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import { Moment } from 'moment';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import {
	ActivityDetailsDto,
	ActivityListItemDto,
	BusinessObjectType,
	InterventionPath,
	OpinionAOTMiTType,
	UserManualInterventionPath,
	UserManualType,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IRizpDictionaryStore } from 'stores/RizpDictionaryStore';

import { IInterventionCommentStore } from '../comments/store/InterventionCommentStore';
import InterventionValidationErrors from '../model/InterventionValidationErrors';
import InterventionActivityCreate from './details/InterventionActivityDetails';
import useActivityState from './hooks/useActivityStateHook';
import { getActivitiesColumnsByInterventionPath } from './InterventionActivityTableColumns';

interface IProps {
	editable?: boolean;
	interventionId: string;
	interventionPath: InterventionPath;
	interventionSubjectId: string;
	opinionAOTMiT: OpinionAOTMiTType;
	isPattern: boolean;
	patternId?: any;
	onChangeActivities: () => void;
	interventionCreatedAt: Moment;
}

const InterventionActivityData: React.FC<IProps> = (props) => {
	const [state, activityActions] = useActivityState();

	const history = useHistory();

	useEffect(() => {
		if (state.activityVisible) {
			history.push(
				`/rizp/${props.isPattern ? 'pattern/' : ''}${
					props.editable ? 'edit' : 'details'
				}/${props.interventionId}/activities/${state.mode}/${
					state.activityId
				}`
			);
		} else {
			history.push(
				`/rizp/${props.isPattern ? 'pattern/' : ''}${
					props.editable ? 'edit' : 'details'
				}/${props.interventionId}/activities`
			);
		}
	}, [state.mode, state.activityVisible, state.activityId]);

	useEffect(() => {
		refresh();
	}, []);

	const interventionCommentStore: IInterventionCommentStore = useRootData(
		(store) => store.interventionCommentStore
	);

	const refresh = () => {
		state.setLoading(true);
		return ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.intervention.getActivities(props.interventionId)
				)
				.then((response) => {
					state.setActivities(response);
					const newRelativeActivities = response
						.filter((x) => x.primaryActivityId)
						?.map((x) => x.primaryActivityId!);
					state.setRelatedActivities(newRelativeActivities);
					state.setLoading(false);
				})
		);
	};

	const validationErrors = InterventionValidationErrors.getErrors();

	const relatedActivitiesTooltip = (record: ActivityListItemDto) => {
		var title = 'Działania powiązane - ';
		var relatedActivities = [];
		if (state.activities?.some((x) => x.id == record.primaryActivityId)) {
			relatedActivities.push(
				'utworzono na podstawie (' +
					state.activities?.find(
						(x) => x.id == record.primaryActivityId
					)?.activityNumber +
					')'
			);
		}
		if (state.activities?.some((x) => x.primaryActivityId == record.id)) {
			state.activities
				?.filter((x) => x.primaryActivityId == record.id)
				.forEach((element) => {
					relatedActivities.push(
						'sklonowane działanie (' + element?.activityNumber + ')'
					);
				});
		}

		return title + relatedActivities.join(', ');
	};

	const columns = [
		state.relatedActivities && state.relatedActivities.length > 0
			? {
					key: 'actions',
					title: 'Kolumna akcji',
					className: 'actionstd',
					render: (text: string, record: ActivityListItemDto) =>
						state.relatedActivities?.some(
							(x) =>
								(x == record.id ||
									x == record.primaryActivityId) &&
								state.activities?.some(
									(e: ActivityListItemDto) => e.id == x
								)
						) && (
							<ProfiBazaTooltip
								placement="top"
								title={relatedActivitiesTooltip(record)}
							>
								<TableActionButton
									label="Powiązane działania"
									kind="ACCEPT"
									style={{ cursor: 'default' }}
								/>
							</ProfiBazaTooltip>
						),
			  }
			: {
					title: 'Kolumna akcji',
					className: 'actionstd',
			  },

		props.interventionId &&
		validationErrors?.hasInterventionError(props.interventionId).hasError
			? {
					key: 'actions',
					title: 'Kolumna akcji',
					className: 'actionstd',
					render: (_: string, record: ActivityListItemDto) => {
						const activityError = validationErrors?.hasActivityError(
							props.interventionId,
							record.id
						);

						return activityError.hasError ? (
							<ProfiBazaTooltip
								placement="top"
								title={activityError.message!}
							>
								<ExclamationOutlined className="exclamation-mark" />
							</ProfiBazaTooltip>
						) : null;
					},
			  }
			: {
					title: 'Kolumna akcji',
					className: 'actionstd',
			  },

		...getActivitiesColumnsByInterventionPath(props.interventionPath),
		RenderActions<ProfiBazaAPIModels.ActivityListItemDto>(
			(text, record, index) => (
				<>
					<ProfiBazaTooltip placement="top" title="Szczegóły">
						<TableActionButton
							label="Szczegóły"
							kind="DETAILS"
							onClick={async () => {
								state.setActivityVisible(true);
								state.setMode('preview');
								state.setActivityId(record.id);

								interventionCommentStore.filterParams.set({
									activityId: record.id!,
									populationId: null,
									sponsoringCompanyId: null,
								});
							}}
						/>
					</ProfiBazaTooltip>

					{props.editable && (
						<ProfiBazaTooltip placement="top" title="Edytuj">
							<TableActionButton
								label="Edytuj"
								kind="EDIT"
								onClick={() => {
									state.setActivityVisible(true);
									state.setMode('edit');
									state.setActivityId(record.id);
									interventionCommentStore.filterParams.set({
										activityId: record.id!,
										populationId: null,
										sponsoringCompanyId: null,
									});
								}}
							/>
						</ProfiBazaTooltip>
					)}

					{props.editable && (
						<>
							{record.id && (
								<ProfiBazaTooltip
									placement="top"
									title="Sklonuj"
								>
									<TableActionButton
										label="Sklonuj"
										kind="CLONE"
										onClick={() => {
											state.setCloneVisible(true);
											state.setActivityId(record.id);
										}}
									/>
								</ProfiBazaTooltip>
							)}

							{!record.activityPatternId && (
								<ProfiBazaTooltip placement="top" title="Usuń">
									<AccessiblePopconfirm
										title={
											'Czy na pewno chcesz usunąć element?'
										}
										onConfirm={() => {
											ajaxByUser(
												'Skasowano działanie',
												() =>
													getProfiBazaApiClient().then(
														(api) => {
															api.activity
																.deleteMethod(
																	record.id!
																)
																.then(() => {
																	props.onChangeActivities();
																	refresh();
																	interventionCommentStore.setComments(
																		props.interventionId
																	);
																});
														}
													)
											);
										}}
									>
										<TableActionButton
											label="Usuń"
											kind="DELETE"
										/>
									</AccessiblePopconfirm>
								</ProfiBazaTooltip>
							)}
						</>
					)}
				</>
			)
		),
	];

	return (
		<>
			<CenteredRow>
				<Col span={20}>
					<Row style={{ marginBottom: '10px' }}>
						{props.editable ? (
							<AddButton
								style={{
									marginTop: '-4px',
								}}
								onClick={() => {
									state.setActivityVisible(true);
									state.setActivityId(undefined);
									state.setMode('new');

									interventionCommentStore.filterParams.set({
										activityId: null,
										populationId: null,
										sponsoringCompanyId: null,
									});
								}}
							>
								Dodaj działanie
							</AddButton>
						) : (
							<></>
						)}
						<div style={{ margin: 'auto 0px auto auto' }}>
							<Space>
								{!props.isPattern && (
									<InterventionCommentButton
										label="Komentarze interwencji"
										interventionData={
											{
												id: props.interventionId,
												interventionPath:
													props.interventionPath,
												subjectId:
													props.interventionSubjectId,
												opinionAOTMiT:
													props.opinionAOTMiT,
											} as IInterventionData
										}
										readonlyMode={true}
									/>
								)}
								<UserManualViewButton
									userManual={{
										manualType: UserManualType.Intervention,
										interventionPath: (props.interventionPath as string) as UserManualInterventionPath,
									}}
								/>
							</Space>
						</div>
					</Row>

					<InterventionActivityCreate
						visible={state.activityVisible!}
						interventionId={props.interventionId}
						activityId={state.activityId}
						interventionCreatedAt={props.interventionCreatedAt}
						mode={state.mode!}
						onOk={() => {
							props.onChangeActivities();
							refresh().then(activityActions.handleCloseModal);
						}}
						onCancel={activityActions.handleCloseModal}
						isPattern={props.isPattern}
						interventionPath={props.interventionPath}
					/>

					<ProfiBazaTable
						columns={columns}
						pagination={false}
						loading={state.loading}
						onRow={(
							record: ProfiBazaAPIModels.ActivityListItemDto,
							rowIndex: number
						) => {
							return {
								onDoubleClick: (
									event: React.MouseEvent<
										HTMLElement,
										MouseEvent
									>
								) => {
									state.setMode('preview');
									state.setActivityVisible(true);
									state.setActivityId(record.id);

									interventionCommentStore.filterParams.set({
										activityId: record.id!,
										populationId: null,
										sponsoringCompanyId: null,
									});
								},
							};
						}}
						rowKey={(r: ActivityListItemDto) => r.id!?.toString()}
						dataSource={state.activities}
					/>

					<Formik
						initialValues={{ isContinuation: false }}
						onSubmit={(values, actions) => {
							state.setCloneVisible(false);
							state.setActivityVisible(true);
							state.setMode(
								values.isContinuation
									? 'clone_continuations'
									: 'clone'
							);
							actions.resetForm();
						}}
						enableReinitialize
						render={(formikProps: FormikProps<any>) => (
							<Modal
								visible={state.cloneVisible}
								onOk={() => {
									formikProps.submitForm();
								}}
								okText="Sklonuj"
								title="Klonowanie działania"
								cancelText="Anuluj"
								onCancel={() => {
									activityActions.handleCloseCloneModal();
									formikProps.resetForm();
								}}
							>
								<Col span={24}>
									<Space>
										<Field
											component={FCheckbox}
											name="isContinuation"
											id="isContinuation"
										/>
										<FFieldLabel
											label="Czy kontynuacja"
											for="isContinuation"
										/>
									</Space>
								</Col>
							</Modal>
						)}
					/>
				</Col>
			</CenteredRow>
			<CenteredRow>
				<Col span={20}>
					<Space className="float-right">
						<BackButtons
							link={props.isPattern ? '/rizp/patterns' : '/rizp'}
							size="large"
							backToCurrentPlace={true}
							visible={false}
							disabled={false}
							onClick={() => {}}
						/>
					</Space>
				</Col>
			</CenteredRow>
		</>
	);
};
export default InterventionActivityData;
