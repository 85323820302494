import DownshiftSearch from '@components/shared/DownshiftSearch';
import useControlAccessibilityHook from '@hooks/accessibility/useControlAccessibilityHook';
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { DictionaryValueWithPathViewDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

interface IProps {
	visible: boolean;
	setVisible: (visible: boolean) => void;
	onSearch: (item: DictionaryValueWithPathViewDto) => void;
	additionalData?: any;
	onClear?: () => void;
}

const DictionarySearchBar: React.FC<IProps> = ({
	visible,
	setVisible,
	onSearch,
	additionalData,
	onClear,
}) => {
	useControlAccessibilityHook(
		visible,
		'button, [class="ant-input-search-button"]',
		'Przycisk wyszukiwania'
	);

	const itemToString = (item: DictionaryValueWithPathViewDto): string => {
		return item.path ?? '';
	};

	const itemToDisplayValue = (
		item: DictionaryValueWithPathViewDto
	): string => {
		return item?.value ?? '';
	};

	const getSearchedElements = async (
		page: number,
		searchCriteria: string,
		additionalData?: any
	): Promise<any> => {
		return getProfiBazaApiClient().then((api) => {
			return api.dictionary.search({
				page: page,
				filters: `(value)@=*${searchCriteria}`,
				...additionalData,
			});
		});
	};

	return (
		<Modal
			className="modal--search"
			visible={visible}
			centered
			closable={false}
			maskClosable={true}
			footer={[
				<Button
					key="close"
					type="default"
					onClick={() => {
						setVisible(false);
					}}
				>
					Zamknij
				</Button>,
			]}
		>
			<DownshiftSearch
				onChange={onSearch}
				onSearchClear={onClear}
				itemToString={itemToString}
				itemToDisplayValue={itemToDisplayValue}
				getData={getSearchedElements}
				additionalData={additionalData}
			></DownshiftSearch>
		</Modal>
	);
};

export default DictionarySearchBar;
