import PermissionValidator from '@authorization/permissionValidator';
import AOTMiTAnnexDocumentTableColumns from '@components/documents/table/columns/AOTMiTAnnexDocumentTableColumns';
import AOTMiTFinalReportTableColumns from '@components/documents/table/columns/AOTMiTFinalReportTableColumns';
import AOTMiTPPZDocumentTableColumns from '@components/documents/table/columns/AOTMiTPPZDocumentTableColumns';
import AOTMiTPronouncementsDocumentTableColumns from '@components/documents/table/columns/AOTMiTPronouncementsDocumentTableColumns';
import AOTMiTRecommendationDocumentTableColumn from '@components/documents/table/columns/AOTMiTRecommendationDocumentTableColumn';
import InterventionArchiveDocumentTableColumns from '@components/documents/table/columns/InterventionArchiveDocumentTableColumns';
import InterventionDocumentTableColumns from '@components/documents/table/columns/InterventionDocumentTableColumns';
import StatementArchiveDocumentTableColumns from '@components/documents/table/columns/StatementArchiveDocumentTableColumns';
import StatementDocumentTableColumns from '@components/documents/table/columns/StatementDocumentTableColumns';
import SourceDocumentsTableColumns from '@components/documents/table/columns/StatementDocumentTableColumns';
import VoivodeOpinionDocumentsTableColumns from '@components/documents/table/columns/VoivodeOpinionDocumentsTableColumns';
import { tagsColumns } from '@components/tags/TagList';
import {
	columns,
	columnsAdmin,
} from 'components/proposal/table/ProposalTableColumns';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { MenuSectionType } from 'layout/Menu/MenuItems';
import { action, observable } from 'mobx';

import DictionariesTableColumns from '../components/dictionary/table/DictionariesTableColumn';
import statementTestColumns from '../components/forms/test/StatementTestTableColumn';
import {
	interventionTableColumns,
	patternTableColumns,
} from '../components/intervention/table/InterventionTableColumns';
import StatementTableColumns from '../components/statements/table/StatementTableColumns';
import SubjectReportingProcessColumns from '../components/subjects/reportingProcess/SubjectReportingProcessColumns';
import { defaultColumns } from '../components/subjects/SubjectTableColumns';

export type GridNameType =
	| 'subject'
	| 'dictionaries'
	| 'intervention'
	| 'statement'
	| 'users'
	| 'activitySubjects'
	| 'subjectReportingProcess'
	| 'proposal'
	| 'documents'
	| 'proposalAdmin'
	| 'terytArea'
	| 'mergeSubject'
	| 'patterns'
	| 'orbeonForms'
	| 'statementsTest'
	| 'tags'
	| 'aotmitPPZ'
	| 'aotmitRecommendations'
	| 'aotmitAnnex'
	| 'aotmitPronouncements'
	| 'aotmitFinalReport'
	| 'interventionDocuments'
	| 'statementDocuments'
	| 'archiveInterventionsDocuments'
	| 'archiveStatementsDocuments'
	| 'voivodeOpinionDocuments';

const tableEntriesInitializer: Array<[
	GridNameType,
	ProfiBazaColumnProps<any>[]
]> = [
	['subject', defaultColumns],
	['dictionaries', DictionariesTableColumns],
	['intervention', interventionTableColumns],
	['statement', StatementTableColumns],
	['subjectReportingProcess', SubjectReportingProcessColumns],
	['proposal', columns],
	['proposalAdmin', columnsAdmin],
	['patterns', patternTableColumns],
	['statementsTest', statementTestColumns],
	['tags', tagsColumns],
	['aotmitPPZ', AOTMiTPPZDocumentTableColumns],
	['aotmitRecommendations', AOTMiTRecommendationDocumentTableColumn],
	['aotmitAnnex', AOTMiTAnnexDocumentTableColumns],
	['aotmitPronouncements', AOTMiTPronouncementsDocumentTableColumns],
	['aotmitFinalReport', AOTMiTFinalReportTableColumns],
	['interventionDocuments', InterventionDocumentTableColumns],
	['statementDocuments', StatementDocumentTableColumns],
	['archiveInterventionsDocuments', InterventionArchiveDocumentTableColumns],
	['archiveStatementsDocuments', StatementArchiveDocumentTableColumns],
	['voivodeOpinionDocuments', VoivodeOpinionDocumentsTableColumns],
];

const sectionsMapper: Map<string, GridNameType[]> = new Map([
	['subject', ['subject']],
	['dictionaries', ['dictionaries']],
	['rizp', ['intervention']],
	['statements', ['statement']],
	['statement', ['statement']],
	['admin', ['users']],
	['subjectReportingProcess', ['subjectReportingProcess']],
	['proposal', ['proposal']],
	['proposalAdmin', ['proposalAdmin']],
	[
		'documents',
		[
			'documents',
			'interventionDocuments',
			'statementDocuments',
			'aotmitPPZ',
			'aotmitRecommendations',
			'aotmitAnnex',
			'aotmitPronouncements',
			'aotmitFinalReport',
			'archiveStatementsDocuments',
			'archiveInterventionsDocuments',
			'voivodeOpinionDocuments',
		],
	],
	['statementsTest', ['statementsTest']],
	['tags', ['tags']],
	['aotmitPPZ', ['aotmitPPZ']],
	['aotmitRecommendations', ['aotmitRecommendations']],
	['aotmitAnnex', ['aotmitAnnex']],
	['aotmitPronouncements', ['aotmitPronouncements']],
	['aotmitFinalReport', ['aotmitFinalReport']],
	['interventionDocuments', ['interventionDocuments']],
	['statementDocuments', ['statementDocuments']],
	['archiveInterventionsDocuments', ['archiveInterventionsDocuments']],
	['archiveStatementsDocuments', ['archiveStatementsDocuments']],
	['voivodeOpinionDocuments', ['voivodeOpinionDocuments']],
]);

const tables: Map<GridNameType, ProfiBazaColumnProps<any>[]> = new Map(
	tableEntriesInitializer
);

export const tableColumnStore = {
	tables: new Map<GridNameType, ProfiBazaColumnProps<any>[]>(tables),
	currentTable: observable.box<GridNameType | undefined>(undefined),

	applyOrder: (gridName: GridNameType, orderedKeys: string[]) => {
		const orderedColumns: ProfiBazaColumnProps<any>[] = [];
		const columnsMap: Map<string, ProfiBazaColumnProps<any>> = new Map();
		const tableColumns = tables.get(gridName);

		if (!tableColumns) return;

		tableColumns.forEach((column) =>
			columnsMap.set(column.key!.toString(), column)
		);
		orderedKeys.forEach((key) => {
			const nextItem = columnsMap.get(key);
			if (nextItem) {
				orderedColumns.push(nextItem);
			}
		});

		tableColumnStore.setTable(gridName, orderedColumns);
	},

	setTable: (
		gridName: GridNameType,
		columns: ProfiBazaColumnProps<any>[]
	) => {
		tableColumnStore.tables.set(gridName, columns);
	},
	getTable: (
		gridName: GridNameType,
		permissionValidator?: PermissionValidator
	): ProfiBazaColumnProps<any>[] => {
		let columns = tableColumnStore.tables.get(gridName);

		if(permissionValidator) {
			columns = columns?.filter(
				(x) =>
					x.permission === undefined ||
					permissionValidator?.has(x.permission)
			);
		}

		return columns ?? [];
	},

	setCurrentTable(gridName: GridNameType | undefined) {
		tableColumnStore.currentTable.set(gridName);
	},
	getCurrentTable: () => tableColumnStore.currentTable.get(),

	isWithinSection: action((section: MenuSectionType) => {
		return sectionsMapper
			.get(section)
			?.includes(tableColumnStore.getCurrentTable()!);
	}),
};

export const createTableColumnStore = () => {
	return tableColumnStore;
};

export type ITableColumnStore = ReturnType<typeof createTableColumnStore>;
