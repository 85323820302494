import { ProfiBazaAPI } from './src/profiBazaAPI';
import { TokenCredentials, ServiceClientCredentials } from '@azure/ms-rest-js';
import { GlobalUserManager } from '@authentication/userManager';
import { User } from 'oidc-client';

export class NoCredentials implements ServiceClientCredentials {
	signRequest(
		webResource: import('@azure/ms-rest-js').WebResource
	): Promise<import('@azure/ms-rest-js').WebResource> {
		return Promise.resolve(webResource);
	}
}

export function getProfiBazaApiClient(): Promise<ProfiBazaAPI> {
	let um = GlobalUserManager.UserManager();
	if (um) {
		let promise = um.getUser().then((user: User | null) => {
			if (user) {
				const token = new TokenCredentials(user.access_token);
				return new ProfiBazaAPI(token);
			}
			return new ProfiBazaAPI(new NoCredentials());
		});
		return promise;
	}

	return Promise.resolve(new ProfiBazaAPI(new NoCredentials()));
}

export function getUser(): Promise<User | null> {
	let um = GlobalUserManager.UserManager();
	if (um) {
		return um.getUser();
	}

	return Promise.resolve(null);
}
