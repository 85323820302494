import React, { useState } from 'react';
import * as DictionaryInterventionPathMapper from '@services/mappers/DictionaryInterventionPath';
import Checkbox, {
	CheckboxOptionType,
	CheckboxChangeEvent,
} from 'antd/lib/checkbox';
import { DictionaryInterventionPath } from 'services/src/models';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

interface IProps {
	disabled: boolean;
	processFilters: DictionaryInterventionPath[] | undefined;
	setProcessFilters: React.Dispatch<
		React.SetStateAction<DictionaryInterventionPath[] | undefined>
	>;
}

const initializeProcessOptions = () => {
	const options: CheckboxOptionType[] = [];
	DictionaryInterventionPathMapper.mappings.forEach((value, key) => {
		options.push({
			label: value,
			value: key,
		});
	});
	return options;
};

const options = initializeProcessOptions();

const ProcessSelector: React.FC<IProps> = (props) => {
	const { disabled, processFilters, setProcessFilters } = props;

	const [indeterminate, setIndeterminate] = useState<boolean>(false);
	const [checkAll, setCheckAll] = useState<boolean>(true);

	const handleSelectAllChange = (e: CheckboxChangeEvent) => {
		if (e.target.checked) {
			const allOptions = options.map(
				(option) => option.value as DictionaryInterventionPath
			);
			setProcessFilters(allOptions);
		} else {
			setProcessFilters([]);
		}
		setIndeterminate(false);
		setCheckAll(e.target.checked);
	};

	const handleChange = (
		checkedList: (CheckboxValueType | DictionaryInterventionPath)[]
	) => {
		setProcessFilters(checkedList as DictionaryInterventionPath[]);
		setIndeterminate(
			!!checkedList.length && checkedList.length < options.length
		);
		setCheckAll(checkedList.length === options.length);
	};

	return (
		<>
			<Checkbox
				indeterminate={indeterminate}
				onChange={handleSelectAllChange}
				checked={checkAll}
				disabled={disabled}
			>
				Pokaż wszystko
			</Checkbox>
			<Checkbox.Group
				disabled={disabled}
				options={options}
				onChange={handleChange}
				value={processFilters as CheckboxValueType[]}
			/>
		</>
	);
};

export default ProcessSelector;
