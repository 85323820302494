import {
	ActivityTabs,
	activityFields,
} from '@components/intervention/fields/activityValidationErrors';
import arrayHelpers from '@helper/arrayHelpers';
import { Popover } from 'antd';
import clsx from 'clsx';
import { FormikErrors } from 'formik';
import _ from 'lodash';
import React from 'react';

export const hasValidationErrors = <T extends any>(
	formikErrors: FormikErrors<T> | undefined,
	tabName: ActivityTabs
) => {
	if (formikErrors === undefined) return false;

	const hasError = activityFields[tabName]?.some((field) =>
		arrayHelpers.containsKeyIgnoreCase(formikErrors, field)
	);

	if (hasError) return true;

	return false;
};

export const markErrors = <T extends any>(
	formikErrors: FormikErrors<T> | undefined,
	tabName: ActivityTabs,
	tabContent: JSX.Element
) => {
	const hasErrors = hasValidationErrors(formikErrors, tabName);
	let invalidValues: string[] = [];

	if (hasErrors) {
		const invalidFields = _.intersectionBy(
			activityFields[tabName],
			Object.keys(formikErrors!),
			(key: string) => key.toLowerCase()
		);
		invalidValues = _.flatten(
			invalidFields.map((field) => {
				const value = formikErrors![field as keyof T];
				if (typeof value === 'object') {
					const errors: string[] = [];
					eachRecursive(value, errors);
					return errors;
				}
				return [value] as string[];
			})
		);
	}

	return (
		<Popover
			overlayStyle={{
				visibility: hasErrors ? 'visible' : 'hidden',
			}}
			content={
				<div className={'form-error-label'}>
					{invalidValues.length
						? invalidValues.map((error) => <p>{error}</p>)
						: 'Zakładka zawiera błędy walidacji'}
				</div>
			}
			trigger={['hover', 'focus']}
		>
			<div className={clsx(hasErrors && 'tab--invalid')}>
				{tabContent}
			</div>
		</Popover>
	);
};

function eachRecursive(obj: any, result: string[]) {
	for (var k in obj) {
		if (typeof obj[k] == 'object' && obj[k] !== null)
			eachRecursive(obj[k], result);
		else {
			result.push(obj[k]);
		}
	}
}
