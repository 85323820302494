import React, { useEffect } from 'react';
import { Row, Col, Space, Tooltip, Button, Select } from 'antd';
import {
	FFieldLabel,
	FCheckbox,
	FInput,
	FTextArea,
	FSelect,
} from 'forms/FormikFormItems';
import { Field, FormikProps } from 'formik';
import { EditOutlined } from '@ant-design/icons';
import { IDictionaryStore } from 'components/dictionary/dictionaryStore';
import { useRootData } from 'hooks/hook';
import { DictionaryName } from 'services/mappers/DictionaryName';

interface IProps {
	editable: boolean;
	codeNameEditable: boolean;
	setCodeNameEditable: React.Dispatch<React.SetStateAction<boolean>>;
	formikProps: FormikProps<any>;
}

export const ValuesForm: React.FC<IProps> = ({
	editable,
	codeNameEditable,
	setCodeNameEditable,
	formikProps,
}) => {
	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);
	const dictionaryInterventionTypes = dictionaryStore!.getBuildInDictionary(
		DictionaryName.DictionaryInterventionPath
	).values;

	useEffect(() => {
		if (
			formikProps.values['canAddTextValue'] &&
			!formikProps.values['canBeSelected']
		) {
			formikProps.setFieldValue('canBeSelected', true);
		}
	}, [formikProps.values['canAddTextValue']]);

	useEffect(() => {
		if (formikProps.values['canAddTextValue']) {
			formikProps.setFieldValue('value', 'Inna');
		}
	}, [formikProps.values['canAddTextValue']]);

	return (
		<>
			<Row>
				<Col span={12}>
					<FFieldLabel label="Numeracja" />
					<Field
						label="Numeracja"
						component={FInput}
						placeholder="Numeracja"
						name="code"
					/>
				</Col>
			</Row>
			<Row align="bottom">
				<Col span={12}>
					<FFieldLabel label="Identyfikator" />
					<Field
						label="Identyfikator"
						component={FInput}
						disabled={!(editable && codeNameEditable)}
						placeholder="Identyfikator"
						name="codeName"
						onBlur={() => {
							setCodeNameEditable(false);
						}}
					/>
				</Col>
				{editable && !codeNameEditable && (
					<Col span={4}>
						<Tooltip title="Edytuj">
							<Button
								aria-label="Edytuj"
								shape="circle"
								size="small"
								type="default"
								className="m-xxs"
								onClick={() => setCodeNameEditable(true)}
								icon={<EditOutlined />}
							/>
						</Tooltip>
					</Col>
				)}
			</Row>
			<Row>
				<Col span={24}>
					<FFieldLabel label="Wartość" />
					<Field
						label="Wartość"
						component={FTextArea}
						rows={4}
						placeholder="Wartość"
						name="value"
					/>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<FFieldLabel label="Typy ścieżek interwencji" />
					<Field
						label="Typy ścieżek interwencji"
						component={FSelect}
						name="interventionPaths"
						mode="multiple"
						children={dictionaryInterventionTypes?.map((p) => {
							return (
								<Select.Option
									key={p.displayName}
									value={p.code}
								>
									{p.displayName}
								</Select.Option>
							);
						})}
					/>
				</Col>
			</Row>
			<Row className="mt-small">
				<Col span={12}>
					<Space>
						<Field
							label="Możliwa wartość inna"
							component={FCheckbox}
							name="canAddTextValue"
							id="canAddTextValue"
						/>
						<FFieldLabel
							label="Możliwa wartość inna"
							for="canAddTextValue"
						/>
					</Space>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Space>
						<Field
							label="Czy może być wybrana"
							component={FCheckbox}
							name="canBeSelected"
							id="canBeSelected"
							disabled={formikProps.values['canAddTextValue']}
						/>
						<FFieldLabel
							label="Czy może być wybrana"
							for="canBeSelected"
						/>
					</Space>
				</Col>
			</Row>
		</>
	);
};
