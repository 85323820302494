import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import  * as IntervantionStatus from './InterventionStatus';

const interventionStatusWithoutArchived = () => {
	var statuses = IntervantionStatus.mappings;
	statuses.delete(ProfiBazaAPIModels.PublicHealthInterventionStatus.ARCHIVED)
	return statuses;
}

export const mappings = interventionStatusWithoutArchived()

export const map = (
	value: ProfiBazaAPIModels.PublicHealthInterventionStatus
): string => mappings.get(value) ?? '';
