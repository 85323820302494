import { ProfiBazaColumnProps } from '@components/shared/paginatedProfiBazaTable/GridHelper';
import {
    RODOManualType,
	UserManualDto,
} from '@services/src/models';
import * as RODOManualTypeMapper from '@services/mappers/RODOManualType';

export const RODOColumns: ProfiBazaColumnProps<UserManualDto>[] = [
	{
		sorter: false,
		dataIndex: 'rodoManualType',
		key: 'rodoManualType',
		title: 'Typ RODO',
		render: (rodoManualType: RODOManualType) =>
        RODOManualTypeMapper.map(rodoManualType),
	},
];
