import TableActionButton from '@components/shared/buttons/TableActionButton';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React from 'react';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { DownloadFile } from '@components/shared/file/FileDownloadFunction';

const DownloadManualButton: React.FC<ProfiBazaAPIModels.UserManualDto> = (
	props
) => {
	const downloadFile = (): void => {
		getProfiBazaApiClient()
			.then((api) => api.userManual.download(props.id!))
			.then((response) => {
				DownloadFile({
					response,
				});
			});
	};

	return (
		<ProfiBazaTooltip placement="top" title="Pobierz instrukcję">
			<TableActionButton
				label="Pobierz instrukcję"
				kind="FILEPDF"
				onClick={downloadFile}
			/>
		</ProfiBazaTooltip>
	);
};

export default DownloadManualButton;
