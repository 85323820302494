/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/dictionaryVersionMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a DictionaryVersion. */
export class DictionaryVersion {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a DictionaryVersion.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryVersionCreateResponse>
   */
  create(options?: Models.DictionaryVersionCreateOptionalParams): Promise<Models.DictionaryVersionCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.Int32IIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.DictionaryVersionCreateOptionalParams, callback: msRest.ServiceCallback<Models.Int32IIdDto>): void;
  create(options?: Models.DictionaryVersionCreateOptionalParams | msRest.ServiceCallback<Models.Int32IIdDto>, callback?: msRest.ServiceCallback<Models.Int32IIdDto>): Promise<Models.DictionaryVersionCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.DictionaryVersionCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryVersionUpdateResponse>
   */
  update(options?: Models.DictionaryVersionUpdateOptionalParams): Promise<Models.DictionaryVersionUpdateResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.DictionaryVersionUpdateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  update(options?: Models.DictionaryVersionUpdateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.DictionaryVersionUpdateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback) as Promise<Models.DictionaryVersionUpdateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryVersionActivateResponse>
   */
  activate(options?: Models.DictionaryVersionActivateOptionalParams): Promise<Models.DictionaryVersionActivateResponse>;
  /**
   * @param callback The callback
   */
  activate(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  activate(options: Models.DictionaryVersionActivateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  activate(options?: Models.DictionaryVersionActivateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.DictionaryVersionActivateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      activateOperationSpec,
      callback) as Promise<Models.DictionaryVersionActivateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryVersionDeactivateResponse>
   */
  deactivate(options?: Models.DictionaryVersionDeactivateOptionalParams): Promise<Models.DictionaryVersionDeactivateResponse>;
  /**
   * @param callback The callback
   */
  deactivate(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deactivate(options: Models.DictionaryVersionDeactivateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  deactivate(options?: Models.DictionaryVersionDeactivateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.DictionaryVersionDeactivateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deactivateOperationSpec,
      callback) as Promise<Models.DictionaryVersionDeactivateResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryVersionDeleteMethodResponse>
   */
  deleteMethod(id: number, options?: msRest.RequestOptionsBase): Promise<Models.DictionaryVersionDeleteMethodResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteMethod(id: number, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(id: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  deleteMethod(id: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.DictionaryVersionDeleteMethodResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteMethodOperationSpec,
      callback) as Promise<Models.DictionaryVersionDeleteMethodResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryVersionGetByIdResponse>
   */
  getById(id: number, options?: msRest.RequestOptionsBase): Promise<Models.DictionaryVersionGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: number, callback: msRest.ServiceCallback<Models.DictionaryVersionDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.DictionaryVersionDto>): void;
  getById(id: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DictionaryVersionDto>, callback?: msRest.ServiceCallback<Models.DictionaryVersionDto>): Promise<Models.DictionaryVersionGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.DictionaryVersionGetByIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryVersionGetItemsResponse>
   */
  getItems(id: number, options?: Models.DictionaryVersionGetItemsOptionalParams): Promise<Models.DictionaryVersionGetItemsResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getItems(id: number, callback: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getItems(id: number, options: Models.DictionaryVersionGetItemsOptionalParams, callback: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): void;
  getItems(id: number, options?: Models.DictionaryVersionGetItemsOptionalParams | msRest.ServiceCallback<Models.DictionaryValueItemDto[]>, callback?: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): Promise<Models.DictionaryVersionGetItemsResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getItemsOperationSpec,
      callback) as Promise<Models.DictionaryVersionGetItemsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryVersionCloneResponse>
   */
  clone(options?: Models.DictionaryVersionCloneOptionalParams): Promise<Models.DictionaryVersionCloneResponse>;
  /**
   * @param callback The callback
   */
  clone(callback: msRest.ServiceCallback<Models.Int32IIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  clone(options: Models.DictionaryVersionCloneOptionalParams, callback: msRest.ServiceCallback<Models.Int32IIdDto>): void;
  clone(options?: Models.DictionaryVersionCloneOptionalParams | msRest.ServiceCallback<Models.Int32IIdDto>, callback?: msRest.ServiceCallback<Models.Int32IIdDto>): Promise<Models.DictionaryVersionCloneResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      cloneOperationSpec,
      callback) as Promise<Models.DictionaryVersionCloneResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryVersionExportByIdResponse>
   */
  exportById(options?: Models.DictionaryVersionExportByIdOptionalParams): Promise<Models.DictionaryVersionExportByIdResponse>;
  /**
   * @param callback The callback
   */
  exportById(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  exportById(options: Models.DictionaryVersionExportByIdOptionalParams, callback: msRest.ServiceCallback<void>): void;
  exportById(options?: Models.DictionaryVersionExportByIdOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.DictionaryVersionExportByIdResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      exportByIdOperationSpec,
      callback) as Promise<Models.DictionaryVersionExportByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryVersionImportMethodResponse>
   */
  importMethod(options?: Models.DictionaryVersionImportMethodOptionalParams): Promise<Models.DictionaryVersionImportMethodResponse>;
  /**
   * @param callback The callback
   */
  importMethod(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  importMethod(options: Models.DictionaryVersionImportMethodOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  importMethod(options?: Models.DictionaryVersionImportMethodOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.DictionaryVersionImportMethodResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      importMethodOperationSpec,
      callback) as Promise<Models.DictionaryVersionImportMethodResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "dictionaries/DictionaryVersion",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateDictionaryVersionCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.Int32IIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "dictionaries/DictionaryVersion",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateDictionaryVersionCommand
  },
  responses: {
    200: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const activateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "dictionaries/DictionaryVersion/activate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ActivateDictionaryVersionCommand
  },
  responses: {
    200: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const deactivateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "dictionaries/DictionaryVersion/deactivate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeactivateDictionaryVersionCommand
  },
  responses: {
    200: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "dictionaries/DictionaryVersion/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/DictionaryVersion/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.DictionaryVersionDto
    },
    default: {}
  },
  serializer
};

const getItemsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/DictionaryVersion/{id}/items",
  urlParameters: [
    Parameters.id1
  ],
  queryParameters: [
    Parameters.interventionPaths
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueItemDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const cloneOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "dictionaries/DictionaryVersion/clone",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CloneDictionaryVersionCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.Int32IIdDto
    },
    default: {}
  },
  serializer
};

const exportByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/DictionaryVersion/export",
  queryParameters: [
    Parameters.id2,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const importMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "dictionaries/DictionaryVersion/import",
  formDataParameters: [
    Parameters.file,
    Parameters.isCodeName,
    Parameters.dictionaryId
  ],
  contentType: "multipart/form-data",
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};
