import { BookOutlined } from '@ant-design/icons';
import { UserManualDto } from '@services/src/models';
import { Button } from 'antd';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { Fragment, useRef } from 'react';

import ViewUserManualModal, {
	IViewUserManualModal,
} from './modals/ViewUserManualModal';

interface IProps {
	userManual: UserManualDto;
}

const UserManualViewButton: React.FC<IProps> = (props) => {
	const viewUserManualModalRef = useRef<
		IViewUserManualModal
	>() as React.RefObject<IViewUserManualModal>;

	return (
		<Fragment>
			<ProfiBazaTooltip title="Instrukcja użytkownika">
				<Button
					aria-label="Instrukcja użytownika"
					shape="circle"
					className="manual-menu-item"
					onClick={() => {
						viewUserManualModalRef.current?.showModal(
							props.userManual
						);
					}}
					icon={<BookOutlined />}
				/>
			</ProfiBazaTooltip>

			<ViewUserManualModal
				ref={viewUserManualModalRef}
				isPreview={false}
			/>
		</Fragment>
	);
};

export default UserManualViewButton;
