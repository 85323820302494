import { ajaxCatchSilently } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { Set } from 'core-js';
import { observable } from 'mobx';

export const tagStore = {
	tags: observable.box<string[]>([]),
	initialize: () => {
		ajaxCatchSilently(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.tag.all({
						pageSize: 1000,
						page: 1,
					})
				)
				.then((response) => {
					tagStore.tags.set(
						Array.from(
							new Set<string>(
								response.results?.map((x) => x.tagName!) ?? []
							)
						)
					);
				})
		);
	},
	insertTag(tagName: string) {
		this.tags.set([...this.tags.get(), tagName])
	},
	removeTag(tagName: string) {
		this.tags.set(this.tags.get().filter((x) => x !== tagName));
	},
};

export type ITagStore = typeof tagStore;
