import Authorized from '@authorization/authorized';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import Modal from 'antd/lib/modal/Modal';
import { DictionaryValueDto } from 'components/dictionary/model/CreateDictionaryValueCommand';
import { UpdateDictionaryValueDto } from 'components/dictionary/model/UpdateDictionaryValueCommand';
import { Formik, FormikActions, FormikProps } from 'formik';
import { showConfirm } from 'help/NotificationHelper';
import { useRootData } from 'hooks/hook';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { DictionaryValueItemDto } from 'services/src/models';
import { IGridStore } from 'stores/GridStore';

import AddButton from '../../../shared/buttons/AddButton';
import { createDictionaryValueValidationSchema } from './DictionaryValueValidationSchema';
import { ValuesForm } from './ValuesForm';

export interface DictionaryValuesModalProps {
	dictionaryVersionId: number;
	refresh: (value?: DictionaryValueItemDto) => void;
	isCodeNameInValuesRequired: boolean;
	id?: number;
	setId: (id: number | undefined) => void;
	visible: boolean;
	setVisible: (visible: boolean) => void;
	smallButton?: boolean;
	parentId?: number;
	noButton?: boolean;
}

export const modalPropsAdapter: DictionaryValuesModalProps = {
	dictionaryVersionId: 0,
	isCodeNameInValuesRequired: false,
	id: undefined,
	setId: () => {},
	visible: false,
	setVisible: () => {},
	refresh: () => {},
};

const DictionaryValuesModal: React.FC<DictionaryValuesModalProps> = (props) => {
	const {
		dictionaryVersionId,
		isCodeNameInValuesRequired,
		id,
		visible,
		setVisible,
		setId,
		smallButton,
		parentId,
		noButton,
	} = props;

	const [updateModel, setUpdateModel] = useState<UpdateDictionaryValueDto>();
	const [codeNameEditable, setCodeNameEditable] = useState<boolean>(false);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	useEffect(() => {
		if (id) {
			ajaxCatch(() =>
				getProfiBazaApiClient().then((api) => {
					return api.dictionaryValue
						.getById(id)
						.then((result: ProfiBazaAPIModels.DictionaryValueDto) =>
							setUpdateModel(new UpdateDictionaryValueDto(result))
						);
				})
			);
		}
	}, [id]);

	const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setVisible(false);
		setId(undefined);
		setCodeNameEditable(false);
	};

	const handleCreate = (
		values: DictionaryValueDto,
		actions: FormikActions<ProfiBazaAPIModels.CreateDictionaryValueCommand>
	) => {
		const command = DictionaryValueDto.toCommand(values);
		let createdDictId: number | undefined;
		ajaxByUser(
			'Utworzono wartość słownikową',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.dictionaryValue
						.create({
							body: command,
						})
						.then((response) => {
							createdDictId = response.id;
							return response;
						})
				),
			() => {
				setVisible(false);
				actions.resetForm();
				const mappedValues = DictionaryValueDto.mapToDictionaryValueItem(
					values,
					createdDictId
				);
				props.refresh(mappedValues);
				gridStore.setRefreshDictionaryVersion(true);
			},
			(errors) => {
				actions.setErrors(errors);
			}
		).then(() => actions.setSubmitting(false));
	};

	const handleUpdate = (
		values: UpdateDictionaryValueDto,
		actions: FormikActions<UpdateDictionaryValueDto>
	) => {
		const command = UpdateDictionaryValueDto.toCommand(values);

		const update = () => {
			ajaxByUser(
				'Zaktualizowano wersję słownika',
				() =>
					getProfiBazaApiClient().then((api) =>
						api.dictionaryValue.update({
							body: command,
						})
					),
				() => {
					setVisible(false);
					actions.resetForm();
					setUpdateModel(undefined);
					setId(undefined);
					const mappedValues = DictionaryValueDto.mapToDictionaryValueItem(
						values,
						values.id
					);
					props.refresh(mappedValues);
					gridStore.setRefreshDictionaryVersion(true);
				},
				(errors) => {
					actions.setErrors(errors);
				}
			).then(() => actions.setSubmitting(false));
		};

		if (values.codeName !== updateModel?.codeName) {
			showConfirm(
				'Czy na pewno chcesz zaktualizować identyfikator?',
				update
			);
		} else {
			update();
		}
	};

	return (
		<>
			<Authorized
				permission={
					ProfiBazaAPIModels.Permission
						.DictionariesVersionsModifications
				}
			>
				{!noButton && (
					<AddButton
						tall={!smallButton}
						onClick={() => setVisible(true)}
					>
						Utwórz
					</AddButton>
				)}
			</Authorized>

			{id === undefined ? (
				<Formik
					validateOnChange
					validateOnBlur
					initialValues={{
						dictionaryVersionId,
						code: undefined,
						value: undefined,
						canAddTextValue: false,
						canBeSelected: true,
						codeName: undefined,
						parentId: parentId,
						interventionPaths: undefined,
						breadcrumb: undefined,
					}}
					enableReinitialize
					validationSchema={createDictionaryValueValidationSchema(
						isCodeNameInValuesRequired
					)}
					isInitialValid
					onSubmit={handleCreate}
					render={(
						props: FormikProps<
							ProfiBazaAPIModels.CreateDictionaryValueCommand
						>
					) => (
						<Modal
							title="Utwórz wartość słownikową"
							visible={visible}
							onOk={(e) => {
								if (props.isValid) {
									props.submitForm();
								}
							}}
							okButtonProps={{ disabled: props.isSubmitting }}
							cancelButtonProps={{ disabled: props.isSubmitting }}
							cancelText="Anuluj"
							centered
							onCancel={(
								e: React.MouseEvent<HTMLElement, MouseEvent>
							) => {
								handleCancel(e);
								props.resetForm();
							}}
							okText="Utwórz"
							destroyOnClose
						>
							<ValuesForm
								codeNameEditable={codeNameEditable}
								setCodeNameEditable={setCodeNameEditable}
								formikProps={props}
								editable={false}
							/>
						</Modal>
					)}
				/>
			) : (
				<Formik
					validateOnChange
					validateOnBlur
					initialValues={updateModel || {}}
					enableReinitialize
					validationSchema={createDictionaryValueValidationSchema(
						isCodeNameInValuesRequired
					)}
					isInitialValid
					onSubmit={handleUpdate}
					render={(props: FormikProps<UpdateDictionaryValueDto>) => (
						<Modal
							title="Zaktualizuj wartość słownikową"
							visible={visible}
							onOk={() => {
								if (props.isValid) {
									props.submitForm();
								}
								setCodeNameEditable(false);
							}}
							okButtonProps={{ disabled: props.isSubmitting }}
							cancelButtonProps={{ disabled: props.isSubmitting }}
							cancelText="Anuluj"
							centered
							onCancel={handleCancel}
							okText="Aktualizuj"
							destroyOnClose
						>
							<ValuesForm
								codeNameEditable={codeNameEditable}
								setCodeNameEditable={setCodeNameEditable}
								formikProps={props}
								editable
							/>
						</Modal>
					)}
				/>
			)}
		</>
	);
};

export default observer(DictionaryValuesModal);
