import './SourceDocumentDetails.less';

import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import DocumentTags from '@components/tags/DocumentTags';
import useDescriptionsAccessibilityHook from '@hooks/accessibility/useDescriptionsAccessibilityHook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import {
	Button,
	Col,
	DatePicker,
	Descriptions,
	Row,
	Space,
	Typography,
} from 'antd';
import FileDownload from 'components/shared/file/FileDownload';
import FilePreview from 'components/shared/file/FilePreview';
import { Field, Formik, FormikProps } from 'formik';
import { FFieldLabel, FFieldValue, FInput } from 'forms/FormikFormItems';
import { ajaxCatch } from 'helper/api';
import { DateFormat } from 'helper/formatHelpers';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import PrimaryCard from 'layout/PrimaryCard';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	AotmitDetailsDto,
	DocumentAotmitType,
	DocumentDetailsDto,
	InterventionPath,
} from 'services/src/models';
import { IGridStore } from 'stores/GridStore';

import FileTree from './FileTree';
import { SourceDocumentDetailsModel } from './models/SourceDocumentDetailsModel';

const { Text } = Typography;

interface IProps {
	documentId: string;
	interventionPath?: InterventionPath;
	baseUrl: string;
	onEmptyDocumentsTree: () => void;
	codeName?: string;
	backPath?: string;
	span?: number;
}

export interface ISourceDocumentDetails {
	forceRefresh: () => void;
}

const mediaTreshold = 1500;

const SourceDocumentDetails = React.forwardRef<ISourceDocumentDetails, IProps>(
	(props, ref) => {
		const history = useHistory();
		const match = useRouteMatch<{ fileId?: string }>();

		const [documentDetails, setDocumentDetails] = useState<
			SourceDocumentDetailsModel
		>();

		const [descriptionLayout, setDescriptionLayout] = useState<
			'vertical' | 'horizontal'
		>(
			window.matchMedia(`(min-width: ${mediaTreshold}px)`).matches
				? 'horizontal'
				: 'vertical'
		);

		useDescriptionsAccessibilityHook(documentDetails);

		const gridStore: IGridStore = useRootData((store) => store.gridStore);
		const [isDownloading, setDownloading] = useState<boolean>(false);

		const refresh = () =>
			ajaxCatch(() =>
				getProfiBazaApiClient()
					.then((api) => api.document.getById(props.documentId))
					.then((result) => {
						setDocumentDetails(
							new SourceDocumentDetailsModel(result)
						);
					})
			);

		useEffect(() => {
			refresh();
		}, []);

		useImperativeHandle(ref, () => ({
			forceRefresh: () => refresh(),
		}));

		const getDictionaryCodeName = (): string => {
			if (props.codeName) {
				return props.codeName;
			}

			return documentDetails?.categoryCodeName!;
		};

		React.useLayoutEffect(() => {
			const media = window.matchMedia(`(min-width: ${mediaTreshold}px)`);
			const listener = (e: MediaQueryListEvent) => {
				setDescriptionLayout(e.matches ? 'horizontal' : 'vertical');
			};
			media.addEventListener('change', listener);
			return () => {
				media.removeEventListener('change', listener);
			};
		}, []);

		const backButton = (
			<Button
				size="large"
				onClick={() => {
					if (props.backPath) {
						history.push(props.backPath);
					} else {
						const tab = gridStore.getBackTab() ?? 'interventions';
						history.push(`/documents/${tab}`);
						gridStore.searching.set(false);
					}
				}}
			>
				Wróć
			</Button>
		);

		return (
			<>
				<SkipLinkContent />
				{documentDetails && (
					<CenteredRow>
						<Col
							span={props.span}
							className="aotmit-description-name"
						>
							<h2>{documentDetails.details!.name!}</h2>
						</Col>
						<Col span={props.span}>
							<PrimaryCard>
								{documentDetails.categoryCodeName ==
									DictionariesConstants.AttachmentCategories
										.InterventionAttachmentCategory &&
									!documentDetails.isArchive &&
									renderInterventionDetails(
										documentDetails.details!
									)}

								{documentDetails.categoryCodeName ==
									DictionariesConstants.AttachmentCategories
										.InterventionAttachmentCategory &&
									documentDetails.isArchive &&
									renderArchiveInterventionDetails(
										documentDetails.details!
									)}

								{documentDetails.categoryCodeName ==
									DictionariesConstants.AttachmentCategories
										.StatementAttachmentCategory &&
									!documentDetails.isVoivodeOpinion &&
									!documentDetails.isArchive &&
									renderStatementDetails(
										documentDetails.details!
									)}

								{documentDetails.categoryCodeName ==
									DictionariesConstants.AttachmentCategories
										.StatementAttachmentCategory &&
									!documentDetails.isVoivodeOpinion &&
									documentDetails.isArchive &&
									renderArchiveStatementDetails(
										documentDetails.details!
									)}

								{documentDetails.isVoivodeOpinion &&
									renderVoivodeDetails(
										documentDetails.details!
									)}

								{documentDetails.documentAotmitType ==
									DocumentAotmitType.PPZ &&
									renderPpz(documentDetails.aotmitDetails!)}

								{documentDetails.documentAotmitType ==
									DocumentAotmitType.Recommendation &&
									renderReccomendation(
										documentDetails.aotmitDetails!
									)}

								{documentDetails.documentAotmitType ==
									DocumentAotmitType.DetailedReportAnnex &&
									renderAnnex(documentDetails.aotmitDetails!)}

								{documentDetails.documentAotmitType ==
									DocumentAotmitType.Pronouncement &&
									renderPronouncement(
										documentDetails.aotmitDetails!
									)}

								{documentDetails.documentAotmitType ==
									DocumentAotmitType.FinalReport &&
									renderReport(
										documentDetails.aotmitDetails!
									)}
							</PrimaryCard>
						</Col>
					</CenteredRow>
				)}
				{documentDetails && documentDetails.file?.files && (
					<CenteredRow>
						<Row>
							<Col>
								<PageHeaderWithContent
									title="Lista dokumentów"
									wideTitle={true}
								/>
							</Col>
						</Row>
						<Col span={props.span}>
							<FileTree
								fileTree={documentDetails.file}
								baseUrl={props.baseUrl}
								selectedFileId={match.params.fileId}
								onFileChanged={(isEmptyTree: boolean) => {
									if (isEmptyTree) {
										props.onEmptyDocumentsTree();
									} else {
										history.push(props.baseUrl);
										refresh();
									}
								}}
								interventionPath={props.interventionPath}
								codeName={getDictionaryCodeName()}
								numberOfFiles={documentDetails.numberOfFiles}
								isArchive={documentDetails.isArchive}
							></FileTree>
						</Col>
					</CenteredRow>
				)}
				<CenteredRow>
					<Col span={props.span}>
						{match.params.fileId && (
							<Row>
								<Col span={24}>
									<PageHeaderWithContent
										title="Podgląd dokumentu"
										wideTitle={true}
									/>
								</Col>
							</Row>
						)}
						{['.doc', '.docx', '.xls', '.xlsx', '.rtf'].some((x) =>
							documentDetails?.file?.fileName
								?.toLowerCase()
								.includes(x)
						) && (
							<CenteredRow>
								<h3 style={{ color: 'red' }}>
									Podgląd nie obsługiwany, w celu zapoznania
									się z zawartością należy pobrać dokument
								</h3>
							</CenteredRow>
						)}

						<Row>
							<Col span={20}>
								{documentDetails && (
									<DocumentTags
										documentId={props.documentId}
										tags={documentDetails.tags!}
									/>
								)}
							</Col>
							<Col span={4}>
								<Row className="float-right">
									<Space>
										{backButton}
										{match.params.fileId && (
											<FileDownload
												fileId={match.params.fileId!}
												large
												disabled={isDownloading}
												setDisabled={(value) =>
													setDownloading(value!)
												}
											></FileDownload>
										)}
									</Space>
								</Row>
							</Col>
						</Row>
					</Col>
				</CenteredRow>

				{match.params.fileId && (
					<>
						<CenteredRow>
							<Col span={props.span} style={{ padding: 0 }}>
								<FilePreview
									fileId={match.params.fileId!}
								></FilePreview>
							</Col>
						</CenteredRow>
						{documentDetails && (
							<CenteredRow>
								<Col span={props.span}>
									<Formik
										initialValues={documentDetails}
										enableReinitialize
										validateOnChange={false}
										validateOnBlur={true}
										onSubmit={(values, actions) => {}}
										render={(
											props: FormikProps<
												SourceDocumentDetailsModel
											>
										) => (
											<PrimaryCard>
												<CenteredRow>
													<Col md={4}>
														<FFieldLabel
															label="Data dodania"
															for="createdDate"
														/>
														<DatePicker
															aria-label="Data dodania"
															placeholder="Brak daty aktualizacji"
															id="createdDate"
															name="createdDate"
															value={
																documentDetails?.createdDate
															}
															format={
																DateFormat.Date
															}
															style={{
																width: '100%',
															}}
															showTime
															disabled
														/>
													</Col>
													<Col md={4}>
														<FFieldLabel
															label="Rodzaj zdarzenia"
															for="actionKind"
														/>
														<Field
															component={FInput}
															id="actionKind"
															name="actionKind"
															readOnly
														/>
													</Col>

													<Col md={4}>
														<FFieldLabel
															label="Właściciel"
															for="subjectName"
														/>
														<Field
															component={FInput}
															id="subjectName"
															name="subjectName"
															readOnly
														/>
													</Col>
													<Col md={4}>
														<FFieldLabel
															label="Autor"
															for="createdBy"
														/>
														<Field
															component={FInput}
															id="createdBy"
															name="createdBy"
															readOnly
															validateOnChange
														/>
													</Col>
													<Col md={4}>
														<FFieldLabel
															label="Kanał"
															for="channelKind"
														/>
														<Field
															label="Kanał"
															component={FInput}
															id="channelKind"
															name="channelKind"
															readOnly
														/>
													</Col>
												</CenteredRow>
											</PrimaryCard>
										)}
									/>
								</Col>
							</CenteredRow>
						)}
						<CenteredRow>
							<Col span={props.span}>
								<Row className="float-right">
									<Space>
										{backButton}

										<FileDownload
											fileId={match.params.fileId!}
											large
										></FileDownload>
									</Space>
								</Row>
							</Col>
						</CenteredRow>
					</>
				)}
			</>
		);
	}
);

const renderValue = (labelValue: string, value: any) => {
	return (
		<Row>
			<Col span={8} style={{ textAlign: 'right' }}>
				<Text strong>{labelValue}</Text>
				<Text strong>:</Text>
			</Col>
			<Col span={1}></Col>
			<Col span={15}>
				<FFieldValue
					label={value}
					className="intervention-info-value"
				/>
			</Col>
		</Row>
	);
};

const renderInterventionDetails = (details: DocumentDetailsDto) => (
	<>
		<Row>
			<Col span={12}>{renderValue('Status', details.status)}</Col>
			<Col span={12}>{renderValue('Podmiot', details.subjectName)}</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue('Województwo', details.voivodeship)}
			</Col>
			<Col span={12}>
				{renderValue(
					'Data od',
					details.dateFrom
						? moment(details.dateFrom).format(DateFormat.Date)
						: ''
				)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue(
					'Data do',
					details.dateTo
						? moment(details.dateTo).format(DateFormat.Date)
						: ''
				)}
			</Col>
			<Col span={12}>
				{renderValue(
					'Data utworzenia',
					details.createdDate
						? moment(details.createdDate).format(DateFormat.Date)
						: ''
				)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue(
					'Rok wydania opinii',
					details.opinionPresidentYear
				)}
			</Col>
			<Col span={12}>{renderValue('Numer AOTMiT', details.number)}</Col>
		</Row>
		<Row>
			<Col span={12}>{renderValue('Powiat', details.county)}</Col>
			<Col span={12}>{renderValue('Gmina', details.commune)}</Col>
		</Row>
	</>
);

const renderArchiveInterventionDetails = (details: DocumentDetailsDto) => (
	<>
		<Row>
			<Col span={12}>{renderValue('Podmiot', details.subjectName)}</Col>
			<Col span={12}>
				{renderValue('Województwo', details.voivodeship)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue(
					'Data od',
					details.dateFrom
						? moment(details.dateFrom).format(DateFormat.Date)
						: ''
				)}
			</Col>
			<Col span={12}>
				{renderValue(
					'Data do',
					details.dateTo
						? moment(details.dateTo).format(DateFormat.Date)
						: ''
				)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue(
					'Data utworzenia',
					details.createdDate
						? moment(details.createdDate).format(DateFormat.Date)
						: ''
				)}
			</Col>
			<Col span={12}>
				{renderValue(
					'Rok wydania opinii',
					details.opinionPresidentYear
				)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>{renderValue('Numer AOTMiT', details.number)}</Col>
			<Col span={12}>{renderValue('Powiat', details.county)}</Col>
		</Row>
		<Row>
			<Col span={12}></Col>
			<Col span={12}>{renderValue('Gmina', details.commune)}</Col>
		</Row>
	</>
);

const renderStatementDetails = (details: DocumentDetailsDto) => (
	<>
		<Row>
			<Col span={12}>
				{renderValue('Rodzaj procesu', details.processKind)}
			</Col>
			<Col span={12}>{renderValue('Status', details.status)}</Col>
		</Row>
		<Row>
			<Col span={12}>{renderValue('Rok', details.year ?? '')}</Col>
			<Col span={12}>{renderValue('Podmiot', details.subjectName)}</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue('Województwo', details.voivodeship)}
			</Col>
			<Col span={12}>{renderValue('Powiat', details.county)}</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue(
					'Data utworzenia',
					details.createdDate
						? moment(details.createdDate).format(DateFormat.Date)
						: ''
				)}
			</Col>
			<Col span={12}>{renderValue('Gmina', details.commune)}</Col>
		</Row>
	</>
);

const renderArchiveStatementDetails = (details: DocumentDetailsDto) => (
	<>
		<Row>
			<Col span={12}>
				{renderValue('Rodzaj procesu', details.processKind)}
			</Col>
			<Col span={12}>{renderValue('Rok', details.year ?? '')}</Col>
		</Row>
		<Row>
			<Col span={12}>{renderValue('Podmiot', details.subjectName)}</Col>
			<Col span={12}>
				{renderValue('Województwo', details.voivodeship)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>{renderValue('Powiat', details.county)}</Col>
			<Col span={12}>
				{renderValue(
					'Data utworzenia',
					details.createdDate
						? moment(details.createdDate).format(DateFormat.Date)
						: ''
				)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>{renderValue('Gmina', details.commune)}</Col>
			<Col span={12}></Col>
		</Row>
	</>
);

const renderVoivodeDetails = (details: DocumentDetailsDto) => (
	<>
		<Row>
			<Col span={12}>
				{renderValue('Rodzaj procesu', details.processKind)}
			</Col>
			<Col span={12}>{renderValue('Rok', details.year ?? '')}</Col>
		</Row>
		<Row>
			<Col span={12}>{renderValue('Podmiot', details.subjectName)}</Col>
			<Col span={12}>
				{renderValue('Województwo', details.voivodeship)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue(
					'Data utworzenia',
					details.createdDate
						? moment(details.createdDate).format(DateFormat.Date)
						: ''
				)}
			</Col>
			<Col span={12}></Col>
		</Row>
	</>
);

const renderPpz = (details: AotmitDetailsDto) => (
	<>
		<Row>
			<Col span={12}>
				{renderValue(
					'Nazwa podmiotu przesyłającego',
					details.subjectName
				)}
			</Col>
			<Col span={12}>
				{renderValue('Rok rozpoczęcia', details.programYearFrom)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue('Rodzaj podmiotu', details.documentSubjectKind)}
			</Col>
			<Col span={12}>
				{renderValue('Rok zakończenia', details.programYearTo)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue('Województwo', details.voivodeship)}
			</Col>
			<Col span={12}>
				{renderValue('Rok nadesłania programu', details.year)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>{renderValue('Powiat', details.county)}</Col>
			<Col span={12}>
				{renderValue(
					'Rodzaj opinii Prezesa',
					details.presidentOpinionKind
				)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue('Problem decyzyjny', details.decisionProblem)}
			</Col>
			<Col span={12}>
				{renderValue(
					'Rok wydania opinii Prezesa',
					details.opinionPresidentYear
				)}
			</Col>
		</Row>
		<Row>
			<Col span={12}></Col>
			<Col span={12}>{renderValue('Numer AOTMiT', details.number)}</Col>
		</Row>
	</>
);

const renderAnnex = (details: AotmitDetailsDto) => (
	<>
		<Row>
			<Col span={12}>
				{renderValue('Rok opracowania Aneksu', details.year)}
			</Col>
			<Col span={12}>
				{renderValue('Problem decyzyjny', details.decisionProblem)}
			</Col>
		</Row>
	</>
);

const renderReport = (details: AotmitDetailsDto) => (
	<>
		<Row>
			<Col span={12}>
				{renderValue('Rok Przysłania Raportu', details.year)}
			</Col>
			<Col span={12}>{renderValue('Numer Raportu', details.number)}</Col>
		</Row>
	</>
);

const renderPronouncement = (details: AotmitDetailsDto) => (
	<>
		<Row>
			<Col span={12}>
				{renderValue('Rok przysłania Oświadczenia', details.year)}
			</Col>
			<Col span={12}>
				{renderValue('Numer Rekomendacji', details.number)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue('Województwo', details.voivodeship)}
			</Col>
			<Col span={12}>{renderValue('Powiat', details.county)}</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue('Problem decyzyjny', details.decisionProblem)}
			</Col>
			<Col span={12}>
				{renderValue('Rok rozpoczęcia', details.programYearFrom)}
			</Col>
		</Row>
		<Row>
			<Col span={12}></Col>
			<Col span={12}>
				{renderValue('Rok zakończenia', details.programYearTo)}
			</Col>
		</Row>
	</>
);

const renderReccomendation = (details: AotmitDetailsDto) => (
	<>
		<Row>
			<Col span={12}>
				{renderValue('Rok opracowania Rekomendacji', details.year)}
			</Col>
			<Col span={12}>
				{renderValue('Numer Rekomendacji', details.number)}
			</Col>
		</Row>
		<Row>
			<Col span={12}>
				{renderValue('Problem decyzyjny', details.decisionProblem)}
			</Col>
			<Col span={12}></Col>
		</Row>
	</>
);

export default SourceDocumentDetails;
