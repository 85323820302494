/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/statementsFormMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a StatementsForm. */
export class StatementsForm {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a StatementsForm.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsFormGetHeaderByStatementIdResponse>
   */
  getHeaderByStatementId(id: string, options?: msRest.RequestOptionsBase): Promise<Models.StatementsFormGetHeaderByStatementIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getHeaderByStatementId(id: string, callback: msRest.ServiceCallback<Models.StatementHeaderDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getHeaderByStatementId(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.StatementHeaderDto>): void;
  getHeaderByStatementId(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.StatementHeaderDto>, callback?: msRest.ServiceCallback<Models.StatementHeaderDto>): Promise<Models.StatementsFormGetHeaderByStatementIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getHeaderByStatementIdOperationSpec,
      callback) as Promise<Models.StatementsFormGetHeaderByStatementIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsFormGetInterventionSectionByStatementIdResponse>
   */
  getInterventionSectionByStatementId(id: string, options?: msRest.RequestOptionsBase): Promise<Models.StatementsFormGetInterventionSectionByStatementIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getInterventionSectionByStatementId(id: string, callback: msRest.ServiceCallback<Models.MZ49AutocompletionDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getInterventionSectionByStatementId(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.MZ49AutocompletionDto>): void;
  getInterventionSectionByStatementId(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.MZ49AutocompletionDto>, callback?: msRest.ServiceCallback<Models.MZ49AutocompletionDto>): Promise<Models.StatementsFormGetInterventionSectionByStatementIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getInterventionSectionByStatementIdOperationSpec,
      callback) as Promise<Models.StatementsFormGetInterventionSectionByStatementIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  saveStatementSection(options?: Models.StatementsFormSaveStatementSectionOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  saveStatementSection(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  saveStatementSection(options: Models.StatementsFormSaveStatementSectionOptionalParams, callback: msRest.ServiceCallback<void>): void;
  saveStatementSection(options?: Models.StatementsFormSaveStatementSectionOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      saveStatementSectionOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsFormGetNPZStatementByStatementIdResponse>
   */
  getNPZStatementByStatementId(id: string, options?: msRest.RequestOptionsBase): Promise<Models.StatementsFormGetNPZStatementByStatementIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getNPZStatementByStatementId(id: string, callback: msRest.ServiceCallback<Models.NPZAutocompletionDto[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getNPZStatementByStatementId(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.NPZAutocompletionDto[]>): void;
  getNPZStatementByStatementId(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.NPZAutocompletionDto[]>, callback?: msRest.ServiceCallback<Models.NPZAutocompletionDto[]>): Promise<Models.StatementsFormGetNPZStatementByStatementIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getNPZStatementByStatementIdOperationSpec,
      callback) as Promise<Models.StatementsFormGetNPZStatementByStatementIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.StatementsFormGetStatementPartsResponse>
   */
  getStatementParts(id: string, options?: msRest.RequestOptionsBase): Promise<Models.StatementsFormGetStatementPartsResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getStatementParts(id: string, callback: msRest.ServiceCallback<Models.StatementPartDto[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getStatementParts(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.StatementPartDto[]>): void;
  getStatementParts(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.StatementPartDto[]>, callback?: msRest.ServiceCallback<Models.StatementPartDto[]>): Promise<Models.StatementsFormGetStatementPartsResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getStatementPartsOperationSpec,
      callback) as Promise<Models.StatementsFormGetStatementPartsResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getHeaderByStatementIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/StatementsForm/header/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.StatementHeaderDto
    },
    default: {}
  },
  serializer
};

const getInterventionSectionByStatementIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/StatementsForm/mz49/interventions/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.MZ49AutocompletionDto
    },
    default: {}
  },
  serializer
};

const saveStatementSectionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "statements/StatementsForm",
  queryParameters: [
    Parameters.statementId,
    Parameters.formPartId,
    Parameters.document
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getNPZStatementByStatementIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/StatementsForm/npz/interventions/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NPZAutocompletionDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getStatementPartsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "statements/StatementsForm/parts/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StatementPartDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};
