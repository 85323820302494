import { IAccountDetailsStore } from 'account/accountDetailsStore';
import Table, { ColumnProps } from 'antd/lib/table';
import PermissionValidator from 'authorization/permissionValidator';
import AddButton from 'components/shared/buttons/AddButton';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { DateFormat } from 'helper/formatHelpers';
import { useRootData } from 'hooks/hook';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import RoleModal from './RoleModal';
import RolePermissionsTable from './RolePermissionsTable';
import { RoleDto } from './UpdateUserRolesCommand';

interface IProps {
	roles: ProfiBazaAPIModels.RoleValueDto[];
	rolePermissions: ProfiBazaAPIModels.RolePermissionDto[];
	addRole: (role: RoleDto) => Promise<void>;
	deleteRole: (role: RoleDto) => Promise<void>;
}

interface IState {
	roles: RoleDto[];
	isModalVisible: boolean;
	modalMode: 'new' | 'edit';
	editedRole?: RoleDto;
}

const RolesTable = (props: IProps) => {
	const [state, setState] = useState<IState>({
		roles: props.roles.map((x) => new RoleDto(x)),
		modalMode: 'new',
		isModalVisible: false,
	});

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const permissionValidator = new PermissionValidator(
		accountDetailsStore.account.get()!
	);

	const columns: ColumnProps<RoleDto>[] = [
		{
			dataIndex: 'roleName',
			key: 'roleName',
			title: 'Rola',
		},
		{
			dataIndex: 'validFrom',
			key: 'validFrom',
			title: 'Data Od',
			width: '20%',
			render: (value?: Moment) => value?.format(DateFormat.Date),
		},
		{
			dataIndex: 'validTo',
			key: 'validTo',
			title: 'Data Do',
			width: '20%',
			render: (value?: Moment) => value?.format(DateFormat.Date),
		},

		RenderActions<RoleDto>((text, record, index) => (
			<>
				{(permissionValidator.has(
					ProfiBazaAPIModels.Permission.AdminRolesAssignmentGlobal
				) ||
					(permissionValidator.has(
						ProfiBazaAPIModels.Permission.AdminRolesAssignmentLocal
					) &&
						record.category ===
							ProfiBazaAPIModels.RoleCategory.LOCAL)) && (
					<>
						<ProfiBazaTooltip placement="top" title="Edytuj">
							<TableActionButton
								kind="EDIT"
								label="Edytuj"
								onClick={() => {
									setState({
										...state,
										editedRole: record,
										isModalVisible: true,
										modalMode: 'edit',
									});
								}}
							/>
						</ProfiBazaTooltip>
						<ProfiBazaTooltip placement="top" title="Usuń">
							<AccessiblePopconfirm
								title={
									<span>
										{`Czy na pewno chcesz usunąć role ${record.roleName}?`}
									</span>
								}
								onConfirm={() => {
									props.deleteRole!(record);
								}}
							>
								<TableActionButton label="Usuń" kind="DELETE" />
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					</>
				)}
			</>
		)),
	];

	useEffect(() => {
		setState({
			...state,
			roles: props.roles.map((x) => new RoleDto(x)),
		});
	}, [props.roles]);

	const addRole = (role: RoleDto): boolean => {
		if (
			state.roles.filter(
				(x) =>
					x.roleId === role.roleId &&
					(state.editedRole === undefined ||
						state.editedRole.roleId !== role.roleId)
			).length > 0
		) {
			return false;
		}

		props.addRole!(role);
		setState({
			...state,
			isModalVisible: false,
			editedRole: undefined,
		});

		return true;
	};

	return (
		<>
			<PageHeaderWithContent
				title="Role"
				left={
					<AddButton
						label="Dodaj"
						onClick={() => {
							setState({
								...state,
								modalMode: 'new',
								isModalVisible: true,
								editedRole: undefined,
							});
						}}
					>
						Dodaj
					</AddButton>
				}
			/>

			<Table<RoleDto>
				size="small"
				columns={columns}
				dataSource={state.roles}
				pagination={false}
			/>

			<RolePermissionsTable
				rolePermissions={props.rolePermissions}
			></RolePermissionsTable>

			<RoleModal
				mode={state.modalMode}
				onCancel={() =>
					setState({
						...state,
						isModalVisible: false,
						editedRole: undefined,
					})
				}
				onSubmit={addRole}
				visible={state.isModalVisible}
				role={state.editedRole}
			></RoleModal>
		</>
	);
};

export default RolesTable;
