import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { ajaxCatch } from '@helper/api';
import { useRootData } from '@hooks/hook';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { AutoComplete, Button, Input, Popover, Tag } from 'antd';
import clsx from 'clsx';
import { TweenOneGroup } from 'rc-tween-one';
import React, { useEffect, useState } from 'react';
import { ITagStore } from 'stores/TagStore';

interface IProps {
	tags: string[];
	addTag: (tag: string, onSuccess: () => void, onError: () => void) => void;
	removeTag: (tag: string, onError: () => void) => void;
}

interface IState {
	tags: string[];
	inputVisible: boolean;
	inputValue: string;
}

interface IOption {
	value: string;
}

const Tags: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({
		inputValue: '',
		inputVisible: false,
		tags: [],
	});
	const [options, setOptions] = useState<IOption[]>([]);
	const [isLabelValid, setIsLabelValid] = useState<boolean>(true);
	const tagStore: ITagStore = useRootData((store) => store.tagStore);

	useEffect(() => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.tag.getTagSuggestions())
				.then((response) =>
					setOptions(
						response.map((x) => ({
							value: x,
						}))
					)
				)
		);
	}, []);

	useEffect(() => {
		setState({ ...state, tags: props.tags });
	}, [props.tags]);

	const handleClose = (removedTag: string) => {
		const tags = state.tags;

		setState({
			...state,
			tags: state.tags.filter((tag) => tag !== removedTag),
		});

		props.removeTag(removedTag, () =>
			setState((prevState) => ({ ...prevState, tags: tags }))
		);
	};

	const showInput = () => {
		setState({ ...state, inputVisible: true });
	};

	const handleInputChange = (value: string) => {
		setState({
			...state,
			inputValue: value,
		});
		setIsLabelValid(!value.includes(' '));
	};

	const handleInputConfirm = (tag?: string) => {
		if (tag === undefined) {
			tag = state.inputValue;
		}

		if (tag !== '' && !state.tags.includes(tag) && !tag.includes(' ')) {
			const tags = state.tags;

			setState({
				tags: [...state.tags, tag].filter((x) => x !== ''),
				inputVisible: false,
				inputValue: '',
			});

			props.addTag(
				tag,
				() => {
					!tagStore.tags.get().includes(tag!) &&
						tagStore.initialize();
				},
				() => {
					setState((prevState) => ({
						...prevState,
						tags: tags,
					}));
				}
			);
		}
	};

	const forMap = (tag: string) => {
		const tagElem = (
			<Tag
				className="closeable-tag"
				closable
				onClose={(e) => {
					e.preventDefault();
					handleClose(tag);
				}}
				closeIcon={
					<Button
						size="small"
						aria-label="Usuń"
						className="icon--revert-color"
						icon={<CloseOutlined />}
					></Button>
				}
			>
				{tag}
			</Tag>
		);
		return (
			<span key={tag} style={{ display: 'inline-block' }}>
				{tagElem}
			</span>
		);
	};

	const tagChild = state.tags.map(forMap);
	return (
		<>
			<div style={{ marginBottom: 16 }}>
				<TweenOneGroup
					enter={{
						scale: 0.8,
						opacity: 0,
						type: 'from',
						duration: 100,
					}}
					leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
					appear={false}
				>
					{tagChild}
					{state.inputVisible && (
						<div
							style={{ display: 'inline' }}
							className={clsx(!isLabelValid && 'form-error')}
						>
							<Popover
								overlayStyle={{
									visibility: !isLabelValid
										? 'visible'
										: 'hidden',
								}}
								content={
									<div className={'form-error-label'}>
										Tag nie może zawierać pustych znaków
									</div>
								}
							>
								<AutoComplete
									options={options}
									size="small"
									value={state.inputValue}
									onChange={handleInputChange}
									onBlur={() =>
										setState({
											...state,
											inputVisible: false,
											inputValue: '',
										})
									}
									autoFocus
									defaultOpen
									dropdownMatchSelectWidth
									style={{ width: 200, minWidth: 'unset' }}
									onSelect={handleInputConfirm}
									filterOption={(inputValue, option) =>
										option?.value
											.toUpperCase()
											.indexOf(
												inputValue.toUpperCase()
											) !== -1
									}
								>
									<Input
										aria-label="wpisz tag"
										size="small"
										type="text"
										autoFocus={true}
										onPressEnter={() =>
											handleInputConfirm()
										}
									/>
								</AutoComplete>
							</Popover>
						</div>
					)}
					{!state.inputVisible && (
						<Tag onClick={showInput} className="wcag">
							<Button size="small">
								<PlusOutlined /> Dodaj tag
							</Button>
						</Tag>
					)}
				</TweenOneGroup>
			</div>
		</>
	);
};

export default Tags;
