/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/userNotificationSettingsMappers";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a UserNotificationSettings. */
export class UserNotificationSettings {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a UserNotificationSettings.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UserNotificationSettingsGetAllResponse>
   */
  getAll(options?: msRest.RequestOptionsBase): Promise<Models.UserNotificationSettingsGetAllResponse>;
  /**
   * @param callback The callback
   */
  getAll(callback: msRest.ServiceCallback<Models.UserNotificationSettingsDTO[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAll(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.UserNotificationSettingsDTO[]>): void;
  getAll(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.UserNotificationSettingsDTO[]>, callback?: msRest.ServiceCallback<Models.UserNotificationSettingsDTO[]>): Promise<Models.UserNotificationSettingsGetAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getAllOperationSpec,
      callback) as Promise<Models.UserNotificationSettingsGetAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UserNotificationSettingsActivateResponse>
   */
  activate(options?: Models.UserNotificationSettingsActivateOptionalParams): Promise<Models.UserNotificationSettingsActivateResponse>;
  /**
   * @param callback The callback
   */
  activate(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  activate(options: Models.UserNotificationSettingsActivateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  activate(options?: Models.UserNotificationSettingsActivateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UserNotificationSettingsActivateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      activateOperationSpec,
      callback) as Promise<Models.UserNotificationSettingsActivateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UserNotificationSettingsDeactivateResponse>
   */
  deactivate(options?: Models.UserNotificationSettingsDeactivateOptionalParams): Promise<Models.UserNotificationSettingsDeactivateResponse>;
  /**
   * @param callback The callback
   */
  deactivate(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deactivate(options: Models.UserNotificationSettingsDeactivateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  deactivate(options?: Models.UserNotificationSettingsDeactivateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UserNotificationSettingsDeactivateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deactivateOperationSpec,
      callback) as Promise<Models.UserNotificationSettingsDeactivateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UserNotificationSettingsUpdateChannelsResponse>
   */
  updateChannels(options?: Models.UserNotificationSettingsUpdateChannelsOptionalParams): Promise<Models.UserNotificationSettingsUpdateChannelsResponse>;
  /**
   * @param callback The callback
   */
  updateChannels(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateChannels(options: Models.UserNotificationSettingsUpdateChannelsOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateChannels(options?: Models.UserNotificationSettingsUpdateChannelsOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UserNotificationSettingsUpdateChannelsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateChannelsOperationSpec,
      callback) as Promise<Models.UserNotificationSettingsUpdateChannelsResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getAllOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "userNotificationSettings/UserNotificationSettings",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UserNotificationSettingsDTO"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const activateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "userNotificationSettings/UserNotificationSettings/activate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ActivateUserNotificationCommand
  },
  responses: {
    204: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const deactivateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "userNotificationSettings/UserNotificationSettings/deactivate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeactivateUserNotificationCommand
  },
  responses: {
    204: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const updateChannelsOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "userNotificationSettings/UserNotificationSettings/updateChannels",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateUserNotificationChannelsCommand
  },
  responses: {
    204: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};
