import { ajaxCatch } from '@helper/api';
import { Col, Form, Modal } from 'antd';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import SearchBar from 'components/shared/paginatedProfiBazaTable/SearchBar';
import RenderActions from 'components/statements/table/RenderActions';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { FormType, OrbeonFormDefinition } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';

import PaginatedProfiBazaTable from '../../shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import { SieveModel } from '../../shared/paginatedProfiBazaTable/SieveModels';
import columns from './OrbeonFormTableColumn';

interface IProps {
	visible: boolean;
	acceptForm?: (value: OrbeonFormDefinition | undefined) => void;
	onCancel: () => void;
	type: FormType;
	mode?: 'checkbox' | undefined;
	selectedRows?: Array<string>;
}

export interface IFormPartsTableModal {
	resetForm: () => void;
}

const OrbeonFormTable: React.FC<IProps> = (props) => {
	const [filter, setFilter] = useState<string | undefined>(undefined);
	const [tableFilters, setTableFilters] = useState<
		Record<keyof OrbeonFormDefinition, string[]> | undefined
	>(undefined);
	const [clearAll, setClearAll] = useState<boolean>(false);
	const [tableLoaded, setTableLoaded] = useState<boolean>(false);
	const [tableSorters, setTableSorters] = useState<
		| Record<
				keyof ProfiBazaAPIModels.OrbeonFormDefinition,
				'descend' | 'ascend' | null | undefined
		  >
		| undefined
	>(undefined);

	useEffect(() => {
		let selectList: HTMLInputElement[] = [];
		Array.from(document.getElementsByClassName('ant-modal')).forEach(
			(el) =>
				(selectList = [
					...selectList,
					...Array.from(el.getElementsByTagName('input')),
				])
		);
		Array.from(selectList).forEach((el) => {
			if (el.getAttribute('role') == 'combobox') {
				el.setAttribute('aria-label', 'Wybierz');
			}
		});
		let buttonList: HTMLButtonElement[] = [];
		Array.from(document.getElementsByClassName('ant-modal')).forEach(
			(el) =>
				(buttonList = [
					...buttonList,
					...Array.from(el.getElementsByTagName('button')),
				])
		);
		Array.from(buttonList).forEach((el) => {
			if (!el.getAttribute('aria-label')) {
				el.setAttribute('aria-label', 'Przycisk');
			}
		});
	}, [tableLoaded]);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	let columnsButtons: ProfiBazaColumnProps<OrbeonFormDefinition>[] = [
		RenderActions<OrbeonFormDefinition>(
			(text, record) => (
				<>
					<ProfiBazaTooltip placement="top" title="Wybierz">
						<TableActionButton
							kind="SELECT"
							label="Wybierz"
							onClick={() => {
								if (props.acceptForm) props.acceptForm(record);
							}}
						/>
					</ProfiBazaTooltip>
				</>
			),
			{
				width: 55,
			}
		),
		...columns(clearAll, setClearAll, tableFilters, tableSorters),
	];

	return (
		<Modal
			destroyOnClose
			footer=""
			title={'Wybierz formularz'}
			visible={props.visible}
			width={'80%'}
			onCancel={() => {
				gridStore.clearGridFilter('mergeSubject');
				props.onCancel();
			}}
			onOk={() => {
				props.onCancel();
			}}
		>
			<CenteredRow gutter={[16, 24]} style={{ marginTop: 32 }}>
				<Col span={5}></Col>
				<Col span={13}>
					<Form size="large">
						<SearchBar
							placeholder="Wyszukaj formularz"
							column="(formName|applicationName|title)"
							filter="contains"
							gridName="orbeonForms"
							adaptiveSearchReducer={(val) => {
								setFilter(val);
								return {
									filter: 'contains',
									column: '(formName|applicationName|title)',
								};
							}}
						/>
					</Form>
				</Col>
				<Col span={5}></Col>
			</CenteredRow>
			<CenteredRow>
				<Col span={23}>
					<PaginatedProfiBazaTable<OrbeonFormDefinition>
						gridName="orbeonForms"
						columns={columnsButtons}
						getRowKey={(r: OrbeonFormDefinition) => r.formName!}
						clearFilters={() => {
							setTableFilters(undefined);
							setClearAll(true);
							setFilter(undefined);
						}}
						showClearFilterButton={true}
						hidePersonalizationSettings={true}
						getFilters={(f, s) => {
							setTableFilters(f);
							setTableSorters({
								...tableSorters!,
								[s.field as string]: s.order,
							});
						}}
						getPagedResult={(
							sieve: SieveModel,
							filters,
							abortSignal
						) => {
							return ajaxCatch(() => {
								return getProfiBazaApiClient().then((api) => {
									return api.orbeon
										.getByType(props.type, { abortSignal })
										.then((result) => {
											return result.filter(
												(x) =>
													!filter ||
													x.applicationName
														?.toLocaleLowerCase()
														.includes(
															filter.toLocaleLowerCase()
														) ||
													x.formName
														?.toLocaleLowerCase()
														.includes(
															filter.toLocaleLowerCase()
														) ||
													x.title
														?.toLocaleLowerCase()
														.includes(
															filter.toLocaleLowerCase()
														)
											);
										});
								});
							});
						}}
						isLoaded={() => setTableLoaded(true)}
					/>
				</Col>
			</CenteredRow>
		</Modal>
	);
};

export default observer(OrbeonFormTable);
