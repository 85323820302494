import React from 'react';
import { FontSizeOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';

interface IProps {
	zoomLevel: number;
	setZoomLevel: (newValue: number) => void;
}

const ZOOM_TRESHOLD = 20;
export const DEFAULT_ZOOM_LEVEL = 100;

const ZoomConfiguration: React.FC<IProps> = ({ zoomLevel, setZoomLevel }) => {
	return (
		<Space>
			<Button
				type="ghost"
				size="middle"
				shape="round"
				disabled={zoomLevel !== null && zoomLevel <= DEFAULT_ZOOM_LEVEL}
				onClick={() => {
					setZoomLevel(zoomLevel! - ZOOM_TRESHOLD);
				}}
			>
				Pomniejsz
			</Button>
			<Button
				type="ghost"
				size="middle"
				shape="round"
				icon={<FontSizeOutlined />}
				onClick={() => {
					setZoomLevel(DEFAULT_ZOOM_LEVEL);
				}}
			>
				Domyślny
			</Button>
			<Button
				type="ghost"
				size="middle"
				shape="round"
				disabled={zoomLevel !== null && zoomLevel >= 200}
				onClick={() => {
					setZoomLevel(zoomLevel + ZOOM_TRESHOLD);
				}}
			>
				Powiększ
			</Button>
		</Space>
	);
};

export default ZoomConfiguration;
