/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/formMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Form. */
export class Form {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Form.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.FormAllResponse>
   */
  all(options?: msRest.RequestOptionsBase): Promise<Models.FormAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.FormItemDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.FormItemDto[]>): void;
  all(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.FormItemDto[]>, callback?: msRest.ServiceCallback<Models.FormItemDto[]>): Promise<Models.FormAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.FormAllResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.FormGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.FormGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.FormDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.FormDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.FormDto>, callback?: msRest.ServiceCallback<Models.FormDto>): Promise<Models.FormGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.FormGetByIdResponse>;
  }

  /**
   * @param type Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53',
   * 'AOTMiTFinalReport', 'ActivityProhealthActivities', 'ActivityResearch',
   * 'ActivitySupervisionMonitoring', 'ActivitySupervisionSanitary', 'ActivityStaffEducation',
   * 'ActivityStaffMonitoring', 'ActivityLegislation', 'InterventionProhealthActivities',
   * 'InterventionResearch', 'InterventionSupervisionMonitoring', 'InterventionSupervisionSanitary',
   * 'InterventionStaffEducation', 'InterventionStaffMonitoring', 'InterventionLegislation'
   * @param [options] The optional parameters
   * @returns Promise<Models.FormGetByTypeResponse>
   */
  getByType(type: Models.FormType, options?: msRest.RequestOptionsBase): Promise<Models.FormGetByTypeResponse>;
  /**
   * @param type Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53',
   * 'AOTMiTFinalReport', 'ActivityProhealthActivities', 'ActivityResearch',
   * 'ActivitySupervisionMonitoring', 'ActivitySupervisionSanitary', 'ActivityStaffEducation',
   * 'ActivityStaffMonitoring', 'ActivityLegislation', 'InterventionProhealthActivities',
   * 'InterventionResearch', 'InterventionSupervisionMonitoring', 'InterventionSupervisionSanitary',
   * 'InterventionStaffEducation', 'InterventionStaffMonitoring', 'InterventionLegislation'
   * @param callback The callback
   */
  getByType(type: Models.FormType, callback: msRest.ServiceCallback<Models.FormDto>): void;
  /**
   * @param type Possible values include: 'StatementNPZ', 'StatementMZ49', 'StatementMZ53',
   * 'AOTMiTFinalReport', 'ActivityProhealthActivities', 'ActivityResearch',
   * 'ActivitySupervisionMonitoring', 'ActivitySupervisionSanitary', 'ActivityStaffEducation',
   * 'ActivityStaffMonitoring', 'ActivityLegislation', 'InterventionProhealthActivities',
   * 'InterventionResearch', 'InterventionSupervisionMonitoring', 'InterventionSupervisionSanitary',
   * 'InterventionStaffEducation', 'InterventionStaffMonitoring', 'InterventionLegislation'
   * @param options The optional parameters
   * @param callback The callback
   */
  getByType(type: Models.FormType, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.FormDto>): void;
  getByType(type: Models.FormType, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.FormDto>, callback?: msRest.ServiceCallback<Models.FormDto>): Promise<Models.FormGetByTypeResponse> {
    return this.client.sendOperationRequest(
      {
        type,
        options
      },
      getByTypeOperationSpec,
      callback) as Promise<Models.FormGetByTypeResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "forms/Form",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormItemDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "forms/Form/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.FormDto
    },
    default: {}
  },
  serializer
};

const getByTypeOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "forms/Form/type/{type}",
  urlParameters: [
    Parameters.type
  ],
  responses: {
    200: {
      bodyMapper: Mappers.FormDto
    },
    default: {}
  },
  serializer
};
