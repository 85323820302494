import Modal from 'antd/lib/modal/Modal';
import React, { useImperativeHandle, useState } from 'react';

export interface IResolveCommentModal {
	showModal: (commentId: string) => void;
}

interface IProps {
	onAccept: (commentId: string) => void;
	onCancel?: () => void;
}

const InterventionResolveCommentModal = React.forwardRef<
	IResolveCommentModal,
	IProps
>((props, ref) => {
	const [visible, setVisible] = useState<boolean>(false);

	const [commentId, setCommentId] = useState<string>();

	useImperativeHandle(ref, () => ({
		showModal: (commentId: string) => {
			setVisible(true);
			setCommentId(commentId);
		},
	}));

	return (
		<Modal
			destroyOnClose
			centered
			title="Oświadczenie"
			visible={visible}
			cancelText="Wróć"
			okText="Potwierdź"
			onOk={() => {
				setVisible(false);
				props.onAccept(commentId!);
			}}
			onCancel={() => setVisible(false)}
		>
			<h4>
				Oświadczam, że wszystkie uwagi AOTMiT zostały uwzględnione w
				interwencji.
			</h4>
		</Modal>
	);
});

export default InterventionResolveCommentModal;
