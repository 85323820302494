import { ajaxCatch } from '@helper/api';
import { Col, Form, Modal, Select } from 'antd';
import { RowSelectionType } from 'antd/lib/table/interface';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import SearchBar from 'components/shared/paginatedProfiBazaTable/SearchBar';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	SubjectAllOptionalParams,
	SubjectListItemDto,
} from 'services/src/models';
import { IGridStore } from 'stores/GridStore';

import { defaultColumns } from '../subjects/SubjectTableColumns';
import PaginatedProfiBazaTable from './paginatedProfiBazaTable/PaginatedProfiBazaTable';
import { SieveModel } from './paginatedProfiBazaTable/SieveModels';

interface IProps {
	mode: RowSelectionType | undefined;
	visible: boolean;
	acceptList?: (list: SubjectListItemDto[]) => void;
	acceptSubject?: (value: SubjectListItemDto | undefined) => void;
	onCancel: () => void;
	selectedRows?: Array<SubjectListItemDto>;
	filteredSelectedRows?: (array: Array<SubjectListItemDto>) => void;
	filters?: string;
	proposal?: boolean;
	showImplementerSponsorFilter?: boolean;
	selectedRowColumn?: (record: any) => any;
	clearSelectionOnSearching?: boolean;
	columns?: ProfiBazaColumnProps<SubjectListItemDto>[];
	onlyActiveSubjects?: boolean;
}

export interface ISubjectTableModal {
	resetForm: () => void;
}

interface IState {
	list: SubjectListItemDto[];
	subject: SubjectListItemDto | undefined;
	selectedList: 'all' | 'implementer' | 'sponsor';
}

const SubjectsTableModal: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({
		list: props.selectedRows!,
		selectedList: 'all',
		subject: undefined,
	});

	const selectOne = props.mode === 'radio';

	useEffect(() => {
		setState((prevState) => ({
			...prevState,
			list: _.cloneDeep(props.selectedRows!),
		}));
	}, [props.visible]);

	const [tableLoaded, setTableLoaded] = useState<boolean>(false);

	useLayoutEffect(() => {
		let selectList: HTMLInputElement[] = [];
		Array.from(document.getElementsByClassName('ant-modal')).forEach(
			(el) =>
				(selectList = [
					...selectList,
					...Array.from(el.getElementsByTagName('input')),
				])
		);
		Array.from(selectList).forEach((el) => {
			if (el.getAttribute('role') == 'combobox') {
				el.setAttribute('aria-label', 'Wybierz');
			}
		});
		let buttonList: HTMLButtonElement[] = [];
		Array.from(document.getElementsByClassName('ant-modal')).forEach(
			(el) =>
				(buttonList = [
					...buttonList,
					...Array.from(el.getElementsByTagName('button')),
				])
		);
		Array.from(buttonList).forEach((el) => {
			if (!el.getAttribute('aria-label')) {
				el.setAttribute('aria-label', 'Przycisk');
			}
		});
	}, [tableLoaded]);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	return (
		<Modal
			visible={props.visible}
			width={'80%'}
			okText="Zatwierdź"
			cancelText="Wróć"
			destroyOnClose
			onCancel={() => {
				props.onCancel();
				gridStore.setSelectedKeys([]);
				gridStore.setSelectedValues([]);
				gridStore.resetGrid('activitySubjects');
			}}
			onOk={() => {
				if (props.acceptList) {
					props.acceptList(state.list);
				} else if (
					props.acceptSubject &&
					props.mode === 'radio' &&
					state?.list?.length > 0
				) {
					props.acceptSubject(state.list[0]);
				} else {
					props.onCancel();
				}
				gridStore.setSelectedKeys([]);
				gridStore.setSelectedValues([]);
				gridStore.resetGrid('activitySubjects');
			}}
		>
			<CenteredRow gutter={[16, 24]} style={{ marginTop: 32 }}>
				<Col span={5}>
					{props.showImplementerSponsorFilter &&
						props.mode == 'checkbox' && (
							<Select
								defaultValue="all"
								onChange={(
									value: 'all' | 'implementer' | 'sponsor'
								) => {
									setState({ ...state, selectedList: value });
								}}
								style={{ width: '30%' }}
								aria-label="Wybór zakresu"
							>
								<Select.Option value="all">
									Wszystkie podmioty
								</Select.Option>
								<Select.Option value="implementer">
									Wykorzystane podmioty realizujące
								</Select.Option>
								<Select.Option value="sponsor">
									Wykorzystane podmioty finansujące
								</Select.Option>
							</Select>
						)}
				</Col>
				<Col span={13}>
					<Form size="large">
						<SearchBar
							placeholder="Wyszukaj podmiot"
							column="(name|codeName)"
							filter="contains"
							gridName="activitySubjects"
						/>
					</Form>
				</Col>
				<Col span={5}></Col>
			</CenteredRow>
			<CenteredRow>
				<Col span={23}>
					<PaginatedProfiBazaTable<SubjectListItemDto>
						gridName="activitySubjects"
						columns={props.columns ?? defaultColumns}
						hasRowSelection={!!props.mode}
						hasSelectAll={false}
						hidePersonalizationSettings
						getRowValuesSelection={(list: SubjectListItemDto[]) => {
							setState({ ...state, list: list });
						}}
						selectedRows={
							props.mode === 'checkbox' || props.mode === 'radio'
								? props.selectedRows
								: undefined
						}
						rowSelectionType={props.mode}
						selectedOne={(list: SubjectListItemDto[]) => {
							if (selectOne) {
								setState({ ...state, list: list });
							}
						}}
						selectedRowColumn={props.selectedRowColumn}
						getRowKey={(r: SubjectListItemDto) => r.id!}
						selectedList={
							props.mode == 'checkbox'
								? state.selectedList
								: undefined
						}
						getPagedResult={(sieve: SieveModel) => {
							const filters = props.filters
								? sieve.filters
									? sieve.filters + ',' + props.filters
									: props.filters
								: sieve.filters;

							const parameters: SubjectAllOptionalParams = {
								filters: filters,
								sorts: sieve.sorts,
								page: sieve.page,
								pageSize: sieve.pageSize,
								onlyActiveSubjects:
									props.onlyActiveSubjects ?? false,
							};
							return ajaxCatch(() => {
								return getProfiBazaApiClient().then((api) => {
									if (state.selectedList == 'implementer') {
										return api.subject
											.getImplementingCompanySubjects(
												parameters
											)
											.then((result) => {
												return result;
											});
									} else if (
										state.selectedList == 'sponsor'
									) {
										return api.subject
											.getSponsoringCompanySubjects(
												parameters
											)
											.then((result) => {
												return result;
											});
									} else if (props.proposal) {
										return api.subject
											.getProposalPossibilitySubjects(
												parameters
											)
											.then((result) => {
												return result;
											});
									} else {
										return api.subject
											.all(parameters)
											.then((result) => {
												return result;
											});
									}
								});
							});
						}}
						isLoaded={() => setTableLoaded(true)}
						clearSelectionOnSearching={
							props.clearSelectionOnSearching
						}
					/>
				</Col>
			</CenteredRow>
		</Modal>
	);
};

SubjectsTableModal.defaultProps = {
	showImplementerSponsorFilter: true,
	clearSelectionOnSearching: true,
};

export default observer(SubjectsTableModal);
