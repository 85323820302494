/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/interventionFormMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a InterventionForm. */
export class InterventionForm {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a InterventionForm.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  saveOrbeonInterventionForm(options?: Models.InterventionFormSaveOrbeonInterventionFormOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  saveOrbeonInterventionForm(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  saveOrbeonInterventionForm(options: Models.InterventionFormSaveOrbeonInterventionFormOptionalParams, callback: msRest.ServiceCallback<void>): void;
  saveOrbeonInterventionForm(options?: Models.InterventionFormSaveOrbeonInterventionFormOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      saveOrbeonInterventionFormOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const saveOrbeonInterventionFormOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "rizp/InterventionForm",
  queryParameters: [
    Parameters.interventionId0,
    Parameters.document
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};
