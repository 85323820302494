import { FilterFilled } from '@ant-design/icons';
import { Button } from 'antd';
import clsx from 'clsx';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React from 'react';

interface IProps {
	id?: string;
	ariaLabel?: string;
	filtered: boolean;
}

const FilterButton: React.FC<IProps> = (props) => {
	const { id, ariaLabel, filtered } = props;

	return (
		<ProfiBazaTooltip placement="top" title="Filtruj">
			<Button
				id={id}
				className="btn-filter"
				aria-label={ariaLabel}
				icon={
					<FilterFilled
						className={clsx(filtered && 'color--light-blue')}
					/>
				}
			/>
		</ProfiBazaTooltip>
	);
};

FilterButton.defaultProps = {
	ariaLabel: 'Filtruj kolumnę',
};

export default FilterButton;
