import React from 'react';
import { CommentsCommand } from './Commands/CommentsCommand';
import Comment from './Comment/index';
import './StatementComment.less';

interface IProps {
	data: CommentsCommand | undefined;
	refresh: () => void;
	editable: boolean;
	statementSubjectId: string;
	setEditable: (
		editable: boolean,
		id: string | undefined,
		visible: boolean
	) => void;
}

const StatementComment = (props: IProps) => {
	const { data } = props;

	return (
		<div className="antsay-ui">
			<div className="antsay-commentbox">
				<div className="antsay-commentbox-header">
					<p>Liczba komentarzy {data?.pagination.total}</p>
				</div>
			</div>
			<div className="antsay-comments">
				{data?.comments.map((comment, i) => (
					<Comment
						key={`comment-${i}`}
						data={comment}
						statementSubjectId={props.statementSubjectId}
						refresh={props.refresh}
						setEditable={props.setEditable}
					/>
				))}
			</div>

			{data?.pagination.hasNextPage && (
				<div className="antsay-more">Pokaż więcej</div>
			)}
		</div>
	);
};

export default StatementComment;
