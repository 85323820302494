import React from 'react';
import { useRouteMatch } from 'react-router';

import DefaultDocumentTable from './DefaultDocumentTable';
import { DocumentSideBarConsts } from './DocumentSideBarConsts';

interface IProps {}

const SourceDocumentsTable: React.FC<IProps> = () => {
	let match = useRouteMatch<{ filter: string }>();

	const RenderView = () => {
		if (match.params.filter) {
			switch (match.params.filter) {
				case 'interventions':
					return (
						<DefaultDocumentTable
							gridName="interventionDocuments"
							key="interventionDocuments"
						/>
					);
				case 'statements':
					return (
						<DefaultDocumentTable
							gridName="statementDocuments"
							key="statementDocuments"
						/>
					);
				case DocumentSideBarConsts.AOTMiTPPZ:
					return (
						<DefaultDocumentTable
							gridName="aotmitPPZ"
							key="aotmitPPZ"
						/>
					);
				case DocumentSideBarConsts.AOTMiTRecommendations:
					return (
						<DefaultDocumentTable
							gridName="aotmitRecommendations"
							key="aotmitRecommendations"
						/>
					);
				case DocumentSideBarConsts.AOTMiTAnnex:
					return (
						<DefaultDocumentTable
							gridName="aotmitAnnex"
							key="aotmitAnnex"
						/>
					);
				case DocumentSideBarConsts.AOTMiTPronouncement:
					return (
						<DefaultDocumentTable
							gridName="aotmitPronouncements"
							key="aotmitPronouncements"
						/>
					);
				case DocumentSideBarConsts.AOTMiTFinalReport:
					return (
						<DefaultDocumentTable
							gridName="aotmitFinalReport"
							key="aotmitFinalReport"
						/>
					);
				case 'archiveStatements':
					return (
						<DefaultDocumentTable
							gridName="archiveStatementsDocuments"
							key="archiveStatementsDocuments"
						/>
					);
				case 'archiveInterventions':
					return (
						<DefaultDocumentTable
							gridName="archiveInterventionsDocuments"
							key="archiveInterventionsDocuments"
						/>
					);
				case 'voivodeOpinion':
					return (
						<DefaultDocumentTable
							gridName="voivodeOpinionDocuments"
							key="voivodeOpinionDocuments"
						/>
					);
				default:
					return (
						<DefaultDocumentTable
							gridName="interventionDocuments"
							key="interventionDocuments"
						/>
					);
			}
		} else return <></>;
	};

	return RenderView();
};

export default SourceDocumentsTable;
