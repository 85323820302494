import { SaveOutlined } from '@ant-design/icons';
import Authorized from '@authorization/authorized';
import AddButton from '@components/shared/buttons/AddButton';
import { CommentButton } from '@components/shared/comments/CommentButton';
import AccessibleDrawer from '@components/shared/modals/AccessibleDrawer';
import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import UserManualViewButton from '@components/userManual/UserManualViewButton';
import { handleOnPressEnter } from '@helper/accessibilityHelpers';
import { useFocusTrap } from '@hooks/accessibility/useFocusTrapVisibleHook';
import { useDrawer } from '@hooks/useDrawerHook';
import { accountDetailsStore } from 'account/accountDetailsStore';
import { Button, Checkbox, Col, Row, Select, Space, Tag } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import PermissionValidator from 'authorization/permissionValidator';
import { DictionaryValueType } from 'components/dictionary/dictionaryIntializer';
import { IDictionaryStore } from 'components/dictionary/dictionaryStore';
import BackButtons from 'components/shared/buttons/BackButtons';
import SubjectsTableModal from 'components/shared/SubjectsTableModal';
import SubjectMergeModal from 'components/subjects/modals/SubjectMergeModal';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FAsyncAutocomplete,
	FCheckbox,
	FDatePicker,
	FFieldLabel,
	FInput,
	FSelect,
	FSelectSubjects,
} from 'forms/FormikFormItems';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import arrayHelpers from 'helper/arrayHelpers';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { showErrorModal } from 'layout/Modals';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import PrimaryCard from 'layout/PrimaryCard';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { DictionaryName } from 'services/mappers/DictionaryName';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import {
	ContactDto,
	GuidIIdDto,
	LocalityDto,
	StreetDto,
	SubjectCreateResponse,
	SubjectKind,
	SubjectReportingProcessDto,
	SubjectStatus,
	UserManualType,
} from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import {
	PaginationSubjectCommentsCommand,
	SubjectCommentsCommand,
} from './comments/commands/SubjectCommentsCommand';
import SubjectComment from './comments/SubjectComment';
import { SubjectCommentArea } from './comments/SubjectCommentArea';
import { SubjectCommentIcon } from './comments/SubjectCommentIcon';
import SubjectCommentModal from './comments/SubjectCommentModal';
import { ISubjectCommentStore } from './comments/SubjectCommentStore';
import SubjectContactTable from './contact/SubjectContactTable';
import {
	SubjectEditCommand,
	createSubject,
	getSubjectWithUpdatedStatus,
	updateIntervention,
} from './model/SubjectCommands';
import SubjectReportingProcessLabels from './reportingProcess/SubjectReportingProcessLabels';
import SubjectReportingProcessModal from './reportingProcess/SubjectReportingProcessModal';
import { SubjectMergeListItemDto } from './SubjectMergeTableColumns';
import { getEditSubjectValidationSchema } from './Validators/SubjectValidation';

interface IProps {
	subjectId?: any;
	modal?: boolean;
	editable?: boolean;
	span?: number;
}

const isJST = (value: SubjectKind | any) => {
	if (
		value !== null &&
		[
			SubjectKind.DISTRICT,
			SubjectKind.GM,
			SubjectKind.GMW,
			SubjectKind.GW,
			SubjectKind.MNP,
			SubjectKind.SP,
			SubjectKind.UW,
			SubjectKind.UM,
			SubjectKind.IC,
			SubjectKind.M,
			SubjectKind.PZH,
			SubjectKind.EDUCATIONALUNIT,
			SubjectKind.PSSE,
			SubjectKind.WSSE,
			SubjectKind.GSSE,
		].includes(value)
	) {
		return true;
	} else {
		return false;
	}
};

interface ICommentState {
	isModalVisible: boolean;
	commentId: string | undefined;
	editableComment: boolean;
}

const SubjectData: React.FC<RouteComponentProps & IProps> = (props) => {
	const { editable, modal, subjectId } = props;

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const [commentState, setCommentState] = useState<ICommentState>({
		isModalVisible: false,
		commentId: undefined,
		editableComment: false,
	});

	const [addCommentVisible, setAddCommentVisible] = useState<boolean>(false);
	const [commentVisible, showComments, hideComments] = useDrawer();
	const [focusTrapActive] = useFocusTrap(commentVisible, addCommentVisible);
	const [data, setData] = useState<SubjectEditCommand | undefined>(undefined);
	const [commentData, setCommentData] = useState<SubjectCommentsCommand>();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [toList, setToList] = useState<boolean>(false);
	const [reportingProcessVisible, setReportingProcessVisible] = useState<
		boolean
	>(false);
	const [selectedReportingProcess, setSelectedReportingProcess] = useState<
		SubjectReportingProcessDto | undefined
	>(undefined);
	const [contacts, setContacts] = useState<ContactDto[]>([]);
	const [subjectVisible, setSubjectVisible] = useState<boolean>(false);
	const [subjectMergeVisible, setSubjectMergeVisible] = useState<boolean>(
		false
	);
	const [confirmMergeVisible, setConfirmMergeVisible] = useState<boolean>(
		false
	);
	const [mergingSubject, setMergingSubject] = useState<
		SubjectMergeListItemDto
	>();

	const [changed, setChanged] = useState<boolean>(false);
	const [changedData, setChangedData] = useState<
		SubjectEditCommand | undefined
	>(undefined);
	const subjectDic = dictionaryStore.getBuildInDictionary(
		DictionaryName.SubjectKind
	).values;

	let [isMerging, setMerging] = useState<boolean>(false);

	const permissionValidator = new PermissionValidator(
		accountDetailsStore.account.get()!
	);
	const isSubjectsProposalPossibilityHandling = permissionValidator.has(
		ProfiBazaAPIModels.Permission.SubjectsProposalPossibilityHandling
	);

	const subjectCommentStore: ISubjectCommentStore = useRootData(
		(store) => store.subjectCommentStore
	);

	useEffect(() => {
		loadData();
	}, [props.subjectId]);

	/** Prevents upadting newly added contacts when saving reporting process */
	const trySetContacts = (
		subject: ProfiBazaAPIModels.SubjectDetailsDto
	): void => {
		if (!contacts.some((x) => x.id === undefined)) {
			setContacts(subject?.contacts!);
		}
	};

	const loadData = () => {
		if (props.subjectId) {
			ajaxCatch(() =>
				getProfiBazaApiClient()
					.then((api) => api.subject.getById(props.subjectId))
					.then((subject: ProfiBazaAPIModels.SubjectDetailsDto) => {
						setData(new SubjectEditCommand(subject));
						setChangedData(new SubjectEditCommand(subject));
						trySetContacts(subject);
						setLoaded(true);
					})
			);
		} else {
			const obj = new SubjectEditCommand();
			setData(obj);
			setChangedData(obj);
			setLoaded(true);
		}
	};

	const checkChanges = (value: any, key: string) => {
		let obj: SubjectEditCommand = {};
		if (key.includes('address.')) {
			obj = {
				...changedData,
				address: {
					...changedData?.address,
					[key.split('.')[1]]: value,
				},
			};
		} else if (key.includes('address')) {
			obj = {
				...changedData,
				address: {
					...changedData?.address,
					...value,
				},
			};
		} else {
			obj = {
				...changedData,
				[key]: value,
			};
		}
		if (arrayHelpers.isEquivalent(data, obj)) {
			setChanged(false);
		} else {
			setChanged(true);
		}
		setChangedData(obj);
	};

	useEffect(() => {
		if (isMerging && mergingSubject) {
			handleMergeSubjects();
		}
	}, [isMerging]);

	const handleMergeSubjects = () => {
		if (isMerging) {
			if (
				props.subjectId &&
				mergingSubject?.id &&
				props.subjectId != mergingSubject?.id
			) {
				ajaxByUser('Podmioty zostały scalone', () =>
					getProfiBazaApiClient()
						.then((api) =>
							api.subject.mergeSubjects({
								body: {
									subjectId: props.subjectId,
									mergingSubjectId: mergingSubject?.id,
								},
							})
						)
						.then((value: GuidIIdDto) => {
							props.history.push(
								`/subject/${
									props.editable ? 'edit' : 'details'
								}/${value.id}`
							);
							setMergingSubject(undefined);
							setSubjectMergeVisible(false);
							setConfirmMergeVisible(false);
						})
				).then(() => setMerging(false));
			} else {
				setMergingSubject(undefined);
				setMerging(false);
				setConfirmMergeVisible(false);
				showErrorModal('Nie można scalić z tym samym podmiotem');
			}
		}
	};

	const onSubmit = (
		value: SubjectEditCommand,
		backToList: boolean,
		actions: FormikActions<SubjectEditCommand>
	) => {
		value.contacts = contacts;
		if (subjectId) {
			ajaxByUser(
				'Zapisano podmiot',
				() =>
					getProfiBazaApiClient().then((api) =>
						api.subject.update({
							body: updateIntervention(value, props.subjectId!),
						})
					),
				() => {
					setChanged(false);
					setToList(false);
					setLoaded(true);
					if (backToList) {
						props.history.push('/subject');
					} else {
						props.history.push(`/subject/edit/${subjectId}`);
					}

					const dataWithUpdatedStatus = getSubjectWithUpdatedStatus(
						value
					);
					setData(dataWithUpdatedStatus);
				},
				(errors) => {
					actions.setErrors(errors);
				}
			).then(() => {
				loadData();
				actions.setSubmitting(false);
			});
		} else {
			ajaxByUser(
				'Zapisano podmiot',
				() =>
					getProfiBazaApiClient()
						.then((api) =>
							api.subject.create({
								body: createSubject(value),
							})
						)
						.then((subject: SubjectCreateResponse) => {
							setToList(false);
							setChanged(false);
							if (backToList) {
								props.history.push('/subject');
							} else {
								props.history.push(
									`/subject/edit/${subject.id}`
								);
							}
						}),
				() => {},
				(errors) => {
					actions.setErrors(errors);
				}
			).then(() => {
				actions.setSubmitting(false);
			});
		}
	};

	const clearAddress = (props: FormikProps<SubjectEditCommand>) => {
		props.setFieldValue('address.simcCode', undefined);
		props.setFieldValue('address.tercCode', undefined);
		props.setFieldValue('address.city', undefined);
		props.setFieldValue('address.voivodeship', undefined);
		props.setFieldValue('address.county', undefined);
		props.setFieldValue('address.community', undefined);

		props.setFieldValue('address.street', undefined);
		props.setFieldValue('address.streetCode', undefined);
		props.setFieldValue('address.zipCode', undefined);
		props.setFieldValue('address.propertyNumber', undefined);
		props.setFieldValue('address.placeNumber', undefined);
		props.setFieldValue('isStreetRequired', false);
	};

	const loadComments = () => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.subjectComments.all({
						sievePageSize: 9999,
						sievePage: 1,
						subjectId: props.subjectId,
					})
				)
				.then(
					(
						response: ProfiBazaAPIModels.SubjectCommentListItemDtoPagedResult
					) => {
						setCommentData(
							new SubjectCommentsCommand(
								response.results!,
								new PaginationSubjectCommentsCommand(response)
							)
						);
						subjectCommentStore.setRecords(response.results!);
					}
				)
		);
	};
	useEffect(() => {
		loadComments();
	}, [props.subjectId]);

	const handleOnParentSubjectClick = (
		formik: FormikProps<SubjectEditCommand>
	) => {
		editable && !formik.values?.cruipCode && setSubjectVisible(true);
	};

	return (
		<>
			<CenteredRow>
				<Col span={props.span}>
					{loaded && (
						<>
							<Formik
								initialValues={data || {}}
								validationSchema={
									editable
										? getEditSubjectValidationSchema(
												!!data?.cruipCode
										  )
										: null
								}
								validateOnChange={false}
								enableReinitialize
								onSubmit={(value, actions) => {
									value.contacts = contacts;
									onSubmit(value, toList, actions);
								}}
								render={(
									formik: FormikProps<SubjectEditCommand>
								) => (
									<>
										<div className="page-title page-title-wide-right">
											<div className="left-content">
												<Space>
													<h2
														style={{
															marginBottom: 0,
															fontSize: 22,
														}}
													>
														Dane podstawowe
													</h2>
													{data?.status ==
														SubjectStatus.DELETED && (
														<Tag
															color="volcano"
															style={{
																height: '25px',
																margin:
																	'auto 0px auto 0px',
															}}
														>
															Podmiot Usunięty
														</Tag>
													)}
												</Space>
											</div>
											<div className="right-content">
												<Space>
													{commentData && (
														<CommentButton
															label="Komentarze podmiotu"
															commentData={
																commentData
															}
															showComments={
																showComments
															}
														/>
													)}

													<AccessibleDrawer
														focusTrapActive={
															focusTrapActive
														}
														destroyOnClose
														title="Komentarze"
														placement="right"
														closable={false}
														onClose={hideComments}
														visible={commentVisible}
														getContainer={false}
														width={400}
														className="comment-drawer"
													>
														<AddButton
															onClick={() => {
																setAddCommentVisible(
																	true
																);
																setCommentState(
																	{
																		...commentState,
																		editableComment: true,
																	}
																);
															}}
														>
															Dodaj komentarz
														</AddButton>

														<SubjectCommentModal
															subjectId={
																props.subjectId
															}
															commentId={
																commentState.commentId
															}
															subjectSubjectId={
																data?.id!
															}
															visible={
																addCommentVisible
															}
															setVisible={(
																visible: boolean
															) => {
																setCommentState(
																	{
																		...commentState,
																		editableComment: false,
																		commentId: undefined,
																	}
																);
																setAddCommentVisible(
																	visible
																);
															}}
															onSave={
																loadComments
															}
															editable={
																commentState.editableComment
															}
														></SubjectCommentModal>
														{commentData && (
															<SubjectComment
																subjectId={
																	data?.id!
																}
																data={
																	commentData
																}
																refresh={
																	loadComments
																}
																setEditable={(
																	value,
																	id,
																	visible
																) => {
																	setCommentState(
																		{
																			...commentState,
																			editableComment: value,
																			commentId: id!,
																		}
																	);
																	setAddCommentVisible(
																		visible
																	);
																}}
																editable={
																	commentState.editableComment
																}
															/>
														)}
													</AccessibleDrawer>
													<UserManualViewButton
														userManual={{
															manualType:
																UserManualType.Subject,
														}}
													/>
												</Space>
											</div>
										</div>

										<PrimaryCard>
											<div
												style={{ padding: '10px 20px' }}
											>
												<Row gutter={[20, 10]}>
													<Col flex={'750px'}>
														<Space>
															<FFieldLabel label="Nazwa podmiotu" />
															<SubjectCommentIcon fieldName="subjectName" />
														</Space>

														<SubjectCommentArea fieldName="subjectName">
															<Field
																label="Nazwa podmiotu"
																component={
																	FInput
																}
																labelAlign="right"
																name="name"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'name'
																	)
																}
																readOnly={
																	!editable ||
																	!!formik
																		.values
																		?.cruipCode ||
																	data?.expired
																}
															/>
														</SubjectCommentArea>
													</Col>
													<Col flex={'150px'}>
														<Space>
															<FFieldLabel label="Kategoria" />
															<SubjectCommentIcon fieldName="subjectKind" />
														</Space>
														<SubjectCommentArea fieldName="subjectKind">
															<Field
																label="Kategoria"
																component={
																	FSelect
																}
																name="subjectKind"
																readOnly={
																	!editable ||
																	!!data?.cruipCode ||
																	data?.expired
																}
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'subjectKind'
																	)
																}
																children={
																	subjectDic &&
																	subjectDic.map(
																		(
																			p: DictionaryValueType
																		) => {
																			return (
																				<Select.Option
																					key={
																						p.displayName
																					}
																					value={
																						p.code
																					}
																				>
																					{
																						p.displayName
																					}
																				</Select.Option>
																			);
																		}
																	)
																}
															/>
														</SubjectCommentArea>
													</Col>
												</Row>
												<Row gutter={[20, 10]}>
													<Col flex={'750px'}>
														<Space>
															<FFieldLabel label="Podlega pod" />
															<SubjectCommentIcon fieldName="parentSubjectName" />
														</Space>
														<SubjectCommentArea fieldName="parentSubjectName">
															<Field
																label="Podlega pod"
																component={
																	FSelectSubjects
																}
																name="parentSubjectName"
																onClick={() => {
																	handleOnParentSubjectClick(
																		formik
																	);
																}}
																onKeyUp={handleOnPressEnter(
																	() =>
																		handleOnParentSubjectClick(
																			formik
																		)
																)}
																array={
																	formik
																		.values
																		?.parentSubjectName
																}
																editable={
																	editable &&
																	!data?.expired
																}
																readOnly={
																	!editable ||
																	data?.expired ||
																	!!formik
																		.values
																		?.cruipCode
																}
																dropDown={true}
															/>
														</SubjectCommentArea>
													</Col>
													<Col flex={'170px'}>
														<Space>
															<FFieldLabel label="Data aktywności od" />
															<SubjectCommentIcon fieldName="validFrom" />
														</Space>
														<SubjectCommentArea fieldName="validFrom">
															<Field
																label="Data aktywności od"
																component={
																	FDatePicker
																}
																style={{
																	width:
																		'100%',
																}}
																labelAlign="right"
																name="validFrom"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'validFrom'
																	)
																}
																inputReadOnly={
																	!editable ||
																	!!formik
																		.values
																		?.cruipCode ||
																	!data?.isDateFromEditable
																}
															/>
														</SubjectCommentArea>
													</Col>
													<Col flex={'165px'}>
														<Space>
															<FFieldLabel label="Data aktywności do" />

															<SubjectCommentIcon fieldName="validTo" />
														</Space>
														<SubjectCommentArea fieldName="validTo">
															<Field
																label="Data aktywności do"
																component={
																	FDatePicker
																}
																labelAlign="right"
																name="validTo"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'validTo'
																	)
																}
																style={{
																	width:
																		'100%',
																}}
																inputReadOnly={
																	!editable ||
																	!!formik
																		.values
																		?.cruipCode
																}
															/>
														</SubjectCommentArea>
													</Col>
												</Row>
												<Row gutter={[20, 10]}>
													<Col flex={'150px'}>
														<Space>
															<FFieldLabel label="NIP" />
															<SubjectCommentIcon fieldName="nip" />
														</Space>
														<SubjectCommentArea fieldName="nip">
															<Field
																label="NIP"
																component={
																	FInput
																}
																labelAlign="right"
																name="nip"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'nip'
																	)
																}
																readOnly={
																	!editable ||
																	!!formik
																		.values
																		?.cruipCode ||
																	data?.expired
																}
															/>
														</SubjectCommentArea>
													</Col>
													<Col flex={'150px'}>
														<Space>
															<FFieldLabel label="REGON" />
															<SubjectCommentIcon fieldName="regon" />
														</Space>
														<SubjectCommentArea fieldName="regon">
															<Field
																label="REGON"
																component={
																	FInput
																}
																labelAlign="right"
																name="regon"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'regon'
																	)
																}
																readOnly={
																	!editable ||
																	data?.expired
																}
															/>
														</SubjectCommentArea>
													</Col>
													<Col flex={'150px'}>
														<Space>
															<FFieldLabel label="KRS" />
															<SubjectCommentIcon fieldName="krsCode" />
														</Space>
														<SubjectCommentArea fieldName="krsCode">
															<Field
																label="KRS"
																component={
																	FInput
																}
																labelAlign="right"
																name="krsCode"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'krsCode'
																	)
																}
																readOnly={
																	!editable ||
																	data?.expired
																}
															/>
														</SubjectCommentArea>
													</Col>
													<Col flex={'250px'}>
														<Space>
															<FFieldLabel label="Kod CRUiP" />
															<SubjectCommentIcon fieldName="cruipCode" />
														</Space>
														<SubjectCommentArea fieldName="cruipCode">
															<Field
																label="Kod CRUiP"
																component={
																	FInput
																}
																labelAlign="right"
																name="cruipCode"
																readOnly
															/>
														</SubjectCommentArea>
													</Col>
													{isJST(
														changedData?.subjectKind
													) && (
														<Col flex={'150px'}>
															<Space>
																<FFieldLabel label="Kod Teryt" />
																<SubjectCommentIcon fieldName="tercCode" />
															</Space>
															<SubjectCommentArea fieldName="tercCode">
																<Field
																	label="Kod Teryt"
																	component={
																		FInput
																	}
																	labelAlign="right"
																	name="tercCode"
																	changeData={(
																		value: any
																	) =>
																		checkChanges(
																			value,
																			'tercCode'
																		)
																	}
																	disabled={
																		!editable
																	}
																/>
															</SubjectCommentArea>
														</Col>
													)}
												</Row>
												{props.subjectId && (
													<Row>
														<Col span={4}>
															<Space>
																<FFieldLabel
																	label="Wnioskowanie dostępne"
																	for="isProposalPossibility"
																/>
																<Field
																	label="Wnioskowanie dostępne"
																	component={
																		FCheckbox
																	}
																	id="isProposalPossibility"
																	name="isProposalPossibility"
																	disabled={
																		!editable ||
																		!isSubjectsProposalPossibilityHandling
																	}
																/>
															</Space>
														</Col>
														<Authorized
															permission={
																ProfiBazaAPIModels
																	.Permission
																	.SubjectsProposalPossibilityHandling
															}
														>
															<Col span={5}>
																{data?.isProposalPossibility && (
																	<p aria-label="Status utworzenia konta administratora">
																		{formik
																			.values
																			.isActiveAdminAccount
																			? 'Konto administratora lokalnego utworzone'
																			: 'Brak konta administratora lokalnego'}
																	</p>
																)}
															</Col>
														</Authorized>
													</Row>
												)}
											</div>
										</PrimaryCard>
										<h2>Dane adresowe</h2>
										<PrimaryCard>
											<div
												style={{ padding: '10px 20px' }}
											>
												<Authorized
													permissions={[
														ProfiBazaAPIModels
															.Permission
															.SubjectsModificationsWithTerytAll,
														ProfiBazaAPIModels
															.Permission
															.SubjectsModificationsWithTerytMine,
													]}
												>
													{editable &&
														!data?.expired &&
														!formik.values
															?.cruipCode && (
															<Row>
																<Col>
																	<Checkbox
																		aria-label="Uzupełnij manualnie"
																		checked={
																			formik
																				.values
																				?.address
																				?.isAddressManual
																		}
																		onChange={(
																			e
																		) => {
																			formik.setFieldValue(
																				'address.isAddressManual',
																				e
																					.target
																					.checked
																			);
																			clearAddress(
																				formik
																			);
																			checkChanges(
																				e
																					.target
																					.checked,
																				'address.isAddressManual'
																			);
																		}}
																	>
																		Uzupełnij
																		manualnie
																	</Checkbox>
																</Col>
															</Row>
														)}
												</Authorized>
												<Row gutter={[20, 10]}>
													<Col flex={'300px'}>
														<Space>
															<FFieldLabel label="Miejscowość" />
															<SubjectCommentIcon fieldName="addresscity" />
														</Space>

														{formik.values?.address
															?.isAddressManual ||
														!editable ? (
															<SubjectCommentArea fieldName="addresscity">
																<Field
																	label="Miejscowość"
																	component={
																		FInput
																	}
																	labelAlign="right"
																	name="address.city"
																	changeData={(
																		value: any
																	) =>
																		checkChanges(
																			value,
																			'address.city'
																		)
																	}
																	readOnly={
																		!editable ||
																		!!formik
																			.values
																			?.cruipCode ||
																		data?.expired
																	}
																/>
															</SubjectCommentArea>
														) : (
															<SubjectCommentArea fieldName="addresscity">
																<Field
																	label="Miejscowość"
																	notFoundContent="Nie znaleziono"
																	placeholder="Wpisz początek nazwy miasta"
																	component={
																		FAsyncAutocomplete
																	}
																	labelAlign="right"
																	name="address.city"
																	allowClear
																	readOnly={
																		!formik
																			.values
																			?.address
																			?.isAddressManual &&
																		(!editable ||
																			!!formik
																				.values
																				?.cruipCode ||
																			data?.expired)
																	}
																	loadData={(
																		data: string
																	) =>
																		getProfiBazaApiClient()
																			.then(
																				(
																					api
																				) =>
																					api.teryt.all(
																						{
																							page: 1,
																							pageSize: 100,
																							filters: `locality@=*${data}`,
																						}
																					)
																			)
																			.then(
																				(
																					response
																				) =>
																					response.results?.map(
																						(
																							x: LocalityDto
																						) => ({
																							...x,
																							key: `${x.simcCode}_${x.tercCode}`,
																							description: `${
																								x.locality
																							} (${
																								x.type
																									? x.type +
																									  ', '
																									: ''
																							}gm. ${
																								x.community
																							}, pow. ${
																								x.county
																							}, woj. ${
																								x.voivodeship
																							})`,
																						})
																					)
																			)
																	}
																	onSelect={(
																		locality: LocalityDto
																	) => {
																		checkChanges(
																			{
																				voivodeship:
																					locality.voivodeship,
																				simCode:
																					locality.simcCode,
																				tercCode:
																					locality.tercCode,
																				city:
																					locality.locality,
																				county:
																					locality.county,
																				community:
																					locality.community,
																				street: undefined,
																				streetCode: undefined,
																			},
																			'address'
																		);
																		formik.setFieldValue(
																			'address.simcCode',
																			locality.simcCode
																		);
																		formik.setFieldValue(
																			'address.tercCode',
																			locality.tercCode
																		);
																		formik.setFieldValue(
																			'address.city',
																			locality.locality
																		);
																		formik.setFieldValue(
																			'address.voivodeship',
																			locality.voivodeship
																		);
																		formik.setFieldValue(
																			'address.county',
																			locality.county
																		);
																		formik.setFieldValue(
																			'address.community',
																			locality.community
																		);

																		formik.setFieldValue(
																			'address.street',
																			locality.hasAnyStreets
																				? undefined
																				: locality.locality
																		);
																		formik.setFieldValue(
																			'address.streetCode',
																			undefined
																		);
																		formik.setFieldValue(
																			'isStreetRequired',
																			locality.hasAnyStreets
																		);
																	}}
																	onClear={() => {
																		clearAddress(
																			formik
																		);
																	}}
																/>
															</SubjectCommentArea>
														)}
													</Col>
													<Col flex={'140px'}>
														<Space>
															<FFieldLabel label="Kod pocztowy" />
															<SubjectCommentIcon fieldName="addresszipCode" />
														</Space>

														<SubjectCommentArea fieldName="addresszipCode">
															<Field
																label="Kod pocztowy"
																component={
																	FInput
																}
																labelAlign="right"
																name="address.zipCode"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'address.zipCode'
																	)
																}
																readOnly={
																	!editable ||
																	!!formik
																		.values
																		?.cruipCode ||
																	data?.expired
																}
															/>
														</SubjectCommentArea>
													</Col>
													<Col flex={'210px'}>
														<Space>
															<FFieldLabel label="Województwo" />
															<SubjectCommentIcon fieldName="addressvoivodeship" />
														</Space>

														<SubjectCommentArea fieldName="addressvoivodeship">
															<Field
																label="Województwo"
																component={
																	FInput
																}
																labelAlign="right"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'address.voivodeship'
																	)
																}
																name="address.voivodeship"
																readOnly={
																	!editable ||
																	!formik
																		.values
																		.address
																		?.isAddressManual ||
																	(formik
																		.values
																		?.address
																		?.isAddressManual &&
																		!!formik
																			.values
																			?.cruipCode) ||
																	data?.expired
																}
															/>
														</SubjectCommentArea>
													</Col>
													<Col flex={'210px'}>
														<Space>
															<FFieldLabel label="Powiat" />
															<SubjectCommentIcon fieldName="addresscounty" />
														</Space>
														<SubjectCommentArea fieldName="addresscounty">
															<Field
																label="Powiat"
																component={
																	FInput
																}
																labelAlign="right"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'address.county'
																	)
																}
																name="address.county"
																readOnly={
																	!editable ||
																	!formik
																		.values
																		.address
																		?.isAddressManual ||
																	(formik
																		.values
																		?.address
																		?.isAddressManual &&
																		!!formik
																			.values
																			?.cruipCode) ||
																	data?.expired
																}
															/>
														</SubjectCommentArea>
													</Col>
													<Col flex={'210px'}>
														<Space>
															<FFieldLabel label="Gmina" />
															<SubjectCommentIcon fieldName="addresscommunity" />
														</Space>
														<SubjectCommentArea fieldName="addresscommunity">
															<Field
																label="Gmina"
																component={
																	FInput
																}
																labelAlign="right"
																name="address.community"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'address.community'
																	)
																}
																readOnly={
																	!editable ||
																	!formik
																		.values
																		.address
																		?.isAddressManual ||
																	(formik
																		.values
																		?.address
																		?.isAddressManual &&
																		!!formik
																			.values
																			?.cruipCode) ||
																	data?.expired
																}
															/>
														</SubjectCommentArea>
													</Col>
												</Row>
												<Row gutter={[20, 10]}>
													<Col flex={'300px'}>
														<Space>
															<FFieldLabel label="Ulica" />
															<SubjectCommentIcon fieldName="addressstreet" />
														</Space>

														{formik.values?.address
															?.isAddressManual ||
														!editable ||
														!formik.values
															.isStreetRequired ||
														data?.expired ? (
															<SubjectCommentArea fieldName="addressstreet">
																<Field
																	label="Ulica"
																	component={
																		FInput
																	}
																	labelAlign="right"
																	name="address.street"
																	changeData={(
																		value: any
																	) =>
																		checkChanges(
																			value,
																			'address.street'
																		)
																	}
																	disabled={
																		!formik
																			.values
																			.address
																			?.isAddressManual &&
																		(!(
																			formik
																				.values
																				.address
																				?.simcCode &&
																			formik
																				.values
																				.address
																				.tercCode
																		) ||
																			!formik
																				.values
																				.isStreetRequired)
																	}
																	readOnly={
																		!editable ||
																		(formik
																			.values
																			.address
																			?.isAddressManual &&
																			(!!formik
																				.values
																				?.cruipCode ||
																				data?.expired))
																	}
																/>
															</SubjectCommentArea>
														) : (
															<SubjectCommentArea fieldName="addressstreet">
																<Field
																	label="Ulica"
																	notFoundContent="Nie znaleziono"
																	placeholder="Wpisz początek nazwy ulicy"
																	component={
																		FAsyncAutocomplete
																	}
																	labelAlign="right"
																	name="address.street"
																	allowClear
																	disabled={
																		!formik
																			.values
																			?.address
																			?.isAddressManual &&
																		(!editable ||
																			!formik
																				.values
																				?.address
																				?.simcCode ||
																			data?.expired)
																	}
																	loadData={(
																		data: string
																	) =>
																		getProfiBazaApiClient()
																			.then(
																				(
																					api
																				) =>
																					api.teryt.getByLocality(
																						{
																							tercCode:
																								formik
																									.values
																									.address
																									?.tercCode,
																							simcCode:
																								formik
																									.values
																									.address
																									?.simcCode,

																							sievePage: 1,
																							sievePageSize: 100,
																							sieveFilters: `(name1|name2)@=*${data}`,
																						}
																					)
																			)
																			.then(
																				(
																					response
																				) =>
																					response.results?.map(
																						(
																							x: StreetDto
																						) => ({
																							...x,
																							key:
																								x.streetCode,
																							description: `${x.name2} ${x.name1}`,
																						})
																					)
																			)
																	}
																	onSelect={(
																		street: StreetDto
																	) => {
																		checkChanges(
																			{
																				street: `${street.name2} ${street.name1}`,
																				streetCode:
																					street.streetCode,
																			},
																			'address'
																		);
																		formik.setFieldValue(
																			'address.street',
																			`${street.name2} ${street.name1}`
																		);
																		formik.setFieldValue(
																			'address.streetCode',
																			street.streetCode
																		);
																	}}
																	onClear={() => {
																		formik.setFieldValue(
																			'address.street',
																			undefined
																		);
																		formik.setFieldValue(
																			'address.streetCode',
																			undefined
																		);
																	}}
																/>
															</SubjectCommentArea>
														)}
													</Col>
													<Col flex={'140px'}>
														<Space>
															<FFieldLabel label="Nr budynku" />
															<SubjectCommentIcon fieldName="addresspropertyNumber" />
														</Space>
														<SubjectCommentArea fieldName="addresspropertyNumber">
															<Field
																label="Nr budynku"
																component={
																	FInput
																}
																labelAlign="right"
																name="address.propertyNumber"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'address.propertyNumber'
																	)
																}
																readOnly={
																	!editable ||
																	!!formik
																		.values
																		?.cruipCode ||
																	data?.expired
																}
															/>
														</SubjectCommentArea>
													</Col>
													<Col flex={'120px'}>
														<Space>
															<FFieldLabel label="Nr lokalu" />
															<SubjectCommentIcon fieldName="addressplaceNumber" />
														</Space>
														<SubjectCommentArea fieldName="addressplaceNumber">
															<Field
																label="Nr lokalu"
																component={
																	FInput
																}
																labelAlign="right"
																name="address.placeNumber"
																changeData={(
																	value: any
																) =>
																	checkChanges(
																		value,
																		'address.placeNumber'
																	)
																}
																readOnly={
																	!editable ||
																	!!formik
																		.values
																		?.cruipCode ||
																	data?.expired
																}
															/>
														</SubjectCommentArea>
													</Col>
												</Row>
											</div>
										</PrimaryCard>
										<SubjectContactTable
											editable={editable}
											contacts={contacts}
											setContacts={(
												contacts: ContactDto[]
											) => {
												checkChanges(
													_.clone(contacts),
													'contacts'
												);
												setContacts(contacts);
											}}
										></SubjectContactTable>

										<SubjectsTableModal
											visible={subjectVisible}
											mode="radio"
											onCancel={() => {
												setSubjectVisible(false);
											}}
											acceptSubject={(value) => {
												setSubjectVisible(false);
												checkChanges(
													value?.id,
													'parentSubjectId'
												);

												formik.setFieldValue(
													'parentSubjectId',
													value?.id
												);
												formik.setFieldValue(
													'parentSubjectName',
													value?.name
												);
											}}
											showImplementerSponsorFilter={true}
										/>

										{props.subjectId && (
											<Authorized
												permissions={[
													ProfiBazaAPIModels
														.Permission
														.SubjectsModificationsStatementObligation,
												]}
											>
												<h2>Sprawozdawczość</h2>
												<PrimaryCard>
													<div
														style={{
															padding:
																'20px 10px',
														}}
													>
														<SubjectReportingProcessLabels
															editable={false}
															deadlineProcess={
																data?.deadlineProcess
															}
															setSelectedProcess={(
																id
															) => {
																ajaxCatch(() =>
																	getProfiBazaApiClient()
																		.then(
																			(
																				api
																			) =>
																				api.subjectReportingProcess.getById(
																					id
																				)
																		)
																		.then(
																			(
																				subjectReporting: ProfiBazaAPIModels.SubjectReportingProcessDto
																			) => {
																				setSelectedReportingProcess(
																					subjectReporting
																				);
																			}
																		)
																);
															}}
															setVisible={
																setReportingProcessVisible
															}
															subjectId={data?.id}
														/>
													</div>
												</PrimaryCard>
											</Authorized>
										)}

										<SubjectReportingProcessModal
											visible={reportingProcessVisible}
											onCancel={() => {
												setReportingProcessVisible(
													false
												);
												setSelectedReportingProcess(
													undefined
												);
											}}
											onCreate={() => {
												setReportingProcessVisible(
													false
												);
												setSelectedReportingProcess(
													undefined
												);
												loadData();
											}}
											mode={
												selectedReportingProcess &&
												!selectedReportingProcess.subjectId
													? 'category'
													: 'subject'
											}
											isSubjectEditable={false}
											editable={false}
											data={
												selectedReportingProcess || {
													subjectId: subjectId,
													subjectName: data?.name,
												}
											}
										/>
										<Modal
											destroyOnClose
											visible={confirmMergeVisible}
											maskClosable={false}
											centered
											okButtonProps={{
												disabled: isMerging,
											}}
											cancelButtonProps={{
												disabled: isMerging,
											}}
											title="Scalanie podmiotów"
											onOk={() => setMerging(true)}
											onCancel={() => {
												setConfirmMergeVisible(false);
												setMergingSubject(undefined);
											}}
											okText="Potwierdź"
											cancelText="Anuluj"
										>
											<>
												Czy na pewno chcesz scalić z{' '}
												{mergingSubject?.name}?{' '}
												<p>
													{data?.name} zostanie
													usunięty.
												</p>
											</>
										</Modal>
										<SubjectMergeModal
											subjectName={data?.name}
											visible={subjectMergeVisible}
											mode="radio"
											onCancel={() => {
												setSubjectMergeVisible(false);
											}}
											acceptSubject={(value) => {
												setConfirmMergeVisible(true);
												setMergingSubject(value);
											}}
										/>
										{!modal && (
											<>
												<Space className="float-right">
													<BackButtons
														link="/subject"
														onClick={() => {
															setToList(true);
															formik.submitForm();
														}}
														disabled={
															formik.isSubmitting
														}
														size="large"
														visible={
															changed &&
															props.editable!
														}
													/>
													{editable && (
														<>
															<Button
																type="primary"
																icon={
																	<SaveOutlined />
																}
																size="large"
																disabled={
																	formik.isSubmitting ||
																	data?.status ==
																		SubjectStatus.DELETED
																}
																onClick={() => {
																	formik.submitForm();
																}}
															>
																Zapisz
															</Button>

															<Button
																type="primary"
																size="large"
																htmlType="submit"
																disabled={
																	formik.isSubmitting ||
																	data?.status ==
																		SubjectStatus.DELETED
																}
																onClick={() => {
																	formik.submitForm();
																	setToList(
																		true
																	);
																}}
															>
																Zapisz i wyjdź
															</Button>
														</>
													)}
													{data?.id &&
														data?.primarySubjectId ==
															null && (
															<>
																<Authorized
																	permission={
																		ProfiBazaAPIModels
																			.Permission
																			.SubjectsMerge
																	}
																>
																	<Button
																		type="primary"
																		size="large"
																		disabled={
																			formik.isSubmitting ||
																			data.status ==
																				SubjectStatus.DELETED
																		}
																		onClick={() => {
																			setSubjectMergeVisible(
																				true
																			);
																		}}
																	>
																		Scal
																		podmioty
																	</Button>
																</Authorized>
															</>
														)}
												</Space>
											</>
										)}
									</>
								)}
							/>
						</>
					)}
				</Col>
			</CenteredRow>
		</>
	);
};

export default observer(SubjectData);
