import React, { useState, useImperativeHandle, useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { List } from 'antd';
import { ajaxCatch } from 'helper/api';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { DictionaryOtherValueHistoryDto } from 'services/src/models';

interface IProps {}

export interface IOtherValueHistoryModal {
	showModal: (valueId: number) => void;
}

const OtherValueHistoryModal = React.forwardRef<
	IOtherValueHistoryModal,
	IProps
>((props, ref) => {
	const [visible, setVisible] = useState<boolean>(false);
	const [id, setId] = useState<number | undefined>(undefined);

	const [data, setData] = useState<DictionaryOtherValueHistoryDto[]>([]);

	useEffect(() => {
		if (id) {
			ajaxCatch(async () => {
				const profiBazaAPI = await getProfiBazaApiClient();
				return profiBazaAPI.dictionaryValue
					.getOtherValueHistory(id)
					.then((response) => {
						setData(response);
						return response;
					});
			});
		}
	}, [id]);

	useImperativeHandle(ref, () => ({
		showModal: (valueId: number) => {
			setVisible(true);
			setId(valueId);
		},
	}));

	const handleClose = () => {
		setVisible(false);
		setId(undefined);
	};

	return (
		<Modal
			visible={visible}
			maskClosable={true}
			centered
			destroyOnClose
			title="Wartości inne"
			onOk={() => {
				handleClose();
			}}
			footer={<></>}
			onCancel={handleClose}
		>
			<List
				itemLayout="vertical"
				size="large"
				dataSource={data ?? []}
				renderItem={(item, index) => (
					<List.Item key={index}>{item.value}</List.Item>
				)}
			/>
		</Modal>
	);
});

export default OtherValueHistoryModal;
