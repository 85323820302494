import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import useTabAccessibility from '@hooks/accessibility/useTabAccessibilityHook';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Col, Tabs } from 'antd';
import PermissionValidator from 'authorization/permissionValidator';
import * as H from 'history';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import React from 'react';
import { SubjectKind } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import SubjectKindPermissions from './SubjectKindPermissions';
import SubjectPermissions from './SubjectPermissions';
import UserPermissions from './UserPermissions';

const { TabPane } = Tabs;

interface IProps {
	activeTab?: 'users' | 'subjects' | 'categories';
	id?: string;
	history: H.History;
}

const PermissionsView: React.FC<IProps> = (props) => {
	const changeActiveTab = (activeTab: string) => {
		props.history.push(`/admin/permissions/${activeTab}`);
	};

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const permissionValidator = new PermissionValidator(
		accountDetailsStore.account.get()!
	);

	useTabAccessibility();

	return (
		<>
			<PageHeaderWithContent title="Zarządzanie uprawnieniami" />
			<SkipLinkContent />
			<CenteredRow>
				<Col span={23}>
					<Tabs
						defaultActiveKey="users"
						activeKey={props.activeTab}
						tabPosition="top"
						type="card"
						tabBarGutter={10}
						onChange={changeActiveTab}
					>
						<TabPane tab="Użytkownicy" key="users">
							{props.activeTab === 'users' && (
								<UserPermissions
									history={props.history}
									userId={props.id}
								/>
							)}
						</TabPane>
						<TabPane tab="Podmioty" key="subjects">
							{props.activeTab === 'subjects' && (
								<SubjectPermissions
									history={props.history}
									subjectId={props.id}
								/>
							)}
						</TabPane>
						{permissionValidator.has(
							ProfiBazaAPIModels.Permission
								.AdminRolesAssignmentGlobal
						) && (
							<TabPane tab="Kategorie podmiotów" key="categories">
								{props.activeTab === 'categories' && (
									<SubjectKindPermissions
										history={props.history}
										subjectKind={
											props.id
												? SubjectKind[
														props.id! as keyof typeof SubjectKind
												  ]
												: undefined
										}
									/>
								)}
							</TabPane>
						)}
					</Tabs>
				</Col>
			</CenteredRow>
		</>
	);
};

export default PermissionsView;
