/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/dictionaryValueMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a DictionaryValue. */
export class DictionaryValue {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a DictionaryValue.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryValueCreateResponse>
   */
  create(options?: Models.DictionaryValueCreateOptionalParams): Promise<Models.DictionaryValueCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.Int32IIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.DictionaryValueCreateOptionalParams, callback: msRest.ServiceCallback<Models.Int32IIdDto>): void;
  create(options?: Models.DictionaryValueCreateOptionalParams | msRest.ServiceCallback<Models.Int32IIdDto>, callback?: msRest.ServiceCallback<Models.Int32IIdDto>): Promise<Models.DictionaryValueCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.DictionaryValueCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryValueUpdateResponse>
   */
  update(options?: Models.DictionaryValueUpdateOptionalParams): Promise<Models.DictionaryValueUpdateResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.DictionaryValueUpdateOptionalParams, callback: msRest.ServiceCallback<string>): void;
  update(options?: Models.DictionaryValueUpdateOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.DictionaryValueUpdateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback) as Promise<Models.DictionaryValueUpdateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryValueOrderResponse>
   */
  order(options?: Models.DictionaryValueOrderOptionalParams): Promise<Models.DictionaryValueOrderResponse>;
  /**
   * @param callback The callback
   */
  order(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  order(options: Models.DictionaryValueOrderOptionalParams, callback: msRest.ServiceCallback<string>): void;
  order(options?: Models.DictionaryValueOrderOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.DictionaryValueOrderResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      orderOperationSpec,
      callback) as Promise<Models.DictionaryValueOrderResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryValueGetByIdResponse>
   */
  getById(id: number, options?: msRest.RequestOptionsBase): Promise<Models.DictionaryValueGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: number, callback: msRest.ServiceCallback<Models.DictionaryValueDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.DictionaryValueDto>): void;
  getById(id: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DictionaryValueDto>, callback?: msRest.ServiceCallback<Models.DictionaryValueDto>): Promise<Models.DictionaryValueGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.DictionaryValueGetByIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(id: number, options?: Models.DictionaryValueDeleteMethodOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteMethod(id: number, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(id: number, options: Models.DictionaryValueDeleteMethodOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(id: number, options?: Models.DictionaryValueDeleteMethodOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryValueGetChildrensResponse>
   */
  getChildrens(id: number, options?: Models.DictionaryValueGetChildrensOptionalParams): Promise<Models.DictionaryValueGetChildrensResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getChildrens(id: number, callback: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getChildrens(id: number, options: Models.DictionaryValueGetChildrensOptionalParams, callback: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): void;
  getChildrens(id: number, options?: Models.DictionaryValueGetChildrensOptionalParams | msRest.ServiceCallback<Models.DictionaryValueItemDto[]>, callback?: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): Promise<Models.DictionaryValueGetChildrensResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getChildrensOperationSpec,
      callback) as Promise<Models.DictionaryValueGetChildrensResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryValueGetValuesByIdsResponse>
   */
  getValuesByIds(options?: Models.DictionaryValueGetValuesByIdsOptionalParams): Promise<Models.DictionaryValueGetValuesByIdsResponse>;
  /**
   * @param callback The callback
   */
  getValuesByIds(callback: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getValuesByIds(options: Models.DictionaryValueGetValuesByIdsOptionalParams, callback: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): void;
  getValuesByIds(options?: Models.DictionaryValueGetValuesByIdsOptionalParams | msRest.ServiceCallback<Models.DictionaryValueItemDto[]>, callback?: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): Promise<Models.DictionaryValueGetValuesByIdsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getValuesByIdsOperationSpec,
      callback) as Promise<Models.DictionaryValueGetValuesByIdsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryValueUpdateParentResponse>
   */
  updateParent(options?: Models.DictionaryValueUpdateParentOptionalParams): Promise<Models.DictionaryValueUpdateParentResponse>;
  /**
   * @param callback The callback
   */
  updateParent(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateParent(options: Models.DictionaryValueUpdateParentOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateParent(options?: Models.DictionaryValueUpdateParentOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.DictionaryValueUpdateParentResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateParentOperationSpec,
      callback) as Promise<Models.DictionaryValueUpdateParentResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryValueGetOtherValueHistoryResponse>
   */
  getOtherValueHistory(id: number, options?: msRest.RequestOptionsBase): Promise<Models.DictionaryValueGetOtherValueHistoryResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getOtherValueHistory(id: number, callback: msRest.ServiceCallback<Models.DictionaryOtherValueHistoryDto[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getOtherValueHistory(id: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.DictionaryOtherValueHistoryDto[]>): void;
  getOtherValueHistory(id: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DictionaryOtherValueHistoryDto[]>, callback?: msRest.ServiceCallback<Models.DictionaryOtherValueHistoryDto[]>): Promise<Models.DictionaryValueGetOtherValueHistoryResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getOtherValueHistoryOperationSpec,
      callback) as Promise<Models.DictionaryValueGetOtherValueHistoryResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryValueCheckDictionaryValueChangeVersionResponse>
   */
  checkDictionaryValueChangeVersion(options?: Models.DictionaryValueCheckDictionaryValueChangeVersionOptionalParams): Promise<Models.DictionaryValueCheckDictionaryValueChangeVersionResponse>;
  /**
   * @param callback The callback
   */
  checkDictionaryValueChangeVersion(callback: msRest.ServiceCallback<Models.DictionaryValueChangeVersionDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  checkDictionaryValueChangeVersion(options: Models.DictionaryValueCheckDictionaryValueChangeVersionOptionalParams, callback: msRest.ServiceCallback<Models.DictionaryValueChangeVersionDto[]>): void;
  checkDictionaryValueChangeVersion(options?: Models.DictionaryValueCheckDictionaryValueChangeVersionOptionalParams | msRest.ServiceCallback<Models.DictionaryValueChangeVersionDto[]>, callback?: msRest.ServiceCallback<Models.DictionaryValueChangeVersionDto[]>): Promise<Models.DictionaryValueCheckDictionaryValueChangeVersionResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      checkDictionaryValueChangeVersionOperationSpec,
      callback) as Promise<Models.DictionaryValueCheckDictionaryValueChangeVersionResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DictionaryValueGetValuesByObjectResponse>
   */
  getValuesByObject(options?: Models.DictionaryValueGetValuesByObjectOptionalParams): Promise<Models.DictionaryValueGetValuesByObjectResponse>;
  /**
   * @param callback The callback
   */
  getValuesByObject(callback: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getValuesByObject(options: Models.DictionaryValueGetValuesByObjectOptionalParams, callback: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): void;
  getValuesByObject(options?: Models.DictionaryValueGetValuesByObjectOptionalParams | msRest.ServiceCallback<Models.DictionaryValueItemDto[]>, callback?: msRest.ServiceCallback<Models.DictionaryValueItemDto[]>): Promise<Models.DictionaryValueGetValuesByObjectResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getValuesByObjectOperationSpec,
      callback) as Promise<Models.DictionaryValueGetValuesByObjectResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "dictionaries/DictionaryValue",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateDictionaryValueCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.Int32IIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "dictionaries/DictionaryValue",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateDictionaryValueCommand
  },
  responses: {
    200: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const orderOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "dictionaries/DictionaryValue/order",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetOrderValueCommand
  },
  responses: {
    200: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/DictionaryValue/{id}",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.DictionaryValueDto
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "dictionaries/DictionaryValue/{id}",
  urlParameters: [
    Parameters.id1
  ],
  queryParameters: [
    Parameters.deleteDescendants
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getChildrensOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/DictionaryValue/{id}/children",
  urlParameters: [
    Parameters.id1
  ],
  queryParameters: [
    Parameters.interventionPaths
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueItemDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getValuesByIdsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/DictionaryValue/ids",
  queryParameters: [
    Parameters.ids
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueItemDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const updateParentOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "dictionaries/DictionaryValue/updateParent",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateDictionaryValueParentCommand
  },
  responses: {
    200: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getOtherValueHistoryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/DictionaryValue/{id}/otherValues",
  urlParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryOtherValueHistoryDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const checkDictionaryValueChangeVersionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "dictionaries/DictionaryValue/CheckDictionaryValueChangeVersion",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CheckDictionaryValueChangeVersionQuery
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueChangeVersionDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getValuesByObjectOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dictionaries/DictionaryValue/GetValuesByObject",
  queryParameters: [
    Parameters.objectId,
    Parameters.objectType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DictionaryValueItemDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};
