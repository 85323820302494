import { useState, Dispatch, SetStateAction } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { uniqueeNotifications } from '../SidebarUtils';

type ReadNotificationsReturnType = {
	loading: boolean;
	fetchData: () => Promise<void>;
	handleInfiniteOnLoad: (page: number) => void;
	hasNextPage: boolean;
	data: ProfiBazaAPIModels.UserNotificationDTO[];
	setData: Dispatch<SetStateAction<ProfiBazaAPIModels.UserNotificationDTO[]>>;
};

const useReadNotifications = (
	signal: AbortSignal
): ReadNotificationsReturnType => {
	const [loading, setLoading] = useState<boolean>(true);
	const [hasNextPage, setHasNextPage] = useState<boolean>(false);
	const [data, setData] = useState<ProfiBazaAPIModels.UserNotificationDTO[]>(
		[]
	);

	const fetchData = async (page: number = 1) => {
		setLoading(true);
		const client = await getProfiBazaApiClient();
		await client.userNotification
			.getReadUserNotifications({
				page: page,
				abortSignal: signal,
			})
			.then((response) => {
				setLoading(false);
				const newData = response.results ?? [];
				setData((prevData) =>
					uniqueeNotifications(prevData.concat(newData))
				);
				setHasNextPage(response.hasNextPage ?? false);
			});
	};

	const handleInfiniteOnLoad = (page: number) => {
		if (!hasNextPage) {
			return;
		}

		fetchData(page);
	};

	return {
		loading,
		fetchData,
		handleInfiniteOnLoad,
		hasNextPage,
		data,
		setData,
	};
};

export default useReadNotifications;
