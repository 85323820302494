/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/subjectApiMappers";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a SubjectApi. */
export class SubjectApi {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a SubjectApi.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @summary (external) API Key
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectApiGetAggregateSubjectResponse>
   */
  getAggregateSubject(options?: msRest.RequestOptionsBase): Promise<Models.SubjectApiGetAggregateSubjectResponse>;
  /**
   * @param callback The callback
   */
  getAggregateSubject(callback: msRest.ServiceCallback<Models.SubjectsAggregateDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAggregateSubject(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SubjectsAggregateDto[]>): void;
  getAggregateSubject(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SubjectsAggregateDto[]>, callback?: msRest.ServiceCallback<Models.SubjectsAggregateDto[]>): Promise<Models.SubjectApiGetAggregateSubjectResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getAggregateSubjectOperationSpec,
      callback) as Promise<Models.SubjectApiGetAggregateSubjectResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getAggregateSubjectOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "external/subjects/aggregate",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubjectsAggregateDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};
