import {
	maxCosts,
	numberOfPeople,
} from 'components/intervention/model/InterventionCommand';
import { NationWideTeryt } from 'components/shared/teryt/TerytAreaPicker/TeryAreaMappers';
import { Moment } from 'moment';
import { DictionariesConstants } from 'services/DictionariesConstants';
import {
	AgeUnit,
	DictionaryInterventionPath,
	RizpDictionaryValueDto,
	SponsoringCompanyDto,
} from 'services/src/models';
import * as Yup from 'yup';

const requiredMessage: string = 'Wartość jest wymagana';
const positiveNumberMessage: string = 'Wartość musi być dodatnia';
const equalOrGreaterThanZeroMessage: string = 'Wartość musi być dodatnia lub równa 0';
const enhanceRequiredMessage = (field: string) =>
	`Pole "${field}" jest wymagane`;

interface IProps {
	interventionPath: DictionaryInterventionPath;
}

export const InterventionActivityValidationSchema = (
	interventionPath: DictionaryInterventionPath,
	interventionEffectDictionaryValues: RizpDictionaryValueDto[]
) =>
	Yup.object().shape({
		activityCategoryDictionaryValueId: Yup.number().required(
			enhanceRequiredMessage('Kategoria działania')
		),
		activityCategoryDictionaryOtherValue: Yup.string().nullable(),
		nzpTaskNumberDictionaryValues: Yup.array().required(
			enhanceRequiredMessage('Numer zadania NPZ')
		),
		publicHealthTaskDictionaryValues: Yup.array().required(
			enhanceRequiredMessage('Zadania zdrowia publicznego')
		),
		prpzDictionaryValues: Yup.array().required(
			enhanceRequiredMessage(
				'Priorytety dla Regionalnej Polityki Zdrowotnej/Wojewódzki Plan Transformacji'
			)
		),
		chosenPopulationHealthsDictionaryValues: Yup.array().test(
			'isChosenPopulationHealthsDictionaryValuesCorrect',
			enhanceRequiredMessage('Zdrowie wybranych populacji'),
			function (values: RizpDictionaryValueDto[]) {
				switch (interventionPath) {
					case DictionaryInterventionPath.PROHEALTHACTIVITIES:
					case DictionaryInterventionPath.LEGISLATION:
					case DictionaryInterventionPath.RESEARCH:
					case DictionaryInterventionPath.SUPERVISIONSANITARY:
						if (!values?.length) return false;

					default:
						return true;
				}
			}
		),
		careAreasDictionaryValues: Yup.array().test(
			'isCareAreasDictionaryValuesCorrect',
			enhanceRequiredMessage('Obszary opieki'),
			function (values: RizpDictionaryValueDto[]) {
				switch (interventionPath) {
					case DictionaryInterventionPath.PROHEALTHACTIVITIES:
						if (!values?.length) return false;

					default:
						return true;
				}
			}
		),
		diseasesMortalityPrevalencesDictionaryValues: Yup.array().test(
			'isDiseasesMortalityPrevalencesDictionaryValuesCorrect',
			enhanceRequiredMessage(
				'Międzynarodowa Statystyczna Klasyfikacja Chorób i Problemów Zdrowotnych ICD-10'
			),
			function (values: RizpDictionaryValueDto[]) {
				switch (interventionPath) {
					case DictionaryInterventionPath.PROHEALTHACTIVITIES:
					case DictionaryInterventionPath.LEGISLATION:
					case DictionaryInterventionPath.RESEARCH:
					case DictionaryInterventionPath.SUPERVISIONSANITARY:
						if (!values?.length) return false;

					default:
						return true;
				}
			}
		),
		sponsoringCompanies: Yup.array()
			.nullable()
			.required('Wybór głównego podmiotu finansującego jest wymagany')
			.test(
				'exactlyOneMainSponsoringSubject',
				'Wybór głównego podmiotu finansującego jest wymagany',
				function (companies: SponsoringCompanyDto[]) {
					return (
						companies?.filter((c) => c.isMainSubject).length === 1
					);
				}
			),
		activityPopulations: getActivityPopulationValidation(interventionPath),
		name: Yup.string()
			.required(enhanceRequiredMessage('Nazwa'))
			.nullable()
			.max(250, 'Nazwa nie może być dłuższa niż 250 znaków'),
		comment: Yup.string()
			.nullable()
			.max(4000, 'Uwagi nie mogą być dłuższe niż 4000 znaków'),
		topic: Yup.string()
			.nullable()
			.max(256, 'Temat nie może być dłuższy niż 256 znaków')
			.test(
				'isSubjectCorrect',
				interventionPath ==
					DictionaryInterventionPath.PROHEALTHACTIVITIES
					? "Pole 'Temat' jest wymagane."
					: "Pole 'Temat (efekty interwencji)' jest wymagane.",
				function (value: string) {
					switch (interventionPath) {
						case DictionaryInterventionPath.PROHEALTHACTIVITIES:
							// let values = this.parent[
							// 	'categoryDictionaryValues'
							// ]?.map((x: RizpDictionaryValueDto) => x.codeName!);
							// if (!values) return true;

							// let dictionariesValuesWhereSubjectIsRequired = values.some(
							// 	(x: any) =>
							// 		x ==
							// 			DictionariesConstants.ActivityCategory
							// 				.EducationMeetingTalkLectureConference ||
							// 		x ==
							// 			DictionariesConstants.ActivityCategory
							// 				.Workshops_Training_TeachingSkillsInstruction
							// );

							// if (
							// 	dictionariesValuesWhereSubjectIsRequired &&
							// 	!value
							// )
							// 	return false;
							return true;

						case DictionaryInterventionPath.RESEARCH:
							let dictionariesValuesWhereSubjectIsRequiredResearch = interventionEffectDictionaryValues?.find(
								(x) =>
									x.codeName ==
									DictionariesConstants.InterventionEffect
										.Conference
							);

							if (
								dictionariesValuesWhereSubjectIsRequiredResearch &&
								!value
							)
								return false;

							return true;

						default:
							return true;
					}
				}
			),
		description: Yup.string()
			.nullable()
			.max(4000, 'Opis nie może być dłuższy niż 4000 znaków'),
		numberOfRecipients:
			interventionPath ===
				DictionaryInterventionPath.PROHEALTHACTIVITIES ||
				interventionPath === DictionaryInterventionPath.STAFFEDUCATION ||
				interventionPath === DictionaryInterventionPath.RESEARCH
				? Yup.number()
					.required(enhanceRequiredMessage('Liczba odbiorców'))
					.nullable()
					.min(0, 'Liczba odbiorców nie może być ujemna')
					.max(
						numberOfPeople,
						`Liczba odbiorców nie może być większa niż ${numberOfPeople}`
					)
				: Yup.number()
					.nullable()
					.min(0, 'Liczba odbiorców nie może być ujemna')
					.max(
						numberOfPeople,
						`Liczba odbiorców nie może być większa niż ${numberOfPeople}`
					),
		numberOfPersonAction:
			interventionPath == DictionaryInterventionPath.PROHEALTHACTIVITIES
				? Yup.number()
					.required(enhanceRequiredMessage('Liczba osobodziałań'))
					.nullable()
					.min(0, 'Liczba osobodziałań nie może być ujemna')
					.min(
						Yup.ref('numberOfRecipients'),
						'Liczba odbiorców nie może być większa niż liczba osobodziałań'
					)
				: Yup.number()
					.nullable()
					.min(0, 'Liczba osobodziałań nie może być ujemna')
					.min(
						Yup.ref('numberOfRecipients'),
						'Liczba odbiorców nie może być większa niż liczba osobodziałań'
					),
		realizationDate: Yup.array()
			.required(enhanceRequiredMessage('Data realizacji'))
			.test(
				'isSameYear',
				'Data końcowa musi być w tym samym roku co data początkowa',
				(value: [Moment, Moment]) =>
					value?.[0]?.toDate().getFullYear() ==
					value?.[1]?.toDate().getFullYear()
			),
		ikpBenefits: Yup.string()
			.nullable()
			.max(
				2500,
				'Pole "Korzyści dla uczestnika programu" nie może być dłuższe niż 2500 znaków'
			)
			.test(
				'isBenefitsCorrect',
				'Pole "Korzyści dla uczestnika programu" jest wymagane',
				function (value: string) {
					let publishedInIkp = this.parent['publishedInIkp'];
					if (!value && publishedInIkp!) return false;
					return true;
				}
			),
		ikpIncludeCriteria: Yup.string()
			.nullable()
			.max(
				2500,
				'Pole "Kryteria włączenia" nie może być dłuższe niż 2500 znaków'
			)
			.test(
				'isIkpIncludeCriteriaCorrect',
				'Pole "Kryteria włączenia" jest wymagane',
				function (value: string) {
					let publishedInIkp = this.parent['publishedInIkp'];
					if (!value && publishedInIkp!) return false;
					return true;
				}
			),
		ikpExcludeCriteria: Yup.string()
			.nullable()
			.max(
				2500,
				'Pole "Kryteria wyłączenia" nie może być dłuższe niż 2500 znaków'
			)
			.test(
				'isIkpExcludedCriteriaCorrect',
				'Pole "Kryteria wyłączenia" jest wymagane',
				function (value: string) {
					let publishedInIkp = this.parent['publishedInIkp'];
					if (!value && publishedInIkp!) return false;
					return true;
				}
			),
		ikpStateDictionaryValueId: Yup.number()
			.nullable()
			.test(
				'isIkpStateCorrect',
				'Pole "Status rekrutacji" jest wymagane',
				function (value: string) {
					let publishedInIkp = this.parent['publishedInIkp'];
					if (!value && publishedInIkp!) return false;
					return true;
				}
			),
		ikpRecrutationDate: Yup.array()
			.nullable()
			.test(
				'isIkpRecrutationDateCorrect',
				'Pole "Data rekrutacji" jest wymagane',
				function (value: any) {
					let publishedInIkp = this.parent['publishedInIkp'];
					if ((!value || !value[0] || !value[1]) && publishedInIkp!)
						return false;
					return true;
				}
			),
		ikpSourceOfKnowledge: Yup.string()
			.nullable()
			.max(
				500,
				'Pole "Źródło wiedzy o programie" nie może być dłuższe niż 500 znaków'
			),
	});

export const InterventionActivityValidationPatternSchema = (
	interventionPath: DictionaryInterventionPath,
	interventionEffectDictionaryValues: RizpDictionaryValueDto[]
) =>
	Yup.object().shape({
		categoryDictionaryValues: Yup.array().nullable(),
		activityCategoryDictionaryValueId: Yup.number().required(
			enhanceRequiredMessage('Kategoria działania')
		),
		activityCategoryDictionaryOtherValue: Yup.string().nullable(),
		nzpTaskNumberDictionaryValues: Yup.array().nullable(),
		publicHealthTaskDictionaryValues: Yup.array().nullable(),
		prpzDictionaryValues: Yup.array().nullable(),
		name: Yup.string()
			.required(enhanceRequiredMessage('Nazwa'))
			.nullable()
			.max(250, 'Nazwa nie może być dłuższa niż 250 znaków'),
		comment: Yup.string()
			.max(4000, 'Uwagi nie mogą być dłuższe niż 4000 znaków')
			.nullable(),
		topic: Yup.string()
			.nullable()
			.max(256, 'Temat nie może być dłuższy niż 256 znaków'),
		description: Yup.string()
			.max(4000, 'Opis nie może być dłuższy niż 4000 znaków')
			.nullable(),
		numberOfRecipients: Yup.number()
			.nullable()
			.min(0, 'Liczba odbiorców nie może być ujemna')
			.max(
				numberOfPeople,
				`Liczba odbiorców nie może być większa niż ${numberOfPeople}`
			),
		numberOfPersonAction: Yup.number()
			.nullable()
			.min(0, 'Liczba osobodziałań nie może być ujemna')
			.max(
				numberOfPeople,
				`Liczba osobodziałań nie może być większa niż ${numberOfPeople}`
			),
		realizationDate: Yup.array().test(
			'isSameYear',
			'Data końcowa musi być w tym samym roku co data początkowa',
			(value: [Moment, Moment]) =>
				value?.[0]?.toDate().getFullYear() ==
				value?.[1]?.toDate().getFullYear()
		),
		ikpBenefits: Yup.string()
			.nullable()
			.max(
				2500,
				'Pole "Korzyści dla uczestnika programu" nie może być dłuższe niż 2500 znaków'
			)
			.test(
				'isBenefitsCorrect',
				'Pole "Korzyści dla uczestnika programu" jest wymagane',
				function (value: string) {
					let publishedInIkp = this.parent['publishedInIkp'];
					if (!value && publishedInIkp!) return false;
					return true;
				}
			),
		ikpIncludeCriteria: Yup.string()
			.nullable()
			.max(
				2500,
				'Pole "Kryteria włączenia" nie może być dłuższe niż 2500 znaków'
			)
			.test(
				'isIkpIncludeCriteriaCorrect',
				'Pole "Kryteria włączenia" jest wymagane',
				function (value: string) {
					let publishedInIkp = this.parent['publishedInIkp'];
					if (!value && publishedInIkp!) return false;
					return true;
				}
			),
		ikpExcludeCriteria: Yup.string()
			.nullable()
			.max(
				2500,
				'Pole "Kryteria wyłączenia" nie może być dłuższe niż 2500 znaków'
			)
			.test(
				'isIkpExcludedCriteriaCorrect',
				'Pole "Kryteria wyłączenia" jest wymagane',
				function (value: string) {
					let publishedInIkp = this.parent['publishedInIkp'];
					if (!value && publishedInIkp!) return false;
					return true;
				}
			),
		ikpStateDictionaryValueId: Yup.number()
			.nullable()
			.test(
				'isIkpStateCorrect',
				'Pole "Status rekrutacji" jest wymagane',
				function (value: string) {
					let publishedInIkp = this.parent['publishedInIkp'];
					if (!value && publishedInIkp!) return false;
					return true;
				}
			),
		ikpRecrutationDate: Yup.array()
			.nullable()
			.test(
				'isIkpRecrutationDateCorrect',
				'Pole "Data rekrutacji" jest wymagane',
				function (value: any) {
					let publishedInIkp = this.parent['publishedInIkp'];
					if ((!value || !value[0] || !value[1]) && publishedInIkp!)
						return false;
					return true;
				}
			),
		ikpSourceOfKnowledge: Yup.string()
			.nullable()
			.max(
				500,
				'Pole "Źródło wiedzy o programie" nie może być dłuższe niż 500 znaków'
			),
	});

export const PopulationValidationSchema = (
	isPattern: boolean,
	interventionPath: DictionaryInterventionPath
) => {
	const isTotalRecipientsRequired =
		isPattern ||
		interventionPath === DictionaryInterventionPath.SUPERVISIONMONITORING ||
		interventionPath === DictionaryInterventionPath.SUPERVISIONSANITARY ||
		interventionPath == DictionaryInterventionPath.STAFFMONITORING;

	return Yup.object().shape({
		genderDictionaryValueId: Yup.number().required(
			enhanceRequiredMessage('Płeć')
		),
		targetPopulationDictionaryValueId: Yup.number().required(
			enhanceRequiredMessage('Populacja docelowa ze względu na miejsce zamieszkania')
		),
		interventionLocations: Yup.array()
			.nullable()
			.required(enhanceRequiredMessage('Miejsce interwencji')),
		healthConditions: Yup.array()
			.nullable()
			.required(enhanceRequiredMessage('Stan zdrowia')),
		socialExclusions: Yup.array()
			.nullable()
			.required(
				enhanceRequiredMessage(
					'Wykluczenie społeczne/zagrożenie wykluczeniem społecznym'
				)
			),
		otherSpecialFeatures: Yup.array()
			.nullable()
			.required(enhanceRequiredMessage('Inne szczególne cechy')),
		totalRecipients: isTotalRecipientsRequired
			? Yup.number()
				.nullable()
				.transform((value: string, originalValue: string) => originalValue.trim() === "" ? null : value)
			: Yup.number()
				.required(enhanceRequiredMessage('Liczba odbiorców'))
				.positive(positiveNumberMessage),
		ageUnit: Yup.string().required(
			enhanceRequiredMessage('Jednostka wieku')
		),
		minAge: Yup.number()
			.required(enhanceRequiredMessage('Minimalny wiek'))
			.when('ageUnit', {
				is: AgeUnit.Weeks,
				then: Yup.number()
					.max(52, 'Wiek nie może być większy niż 52 tygodnie')
					.min(0, positiveNumberMessage),
				othwerwise: Yup.number().positive(positiveNumberMessage),
			}),
		maxAge: Yup.number()
			.when('ageUnit', {
				is: AgeUnit.Weeks,
				then: Yup.number()
					.max(52, 'Wiek nie może być większy niż 52 tygodnie')
					.min(
						Yup.ref('minAge'),
						'Maksymalny wiek musi być większy od minimalnego'
					)
					.typeError('Maksymalny wiek musi być liczbą'),
				otherwise: Yup.number()
					.min(
						Yup.ref('minAge'),
						'Maksymalny wiek musi być większy od minimalnego'
					)
					.typeError('Maksymalny wiek musi być liczbą')
			})
			.nullable()
			.transform((value: string, originalValue: string) => originalValue === null ? null : value)
	});
};

export const ImplementersValidationSchema = Yup.object().shape({
	implementingCompaniesId: Yup.mixed()
		.when('isArray', {
			is: Array.isArray,
			then: Yup.array().of(Yup.string()),
			otherwise: Yup.string(),
		})
		.required(requiredMessage),
});

export const SponsorsValidationSchema = (
	isPattern: boolean,
	interventionPath: DictionaryInterventionPath
) =>
	isPattern
		? Yup.object().shape({
			sponsorCompaniesId: Yup.mixed().when('isArray', {
				is: Array.isArray,
				then: Yup.array().of(
					Yup.string().required(requiredMessage)
				),
				otherwise: Yup.string()
					.nullable()
					.when(['subjectId'], {
						is: (subjectId) => !subjectId,
						then: Yup.string().required(requiredMessage),
					}),
			}),
			financingSourceDictionaryValues: Yup.array().required(
				'Wymagane jest conajmniej jedno źródło finansowania'
			),
			plannedCosts: Yup.number()
				.positive(positiveNumberMessage)
				.max(
					maxCosts,
					`Koszty planowane nie mogą być większe niż ${maxCosts}`
				),
			realizationCosts: Yup.number()
				.nullable()
				.min(0, positiveNumberMessage)
				.max(
					maxCosts,
					`Koszty planowane nie mogą być większe niż ${maxCosts}`
				),
		})
		: Yup.object().shape({
			sponsorCompaniesId: Yup.mixed().when('isArray', {
				is: Array.isArray,
				then: Yup.array().of(
					Yup.string().required(requiredMessage)
				),
				otherwise: Yup.string()
					.nullable()
					.when(['subjectId'], {
						is: (subjectId) => !subjectId,
						then: Yup.string().required(requiredMessage),
					}),
			}),
			financingSourceDictionaryValues: Yup.array().required(
				'Wymagane jest conajmniej jedno źródło finansowania'
			),
			plannedCosts: Yup.number().when(
				'interventionPath',
				(
					_: DictionaryInterventionPath,
					schema: Yup.NumberSchema
				) => {
					if (
						interventionPath ===
						DictionaryInterventionPath.PROHEALTHACTIVITIES
					) {
						return schema
							.required(requiredMessage)
							.min(0, equalOrGreaterThanZeroMessage)
							.max(
								maxCosts,
								`Koszty planowane nie mogą być większe niż ${maxCosts}`
							);
					}
					return schema;
				}
			),
			realizationCosts: Yup.number()
				.nullable()
				.min(0, positiveNumberMessage)
				.max(
					maxCosts,
					`Koszty planowane nie mogą być większe niż ${maxCosts}`
				),
		});

export const StagesValidationSchema = (interventionAreas: string[]) =>
	Yup.object().shape({
		id: Yup.string().notRequired(),
		number: Yup.number().notRequired(),
		recruitmentStatusDictionaryValueId: Yup.number().required(
			requiredMessage
		),
		name: Yup.string()
			.required(requiredMessage)
			.max(250, 'Nazwa nie może być dłuższa niż 250 znaków'),
		date: Yup.mixed()
			.when('isArray', {
				is: Array.isArray,
				then: Yup.array().of(Yup.string()),
				otherwise: Yup.string(),
			})
			.required(requiredMessage),
		terytCodes: Yup.array()
			.of(Yup.string())
			.required('Wymagany jest conajmniej jeden teren')
			.test(
				'teryt-areas-against-intervention-areas-test',
				'Zdefiniowane tereny są niezgodne z terenem interwencji',
				function (stageCodes: string[]) {
					if (!stageCodes?.length) return false;

					for (const stageCode of stageCodes) {
						if (
							!isCodeWithinRestrictedAreas(
								interventionAreas,
								stageCode
							)
						)
							return false;
					}

					return true;
				}
			),
	});

export const CoProducersValidationSchema = () =>
	Yup.object().shape({
		subjectId: Yup.string().required('Współrealizator jest wymagany'),
		roles: Yup.array()
			.nullable()
			.required('Wymagana jest conajmniej jedna rola współrealizatora'),
	});

export const isCodeWithinRestrictedAreas = (
	restrictTerytAreas: string[],
	stageCode: string
): boolean => {
	const isWithinAreas = restrictTerytAreas.some((area) =>
		stageCode.startsWith(area)
	);
	if (
		!isWithinAreas &&
		!restrictTerytAreas.includes(NationWideTeryt.terytCode)
	) {
		return false;
	} else return true;
};

const getActivityPopulationValidation = (
	interventionPath: DictionaryInterventionPath
) => {
	if (interventionPath === DictionaryInterventionPath.PROHEALTHACTIVITIES) {
		return Yup.array().nullable().required('Populacja jest wymagana');
	}
	return Yup.array().nullable();
};
