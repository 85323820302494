import {
	CloseOutlined,
	DownOutlined,
	LoadingOutlined,
	SearchOutlined,
	UpOutlined,
} from '@ant-design/icons';
import { Button, Input, List, Spin } from 'antd';
import Downshift from 'downshift';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

interface IProps {
	onChange: (value: any) => void;
	onSearchClear?: () => void;
	defaultValue?: string;
	itemToString: (item: any) => string;
	itemToDisplayValue: (item: any) => string;
	getData: (
		page: number,
		searchCriteria: string,
		additionalData?: any
	) => Promise<any>;
	additionalData?: any;
}

const DownshiftSearch: React.FC<IProps> = (props) => {
	const {
		onChange,
		onSearchClear,
		defaultValue,
		itemToString,
		itemToDisplayValue,
		getData,
		additionalData,
	} = props;

	const [values, setValues] = useState<any[]>([]);
	const [typingTimeout, setTypingTimeout] = useState<any>();
	const [hasNextPage, setHasNextPage] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [value, setValue] = useState<string>('');

	const fetchData = async (
		inputValue?: string | undefined,
		page: number = 1,
		concatResponse: boolean = false
	) => {
		let searchCriteria: string = inputValue ?? value;

		setLoading(true);
		getData(page, searchCriteria, additionalData)
			.then((response) => {
				const responseData = response.results ?? [];
				if (concatResponse) {
					setValues((prevData) => prevData.concat(responseData));
				} else {
					setValues(responseData);
				}
				setHasNextPage(response.hasNextPage ?? false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onChangeInputValue = (value: any) => {
		const { inputValue, type } = value;
		if (type === '__autocomplete_change_input__' || type === 11) {
			if (
				inputValue == undefined ||
				inputValue === '' ||
				inputValue.length < 2
			) {
				setValues([]);
				setTypingTimeout(undefined);
				return;
			}

			if (typingTimeout) {
				clearTimeout(typingTimeout);
			}

			setValue(inputValue);

			let timer: ReturnType<typeof setTimeout> = setTimeout(
				() => fetchData(inputValue),
				1000
			);

			setTypingTimeout(timer);
		}
	};

	const handleFetchNextData = (page: number) => {
		if (!hasNextPage) {
			return;
		}

		fetchData(undefined, page, true);
	};

	return (
		<Downshift
			id="downshift-search"
			aria-label="pisz by wyszukać"
			itemToString={itemToDisplayValue}
			onChange={(selectedItem) => {
				if (selectedItem) {
					onChange(selectedItem);
				}
			}}
			onStateChange={onChangeInputValue}
			onSelect={(selectedItem) => {
				if (selectedItem) {
					setTypingTimeout(undefined);
				}
			}}
		>
			{({
				getInputProps,
				getItemProps,
				isOpen,
				inputValue,
				clearSelection,
				getToggleButtonProps,
			}) => {
				const renderInputAddon = () => {
					if (loading)
						return (
							<span className="ant-btn-loading-icon">
								<LoadingOutlined style={{ fontSize: 24 }} />
							</span>
						);
					else if (inputValue) {
						return (
							<>
								<Button
									size="large"
									type="ghost"
									className="ant-input-search-button"
									aria-label="Wyczyść"
									icon={<CloseOutlined />}
									onClick={() => {
										clearSelection();
										if (onSearchClear) {
											onSearchClear();
										}
									}}
								/>
								{values && values.length > 0 && (
									<Button
										{...getToggleButtonProps()}
										size="large"
										type="ghost"
										className="ant-input-search-button"
										aria-label={isOpen ? 'Rozwiń' : 'Zwiń'}
										icon={
											isOpen ? (
												<UpOutlined />
											) : (
												<DownOutlined />
											)
										}
									/>
								)}
							</>
						);
					}
					return <></>;
				};

				let styleSearchBar = {};
				if (isOpen && values && values.length > 0) {
					styleSearchBar = {
						borderBottomLeftRadius: '0px',
						borderBottomRightRadius: '0px',
					};
				} else {
					styleSearchBar = {};
				}

				return (
					<div
						className="searchDownshif"
						style={{ position: 'relative' }}
					>
						<>
							<label
								id="downshift-search-label"
								hidden={true}
								aria-hidden={true}
							>
								pisz aby wyszukać
							</label>
							<span
								className="ant-input-group-wrapper ant-input-group-wrapper-lg ant-input-search ant-input-search-large searchBar"
								style={{
									...styleSearchBar,
								}}
							>
								<span
									className="ant-input-wrapper ant-input-group"
									style={{
										paddingLeft: '1em',
										paddingRight: '1em',
									}}
								>
									<span className="ant-input-group-addon">
										<SearchOutlined />
									</span>
									<Input
										placeholder="pisz aby wyszukać"
										{...getInputProps()}
										bordered={false}
										defaultValue={defaultValue}
									/>
									<span className="ant-input-group-addon">
										{renderInputAddon()}
									</span>
								</span>
							</span>
						</>

						{isOpen && values && values.length > 0 && (
							<div
								className="searchDownshiftList"
								style={{
									position: 'absolute',
									paddingBottom: '1em',
								}}
							>
								<div
									style={{
										overflow: 'auto',
										maxHeight: '300px',
									}}
								>
									<InfiniteScroll
										initialLoad={false}
										pageStart={1}
										loadMore={handleFetchNextData}
										hasMore={hasNextPage}
										useWindow={false}
										loader={
											<Spin
												style={{
													fontSize: 24,
													textAlign: 'center',
													marginLeft: '50%',
												}}
											/>
										}
									>
										<List
											dataSource={values}
											renderItem={(item: any, index) => (
												<List.Item
													style={{
														cursor: 'pointer',
														padding: '1em',
													}}
													{...getItemProps({
														key:
															item.simcCode ??
															item.tercCode,
														index,
														item: item,
													})}
												>
													{itemToString(item)}
												</List.Item>
											)}
										></List>
									</InfiniteScroll>
								</div>
							</div>
						)}
					</div>
				);
			}}
		</Downshift>
	);
};

export default DownshiftSearch;
