import { WarningTwoTone } from '@ant-design/icons';
import {
	BusinessObjectType,
	DictionaryValueGetValuesByObjectResponse,
} from '@services/src/models';
import { Col, Row, Space } from 'antd';
import AddButton from 'components/shared/buttons/AddButton';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { FormikActions } from 'formik';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import ProfiBazaTable from 'layout/ProfiBazaTable';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import _ from 'lodash';
import React, { useState } from 'react';
import { IRizpDictionaryStore } from 'stores/RizpDictionaryStore';

import ActivityDetailsFormCard from './ActivityDetailsFormCard';

interface IProps<T> {
	onCancel?: () => void;
	onCreate: (values: T, formikActions: FormikActions<T>) => void;
	onRemove?: (values: T[]) => void;
	onUpdate: (
		oldValues: T,
		updatedValues: T,
		formikActions: FormikActions<T>
	) => void;
	cleanSideEffects?: { key: string; value: any }[];
	canEdit: boolean;
	canPreview: ((item: T) => boolean) | boolean;
	onPreview?: (item: T) => void;
	cardContent: (
		item?: T,
		formikActions?: FormikActions<T>,
		previewMode?: boolean
	) => JSX.Element;
	editable: boolean;
	customHeaderButton?: JSX.Element;
	columns: any;
	array: T[];
	addButtonLabel: string;
	validationScheme: any;
	validateOnBlur?: boolean;
	changedDictionariesValues?: T[] | undefined;
	getItemId: (item: T) => any;
	patternCollection: T[];
	isPattern: boolean;
	setVisible?: () => void;
	onRecordSelect?: (record: any) => void;
	initRizpDictionary: (
		objectId: string
	) => Promise<DictionaryValueGetValuesByObjectResponse | void>;
}

interface IState<T> {
	data?: T;
	visible: boolean;
	previewMode: boolean;
}

const ActivityDetailsTableTemplate = <
	T extends { prevSubjectId?: string; prevSubjectName?: string }
>(
	props: IProps<T>
) => {
	const { array } = props;
	const [state, setState] = useState<IState<T>>({
		visible: false,
		previewMode: false,
	});

	const isRecordDeletable = (record: any): boolean =>
		(props.editable && !props.patternCollection?.length) ||
		(props.editable &&
			!props.patternCollection?.some(
				(x: any) =>
					!Object.keys(x)
						.filter((key) => !key.includes('id'))
						.some(
							(key) =>
								!Object.keys(record)
									.filter((key2) => !key2.includes('id'))
									.some(
										(key2) =>
											key == key2 &&
											record[key2] == x[key]
									)
						)
			));

	const isRecordEditable = (record: any): boolean =>
		props.canEdit &&
		!props.patternCollection?.some(
			(x: any) =>
				!Object.keys(x)
					.filter((key) => !key.includes('id'))
					.some(
						(key) =>
							!Object.keys(record)
								.filter((key2) => !key2.includes('id'))
								.some(
									(key2) =>
										key == key2 && record[key2] == x[key]
								)
					)
		);

	const columns = props.editable
		? [
				array.some((x) => x.prevSubjectId)
					? {
							key: 'actions',
							title: 'Kolumna akcji',
							className: 'actionstd',
							render: (text: string, record: T) =>
								record.prevSubjectId && (
									<ProfiBazaTooltip
										placement="top"
										title={`Podmiot scalony - ${record.prevSubjectName}`}
									>
										<TableActionButton
											kind="WARNING"
											label="Scal podmioty"
										/>
									</ProfiBazaTooltip>
								),
					  }
					: {
							className: 'actionstd',
							title: 'Kolumna akcji',
							width: 0,
					  },
				props.changedDictionariesValues &&
				props.changedDictionariesValues.length > 0 &&
				!props.changedDictionariesValues.some((x: any) =>
					Object.keys(x)
						.filter(
							(keyx) =>
								keyx.includes('ValueId') ||
								keyx.includes('Values') ||
								keyx.includes('roles')
						)
						.some((keyx) =>
							array.some((y: any) =>
								Object.keys(y)
									.filter(
										(keyy) =>
											(keyy.includes('ValueId') ||
												keyy.includes('Values') ||
												keyy.includes('roles')) &&
											!keyy.includes('gender')
									)
									.some((keyy) => _.isEqual(x[keyx], y[keyy]))
							)
						)
				)
					? {
							key: 'actions',
							className: 'actionstd',
							title: 'Kolumna akcji',
							render: () => (
								<ProfiBazaTooltip
									placement="top"
									title="Zmieniona wersja słownikowa"
								>
									<WarningTwoTone twoToneColor="#ff0000" />
								</ProfiBazaTooltip>
							),
					  }
					: {
							className: 'actionstd',
							title: 'Kolumna akcji',
							width: 0,
					  },
				...props.columns,
				RenderActions<T>((text, record: any, index) => (
					<>
						{((typeof props.canPreview === 'boolean' &&
							props.canPreview) ||
							(typeof props.canPreview === 'function' &&
								props.canPreview(record))) && (
							<ProfiBazaTooltip placement="top" title="Szczegóły">
								<TableActionButton
									label="Szczegóły"
									kind="DETAILS"
									disabled={state.visible}
									onClick={() => {
										props
											.initRizpDictionary(record.id)
											.then(() => {
												if (props.onPreview) {
													props.onPreview(record);
												} else {
													setState({
														...state,
														data: record,
														visible: true,
														previewMode: true,
													});

													if (props.onRecordSelect) {
														props.onRecordSelect(
															record
														);
													}
												}
											});
									}}
								/>
							</ProfiBazaTooltip>
						)}

						{isRecordEditable(record) && (
							<ProfiBazaTooltip placement="top" title="Edytuj">
								<TableActionButton
									kind="EDIT"
									label="Edytuj"
									disabled={state.visible}
									onClick={() => {
										setState({
											...state,
											data: record,
											visible: true,
											previewMode: false,
										});

										if (props.onRecordSelect) {
											props.onRecordSelect(record);
										}
									}}
								/>
							</ProfiBazaTooltip>
						)}

						{isRecordDeletable(record) && (
							<ProfiBazaTooltip placement="top" title="Usuń">
								<AccessiblePopconfirm
									title={
										'Czy na pewno chcesz usunąć element?'
									}
									onConfirm={() => {
										setState({
											...state,
											data: undefined,
										});
										const filteredArray = array.filter(
											(item) =>
												props.getItemId(item) !==
												props.getItemId(record)
										);
										props.onRemove &&
											props.onRemove(filteredArray);
									}}
								>
									<TableActionButton
										kind="DELETE"
										label="Usuń"
									/>
								</AccessiblePopconfirm>
							</ProfiBazaTooltip>
						)}
					</>
				)),
		  ]
		: [
				...props.columns,
				RenderActions<T>((text, record: any) => (
					<>
						{!props.editable &&
							((typeof props.canPreview === 'boolean' &&
								props.canPreview) ||
								(typeof props.canPreview === 'function' &&
									props.canPreview(record))) && (
								<ProfiBazaTooltip
									placement="top"
									title="Szczegóły"
								>
									<TableActionButton
										kind="DETAILS"
										label="Szczegóły"
										disabled={state.visible}
										onClick={() => {
											props
												.initRizpDictionary(record.id)
												.then(() => {
													if (props.onPreview) {
														props.onPreview(record);
													} else {
														setState({
															...state,
															data: record,
															visible: true,
															previewMode: true,
														});

														if (
															props.onRecordSelect
														) {
															props.onRecordSelect(
																record
															);
														}
													}
												});
										}}
									/>
								</ProfiBazaTooltip>
							)}
					</>
				)),
		  ];

	return (
		<CenteredRow style={{ marginTop: '20px' }}>
			<Col span={24}>
				<Row style={{ marginBottom: '10px' }}>
					{props.editable && (
						<Space>
							<AddButton
								type="primary"
								onClick={() => {
									setState((prevState) => ({
										...prevState,
										visible: true,
									}));
									props.setVisible && props.setVisible();
								}}
								disabled={!props.setVisible && state.visible}
							>
								{props.addButtonLabel}
							</AddButton>
							{props.customHeaderButton &&
								props.customHeaderButton}
						</Space>
					)}
				</Row>

				<ProfiBazaTable
					columns={columns}
					pagination={false}
					rowKey={(r: any) => r?.name?.toString()}
					dataSource={array}
					onRow={(record: any) => {
						return {
							onDoubleClick: () => {
								props.initRizpDictionary(record.id).then(() => {
									if (props.onPreview) {
										props.onPreview(record);
									} else if (!props.editable) {
										setState({
											...state,
											data: record,
											visible: true,
											previewMode: true,
										});

										if (props.onRecordSelect) {
											props.onRecordSelect(record);
										}
									}
								});
							},
						};
					}}
				/>
			</Col>

			<Col span={24} style={{ padding: '8px 0' }}>
				<ActivityDetailsFormCard<T>
					data={state.data}
					editable={props.editable}
					previewMode={state.previewMode}
					visible={state.visible}
					visibleButtons={!props.setVisible}
					validationScheme={props.validationScheme}
					validateOnBlur={props.validateOnBlur}
					cleanSideEffects={props.cleanSideEffects}
					onSubmit={(values: T, actions: FormikActions<T>) => {
						if (state.data) {
							props.onUpdate(state.data!, values, actions);
						} else {
							props.onCreate(values, actions);
						}

						setState((st) => ({
							...st,
							data: undefined,
						}));
					}}
					handleClose={() => {
						setState((st) => ({
							...st,
							visible: false,
							data: undefined,
							previewMode: false,
						}));
						if (props.onCancel) {
							props.onCancel();
						}
					}}
					content={props.cardContent}
				></ActivityDetailsFormCard>
			</Col>
		</CenteredRow>
	);
};

ActivityDetailsTableTemplate.defaultProps = {
	canEdit: true,
	canPreview: false,
};

export default ActivityDetailsTableTemplate;
