import { LoadingOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import React from 'react';
import { MenuButton, MenuStateReturn } from 'reakit/Menu';
interface IProps {
	loading?: boolean;
	circle?: boolean;
	menuState: MenuStateReturn;
	ariaLabel: string;
}

const ReakitMenuButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
	const { loading, circle, menuState, children, ariaLabel } = props;

	return (
		<MenuButton
			aria-label={ariaLabel}
			{...menuState}
			className={clsx(
				'ant-btn ant-btn-ghost',
				circle && 'ant-btn-circle',
				'ant-btn-sm',
				'ant-btn-icon-only',
				loading && 'ant-btn-loading'
			)}
		>
			{loading ? (
				<span className="ant-btn-loading-icon">
					<LoadingOutlined />
				</span>
			) : (
				<span role="button" aria-label={ariaLabel}>
					{children}
				</span>
			)}
		</MenuButton>
	);
};

ReakitMenuButton.defaultProps = {};

export default ReakitMenuButton;
