import useIsNotEmptyArray from '@hooks/UseIsNotEmptyArrayHook';
import { Button, Col, Row, Steps } from 'antd';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useContext } from 'react';

import { MultiDictionaryBodyContext } from './contexts/MultiDictionaryBodyContext';
import { MultiDictionaryContext } from './contexts/MultiDictionaryContext';
import ProcessSelector from './ProcessSelector';
import SortableDictionaryComponent from './SortableDictionaryComponent';

const toRoman = require('roman-numerals').toRoman;

interface IProps {}

const MultiLevelDictionaryBody: React.FC<IProps> = (props) => {
	const dictionaryBodyState = useContext(MultiDictionaryBodyContext);

	const {
		currentLevel,
		handleLevelChange,
		levels,
		keysStack,
		switchToLevel,
		renderSearchButton,
		selectableMode,
		rootValue,
		values,
		dictionaryState,
		processFilters,
		setProcessFilters,
	} = dictionaryBodyState;

	const levelStackNotEmpty = useIsNotEmptyArray(levels.levelStack);

	return (
		<>
			{!selectableMode && (
				<Row justify="center">
					<Col>
						<ProcessSelector
							disabled={rootValue?.id! !== undefined}
							processFilters={processFilters}
							setProcessFilters={setProcessFilters!}
						/>
					</Col>
				</Row>
			)}
			<Row justify="center">
				<Col span={22}>
					{levelStackNotEmpty && (
						<Steps
							type="navigation"
							current={currentLevel}
							onChange={handleLevelChange}
							className="site-navigation-steps"
						>
							{levels.levelStack.map((level, index) => (
								<Steps.Step
									key={`step-${index}`}
									icon={
										<Button type="primary" shape="circle">
											{toRoman(index + 1)}
										</Button>
									}
									style={{ maxWidth: 200 }}
									status="process"
									title={keysStack[index]?.code}
									onClick={() => {
										switchToLevel(index);
									}}
								/>
							))}
						</Steps>
					)}
				</Col>

				<Col span={2} style={{ display: 'flex', alignItems: 'center' }}>
					<ProfiBazaTooltip placement="top" title="Wyszukaj">
						{renderSearchButton()}
					</ProfiBazaTooltip>
				</Col>
			</Row>

			<MultiDictionaryContext.Provider value={dictionaryState!}>
				{values && <SortableDictionaryComponent />}
			</MultiDictionaryContext.Provider>
		</>
	);
};
export default MultiLevelDictionaryBody;
