/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/formVersionMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a FormVersion. */
export class FormVersion {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a FormVersion.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.FormVersionGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.FormVersionGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.FormVersionDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.FormVersionDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.FormVersionDto>, callback?: msRest.ServiceCallback<Models.FormVersionDto>): Promise<Models.FormVersionGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.FormVersionGetByIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteMethod(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.FormVersionCreateResponse>
   */
  create(options?: Models.FormVersionCreateOptionalParams): Promise<Models.FormVersionCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.Int32IIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.FormVersionCreateOptionalParams, callback: msRest.ServiceCallback<Models.Int32IIdDto>): void;
  create(options?: Models.FormVersionCreateOptionalParams | msRest.ServiceCallback<Models.Int32IIdDto>, callback?: msRest.ServiceCallback<Models.Int32IIdDto>): Promise<Models.FormVersionCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.FormVersionCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.FormVersionUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.FormVersionUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.FormVersionUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  activate(options?: Models.FormVersionActivateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  activate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  activate(options: Models.FormVersionActivateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  activate(options?: Models.FormVersionActivateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      activateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deactivate(options?: Models.FormVersionDeactivateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deactivate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deactivate(options: Models.FormVersionDeactivateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deactivate(options?: Models.FormVersionDeactivateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deactivateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.FormVersionCloneResponse>
   */
  clone(options?: Models.FormVersionCloneOptionalParams): Promise<Models.FormVersionCloneResponse>;
  /**
   * @param callback The callback
   */
  clone(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  clone(options: Models.FormVersionCloneOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  clone(options?: Models.FormVersionCloneOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.FormVersionCloneResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      cloneOperationSpec,
      callback) as Promise<Models.FormVersionCloneResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "forms/FormVersion/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.FormVersionDto
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "forms/FormVersion/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "forms/FormVersion",
  formDataParameters: [
    Parameters.formId,
    Parameters.validFrom,
    Parameters.xSLTDefinition,
    Parameters.xLSXDefinition
  ],
  contentType: "multipart/form-data",
  responses: {
    201: {
      bodyMapper: Mappers.Int32IIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "forms/FormVersion",
  formDataParameters: [
    Parameters.id3,
    Parameters.validFrom,
    Parameters.xSLTDefinition,
    Parameters.xLSXDefinition
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const activateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "forms/FormVersion/activate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ActivateFormVersionCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deactivateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "forms/FormVersion/deactivate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeactivateFormVersionCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const cloneOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "forms/FormVersion/clone",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CloneFormVersionCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};
