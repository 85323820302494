import { SortHelper } from '@components/shared/paginatedProfiBazaTable/SortHelper';
import { List } from 'antd';
import formatHelpers from 'helper/formatHelpers';
import React from 'react';
import {
	ActivityListItemDto,
	InterventionPath,
	RizpDictionaryValueDto,
} from 'services/src/models';

export const getActivitiesColumnsByInterventionPath = (
	path: InterventionPath
) => {
	let cols = [
		{
			dataIndex: 'activityNumber',
			key: 'activityNumber',
			title: 'Id',
			sorter: (a: ActivityListItemDto, b: ActivityListItemDto) =>
				SortHelper.compareString(b.activityNumber, a.activityNumber),
		},
		{
			dataIndex: 'activityCategory',
			key: 'activityCategory',
			title: 'Kategoria',
			render: (value?: RizpDictionaryValueDto) => (
				<>{value && renderDictionaryItem(value, true)}</>
			),
			sorter: (a: ActivityListItemDto, b: ActivityListItemDto) =>
				SortHelper.compareString(
					b.activityCategory?.value,
					a.activityCategory?.value
				),
		},
		{
			dataIndex: 'dateFrom',
			key: 'dateFrom',
			title: 'Data realizacji od',
			render: (value?: Date) => formatHelpers.formatDate(value),
			sorter: (a: ActivityListItemDto, b: ActivityListItemDto) =>
				SortHelper.compareDates(b.dateFrom, a.dateFrom),
		},
		{
			dataIndex: 'dateTo',
			key: 'dateTo',
			title: 'Data realizacji do',
			render: (value?: Date) => formatHelpers.formatDate(value),
			sorter: (a: ActivityListItemDto, b: ActivityListItemDto) =>
				SortHelper.compareDates(b.dateTo, a.dateTo),
		},
		{
			dataIndex: 'implementingCompanies',
			key: 'implementingCompanies',
			title: 'Liczba realizatorów',
			sorter: (a?: ActivityListItemDto, b?: ActivityListItemDto) =>
				SortHelper.compareNumbers(
					b?.implementingCompanies,
					a?.implementingCompanies
				),
		},
		{
			dataIndex: 'nzpTaskNumberDictionaryValues',
			key: 'nzpTaskNumberDictionaryValues',
			title: 'Numer NPZ',
			render: (values?: RizpDictionaryValueDto[]) => (
				<>{values && renderDictionaryList(values, false)}</>
			),
			sorter: (a: ActivityListItemDto, b: ActivityListItemDto) =>
				SortHelper.compareString(
					b.nzpTaskNumberDictionaryValues?.map((x) => x.value).join(),
					a.nzpTaskNumberDictionaryValues?.map((x) => x.value).join()
				),
		},
	];

	switch (path) {
		case InterventionPath.PROHEALTHACTIVITIES:
		case InterventionPath.RESEARCH:
		case InterventionPath.STAFFEDUCATION:
			cols.splice(4, 0, {
				dataIndex: 'numberOfRecipients',
				key: 'numberOfRecipients',
				title: 'Liczba odbiorców',
				render: (value?: any) => value,
				sorter: (a: ActivityListItemDto, b: ActivityListItemDto) =>
					SortHelper.compareNumbers(
						b.numberOfRecipients,
						a.numberOfRecipients
					),
			});
			break;
	}

	return cols;
};

export const renderDictionaryList = (
	values: RizpDictionaryValueDto[],
	showBreadcrumbs: boolean
) => (
	<List
		size="small"
		locale={{ emptyText: <></> }}
		dataSource={values}
		renderItem={(item) => (
			<List.Item key={item.id}>
				{item.otherValue
					? `${showBreadcrumbs ? item.breadcrumb : ''}${
							item.value
					  }: ${item.otherValue}`
					: `${showBreadcrumbs ? item.breadcrumb : ''}${item.value}`}
			</List.Item>
		)}
	/>
);

export const renderDictionaryItem = (
	value: RizpDictionaryValueDto,
	showBreadcrumbs: boolean
) => {
	return value.otherValue
		? `${showBreadcrumbs ? value.breadcrumb : ''}${value.value}: ${
				value.otherValue
		  }`
		: `${showBreadcrumbs ? value.breadcrumb : ''}${value.value}`;
};
