import { useOrbeonAccessibilityHook } from '@hooks/accessibility/useOrbeonAccessibilityHook';
import { useRootData } from '@hooks/hook';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Spin } from 'antd';
// @ts-ignore
import IframeResizer, { IFrameObject } from 'iframe-resizer-react';
import { observer } from 'mobx-react-lite';
import { User } from 'oidc-client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getUser } from 'services/ProfiBazaApi';

interface IProps {
	applicationName: string;
	formName: string;
	documentId?: string;
	mode: 'preview' | 'edit';
	query?: any;
	headers?: any;
}

const loadScript = (
	index: number,
	scripts: NodeListOf<HTMLScriptElement>,
	loadedSrc: string[]
) => {
	if (index >= scripts.length) {
		return;
	}

	while (loadedSrc.indexOf(scripts[index].src) >= 0) {
		index++;
	}
	var scriptDomElement = document.createElement('script');
	scriptDomElement.type = 'text/javascript';
	scriptDomElement.className = 'orbeon';
	if (scripts[index].src) {
		scriptDomElement.onload = () =>
			loadScript(index + 1, scripts, loadedSrc);
		scriptDomElement.src = scripts[index].src;
	} else {
		scriptDomElement.textContent = scripts[index].innerText;
	}
	window.document.head.appendChild(scriptDomElement);
	if (!scripts[index].src) {
		loadScript(index + 1, scripts, loadedSrc);
	}
};

const EmbeddedForm: React.FC<IProps> = (props) => {
	const [orbeonUrl, setOrbeonUrl] = useState<string | undefined>(undefined);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const iframeRef = useRef<IFrameObject>(null);

	const handleTokenRefresh = (accessToken: string | undefined) => {
		iframeRef.current?.sendMessage({
			token: accessToken,
		});
	};

	const [isLodaded, setIsLoaded] = useState<boolean>(false);

	useOrbeonAccessibilityHook(isLodaded);

	useEffect(() => {
		getUser().then((user: User | null) => {
			let url = `/orbeon/fr/${props.applicationName}/${props.formName}`;
			if (props.mode === 'edit') {
				if (props.documentId === undefined) {
					url += '/new';
				} else {
					url += `/edit/${props.documentId}`;
				}
			} else {
				url += `/view/${props.documentId}`;
			}

			const queryExtended = {
				...props.query,
				'orbeon-embeddable': true,
				'Orbeon-Username': user?.profile.sub,
				access_token: `Bearer ${user?.access_token}`,
			};

			const queryString = Object.keys(queryExtended)
				.map((key) => key + '=' + queryExtended[key])
				.join('&');

			url += '?' + queryString;

			setOrbeonUrl(url);
			setIsLoaded(false);
		});

		accountDetailsStore.oidcUser.observe((oidcUser) => {
			if (
				oidcUser.oldValue?.access_token !==
				oidcUser.newValue?.access_token
			) {
				handleTokenRefresh(oidcUser.newValue?.access_token);
			}
		});

		return () => {};
	}, [props.applicationName, props.formName, props.documentId]);

	const handleMessage = useCallback(
		(event: { iframe: IframeResizer.IFrameComponent; message: any }) => {
			const { message } = event;
			if (typeof message === 'object' && message.height) {
				const iframeElement = document.querySelector(
					'iframe[title="iframe"]'
				) as HTMLIFrameElement | null;
				if (iframeElement) {
					iframeElement.style.setProperty(
						'height',
						`${message.height}px`
					);

					const additionalStyles = `
                    .yui-panel-container.yui-dialog {						
						left: 50% !important; 
						transform: translateX(-50%) !important; 
						bottom: 50 !important; 
						top: auto !important;
                    }
                `;
					const styleElement = document.createElement('style');
					styleElement.textContent = additionalStyles;
					iframeElement.contentDocument?.head.appendChild(
						styleElement
					);
				}
			}
		},
		[]
	);

	return (
		<Spin tip="Trwa ładowanie..." spinning={!isLodaded}>
			{orbeonUrl && (
				<IframeResizer
					forwardRef={iframeRef}
					src={orbeonUrl}
					style={{
						width: '1px',
						minWidth: '100%',
						borderWidth: 0,
					}}
					onLoad={() => {
						setIsLoaded(true);
					}}
					onInit={(iframe) => {
						window.dispatchEvent(new Event('resize'));
					}}
					onMessage={handleMessage}
				/>
			)}
		</Spin>
	);
};

EmbeddedForm.defaultProps = {
	query: {},
	headers: {},
};

export default observer(EmbeddedForm);
