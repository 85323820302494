import React from 'react';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { StatementTestListItemDto } from 'services/src/models';
import * as StatementArea from 'services/mappers/StatementArea';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { DictionaryName } from 'services/mappers/DictionaryName';
import formatHelpers from 'helper/formatHelpers';

const columns: ProfiBazaColumnProps<StatementTestListItemDto>[] = [
	{
		sorter: true,
		dataIndex: 'statementArea',
		key: 'statementArea',
		title: 'Rodzaj',
		filter: 'dictionary',
		filterDictionary: DictionaryName.StatementArea,
		render: (value: ProfiBazaAPIModels.StatementArea) =>
			StatementArea.map(value),
	},
	{
		sorter: true,
		dataIndex: 'formVersion',
		key: 'Numer wersji formularza',
		title: 'Numer wersji sprawozdania',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Właściciel',
		filter: 'contains',
	},
	{
		sorter: true,
		dataIndex: 'year',
		key: 'year',
		title: 'Rok',
		filter: 'equals',
	},
	{
		sorter: true,
		dataIndex: 'createdAt',
		key: 'createdAt',
		title: 'Data utworzenia',
		filter: 'dateRange',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
];

export default columns;
