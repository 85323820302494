/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/aOTMiTApiMappers";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a AOTMiTApi. */
export class AOTMiTApi {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a AOTMiTApi.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @summary (external) API Key
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  projectRegisteredAOTMIT(options?: Models.AOTMiTApiProjectRegisteredAOTMITOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  projectRegisteredAOTMIT(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  projectRegisteredAOTMIT(options: Models.AOTMiTApiProjectRegisteredAOTMITOptionalParams, callback: msRest.ServiceCallback<void>): void;
  projectRegisteredAOTMIT(options?: Models.AOTMiTApiProjectRegisteredAOTMITOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      projectRegisteredAOTMITOperationSpec,
      callback);
  }

  /**
   * @summary (external) API Key
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setAOTMiTStatus(options?: Models.AOTMiTApiSetAOTMiTStatusOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setAOTMiTStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setAOTMiTStatus(options: Models.AOTMiTApiSetAOTMiTStatusOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setAOTMiTStatus(options?: Models.AOTMiTApiSetAOTMiTStatusOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      setAOTMiTStatusOperationSpec,
      callback);
  }

  /**
   * @summary (external) API Key
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  opinionAOTMiT(options?: Models.AOTMiTApiOpinionAOTMiTOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  opinionAOTMiT(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  opinionAOTMiT(options: Models.AOTMiTApiOpinionAOTMiTOptionalParams, callback: msRest.ServiceCallback<void>): void;
  opinionAOTMiT(options?: Models.AOTMiTApiOpinionAOTMiTOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      opinionAOTMiTOperationSpec,
      callback);
  }

  /**
   * @summary (external) API Key
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadRecommendations(options?: Models.AOTMiTApiUploadRecommendationsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  uploadRecommendations(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadRecommendations(options: Models.AOTMiTApiUploadRecommendationsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadRecommendations(options?: Models.AOTMiTApiUploadRecommendationsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      uploadRecommendationsOperationSpec,
      callback);
  }

  /**
   * @summary (external) API Key
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadDetailedReportAnnex(options?: Models.AOTMiTApiUploadDetailedReportAnnexOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  uploadDetailedReportAnnex(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadDetailedReportAnnex(options: Models.AOTMiTApiUploadDetailedReportAnnexOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadDetailedReportAnnex(options?: Models.AOTMiTApiUploadDetailedReportAnnexOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      uploadDetailedReportAnnexOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const projectRegisteredAOTMITOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "external/aotmit/project/Registered",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ProjectRegisteredCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setAOTMiTStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "external/aotmit/project/SetStatus",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SetStatusCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const opinionAOTMiTOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "external/aotmit/project/Opinion",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.OpinionAOTMiTCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadRecommendationsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "external/aotmit/Recommendations",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.AddRecommendationsCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadDetailedReportAnnexOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "external/aotmit/DetailedReportAnnex",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.AddDetailedReportAnnexCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
