import { PlusOutlined } from '@ant-design/icons';
import Authorized from '@authorization/authorized';
import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import { Button, Col, Modal, Select } from 'antd';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { tagTextAccordingToStatus } from 'helper/jsxEnhancers';
import { CenteredRow } from 'layout/CenteredRow';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React from 'react';
import * as RoleCategoryMapper from 'services/mappers/RoleCategory';
import * as RoleTypeMapper from 'services/mappers/RoleType';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { FFieldLabel, FInput, FSelect } from '../../forms/FormikFormItems';
import { PageHeaderWithContent } from '../../layout/PageHeaderWithContent';
import ProfiBazaTable from '../../layout/ProfiBazaTable';
import { AntPagination } from '../shared/paginatedProfiBazaTable/SieveModels';
import { RoleValidationSchema } from './RoleValidation';

const { Option } = Select;
interface IState {
	results?: ProfiBazaAPIModels.RoleDto[] | undefined;
	pagination?: AntPagination | undefined;
	loaded?: boolean;
	filter?: string | undefined;
	sorts?: string | undefined;

	showCreateModal?: boolean | false;
}

export default class Roles extends React.Component<{}, IState> {
	constructor(props: any) {
		super(props);
		this.state = { pagination: new AntPagination() };
	}

	private columns: ColumnProps<ProfiBazaAPIModels.RoleItemDto>[] = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Nazwa',
		},
		{
			dataIndex: 'type',
			key: 'type',
			title: 'Typ',
			render: (type: ProfiBazaAPIModels.RoleType) =>
				RoleTypeMapper.map(type),
		},
		{
			dataIndex: 'category',
			key: 'category',
			title: 'Zasięg',
			render: (category: ProfiBazaAPIModels.RoleCategory) =>
				RoleCategoryMapper.map(category),
		},
		{
			sorter: false,
			dataIndex: 'isActive',
			key: 'isActive',
			title: 'Czy aktywna',
			render: (text: any, record: ProfiBazaAPIModels.RoleItemDto) => (
				<>
					{tagTextAccordingToStatus(
						record.isActive! ? 'Tak' : 'Nie',
						record.isActive
					)}
				</>
			),
		},
		RenderActions<ProfiBazaAPIModels.RoleItemDto>((text, record) => (
			<>
				<Authorized
					permissions={[
						ProfiBazaAPIModels.Permission.AdminRolesModifications,
					]}
				>
					<ProfiBazaTooltip placement="top" title="Edytuj">
						<TableActionButton
							label="Edytuj"
							kind="EDIT"
							linkTo={'/admin/roles/edit/' + record.id}
						/>
					</ProfiBazaTooltip>
				</Authorized>
				<Authorized
					permissions={[
						ProfiBazaAPIModels.Permission.AdminRolesModifications,
					]}
				>
					<ProfiBazaTooltip placement="top" title="Usuń">
						<AccessiblePopconfirm
							title={
								<>Czy na pewno chcesz usunąć {record.name}?</>
							}
							onConfirm={() => {
								ajaxByUser('Usunięto rolę', () =>
									getProfiBazaApiClient()
										.then((api) =>
											api.role.deleteMethod(record.id!)
										)
										.then(() => {
											this.refresh();
										})
								);
							}}
						>
							<TableActionButton label="Usuń" kind="DELETE" />
						</AccessiblePopconfirm>
					</ProfiBazaTooltip>
				</Authorized>
				<Authorized
					permissions={[
						ProfiBazaAPIModels.Permission.AdminRolesModifications,
					]}
				>
					{record.isActive! ? (
						<ProfiBazaTooltip placement="top" title="Dezaktywuj">
							<AccessiblePopconfirm
								title={
									<>
										Czy na pewno chcesz dezaktywować{' '}
										{record.name}?
									</>
								}
								onConfirm={() => {
									ajaxByUser('Dezaktywowano rolę', () =>
										getProfiBazaApiClient()
											.then((api) =>
												api.role.deactivate({
													body: {
														id: record.id!,
													},
												})
											)
											.then(() => {
												this.refresh();
											})
									);
								}}
							>
								<TableActionButton
									label="Dezaktywuj"
									kind="DEACTIVATE"
								/>
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					) : (
						<ProfiBazaTooltip placement="top" title="Aktywuj">
							<AccessiblePopconfirm
								title={
									<>
										Czy na pewno chcesz aktywować{' '}
										{record.name}?
									</>
								}
								onConfirm={() => {
									ajaxByUser('Aktywowano rolę', () =>
										getProfiBazaApiClient()
											.then((api) =>
												api.role.activate({
													body: {
														id: record.id!,
													},
												})
											)
											.then(() => {
												this.refresh();
											})
									);
								}}
							>
								<TableActionButton
									label="Aktywuj"
									kind="ACTIVATE"
								/>
							</AccessiblePopconfirm>
						</ProfiBazaTooltip>
					)}
				</Authorized>
			</>
		)),
	];

	public componentDidMount() {
		this.refresh();
	}

	private refresh() {
		let { pagination, filter, sorts } = this.state;
		this.getResults(
			{ current: pagination!.current, pageSize: pagination!.pageSize },
			filter,
			sorts
		);
	}

	public handleOnTableChange = (
		pagination: any,
		filters: any,
		sorter: any
	) => {
		let sort = '';
		if (
			!(Object.keys(sorter).length === 0 && sorter.constructor === Object)
		) {
			sort = (sorter.order == 'ascend' ? '' : '-') + sorter.column.key;
		}

		this.getResults(
			{ currentPage: pagination.current, pageSize: pagination.pageSize },
			this.state.filter,
			sort
		);
	};

	private getResults(pagination: any, filters?: string, sorts?: string) {
		this.setState({
			...this.state,
			loaded: false,
			pagination: pagination,
			filter: filters,
			sorts,
		});
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.role.all())
				.then((data) => {
					this.setState((state) => ({
						...state,
						results: data,
						loaded: true,
					}));
				})
				.catch((e) => {
					this.setState({ ...this.state, loaded: true });
					throw e;
				})
		);
	}

	public render() {
		return (
			<>
				<Formik
					validateOnChange={true}
					validateOnBlur={false}
					initialValues={{}}
					validationSchema={RoleValidationSchema}
					onSubmit={(
						values: ProfiBazaAPIModels.CreateRoleCommand,
						actions: FormikActions<
							ProfiBazaAPIModels.CreateRoleCommand
						>
					) => {
						ajaxByUser(
							'Utworzono rolę',
							() =>
								getProfiBazaApiClient().then((api) =>
									api.role.create({
										body: values,
									})
								),
							() => {
								this.refresh();
								this.setState({ showCreateModal: false });
								actions.resetForm();
							},
							(errors) => {
								actions.setErrors(errors);
							}
						).then(() => actions.setSubmitting(false));
					}}
					render={(
						props: FormikProps<ProfiBazaAPIModels.CreateRoleCommand>
					) => (
						<Modal
							visible={this.state.showCreateModal}
							maskClosable={false}
							centered
							title="Tworzenie roli"
							onOk={() => {
								if (!props.isSubmitting) props.submitForm();
							}}
							onCancel={() => {
								this.setState({ showCreateModal: false });
								props.resetForm();
							}}
							okButtonProps={{ disabled: props.isSubmitting }}
							cancelButtonProps={{ disabled: props.isSubmitting }}
							okText="Dodaj"
						>
							<FFieldLabel
								label="Nazwa roli"
								for="name"
							></FFieldLabel>
							<Field
								autoFocus
								component={FInput}
								placeholder="Wpisz unikalną nazwę roli"
								name="name"
							/>

							<FFieldLabel label="Typ" for="type"></FFieldLabel>
							<Field component={FSelect} name="type">
								{Array.from(
									RoleTypeMapper.mappings.entries()
								).map((x, i) => (
									<Option key={i} value={x[0]}>
										{x[1]}
									</Option>
								))}
							</Field>

							<FFieldLabel
								label="Zasięg"
								for="category"
							></FFieldLabel>
							<Field component={FSelect} name="category">
								{Array.from(
									RoleCategoryMapper.mappings.entries()
								).map((x, i) => (
									<Option key={i} value={x[0]}>
										{x[1]}
									</Option>
								))}
							</Field>
						</Modal>
					)}
				/>
				<CenteredRow>
					<Col span={23}>
						<PageHeaderWithContent
							left={
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.AdminRolesModifications,
									]}
								>
									<Button
										aria-label="Dodaj"
										type="primary"
										icon={<PlusOutlined />}
										onClick={() => {
											this.setState({
												showCreateModal: true,
											});
										}}
									>
										Dodaj
									</Button>
								</Authorized>
							}
							title="ROLE"
						/>
						<SkipLinkContent />
						<ProfiBazaTable
							detailsUrl={'/admin/roles/edit'}
							columns={this.columns}
							loading={!this.state.loaded}
							pagination={
								this.state.pagination as TablePaginationConfig
							}
							rowKey={(r: ProfiBazaAPIModels.RoleItemDto) =>
								r.id!
							}
							onChange={this.handleOnTableChange}
							dataSource={this.state.results}
						/>
					</Col>
				</CenteredRow>
			</>
		);
	}
}
