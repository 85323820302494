import { ProfiBazaColumnProps } from '@components/shared/paginatedProfiBazaTable/GridHelper';
import {
	UserManualDto,
	UserManualStatetmentReportType,
} from '@services/src/models';
import * as UserManualStatetmentReportTypeMapper from '@services/mappers/UserManualStatetmentReportType';
import formatHelpers from 'helper/formatHelpers';

export const StatementColumns: ProfiBazaColumnProps<UserManualDto>[] = [
	{
		sorter: false,
		dataIndex: 'reportType',
		key: 'reportType',
		title: 'Typ sprawozdania',
		render: (
			userManualStatetmentReportType: UserManualStatetmentReportType
		) =>
			UserManualStatetmentReportTypeMapper.map(
				userManualStatetmentReportType
			),
	},
	{
		sorter: false,
		dataIndex: 'formValidFrom',
		key: 'formValidFrom',
		title: 'Data obowiązywania',
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
	{
		sorter: false,
		dataIndex: 'formVersion',
		key: 'formVersion',
		title: 'Wersja sprawozdania',
	},
];
