import * as Yup from 'yup';

const requiredMessage: string = 'Wartość jest wymagana';

export const addStatementTestValidationSchema = Yup.object().shape({
	year: Yup.number().required(requiredMessage),
	statementArea: Yup.string().required(requiredMessage),
	subjectName: Yup.string().required(requiredMessage),
	formVersionId: Yup.string().required(requiredMessage),
});
