import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const columns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.DocumentListItemDto
>[] = [
	{
		dataIndex: 'fileName',
		key: 'fileName',
		title: 'Nazwa',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'year',
		key: 'year',
		title: 'Rok opracowania Rekomendacji',
		filter: 'equals',
		sorter: true,
	},

	{
		dataIndex: 'decisionProblem',
		key: 'decisionProblem',
		title: 'Problem decyzyjny',
		filter: 'contains',
		sorter: true,
	},

	{
		dataIndex: 'number',
		key: 'number',
		title: 'Numer Rekomendacji',
		filter: 'contains',
		sorter: true,
	},
];

export default columns;
