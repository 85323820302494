/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/roleMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Role. */
export class Role {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Role.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.RoleAllResponse>
   */
  all(options?: msRest.RequestOptionsBase): Promise<Models.RoleAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.RoleItemDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.RoleItemDto[]>): void;
  all(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.RoleItemDto[]>, callback?: msRest.ServiceCallback<Models.RoleItemDto[]>): Promise<Models.RoleAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.RoleAllResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.RoleCreateResponse>
   */
  create(options?: Models.RoleCreateOptionalParams): Promise<Models.RoleCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.RoleCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.RoleCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.RoleCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.RoleCreateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.RoleUpdateResponse>
   */
  update(options?: Models.RoleUpdateOptionalParams): Promise<Models.RoleUpdateResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.RoleUpdateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  update(options?: Models.RoleUpdateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.RoleUpdateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback) as Promise<Models.RoleUpdateResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.RoleGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.RoleGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.RoleDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.RoleDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.RoleDto>, callback?: msRest.ServiceCallback<Models.RoleDto>): Promise<Models.RoleGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.RoleGetByIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.RoleDeleteMethodResponse>
   */
  deleteMethod(id: string, options?: msRest.RequestOptionsBase): Promise<Models.RoleDeleteMethodResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteMethod(id: string, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  deleteMethod(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.RoleDeleteMethodResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteMethodOperationSpec,
      callback) as Promise<Models.RoleDeleteMethodResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  activate(options?: Models.RoleActivateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  activate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  activate(options: Models.RoleActivateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  activate(options?: Models.RoleActivateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      activateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deactivate(options?: Models.RoleDeactivateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deactivate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deactivate(options: Models.RoleDeactivateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deactivate(options?: Models.RoleDeactivateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deactivateOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "authorization/Role",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleItemDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "authorization/Role",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateRoleCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "authorization/Role",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateRoleCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "authorization/Role/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.RoleDto
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "authorization/Role/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const activateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "authorization/Role/activate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ActivateRoleCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deactivateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "authorization/Role/deactivate",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeactivateRoleCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
