import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Col } from 'antd';
import AddButton from 'components/shared/buttons/AddButton';
import TableActionButton from 'components/shared/buttons/TableActionButton';
import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import PaginatedProfiBazaTable from 'components/shared/paginatedProfiBazaTable/PaginatedProfiBazaTable';
import { SieveModel } from 'components/shared/paginatedProfiBazaTable/SieveModels';
import AccessiblePopconfirm from 'components/statements/table/AccessiblePopconfirm';
import RenderActions from 'components/statements/table/RenderActions';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProposalStatus } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

import AddProposalFileModal from '../modal/AddProposalFileModal';
import RejectProposalModal from '../modal/RejectProposalModal';
import ProposalCreate from '../ProposalCreate';
import ProposalDetails from '../ProposalDetails';

interface IProps {
	isAdmin: boolean;
}

interface IState {
	proposals: ProfiBazaAPIModels.ProposalItemDto[];
	visibleCreate: boolean;
	visibleDetails: boolean;
	visibleReject: boolean;
	visibleFile: boolean;
	visibleConfirm: boolean;
	selectedRecord: ProfiBazaAPIModels.ProposalDetailsDto | undefined;
	loadedTable: boolean;
}

const ProposalTable: React.FC<IProps> = (props) => {
	const tableColumnStore: ITableColumnStore = useRootData(
		(store) => store.tableColumnStore
	);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const [state, setState] = useState<IState>({
		proposals: [],
		visibleCreate: false,
		visibleDetails: false,
		visibleReject: false,
		visibleConfirm: false,
		selectedRecord: undefined,
		visibleFile: false,
		loadedTable: false,
	});

	const showDetails = (record: ProfiBazaAPIModels.ProposalItemDto): void => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.proposal.getProposal(record.id!))
				.then((proposal: ProfiBazaAPIModels.ProposalDetailsDto) => {
					setState({
						...state,
						selectedRecord: proposal,
						visibleDetails: true,
					});
				})
		);
	};

	const columns: ProfiBazaColumnProps<
		ProfiBazaAPIModels.ProposalItemDto
	>[] = [
		RenderActions<ProfiBazaAPIModels.ProposalItemDto>(
			(text, record) => (
				<>
					<ProfiBazaTooltip placement="top" title="Podgląd">
						<TableActionButton
							label="Podgląd"
							kind="DETAILS"
							onClick={() => showDetails(record)}
						/>
					</ProfiBazaTooltip>
					{props.isAdmin && record.status == ProposalStatus.Sended && (
						<>
							<ProfiBazaTooltip placement="top" title="Akceptuj">
								<AccessiblePopconfirm
									title={
										<>
											Czy na pewno chcesz zaakceptować
											wniosek?
										</>
									}
									onConfirm={() => {
										ajaxByUser(
											'Wniosek zaakceptowany',
											() =>
												getProfiBazaApiClient()
													.then((api) =>
														api.proposal.considerProposal(
															{
																body: {
																	id:
																		record.id,
																	status:
																		ProposalStatus.Accepted,
																},
															}
														)
													)
													.then(() => {
														setState({
															...state,
															selectedRecord: undefined,
															visibleReject: false,
														});
														gridStore.searching.set(
															true
														);
													})
										);
									}}
								>
									<TableActionButton
										label="Akceptuj"
										kind="ACCEPT"
									/>
								</AccessiblePopconfirm>
							</ProfiBazaTooltip>
							<ProfiBazaTooltip placement="top" title="Odrzuć">
								<TableActionButton
									kind="REJECT"
									label="Odrzuć"
									onClick={() => {
										setState({
											...state,
											selectedRecord: record,
											visibleReject: true,
										});
									}}
								/>
							</ProfiBazaTooltip>
						</>
					)}
					{!record.isAttachment &&
						!props.isAdmin &&
						record.status == ProposalStatus.Sended && (
							<ProfiBazaTooltip
								placement="top"
								title="Dodaj załącznik"
							>
								<TableActionButton
									kind="VIEW"
									label="Dodaj załącznik"
									onClick={() =>
										setState({
											...state,
											selectedRecord: record,
											visibleFile: true,
										})
									}
								/>
							</ProfiBazaTooltip>
						)}
				</>
			),
			{ width: 40 }
		),
		...tableColumnStore.getTable(
			props.isAdmin ? 'proposalAdmin' : 'proposal'
		),
	];

	return (
		<>
			<ProposalCreate
				visible={state.visibleCreate}
				setVisible={(visible) =>
					setState({ ...state, visibleCreate: visible })
				}
			/>
			<ProposalDetails
				isAdmin={props.isAdmin}
				visible={state.visibleDetails}
				setVisible={(visible) =>
					setState({ ...state, visibleDetails: visible })
				}
				data={state.selectedRecord}
			/>
			<CenteredRow>
				<Col span={23}>
					<PageHeaderWithContent
						title="Wnioski o dostęp do ProfiBazy"
						left={
							!props.isAdmin && (
								<AddButton
									label="Utwórz"
									tall
									onClick={() =>
										setState({
											...state,
											visibleCreate: true,
										})
									}
								>
									Utwórz
								</AddButton>
							)
						}
					/>
					<SkipLinkContent />
					{!props.isAdmin && (
						<Col span={12} offset={6}>
							<h4 style={{ textAlign: 'center' }}>
								W celu uzyskania dostępu do zasobów systemu
								ProfiBazy należy złożyć wniosek wybierając przy
								tym między innymi rodzaj dostępu (zwykły,
								administracyjny) oraz podmiot w ramach, którego
								wniosek jest składany. Po zaaprobowaniu wniosku
								przez administratora otrzymasz dostęp do systemu
								zgodnie z przydzielonymi uprawnieniami.
							</h4>
						</Col>
					)}

					{accountDetailsStore.account.get()?.gridSettings && (
						<PaginatedProfiBazaTable<
							ProfiBazaAPIModels.ProposalItemDto
						>
							gridName={
								props.isAdmin ? 'proposalAdmin' : 'proposal'
							}
							hidePersonalizationSettings={
								!accountDetailsStore.account.get()
									?.existsInProfiBaza
							}
							getRowKey={(
								r: ProfiBazaAPIModels.ProposalItemDto
							) => r.id!}
							columns={columns}
							getPagedResult={(
								sieve: SieveModel,
								filters,
								abortSignal
							) => {
								const parameters: ProfiBazaAPIModels.ProposalGetProposalsOptionalParams = {
									filters: sieve.filters,
									sorts: sieve.sorts,
									page: sieve.page,
									pageSize: sieve.pageSize,
									abortSignal,
								};
								return getProfiBazaApiClient().then((api) => {
									return props.isAdmin
										? api.proposal
												.getProposalsAdmin(parameters)
												.then((result) => result)
										: api.proposal
												.getProposals(parameters)
												.then((result) => result);
								});
							}}
							isLoaded={() =>
								setState({
									...state,
									loadedTable: true,
									visibleConfirm: false,
									visibleCreate: false,
									visibleDetails: false,
									visibleFile: false,
									visibleReject: false,
									selectedRecord: undefined,
								})
							}
							onRow={(
								record: ProfiBazaAPIModels.ProposalItemDto
							) => {
								return {
									onDoubleClick: () => {
										showDetails(record);
									},
								};
							}}
						/>
					)}
				</Col>
			</CenteredRow>
			<RejectProposalModal
				visible={state.visibleReject}
				selectedRecord={state.selectedRecord}
				onReject={(refresh) => {
					setState({
						...state,
						visibleReject: false,
						selectedRecord: undefined,
					});
					refresh && gridStore.searching.set(true);
				}}
			/>
			<AddProposalFileModal
				visible={state.visibleFile}
				selectedRecord={state.selectedRecord}
				onCreate={(refresh) => {
					setState({
						...state,
						visibleFile: false,
						selectedRecord: undefined,
					});
					refresh && gridStore.searching.set(true);
				}}
			/>
		</>
	);
};

export default ProposalTable;
