// @ts-ignore
import highlightjs from 'highlight.js';
// @ts-ignore
import marked from 'marked';
// @ts-ignore
import showdown from 'showdown';

const converter = new showdown.Converter();

const renderer = new marked.Renderer();

renderer.heading = function (text: string, level: string) {
	return `<h${level}>${text}</h${level}>`;
};

marked.setOptions({
	gfm: true,
	breaks: true,
	renderer,
	sanitize: true,
	highlight: (code: string) => highlightjs.highlightAuto(code).value,
});

export const markdown = (str: string) => converter.makeHtml(marked(str));

export const getCommentFieldName = <
	T extends Record<string, Record<string, string>>
>(
	fieldName: string,
	fields: T
): string => {
	let defaultValue = 'Nie znaleziono pola';

	Object.values(fields).forEach((group) => {
		Object.entries(group).forEach((item) => {
			if (item[0] == fieldName) {
				defaultValue = item[1];
			}
		});
	});

	return defaultValue;
};
