/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/iKPMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a IKP. */
export class IKP {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a IKP.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @summary (external) API Key
   * @param [options] The optional parameters
   * @returns Promise<Models.IKPProjectRegisteredAOTMITResponse>
   */
  projectRegisteredAOTMIT(options?: Models.IKPProjectRegisteredAOTMITOptionalParams): Promise<Models.IKPProjectRegisteredAOTMITResponse>;
  /**
   * @param callback The callback
   */
  projectRegisteredAOTMIT(callback: msRest.ServiceCallback<Models.PublicHealthInterventionIKPDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  projectRegisteredAOTMIT(options: Models.IKPProjectRegisteredAOTMITOptionalParams, callback: msRest.ServiceCallback<Models.PublicHealthInterventionIKPDtoPagedResult>): void;
  projectRegisteredAOTMIT(options?: Models.IKPProjectRegisteredAOTMITOptionalParams | msRest.ServiceCallback<Models.PublicHealthInterventionIKPDtoPagedResult>, callback?: msRest.ServiceCallback<Models.PublicHealthInterventionIKPDtoPagedResult>): Promise<Models.IKPProjectRegisteredAOTMITResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      projectRegisteredAOTMITOperationSpec,
      callback) as Promise<Models.IKPProjectRegisteredAOTMITResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const projectRegisteredAOTMITOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "external/ikp/interventions",
  queryParameters: [
    Parameters.minAge,
    Parameters.maxAge,
    Parameters.ageUnit,
    Parameters.gender,
    Parameters.areaTercCodes,
    Parameters.iCD10Codes,
    Parameters.page1,
    Parameters.pageSize1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PublicHealthInterventionIKPDtoPagedResult
    },
    default: {}
  },
  serializer
};
