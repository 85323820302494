import { useRootData } from 'hooks/hook';
import React from 'react';
import { IGridStore } from 'stores/GridStore';
import TableActions, { ITableActions } from './TableActions';

interface IProps<T> {
	actions?: Array<ITableActions<T>>;
	children?: React.ReactNode;
	getRowKey: (record: T) => string | number;
	results: T[] | undefined;
}

const ProfiBazaTableActions = <T extends any>(props: IProps<T>) => {
	const gridStore: IGridStore = useRootData((store) => store.gridStore);
	const { actions, results, getRowKey } = props;

	return (
		<TableActions<T>
			allRowsSelected={gridStore.allRowsSelected.get()}
			excludedKeys={gridStore.excludedKeys}
			selectedKeys={gridStore.selectedKeys}
			selectedValues={gridStore.selectedValues as T[]}
			actions={actions}
			results={results}
			getRowKey={getRowKey}
		>
			{props.children}
		</TableActions>
	);
};

export default ProfiBazaTableActions;
