import TableActionButton from '@components/shared/buttons/TableActionButton';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { Fragment, useRef } from 'react';

import ViewUserManualModal, {
	IViewUserManualModal,
} from '../modals/ViewUserManualModal';

const PreviewManualButton: React.FC<ProfiBazaAPIModels.UserManualDto> = (
	props
) => {
	const viewUserManualModalRef = useRef<
		IViewUserManualModal
	>() as React.RefObject<IViewUserManualModal>;

	return (
		<Fragment>
			<ProfiBazaTooltip placement="top" title="Pokaż instrukcję">
				<TableActionButton
					label="Pokaż instrukcję"
					kind="DETAILS"
					onClick={() =>
						viewUserManualModalRef.current?.showModal(props)
					}
				/>
			</ProfiBazaTooltip>
			<ViewUserManualModal
				ref={viewUserManualModalRef}
				isPreview={true}
			/>
		</Fragment>
	);
};

export default PreviewManualButton;
