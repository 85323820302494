import LabelHelpTooltip from '@components/shared/labels/LabelHelpTooltip';
import { Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { numberOfPeople } from 'components/intervention/model/InterventionCommand';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FFieldLabel,
	FInput,
	FInputNumber,
	FSelect,
	FTextArea,
} from 'forms/FormikFormItems';
import React from 'react';
import * as RIZPSubjectKindMappers from 'services/mappers/RIZPSubjectKind';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import * as Yup from 'yup';
interface IProps {
	isVisible: boolean;
	onCancel: () => void;
	onSubmit: (value: ProfiBazaAPIModels.ImplementingCompanyDto) => void;
}

const validationSchema = Yup.object().shape({
	subjectName: Yup.string()
		.nullable(false)
		.required('Nazwa jest wymagana')
		.max(256, 'Nazwa może mieć maksymalnie 256 znaków'),
	number: Yup.number()
		.nullable(false)
		.required('Liczba realizatorów jest wymagana')
		.min(1, 'Liczba realizatorów musi być większa od 0')
		.max(
			numberOfPeople,
			`Liczba realizatorów nie może być większa niż ${numberOfPeople}`
		),
	subjectKind: Yup.string()
		.nullable(false)
		.required('Kategoria jest wymagana'),
});

const ImplementingCompanyTable = (props: IProps) => {
	if (!props.isVisible) return <></>;
	return (
		<>
			<Formik
				validateOnChange={true}
				initialValues={{}}
				validationSchema={validationSchema}
				onSubmit={(
					value: ProfiBazaAPIModels.ImplementingCompanyDto,
					actions: FormikActions<
						ProfiBazaAPIModels.ImplementingCompanyDto
					>
				) => {
					value.number = parseInt(value.number as any);
					props.onSubmit(value);
					actions.resetForm();
				}}
				render={(
					formik: FormikProps<
						ProfiBazaAPIModels.ImplementingCompanyDto
					>
				) => (
					<>
						<Modal
							title=""
							visible={props.isVisible}
							destroyOnClose
							okText={'Ok'}
							onOk={formik.submitForm}
							cancelText="Wróć"
							onCancel={() => {
								props.onCancel();
								formik.resetForm();
							}}
						>
							<FFieldLabel label="Nazwa" />
							<LabelHelpTooltip
								field="ImplementingRecipientsName"
								defaultValue="Nazwa"
							/>
							<Field
								label="Nazwa"
								component={FTextArea}
								name="subjectName"
								autoFocus={true}
								rows={1}
							/>

							<FFieldLabel label="Liczba realizatorów" />
							<LabelHelpTooltip
								field="ImplementingRecipientsNumber"
								defaultValue="Liczba realizatorów"
							/>
							<Field
								label="Liczba realizatorów"
								component={FInputNumber}
								precision={0}
								name="number"
							/>

							<FFieldLabel label="Kategoria" />
							<Field
								label="Kategoria"
								component={FSelect}
								name="subjectKind"
								children={Array.from(
									RIZPSubjectKindMappers.mappings.entries()
								).map((x, i) => (
									<Select.Option key={i} value={x[0]}>
										{x[1]}
									</Select.Option>
								))}
							/>
						</Modal>
					</>
				)}
			></Formik>
		</>
	);
};

export default ImplementingCompanyTable;
