import React from 'react';

export const handleControlAccessibility = (
	querySelector: string,
	ariaLabel: string | undefined
) => {
	document.querySelectorAll(querySelector).forEach((control) => {
		if (!ariaLabel) {
			control.setAttribute('aria-hidden', 'true');
		} else {
			control.setAttribute('aria-label', ariaLabel);
		}
	});
};

const useControlAccessibilityHook = (
	dependency: any,
	querySelector: string,
	ariaLabel: string | undefined
) => {
	React.useLayoutEffect(() => {
		handleControlAccessibility(querySelector, ariaLabel);
	}, [dependency]);
};

export default useControlAccessibilityHook;
