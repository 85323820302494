import { MessageOutlined } from '@ant-design/icons';
import { useRootData } from '@hooks/hook';
import {
	SubjectCommentListItemDto,
	SubjectCommentType,
} from '@services/src/models';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useState } from 'react';

import { ISubjectCommentStore } from './SubjectCommentStore';

interface ICommentIconProps {
	fieldName: string;
}

const getIconClassByCommentType = (type: SubjectCommentType) => {
	if (type === SubjectCommentType.IMPORTANT) {
		return 'comment-icon important';
	}

	return 'comment-icon info';
};
export const SubjectCommentIcon = (props: ICommentIconProps) => {
	const subjectCommentStore: ISubjectCommentStore = useRootData(
		(store) => store.subjectCommentStore
	);

	const [state, setState] = useState<
		Record<string, SubjectCommentListItemDto>
	>(subjectCommentStore.getRecords());

	subjectCommentStore.commentRecords.observe(() => {
		setState(subjectCommentStore.getRecords());
	});

	if (state) {
		const comment: SubjectCommentListItemDto | undefined = state![
			props.fieldName
		];

		if (comment?.description) {
			return (
				<ProfiBazaTooltip
					aria-label="komentarz"
					placement="left"
					title={comment.description}
				>
					<MessageOutlined
						className={getIconClassByCommentType(
							comment.commentType!
						)}
						aria-label="komentarz"
						role="tooltip"
						aria-roledescription={comment?.description}
						tabIndex={0}
					/>
				</ProfiBazaTooltip>
			);
		}
	}
	return <></>;
};
