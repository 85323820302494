import TableActionButton from '@components/shared/buttons/TableActionButton';
import AccessiblePopconfirm from '@components/statements/table/AccessiblePopconfirm';
import { UserManualDto } from '@services/src/models';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { Fragment, useRef } from 'react';

import EditUserManualModal, {
	IEditUserManualModal,
} from '../modals/EditUserManualModal';

interface IProps {
	refreshCallback(): void;
	record: UserManualDto;
}

const EditManualButton: React.FC<IProps> = (props) => {
	const editUserManualModalRef = useRef<
		IEditUserManualModal
	>() as React.RefObject<IEditUserManualModal>;

	return (
		<Fragment>
			<ProfiBazaTooltip placement="top" title="Edytuj instrukcję">
				<AccessiblePopconfirm
					title="Czy na pewno chcesz nadpisać wcześniejszą wersje instrukcji ?"
					onConfirm={() =>
						editUserManualModalRef.current?.showModal()
					}
				>
					<TableActionButton label="Edytuj instrukcję" kind="EDIT" />
				</AccessiblePopconfirm>
			</ProfiBazaTooltip>
			<EditUserManualModal
				ref={editUserManualModalRef}
				userManual={props.record}
				onCreate={props.refreshCallback}
			/>
		</Fragment>
	);
};

export default EditManualButton;
