import { IDictionaryStore } from '@components/dictionary/dictionaryStore';
import { useRootData } from '@hooks/hook';
import { DictionariesConstants } from '@services/DictionariesConstants';
import { Modal } from 'antd';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import DictionaryPicker from 'components/dictionary/picker/DictionaryPicker';
import { Formik, FormikActions, FormikProps } from 'formik';
import { showNotExpectedErrorModal } from 'layout/Modals';
import NProgress from 'nprogress';
import React, { useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { DictionaryInterventionPath } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

interface IProps {
	statementId: string;
	options: RcCustomRequestOptions;
	setSourceDocumentId: (value: string | undefined) => void;
	refreshDocumentDetailsRef: () => void;
	onDestroy: () => void;
	codeName: string;
}

interface IState {
	isModalVisible: boolean;
	file?: ProfiBazaAPIModels.FileTreeItemDto;
}

const StatementDocumentsCategorySelect: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({ isModalVisible: true });

	const dictionaryStore: IDictionaryStore = useRootData(
		(store) => store.dictionaryStore
	);

	const showImportMessage = (
		fileCategoryDictionaryValueId: number | undefined
	): void => {
		if (!fileCategoryDictionaryValueId) {
			return;
		}

		const dictionary = dictionaryStore.getDictionary(props.codeName);
		const dictionaryItem = dictionary?.items?.find(
			(x) => x.id === fileCategoryDictionaryValueId
		);

		if (
			DictionariesConstants.MZ53Categories.some(
				(x) => x === dictionaryItem?.codeName
			)
		) {
			Modal.success({
				centered: true,
				maskClosable: true,
				title:
					'Gdy plik zostanie zaimportowany otrzymasz komunikat, w przypadku błędu zostanie on odpowiednio oznaczony.',
			});
		}
	};

	return (
		<Formik
			validateOnChange={true}
			validateOnBlur={false}
			initialValues={{
				id: state.file?.id,
				fileCategoryDictionaryValueId:
					state.file?.fileCategoryDictionaryValueId,
				fileCategoryDictionaryOtherValue:
					state.file?.fileCategoryDictionaryValue,
			}}
			enableReinitialize={true}
			onSubmit={(
				values: ProfiBazaAPIModels.StatementsUploadAttachmentOptionalParams,
				actions: FormikActions<
					ProfiBazaAPIModels.StatementsUploadAttachmentOptionalParams
				>
			) => {
				getProfiBazaApiClient()
					.then((api) =>
						api.statements.uploadAttachment({
							id: props.statementId,
							file: props.options.file,
							fileCategoryDictionaryValueId:
								values.fileCategoryDictionaryValueId,
							fileCategoryDictionaryOtherValue:
								values.fileCategoryDictionaryOtherValue,
							onUploadProgress: (event) => {
								const loadedProgress =
									event.loadedBytes / props.options.file.size;

								NProgress.set(loadedProgress);
							},
						})
					)
					.then((result) => {
						props.setSourceDocumentId(result.id);
						props.options.onSuccess(result, props.options.file);
						props.refreshDocumentDetailsRef();
						setState({
							isModalVisible: false,
							file: undefined,
						});
						showImportMessage(values.fileCategoryDictionaryValueId);
						NProgress.done();
					})
					.catch((err) => {
						actions.setErrors(err);
						showNotExpectedErrorModal(err);
						props.options.onError(err);
						NProgress.done();
					})
					.finally(() => {
						actions.setSubmitting(false);
						props.onDestroy();
						NProgress.done();
					});
			}}
			render={(
				formikProps: FormikProps<
					ProfiBazaAPIModels.StatementsUploadAttachmentOptionalParams
				>
			) => (
				<Modal
					visible={state.isModalVisible}
					maskClosable={false}
					centered
					title="Ustaw kategorię"
					onOk={() => {
						if (!formikProps.isSubmitting) formikProps.submitForm();
					}}
					onCancel={() => {
						setState({
							isModalVisible: false,
							file: undefined,
						});
						props.onDestroy();
						props.options.onError(new Error());
					}}
					okButtonProps={{ disabled: formikProps.isSubmitting }}
					cancelButtonProps={{ disabled: formikProps.isSubmitting }}
					okText="Zapisz"
					destroyOnClose
				>
					<DictionaryPicker
						label="Kategoria załącznika sprawozdania"
						codeName={props.codeName}
						otherValue="fileCategoryDictionaryOtherValue"
						name="fileCategoryDictionaryValueId"
						interventionPath={
							DictionaryInterventionPath.PROHEALTHACTIVITIES
						}
					/>
				</Modal>
			)}
		/>
	);
};

export default StatementDocumentsCategorySelect;
