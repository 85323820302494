import { Col } from 'antd';
import { CenteredRow } from 'layout/CenteredRow';
import React from 'react';

interface IProps {
	left?: JSX.Element | string;
	right?: JSX.Element | string;
}

const GridRow: React.FC<IProps> = (props) => {
	return (
		<CenteredRow gutter={[16, 24]} style={{ marginTop: 32 }}>
			<Col span={5}>{props.left}</Col>
			<Col span={13}>{props.children}</Col>
			<Col span={5}>{props.right}</Col>
		</CenteredRow>
	);
};

export default GridRow;
