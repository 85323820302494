import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import formatHelpers from 'helper/formatHelpers';
import { DictionaryName } from 'services/mappers/DictionaryName';
import * as DocumentSource from 'services/mappers/DocumentSource';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const columns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.DocumentListItemDto
>[] = [
	{
		dataIndex: 'fileName',
		key: 'fileName',
		title: 'Nazwa',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'source',
		key: 'source',
		title: 'Rodzaj procesu',
		filter: 'dictionary',
		sorter: true,
		filterDictionary: DictionaryName.DocumentProcessKind,
		render: (value: ProfiBazaAPIModels.DocumentSource) =>
			DocumentSource.map(value),
	},
	{
		dataIndex: 'year',
		key: 'year',
		title: 'Rok',
		filter: 'equals',
		sorter: true,
	},
	{
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Podmiot',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'voivodeship',
		key: 'voivodeship',
		title: 'Województwo',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'createdDate',
		key: 'createdDate',
		title: 'Data utworzenia',
		filter: 'dateRange',
		sorter: true,
		render: (value?: Date) => formatHelpers.formatDate(value),
	},
];

export default columns;
