import { useState } from 'react';

type DrawerReturnType = [boolean, () => void, () => void];

export const useDrawer = (
	initialVisibility: boolean = false
): DrawerReturnType => {
	const [visible, setVisible] = useState<boolean>(initialVisibility);

	const showDrawer = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};

	return [visible, showDrawer, onClose];
};
