import Dashboard from '@components/dashboard/Dashboard';
import SourceDocumentDetails from '@components/documents/SourceDocumentDetails';
import SourceDocumentsTable from '@components/documents/table/SourceDocumentsTable';
import Forms from '@components/forms/Forms';
import StatementTestDetails from '@components/forms/test/StatementTestDetails';
import Interventions from '@components/intervention/Interventions';
import NotificationsView from '@components/notifications/NotificationsView';
import PermissionsView from '@components/permissions/PermissionsView';
import ProposalTable from '@components/proposal/table/ProposalTable';
import Reports from '@components/reports/Reports';
import { SkipLinkContent, SkipLinks } from '@components/skipLinks/SkipLinks';
import StatementDetails from '@components/statements/StatementDetails';
import StatementRegistry from '@components/statements/table/StatementRegistry';
import TagList from '@components/tags/TagList';
import RODOManualModal, {
	IRODOManualModal,
} from '@components/userManual/modals/RODOManualModal';
import UserManualView from '@components/userManual/UserManualView';
import { ajaxByUser } from '@helper/api';
import useIconsAccessibility from '@hooks/accessibility/useIconsAccessibilityHook';
import { useRODO } from '@hooks/useRODOHook';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { AccountDetailsDto } from '@services/src/models';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Spin } from 'antd';
import InterventionData from 'components/intervention/InterventionData';
import InterventionDetails from 'components/intervention/InterventionDetails';
import SubjectReportingProcessTable from 'components/subjects/reportingProcess/SubjectReportingProcessTable';
import SubjectData from 'components/subjects/SubjectData';
import SubjectDetails from 'components/subjects/SubjectDetails';
import SubjectRegistry from 'components/subjects/SubjectRegistry';
import { useRootData } from 'hooks/hook';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { Redirect, Switch, useHistory } from 'react-router';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import PrimaryView from 'views/PrimaryView';

import Authenticated from './authentication/authenticated';
import Authorized from './authorization/authorized';
import Dictionaries from './components/dictionary/Dictionaries';
import RoleEdit from './components/role/RoleEdit';
import Roles from './components/role/Roles';
import ProfiBazaLayout from './layout/Layout';

interface IProps {}

const App: React.FC<RouteComponentProps<any> & IProps> = (props) => {
	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const rodoManualModalRef = useRef<IRODOManualModal>() as React.RefObject<
		IRODOManualModal
	>;

	useRODO(accountDetailsStore, rodoManualModalRef);
	const history = useHistory();

	const acceptRodo = (): void => {
		ajaxByUser('Klauzula informacyjna RODO została zaakceptowana', () =>
			getProfiBazaApiClient().then((api) =>
				api.account
					.acceptRODO()
					.then(accountDetailsStore.fetchAccountDetails)
			)
		);
	};

	useIconsAccessibility();

	useEffect(() => {
		const iframes = document.querySelectorAll('iframe');

		for (const iframe of Array.from(iframes)) {
			if (iframe && iframe.style.visibility === 'hidden') {
				iframe.setAttribute('aria-hidden', 'true');

				if (iframe.contentDocument?.documentElement) {
					iframe.contentDocument.documentElement.lang = 'pl';
				}
			}
		}
	}, [history.location]);

	const preloader = accountDetailsStore.preloader.get();

	const switchView = (account: AccountDetailsDto | undefined) => {
		if (account) {
			if (account.existsInProfiBaza) {
				return (
					<Switch>
						<Redirect exact from="/" to="/start" />
						<Route
							path="/start"
							render={(props) => (
								<PrimaryView
									content={<Dashboard {...props} />}
									title="Dashboard"
								/>
							)}
						/>
						<Route
							path="/admin/roles"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.AdminRolesModifications,
										ProfiBazaAPIModels.Permission
											.AdminRolesPreview,
									]}
								>
									<PrimaryView
										content={<Roles />}
										title="Administracja Rolami"
									/>
								</Authorized>
							)}
						/>
						<Route
							path="/admin/roles/edit/:id"
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.AdminRolesModifications,
										ProfiBazaAPIModels.Permission
											.AdminRolesPreview,
									]}
								>
									<PrimaryView
										content={
											<RoleEdit
												roleId={props.match.params.id}
												{...props}
											/>
										}
										title="Administracja Rolami"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/admin/proposal"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.ProposalsHandlingAll,
										ProfiBazaAPIModels.Permission
											.ProposalsHandlingMine,
									]}
								>
									<PrimaryView
										content={
											<ProposalTable
												{...props}
												isAdmin={true}
											/>
										}
										title="Zarządzanie wnioskami o dostęp"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/statements/:filter?"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.StatementPreviewAllNPZ,
										ProfiBazaAPIModels.Permission
											.StatementPreviewMineNPZ,
										ProfiBazaAPIModels.Permission
											.StatementPreviewSubordinateNPZ,
										ProfiBazaAPIModels.Permission
											.StatementPreviewAllMZ49,
										ProfiBazaAPIModels.Permission
											.StatementPreviewMineMZ49,
										ProfiBazaAPIModels.Permission
											.StatementPreviewSubordinateMZ49,
										ProfiBazaAPIModels.Permission
											.StatementPreviewAllMZ53,
										ProfiBazaAPIModels.Permission
											.StatementPreviewMineMZ53,
										ProfiBazaAPIModels.Permission
											.StatementPreviewSubordinateMZ53,
									]}
								>
									<PrimaryView
										content={
											<StatementRegistry {...props} />
										}
										title="Sprawozdania"
									/>
								</Authorized>
							)}
						/>

						<Route
							path={[
								'/statement/details/:id/:tab?/:fileId?',
								'/statement/details/:id/:formPartId?/:applicationName?/:formName?/:documentId?',
							]}
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.StatementPreviewAllNPZ,
										ProfiBazaAPIModels.Permission
											.StatementPreviewMineNPZ,
										ProfiBazaAPIModels.Permission
											.StatementPreviewSubordinateNPZ,
									]}
								>
									<PrimaryView
										content={
											<StatementDetails
												statementId={
													props.match.params.id
												}
												editable={false}
												{...props}
											/>
										}
										title="Sprawozdanie"
									/>
								</Authorized>
							)}
						/>

						<Route
							path={[
								'/statement/edit/:id/:tab?/:fileId?',
								'/statement/edit/:id/:formPartId?/:applicationName?/:formName?/:documentId?',
							]}
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.StatementsGenerationMZ49,
										ProfiBazaAPIModels.Permission
											.StatementsGenerationMZ53,
										ProfiBazaAPIModels.Permission
											.StatementsGenerationNPZ,
									]}
								>
									<PrimaryView
										content={
											<StatementDetails
												editable={true}
												statementId={
													props.match.params.id
												}
												{...props}
											/>
										}
										title="Sprawozdanie"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/subject"
							exact={true}
							render={(props) => (
								<Authorized
									permission={
										ProfiBazaAPIModels.Permission
											.SubjectsListPreview
									}
								>
									<PrimaryView
										content={<SubjectRegistry {...props} />}
										title="Podmioty"
									/>
								</Authorized>
							)}
						/>
						<Route
							path="/subject/details/:id/:tab?/:fileId?"
							exact={true}
							render={(props) => (
								<Authorized
									permission={
										ProfiBazaAPIModels.Permission
											.SubjectsDetailsPreview
									}
								>
									<PrimaryView
										content={
											<SubjectDetails
												subjectId={
													props.match.params.id
												}
												span={18}
												{...props}
											/>
										}
										title="Podmiot"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/subject/edit/:id/:tab?/:fileId?"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.SubjectsModificationsAll,
										ProfiBazaAPIModels.Permission
											.SubjectsModificationsMine,
									]}
								>
									<PrimaryView
										content={
											<SubjectDetails
												editable={true}
												subjectId={
													props.match.params.id
												}
												span={18}
												{...props}
											/>
										}
										title="Podmiot"
									/>
								</Authorized>
							)}
						/>
						<Route
							path="/subject/add"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.SubjectsModificationsAll,
										ProfiBazaAPIModels.Permission
											.SubjectsModificationsMine,
									]}
								>
									<PrimaryView
										content={
											<SubjectData
												span={18}
												editable={true}
												{...props}
											/>
										}
										title="Podmiot"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/dictionaries/:dictionaryId?/:dictionaryVersionId?"
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.DictionariesListPreview,
									]}
								>
									<PrimaryView
										content={
											<Dictionaries
												{...props}
												dictionaryId={
													props.match.params
														.dictionaryId
														? parseInt(
																props.match
																	.params
																	.dictionaryId
														  )
														: undefined
												}
												dictionaryVersionId={
													props.match.params
														.dictionaryVersionId
														? parseInt(
																props.match
																	.params
																	.dictionaryVersionId
														  )
														: undefined
												}
											/>
										}
										title="Słowniki"
									/>
								</Authorized>
							)}
						/>

						<Route
							path={[
								'/rizp/edit/:id/:tab?/:fileId?',
								'/rizp/edit/:id/:tab(activities)/:mode/:activityId/:formPartId?/:applicationName?/:formName?/:documentId?',
								'/rizp/edit/:id/:tab?/:formPartId?/:applicationName?/:formName?/:documentId?',
							]}
							exact={true}
							render={(props) => (
								<Authorized
									permission={
										ProfiBazaAPIModels.Permission
											.RIZPModifications
									}
								>
									<PrimaryView
										content={
											<InterventionDetails
												editable={true}
												interventionId={
													props.match.params.id
												}
												{...props}
												isPattern={false}
											/>
										}
										title="Interwencja"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/rizp/add"
							exact={true}
							render={(props) => (
								<Authorized
									permission={
										ProfiBazaAPIModels.Permission
											.RIZPModifications
									}
								>
									<PrimaryView
										content={
											<InterventionData
												editable={true}
												{...props}
											/>
										}
										title="Interwencja"
									/>
								</Authorized>
							)}
						/>
						<Route
							path="/rizp/pattern/add"
							exact={true}
							render={(props) => (
								<Authorized
									permission={
										ProfiBazaAPIModels.Permission
											.RIZPModifications
									}
								>
									<PrimaryView
										content={
											<InterventionData
												editable={true}
												isPattern={true}
												{...props}
											/>
										}
										title="Wzorzec interwencji"
									/>
								</Authorized>
							)}
						/>
						<Route
							path={[
								'/rizp/pattern/edit/:id/:tab?/:fileId?',
								'/rizp/pattern/edit/:id/:tab(activities)/:mode/:activityId/:formPartId?/:applicationName?/:formName?/:documentId?',
								'/rizp/pattern/edit/:id/:tab?/:formPartId?/:applicationName?/:formName?/:documentId?',
							]}
							exact={true}
							render={(props) => (
								<Authorized
									permission={
										ProfiBazaAPIModels.Permission
											.RIZPModifications
									}
								>
									<PrimaryView
										content={
											<InterventionDetails
												editable={true}
												interventionId={
													props.match.params.id
												}
												{...props}
												isPattern={true}
											/>
										}
										title="Wzorzec interwencji"
									/>
								</Authorized>
							)}
						/>
						<Route
							path={[
								'/rizp/details/:id/:tab?/:fileId?',
								'/rizp/details/:id/:tab(activities)/:mode/:activityId/:formPartId?/:applicationName?/:formName?/:documentId?',
								'/rizp/details/:id/:tab?/:formPartId?/:applicationName?/:formName?/:documentId?',
							]}
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.RIZPPreviewAll,
										ProfiBazaAPIModels.Permission
											.RIZPPreviewMine,
										ProfiBazaAPIModels.Permission
											.RIZPPreviewSubordinate,
									]}
								>
									<PrimaryView
										content={
											<InterventionDetails
												editable={false}
												interventionId={
													props.match.params.id
												}
												isPattern={false}
												{...props}
											/>
										}
										title="Interwencja"
									/>
								</Authorized>
							)}
						/>

						<Route
							path={[
								'/rizp/pattern/details/:id/:tab?/:fileId?',
								'/rizp/pattern/details/:id/:tab(activities)/:mode/:activityId/:formPartId?/:applicationName?/:formName?/:documentId?',
								'/rizp/pattern/details/:id/:tab?/:formPartId?/:applicationName?/:formName?/:documentId?',
							]}
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.RIZPPreviewAll,
										ProfiBazaAPIModels.Permission
											.RIZPPreviewMine,
										ProfiBazaAPIModels.Permission
											.RIZPPreviewSubordinate,
									]}
								>
									<PrimaryView
										content={
											<InterventionDetails
												editable={false}
												interventionId={
													props.match.params.id
												}
												{...props}
												isPattern={true}
											/>
										}
										title="Wzorzec"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/rizp/:filter?"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.RIZPPreviewMine,
										ProfiBazaAPIModels.Permission
											.RIZPPreviewSubordinate,
										ProfiBazaAPIModels.Permission
											.RIZPPreviewAll,
									]}
								>
									<PrimaryView
										content={<Interventions {...props} />}
										title="Interwencje"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/reports"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.ReportsAOTMiTPreview,
										ProfiBazaAPIModels.Permission
											.ReportsAllPreview,
										ProfiBazaAPIModels.Permission
											.ReportsMZPreview,
										ProfiBazaAPIModels.Permission
											.ReportsProgramyZdrowotnePreview,
										ProfiBazaAPIModels.Permission
											.ReportsRPPreview,
										ProfiBazaAPIModels.Permission
											.ReportsSZChorobowoscPreview,
										ProfiBazaAPIModels.Permission
											.ReportsSZDemografiaPreview,
										ProfiBazaAPIModels.Permission
											.ReportsSZDeprywacjaPreview,
										ProfiBazaAPIModels.Permission
											.ReportsSZPreview,
										ProfiBazaAPIModels.Permission
											.ReportsSZStatusyPreview,
										ProfiBazaAPIModels.Permission
											.ReportsSZUmieralnoscPreview,
									]}
								>
									<PrimaryView
										content={<Reports />}
										title="Raporty"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/admin/permissions/:tab?/:id?"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.AdminRolesAssignmentGlobal,
										ProfiBazaAPIModels.Permission
											.AdminRolesAssignmentLocal,
									]}
								>
									<PrimaryView
										content={
											<PermissionsView
												{...props}
												activeTab={
													props.match.params.tab
												}
												id={props.match.params.id}
											/>
										}
										title="Zarządzanie uprawnieniami"
									/>
								</Authorized>
							)}
						/>
						<Route
							path="/admin/notifications"
							exact
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.NotificationsManagement,
									]}
								>
									<PrimaryView
										content={<NotificationsView />}
										title="Zarządzanie powiadomieniami"
									/>
								</Authorized>
							)}
						/>
						<Route
							path="/admin/userManuals"
							exact
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.UserManualManagement,
									]}
								>
									<PrimaryView
										content={<UserManualView />}
										title="Instrukcję użytkownika"
									/>
								</Authorized>
							)}
						/>
						<Route
							path="/admin/subjectReportingProcess"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.SubjectsModificationsStatementObligation,
									]}
								>
									<PrimaryView
										content={
											<SubjectReportingProcessTable
												{...props}
											/>
										}
										title="Obowiązek sprawozdawczości"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/proposal"
							exact={true}
							render={(props) => (
								<PrimaryView
									content={
										<ProposalTable
											{...props}
											isAdmin={false}
										/>
									}
									title="Wnioski o dostęp"
								/>
							)}
						/>

						<Route
							path="/documents/details/:id?/:fileId?"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.DocumentsRepositoryPreview,
									]}
								>
									<PrimaryView
										content={
											<SourceDocumentDetails
												documentId={
													props.match.params.id
												}
												baseUrl={`/documents/details/${props.match.params.id}`}
												onEmptyDocumentsTree={() =>
													props.history.push(
														'/documents'
													)
												}
												span={18}
											/>
										}
										title="Dokumenty"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/documents/:filter?"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.DocumentsRepositoryListPreview,
									]}
								>
									<PrimaryView
										content={
											<>
												<SkipLinkContent />
												<SourceDocumentsTable
													{...props}
												/>
											</>
										}
										title="Dokumenty"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/forms/test/details/:id/:formPartId?/:applicationName?/:formName?/:documentId?"
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.FormsManagement,
									]}
								>
									<PrimaryView
										content={
											<StatementTestDetails
												editable={false}
												statementId={
													props.match.params.id
												}
											></StatementTestDetails>
										}
										title="Sprawozdania testowe"
									/>
								</Authorized>
							)}
						/>
						<Route
							path="/forms/test/edit/:id/:formPartId?/:applicationName?/:formName?/:documentId?"
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.FormsManagement,
									]}
								>
									<PrimaryView
										content={
											<StatementTestDetails
												editable={true}
												statementId={
													props.match.params.id
												}
											></StatementTestDetails>
										}
										title="Sprawozdania testowe"
									/>
								</Authorized>
							)}
						/>

						<Route
							path={[
								'/forms/test',
								'/forms/:formId?/:formVersionId?',
							]}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.FormsManagement,
									]}
								>
									<PrimaryView
										content={
											<Forms
												{...props}
												formId={
													props.match.params.formId
														? props.match.params
																.formId
														: undefined
												}
												formVersionId={
													props.match.params
														.formVersionId
														? props.match.params
																.formVersionId
														: undefined
												}
											/>
										}
										title="Formularze i sprawozdania testowe"
									/>
								</Authorized>
							)}
						/>

						<Route
							path="/admin/tags"
							exact={true}
							render={(props) => (
								<Authorized
									permissions={[
										ProfiBazaAPIModels.Permission
											.TagsManagementGlobal,
										ProfiBazaAPIModels.Permission
											.TagsManagementLocal,
									]}
								>
									<PrimaryView
										content={<TagList />}
										title="Zarządzanie tagami"
									/>
								</Authorized>
							)}
						/>
					</Switch>
				);
			} else {
				return (
					<Route
						path="*"
						exact={true}
						render={(props) => (
							<PrimaryView
								content={
									<ProposalTable {...props} isAdmin={false} />
								}
								title="Wnioski o dostęp do ProfiBazy"
							/>
						)}
					/>
				);
			}
		}

		return <></>;
	};

	return (
		<Spin
			className="full-screen"
			tip={preloader}
			spinning={preloader !== undefined}
		>
			<Authenticated>
				<SkipLinks />
				<ProfiBazaLayout>
					{switchView(accountDetailsStore.account.get())}
				</ProfiBazaLayout>
			</Authenticated>
			<RODOManualModal
				ref={rodoManualModalRef}
				onAccept={acceptRodo}
				allowCancel={false}
			/>
		</Spin>
	);
};

export default withRouter(observer(App));
