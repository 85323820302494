import RODOManualModal, {
	IRODOManualModal,
} from '@components/userManual/modals/RODOManualModal';
import { RODOManualType, UserManualType } from '@services/src/models';
import { Button, Col, Input, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { FFieldLabel, FInput } from 'forms/FormikFormItems';
import { ajaxByUser, ajaxCatch } from 'helper/api';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { UpdateProfileCommand } from '../commands/UpdateProfileCommand';
import { UserProfileValidationSchema } from '../validators/UserProfileValidation';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { useRootData } from 'hooks/hook';

interface IProps {
	onUserFullNameChange: (value: string) => void;
}

interface IState {
	visible: boolean;
	loading: boolean;
	data: ProfiBazaAPIModels.UserProfileDto;
	footerButtons: React.ReactNode[];
	isEditMode: boolean;
	subjectName?: string;
}

export interface IIUserProfileModal {
	showModal: () => void;
}

const UserProfileModal = React.forwardRef<IIUserProfileModal, IProps>(
	(props, ref) => {
		const [state, setState] = useState<IState>({
			visible: false,
			loading: true,
			data: {},
			footerButtons: [],
			isEditMode: false,
			subjectName: '',
		});

		const accountDetailsStore: IAccountDetailsStore = useRootData(
			(store) => store.accountDetailsStore
		);

		useImperativeHandle(ref, () => ({
			showModal: () => {
				setState({
					...state,
					visible: true,
					subjectName: accountDetailsStore?.account?.get()?.subject?.name
				});
			},
		}));

		const rodoManualModalRef = useRef<
			IRODOManualModal
		>() as React.RefObject<IRODOManualModal>;

		const fetchData = async () => {
			const client = await getProfiBazaApiClient();
			await client.account
				.profile()
				.then((result: ProfiBazaAPIModels.UserProfileDto) => {
					setState((state) => ({
						...state,
						loading: false,
						data: result,
						footerButtons: initButtons(result.isFromCRUiP),
						isEditMode: false,
					}));

					props.onUserFullNameChange(result.firstName + ' ' + result.lastName);
				});
		};

		const abortController = new AbortController();

		useEffect(() => {
			if (state.visible) {
				fetchData();
			}

			return () => {
				abortController.abort();
			};
		}, [state.visible]);

		const handleCancel = async () => {
			setState({
				...state,
				visible: false,
				isEditMode: false,
			});
		};

		const initButtons = (
			isFromCruip?: boolean,
			isEditMode?: boolean
		): React.ReactNode[] => {
			let rodoType = isFromCruip
				? RODOManualType.CRUIP
				: RODOManualType.ProposalAccess;

			let editMode = isEditMode != null ? isEditMode : state.isEditMode;

			let buttonsArr = [
				<Button
					key="rodo"
					onClick={() => {
						rodoManualModalRef.current?.showModal({
							manualType: UserManualType.RODO,
							rodoManualType: rodoType,
						});
					}}
				>
					Klazula RODO
				</Button>,
			];
			
			if (isFromCruip && !editMode) {
				buttonsArr.push(
					<Button type="primary" onClick={() => editForm()}>
						Edytuj
					</Button>
				);
			}

			return buttonsArr;
		};

		const cancelEdit = () => {
			let isCruip = state.data.isFromCRUiP;
			setState((state) => ({
				...state,
				isEditMode: false,
				footerButtons: initButtons(isCruip, false),
			}));
		};

		const editForm = () => {
			let isCruip = state.data.isFromCRUiP;
			setState((state) => ({
				...state,
				isEditMode: true,
				footerButtons: initButtons(isCruip, true),
			}));
		};

		return (
			<Formik
				validateOnChange={true}
				validateOnBlur={false}
				initialValues={new UpdateProfileCommand(state.data)}
				enableReinitialize
				validationSchema={UserProfileValidationSchema}
				onSubmit={(
					values: UpdateProfileCommand,
					actions: FormikActions<UpdateProfileCommand>
				) => {
					ajaxByUser(
						'Edytowano profil użytkownika',
						() =>
							getProfiBazaApiClient().then((api) =>
								api.account.updateProfile({
									body: UpdateProfileCommand.toUpdate(values),
								})
							),
						() => {
							let account = accountDetailsStore.account.get();

							if(account){
								account.lastName = values.lastName;
								account.firstName = values.firstName;

								accountDetailsStore!.account.set(account);
								props.onUserFullNameChange(values.firstName + " " + values.lastName);
							}
							handleCancel();
							actions.resetForm();
						},
						(errors) => {
							actions.setErrors(errors);
						}
					).then(() => actions.setSubmitting(false));
				}}
				render={(props: FormikProps<UpdateProfileCommand>) => (
					<Modal
						title="Mój profil"
						closable
						visible={state.visible}
						maskClosable
						centered
						destroyOnClose
						okText={'Zapisz'}
						onCancel={() => {
							handleCancel();
							props.resetForm();
						}}
						footer={state.footerButtons}
					>
						<RODOManualModal
							ref={rodoManualModalRef}
							allowCancel={true}
						/>
						{!state.loading && (
							<>
								<Row>
									<Col span={24}>
										<FFieldLabel label={'Imię'} />
										<Field
											component={FInput}
											style={{
												width: '100%',
											}}
											readOnly={!state.isEditMode}
											name="firstName"
											label="Imię"
										/>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<FFieldLabel label={'Nazwisko'} />
										<Field
											component={FInput}
											style={{
												width: '100%',
											}}
											readOnly={!state.isEditMode}
											name="lastName"
											label="Nazwisko"
										/>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<label>Email:</label>
										<Input
											style={{
												width: '100%',
											}}
											type="email"
											value={state.data?.email}
											readOnly={true}
											name="email"
											aria-label="Email"
										/>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<label>Podmiot:</label>
										<Input
											style={{
												width: '100%',
											}}
											type="subjectName"
											value={state.subjectName}
											readOnly={true}
											name="subjectName"
											aria-label="Podmiot"
										/>
									</Col>
								</Row>
								<Row justify="center" align="bottom">
									{state.isEditMode && (
										<>
											<Col
												span={4}
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Button
													onClick={() => {
														props.resetForm();
														cancelEdit();
													}}
												>
													Anuluj
												</Button>
											</Col>
											<Col
												span={4}
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Button
													type="primary"
													htmlType="submit"
													onClick={props.submitForm}
												>
													Zapisz
												</Button>
											</Col>
										</>
									)}
								</Row>
							</>
						)}
					</Modal>
				)}
			/>
		);
	}
);

export default UserProfileModal;
