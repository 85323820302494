import React, { useState, useEffect } from 'react';
import { RoleDto } from './UpdateUserRolesCommand';
import { Formik, FormikActions, FormikProps, Field } from 'formik';
import { RoleValueValidationSchema } from './RoleValueValidations';
import { Modal, Select } from 'antd';
import { FDatePicker, FSelect, FFieldLabel } from 'forms/FormikFormItems';
import { RoleItemDto } from 'services/src/models';
import { ajaxCatch } from 'helper/api';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { useRootData } from 'hooks/hook';
import PermissionValidator from 'authorization/permissionValidator';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

interface IProps {
	mode: 'new' | 'edit';
	role?: RoleDto;
	visible: boolean;
	onSubmit: (role: RoleDto) => boolean;
	onCancel: () => void;
}

interface IState {
	roles: RoleItemDto[];
}

const RoleModal: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({ roles: [] });

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);
	const permissionValidator = new PermissionValidator(
		accountDetailsStore.account.get()!
	);

	useEffect(() => {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.role.all())
				.then((response: RoleItemDto[]) => {
					if (
						permissionValidator.has(
							ProfiBazaAPIModels.Permission
								.AdminRolesAssignmentGlobal
						)
					) {
						setState({ roles: response! });
					} else if (
						permissionValidator.has(
							ProfiBazaAPIModels.Permission
								.AdminRolesAssignmentLocal
						)
					) {
						setState({
							roles: response!.filter(
								(x) =>
									x.category ===
									ProfiBazaAPIModels.RoleCategory.LOCAL
							),
						});
					}
				})
		);
	}, []);

	return (
		<Formik
			validateOnBlur={true}
			enableReinitialize={true}
			initialValues={props.mode === 'new' ? {} : props.role!}
			validationSchema={RoleValueValidationSchema}
			onSubmit={(values: RoleDto, actions: FormikActions<RoleDto>) => {
				if (props.onSubmit(values)) {
					actions.setSubmitting(false);
					actions.resetForm();
				} else {
					actions.setErrors({
						roleId: 'Użytkownik posiada już wybraną rolę.',
					});
					actions.setSubmitting(false);
				}
			}}
			render={(formik: FormikProps<RoleDto>) => (
				<Modal
					visible={props.visible}
					maskClosable={false}
					centered
					title={
						props.mode === 'new' ? 'Dodaj rolę' : 'Zaktualizuj rolę'
					}
					onOk={() => {
						formik.submitForm();
					}}
					onCancel={() => {
						props.onCancel();
						formik.resetForm();
					}}
					okButtonProps={{ disabled: formik.isSubmitting }}
					cancelButtonProps={{ disabled: formik.isSubmitting }}
					okText={props.mode === 'new' ? 'Dodaj' : 'Aktualizuj'}
				>
					<FFieldLabel label="Rola" />
					<Field
						label="Rola"
						component={FSelect}
						name="roleId"
						mode="single"
						onChangeAttempt={(value: string) => {
							formik.setFieldValue(
								'roleName',
								state.roles.find((x) => x.id == value)?.name
							);
							return true;
						}}
					>
						{state.roles &&
							state.roles!.map((p: RoleItemDto) => {
								return (
									<Select.Option
										key={p.id}
										value={p.id!}
										disabled={!p.isActive!}
									>
										{p.name}
									</Select.Option>
								);
							})}
					</Field>

					<FFieldLabel label="Data od" />
					<Field
						label="Data od"
						component={FDatePicker}
						name="validFrom"
						mode="date"
						style={{ width: '100%' }}
					></Field>

					<FFieldLabel label="Data do" />
					<Field
						label="Data do"
						component={FDatePicker}
						name="validTo"
						mode="date"
						style={{ width: '100%' }}
					></Field>
				</Modal>
			)}
		/>
	);
};

export default RoleModal;
