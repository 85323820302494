import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<ProfiBazaAPIModels.CommentType, string>([
	[ProfiBazaAPIModels.CommentType.IMPORTANT, 'ważny'],
	[ProfiBazaAPIModels.CommentType.PRIVATE, 'informacyjny w ramach podmiotu'],
	[ProfiBazaAPIModels.CommentType.PUBLIC, 'informacyjny publiczny'],
]);

export const map = (value: ProfiBazaAPIModels.CommentType): string =>
	mappings.get(value) ?? '';

