import PermissionValidator from "@authorization/permissionValidator";
import { StatementArea } from "@services/src/models";
import { ProfiBazaAPIModels } from "@services/src/profiBazaAPI";

export const hasEditStatementPermission = (
    permissionValidator: PermissionValidator,
    statementArea: StatementArea) => {
    
        if(statementArea == StatementArea.MZ49)
            return permissionValidator.has(ProfiBazaAPIModels.Permission.StatementsGenerationMZ49);

        if(statementArea == StatementArea.MZ53)
            return permissionValidator.has(ProfiBazaAPIModels.Permission.StatementsGenerationMZ53);	

        if(statementArea == StatementArea.NPZ)
            return permissionValidator.has(ProfiBazaAPIModels.Permission.StatementsGenerationNPZ);	

        return false;
}

export const hasVerifyStatementPermission = (
    permissionValidator: PermissionValidator,
    statementArea: StatementArea) => {
    
        if(statementArea == StatementArea.MZ49)
            return permissionValidator.has(ProfiBazaAPIModels.Permission.StatementVerificationMZ49);

        if(statementArea == StatementArea.MZ53)
            return permissionValidator.has(ProfiBazaAPIModels.Permission.StatementVerificationMZ53);	

        if(statementArea == StatementArea.NPZ)
            return permissionValidator.has(ProfiBazaAPIModels.Permission.StatementVerificationNPZ);	

        return false;
}

export const hasCriticalWarningPermission = (permissionValidator: PermissionValidator) => {
    return permissionValidator.has(ProfiBazaAPIModels.Permission.StatementCriticalWarning);
};