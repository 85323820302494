/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/userNotificationMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a UserNotification. */
export class UserNotification {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a UserNotification.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UserNotificationGetReadUserNotificationsResponse>
   */
  getReadUserNotifications(options?: Models.UserNotificationGetReadUserNotificationsOptionalParams): Promise<Models.UserNotificationGetReadUserNotificationsResponse>;
  /**
   * @param callback The callback
   */
  getReadUserNotifications(callback: msRest.ServiceCallback<Models.UserNotificationDTOPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getReadUserNotifications(options: Models.UserNotificationGetReadUserNotificationsOptionalParams, callback: msRest.ServiceCallback<Models.UserNotificationDTOPagedResult>): void;
  getReadUserNotifications(options?: Models.UserNotificationGetReadUserNotificationsOptionalParams | msRest.ServiceCallback<Models.UserNotificationDTOPagedResult>, callback?: msRest.ServiceCallback<Models.UserNotificationDTOPagedResult>): Promise<Models.UserNotificationGetReadUserNotificationsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getReadUserNotificationsOperationSpec,
      callback) as Promise<Models.UserNotificationGetReadUserNotificationsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UserNotificationGetUnreadUserNotificationsResponse>
   */
  getUnreadUserNotifications(options?: Models.UserNotificationGetUnreadUserNotificationsOptionalParams): Promise<Models.UserNotificationGetUnreadUserNotificationsResponse>;
  /**
   * @param callback The callback
   */
  getUnreadUserNotifications(callback: msRest.ServiceCallback<Models.UserNotificationDTOPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getUnreadUserNotifications(options: Models.UserNotificationGetUnreadUserNotificationsOptionalParams, callback: msRest.ServiceCallback<Models.UserNotificationDTOPagedResult>): void;
  getUnreadUserNotifications(options?: Models.UserNotificationGetUnreadUserNotificationsOptionalParams | msRest.ServiceCallback<Models.UserNotificationDTOPagedResult>, callback?: msRest.ServiceCallback<Models.UserNotificationDTOPagedResult>): Promise<Models.UserNotificationGetUnreadUserNotificationsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getUnreadUserNotificationsOperationSpec,
      callback) as Promise<Models.UserNotificationGetUnreadUserNotificationsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UserNotificationMarkAsReadResponse>
   */
  markAsRead(options?: Models.UserNotificationMarkAsReadOptionalParams): Promise<Models.UserNotificationMarkAsReadResponse>;
  /**
   * @param callback The callback
   */
  markAsRead(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  markAsRead(options: Models.UserNotificationMarkAsReadOptionalParams, callback: msRest.ServiceCallback<string>): void;
  markAsRead(options?: Models.UserNotificationMarkAsReadOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UserNotificationMarkAsReadResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      markAsReadOperationSpec,
      callback) as Promise<Models.UserNotificationMarkAsReadResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UserNotificationToggleActiveOfSameKindResponse>
   */
  toggleActiveOfSameKind(options?: Models.UserNotificationToggleActiveOfSameKindOptionalParams): Promise<Models.UserNotificationToggleActiveOfSameKindResponse>;
  /**
   * @param callback The callback
   */
  toggleActiveOfSameKind(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  toggleActiveOfSameKind(options: Models.UserNotificationToggleActiveOfSameKindOptionalParams, callback: msRest.ServiceCallback<string>): void;
  toggleActiveOfSameKind(options?: Models.UserNotificationToggleActiveOfSameKindOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UserNotificationToggleActiveOfSameKindResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      toggleActiveOfSameKindOperationSpec,
      callback) as Promise<Models.UserNotificationToggleActiveOfSameKindResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getReadUserNotificationsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "notifications/UserNotification/read",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.UserNotificationDTOPagedResult
    },
    default: {}
  },
  serializer
};

const getUnreadUserNotificationsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "notifications/UserNotification/unread",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.UserNotificationDTOPagedResult
    },
    default: {}
  },
  serializer
};

const markAsReadOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "notifications/UserNotification/markAsRead",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.MarkAsReadCommand
  },
  responses: {
    204: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const toggleActiveOfSameKindOperationSpec: msRest.OperationSpec = {
  httpMethod: "PATCH",
  path: "notifications/UserNotification/toggleActiveOfSameKind",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ToggleActiveOfSameKindCommand
  },
  responses: {
    204: {},
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};
