import { ProfiBazaColumnProps } from 'components/shared/paginatedProfiBazaTable/GridHelper';
import { DictionaryName } from 'services/mappers/DictionaryName';
import * as DocumentSubjectKind from 'services/mappers/DocumentSubjectKind';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const columns: ProfiBazaColumnProps<
	ProfiBazaAPIModels.DocumentListItemDto
>[] = [
	{
		dataIndex: 'fileName',
		key: 'fileName',
		title: 'Nazwa',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'year',
		key: 'year',
		title: 'Rok nadesłania',
		filter: 'equals',
		sorter: true,
	},
	{
		dataIndex: 'voivodeship',
		key: 'voivodeship',
		title: 'Województwo',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'county',
		key: 'county',
		title: 'Powiat',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'programYearFrom',
		key: 'programYearFrom',
		title: 'Rok rozpoczęcia',
		filter: 'equals',
		sorter: true,
	},
	{
		dataIndex: 'programYearTo',
		key: 'programYearTo',
		title: 'Rok zakończenia',
		filter: 'equals',
		sorter: true,
	},
	{
		dataIndex: 'decisionProblem',
		key: 'decisionProblem',
		title: 'Problem decyzyjny',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'presidentOpinionKind',
		key: 'presidentOpinionKind',
		title: 'Rodzaj opinii Prezesa',
		filter: 'contains',
		sorter: true,
	},
	{
		dataIndex: 'opinionPresidentYear',
		key: 'opinionPresidentYear',
		title: 'Rok wydania opinii Prezesa',
		filter: 'equals',
		sorter: true,
		defaultHidden: true,
	},

	{
		dataIndex: 'documentSubjectKind',
		key: 'documentSubjectKind',
		title: 'Rodzaj podmiotu',
		filter: 'dictionary',
		sorter: true,
		defaultHidden: true,
		filterDictionary: DictionaryName.DocumentSubjectKind,
		render: (value: ProfiBazaAPIModels.DocumentSubjectKind) =>
			DocumentSubjectKind.map(value),
	},
	{
		dataIndex: 'subjectName',
		key: 'subjectName',
		title: 'Nazwa podmiotu przesyłającego',
		filter: 'contains',
		sorter: true,
		defaultHidden: true,
	},
	{
		dataIndex: 'number',
		key: 'number',
		title: 'Numer programu',
		filter: 'contains',
		sorter: true,
		defaultHidden: true,
	},
];

export default columns;
