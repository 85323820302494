import './AccountMenu.less';

import UserProfileModal, {
	IIUserProfileModal,
} from '@components/profile/modals/UserProfileModal';
import { isEmptyString } from '@helper/stringHelper';
import { Tooltip } from 'antd';
import { GlobalUserManager } from 'authentication/userManager';
import InterventionValidationErrors from 'components/intervention/model/InterventionValidationErrors';
import ManageNotificationVisibilityModal, {
	IIManageNotificationVisibilityModal,
} from 'components/notifications/modals/manageNotificationVisibilityModal/ManageNotificationVisibilityModal';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, useMenuState } from 'reakit/Menu';

interface IProps {
	userFullName?: string;
	onUserFullNameChange: (value: string) => void;
}

const AccountMenu: React.FC<IProps> = (props) => {
	const menu = useMenuState();
	const manageNotificationVisibilityModalRef = useRef<
		IIManageNotificationVisibilityModal
	>() as React.RefObject<IIManageNotificationVisibilityModal>;

	const userProfileModal = useRef<IIUserProfileModal>() as React.RefObject<
		IIUserProfileModal
	>;

	function getInitials(name: string | undefined): string {
		if (name && !isEmptyString(name)) {
			return name
				.split(' ')
				.map((n) => n[0].toUpperCase())
				.join('');
		} else return '';
	}

	return (
		<>
			<MenuButton
				{...menu}
				className="ant-btn offset0 ant-dropdown-trigger ant-btn-link ant-btn-circle ant-btn-lg"
				aria-label="konto użytkownika"
			>
				<Tooltip placement="left" title={props.userFullName}>
					<span className="ant-avatar account-avatar offset0 ant-avatar-lg ant-avatar-circle">
						{props.userFullName && getInitials(props.userFullName)}
					</span>
				</Tooltip>
			</MenuButton>

			<Menu
				{...menu}
				aria-label="Konto"
				className="ant-menu ant-menu-sub account-menu-vertical"
			>
				<MenuItem
					className="ant-menu-item sub-menu-item"
					{...menu}
					as={Link}
					to="/proposal"
					onClick={menu.hide}
				>
					Wnioski o dostęp
				</MenuItem>

				<MenuItem
					className="ant-menu-item sub-menu-item"
					{...menu}
					as="a"
					// @ts-ignore
					href={`${window._env_.REACT_APP_AUTHORITY_URI}${window._env_.REACT_APP_LINKING_ACCOUNT}`}
				>
					Połącz z kontem
				</MenuItem>
				<MenuItem
					className="ant-menu-item sub-menu-item"
					{...menu}
					onClick={() => {
						userProfileModal.current?.showModal();
					}}
				>
					Mój profil
				</MenuItem>
				<MenuItem
					className="ant-menu-item sub-menu-item"
					{...menu}
					onClick={() => {
						manageNotificationVisibilityModalRef.current?.showModal();
					}}
				>
					Ustawienia powiadomień
				</MenuItem>
				<MenuItem
					className="ant-menu-item sub-menu-item"
					{...menu}
					onClick={() => {
						GlobalUserManager.UserManager()
							.revokeAccessToken()
							.then((x) =>
								GlobalUserManager.UserManager().signoutRedirect()
							);
						InterventionValidationErrors.clear();
					}}
				>
					Wyloguj się
				</MenuItem>
			</Menu>

			<ManageNotificationVisibilityModal
				ref={manageNotificationVisibilityModalRef}
			/>

			<UserProfileModal
				ref={userProfileModal}
				onUserFullNameChange={props.onUserFullNameChange}
			/>
		</>
	);
};

export default AccountMenu;
