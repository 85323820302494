/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/interventionMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a Intervention. */
export class Intervention {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a Intervention.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.InterventionGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.InterventionDetailsDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.InterventionDetailsDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.InterventionDetailsDto>, callback?: msRest.ServiceCallback<Models.InterventionDetailsDto>): Promise<Models.InterventionGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.InterventionGetByIdResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteMethod(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionGetTinyByIdResponse>
   */
  getTinyById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.InterventionGetTinyByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getTinyById(id: string, callback: msRest.ServiceCallback<Models.InterventionDetailsTinyDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getTinyById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.InterventionDetailsTinyDto>): void;
  getTinyById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.InterventionDetailsTinyDto>, callback?: msRest.ServiceCallback<Models.InterventionDetailsTinyDto>): Promise<Models.InterventionGetTinyByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getTinyByIdOperationSpec,
      callback) as Promise<Models.InterventionGetTinyByIdResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionAllResponse>
   */
  all(options?: Models.InterventionAllOptionalParams): Promise<Models.InterventionAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<Models.InterventionListDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.InterventionAllOptionalParams, callback: msRest.ServiceCallback<Models.InterventionListDtoPagedResult>): void;
  all(options?: Models.InterventionAllOptionalParams | msRest.ServiceCallback<Models.InterventionListDtoPagedResult>, callback?: msRest.ServiceCallback<Models.InterventionListDtoPagedResult>): Promise<Models.InterventionAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.InterventionAllResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionCreateResponse>
   */
  create(options?: Models.InterventionCreateOptionalParams): Promise<Models.InterventionCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.InterventionCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.InterventionCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.InterventionCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.InterventionCreateResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.InterventionUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.InterventionUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.InterventionUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionGetActivitiesResponse>
   */
  getActivities(id: string, options?: msRest.RequestOptionsBase): Promise<Models.InterventionGetActivitiesResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getActivities(id: string, callback: msRest.ServiceCallback<Models.ActivityListItemDto[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getActivities(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ActivityListItemDto[]>): void;
  getActivities(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ActivityListItemDto[]>, callback?: msRest.ServiceCallback<Models.ActivityListItemDto[]>): Promise<Models.InterventionGetActivitiesResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getActivitiesOperationSpec,
      callback) as Promise<Models.InterventionGetActivitiesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionCreateOnExistingResponse>
   */
  createOnExisting(options?: Models.InterventionCreateOnExistingOptionalParams): Promise<Models.InterventionCreateOnExistingResponse>;
  /**
   * @param callback The callback
   */
  createOnExisting(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createOnExisting(options: Models.InterventionCreateOnExistingOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  createOnExisting(options?: Models.InterventionCreateOnExistingOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.InterventionCreateOnExistingResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOnExistingOperationSpec,
      callback) as Promise<Models.InterventionCreateOnExistingResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  suspend(options?: Models.InterventionSuspendOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  suspend(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  suspend(options: Models.InterventionSuspendOptionalParams, callback: msRest.ServiceCallback<void>): void;
  suspend(options?: Models.InterventionSuspendOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      suspendOperationSpec,
      callback);
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unsuspend(options?: Models.InterventionUnsuspendOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  unsuspend(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  unsuspend(options: Models.InterventionUnsuspendOptionalParams, callback: msRest.ServiceCallback<void>): void;
  unsuspend(options?: Models.InterventionUnsuspendOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      unsuspendOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionGetFileListResponse>
   */
  getFileList(options?: Models.InterventionGetFileListOptionalParams): Promise<Models.InterventionGetFileListResponse>;
  /**
   * @param callback The callback
   */
  getFileList(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFileList(options: Models.InterventionGetFileListOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getFileList(options?: Models.InterventionGetFileListOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.InterventionGetFileListResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getFileListOperationSpec,
      callback) as Promise<Models.InterventionGetFileListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionGetFileDetailsResponse>
   */
  getFileDetails(options?: Models.InterventionGetFileDetailsOptionalParams): Promise<Models.InterventionGetFileDetailsResponse>;
  /**
   * @param callback The callback
   */
  getFileDetails(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFileDetails(options: Models.InterventionGetFileDetailsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getFileDetails(options?: Models.InterventionGetFileDetailsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.InterventionGetFileDetailsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getFileDetailsOperationSpec,
      callback) as Promise<Models.InterventionGetFileDetailsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionGetFileFlatListResponse>
   */
  getFileFlatList(options?: Models.InterventionGetFileFlatListOptionalParams): Promise<Models.InterventionGetFileFlatListResponse>;
  /**
   * @param callback The callback
   */
  getFileFlatList(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFileFlatList(options: Models.InterventionGetFileFlatListOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  getFileFlatList(options?: Models.InterventionGetFileFlatListOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.InterventionGetFileFlatListResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getFileFlatListOperationSpec,
      callback) as Promise<Models.InterventionGetFileFlatListResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  archive(options?: Models.InterventionArchiveOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  archive(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  archive(options: Models.InterventionArchiveOptionalParams, callback: msRest.ServiceCallback<void>): void;
  archive(options?: Models.InterventionArchiveOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      archiveOperationSpec,
      callback);
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unarchive(options?: Models.InterventionUnarchiveOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  unarchive(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  unarchive(options: Models.InterventionUnarchiveOptionalParams, callback: msRest.ServiceCallback<void>): void;
  unarchive(options?: Models.InterventionUnarchiveOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      unarchiveOperationSpec,
      callback);
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionUploadAttachmentResponse>
   */
  uploadAttachment(options?: Models.InterventionUploadAttachmentOptionalParams): Promise<Models.InterventionUploadAttachmentResponse>;
  /**
   * @param callback The callback
   */
  uploadAttachment(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadAttachment(options: Models.InterventionUploadAttachmentOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  uploadAttachment(options?: Models.InterventionUploadAttachmentOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.InterventionUploadAttachmentResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      uploadAttachmentOperationSpec,
      callback) as Promise<Models.InterventionUploadAttachmentResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  cancel(options?: Models.InterventionCancelOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  cancel(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  cancel(options: Models.InterventionCancelOptionalParams, callback: msRest.ServiceCallback<void>): void;
  cancel(options?: Models.InterventionCancelOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      cancelOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendInterventionToAOTMIT(options?: Models.InterventionSendInterventionToAOTMITOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendInterventionToAOTMIT(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendInterventionToAOTMIT(options: Models.InterventionSendInterventionToAOTMITOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sendInterventionToAOTMIT(options?: Models.InterventionSendInterventionToAOTMITOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      sendInterventionToAOTMITOperationSpec,
      callback);
  }

  /**
   * @param field
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionGetTooltipByNameResponse>
   */
  getTooltipByName(field: string, options?: msRest.RequestOptionsBase): Promise<Models.InterventionGetTooltipByNameResponse>;
  /**
   * @param field
   * @param callback The callback
   */
  getTooltipByName(field: string, callback: msRest.ServiceCallback<Models.TooltipDto>): void;
  /**
   * @param field
   * @param options The optional parameters
   * @param callback The callback
   */
  getTooltipByName(field: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.TooltipDto>): void;
  getTooltipByName(field: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TooltipDto>, callback?: msRest.ServiceCallback<Models.TooltipDto>): Promise<Models.InterventionGetTooltipByNameResponse> {
    return this.client.sendOperationRequest(
      {
        field,
        options
      },
      getTooltipByNameOperationSpec,
      callback) as Promise<Models.InterventionGetTooltipByNameResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  defineTooltip(options?: Models.InterventionDefineTooltipOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  defineTooltip(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  defineTooltip(options: Models.InterventionDefineTooltipOptionalParams, callback: msRest.ServiceCallback<void>): void;
  defineTooltip(options?: Models.InterventionDefineTooltipOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      defineTooltipOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionGetValidationWarningsResponse>
   */
  getValidationWarnings(id: string, options?: msRest.RequestOptionsBase): Promise<Models.InterventionGetValidationWarningsResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getValidationWarnings(id: string, callback: msRest.ServiceCallback<Models.ValidationWarningDto[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getValidationWarnings(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ValidationWarningDto[]>): void;
  getValidationWarnings(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ValidationWarningDto[]>, callback?: msRest.ServiceCallback<Models.ValidationWarningDto[]>): Promise<Models.InterventionGetValidationWarningsResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getValidationWarningsOperationSpec,
      callback) as Promise<Models.InterventionGetValidationWarningsResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.InterventionGetActivitiesSummaryResponse>
   */
  getActivitiesSummary(id: string, options?: msRest.RequestOptionsBase): Promise<Models.InterventionGetActivitiesSummaryResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getActivitiesSummary(id: string, callback: msRest.ServiceCallback<Models.ActivitiesSummaryDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getActivitiesSummary(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ActivitiesSummaryDto>): void;
  getActivitiesSummary(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ActivitiesSummaryDto>, callback?: msRest.ServiceCallback<Models.ActivitiesSummaryDto>): Promise<Models.InterventionGetActivitiesSummaryResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getActivitiesSummaryOperationSpec,
      callback) as Promise<Models.InterventionGetActivitiesSummaryResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Intervention/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.InterventionDetailsDto
    },
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "rizp/Intervention/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getTinyByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Intervention/{id}/tiny",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.InterventionDetailsTinyDto
    },
    default: {}
  },
  serializer
};

const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Intervention",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0,
    Parameters.filter2,
    Parameters.labels0,
    Parameters.selectedIds
  ],
  responses: {
    200: {
      bodyMapper: Mappers.InterventionListDtoPagedResult
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "rizp/Intervention",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateInterventionCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "rizp/Intervention",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateInterventionCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getActivitiesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Intervention/{id}/activities",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ActivityListItemDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const createOnExistingOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "rizp/Intervention/createInterventionOnExisting",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateInterventionOnExistingCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const suspendOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "rizp/Intervention/suspend",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SuspendInterventionCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const unsuspendOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "rizp/Intervention/unsuspend",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UnsuspendInterventionCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getFileListOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Intervention/export/list",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.labels1,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType,
    Parameters.filter3
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const getFileDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Intervention/export/details",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.labels1,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType,
    Parameters.filter3
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const getFileFlatListOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Intervention/export/flat",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.labels1,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType,
    Parameters.isTechnicalVersion,
    Parameters.filter3
  ],
  responses: {
    202: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const archiveOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "rizp/Intervention/archive",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ArchiveInterventionCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const unarchiveOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "rizp/Intervention/unarchive",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UnarchiveInterventionCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadAttachmentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "rizp/Intervention/uploadAttachment",
  formDataParameters: [
    Parameters.id3,
    Parameters.file,
    Parameters.fileCategoryDictionaryValueId,
    Parameters.fileCategoryDictionaryOtherValue
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const cancelOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "rizp/Intervention/cancel",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CancelInterventionCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const sendInterventionToAOTMITOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "rizp/Intervention/sendToAOTMIT",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.SendInterventionCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getTooltipByNameOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Intervention/tooltip/{field}",
  urlParameters: [
    Parameters.field
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TooltipDto
    },
    default: {}
  },
  serializer
};

const defineTooltipOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "rizp/Intervention/tooltip",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateTooltipCommand
  },
  responses: {
    201: {},
    default: {}
  },
  serializer
};

const getValidationWarningsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Intervention/warnings/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ValidationWarningDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getActivitiesSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "rizp/Intervention/{id}/activitiesSummary",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ActivitiesSummaryDto
    },
    default: {}
  },
  serializer
};
