import { Col } from 'antd';
import { InterventionPathContextType } from 'components/intervention/context/InterventionPathContext';
import React from 'react';
import {
	InterventionCreatedAt,
	InterventionNumber,
	InterventionOwner,
	InterventionPath,
	InterventionRealizationDate,
	InterventionStatus,
} from '../InterventionFields';

const LegislationInfoFieldGroup: React.FC<InterventionPathContextType> = (
	props
) => {
	return (
		<>
			<Col span={12}>{InterventionPath(props)}</Col>
			<Col span={12}>{InterventionStatus(props)}</Col>
			<Col span={12}>{InterventionOwner(props)}</Col>
			<Col span={12}>{InterventionRealizationDate(props)}</Col>
			<Col span={12}>{InterventionNumber(props)}</Col>
			<Col span={12}></Col>
			<Col span={12}>{InterventionCreatedAt(props)}</Col>
		</>
	);
};

export default LegislationInfoFieldGroup;
