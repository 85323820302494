import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { TerritorialUnitDto } from '@services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

const territorialUnitTableColumns: (
	values: ProfiBazaAPIModels.AreaValueDto[]
) => ColumnProps<TerritorialUnitDto>[] = (values) => [
	{
		sorter: true,
		dataIndex: 'name',
		key: 'name',
		title: 'Nazwa jednostki',
		render: (text: any, record: any) =>
			values?.filter((val) => val.terytCode === record.tercCode)
				.length ? (
				<span className="color--light-blue">{text}</span>
			) : (
				<>{text}</>
			),
	},
];

export default territorialUnitTableColumns;
