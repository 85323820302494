import './UserManual.less';

import { BookOutlined } from '@ant-design/icons';
import Authorized from '@authorization/authorized';
import {
	UserManualAdministrativeType,
	UserManualType,
} from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { Tooltip } from 'antd';
import React, { Fragment, useRef } from 'react';
import { Menu, MenuButton, MenuItem, useMenuState } from 'reakit/Menu';

import ViewUserManualModal, {
	IViewUserManualModal,
} from './modals/ViewUserManualModal';

interface IProps {}

const UserManualMenu: React.FC<IProps> = (props) => {
	const menu = useMenuState();

	const viewUserManualModalRef = useRef<
		IViewUserManualModal
	>() as React.RefObject<IViewUserManualModal>;

	return (
		<Fragment>
			<Tooltip placement="left" title="Instrukcje użytkownika">
				<MenuButton
					{...menu}
					className="ant-btn offset0 ant-dropdown-trigger ant-btn-link ant-btn-circle ant-btn-lg"
					aria-label="instrukcje użytkownika"
				>
					<span className="ant-avatar account-avatar offset0 ant-avatar-lg ant-avatar-circle manual-menu-item">
						<BookOutlined />
					</span>
				</MenuButton>
			</Tooltip>
			<Menu
				{...menu}
				aria-label="Konto"
				className="ant-menu ant-menu-sub account-menu-vertical"
			>
				<MenuItem
					className="ant-menu-item sub-menu-item"
					{...menu}
					onClick={() => {
						viewUserManualModalRef.current?.showModal({
							manualType: UserManualType.Main,
						});
					}}
				>
					Instrukcja ogólna systemowa
				</MenuItem>
				<Authorized
					permissions={[
						ProfiBazaAPIModels.Permission
							.AdminRolesAssignmentGlobal,
					]}
				>
					<MenuItem
						className="ant-menu-item sub-menu-item"
						{...menu}
						onClick={() => {
							viewUserManualModalRef.current?.showModal({
								manualType: UserManualType.Administrative,
								userManualAdministrativeType:
									UserManualAdministrativeType.Global,
							});
						}}
					>
						Instrukcja administracyjna globalna
					</MenuItem>
				</Authorized>

				<Authorized
					permissions={[
						ProfiBazaAPIModels.Permission.AdminRolesAssignmentLocal,
					]}
				>
					<MenuItem
						className="ant-menu-item sub-menu-item"
						{...menu}
						onClick={() => {
							viewUserManualModalRef.current?.showModal({
								manualType: UserManualType.Administrative,
								userManualAdministrativeType:
									UserManualAdministrativeType.Local,
							});
						}}
					>
						Instrukcja administracyjna lokalna
					</MenuItem>
				</Authorized>
			</Menu>
			<ViewUserManualModal
				ref={viewUserManualModalRef}
				isPreview={false}
			/>
		</Fragment>
	);
};

export default UserManualMenu;
