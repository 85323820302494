import arrayHelpers from '@helper/arrayHelpers';
import { DictionaryValueItemDto } from 'services/src/models';

export class DictionaryLevelHistory {
	private history: Map<number | undefined, DictionaryValueItemDto[]>;
	levelStack: Array<number | undefined>;

	constructor() {
		this.history = new Map<number, DictionaryValueItemDto[]>();
		this.levelStack = new Array<number>();
	}

	public addLevel(
		levelId: number | undefined,
		children: DictionaryValueItemDto[]
	) {
		this.levelStack.push(levelId);
	}

	public switchToLevel(level: number) {
		if (level < 0 || level >= this.levelStack.length)
			throw new Error('Wskazany poziom słownika nie istnieje');

		this.levelStack = this.levelStack.slice(0, level + 1);
	}

	public hasCachedValues(id: number | undefined) {
		return this.history.has(id);
	}

	public getCachedValues(id: number | undefined): DictionaryValueItemDto[] {
		if (!this.hasCachedValues(id)) return [];

		return this.history.get(id)!;
	}

	public cacheValues(
		id: number | undefined,
		children: DictionaryValueItemDto[]
	) {
		this.history.set(id, children);
	}

	public removeCachedValue(id: number | undefined) {
		this.history.delete(id);
	}

	/**
	 * @description Get collection of values from the ancestor level
	 * @param lastSelectedValueId An optional parameter which determines that the element under
	 * the specified index is moved at the 1st position of the result array
	 */
	public getPreviousLevel(
		lastSelectedValueId: number | undefined = undefined
	): DictionaryValueItemDto[] {
		if (this.levelStack.length <= 1) return [];

		const lvlId = this.levelStack[this.levelStack.length - 2];
		const items = this.history.get(lvlId) ?? [];
		const previousLevelItems = [...items];
		if (lastSelectedValueId) {
			const lastSelectedValueIndex = previousLevelItems.findIndex(
				(val) => val.id === lastSelectedValueId
			);
			if (lastSelectedValueIndex > -1) {
				arrayHelpers.moveArrayElement(
					previousLevelItems,
					lastSelectedValueIndex,
					0
				);
			}
		}
		return previousLevelItems;
	}

	public getLevelValues(level: number) {
		const lvlId = this.levelStack[level];

		return this.history.get(lvlId) ?? [];
	}

	public reset() {
		this.history = new Map<number, DictionaryValueItemDto[]>();
		this.levelStack = new Array<number>();
	}
}
