export type ActivityTabs =
	| 'main'
	| 'populationHealth'
	| 'population'
	| 'implementingCompanies'
	| 'sponsoringCompanies'
	| 'ikp';

export const activityFields: Record<ActivityTabs, string[]> = {
	main: [
		'name',
		'activityNumber',
		'activityCategoryDictionaryValueId',
		'nzpTaskNumberDictionaryValues',
		'publicHealthTaskDictionaryValues',
		'prpzDictionaryValues',
		'description',
		'comment',
		'topic',
		'numberOfRecipients',
		'numberOfPersonAction',
		'realizationDate',
		//Backend fields
		'dateFrom',
		'dateTo',
	],
	populationHealth: [
		'chosenPopulationHealthsDictionaryValues',
		'careAreasDictionaryValues',
		'diseasesMortalityPrevalencesDictionaryValues',
	],
	population: [
		'genderDictionaryValueId',
		'targetPopulations',
		'interventionLocations',
		'healthConditions',
		'socialExclusions',
		'otherSpecialFeatures',
		'totalRecipients',
		'ageUnit',
		'minAge',
		'maxAge',
		'activityPopulations',
	],
	implementingCompanies: [],
	sponsoringCompanies: [
		'sponsorCompaniesId',
		'financingSourceDictionaryValues',
		'realizationCosts',
		'plannedCosts',
		'isMainSubject',
		'sponsoringCompanies',
	],
	ikp: [
		'ikp', //for backend validation
		'ikpRecrutationDate',
		'ikpStateDictionaryValueId',
		'ikpIncludeCriteria',
		'ikpExcludeCriteria',
		'ikpBenefits',
		'ikpSourceOfKnowledge',
		'publishedInIkp',
	],
};
