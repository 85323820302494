import { ajaxCatch } from 'helper/api';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import DictionariesValuesTable from './table/DictionariesValuesTable';
import DictionariesVersionsTable from './table/DictionariesVersionsTable';

interface IProps {
	dictionaryId: number;
	dictionaryVersionId?: number;
}

interface IState {
	dictionary: ProfiBazaAPIModels.DictionaryDto | undefined;
	loading: boolean;
}

const DictionaryDetails: React.FC<IProps> = (props) => {
	const [state, setState] = useState<IState>({
		dictionary: undefined,
		loading: true,
	});

	const history = useHistory();

	const [previousAbort, setPreviousAbort] = useState<
		AbortController | undefined
	>();

	useEffect(() => {
		if (previousAbort) {
			previousAbort.abort();
		}
		const abortController = new AbortController();
		setPreviousAbort(abortController);
		refresh(abortController.signal);

		return () => {
			abortController.abort();
		};
	}, [props.dictionaryId]);

	const refresh = (abortSignal?: AbortSignal) => {
		setState({
			...state,
			loading: true,
		});
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) =>
					api.dictionary.getById(props.dictionaryId, { abortSignal })
				)
				.then((response: ProfiBazaAPIModels.DictionaryDto) => {
					setState({
						...state,
						dictionary: response!,
						loading: false,
					});
				})
		);
	};

	const handleVersionsTableChanges = () => {
		if (props.dictionaryId) {
			history.push(`/dictionaries/${props.dictionaryId}`);
		}
	};

	return (
		<>
			<DictionariesVersionsTable
				dictionaryId={props.dictionaryId}
				items={state.dictionary?.versions}
				codeName={state.dictionary?.codeName}
				loading={state.loading}
				refresh={refresh}
				onChanges={handleVersionsTableChanges}
			/>

			{props.dictionaryVersionId && (
				<DictionariesValuesTable
					tree={state.dictionary?.isHierarchical ?? false}
					isCodeNameInValuesRequired={
						state.dictionary?.isCodeNameInValuesRequired ?? false
					}
					dictionaryVersionId={props.dictionaryVersionId}
					dictionaryVersion={state.dictionary?.versions?.find(
						(ver) => ver.id === props.dictionaryVersionId
					)}
				/>
			)}
		</>
	);
};

export default observer(DictionaryDetails);
