import { ForkOutlined } from '@ant-design/icons';
import { Badge, Button, Modal } from 'antd';
import { useRootData } from 'hooks/hook';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { TerritorialUnitType, TerritoryDto } from 'services/src/models';
import { IGridStore } from 'stores/GridStore';

import DownshiftSearch from '../../DownshiftSearch';

interface IProps {
	gridName: string;
	handleSearch: () => void;
}

export const terytFilterColumn = 'areas';

const GridTerytFilters: React.FC<IProps> = (props) => {
	const { gridName, handleSearch } = props;

	const [visible, setVisible] = useState(false);
	const [value, setValue] = useState<string | undefined>(undefined);

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	const clear = () => {
		setValue(undefined);

		gridStore.clearColumnFilter(gridName, terytFilterColumn);

		handleSearch();
		setVisible(false);
	};

	const search = (code: string | undefined) => {
		setValue(code);

		if (code === undefined) return;

		gridStore.saveColumnFilter(
			gridName,
			terytFilterColumn,
			code,
			'contains'
		);
		handleSearch();
		setVisible(false);
	};

	const renderSearchButton = () => {
		const searchButton = (
			<ProfiBazaTooltip title="Filtruj po obszarze" placement="topRight">
				<Button
					aria-label="Filtruj po obszarze"
					type="ghost"
					size="small"
					shape="circle"
					icon={<ForkOutlined />}
					onClick={() => setVisible(true)}
				/>
			</ProfiBazaTooltip>
		);

		return value ? (
			<Badge status="warning" dot>
				{searchButton}
			</Badge>
		) : (
			searchButton
		);
	};

	const searchedItem = (territory: TerritoryDto) => {
		search(territory.simcCode ?? territory.tercCode);
	};

	const itemToString = (item: any): string => {
		if (!item) return '';

		let result: string = item?.voivodeship ?? '';

		if (item?.territorialUnitType === TerritorialUnitType.Voivodeship)
			return result;
		else {
			const chunks = [item.county, item.community];

			if (item.simcCode) {
				chunks.push(item.name);
			}

			chunks.forEach((chunk) => {
				if (chunk) {
					result += ` > ${chunk}`;
				}
			});
			return result;
		}
	};

	const itemToDisplayValue = (item: any): string => {
		return item?.name ?? '';
	};

	const getTerritory = async (
		page: number,
		searchCriteria: string
	): Promise<any> => {
		return getProfiBazaApiClient().then((api) => {
			return api.teryt.searchTerritories({
				page: page,
				filters: `(name|tercCode)@=*${searchCriteria}`,
			});
		});
	};

	return (
		<>
			{renderSearchButton()}
			<Modal
				className="modal--search"
				visible={visible}
				centered
				closable={false}
				maskClosable
				okText="Wyczyść"
				okButtonProps={{
					type: 'default',
				}}
				onOk={clear}
				cancelText="Zamknij"
				onCancel={() => setVisible(false)}
			>
				<DownshiftSearch
					onChange={searchedItem}
					defaultValue={
						gridStore.getColumnFilter(gridName, terytFilterColumn)
							?.value!
					}
					itemToString={itemToString}
					itemToDisplayValue={itemToDisplayValue}
					getData={getTerritory}
				/>
			</Modal>
		</>
	);
};

export default observer(GridTerytFilters);
