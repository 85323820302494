import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<
	ProfiBazaAPIModels.InterventionCommentType,
	string
>([
	[ProfiBazaAPIModels.InterventionCommentType.IMPORTANT, 'ważny'],
	[ProfiBazaAPIModels.InterventionCommentType.PRIVATE, 'Informacyjny w ramach podmiotu'],
	[ProfiBazaAPIModels.InterventionCommentType.PUBLIC, 'informacyjny publiczny'],
]);

export const map = (
	value: ProfiBazaAPIModels.InterventionCommentType
): string => mappings.get(value) ?? '';
