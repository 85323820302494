import './NotiificationSidebar.less';

import { BellOutlined } from '@ant-design/icons';
import AccessibleDrawer from '@components/shared/modals/AccessibleDrawer';
import { useFocusTrap } from '@hooks/accessibility/useFocusTrapVisibleHook';
import { useDrawer } from '@hooks/useDrawerHook';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Badge, Button, Divider, Drawer } from 'antd';
import { useRootData } from 'hooks/hook';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import { HubActions } from '../hubs/hubEvents';
import { INotificationStore } from '../notificationStore';
import useNotificationHub from './hooks/useNotificationHubHook';
import useReadNotifications from './hooks/useReadNotificationsHook';
import useUnreadNotifications from './hooks/useUnreadNotificationsHook';
import { NotificationContext } from './NotificationContext';
import NotificationOccurrenceList from './notificationOccurrenceList';
import { uniqueeNotifications } from './SidebarUtils';

interface IProps {}

const NotificationSidebar: React.FC<IProps> = (props) => {
	const [visible, showDrawer, closeDrawer] = useDrawer();
	const [focusTrapActive] = useFocusTrap(visible);

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const [abortController, setAbortController] = useState<AbortController>(
		new AbortController()
	);

	const notificationHub = useNotificationHub(accountDetailsStore);

	const unreadNotifications = useUnreadNotifications(abortController.signal);

	const [showRead, setShowRead] = useState<boolean>(false);
	const readNotifications = useReadNotifications(abortController.signal);

	const notificationStore: INotificationStore = useRootData(
		(store) => store.notificationStore
	);

	React.useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;
		notificationStore.initialize((result: any) => {}, signal);

		return () => {
			abortController.abort();
		};
	}, []);

	const handleShowRead = () => {
		if (showRead) {
			setShowRead(false);
			readNotifications.setData([]);
		} else {
			setShowRead(true);
			readNotifications.fetchData();
		}
	};

	useEffect(() => {
		unreadNotifications.fetchData();

		return () => {
			abortController.abort();
		};
	}, []);

	useEffect(() => {
		if (notificationHub) {
			notificationHub.start().catch((err) => console.error(err));

			notificationHub.on(
				HubActions.RECEIVE_NOTIFICATION,
				(message: ProfiBazaAPIModels.UserNotificationDTO) => {
					unreadNotifications.setTotalUnread(
						(prevTotal) => prevTotal + 1
					);

					unreadNotifications.setData((prevData) =>
						uniqueeNotifications([message, ...prevData])
					);
				}
			);
		}
	}, [notificationHub]);

	useEffect(() => {
		if (notificationHub) {
			notificationHub.off(HubActions.MARK_AS_READ);

			notificationHub.on(
				HubActions.MARK_AS_READ,
				(message: ProfiBazaAPIModels.UserNotificationDTO) => {
					unreadNotifications.setTotalUnread(
						(prevTotal) => prevTotal - 1
					);

					unreadNotifications.setData((prevData) =>
						prevData.filter((n) => n.id !== message.id)
					);

					if (showRead) {
						readNotifications.setData((prevData) =>
							uniqueeNotifications([message, ...prevData])
						);
					}
				}
			);
		}
	}, [notificationHub, showRead]);

	return (
		<>
			<ProfiBazaTooltip placement="bottom" title="Powiadomienia">
				<Badge
					count={unreadNotifications.totalUnread > 0 ? unreadNotifications.totalUnread : 0}
					className="notification-badge"
				>
					<Button
						aria-label="powiadomienia"
						size="large"
						shape="circle"
						onClick={showDrawer}
						icon={<BellOutlined />}
					/>
				</Badge>
			</ProfiBazaTooltip>
			<AccessibleDrawer
				title="Powiadomienia"
				placement="right"
				className="notification-drawer"
				width={500}
				closable={false}
				onClose={closeDrawer}
				visible={visible}
				destroyOnClose
				focusTrapActive={focusTrapActive}
			>
				<Divider orientation="left">Nowe</Divider>
				<NotificationContext.Provider
					value={{
						markAsRead: unreadNotifications.markAsRead,
						deactivateSameKind:
							unreadNotifications.deactivateSameKind,
					}}
				>
					<NotificationOccurrenceList
						unread
						data={unreadNotifications.data}
						loading={unreadNotifications.loading}
						handleInfiniteOnLoad={
							unreadNotifications.handleInfiniteOnLoad
						}
						hasNextPage={unreadNotifications.hasNextPage}
						initialLoad
					/>
				</NotificationContext.Provider>

				{!unreadNotifications.hasNextPage && (
					<>
						<Divider orientation="left">
							<Button
								type="text"
								className="text-white notification-header"
								onClick={handleShowRead}
								aria-label={
									showRead
										? 'Ukryj przeczytane'
										: 'Pokaż przeczytane'
								}
							>
								{showRead
									? 'Ukryj przeczytane'
									: 'Pokaż przeczytane'}
							</Button>
						</Divider>
						{showRead && (
							<NotificationOccurrenceList
								data={readNotifications.data}
								loading={readNotifications.loading}
								handleInfiniteOnLoad={
									readNotifications.handleInfiniteOnLoad
								}
								hasNextPage={readNotifications.hasNextPage}
							/>
						)}
					</>
				)}
			</AccessibleDrawer>
		</>
	);
};

export default observer(NotificationSidebar);
