import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import  * as StatementStatus from './StatementStatus';

const statementsStatusAmended = () => {
    var statuses = StatementStatus.mapings;
	statuses.delete(ProfiBazaAPIModels.StatementStatus.INVERIFICATION)
	statuses.delete(ProfiBazaAPIModels.StatementStatus.DUPLICATE)
    statuses.delete(ProfiBazaAPIModels.StatementStatus.TOIMPROVE)
	statuses.delete(ProfiBazaAPIModels.StatementStatus.TOCOMPLETE)
	statuses.delete(ProfiBazaAPIModels.StatementStatus.INCORRECT)
	return statuses;
}

export const mappings = statementsStatusAmended()

export const map = (
	value: ProfiBazaAPIModels.StatementStatus
): string => mappings.get(value) ?? '';