import { SubjectCommentType } from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';

export class AddSubjectCommentDto {
	subjectId?: string;
	fieldName?: string;
	column?: string;
	row?: string;
	description?: string;
	commentType?: SubjectCommentType;

	constructor(model: ProfiBazaAPIModels.CreateSubjectCommentCommand) {
		this.subjectId = model.subjectId;
		this.fieldName = model.fieldName;
		this.description = model.description;
		this.commentType = model.commentType;
	}

	static toCommand(
		model: AddSubjectCommentDto
	): ProfiBazaAPIModels.CreateSubjectCommentCommand {
		const command: ProfiBazaAPIModels.CreateSubjectCommentCommand = {
			subjectId: model.subjectId,
			fieldName: model.fieldName,
			description: model.description,
			commentType: model.commentType,
		};
		return command;
	}
}
