import {
	UserManager,
	UserManagerSettings,
	WebStorageStateStore,
} from 'oidc-client';
import urljoin from 'url-join';
import { baseAppUrl } from '@helper/api';
export class GlobalUserManager {
	private static _userManager: UserManager | undefined = undefined;

	public static Init(): void {
		GlobalUserManager._userManager = createUserManagerWithSettings();
		GlobalUserManager._userManager.events.addSilentRenewError(()=>{
			this._userManager?.signoutRedirect();
		})
	}

	public static UserManager = (): UserManager => {
		return GlobalUserManager._userManager!;
	};
}

function createUserManagerWithSettings(): UserManager {
	var config: UserManagerSettings = {
		// @ts-ignore
		authority: window._env_.REACT_APP_AUTHORITY_URI,
		// @ts-ignore
		client_id: window._env_.REACT_APP_CLIENT_ID,
		// @ts-ignore
		client_secret: window._env_.REACT_APP_SECRET,
		redirect_uri: urljoin(baseAppUrl, '/authCallback'),
		response_type: 'id_token token',
		scope: 'profile openid profibaza-api.full-access',
		post_logout_redirect_uri: baseAppUrl.replace(/\$/, ''),
		//loadUserInfo: true,
		automaticSilentRenew: true,
		accessTokenExpiringNotificationTime: 120,
		silent_redirect_uri: urljoin(baseAppUrl, '/silent-renew.html'),
		userStore: new WebStorageStateStore({ store: window.localStorage })
	};

	return new UserManager(config);
}
