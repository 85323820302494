import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mapings = new Map<ProfiBazaAPIModels.SubjectStatus, string>([
	[ProfiBazaAPIModels.SubjectStatus.NEW, 'nowy'],
	[ProfiBazaAPIModels.SubjectStatus.MODIFIED, 'zmodyfikowany'],
	[ProfiBazaAPIModels.SubjectStatus.DELETED, 'usunięty'],
	[ProfiBazaAPIModels.SubjectStatus.MERGED, 'scalony'],
]);

export const map = (value: ProfiBazaAPIModels.SubjectStatus): string =>
	mapings.get(value) ?? '';
