import { Button, Card, Col, Divider, Row, Space } from 'antd';
import { Formik, FormikActions, FormikProps } from 'formik';
import React, { useRef } from 'react';

interface IProps<T extends {}> {
	data?: T;
	visible: boolean;
	editable: boolean;
	visibleButtons: boolean;
	validationScheme: any;
	validateOnBlur?: boolean;
	onSubmit: (values: T, formikActions: FormikActions<T>) => void;
	handleClose: () => void;
	content: (item?: T, formikActions?: FormikActions<T>, previewMode?: boolean) => JSX.Element;
	cleanSideEffects?: { key: string; value: any }[];
	previewMode: boolean;
}

export const ActivityDetailsFormCard = <T extends {}>(props: IProps<T>) => {
	const formikRef = useRef<Formik<T>>(null);

	function clearFrom(): void  {
		formikRef?.current?.resetForm();
				if (props.cleanSideEffects && props.data) {
					props.cleanSideEffects.forEach((sideEffect) => {
						const field = formikRef?.current?.fields[sideEffect.key];
						if(field && (!field?.props["readOnly"] && !field?.props["disabled"]))
						{
							formikRef?.current?.setFieldValue(
								sideEffect.key,
								sideEffect.value
							);
						}
					});
				}
	}

	const clearButton = (
		<Button
			type="default"
			onClick={() => clearFrom()}
		>
			Wyczyść
		</Button>
	);
	const submitButton = (
		<Button
			type="primary"
			htmlType="submit"
			onClick={() => {
				formikRef?.current?.submitForm();
			}}
		>
			Zatwierdź
		</Button>
	);

	const closeButton = (
		<Button type="default" onClick={() => props.handleClose()}>
			Zamknij
		</Button>
	);

	const cancelButton = (
		<Button
			type="default"
			onClick={() => {
				clearFrom();
				props.handleClose();
			}}
		>
			Anuluj
		</Button>
	);

	const getButtonsByMode = () => {
		if (props.previewMode) {
			return [closeButton];
		}
		return [cancelButton, clearButton, submitButton];
	};

	return (
		<>
			<Formik
				initialValues={props.data || ({} as T)}
				validationSchema={props.validationScheme}
				validateOnChange={false}
				validateOnBlur={
					props.validateOnBlur !== undefined
						? props.validateOnBlur
						: true
				}
				enableReinitialize
				onSubmit={(values: T, actions: FormikActions<T>) => {
					props.onSubmit(values, actions);
					props.handleClose();
					actions.resetForm();
				}}
				ref={formikRef}
				render={(formik: FormikProps<any>) => (
					<>
						{props.visible && (
							<>
								{props.visibleButtons ? (
									<Card>
										{props.content(props.data, formik, props.previewMode)}
										<Divider style={{ margin: '8px 0' }} />
										{props.visible ? (
											<Col>
												<Row justify="end">
													<Space>
														{getButtonsByMode()}
													</Space>
												</Row>
											</Col>
										) : undefined}
									</Card>
								) : (
									props.content(props.data, formik)
								)}
							</>
						)}
					</>
				)}
			/>
		</>
	);
};

export default ActivityDetailsFormCard;
