import { MessageOutlined } from '@ant-design/icons';
import {
	InterventionCommentDto,
	InterventionCommentsCommand,
} from '@components/intervention/comments/commands/InterventionCommentsCommand';
import { InterventionCommentSidebar } from '@components/intervention/comments/InterventionCommentSidebar';
import { IInterventionCommentStore } from '@components/intervention/comments/store/InterventionCommentStore';
import { useRootData } from '@hooks/hook';
import {
	InterventionCommentStatus,
	InterventionPath,
	OpinionAOTMiTType,
} from '@services/src/models';
import { Badge, Button, Space } from 'antd';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useState } from 'react';

export interface IInterventionData {
	id: string;
	subjectId: string;
	interventionPath: InterventionPath;
	opinionAOTMiT?: OpinionAOTMiTType;
}

interface IProps {
	interventionData: IInterventionData;
	label: string;
	readonlyMode: boolean;
}

const countUnprocessedComments = (
	commentData: InterventionCommentsCommand
): number => {
	if (commentData?.comments) {
		return commentData.comments.filter(
			(x: InterventionCommentDto) =>
				x.status !== InterventionCommentStatus.PROCESSED
		).length;
	}
	return 0;
};

export const InterventionCommentButton: React.FC<IProps> = (props) => {
	const interventionCommentStore: IInterventionCommentStore = useRootData(
		(store) => store.interventionCommentStore
	);

	interventionCommentStore.comments.observe((value) => {
		setCommentData(value.newValue);
	});

	const [commentData, setCommentData] = useState<InterventionCommentsCommand>(
		interventionCommentStore.comments.get()
	);

	const [showComments, setShowComments] = useState<boolean>(false);

	return (
		<Space>
			<ProfiBazaTooltip title={props.label}>
				<Badge
					count={countUnprocessedComments(commentData!)}
					className="comments-badge"
				>
					<Button
						aria-label={props.label}
						shape="circle"
						className="manual-menu-item"
						onClick={() => setShowComments(!showComments)}
						icon={<MessageOutlined />}
					/>
				</Badge>
			</ProfiBazaTooltip>
			<InterventionCommentSidebar
				interventionData={props.interventionData}
				commentData={commentData!}
				loadComments={interventionCommentStore.setComments}
				showComments={showComments}
				readonlyMode={props.readonlyMode}
			/>
		</Space>
	);
};
