import Authorized from '@authorization/authorized';
import { ajaxByUser, ajaxCatch } from '@helper/api';
import { Button, Col, Row, Select, Space } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import BackButtons from 'components/shared/buttons/BackButtons';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { CenteredRow } from 'layout/CenteredRow';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import * as RoleCategoryMapper from 'services/mappers/RoleCategory';
import * as RoleTypeMapper from 'services/mappers/RoleType';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import {
	FErrorMessage,
	FFieldLabel,
	FInput,
	FSelect,
} from '../../forms/FormikFormItems';
import { showNotExpectedErrorModal } from '../../layout/Modals';
import { PageHeaderWithContent } from '../../layout/PageHeaderWithContent';
import PrimaryCard from '../../layout/PrimaryCard';
import ProfiBazaTable from '../../layout/ProfiBazaTable';
import { EditRoleValidationSchema } from './RoleValidation';

const { Option } = Select;
interface IProps {
	roleId: any;
}

interface IState {
	data: ProfiBazaAPIModels.UpdateRoleCommand | null;
	loaded: boolean;
	permissions: ProfiBazaAPIModels.PermissionDto[];
	changed: boolean;
}

export default class RoleEdit extends React.Component<
	RouteComponentProps & IProps,
	IState
> {
	constructor(props: RouteComponentProps & IProps) {
		super(props);

		this.state = {
			data: null,
			loaded: false,
			permissions: [],
			changed: false,
		};
	}

	private columns: ColumnProps<ProfiBazaAPIModels.PermissionDto>[] = [
		{
			sorter: true,
			dataIndex: 'name',
			key: 'name',
			title: 'Uprawnienie',
		},
	];

	private refresh() {
		this.getResults();
	}

	private getResults() {
		this.setState({ ...this.state, loaded: false });
		getProfiBazaApiClient()
			.then((api) =>
				api.permission.all().then((data) => {
					this.setState((state) => ({
						...state,
						permissions: data,
						loaded: true,
					}));
				})
			)
			.catch((e) => {
				showNotExpectedErrorModal(e);
				this.setState({ ...this.state, loaded: true });
			});
	}

	public componentDidMount() {
		ajaxCatch(() =>
			getProfiBazaApiClient()
				.then((api) => api.role.getById(this.props.roleId))
				.then((role: ProfiBazaAPIModels.RoleDto) => {
					this.setState({
						data: role,
					});
				})
		);

		this.refresh();
	}

	public send(
		values: ProfiBazaAPIModels.UpdateRoleCommand,
		actions: FormikActions<ProfiBazaAPIModels.UpdateRoleCommand>
	) {
		ajaxByUser(
			'Zaktualizowano  rolę',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.role.update({ body: values })
				),
			() => {
				actions.setSubmitting(false);
				this.props.history.push(`/admin/roles`);
			},
			(errors) => {
				actions.setErrors(errors);
			}
		).then(() => actions.setSubmitting(false));
	}

	public render() {
		return (
			<>
				<CenteredRow>
					<Col span={23}>
						<PageHeaderWithContent
							title="EDYCJA ROLI"
							goBack="/admin/roles"
						/>
						{this.state.data && (
							<Formik
								initialValues={this.state.data}
								validationSchema={EditRoleValidationSchema}
								validateOnChange={false}
								validateOnBlur={true}
								onSubmit={(values, actions) => {
									this.send(values, actions);
								}}
								render={(
									props: FormikProps<
										ProfiBazaAPIModels.UpdateRoleCommand
									>
								) => (
									<>
										<PrimaryCard>
											<CenteredRow>
												<Col md={8}>
													<FFieldLabel
														label="Nazwa roli"
														for="name"
													/>
													<Field
														label="Nazwa roli"
														component={FInput}
														name="name"
														changeData={(
															value: any
														) => {
															this.setState({
																...this.state,
																changed: true,
															});
														}}
													/>
												</Col>
												<Col md={8}>
													<FFieldLabel
														label="Typ"
														for="type"
													/>
													<Field
														component={FSelect}
														name="type"
														changeData={(
															value: any
														) => {
															this.setState({
																...this.state,
																changed: true,
															});
														}}
													>
														{Array.from(
															RoleTypeMapper.mappings.entries()
														).map((x, i) => (
															<Option
																key={i}
																value={x[0]}
															>
																{x[1]}
															</Option>
														))}
													</Field>
												</Col>
												<Col md={8}>
													<FFieldLabel
														label="Zasięg"
														for="category"
													/>
													<Field
														component={FSelect}
														name="category"
														changeData={(
															value: any
														) => {
															this.setState({
																...this.state,
																changed: true,
															});
														}}
													>
														{Array.from(
															RoleCategoryMapper.mappings.entries()
														).map((x, i) => (
															<Option
																key={i}
																value={x[0]}
															>
																{x[1]}
															</Option>
														))}
													</Field>
												</Col>
											</CenteredRow>
											<Row>
												{
													<FErrorMessage
														errors={props.errors}
														name="permissions"
													/>
												}
												<Col xs={24}>
													{this.state.data !=
														null && (
														<ProfiBazaTable
															rowSelection={{
																onChange: (
																	selectedRowKeys: ProfiBazaAPIModels.Permission[],
																	selectedRows: ProfiBazaAPIModels.PermissionDto[]
																) => {
																	props.setFieldValue(
																		'permissions',
																		selectedRowKeys
																	);

																	props.setFieldTouched(
																		'permissions',
																		true
																	);

																	this.setState(
																		{
																			...this
																				.state,
																			changed: true,
																		}
																	);
																},
																getCheckboxProps: (
																	record: any
																) => ({
																	name:
																		record.name,
																}),
																selectedRowKeys:
																	props.values
																		.permissions,
															}}
															columns={
																this.columns
															}
															loading={
																!this.state
																	.loaded
															}
															rowKey={(
																r: ProfiBazaAPIModels.PermissionDto
															) => r.permission!}
															dataSource={
																this.state
																	.permissions
															}
															pagination={false}
														/>
													)}
												</Col>
											</Row>
										</PrimaryCard>
										<Row>
											<Space>
												<BackButtons
													link={'/admin/roles'}
													onClick={() => {
														props.submitForm();
													}}
													size="large"
													disabled={
														props.isSubmitting
													}
													backToCurrentPlace={true}
													visible={this.state.changed}
													aria-label="Anuluj"
												/>
												<Authorized
													permissions={[
														ProfiBazaAPIModels
															.Permission
															.AdminRolesModifications,
													]}
												>
													<Button
														type="primary"
														size="large"
														disabled={
															props.isSubmitting
														}
														onClick={() => {
															props.submitForm();
														}}
														aria-label="Zapisz"
													>
														Zapisz
													</Button>
												</Authorized>
											</Space>
										</Row>
									</>
								)}
							/>
						)}
					</Col>
				</CenteredRow>
			</>
		);
	}
}
