import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import React, { useState, useEffect } from 'react';
import Table, { ColumnProps } from 'antd/lib/table';
import { PageHeaderWithContent } from 'layout/PageHeaderWithContent';

interface IProps {
	rolePermissions: ProfiBazaAPIModels.RolePermissionDto[];
}

const RolePermissionsTable = (props: IProps) => {
	const columns: ColumnProps<ProfiBazaAPIModels.RolePermissionDto>[] = [
		{
			dataIndex: 'roleName',
			key: 'roleName',
			title: 'Rola',
		},
		{
			dataIndex: 'permissionName',
			key: 'permissionName',
			title: 'Uprawnienie',
		},
	];

	return (
		<>
			<PageHeaderWithContent title="Uprawnienia" />

			<Table<ProfiBazaAPIModels.RolePermissionDto>
				size="small"
				columns={columns}
				dataSource={props.rolePermissions}
				pagination={false}
			/>
		</>
	);
};

export default RolePermissionsTable;
