import { InterventionActivityCommand } from '@components/intervention/model/InterventionBaseCommand';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import {
	CheckDictionaryValueChangeVersionQuery,
	DictionaryInterventionPath,
	DictionaryValueChangeVersionDto,
	InterventionPath,
	RizpDictionaryValueDto,
} from '@services/src/models';
import { Moment } from 'moment';

export const updateActivityDictionaries = (
	model: InterventionActivityCommand,
	interventionPath: InterventionPath,
	plannedDateRange: [Moment?, Moment?] | undefined
): Promise<{ changed: boolean; model: InterventionActivityCommand }> => {
	const dict = new ActivityDictionaries(
		interventionPath,
		model,
		plannedDateRange
	);

	return getProfiBazaApiClient()
		.then((api) =>
			api.dictionaryValue.checkDictionaryValueChangeVersion({
				body: dict.toCommand(),
			})
		)
		.then((response) => {
			if (response.length === 0) return { model: model, changed: false };

			ActivityDictionaries.updateDictionaryValues(model, response);
			return { model: model, changed: true };
		});
};

class ActivityDictionaries {
	interventionPath: DictionaryInterventionPath | undefined;
	dateFrom?: Date;
	dateTo?: Date;
	dictionaryValueIds?: number[];

	constructor(
		interventionPath: InterventionPath,
		model: InterventionActivityCommand,
		plannedDateRange: [Moment?, Moment?] | undefined
	) {
		this.interventionPath = (interventionPath as any) as DictionaryInterventionPath;
		this.dateFrom =
			plannedDateRange && plannedDateRange[0]
				? plannedDateRange[0].utc(true).toDate()
				: undefined;
		this.dateTo =
			plannedDateRange && plannedDateRange[1]
				? plannedDateRange[1].utc(true).toDate()
				: undefined;

		const dictionaries = [
			...model.prpzDictionaryValues!,
			...model.careAreasDictionaryValues!,
			...model.nzpTaskNumberDictionaryValues!,
			...model.publicHealthTaskDictionaryValues!,
			...model.chosenPopulationHealthsDictionaryValues!,
			...model.diseasesMortalityPrevalencesDictionaryValues!,
		];
		let activitiesDictionaries: number[] = [
			model.activityCategoryDictionaryValueId!,
			...dictionaries.map((x) => x.id!),
		];

		this.dictionaryValueIds = activitiesDictionaries;
	}

	toCommand(): CheckDictionaryValueChangeVersionQuery {
		let obj: CheckDictionaryValueChangeVersionQuery = {
			interventionPaths: this.interventionPath,
			dictionaryValueIds: this.dictionaryValueIds!.filter((x) => x),
			dateFrom: this.dateFrom,
			dateTo: this.dateTo,
		};
		return obj;
	}

	static updateDictionaryValues(
		activity: InterventionActivityCommand,
		value: DictionaryValueChangeVersionDto[]
	) {
		const category = value.find(
			(x) =>
				x.oldDictionaryValueId ===
				activity.activityCategoryDictionaryValueId
		);
		if (category) {
			activity.activityCategoryDictionaryValueId =
				category.newDictionaryValue?.id;
		}

		const mapDictionaries = (
			items: RizpDictionaryValueDto[] | undefined
		): RizpDictionaryValueDto[] | undefined =>
			items
				?.map((dict): RizpDictionaryValueDto | undefined => {
					const item = value.find(
						(x) => x.oldDictionaryValueId === dict.id
					);
					if (item) {
						return item.newDictionaryValue
							? {
									id: item.newDictionaryValue.id,
									code: item.newDictionaryValue.code,
									value: item.newDictionaryValue.value,
									codeName: item.newDictionaryValue.codeName,
									breadcrumb: item.newDictionaryValue.breadcrumb,
									otherValue: item.newDictionaryValue
										.canAddTextValue
										? dict.otherValue
										: undefined,
							  }
							: undefined;
					}
					return dict;
				})
				.filter((x) => x !== undefined) as RizpDictionaryValueDto[];

		activity.nzpTaskNumberDictionaryValues = mapDictionaries(
			activity.nzpTaskNumberDictionaryValues
		);

		activity.prpzDictionaryValues = mapDictionaries(
			activity.prpzDictionaryValues
		);
		activity.careAreasDictionaryValues = mapDictionaries(
			activity.careAreasDictionaryValues
		);
		activity.publicHealthTaskDictionaryValues = mapDictionaries(
			activity.publicHealthTaskDictionaryValues
		);
		activity.chosenPopulationHealthsDictionaryValues = mapDictionaries(
			activity.chosenPopulationHealthsDictionaryValues
		);
		activity.diseasesMortalityPrevalencesDictionaryValues = mapDictionaries(
			activity.diseasesMortalityPrevalencesDictionaryValues
		);
	}
}
