import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

export const mappings = new Map<ProfiBazaAPIModels.RIZPSubjectKind, string>([
	[ProfiBazaAPIModels.RIZPSubjectKind.PSSE, 'PSSE'],
	[ProfiBazaAPIModels.RIZPSubjectKind.WSSE, 'WSSE'],
	[ProfiBazaAPIModels.RIZPSubjectKind.GSSE, 'GSSE'],
	[ProfiBazaAPIModels.RIZPSubjectKind.PZH, 'PZH'],
	[ProfiBazaAPIModels.RIZPSubjectKind.GIS, 'GIS'],
	[ProfiBazaAPIModels.RIZPSubjectKind.HOSPITAL, 'Szpital'],
	[ProfiBazaAPIModels.RIZPSubjectKind.CONTRACTOR, 'Kontrahent'],
	[ProfiBazaAPIModels.RIZPSubjectKind.WCZP, 'WCZP'],
	[ProfiBazaAPIModels.RIZPSubjectKind.DEPARTMENT, 'Dział'],
	[ProfiBazaAPIModels.RIZPSubjectKind.DISTRICT, 'Urząd Dzielnicy'],
	[ProfiBazaAPIModels.RIZPSubjectKind.EDUCATIONALUNIT, 'Jednostka oświatowa'],
	[ProfiBazaAPIModels.RIZPSubjectKind.GM, 'Gmina Miejska'],
	[ProfiBazaAPIModels.RIZPSubjectKind.GMW, 'Gmina Miejsko-Wiejska'],
	[ProfiBazaAPIModels.RIZPSubjectKind.GW, 'Gmina Wiejska'],
	[ProfiBazaAPIModels.RIZPSubjectKind.MNP, 'Miasto na pr. powiatu'],
	[ProfiBazaAPIModels.RIZPSubjectKind.SP, 'Starostwo Powiatowe'],
	[ProfiBazaAPIModels.RIZPSubjectKind.UW, 'Urząd Wojewódzki'],
	[ProfiBazaAPIModels.RIZPSubjectKind.UM, 'Urząd Marszałkowski'],
	[ProfiBazaAPIModels.RIZPSubjectKind.IC, 'Instytucja centralna'],
	[ProfiBazaAPIModels.RIZPSubjectKind.M, 'Ministerstwo'],
]);

export const map = (value: ProfiBazaAPIModels.RIZPSubjectKind): string =>
	mappings.get(value) ?? '';
