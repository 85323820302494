import './Layout.less';

import { VerticalAlignTopOutlined } from '@ant-design/icons';
import PermissionValidator from '@authorization/permissionValidator';
import { BackTop, Col, Layout, Menu, Row } from 'antd';
import { useRootData } from 'hooks/hook';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { IGridStore } from 'stores/GridStore';
import { ITableColumnStore } from 'stores/TableColumnStore';

import packageJson from '../../package.json';
import { IAccountDetailsStore } from '../account/accountDetailsStore';
import Account from './Account/Account';
import ApplicationMenu from './Menu/ApplicationMenu';
import { menuItems } from './Menu/MenuInitializer';
import { MenuSectionType } from './Menu/MenuItems';

interface IProps extends RouteComponentProps {}

interface IStore {
	accountDetailsStore: IAccountDetailsStore;
	gridStore: IGridStore;
	tableColumnStore: ITableColumnStore;
}

const ProfiBazaLayout: React.FC<IProps & RouteComponentProps> = observer(
	(props) => {
		const { history } = props;

		const {
			accountDetailsStore,
			gridStore,
			tableColumnStore,
		}: IStore = useRootData((store) => ({
			accountDetailsStore: store.accountDetailsStore,
			gridStore: store.gridStore,
			tableColumnStore: store.tableColumnStore,
		}));

		var permissionValidator = new PermissionValidator(
			accountDetailsStore!.account.get()!
		);

		useEffect(() => {
			const unlistenHistory = history.listen((location, action) => {
				const splitPathname = location.pathname
					.split('/')
					.filter((chunk) => chunk.length);
				const section:
					| MenuSectionType
					| undefined = splitPathname.length
					? (splitPathname[0] as MenuSectionType)
					: undefined;
				const currentTableName = tableColumnStore.getCurrentTable();
				if (!section || !tableColumnStore.isWithinSection(section)) {
					currentTableName && gridStore.resetGrid(currentTableName);
					gridStore.clearSelection();
					tableColumnStore.setCurrentTable(undefined);
				}
			});

			return () => {
				unlistenHistory();
			};
		}, []);

		return (
			<Layout>
				<Layout.Header className="top-bar">
					<Row wrap={false} align="middle">
						<Col>
							<div className="logo mr-xs">
								<img
									alt="Narodowy Instytut Zdrowia Publicznego PZH – Państwowy Instytut Badawczy logotyp. Niebiesko granatowa forma graficzna skrótu PZH w kształcie konturu Polski."
									src="pzh_logo.png"
									style={{ height: '48px' }}
								/>
							</div>

							<div className="logo mr-xs">
								<Link
									aria-label="Start"
									role="button"
									tabIndex={0}
									to={
										accountDetailsStore.account.get()
											?.existsInProfiBaza
											? '/start'
											: '/'
									}
								>
									<img
										alt="ProfiBaza logotyp po lewej stronie znak graficzny niebiesko granatowe paski i kółka na kształt sześciokąta. Po prawej stronie granatowo niebieska nazwa ProfiBaza"
										src="logo.png"
										style={{ height: '40px' }}
									/>
								</Link>
							</div>
						</Col>
						<Col
							xs={16}
							sm={16}
							md={16}
							lg={18}
							xl={19}
							flex="auto"
						>
							<ApplicationMenu
								items={menuItems}
								permissionValidator={permissionValidator}
							/>
						</Col>
						<Col
							xs={8}
							sm={8}
							md={8}
							lg={6}
							xl={5}
							className="account-container"
						>
							<Account />
						</Col>
					</Row>
					{/* </div> */}
				</Layout.Header>

				<Layout.Content className="ant-layout-content--main">
					<BackTop />
					{props.children}
				</Layout.Content>

				<Layout.Footer>
					Profibaza v{packageJson.version}{' '}
					{process.env.REACT_APP_BUILD && (
						<>build {process.env.REACT_APP_BUILD}</>
					)}
				</Layout.Footer>
			</Layout>
		);
	}
);

export default withRouter(ProfiBazaLayout);
