import { SaveOutlined } from '@ant-design/icons';
import { useRootData } from '@hooks/hook';
import { CreateTooltipCommand } from '@services/src/models';
import { Button } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { FTextArea } from 'forms/FormikFormItems';
import { openNotificationWithIcon } from 'help/NotificationHelper';
import ProfiBazaTooltip from 'layout/ProfiBazaTooltip';
import React, { useRef } from 'react';
import { useImperativeHandle } from 'react';
import { ITooltipStore } from 'stores/TooltipStore';

interface IProps {
	initialValue: string | undefined;
	field: string;
}

export interface IEditableTooltipContentReset {
	reset: () => void;
}

const EditableTooltipContent = React.forwardRef<
	IEditableTooltipContentReset,
	IProps
>((props, ref) => {
	const { initialValue, field } = props;

	const formikRef = useRef<Formik<CreateTooltipCommand>>(null);
	const tooltipStore: ITooltipStore = useRootData(
		(store) => store.tooltipStore
	);

	useImperativeHandle(ref, () => ({
		reset: () => {
			formikRef.current?.resetForm();
		},
	}));

	const handleSubmit = (
		values: CreateTooltipCommand,
		formikActions: FormikActions<CreateTooltipCommand>
	) => {
		tooltipStore.create(values as Required<CreateTooltipCommand>, () => {
			formikActions.setSubmitting(false);
			openNotificationWithIcon(
				'success',
				'Podpowiedź została zaktualizowana',
				<></>
			);
		});
	};

	return (
		<Formik<CreateTooltipCommand>
			ref={formikRef}
			validateOnChange
			validateOnBlur
			initialValues={{ field, value: initialValue }}
			enableReinitialize
			isInitialValid
			onSubmit={handleSubmit}
			render={(formikProps: FormikProps<CreateTooltipCommand>) => (
				<div style={{ display: 'flex' }}>
					<Field
						component={FTextArea}
						className="text-white"
						label="Treść podpowiedzi"
						name="value"
						bordered={false}
						autoSize
						maxLength={256}
					/>
					<ProfiBazaTooltip title="Zatwierdź" destroyTooltipOnHide>
						<Button
							aria-label="Zatwierdź"
							shape="circle"
							size="small"
							type="primary"
							icon={<SaveOutlined />}
							loading={formikProps.isSubmitting}
							onClick={() => {
								if (!formikProps.isSubmitting) {
									formikProps.submitForm();
								}
							}}
						/>
					</ProfiBazaTooltip>
				</div>
			)}
		/>
	);
});

export default EditableTooltipContent;
