import Authorized from '@authorization/authorized';
import { SkipLinkContent } from '@components/skipLinks/SkipLinks';
import { InterventionCreateResponse } from '@services/src/models';
import { Col, Form, Select } from 'antd';
import AddButton from 'components/shared/buttons/AddButton';
import SearchBar from 'components/shared/paginatedProfiBazaTable/SearchBar';
import { ajaxByUser } from 'helper/api';
import { CenteredRow } from 'layout/CenteredRow';
import React, { useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';

import AddInterventionModal from './create/AddInterventionModal';
import { AddInterventionModalForm } from './model/AddInterventionModalForm';
import InterventionTable from './table/InterventionTable';

const { Option } = Select;

interface IProps {}

const Interventions: React.FC<RouteComponentProps<IProps>> = (props) => {
	const [visible, setVisible] = useState<boolean>(false);
	const location = useLocation();

	const onCreateIntervention = (value: AddInterventionModalForm) => {
		ajaxByUser('Utworzono interwencję', () =>
			getProfiBazaApiClient()
				.then((api) =>
					api.intervention.create({
						body: {
							interventionPath: value.interventionPath,
							interventionTypeDictionaryValueId:
								value.interventionTypeDictionaryValueId,
							interventionTypeDictionaryOtherValue:
								value.interventionTypeDictionaryOtherValue,
							patternId: value.patternId,
						},
					})
				)
				.then((intervention: InterventionCreateResponse) => {
					props.history.push(`/rizp/edit/${intervention.id}`);
				})
		);
	};

	return (
		<>
			<CenteredRow gutter={[16, 24]} style={{ marginTop: 32 }}>
				<Col span={5}>
					{(location.pathname == '/rizp/intervention' ||
						location.pathname == '/rizp') && (
						<Authorized
							permission={
								ProfiBazaAPIModels.Permission.RIZPModifications
							}
						>
							<AddButton
								tall
								onClick={() => setVisible(true)}
								label="Utwórz interwencję"
							>
								Utwórz interwencję
							</AddButton>
						</Authorized>
					)}
					{location.pathname == '/rizp/patterns' && (
						<Authorized
							permission={
								(ProfiBazaAPIModels.Permission
									.RIZPPatternsModificationsNationwide,
								ProfiBazaAPIModels.Permission
									.RIZPPatternsModificationsVoivodeship)
							}
						>
							<AddButton
								tall
								label="Utwórz wzorzec interwencji"
								onClick={() =>
									props.history.push('/rizp/pattern/add')
								}
							>
								Utwórz wzorzec interwencji
							</AddButton>
						</Authorized>
					)}
				</Col>
				<Col span={13}>
					<Form size="large">
						<SearchBar
							allowTags={true}
							column="(name|codeName)"
							filter="contains"
							gridName={
								location.pathname == '/rizp/patterns'
									? 'patterns'
									: 'intervention'
							}
						/>
					</Form>
				</Col>
				<Col span={5}></Col>
			</CenteredRow>
			<CenteredRow>
				<Col span={23}>
					<SkipLinkContent/>
					<InterventionTable {...props} />
				</Col>
			</CenteredRow>
			<AddInterventionModal
				visible={visible}
				setVisible={(visible: boolean) => setVisible(visible)}
				create={(values: AddInterventionModalForm) => {
					onCreateIntervention(values);
				}}
			></AddInterventionModal>
		</>
	);
};

export default Interventions;
