import { Row, Col, Button } from 'antd';
import DictionaryValuesModal, {
	DictionaryValuesModalProps,
} from '../modals/dictionaryValues/DictionaryValuesModal';
import { DictionaryValueItemDto } from 'services/src/models';
import DeleteValueModal, { IDeleteValueModal } from './DeleteValueModal';
import { ajaxByUser } from 'helper/api';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import OtherValueHistoryModal, {
	IOtherValueHistoryModal,
} from './OtherValueHistoryModal';
import { DictionaryLevelHistory } from './DictionaryLevelHistory';
import React, { useContext } from 'react';
import { MultiDictionaryFooterContext } from './contexts/MultiDictionaryFooterContext';
import { IGridStore } from 'stores/GridStore';
import { useRootData } from 'hooks/hook';

interface IProps {}

const MultiLevelDictionaryFooter: React.FC<IProps> = (props) => {
	const dictionaryFooterState = useContext(MultiDictionaryFooterContext);
	const gridStore: IGridStore = useRootData(store => store.gridStore)

	const {
		notModal,
		isEditable,
		values,
		valueModalProps,
		setActionBaseId,
		rootValue,
		restValueModalProps,
		actionBaseId,
		levels,
		updateValuesCollection,
		deleteModalRef,
		refreshBaseItems,
		getChildren,
		otherValueHistoryModalRef,
		handleCancel,
		processFilters,
	} = dictionaryFooterState;

	return (
		<>
			<Row
				justify="space-between"
				style={{ marginTop: notModal ? 10 : 0 }}
			>
				<Col>
					{isEditable && (
						<>
							<Button
								type="primary"
								onClick={(e) => {
									if (valueModalProps) {
										setActionBaseId(rootValue?.id);
										valueModalProps.setVisible(true);
									}
								}}
							>
								Dodaj
							</Button>
							<DictionaryValuesModal
								{...restValueModalProps}
								noButton
								refresh={(newValue) => {
									if (newValue) {
										const foundIndex = values
											.map((val) => val.id)
											.indexOf(newValue.id);

										let updatedValues: DictionaryValueItemDto[] = [
											...values,
										];
										if (foundIndex !== -1) {
											const foundValue = values.filter(
												(val) => val.id === newValue.id
											);
											newValue.hasChildren =
												foundValue[0].hasChildren;
											updatedValues[
												foundIndex
											] = newValue;
										} else if (
											actionBaseId === rootValue?.id
										) {
											updatedValues.push(newValue);
										} else if (actionBaseId !== undefined) {
											levels.removeCachedValue(
												actionBaseId
											);
											const populatedValueIndex = values
												.map((val) => val.id)
												.indexOf(actionBaseId);
											updatedValues[
												populatedValueIndex
											].hasChildren = true;
										}
										updateValuesCollection(
											rootValue?.id!,
											updatedValues
										);
									}
								}}
								parentId={actionBaseId}
							/>

							<DeleteValueModal
								ref={deleteModalRef}
								handleDelete={(
									id: number,
									deleteDescendants: boolean
								) => {
									ajaxByUser('Usunięto wartość', () =>
										getProfiBazaApiClient()
											.then((api) =>
												api.dictionaryValue.deleteMethod(
													id,
													{
														deleteDescendants: deleteDescendants,
													}
												)
											)
											.then((result) => {
												gridStore.setRefreshDictionaryVersion(true);
												levels.removeCachedValue(
													rootValue?.id!
												);
												if (!rootValue?.id) {
													refreshBaseItems &&
														refreshBaseItems(
															processFilters
														);
												} else {
													getChildren?.(
														rootValue?.id!,
														true
													);
												}
												return result;
											})
									);
								}}
							/>
						</>
					)}
					<OtherValueHistoryModal ref={otherValueHistoryModalRef} />
				</Col>
				{!notModal && (
					<Col>
						<Button onClick={handleCancel}>Zamknij</Button>
					</Col>
				)}
			</Row>
		</>
	);
};

export default MultiLevelDictionaryFooter;
