import { StatusCode } from '@helper/statusCode';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { DownloadFile } from 'components/shared/file/FileDownloadFunction';
import { ajaxCatch } from 'helper/api';
import React, { CSSProperties } from 'react';
import { MenuItem, MenuStateReturn } from 'reakit/Menu';
import { FileType } from 'services/src/models';

import { showNotExpectedErrorModal, showWarning } from './Modals';

export interface ExportBlobType {
	blobBody?: Promise<Blob>;
	_response: any;
}

export interface ExportAsyncType {
	id: string;
	_response: any;
}

interface IProps {
	key?: string | number | undefined;
	style?: CSSProperties;
	fileType: FileType;
	text?: string;
	getFile?: () => Promise<void | ExportBlobType>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	menuSpread: MenuStateReturn;
}

export const FileExportMenuItem: React.FC<IProps> = (props) => {
	const startLoading = () => {
		props.setLoading(true);
	};

	const finishLoading = () => {
		props.setLoading(false);
	};

	const getAsyncExport = (exportId: string, downloadNumber: number) => {
		getProfiBazaApiClient()
			.then((x) => x.exporter.getById(exportId))
			.then((response) => {
				if (response._response.status === StatusCode.NotFound) {
					if (downloadNumber > 7) {
						showWarning(
							'Po zakończeniu operacji system poinformuje o dostępności eksportu do pobrania w widoku powiadomień.'
						);
						finishLoading();
						return;
					}

					setTimeout(() => {
						const nextDownloadNumber = downloadNumber + 1;
						getAsyncExport(exportId, nextDownloadNumber);
					}, downloadNumber * 1000);
				} else {
					DownloadFile({
						response: response as ExportBlobType,
						action: () => finishLoading(),
					});
				}
			})
			.catch((error) => {
				finishLoading();
			});
	};

	const generateFile = (): any => {
		startLoading();
		props.menuSpread.hide();
		props.getFile &&
			ajaxCatch(
				() =>
					props.getFile!().then((response) => {
						console.log(response);
						const asyncExportRes = response as ExportAsyncType;
						if (
							asyncExportRes._response.status ===
							StatusCode.Accepted
						) {
							setTimeout(() => {
								getAsyncExport(asyncExportRes.id, 1);
							}, 1000);
						} else {
							const res = response as ExportBlobType;
							if (
								res._response.status === StatusCode.BadRequest
							) {
								res._response.blobBody
									.then((blob: any) => blob.text())
									.then((text: string) => {
										showNotExpectedErrorModal({
											statusCode: res._response.status,
											response: { body: text },
										});
										finishLoading();
									});
							} else {
								DownloadFile({
									response: response as ExportBlobType,
									action: () => finishLoading(),
								});
							}
						}
					}),
				undefined,
				() => finishLoading()
			);
	};

	return (
		<MenuItem
			key={props.key}
			style={props.style}
			className="ant-menu-item sub-menu-item"
			{...props.menuSpread}
			onClick={generateFile}
			disabled={props.loading}
		>
			{props.text}
		</MenuItem>
	);
};
