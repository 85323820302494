import { SupportedExportType } from '@components/shared/paginatedProfiBazaTable/actions/ExportButtons';
import { SieveModel } from '@components/shared/paginatedProfiBazaTable/SieveModels';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { FileType } from '@services/src/models';

export const exportActions = (
	exportType: SupportedExportType,
	abortSignal: AbortSignal | undefined
) => {
	switch (exportType) {
		case 'default':
			return (
				sieve: SieveModel,
                allRowsSelected?: boolean,
                filter?: any,
				checkedIds?: string[],
				unCheckedIds?: string[],
				fileType?: FileType
			) => {
				return getProfiBazaApiClient().then((api) =>
					api.subject.getFileList({
						sieveFilters: sieve.filters,
						sieveSorts: sieve.sorts,
						sievePage: sieve.page,
						sievePageSize: sieve.pageSize,
						isCheckedAll: allRowsSelected,
						checked: checkedIds,
						unchecked: unCheckedIds,
						fileType: fileType,
						abortSignal,
					})
				);
			};

		case 'full':
			return (
				sieve: SieveModel,
                allRowsSelected: boolean,
                filter?: any,
				checkedIds?: string[],
				unCheckedIds?: string[],
				fileType?: FileType
			) => {
				return getProfiBazaApiClient().then((api) =>
					api.subject.getFileDetails({
						sieveFilters: sieve.filters,
						sieveSorts: sieve.sorts,
						sievePage: sieve.page,
						sievePageSize: sieve.pageSize,
						isCheckedAll: allRowsSelected,
						checked: checkedIds,
						unchecked: unCheckedIds,
						fileType: fileType,
						abortSignal,
					})
				);
			};

		default:
			throw new Error('Not supported export type');
	}
};
