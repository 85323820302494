import useSelectsAccessibility from '@hooks/accessibility/useSelectsAccessibilityHook';
import { useRootData } from '@hooks/hook';
import { Col, Select } from 'antd';
import { FFieldLabel } from 'forms/FormikFormItems';
import { CenteredRow } from 'layout/CenteredRow';
import PrimaryCard from 'layout/PrimaryCard';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getProfiBazaApiClient } from 'services/ProfiBazaApi';
import { FormItemDto } from 'services/src/models';
import { ProfiBazaAPIModels } from 'services/src/profiBazaAPI';
import { IGridStore } from 'stores/GridStore';

interface IProps {
	formId?: string;
}

interface IState {
	formsList: FormItemDto[] | undefined;
	form: FormItemDto | undefined;
}

const FormsTable: React.FC<IProps & RouteComponentProps> = (props) => {
	const [state, setState] = useState<IState>({
		formsList: [],
		form: undefined,
	});

	const gridStore: IGridStore = useRootData((store) => store.gridStore);

	useEffect(() => {
		getProfiBazaApiClient()
			.then((api) =>
				api.form.all({
					filters: undefined,
					page: undefined,
					pageSize: 9999,
					sorts: undefined,
				})
			)
			.then((result: ProfiBazaAPIModels.FormItemDto[]) =>
				setState({
					formsList: result,
					form: result?.find((x) => x.id == props.formId),
				})
			);
	}, []);

	useEffect(() => {
		if (state.form && !props.formId) {
			setState({ ...state, form: undefined });
		}
	}, [props.formId]);

	useEffect(() => {
		if (state.form && !props.formId) {
			setState({ ...state, form: undefined });
		}

		if (
			(!state.form && props.formId) ||
			(state.form && state.form.id !== props.formId)
		) {
			setState({
				...state,
				form: state.formsList?.find(
					(x) => x.id == props.formId
				),
			});
		}
	}, [props.formId]);

	useSelectsAccessibility();

	return (
		<>
			<PrimaryCard>
				<CenteredRow>
					<Col span={8}>
						<FFieldLabel label="Wybierz formularz" for="formId" />
						<Select
							id="formId"
							value={state.form?.id}
							onChange={(value) => {
								setState({
									...state,
									form: state.formsList?.find(
										(x) => x.id == value
									),
								});
								props.history.push(`/forms/${value}`);
								gridStore.clearFormsVersionsMeta.set(Date.now());
							}}
						>
							{state.formsList?.map((x, i) => (
								<Select.Option key={i} value={x.id!}>
									{x.description}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col span={16} />
				</CenteredRow>
			</PrimaryCard>
		</>
	);
};

export default FormsTable;
