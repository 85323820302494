import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import moment, { Moment } from 'moment';
import {
	SubjectKind,
	SubjectStatus,
	AddressDto,
	ContactDto,
	DeadlineProcessDto,
} from 'services/src/models';
import _ from 'lodash';
export class SubjectEditCommand {
	id?: string;
	codeName?: string;
	name?: string;
	nip?: string;
	krsCode?: string;
	cruipCode?: string;
	tercCode?: string;
	regon?: string;
	/**
	 * Possible values include: 'PSSE', 'WSSE', 'PZH', 'GIS', 'HOSPITAL', 'CONTRACTOR', 'WCZP',
	 * 'DEPARTMENT'
	 */
	subjectKind?: SubjectKind;
	/**
	 * Possible values include: 'New', 'Modified', 'Deleted'
	 */
	primarySubjectId?: string;
	parentSubjectId?: string;
	parentSubjectName?: string;
	status?: SubjectStatus;
	validTo?: Moment;
	validFrom?: Moment;
	isDateFromEditable?: boolean;
	active?: boolean;
	expired?: boolean;
	isLaboratory?: boolean;
	address?: AddressDto;
	isStreetRequired?: boolean;
	contacts?: ContactDto[];
	deadlineProcess?: DeadlineProcessDto[];
	isProposalPossibility?: boolean;
	isActiveAdminAccount?: boolean;

	constructor(model?: ProfiBazaAPIModels.SubjectDetailsDto) {
		this.id = model?.id;
		this.tercCode = model?.tercCode;
		this.cruipCode = model?.cruipCode;
		this.codeName = model?.codeName;
		this.name = model?.name;
		this.nip = model?.nip;
		this.krsCode = model?.krsCode;
		this.primarySubjectId = model?.primarySubjectId;
		this.parentSubjectId = model?.parentSubjectId;
		this.parentSubjectName = model?.parentSubjectName;
		this.address = model?.address;
		this.regon = model?.regon;
		this.subjectKind = model?.subjectKind;
		this.status = model?.status;
		this.validTo = model?.validTo ? moment(model.validTo) : undefined;
		this.validFrom = model?.validFrom ? moment(model.validFrom) : undefined;
		this.active = isSubjectActive(model?.validFrom, model?.validTo);

		this.expired =
			!!this?.validTo && moment(new Date()).isAfter(this?.validTo, 'day');

		this.isDateFromEditable =
			!model?.id ||
			(!!model?.validFrom &&
				moment(new Date()).isBefore(model?.validFrom, 'day'));

		this.contacts = model?.contacts;
		this.isStreetRequired = !!model?.address?.street;
		this.deadlineProcess = model?.deadlineProcess;
		this.isProposalPossibility = model?.isProposalPossibility;
		this.isActiveAdminAccount = model?.isActiveAdminAccount;
	}

	static toCommand(
		model: SubjectEditCommand
	): ProfiBazaAPIModels.SubjectDetailsDto {
		const command: ProfiBazaAPIModels.SubjectDetailsDto = {
			id: model.id,
		};
		return command;
	}
}
export const createSubject = (value: SubjectEditCommand) => {
	let body: ProfiBazaAPIModels.CreateSubjectApiCommand = {
		krsCode: value.krsCode,
		tercCode: value.tercCode,
		name: value.name,
		nip: value.nip,
		regon: value.regon,
		subjectKind: value.subjectKind,
		validFrom: value.validFrom?.utc(true).startOf('day').toDate(),
		validTo: value.validTo?.utc(true).startOf('day').toDate(),
		parentSubjectId: value.parentSubjectId,
		address: value.address,
		contacts: value.contacts,
		isProposalPossibility: value.isProposalPossibility,
	};
	return body;
};

export const updateIntervention = (value: SubjectEditCommand, id: any) => {
	let body: ProfiBazaAPIModels.UpdateSubjectApiCommand = {
		id: id,
		krsCode: value.krsCode,
		tercCode: value.tercCode,
		name: value.name,
		nip: value.nip,
		regon: value.regon,
		subjectKind: value.subjectKind,
		validFrom: value.validFrom?.utc(true).startOf('day').toDate(),
		validTo: value.validTo?.utc(true).startOf('day').toDate(),
		parentSubjectId: value.parentSubjectId,
		address: value.address,
		contacts: value.contacts,
		isProposalPossibility: value.isProposalPossibility,
	};
	return body;
};

export const isSubjectActive = (
	validFrom: Date | undefined,
	validTo: Date | undefined
): boolean => {
	return moment(new Date()).isBetween(
		validFrom ?? new Date(),
		validTo ?? new Date(),
		'day',
		'[]'
	);
};

export const getSubjectWithUpdatedStatus = (subject: SubjectEditCommand) => {
	const dataWithUpdatedStatus = _.clone(subject);
	let validFrom = dataWithUpdatedStatus?.validFrom?.utc(true).toDate();
	validFrom?.setHours(0);
	validFrom?.setMinutes(0);
	dataWithUpdatedStatus.active = isSubjectActive(
		validFrom,
		dataWithUpdatedStatus?.validTo?.utc(true).toDate()
	);
	return dataWithUpdatedStatus;
};
