import React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/lib/button';

interface IProps {
	tall?: boolean;
	onClick?: () => void;
	label?: string;
	small?: boolean;
}

function AddButton(props: React.PropsWithChildren<IProps & ButtonProps>) {
	const { tall, type, onClick, small, ...others } = props;

	const styleSettings = tall
		? { height: '100%' }
		: small
		? { fontSize: '11px', height: 28, padding: '6px 10px', marginTop: '-5px' }
		: {};

	return (
		<Button
			icon={<PlusOutlined />}
			size={tall ? 'large' : 'middle'}
			aria-label={props.label}
			type={type ? type : 'primary'}
			style={styleSettings}
			onClick={props.onClick}
			{...others}
		>
			{props.children}
		</Button>
	);
}

export default AddButton;
