import { ajaxByUser, ajaxCatch } from '@helper/api';
import { getProfiBazaApiClient } from '@services/ProfiBazaApi';
import { CommentType } from '@services/src/models';
import { ProfiBazaAPIModels } from '@services/src/profiBazaAPI';
import { IAccountDetailsStore } from 'account/accountDetailsStore';
import { Col, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import {
	FFieldLabel,
	FInput,
	FRadioGroup,
	FTextArea,
} from 'forms/FormikFormItems';
import { useRootData } from 'hooks/hook';
import { CenteredRow } from 'layout/CenteredRow';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { AddCommentDto } from './Commands/AddCommentCommand';
import { UpdateCommentDto } from './Commands/UpdateCommentCommand';

export interface CommentModalProps {
	statementId: string;
	commentId: string | undefined;
	editable: boolean;
	visible: boolean;
	setVisible: (visible: boolean) => void;
	onSave: () => void;
	statementSubjectId: string;
}

const CommentModal: React.FC<CommentModalProps> = (props) => {
	const {
		statementId,
		commentId,
		visible,
		setVisible,
		onSave,
		statementSubjectId,
	} = props;

	const accountDetailsStore: IAccountDetailsStore = useRootData(
		(store) => store.accountDetailsStore
	);

	const [isProcessed, setIsProcessed] = useState<boolean>(false);
	const [updateModel, setUpdateModel] = useState<UpdateCommentDto>();

	useEffect(() => {
		if (commentId) {
			ajaxCatch(() =>
				getProfiBazaApiClient().then((api) => {
					return api.statementComments
						.getById(commentId)
						.then(
							(
								result: ProfiBazaAPIModels.StatementCommentDto
							) => {
								setIsProcessed(
									ProfiBazaAPIModels.CommentStatus
										.PROCESSED == result?.status!
								);
								setUpdateModel(
									new UpdateCommentDto(result, commentId)
								);
							}
						);
				})
			);
		}
	}, [commentId]);

	const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setVisible(false);
	};

	const handleCreate = (
		values: AddCommentDto,
		actions: FormikActions<ProfiBazaAPIModels.CreateCommentCommand>
	) => {
		const command = AddCommentDto.toCommand(values);
		ajaxByUser(
			'Dodano komentarz',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.statementComments
						.create({
							body: command,
						})
						.then((response) => {
							return response;
						})
				),
			() => {
				setVisible(false);
				actions.resetForm();
				onSave();
			},
			(errors) => {
				actions.setErrors(errors);
			}
		).then(() => actions.setSubmitting(false));
	};

	const renderBody = (formikProps: FormikProps<any>) => {
		return (
			<>
				{accountDetailsStore.account.get()?.subject?.id ==
					statementSubjectId &&
					!props.editable && (
						<CenteredRow>
							<Col span={20}>
								{isProcessed && (
									<p style={{ fontWeight: 'bold' }}>
										Komentarz rozpatrzony
									</p>
								)}
							</Col>
						</CenteredRow>
					)}
				<CenteredRow>
					<Col span={20}>
						<Field
							component={FTextArea}
							label="Tutaj wpisz swój komentarz"
							name="description"
							placeholder="Tutaj wpisz swój komentarz"
							readOnly={!props.editable}
						/>
					</Col>
				</CenteredRow>
				<CenteredRow>
					<Col span={10}>
						<FFieldLabel label="Dział / ID interwencji" />
						<Field
							label="Dział / ID interwencji"
							component={FInput}
							name="statementPartInterventionCode"
							readOnly={!props.editable}
						/>
					</Col>
					<Col span={5}>
						<FFieldLabel label="Wiersz" />
						<Field
							label="Wiersz"
							component={FInput}
							name="row"
							readOnly={!props.editable}
						/>
					</Col>
					<Col span={5}>
						<FFieldLabel label="Kolumna" />
						<Field
							label="Kolumna"
							component={FInput}
							name="column"
							readOnly={!props.editable}
						/>
					</Col>
				</CenteredRow>
				{!props.commentId && (
					<Row>
						<Col span={2}></Col>
						<Col span={22} className="commentType">
							<FFieldLabel label="Typ komentarza" />
							<Field
								readOnly={false}
								style={{ width: '100%' }}
								defaultValue={CommentType.PUBLIC}
								values={[
									CommentType.PUBLIC,
									CommentType.PRIVATE,
									CommentType.IMPORTANT,
								]}
								labels={[
									'Informacyjny publiczny',
									'Informacyjny w ramach podmiotu',
									'Ważny',
								]}
								component={FRadioGroup}
								name="commentType"
							/>
						</Col>
					</Row>
				)}
			</>
		);
	};

	const handleUpdate = (
		values: UpdateCommentDto,
		actions: FormikActions<UpdateCommentDto>
	) => {
		const command = UpdateCommentDto.toCommand(values);
		ajaxByUser(
			'Zaktualizowano komentarz',
			() =>
				getProfiBazaApiClient().then((api) =>
					api.statementComments.update({
						body: command,
					})
				),
			() => {
				setVisible(false);
				actions.resetForm();
				setUpdateModel(undefined);
				onSave();
			},
			(errors) => {
				actions.setErrors(errors);
			}
		).then(() => actions.setSubmitting(false));
	};

	return (
		<>
			{props.commentId === undefined ? (
				<Formik
					validateOnChange
					validateOnBlur
					initialValues={{
						statementId: statementId,
						statementPartInterventionCode: undefined,
						column: undefined,
						row: undefined,
						description: undefined,
						commentType: ProfiBazaAPIModels.CommentType.PUBLIC,
					}}
					enableReinitialize
					isInitialValid
					onSubmit={handleCreate}
					render={(
						formikProps: FormikProps<
							ProfiBazaAPIModels.CreateCommentCommand
						>
					) => (
						<Modal
							title="Dodawanie komentarza"
							visible={visible}
							onOk={(e) => {
								formikProps.submitForm();
							}}
							okButtonProps={{
								disabled: formikProps.isSubmitting,
							}}
							cancelButtonProps={{
								disabled: formikProps.isSubmitting,
							}}
							cancelText="Anuluj"
							centered
							onCancel={handleCancel}
							okText="Dodaj"
							destroyOnClose
						>
							{renderBody(formikProps)}
						</Modal>
					)}
				/>
			) : (
				<Formik
					validateOnChange
					validateOnBlur
					initialValues={updateModel || {}}
					enableReinitialize
					isInitialValid
					onSubmit={handleUpdate}
					render={(formikProps: FormikProps<UpdateCommentDto>) => (
						<Modal
							title={
								props.editable
									? 'Zaktualizuj komentarz'
									: 'Szczegóły komentarza'
							}
							visible={visible}
							onOk={() => {
								formikProps.submitForm();
							}}
							okButtonProps={{
								disabled: formikProps.isSubmitting,
								hidden: props.editable ? false : true,
							}}
							cancelButtonProps={{
								disabled: formikProps.isSubmitting,
							}}
							cancelText={props.editable ? 'Anuluj' : 'Zamknij'}
							centered
							onCancel={handleCancel}
							okText={'Aktualizuj'}
							destroyOnClose
						>
							{renderBody(formikProps)}
						</Modal>
					)}
				/>
			)}
		</>
	);
};

export default observer(CommentModal);
