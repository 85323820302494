/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/authorizationInternalMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a AuthorizationInternal. */
export class AuthorizationInternal {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a AuthorizationInternal.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @param apiKey
   * @param [options] The optional parameters
   * @returns Promise<Models.AuthorizationInternalGetSubjectResponse>
   */
  getSubject(apiKey: string, options?: msRest.RequestOptionsBase): Promise<Models.AuthorizationInternalGetSubjectResponse>;
  /**
   * @param apiKey
   * @param callback The callback
   */
  getSubject(apiKey: string, callback: msRest.ServiceCallback<Models.ISubject>): void;
  /**
   * @param apiKey
   * @param options The optional parameters
   * @param callback The callback
   */
  getSubject(apiKey: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ISubject>): void;
  getSubject(apiKey: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ISubject>, callback?: msRest.ServiceCallback<Models.ISubject>): Promise<Models.AuthorizationInternalGetSubjectResponse> {
    return this.client.sendOperationRequest(
      {
        apiKey,
        options
      },
      getSubjectOperationSpec,
      callback) as Promise<Models.AuthorizationInternalGetSubjectResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getSubjectOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "internal/authorization/subject/{apiKey}",
  urlParameters: [
    Parameters.apiKey
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ISubject
    },
    default: {}
  },
  serializer
};
