/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/subjectOperationsMappers";
import * as Parameters from "../models/parameters";
import { ProfiBazaAPIContext } from "../profiBazaAPIContext";

/** Class representing a SubjectOperations. */
export class SubjectOperations {
  private readonly client: ProfiBazaAPIContext;

  /**
   * Create a SubjectOperations.
   * @param {ProfiBazaAPIContext} client Reference to the service client.
   */
  constructor(client: ProfiBazaAPIContext) {
    this.client = client;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectAllResponse>
   */
  all(options?: Models.SubjectAllOptionalParams): Promise<Models.SubjectAllResponse>;
  /**
   * @param callback The callback
   */
  all(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  all(options: Models.SubjectAllOptionalParams, callback: msRest.ServiceCallback<any>): void;
  all(options?: Models.SubjectAllOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.SubjectAllResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      allOperationSpec,
      callback) as Promise<Models.SubjectAllResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectCreateResponse>
   */
  create(options?: Models.SubjectCreateOptionalParams): Promise<Models.SubjectCreateResponse>;
  /**
   * @param callback The callback
   */
  create(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  create(options: Models.SubjectCreateOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  create(options?: Models.SubjectCreateOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.SubjectCreateResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      createOperationSpec,
      callback) as Promise<Models.SubjectCreateResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(options?: Models.SubjectUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  update(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  update(options: Models.SubjectUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  update(options?: Models.SubjectUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      updateOperationSpec,
      callback);
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteMethod(id: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteMethod(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteMethod(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteMethod(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      deleteMethodOperationSpec,
      callback);
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectGetByIdResponse>
   */
  getById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.SubjectGetByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getById(id: string, callback: msRest.ServiceCallback<Models.SubjectDetailsDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SubjectDetailsDto>): void;
  getById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SubjectDetailsDto>, callback?: msRest.ServiceCallback<Models.SubjectDetailsDto>): Promise<Models.SubjectGetByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getByIdOperationSpec,
      callback) as Promise<Models.SubjectGetByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectGetImplementingCompanySubjectsResponse>
   */
  getImplementingCompanySubjects(options?: Models.SubjectGetImplementingCompanySubjectsOptionalParams): Promise<Models.SubjectGetImplementingCompanySubjectsResponse>;
  /**
   * @param callback The callback
   */
  getImplementingCompanySubjects(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getImplementingCompanySubjects(options: Models.SubjectGetImplementingCompanySubjectsOptionalParams, callback: msRest.ServiceCallback<any>): void;
  getImplementingCompanySubjects(options?: Models.SubjectGetImplementingCompanySubjectsOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.SubjectGetImplementingCompanySubjectsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getImplementingCompanySubjectsOperationSpec,
      callback) as Promise<Models.SubjectGetImplementingCompanySubjectsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectGetSponsoringCompanySubjectsResponse>
   */
  getSponsoringCompanySubjects(options?: Models.SubjectGetSponsoringCompanySubjectsOptionalParams): Promise<Models.SubjectGetSponsoringCompanySubjectsResponse>;
  /**
   * @param callback The callback
   */
  getSponsoringCompanySubjects(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getSponsoringCompanySubjects(options: Models.SubjectGetSponsoringCompanySubjectsOptionalParams, callback: msRest.ServiceCallback<any>): void;
  getSponsoringCompanySubjects(options?: Models.SubjectGetSponsoringCompanySubjectsOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.SubjectGetSponsoringCompanySubjectsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getSponsoringCompanySubjectsOperationSpec,
      callback) as Promise<Models.SubjectGetSponsoringCompanySubjectsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectMergeSubjectsResponse>
   */
  mergeSubjects(options?: Models.SubjectMergeSubjectsOptionalParams): Promise<Models.SubjectMergeSubjectsResponse>;
  /**
   * @param callback The callback
   */
  mergeSubjects(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  mergeSubjects(options: Models.SubjectMergeSubjectsOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  mergeSubjects(options?: Models.SubjectMergeSubjectsOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.SubjectMergeSubjectsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      mergeSubjectsOperationSpec,
      callback) as Promise<Models.SubjectMergeSubjectsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectGetProposalPossibilitySubjectsResponse>
   */
  getProposalPossibilitySubjects(options?: Models.SubjectGetProposalPossibilitySubjectsOptionalParams): Promise<Models.SubjectGetProposalPossibilitySubjectsResponse>;
  /**
   * @param callback The callback
   */
  getProposalPossibilitySubjects(callback: msRest.ServiceCallback<any>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getProposalPossibilitySubjects(options: Models.SubjectGetProposalPossibilitySubjectsOptionalParams, callback: msRest.ServiceCallback<any>): void;
  getProposalPossibilitySubjects(options?: Models.SubjectGetProposalPossibilitySubjectsOptionalParams | msRest.ServiceCallback<any>, callback?: msRest.ServiceCallback<any>): Promise<Models.SubjectGetProposalPossibilitySubjectsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getProposalPossibilitySubjectsOperationSpec,
      callback) as Promise<Models.SubjectGetProposalPossibilitySubjectsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectIsMergedSubjectResponse>
   */
  isMergedSubject(options?: Models.SubjectIsMergedSubjectOptionalParams): Promise<Models.SubjectIsMergedSubjectResponse>;
  /**
   * @param callback The callback
   */
  isMergedSubject(callback: msRest.ServiceCallback<{ [propertyName: string]: Models.SubjectIdNameDto }>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isMergedSubject(options: Models.SubjectIsMergedSubjectOptionalParams, callback: msRest.ServiceCallback<{ [propertyName: string]: Models.SubjectIdNameDto }>): void;
  isMergedSubject(options?: Models.SubjectIsMergedSubjectOptionalParams | msRest.ServiceCallback<{ [propertyName: string]: Models.SubjectIdNameDto }>, callback?: msRest.ServiceCallback<{ [propertyName: string]: Models.SubjectIdNameDto }>): Promise<Models.SubjectIsMergedSubjectResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      isMergedSubjectOperationSpec,
      callback) as Promise<Models.SubjectIsMergedSubjectResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectGetFileListResponse>
   */
  getFileList(options?: Models.SubjectGetFileListOptionalParams): Promise<Models.SubjectGetFileListResponse>;
  /**
   * @param callback The callback
   */
  getFileList(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFileList(options: Models.SubjectGetFileListOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getFileList(options?: Models.SubjectGetFileListOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.SubjectGetFileListResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getFileListOperationSpec,
      callback) as Promise<Models.SubjectGetFileListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectGetFileDetailsResponse>
   */
  getFileDetails(options?: Models.SubjectGetFileDetailsOptionalParams): Promise<Models.SubjectGetFileDetailsResponse>;
  /**
   * @param callback The callback
   */
  getFileDetails(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFileDetails(options: Models.SubjectGetFileDetailsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  getFileDetails(options?: Models.SubjectGetFileDetailsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<Models.SubjectGetFileDetailsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getFileDetailsOperationSpec,
      callback) as Promise<Models.SubjectGetFileDetailsResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectGetTinyByIdResponse>
   */
  getTinyById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.SubjectGetTinyByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getTinyById(id: string, callback: msRest.ServiceCallback<Models.SubjectDetailsTinyDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getTinyById(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SubjectDetailsTinyDto>): void;
  getTinyById(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SubjectDetailsTinyDto>, callback?: msRest.ServiceCallback<Models.SubjectDetailsTinyDto>): Promise<Models.SubjectGetTinyByIdResponse> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getTinyByIdOperationSpec,
      callback) as Promise<Models.SubjectGetTinyByIdResponse>;
  }

  /**
   * @summary (external) API Key / JWT Bearer Token
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectUploadAttachmentResponse>
   */
  uploadAttachment(options?: Models.SubjectUploadAttachmentOptionalParams): Promise<Models.SubjectUploadAttachmentResponse>;
  /**
   * @param callback The callback
   */
  uploadAttachment(callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadAttachment(options: Models.SubjectUploadAttachmentOptionalParams, callback: msRest.ServiceCallback<Models.GuidIIdDto>): void;
  uploadAttachment(options?: Models.SubjectUploadAttachmentOptionalParams | msRest.ServiceCallback<Models.GuidIIdDto>, callback?: msRest.ServiceCallback<Models.GuidIIdDto>): Promise<Models.SubjectUploadAttachmentResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      uploadAttachmentOperationSpec,
      callback) as Promise<Models.SubjectUploadAttachmentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectPermissionListResponse>
   */
  permissionList(options?: Models.SubjectPermissionListOptionalParams): Promise<Models.SubjectPermissionListResponse>;
  /**
   * @param callback The callback
   */
  permissionList(callback: msRest.ServiceCallback<Models.SubjectPermissionListItemDtoPagedResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  permissionList(options: Models.SubjectPermissionListOptionalParams, callback: msRest.ServiceCallback<Models.SubjectPermissionListItemDtoPagedResult>): void;
  permissionList(options?: Models.SubjectPermissionListOptionalParams | msRest.ServiceCallback<Models.SubjectPermissionListItemDtoPagedResult>, callback?: msRest.ServiceCallback<Models.SubjectPermissionListItemDtoPagedResult>): Promise<Models.SubjectPermissionListResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      permissionListOperationSpec,
      callback) as Promise<Models.SubjectPermissionListResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.SubjectGetById1Response>
   */
  getById1(id: string, options?: msRest.RequestOptionsBase): Promise<Models.SubjectGetById1Response>;
  /**
   * @param id
   * @param callback The callback
   */
  getById1(id: string, callback: msRest.ServiceCallback<Models.SubjectRolesDto>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getById1(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SubjectRolesDto>): void;
  getById1(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SubjectRolesDto>, callback?: msRest.ServiceCallback<Models.SubjectRolesDto>): Promise<Models.SubjectGetById1Response> {
    return this.client.sendOperationRequest(
      {
        id,
        options
      },
      getById1OperationSpec,
      callback) as Promise<Models.SubjectGetById1Response>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addRole(options?: Models.SubjectAddRoleOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addRole(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addRole(options: Models.SubjectAddRoleOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addRole(options?: Models.SubjectAddRoleOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      addRoleOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteRole(options?: Models.SubjectDeleteRoleOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteRole(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteRole(options: Models.SubjectDeleteRoleOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteRole(options?: Models.SubjectDeleteRoleOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      deleteRoleOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const allOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/Subject",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0,
    Parameters.onlyActiveSubjects
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectListItemDtoPagedResult
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const createOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "subjects/Subject",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.CreateSubjectApiCommand
  },
  responses: {
    201: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "subjects/Subject",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.UpdateSubjectApiCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "subjects/Subject/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/Subject/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectDetailsDto
    },
    default: {}
  },
  serializer
};

const getImplementingCompanySubjectsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/Subject/getImplementingCompanySubjects",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectListItemDtoPagedResult
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const getSponsoringCompanySubjectsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/Subject/getSponsoringCompanySubjects",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectListItemDtoPagedResult
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const mergeSubjectsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "subjects/Subject/mergeSubjects",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.MergeSubjectsApiCommand
  },
  responses: {
    200: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const getProposalPossibilitySubjectsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/Subject/getProposalPossibilitySubjects",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectListItemDtoPagedResult
    },
    404: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    default: {}
  },
  serializer
};

const isMergedSubjectOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "subjects/Subject/isMergedSubject",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Uuid"
          }
        }
      }
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Composite",
              className: "SubjectIdNameDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getFileListOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/Subject/export/list",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const getFileDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/Subject/export/details",
  queryParameters: [
    Parameters.sieveFilters,
    Parameters.sieveSorts,
    Parameters.sievePage,
    Parameters.sievePageSize,
    Parameters.isCheckedAll,
    Parameters.checked,
    Parameters.unchecked,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Stream"
        }
      }
    },
    default: {}
  },
  serializer
};

const getTinyByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "subjects/Subject/{id}/tiny",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectDetailsTinyDto
    },
    default: {}
  },
  serializer
};

const uploadAttachmentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "subjects/Subject/uploadAttachment",
  formDataParameters: [
    Parameters.id3,
    Parameters.file,
    Parameters.fileCategoryDictionaryValueId,
    Parameters.fileCategoryDictionaryOtherValue
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {
      bodyMapper: Mappers.GuidIIdDto
    },
    default: {}
  },
  serializer
};

const permissionListOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "authorization/Subject",
  queryParameters: [
    Parameters.filters,
    Parameters.sorts,
    Parameters.page0,
    Parameters.pageSize0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectPermissionListItemDtoPagedResult
    },
    default: {}
  },
  serializer
};

const getById1OperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "authorization/Subject/{id}",
  urlParameters: [
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SubjectRolesDto
    },
    default: {}
  },
  serializer
};

const addRoleOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "authorization/Subject/role",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.AddSubjectRoleCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteRoleOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "authorization/Subject/role",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.DeleteSubjectRoleCommand
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};
